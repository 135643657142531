import Bar, { IBar, IBarData } from "../atoms/Bar";
import { Box, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import classes from "./BarGroup.module.css";
interface IBarGroupProps {
  /**
   * The bars to be shown in the bar group
   */
  bars: IBarData[];

  /**
   * Gap between the bars
   */
  gap?: number;

  /**
   * Max value of the bars
   */
  max: number;

  /**
   * Type of the bar
   */
  type?: string;
}

const BarGroup = ({ bars, gap = 100, max, type = "Visitors" }: IBarGroupProps) => {

  return (
    <Box px={20} h={"100%"}>
      <Box className={classes["bar-group"]}>
        <Box
          className={classes.bar}
          style={{
            gap: `${gap}px`
          }}>
          {bars.map((bar, index) => (
            <Bar
              key={index}
              data={bar}
              nextData={bars[index + 1] || null}
              max={max}
              isLast={index === bars.length - 1}
              width={bars.length <= 4 ? 120 : 80}
              type={type}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default BarGroup;
