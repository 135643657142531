/* eslint-disable react-hooks/exhaustive-deps */
import { getReadableFormatNumber, numberToCommas } from "@/lib/utils/StringUtility";
import React from "react";
import { ActiveCardProps, LoadingStateProps } from "@/types/types.d";
import { SummaryComparisonCard } from "../../../Cards/SummaryComparisonCard";

type Props = {
  activeCard: ActiveCardProps;
  loading: LoadingStateProps;
  comparisonData: any;
};

const UniqueVisitorsConvComparisonCard = ({ activeCard, loading, comparisonData }: Props) => {
  return (
    <div data-cy="events-card">
      <SummaryComparisonCard
        activeCard={activeCard}
        card={"unique_visitors"}
        uniquekey={"uniqueVisitors"}
        loading={loading}
        comparisonValue={getReadableFormatNumber(comparisonData?.unique_visitors)}
        comparisonTooltip={`${numberToCommas(comparisonData.unique_visitors)} Unique Visitors`}
      />
    </div>
  );
};
const UniqueVisitorsConvComparisonCardMemoized = React.memo(UniqueVisitorsConvComparisonCard);
export { UniqueVisitorsConvComparisonCardMemoized as UniqueVisitorsConvComparisonCard };
