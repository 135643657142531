import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { ConditionItem } from "types/types.d";
import create from "zustand";

interface Props {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  id: string;
  setId: (id: string) => void;
  name: string;
  setName: (name: string) => void;
  description: string;
  setDescription: (description: string) => void;
  isVisitorsStepEnabled: boolean;
  setIsVisitorsStepEnabled: (value: boolean) => void;
  isLocked: boolean;
  setIsLocked: (value: boolean) => void;
  lockedInsightId: string | null;
  setLockedInsightId: (lockedInsightId: string | null) => void;
  conditions: ConditionItem[];
  setConditions: (conditions: ConditionItem[]) => void;
  reset: (overwrites?: any) => void;
  stepsOrder: "sequential" | "strict";
  setStepsOrder: (stepsOrder: "sequential" | "strict") => void;
  funnelType: "visitor" | "user" | "company";
  setFunnelType: (funnelType: "visitor" | "user" | "company") => void;
  activeFunnelTab: "all" | "visitor" | "user" | "company";
  setActiveFunnelTab: (funnelType: "all" | "visitor" | "user" | "company") => void;
  windowSize: number;
  setWindowSize: (windowSize: number) => void;
  windowSizeType: "days" | "hours" | "minutes";
  setWindowSizeType: (windowSizeType: "days" | "hours" | "minutes") => void;
  // set funnel
  setFunnelStore: (funnel: any) => void;

  // update funnel set

  setUpdateFunnel: (funnel: any) => void;

  // listing

  list: any[];
  setList: (list: any[]) => void;
  listLoading: boolean;
  setListLoading: (listLoading: boolean) => void;
  loadingMore: boolean;
  noMoreData: boolean;

  fetchAll: (
    wsId: string,
    sortOrder: string | null,
    startDate?: string,
    endDate?: string,
    pageNo?: number
  ) => void;
}

export const useConversionFunnelStore = create<Props>((set) => ({
  // create new funnel
  modalOpen: false,
  setModalOpen: (modalOpen: boolean) => set({ modalOpen }),
  id: "",
  setId: (id: string) => set({ id }),
  name: "",
  setName: (name: string) => set({ name }),
  description: "",
  setDescription: (description: string) => set({ description }),
  stepsOrder: "sequential",
  setStepsOrder: (stepsOrder: "sequential" | "strict") => set({ stepsOrder }),
  funnelType: "visitor",
  setFunnelType: (funnelType: "visitor" | "user" | "company") => set({ funnelType }),

  activeFunnelTab: "all",
  setActiveFunnelTab: (activeFunnelTab: "all" | "visitor" | "user" | "company") =>
    set({ activeFunnelTab }),

  isVisitorsStepEnabled: true,
  setIsVisitorsStepEnabled: (value: boolean) => set({ isVisitorsStepEnabled: value }),
  isLocked: false,
  setIsLocked: (value: boolean) => set({ isLocked: value }),
  lockedInsightId: null,
  setLockedInsightId: (lockedInsightId: string | null) => set({ lockedInsightId }),
  conditions: [],
  setConditions: (conditions: ConditionItem[]) => set({ conditions }),
  windowSize: 0,
  setWindowSize: (windowSize: number) => set({ windowSize }),
  windowSizeType: "days",
  setWindowSizeType: (windowSizeType: "days" | "hours" | "minutes") =>
    set({ windowSizeType: windowSizeType }),
  reset: (overwrites?: any) =>
    set({
      id: "",
      name: "",
      isVisitorsStepEnabled: true,
      isLocked: false,
      conditions: [],
      stepsOrder: "sequential",
      funnelType: "visitor",
      noMoreData: false,
      loadingMore: false,
      description: "",
      windowSize: 0,
      windowSizeType: "days",
      ...overwrites
    }),

  // set funnel
  setFunnelStore: (funnel: any) => {
    set({
      name: funnel.name,
      isVisitorsStepEnabled: funnel.conditions?.is_visitors_step_enabled,
      isLocked: funnel.is_locked,
      lockedInsightId: funnel.locked_insight_id,
      conditions: funnel.conditions?.funnel_conditions || [],
      stepsOrder: funnel.conditions?.steps_order || "sequential",
      funnelType: funnel.conditions?.funnel_type || "visitor",
      windowSize: funnel.conditions?.window_size || 0,
      description: funnel.description,
      windowSizeType: funnel.conditions?.window_size_type || "days"
    });
  },

  // update funnel
  setUpdateFunnel: (funnel: any) => {
    set({
      id: funnel.id,
      name: funnel.name,
      isVisitorsStepEnabled: funnel.conditions?.is_visitors_step_enabled,
      isLocked: funnel.is_locked,
      lockedInsightId: funnel.locked_insight_id,
      conditions: funnel.conditions?.funnel_conditions || [],
      stepsOrder: funnel.conditions?.steps_order || "sequential",
      funnelType: funnel.conditions?.funnel_type || "visitor",
      windowSize: funnel.conditions?.window_size || 0,
      windowSizeType: funnel.conditions?.window_size_type || "days",
      description: funnel.description
    });
  },

  // listing
  list: [],
  setList: (list: any[]) => set({ list }),
  listLoading: false,
  setListLoading: (listLoading: boolean) => set({ listLoading }),
  loadingMore: false,
  noMoreData: false,
  /**
   * Fetch all conversion funnels for web analytics. This is used to display the conversion funnel cards.
   */
  fetchAll: async (wsId: string, sortOrder: string | null, funnelType = "all", pageNo = "1") => {
    if (Number(pageNo) === 1) {
      set({ listLoading: true, list: [], noMoreData: false });
    } else {
      set({ loadingMore: true, noMoreData: false });
    }
    await new WebAnalyticsService()
      .funnelConversionsList(
        wsId,
        sortOrder || "created_at:desc",
        Number(pageNo) || 1,
        "",
        "web_conversion_funnel",
        12,
        funnelType
      )
      .then((res) => {
        if (Number(pageNo) === 1) {
          if (res.data) {
            set({ list: res.data, noMoreData: res.data.length < 3 ? true : false });
          } else {
            set({ list: [] });
          }
        } else {
          console.log("state list", res.data);
          if (res.data) {
            set((state) => {
              return {
                list: [...state.list, ...res.data],
                loadingMore: false,
                noMoreData: res.data.length < 3 ? true : false
              };
            });
          }

          if (!res.data || res.data.length < 3) {
            set({ noMoreData: true, loadingMore: false });
          }
        }
      })
      .catch((err) => err);
    set({ listLoading: false });
  }
}));
