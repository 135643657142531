import { InsightEventButtonCard } from "@/ui/components/App/SetupGuides/InsightEventButtonCard/InsightEventButtonCard"

const InsightsSetupGuide = () => {
  return (
    <>
      <div className="w-9/12 bg-white my-6 ml-4 border p-6 border-gray-200 border-solid">
        <h2 className="font-bold text-xl pb-4 ">Setup Insights</h2>
        <p className="pb-4">
          To begin with getting insights for your SaaS business, you need to answer the following
          questions and select relevant events.
        </p>
        <div className="h-px bg-gray-100 my-4"></div>
        <h3 className="font-semibold py-4 mb-4 text-gray-700 flex items-center">
          {/* <span className="w-8 h-8 flex items-center bg-purple-700 text-white rounded-full justify-center mr-4">
            1
          </span> */}
          1. What conversion goals do you have on your main website?
        </h3>
        <div className="grid grid-cols-2 gap-x-3 gap-y-6">
          <InsightEventButtonCard
            title={"Sign up"}
            description="Track sign up conversion rate."
            icon={"user-plus"}
          ></InsightEventButtonCard>
          <InsightEventButtonCard
            title={"Book a demo"}
            description="Track booking a demo conversion rate."
            icon={"laptop-code"}
          ></InsightEventButtonCard>
        </div>

        <div className="h-px bg-gray-100 my-4"></div>

        <h3 className="font-semibold mb-4 py-4 text-gray-700 flex items-center">
          {/* <span className="w-8 h-8 flex items-center bg-purple-700 text-white rounded-full justify-center mr-4">
            2
          </span> */}
          2. What are the steps required for the user to reach a core functionality of your product?
        </h3>
        <div className="grid grid-cols-2 gap-x-3 gap-y-3">
          <InsightEventButtonCard
            title={"Onboarding Steps"}
            icon={"th-list"}
            description="Measure your onboarding conversion rate, see on which steps users are getting stuck."
          ></InsightEventButtonCard>
        </div>
        <div className="h-px bg-gray-100 my-4"></div>
        <div className="font-semibold mb-4  py-4 text-gray-700 flex items-center">
          {/* <span className="w-8 h-8 flex items-center bg-purple-700 text-white rounded-full justify-center mr-4">
            3
          </span> */}
          <div className="flex flex-col">
            <h3 className="flex items-center pb-2">
              3. What actions does the user perform once he is onboarded?
            </h3>

            <p>
              Think of the functionalities that are more likely to make first time users more
              successful.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-3 gap-y-3">
          <InsightEventButtonCard
            title={"Product engagement actions"}
            icon={"users"}
            description={"Track product actions such as created campaign, published content, etc."}
            showChecked={false}
          ></InsightEventButtonCard>
        </div>
        <div className="h-px bg-gray-100 my-4"></div>
        <div className="font-semibold mb-4  py-4 text-gray-700 flex items-center">
          {/* <span className="w-8 h-8 flex items-center bg-purple-700 text-white rounded-full justify-center mr-4">
            4
          </span> */}
          <div className="flex flex-col">
            <h3 className="flex items-center pb-2">
              4. What are the main features of your product?
            </h3>
            <p>
              Select the features of your product that are likely to provide value to your users and
              customers.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-3 gap-y-3">
          <InsightEventButtonCard
            title={"Product features"}
            description={
              "Measure your feature adoption and see which features are most popular. e.g publish content, schedule report, etc. "
            }
            icon={"badge-check"}
            showChecked={false}
          ></InsightEventButtonCard>
        </div>
      </div>
    </>
  )
}
export default InsightsSetupGuide
