import { MantineTheme, useMantineColorScheme } from "@mantine/core";
import { IFunnelStep } from "../FunnelDetailedView/FunnelDetails";
import { numberToCommas } from "../../../../../lib/utils/StringUtility";

export const useFunnelChartOption = (theme: MantineTheme, isMobile: boolean = false) => {
  const { colorScheme } = useMantineColorScheme();
  return {
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "cross",
        crossStyle: {
          width: 0
        },
        label: {
          backgroundColor: "#676767"
        }
      },
      borderWidth: 0,
      rich: {
        b: {
          fontSize: 13,
          lineHeight: 20
        },
        c: {
          fontSize: 13,
          lineHeight: 20
        }
      }
    },
    grid: {
      left: isMobile ? 100 : 220,
      top: 40,
      right: isMobile ? 60 : 120,
      bottom: 40
    },
    xAxis: [
      {}, // empty to shift the second axis to the top
      {
        type: "value",
        name: "",
        min: 0,
        max: "dataMax",
        alignTicks: true,
        axisTick: {
          show: false,
          splitNumber: 4
        },
        axisPointer: {
          label: {
            show: false
          }
        },
        axisLabel: {
          formatter: function (value: number) {
            return value.toLocaleString("en-US", {
              notation: "compact",
              compactDisplay: "short"
            });
          }
        },

        splitLine: {
          lineStyle: {
            color: colorScheme === "dark" ? theme.colors.dark[8] : "#f8f8f8",
            width: 2
          }
        }
      }
    ],
    yAxis: {
      type: "category",
      inverse: true,
      data: [],
      axisTick: {
        show: false,
        splitNumber: 4
      },
      axisLabel: {
        align: "left",
        margin: isMobile ? 100 : 210,
        rich: {
          a: {
            margin: 30,
            borderRadius: 4,
            borderWidth: 1,
            fontWeight: "600",
            padding: [5, 8],
            fontSize: 12,
            color: colorScheme === "dark" ? theme.colors.dark[0] : "#595c5f",
            backgroundColor: colorScheme === "dark" ? theme.colors.dark[6] : "#f1f3f5",
            borderColor: colorScheme === "dark" ? theme.colors.dark[6] : "#f1f3f5",
            fontFamily: "Inter, sans-serif"
          },

          b: {
            fontSize: 13,
            fontWeight: "600",
            padding: [5, 8],
            color: colorScheme === "dark" ? theme.colors.dark[0] : "#595c5f",
            fontFamily: "Inter, sans-serif"
          }
        },
        formatter: function (value: string, index: number) {
          if (isMobile) {
            return `{a|${index + 1}} {b|${
              value.length > 8 ? value.substring(0, 8) + "..." : value
            }}`;
          }

          return `{a|${index + 1}} {b|${
            value.length > 24 ? value.substring(0, 24) + "..." : value
          }}`;
        }

        // formatter: function (value: string) {
        //   return value.length > 20 ? value.substring(0, 20) + "..." : value
        // },
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: colorScheme === "dark" ? theme.colors.dark[6] : "#f1f3f5"
        }
      }
    },
    calculable: true,
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        },
        pointWidth: 10,
        pointPadding: 0
      }
    },
    series: [
      {
        name: "Funnel",
        type: "bar",
        data: [],
        stack: "visitors",
        barGap: "50%",
        barCategoryGap: "50%",
        xAxisIndex: 1,
        label: {
          show: true,
          // position: "insideBottomRight",
          position: ["0%", "125%"],
          offset: [10, 5],
          textShadowColor: "transparent",
          fontFamily: "Inter, sans-serif",
          fontWeight: "600",
          color: "#c74554",

          rich: {
            a: {
              fontFamily: "Inter, sans-serif",
              fontWeight: "600",
              color: colorScheme === "dark" ? theme.colors.dark[0] : "#030303"
            },
            b: {
              color: "#a173f3",
              fontFamily: "Inter, sans-serif",
              fontWeight: "600"
            },
            c: {
              color: "#26b774",
              fontFamily: "Inter, sans-serif",
              fontWeight: "600"
            },
            time: {
              height: 12,
              align: "left",
              backgroundColor: {
                image:
                  "https://storage.googleapis.com/contentstudio-media-library-nearline/media_library/5fa4f1cc2b07115bfb632ad2/uncategorized/original/iDJr2y5d7QMYMCHvxdzF7J8caK8XSSeaeeI3Mr33.png"
              }
            }
          }
        },
        color: "#7f17d1",

        itemStyle: {
          borderRadius: [0, 6, 6, 0]
        }
      },

      {
        name: "Funnel",
        type: "bar",
        xAxisIndex: 1,
        label: {
          show: true,
          position: "insideLeft",
          textShadowColor: "transparent",
          fontWeight: "600",
          color: "#c74554",
          fontSize: 10,
          fontFamily: "Inter, sans-serif",
          // offset: [10, 5],
          rich: {
            a: {
              fontWeight: "600",
              padding: [3, 6],
              borderRadius: 8,
              borderWidth: 1,
              borderColor: colorScheme === "dark" ? theme.colors.dark[7] : "#ebdafe",
              fontSize: 10,
              color: colorScheme === "dark" ? theme.colors.dark[1] : "#5230af",
              backgroundColor: colorScheme === "dark" ? theme.colors.dark[5] : "#ebdafe",
              fontFamily: "Inter, sans-serif"
            },

            b: {
              color: colorScheme === "dark" ? theme.colors.dark[0] : "#030303",
              fontWeight: "600",
              textShadowColor: "transparent",
              fontSize: 10,
              align: "center",
              fontFamily: "Inter, sans-serif"
            },
            c: {
              color: "#a173f3",
              fontSize: 10,
              fontFamily: "Inter, sans-serif",
              fontWeight: "600"
            },
            time: {
              height: 12,
              width: 12,
              align: "left",
              backgroundColor: {
                image:
                  "https://storage.googleapis.com/contentstudio-media-library-nearline/media_library/5fa4f1cc2b07115bfb632ad2/uncategorized/original/iDJr2y5d7QMYMCHvxdzF7J8caK8XSSeaeeI3Mr33.png"
              }
            }
          }
        },

        data: [],
        stack: "visitors",
        color: colorScheme === "dark" ? theme.colors.dark[5] : "#e9ecef",
        emphasis: {
          disabled: true
        },
        itemStyle: {
          borderRadius: [0, 6, 6, 0]
        }
      }
    ],

    animationDuration: 0,
    animationDurationUpdate: 3000,
    animationEasing: "linear",
    animationEasingUpdate: "linear"
  };
};

export const useFunnelBreakdownOptions = (
  theme: MantineTheme,
  steps: IFunnelStep[][],
  stepsNames: string[],
  seriesData: any,
  isMobile: boolean = false,
  tooltipLabels: string[] = [
    "Completed Step",
    "Completed Step Ratio",
    "Drop Off (from previous step)",
    "Drop Off Ratio (from previous step)",
    "Drop Off Ratio (from first step)"
  ]
) => {
  const { colorScheme } = useMantineColorScheme();
  return {
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "cross",
        crossStyle: {
          width: 0
        },
        label: {
          backgroundColor: "#676767"
        }
      },
      borderWidth: 0,
      rich: {
        b: {
          fontSize: 13,
          lineHeight: 20
        },
        c: {
          fontSize: 13,
          lineHeight: 20
        }
      },
      formatter: (params: any) => {
        const { seriesIndex, dataIndex } = params;

        // Resolve the seriesIndex to the correct step
        // for example, seriesIndex 0, 1 refers to the first step, 2, 3 refers to the second step, etc.
        const stepIndex = Math.floor(seriesIndex / 2);
        const data = steps[stepIndex][dataIndex];
        const header = `<div style="
                              color: ${colorScheme === "dark" ? theme.colors.dark[0] : "#000d21"};
                              font-size: 14px;
                              font-weight: 600;
                              font-family: 'Inter', sans-serif;
                              background: ${
                                colorScheme === "dark"
                                  ? theme.colors.dark[6]
                                  : "rgba(240, 240, 240, 0.4)"
                              };
                              border-bottom: 1px solid rgba(204, 204, 212, 0.31);
                              border-color: ${
                                colorScheme === "dark"
                                  ? theme.colors.dark[6]
                                  : "rgba(204, 204, 212, 0.31)"
                              };
                              padding: 0.75rem 1rem;
                            ">${data.breakdown_prop}</div>`;

        const values = [
          {
            name: tooltipLabels[0],
            value: numberToCommas(data.visitors),
            color: theme.colors.green[7]
          }
        ];

        if (data.conv_rate_prev_step !== null) {
          values.push({
            name: tooltipLabels[1],
            value: data.conv_rate_prev_step + "%",
            color: theme.colors.green[7]
          });
        } else if (params.dataIndex === 0 && data.conv_rate_prev_step === null) {
          values.push({
            name: tooltipLabels[1],
            value: "100%",
            color: theme.colors.green[7]
          });
        }

        if (data.drop_off_count_prev_step && data.drop_off_count_prev_step > 0) {
          values.push({
            name: tooltipLabels[2],
            value: numberToCommas(data.drop_off_count_prev_step),
            color: theme.colors.red[7]
          });
        }

        if (data.drop_off_rate_prev_step !== null) {
          values.push({
            name: tooltipLabels[3],
            value: data.drop_off_rate_prev_step + "%",
            color: theme.colors.red[7]
          });
        }

        if (data.drop_off_rate_from_first_step && data.drop_off_rate_from_first_step > 0) {
          values.push({
            name: tooltipLabels[4],
            value: data.drop_off_rate_from_first_step + "%",
            color: theme.colors.red[7]
          });
        }

        return `
               <div style='
                margin: -10px;
                background-color: ${colorScheme === "dark" ? theme.colors.dark[5] : "#FFF"};
               '>
                  ${header}
                  <div style='
                    display: flex;
                    flex-direction: column;
                    padding: 0.5rem 0;
                  '>
                    ${values
                      .map(
                        (item) =>
                          `
                        <div style='
                          padding: 0.2rem 1rem;
                          display: flex;
                          line-height: 1.25rem;
                          font-size: 0.9325rem;
                          gap: 16px;
                        '>
                          <div style="
                                display: flex;
                                flex-grow: 1;
                                font-family: 'Inter', sans-serif;
                                color: ${item.color}
                              "
                          >
                              ${item.name}
                          </div>
                          <div style="
                            font-weight: 700;
                            font-family: 'Inter', sans-serif;
                            color: ${colorScheme === "dark" ? theme.colors.dark[0] : "#000d21"}
                           "
                           >
                              ${item.value}
                          </div>
                        </div>
                        `
                      )
                      .join(" ")}
                  </div>
                </div>
              `;
      }
    },
    legend: {
      top: "2%",
      textStyle: {
        color: colorScheme === "dark" ? theme.colors.dark[0] : "#000d21"
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        },
        pointWidth: 10,
        pointPadding: 0
      }
    },
    xAxis: [
      {}, // empty to shift the second axis to the top
      {
        type: "value",
        name: "",
        min: 0,
        max: "dataMax",
        alignTicks: true,
        axisTick: {
          show: false,
          splitNumber: 4
        },
        axisPointer: {
          label: {
            show: false
          }
        },
        axisLabel: {
          formatter: function (value: number) {
            return value.toLocaleString("en-US", {
              notation: "compact",
              compactDisplay: "short"
            });
          }
        },
        splitLine: {
          lineStyle: {
            color: colorScheme === "dark" ? theme.colors.dark[8] : "#f8f8f8",
            width: 2
          }
        }
      }
    ],
    grid: {
      left: isMobile ? 100 : 220,
      top: 100,
      right: isMobile ? 60 : 120,
      bottom: 40
    },
    yAxis: [
      {
        type: "category",
        axisTick: { show: false },
        inverse: true,
        data: stepsNames,
        axisLabel: {
          align: "left",
          margin: isMobile ? 100 : 210,
          rich: {
            a: {
              margin: 30,
              borderRadius: 4,
              borderWidth: 1,
              fontWeight: "600",
              padding: [5, 8],
              fontSize: 12,
              color: colorScheme === "dark" ? theme.colors.dark[0] : "#595c5f",
              backgroundColor: colorScheme === "dark" ? theme.colors.dark[6] : "#f1f3f5",
              borderColor: colorScheme === "dark" ? theme.colors.dark[6] : "#f1f3f5",
              fontFamily: "Inter, sans-serif"
            },

            b: {
              fontSize: 13,
              fontWeight: "600",
              padding: [5, 8],
              color: colorScheme === "dark" ? theme.colors.dark[0] : "#595c5f",
              fontFamily: "Inter, sans-serif"
            }
          },

          formatter: function (value: string, index: number) {
            if (isMobile) {
              return `{a|${index + 1}} {b|${
                value.length > 8 ? value.substring(0, 8) + "..." : value
              }}`;
            }

            return `{a|${index + 1}} {b|${
              value.length > 24 ? value.substring(0, 24) + "..." : value
            }}`;
          }
        },

        axisLine: {
          show: true,
          lineStyle: {
            color: colorScheme === "dark" ? theme.colors.dark[6] : "#f1f3f5"
          }
        }
      }
    ],
    series: [...seriesData],

    animationDuration: 0,
    animationDurationUpdate: 3000,
    animationEasing: "linear",
    animationEasingUpdate: "linear"
  };
};
