import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { UserService } from "@/lib/services/UserService";
import { TOAST_MESSAGES } from "@/lib/utils/Constants";
import { Button, Text, Title } from "@mantine/core";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { convertDateToTimezone } from "../../../../lib/utils/DateUtility";

export const AccountsSupportAccess: React.FC<any> = () => {
  const { user, setUser, activeWorkspace } = useContext(AppLifecycleContext);
  const [loading, setLoading] = useState("idle");

  const supportAccessAction = async (data: any) => {
    console.log(data);
    const userServiceObj = new UserService();
    setLoading("loading");
    await userServiceObj
      .updateRequestSupportAccess(user?.id, data.requested_support_access)
      .then((res) => {
        if (res.data) {
          setUser(res.data);
          toast.success(
            data.requested_support_access
              ? TOAST_MESSAGES.SUPPORT_ACCESS_GIVEN
              : TOAST_MESSAGES.SUPPORT_ACCESS_REVOKED
          );
        }
      })
      .catch((err) => {
        toast.error("Unable to update support access, please try again.");
      });
    setLoading("loaded");
  };

  const getDescriptionMessage = user?.requested_support_access
    ? `You have granted Usermaven access to your account for support purposes. This access expires ${convertDateToTimezone(
        user.requested_support_access_expires_at,
        activeWorkspace.timezone
      )}.`
    : "Grant Usermaven support temporary access to your account so we can troubleshoot problems on your behalf. You can revoke access at any time";

  return (
    <>
      <Title order={4} fw={600} mb="md">
        Support access
      </Title>
      <Text mb="md">{getDescriptionMessage}</Text>
      {user.requested_support_access ? (
        <Button
          type="submit"
          color={"red.6"}
          disabled={loading === "loading"}
          loading={loading === "loading"}
          onClick={() => supportAccessAction({ requested_support_access: false })}>
          Revoke Access
        </Button>
      ) : (
        <Button
          type="submit"
          disabled={loading === "loading"}
          loading={loading === "loading"}
          onClick={() => supportAccessAction({ requested_support_access: true })}>
          Allow Support Access
        </Button>
      )}
    </>
  );
};
