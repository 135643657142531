import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  Code,
  Text,
  useMantineTheme,
  Image,
  Accordion,
  Avatar,
  Anchor,
  Flex,
  Title
} from "@mantine/core";
import { CodeHighlight } from "@mantine/code-highlight";
// import { Prism } from "@mantine/prism";
// import duotoneDark from "prism-react-renderer/themes/dracula";
// import githubTheme from "prism-react-renderer/themes/github";
import ConfigureUsermavenActionsImg from "@assets/images/icons/integrations/segment/configure-usermaven-actions.png";
import SelectDataSourceImg from "@assets/images/icons/integrations/segment/select-data-source.png";
import DestinationNameImg from "@assets/images/icons/integrations/segment/destination-name.png";
import AddCredentialsImg from "@assets/images/icons/integrations/segment/add-credentials.png";

import { useContext } from "react";
import { GeneralAPIKeyAndServerToken } from "@/ui/pages/Settings/WorkspaceSettings/General/GeneralAPIKeyAndServerToken";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { identifyPixelSnippet, trackingEventsSnippet } from "@/lib/utils/TrackingPixelUtility";
import { Link, NavLink } from "react-router-dom";

type ISegmentInstallationProps = {
  connectedDataSource?: boolean;
  identifiedUsers?: boolean;
  trackedBehaviors?: boolean;
};

export const SegmentInstallation = ({
  connectedDataSource = false,
  identifiedUsers = false,
  trackedBehaviors = false
}: ISegmentInstallationProps) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const SEGMENT_URL = `https://app.segment.com/<YOUR WORKSPACE SLUG>/destinations/catalog/actions-usermaven`;
  const WORKSPACE_SLUG = `<Your Workspace Slug>`;

  const defaultOpened = connectedDataSource
    ? identifiedUsers
      ? trackedBehaviors
        ? "" // all done - all panels closed
        : "custom_events" // only custom events left
      : "user_identify" // first step done - second panel open
    : "connect_destination"; // nothing done - first panel open
  return (
    <>
      <Title order={4} my="md">
        Credentials
      </Title>
      <GeneralAPIKeyAndServerToken withBorder={true} withHeader={false} />
      <Accordion mt={32} variant="separated" defaultValue={defaultOpened}>
        <Accordion.Item value="connect_destination">
          <Accordion.Control>
            <Flex align={"center"}>
              {connectedDataSource ? (
                <Avatar size="md" color="teal.7" radius="xl" className="mr-4">
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" mr={"sm"}>
                  1
                </Avatar>
              )}
              <Text className="flex-grow" fz="md" fw="bold">
                Connect Usermaven Destination to your Segment Source
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <>
              <Text className="py-4" fz="sm" mb="sm">
                Installing Usermaven via Segment allows you to send the user identities, custom user
                properties, group events and custom events configured in Segment.
              </Text>
              <Text mb="sm" className="py-4" fz="sm">
                As of now our integration is in Private Beta and not listed in Segment Catalog.
                Please use the following link to install Usermaven as a destination in your Segment
                workspace.
              </Text>
              <CodeHighlight mb="sm" code={SEGMENT_URL} language="text" />
              <Box w="75%" my={"sm"}>
                <Alert
                  color="yellow"
                  icon={<FontAwesomeIcon className="text-2xl" icon={regular("info-circle")} />}>
                  <Flex align={"center"}>
                    <Text fw={500}>Note:</Text> replace{" "}
                    <Code mr={4} color="orange">
                      {WORKSPACE_SLUG}
                    </Code>
                    with your Segment workspace slug.
                  </Flex>
                </Alert>
              </Box>
              <Image
                src={ConfigureUsermavenActionsImg}
                radius={"md"}
                className="rounded-md shadow-sm border border-solid border-gray-200 mb-4"
              />
              <Text className="py-4" fz="sm" mt="sm">
                Next, click on the{" "}
                <span className="font-semibold">"Configure Usermaven (Actions)"</span> button.
              </Text>
            </>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="data_source">
          <Accordion.Control>
            <Flex align={"center"}>
              {connectedDataSource ? (
                <Avatar size="md" color="teal.7" radius="xl" className="mr-4">
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" mr="sm">
                  2
                </Avatar>
              )}
              <Text className="flex-grow" fz="md" fw="bold">
                Select data source
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <>
              <Text className="py-4" fz="sm" mb="sm">
                On the next step, you will be asked to select the data source. Select the source
                from which you want to send the data to Usermaven.
              </Text>
              <Image src={SelectDataSourceImg} radius={"md"} mb="sm" />

              <Text className="py-4" fz="sm" mb="sm">
                Next step is to provide the Destination name.
              </Text>
              <Image src={DestinationNameImg} radius={"md"} />
            </>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="api_key">
          <Accordion.Control>
            <Flex align={"center"}>
              {connectedDataSource ? (
                <Avatar size="md" color="teal.7" radius="xl" className="mr-4">
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" className="mr-4 font-bold">
                  3
                </Avatar>
              )}
              <Text className="flex-grow" fz="md" fw="bold">
                Add API Key and Server Token
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <>
              <Text className="py-4" fz="sm" mb="sm">
                After that you need to add the API Key and Server Token. Once done, turn on the
                "Enable Destination".
              </Text>
              <Image src={AddCredentialsImg} radius={"md"} mb="sm" />
              <Text className="py-4" fz="sm">
                Click on "Save Changes" button and you are done. Now, your Segment source will start
                sending the data to Usermaven.
              </Text>
            </>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="user_identify">
          <Accordion.Control>
            <Flex align={"center"}>
              {identifiedUsers ? (
                <Avatar size="md" color="teal.7" radius="xl" className="mr-4">
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" className="mr-4 font-bold">
                  4
                </Avatar>
              )}
              <Text className="flex-grow" fz="md" fw="bold">
                Send users and companies attributes
              </Text>
            </Flex>
          </Accordion.Control>

          <Accordion.Panel>
            <Text className="py-4" fz="sm" mb="sm">
              <Text fz="sm" span fw={500}>
                Next, use segment identify function
              </Text>{" "}
              to send us customer data from segment.
            </Text>

            <Text className="py-4" fz="sm">
              For example, now if you are using javascript as a source, and added{" "}
              <b>segment snippet</b> to your website, you can{" "}
              <Anchor
                fz="sm"
                href={"https://segment.com/docs/connections/spec/identify/"}
                target={"_blank"}>
                identify
              </Anchor>{" "}
              users like this:
            </Text>
            <CodeHighlight
              language="javascript"
              code={`analytics.identify(
  'lzL24K3kYw'    // Unique ID for the user in database.
  {
  // Required attributes
  email: "user@domain.com", // Email address for the user.
  created_at: "2021-01-20T09:55:35",   // DateTime string in your system that represents when the user first signed up.
  
  // Recommended attributes
  // First name and last name are shown on user pages.
  first_name: 'John',       
  last_name: 'Smith',       
});
              `}
            />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="custom_events">
          <Accordion.Control>
            <Flex align={"center"}>
              {trackedBehaviors ? (
                <Avatar size="md" color="teal.7" radius="xl" className="mr-4">
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" className="mr-4 font-bold">
                  5
                </Avatar>
              )}
              <Text className="flex-grow" fz="md" fw="bold">
                Send important events
              </Text>
            </Flex>
          </Accordion.Control>

          <Accordion.Panel>
            <Text className="py-4" fz="sm" mb="md">
              Usermaven allows you to track user behaviours by using Pinned Events as well as have
              an option to send the custom events.
            </Text>
            <Flex mb={16} ml={16} mt="sm">
              <Avatar variant="outline" size="sm" color="brand.5" radius="xl">
                1
              </Avatar>
              <Box ml={16}>
                <Title order={5} mb={"sm"}>
                  Pinned Events
                </Title>
                <Text fz="sm">
                  You can create your Pinned events from{" "}
                  <NavLink to={`/env/${activeWorkspace.identifier}/events/pinned-events`}>
                    here
                  </NavLink>
                  .{" "}
                  <Anchor
                    fz="sm"
                    href="https://usermaven.com/docs/getting-started/creating-pinned-events"
                    target="_blank">
                    Learn more about Pinned Events.
                  </Anchor>
                </Text>
              </Box>
            </Flex>
            <Flex ml={16}>
              <Avatar variant="outline" size="sm" color="brand.5" radius="xl">
                2
              </Avatar>
              <Box ml={16}>
                <Title order={5} mb="sm">
                  Custom Events
                </Title>
                <Box>
                  <Text className="py-4" fz="sm" mb="sm">
                    Start sending your important events such as <Code>signed_up</Code>,
                    <Code>book-a-demo</Code> etc.
                  </Text>

                  <CodeHighlight
                    lang="javascript"
                    code={`analytics.track("book-a-demo", {
  // additional properties
});`}
                  />
                </Box>
              </Box>
            </Flex>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
