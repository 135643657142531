import create from "zustand";

export interface Feature {
  id: string;
  name: string;
  conditions: Record<string, any>; // You can specify the conditions type more accurately
  adoption_rate: number;
  retention_period: string;
  is_new: boolean;
  is_active: boolean;
  is_saved: boolean;
}

export interface Module {
  id: string;
  name: string;
  description: string;
  is_default: boolean;
  features: Feature[];
  is_new: boolean;
  is_active: boolean;
  is_saved: boolean;
}

interface FeatureAdoptionStore {
  onboardingFeatureAdoption: boolean;
  setOnboardingFeatureAdoption: (onboardingFeatureAdoption: boolean) => void;
  showManageFeatureAdoptionModal: boolean;
  setShowManageFeatureAdoptionModal: (showManageFeatureAdoptionModal: boolean) => void;
  modules: Module[];
  setModules: (modules: Module[]) => void;
  resetModules: () => void;
  addModule: (data: Module) => void;
  updateModule: (moduleId: string, updatedValues: any) => void;
  removeModule: (moduleId: string) => void;
  addFeatureToModule: (moduleId: string, feature: Feature) => void;
  updateFeature: (moduleId: string, featureId: string, updatedValues: any) => void;
  updateFeaturesInModule: (moduleId: string, updatedFeatures: any) => void;
  removeFeatureFromModule: (moduleId: string, featureId: string) => void;
  isNewFeatureAvailable: (moduleId: string) => boolean;
  moveFeatureToAnotherModule: (moduleId: string, featureId: string, newModuleId: string) => void;
}

// Define the store
const useFeatureAdoptionStore = create<FeatureAdoptionStore>((set, get) => ({
  onboardingFeatureAdoption: false,
  setOnboardingFeatureAdoption: (onboardingFeatureAdoption: boolean) => {
    set({ onboardingFeatureAdoption });
  },
  showManageFeatureAdoptionModal: false,
  setShowManageFeatureAdoptionModal: (showManageFeatureAdoptionModal: boolean) => {
    set({ showManageFeatureAdoptionModal });
  },
  modules: [],
  setModules: (modules: Module[]) => {
    set({ modules });
  },
  resetModules: () => {
    set({ modules: [] });
  },
  addModule: (data: Module) => {
    set((state) => ({
      modules: [
        ...state.modules,
        data
        // { id: moduleId, name: moduleName, description: description, is_default: is_default, features: [], is_new: is_new, is_active: is_active, is_saved: is_saved },
      ]
    }));
  },
  // Generic function to update a module with given updated values
  updateModule: (moduleId: string, updatedValues: any) => {
    set((state) => ({
      modules: state.modules.map((module) =>
        module.id === moduleId
          ? {
              ...module,
              ...updatedValues
            }
          : module
      )
    }));
  },

  // Function to remove a module based on moduleId
  removeModule: (moduleId: string) => {
    set((state) => ({
      modules: state.modules.filter((module) => module.id !== moduleId)
    }));
  },

  addFeatureToModule: (moduleId, feature) => {
    set((state) => ({
      modules: state.modules.map((module) =>
        module.id === moduleId
          ? {
              ...module,
              features: [...module.features, feature]
            }
          : module
      )
    }));
  },
  // Function to update particular attribute within a feature of a module
  updateFeature: (moduleId: string, featureId: string, updatedValues: any) => {
    set((state) => ({
      modules: state.modules.map((module) =>
        module.id === moduleId
          ? {
              ...module,
              features: module.features.map((feature) =>
                feature.id === featureId
                  ? {
                      ...feature,
                      ...updatedValues
                    }
                  : feature
              )
            }
          : module
      )
    }));
  },
  // Function to update all features of a module
  updateFeaturesInModule: (moduleId: string, updatedFeatures: any) => {
    set((state) => ({
      modules: state.modules.map((module) =>
        module.id === moduleId
          ? {
              ...module,
              features: updatedFeatures
            }
          : module
      )
    }));
  },
  // Function to remove a feature from a module
  removeFeatureFromModule: (moduleId: string, featureId: string) => {
    set((state) => ({
      modules: state.modules.map((module) =>
        module.id === moduleId
          ? {
              ...module,
              features: module.features.filter((feature) => feature.id !== featureId)
            }
          : module
      )
    }));
  },
  // Computed state to check if any feature in a module is new
  isNewFeatureAvailable: (moduleId: any) => {
    const module = get().modules.find((module: { id: any }) => module.id === moduleId);
    if (module) {
      return module.features.some((feature: { is_new: any }) => feature.is_new);
    }
    return false;
  },
  // Function to move a feature from one module to another
  moveFeatureToAnotherModule: (moduleId: string, featureId: string, newModuleId: string) => {
    const feature = get()
      .modules.find((module: { id: any }) => module.id === moduleId)
      ?.features.find((feature: { id: any }) => feature.id === featureId);
    if (feature) {
      get().removeFeatureFromModule(moduleId, featureId);
      get().addFeatureToModule(newModuleId, feature);
    }
  }
}));

export default useFeatureAdoptionStore;
