import { DateDropdownMenu } from "../../../../components/App/Dropdowns/DateDropdownMenu";
import { useContext, useEffect } from "react";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useWebFiltersStore } from "../../../../../stores/useWebFiltersStore";
import { getReportingPeriodRange } from "../../../../../lib/utils/ReportingPeriodUtility";
import { WorkspaceMiscService } from "@/lib/services/WorkspaceMiscService";
import { toast } from "react-toastify";
import { Box, Title, Text, Divider } from "@mantine/core";

export const MiscellaneousWorkspaceReportingPeriod = () => {
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [setDateLabel] = useWebFiltersStore((state) => [state.setDateLabel]);

  const handleOnChange = (startDate: string, endDate: string, label: string) => {
    new WorkspaceMiscService()
      .reportingPeriod(activeWorkspace.id, label.replaceAll(" ", "_").toLowerCase())
      .then((res) => {
        if (res.data) {
          activeWorkspace.reporting_period = res.data.data;
          setActiveWorkspace(activeWorkspace);
          toast.success(`Your default reporting period has been set to ${label}`);
        }
      })
      .catch((err) => err);
  };

  useEffect(() => {
    setDateLabel(
      getReportingPeriodRange(activeWorkspace.reporting_period)?.label || "Last 30 Days"
    );
  }, []);

  return (
    <>
      <Title order={4} fw={600} mb="md">
        Default Reporting period
      </Title>
      <Text mb="md">Select the default value for the time range filter</Text>
      <DateDropdownMenu onChange={handleOnChange} allowCustom={false} />
    </>
  );
};
