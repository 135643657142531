import { Box, Flex, Skeleton, Title, Tooltip, useMantineTheme } from "@mantine/core";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import ReactTooltip from "react-tooltip";
import ContentLoader from "react-content-loader";
import classes from "./SummaryCard.module.css";
import clsx from "clsx";
interface Props {
  activeCard: ActiveCardProps;
  card: ActiveCardProps;
  uniquekey: string;
  loading: LoadingStateProps;
  borderRight?: boolean;
  cursor?: string;
  onClick?: () => void;
  comparisonValue?: number | string;
  comparisonTooltip?: string;
  comparisonPercentageDiff?: number;
}

export const SummaryComparisonCard = ({
  activeCard,
  card,
  uniquekey,
  loading,
  borderRight = true,
  onClick,
  cursor = "cursor-pointer",
  comparisonValue,
  comparisonTooltip
}: Props) => {
  return (
    <Box
      className={clsx(
        classes.card,
        !borderRight && activeCard ? classes.noBorder : "",
        !borderRight && !activeCard ? classes.noBorder : "",
        card === activeCard ? classes.activeCard : ""
      )}
      p={"xs"}
      onClick={onClick}>
      <>
        {loading === "loaded" ? (
          <>
            <Flex align="center" flex={1}>
              <Tooltip label={comparisonTooltip}>
                <Title order={4}>{comparisonValue}</Title>
              </Tooltip>
            </Flex>
          </>
        ) : (
          <>
            <Box mt={8}>
              <Skeleton height={15} width={100} />
            </Box>
          </>
        )}
      </>
    </Box>
  );
};
