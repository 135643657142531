import { ConditionItem } from "../../../../types/types.d";
import {
  ActionIcon,
  Autocomplete,
  Box,
  Button,
  Select,
  TextInput,
  Badge,
  useMantineTheme,
  useMantineColorScheme,
  Flex
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { InsightCondition } from "../InsightsSetupEvents/InsightCondition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import axios from "../../../../lib/services/JWTService";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface IJourneyGroupingRule {
  conditions: Array<{ alias: string; regex: string }>;
  setConditions: (conditions: Array<{ alias: string; regex: string }>) => void;
  onAddCondition?: () => void;
  onRemoveCondition?: (index: number) => void;
  withAddCondition?: boolean;
  addConditionLabel?: string;
  conditionsErrors: Array<{ alias: string; regex: string }>;
}

const JourneyGroupingRule = ({
  conditions,
  setConditions,
  conditionsErrors,
  onAddCondition = () => {},
  onRemoveCondition = () => {},
  withAddCondition = false,
  addConditionLabel = "Add condition"
}: IJourneyGroupingRule) => {
  const theme = useGlobalMantineTheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const { colorScheme } = useMantineColorScheme();
  const handleItemSelectChange = (index: number, value: string, type: "alias" | "regex") => {
    const newConditions = [...conditions];
    newConditions[index][type] = value;
    setConditions(newConditions);
  };

  return (
    <>
      <div>
        <div>
          {conditions.map((item, index) => (
            <Box mb="md" key={index}>
              {index > 0 && (
                <>
                  <Badge
                    radius={"sm"}
                    color={colorScheme === "dark" ? "dark.5" : "gray.6"}
                    variant="filled">
                    OR
                  </Badge>
                </>
              )}

              <Box mt={"md"}>
                <Flex
                  align={"center"}
                  // sx={(theme) => ({
                  //   [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                  //     flexWrap: "wrap",
                  //     gap: 10,

                  //     ".um-insights-setup-events__filters-item-drag": {
                  //       display: "none"
                  //     },

                  //     ".um-insights-setup-events__filters-item-select": {
                  //       width: "100%",
                  //       marginRight: "0",
                  //       marginLeft: "0",

                  //       ".usrmvn-Select-root": {
                  //         width: "100%"
                  //       }
                  //     },

                  //     "usrmvn-Select-wrapper": {
                  //       width: "100%"
                  //     },

                  //     ".um-error-field": {
                  //       position: "relative"
                  //     },

                  //     ".um-insights-setup-events__filters-input-text": {
                  //       marginLeft: "0 !important"
                  //     }
                  //   }
                  // })}
                >
                  <TextInput
                    mr={"sm"}
                    placeholder={"Enter the Alias"}
                    value={item.alias}
                    onChange={(e) => handleItemSelectChange(index, e.target.value, "alias")}
                    error={conditionsErrors[index] ? conditionsErrors[index].alias : undefined}
                  />

                  <Box w={"100%"} mr={"sm"}>
                    <TextInput
                      w={"100%"}
                      placeholder={"Enter the regex. For example: /merchants/d+/dashboard$"}
                      value={item.regex}
                      onChange={(e) => handleItemSelectChange(index, e.target.value, "regex")}
                      error={conditionsErrors[index] ? conditionsErrors[index].regex : undefined}
                    />
                  </Box>

                  {/* Remove item from the list */}
                  {(conditions.length > 1 || withAddCondition) && (
                    <>
                      <ActionIcon
                        variant="outline"
                        color={"red"}
                        onClick={() => onRemoveCondition(index)}>
                        <FontAwesomeIcon icon={regular("trash")} />
                      </ActionIcon>
                    </>
                  )}
                </Flex>
              </Box>
            </Box>
          ))}

          {withAddCondition && (
            <Button
              variant="outline"
              // color={theme.colorScheme === 'dark' ? theme.colors.dark[5] : 'gray.9'}
              // variant={theme.colorScheme === 'dark' ? 'subtle' : 'light'}
              size="sm"
              leftSection={<FontAwesomeIcon icon={regular("circle-plus")} />}
              onClick={onAddCondition}>
              {addConditionLabel}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default JourneyGroupingRule;
