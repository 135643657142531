import {
  Box,
  Button,
  Center,
  Divider,
  Paper,
  Space,
  Text,
  Title,
  useMantineTheme
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import { MOBILE_BREAKPOINT_QUERY, PLAN_PRODUCT_TYPE } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { TrackingPixel } from "@/ui/components/App/TrackingPixel/TrackingPixel";
import { useMediaQuery } from "@mantine/hooks";

const IntegrationSetupGuide = () => {
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState("idle");
  const checkIsPixelInstalled = async (showToast: boolean = true) => {
    setIsLoading("loading");
    const wsService = new WorkspaceService();

    await wsService
      .isPixelEventsAvailable(activeWorkspace?.id)
      .then((res) => {
        if (res.data?.id) {
          setActiveWorkspace(res.data);
          navigate(`/env/${res.data.identifier}/web/statistics`);
        } else {
          if (showToast) {
            toast.error(
              "Pixel not installed yet. If you have installed pixel, please wait for few minutes."
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading("loaded");
  };

  useEffect(() => {
    // For the web product type users, check if pixel installed and act accordingly.
    if (activeWorkspace?.organization?.plan?.plan_type === PLAN_PRODUCT_TYPE.WEB) {
      checkIsPixelInstalled(false);
    }
  }, []);

  return (
    <>
      <Paper
        withBorder
        component="div"
        p={"md"}
        style={{
          width: isMobileView ? "100%" : "",
          marginLeft: isMobileView ? "0" : ""
        }}
        my="md">
        <Title mb={"md"} order={4}>
          Install Usermaven
        </Title>
        <Text mb="sm">
          Start integrating Usermaven into your site with our easy-to-use JavaScript snippet, or
          explore other integration options including Segment, Google Tag Manager, Python SDK, Wix,
          WordPress, and more for seamless data transmission.
        </Text>

        <Divider my="sm" />

        <TrackingPixel workspaceId={activeWorkspace?.identifier}></TrackingPixel>

        <Center my="md">
          <Button
            onClick={() => {
              checkIsPixelInstalled();
            }}
            loading={isLoading === "loading"}>
            Check Installation
          </Button>
        </Center>
      </Paper>
    </>
  );
};

export default IntegrationSetupGuide;
