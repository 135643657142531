/* eslint-disable react-hooks/exhaustive-deps */
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  Skeleton,
  Text,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { openConfirmModal, openModal } from "@mantine/modals";
import ErrorStateIcon from "@assets/images/icons/error-state.svg";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspacePinnedEventsService } from "@/lib/services/WorkspacePinnedEventsService";
import { AUTO_CAPTURE_WIZARD_TYPES, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { ClickhouseDateToLocalRelativeTime } from "@/lib/utils/DateUtility";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { useContext, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useAutoCapturedEventsStore } from "@/stores/useAutoCapturedEventsStore";
import { usePinnedEventsListStore } from "@/stores/usePinnedEventListStore";
import { usePinnedEventStore } from "@/stores/usePinnedEventStore";
import { loaderColor } from "@/style/Colors";
import { PulseLineGraph } from "@/ui/components/App/PulseLineGraph/PulseLineGraph";
import { LooseObject } from "types/types.d";
import { EventsDetailedActivity } from "./Components/EventsDetailedActivity";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const PinnedEvents = () => {
  const theme = useGlobalMantineTheme();

  /**
   * Active workspace of the project
   */
  const { activeWorkspace } = useContext(AppLifecycleContext);
  /**
   * Permission utility hook which checks if the user has permission to perform certain actions
   */
  const { hasRolesNotWith } = useWorkspaceUtilityHook();

  /**
   * State handler for detailed activity view
   */
  const [eventDetailedActivityView, setEventDetailedActivityView] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<LooseObject>({});
  const [firstOccurred, setFirstOccurred] = useState<string>("");
  const [lastOccurred, setLastOccurred] = useState<string>("");

  /**
   * Zustand store for pinned events
   */
  const [
    setPinnedEventId,
    setPinnedEventName,
    setPinnedEventModal,
    resetPinnedEvent,
    setRules,
    fetchTotalEvents
  ] = usePinnedEventStore((state) => [
    state.setId,
    state.setName,
    state.setModalOpen,
    state.reset,
    state.setRules,
    state.fetchTotalEvents
  ]);

  /**
   * Iteration #1 had an option to select the events, we will remove it based on the customer's feedback.
   */
  const [setSelectedEvents, setWizard, resetAutoCapturedEvents] = useAutoCapturedEventsStore(
    (state) => [state.setSelectedEvents, state.setWizard, state.reset]
  );

  /**
   * Zustand stored for pinned events list.
   */
  const [
    loading,
    search,
    setSearch,
    list,
    setList,
    hasMore,
    page,
    fetch,
    reset,
    fetchHistory,
    eventsHistoryList,
    historyLoading
  ] = usePinnedEventsListStore((state) => [
    state.loading,
    state.search,
    state.setSearch,
    state.list,
    state.setList,
    state.hasMore,
    state.page,
    state.fetch,
    state.reset,
    state.fetchHistory,
    state.historyList,
    state.historyLoading
  ]);

  useEffect(() => {
    fetch(activeWorkspace.id, 1);
    return () => {
      reset();
    };
  }, []);

  /**
   * Edit pinned event id. This is used to open the modal to edit the pinned event.
   * @param id Pinned event id
   */
  const editPinnedEvent = (id: string) => {
    new WorkspacePinnedEventsService()
      .get(activeWorkspace.id, id)
      .then((res) => {
        if (res.data.id) {
          setPinnedEventId(res.data.id);
          setPinnedEventName(res.data.name);
          setSelectedEvents(res.data.rules);
          setWizard(AUTO_CAPTURE_WIZARD_TYPES.REVIEW_AND_SAVE);
          setPinnedEventModal(true);
          setRules(res.data.rules);
          fetchTotalEvents(activeWorkspace.id);
        }
      })
      .catch((err) => {});
  };

  /**
   * Function to remove pinned event
   * @param id of Pinned event
   */
  const removePinnedEvent = (id: string) => {
    new WorkspacePinnedEventsService()
      .delete(activeWorkspace.id, id)
      .then((res) => {
        if (res.data.message) {
          toast.success(res.data.message);
          const filteredList = list.filter((item: any) => item.id !== id);
          setList(filteredList);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          toast.error(err.response.data.detail);
        }
      });
  };

  /**
   * Function to confirm the removal of pinned event
   * @param event Pinned event
   */

  const removePinnedPrompt = (event: any) => {
    openConfirmModal({
      title: "Delete pinned event",
      children: <Text size="sm">Are you sure you want to delete your pinned event?</Text>,
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6", className: "pinned-event-confirm-delete" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => removePinnedEvent(event.id)
    });
  };

  /**
   * Function to check usage of pinned event. If the pinned event is already used in insights, then it cannot be deleted.
   * @param pinnedEvent pinned event
   */

  const promptPinnedEventUsage = (pinnedEvent: any) => {
    openModal({
      title: <h2 className="flex flex-grow-1 justify-center">Pinned event cannot be deleted</h2>,
      // title: "Pinned event cannot be deleted",
      children: (
        <>
          <div className=" ">
            <div className="flex items-center justify-center pb-4 ">
              <img src={ErrorStateIcon} alt="" className="h-32 w-32" />
            </div>

            <p className="pb-4">
              Your <span className="font-semibold">{pinnedEvent.name}</span> is{" "}
              <span className="font-semibold">used {pinnedEvent.usages.length} time(s) </span>
              in the following conversion goals, funnel analysis, insights or segments:
            </p>
            <ul className="ml-12 list-disc mb-8 font-semibold">
              {pinnedEvent.usages.map((usage: any) => (
                <li key={`usage-${usage.id}`}>
                  {usage.insight &&
                    `${usage.insight.name} (${usage.insight.type.replaceAll("_", " ")})`}
                  {usage.segment && `${usage.segment.name} (Segment)`}
                  {usage.feature && `${usage.feature.name} (Adoption Feature)`}
                </li>
              ))}
            </ul>
          </div>
        </>
      )
    });
  };

  /**
   * Function to show the usage prompt or remove the pinned event.
   * If the pinned event is not used, then it can be deleted.
   * If the pinned event is used, then it cannot be deleted.
   * @param pinnedEvent
   */

  const handleDeletePinnedEvent = (pinnedEvent: any) => {
    if (pinnedEvent.usages.length > 0) {
      promptPinnedEventUsage(pinnedEvent);
    } else {
      removePinnedPrompt(pinnedEvent);
    }
  };

  /**
   * Handle opening the detailed activity modal
   */
  const handleOpenEventsDetailedActivity = (event: any) => {
    setSelectedEvent(event);
    setFirstOccurred(
      ClickhouseDateToLocalRelativeTime(eventsHistoryList[event.id].first_occurrence)
    );
    setLastOccurred(ClickhouseDateToLocalRelativeTime(eventsHistoryList[event.id].last_occurrence));
    setEventDetailedActivityView(!eventDetailedActivityView);
  };

  useEffect(() => {
    console.log("fetchEventsHistory");
    fetchHistory(activeWorkspace.id);
  }, [list.length]);

  useEffect(() => {
    document.title = "Events | Pinned | Usermaven";
  }, []);

  return (
    <>
      <EventsDetailedActivity
        pinnedEventId={selectedEvent.id}
        eventName={selectedEvent.name}
        firstOccurred={firstOccurred}
        lastOccurred={lastOccurred}
        eventType="pinned"
        insightUsage={selectedEvent.usages}
        eventDetailedActivityView={eventDetailedActivityView}
        setEventDetailedActivityView={setEventDetailedActivityView}
      />
      <div>
        <Text mb="md">
          Pinned events are the most important events you've picked from auto-captured events. You
          can use them for conversion goals, funnels, journeys, product analytics and across
          Useramven's tools.
        </Text>
        <Divider mb="md" />
        <Box component="div">
          {/* <div className="flex py-4 items-center">
          <SearchInput placeholder="Search event by name" size="medium" onChange={e => {}} />
        </div> */}

          <Box>
            <Box>
              {list.length > 0 && (
                <Grid fz="sm" fw={600} my={"md"}>
                  <Grid.Col
                    span={3}
                    onClick={() => {
                      //   changeSortOrder("event_type")
                    }}>
                    Name
                  </Grid.Col>
                  <Grid.Col span={2}>Total occurrence</Grid.Col>
                  <Grid.Col span={2}>First occurred</Grid.Col>
                  <Grid.Col span={2}>Last occurred</Grid.Col>
                  <Grid.Col span={2} ta="center">
                    Activity{" "}
                    <Tooltip
                      withArrow
                      label="Last 30 days of events activity."
                      aria-label="Last 30 days of events activity.">
                      <FontAwesomeIcon
                        icon={regular("info-circle")}
                        className="text-gray-700 ml-2 cursor-pointer"
                      />
                    </Tooltip>
                  </Grid.Col>
                  <Grid.Col span={1}>Actions</Grid.Col>
                </Grid>
              )}

              <Divider py="sm" />
              <>
                {list.length > 0 ? (
                  <>
                    {list.map((event, index: number) => (
                      <>
                        <Grid
                          pb="md"
                          c={event.is_muted ? "gray.7" : undefined}
                          key={`pinned:event:${event.id}:${index}`}>
                          <Grid.Col span={3}>
                            <Tooltip label={`Click to see detailed view`}>
                              <Text
                                fw={600}
                                data-cy="pinned-event-name-list"
                                className="cursor-pointer hover:text-purple-500"
                                onClick={() => handleOpenEventsDetailedActivity(event)}>
                                {event.name}
                              </Text>
                            </Tooltip>
                          </Grid.Col>
                          <Grid.Col span={2}>
                            {historyLoading === "loading" &&
                            !eventsHistoryList.hasOwnProperty(event.id) ? (
                              <Skeleton height={10} width={100} />
                            ) : (
                              <Text fw={500}>
                                {numberToCommas(eventsHistoryList[event.id]?.total_occurrence)}
                              </Text>
                            )}
                          </Grid.Col>
                          <Grid.Col span={2}>
                            {historyLoading === "loading" &&
                            !eventsHistoryList.hasOwnProperty(event.id) ? (
                              <Skeleton height={10} width={100} />
                            ) : (
                              <Text>
                                {eventsHistoryList[event.id]?.first_occurrence && (
                                  <>
                                    {ClickhouseDateToLocalRelativeTime(
                                      eventsHistoryList[event.id].first_occurrence
                                    )}
                                  </>
                                )}
                              </Text>
                            )}
                          </Grid.Col>
                          {/* <div>{event.usage.length}</div> */}
                          <Grid.Col span={2}>
                            {historyLoading === "loading" &&
                            !eventsHistoryList.hasOwnProperty(event.id) ? (
                              <Skeleton height={10} width={100} />
                            ) : (
                              <Text>
                                {eventsHistoryList[event.id]?.last_occurrence && (
                                  <>
                                    {ClickhouseDateToLocalRelativeTime(
                                      eventsHistoryList[event.id].last_occurrence
                                    )}
                                  </>
                                )}
                              </Text>
                            )}
                          </Grid.Col>
                          <Grid.Col span={2}>
                            {historyLoading === "loading" &&
                            !eventsHistoryList.hasOwnProperty(event.id) ? (
                              <Center>
                                <Skeleton height={10} width={100} />
                              </Center>
                            ) : (
                              <>
                                {eventsHistoryList.hasOwnProperty(event.id) &&
                                  eventsHistoryList[event.id]?.histogram &&
                                  eventsHistoryList[event.id]?.histogram.length > 0 && (
                                    <>
                                      <PulseLineGraph
                                        color={theme.colors.brand[5]}
                                        data={eventsHistoryList[event.id].histogram}
                                        name="Events"></PulseLineGraph>
                                    </>
                                  )}
                              </>
                            )}
                          </Grid.Col>
                          <Grid.Col span={1} ta="center">
                            <Flex justify={"center"}>
                              <Tooltip withArrow label="Detailed View" aria-label="Detailed View">
                                <ActionIcon
                                  mr={8}
                                  size="sm"
                                  fz={"xs"}
                                  color="gray.6"
                                  variant="outline"
                                  onClick={() => handleOpenEventsDetailedActivity(event)}
                                  data-cy="pinned-event-detailed-view-icon">
                                  <FontAwesomeIcon icon={regular("chart-bar")} />
                                </ActionIcon>
                              </Tooltip>
                              {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                                <>
                                  <Tooltip
                                    withArrow
                                    label="Edit pinned event"
                                    aria-label="Edit pinned event">
                                    <ActionIcon
                                      size="sm"
                                      fz={"xs"}
                                      mr={8}
                                      color="gray.6"
                                      data-tip="Edit pinned event"
                                      variant="outline"
                                      onClick={() => {
                                        editPinnedEvent(event.id);
                                      }}>
                                      <FontAwesomeIcon icon={regular("pencil")} />
                                    </ActionIcon>
                                  </Tooltip>

                                  <Tooltip
                                    withArrow
                                    label="Delete pinned event"
                                    aria-label="Delete pinned event">
                                    <ActionIcon
                                      size="sm"
                                      fz={"xs"}
                                      mr={8}
                                      data-tip="Delete pinned event"
                                      variant="outline"
                                      color={"red"}
                                      onClick={() => {
                                        handleDeletePinnedEvent(event);
                                      }}
                                      data-cy="pinned-event-delete-icon">
                                      <FontAwesomeIcon
                                        icon={regular("trash")}
                                        className=" text-red-500 transform "
                                      />
                                    </ActionIcon>
                                  </Tooltip>
                                </>
                              )}
                            </Flex>
                          </Grid.Col>
                        </Grid>
                        <Divider mb="sm" />
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {!loading && (
                      <>
                        <div className="flex items-center justify-center flex-grow w-full py-12">
                          {search.length > 0 ? (
                            <p>There are no events for your searched query.</p>
                          ) : (
                            <Flex direction={"column"} justify={"center"}>
                              <p>
                                There are no pinned events available. Create your first Pinned event
                                by clicking the button below.
                              </p>
                              <Center>
                                <Group my={16}>
                                  <Button
                                    // variant="gradient"
                                    onClick={() => {
                                      resetPinnedEvent();
                                      resetAutoCapturedEvents();
                                      setPinnedEventModal(true);
                                    }}>
                                    Create Pinned Event
                                  </Button>
                                  <Anchor
                                    href="https://usermaven.com/docs/getting-started/creating-pinned-events"
                                    target="_blank">
                                    <Button
                                      variant="subtle"
                                      leftSection={
                                        <>
                                          <FontAwesomeIcon
                                            icon={regular("arrow-up-right-from-square")}
                                          />
                                        </>
                                      }>
                                      Learn more about pinned events
                                    </Button>
                                  </Anchor>
                                </Group>
                              </Center>
                            </Flex>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
              {loading && (
                <>
                  <Center py="md">
                    <Loader size={"sm"} />
                  </Center>
                </>
              )}

              {list.length > 0 && hasMore && (
                <div className="flex justify-center flex-grow my-2">
                  <Button
                    color="gray.7"
                    disabled={loading}
                    loading={loading}
                    onClick={() => {
                      fetch(activeWorkspace.id, page + 1);
                    }}>
                    Load more
                  </Button>
                </div>
              )}
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};
