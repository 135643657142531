import axios from "./JWTService";

export class MavenAIService {
  private workspace_id: string;

  constructor(workspace_id: string) {
    this.workspace_id = workspace_id;
  }

  /**
   * Fetch all the available chats
   */
  chats = (flag: 'mine' | 'all'): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/maven_ai/${this.workspace_id}/${flag}/chats`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Fetch all the messages for a chat
   */
  messages = (chat_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/maven_ai/${this.workspace_id}/chats/${chat_id}/messages`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Send a message to a chat
   */
  ask = (message: string, chat_id?: string, mode: 'fast' | 'smart' = 'fast'): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/maven_ai/${this.workspace_id}/ask`, {
          user_question: message,
          chat_id: chat_id,
          mode: mode
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Delete a chat
   */
  deleteChat = (chat_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/v1/maven_ai/${this.workspace_id}/chats/${chat_id}/delete`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Rename a chat
   */
  renameChat = (chat_id: string, title: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/maven_ai/${this.workspace_id}/chats/${chat_id}/rename`, {
          title: title
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Feedback for a message
   */
  feedback = (chat_id: string, message_ids: string[], negative: boolean): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/maven_ai/${this.workspace_id}/chats/${chat_id}/feedback/`, {
          negative: negative,
          message_ids: message_ids
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

}