/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  Flex,
  Grid,
  List,
  Text,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { PieChart } from "echarts/charts";
import { LegendComponent, TooltipComponent } from "echarts/components";
import * as echarts from "echarts/core";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { BulletListSkeleton } from "@/lib/utils/ChartsSkeletons";
import { capitalizeFirstLetter, numberToUnit, truncateText } from "@/lib/utils/StringUtility";
import { useContext, useEffect, useState } from "react";
import { LoadingStateProps, LooseObject } from "types/types.d";
import { NoResults } from "../NoResults/NoResults";
import classes from "./WebAnalyticsOverview.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
const channelColors = [
  "#A173F3",
  "#FD60B7",
  "#2fa6d9",
  "#fec107",
  "#e64980",
  "#D4B6FD",
  "#478978",
  "#FD87AA",
  "#FAFF7F",
  "#4D7298"
];
const sourceColors = [
  "#fc8452",
  "#73C0DE",
  "#9FE080",
  "#D68FD6",
  "#05B3A7",
  "#E6E49F",
  "#478978",
  "#FD87AA",
  "#FAFF7F",
  "#4D7298",
  "#fec107",
  "#e64980"
];

echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout]);
export const TopSourcesOverview = ({ query, onHandleSourceClick, onHandleChannelClick }: any) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const options = {
    tooltip: {
      confine: true,
      extraCssText: "width:auto; white-space:pre-wrap;",
      trigger: "item",
      backgroundColor: colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
      }
    },
    series: [
      {
        color: channelColors,
        name: "Channels",
        type: "pie",
        radius: ["20%", "50%"],
        label: {
          show: false
        },
        data: [{ value: 0, name: "" }]
      },
      {
        name: "Sources",
        type: "pie",
        color: sourceColors,
        radius: ["60%", "90%"],
        label: {
          show: false
        },
        data: [{ value: 0, name: "" }]
      }
    ]
  };

  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [aggregatedStats, setAggregatedStats] = useState({
    top_channel_stats: [],
    top_source_stats: []
  });
  const fetchAggregatedSourcesStats = async () => {
    setLoading("loading");
    await new WebAnalyticsService()
      .aggregatedTopChannelsSourcesCount(activeWorkspace.id, query)
      .then((res) => {
        if (res.data) {
          setAggregatedStats(res.data);
        }
      })
      .catch((err) => {
        setAggregatedStats({ top_channel_stats: [], top_source_stats: [] });
      });
    setLoading("loaded");
  };

  const getTopSourcesPieValues = () => {
    const channelData = aggregatedStats.top_channel_stats?.map((item: any, index: number) => {
      return {
        value: item.visitors,
        name: capitalizeFirstLetter(item.channel),
        itemStyle: {
          color: channelColors[index]
        }
      };
    });
    options.series[0].data = channelData;
    const sourceData = aggregatedStats.top_source_stats?.map((item: any, index: number) => {
      return {
        value: item.visitors,
        itemStyle: {
          color: sourceColors[index]
        },

        // to differentiate between direct channel above and direct source
        // rename 'direct' source to 'direct traffic'
        name: item.source === "Direct" ? "Direct Traffic" : capitalizeFirstLetter(item.source)
      };
    });
    options.series[1].data = sourceData;
    return options;
  };

  /**
   * Set the active tab to the selected channel
   * @param channel
   */

  /**
   * Event handler for the pie chart
   */
  const _onEvents = {
    click: (params: any) => {
      if (params.seriesName === "Channels") {
        onHandleChannelClick(params.name as string);
      }
      if (params.seriesName === "Sources") {
        onHandleSourceClick(params.name);
      }
    }
  };

  useEffect(() => {
    fetchAggregatedSourcesStats();
  }, [query, activeWorkspace.id]);

  const renderPieChart = () => {
    return (
      <>
        <Grid>
          <Grid.Col span={{ base: 12, sm: 6 }} p="lg">
            <Flex direction={"column"} flex={1} mt={32}>
              <ReactEChartsCore
                style={{
                  height: isMobile ? "250px" : "300px",
                  width: isMobile ? "250px" : "300px"
                }}
                onEvents={_onEvents}
                echarts={echarts}
                option={getTopSourcesPieValues()}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col
            span={{
              base: 12,
              sm: 6
            }}>
            {aggregatedStats.top_channel_stats?.length + aggregatedStats.top_source_stats?.length >
              0 && (
              <Flex direction={"column"} align={"center"} justify={"center"} mt={32}>
                <List w={"80%"}>
                  {aggregatedStats.top_channel_stats?.length > 0 && (
                    <>
                      <Title fz="sm" order={3}>
                        Top Channels
                      </Title>
                      <Divider my={4} variant="dotted" orientation="horizontal" />
                    </>
                  )}
                  {aggregatedStats.top_channel_stats?.map((item: LooseObject, index: number) => (
                    <li
                      onClick={() => {
                        onHandleChannelClick(item.channel);
                      }}
                      className={classes.topSourceOverview}
                      key={`topSourceAggregatedItem:${index}:${item.channel}`}>
                      <Flex align={"center"} w={"100%"} pb={2}>
                        <Flex align={"center"} flex="1">
                          <Box
                            h={8}
                            mr={8}
                            w={8}
                            style={{
                              borderRadius: "50%",
                              backgroundColor: channelColors[index]
                            }}></Box>

                          <Text size={"sm"} fz={13} tt="capitalize">
                            {item.channel ? <>{item.channel}</> : <>Direct</>}{" "}
                          </Text>
                        </Flex>
                        <Flex>
                          <Text size={"xs"} fw={700} pr={4}>
                            {item?.conversion_rate}%
                          </Text>
                          <Text size={"xs"}>({numberToUnit(item.visitors)})</Text>
                        </Flex>
                      </Flex>
                    </li>
                  ))}
                  {aggregatedStats.top_source_stats?.length > 0 && (
                    <>
                      <Title order={3} mt="md" fz="sm">
                        Top Sources
                      </Title>
                      <Divider my={4} variant="dotted" orientation="horizontal" />
                    </>
                  )}
                  {aggregatedStats.top_source_stats
                    // ?.splice(0, 10)
                    .map((item: LooseObject, index: number) => (
                      <li
                        onClick={() => {
                          onHandleSourceClick(item.source);
                        }}
                        className={classes.topSourceOverview}
                        key={`topSourceAggregatedItem:${index}:${item.source}`}>
                        <Flex align={"center"} w={"100%"}>
                          <Flex align={"center"} flex={1}>
                            <Box
                              h={8}
                              mr={8}
                              w={8}
                              style={{
                                borderRadius: "50%",
                                backgroundColor: sourceColors[index]
                              }}></Box>
                            <Tooltip
                              withinPortal
                              zIndex={999}
                              label={item.source}
                              disabled={item.source.length < 20}>
                              <Text tt="capitalize" fz={13} pr={4}>
                                {item.source ? <>{truncateText(item.source, 20)}</> : <>Direct</>}{" "}
                              </Text>
                            </Tooltip>
                          </Flex>
                          <Flex>
                            <Text size={"xs"} fw={700} pr={4}>
                              {item?.conversion_rate}%
                            </Text>
                            <Text size={"xs"}>({numberToUnit(item.visitors)})</Text>
                          </Flex>
                        </Flex>
                      </li>
                    ))}
                </List>
              </Flex>
            )}
          </Grid.Col>
        </Grid>
      </>
    );
  };

  return (
    <div className="relative">
      {loading === "loaded" ? (
        <>
          {/* {renderPreferenceChoice()} */}
          {aggregatedStats.top_channel_stats?.length + aggregatedStats.top_source_stats?.length >
          0 ? (
            <>
              <>{renderPieChart()}</>
            </>
          ) : (
            <>
              <Flex align={"center"} justify={"center"} h={400}>
                <NoResults text={"There is no data for sources."} heading={"No Results"} />
              </Flex>
            </>
          )}
        </>
      ) : (
        <>
          <div className="ml-4">
            <BulletListSkeleton />
          </div>
        </>
      )}
    </div>
  );
};
