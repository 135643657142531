/* eslint-disable react-hooks/exhaustive-deps */
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import { FeatureAdoptionTableExpandable } from "@/ui/components/App/FeatureAdoptionTable/FeatureAdoptionTableExpandable";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Flex,
  Grid,
  Paper,
  Select,
  Text,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { FeatureAdoptionReportGraph } from "../../Components/Graphs/FeatureAdoptionReportGraph";
import { ExtendedAdoptionModule } from "../InsightsDashboardFeatureAdoption";

interface IFeatureAdoptionOverviewProps {
  adoptionReportData: ExtendedAdoptionModule[];
  setAdoptionReportData: (e: any) => void;
  isFeaturesReportLoading: string;
  reportType: string;
}

const FeatureAdoptionOverview: React.FC<IFeatureAdoptionOverviewProps> = ({
  adoptionReportData,
  setAdoptionReportData,
  isFeaturesReportLoading,
  reportType
}) => {
  const { colorScheme } = useMantineColorScheme();
  const combinedFeaturesList = adoptionReportData.flatMap((module) => module.features || []);
  const [cohortInsightType, setCohortInsightType] = useState<string | null>("adoption_rate");
  const isLaptopScreen = useMediaQuery("(max-width: 1540px)");

  useEffect(() => {
    document.title = "Feature Adoption Overview | Usermaven";
    getFeatureWithHighestAvgUsage();
  }, [adoptionReportData]);

  const getFeatureWithHighestAvgUsage = () => {
    return combinedFeaturesList.reduce((highest, current) => {
      return current.frequency > highest.frequency ? current : highest;
    }, combinedFeaturesList[0]);
  };

  const getTopThreeFeatures = () => {
    const sortedFeatures = combinedFeaturesList.sort((a, b) => b.frequency - a.frequency);
    const top3Features = sortedFeatures.slice(0, 3);
    const featureNames = top3Features.map(
      ({ feature_name }, index) => `${toTitleCase(feature_name)}`
    );
    const result = featureNames.join(", ");
    return result;
  };

  const getFeatureWithHighestAdoptionIncrease = () => {
    return combinedFeaturesList.reduce(
      (result, feature) => {
        if (feature && feature.previous_adoption) {
          const adoptionIncreasePercentage =
            ((feature.adoption - feature.previous_adoption) / feature.previous_adoption) * 100;

          if (adoptionIncreasePercentage > result.percentage) {
            result.feature_name = toTitleCase(feature.feature_name);
            result.percentage = Number(adoptionIncreasePercentage.toFixed(2));
          }
        }
        return result;
      },
      { feature_name: "X", percentage: 0.0 }
    );
  };

  const getFeatureWithHighestRetentionDecrease = () => {
    return combinedFeaturesList.reduce(
      (result, feature) => {
        if (feature && feature.previous_retention) {
          const retentionDecreasePercentage =
            ((feature.previous_retention - feature.retention) / feature.previous_retention) * 100;

          if (retentionDecreasePercentage > result.percentage) {
            result.feature_name = toTitleCase(feature.feature_name);
            result.percentage = Number(retentionDecreasePercentage.toFixed(2));
          }
        }
        return result;
      },
      { feature_name: "X", percentage: 0.0 }
    );
  };

  return (
    <>
      <InsightSection
        title="Features audit matrix"
        description="In this graph, you can see how users engage with the product features at different
                frequencies and how these patterns correlate with adoption and retention rates.
                Bigger bubbles mean more people are using the feature."
        rightSection={
          <Select
            ml={"xl"}
            label="Select X-Axis"
            placeholder="Select X-Axis"
            value={cohortInsightType}
            onChange={setCohortInsightType}
            data={[
              // {
              //   label: "Usage rate",
              //   value: "usage_rate"
              // },
              {
                label: "Adoption rate",
                value: "adoption_rate"
              },

              {
                label: "Retention rate",
                value: "retention_rate"
              }
            ]}
          />
        }>
        {isFeaturesReportLoading === "loaded" ? (
          adoptionReportData.length > 0 ? (
            <Box>
              <Grid>
                <Grid.Col span={isLaptopScreen ? 12 : 9}>
                  <FeatureAdoptionReportGraph
                    reportType={reportType}
                    adoptionReportData={adoptionReportData}
                    cohortInsightType={cohortInsightType}
                    isFeatureUsageGraph={false}
                  />
                </Grid.Col>

                <Grid.Col span={isLaptopScreen ? 12 : 3}>
                  <Box
                    style={(theme) => ({
                      backgroundColor:
                        colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[1],
                      padding: theme.spacing.lg,
                      borderRadius: "4px"
                    })}>
                    <Flex align={"center"} justify={"center"} mb="md">
                      <Box w={16} c="yellow" mr="lg">
                        <FontAwesomeIcon icon={solid("lightbulb-on")} />
                      </Box>
                      <Title order={4}>Insights</Title>
                    </Flex>

                    <Grid justify="center">
                      <Grid.Col span={isLaptopScreen ? 6 : 12}>
                        <Box pos="relative">
                          <Paper withBorder radius="md" p="lg">
                            <Text>
                              The most used feature is: "
                              <Text span fw={600}>
                                {toTitleCase(getFeatureWithHighestAvgUsage().feature_name)}
                              </Text>
                              ", and it is used{" "}
                              <Text span fw={600}>
                                {getFeatureWithHighestAvgUsage().frequency_category}
                              </Text>
                              .
                            </Text>
                          </Paper>
                        </Box>
                      </Grid.Col>

                      {getTopThreeFeatures() && (
                        <Grid.Col span={isLaptopScreen ? 6 : 12}>
                          <Box pos={"relative"} h={"100%"}>
                            <Paper
                              withBorder
                              radius="md"
                              p="lg"
                              style={{
                                userSelect: "none"
                              }}>
                              <Text>
                                Your top 3 frequently used features are:{" "}
                                <Text span fw={600}>
                                  {getTopThreeFeatures()}
                                </Text>
                              </Text>
                            </Paper>
                          </Box>
                        </Grid.Col>
                      )}
                      {getFeatureWithHighestAdoptionIncrease().feature_name !== "X" && (
                        <Grid.Col span={isLaptopScreen ? 6 : 12}>
                          <Box pos="relative">
                            <Paper
                              withBorder
                              radius="md"
                              p="lg"
                              style={{
                                userSelect: "none"
                              }}>
                              <Text>
                                Adoption of feature{" "}
                                <Text span fw={600}>
                                  {getFeatureWithHighestAdoptionIncrease().feature_name}
                                </Text>{" "}
                                has increased the most{" "}
                                <Text span fw={600} c="green.7">
                                  {getFeatureWithHighestAdoptionIncrease().percentage}%
                                </Text>{" "}
                                compared to the previous period.
                              </Text>
                            </Paper>
                          </Box>
                        </Grid.Col>
                      )}
                      {getFeatureWithHighestRetentionDecrease().feature_name !== "X" && (
                        <Grid.Col span={isLaptopScreen ? 6 : 12}>
                          <Box pos="relative">
                            <Paper
                              withBorder
                              radius="md"
                              p="lg"
                              style={{
                                userSelect: "none"
                              }}>
                              <Text>
                                Retention of feature{" "}
                                <Text span fw={600}>
                                  {getFeatureWithHighestRetentionDecrease().feature_name}
                                </Text>{" "}
                                has decreased the most{" "}
                                <Text span fw={600} c="green.7">
                                  {getFeatureWithHighestRetentionDecrease().percentage}%
                                </Text>{" "}
                                compared to the previous period.
                              </Text>
                            </Paper>
                          </Box>
                        </Grid.Col>
                      )}
                    </Grid>
                  </Box>
                </Grid.Col>
              </Grid>
            </Box>
          ) : (
            <Flex justify={"center"} align={"center"}>
              <NoResults heading={"No results"} text={"There is no activity"} />
            </Flex>
          )
        ) : (
          <BarChartSkeleton />
        )}
      </InsightSection>

      <FeatureAdoptionTableExpandable
        isLoading={isFeaturesReportLoading}
        data={adoptionReportData}
        setAdoptionReportData={setAdoptionReportData}
        reportType={reportType}
      />
    </>
  );
};

export default FeatureAdoptionOverview;
