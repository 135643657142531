import { createContext } from 'react';
import { LooseObject } from 'types/types.d';

type AppLifecycleContextType = {
  user: any;
  setUser: (value: object) => void;
  activeWorkspace: any;
  setActiveWorkspace: (value: object) => void;
  workspaces: any;
  setWorkspaces: (value: object) => void;
  allMembers: any;
  setAllMembers: (value: any) => void;
  isCollapsedLeftMenu: boolean;
  setIsCollapsedLeftMenu: (value: any) => void;
};

export const AppLifecycleContext = createContext<AppLifecycleContextType | undefined | any>(
  undefined
);
export default AppLifecycleContext;
