// @ts-ignore
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import { regular, light } from "@fortawesome/fontawesome-svg-core/import.macro";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { MOBILE_BREAKPOINT_QUERY, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { useContext, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import Icon from "@/ui/components/Common/Icon/Icon";
import "./settings_navigation.scss";
import { IntegrationService } from "./WorkspaceSettings/Integrations/WorkspaceIntegrationsListing";
import { Box, Paper, Stack, Title, Divider, Text, Flex, NavLink } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import AppLifecycleContext from "../../../lib/contexts/AppLifecycleContext";

import WorkspaceSettingsGeneral from "@/ui/pages/Settings/WorkspaceSettings/General/General";
import WorkspaceTeamMembers from "@/ui/pages/Settings/WorkspaceSettings/TeamMembers";
import WorkspaceTrackingSetup from "@/ui/pages/Settings/WorkspaceSettings/WorkspaceTrackingSetup";
import WorkspaceCustomDomain from "@/ui/pages/Settings/WorkspaceSettings/CustomDomain";

import WorkspaceMiscellaneous from "@/ui/pages/Settings/WorkspaceSettings/Miscellaneous/Miscellaneous";
import WorkspaceIntegrations from "@/ui/pages/Settings/WorkspaceSettings/Integrations/Integrations";
import CustomizeMenu from "@/ui/pages/Settings/WorkspaceSettings/CustomizeMenu/CustomizeMenu";
import ChannelMapping from "./WorkspaceSettings/ChannelMapping/ChannelMapping";
import { Header } from "@/ui/components/Common/Header/Header";

type SlugParams = {
  workspaceIdentifier: string;
};

const WorkspaceSettingsNavigation = (props: any) => {
  const { workspaceIdentifier } = useParams<SlugParams>();
  const { hasRolesWith } = useWorkspaceUtilityHook();
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);
  const { isCollapsedLeftMenu } = useContext(AppLifecycleContext);
  useEffect(() => {
    console.log("Settings...");
    document.title = "Settings | Usermaven";
  }, []);
  const location = useLocation();

  const navigate = useNavigate();
  const currentPageHeader = useMemo(() => {
    if (location.pathname.includes("/setup")) {
      return "Setup";
    } else if (location.pathname.includes("/general")) {
      return "General Settings";
    } else if (location.pathname.includes("/team")) {
      return "Team Members";
    } else if (location.pathname.includes("/custom_domain")) {
      return "Pixel White-labelling";
    } else if (location.pathname.includes("/customize_menu")) {
      return "Customize Menu";
    } else if (location.pathname.includes("/channel_mapping")) {
      return "Channel Mapping";
    } else if (location.pathname.includes("/integrations")) {
      return "Integrations";
    } else if (location.pathname.includes("/miscellaneous")) {
      return "Miscellaneous";
    }
    return "General Settings";
  }, [location.pathname]);
  return (
    <>
      <Box
        className="subnav-navigation"
        sx={{
          left: isMobileView
            ? "16px !important"
            : isCollapsedLeftMenu
            ? "55px !important"
            : "220px",
          transition: "all 0.3s ease"
        }}>
        <div className="container">
          <Box ml={8}>
            <Title mb="xs" order={5}>
              Settings
            </Title>
            <Text>Current workspace</Text>
          </Box>
          <Divider my="md" />
          <Stack gap={4} fw={500}>
            <NavLink
              className="navigation-link"
              leftSection={
                <Flex w={16} justify={"center"}>
                  <Icon icon="Integrations" />
                </Flex>
              }
              label="Setup"
              active={location.pathname.endsWith("/setup")}
              onClick={() => {
                navigate(`/env/${workspaceIdentifier}/settings/setup`);
              }}></NavLink>
            <NavLink
              className="navigation-link"
              leftSection={
                <Flex w={16} justify={"center"}>
                  <Icon icon="General" />
                </Flex>
              }
              label="General"
              active={location.pathname.endsWith("/general")}
              onClick={() => {
                navigate(`/env/${workspaceIdentifier}/settings/general`);
              }}></NavLink>
            {hasRolesWith([WORKSPACE_MEMBER_ROLES.OWNER, WORKSPACE_MEMBER_ROLES.ADMINISTRATOR]) && (
              <NavLink
                className="navigation-link"
                leftSection={
                  <Flex w={16} justify={"center"}>
                    <Icon icon="Your-Team" />
                  </Flex>
                }
                label="Team Members"
                active={location.pathname.endsWith("/team")}
                onClick={() => {
                  navigate(`/env/${workspaceIdentifier}/settings/team`);
                }}
              />
            )}
            {hasRolesWith([
              WORKSPACE_MEMBER_ROLES.OWNER,
              WORKSPACE_MEMBER_ROLES.MANAGER,
              WORKSPACE_MEMBER_ROLES.ADMINISTRATOR
            ]) && (
              <NavLink
                className="navigation-link"
                leftSection={
                  <Flex w={16} justify={"center"}>
                    <FontAwesomeIcon icon={light("globe")} />
                  </Flex>
                }
                label="Pixel white-labelling"
                active={location.pathname.endsWith("/custom_domain")}
                onClick={() => {
                  navigate(`/env/${workspaceIdentifier}/settings/custom_domain`);
                }}></NavLink>
            )}

            {hasRolesWith([WORKSPACE_MEMBER_ROLES.OWNER, WORKSPACE_MEMBER_ROLES.ADMINISTRATOR]) && (
              <NavLink
                className="navigation-link"
                leftSection={
                  <Flex w={16} justify={"center"}>
                    <FontAwesomeIcon icon={regular("list-check")} />
                  </Flex>
                }
                label="Customize Menu"
                active={location.pathname.endsWith("/customize_menu")}
                onClick={() => {
                  navigate(`/env/${workspaceIdentifier}/settings/customize_menu`);
                }}></NavLink>
            )}
            {hasRolesWith([WORKSPACE_MEMBER_ROLES.OWNER, WORKSPACE_MEMBER_ROLES.ADMINISTRATOR]) && (
              <NavLink
                className="navigation-link"
                leftSection={
                  <Flex w={16} justify={"center"}>
                    <FontAwesomeIcon icon={light("diagram-next")} rotation={270} />
                  </Flex>
                }
                label="Channel Mapping"
                active={location.pathname.endsWith("/channel_mapping")}
                onClick={() => {
                  navigate(`/env/${workspaceIdentifier}/settings/channel_mapping`);
                }}></NavLink>
            )}
            {hasRolesWith([
              WORKSPACE_MEMBER_ROLES.OWNER,
              WORKSPACE_MEMBER_ROLES.MANAGER,
              WORKSPACE_MEMBER_ROLES.ADMINISTRATOR
            ]) && (
              <>
                <NavLink
                  className="navigation-link"
                  leftSection={
                    <Flex w={16} justify={"center"}>
                      <Icon icon="Integrations" />
                    </Flex>
                  }
                  label="Integrations"
                  active={location.pathname.includes("/integrations")}
                  onClick={() => {
                    navigate(`/env/${workspaceIdentifier}/settings/integrations`);
                  }}></NavLink>
                <NavLink
                  className="navigation-link"
                  leftSection={
                    <Flex w={16} justify={"center"}>
                      <FontAwesomeIcon icon={regular("circle-dashed")} />
                    </Flex>
                  }
                  label="Miscellaneous"
                  active={location.pathname.endsWith("/miscellaneous")}
                  onClick={() => {
                    navigate(`/env/${workspaceIdentifier}/settings/miscellaneous`);
                  }}></NavLink>
              </>
            )}
          </Stack>
        </div>
      </Box>
      <Box className="subnav-content-container">
        <Header title={currentPageHeader} />

        <Paper mt={"xl"} p="md" shadow="md" withBorder>
          <Routes>
            <Route path={`general`} element={<WorkspaceSettingsGeneral />} />
            <Route path={`team`} element={<WorkspaceTeamMembers />} />
            <Route path={`setup`} element={<WorkspaceTrackingSetup />} />
            <Route path={`custom_domain`} element={<WorkspaceCustomDomain />} />
            <Route path={`miscellaneous/`} element={<WorkspaceMiscellaneous />} />{" "}
            <Route path={`customize_menu`} element={<CustomizeMenu />} />
            <Route path={`channel_mapping`} element={<ChannelMapping />} />
            <Route path={`integrations/`} element={<WorkspaceIntegrations />} />
            <Route path={`integrations/:service`} element={<IntegrationService />} />
            <Route
              path="*"
              element={<Navigate to={`/env/${workspaceIdentifier}/settings/general`} replace />}
            />
          </Routes>
        </Paper>
      </Box>
    </>
  );
};

export default WorkspaceSettingsNavigation;
