import axios from './JWTService';

export class WorkspaceExcludeDomainsService {
  post = (workspaceId: string, domain: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspace_exclude_domains/${workspaceId}`, {
          url: domain
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  delete = (workspaceId: string, domain: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`v1/workspace_exclude_domains/${workspaceId}`, {
          data: {
            url: domain
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
