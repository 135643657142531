import {
  CheckIcon,
  Combobox,
  Flex,
  Input,
  Pill,
  PillsInput,
  Text,
  useCombobox
} from "@mantine/core";
import { ATTRIBUTION_MODELS } from "@/lib/utils/Constants";
import { toTitleCaseLabel } from "@/lib/utils/StringUtility";

const MAX_DISPLAYED_VALUES = 1;
export const AttributionModelCombobox = ({
  value,
  setValue
}: {
  value: string[];
  setValue: any;
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active")
  });

  const handleValueSelect = (val: string) =>
    setValue((current: any) => {
      const newValue = current.includes(val)
        ? current.filter((v: any) => v !== val)
        : [...current, val];

      if (newValue.length > 0) {
        return newValue;
      }

      return current;
    });

  const handleValueRemove = (val: string) => {
    // if only one value is left, dont remove it
    if (value.length === 1) return;

    setValue((current: any) => current.filter((v: any) => v !== val));
  };

  const values = value
    .slice(
      0,
      MAX_DISPLAYED_VALUES === value.length ? MAX_DISPLAYED_VALUES : MAX_DISPLAYED_VALUES - 1
    )
    .map((item) => (
      <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
        {toTitleCaseLabel(item.replace(/_/g, " "))}
      </Pill>
    ));

  const optionsList = [
    ATTRIBUTION_MODELS.FIRST_TOUCH,
    ATTRIBUTION_MODELS.LAST_TOUCH,
    ATTRIBUTION_MODELS.LINEAR,
    ATTRIBUTION_MODELS.U_SHAPED,
    ATTRIBUTION_MODELS.TIME_DECAY,
    ATTRIBUTION_MODELS.FIRST_TOUCH_NON_DIRECT,
    ATTRIBUTION_MODELS.LAST_TOUCH_NON_DIRECT
  ];

  const options = optionsList.map((item) => (
    <Combobox.Option value={item} key={item} active={value.includes(item)}>
      <Flex justify={"space-between"} gap="xs">
        <Text fz={13}>{toTitleCaseLabel(item.replace(/_/g, " "))}</Text>
        {value.includes(item) ? <CheckIcon size={12} /> : null}
      </Flex>
    </Combobox.Option>
  ));

  return (
    <Flex direction="column">
      <Text fz={13} mb={3} pt={3}>
        Attribution Models
      </Text>
      <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false} w={200}>
        <Combobox.DropdownTarget>
          <PillsInput pointer onClick={() => combobox.toggleDropdown()}>
            <Pill.Group>
              {value.length > 0 ? (
                <>
                  {values}
                  {value.length > MAX_DISPLAYED_VALUES && (
                    <Pill>+{value.length - (MAX_DISPLAYED_VALUES - 1)} more</Pill>
                  )}
                </>
              ) : (
                <Input.Placeholder>Select attribution model</Input.Placeholder>
              )}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  type="hidden"
                  onBlur={() => combobox.closeDropdown()}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace") {
                      event.preventDefault();
                      handleValueRemove(value[value.length - 1]);
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Flex>
  );
};
