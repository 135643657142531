import { ConditionItem } from "../../../../types/types.d";
import {
  ActionIcon,
  Autocomplete,
  Badge,
  Box,
  Button,
  Flex,
  Select,
  TextInput,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { InsightCondition } from "../InsightsSetupEvents/InsightCondition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import axios from "../../../../lib/services/JWTService";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface IJourneySetupEvents {
  conditions: ConditionItem[];
  setConditions: (conditions: ConditionItem[]) => void;
  conditionsErrors: string[];
  attributesOptions: Array<any>;
  pageViewSelectOptions: Array<any>;
  onAddCondition?: () => void;
  onRemoveCondition?: (index: number) => void;
  withAddCondition?: boolean;
  addConditionLabel?: string;
}

const JourneySetupEvents = ({
  conditions,
  setConditions,
  conditionsErrors,
  attributesOptions,
  pageViewSelectOptions,
  onAddCondition = () => {},
  onRemoveCondition = () => {},
  withAddCondition = false,
  addConditionLabel = "Add condition"
}: IJourneySetupEvents) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const handleItemSelectChange = (e: any, index: number) => {
    console.log(e.target.value, index);
    const newConditions = [...conditions];
    newConditions[index].type = e.target.value;
    setConditions(newConditions);
  };

  return (
    <>
      <Box>
        {conditions.map((item, index) => (
          <Box mb="md" key={index}>
            {index > 0 && (
              <>
                <Badge
                  radius={"sm"}
                  color={colorScheme === "dark" ? "dark.5" : "gray.6"}
                  variant="filled">
                  AND
                </Badge>
              </>
            )}

            <Box mt="md">
              <Flex
                align={"center"}
                // key={`audienceFilterCondition:${item.key}:${item.value}`}
                // sx={(theme) => ({
                //   [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                //     flexWrap: "wrap",
                //     gap: 10,

                //     ".um-insights-setup-events__filters-item-drag": {
                //       display: "none"
                //     },

                //     ".um-insights-setup-events__filters-item-select": {
                //       width: "100%",
                //       marginRight: "0",
                //       marginLeft: "0",

                //       ".usrmvn-Select-root": {
                //         width: "100%"
                //       }
                //     },

                //     "usrmvn-Select-wrapper": {
                //       width: "100%"
                //     },

                //     ".um-error-field": {
                //       position: "relative"
                //     },

                //     ".um-insights-setup-events__filters-input-text": {
                //       marginLeft: "0 !important"
                //     }
                //   }
                // })}
              >
                <Select
                  mr="sm"
                  value={item.type}
                  onChange={(e) =>
                    handleItemSelectChange(
                      {
                        target: {
                          value: e
                        }
                      },
                      index
                    )
                  }
                  data={[
                    {
                      label: "Page URL",
                      value: "pageview"
                    },
                    {
                      label: "Event",
                      value: "event"
                    }
                  ]}></Select>
                <Box w={"100%"} mr={"sm"}>
                  <Autocomplete
                    placeholder={item.type === "pageview" ? "Enter page URL" : "Enter event name"}
                    limit={25}
                    maxDropdownHeight={150}
                    data={item.type === "pageview" ? pageViewSelectOptions : attributesOptions}
                    value={item.value}
                    onChange={(value) => {
                      const newConditions = [...conditions];
                      newConditions[index].value = value;
                      setConditions(newConditions);
                    }}
                    error={conditionsErrors[index] ? conditionsErrors[index] : undefined}
                  />
                </Box>
                {/* Remove item from the list */}
                {(conditions.length > 1 || withAddCondition) && (
                  <>
                    <ActionIcon
                      variant="outline"
                      color={"red"}
                      onClick={() => onRemoveCondition(index)}>
                      <FontAwesomeIcon
                        icon={regular("trash")}
                        className=" text-red-500 transform "
                      />
                    </ActionIcon>
                  </>
                )}
              </Flex>
            </Box>
          </Box>
        ))}

        {withAddCondition && (
          <Button
            className="!font-medium "
            variant="outline"
            // color={theme.colorScheme === 'dark' ? theme.colors.dark[5] : 'gray.9'}
            // variant={theme.colorScheme === 'dark' ? 'subtle' : 'light'}
            size="sm"
            leftSection={<FontAwesomeIcon icon={regular("circle-plus")} />}
            onClick={onAddCondition}>
            {addConditionLabel}
          </Button>
        )}
      </Box>
    </>
  );
};

export default JourneySetupEvents;
