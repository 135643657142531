import { usePageView, useUsermaven } from "@/hooks/useUsermaven";
import { USERMAVEN_OPTS } from "@/lib/utils/Constants";
import logoBlackSvg from "@assets/images/logos/logo-black-usermaven.svg";
import logoWhiteSvg from "@assets/images/logos/logo-white-usermaven.svg";
import { Box, Divider, Paper, Text, useMantineColorScheme } from "@mantine/core";

export const AuthorizationLayout = (props: any) => {
  const { usermaven } = useUsermaven(USERMAVEN_OPTS);
  usePageView(usermaven);
  const theme = useMantineColorScheme();

  if (props.variant === "subtle") {
    return <>{props.children}</>;
  }
  return (
    <Box
    // sx={(theme, u) => ({
    //   ".auth-header": {
    //     background: theme.colorScheme === "dark" ? theme.colors.dark[7] : "#f0f0f0",
    //     borderColor: theme.colorScheme === "dark" ? theme.colors.gray[8] : "#dce0e3"
    //   },

    //   ".auth-container": {
    //     background: theme.colorScheme === "dark" ? theme.colors.dark[8] : "#F8F9FA"
    //   },

    //   ".auth-container--box": {
    //     background: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#fff",

    //     boxShadow:
    //       theme.colorScheme === "dark"
    //         ? "0 3px 16px 0 rgba(0, 0, 0, 0.6)"
    //         : "0 3px 16px 0 rgba(220, 224, 227, 0.6)"
    //   },

    //   ".auth-footer": {
    //     background: theme.colorScheme === "dark" ? theme.colors.dark[8] : "#F8F9FA",
    //     borderColor: theme.colorScheme === "dark" ? theme.colors.gray[8] : "#dce0e3"
    //   }
    // })}
    >
      <div className="auth-wrapper">
        <Box className="auth-header" bg={theme.colorScheme === "dark" ? "dark.7" : "gray.1"}>
          <img
            className="auth-logo"
            src={theme.colorScheme === "dark" ? logoWhiteSvg : logoBlackSvg}
            alt="Usermaven"
          />
        </Box>
        <div className="auth-container">{props.children}</div>
        <Divider />
        <Box className="auth-footer" bg={theme.colorScheme === "dark" ? "dark.7" : "gray.1"}>
          <Text fz={13}>© 2024 Usermaven. All rights reserved</Text>
        </Box>
      </div>
    </Box>
  );
};
