// @ts-ignore
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { DATE_FORMAT, STARTER_PLAN_DISABLED_DATE_RANGES } from "@/lib/utils/Constants";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Divider, Flex, Menu, Paper, Text } from "@mantine/core";
import { addDays, endOfDay, format, startOfDay, subDays } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { useDetectClickOutside } from "react-detect-click-outside";
import { toast } from "react-toastify";
import { LooseObject } from "types/types.d";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import styles from "./DateRangeDropdown.module.css";

interface DateRangeDropdownProps {
  id: string;
  selectDateRange: (startDate: string, endDate: string) => any;
  ranges?: LooseObject;
  defaultLabel?: string;
  style?: React.CSSProperties;
  innerRef?: React.Ref<any>;
  className?: string;
}

export const DateRangeDropdown: React.FC<DateRangeDropdownProps> = ({
  id = "dateRangeDropdown",
  defaultLabel = "Last 30 days",
  ranges = {
    "Last 7 days": [
      format(startOfDay(subDays(new Date(), 7)), DATE_FORMAT),
      format(endOfDay(new Date()), DATE_FORMAT)
    ],
    "Last 14 days": [
      format(startOfDay(subDays(new Date(), 14)), DATE_FORMAT),
      format(endOfDay(new Date()), DATE_FORMAT)
    ],
    "Last 30 days": [
      format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
      format(endOfDay(new Date()), DATE_FORMAT)
    ],
    "Last 60 days": [
      format(startOfDay(subDays(new Date(), 60)), DATE_FORMAT),
      format(endOfDay(new Date()), DATE_FORMAT)
    ],
    "Last 90 days": [
      format(startOfDay(subDays(new Date(), 90)), DATE_FORMAT),
      format(endOfDay(new Date()), DATE_FORMAT)
    ]
  },
  selectDateRange,
  style,
  innerRef,
  className
}) => {
  const [opened, setOpened] = useState(false);
  const theme = useGlobalMantineTheme();
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [label, setLabel] = useState(defaultLabel);
  // active Workspace from AppLifecycleContext
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const { isStarterPlan } = useWorkspaceUtilityHook();

  const closeDropdown = () => {
    setIsDropdownVisible(false);
  };
  const closeCalendarDropdown = () => {
    setIsCalendarVisible(false);
  };
  const refDropdown = useDetectClickOutside({ onTriggered: closeDropdown });
  const refCalendarDropdown = useDetectClickOutside({
    onTriggered: closeCalendarDropdown
  });
  const applyChange = (range: any) => {
    console.log(range);
    setLabel(range[0]);
    selectDateRange(range[1][0], range[1][1]);
    setIsDropdownVisible(false);
  };

  const applyCustomChange = () => {
    selectDateRange(
      format(new Date(state[0].startDate), DATE_FORMAT),
      format(new Date(state[0].endDate), DATE_FORMAT)
    );
    setLabel(
      `${format(new Date(state[0].startDate), "MMM dd")} - ${format(
        new Date(state[0].endDate),
        "MMM dd"
      )}`
    );
    setIsCalendarVisible(false);
    setIsDropdownVisible(false);
  };

  useEffect(() => {
    setLabel(defaultLabel);
  }, [defaultLabel]);

  console.log(isCalendarVisible);

  return (
    <Box
      pos="relative"
      style={{
        zIndex: 200
      }}>
      {" "}
      <Menu shadow="md" width={200} onChange={setOpened}>
        <Menu.Target>
          <Button
            size="xs"
            variant="outline"
            color="gray.6"
            rightSection={
              <FontAwesomeIcon
                icon={solid("angle-up")}
                className={`transition-all ease-in-out transform rotate-180 ${
                  opened ? "rotate-0" : ""
                }`}
              />
            }
            leftSection={<FontAwesomeIcon icon={regular("list-dropdown")} />}>
            {label}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {Object.entries(ranges).map((range) =>
            STARTER_PLAN_DISABLED_DATE_RANGES.includes(range[0].toLowerCase()) &&
            activeWorkspace &&
            isStarterPlan ? (
              <Menu.Item
                className={`${styles.menuItem} ${styles.disabledMenuItem}`}
                onClick={() => toast.error("Please upgrade your plan to unlock these date ranges")}
                key={range[0]}>
                <Flex justify={"space-between"}>
                  <Text className={styles.menuItemLabel}>{range[0]}</Text>
                  <Text>
                    <FontAwesomeIcon size="sm" key={"fa" + range[0]} icon={regular("lock")} />
                  </Text>
                </Flex>
              </Menu.Item>
            ) : (
              <Menu.Item
                className={styles.menuItem}
                onClick={(e) => {
                  e.stopPropagation();
                  applyChange(range);
                }}
                key={range[0]}>
                <Flex justify={"space-between"}>
                  <Text className={styles.menuItemLabel}>{range[0]}</Text>
                  {label === range[0] && (
                    <Text className={styles.activeIcon}>
                      <FontAwesomeIcon icon={regular("check")} />
                    </Text>
                  )}
                </Flex>
              </Menu.Item>
            )
          )}

          {isStarterPlan ? (
            <Menu.Item
              className={`${styles.menuItem} ${styles.disabledMenuItem}`}
              onClick={() => toast.error("Please upgrade your plan to unlock these date ranges")}
              key={"custom"}>
              <Flex justify={"space-between"}>
                <Text className={styles.menuItemLabel}>Custom</Text>
                <Text>
                  <FontAwesomeIcon icon={regular("lock")} />
                </Text>
              </Flex>
            </Menu.Item>
          ) : (
            <Menu.Item
              className={styles.menuItem}
              key={"custom"}
              onClick={() => {
                console.debug("custom calendar time.");
                setIsCalendarVisible(true);
              }}>
              Custom
            </Menu.Item>
          )}
        </Menu.Dropdown>
        {isCalendarVisible && (
          <Paper
            shadow="md"
            withBorder
            pos="absolute"
            right={0}
            sx={() => ({
              // borderColor:
              //   colorScheme === "dark"
              //     ? theme.colors.darkBorderColor[0]
              //     : theme.colors.lightBorderColor[0],

              ".rdrCalendarWrapper": {
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : ""
              },

              ".rdrDateDisplayWrapper": {
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : ""
              },

              ".rdrDateDisplayItem": {
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : ""
              },

              ".rdrNextPrevButton": {
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : ""
              },

              ".rdrDayNumber span": {
                color: theme.colorScheme === "dark" ? theme.colors.dark[1] : ""
              },

              ".rdrDayDisabled": {
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : ""
              }
            })}>
            <DateRange
              className="um-react-date-range"
              editableDateInputs={false}
              onChange={(item: any) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
              maxDate={addDays(new Date(), 0)}
              rangeColors={["#7D47EB"]}
            />
            <Divider />
            <Flex py={"sm"} justify={"end"}>
              <Button
                color="red"
                size="xs"
                onClick={() => {
                  setIsCalendarVisible(false);
                }}>
                Cancel
              </Button>
              <Button
                size="xs"
                ml={6}
                mr={6}
                onClick={() => {
                  applyCustomChange();
                }}>
                Apply
              </Button>
            </Flex>
          </Paper>
        )}
      </Menu>
    </Box>
  );
};
