import { Box, Center, Flex, SegmentedControl, Select, useMantineTheme } from "@mantine/core";
import { usePrevious } from "@mantine/hooks";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import useInsightsListHook from "@/hooks/useInsightsListHook";
import useSlippingAwayUsersHook from "@/hooks/useSlippingAwayUsersHook";
import useUserRetentionCohortHook from "@/hooks/useUserRetentionCohortHook";
import useUserRetentionHistogramHook from "@/hooks/useUserRetentionHistogramHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import {
  getCohortDateDefaultLabelByFrequency,
  getCohortDateRangesByFrequency,
  getCohortDefaultDateRangeByFrequency
} from "@/lib/utils/CommonDropdownRangesUtility";
import { DATE_FORMAT } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSegmentDropdownStore } from "@/stores/useSegmentDropdownStore";
import { useSegmentFilterStore } from "@/stores/useSegmentFilterStore";
import { useSegmentListStore } from "@/stores/useSegmentListStore";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { CohortsByRows } from "@/ui/components/App/Cohorts/CohortsByRows";
import { SegmentDropdownMenu } from "@/ui/components/App/Dropdowns/SegmentDropdownMenu";
import { EmptyGraphResults } from "@/ui/components/App/EmptyGraphResults/EmptyGraphResults";
import { RetentionMessage } from "@/ui/components/App/InsightsDashboardMessages/RetentionMessage";
import { ReportTypeToggle } from "@/ui/components/App/ReportTypeToggle/ReportTypeToggle";
import { ISegmentsQueryBuilderForm } from "@/ui/components/App/Segments/SegmentFormContext";
import { SlippingAwayUsersListModal } from "@/ui/components/App/SlippingAwayUsersListModal/SlippingAwayUsersListModal";
import {
  TrendsGraphBody,
  TrendsGraphContainer,
  TrendsGraphHeader,
  TrendsGraphHeaderH3,
  TrendsGraphHeaderLeft,
  TrendsGraphHeaderParagraph,
  TrendsGraphHeaderRight
} from "@/ui/components/App/TrendsGraph/TrendsGraph.style";
import { DateRangeDropdown } from "@/ui/components/Common/DateRangeDropdown/DateRangeDropdown";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import { SlippingAwayUsersHistogram } from "@/ui/pages/Insights/Trends/SlippingAwayUsers/Components/SlippingAwayUsersHistogram";
import { SlippingAwayUsersTable } from "@/ui/pages/Insights/Trends/SlippingAwayUsers/Components/SlippingAwayUsersTable";
import { StringParam, useQueryParam } from "use-query-params";
import { IReportType, InsightTemplateType } from "../../../../types/types.d";
import { EditInsight } from "../Components/EditInsight/EditInsight";
import { InsightsDashboardSetupReport } from "../Components/InsightsDashboardSetupReport/InsightsDashboardSetupReport";
import {
  InsightsDashboardHeader,
  InsightsDashboardHeaderLeft,
  InsightsDashboardHeaderRight
} from "../Components/InsightsDashboardStyle/InsightsDashboardStyle.style";
import UserRetentionSetupModal from "../../Insights/Setup/UserRetentionSetupModal";
import SlippingAwayUsersSetupModal from "../../Insights/Setup/SlippingAwayUsersSetupModal";
import { IOutletContext } from "../InsightsDashboard";
import { groupInsights } from "@/lib/utils/InsightsUtility";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  MarkLineComponent
]);
const InsightsDashboardRetention = () => {
  const [insightId] = useQueryParam("insightId", StringParam);
  const previousInsightId = usePrevious(insightId);
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [slippingAwayUsersPeriod, setSlippingAwayUsersPeriod] = useState("");
  // states to manage the setup insight modal
  const [showInsightSetupModal, setShowInsightSetupModal] = useState(false);
  const { showManageInsightsModal }: IOutletContext = useOutletContext();
  const [reportType, setReportType] = useState<IReportType>(
    activeWorkspace.reporting_insights_preference
  );
  const [retentionFrequency, setRetentionFrequency] = useState<"daily" | "weekly" | "monthly">(
    "daily"
  );
  /**
   * Segments dropdown store
   */
  const [setSelectedSegment] = useSegmentDropdownStore((state) => [state.setId]);

  /**
   * Segment filter store
   */
  const [filterVisible, setFilterVisible] = useSegmentFilterStore((state) => [
    state.filterVisible,
    state.setFilterVisible
  ]);
  /**
   * Segments Store
   */
  const [segments] = useSegmentListStore((state) => [state.segments]);

  /**
   * Segment Query builder store
   */
  const [filters, setFilters, resetExceptFilters, reset, setSegmentModalOpen] = useSegmentStore(
    (state) => [
      state.filters,
      state.setFilters,
      state.resetExceptFilters,
      state.reset,
      state.setModalOpen
    ]
  );
  const navigate = useNavigate();
  const {
    insightsList,
    isInsightsLoading,
    getDefaultInsight,
    getInsightById,
    getInsightReportType,
    fetchInsightsList
  } = useInsightsListHook("retention");

  const {
    isRetentionCohortLoading,
    cohortData,
    cohortValuesType,
    fetchUserRetentionCohort,
    getCohortColumns,
    setCohortValuesType
  } = useUserRetentionCohortHook();

  const { isCountLoading, fetchSlippingAwayUsersCount, slippingAwayUsersCount, totalUsers } =
    useSlippingAwayUsersHook();

  // store startDate and endDate in state and then use these dates for the query when audience filter applied.

  const [dropdownFilterDate, setDropdownFilterDate] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT)
  });

  const getRetentionFrequency = () => {
    return getInsightById(insightId || getDefaultInsight()?.value)?.conditions.retention_frequency;
  };

  const {
    isUserRetentionHistogramLoading,
    fetchUserRetentionHistogram,
    userRetentionHistogram,
    userRetentionMessage
  } = useUserRetentionHistogramHook();

  const onChangeDate = (startDate: any, endDate: any) => {
    if (!retentionFrequency) {
      return;
    }
    fetchUserRetentionCohort(
      insightId || getDefaultInsight()?.value,
      retentionFrequency,
      reportType,
      filters.rules,
      filters.combinator,
      filters.groups,
      startDate,
      endDate
    );
    fetchUserRetentionHistogram(
      insightId || getDefaultInsight()?.value,
      retentionFrequency,
      reportType,
      filters.rules,
      filters.combinator,
      filters.groups,
      startDate,
      endDate
    );
    setDropdownFilterDate({ startDate: startDate, endDate: endDate });
  };
  const handleSlippingAwayUsersModal = (days: string) => {
    setSlippingAwayUsersPeriod(days);
    toggle();
  };

  const toggle = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleClearFiltersChange = () => {
    const insightReportType = getInsightReportType(insightId || getDefaultInsight()?.value);
    if (insightReportType === "user_retention") {
      if (!retentionFrequency) {
        return;
      }
      fetchUserRetentionCohort(
        insightId || getDefaultInsight()?.value,
        retentionFrequency,
        reportType,
        [],
        "or",
        [],
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate
      );
      fetchUserRetentionHistogram(
        insightId || getDefaultInsight()?.value,
        retentionFrequency,
        reportType,
        [],
        "or",
        [],
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate
      );
    }
    if (insightReportType === "slipping_away_users") {
      fetchSlippingAwayUsersCount(
        insightId || getDefaultInsight()?.value,
        reportType,
        [],
        "or",
        []
      );
    }
  };

  const onApplyChange = (filters: ISegmentsQueryBuilderForm) => {
    const insightReportType = getInsightReportType(insightId || getDefaultInsight()?.value);
    if (insightReportType === "user_retention") {
      if (!retentionFrequency) {
        return;
      }
      fetchUserRetentionCohort(
        insightId || getDefaultInsight()?.value,
        retentionFrequency,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups,
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate
      );
      fetchUserRetentionHistogram(
        insightId || getDefaultInsight()?.value,
        retentionFrequency,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups,
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate
      );
    }
    if (insightReportType === "slipping_away_users") {
      fetchSlippingAwayUsersCount(
        insightId || getDefaultInsight()?.value,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups
      );
    }
    // for slipping away users.
  };

  useEffect(() => {
    document.title = "Insights | User Retention | Usermaven";
    if (isInsightsLoading === "loaded") {
      const insightReportType = getInsightReportType(insightId || getDefaultInsight()?.value);

      // user retention
      if (insightReportType === "user_retention") {
        if (!retentionFrequency || insightId != previousInsightId) {
          setRetentionFrequency(getRetentionFrequency());
          return;
        }
        console.log("Sending service call with frequency", retentionFrequency);
        fetchUserRetentionCohort(
          insightId || getDefaultInsight()?.value,
          retentionFrequency,
          reportType,
          filters.rules,
          filters.combinator,
          filters.groups
        );
        fetchUserRetentionHistogram(
          insightId || getDefaultInsight()?.value,
          retentionFrequency,
          reportType,
          filters.rules,
          filters.combinator,
          filters.groups
        );

        const defaultDateRange = getCohortDefaultDateRangeByFrequency(retentionFrequency);
        // for the audience filter, we need to store date somewhere.
        setDropdownFilterDate({
          startDate: defaultDateRange.startDate,
          endDate: defaultDateRange.endDate
        });
      }

      // slipping away users

      if (insightReportType === "slipping_away_users") {
        fetchSlippingAwayUsersCount(
          insightId || getDefaultInsight()?.value,
          reportType,
          filters.rules,
          filters.combinator,
          filters.groups
        );
      }

      console.log("Component loaded... now get the data we need.");
    }
    return () => {
      reset();
    };
  }, [isInsightsLoading, reportType, retentionFrequency]);

  // adding this useffect to reload the list when the manage insights modal is closed
  // or when a new insight is created in the setup modal and insight id changes in the url
  useEffect(() => {
    if (!showInsightSetupModal && !showManageInsightsModal) {
      fetchInsightsList();
    }
  }, [showInsightSetupModal, showManageInsightsModal, insightId]);

  if (isInsightsLoading !== "loaded") {
    return <LoadingComponent />;
  }

  return (
    <>
      {getInsightReportType(insightId || getDefaultInsight()?.value) === "slipping_away_users" ? (
        <SlippingAwayUsersSetupModal
          isModalOpen={showInsightSetupModal}
          setIsModalOpen={setShowInsightSetupModal}
          insightId={insightId || getDefaultInsight()?.value}
        />
      ) : (
        // default to user retention
        <UserRetentionSetupModal
          isModalOpen={showInsightSetupModal}
          setIsModalOpen={setShowInsightSetupModal}
          insightId={insightId || getDefaultInsight()?.value}
        />
      )}
      {insightsList.length === 0 ? (
        <>
          <InsightsDashboardSetupReport
            title={"Create your first retention insight"}
            description={
              "Find slipping away customers to reduce churn and track retention using cohort analysis."
            }
            buttonText={"Setup Insight"}
            setShowInsightSetupModal={setShowInsightSetupModal}
          />
        </>
      ) : (
        <>
          <Flex mb={"sm"} align={"center"} justify={"space-between"}>
            <Flex align={"center"}>
              <Select
                mr={4}
                variant="filled"
                size="sm"
                defaultValue={getDefaultInsight()?.value}
                // searchable
                w={200}
                onChange={(item: any) => {
                  navigate({ search: `insightId=${item}` });
                }}
                data={groupInsights(insightsList)}></Select>

              <EditInsight setShowInsightSetupModal={setShowInsightSetupModal} />
            </Flex>

            <Flex align={"center"}>
              {getInsightReportType(insightId || getDefaultInsight()?.value) ===
                "user_retention" && (
                <SegmentedControl
                  mr="sm"
                  value={retentionFrequency}
                  size="xs"
                  onChange={(value: any) => {
                    setRetentionFrequency(value);
                  }}
                  data={[
                    {
                      value: "daily",
                      label: (
                        <Center>
                          <Box ml={8} mr={2}>
                            Daily
                          </Box>
                        </Center>
                      )
                    },
                    {
                      value: "weekly",
                      label: (
                        <Center>
                          <Box ml={8} mr={2}>
                            Weekly
                          </Box>
                        </Center>
                      )
                    },
                    {
                      value: "monthly",
                      label: (
                        <Center>
                          <Box ml={8} mr={2}>
                            Monthly
                          </Box>
                        </Center>
                      )
                    }
                  ]}
                />
              )}
              <ReportTypeToggle
                reportType={reportType}
                setReportType={(value: IReportType) => {
                  reset();
                  setSelectedSegment("");
                  setReportType(value);
                }}
              />

              <Box mr="sm" ml="sm">
                <SegmentDropdownMenu
                  type={reportType === "user" ? "user" : "company"}
                  onChange={(value: any) => {
                    if (!value) {
                      handleClearFiltersChange();
                      return;
                    }
                    setFilters(segments.find((s: any) => s.id === value).filters);
                    setFilterVisible(false);
                    onApplyChange(segments.find((s: any) => s.id === value).filters);
                  }}
                />
              </Box>

              {getInsightReportType(insightId || getDefaultInsight()?.value) ===
                "user_retention" && (
                <DateRangeDropdown
                  selectDateRange={onChangeDate}
                  id={""}
                  defaultLabel={getCohortDateDefaultLabelByFrequency(retentionFrequency)}
                  ranges={getCohortDateRangesByFrequency(retentionFrequency)}
                />
              )}
            </Flex>
          </Flex>

          {getInsightReportType(insightId || getDefaultInsight()?.value) === "user_retention" ? (
            <>
              <InsightSection
                title="Overall Retention Curve"
                description={
                  <>
                    The retention rate of {reportType == "user" ? "users who" : "companies which"}{" "}
                    have used your app after they signed up.
                  </>
                }>
                <div>
                  {isUserRetentionHistogramLoading === "loaded" ? (
                    <>
                      {userRetentionHistogram.series.length === 0 ? (
                        <>
                          <EmptyGraphResults />
                        </>
                      ) : (
                        <>
                          <div className="h-full w-full">
                            <ReactEChartsCore
                              style={{ height: "400px" }}
                              // ref={chartRef}
                              opts={{ renderer: "svg" }}
                              notMerge={true}
                              echarts={echarts}
                              option={userRetentionHistogram}
                              // onChartReady={handleChartReady}
                            />
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <BarChartSkeleton />
                  )}
                </div>

                {isUserRetentionHistogramLoading === "loaded" && (
                  <Center>
                    <RetentionMessage message={userRetentionMessage} />
                  </Center>
                )}
              </InsightSection>

              <InsightSection
                title={"Cohort Analysis"}
                description={
                  <>
                    The retention cohort of {reportType == "user" ? "users who" : "companies which"}{" "}
                    have used your app after they signed up.
                  </>
                }
                rightSection={
                  <SegmentedControl
                    value={cohortValuesType}
                    size={"xs"}
                    onChange={setCohortValuesType}
                    data={[
                      { label: "View absolute numbers", value: "numbers" },
                      { label: "View percentage", value: "ratio" }
                    ]}
                  />
                }>
                <div style={{}}>
                  {isRetentionCohortLoading === "loaded" ? (
                    <>
                      {cohortData.length === 0 ? (
                        <>
                          <EmptyGraphResults />
                        </>
                      ) : (
                        <>
                          <CohortsByRows
                            isPercentage={cohortValuesType === "ratio"}
                            headerCells={getCohortColumns(
                              retentionFrequency || "daily",
                              reportType
                            )}
                            bodyCells={cohortData}></CohortsByRows>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <BarChartSkeleton />
                    </>
                  )}
                </div>
              </InsightSection>
            </>
          ) : (
            <>
              <SlippingAwayUsersListModal
                period={slippingAwayUsersPeriod}
                visible={isModalVisible}
                setVisible={setIsModalVisible}
                insightId={insightId || getDefaultInsight()?.value}
                reportType={reportType}></SlippingAwayUsersListModal>
              <SlippingAwayUsersHistogram
                slippingAwayUsersCount={slippingAwayUsersCount}
                isCountLoading={isCountLoading}
                totalUsers={totalUsers}
                reportType={reportType}
                handleSlippingAwayUsersModal={handleSlippingAwayUsersModal}
              />
              <SlippingAwayUsersTable
                slippingAwayUsersCount={slippingAwayUsersCount}
                isCountLoading={isCountLoading}
                totalUsers={totalUsers}
                reportType={reportType}
                handleSlippingAwayUsersModal={handleSlippingAwayUsersModal}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default InsightsDashboardRetention;
