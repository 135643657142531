import axios from "./JWTService";

export class EventsService {
  groupedEvents = (
    workspaceId: string,
    eventName: string,
    page: number = 1,
    sort: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `v1/events/${workspaceId}/grouped_events?event_name=${eventName}&page=${page}&sort=${sort}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  individualEventHistogram = (
    workspaceId: string,
    eventName: string,
    startDate: string,
    endDate: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `v1/events/${workspaceId}/individual_event_histogram?event_name=${eventName}&start_date=${startDate}&end_date=${endDate}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  activity = (workspaceId: string, status: string = ""): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/events/${workspaceId}/events/activity?status=${status}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  muteEvent = (workspaceId: string, eventName: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/events/${workspaceId}/mute`, { event_name: eventName })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  individualEventDetailedActivity = (
    workspaceId: string,
    eventName: string,
    page: number,
    fromDate: string,
    toDate: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `v1/events/${workspaceId}/individual_event_detailed_activity?event_name=${eventName}&page=${page}&start_date=${fromDate}&end_date=${toDate}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  eventBreakdownByPage = (
    workspaceId: string,
    eventName: string,
    page: number,
    fromDate: string,
    toDate: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `v1/events/${workspaceId}/event_breakdown_by_page?event_name=${eventName}&page=${page}&start_date=${fromDate}&end_date=${toDate}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  eventAttributes = (
    workspaceId: string,
    event: any,
    fromDate: string,
    toDate: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `/v1/events/${workspaceId}/individual_event_properties?event_name=${event}&start_date=${fromDate}&end_date=${toDate}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  eventPropertiesByKey = (
    workspaceId: string,
    event: string,
    eventKey: string,
    fromDate: string,
    toDate: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `/v1/events/${workspaceId}/individual_event_property_by_key/${event}/${eventKey}?start_date=${fromDate}&end_date=${toDate}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  groupedEventsByContact = (
    workspaceId: string,
    contact_id: string,
    page: number = 1,
    type: "visitor" | "user" | "company",
    eventName: string = "",
    sort: string = "count:desc"
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `v1/events/${workspaceId}/grouped_events/${contact_id}/${type}?event_name=${eventName}&page=${page}&sort=${sort}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  eventCountByContact = (
    workspaceId: string,
    contact_id: string,
    eventName: string,
    page: number = 1,
    type: "user" | "visitor" | "company" = "user"
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `v1/events/${workspaceId}/count/${contact_id}/${type}?event_name=${eventName}&page=${page}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
