import { UserCRMDetailActivities } from "./UserCRMDetailActivities"
import { UserCRMDetailAttributes } from "./UserCRMDetailAttributes"
import "./users_detail.scss"
export const UserCRMDetailOverview = (props: any) => {
  return (
    <>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <UserCRMDetailAttributes></UserCRMDetailAttributes>
        </div>
        <div className="col-span-2">
          <UserCRMDetailActivities></UserCRMDetailActivities>
        </div>
      </div>
    </>
  )
}
