import { Box } from "@mantine/core";
import clsx from "clsx";
import { FC } from "react";
import classes from "./Legend.module.css";
interface LegendProps {
  color: string;
  isActive: boolean;
  text: string;
  onClick: () => void;
}

export const Legend: FC<LegendProps> = ({ color, isActive, text, onClick }) => {
  return (
    <>
      <Box
        onClick={onClick}
        mr={"xs"}
        mb={"sm"}
        style={{ background: color }}
        className={clsx(classes.legend, !isActive ? classes.legendInactive : "")}>
        {text}
      </Box>
    </>
  );
};
