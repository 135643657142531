import useStyles from "./SidePane.styles";
import { ActionIcon, Box, Group, SegmentedControl, Skeleton, Stack, Text, TextInput, Tooltip, useMantineTheme } from "@mantine/core";
import React, { useContext, useEffect, useRef } from "react";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { truncateText } from "@/lib/utils/StringUtility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useCustomizeMenuStore } from "@/stores/useCustomizeMenuStore";
import useMavenAIStore, { TChat } from "@/stores/useMavenAIStore";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { closeAllModals, openConfirmModal } from "@mantine/modals";
import { MavenAIService } from "@/lib/services/MavenAIService";
import { toast } from "react-toastify";
import { WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { StringParam, useQueryParams } from "use-query-params";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";


const SidePane = () => {
  const theme = useGlobalMantineTheme()

  const { classes } = useStyles();

  const { id } =
    useParams<{
      id: string
    }>();


  const navigate = useNavigate();
  const renameInput = useRef<HTMLInputElement>(null)
  const { activeWorkspace, isCollapsedLeftMenu, setIsCollapsedLeftMenu } =
    useContext(AppLifecycleContext);
  const service = new MavenAIService(activeWorkspace?.id || "");
  const { hasRolesNotWith } = useWorkspaceUtilityHook();


  const [chats,chatsOrder, fetchChats, fetchingChats, setActiveChat, clearMessages, setInputMessage, flag, setFlag] = useMavenAIStore((state) => [
    state.chats,
    state.chatsOrder,
    state.fetchChats,
    state.fetchingChats,
    state.setActiveChat,
    state.clearMessages,
    state.setInputMessage,
    state.flag, state.setFlag
  ]);

  const [query, setQuery] = useQueryParams({
    flag: StringParam
  });

  const [internalAside] =
    useCustomizeMenuStore((state: any) => [
      state.internalAside
    ]);

  useEffect(() => {
    fetchChats(activeWorkspace?.id || "");
    if (query) {
      if (query.flag) {
        setQuery({
          flag: query.flag === 'mine' ? 'mine' : 'all'
        });
        setFlag(query.flag === 'mine' ? 'mine' : 'all');
      }  else {
        setQuery({
          flag: 'mine'
        });
      }
    }  else {
      setQuery({
        flag: 'mine'
      });
    }
  }, [activeWorkspace, flag]);

  const selectChat = (chat: TChat) => {
    if (chat.chat_id === id){
      return;
    }

    // Set the active chat
    setActiveChat(chat);

    // clear the messages
    clearMessages();
    setInputMessage("");

    // Redirect to the chat
    navigate(`/env/${activeWorkspace.identifier}/maven-ai/${chat.chat_id}?flag=${flag}`);
  };

  const handleRemoveChat = (chat: TChat) => {
    openConfirmModal({
      title: <h3 className="font-medium">Delete chat</h3>,
      size: "sm",
      children: (
        <>
          <Text size="sm" className="mt-4">
            Are you sure you want to delete the chat?
          </Text>
        </>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: {
        color: "red.6",
      },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => onDelete(chat.chat_id),
    })
  }

  const handleRenameChat = (chat: TChat) => {
    openConfirmModal({
      title: <h3 className="font-medium">Rename title</h3>,
      size: "lg",
      children: (
        <>

          <TextInput className="mt-4" placeholder="Chat name" ref={renameInput}
            defaultValue={chat.title}
          />
        </>
      ),
      labels: { confirm: "Rename", cancel: "Cancel" },

      closeOnConfirm: false,
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        const name = renameInput.current?.value
        if (name?.trim()) {
          onRename(chat.chat_id, name);
          closeAllModals();
        }
      },
    })
  }

  const onDelete = (chatId: string) => {
    console.log("Delete chat with id: ", chatId);
    service.deleteChat(chatId).then(() => {
      // if active chat is deleted, clear the messages
      if (id === chatId) {
        clearMessages();
        setInputMessage("");

        // navigate to the first chat
        navigate(`/env/${activeWorkspace.identifier}/maven-ai/new?flag=${flag}`);

        // clear active
        setActiveChat(null);
      }

      fetchChats(activeWorkspace?.id || "");
    });
  }

  const onRename = (chatId: string, title: string) => {
    service.renameChat(chatId, title).then(() => {
      fetchChats(activeWorkspace?.id || "");
    });
  }

  /**
   we need to validate the id here, either it can be 'new' or a valid id
   **/
  useEffect(() => {
      if (id === "new") {
        // Create a new chat
        setActiveChat(null);
        clearMessages();
      } else {
        // check if chats are loaded and if the chat exists
        if (!fetchingChats && Object.keys(chats).length > 0) {
          // const chat = chats.find((chat) => chat.chat_id === id);
          // if (!chat) {
          //   // Redirect to the first chat
          //   navigate(`/env/${activeWorkspace.identifier}/maven-ai/new`);
          //   setActiveChat(null);
          //   clearMessages();
          // } else {
          //   // set the active chat
          //   setActiveChat(chat);
          // }

          const chat = Object.values(chats).map((chats) => chats.find((chat) => chat.chat_id === id)).filter(Boolean)[0];
          if (!chat) {
            // Redirect to the first chat
            navigate(`/env/${activeWorkspace.identifier}/maven-ai/new?flag=${flag}`);
            setActiveChat(null);
            clearMessages();
          } else {
            // set the active chat
            setActiveChat(chat);
          }

        }
      }
    }
    , [id, chats]);

  const handleFlag = (value: 'mine' | 'all') => {
    setFlag(value);
    // Set query param
    setQuery({
      flag: value
    });
  }

  return (
    <Box
      className={classes.root}
      sx={{
        width: !internalAside ? "0px" : "244px",
        opacity: !internalAside ? 0 : 1
      }}>
      <Box className={classes.header}>
        <Group gap={8} wrap={'nowrap'} justify={"space-between"} w={"100%"}>
          <Group gap={8}  wrap={'nowrap'}>
            <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.2 9.97542H8.05V10.1254V11.0005C8.05 12.0525 8.92835 12.9005 10 12.9005H13.2502L15.4916 14.5343L15.4916 14.5343L15.4928 14.5352C15.6747 14.6651 15.916 14.6872 16.1181 14.589C16.3204 14.4907 16.45 14.2886 16.45 14.0631V13.188V12.9005H16.75H17.2C18.2717 12.9005 19.15 12.0525 19.15 11.0005V5.75023C19.15 4.69816 18.2717 3.85015 17.2 3.85015H13.6H13.45V4.00015V4.43767V4.58767H13.6H17.2C17.8665 4.58767 18.4 5.11238 18.4 5.75023V11.0005C18.4 11.6383 17.8665 12.163 17.2 12.163H16.3C15.9737 12.163 15.7 12.4231 15.7 12.7505V13.7681L13.6574 12.2793C13.5527 12.203 13.4289 12.163 13.2991 12.163H10C9.33352 12.163 8.8 11.6383 8.8 11.0005V10.1254V9.97542H8.65H8.2ZM10.9 8.66286H6.70094C6.57108 8.66286 6.44725 8.70282 6.34256 8.77916L4.3 10.268V9.25038C4.3 8.92295 4.02634 8.66286 3.7 8.66286H2.8C2.13353 8.66286 1.6 8.13815 1.6 7.5003V2.25008C1.6 1.61223 2.13353 1.08752 2.8 1.08752H10.9C11.5665 1.08752 12.1 1.61223 12.1 2.25008V7.5003C12.1 8.13815 11.5665 8.66286 10.9 8.66286ZM3.55 9.40038V9.6879V10.5629C3.55 10.7872 3.67569 10.9904 3.88289 11.0893C4.08626 11.1864 4.32793 11.1657 4.50838 11.0341L6.7498 9.40038H10.9C11.9716 9.40038 12.85 8.55237 12.85 7.5003V2.25008C12.85 1.19801 11.9716 0.35 10.9 0.35H2.8C1.72835 0.35 0.85 1.19801 0.85 2.25008V7.5003C0.85 8.55237 1.72835 9.40038 2.8 9.40038H3.25H3.55Z"
                fill={theme.colorScheme === "dark" ? "white" : "black"}
                stroke={theme.colorScheme === "dark" ? "white" : "black"}
                strokeWidth="0.3" />
            </svg>
            <span>
              <Text size={"md"} fw={600}>
                Recent
              </Text>
            </span>
          </Group>

          <Tooltip label={"Create a new chat"} position={"bottom"} withinPortal>
            <ActionIcon
              size={"xs"}
              color={"gray.6"}
              onClick={() => {
                // Redirect to the chat
                navigate(`/env/${activeWorkspace.identifier}/maven-ai/new?flag=${flag}`);
                // clear active chat
                setActiveChat(null);
                // clear the messages
                clearMessages();
                setInputMessage("");

              }}
              disabled={id === "new"}
            >
              <FontAwesomeIcon
                size={"xs"}
                icon={solid("plus")}
                className=" transform "
              />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Box>
      <Box className={classes.section}
           sx={{
             flex: 1
           }}>
        <SegmentedControl
          value={flag}
          onChange={(value: any) => handleFlag(value)}
          size={'xs'}
          mb={'lg'}
          data={[
            { label: 'Mine', value: 'mine' },
            { label: 'All', value: 'all' }
          ]}
          fullWidth
          styles={() => ({
            root: {
              backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : '#dfdfdf',
              // backgroundColor: 'light-dark(#dfdfdf, var(--mantine-color-dark-7))',
            }
          })}
        />
        <Stack gap={20}>
          {fetchingChats ? (
            <>
              <Skeleton height={30} radius="xl" />
              <Skeleton height={30} radius="xl" />
              <Skeleton height={30} radius="xl" />
            </>
          ) : (
            <>
              {chatsOrder.length === 0 && (
                <Box>
                  <Text size={"sm"} color={"dimmed"}>
                    No queries have been found. You can easily create a new one by asking a question.
                  </Text>
                </Box>
              )}
              {
                chatsOrder.map((group: string) => (
                  <>
                    <Text size={"xs"} color={"dimmed"} sx={{
                      textTransform: "uppercase",
                      fontWeight: 600,
                    }}>
                      {group}
                    </Text>
                    {
                      chats[group].map((chat, index) => (
                        <Box
                          key={`chat-${chat.chat_id}`}
                          // className={`${classes.sectionItem}`}
                          className={`${classes.sectionItem} ${
                            chat.chat_id === id ? "active" : ""
                          }`}
                          sx={{
                            marginBottom: chats[group].length - 1 === index ? "10px" : "0px"
                          }}
                          onClick={() => selectChat(chat)}
                        >
                          <Tooltip
                            label={chat.title}
                            position={"bottom-start"}
                            withinPortal
                            disabled={chat.title.length < 30}>
                            <Group
                              sx={{
                                flexWrap: "nowrap"
                              }}
                              gap={"xs"}>
                              <Text sx={{
                                whiteSpace: "nowrap",
                                textTransform: "capitalize"
                              }}>
                                {/*{truncateText(chat.title, 30)}*/}
                                {chat.title}
                              </Text>

                              <Box className={'fade-effect'}></Box>
                            </Group>
                          </Tooltip>


                          <div className={"side-pane-item-actions"}>
                            <ActionIcon variant="outline" size={"xs"} color={"gray.6"}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleRenameChat(chat);
                                        }}
                                        disabled={!hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER])}

                            >
                              <FontAwesomeIcon
                                size={"2xs"}
                                icon={solid("edit")}
                                className=" transform "

                              />
                            </ActionIcon>
                            <ActionIcon
                              variant="outline"
                              color={"red"}
                              size={"xs"}
                              disabled={!hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER])}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleRemoveChat(chat);
                              }}>
                              <FontAwesomeIcon
                                size={"2xs"}
                                icon={solid("trash")}
                                className=" transform "
                              />
                            </ActionIcon>
                          </div>
                        </Box>
                      ))
                    }
                  </>

                ))
              }
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default React.memo(SidePane);
