/* eslint-disable react-hooks/exhaustive-deps */
import { useLocalStorage, useMediaQuery } from "@mantine/hooks";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { BulletListSkeleton } from "@/lib/utils/ChartsSkeletons";
import { LS_TOP_SOURCES_PREFERENCE } from "@/lib/utils/Storage";
import React, { useContext, useEffect, useState } from "react";
import { LoadingStateProps, LooseObject, TopSourcesObjectProps } from "types/types.d";
import { NoResults } from "../NoResults/NoResults";
import { TopSourcesDetailedView } from "./DetailedView/TopSourcesDetailedView";
import { isGoalSelected } from "./Filters/FiltersUtility";
import { TopSourcesOverview } from "./TopSourcesOverview";
import { ViewDetailedButton } from "./ViewDetailedButton";
import {
  Box,
  Button,
  Divider,
  Flex,
  Loader,
  Menu,
  Paper,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import useDeepCompareEffect from "@/hooks/useDeepCompareEffect";
import { ALL_DEFAULT_CHANNELS, LOADER_COLOR, PALE_SHADES } from "@/lib/utils/Constants";
import { capitalizeFirstLetter, numberToCommas, truncateText } from "@/lib/utils/StringUtility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ChannelMappingService } from "@/lib/services/ChannelMappingService";
import { DynoTable, ProgressCell } from "../../Common/DynoTable/DynoTable";
import { Oval } from "react-loader-spinner";
import classes from "./WebAnalyticsOverview.module.css";
import clsx from "clsx";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const waService = new WebAnalyticsService();

const defaultChannelsDropdownList: Array<TopSourcesObjectProps> = [
  { key: "all", value: "All" },
  ...ALL_DEFAULT_CHANNELS.map((item) => ({ key: item, value: item.replace("_", " ") }))
];

const TopSources = ({ query, setQuery, isPublic }: any) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const theme = useGlobalMantineTheme();

  // color scheme

  const { colorScheme } = useMantineColorScheme();

  // State for top sources detailed view modal
  const [opened, setOpened] = useState(false);

  // State for channels dropdown list
  // Initial state includes default channels and an 'All' option
  const [channelsDropdown, setChannelsDropdown] = useState<TopSourcesObjectProps[]>(
    defaultChannelsDropdownList
  );
  // Local storage for active tab
  const [activeTab, setActiveTab] = useLocalStorage<string>({
    key: LS_TOP_SOURCES_PREFERENCE,
    defaultValue: "overview",
    getInitialValueInEffect: false
  });

  // setting list for results
  const [list, setList] = useState<
    Array<{
      source: string;
      visitors: number;
      visitors_comparison?: number;
      visitors_percentage_change?: number;
      bounce_rate: number;
      bounce_rate_comparison?: number;
      bounce_rate_percentage_change?: number;
      conversion_rate: number;
      conversion_rate_comparison?: number;
      conversion_rate_percentage_change?: number;
    }>
  >([]);

  const [tableColumns, setTableColumns] = useState<any[]>([]);

  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const isMobile = useMediaQuery("(max-width: 920px)");

  /**
   * On clicking any of the sources, it will be added to the query
   */
  const onHandleSourceClick = (source: string) => {
    // As we have renamed 'Direct' source to 'Direct Traffic' for differentiating in the pie chart
    const formattedSource = source === "Direct Traffic" ? "Direct" : source;
    setQuery({
      ...query,
      filters: [
        ...query.filters.filter((value: string) => !value.startsWith(`source:`)),
        `source:${formattedSource}`
      ]
    });
  };

  /**
   * On clicking any of the channels, it will be added to the query
   */
  const onHandleChannelClick = (channel: string) => {
    let channelType = channel.toLowerCase().replace(/ /g, "_");
    // If the channelType is in the dropdown list, set the activeTab to the channelType
    // If not, set the activeTab to "overview"
    if (channelsDropdown.find((item) => item.key === channelType)) {
      setActiveTab(channelType);
    } else {
      setActiveTab("overview");
    }

    setQuery({
      ...query,
      filters: [
        ...query.filters.filter((value: string) => !value.startsWith(`channel:`)),
        `channel:${channel.toLowerCase()}`
      ]
    });
  };

  const channelString = query.filters
    .find((str: string) => str.startsWith("channel:"))
    ?.split(":")[1]
    .toLowerCase()
    .replace(/ /g, "_");

  // Fetch sources calls
  const fetchSourcesForChannel = async () => {
    if (activeTab === "overview") return;

    setLoading("loading");
    // Check if the channelString matches any of the channels in the dropdown list
    // If it does, set the selectedTab to the channelString, else set it to "all"
    // If channelString is undefined, keep channelType as it is.
    const selectedTab = channelString
      ? channelsDropdown.find((item) => item.key === channelString)
        ? channelString
        : "all"
      : activeTab;

    let queryParams = query;
    if (channelString) {
      // Create a copy of query.filters excluding items starting with "channel:"
      const filteredFilters = query.filters.filter(
        (filterItem: string) => !filterItem.startsWith("channel:")
      );

      // updte the queryParams with the new filteredFilters without the channel filter in case of Top Sources only.
      queryParams = { ...query, filters: filteredFilters };
    }
    setActiveTab(selectedTab);

    await waService
      .topSourcesForChannel(
        activeWorkspace.id,
        selectedTab,
        "list",
        1,
        10,
        "visitors:desc",
        queryParams
      )
      .then((res) => {
        if (res.data) {
          setList(res.data.data);
          if (res.data.columns) {
            const highestValue = res.data.highest_value;
            let columns = res.data.columns;
            // exclude the columns that starts with "bounce_rate"
            if (columns) {
              columns = columns.filter(
                (column: { accessor: string }) => !column.accessor.startsWith("bounce_rate")
              );
            }

            // Modify columns before setting the state
            const modifiedColumns = columns.map((column: { accessor: string }) => {
              if (column.accessor === "source_with_value") {
                return {
                  ...column,
                  render: (data: any) => (
                    <BarExpandable
                      data={data}
                      progressCellValue={
                        highestValue === 0 ? 0 : Math.floor((data.value / highestValue) * 100)
                      }
                      onClick={onHandleSourceClick}
                      activeTab={activeTab}
                    />
                  )
                };
              }
              if (column.accessor === "visitors" || column.accessor === "conversions") {
                return {
                  ...column,
                  render: (data: any) => (
                    <Flex align={"flex-start"}>
                      <Text fw={400}>{numberToCommas(data)}</Text>
                    </Flex>
                  )
                };
              }
              if (column.accessor === "conversion_rate") {
                return {
                  ...column,
                  render: (data: any) => (
                    <Flex align={"flex-start"}>
                      <Text fw={400}>{data}%</Text>
                    </Flex>
                  )
                };
              }

              if (column.accessor.endsWith("percentage_change")) {
                return {
                  ...column,
                  render: ({ label, percentage_change, comparison_value }: any) => (
                    <Flex align={"flex-start"}>
                      <Tooltip.Floating
                        label={`Comparison period ${label}: ${comparison_value}${
                          label === "CR" ? "%" : ""
                        }`}
                        position="top"
                        style={{ fontSize: "12px" }}>
                        <Text
                          fw={400}
                          color={
                            percentage_change !== undefined && percentage_change !== null
                              ? percentage_change > 0
                                ? "green.6"
                                : percentage_change < 0
                                ? "red.6"
                                : "gray.6"
                              : "gray.6"
                          }>
                          {percentage_change !== undefined &&
                          percentage_change !== null &&
                          percentage_change !== 0
                            ? `${percentage_change > 0 ? "+" : ""}${percentage_change.toFixed(1)}%`
                            : ""}
                        </Text>
                      </Tooltip.Floating>
                    </Flex>
                  )
                };
              }
              return column;
            });
            setTableColumns(modifiedColumns);
          }
        }
      })
      .catch((err) => {
        setList([]);
      });
    setLoading("loaded");
  };

  const fetchExpandableSources = async (searchQuery: string, page: number) => {
    const res = await waService.referrer(activeWorkspace.id, searchQuery, activeTab, page, query);

    return res.data;
  };

  // Fetch custom channels for the workspace
  const fetchCustomChannels = async () => {
    await new ChannelMappingService().fetchChannelMappings(activeWorkspace.id).then((response) => {
      if (response) {
        const customChannels = response.map((item: any) => ({
          key: item.name.replace(/ /g, "_").toLowerCase(),
          value: item.name
        }));
        setChannelsDropdown([...defaultChannelsDropdownList, ...customChannels]);
      }
    });
  };

  const BarExpandable = ({ data, progressCellValue, onClick, activeTab }: any) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState<LoadingStateProps>("idle");
    const [expandedItems, setExpandedItems] = useState<Array<LooseObject>>([]);
    const [loadMore, setLoadMore] = useState(false);

    const handleExpandClick = async () => {
      if (isExpanded && expandedItems.length > 0) {
        setPage(0);
        setIsExpanded(false);
        return;
      }

      setIsLoading("loading");

      setPage(page + 1);
      setIsExpanded(!isExpanded);
      // Note: we are making this component reusable for sites, search, social etc.
      // We are fetching the data from the api and then storing it its own states.
      const res = await fetchExpandableSources(data.source, page + 1);
      if (res) {
        setExpandedItems(res);
        handleLoadMore(res);
      }
      setIsLoading("loaded");
    };

    const handleLoadMore = (res: any) => {
      if (res.length === 10) {
        console.debug("moreLoadingRows, add it.");
        setLoadMore(true);
      } else {
        setLoadMore(false);
      }
    };

    const moreLoadingRows = async () => {
      setIsLoading("loading");
      setPage(page + 1);
      const res = await fetchExpandableSources(data.source, page + 1);
      if (res) {
        setExpandedItems([...expandedItems, ...res]);
        handleLoadMore(res);
      }
      setIsLoading("loaded");
    };

    return (
      <Box onClick={() => onHandleSourceClick(data.source)}>
        <ProgressCellWithHover
          value={progressCellValue}
          cellData={data}
          activeTab={activeTab}
          isExpanded={isExpanded}
          handleExpandClick={handleExpandClick}
        />
        {isExpanded && (
          <Box>
            {isLoading === "loading" && page === 1 ? (
              <>
                <Box ml={40} my={8}>
                  {/* <Oval color={LOADER_COLOR} height={14} width={14} /> */}
                  <Loader size={"xs"} />
                </Box>
              </>
            ) : (
              <Flex direction={"column"} py={8} px={16}>
                {expandedItems.map((item: LooseObject, index: number) => (
                  <Flex
                    pl={32}
                    py={4}
                    key={`source:expanded:${index}${
                      item?.referrer_source ? ":" + item?.referrer_source : ""
                    }`}>
                    <Flex align={"center"} flex={1} className={classes.expandedRow}>
                      <Text fz="xs" truncate>
                        {truncateText(
                          item?.referrer_path.replace("https://", "").replace("http://", ""),
                          60
                        )}
                      </Text>

                      <Flex
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(item?.referrer_path, "_blank");
                        }}>
                        <Box fz={"xs"} ml={8} c={colorScheme === "dark" ? "dark.2" : "gray.9"}>
                          <FontAwesomeIcon icon={regular("up-right-from-square")} />
                        </Box>
                      </Flex>
                    </Flex>

                    {isGoalSelected() ? (
                      <>
                        <Box ml={8}>
                          <Text ta="right">{numberToCommas(item?.visitors)} </Text>
                        </Box>
                        <Box>
                          <Text ta="right">{item.conversion_rate}% </Text>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box ml={8}></Box>
                        <Box>
                          <Text ta="right">{numberToCommas(item?.visitors)} </Text>
                        </Box>
                      </>
                    )}
                  </Flex>
                ))}
              </Flex>
            )}

            {loadMore && expandedItems.length > 0 && (
              <Flex ml={32} mb="xs">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    moreLoadingRows();
                  }}
                  variant="subtle"
                  size="xs"
                  color="gray"
                  disabled={isLoading === "loading"}
                  loading={isLoading === "loading"}>
                  Load more
                </Button>
              </Flex>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const ProgressCellWithHover = ({
    value,
    cellData,
    activeTab,
    isExpanded,
    handleExpandClick
  }: any) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <ProgressCell
        value={value}
        label={
          <LabelWithActions
            data={cellData}
            isHovered={isHovered}
            activeTab={activeTab}
            isExpanded={isExpanded}
            handleExpandClick={handleExpandClick}
          />
        }
        setIsHovered={setIsHovered}
        barColor={PALE_SHADES.purple}
      />
    );
  };

  const LabelWithActions = ({ data, isHovered, activeTab, isExpanded, handleExpandClick }: any) => {
    const img =
      data.source.includes(".") || data.source === "Direct" ? data.source : data.source + ".com";
    const externalLink = activeTab === "referral" ? `https://${data.source}` : "";

    return (
      <Flex direction={"column"}>
        <Flex align={"center"}>
          <Box
            onClick={(e) => {
              e.stopPropagation();
              handleExpandClick();
            }}
            className={`transition-all transform ease-in-out ${
              isMobile ? "mr-1" : "mr-3"
            } cursor-pointer flex ${isExpanded ? "rotate-90" : ""}`}>
            <Box fz="xs" mr={8}>
              <FontAwesomeIcon
                icon={solid("angle-right")}
                key={`source-item-expandable-container-icon:${data.source}`}></FontAwesomeIcon>
            </Box>
          </Box>
          <Flex align={"center"} justify={"space-between"}>
            <Box h={16}>
              {img && typeof img === "string" && img.length > 0 && (
                <img
                  height={16}
                  src={`https://favicon.usermaven.com/?url=${
                    img.length > 0 ? img : "temp-mail.org"
                  }&size=32${colorScheme === "dark" ? "&fallback=default-white.svg" : ""}`}
                  alt=""
                />
              )}
              {img && typeof img !== "string" && <>{img}</>}
            </Box>
            <Text pl={5}>{data.source}</Text>
            {externalLink && isHovered && (
              <Tooltip label="Open link" color="dark" withArrow style={{ fontSize: "12px" }}>
                <Box
                  pl={"1rem"}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(externalLink, "_blank");
                  }}>
                  <Box fz="xs">
                    <FontAwesomeIcon icon={regular("up-right-from-square")} />
                  </Box>
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  };

  useDeepCompareEffect(() => {
    console.log(`Active tab: ${activeTab}, ${JSON.stringify(query)}, ${activeWorkspace.id}`);
    if (loading !== "loading") {
      fetchSourcesForChannel();
    }
  }, [activeTab, query, query.filters, activeWorkspace.id]);

  useEffect(() => {
    fetchCustomChannels();
  }, []);

  return (
    <Paper shadow="xs" withBorder>
      {/* Modal for detailed view */}
      <TopSourcesDetailedView
        query={query}
        opened={opened}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setOpened={setOpened}
        onHandleClick={onHandleSourceClick}
        isPublic={isPublic}
      />
      <Flex
        direction={"column"}
        h={510}
        component="div"
        style={{
          overflowY: "auto"
        }}>
        <Flex component="div" align={"center"} p={"md"}>
          <Title flex={1} order={6} fw={600}>
            Top Channels / Sources
          </Title>
          <Text
            className={clsx(classes.tab, activeTab === "overview" ? classes.activeTab : "")}
            // c={activeTab === "overview" ? "brand" : ""}
            onClick={() => setActiveTab("overview")}>
            Overview
          </Text>
          <Divider orientation="vertical" mx={8} />
          <Menu width={160} withArrow withinPortal shadow="lg">
            <Menu.Target>
              <UnstyledButton>
                <Flex align={"center"}>
                  <Text fw={500} fz={13} tt={"capitalize"}>
                    {activeTab === "overview" ? (
                      <>By Channel</>
                    ) : (
                      <>{activeTab.split("_").join(" ")}</>
                    )}
                  </Text>
                  <Box fz="xs" ml={4}>
                    <FontAwesomeIcon icon={regular("chevron-down")} />
                  </Box>
                </Flex>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown
              style={{
                height: "350px",
                overflowY: "auto",
                marginTop: "-1.5rem"
              }}>
              {channelsDropdown.map((item: TopSourcesObjectProps, index) => (
                <Menu.Item
                  key={`top-sources:${index}`}
                  style={{
                    textTransform: "capitalize"
                  }}
                  // disable all items if channel filter is set
                  // only enable the item in the list that matches the channel filter
                  disabled={channelString !== undefined && item.key !== channelString}
                  py={6}
                  bg={
                    activeTab === item.key
                      ? colorScheme === "dark"
                        ? theme.colors.dark[9]
                        : theme.colors.gray[1]
                      : ""
                  }
                  onClick={() => {
                    // only need to set the activeTab
                    // do not need to set the query.filters
                    setActiveTab(item.key);
                  }}>
                  <Text lineClamp={1} tt={"capitalize"}>
                    {item.value}
                  </Text>
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </Flex>
        <Divider />
        <div className="" id="WebAnalytics:TopSources">
          {activeTab === "overview" ? (
            <>
              <TopSourcesOverview
                query={query}
                onHandleSourceClick={onHandleSourceClick}
                onHandleChannelClick={onHandleChannelClick}
              />
            </>
          ) : (
            <>
              {loading === "loaded" ? (
                <>
                  {list.length > 0 ? (
                    <Box data-cy="top-sources-results-list">
                      {/*Detailed button for opening modal for showing detailed view*/}
                      <DynoTable columns={tableColumns} data={list} />
                      {
                        <div data-cy="top-sources-detailed-view-btn">
                          <ViewDetailedButton
                            onClick={() => {
                              setOpened(true);
                            }}
                          />
                        </div>
                      }
                    </Box>
                  ) : (
                    <>
                      <Flex align={"center"} justify={"center"} h={400}>
                        <NoResults
                          text={`There are no sources for ${capitalizeFirstLetter(
                            activeTab.replace("_", " ")
                          )}.`}
                          heading={"No Results"}
                        />
                      </Flex>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="ml-4">
                    <BulletListSkeleton />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Flex>
    </Paper>
  );
};

const TopSourcesMemoized = React.memo(TopSources);
export { TopSourcesMemoized as TopSources };
