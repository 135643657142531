/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  HoverCard,
  Table,
  Text,
  useMantineTheme
} from "@mantine/core";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { ReportService } from "@/lib/services/ReportService";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { LoadingStateProps } from "@/types/types.d";
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import { getRelativeDateToNow } from "@/lib/utils/DateUtility";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { Oval } from "react-loader-spinner";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface NotesDetailViewProps {
  opened: boolean;
  setOpen: (e: boolean) => void;
}

const ReportActivityLogs = ({ opened, setOpen }: NotesDetailViewProps) => {
  const theme = useGlobalMantineTheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");

  const [reportActivityLogs, setReportActivityLogs] = useState([]);

  const [hasMore, setHasMore] = useState(true);
  const page = useRef(0);

  const fetchReportActivityLogs = async (page: number) => {
    setLoading("loading");
    const service = new ReportService();

    page = page ? page : 1;
    let limit = 50;

    await service
      .getReportActivityLogs(activeWorkspace.id, page, limit)
      .then((res) => {
        if (res.data) {
          setReportActivityLogs(res.data);
          setHasMore(res.data.length > 50);
        }
      })
      .catch((err) => {});
    setLoading("loaded");
  };

  useEffect(() => {
    fetchReportActivityLogs(1);
  }, []);

  return (
    <SidePanel
      opened={opened}
      onCancel={() => setOpen(false)}
      loading={true}
      title={"Report Activity Logs"}>
      <div
        className="flex-col py-4 justify-between"
        style={{
          overflowX: "auto",
          whiteSpace: "nowrap"
        }}>
        <Table striped verticalSpacing={"sm"} highlightOnHover>
          <Table.Thead fw={600}>
            <Table.Tr>
              <Table.Th className={"capitalize"}>Type</Table.Th>
              <Table.Th className={"capitalize"}>Period</Table.Th>
              <Table.Th className={"capitalize"}>Status</Table.Th>
              <Table.Th className={"capitalize"}>Recipient</Table.Th>
              <Table.Th>Sent at</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {loading === "loaded" ? (
              <>
                {reportActivityLogs.map((log: any, index: number) => (
                  <Table.Tr key={index}>
                    <Table.Td>
                      <Text size="sm" tt="capitalize" fw={500}>
                        {toTitleCase(log.report.name)}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text>{toTitleCase(log.report.period)}</Text>
                    </Table.Td>
                    <Table.Td>
                      <>
                        {log?.error ? (
                          <HoverCard
                            width={280}
                            position="top"
                            shadow="md"
                            withArrow
                            disabled={!log?.error}>
                            <HoverCard.Target>
                              <Badge
                                size="lg"
                                radius="md"
                                tt="capitalize"
                                variant="dot"
                                fw={500}
                                color={log?.error ? "red.7" : "green.7"}>
                                {log?.error ? "Failed" : "Success"}{" "}
                              </Badge>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                              <Text size="xs">{log?.error}</Text>
                            </HoverCard.Dropdown>
                          </HoverCard>
                        ) : (
                          <Badge
                            size="lg"
                            radius="md"
                            variant="dot"
                            fw={500}
                            tt={"capitalize"}
                            color={log?.error ? "red.7" : "green.7"}>
                            {log?.error ? "Failed" : "Success"}{" "}
                          </Badge>
                        )}
                      </>
                    </Table.Td>
                    <Table.Td>{log.recipient}</Table.Td>
                    <Table.Td> {getRelativeDateToNow(log.sent_at || "")}</Table.Td>
                  </Table.Tr>
                ))}

                {reportActivityLogs && reportActivityLogs.length === 0 && (
                  <tr>
                    <td colSpan={12}>
                      <NoResults
                        text={"There are no logs available."}
                        heading={"No logs"}></NoResults>
                    </td>
                  </tr>
                )}
              </>
            ) : (
              <>
                <tr>
                  <td colSpan={12}>
                    <div className="flex justify-center items-center">
                      <Oval color={LOADER_COLOR} height={14} width={14} />
                    </div>
                  </td>
                </tr>
              </>
            )}
          </Table.Tbody>
        </Table>
      </div>
      {hasMore && reportActivityLogs.length > 0 && (
        <Center py="md">
          <Button
            loading={loading === "loading"}
            disabled={loading === "loading"}
            variant="outline"
            onClick={() => {
              page.current += 1;
              fetchReportActivityLogs(page.current);
            }}
            data-cy="reports-activity-load-more-btn">
            Load more
          </Button>
        </Center>
      )}
    </SidePanel>
  );
};

export default ReportActivityLogs;
