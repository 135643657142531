import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Alert,
  Button,
  Group,
  Input,
  Title,
  Text,
  Flex,
  Center,
  Loader,
  Tooltip,
  Divider,
  Anchor
} from "@mantine/core";
import { WorkspaceAdditionalDomainsService } from "@/lib/services/WorkspaceAdditionalDomainsService";
import { Oval } from "react-loader-spinner";
import { NavLink, useNavigate } from "react-router-dom";
import { LoadingStateProps } from "types/types.d";
import { SettingsHeaderContent } from "@/ui/components/App/Settings/SettingsHeaderContent/SettingsHeaderContent";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";

interface IFormInputs {
  domain: string;
}
export const GeneralAdditionalDomains: React.FC<any> = () => {
  const [list, setList] = useState([]);
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [isRemoveLoading, setIsRemoveLoading] = useState<LoadingStateProps>("idle");
  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<IFormInputs>();

  const onSubmit = async (data: IFormInputs) => {
    setIsRemoveLoading("loading");
    await new WorkspaceAdditionalDomainsService()
      .post(activeWorkspace.id, data.domain)
      .then((res) => {
        if (res.data) {
          setList(list.concat(res.data));
          reset();
        }
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          toast.error(err.response.data.detail);
        }
      });
    setIsRemoveLoading("loaded");
  };

  const removeAdditionalDomain = async (id: string) => {
    await new WorkspaceAdditionalDomainsService()
      .delete(activeWorkspace.id, id)
      .then((res) => {
        if (res.data) {
          const newList = list.filter((item: any) => item.id !== id);
          setList(newList);
          toast.success("Your additional domain removed successfully.");
        }
      })
      .then((err: any) => {
        if (err?.response?.status === 422) {
          toast.error(err.response.data.detail);
        }
      });
  };

  const fetchAdditionalDomains = async () => {
    setLoading("loading");
    await new WorkspaceAdditionalDomainsService()
      .get(activeWorkspace.id)
      .then((res) => setList(res.data))
      .catch((err) => err);
    setLoading("loaded");
  };
  const navigate = useNavigate();

  useEffect(() => {
    fetchAdditionalDomains();
  }, []);

  return (
    <>
      <Title order={4} fw={600} mb="md">
        Additional domains
      </Title>
      <Text mb="md">
        We recommend having only 1 domain (and its sub-domains) in one workspace. But, If you want
        to track analytics for more than one domain in the same workspace, please add the root
        URL(s) here.
      </Text>

      {loading === "loaded" && list.length > 0 && (
        <Alert
          color="yellow"
          radius={"md"}
          mb="md"
          icon={<FontAwesomeIcon className="text-2xl" icon={regular("info-circle")} />}>
          <span className="font-semibold">Note:</span> Don't forget to add the{" "}
          <Anchor
            className={"px-1 primary-link-color"}
            onClick={() => navigate(`/env/${activeWorkspace.identifier}/settings/setup`)}>
            tracking script
          </Anchor>{" "}
          to all the domains.
        </Alert>
      )}
      <>
        <>
          <>
            {loading === "loaded" ? (
              <>
                {list.length > 0 && (
                  <Text mb={"md"}>
                    Apart from the main domain mentioned in the 'Workspace URL', you have{" "}
                    <Text span fw={500}>
                      {list.length} additional domains
                    </Text>{" "}
                    enabled in this workspace.
                  </Text>
                )}

                {list.map((item: any, index: number) => (
                  <Flex
                    w={"50%"}
                    align="center"
                    key={`additional-domain:${item.id}:${index}`}
                    pb="sm">
                    <Text fw={500} w={200}>
                      {item.url}
                    </Text>
                    <Flex>
                      <Tooltip label="Delete additional domain">
                        <ActionIcon
                          data-tip="Remove"
                          variant="subtle"
                          color={"red"}
                          size="sm"
                          onClick={() => {
                            removeAdditionalDomain(item.id);
                          }}>
                          <FontAwesomeIcon
                            icon={regular("trash")}
                            className=" text-red-500 transform text-sm"
                          />
                        </ActionIcon>
                      </Tooltip>
                    </Flex>
                    <Divider my="md" />
                  </Flex>
                ))}
              </>
            ) : (
              <Center py="lg">
                <Loader size={"xs"} />
              </Center>
            )}
          </>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex align={"center"}>
              <div className="max-w-2/5">
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="e.g yourdomain.com"
                      error={(invalid || error) === true}
                      defaultValue={activeWorkspace?.domain}
                    />
                  )}
                  name="domain"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true, maxLength: 100 }}
                />
                {/* {JSON.stringify(errors)} */}
                {errors.domain?.type === "required" && (
                  <ValidationLabel
                    validationType="error"
                    text="Domain is required"
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
                {errors.domain?.type === "maxLength" && (
                  <ValidationLabel
                    validationType="error"
                    text="Domain should be less than 100 characters."
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
              </div>

              <Button type="submit" loading={loading === "loading"} ml={"md"}>
                Add Domain
              </Button>
            </Flex>
          </form>
        </>
      </>
    </>
  );
};
