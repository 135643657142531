import { useEffect } from "react";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { ActiveUsersTrendsService } from "@/lib/services/ActiveUsersTrendsService";
import { useContext, useState } from "react";
import { ActiveUsersTrendsCountProps, InsightIdType } from "types/types.d";
import { numberToCommas } from "@/lib/utils/StringUtility";
import ReactTooltip from "react-tooltip";
import { MemoizedTrendsCard as TrendsCard } from "@/ui/components/App/TrendsCard/TrendsCard";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { ActiveUsersListModal } from "./ActiveUsersListModal";
import { Flex, Text, Button } from "@mantine/core";
import StatsCard from "@/ui/components/Common/StatsCard/StatsCard";

export const MAUActiveUsers: React.FC<InsightIdType> = ({ insightId, reportType = "user" }) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [value, setValue] = useState<ActiveUsersTrendsCountProps>({
    count: 0,
    running_diff_from_previous: 0,
    start_date: ""
  });
  const [isLoading, setIsLoading] = useState("idle");
  const [viewList, setViewList] = useState(false);
  const [filters] = useSegmentStore((state) => [state.filters]);
  const getMAU = async () => {
    const activeUsersTrendsService = new ActiveUsersTrendsService();
    setIsLoading("loading");
    await activeUsersTrendsService
      .activeUsersCount(
        activeWorkspace.id,
        insightId,
        "monthly",
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => setValue(res.data))
      .catch();
    setIsLoading("loaded");
    ReactTooltip.rebuild();
  };
  useEffect(() => {
    getMAU();
  }, [insightId, filters]);
  return (
    <>
      <ActiveUsersListModal
        listView={viewList}
        setListView={setViewList}
        insightId={insightId}
        type="monthly"
        reportType={reportType}
        fromDate={value.start_date}
        toDate={value.start_date}
      />
      <StatsCard
        title={
          reportType == "user" ? "Monthly active users (MAU)" : "Monthly active companies (MAC)"
        }
        description={
          <>
            The number of active {reportType == "user" ? "users" : "companies"} this month{" "}
            <Flex justify={"space-between"} align={"center"}>
              <Text
                mt="xs"
                fz="xs"
                c={value.running_diff_from_previous >= 0 ? "green.6" : "red.6"}
                fw={600}>
                {value.running_diff_from_previous}% vs Yesterday
              </Text>
              <Button onClick={() => setViewList(true)} variant="light" size="compact-xs">
                View list
              </Button>
            </Flex>
          </>
        }
        value={numberToCommas(value.count)}
        icon={""}
        bg={""}
        iconColor={""}
      />
    </>
  );
};
