// @ts-ignore
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { MOBILE_BREAKPOINT_QUERY, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { WorkspaceSlugParams } from "types/types.d";
import { Button, Tooltip, useMantineTheme } from "@mantine/core";
import { StringParam, useQueryParam } from "use-query-params";
import {
  HeaderNavigationActions,
  HeaderNavigationContainer,
  HeaderNavigationH1
} from "./HeaderNavigation.style";
import { useMediaQuery } from "@mantine/hooks";
import { InsightsCreateButton } from "./InsightsCreateButton";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const HeaderNavigation = ({ ...props }) => {
  const [insightType] = useQueryParam("type", StringParam);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  const { workspaceIdentifier } = useParams<WorkspaceSlugParams>();
  const { hasRolesNotWith } = useWorkspaceUtilityHook();

  const redirectInsightsRoute = () => {
    if (!insightType) {
      navigate(`/env/${workspaceIdentifier}/insights/dashboard/overview`);
    } else {
      navigate(`/env/${workspaceIdentifier}/insights/dashboard/${insightType}`);
    }
  };

  const theme = useGlobalMantineTheme();
  return (
    <HeaderNavigationContainer
      theme={theme}
      style={{
        height: isMobileView ? "auto" : "",
        paddingBottom: isMobileView ? "0.5rem" : ""
      }}>
      {/* Show "view insights button" */}
      {(props?.is_insights_view || props?.is_reports_view) && (
        <Button
          size="xs"
          variant="outline"
          color="gray.6"
          className="mr-4"
          onClick={() => {
            redirectInsightsRoute();
          }}>
          &larr; Back to Insights
        </Button>
      )}

      {/* Header content such as title for the web page */}

      <HeaderNavigationH1>
        {props?.is_integration_view && (
          <>
            <Link to={`/env/${workspaceIdentifier}/settings/integrations`}>Integrations</Link> &gt;{" "}
            {props?.integration_name}
          </>
        )}
        {props?.is_people_view && (
          <>
            <Link to={`/env/${workspaceIdentifier}/users/everyone`}>Users</Link> &gt;{" "}
            {props?.people_name}
          </>
        )}
        {props?.is_company_view && (
          <>
            <Link to={`/env/${workspaceIdentifier}/companies/everyone`}>Company</Link> &gt;{" "}
            {props?.company_name}
          </>
        )}
        {props?.is_trends_view && (
          <>
            <Link to={`/env/${workspaceIdentifier}/insights/reports?type=${props?.report_type}`}>
              Product Insights
            </Link>{" "}
            &gt; {props?.insight_name}
          </>
        )}
        {props?.is_reports_setup_view && (
          <>
            <Link to={`/env/${workspaceIdentifier}/insights/reports?type=${props?.report_type}`}>
              Product Insights
            </Link>{" "}
            &gt; {props?.report_name}
          </>
        )}
        {props.title && <>{props.title}</>}
        {props?.is_insights_dashboard && (
          <>
            <span className=" ml-4">
              <a
                className=""
                href="https://www.youtube.com/watch?v=bNJ9F3pVlsI"
                target={"_blank"}
                rel="noreferrer">
                <Tooltip className="!font-medium" label="Learn more about product insights">
                  <FontAwesomeIcon
                    id="product-insights-youtube-video"
                    className="h-5 w-5 cursor-pointer transition-all ease-in-out text-red-600 rounded hover:text-red-500   mt-2"
                    icon={brands("youtube")}
                  />
                </Tooltip>
              </a>
            </span>
          </>
        )}
      </HeaderNavigationH1>

      {/* Actions section such as notifications, setup reports etc. */}
      <HeaderNavigationActions>
        {props.is_insights_dashboard && (
          <>
            {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
              <>
                {props.title === "Feature Adoption" && location.pathname.includes("feature") && (
                  <Button
                    onClick={() => {
                      props.setShowManageFeatureAdoptionModal(true);
                    }}
                    // variant="gradient"
                    className="mr-4"
                    size={isMobileView ? "xs" : "sm"}>
                    Manage Adoption
                  </Button>
                )}
                {/* show create new button if on any insight tab except overview page */}
                {!location.pathname.includes("overview") && (
                  <InsightsCreateButton
                    setShowInsightSetupModal={props.setShowInsightSetupModal}
                    setInsightType={props.setInsightType}
                  />
                )}
                <Button
                  // variant="gradient"
                  onClick={() => props.setShowManageInsightsModal(true)}
                  size={isMobileView ? "xs" : "sm"}>
                  Manage Insights
                </Button>
              </>
            )}
          </>
        )}
        {/* <Icon icon="Help" />
        <Icon icon="Notification-Active" /> */}
        {/* <FrillWidget /> */}
      </HeaderNavigationActions>
    </HeaderNavigationContainer>
  );
};
