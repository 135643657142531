import { WorkspaceSharingType } from "types/types.d";
import axios from "./JWTService";

type WorkspaceAPIInput = {
  name: string;
  timezone: string;
  website: string;
};

type WorkspaceInviteAPIInput = {
  email: string;
  role: string;
};

type UpdateWorkspaceDetailsProps = {
  name: string;
  timezone: string;
  website: string;
};

export class WorkspaceService {
  create = (payload: WorkspaceAPIInput): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post("/v1/workspaces", payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  workspacesAccess = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get("/v1/workspaces/access")
        .then((res) => {
          let response = {
            defaultWorkspace: {},
            workspacesList: []
          };
          // console.log(`Workspace access response`, res);
          if (res.data.length > 0) {
            const urlPath = window.location.pathname;
            let defaultWorkspace = [];

            // get workspace from url and its setup is completed then redirect to the active workspace.
            if (urlPath.includes("/env/")) {
              const urlWorkspaceIdentifier = urlPath.split("/")[2];

              defaultWorkspace = res.data.filter((workspaceItem: any) => {
                // console.log(workspaceItem);
                return (
                  workspaceItem.Workspace.identifier === urlWorkspaceIdentifier
                  // NOTE: Removed this check as it sets the active workspace default for the new workspace if setup is not completed.
                  // &&
                  // workspaceItem.Workspace.is_setup_completed
                );
              });
            }

            // if the workspace is not retrieved form the url, the find the default active workspace.

            if (defaultWorkspace.length === 0) {
              defaultWorkspace = res.data.filter((workspaceItem: any) => {
                // console.log(workspaceItem);
                return workspaceItem.WorkspaceMember.is_default_workspace === true;
              });
            }

            // get all workspaces list

            let workspacesList = res.data.map((workspaceItem: any) => workspaceItem.Workspace);
            // console.log("Workspace lists are:", workspacesList);
            response.workspacesList = workspacesList;
            // console.log("Default Workspace is:", defaultWorkspace);

            if (defaultWorkspace.length > 0) {
              // Note: Corner Case:  More than 1 workspace and default workspace setup not completed.
              // Set only if the workspace setup completed, as the user will be redirected to view all workspaces case otherwise.

              if (
                // Commented because of the corner case of home setup page sets to the active workspace.
                // defaultWorkspace[0].Workspace?.is_setup_completed &&
                response.workspacesList.length > 1
              ) {
                response.defaultWorkspace = defaultWorkspace[0].Workspace;
              }

              // Single workspace, set it as a default workspace regardless of their status.
              if (response.workspacesList.length === 1) {
                response.defaultWorkspace = defaultWorkspace[0].Workspace;
              }
            }
          }
          // console.log(res.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  allMembers = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get("v1/workspaces/all_members")
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  workspaceMembers = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspaces/${workspaceId}/members`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  inviteMember = (workspaceId: string, payload: WorkspaceInviteAPIInput): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspaces/${workspaceId}/invite`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  updateWorkspaceDetails = (
    workspaceId: string,
    payload: UpdateWorkspaceDetailsProps
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces/${workspaceId}`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  isPixelEventsAvailable = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspaces/${workspaceId}/check_connection`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  setEventsRecommendationsViewed = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspaces/${workspaceId}/events_recommendations_viewed`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  firstEventDate = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspaces/${workspaceId}/first_event_date`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  groupedEvents = (
    workspaceId: string,
    eventName: string,
    page: number = 1,
    sort: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `v1/workspaces/${workspaceId}/grouped_events?event_name=${eventName}&page=${page}&sort=${sort}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  individualEventHistogram = (workspaceId: string, eventName: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspaces/${workspaceId}/individual_event_histogram?event_name=${eventName}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  eventsActivity = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspaces/${workspaceId}/events/activity`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  customDomain = (workspaceId: string, domain: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspaces/${workspaceId}/custom_domain`, { domain: domain })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  removeCustomDomain = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`v1/workspaces/${workspaceId}/custom_domain`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  checkCustomDomainConnectivity = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspaces/${workspaceId}/custom_domain_connectivity`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  starred = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces/${workspaceId}/starred`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  unstarred = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces/${workspaceId}/unstarred`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  sharePasswordValidation = (workspaceId: string, sharePassword: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspaces/${workspaceId}/share_password_validation`, {
          share_password: sharePassword
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  deleteWorkspace = (ws_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/v1/workspaces/${ws_id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getIsDefaultWorkspace = (ws_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/workspaces/${ws_id}/is_default`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  emailReportsPreference = (workspaceId: string, emailReportsPreference: boolean): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces/${workspaceId}/email_reports_preference`, {
          email_reports_preference: emailReportsPreference
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  uploadLogo = (workspaceId: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/workspaces/${workspaceId}/logo`, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
