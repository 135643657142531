/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingStateProps, PagesObjectProps } from "../../../../../types/types.d";
import { Button, Box, Text, TextInput, Tooltip, useMantineTheme, Flex } from "@mantine/core";
import { useContext, useEffect, useRef, useState } from "react";
import { useDebouncedState } from "@mantine/hooks";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "../../../../../lib/services/WebAnalyticsService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  convertSecondsToReadableFormat,
  numberToCommas
} from "../../../../../lib/utils/StringUtility";
import { NoResultsDetailedView } from "./NoResultsDetailedView";
import { DATE_FORMAT, WORKSPACE_MEMBER_ROLES } from "../../../../../lib/utils/Constants";
import { ExportButton } from "../../../Common/ExportButton/ExportButton";
import useWorkspaceUtilityHook from "../../../../../hooks/useWorkspaceUtilityHook";
import { format } from "date-fns";
import { downloadCSVFromResponse } from "../../../../../lib/utils/CSVExporterUtility";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { DynoTable, ProgressCell } from "@/ui/components/Common/DynoTable/DynoTable";
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import { isGoalSelected } from "../Filters/FiltersUtility";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const waService = new WebAnalyticsService();

export const TopPagesDetailedView = ({
  query,
  opened,
  activeTab,
  setOpened,
  handleOnClick,
  getExternalLink,
  TopPagesNavList,
  isPublic
}: any) => {
  const theme = useGlobalMantineTheme();
  const page = useRef(0);
  const [hasMore, setHasMore] = useState(true);
  const [sort, setSort] = useState(isGoalSelected() ? "conversions:desc" : "visitors:desc");
  const [isSortApplied, setIsSortApplied] = useState(true);

  // handle search using debounce
  const [searchSources, setSearchSources] = useDebouncedState("", 200);

  const [loadingDetailedList, setLoadingDetailedList] = useState<LoadingStateProps>("idle");
  // detailed listing page
  const [detailedList, setDetailedList] = useState<
    Array<{
      source: string;
      count: number;
      count_comparison?: number;
      count_percentage_change?: {
        label: string;
        percentage_change: number;
        comparison_value: number;
      };
      percentage: number;
      percentage_comparison?: number;
      percentage_percentage_change?: {
        label: string;
        percentage_change: number;
        comparison_value: number;
      };
      pageviews?: number;
      pageviews_comparison?: number;
      pageviews_percentage_change?: {
        label: string;
        percentage_change: number;
        comparison_value: number;
      };
      total_entrances?: number;
      total_entrances_comparison?: number;
      total_entrances_percentage_change?: number;
      bounce_rate: number;
      bounce_rate_comparison?: number;
      bounce_rate_percentage_change?: number;
      visit_duration: number;
      visit_duration_comparison?: number;
      visit_duration_percentage_change?: number;
      scroll_depth: number;
      scroll_depth_comparison?: number;
      scroll_depth_percentage_change?: number;
      time_on_page: number;
      time_on_page_comparison?: number;
      time_on_page_percentage_change?: number;
    }>
  >([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);

  const { activeWorkspace } = useContext(AppLifecycleContext);
  // Get user role in the workspace.
  const { hasRolesNotWith } = useWorkspaceUtilityHook();
  // exporting state for export list
  const [exporting, setIsExporting] = useState("idle");

  // fetching detailed list
  const fetchDetailedPagesList = async () => {
    setLoadingDetailedList("loading");
    // adding page number to show more results
    page.current = page.current + 1;
    await waService
      .topPages(activeWorkspace.id, activeTab, page.current, 50, sort, query, searchSources)
      .then((res) => {
        if (res.data.data) {
          // If the detailed list has already some results, then append the new results to the list.
          if (page.current === 1) {
            setDetailedList(res.data.data);
          } else {
            setDetailedList([...detailedList, ...res.data.data]);
          }

          if (res.data.columns) {
            // Modify columns before setting the state
            const modifiedColumns = res.data.columns.map((column: { accessor: string }) => {
              if (column.accessor === "source_with_value") {
                return {
                  ...column,
                  render: (data: any) => (
                    <ProgressCellWithClick data={data} onClick={handleOnClick} />
                  )
                };
              }
              if (column.accessor === "visitors_with_percentage") {
                return {
                  ...column,
                  header: (
                    <Box
                      sx={{
                        display: "flex"
                      }}
                      onClick={() => {
                        changeSortOrder("visitors"); // visitors or bounce_rate
                      }}>
                      <Text>{toTitleCase("visitors")}</Text>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "4px"
                        }}>
                        <FontAwesomeIcon
                          icon={solid("sort")}
                          style={{
                            color: sort.startsWith("visitors")
                              ? theme.colors.brand[4]
                              : theme.colorScheme === "light"
                              ? theme.colors.gray[5]
                              : theme.colors.gray[7]
                          }}
                          className={`ml-2 transform   transition-all duration-200 ease-in-out`}
                        />
                      </Box>
                    </Box>
                  ),
                  render: (data: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center"
                      }}>
                      <Text fw={400}>{numberToCommas(data.visitors)}</Text>
                      <Text fw={500} color="gray.6" fz={10} pl={"5px"} pt={"3px"}>
                        ({data.percentage}%)
                      </Text>
                    </Box>
                  )
                };
              }
              if (
                [
                  "visitors",
                  "pageviews",
                  "time_on_page",
                  "conversions",
                  "total_entrances",
                  "total_exits"
                ].includes(column.accessor)
              ) {
                return {
                  ...column,
                  header: (
                    <Box
                      sx={{
                        display: "flex"
                      }}
                      onClick={() => {
                        changeSortOrder(column.accessor);
                      }}>
                      <Text>
                        {toTitleCase(
                          activeTab === "top"
                            ? column.accessor
                            : activeTab === "entry"
                            ? "unique_entrances"
                            : "unique_exits"
                        )}
                      </Text>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "4px"
                        }}>
                        <FontAwesomeIcon
                          icon={solid("sort")}
                          style={{
                            color: sort.startsWith(column.accessor)
                              ? theme.colors.brand[4]
                              : theme.colorScheme === "light"
                              ? theme.colors.gray[5]
                              : theme.colors.gray[7]
                          }}
                          className={`ml-2 transform   transition-all duration-200 ease-in-out`}
                        />
                      </Box>
                    </Box>
                  ),
                  render: (data: any) => (
                    <Text fw={400}>
                      {column.accessor === "time_on_page"
                        ? convertSecondsToReadableFormat(data)
                        : numberToCommas(data)}
                    </Text>
                  )
                };
              }
              if (
                ["bounce_rate", "scroll_depth", "conversion_rate", "percentage"].includes(
                  column.accessor
                )
              ) {
                return {
                  ...column,
                  header: (
                    <Box
                      sx={{
                        display: "flex"
                      }}
                      onClick={() => {
                        changeSortOrder(column.accessor); // visitors or bounce_rate
                      }}>
                      <Text>
                        {toTitleCase(
                          column.accessor === "percentage"
                            ? activeTab === "top"
                              ? column.accessor
                              : activeTab === "entry"
                              ? "entry_rate"
                              : "exit_rate"
                            : column.accessor
                        )}
                      </Text>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "4px"
                        }}>
                        <FontAwesomeIcon
                          icon={solid("sort")}
                          style={{
                            color: sort.startsWith(column.accessor)
                              ? theme.colors.brand[4]
                              : theme.colorScheme === "light"
                              ? theme.colors.gray[5]
                              : theme.colors.gray[7]
                          }}
                          className={`ml-2 transform   transition-all duration-200 ease-in-out`}
                        />
                      </Box>
                    </Box>
                  ),
                  render: (data: any) => <Text fw={400}>{data}%</Text>
                };
              }

              if (column.accessor.endsWith("percentage_change")) {
                return {
                  ...column,
                  render: ({ label, percentage_change, comparison_value }: any) => (
                    <Tooltip.Floating
                      label={`Comparison period ${label}: ${comparison_value}${
                        label === "CR" ? "%" : ""
                      }`}
                      position="top"
                      style={{ fontSize: "12px" }}>
                      <Text
                        fw={400}
                        color={
                          percentage_change !== undefined && percentage_change !== null
                            ? percentage_change > 0
                              ? "green.6"
                              : percentage_change < 0
                              ? "red.6"
                              : "gray.6"
                            : "gray.6"
                        }>
                        {percentage_change !== undefined &&
                        percentage_change !== null &&
                        percentage_change !== 0
                          ? `${percentage_change > 0 ? "+" : ""}${percentage_change.toFixed(1)}%`
                          : ""}
                      </Text>
                    </Tooltip.Floating>
                  )
                };
              }
              return column;
            });
            setTableColumns(modifiedColumns);
          }

          // if the length of the results is less than 50, then there are no more results to show.
          if (res.data.data.length < 50) {
            setHasMore(false);
          }

          // Reset the sort applied to false after the data is fetched.
          setIsSortApplied(false);
        }
      })
      .catch((err) => {
        setHasMore(false);
      });
    setLoadingDetailedList("loaded");
  };

  const ProgressCellWithClick = ({ data, onClick }: any) => {
    return (
      <Box
        onClick={() => {
          onClick(data.source);
          setOpened(false);
        }}>
        <ProgressCell
          label={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <Text>{data.source}</Text>
            </Box>
          }
        />
      </Box>
    );
  };

  //Service call to top pages list as CSV.
  const exportTopPages = async () => {
    setIsExporting("loading");
    try {
      const response = await waService.topPagesExport(
        activeWorkspace.id,
        activeTab,
        1,
        10000,
        sort,
        query,
        searchSources
      );
      if (response.data) {
        //Setting filename of the exported file in accordance with the activeTab, date and time of export.
        let filename = `${activeTab}_pages_list_${format(new Date(), DATE_FORMAT)}.csv`;
        //Utility function to download the csv from response.
        downloadCSVFromResponse(response, filename);
        setIsExporting("loaded");
        return true;
      }
    } catch (err) {
      setIsExporting("loaded");
      throw new Error("400");
    }
    return false;
  };

  // function to handle change in sort on clicking table header
  const changeSortOrder = (field: string) => {
    setIsSortApplied(true);
    let sortingKey;
    // if sort is already set to desc, then set it to asc and vice versa
    if (sort.endsWith(":desc")) {
      sortingKey = `${field}:asc`;
    } else if (sort.endsWith(":asc")) {
      sortingKey = `${field}:desc`;
    } else {
      sortingKey = `${field}:desc`;
    }

    // setting detailed list to empty and page to 1 so that new sorted data can be fetched
    setSort(sortingKey);
    setDetailedList([]);
    page.current = 0;
    setHasMore(true);
    setLoadingDetailedList("loading");
  };

  // on close of modal, set detailed list to empty and page to 1
  const onCloseModal = () => {
    setOpened(false);
    setDetailedList([]);
    setSearchSources("");
    setSort("visitors:desc");
    page.current = 0;
    setTableColumns([]);
  };

  // useEffect to fetch detailed list on opening modal
  useEffect(() => {
    // Send the service call when the modal is opened.
    // In the rest of the cases, the service call will not be sent.
    if (opened) {
      page.current = 0;
      setHasMore(true);
      setIsSortApplied(true);
      fetchDetailedPagesList();
    }
  }, [opened, sort, searchSources]);

  return (
    <>
      <SidePanel
        loading={true}
        onCancel={onCloseModal}
        opened={opened}
        title={TopPagesNavList.find((page: PagesObjectProps) => page.key === activeTab)?.value}>
        <Flex justify={"space-between"} py="md">
          {/*search input to search sources*/}
          <TextInput
            data-autofocus
            leftSection={<FontAwesomeIcon icon={regular("search")} />}
            placeholder="Search by page URL"
            onChange={(e) => {
              setSearchSources(e.target.value);
            }}></TextInput>
          {/*Export button which will allow the user to export list as csv. It will be set to disabled if it's in the loading state*/}
          <div className="pr-2">
            {!isPublic && hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
              <ExportButton
                planName="Pro or Premium"
                onClick={exportTopPages}
                disabled={exporting === "loading"}
                loading={exporting === "loading"}
              />
            )}
          </div>
        </Flex>
        {opened &&
        loadingDetailedList === "loaded" &&
        !isSortApplied &&
        detailedList.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <NoResultsDetailedView colspan={3} />
          </Box>
        ) : (
          <>
            <DynoTable
              columns={tableColumns}
              data={detailedList}
              fontSize={14}
              highlightOnHover={true}
              stripped={true}
              loading={loadingDetailedList === "loading" && isSortApplied ? true : false}
            />
            {!isSortApplied && hasMore && detailedList.length > 0 && (
              <Flex justify={"center"} mt="md">
                {/* Load more option to fetch the remaining results */}
                <Button
                  loading={loadingDetailedList === ("loading" as LoadingStateProps)}
                  disabled={loadingDetailedList === ("loading" as LoadingStateProps)}
                  variant="outline"
                  color={theme.colorScheme === "dark" ? "dark.2" : "gray.7"}
                  onClick={() => fetchDetailedPagesList()}
                  data-cy="top-pages-detailed-view-load-more-btn">
                  Load more
                </Button>
              </Flex>
            )}
          </>
        )}
      </SidePanel>
    </>
  );
};
