/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Text, useMantineTheme } from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext, useEffect, useState } from "react";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FeatureAdoptionService } from "@/lib/services/FeatureAdoptionService";
import useFeatureAdoptionStore, { Feature, Module } from "@/stores/useFeatureAdoptionStore";
import { ModuleSetup } from "@/ui/pages/Insights/Components/Setup/ModuleSetup";
import AddOrUpdateModuleModal from "./AddOrUpdateModuleModal";

interface IFeatureAdoptionModal {
  isModalOpen: boolean;
  setIsModalOpen: (e: boolean) => void;
}

const ManageFeatureAdoptionModal = ({ isModalOpen, setIsModalOpen }: IFeatureAdoptionModal) => {

  // States that are used.
  const [isLoading, setIsLoading] = useState("idle");
  const [openAddModulePrompt, setOpenAddModulePrompt] = useState(false);

  const { activeWorkspace } = useContext(AppLifecycleContext);

  const [modules, addModule, addFeatureToModule] = useFeatureAdoptionStore((state) => [
    state.modules,
    state.addModule,
    state.addFeatureToModule
  ]);

  // all service calls
  const featureAdoptionService = new FeatureAdoptionService();

  const getModulesAndFeatures = async () => {
    setIsLoading("loading");
    await featureAdoptionService
      .getFeatures(activeWorkspace.id)
      .then((res) => {
        if (res.data) {
          const apiResponse = res.data;
          // Create a Set to keep track of existing module names
          const existingModules = new Set(modules.map((module) => module.name));

          // Parse and store the data from the API response
          apiResponse.forEach((moduleData: Module) => {
            const { id, name, description, is_default, features } = moduleData;

            // Check if the module already exists in the store
            if (!existingModules.has(name)) {
              const newModule = {
                id: id,
                name: name,
                description: description,
                is_default: is_default,
                features: [],
                is_saved: true,
                is_new: false,
                is_active: false
              };
              addModule(newModule);
              existingModules.add(name); // Add the module name to the set to mark it as existing
              console.log("Module", newModule);
              features.forEach((feature: Feature) => {
                // Check if the feature already exists within the module
                const existingFeature = modules
                  .find((module) => module.name === name)
                  ?.features.find((f) => f.name === feature.name);

                if (!existingFeature) {
                  addFeatureToModule(id, {
                    id: feature.id,
                    name: feature.name,
                    conditions: feature.conditions,
                    adoption_rate: feature.adoption_rate,
                    retention_period: feature.retention_period,
                    is_new: feature.is_new,
                    is_active: feature.is_active,
                    is_saved: feature.is_saved
                  });
                }
              });
              console.log(features, existingModules);
            }
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading("loaded");
      });
  };

  useEffect(() => {
    getModulesAndFeatures();
  }, []);

  if (isLoading !== "loaded") {
    return (
      <>
        <LoadingComponent></LoadingComponent>
      </>
    );
  }

  return (
    <SidePanel
      opened={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      loading={true}
      title={"Manage features and evaluation criteria"}>
      <Box p="md">
        <Text mb={4} c="dimmed">
          A module is a distinct component, like a building block, designed for a specific function.
          The 'Reporting' module, for instance, is tailored with features for custom report
          creation, real-time data analysis, and exporting tools — each feature acting like a piece
          of specialized equipment within this module.
        </Text>
        {modules && modules.length > 0 ? (
          <>
            {modules.map((module, moduleIndex) => (
              <ModuleSetup key={moduleIndex} module={module} moduleIndex={moduleIndex} />
            ))}

            <div className="flex justify-center items-center pt-4">
              <Button
                size={"sm"}
                radius="md"
                variant="outline"
                onClick={() => setOpenAddModulePrompt(true)}>
                <FontAwesomeIcon icon={regular("plus")} className="w-4 h- hover:opacity-80 mr-2" />
                Add new module
              </Button>
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center pt-4">
              <Button
                size={"sm"}
                radius="md"
                variant="outline"
                onClick={() => setOpenAddModulePrompt(true)}>
                <FontAwesomeIcon icon={regular("plus")} className="w-4 h- hover:opacity-80 mr-2" />
                Add new module
              </Button>
            </div>
          </div>
        )}
        <AddOrUpdateModuleModal open={openAddModulePrompt} setOpen={setOpenAddModulePrompt} />
      </Box>
    </SidePanel>
  );
};

export default ManageFeatureAdoptionModal;
