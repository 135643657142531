import {
  ActionIcon,
  Box,
  Flex,
  Group,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import AppLifecycleContext from "../../../lib/contexts/AppLifecycleContext";
import WorkspacesDropdown from "../../components/App/WorkspaceDropdown/WorkspacesDropdown";
import { Divider } from "@mantine/core";
import { useCustomizeMenuStore } from "../../../stores/useCustomizeMenuStore";
import { WorkspaceModal } from "../../components/App/Modals/WorkspaceModal";
import useResponsiveness from "../../../hooks/useResponsiveness";
import NavMenu from "./NavMenu";
import { useMediaQuery } from "@mantine/hooks";
import { MOBILE_BREAKPOINT_QUERY, ORGANIZATION_STATES } from "../../../lib/utils/Constants";
import { setLeftPaneCollapsePreference } from "@/lib/utils/Storage";
import classes from "./MenuPane.module.css";
// const useStyles = createStyles((theme) => ({
//   navbarMobile: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     height: 70,
//     paddingLeft: theme.spacing.lg,
//     paddingRight: theme.spacing.lg,
//     background: theme.colorScheme === "dark" ? theme.colors.dark[5] : "#000d21",
//     borderBottom: "1px solid",
//     borderColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#0a1a3d"
//   }
// }));

const MenuPane = () => {
  // const { classes } = useStyles();
  const navigate = useNavigate();
  const [isWorkspaceModalOpen, setIsWorkspaceModalOpen] = useState(false);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);
  const { isNavMenuOpened, toggleNavMenuOpened } = useResponsiveness();

  const {
    user,
    activeWorkspace,
    workspaces,
    setActiveWorkspace,
    isCollapsedLeftMenu,
    setIsCollapsedLeftMenu
  } = useContext(AppLifecycleContext);
  const fetchMenuItems = useCustomizeMenuStore((state: any) => state.fetchMenuItems);

  const isBillingLocked = useCallback(
    (activeWorkspace: any) => {
      return activeWorkspace.organization.state === ORGANIZATION_STATES.QUOTA_EXCEEDED;
    },
    [activeWorkspace]
  );

  const onCreateWorkspace = () => {
    console.log("CreateWorkspaceModal");
    setIsWorkspaceModalOpen(true);
  };
  const onSelectWorkspace = async (workspace: any): Promise<any> => {
    await fetchMenuItems(workspace.id);
    setActiveWorkspace(workspace);

    if (isBillingLocked(workspace)) {
      return null;
    }

    const redirectURL = workspace.is_setup_completed
      ? `/env/${workspace.identifier}/web/statistics`
      : `/env/${workspace.identifier}/setup-guides/integration`;
    navigate(redirectURL);
  };

  return (
    <Box
      mb={"xs"}
      style={{
        position: isMobileView ? "sticky" : "fixed",
        top: 0,
        zIndex: 9
      }}>
      {isMobileView && (
        <Flex justify={"space-between"} className={classes.navbarMobile} px={"md"} py="sm">
          <Group>
            <WorkspacesDropdown
              activeWorkspace={activeWorkspace}
              workspaces={workspaces}
              onSelectWorkspace={onSelectWorkspace}
              onCreateWorkspace={onCreateWorkspace}></WorkspacesDropdown>

            <Box>
              <Divider size="sm" orientation="vertical" h={"32px"} ml={"6px"} />
            </Box>

            <ActionIcon variant={"transparent"} onClick={() => toggleNavMenuOpened()}>
              {isNavMenuOpened ? (
                <FontAwesomeIcon icon={solid("x")}></FontAwesomeIcon>
              ) : (
                <FontAwesomeIcon icon={solid("bars")}></FontAwesomeIcon>
              )}
            </ActionIcon>
          </Group>

          <Group>
            <ActionIcon variant={"transparent"} onClick={() => toggleColorScheme()}>
              {dark ? (
                <FontAwesomeIcon icon={regular("sun-bright")}></FontAwesomeIcon>
              ) : (
                <FontAwesomeIcon icon={regular("moon")}></FontAwesomeIcon>
              )}
            </ActionIcon>
          </Group>
        </Flex>
      )}

      <NavMenu
        isMobileView={isMobileView}
        workspaceDropdownComponent={
          <WorkspacesDropdown
            activeWorkspace={activeWorkspace}
            workspaces={workspaces}
            onSelectWorkspace={onSelectWorkspace}
            onCreateWorkspace={onCreateWorkspace}
            compact={isCollapsedLeftMenu ? true : false}
            withBackground></WorkspacesDropdown>
        }
      />

      <WorkspaceModal
        open={isWorkspaceModalOpen}
        isEdit={false}
        isRedirect={true}
        closeModal={(value: boolean) => setIsWorkspaceModalOpen(value)}
      />
    </Box>
  );
};

export default MenuPane;
