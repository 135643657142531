import { usePageView, useUsermaven } from "@/hooks/useUsermaven"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { USERMAVEN_OPTS } from "@/lib/utils/Constants"
import { getPublicRedirectionRouteURL } from "@/lib/utils/RedirectionRoute"
import { useContext } from "react"
import { Navigate, RouteProps } from "react-router-dom"
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading"
import { StringParam, useQueryParam } from "use-query-params"

const PublicRoute = ({ children, restricted = false }: { children: any; restricted?: boolean }) => {
  const { user, isLoading, activeWorkspace } = useContext(AppLifecycleContext)
  // eslint-disable-next-line
  const [redirect, setRedirect] = useQueryParam("redirect", StringParam)
  const redirectPath = getPublicRedirectionRouteURL(user, activeWorkspace, redirect)
  // const { component: Component, restricted, ...rest } = props
  console.log(`Public route route user state is:`, user, activeWorkspace, restricted)
  console.log(redirectPath, user, restricted)

  if (isLoading) {
    return <LoadingComponent />
  }
  if (user && restricted) {
    return (
      <Navigate
        to={{
          pathname: redirectPath,
        }}
      />
    )
  }
  return children
}

export default PublicRoute
