/* eslint-disable react-hooks/exhaustive-deps */
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { ScatterChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { UniversalTransition } from "echarts/features";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Loader,
  Space,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useNavigate } from "react-router-dom";
import isEqual from "react-fast-compare";
import { EChartsInstance, EChartsOption } from "echarts-for-react";
import { ColorsTheme } from "@/style/Colors";
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import { Legend } from "@/ui/components/App/Legend/Legend";
import { EmptyGraphResults } from "@/ui/components/App/EmptyGraphResults/EmptyGraphResults";
import { ExtendedAdoptionModule } from "../../Pages/InsightsDashboardFeatureAdoption";
import cloneDeep from "lodash.clonedeep";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  ScatterChart,
  CanvasRenderer,
  UniversalTransition
]);

export interface IFeatureAdoptionReportGraph {
  adoptionReportData: ExtendedAdoptionModule[];
  cohortInsightType: string | null;
  isFeatureUsageGraph: boolean;
  reportType: string;
}

interface FrequencyCategory {
  [key: string]: number;
}

export const FeatureAdoptionReportGraph: React.FC<IFeatureAdoptionReportGraph> = ({
  adoptionReportData,
  cohortInsightType,
  isFeatureUsageGraph,
  reportType
}) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [loading, setLoading] = useState(true);
  const [tooltipText, setTooltipText] = useState("");

  const [adoptionReportGraphData, setAdoptionReportGraphData] =
    useState<ExtendedAdoptionModule[]>(adoptionReportData);

  const xAxisLabelRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  // const LineSeriesColorsTheme = [...ColorsTheme].reverse();

  const frequencyCategory: FrequencyCategory = {
    // never: 6,
    daily: 4,
    weekly: 3,
    "bi-weekly": 2,
    monthly: 1,
    quarterly: 0
    // yearly: 0
  };

  const [option, setOption] = useState({
    tooltip: {
      borderColor: colorScheme === "dark" ? theme.colors.darkBorderColor[1] : theme.colors.gray[2],
      backgroundColor: colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[9]
      },
      show: true, // Enables the tooltip
      trigger: "item", // Trigger type; 'item' means the tooltip is triggered on data item (i.e., a point in scatter graph)
      formatter: function (params: any) {
        const usageMessage = `
        <span style="color:${params.color};" class="font-bold capitalize">${params.data[3]} (${
          params.seriesName
        })</span>
          <br>
          ${
            cohortInsightType === "adoption_rate"
              ? "Adopted"
              : cohortInsightType === "retention_rate"
              ? "Retented"
              : "Adopted"
          } users: <span class="font-bold">${params.data[0]}</span><br>
          Usage frequency: 
          <span class="font-bold capitalize">${params.name}</span>
          <br>
          Average count of feature engagements per user: <span class="font-bold">${
            params.data[4] !== 0 ? params.data[2] / params.data[4] : params.data[2]
          }</span><br>
          `;
        return `
        ${usageMessage} `;
      }
    },
    color: ColorsTheme,
    // legend: {
    //   right: "10%",
    //   top: "0%",
    //   data: ["General", "Planner", "Analytics"],
    //   textStyle: {
    //     fontSize: 12
    //   }
    // },
    grid: {
      height: "360px",
      left: isFeatureUsageGraph ? "15%" : "9.6%",
      bottom: "15%"
    },
    xAxis: {
      triggerEvent: true,
      type: "value",
      name:
        cohortInsightType === "adoption_rate"
          ? "Adoption (%)"
          : cohortInsightType === "retention_rate"
          ? "Retention (%)"
          : "Usage (%)",
      nameGap: 16,
      max: 100,
      nameLocation: "middle",
      nameTextStyle: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 13,
        align: "center",
        verticalAlign: "top",
        lineHeight: 60
      },
      axisLine: {
        show: false
      },
      splitLine: {
        show: true,
        interval: 2,
        lineStyle: {
          width: 0.75,
          color: [colorScheme === "dark" ? theme.colors.dark[3] : "#ddd"]
        }
      }
    },
    yAxis: {
      type: "category",
      name: "Usage Frequency",
      nameGap: 80,
      nameLocation: "middle",
      nameTextStyle: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 13,
        align: "center",
        verticalAlign: "top",
        lineHeight: 1,
        padding: 0
      },
      axisLabel: {},
      axisLine: {
        show: false
      },
      label: {
        show: true,
        position: "bottom",
        formatter: function (params: any) {
          return params.value;
        },
        color: "auto",
        fontSize: 10
      },
      splitLine: {
        show: true,
        // interval: 2,
        lineStyle: {
          width: 0.75,
          color: [colorScheme === "dark" ? theme.colors.dark[3] : "#ddd"]
        }
      },
      data: ["quarterly", "monthly", "bi-weekly", "weekly", "daily"]
    },
    visualMap: [
      {
        show: false,
        left: "right",
        top: "10%",
        dimension: 2,
        textStyle: {
          color: theme.colors.gray[7]
        },
        min: 0,
        max: 250,
        itemWidth: 30,
        itemHeight: 120,
        calculable: true,
        precision: 0.1,
        text: ["Usage"],
        textGap: 30,
        padding: [
          20, // up
          5, // right
          5, // down
          10 // left
        ],
        inRange: {
          symbolSize: [10, 70]
        },
        outOfRange: {
          symbolSize: [10, 70],
          color: theme.colors.gray[5]
        },
        controller: {
          inRange: {
            color: theme.colors.gray[5]
          },
          outOfRange: {
            color: theme.colors.gray[2]
          }
        }
      }
    ],
    series: []
  });

  const onEvents = {
    mouseover: (params: any) => {
      if (params.componentType === "xAxis" && params.targetType === "axisLabel") {
        const fullText = params.value;
        setTooltipText(fullText);
      }
    },
    mouseout: (params: any) => {
      if (params.componentType === "xAxis" && params.targetType === "axisLabel") {
        setTooltipText("");
      }
    }
  };

  const formatFeatureAdoptionData = (
    adoptionReportGraphData: ExtendedAdoptionModule[],
    cohortInsightType: string | null
  ) => {
    const itemStyle = {
      opacity: 0.8,
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowColor: "rgba(0,0,0,0.3)"
    };

    const seriesData: any[] = [];
    const seriesColors: any[] = [];
    adoptionReportGraphData.forEach((moduleData, moduleIndex) => {
      if (moduleData.isActive) {
        const features: any[] = [];
        // Define a minimum bubble size to ensure visibility
        const minBubbleSize = 10; // You can adjust this value as needed
        const sizeScalingFactor = 1.25; // Factor to scale the size of bubbles
        // Bubble Data (Scatter Chart)
        moduleData.features.forEach((featureData, featureIndex) => {
          // Calculate the base size, you can adjust the scaling here
          let baseSize =
            featureData.awareness_ratio <= 10
              ? featureData.awareness_ratio * sizeScalingFactor
              : featureData.awareness_ratio;

          // Ensure that the base size does not fall below the minimum size threshold
          let bubbleSize = Math.max(baseSize, minBubbleSize);

          features.push([
            cohortInsightType === "usage_rate"
              ? featureData.awareness_ratio
              : cohortInsightType === "adoption_rate"
              ? featureData.adoption_ratio
              : featureData.retention_ratio,
            frequencyCategory[featureData.frequency_category],
            bubbleSize, // Use the adjusted bubble size
            toTitleCase(featureData.feature_name),
            bubbleSize <= minBubbleSize ? minBubbleSize : 0, // Set the minimum size flag if needed
            featureData.frequency
          ]);
        });
        seriesData.push({
          name: toTitleCase(moduleData.module),
          type: "scatter",
          itemStyle: itemStyle,
          data: features,
          label: {
            show: false,
            position: "bottom",
            fontSize: 10,
            formatter: function (params: any) {
              return params.data[3];
            }
          }
        });

        // Lines Data
        // moduleData.features.forEach((featureData, featureIndex) => {
        //   seriesData.push({
        //     data: [
        //       [featureData.previous_adoption, featureData.previous_frequency],
        //       [featureData.adoption, featureData.frequency],
        //     ],
        //     type: "line",
        //     color: LineSeriesColorsTheme[moduleIndex]
        //   });
        // });

        seriesColors.push(moduleData.color);
      }
    });

    let { minAvgUsage, maxAvgUsage } = adoptionReportGraphData.reduce(
      (acc, module) =>
        module.features.reduce(
          (featureAcc, feature) => ({
            minAvgUsage: Math.min(
              featureAcc.minAvgUsage,
              feature.adoption !== undefined ? feature.adoption : feature.frequency || 0
            ),
            maxAvgUsage: Math.max(
              featureAcc.maxAvgUsage,
              feature.adoption !== undefined ? feature.adoption : feature.frequency || 0
            )
          }),
          acc
        ),
      { minAvgUsage: 0, maxAvgUsage: 0 }
    );
    // maxAvgUsage = maxAvgUsage - 100;
    // console.log(minAvgUsage, maxAvgUsage);

    setOption({
      ...option,
      tooltip: {
        ...option.tooltip,
        formatter: function (params: any) {
          const usageMessage = `
          <span style="color:${params.color};" class="font-bold capitalize">${params.data[3]} (${
            params.seriesName
          })</span>
            <br>
            ${
              cohortInsightType?.startsWith("adoption")
                ? "Adopted"
                : cohortInsightType === "usage_rate"
                ? "Used"
                : "Retained"
            } ${reportType === "user" ? "users" : "companies"}: <span class="font-bold">${
            params.data[0]
          }%</span><br>
            Usage frequency: 
            <span class="font-bold capitalize">${params.name}</span>
            <br>
            Average count of feature engagements per ${reportType}: <span class="font-bold">${
            params.data[4] !== 0 ? params.data[2] / params.data[4] : params.data[2]
          }</span><br>
            `;
          return `
          ${usageMessage} `;
        }
      },
      series: seriesData as never[],
      color: seriesColors,
      xAxis: {
        ...option.xAxis,
        name: `${toTitleCase(cohortInsightType?.split("_").join(" ") || "Adoption")} (%)`,
        splitLine: {
          ...option.xAxis.splitLine,
          lineStyle: {
            ...option.xAxis.splitLine.lineStyle,
            color: [colorScheme === "dark" ? theme.colors.dark[3] : "#ddd"]
          }
        }
      },
      yAxis: {
        ...option.yAxis,
        nameTextStyle: {
          ...option.xAxis.nameTextStyle,
          padding: isFeatureUsageGraph ? -20 : -25
        },
        splitLine: {
          ...option.yAxis.splitLine,
          lineStyle: {
            ...option.yAxis.splitLine.lineStyle,
            color: [colorScheme === "dark" ? theme.colors.dark[3] : "#ddd"]
          }
        }
      },
      visualMap: [
        {
          ...option.visualMap[0], // Assuming you only have one visualMap
          textStyle: {
            color: colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7]
          },
          min: minAvgUsage,
          max: maxAvgUsage,
          inRange: {
            ...option.visualMap[0].inRange,
            symbolSize: [minAvgUsage, maxAvgUsage]
          },
          outOfRange: {
            ...option.visualMap[0].outOfRange,
            symbolSize: [minAvgUsage, maxAvgUsage]
          }
        }
      ]
    });
    setLoading(false);
  };

  const toggleModules = (value: string) => {
    setAdoptionReportGraphData(
      adoptionReportGraphData.map((item: any) => {
        if (item.module === value) {
          item.isActive = !item.isActive;
        }
        return item;
      })
    );
  };

  const toggleAllModules = (status: boolean) => {
    setAdoptionReportGraphData(
      adoptionReportData.map((item: any) => {
        item.isActive = status;
        return item;
      })
    );
  };

  useEffect(() => {
    formatFeatureAdoptionData(adoptionReportGraphData, cohortInsightType);
    // Initialize min and max to null or initial values as needed
    let minAvgUsage: number | null = null;
    let maxAvgUsage: number | null = null;

    adoptionReportGraphData.forEach((module) => {
      module.features.forEach((feature) => {
        const avgUsage = feature.avg_usage;
        if (minAvgUsage === null || avgUsage < minAvgUsage) {
          minAvgUsage = avgUsage;
        }
        if (maxAvgUsage === null || avgUsage > maxAvgUsage) {
          maxAvgUsage = avgUsage;
        }
      });
    });
  }, [adoptionReportGraphData, cohortInsightType, colorScheme]);

  if (adoptionReportData.length === 0) {
    return (
      <Flex align="center" justify="center" my="lg">
        <Button
          variant="outline"
          onClick={() =>
            navigate(`/env/${activeWorkspace.identifier}/insights/dashboard/feature`, {
              state: { isModalOpen: true }
            })
          }>
          Create Feature Adoption Insight
        </Button>
      </Flex>
    );
  }
  return (
    <div>
      {loading ? (
        <>
          <Center my="xl">
            <Loader size="xs" />
          </Center>
        </>
      ) : (
        <Box>
          <Flex>
            {adoptionReportGraphData.map((item: any) => (
              <Legend
                onClick={() => {
                  toggleModules(item.module);
                }}
                key={item.module}
                text={item.module}
                color={item.color}
                isActive={item.isActive}></Legend>
            ))}
            {adoptionReportGraphData.length > 5 && (
              <Flex>
                <Button
                  td="underline"
                  color={colorScheme === "dark" ? "dark.5" : "gray.7"}
                  variant="subtle"
                  size="xs"
                  onClick={() => {
                    toggleAllModules(true);
                  }}>
                  Select all
                </Button>{" "}
                <Button
                  td="underline"
                  color={colorScheme === "dark" ? "dark.5" : "gray.7"}
                  variant="subtle"
                  size="xs"
                  onClick={() => {
                    toggleAllModules(false);
                  }}>
                  Unselect all
                </Button>{" "}
              </Flex>
            )}
          </Flex>
          <Divider my="md" />
          {option.series && option.series.length > 0 ? (
            <Tooltip.Floating
              label={tooltipText}
              withinPortal
              styles={{
                tooltip: {
                  display: tooltipText.length === 0 ? "none !important" : ""
                }
              }}>
              <Box
                ml={isFeatureUsageGraph ? 15 : 32}
                tabIndex={0} // Make the div focusable
                ref={xAxisLabelRef}>
                <MemoizedEchart
                  echarts={echarts}
                  style={{
                    height: "350px",
                    width: isFeatureUsageGraph ? "auto" : "auto"
                  }}
                  option={option}
                  onEvents={onEvents}
                />
              </Box>
            </Tooltip.Floating>
          ) : (
            <EmptyGraphResults description="Please select the features that you'd like to show."></EmptyGraphResults>
          )}
        </Box>
      )}
    </div>
  );
};

type ChartProps = {
  echarts: EChartsInstance;
  style: CSSProperties;
  option: EChartsOption;
  onEvents: Record<string, Function> | undefined;
};

const MemoizedEchart: React.FC<ChartProps> = React.memo(
  ({ echarts, style, option, onEvents }) => (
    <ReactEChartsCore echarts={echarts} style={style} option={option} onEvents={onEvents} />
  ),
  (prevProps, nextProps) => {
    // Here we can perform a deep equality check to prevent re-render
    // For example when option prop doesn't change structurally
    return isEqual(prevProps.option, nextProps.option);
  }
);
