import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { ContactNotesService } from "@/lib/services/ContactNotesService";
import { Box, Button, Flex, Loader, Text, Timeline } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { NotesCard } from "./NotesCard";
import { RichTextEditor } from "./RichTextEditor";

export interface INotesTab {
  uniqueId: string | undefined;
  type: "user" | "company" | "visitor";
  setNotesCount: React.Dispatch<React.SetStateAction<number>>;
}

export const NotesTab = ({ uniqueId, type, setNotesCount }: INotesTab) => {
  const [toggle, setToggle] = useState(false);
  const [notes, setNotes] = useState<any>([]);
  const [notesLoading, setNotesLoading] = useState(true);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const contactsService = new ContactNotesService();
  const fetchNotes = async () => {
    setNotesLoading(true);
    const response = await contactsService.getNotes(activeWorkspace.id, uniqueId || "", type);
    if (response?.data) {
      setNotes(response.data);
    }
    setNotesLoading(false);
  };

  const saveNote = async (content: string) => {
    const data = {
      body: content,
      contact_id: uniqueId,
      contact_type: type
    };
    const response = await contactsService
      .createNote(activeWorkspace.id, data)
      .then((res) => {
        if (res.data) {
          setNotes([res.data, ...notes]);
          setNotesCount((currentCount) => currentCount + 1);
        }
      })
      .catch((err) => err);
    if (response?.data) {
    }
    setToggle(false);
  };

  const editNote = async (noteId: string | number, content: string) => {
    const data = {
      body: content
    };
    await new ContactNotesService()
      .updateNote(activeWorkspace.id, noteId, data)
      .then((res) => {
        if (res.data) {
          const index = notes.findIndex((note: any) => note.id === noteId);
          const newNotes = [...notes];
          let data = {
            ...newNotes[index],
            body: content,
            updated_at: res.data.updated_at
          };
          newNotes[index] = data;
          setNotes(newNotes);
        }
      })
      .catch();
  };

  const deleteNote = async (noteId: string | number) => {
    await new ContactNotesService()
      .deleteNote(activeWorkspace.id, noteId)
      .then((res) => {
        if (res.data) {
          const index = notes.findIndex((note: any) => note.id === noteId);
          const newNotes = [...notes];
          newNotes.splice(index, 1);
          setNotes(newNotes);
          setNotesCount(newNotes.length);
        }
      })
      .catch();
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  if (notesLoading) {
    return (
      <>
        <Flex align={"center"} justify={"center"} mt="xl" pt="xl">
          <Loader color="gray" size={16} />
        </Flex>
      </>
    );
  }
  return (
    <>
      <Box
        ml={16}
        my={16}
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}>
        <Button onClick={() => setToggle(!toggle)} mt={8} size="xs" variant="outline">
          + Create Note
        </Button>
      </Box>

      <Box ml={16} fz={"sm"}>
        <RichTextEditor
          isEdit={toggle}
          noteId={-1}
          onCancel={(noteId: number) => setToggle(!toggle)}
          onSave={saveNote}
        />
      </Box>
      {notes.length > 0 ? (
        <>
          <Timeline color={"gray.8"} ml={16} mt={32} active={-1} lineWidth={1} bulletSize={0}>
            {notes.map((note: any) => (
              <div key={note.id}>
                <Timeline.Item
                  styles={{
                    itemBullet: {
                      marginLeft: "-5px !important"
                    }
                  }}>
                  <Box ml={24}>
                    <NotesCard
                      onDelete={deleteNote}
                      noteId={note.id}
                      body={note.body}
                      name={note.created_by_user?.full_name}
                      createdAt={note.created_at}
                      updatedAt={note.updated_at}
                      onEdit={editNote}
                    />
                  </Box>
                </Timeline.Item>
              </div>
            ))}
          </Timeline>
        </>
      ) : (
        <>
          {!toggle && (
            <Text align={"center"} justify={"center"}>
              You do not have any notes created.
            </Text>
          )}
        </>
      )}
    </>
  );
};
