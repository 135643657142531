import axios from './JWTService';

export class BillingService {
  overridePrice = (product_id: number, price: number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/v1/billing/paddle/override_price', {
          billable_id: product_id,
          price: price
        })
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  subscriptionDetail = (subscription_id: number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/billing/paddle/subscription_detail/${subscription_id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  restart = (subscription_id: number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/billing/paddle/subscription_detail/${subscription_id}/restart`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  upgrade = (subscription_id: number, plan_id: number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/billing/paddle/subscription_detail/${subscription_id}/upgrade/${plan_id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  slots = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('v1/billing_utility/slots')
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
}
