import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Image,
  Loader,
  SimpleGrid,
  Text,
  Title
} from "@mantine/core";
import Mastercard from "@assets/images/icons/mastercard.png";
import Visa from "@assets/images/icons/visa.png";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { BillingService } from "@/lib/services/BillingService";
import { LOADER_COLOR, PADDLE_SUBSCRIPTION_STATUS } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { LoadingStateProps, SubscriptionInfoProps } from "types/types.d";

import { SettingsHeaderContent } from "../Settings/SettingsHeaderContent/SettingsHeaderContent";
import { Crisp } from "crisp-sdk-web";

export const BillingInformation = ({ subscriptions, fetchOrganizationPlan }: any) => {
  const { activeWorkspace, user, setActiveWorkspace, workspaces, setWorkspaces } =
    useContext(AppLifecycleContext);
  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfoProps>(null);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [restartLoading, setRestartLoading] = useState<LoadingStateProps>("idle");
  const fetchPaddleSubscriptionDetail = async () => {
    setLoading("loading");
    await new BillingService()
      .subscriptionDetail(subscriptions[0].paddle_id)
      .then((res) => {
        if (res.data) {
          setSubscriptionInfo(res.data.response[0]);
        }
      })
      .catch((err) => setSubscriptionInfo(null));
    setLoading("loaded");
  };

  const requestToUpdateBillingEmail = () => {
    Crisp.chat.open();
    Crisp.message.send("text", "Hello, Can you please update my billing email address?");
  };

  const restartSubscription = async () => {
    setRestartLoading("loading");
    await new BillingService()
      .restart(subscriptions[0].paddle_id)
      .then((res) => {
        if (res.data?.response?.subscription_id) {
          toast.success("Subscription restarted successfully.");
          fetchPaddleSubscriptionDetail();
          fetchOrganizationPlan();
          // update current workspace

          let updateWorkspace = { ...activeWorkspace };
          updateWorkspace.organization.subscriptions[0].paddle_status = "active";
          updateWorkspace.organization.subscriptions[0].paused_from = "";
          setActiveWorkspace(updateWorkspace);

          // update remaining workspaces

          let remainingWorkspaces = workspaces.map((wsItem: any) => {
            if (wsItem.organization.id === activeWorkspace.organization.id) {
              wsItem.organization.subscriptions[0].paddle_status = "active";
              wsItem.organization.subscriptions[0].paused_from = "";
            }

            return wsItem;
          });
          setWorkspaces(remainingWorkspaces);
        }
      })
      .catch((err) => {
        toast.error("Unable to restart subscription, please contact support.");
      });

    setRestartLoading("loaded");
  };

  useEffect(() => {
    console.log(subscriptions.length);
    if (subscriptions.length > 0) {
      fetchPaddleSubscriptionDetail();
    }
  }, [subscriptions.length]);
  return (
    <>
      <Title order={4} fw={600} mb="md">
        Billing Information
      </Title>
      <Text mb="md">Your credit card and billing details.</Text>
      <Divider my="md" />
      {loading === "loaded" && subscriptions.length > 0 ? (
        <>
          <SimpleGrid my="sm" cols={2}>
            <Box pt="sm">
              <Text fw={600} mb="md">
                Payment Method
              </Text>
              <Flex align={"center"} mb="md" h={36}>
                {subscriptionInfo?.state !== PADDLE_SUBSCRIPTION_STATUS.DELETED ? (
                  <>
                    <Box py={12} mr={8}>
                      {subscriptionInfo?.payment_information.card_type === "visa" ? (
                        <Box w={36}>
                          <Image width={24} height={24} fit="contain" src={Visa} alt="" />
                        </Box>
                      ) : (
                        <Box w={36}>
                          <Image width={24} height={24} fit="contain" src={Mastercard} alt="" />
                        </Box>
                      )}
                    </Box>
                    <Title order={4} pr="xs">
                      **** **** ****
                    </Title>
                    <Title order={4}>
                      {" "}
                      {subscriptionInfo?.payment_information.last_four_digits}
                    </Title>
                    <Text pl={"sm"}>({subscriptionInfo?.payment_information.expiry_date})</Text>
                  </>
                ) : (
                  <>
                    <Title order={4} fw={600}>
                      No card details found.
                    </Title>
                  </>
                )}
              </Flex>

              <Flex align={"center"}>
                {subscriptionInfo?.state !== PADDLE_SUBSCRIPTION_STATUS.DELETED && (
                  <>
                    <Button
                      mr={"xs"}
                      color="gray.7"
                      onClick={() => {
                        window.open(subscriptionInfo?.update_url, "_blank");
                      }}>
                      Update Credit Card
                    </Button>
                  </>
                )}

                {subscriptionInfo?.paused_from &&
                  [PADDLE_SUBSCRIPTION_STATUS.ACTIVE, PADDLE_SUBSCRIPTION_STATUS.PAUSED].indexOf(
                    subscriptionInfo.state
                  ) >= 0 && (
                    <>
                      <Button
                        disabled={restartLoading === "loading"}
                        onClick={() => {
                          restartSubscription();
                        }}>
                        Restart Subscription
                      </Button>
                    </>
                  )}
              </Flex>
            </Box>
            {activeWorkspace.organization?.billing_email && (
              <>
                <Box pt="sm">
                  <Title order={5} fw={600} mb="xs">
                    Billing Contact
                  </Title>
                  <Text mb="sm">{activeWorkspace.organization.billing_email}</Text>

                  <Text
                    td="underline"
                    className="underline cursor-pointer hover:opacity-80 transition-all ease-in-out "
                    onClick={() => requestToUpdateBillingEmail()}>
                    Request to update
                  </Text>
                </Box>
              </>
            )}
          </SimpleGrid>
        </>
      ) : subscriptions.length === 0 ? (
        <>
          <Flex py={32} align={"center"} justify={"center"}>
            <Text>You do not have any active subscription.</Text>
          </Flex>
        </>
      ) : (
        <>
          <Center h={40}>
            <Loader size="xs" />
          </Center>
        </>
      )}
    </>
  );
};
