import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";

import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { InsightsService } from "@/lib/services/InsightsService";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { convertDateToTimezone } from "@/lib/utils/DateUtility";
import { FC, useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router";
import ReactTooltip from "react-tooltip";
import "./insights_list.scss";
import { InsightTemplateType } from "@/types/types.d";
import { SetupInsightsModal } from "../Setup/SetupInsightsModal";

interface InsightsListProps {
  insightsType: string;
}

export const InsightsList: FC<InsightsListProps> = ({ insightsType = "all" }) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [isLoading, setIsLoading] = useState("idle");
  const [insightsItems, setInsightsItems] = useState<Array<any>>([]);
  const [insightsPage, setInsightsPage] = useState(1);
  const [moreInsightsList, setMoreInsightsList] = useState(true);
  const [sortOrder, setSortOrder] = useState<string | null>("created_at:desc");
  const navigate = useNavigate();
  const [searchInsight, setSearchInsight] = useState("");
  const [debouncedSearchTerm] = useDebouncedValue(searchInsight, 200);
  // modal state
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [insightId, setInsightId] = useState("");
  const [insightType, setInsightType] = useState<InsightTemplateType>();
  // location
  const location = useLocation();
  const isOverviewPage = location.pathname.includes("/insights/dashboard/overview");
  // service calls
  const insightService = new InsightsService();
  const getInsightsList = async () => {
    setIsLoading("loading");
    await insightService
      .getAll(activeWorkspace.id, sortOrder, 1, searchInsight, insightsType)
      .then((res) => {
        if (res.data) {
          if (isOverviewPage) {
            // if on the insight overview page only show product insights
            const filteredInsights = res.data.filter(
              (item: any) =>
                item.type === "onboarding_completion_rate" ||
                item.type === "active_users" ||
                item.type === "power_users" ||
                item.type === "feature_adoption" ||
                item.type === "user_retention" ||
                item.type === "slipping_away_users"
            );
            setInsightsItems(filteredInsights);
          } else {
            setInsightsItems(res.data);
          }
          if (res.data.length < 20) {
            setMoreInsightsList(false);
          }
        }
      })
      .catch((err) => {
        setIsLoading("loaded");
      });
    setIsLoading("loaded");
    ReactTooltip.rebuild();
  };

  const fetchMoreInsights = async () => {
    setIsLoading("loading");
    setInsightsPage(insightsPage + 1);
    insightService
      .getAll(activeWorkspace.id, sortOrder, insightsPage + 1, searchInsight, insightsType)
      .then((res) => {
        if (res.data) {
          if (isOverviewPage) {
            // if on the insight overview page only show product insights
            const filteredInsights = res.data.filter(
              (item: any) =>
                item.type === "onboarding_completion_rate" ||
                item.type === "active_users" ||
                item.type === "power_users" ||
                item.type === "feature_adoption" ||
                item.type === "user_retention" ||
                item.type === "slipping_away_users"
            );
            setInsightsItems((previousInsightItems) => [
              ...previousInsightItems,
              ...filteredInsights
            ]);
          } else {
            setInsightsItems((previousInsightItems) => [...previousInsightItems, ...res.data]);
          }
          if (res.data.length < 20) {
            setMoreInsightsList(false);
          }
        } else setMoreInsightsList(false);
        setIsLoading("loaded");
      })
      .catch((err) => {
        setIsLoading("loaded");
      });
  };

  const deleteInsight = async (insight: any) => {
    insightService
      .delete(activeWorkspace.id, insight.id)
      .then((res) => {
        if (res.data) {
          setInsightsItems(insightsItems.filter((item: any) => item.id !== insight.id));
        }
      })
      .catch((err) => {});
  };

  const removeInsightPrompt = (insight: any) => {
    openConfirmModal({
      title: "Delete insight",
      children: (
        <Text size="sm">
          Are you sure you want to delete your{" "}
          <span className="font-semibold">"{insight.name}"</span> insight?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => deleteInsight(insight)
    });
  };

  const setAsDefaultInsight = (insightId: any, insightType: string) => {
    insightService
      .setAsDefault(activeWorkspace.id, insightId)
      .then((res) => {
        const updatedInsightsList = insightsItems.map((item: any) => {
          // check type of insight. If it is the same as the one being set as default, then set it as default

          if (item.type === insightType) {
            item.is_default = false;
          }

          if (item.id === insightId) {
            item.is_default = true;
          }
          return item;
        });

        setInsightsItems(updatedInsightsList);
      })
      .catch((err) => {});
  };

  const handleEditInsight = (insight: any) => {
    setInsightId(insight.id);
    setInsightType(insight.type);
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    if (!isEditModalOpen) {
      // if modal is closed, then fetch insights list again
      getInsightsList();
    }
  }, [sortOrder, insightsType, debouncedSearchTerm, isEditModalOpen]);

  return (
    <>
      <SetupInsightsModal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        insightId={insightId}
        insightType={insightType}
      />
      <Flex align={"center"} justify={"space-between"} mb={16}>
        <TextInput
          size="sm"
          w={300}
          leftSection={<FontAwesomeIcon icon={regular("search")} />}
          placeholder="Search insight"
          value={searchInsight}
          onChange={(event) => setSearchInsight(event.currentTarget.value)}></TextInput>
        <Select
          size="sm"
          variant="filled"
          onChange={setSortOrder}
          defaultValue={sortOrder}
          data={[
            {
              label: "Recent",
              value: "created_at:desc"
            },
            {
              label: "Name A-Z",
              value: "name:asc"
            },
            {
              label: "Name Z-A",
              value: "name:desc"
            }
          ]}
        />
      </Flex>
      <Box style={{ overflowX: "auto" }}>
        {/* <Box
          style={(theme) => ({
            minWidth: "800px"
          })}> */}
        <Table striped highlightOnHover verticalSpacing={"md"} withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Insight Name</Table.Th>
              <Table.Th>Type</Table.Th>
              <Table.Th>Created</Table.Th>
              <Table.Th>Updated</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {insightsItems.length === 0 && isLoading === "loaded" && (
              <div className="um-insights--list__table-body-row">
                <div className="um-insights--list__table-body-row-no-results">
                  {searchInsight.length > 0 ? (
                    <>
                      There are no insights for your searched query. Try changing your search query.
                    </>
                  ) : (
                    <>You do not have any insights. Please create a new insight.</>
                  )}
                </div>
              </div>
            )}
            {insightsItems &&
              insightsItems.map((item: any) => (
                <Table.Tr key={item.id}>
                  <Table.Td
                    onClick={() => {
                      if (item.type === "attribution") {
                        navigate(
                          `/env/${activeWorkspace.identifier}/insights/dashboard/acquisition?insightId=${item.id}`
                        );
                      }
                    }}>
                    <Flex align={"center"}>
                      <Avatar size={26} color="brand" variant="light" mr="sm">
                        {item.name.substring(0, 1)}
                      </Avatar>
                      <Text>{item.name}</Text>
                      {item.is_default && (
                        <>
                          <Badge ml={8} radius="sm" variant="light">
                            Default
                          </Badge>
                        </>
                      )}
                    </Flex>
                  </Table.Td>

                  <Table.Td>{item.type.replaceAll("_", " ")}</Table.Td>
                  <Table.Td>
                    {convertDateToTimezone(item.created_at, activeWorkspace.timezone)}
                  </Table.Td>

                  <Table.Td>
                    {convertDateToTimezone(item.updated_at, activeWorkspace.timezone)}
                  </Table.Td>
                  <Table.Td>
                    <ActionIcon
                      size={"sm"}
                      mr={8}
                      color="gray.6"
                      data-tip="Set as default"
                      variant="outline"
                      fz={12}
                      onClick={() => {
                        setAsDefaultInsight(item.id, item.type);
                      }}>
                      <FontAwesomeIcon icon={regular("star")} className=" " />
                    </ActionIcon>
                    <ActionIcon
                      size={"sm"}
                      mr={8}
                      color="gray.6"
                      data-tip="Edit "
                      variant="outline"
                      fz={12}
                      onClick={() => {
                        handleEditInsight(item);
                      }}>
                      <FontAwesomeIcon icon={regular("pencil")} className=" " />
                    </ActionIcon>

                    <ActionIcon
                      size={"sm"}
                      mr={8}
                      data-tip="Delete"
                      variant="outline"
                      color={"red"}
                      fz={12}
                      onClick={() => {
                        removeInsightPrompt(item);
                      }}>
                      <FontAwesomeIcon
                        icon={regular("trash")}
                        className=" text-red-500 transform "
                      />
                    </ActionIcon>
                  </Table.Td>
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>

        {isLoading === "loading" ? (
          <div className="um-virtual-table-loader py-4">
            <Oval color={LOADER_COLOR} height={16} width={16} />
          </div>
        ) : (
          moreInsightsList && (
            <div className="flex items-center justify-center flex-grow w-full h-20">
              <Button size="xs" variant="light" onClick={fetchMoreInsights}>
                Load More
              </Button>
            </div>
          )
        )}
        {/* </Box> */}
      </Box>
    </>
  );
};
