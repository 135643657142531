import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { PowerUsersTrendsService } from "@/lib/services/PowerUsersTrendsService";
import { CalendarMonthsRange } from "@/lib/utils/CalendarDatesUtility";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import { DATE_FORMAT } from "@/lib/utils/Constants";
import { DropdownMenu } from "@/ui/components/App/Dropdowns/DropdownMenu";
import { EmptyGraphResults } from "@/ui/components/App/EmptyGraphResults/EmptyGraphResults";
import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import * as echarts from "echarts/core";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { InsightIdType } from "types/types.d";

import { useSegmentStore } from "@/stores/useSegmentStore";
import { BarGraphWrapper } from "@/ui/components/App/EChartsWrappers/BarGraphWrapper";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";
import { Text, useMantineTheme } from "@mantine/core";
import { PowerUsersDetailedView } from "./PowerUsersDetailedView";

export const L30Users: FC<InsightIdType> = ({ insightId, reportType = "user" }) => {
  const [filters] = useSegmentStore((state) => [state.filters]);
  const [isLoading, setIsLoading] = useState("idle");
  const [monthDays, setMonthDays] = useState({
    categories: [],
    series: []
  });
  const { activeWorkspace } = useContext(AppLifecycleContext);
  // const { insightId } = useParams<{
  //   insightId: string;
  // }>();
  const memoizedCalendarDateRange = useMemo(() => CalendarMonthsRange(), [insightId]);
  const [L30Users, setL30Users] = useState<any>({
    categories: [],
    series: []
  });
  // Detailed View state to toggle between detailed view and normal view.
  const [detailedView, setDetailedView] = useState(false);
  // Days Active state to pass to the detailed view component for showing users related to selected day
  const [daysActive, setDaysActive] = useState(0);
  // Period State to be used in this component and to be passed to detailed view component for showing user related to selected time period
  const [period, setPeriod] = useState({
    startDate: format(startOfMonth(subMonths(new Date(), 1)), DATE_FORMAT),
    endDate: format(endOfMonth(subMonths(new Date(), 1)), DATE_FORMAT)
  });

  const getPowerUsersHistogram = async (startDate = period.startDate, endDate = period.endDate) => {
    setIsLoading("loading");
    const powerUsersTrendsService = new PowerUsersTrendsService();
    await powerUsersTrendsService
      .powerUsersHistogram(
        activeWorkspace.id,
        insightId,
        "monthly",
        reportType,
        startDate,
        endDate,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        if (res.data) {
          setL30Users({
            categories: res.data.map((item: any) =>
              item.daysActive == 1 ? "1 day" : `${item.daysActive} days`
            ),
            series: [
              {
                type: "bar",
                name: `Power ${reportType == "user" ? "Users" : "Companies"}`,
                data: res.data.map((item: any) => item.percentage || 0),
                itemStyle: {
                  borderRadius: [4, 4, 0, 0]
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgb(125, 71, 235, 0.7)" },
                  { offset: 0.5, color: "rgb(125, 71, 235, 0.8)" },
                  { offset: 1, color: "rgb(125, 71, 235, 1)" }
                ])
              }
            ]
          });
        }
      });
    setIsLoading("loaded");
  };

  // On clicking any bar, the detailed view will open and daysActive would be set for selected day
  const handleOnClick = (value: any) => {
    const daysActive = Number(value.split(" ")[0]);
    console.log("clicked", daysActive);
    setDaysActive(daysActive);
    setDetailedView(!detailedView);
  };

  useEffect(() => {
    getPowerUsersHistogram();
    // memoizedCalendarDateRange;
  }, [insightId, filters]);

  // On changing the period, the graph will be updated with new data and period state will be updated to be passed to detailed view component
  const onChangeDate = (item: { label: string; value: string }) => {
    const splitDates = item.value.split("_");
    setPeriod({ startDate: splitDates[0], endDate: splitDates[1] });
    getPowerUsersHistogram(period.startDate, period.endDate);
  };

  // =
  return (
    <>
      <InsightSection
        title={` Power ${reportType == "user" ? "Users" : "Companies"} Curve on 30 days interval`}
        description={
          <>
            {reportType == "user" ? "Users'" : "Companies'"} engagement by the total number of days
            they were active in a month, from 1 day out of the month to all 30 (or 28, or 31) days.
          </>
        }
        rightSection={
          <DropdownMenu
            selectedOption={memoizedCalendarDateRange[0]}
            options={memoizedCalendarDateRange}
            onChange={(item: any) => {
              onChangeDate(item);
            }}></DropdownMenu>
        }>
        <div>
          {isLoading === "loaded" ? (
            <>
              {L30Users.series.length > 0 ? (
                <>
                  {/* Detailed View for power users | companies list */}
                  <PowerUsersDetailedView
                    detailedView={detailedView}
                    setDetailedView={setDetailedView}
                    daysActive={daysActive}
                    insightId={insightId}
                    listReportType={"L30_users"}
                    reportType={reportType}
                    startDate={period.startDate}
                    endDate={period.endDate}
                  />
                  <BarGraphWrapper
                    series={L30Users.series}
                    categories={L30Users.categories}
                    showPercentage={true}
                    customTooltipFormatter={(params) => {
                      console.log(params);
                      // return `${params.value} users have performed ${params.name} events`
                      return `<span class="font-bold">${params[0].seriesName} </span> <br />
                                  <span class="font-bold">${params[0].data}%</span> of ${
                        reportType == "user" ? "users" : "companies"
                      } have been active for <span class="font-bold">${
                        params[0].axisValue
                      }</span>. <br />
                                  `;
                    }}
                    onClick={(params: any) => handleOnClick(params.name)}
                    yAxisName="Percentage"
                  />
                  <Text ta="center" py="sm">
                    Total active days within a month
                  </Text>
                </>
              ) : (
                <>
                  <EmptyGraphResults />
                </>
              )}
            </>
          ) : (
            <BarChartSkeleton />
          )}
        </div>
      </InsightSection>
    </>
  );
};
