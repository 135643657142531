import { GeneralAPIKeyAndServerToken } from "@/ui/pages/Settings/WorkspaceSettings/General/GeneralAPIKeyAndServerToken";
import { Accordion, Avatar, Box, Button, Text, Image, Flex, Title } from "@mantine/core";
import BubblePluginImage from "@assets/images/icons/integrations/bubble-usermaven-plugin.png";
export const BubbleInstallation = () => {
  return (
    <>
      <Title order={4} my="md">
        Credentials
      </Title>{" "}
      <GeneralAPIKeyAndServerToken withBorder={true} withHeader={false} apiKeyOnly={false} />
      <Accordion mt={32} variant="separated" defaultValue="http_event">
        <Accordion.Item value="http_event">
          <Accordion.Control>
            <Flex align="center">
              <Avatar size="md" color="brand" radius="xl" mr="sm">
                1{" "}
              </Avatar>
              <Text className="flex-grow" fz="md" fw="bold">
                Install Usermaven Bubble Plugin
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Text fz="sm" mb="sm">
              Within your Bubble.io app, navigate to the Plugins tab and click on{" "}
              <Text fz="sm" fw={500} span>
                Add plugins
              </Text>
              . Search for{" "}
              <Text fz="sm" fw={500} span>
                Usermaven
              </Text>{" "}
              and proceed to install the plugin.
            </Text>
            <Image src={BubblePluginImage} mb={"md"} radius={"md"} />
            <Flex my={"lg"} align={"center"}>
              <Avatar size="md" color="brand" radius="xl" mr="sm">
                2{" "}
              </Avatar>
              <Text className="flex-grow" fz="md" fw="bold">
                Follow Installation Guide
              </Text>
            </Flex>

            <Text my="md" fz="sm">
              After installing the Plugin, copy the{" "}
              <Text fz="sm" fw={500} span>
                API KEY
              </Text>{" "}
              from above and paste into the API key section. Follow the below guide to complete the
              installation.
            </Text>

            <Button
              variant="outline"
              onClick={() => {
                window.open(
                  "https://usermaven.com/docs/integrations/bubble#2-configure-tracking-setup-for-bubbleio-plugin",
                  "_blank"
                );
              }}>
              View Installation Guide
            </Button>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
