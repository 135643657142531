import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Alert,
  Avatar,
  Text,
  Code,
  useMantineTheme,
  Box,
  Flex,
  Anchor,
  Title
} from "@mantine/core";
// import { Prism } from "@mantine/prism";
// import duotoneDark from "prism-react-renderer/themes/dracula";
// import githubTheme from "prism-react-renderer/themes/github";
import { useContext } from "react";
import { GeneralAPIKeyAndServerToken } from "@/ui/pages/Settings/WorkspaceSettings/General/GeneralAPIKeyAndServerToken";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { CodeHighlight } from "@mantine/code-highlight";
export const TrackingPixelHTTP = () => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const EVENT_URL = `https://events.usermaven.com/api/v1/s2s/event?token=${activeWorkspace.identifier}.${activeWorkspace.server_token}`;
  return (
    <>
      <Title order={4} my="md">
        Credentials
      </Title>{" "}
      <GeneralAPIKeyAndServerToken withBorder={true} withHeader={false} />
      <Accordion mt={32} variant="separated" defaultValue="http_event">
        <Accordion.Item value="http_event">
          <Accordion.Control>
            <Flex align="center">
              <Avatar size="md" color="brand" radius="xl" mr="sm">
                1{" "}
              </Avatar>
              <Text className="flex-grow" fz="md" fw="bold">
                Send HTTP requests to Usermaven
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <>
              <Text className="py-4" fz="sm" mb="sm">
                To send the event to Usermaven, you will have to send the POST request to the
                following endpoint.
              </Text>
              <CodeHighlight language="bash" code={EVENT_URL} />

              <Text fz="sm" my="sm">
                The body is JSON object.
              </Text>

              <CodeHighlight
                language="json"
                code={`{
    "api_key": "${activeWorkspace.identifier}",
    "event_type": "user_identify", // once the user signs in to the platform. 
    "ids": {},
    "user": {
      // Required attributes
      "anonymous_id": "random_id" , // Ideally, use the __eventn_id from the cookies to stitch the user activity.
      "id": "lzL24K3kYw",    // Unique ID for the user in database.
      "email": "user@domain.com", // Email address for the user.
      "created_at": "2021-01-20T09:55:35",   // DateTime string in your system that represents when the user first signed up.
      
      // Recommended attributes
      // First name and last name are shown on people pages.
      "first_name": "John",
      "last_name": "Smith",
      
      // Optional attributes (you can name attributes what you wish)
      "custom": {
      "plan_name": "premium"
      },
    },
 
   // Send company level attributes
   "company": {
    "id": "65f02acc-e104-45d9-8199-1491332d8454",
    "name": "azhar",
    "created_at": "2022-02-15T17:25:08",
    "custom": {
        "plan": "premium"
      }
    },
    "screen_resolution": "0",
    "src": "http"
}`}
              />

              <Text className="py-4" fz="sm" my="sm">
                Below is an example using CURL.
              </Text>
              <CodeHighlight
                language="bash"
                code={`curl -XPOST 'https://events.usermaven.com/api/v1/s2s/event?token=${activeWorkspace.identifier}.${activeWorkspace.server_token}' -H "Content-type: application/json" -d '{
    "api_key": "${activeWorkspace.identifier}", 
    "event_type": "user_identify", 
    "ids": {},
    "user": {
      "anonymous_id": "random_id",
      "id": "lzL24K3kYw",
      "email": "user@domain.com",
      "created_at": "2021-01-20T09:55:35",
      "first_name": "John",
      "last_name": "Smith",
      "custom": {
        "plan_name": "premium"
      }
    },
 
    "company": {
      "id": "65f02acc-e104-45d9-8199-1491332d8454",
      "name": "azhar",
      "created_at": "2022-02-15T17:25:08",
      "custom": {
        "plan": "premium"
      }
    },
    "screen_resolution": "0",
    "src": "http"
}'`}
              />

              <Alert
                mt={"sm"}
                icon={<FontAwesomeIcon className="text-2xl" icon={regular("info-circle")} />}>
                <span className="font-bold">Note:</span> <Code>user_identify</Code> event type is
                required for the signed in users. Based on the <Code>user_identify</Code> event,
                your users will be available in our People view.
                <br></br>
                <br></br>For sending custom events to Usermaven, please{" "}
                <Anchor
                  fz="sm"
                  fw={500}
                  href="https://usermaven.com/docs/integrations/event-api#sending-custom-event-attributes"
                  target={"_blank"}>
                  follow this guide.
                </Anchor>
              </Alert>
            </>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
