import { Box, Center, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { format } from "date-fns";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { UsersCRMService } from "@/lib/services/UsersCRMService";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import cloneDeep from "lodash.clonedeep";
import { useContext, useEffect, useState } from "react";
import { LoadingStateProps } from "types/types.d";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { useMediaQuery } from "@mantine/hooks";
import { MOBILE_BREAKPOINT_QUERY } from "../../../../../lib/utils/Constants";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

export const UsersHistogram = ({ query }: any) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  let DEFAULT_CHART_OPTIONS = {
    color: ["#7D47EB"],
    title: {
      text: ""
    },
    tooltip: {
      borderColor: colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2],
      backgroundColor: colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: "#595c5f"
      },
      trigger: "axis",
      axisPointer: {
        type: "line",
        label: {
          formatter: (params: any) => {
            return format(new Date(params.value), "eee, MMMM dd, yyyy");
          }
        }
      }
    },
    legend: {
      show: false,
      data: ["Users"]
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      left: "5%",
      right: "4%",
      bottom: "5%",
      containLabel: true
    },
    xAxis: [
      {
        type: "time",
        axisLine: {
          show: false
        },
        axisLabel: {
          fontSize: 10,
          formatter: "{MMM} {dd}",
          axisPointer: {
            show: false
          },
          margin: 14
        },
        splitLine: {
          show: false
        },
        axisTick: {
          splitNumber: 1
        }
      }
    ],
    yAxis: [
      {
        min: 0,
        type: "value",
        splitLine: true,
        alignTicks: true,
        axisTick: {
          show: true
        }
      }
    ],
    series: [
      {
        name: "Users",
        type: "line",
        smooth: false,
        lineStyle: {
          width: 2
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgb(125, 71, 235, 0.6)"
            },
            {
              offset: 1,
              color: "rgba(125, 71, 235, 0.01)"
            }
          ])
        },
        emphasis: {
          focus: "series"
        },
        data: []
      }
    ],
    animationDuration: 400
  };

  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [histogramLoading, setHistogramLoading] = useState<LoadingStateProps>("idle");
  const [usersHistogram, setUsersHistogram] = useState<any>(DEFAULT_CHART_OPTIONS);
  const fetchUsersHistogram = async () => {
    setHistogramLoading("loading");
    await new UsersCRMService()
      .usersHistogram(activeWorkspace.id, query)
      .then((res) => {
        usersHistogram.series[0].data = res.data.map((item: any) => [item.date, item.count]);
        const itemCountList = res.data.map((item: any) => item.count);
        const minValue = Math.min(...itemCountList);

        usersHistogram.yAxis[0] = {
          ...usersHistogram.yAxis[0],
          min: minValue
        };

        if (isMobileView) {
          usersHistogram.yAxis[0] = {
            ...usersHistogram.yAxis[0],
            splitNumber: 3
          };
        } else {
          // Remove splitNumber from yAxis
          const { splitNumber, ...rest } = usersHistogram.yAxis[0];
          usersHistogram.yAxis[0] = {
            ...rest
          };
        }

        setUsersHistogram(usersHistogram);
      })
      .catch((err) => {
        if (err?.response?.status === 500 || err?.response?.status === 422) {
          setUsersHistogram([{ id: "Users", color: "#7D47EB", data: [] }]);
        }
      });
    setHistogramLoading("loaded");
  };

  const getMinYScale = () => {
    return Math.min(...usersHistogram[0].data.map((item: any) => item.y));
  };
  const getMaxYScale = () => {
    return Math.max(...usersHistogram[0].data.map((item: any) => item.y));
  };

  useEffect(() => {
    if (isMobileView !== undefined) {
      fetchUsersHistogram();
    }
  }, [activeWorkspace.id, query, isMobileView]);

  useEffect(() => {
    const deepCopy = cloneDeep(usersHistogram);
    deepCopy.tooltip.borderColor =
      colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2];
    deepCopy.tooltip.backgroundColor = colorScheme === "dark" ? theme.colors.dark[9] : "#fff";
    deepCopy.tooltip.textStyle.color = colorScheme === "dark" ? "#A6A7AB" : "#595c5f";
    setUsersHistogram(deepCopy);
  }, [colorScheme]);

  console.log(usersHistogram);
  return (
    <>
      {histogramLoading === "loaded" ? (
        <>
          {usersHistogram.series[0].data.length === 0 ? (
            <>
              <NoResults text={"There is no data available for users."} heading={"No Results"} />
            </>
          ) : (
            <>
              <Box h={"100%"} w={"100%"}>
                <ReactEChartsCore echarts={echarts} option={usersHistogram} />
              </Box>
            </>
          )}
        </>
      ) : (
        <Center my="md" h={'300px'}>
          <BarChartSkeleton />
        </Center>
      )}
    </>
  );
};
