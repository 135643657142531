import { ActionIcon, Box, Flex, Group, Stack, Text, Tooltip, useMantineTheme } from "@mantine/core";
import Chart from "@/ui/pages/MavenAi/components/Assistant/components/Chart";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const ChatRichMessage = ({
  data,
  isUser = false,
  onRate,
  username
}: {
  data: {
    text?: string;
    table_data?: Record<string, Record<string, string | number>>;
    chart_data?: {
      data: string[];
      values: number[];
    };
  };
  isUser?: boolean;
  onRate?: () => void;
  username?: string;
}) => {
  const theme = useGlobalMantineTheme();

  const tableData = useMemo(() => {
    if (data?.table_data) {
      // Check if the table data is not empty
      if (Object.keys(data?.table_data).length === 0 && data?.table_data.constructor === Object) {
        return undefined;
      }
    }

    return data.table_data;
  }, [data.table_data]);

  return (
    <Flex
      align={"center"}
      justify={isUser ? "flex-end" : "flex-start"}
      sx={{
        marginBottom: "16px"
      }}>
      <Box>
        <Stack
          sx={{
            background: isUser
              ? "#464DEB"
              : theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : "#FFFFFF",
            color: isUser ? "#FFFFFF" : theme.colorScheme === "dark" ? "#C1C2C5" : "#01002a",
            borderRadius: "4px",
            padding: "16px",
            boxShadow: "0px 4px 4px 0px #0000001A",
            maxWidth: "100%",
            width: data.chart_data || data.table_data ? "55rem" : "auto",
            textAlign: "left",
            position: "relative",
            paddingBottom: "32px"
          }}>
          {username && (
            <Box
              sx={{
                position: "absolute",
                bottom: "-16px",
                right: "16px",
                fontSize: "10px",
                fontWeight: 500,
                background: isUser
                  ? "#161b85"
                  : theme.colorScheme === "dark"
                  ? "#1E1E1E"
                  : "#FFFFFF",
                padding: "4px 8px",
                borderRadius: "999px",
                border: "1px solid",
                borderColor: isUser
                  ? "#161b85"
                  : theme.colorScheme === "dark"
                  ? "#1E1E1E"
                  : "#C1C2C5",
                width: "max-content"
              }}>
              ~ {username}
            </Box>
          )}

          {data.text && (
            <Group gap={4}>
              <Text
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  wordBreak: "break-word",
                  whiteSpace: "normal",

                  p: {
                    fontSize: "14px"
                  },

                  b: {
                    fontWeight: 500
                  },

                  i: {
                    fontStyle: "italic"
                  },

                  u: {
                    textDecoration: "underline"
                  },

                  ul: {
                    listStyleType: "disc",
                    paddingLeft: "20px",
                    marginTop: "8px"
                  },

                  ol: {
                    listStyleType: "decimal",
                    paddingLeft: "20px",
                    marginTop: "8px"
                  },

                  li: {
                    marginBottom: "8px",
                    fontSize: "14px"
                  },

                  blockquote: {
                    borderLeft: "4px solid #464DEB",
                    paddingLeft: "16px",
                    margin: "16px 0"
                  },

                  code: {
                    background: theme.colorScheme === "dark" ? "#1E1E1E" : "#F9F9F9",
                    padding: "4px",
                    borderRadius: "4px"
                  }
                }}
                dangerouslySetInnerHTML={{ __html: data.text }}></Text>
            </Group>
          )}

          {(data?.chart_data?.values || tableData) && (
            <Chart chartData={data?.chart_data} tableData={tableData} />
          )}
        </Stack>
        {!isUser && (
          <Flex direction={"row-reverse"} mt={"sm"}>
            <Group gap={4}>
              <Tooltip label={"Bad Response"} position={"bottom"}>
                <ActionIcon
                  variant={"subtle"}
                  color={"gray.6"}
                  onClick={() => {
                    onRate && onRate();
                  }}>
                  <FontAwesomeIcon flip={"horizontal"} icon={regular("thumbs-down")} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default ChatRichMessage;
