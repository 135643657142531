import { WorkspaceMemberService } from "@/lib/services/WorkspaceMemberService"
import { WorkspaceService } from "@/lib/services/WorkspaceService"
import { TOAST_MESSAGES, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants"
import { ThirdPartyTracking } from "@/lib/utils/ThirdPartyTracking"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { EditWorkspaceMemberType } from "types/types.d"
// import { Button } from "../../Common/Button/Button"
import { Button, Input, Modal, useMantineTheme } from "@mantine/core"
import Icon from "../../Common/Icon/Icon"
import { Label } from "../../Common/Label/Label"
import { ValidationLabel } from "../../Common/ValidationLabel/ValidationLabel"
import "./modal.scss"
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
interface IFormInputs {
  email: string
  role: string
}
export interface TeamMemberInviteModalProps {
  /**
   * Button contents
   */
  title?: string
  /**
   * Active Workspace ID
   */
  workspaceId: string
  /**
   * Optional click handler
   */
  open: boolean
  isEdit?: boolean
  editMemberDetails?: EditWorkspaceMemberType

  closeModal: () => void
  addTeamMember: (member: any) => void
  updateTeamMember?: (member: any) => void
}
export const TeamMemberInviteModal: React.FC<TeamMemberInviteModalProps> = ({
  open,
  closeModal,
  addTeamMember,
  isEdit = false,
  workspaceId,
  ...props
}) => {
  console.log(open)
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    register,
    reset,
  } = useForm<IFormInputs>({
    defaultValues: {
      email: props.editMemberDetails?.email ? props.editMemberDetails?.email : "",
      role:
        props.editMemberDetails?.role === undefined
          ? WORKSPACE_MEMBER_ROLES.ADMINISTRATOR
          : props.editMemberDetails?.role,
    },
  })
  const [loading, setLoading] = useState("idle")

  useEffect(() => {
    console.log("Reset TeamMemberForm")
    reset({
      email: props.editMemberDetails?.email ? props.editMemberDetails?.email : "",
      role:
        props.editMemberDetails?.role === undefined
          ? WORKSPACE_MEMBER_ROLES.ADMINISTRATOR
          : props.editMemberDetails?.role,
    })
  }, [isEdit, open])

  const onSubmit = (data: any) => {
    console.log(data)
    if (isEdit) {
      editTeamMember(data)
    } else {
      addNewTeamMember(data)
    }
  }

  const addNewTeamMember = async (data: any) => {
    console.log("Add new team member")
    console.log(data)
    const workspace_service = new WorkspaceService()
    setLoading("loading")
    await workspace_service
      .inviteMember(workspaceId, data)
      .then(res => {
        console.log(res)
        addTeamMember(res.data)
        toast.success(TOAST_MESSAGES.TEAM_MEMBER_INVITED_TO_WORKSPACE)

        // fire track event
        const TPService = new ThirdPartyTracking()
        TPService.track("invited_member")
        closeModal()
      })
      .catch(err => {
        console.log(err)
        if (err && err?.response?.status && err.response.status === 422) {
          if (
            err?.response?.data.hasOwnProperty("detail") &&
            typeof err.response.data.detail === "string"
          ) {
            toast.error(err.response.data.detail)
          }
        }
      })

    setLoading("loaded")
  }

  const editTeamMember = async (data: any) => {
    console.log("Edit team member", data, props.editMemberDetails)
    const wsMemberService = new WorkspaceMemberService()

    setLoading("loading")
    await wsMemberService
      .updateRole(props.editMemberDetails?.id, data.role)
      .then(res => {
        console.log(res)
        // Null assertion as TS raise an error due to optional function.
        // REF: https://github.com/microsoft/TypeScript/issues/31609#issuecomment-496204436
        props.updateTeamMember!(res.data)
        toast.success(TOAST_MESSAGES.TEAM_MEMBER_ROLE_CHANGED)
        closeModal()
      })
      .catch(err => {
        console.log(err)
        if (err && err?.response?.status && err.response.status === 422) {
          if (
            err?.response?.data.hasOwnProperty("detail") &&
            typeof err.response.data.detail === "string"
          ) {
            toast.error(err.response.data.detail)
          }
        }
      })

    setLoading("loaded")
  }
  const teamMemberRole = watch(
    "role",
    props.editMemberDetails?.role || WORKSPACE_MEMBER_ROLES.ADMINISTRATOR
  )

  const theme = useGlobalMantineTheme()
  console.log("TeamMember Invite Modal Role:", teamMemberRole, props.editMemberDetails?.role)
  return (
    <>
      <Modal
        opened={open}
        onClose={() => closeModal()}
        size={"540px"}
        title={
          isEdit ? (
            <h2 className="font-medium">Update Team Member</h2>
          ) : (
            <h2 className="font-medium">Add Team Member</h2>
          )
        }
      >
        <p>Invite a team member to access this project in Usermaven.</p>
        <div className="pt-4">
          <div className="um-modal--content--form">
            <form onSubmit={handleSubmit(onSubmit)} id="teamMemberInviteModal">
              <div className="um-modal--content--form--input">
                {/* <Label bold required size="small" text="Email" /> */}

                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <Input.Wrapper size="xs" id={"workEmail"} label="Work Email" required>
                      <Input
                        mt={4}
                        value={value}
                        onChange={onChange}
                        disabled={isEdit}
                        defaultValue={props.editMemberDetails?.email}
                        placeholder="carl@usermaven.com"
                        invalid={(invalid || error) === true}
                      />
                    </Input.Wrapper>
                  )}
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, pattern: /^\S+@\S+$/ }}
                />

                {errors.email?.type === "required" && (
                  <ValidationLabel
                    validationType="error"
                    text="Email is required"
                    size="small"
                    customClasses="pt-2"
                  ></ValidationLabel>
                )}
                {errors.email?.type === "pattern" && (
                  <ValidationLabel
                    validationType="error"
                    text="Please enter a valid email address"
                    size="small"
                    customClasses="pt-2"
                  ></ValidationLabel>
                )}
              </div>
              <div className="um-modal--content--form--input mb-0">
                <Label bold required size="small" text="Role" />
                <div className="um-modal--team-member--radio">
                  <input
                    type="radio"
                    id="administrator"
                    value="administrator"
                    {...register("role")}
                  />
                  <label
                    htmlFor="administrator"
                    className={
                      teamMemberRole === WORKSPACE_MEMBER_ROLES.ADMINISTRATOR
                        ? "um-modal--team-member--radio--active"
                        : ""
                    }
                  >
                    <div className="um-modal--team-member--radio--content">
                      <h3>Administrator</h3>
                      <p>
                        Full account access, including billing, plan information, &amp; team
                        management.
                      </p>
                    </div>
                    {teamMemberRole === WORKSPACE_MEMBER_ROLES.ADMINISTRATOR && (
                      <Icon icon="Check" height="16"></Icon>
                    )}
                  </label>

                  <input type="radio" id="manager" {...register("role")} value="manager" />
                  <label
                    htmlFor="manager"
                    className={
                      teamMemberRole === WORKSPACE_MEMBER_ROLES.MANAGER
                        ? "um-modal--team-member--radio--active"
                        : ""
                    }
                  >
                    <div className="um-modal--team-member--radio--content">
                      <h3>Manager</h3>
                      <p>
                        Full control of workspaces. No access to billing, plan information &amp;
                        team management.
                      </p>
                    </div>
                    {teamMemberRole === WORKSPACE_MEMBER_ROLES.MANAGER && (
                      <Icon icon="Check"></Icon>
                    )}
                  </label>

                  <input type="radio" id="author" {...register("role")} value="author" />
                  {/* <label
                    htmlFor="author"
                    className={
                      teamMemberRole === WORKSPACE_MEMBER_ROLES.AUTHOR
                        ? "um-modal--team-member--radio--active"
                        : ""
                    }
                  >
                    <div className="um-modal--team-member--radio--content">
                      <h3>Author</h3>
                      <p>
                        Full access for creating, editing, saving insights. No billing, plan
                        management &amp; team management, or importing of new data.
                      </p>
                    </div>
                    {teamMemberRole === WORKSPACE_MEMBER_ROLES.AUTHOR && <Icon icon="Check"></Icon>}
                  </label> */}
                  <input type="radio" id="viewer" {...register("role")} value="viewer" />
                  <label
                    htmlFor="viewer"
                    className={
                      teamMemberRole === WORKSPACE_MEMBER_ROLES.VIEWER
                        ? "um-modal--team-member--radio--active"
                        : ""
                    }
                  >
                    <div className="um-modal--team-member--radio--content">
                      <h3>Viewer</h3>
                      <p>
                        Read-only access of the workspace. No editing, saving exporting of data,
                        team member management or access to billing or plan information.
                      </p>
                    </div>
                    {teamMemberRole === WORKSPACE_MEMBER_ROLES.VIEWER && <Icon icon="Check"></Icon>}
                  </label>
                </div>
              </div>
              <div className="um-modal--content--form--actions">
                <div className="um-modal--content--form--actions--info"></div>
                <div className="um-modal--content--form--actions--buttons">
                  <Button
                    disabled={loading === "loading"}
                    loading={loading === "loading"}
                    type="submit"
                  >
                    {isEdit ? "Update" : "Invite"}
                  </Button>
                  <Button
                    variant="subtle"
                    color={theme.colorScheme === "dark" ? "dark.2" : "gray.9"}
                    type="button"
                    onClick={(e: any) => {
                      e.stopPropagation()
                      closeModal()
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}
