/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import { GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent } from "echarts/components";
import * as echarts from "echarts/core";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import {
  GenericTooltip,
  GenericTooltipBar,
  getDateFormatFromPeriod,
  getDateWithZonedTimeToUTC,
  xAxisLabelFormatter
} from "@/lib/utils/EChartsTooltipUtility";
import { useWebFiltersStore } from "@/stores/useWebFiltersStore";
import { purple } from "@/style/Colors";
import { Box, Center, CloseButton, Divider, Flex, Modal, Paper, Text } from "@mantine/core";
import { format, parseISO, startOfMonth, startOfWeek } from "date-fns";
import cloneDeep from "lodash.clonedeep";
import { LoadingStateProps } from "types/types.d";
import { BarGraphWrapper } from "../../EChartsWrappers/BarGraphWrapper";
import { NoResults } from "../../NoResults/NoResults";
import { getRangeByDateAndInterval } from "../Filters/FiltersUtility";
import { ITimeSeriesDataMetric } from "../WebAnalyticsOverview";
import { snakeCaseToSimpleString, truncateText } from "@/lib/utils/StringUtility";
import { Note } from "@/stores/useAnnotationNotesStore";
import { groupBy } from "lodash";
import { formatToMidnightUTC } from "@/lib/utils/DateUtility";
import { NoteVisibilityEnum } from "@/ui/components/Common/Notes/NotesModel";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

type timeSeriesDataObject = {
  name: string;
  data: ITimeSeriesDataMetric;
};

type Props = {
  query: any;
  setQuery: any;
  chartType: string;
  firstSeries: timeSeriesDataObject;
  secondSeries: timeSeriesDataObject;
  loading: LoadingStateProps;
  notes: Note[];
  dashboardUrl?: string;
  colorScheme?: string;
};

const GenericMultiSeriesLineAndBarGraphWrapper = ({
                                                    query,
                                                    setQuery,
                                                    chartType,
                                                    firstSeries,
                                                    secondSeries,
                                                    loading,
                                                    notes,
                                                    dashboardUrl,
                                                    colorScheme
                                                  }: Props) => {
  const theme = useGlobalMantineTheme();
  const [hasResults, setHasResults] = useState<boolean>(true);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [toggleLineFirstSeries, setToggleLineFirstSeries] = useState(true);
  const [toggleLineSecondSeries, setToggleLineSecondSeries] = useState(true);
  const [lineGraph, setLineGraph] = useState<any>({
    animationDuration: 500,
    color: [purple],
    title: {
      text: ""
    },
    notMerge: true,
    tooltip: {
      borderColor: colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2],
      backgroundColor: colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
      },
      trigger: "axis",
      axisPointer: {
        type: "line"
      }
      // formatter: GenericTooltip,
    },
    legend: {
      show: false,
      data: []
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      top: "7%",
      left: "0%",
      right: "3%",
      bottom: "5%",
      containLabel: true
    },
    xAxis: [
      {
        type: "time",
        axisLine: {
          show: false
        },
        axisLabel: {
          formatter: "{MMM} {dd}",
          fontSize: 10,
          axisPointer: {
            show: false
          },
          margin: 14
        },
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        min: 0,
        max: function(value: any) {
          return Math.floor(value.max * 1.4).toString();
        },
        type: "value",
        splitLine: true,
        alignTicks: true,
        axisTick: {
          show: true,
          splitNumber: 4
        }
      }
    ],
    series: [],
    replaceMerge: ["series"]
  });
  const [barGraph, setBarGraph] = useState<any>({
    categories: [],
    series: []
  });
  const [setDateLabel] = useWebFiltersStore((state) => [state.setDateLabel]);
  const [toggleBarFirstSeries, setToggleBarFirstSeries] = useState<boolean>(true);
  const [toggleBarSecondSeries, setToggleBarSecondSeries] = useState<boolean>(true);

  const [markPointAnnotations, setMarkPointAnnotations] = useState<Note[]>([]);
  const [annotationPortalOpened, setAnnotationPortalOpened] = useState(false);
  const [portalPosition, setPortalPosition] = useState({ x: 0, y: 0 });

  const checkIsSharedDashboard = (url: string): boolean =>
    url.split("/")[1] === "share" && url.split("/").pop() === "web";
  const isSharedDashboard = dashboardUrl && checkIsSharedDashboard(dashboardUrl);

  // Function to get the start of the period (week or month)
  const getPeriodStartDate = (date: Date, period: string): Date => {
    if (period === "week") {
      return startOfWeek(date, { weekStartsOn: 1 }); // Adjust weekStartsOn as per your locale
    } else if (period === "month") {
      return startOfMonth(date);
    }
    return date; // Default to day if period is not week or month
  };

  let annotationsGroupedData = groupBy(
    notes.filter((note) => {
      if (isSharedDashboard) {
        // If isSharedDashboard is true, show notes with visibility "All Users" and on_shareable_dashboard true,
        // and hide_note is false
        return (
          note.visibility === NoteVisibilityEnum.AllUsers &&
          note.on_shareable_dashboard &&
          !note.hide_note
        );
      } else {
        // If isSharedDashboard is false, show all notes that are not hidden
        return !note.hide_note;
      }
    }),
    (item) => format(getPeriodStartDate(new Date(item.date), query.period), "yyyy-MM-dd")
  );

  const [annotationsGroupedDataState, setAnnotationsGroupedDataState] =
    useState<Record<string, Note[]>>(annotationsGroupedData);

  const processGraphData = async () => {
    if (firstSeries.data.histogram.length === 0 && secondSeries.data.histogram.length === 0) {
      setHasResults(false);
    } else {
      setHasResults(true);
    }

    storeLineGraphData(firstSeries, secondSeries);
    storeBarGraph(firstSeries, secondSeries);
  };

  const handleGraphOnClick = (params: any) => {
    if (params.componentType === "markPoint") {
      // show annotations modal
      const datePoint = params.data.name.split("_")[1];
      const annotations = annotationsGroupedDataState && annotationsGroupedDataState[datePoint];
      setMarkPointAnnotations(annotations);

      // Get cursor position
      const cursorPositionX = params.event.offsetX;
      const cursorPositionY = params.event.offsetY;

      setPortalPosition({
        x: cursorPositionX > 1000 ? cursorPositionX - 50 : cursorPositionX - 400,
        y: cursorPositionY + 50
      });
      setAnnotationPortalOpened(true);
    } else {
      // bar drill down
      if (query.period !== "hour" && query.period !== "live") {
        const dataPointDate =
          params.componentSubType === "bar"
            ? params.data.date
            : formatToMidnightUTC(params.data[0]);
        const { from_date, to_date } = getRangeByDateAndInterval(dataPointDate, query.period);
        setQuery({
          ...query,
          period: query.period === "day" ? "hour" : "day",
          from_date: from_date,
          to_date: to_date
        });
        if (query.period === "day") {
          setDateLabel(format(new Date(from_date), "MMM d, yyyy"));
        } else {
          setDateLabel(
            `${format(new Date(from_date), "MMM d")} - ${format(new Date(to_date), "MMM d")} `
          );
        }
      }
    }
  };

  const storeBarGraph = (firstSeries: timeSeriesDataObject, secondSeries: timeSeriesDataObject) => {
    const { period } = query;
    const firstSeriesName = snakeCaseToSimpleString(firstSeries.name);
    const secondSeriesName = snakeCaseToSimpleString(secondSeries.name);

    let categories = firstSeries.data.histogram.map((item: any) => {
      return getDateWithZonedTimeToUTC(item.date, query.period);
    });

    const completeData = firstSeries.data.histogram;

    if (query.period === "week" || query.period === "month") {
      const mappedData: Record<string, any[]> = Object.fromEntries(
        Object.entries(annotationsGroupedData).map(([date, data]) => [
          completeData.reduce(
            (a, b) =>
              Math.abs(new Date(b.date).getTime() - new Date(date).getTime()) <
              Math.abs(new Date(a).getTime() - new Date(date).getTime())
                ? b.date
                : a,
            completeData[0].date
          ),
          data
        ])
      );
      annotationsGroupedData = mappedData;
      setAnnotationsGroupedDataState(mappedData);
    }

    const markPoints = completeData
      .map((item: any) => {
        const matchingData = annotationsGroupedData[item.date];
        if (matchingData) {
          const markPointLabel =
            matchingData.length > 1 ? `${matchingData.length} Notes` : "1 Note";
          return {
            coord: [
              getDateWithZonedTimeToUTC(item.date, query.period),
              parseInt((item.count * 1.18).toFixed(0))
            ],
            name: "Annotation_" + item.date,
            symbolSize: 25,
            itemStyle: {
              color: `rgb(125, 71, 235, 0.6)`,
              borderColor: "#7D47EB",
              borderWidth: 1
            },
            label: {
              show: true,
              formatter: `${markPointLabel}`,
              position: "top",
              color: colorScheme === "dark" ? theme.colors.dark[3] : "#242424",
              backgroundColor: "transparent"
            }
          };
        }
        return null; // If no matching data is found
      })
      .filter(Boolean); // Remove null entries

    const markLines = completeData
      .map((item: any) => {
        const matchingData = annotationsGroupedData[item.date];
        if (matchingData && matchingData.length >= 1) {
          const line = [
            {
              xAxis: getDateWithZonedTimeToUTC(item.date, query.period),
              yAxis: item.count
            },
            {
              xAxis: getDateWithZonedTimeToUTC(item.date, query.period),
              yAxis: parseInt((item.count * 1.18).toFixed(0)),
              lineStyle: {
                type: "dotted",
                color: "#7D47EB"
              }
            }
          ];
          return line;
        }
        return null; // If no matching data or not enough data is found
      })
      .filter(Boolean); // Remove null entries

    let series = [
      {
        type: "bar",
        name: firstSeriesName,
        stack: "stats",
        data: firstSeries.data.histogram.map((item: any) => {
          const dateValue = !item.date.includes(" ") ? `${item.date} 00:00:00` : item.date;
          const formattedDate =
            period === "hour" || period === "live"
              ? format(new Date(dateValue), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
              : format(new Date(dateValue), "yyyy-MM-dd");
          return { value: item.count, date: formattedDate };
        }),
        markPoint: {
          data: !query.comparison && markPoints,
          emphasis: {
            label: {
              show: true
            }
          }
        },
        markLine: {
          symbol: "none",
          data: !query.comparison && markLines
        },
        itemStyle: {
          // borderRadius: [4, 4, 0, 0],
        },
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgb(125, 71, 235, 0.7)" },
          { offset: 0.5, color: "rgb(125, 71, 235, 0.8)" },
          { offset: 1, color: "rgb(125, 71, 235, 1)" }
        ])
      },
      {
        type: "bar",
        name: secondSeriesName,
        stack: "stats",
        data: secondSeries.data.histogram.map((item: any) => {
          const dateValue = !item.date.includes(" ") ? `${item.date} 00:00:00` : item.date;
          const formattedDate =
            period === "hour" || period === "live"
              ? format(new Date(dateValue), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
              : format(new Date(dateValue), "yyyy-MM-dd");
          return { value: item.count, date: formattedDate };
        }),
        itemStyle: {
          borderRadius: [4, 4, 0, 0]
        },
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgb(253, 96, 183, 0.7)" },
          { offset: 0.5, color: "rgb(253, 96, 183, 0.8)" },
          { offset: 1, color: "rgb(253, 96, 183, 1)" }
        ])
      }
    ];

    setBarGraph({
      categories: categories,
      series: series
    });
  };

  const getBarGraphData = () => {
    if (toggleBarFirstSeries && toggleBarSecondSeries) {
      return barGraph.series;
    } else if (toggleBarFirstSeries) {
      return barGraph.series.filter(
        (item: any) => item.name === snakeCaseToSimpleString(firstSeries.name)
      );
    } else if (toggleBarSecondSeries) {
      return barGraph.series.filter(
        (item: any) => item.name === snakeCaseToSimpleString(secondSeries.name)
      );
    } else {
      return [];
    }
  };

  const storeLineGraphData = (firstSeries: any, secondSeries: any) => {
    let series;
    const { period } = query;
    const firstSeriesName = snakeCaseToSimpleString(firstSeries.name);
    const secondSeriesName = snakeCaseToSimpleString(secondSeries.name);

    let completeFirstSeries = firstSeries.data.histogram.map((item: any, index: number) => {
      return [item.date, item.count];
    });
    let completeSecondSeries = secondSeries.data.histogram.map((item: any, index: number) => {
      return [item.date, item.count];
    });

    const completeData = completeFirstSeries.map(([date, count]: [any, any]) => ({ date, count }));
    if (query.period === "week" || query.period === "month") {
      const mappedData: Record<string, any[]> = Object.fromEntries(
        Object.entries(annotationsGroupedData).map(([date, data]) => [
          completeData.reduce(
            (a: string | number | Date, b: { date: string | number | Date }) =>
              Math.abs(new Date(b.date).getTime() - new Date(date).getTime()) <
              Math.abs(new Date(a).getTime() - new Date(date).getTime())
                ? b.date
                : a,
            completeData[0].date
          ),
          data
        ])
      );
      annotationsGroupedData = mappedData;
      setAnnotationsGroupedDataState(mappedData);
    }

    // if the present index is available, show the dashed line graph.
    if (firstSeries.data.present_index !== -1) {
      let currentFirstSeries = firstSeries.data.histogram
        .slice(0, firstSeries.data.present_index)
        .map((item: any, index: number) => {
          return [item.date, item.count];
        });

      let partialFirstSeries = firstSeries.data.histogram
        .slice(firstSeries.data.present_index - 1, firstSeries.data.present_index + 1)
        .map((item: any, index: number) => {
          return [item.date, item.count];
        });

      let currentSecondSeries = secondSeries.data.histogram
        .slice(0, secondSeries.data.present_index)
        .map((item: any, index: number) => {
          return [item.date, item.count];
        });

      let partialSecondSeries = secondSeries.data.histogram
        .slice(secondSeries.data.present_index - 1, secondSeries.data.present_index + 1)
        .map((item: any, index: number) => {
          return [item.date, item.count];
        });

      if (period === "hour" || period === "live") {
        currentFirstSeries = currentFirstSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          value
        ]);
        currentSecondSeries = currentSecondSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          value
        ]);
        partialFirstSeries = partialFirstSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          value
        ]);
        partialSecondSeries = partialSecondSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          value
        ]);
      } else {
        currentFirstSeries = currentFirstSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd"),
          value
        ]);
        currentSecondSeries = currentSecondSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd"),
          value
        ]);
        partialFirstSeries = partialFirstSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd"),
          value
        ]);
        partialSecondSeries = partialSecondSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd"),
          value
        ]);
      }

      const markPoints = currentFirstSeries
        .map(([date, value]: [string, number]) => {
          const matchingData = annotationsGroupedData[date];
          if (matchingData) {
            const markPointLabel =
              matchingData.length > 1 ? `${matchingData.length} Notes` : "1 Note";
            return {
              coord: [date, parseInt((value * 1.18).toFixed(0))],
              name: "Annotation_" + date,
              symbolSize: 25,
              itemStyle: {
                color: `rgb(125, 71, 235, 0.4)`,
                borderColor: "#7D47EB",
                borderWidth: 1
              },
              label: {
                show: true,
                formatter: `${markPointLabel}`,
                position: "top",
                color: colorScheme === "dark" ? "#A6A7AB" : "#242424",
                backgroundColor: "transparent"
              }
            };
          }
          return null; // If no matching data is found
        })
        .filter(Boolean); // Remove null entries

      const markLines = currentFirstSeries
        .map(([date, value]: [string, number]) => {
          const matchingData = annotationsGroupedData[date];
          if (matchingData && matchingData.length >= 1) {
            const line = [
              {
                xAxis: date,
                yAxis: value
              },
              {
                xAxis: date,
                yAxis: parseInt((value * 1.18).toFixed(0)),
                lineStyle: {
                  type: "dotted",
                  color: "#7D47EB"
                }
              }
            ];
            return line;
          }
          return null; // If no matching data or not enough data is found
        })
        .filter(Boolean); // Remove null entries

      const partialMarkPoints = partialFirstSeries
        .slice(1)
        .map(([date, value]: [string, number]) => {
          const matchingData = annotationsGroupedData[date];
          if (matchingData) {
            const markPointLabel =
              matchingData.length > 1 ? `${matchingData.length} Notes` : "1 Note";
            return {
              coord: [date, parseInt((value * 1.18).toFixed(0))],
              name: "Annotation_" + date,
              symbolSize: 25,
              itemStyle: {
                color: `rgb(125, 71, 235, 0.4)`,
                borderColor: "#7D47EB",
                borderWidth: 1
              },
              label: {
                show: true,
                formatter: `${markPointLabel}`,
                position: "top",
                color: "#242424",
                backgroundColor: "transparent"
              }
            };
          }
          return null; // If no matching data is found
        })
        .filter(Boolean); // Remove null entries

      const partialMarkLines = partialFirstSeries
        .slice(1)
        .map(([date, value]: [string, number]) => {
          const matchingData = annotationsGroupedData[date];
          if (matchingData && matchingData.length >= 1) {
            const line = [
              {
                xAxis: date,
                yAxis: value
              },
              {
                xAxis: date,
                yAxis: parseInt((value * 1.18).toFixed(0)),
                lineStyle: {
                  type: "dotted",
                  color: "#7D47EB"
                }
              }
            ];
            return line;
          }
          return null; // If no matching data or not enough data is found
        })
        .filter(Boolean); // Remove null entries

      series = [
        {
          toggle: toggleLineFirstSeries,
          type: "line",
          name: firstSeriesName,
          id: firstSeriesName,
          color: "#7D47EB",
          smooth: false,
          lineStyle: {
            width: 2
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(125, 71, 235, 0.6)"
              },
              {
                offset: 1,
                color: "rgba(125, 71, 235, 0.01)"
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: currentFirstSeries,
          markPoint: {
            data: !query.comparison && markPoints,
            emphasis: {
              label: {
                show: true
              }
            }
          },
          markLine: {
            symbol: "none",
            data: !query.comparison && markLines
          }
        },
        {
          toggle: toggleLineSecondSeries,

          type: "line",
          name: secondSeriesName,
          id: secondSeriesName,
          color: "#FD60B7",
          smooth: false,
          lineStyle: {
            width: 2
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(253, 96, 183, 0.6)"
              },
              {
                offset: 1,
                color: "rgba(253, 96, 183, 0.01)"
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: currentSecondSeries,
          markPoint: {
            data: [],
            emphasis: {
              label: {
                show: true
              }
            }
          },
          markLine: {
            symbol: "none",
            data: []
          }
        },
        {
          toggle: toggleLineFirstSeries,
          type: "line",
          name: `Partial Day ${firstSeriesName}`,
          id: `Partial Day ${firstSeriesName}`,
          color: "#7D47EB",
          smooth: false,
          lineStyle: {
            width: 2,
            type: "dashed"
          },
          itemStyle: {
            emphasis: {
              label: {
                show: false
              }
            }
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(125, 71, 235, 0.4)"
              },
              {
                offset: 1,
                color: "rgba(125, 71, 235, 0.01)"
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: partialFirstSeries,
          markPoint: {
            data: !query.comparison && partialMarkPoints,
            emphasis: {
              label: {
                show: true
              }
            }
          },
          markLine: {
            symbol: "none",
            data: !query.comparison && partialMarkLines
          }
        },
        {
          toggle: toggleLineSecondSeries,

          type: "line",
          name: `Partial Day ${secondSeriesName}`,
          id: `Partial Day ${secondSeriesName}`,
          color: "#FD60B7",
          smooth: false,
          lineStyle: {
            width: 2,
            type: "dashed"
          },
          itemStyle: {
            emphasis: {
              label: {
                show: false
              }
            }
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(253, 96, 183, 0.4)"
              },
              {
                offset: 1,
                color: "rgba(253, 96, 183, 0.01)"
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: partialSecondSeries,
          markPoint: {
            data: [],
            emphasis: {
              label: {
                show: true
              }
            }
          },
          markLine: {
            symbol: "none",
            data: []
          }
        },
        {
          id: "Empty",
          type: "line",
          name: "Empty",
          color: "#fff",
          data: firstSeries.data.histogram
            .slice(firstSeries.data.present_index + 1)
            .map((item: any, index: number) => {
              return [format(new Date(item.date), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"), item.count];
            }),
          toggle: true
        }
      ];
    } else {
      // no partial data, plot two line graph for first series and second series
      if (period === "hour" || period === "live") {
        completeFirstSeries = completeFirstSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          value
        ]);
        completeSecondSeries = completeSecondSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          value
        ]);
      } else {
        completeFirstSeries = completeFirstSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd"),
          value
        ]);
        completeSecondSeries = completeSecondSeries.map(([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd"),
          value
        ]);
      }

      const markPoints = completeFirstSeries
        .map(([date, value]: [string, number]) => {
          const matchingData = annotationsGroupedData[date];
          if (matchingData) {
            const markPointLabel =
              matchingData.length > 1 ? `${matchingData.length} Notes` : "1 Note";
            return {
              coord: [date, parseInt((value * 1.18).toFixed(0))],
              name: "Annotation_" + date,
              symbolSize: 25,
              itemStyle: {
                color: `rgb(125, 71, 235, 0.4)`,
                borderColor: "#7D47EB",
                borderWidth: 1
              },
              label: {
                show: true,
                formatter: `${markPointLabel}`,
                position: "top",
                color: colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[9],
                backgroundColor: "transparent"
              }
            };
          }
          return null; // If no matching data is found
        })
        .filter(Boolean); // Remove null entries

      const markLines = completeFirstSeries
        .map(([date, value]: [string, number]) => {
          const matchingData = annotationsGroupedData[date];
          if (matchingData && matchingData.length >= 1) {
            const line = [
              {
                xAxis: date,
                yAxis: value
              },
              {
                xAxis: date,
                yAxis: parseInt((value * 1.18).toFixed(0)),
                lineStyle: {
                  type: "dotted",
                  color: "#7D47EB"
                }
              }
            ];
            return line;
          }
          return null; // If no matching data or not enough data is found
        })
        .filter(Boolean); // Remove null entries

      series = [
        {
          toggle: toggleLineFirstSeries,
          id: firstSeriesName,
          type: "line",
          name: firstSeriesName,
          color: "#7D47EB",
          smooth: false,
          lineStyle: {
            width: 2
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(125, 71, 235, 0.6)"
              },
              {
                offset: 1,
                color: "rgba(125, 71, 235, 0.01)"
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: completeFirstSeries,
          markPoint: {
            data: !query.comparison && markPoints,
            emphasis: {
              label: {
                show: true
              }
            }
          },
          markLine: {
            symbol: "none",
            data: !query.comparison && markLines
          }
        },
        {
          toggle: toggleLineSecondSeries,
          id: secondSeriesName,
          type: "line",
          name: secondSeriesName,
          color: "#FD60B7",
          smooth: false,
          lineStyle: {
            width: 2
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(253, 96, 183, 0.6)"
              },
              {
                offset: 1,
                color: "rgba(253, 96, 183, 0.01)"
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: completeSecondSeries
        }
      ];
    }
    lineGraph.series = series;
    lineGraph.xAxis[0].axisLabel.formatter = (params: any) => {
      return xAxisLabelFormatter(query.period, params);
    };
    lineGraph.tooltip.formatter = (params: any) =>
      GenericTooltip(
        params,
        toggleLineFirstSeries && toggleLineSecondSeries,
        getDateFormatFromPeriod(query.period),
        query.period
      );

    setLineGraph(cloneDeep(lineGraph));
  };

  /**
   * Toggle first series data graph. i.e line graph has two points (line and partial).
   * If the data points are 4, we toggle the first two indexes.
   * If the data points are 2, only toggle first one.
   */
  const handleToggleFirstSeries = () => {
    const indexValue = lineGraph.series.length === 5 ? 2 : 1;
    setToggleLineFirstSeries(!toggleLineFirstSeries);
    const firstSeriesName = snakeCaseToSimpleString(firstSeries.name);

    let copy = cloneDeep(lineGraph);
    copy.series.map((item: any, index: number) => {
      if (item.name === firstSeriesName || item.name === `Partial Day ${firstSeriesName}`) {
        item.toggle = !item.toggle;
      }
      return item;
    });
    copy.tooltip.formatter = (params: any) =>
      GenericTooltip(
        params,
        !toggleLineFirstSeries && toggleLineSecondSeries,
        getDateFormatFromPeriod(query.period),
        query.period
      );
    setLineGraph(copy);
  };
  /**
   * Toggle second series data graph. i.e line graph has two points (line and partial).
   * If the data points are 4, we toggle the last two indexes.
   * If the data points are 2, only toggle last one.
   */
  const handleToggleSecondSeries = () => {
    const indexValue = lineGraph.series.length === 5 ? 1 : 0;
    const secondSeriesName = snakeCaseToSimpleString(secondSeries.name);
    setToggleLineSecondSeries(!toggleLineSecondSeries);
    let copy = cloneDeep(lineGraph);
    copy.series.map((item: any, index: number) => {
      if (item.name === secondSeriesName || item.name === `Partial Day ${secondSeriesName}`) {
        item.toggle = !item.toggle;
      }
      return item;
    });
    copy.tooltip.formatter = (params: any) =>
      GenericTooltip(
        params,
        toggleLineFirstSeries && !toggleLineSecondSeries,
        getDateFormatFromPeriod(query.period),
        query.period
      );
    setLineGraph(copy);
  };

  const getHistogram = () => {
    let copyHistogram = cloneDeep(lineGraph);
    copyHistogram.series = lineGraph.series.filter((item: any) => item?.toggle);
    return copyHistogram;
  };

  useEffect(() => {
    processGraphData();
    setAnnotationsGroupedDataState(annotationsGroupedData);
    // Add rerendering of the chart on colorScheme change
    setLineGraph((currentGraph: any) => {
      currentGraph.tooltip.borderColor = colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2];
      currentGraph.tooltip.backgroundColor = colorScheme === "dark" ? theme.colors.dark[9] : "#fff";
      currentGraph.tooltip.textStyle.color = colorScheme === "dark" ? "#A6A7AB" : "#595c5f";
      return cloneDeep(currentGraph);
    });
  }, [query, activeWorkspace.id, notes, query?.period, colorScheme]);

  const renderLineGraph = () => {
    return (
      <>
        {lineGraph.hasOwnProperty("series") && hasResults ? (
          <div data-cy={`${firstSeries.name.split("_")[1]}-line-graph`}>
            <ReactEChartsCore
              style={{ height: "300px" }}
              echarts={echarts}
              option={getHistogram()}
              notMerge={true}
              onEvents={{
                click: (params: any) => handleGraphOnClick(params)
              }}
            />
          </div>
        ) : (
          <div className="pt-28">
            <NoResults heading={"No results"} text={"There is no activity"} />
          </div>
        )}
      </>
    );
  };

  const renderBarGraph = () => {
    return (
      <>
        {barGraph.hasOwnProperty("series") && barGraph.series.length > 0 && hasResults ? (
          <div data-cy={`${firstSeries.name.split("_")[1]}-bar-graph`}>
            <BarGraphWrapper
              height={"300px"}
              categories={barGraph.categories}
              series={getBarGraphData()}
              onClick={(params: any) => handleGraphOnClick(params)}
              grid={{
                top: "5%",
                left: "0%",
                right: "1%",
                bottom: "0%",
                containLabel: true
              }}
              customTooltipFormatter={(params: any) =>
                GenericTooltipBar(
                  params,
                  toggleBarFirstSeries && toggleBarSecondSeries,
                  getDateFormatFromPeriod(query.period),
                  query.period
                )
              }
            />
          </div>
        ) : (
          <>
            <div className="pt-28">
              <NoResults heading={"No results"} text={"There is no activity"} />
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Box>
        {loading === "loaded" ? (
          <>
            {(chartType === "line" &&
              lineGraph.series.length > 0 &&
              lineGraph.series.filter((item: any) => item.toggle && item.id !== "Empty").length ===
              0) ||
            (chartType === "bar" && !toggleBarFirstSeries && !toggleBarSecondSeries) ? (
              <>
                <NoResults
                  className="pt-28"
                  text={`Please click on the ${snakeCaseToSimpleString(
                    firstSeries.name
                  )} or ${snakeCaseToSimpleString(secondSeries.name)} to view results`}
                  heading={"No Results"}
                />
              </>
            ) : (
              <>
                {lineGraph.series.length > 0 || barGraph.series.length > 0 ? (
                  <div data-cy={`${firstSeries.name.split("_")[1]}-graph`}>
                    {chartType === "line" ? <>{renderLineGraph()}</> : <>{renderBarGraph()}</>}

                    {/* Annotation Modal */}
                    <Modal
                      zIndex={1999}
                      opened={annotationPortalOpened}
                      onClose={() => setAnnotationPortalOpened(false)}
                      shadow="sm"
                      withCloseButton={false}
                      overlayProps={{
                        blur: 0,
                        opacity: 0.5
                      }}
                      padding={10}
                      size={355}
                      title={null}
                      styles={{
                        header: {
                          marginBottom: 0,
                          marginTop: -8,
                          position: "absolute",
                          right: 0
                        },
                        content: {
                          paddingTop: "1rem",
                          left: `${portalPosition.x}px`,
                          top: `${portalPosition.y}px`,
                          position: "absolute",
                          flexShrink: 0,
                          width: "100%",
                          maxWidth: "355px"
                        },
                        inner: {
                          maxHeight: "280px"
                        }
                      }}
                      withinPortal>
                      <Box
                        sx={{
                          maxHeight: "280px",
                          overflowY: "scroll",
                          "&::-webkit-scrollbar": {
                            display: "none" // hides scrollbar for Webkit browsers
                          },
                          "-ms-overflow-style": "none", // hides scrollbar for IE and Edge
                          scrollbarWidth: "none" // hides scrollbar for Firefox
                        }}>
                        <Box w={350} pos={"relative"}>
                          <Box pos="absolute" right={12}>
                            <CloseButton onClick={() => setAnnotationPortalOpened(false)} />
                          </Box>
                          <Flex direction={"column"} pt={"xs"}>
                            {markPointAnnotations &&
                              markPointAnnotations.map((annotation: Note, index: number) => (
                                <>
                                  <Flex direction={"column"} maw={350}>
                                    <Text fw={600}>{annotation.title}</Text>
                                    <Text> {truncateText(annotation.description, 150)}</Text>
                                    <Flex gap={10}>
                                      <Text c="dimmed" fz="xs" my={3}>
                                        {format(parseISO(annotation.date), "MMM dd, yyyy")},
                                      </Text>
                                      <Text c="dimmed" fz="xs" my={3}>
                                        {annotation.author}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                  {index !== markPointAnnotations.length - 1 && <Divider my="sm" />}
                                </>
                              ))}
                          </Flex>
                        </Box>
                      </Box>
                    </Modal>
                  </div>
                ) : (
                  <div className="">
                    <BarChartSkeleton />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Center my="md" h={'300px'}>
              <BarChartSkeleton />
            </Center>
          </>
        )}
      </Box>
      {/* Toggle graphs i.e New visitors vs returning visitors */}

      <>
        <Flex
          justify={"center"}
          // pos="absolute"
          bottom={-16}
          flex={1}
          align={"center"}
          pt={32}
          // className="flex justify-center pt-8 items-center absolute -bottom-4 w-full"
        >
          <Flex
            mr={"sm"}
            align={"center"}
            style={{
              cursor: "pointer",
              transition: "all 0.2s ease-in-out",
              opacity: !toggleLineFirstSeries && !toggleBarFirstSeries ? 0.5 : 1
            }}
            onClick={() => {
              handleToggleFirstSeries();
              setToggleBarFirstSeries(!toggleBarFirstSeries);
            }}
            data-cy={`${firstSeries.name}-toggle`}>
            <Paper h={12} w={12} bg={"brand"} radius={"xl"} mr={"xs"} />
            <Text tt="capitalize" fw={600}>
              {snakeCaseToSimpleString(firstSeries.name)}
            </Text>
          </Flex>
          <Flex
            align={"center"}
            style={{
              cursor: "pointer",
              transition: "all 0.2s ease-in-out",
              opacity: !toggleLineSecondSeries && !toggleBarSecondSeries ? 0.5 : 1
            }}
            onClick={() => {
              handleToggleSecondSeries();
              setToggleBarSecondSeries(!toggleBarSecondSeries);
            }}
            data-cy={`${secondSeries.name}-toggle`}>
            <Paper h={12} w={12} bg={"pink"} radius={"xl"} mr={"xs"} />
            <Text tt="capitalize" fw={600}>
              {snakeCaseToSimpleString(secondSeries.name)}
            </Text>
          </Flex>
        </Flex>
      </>
    </>
  );
};

const areEqual = (prevProps: any, nextProps: any) => {
  return Object.keys(prevProps).every((key) => {
    return prevProps[key] === nextProps[key];
  });
};

const GenericMultiSeriesLineAndBarGraphWrapperMemoized = React.memo(
  GenericMultiSeriesLineAndBarGraphWrapper,
  areEqual
);

export default GenericMultiSeriesLineAndBarGraphWrapperMemoized;
