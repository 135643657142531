import axios from "./JWTService";
export class AttributionService {
  private readonly _endpoint;
  constructor() {
    this._endpoint = "/v1/attribution";
  }

  attribution = (
    ws_id: string,
    insight_id: string,
    startDate: string,
    endDate: string,
    lookbackWindow: number = 90,
    isSourceAttribution: boolean = false
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}?start_date=${startDate}&end_date=${endDate}&lookback_window=${lookbackWindow}&source_attribution=${isSourceAttribution}`,
          {}
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
