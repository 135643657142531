import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { shouldRedirectToLoginRoute } from '@/lib/utils/RedirectionRoute';
import { authAccessTokenFromStorage } from '@/lib/utils/Storage';
import { ApplicationConfiguration } from './ApplicationConfiguration';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const _applicationConfiguration = new ApplicationConfiguration();
  console.debug(`[request] [${JSON.stringify(config)}]`);
  const token = authAccessTokenFromStorage();
  if (config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.baseURL = _applicationConfiguration.backendAPIBase;
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.debug(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.debug(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // console.debug(`[response error] [${JSON.stringify(error)}]`);

  // handling global errors
  if (error) {
    console.log(error?.response?.status);
    switch (error?.response?.status) {
      case 403:
        console.log('403 status code');
        break;
      case 401:
        console.log('401 status code');
        if (error && error.response && error.response.data) {
          if (
            //@ts-ignore
            error.response.data.hasOwnProperty('detail') &&
            //@ts-ignore
            typeof error.response.data.detail === 'string'
          ) {
            // checking detail/response and matching string.
            //@ts-ignore
            const detail = error.response.data.detail;
            if (
              detail === 'Invalid token or expired token.' ||
              detail === 'Invalid authentication scheme.' ||
              detail === 'Invalid refresh token.'
            ) {
              shouldRedirectToLoginRoute();
            }
            // toast.error(error.response.data.detail);
          }
        }
        break;

      default:
        console.log(error?.response?.status);

        break;
    }
  }

  return Promise.reject(error);
};

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
setupInterceptorsTo(axios);

// Function that will be called to refresh authorization
// We'll implement this down the road.

// const refreshAuthLogic = (failedRequest: any): any => {
//   console.log(
//     "Send refresh auth logic.",
//     failedRequest.request,
//     failedRequest.response
//   );
//   if (!failedRequest.request.responseURL.includes("v1/auth/refresh_token"))
//     // failedRequest.request.config.headers.Authorization = `Bearer ${authRefreshTokenFromStorage()}`;
//     axios
//       .get("v1/auth/refresh_token", {
//         headers: {
//           Authorization: `Bearer ${authRefreshTokenFromStorage()}`,
//           Test: "Additional",
//         },
//       })
//       .then((res) => {
//         console.log("Refresh Auth Logic", res);
//         // localStorage.setItem("token", tokenRefreshResponse.data.token);
//         // failedRequest.response.config.headers["Authorization"] =
//         // "Bearer " + tokenRefreshResponse.data.token;
//         return Promise.resolve();
//       });
// };

// createAuthRefreshInterceptor(axios, refreshAuthLogic);

export default axios;
