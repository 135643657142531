import { Button, Table, useMantineTheme, Text, Flex, Center, Loader } from "@mantine/core";
import { format } from "date-fns";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { SlippingAwayUsersService } from "@/lib/services/SlippingAwayUsersService";
import { DATE_FORMAT, LOADER_COLOR, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { downloadCSVFromResponse } from "@/lib/utils/CSVExporterUtility";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { ExportButton } from "@/ui/components/Common/ExportButton/ExportButton";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { IReportType } from "../../../../types/types.d";
import { AvatarWithText } from "../../Common/AvatarWithText/AvatarWithText";
import { getRandomColor } from "@/style/Colors";
import { RelativeDateWithText } from "../../Common/RelativeDateWithText/RelativeDateWithText";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface Props {
  period: string;
  visible: boolean;
  setVisible: any;
  insightId: string | undefined;
  reportType?: IReportType;
}

export const SlippingAwayUsersListModal: FC<Props> = ({
  period = "7_days",
  visible,
  setVisible,
  insightId,
  reportType = "user"
}) => {
  // colors for the avatar
  const colors = useMemo(() => {
    return Array.from(Array(100).keys()).map((i) => getRandomColor());
  }, []);
  const theme = useGlobalMantineTheme();
  const { hasRolesNotWith } = useWorkspaceUtilityHook();
  const [exporting, setIsExporting] = useState("idle");
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [title, setTitle] = useState("Slipping Away Users: ");
  const [slippingAwayUsersList, setSlippingAwayUsersList] = useState([]);
  const [loading, setLoading] = useState("idle");
  const [loadingMore, setLoadingMore] = useState("idle");
  const [moreUsers, setMoreUsers] = useState(true);
  const [page, setPage] = useState(1);
  const [filters] = useSegmentStore((state) => [state.filters]);
  const fetchSlippingAwayUsersList = async () => {
    setLoading("loading");
    const slippingAwayService = new SlippingAwayUsersService();
    await slippingAwayService
      .list(
        activeWorkspace.id,
        insightId,
        period,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups,
        1
      )
      .then((res) => {
        const updatedList = res.data.map((item: any) => {
          return {
            ...item,
            color: colors[Math.floor(Math.random() * 100)]
          };
        });
        setSlippingAwayUsersList(updatedList);
        if (res.data.length < 100) {
          setMoreUsers(false);
        }
      })
      .catch();
    setLoading("loaded");
  };

  const fetchMoreSlippingAwayUsersList = async () => {
    setLoadingMore("loading");
    setPage(page + 1);
    await new SlippingAwayUsersService()
      .list(
        activeWorkspace.id,
        insightId,
        period,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups,
        page + 1
      )
      .then((res) => {
        if (res.data) {
          const updatedList = res.data.map((item: any) => {
            return {
              ...item,
              color: colors[Math.floor(Math.random() * 100)]
            };
          });
          setSlippingAwayUsersList(slippingAwayUsersList.concat(updatedList));
          if (res.data.length === 0) {
            setMoreUsers(false);
          }
        }
      })
      .catch();
    setLoadingMore("loaded");
  };

  useEffect(() => {
    if (period) {
      setPage(1);
      switch (period) {
        case "7_days":
          setTitle(`Slipping Away ${reportType == "user" ? "Users" : "Companies"}: Last 7 Days`);
          break;
        case "14_days":
          setTitle(`Slipping Away ${reportType == "user" ? "Users" : "Companies"}: Last 14 Days`);
          break;
        case "30_days":
          setTitle(`Slipping Away ${reportType == "user" ? "Users" : "Companies"}: Last 30 Days`);
          break;
        case "60_days":
          setTitle(`Slipping Away ${reportType == "user" ? "Users" : "Companies"}: Last 60 Days`);
          break;
        case "90_days":
          setTitle(`Slipping Away ${reportType == "user" ? "Users" : "Companies"}: Last 90 Days`);
          break;
      }
      fetchSlippingAwayUsersList();
    }
    return () => {
      setSlippingAwayUsersList([]);
    };
  }, [period, reportType]);

  const exportSlippingAwayUsers = async () => {
    const slippingAwayService = new SlippingAwayUsersService();
    setIsExporting("loading");
    try {
      const response = await slippingAwayService.export(
        activeWorkspace.id,
        insightId,
        period,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups
      );
      if (response.data) {
        let filename = `slipping_away_${
          reportType == "user" ? "users" : "companies"
        }_last_${period}_${format(new Date(), DATE_FORMAT)}.csv`;
        downloadCSVFromResponse(response, filename);
        setIsExporting("loaded");
        return true;
      }
    } catch (err) {
      setIsExporting("loaded");
      throw new Error("400");
    }
    return false;
  };

  const navigate = useNavigate();
  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);

  return (
    <>
      <SidePanel
        opened={visible}
        onCancel={() => {
          setVisible(!visible);
        }}
        loading={true}
        title={title}>
        <Flex align="center" direction={"row-reverse"}>
          {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
            <ExportButton
              onClick={exportSlippingAwayUsers}
              disabled={exporting === "loading"}
              loading={exporting === "loading"}
            />
          )}
        </Flex>

        {loading === "loaded" ? (
          <>
            <Table>
              <Table.Thead fw={600}>
                {reportType == "user" ? (
                  <Table.Tr>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>Email</Table.Th>
                    <Table.Th>Last seen</Table.Th>
                    <Table.Th>Created at</Table.Th>
                  </Table.Tr>
                ) : (
                  <Table.Tr>
                    <Table.Th>Company Name</Table.Th>
                    <Table.Th>Total Users</Table.Th>
                    <Table.Th>Last seen</Table.Th>
                    <Table.Th>Created at</Table.Th>
                  </Table.Tr>
                )}
              </Table.Thead>
              <Table.Tbody>
                {slippingAwayUsersList.map((user: any, index) =>
                  reportType == "user" ? (
                    <Table.Tr
                      key={`slipping:away:${user.email}:${index}`}
                      onClick={() => {
                        // navigate(
                        //   `/env/${activeWorkspace.identifier}/users/everyone/${user.user_id}`
                        // )
                        setVisible(false);
                        setOverviewModalOpen(true, "user", user.user_id);
                      }}
                      className={"hover:underline transition-all ease-in-out cursor-pointer"}>
                      <Table.Td>
                        <AvatarWithText
                          text={user.first_name + " " + user.last_name}
                          color={user.color}
                        />
                      </Table.Td>
                      <Table.Td>{user.email || "..."}</Table.Td>
                      <Table.Td>
                        <RelativeDateWithText text={user.last_visited_time} />
                      </Table.Td>
                      <Table.Td>
                        <RelativeDateWithText text={user.user_created_at} />
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    <Table.Tr
                      key={`slipping:away${user.company_id}`}
                      onClick={() => {
                        // navigate(
                        //   `/env/${activeWorkspace.identifier}/companies/everyone/${user.company_id}`
                        // )
                        setVisible(false);
                        setOverviewModalOpen(true, "company", user.company_id);
                      }}
                      className={"hover:underline transition-all ease-in-out cursor-pointer"}>
                      <Table.Td>
                        <AvatarWithText text={user.name} color={user.color} />
                      </Table.Td>
                      <Table.Td>
                        <Text c="dimmed" fw={500}>
                          {user.number_of_users} {user.number_of_users > 1 ? "users" : "user"}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <RelativeDateWithText text={user.last_visited_time} />
                      </Table.Td>
                      <Table.Td>
                        <RelativeDateWithText text={user.company_created_at} />
                      </Table.Td>
                    </Table.Tr>
                  )
                )}
              </Table.Tbody>
            </Table>

            {moreUsers && (
              <div className="flex justify-center pt-8">
                <Button
                  variant="outline"
                  size="xs"
                  loading={loadingMore === "loading"}
                  disabled={loadingMore === "loading"}
                  onClick={() => fetchMoreSlippingAwayUsersList()}>
                  Load More
                </Button>
              </div>
            )}
          </>
        ) : (
          <Center my="md">
            <Loader size="xs" />
          </Center>
        )}
      </SidePanel>
    </>
  );
};
