import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
// @ts-ignore
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// import { IntegrationSetupGuide } from "./IntegrationSetupGuide"
import { Box, Divider, Grid, List, Paper, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { NavLink, Outlet } from "react-router-dom";
import { MOBILE_BREAKPOINT_QUERY } from "../../../lib/utils/Constants";
// import { InsightsSetupGuide } from "./InsightsSetupGuide"
import clsx from "clsx";
import classes from "./SetupGuidesHome.module.css";
const SetupGuidesHome = () => {
  const { user, activeWorkspace } = useContext(AppLifecycleContext);
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  return (
    <>
      <Paper>
        <Title
          mb={"md"}
          order={3}
          fw={700}>{`Welcome to Usermaven.com, ${user?.first_name}`}</Title>
        <Divider my="md" />

        <Grid>
          <Grid.Col span={2.5}>
            {isMobileView ? null : (
              <Box>
                <Title py="md" order={5}>
                  Your Setup List
                </Title>
                <Text>Follow the tasks below to setup Usermaven workspace the right way.</Text>
                <Grid my="md">
                  <Grid.Col span={6}>
                    <Paper h={6} className={classes.completed} w={"100%"} />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Paper
                      h={6}
                      w={"100%"}
                      className={
                        activeWorkspace.is_integrated_with_pixel
                          ? classes.completed
                          : classes.remaining
                      }
                    />
                  </Grid.Col>
                </Grid>
                <List>
                  <List.Item
                    mb={"md"}
                    className={classes.listItemBg}
                    icon={
                      <Box w={32} c="green.8">
                        <FontAwesomeIcon
                          icon={solid("check-circle")}
                          size={"2x"}
                          className={"text-green-800"}
                        />
                      </Box>
                    }>
                    Create your workspace
                  </List.Item>
                  <List.Item
                    className={clsx(classes.listItemBg, classes.active)}
                    icon={
                      <Box w={32}>
                        <FontAwesomeIcon icon={solid("check-circle")} size={"2x"} />
                      </Box>
                    }>
                    <NavLink
                      to={`/env/${activeWorkspace.identifier}/setup-guides/integration`}
                      className={classes.link}>
                      <Text size="md">Install tracking script</Text>
                    </NavLink>
                  </List.Item>
                </List>

                {/* <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "border-l-4 border-solid border-transparent font-bold text-lg py-4 px-6 items-center flex cursor-pointer text-gray-800 no-underline hover:text-gray-900 border-solid border-l-4 border-purple-800 bg-gray-200 rounded"
                        : "border-l-4 border-solid border-transparent font-bold text-lg py-4 px-6 items-center flex cursor-pointer text-gray-800 no-underline hover:text-gray-900"
                    }
                    to={`/env/${activeWorkspace.identifier}/setup-guides/insights`}
                  >
                    <span className="pr-4">
                      <FontAwesomeIcon
                        icon={solid("check-circle")}
                        size={"2x"}
                        className={"text-gray-400"}
                      />
                    </span>
                    Setup Insights
                  </NavLink>
                </li> */}
              </Box>
            )}
          </Grid.Col>
          <Grid.Col span={9.5}>
            <Outlet />
          </Grid.Col>
        </Grid>
      </Paper>
    </>
  );
};
export default SetupGuidesHome;
