import { Flex, Anchor } from "@mantine/core";
import styles from "./ShareDashboardFooter.module.css";

export const ShareDashboardFooter = () => {
  return (
    <Flex className={styles.footerContainer}>
      <div className={styles.flexGrow}>
        <Anchor className={styles.anchor} href="https://usermaven.com" target="_blank">
          © Powered by Usermaven
        </Anchor>
      </div>
      <div className={styles.flexCol}>
        <Anchor
          className={`${styles.anchor} ${styles.marginRight4}`}
          href="https://usermaven.com/terms-of-service"
          target="_blank">
          Terms of service
        </Anchor>
        <Anchor
          className={`${styles.anchor} ${styles.marginRight4}`}
          href="https://usermaven.com/privacy"
          target="_blank">
          Privacy Policy
        </Anchor>
        <Anchor
          className={`${styles.anchor} ${styles.marginRight4}`}
          href="https://usermaven.com/gdpr"
          target="_blank">
          GDPR
        </Anchor>
        <Anchor className={styles.anchor} href="https://usermaven.com/ccpa" target="_blank">
          CCPA
        </Anchor>
      </div>
    </Flex>
  );
};
