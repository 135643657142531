import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
import { ProductInsightsOnboarding } from "@/ui/components/App/ProductInsightsOnboarding/ProductInsightsOnboarding";
import { Anchor, Box, Button, Paper, Tooltip } from "@mantine/core";
import { SetupInsightsModal } from "../Insights/Setup/SetupInsightsModal";
import { InsightTemplateType } from "@/types/types.d";
import { ManageInsightsModal } from "../Insights/ManageInsightsModal";
import ManageFeatureAdoptionModal from "./Components/Modals/ManageFeatureAdoptionModal";
import useFeatureAdoptionStore from "@/stores/useFeatureAdoptionStore";
import { Header } from "@/ui/components/Common/Header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { InsightsCreateButton } from "@/ui/components/App/HeaderNavigation/InsightsCreateButton";

export interface IOutletContext {
  showManageInsightsModal: boolean;
}

const InsightsDashboard = () => {
  const { hasRolesNotWith } = useWorkspaceUtilityHook();

  const { activeWorkspace } = useContext(AppLifecycleContext);
  // states to manage the header title based on the url
  const location = useLocation();
  let typeOfReport = location.pathname.split("/").pop() || "";
  const urlParams = location.pathname.split("/");
  if (urlParams.includes("feature")) {
    typeOfReport = "feature";
  }

  const getInsightsTypeName = () => {
    switch (typeOfReport) {
      case "adoption_engagement":
        return "Product Engagement";
      case "onboarding":
        return "Onboarding";
      case "retention":
        return "Retention";
      case "feature":
        return "Feature Adoption";
      case "overview":
        return "Overview";
      default:
        return "Product Insights";
    }
  };

  // get the isModalOpen state from the location state to determine if the SETUP modal should be open on load
  const { state } = useLocation();
  const shouldModalOpenOnLoad = state?.isModalOpen ? state.isModalOpen : false;
  const [showInsightSetupModal, setShowInsightSetupModal] = useState(shouldModalOpenOnLoad);

  // states to manage the manage insights modal
  const [showManageInsightsModal, setShowManageInsightsModal] = useState(false);
  // states to manage the manage feature adoption modal
  const [showManageFeatureAdoptionModal, setShowManageFeatureAdoptionModal] =
    useFeatureAdoptionStore((state) => [
      state.showManageFeatureAdoptionModal,
      state.setShowManageFeatureAdoptionModal
    ]);

  // states to manage the insights onboarding modal which is shown when the insights onboarding is not completed
  // and the user has less insights
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [insightId, setInsightId] = useState<string | undefined>("" || undefined);
  const [insightType, setInsightType] = useState<InsightTemplateType>(undefined);
  useEffect(() => {
    const showInsightsOnboarding = activeWorkspace?.is_insights_onboarding_completed !== true;

    setShowOnboardingModal(showInsightsOnboarding);
  }, [activeWorkspace.is_insights_onboarding_completed]);

  return (
    <Box>
      {/* <div className="um-mc"> */}
      {/* NOTE: For Feature Adoption Header Nav is inside Feature Adoption Component */}
      {typeOfReport !== "feature" && (
        <>
          <Header
            title={getInsightsTypeName()}
            leftSection={
              <>
                <Anchor
                  c="red"
                  href="https://www.youtube.com/watch?v=bNJ9F3pVlsI"
                  target={"_blank"}
                  rel="noreferrer">
                  <Tooltip className="!font-medium" label="Learn more about product insights">
                    <Box h={20} w={20}>
                      <FontAwesomeIcon
                        id="product-insights-youtube-video"
                        icon={brands("youtube")}
                      />
                    </Box>
                  </Tooltip>
                </Anchor>
              </>
            }
            rightSection={
              <>
                {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                  <>
                    {typeOfReport === "feature" && location.pathname.includes("feature") && (
                      <Button
                        onClick={() => {
                          setShowManageFeatureAdoptionModal(true);
                        }}
                        // variant="gradient"
                        className="mr-4"
                        // size={isMobileView ? "xs" : "sm"}
                      >
                        Manage Adoption
                      </Button>
                    )}
                    {/* show create new button if on any insight tab except overview page */}
                    {!location.pathname.includes("overview") && (
                      <InsightsCreateButton
                        setShowInsightSetupModal={setShowInsightSetupModal}
                        setInsightType={setInsightType}
                      />
                    )}
                    <Button
                      // variant="gradient"
                      onClick={() => setShowManageInsightsModal(true)}
                      // size={isMobileView ? "xs" : "sm"}
                    >
                      Manage Insights
                    </Button>
                  </>
                )}
              </>
            }></Header>
          {/* <HeaderNavigation
            is_insights_dashboard={true}
            title={getInsightsTypeName()}
            setShowInsightSetupModal={setShowInsightSetupModal}
            setShowManageInsightsModal={setShowManageInsightsModal}
            setShowManageFeatureAdoptionModal={setShowManageFeatureAdoptionModal}
            setInsightId={setInsightId}
            setInsightType={setInsightType}
          /> */}
        </>
      )}
      {showOnboardingModal && (
        <ProductInsightsOnboarding setShowOnboardingModal={setShowOnboardingModal} />
      )}
      <SetupInsightsModal
        isModalOpen={showInsightSetupModal}
        setIsModalOpen={setShowInsightSetupModal}
        insightType={insightType}
        insightId={insightId}
      />
      <ManageFeatureAdoptionModal
        isModalOpen={showManageFeatureAdoptionModal}
        setIsModalOpen={setShowManageFeatureAdoptionModal}
      />
      <ManageInsightsModal
        isModalOpen={showManageInsightsModal}
        setIsModalOpen={setShowManageInsightsModal}
      />

      <Outlet context={{ showManageInsightsModal }} />
      {/* </div> */}
    </Box>
  );
};
export default InsightsDashboard;
