import axios from './JWTService';
export class UserRetentionService {
  private readonly _endpoint;
  constructor() {
    this._endpoint = '/v1/user_retention';
  }
  cohort = (
    ws_id: any,
    insight_id: any,
    startDate: string,
    endDate: string,
    type: 'daily' | 'weekly' | 'monthly',
    reportType: 'user' | 'company',
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>,
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/cohort/${type}/${reportType}?start_date=${startDate}&end_date=${endDate}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  histogram = (
    ws_id: any,
    insight_id: any,
    startDate: string,
    endDate: string,
    type: 'daily' | 'weekly' | 'monthly',
    reportType: 'user' | 'company',
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>,
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/histogram/${type}/${reportType}?start_date=${startDate}&end_date=${endDate}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
