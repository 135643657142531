import { IconProp } from "@fortawesome/fontawesome-svg-core"
// @ts-ignore
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"
import {
  faUserPlus,
  faLaptopCode,
  faThList,
  faBadgeCheck,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons"
interface Props {
  title: string
  description?: string
  icon: IconProp
  checked?: boolean
  showChecked?: boolean
}

export const InsightEventButtonCard: FC<Props> = ({
  title,
  description = "",
  icon,
  checked,
  showChecked = true,
}) => {
  const myIcons = {
    "user-plus": faUserPlus,
    "laptop-code": faLaptopCode,
    "th-list": faThList,
    "badge-check": faBadgeCheck,
    users: faUsers,
  }

  // @ts-ignore
  const currentIcon: IconDefinition = myIcons[icon]

  return (
    <>
      <div className="flex relative items-center py-4 font-regular border border-solid border-gray-200 rounded hover:border-purple-500 cursor-pointer transition-all">
        <div className="flex item">
          <div className="flex  py-2 px-4 flex-col flex-1">
            <span className="mb-4">
              <FontAwesomeIcon icon={currentIcon} size={"2x"} className={"text-purple-400"} />
            </span>
            <h3>{title}</h3>
            <p className="pt-2">{description}</p>
          </div>
          <div className="absolute right-4">
            {showChecked && checked && (
              <FontAwesomeIcon
                icon={solid("check-circle")}
                size={"1x"}
                className={checked ? "text-green-000" : "text-gray-400"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
