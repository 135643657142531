import { Box, lighten, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { FUNNEL_BREAKDOWN_COLORS } from "@/lib/utils/Constants";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface ILabelProps {
  /**
   * This label will be shown on the bar
   */
  label: string;

  /**
   * Position of the label
   */
  position: "top" | "bottom";

  /**
   * color of the label
   */
  color?: string;
}

const Label = ({ label, position, color = FUNNEL_BREAKDOWN_COLORS[0] }: ILabelProps) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Box
      style={{
        overflow: "hidden",
        borderRadius: "8px",
        fontSize: "10px",
        fontWeight: 600,
        position: "absolute",
        top: position === "top" ? "-20px" : "100%",
        left: "5px",
        textAlign: "center",
        width: "calc(100% - 10px)",
        zIndex: 1,
        color: colorScheme === "dark" ? theme.colors.dark[0] : color,
        backgroundColor: colorScheme === "dark" ? "#2c2e33" : lighten(color, 0.8),
        boxShadow: theme.shadows.sm,
        minWidth: "max-content",
        border: `1px solid ${colorScheme === "dark" ? "transparent" : lighten(color, 0.78)}`,
        textTransform: "capitalize"
      }}>
      <Box bg="transparent" style={{ backdropFilter: "blur(10px)" }}>
        <Box bg="transparent">{label}</Box>
      </Box>
    </Box>
  );
};

export default Label;
