import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ActionIcon, Button, Flex } from "@mantine/core"
import React from "react"

interface INotesMenu {
  editor: any
  onCancel: (e: any) => void
  onSave: (content: string) => void
}
export const NotesMenu: React.FC<INotesMenu> = ({ editor, onCancel, onSave }) => {
  return (
    <>
      <Flex>
        <Flex
          mt={8}
          mb={8}
          sx={{
            flexGrow: 1,
          }}
        >
          <ActionIcon
            mr={8}
            size="xs"
            variant="outline"
            color="gray.7"
            onClick={() => editor.chain().focus().toggleBold().run()}
            //   disabled={!editor.can().chain().focus().toggleBold().run()}
            className={editor.isActive("bold") ? "is-active" : ""}
          >
            <FontAwesomeIcon icon={solid("bold")} />
          </ActionIcon>
          <ActionIcon
            mr={8}
            size="xs"
            variant="outline"
            color="gray.7"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            //   disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={editor.isActive("italic") ? "is-active" : ""}
          >
            <FontAwesomeIcon icon={solid("italic")} />
          </ActionIcon>
          <ActionIcon
            size="xs"
            variant="outline"
            color="gray.7"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            //   disabled={!editor.can().chain().focus().toggleStrike().run()}
            className={editor.isActive("strike") ? "is-active" : ""}
          >
            <FontAwesomeIcon icon={solid("strikethrough")} />
          </ActionIcon>
        </Flex>
        <Flex>
          <Button onClick={onCancel} size="xs" color="red" variant="subtle" mr={16}>
            Cancel
          </Button>
          <Button onClick={() => onSave(editor.getHTML())} size="xs">
            Save
          </Button>
        </Flex>
      </Flex>
    </>
  )
}
