import { Box, Center, Divider, Grid, Loader, Text, useMantineColorScheme } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { Fragment, useContext, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import update from "immutability-helper";
import { ChannelMappingRow, ChannelMappingRowType } from "./ChannelMappingRow";
import { CHANNEL_MAPPING_COLUMNS } from "./ChannelMappingUtility";
import { useChannelMappingListStore } from "@/stores/useChannelMappingListStore";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { LoadingComponent } from "../../Common/Loading/Loading";
import { useChannelMappingStore } from "@/stores/useChannelMappingStore";

const ChannelMappingTable = () => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [
    mappingList,
    setMappingList,
    loading,
    fetchChannelMappings,
    updateAllMappingPriorities,
    deleteChannelMapping
  ] = useChannelMappingListStore((state) => [
    state.mappingList,
    state.setMappingList,
    state.loading,
    state.fetchChannelMappings,
    state.updateAllMappingPriorities,
    state.deleteChannelMapping
  ]);

  const [setModalOpen, setId] = useChannelMappingStore((state) => [
    state.setModalOpen,
    state.setId
  ]);

  const handleEditMapping = (id: string) => {
    setId(id);
    setModalOpen(true);
  };

  const onDeleteMapping = (id: string) => {
    openConfirmModal({
      title: "Delete Channel Mapping",
      children: (
        <Text size="sm">
          Are you sure you want to delete this mapping? This action cannot be undone.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6", className: "confirmation-modal-confirm-btn" },

      onCancel: () => {},
      onConfirm: () => {
        deleteChannelMapping(activeWorkspace.id, id);
        toast.success("Channel Mapping deleted successfully");
      }
    });
  };

  /**
   * Function to handle drag and drop of the Channel mapping rows
   */
  const moveMappingRow = (dragIndex: number, hoverIndex: number) => {
    // get the item that is being dragged
    const dragChannelMapping = mappingList[dragIndex];

    // update the list with the new order
    // The `update` function is used to manipulate collections immutably.
    // Here, we're creating a new list from `mappingList` with two operations:
    // 1. Remove the item at `dragIndex` (the index of the item being dragged).
    // 2. Insert the `dragChannelMapping` (the item being dragged) at `hoverIndex`
    // (the index where the item is being dragged over).
    const newList = update(mappingList, {
      $splice: [
        [dragIndex, 1], // Remove the item at `dragIndex`.
        [hoverIndex, 0, dragChannelMapping] // Insert `dragChannelMapping` at `hoverIndex`.
      ]
    });

    // Update the priority of the items
    newList.forEach((item, index) => {
      item.priority = index + 1;
    });

    // Update the store with the new list
    setMappingList(newList);
  };

  const dropMappingRow = (originalList: ChannelMappingRowType[] = []) => {
    // Drag and drop is complete
    // So we need to update the priority of the mappings
    // in the database
    updateAllMappingPriorities(activeWorkspace.id, originalList)
      .then(() => {
        toast.success("Channel mapping priority updated successfully");
      })
      .catch((error) => {
        toast.error("Channel mapping priority update failed");
      });
  };

  useEffect(() => {
    fetchChannelMappings(activeWorkspace.id);
  }, []);

  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      {loading === "loaded" ? (
        mappingList && mappingList.length > 0 ? ( // loaded and has data
          <DndProvider backend={HTML5Backend}>
            <Box>
              <Box>
                {/* Title Row */}
                <Grid
                  bg={colorScheme === "light" ? "gray.1" : undefined}
                  m={0}
                  align="center"
                  justify="space-between"
                  columns={12}
                  py={"sm"}>
                  {CHANNEL_MAPPING_COLUMNS.map((column) => {
                    return (
                      <Fragment key={column.key}>
                        {column.title && (
                          <Grid.Col span={column.span}>
                            <Text ta="center" fw={600}>
                              {column.title}
                            </Text>
                          </Grid.Col>
                        )}
                      </Fragment>
                    );
                  })}
                </Grid>
                <Divider />

                {/* Channel Mapping Rows */}
                {mappingList.map((row, index) => (
                  <ChannelMappingRow
                    index={index}
                    key={row.id}
                    row={row}
                    onDeleteMapping={onDeleteMapping}
                    handleEditMapping={handleEditMapping}
                    moveMappingRow={moveMappingRow}
                    dropMappingRow={dropMappingRow}
                    nameIdentifier="ChannelMappingRow"
                  />
                ))}
              </Box>
            </Box>
          </DndProvider>
        ) : (
          // loaded and no data
          <>
            <Text ta="center" py="md">
              No custom channel mappings created
            </Text>
          </>
        )
      ) : (
        // Loading state
        <Center>
          <Loader size="xs" py="md" />
        </Center>
      )}
    </>
  );
};

export default ChannelMappingTable;
