/**
 * Segment Form Context
 */

import { createFormContext } from "@mantine/form";
import { ISegmentCondition } from "./SegmentCondition";
import { ISegmentGroup } from "./SegmentGroup";

export interface ISegmentsQueryBuilderForm {
  combinator: "and" | "or";
  rules: ISegmentCondition[];
  groups: ISegmentGroup[];
  // groups: ISegmentsQueryBuilderForm[]; // this will support nested groups
}

// You can give context variables any name
export const [SegmentFormProvider, useSegmentFormContext, useSegmentForm] =
  createFormContext<ISegmentsQueryBuilderForm>();
