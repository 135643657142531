/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Flex, Text, useMantineTheme, Button, Grid, Title, Divider } from "@mantine/core";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { DATE_FORMAT, MOBILE_BREAKPOINT_QUERY } from "@/lib/utils/Constants";
import FeatureDetail from "./FeatureAdoption/FeatureDetail";
import FeatureAdoptionOverview from "./FeatureAdoption/Overview";
import { FeatureAdoptionService } from "@/lib/services/FeatureAdoptionService";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import useFeatureAdoptionStore from "@/stores/useFeatureAdoptionStore";
import ManageFeatureAdoptionModal from "../Components/Modals/ManageFeatureAdoptionModal";
import {
  InsightsDashboardHeader,
  InsightsDashboardHeaderLeft,
  InsightsDashboardHeaderRight
} from "../Components/InsightsDashboardStyle/InsightsDashboardStyle.style";
import { ReportTypeToggle } from "@/ui/components/App/ReportTypeToggle/ReportTypeToggle";
import { IReportType } from "@/types/types.d";
import { SegmentDropdownMenu } from "@/ui/components/App/Dropdowns/SegmentDropdownMenu";
import { DateDropdownMenu } from "@/ui/components/App/Dropdowns/DateDropdownMenu";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { useWebFiltersStore } from "@/stores/useWebFiltersStore";
import { useSegmentFilterStore } from "@/stores/useSegmentFilterStore";
import { useSegmentListStore } from "@/stores/useSegmentListStore";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { ISegmentsQueryBuilderForm } from "@/ui/components/App/Segments/SegmentFormContext";
import { ColorsTheme } from "@/style/Colors";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { getLeftPaneCollapsePreference, setLeftPaneCollapsePreference } from "@/lib/utils/Storage";
import { FeatureAdoptionLeftPane } from "../Components/FeatureAdoption/FeatureAdoptionLeftPane";
import { FeatureAdoptionDateMenu } from "../../Insights/Trends/FeatureAdoption/Components/FeatureAdoptionDateMenu";
import { createStyles } from "@mantine/emotion";

const useStyles = createStyles((theme) => ({
  headerNavigationContainer: {
    // height: "50px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    // borderBottom: "solid 1px",
    padding: "1rem 0rem",
    borderColor:
      theme.colorScheme === "dark"
        ? theme.colors.darkBorderColor[0]
        : theme.colors.lightBorderColor[0]
  },
  insightsNav: {
    width: "210px",
    height: "100vh",
    // background: "white",
    overflowY: "auto",
    position: "fixed",
    zIndex: 1,
    boxShadow: "5px 0 10px rgba(0, 0, 0, 0.03)"
  },
  insightsContent: {
    flex: "1",
    width: "calc(100%)",
    WebkitTransition: "left 0.2s ease",
    transition: "left 0.2s ease",
    // padding: "8px", // Equivalent to @apply px-8
    overflowY: "scroll"
  },
  insightsNavInner: {
    flex: "1 1 0px",
    position: "relative",
    display: "flex",
    padding: "1rem",
    flexDirection: "column"
  },
  insightsNavInnerHeader: {
    // height: "50px",
    paddingBottom: "0.5rem",
    h2: {
      paddingLeft: "0.9325rem",
      fontWeight: 500,
      color: theme.colorScheme === "dark" ? theme.colors.gray[6] : theme.colors.dark[5],
      lineHeight: "2.5rem"
    }
  },
  insightsActiveLink: {
    color: theme.colors.brand[5],
    // fontWeight: 700,
    textDecorationLine: "none"
  },
  insightsOverviewNavLink: {
    color: theme.colors.gray[9],
    fontWeight: 500,
    textDecorationLine: "none",
    includes: ["transition(all, 0.3s, ease-in-out)", "hover", "focus"]
  },
  insightsOtherNavLink: {
    marginLeft: "-1rem",
    a: {
      display: "flex",
      textDecoration: "none",
      borderRadius: "0.25rem",
      padding: "0.625rem 0.325rem",
      includes: ["transition(all, 0.3s, ease-in-out)", "hover", "focus"]
    },
    "&:hover": {
      transition: "all 0.3s ease-in-out",
      div: {
        span: {
          color: "rgba(131, 71, 235, 0.9)"
        }
      }
    }
  },
  insightsOverviewNavLinkWithoutHover: {
    // backgroundImage: `transparent`,
    // borderRadius: "0.25rem"
  },
  insightsNavItemsInfo: {
    // marginLeft: "-0.625rem",
    display: "flex",
    flexDirection: "column",
    span: {
      color: theme.colors.gray[7],
      fontWeight: 500,
      paddingLeft: "0.75rem"
    },
    div: {
      display: "flex",
      alignItems: "center",
      span: {
        paddingLeft: "0rem"
      }
    }
  },
  activeOverviewNavigationLink: {
    // svg: {
    //   color: "#fff"
    // },
    // backgroundImage: `linear-gradient(90deg, rgba(131, 71, 235, 0.9), rgba(131, 71, 235, 0.75) 34%, rgba(131, 71, 235, 0.65))`,
    // borderRadius: "0.25rem"
    // div: {
    //   span: {
    //     color: "#fff"
    //   }
    // }
  },
  activeOtherNavigationLink: {
    div: {
      span: {
        color: "rgba(131, 71, 235, 0.9)"
      }
    }
  }
}));

type SlugParams = {
  workspaceIdentifier: string;
};

export interface AdoptionFeature {
  feature_id: string;
  feature_name: string;
  active_users: number;
  avg_usage: number;
  awareness: number;
  awareness_ratio: number;
  adoption: number;
  adoption_ratio: number;
  days_active: number;
  frequency: number;
  frequency_category: string;
  retention: number;
  retention_ratio: number;
  engagement_histogram: number[];
  previous_feature_name?: string;
  previous_active_users?: number;
  previous_avg_usage?: number;
  previous_awareness?: number;
  previous_awareness_ratio?: number;
  previous_adoption?: number;
  previous_adoption_ratio?: number;
  previous_days_active?: number;
  previous_frequency?: number;
  previous_frequency_category?: string;
  previous_retention?: number;
  previous_retention_ratio?: number;
  previous_engagement_histogram?: number[];
  awareness_diff?: number;
  adoption_diff?: number;
  retention_diff?: number;
}

export interface AdoptionModule {
  module_id: string;
  module: string;
  features: AdoptionFeature[];
  expanded?: boolean;
  color?: string;
}

export interface ExtendedAdoptionModule extends AdoptionModule {
  color: string;
  isActive: boolean;
}

export interface Feature {
  id: string;
  name: string;
  conditions: any;
  adoption_rate: number;
  retention_period: string;
  is_new: boolean;
  is_active: boolean;
  is_saved: boolean;
}

interface Module {
  id: string;
  name: string;
  features: Feature[];
  is_new: boolean;
  is_active: boolean;
  is_saved: boolean;
}

// Define an interface for the state type
interface DropdownFilterDate {
  startDate: string;
  endDate: string;
}

// Initialize the state using the interface
const initialDropdownFilterDate: DropdownFilterDate = {
  startDate: format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
  endDate: format(endOfDay(new Date()), DATE_FORMAT)
};

const InsightsDashboardFeatureAdoption = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { workspaceIdentifier } = useParams<SlugParams>();
  let { moduleId, featureId } = useParams();

  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);
  const { activeWorkspace, isCollapsedLeftMenu, setIsCollapsedLeftMenu } =
    useContext(AppLifecycleContext);

  const [isFeaturesListLoading, setIsFeaturesListLoading] = useState("idle");
  // const [moduleList, setModuleList] = useState<Module[]>([]);
  const [activeModule, setActiveModule] = useState<Module | null>(null);
  const [activeFeature, setActiveFeature] = useState<Feature | null>(null);
  const [isFeaturesReportLoading, setIsFeaturesReportLoading] = useState("idle");

  const [
    onboardingFeatureAdoption,
    showManageFeatureAdoptionModal,
    setShowManageFeatureAdoptionModal,
    moduleList,
    setModuleList
  ] = useFeatureAdoptionStore((state) => [
    state.onboardingFeatureAdoption,
    state.showManageFeatureAdoptionModal,
    state.setShowManageFeatureAdoptionModal,
    state.modules,
    state.setModules
  ]);

  const [reportType, setReportType] = useState<IReportType>(
    activeWorkspace.reporting_insights_preference
  );
  const [adoptionReportData, setAdoptionReportData] = useState<ExtendedAdoptionModule[]>([]);
  const [dropdownFilterDate, setDropdownFilterDate] =
    useState<DropdownFilterDate>(initialDropdownFilterDate);
  const [cohortValuesType, setCohortValuesType] = useState<"numbers" | "ratio" | string>("ratio");

  const [setDateLabel] = useWebFiltersStore((state) => [state.setDateLabel]);
  /** Segment filter store **/
  const [filterVisible, setFilterVisible] = useSegmentFilterStore((state) => [
    state.filterVisible,
    state.setFilterVisible
  ]);
  /** Segments Store **/
  const [segments] = useSegmentListStore((state) => [state.segments]);
  /** Segment Query builder store **/
  const [filters, setFilters, resetExceptFilters, reset, setSegmentModalOpen] = useSegmentStore(
    (state) => [
      state.filters,
      state.setFilters,
      state.resetExceptFilters,
      state.reset,
      state.setModalOpen
    ]
  );

  const onChangeDate = (startDate: string, endDate: string) => {
    if (moduleId && featureId) {
      getFeatureAdoptionReport(
        startDate,
        endDate,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups,
        true, // comparison
        moduleId,
        featureId
      );
    } else {
      getFeatureAdoptionReport(
        startDate,
        endDate,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups
      );
    }
    setDropdownFilterDate({
      startDate: startDate,
      endDate: endDate
    });
  };

  const onClearFiltersChange = () => {
    // NOTE: Call onChange with default values on clear filters
    onChangeDate(dropdownFilterDate.startDate, dropdownFilterDate.endDate);
  };

  const onApplyChange = (filters: ISegmentsQueryBuilderForm) => {
    if (moduleId && featureId) {
      getFeatureAdoptionReport(
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups,
        true, // comparison
        moduleId,
        featureId
      );
    } else {
      getFeatureAdoptionReport(
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups
      );
    }
  };

  const featureService = new FeatureAdoptionService();

  const getModulesAndFeatures = async () => {
    setIsFeaturesListLoading("loading");
    await featureService
      .getFeatures(activeWorkspace.id)
      .then((res) => {
        if (res.data) {
          const apiResponse = res.data;
          setModuleList(apiResponse);

          // Set Active Module and Feature
          if (moduleId && featureId) {
            const module = apiResponse.find((m: any) => m.id === moduleId);
            const feature = module?.features.find((f: any) => f.id === featureId);
            setActiveModule(module || null);
            setActiveFeature(feature || null);

            // Set Module Nav Active
            setModuleList(
              moduleList.map((module) =>
                module.id === moduleId ? { ...module, is_active: true } : module
              )
            );
          }
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsFeaturesListLoading("loaded");
      });
  };

  const getFeatureAdoptionReport = async (
    startDate = format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
    endDate = format(endOfDay(new Date()), DATE_FORMAT),
    reportType: IReportType = "user",
    audienceConditions: Array<any> = [],
    audienceConditionType: string = "or",
    audienceGroupConditions: Array<any> = [],
    comparison: boolean = true,
    moduleId?: string,
    featureId?: string
  ) => {
    setIsFeaturesReportLoading("loading");

    await featureService
      .report(
        activeWorkspace.id,
        startDate,
        endDate,
        reportType,
        audienceConditions,
        audienceConditionType,
        audienceGroupConditions,
        comparison,
        moduleId && moduleId,
        featureId && featureId
      )
      .then((res) => {
        setIsFeaturesReportLoading("loaded");
        if (res.data?.length > 0) {
          // Add color to each feature
          let apiResponse = res.data;
          const updatedApiResponse = apiResponse.map((module: any, index: number) => ({
            ...module,
            color: ColorsTheme[index % ColorsTheme.length],
            isActive: true
          }));

          setAdoptionReportData(updatedApiResponse);
        }
        return true;
      })
      .catch((err) => {
        if (err?.response?.status === 422 || err?.response?.status === 500) {
          setIsFeaturesReportLoading("loaded");
        }
        return false;
      });
  };

  useEffect(() => {
    // setLeftPaneCollapsePreference(isCollapsedLeftMenu);
    // setIsCollapsedLeftMenu(true);

    getModulesAndFeatures();
    getFeatureAdoptionReport(
      dropdownFilterDate.startDate,
      dropdownFilterDate.endDate,
      reportType,
      filters.rules,
      filters.combinator,
      filters.groups
    );
    return () => {
      // setIsCollapsedLeftMenu(getLeftPaneCollapsePreference());
    };
  }, [activeWorkspace, onboardingFeatureAdoption]);

  useEffect(() => {
    document.title = "Insights | Feature Adoption | Usermaven";
    setAdoptionReportData([]);
    if (moduleId && featureId) {
      const module = moduleList.find((m: any) => m.id === moduleId);
      const feature = module?.features.find((f: any) => f.id === featureId);
      setActiveModule(module || null);
      setActiveFeature(feature || null);

      navigate(`/env/${workspaceIdentifier}/insights/dashboard/feature/${moduleId}/${featureId}`, {
        replace: true
      });
      getFeatureAdoptionReport(
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups,
        true, // comparison
        moduleId,
        featureId
      );
    } else {
      // Set All Module Navs Inactive
      setModuleList(moduleList.map((module) => ({ ...module, is_active: false })));
      getFeatureAdoptionReport(
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups
      );
    }
  }, [moduleId, featureId, workspaceIdentifier, reportType]);

  useEffect(() => {
    if (!showManageFeatureAdoptionModal) {
      getFeatureAdoptionReport(
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate,
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups,
        true, // comparison
        moduleId,
        featureId
      );
    }
  }, [showManageFeatureAdoptionModal]);

  if (isFeaturesListLoading !== "loaded") {
    return <LoadingComponent />;
  }

  return (
    <Box>
      <FeatureAdoptionLeftPane
        setActiveFeature={setActiveFeature}
        setActiveModule={setActiveModule}
      />
      {/* <Grid columns={12}>
        <Grid.Col span={moduleList.length === 0 ? 12 : 10}></Grid.Col>
      </Grid> */}
      <Box ml={244} px={"md"} className={"subnav-content-container"}>
        <Box className={classes.headerNavigationContainer}>
          <Title order={4}>
            {moduleId && featureId
              ? `${activeModule?.name} / ${activeFeature?.name && toTitleCase(activeFeature?.name)}`
              : moduleList.length === 0
              ? "Feature Adoption"
              : "Group Report"}
          </Title>
          {moduleList.length !== 0 && (
            <>
              <Button
                // variant="gradient"
                variant="outline"
                onClick={() => {
                  setShowManageFeatureAdoptionModal(true);
                }}
                size={isMobileView ? "xs" : "sm"}>
                Manage Features
              </Button>
            </>
          )}
        </Box>
        <Divider mb="md" />

        {moduleList.length !== 0 && (
          <>
            <Flex justify={"space-between"} mb="md">
              <Flex>
                <SegmentDropdownMenu
                  type={reportType}
                  onChange={(value: any) => {
                    if (!value) {
                      reset();
                      onClearFiltersChange();
                      return;
                    }
                    setFilters(segments.find((s: any) => s.id === value).filters);
                    setFilterVisible(false);
                    onApplyChange(segments.find((s: any) => s.id === value).filters);
                  }}
                />
                <Box ml={8}></Box>
                <ReportTypeToggle
                  reportType={reportType}
                  setReportType={(value: IReportType) => {
                    reset();
                    setReportType(value);
                  }}
                />
              </Flex>
              <Flex>
                <FeatureAdoptionDateMenu onChange={onChangeDate} />
              </Flex>
            </Flex>
          </>
        )}
        <Divider mb="md" />

        {moduleList.length === 0 ? (
          <Flex justify={"center"} align={"center"} direction={"column"} h={200}>
            <Text mb="md">
              <FontAwesomeIcon icon={light("filter-list")} size="2xl" />
            </Text>

            <Text mb="md">You have not setup any features criteria yet.</Text>
            <Button size="sm" onClick={() => setShowManageFeatureAdoptionModal(true)}>
              Setup
            </Button>
          </Flex>
        ) : adoptionReportData.length === 0 && isFeaturesReportLoading === "loaded" ? (
          <Flex justify={"center"} align={"center"} my="xl">
            <NoResults heading={"No results"} text={"There is no activity"} />
          </Flex>
        ) : (
          <Box>
            {moduleId && featureId ? (
              <FeatureDetail
                adoptionReportData={adoptionReportData}
                isFeaturesReportLoading={isFeaturesReportLoading}
                reportType={reportType}
                moduleId={moduleId}
                featureId={featureId}
                dropdownFilterDate={dropdownFilterDate}
                filters={filters}
                activeFeature={activeFeature}
              />
            ) : (
              <FeatureAdoptionOverview
                reportType={reportType}
                adoptionReportData={adoptionReportData}
                setAdoptionReportData={setAdoptionReportData}
                isFeaturesReportLoading={isFeaturesReportLoading}
              />
            )}
          </Box>
        )}
      </Box>
      <ManageFeatureAdoptionModal
        isModalOpen={showManageFeatureAdoptionModal}
        setIsModalOpen={setShowManageFeatureAdoptionModal}
      />
    </Box>
  );
};

export default InsightsDashboardFeatureAdoption;
