// @ts-ignore
import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext } from "react";

export const TeamMemberBillingView = () => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const subscriptionPaused = () => {
    return (
      activeWorkspace?.organization?.subscriptions?.length > 0 &&
      activeWorkspace?.organization?.subscriptions[0].paddle_status === "paused"
    );
  };

  const subscriptionCancelled = () => {
    return (
      activeWorkspace?.organization?.subscriptions?.length > 0 &&
      activeWorkspace?.organization?.subscriptions[0].paddle_status === "deleted"
    );
  };

  return (
    <>
      {subscriptionPaused() ? (
        <>
          <div className="flex justify-center flex-col items-center py-4">
            <FontAwesomeIcon
              icon={solid("circle-pause")}
              className="text-3xl mb-4 text-orange-600"
            />
            <h3 className="font-semibold pb-4">Your subscription is paused</h3>
            <p className="w-3/5 text-center">
              Your subscription is paused by{" "}
              <span className="font-semibold">
                {activeWorkspace.organization.creator.full_name} (
                {activeWorkspace.organization.creator.email})
              </span>
              . Please contact your organization owner to resume subscription.
            </p>
          </div>
        </>
      ) : (
        <>
          {subscriptionCancelled() ? (
            <>
              <div className="flex justify-center flex-col items-center py-4">
                <FontAwesomeIcon icon={solid("ban")} className="text-3xl mb-4 text-red-600" />
                <h3 className="font-semibold pb-4">Your subscription is cancelled</h3>
                <p className="w-3/5 text-center">
                  Your subscription is cancelled. Please contact account owner{" "}
                  <span className="font-semibold">
                    {activeWorkspace.organization.creator.full_name} (
                    {activeWorkspace.organization.creator.email})
                  </span>{" "}
                  to renew your subscription to avoid any interruption.
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-center flex-col items-center py-4">
                <FontAwesomeIcon icon={solid("ban")} className="text-3xl mb-4 text-red-600" />
                <h3 className="font-semibold pb-4">
                  You do not have permission to access this resource
                </h3>
                <p className="w-3/5 text-center">
                  Your account owner{" "}
                  <span className="font-semibold">
                    {activeWorkspace.organization.creator.full_name} (
                    {activeWorkspace.organization.creator.email})
                  </span>{" "}
                  can only access this page. If you'd like to change your subscription, please
                  contact your organization owner.
                </p>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
