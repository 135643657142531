/**
 * Function to convert an event to the conditions for Pinned event query builder.
 *
 * @param event An event object from clickhouse, it contains the attributes text_label, destination_url, source_page_url etc.
 * and transforms it into a format which our conditional rules.
 * @returns an array of conditions for the conditional rules.
 */
export const translateEventToConditions = (event: any): any[] => {
  let conditions = []
  if (event.text_label) {
    conditions.push({
      selector: "label_text",
      operator: "is",
      value: event.text_label,
    })
  }
  if (event.destination_url) {
    conditions.push({
      selector: "destination_url",
      operator: "is",
      value: event.destination_url,
    })
  }
  if (event.source_page_url) {
    conditions.push({
      selector: "source_page_url",
      operator: "is",
      value: event.source_page_url,
    })
  }
  if (event?.autocaptured_attr_class) {
    conditions.push({
      selector: "css_attribute",
      operator: "is",
      value: event.autocaptured_attr_class,
    })
  }
  if (event?.autocaptured_attr_id) {
    conditions.push({
      selector: "element_id",
      operator: "is",
      value: event.autocaptured_attr_id,
    })
  }
  if (event?.autocaptured_attr_name) {
    conditions.push({
      selector: "element_name",
      operator: "is",
      value: event.autocaptured_attr_name,
    })
  }
  return conditions
}

/**
 * Accept multiple events, and convert them to the conditional rules for query builder.
 * @param selectedEvents
 * @returns
 */
export const translateEventToConditionsWithRules = (selectedEvents: any): any[] => {
  console.log(selectedEvents)
  let rules: any = []
  selectedEvents.forEach((event: any) => {
    const conditions = translateEventToConditions(event)
    rules.push({
      isEdit: true,
      conditions: conditions,
    })
  })
  return rules
}
