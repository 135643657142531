import create from "zustand";
import { ConditionItem, LoadingStateProps } from "../types/types.d";
import { JourneyService } from "@/lib/services/JourneyService";

interface JourneyStoreProps {
  /**
   * Modal open/close state
   */
  modalOpen: boolean;
  /**
   * Set modal instance open/close.
   * @param open boolean
   * @returns
   */
  setModalOpen: (open: boolean) => void;

  /**
   * Attribution loader
   */
  loading: LoadingStateProps;

  /**
   * Set attribution loader
   */
  setLoading: (loading: LoadingStateProps) => void;

  /**
   * id of the insight
   */
  id: string;

  /**
   * Set id of the insight
   */
  setId: (id: string) => void;

  /**
   * Journey name
   */
  name: string;

  /**
   * Set journey name
   */
  setName: (name: string) => void;

  /**
   * Journey Description
   */
  description: string;

  /**
   * Set journey description
   */
  setDescription: (description: string) => void;

  /**
   * Journey Flow
   */
  flow: "after" | "before";

  /**
   * Set journey flow
   */
  setFlow: (flow: "after" | "before") => void;

  /**
   * Journey Path Type
   */
  pathType: "session-based" | "user-based";

  /**
   * Set journey path type
   */
  setPathType: (pathType: "session-based" | "user-based") => void;

  /**
   * Condition item
   */
  condition: ConditionItem;

  /**
   * Set condition item
   */
  setCondition: (condition: ConditionItem) => void;

  /**
   * Exclusion conditions item
   */
  exclusionConditions: ConditionItem[];

  /**
   * Set exclusion conditions item
   */
  setExclusionConditions: (exclusionConditions: ConditionItem[]) => void;

  /**
   * Grouping rules with aliases and regex
   */
  groupingRules: Array<{
    alias: string;
    regex: string;
  }>;

  /**
   * Set grouping rules with aliases and regex
   */
  setGroupingRules: (groupingRules: Array<{ alias: string; regex: string }>) => void;

  /**
   * Reset all the journey store values
   */
  resetJourneyStore: () => void;

  /**
   * Set journey store values
   */
  setJourneyStore: (journey: any) => void;

  /**
   * Create journey API call using the journey store values
   */
  createJourney: (workspaceId: string) => void;
}

export const useJourneyStore = create<JourneyStoreProps>((set) => ({
  modalOpen: false,
  setModalOpen: (open: boolean) => set({ modalOpen: open }),
  loading: "idle",
  setLoading: (loading: LoadingStateProps) => set({ loading }),
  condition: {
    key: "",
    value: "",
    operator: "",
    type: "pageview",
    label: "",
    ignoreQueryParams: true
  },
  id: "",
  setId: (id: string) => set({ id }),
  name: "",
  setName: (name: string) => set({ name }),
  description: "",
  setDescription: (description: string) => set({ description }),
  flow: "after",
  setFlow: (flow: "after" | "before") => set({ flow }),
  pathType: "session-based",
  setPathType: (pathType: "session-based" | "user-based") => set({ pathType }),
  setCondition: (condition: ConditionItem) => set({ condition }),
  exclusionConditions: [],
  setExclusionConditions: (exclusionConditions: ConditionItem[]) => set({ exclusionConditions }),
  groupingRules: [],
  setGroupingRules: (groupingRules: Array<{ alias: string; regex: string }>) =>
    set({ groupingRules }),
  resetJourneyStore: () =>
    set({
      modalOpen: false,
      loading: "idle",
      condition: {
        key: "",
        value: "",
        operator: "",
        type: "pageview",
        label: "",
        ignoreQueryParams: true
      },
      id: "",
      name: "",
      description: "",
      flow: "after",
      pathType: "session-based",
      exclusionConditions: [],
      groupingRules: []
    }),
  setJourneyStore: (journey: any) =>
    set({
      modalOpen: false,
      loading: "idle",
      condition: journey.condition,
      id: journey.id,
      name: journey.name,
      description: journey.description,
      flow: journey.flow,
      pathType: journey.pathType,
      exclusionConditions: journey.exclusionConditions,
      groupingRules: journey.groupingRules
    }),
  createJourney: async (workspaceId) => {
    const {
      name,
      description,
      flow,
      pathType,
      condition,
      exclusionConditions,
      groupingRules,
      resetJourneyStore
    } = useJourneyStore.getState();

    // Calling the API with the values from the store
    await new JourneyService().createJourney(workspaceId, {
      name: name,
      description: description,
      journey_flow: flow,
      journey_path_type: pathType,
      conditions: JSON.stringify([
        {
          type: condition.type,
          value: condition.value,
          ignoreQueryParams: condition.ignoreQueryParams || true
        }
      ]),
      exclusion_rules: exclusionConditions,
      grouping_rules: groupingRules
    });
    // API call succeeded, now reset the store
    resetJourneyStore();
  }
}));
