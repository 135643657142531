import { InsightShareService } from '@/lib/services/InsightShareService';
import { LoadingStateProps } from 'types/types.d';
import create from 'zustand';

interface Props {
  /**
   * Insight id
   */
  insightId: string;
  /**
   * Set insight id
   */
  setInsightId: (id: string) => void;
  /**
   * Is Public share enabled
   */

  isPublicShareEnabled: boolean;
  /**
   * Set is public share enabled
   *
   */
  setIsPublicShareEnabled: (isPublicShareEnabled: boolean) => void;
  /**
   * Is authorized to view insight
   */
  isAuthorized: boolean;
  /**
   * Set is authorized to view insight
   * @param isAuthorized
   * @returns
   */
  setIsAuthorized: (isAuthorized: boolean) => void;
  /**
   * Is loading insight by secret
   */
  isLoading: LoadingStateProps;
  /**
   * Set is loading insight by secret
   * @param isLoading
   * @returns
   * */
  setIsLoading: (isLoading: LoadingStateProps) => void;
  /**
   * Fetch insight by secret
   */
  fetchInsightBySecret: (workspaceId: string, secret: string) => Promise<string | null>;
}

export const useInsightShareableStore = create<Props>((set) => ({
  insightId: '',
  setInsightId: (id: string) => set({ insightId: id }),
  isAuthorized: false,
  setIsAuthorized: (isAuthorized: boolean) => set({ isAuthorized }),
  isLoading: 'idle',
  setIsLoading: (isLoading: LoadingStateProps) => set({ isLoading }),
  isPublicShareEnabled: false,
  setIsPublicShareEnabled: (isPublicShareEnabled: boolean) => set({ isPublicShareEnabled }),
  fetchInsightBySecret: async (workspaceId: string, secret: string) => {
    set({ isLoading: 'loading' });
    // Send the request to get the insight id.
    const res = await new InsightShareService()
      .getBySecret(workspaceId, secret || '')
      .then((res) => {
        if (res.data) {
          set({
            isAuthorized: true,
            insightId: res.data.insight_id,
            isPublicShareEnabled: res.data.is_public
          });
        }
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          set({ isAuthorized: false });
        }
        return null;
      });
    set({ isLoading: 'loaded' });
    if (res) {
      return res.insight_id || null;
    }
    return null;
  }
}));
