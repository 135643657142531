import { stringify } from 'query-string';
import { UsersCRMViewType } from 'types/types.d';
import axios from './JWTService';

export class DashboardService {
  private _startDate: string;
  private _endDate: string;
  private _wsId: string;

  constructor(wsId: string, startDate: string = '', endDate: string = '') {
    this._startDate = startDate;
    this._endDate = endDate;
    this._wsId = wsId;
  }
  companiesCount = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/dashboard/${this._wsId}/companies_count`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  usersCount = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/dashboard/${this._wsId}/people_count`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  userStickinessAvg = (
    ws_id: any,

    type: 'dau_mau_avg' | 'dau_wau_avg' | 'wau_mau_avg'
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/dashboard/${ws_id}/user_stickiness/${type}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  userAcquisitionByChannel = (query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/dashboard/${this._wsId}/attribution?${stringify(query)}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  featureUsageHistogram = (query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/dashboard/${this._wsId}/feature_usage?${stringify(query)}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
