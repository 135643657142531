import { Anchor, Box, Button, Flex, Text, TextInput, Tooltip } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import ReactTooltip from "react-tooltip";
import { ClickhouseDateToLocalRelativeTime } from "@/lib/utils/DateUtility";

const FormsActivity = () => {
  const [formId, setFormId] = useState("");
  const isMobile = useMediaQuery("(max-width: 768px)");


  return (
    <>
      <div className="um-settings-wrapper pt-0">
        <Box
          component="div"
          sx={(theme) => ({
            border: `1px solid`,
            borderColor:
              theme.colorScheme === "dark"
                ? theme.colors.darkBorderColor[0]
                : theme.colors.lightBorderColor[0],
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : "#fff"
          })}
          className="um-settings-wrapper--container shadow-sm">
          <div className="flex py-4 items-center gap-4">
            <div>
              <TextInput
                value={formId}
                onChange={(event) => setFormId(event.currentTarget.value)}
                placeholder="Search form by ID or class" />
            </div>
            <div>
              <Tooltip label={"Learn how to track forms"}>
                <Anchor
                  href="https://usermaven.com/docs/getting-started/sending-custom-events"
                  target="_blank"
                  className="primary-link-color"
                  rel="noreferrer">
                  <Flex align="center">
                    <FontAwesomeIcon
                      className="text-gray-700"
                      icon={regular("info-circle")}></FontAwesomeIcon>
                    {!isMobile && (
                      <>
                        <Text ml={8} color="gray.7" td="underline">
                          Learn more
                        </Text>
                      </>
                    )}
                  </Flex>
                </Anchor>
              </Tooltip>
            </div>
          </div>

          <div className="grid grid-cols-7 gap-4 font-medium p-4">
            <div className="col-span-2 cursor-pointer">
              Form

            </div>
            <div
              className="cursor-pointer col-span-2"
            >
              Source page URL

            </div>
            <div
              className="cursor-pointer"
            >
              Total submissions

            </div>
            <div
              className="cursor-pointer"
            >
              Last submitted

            </div>
            <div className="flex justify-center items-center"></div>
          </div>
          <Box
            component="div"
            sx={(theme) => ({
              border: `1px solid`,
              borderColor:
                theme.colorScheme === "dark"
                  ? theme.colors.darkBorderColor[0]
                  : theme.colors.lightBorderColor[0]
            })}
            className={`grid grid-cols-7 gap-4 px-4 py-1 border border-solid  rounded mb-2 items-center`}
          >
            <div className="col-span-2 cursor-pointer">
              <Text sx={{
                b: {
                  fontWeight: 500
                }
              }}>
                Submitted form with id <b>value</b> and class <b>classvalue</b>
              </Text>
            </div>
            <div className="cursor-pointer col-span-2">
              <Anchor
                href="https://usermaven.com/docs/getting-started"
                target="_blank"
                className="primary-link-color"
                rel="noreferrer">
                <Text sx={{
                  fontWeight: 500
                }}>
                  https://usermaven.com/docs/getting-started
                </Text>
              </Anchor>
            </div>
            <div
              className="cursor-pointer"
            >
              <Text sx={{
                fontWeight: 500
              }}>
                3,902
              </Text>
            </div>
            <div
              className="cursor-pointer"
            >
              about 3 hours ago
            </div>
            <div className="flex justify-center items-center">
              <Button
                compact
                variant="subtle"
                size="sm"
              >
                Detailed view
              </Button>
            </div>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default FormsActivity;
