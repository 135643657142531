import create from "zustand";

interface ISegmentStore {
  /**
   * Segment id
   */
  id: string;
  /**
   * Segment name
   **/
  name: string;
  /**
   * Description
   */
  description: string;
  /**
   * Segment type i.e visitor, user, company
   */
  type: string;

  /**
   * Segment modal is open or not.
   * */
  modalOpen: boolean;

  /**
   *
   * Filters
   **/
  filters: any;

  /**
   * Set segment id.
   * */
  setId: (id: string) => void;

  /**
   * Set segment id.
   * */
  setName: (id: string) => void;
  /** Set segment description */

  setDescription: (description: string) => void;

  /**
   * Set the segment type
   */
  setType: (type: string) => void;
  /**
   * Set segment modal open or not.
   * */
  setModalOpen: (modalOpen: boolean) => void;
  /**
   * Set filters
   * */
  setFilters: (filters: any) => void;
  /**
   * Reset segment store
   */
  reset: () => void;

  /**
   * Reset everything except filters
   */

  resetExceptFilters: () => void;
}

export const useSegmentStore = create<ISegmentStore>((set) => ({
  id: "",
  name: "",
  description: "",
  type: "user",
  modalOpen: false,
  setModalOpen: (modalOpen: boolean) => set({ modalOpen }),
  setId: (id: string) => set({ id }),
  setName: (name: string) => set({ name }),
  setDescription: (description: string) => set({ description }),
  setType: (type: string) => set({ type }),
  filters: {
    combinator: "and",
    rules: [
      {
        type: "attribute",
        field: "",
        operator: "",
        value: "",
        times: 1,
        within: 1,
        frequency: "",
        filters: {
          combinator: "and",
          rules: [],
        },
      },
    ],
    groups: [],
  },
  setFilters: (filters: any) => set({ filters }),
  reset: () =>
    set({
      id: "",
      name: "",
      description: "",
      modalOpen: false,
      filters: {
        combinator: "and",
        rules: [
          {
            type: "attribute",
            field: "",
            operator: "",
            value: "",
            times: 1,
            within: 1,
            frequency: "",
            filters: {
              combinator: "and",
              rules: [],
            },
          },
        ],
        groups: [],
      },
    }),
  resetExceptFilters: () =>
    set({
      id: "",
      name: "",
      description: "",
      modalOpen: false,
    }),
}));
