import { SegmentCreatePayload } from "types/types.d";
import axios from "./JWTService";

export class SegmentsService {
  allCounts = (ws_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/contacts/${ws_id}/counts`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  count = (ws_id: any, id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/segments/${ws_id}/count/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  getAll = (ws_id: any, type: "all" | "visitor" | "user" | "company" = "all"): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/segments/${ws_id}/all?type=${type}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  delete = (ws_id: any, segment_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/v1/segments/${ws_id}/${segment_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  post = (ws_id: any, payload: SegmentCreatePayload): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/segments/${ws_id}/`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  put = (ws_id: any, id: string, payload: SegmentCreatePayload): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/segments/${ws_id}/${id}`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
