/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  useMantineTheme,
  TextInput
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useFeatureAdoptionStore, { Module } from "@/stores/useFeatureAdoptionStore";
import AddOrUpdateModuleModal from "@/ui/pages/InsightsDashboard/Components/Modals/AddOrUpdateModuleModal";
import {  useState } from "react";
import { OnboardingFeatureSetup } from "./OnboardingFeatureSetup";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface ModuleSetupProps {
  module: Module;
  moduleIndex: number;
}

export const OnboardingModuleSetup: React.FC<ModuleSetupProps> = ({ module, moduleIndex }) => {
  const theme = useGlobalMantineTheme();

  const [openUpdateModulePrompt, setOpenUpdateModulePrompt] = useState(false);

  const [modules, updateModule, removeModule, addFeatureToModule] =
    useFeatureAdoptionStore((state) => [
      state.modules,
      state.updateModule,
      state.removeModule,
      state.addFeatureToModule
    ]);

  // Function to add an empty feature object to the current module
  const addNewFeature = (moduleIndex: number) => {
    const tempRandomUUID = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) =>
      c === "x" ? ((Math.random() * 16) | 0).toString(16) : (0x8 | (Math.random() * 4)).toString(16)
    );
    addFeatureToModule(modules[moduleIndex].id, {
      id: tempRandomUUID,
      name: "",
      conditions: { feature_events_conditions: [] },
      adoption_rate: 2,
      retention_period: "bi-weekly",
      is_new: true,
      is_active: true,
      is_saved: false
    });
  };

  const deleteModule = (module: Module) => {
      // Remove module from store.
      removeModule(module.id);
  };

  return (
    <>
      <Box
        sx={(theme) => {
          return {
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.grape[0],
            borderColor:
              theme.colorScheme === "dark"
                ? theme.colors.darkBorderColor[0]
                : theme.colors.lightBorderColor[0],
            borderRadius: theme.radius.sm
          };
        }}>
        <Box m={15}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}>
            <Flex className="mb-4">
              <Flex align={"center"}>
                <FontAwesomeIcon
                  icon={solid("angle-right")}
                  className={`ml-2 mr-3 transform  text-gray-800 transition-all duration-200 ease-in-out ${
                    module.is_active ? "rotate-90" : ""
                  }`}
                  onClick={() => {
                    updateModule(module.id, {
                      is_active: !module.is_active
                    });
                  }}
                />
              </Flex>
              <Flex align={"center"}>
                <TextInput
                  className=" w-80"
                  placeholder="Module Name"
                  value={module.name}
                  onChange={e => {
                    updateModule(module.id, {
                        name: e.target.value
                      })
                  }}
                />
              </Flex>
            </Flex>
            <Flex gap={"sm"}>
              <Avatar
                variant="outline"
                size={"sm"}
                radius="xl"
                color="red.4"
                onClick={() => deleteModule(module)}
                styles={{
                  placeholder: {
                    borderWidth: "2px"
                  }
                }}>
                <FontAwesomeIcon icon={solid("xmark")} />
              </Avatar>
            </Flex>
          </Box>

          {module.is_active && (
            <>
              <Box ml={25}>
                <OnboardingFeatureSetup
                  moduleId={module.id}
                  moduleName={module.name}
                  features={module.features}
                  title={"Select events for your feature"}
                  description={"You can select more than one event if you have multiple actions."}
                  conditionType={"feature_adoption"}
                />
              </Box>

              <Flex justify="center" align="center" mt={20}>
                <Button
                  className="!font-medium "
                  color={theme.colorScheme === "dark" ? theme.colors.dark[5] : "gray.9"}
                  variant={theme.colorScheme === "dark" ? "subtle" : "light"}
                  size="sm"
                  leftSection={<FontAwesomeIcon icon={regular("circle-plus")} />}
                  onClick={() => addNewFeature(moduleIndex)}>
                  Add Feature
                </Button>
              </Flex>
            </>
          )}
        </Box>
      </Box>

      <Divider my="md" variant="dashed" />

      <AddOrUpdateModuleModal
        open={openUpdateModulePrompt}
        setOpen={setOpenUpdateModulePrompt}
        module={module}
      />
    </>
  );
};
