import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Center, SegmentedControl } from "@mantine/core"
import { toast } from "react-toastify"
import { IReportType } from "types/types.d"
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook"

interface IReportTypeToggle {
  reportType: string
  setReportType: (reportType: IReportType) => void
}

export const ReportTypeToggle = ({ reportType, setReportType }: IReportTypeToggle) => {
  const { isStarterPlan } = useWorkspaceUtilityHook()

  return (
    <>
      <SegmentedControl
        value={reportType}
        size="xs"
        onChange={value => {
          if (isStarterPlan) {
            toast.error("Please upgrade your plan to Premium to access this feature.")
            return
          }
          setReportType(value as IReportType)
        }}
        data={[
          {
            value: "user",
            label: (
              <Center>
                <FontAwesomeIcon
                  //   className=" text-gray-900"
                  icon={regular("user")}
                ></FontAwesomeIcon>
                <Box ml={8} mr={2}>
                  Users
                </Box>
              </Center>
            ),
          },
          {
            value: "company",
            label: (
              <Center className={isStarterPlan ? "opacity-50" : ""}>
                <FontAwesomeIcon
                  //   className=" text-gray-900"
                  icon={regular("building")}
                ></FontAwesomeIcon>
                <Box ml={8} mr={2}>
                  Companies
                </Box>
                {isStarterPlan && (
                  <FontAwesomeIcon
                    className="ml-2"
                    //   className=" text-gray-900"
                    icon={regular("lock")}
                  ></FontAwesomeIcon>
                )}
              </Center>
            ),
          },
        ]}
      />
    </>
  )
}
