import {
  Box,
  Flex,
  MantineTransition,
  Modal,
  Grid,
  Tabs,
  Badge,
  Drawer,
  useMantineColorScheme
} from "@mantine/core";
import { useContext, useEffect, useMemo, useState } from "react";
import ContactSection, { IContact } from "./components/ContactSection";
import AttributeSection, { IAttributeSectionItem } from "./components/AttributeSection";
import ActivitiesTab from "./components/ActivitiesTab/ActivitiesTab";
import UserSection from "./components/UserSection";
import { VisitorService } from "@/lib/services/VisitorService";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { utcToRelativeTimezone } from "@/lib/utils/DateUtility";
import { _getRowValue } from "@/lib/utils/Virtualtable";
import { useNavigate, useParams } from "react-router-dom";
import { UsersCRMService } from "../../../../lib/services/UsersCRMService";
import { CompaniesService } from "../../../../lib/services/CompaniesService";
import useGetElement from "../../../../hooks/useGetElement";
import EngagementSection, { IEngagement } from "./components/EngagementSection";
import TopPagesTab from "./components/TopPagesTab/TopPagesTab";
import OnboardingSection from "./components/OnboardingSection";
import TopEventsTab from "./components/TopEventsTab/TopEventsTab";
import { useMediaQuery } from "@mantine/hooks";
import { FeatureAdoptionTab } from "./components/FeatureAdoptionTab/FeatureAdoptionTab";
import { NotesTab } from "./components/NotesTab/NotesTab";
import { ContactNotesService } from "@/lib/services/ContactNotesService";
import TimelineTab from "./components/TimelineTab/TimelineTab";
import { capitalizeFirstLetter } from "@/lib/utils/StringUtility";

export interface OverviewModalProps {
  /**
   * Specify the modal open state.
   */
  opened?: boolean;

  /**
   * Specify the modal open setter.
   */
  setOpened?: (opened: boolean) => void;

  /**
   * Specify the modal title.
   */
  title?: string;

  /**
   * Specify the modal transition.
   * Refer to https://mantine.dev/core/modal/#transitions for more details.
   */
  transition?: MantineTransition | undefined;

  /**
   * Specify the type of the modal.
   * Either 'company', 'user', or 'visitor'.
   */
  type?: "company" | "user" | "visitor";

  /**
   * Specify the id of the payload.
   * e.g. company_id, people_id, or visitor_id.
   */
  id?: string;
}

const OverviewModal = ({
  title = "Overview",
  transition = "slide-left",
  type = "visitor", // all changes?
  opened = false,
  setOpened,
  id = ""
}: OverviewModalProps) => {
  const { colorScheme } = useMantineColorScheme();
  const element = useGetElement("#overview-modal .usrmvn-Modal-inner");
  const [notesCount, setNotesCount] = useState(0);
  const [contact, setContact] = useState<(IContact & IEngagement) | null>(null);
  const [loadingContact, setLoadingContact] = useState(false);

  const navigate = useNavigate();

  // Get visitor id from the params.
  let { uniqueId, segment } = useParams<{
    uniqueId: string;
    segment: string;
  }>();

  uniqueId = id || uniqueId;

  // if (!uniqueId) {
  //   return <></>;
  // }

  // Visitor service instance - used to handle all visitor related api operations.
  const visitorService = new VisitorService();

  // Users CRM service instance - used to handle all users related api operations.
  const usersCRMService = new UsersCRMService();

  // Companies service instance - used to handle all Companies related api operations.
  const companiesService = new CompaniesService();

  // For active workspace id of the user.
  const { activeWorkspace } = useContext(AppLifecycleContext);

  useEffect(() => {
    // const element = document.querySelector("#overview-modal")
    console.log("useLayoutEffect", element);
    if (element) {
      element.id = "overview-modal-inner";
    }
  }, [element]);

  const modalElement = useMemo(() => {
    const element = document.querySelector("#overview-modal .usrmvn-Modal-inner");
    if (element) {
      element.id = "overview-modal-inner";
    }
    return element;
  }, [opened]);

  /**
   * Fetch contact card data on component mount.
   * Fetch note count on component mount.
   */
  useEffect(() => {
    if (!uniqueId) {
      return;
    }

    fetchContactCardData();
    getNoteCount();
  }, [id]);

  /** Method to fetch contact card data. */
  const fetchContactCardData = async () => {
    setLoadingContact(true);
    let contact = null;
    if (type === "visitor") {
      contact = await fetchVisitorContactCardData();
    } else if (type === "user") {
      contact = await fetchUserContactCardData();
    } else if (type === "company") {
      contact = await fetchCompanyContactCardData();
    }

    if (contact) {
      setContact(contact);
    }
    setLoadingContact(false);

    return null;
  };

  /** Method to fetch activities */
  const onFetchSessions = async (page: number, autocaptured = true) => {
    if (type === "visitor") {
      return await fetchVisitorSessions(page, autocaptured);
    } else if (type === "user") {
      return await fetchUserSessions(page);
    }

    return [];
  };

  /** Method to fetch events of sessions */
  const onFetchEvents = async (sessionId: string, page: number, autocaptured = true) => {
    if (type === "visitor") {
      return await fetchVisitorEvents(sessionId, page, autocaptured);
    } else if (type === "user") {
      return await fetchUserEvents(sessionId, page, autocaptured);
    }

    return [];
  };

  /** Method to fetch attributes */
  const onFetchAttributes = async () => {
    if (type === "user") {
      return await fetchUserAttributes();
    } else if (type === "company") {
      return await fetchCompanyAttributes();
    } else if (type === "visitor") {
      return await fetchVisitorAttributes();
    }

    return [];
  };

  /** Method to fetch user data. */
  const onFetchUsers = async (page: number) => {
    if (type === "company") {
      return await fetchCompanyUsers(page);
    }

    return [];
  };

  /** Method to fetch onboarding status data. */
  const onFetchOnboarding = async () => {
    if (type === "user") {
      return await fetchUserOnboarding();
    } else if (type === "company") {
      return await fetchCompanyOnboarding();
    }
  };

  /**
   * Below methods are used to fetch data for company.
   * fetchCompanyContactCardData - Fetches contact card data for company.
   * fetchCompanyAttributes - Fetches attributes for company.
   * fetchCompanyUsers - Fetches users for company.
   */

  const fetchCompanyContactCardData = async () => {
    const response = await companiesService.detailsById(activeWorkspace?.id, uniqueId);
    if (response?.data) {
      const {
        name,
        created_at,
        channel = "Direct",
        source = "Direct",
        detailed_source,
        referrer,
        last_seen,
        number_of_users,
        visits,
        sessions,
        heatmap_events_array
      } = response.data;

      const cardData: IContact & IEngagement = {
        title: name || "...",
        subtitle: ``,
        company_name: "",
        company_id: "",
        city: "",
        country: "",
        avatar: {
          children: `${name
            .split(" ")
            .map((word: string) => word[0])
            .join("")}`
        },
        heatmap_events_array: heatmap_events_array,
        items: [
          {
            title: "Created",
            subtitle: `${
              created_at && utcToRelativeTimezone(created_at)
            } from ${capitalizeFirstLetter(channel)}${
              channel.trim().toLowerCase() != "direct" || source.trim().toLowerCase() != "direct"
                ? " / " + source
                : ""
            }`,
            props: {
              channel: channel,
              detailedSource: detailed_source,
              referrer: referrer
            }
          },
          {
            title: "Last Seen",
            subtitle: last_seen ? utcToRelativeTimezone(last_seen) : "..."
          },
          {
            title: "Users / Sessions",
            subtitle: number_of_users + " / " + sessions
          },
          {
            title: "Total Page Views",
            subtitle: visits
          }
        ]
      };

      return cardData;
    }
    return null;
  };

  const fetchCompanyAttributes = async () => {
    const response = await companiesService.attributesById(activeWorkspace?.id, uniqueId);

    if (response?.data) {
      const { company_id, company_name, company_created_at, company_custom_map } = response.data;

      const attributes: IAttributeSectionItem[] = [];

      attributes.push({
        key: "id",
        value: company_id,
        cast: "string",
        type: "Identifier"
      });

      attributes.push({
        key: "name",
        value: company_name,
        cast: "string",
        type: "Required"
      });

      attributes.push({
        key: "created_at",
        value: company_created_at || "",
        cast: "string",
        type: "Required"
      });

      if (company_custom_map) {
        Object.keys(company_custom_map).forEach((key) => {
          attributes.push({
            key: key.replace("company_custom_", ""),
            value: company_custom_map[key],
            cast: "string",
            type: "Custom"
          });
        });
      }

      return attributes;
    }

    return [];
  };

  const fetchCompanyUsers = async (page: number) => {
    const response = await companiesService.usersById(activeWorkspace?.id, uniqueId);

    if (response?.data) {
      return response.data;
    }

    return [];
  };

  const fetchCompanyOnboarding = async () => {
    const response = await companiesService.onboardingById(activeWorkspace?.id, uniqueId);

    if (response?.data) {
      return response.data;
    }

    return [];
  };

  /** End of company related methods. */

  /**
   * Below methods are used to fetch data for user.
   * fetchUserContactCardData - fetches contact card data for user.
   * fetchUserAttributes - fetches attributes for user.
   * fetchUserSessions - fetches sessions for user.
   * fetchUserEvents - fetches events for user of a particular session.
   */
  const fetchUserContactCardData = async () => {
    const response = await usersCRMService.individualUserDetailsById(
      activeWorkspace?.id,
      uniqueId || ""
    );
    if (response?.data) {
      const {
        first_name,
        last_name,
        email,
        created_at,
        last_seen,
        first_seen,
        channel = "direct",
        source = "direct",
        detailed_source,
        referrer,
        user_engagement_level
      } = response.data;

      const cardData: IContact & IEngagement = {
        title: `${first_name} ${last_name}`,
        subtitle: email,
        avatar: {
          children: `${first_name?.charAt(0)}${last_name?.charAt(0)}`
        },
        items: [
          {
            title: "First Seen",
            subtitle: `${
              first_seen && utcToRelativeTimezone(first_seen)
            } from ${capitalizeFirstLetter(channel)}${
              channel.trim().toLowerCase() != "direct" || source.trim().toLowerCase() != "direct"
                ? " / " + source
                : ""
            }`,
            props: {
              channel: channel,
              detailedSource: detailed_source,
              referrer: referrer
            }
          },
          {
            title: "Created",
            subtitle: created_at ? utcToRelativeTimezone(created_at) : "..."
          },

          {
            title: "Last seen",
            subtitle: last_seen ? utcToRelativeTimezone(last_seen) : "..."
          },
          {
            title: "Engagement",
            subtitle: user_engagement_level || "At Risk"
          }
        ],
        ...response?.data
      };

      return cardData;
    }

    return null;
  };

  const fetchUserAttributes = async () => {
    const response = await usersCRMService.individualUserAttributesById(
      activeWorkspace?.id,
      uniqueId || ""
    );
    if (response?.data) {
      const attributes: IAttributeSectionItem[] = [];
      const {
        user_id,
        user_email,
        user_created_at,
        user_first_name,
        user_last_name,
        user_custom_map
      } = response.data;

      attributes.push({
        key: "id",
        value: user_id,
        cast: "string",
        type: "Identifier"
      });

      attributes.push({
        key: "email",
        value: user_email,
        cast: "string",
        type: "Reserved"
      });

      attributes.push({
        key: "created_at",
        value: user_created_at,
        cast: "string",
        type: "Reserved"
      });

      attributes.push({
        key: "first_name",
        value: user_first_name,
        cast: "string",
        type: "Recommended"
      });

      attributes.push({
        key: "last_name",
        value: user_last_name,
        cast: "string",
        type: "Recommended"
      });

      if (user_custom_map) {
        Object.entries(user_custom_map).forEach(([key, value]) => {
          attributes.push({
            key: key,
            value: value,
            cast: typeof value || "",
            type: "Custom"
          });
        });
      }

      return attributes;
    }
    return [];
  };

  const fetchUserSessions = async (page: number) => {
    const response = await usersCRMService.individualUserSessionsById(
      activeWorkspace?.id,
      uniqueId || "",
      page
    );
    if (response?.data.length > 0) {
      return response.data;
    }
    return [];
  };

  const fetchUserEvents = async (sessionId: string, page: number, autocaptured: boolean) => {
    const response = await usersCRMService.individualUserEventsById(
      activeWorkspace?.id,
      sessionId,
      page,
      autocaptured
    );
    if (response?.data.length > 0) {
      return response.data;
    }
    return [];
  };

  const fetchUserOnboarding = async () => {
    const response = await usersCRMService.individualUserOnboardingById(
      activeWorkspace?.id,
      uniqueId || ""
    );
    if (response?.data) {
      return response.data;
    }
    return [];
  };

  /** End of user related methods. */

  /**
   * Below methods are used to fetch data for visitor.
   * fetchVisitorContactCardData - fetches contact card data for visitor.
   * fetchVisitorAttributes - fetches attributes for visitor.
   * fetchVisitorSessions - fetches activities for visitor.
   * fetchVisitorEvents - fetches events for visitor.
   */
  const fetchVisitorContactCardData = async () => {
    const response = await visitorService.details(activeWorkspace?.id, uniqueId || "");

    if (response?.data) {
      const {
        user_name,
        location_country,
        location_city,
        pageviews,
        last_seen,
        first_visit,
        source = "direct",
        channel = "direct",
        detailed_source,
        referer
      } = response.data;

      const cardData: IContact & IEngagement = {
        title: user_name,
        city: location_city,
        country: location_country,
        items: [
          {
            title: "Total Page Views",
            subtitle: pageviews
          },
          {
            title: "First Touch Point",
            subtitle:
              capitalizeFirstLetter(channel) +
              (channel.trim().toLowerCase() != "direct" || source.trim().toLowerCase() != "direct"
                ? " / " + source
                : ""),
            props: {
              channel: channel,
              detailedSource: detailed_source,
              referrer: referer
            }
          },
          {
            title: "First Visited",
            subtitle: first_visit && utcToRelativeTimezone(first_visit)
          },
          {
            title: "Last Seen",
            subtitle: last_seen && utcToRelativeTimezone(last_seen)
          }
        ],
        ...response?.data
      };

      return cardData;
    }

    return null;
  };

  const fetchVisitorAttributes = async () => {
    const response = await visitorService.attributes(activeWorkspace?.id, uniqueId || "");

    if (response?.data) {
      const attributes: IAttributeSectionItem[] = [];
      const {
        first_seen_url,
        last_url,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content
      } = response.data;

      attributes.push({
        key: "First visited URL",
        value: first_seen_url,
        cast: "string"
      });

      attributes.push({
        key: "Last visited URL",
        value: last_url,
        cast: "string"
      });

      attributes.push({
        key: "UTM Source",
        value: utm_source,
        cast: "string"
      });

      attributes.push({
        key: "UTM Medium",
        value: utm_medium,
        cast: "string"
      });

      attributes.push({
        key: "UTM Campaign",
        value: utm_campaign,
        cast: "string"
      });

      attributes.push({
        key: "UTM Term",
        value: utm_term,
        cast: "string"
      });

      attributes.push({
        key: "UTM Content",
        value: utm_content,
        cast: "string"
      });

      return attributes;
    }

    return [];
  };

  const fetchVisitorSessions = async (page: number, autocaptured = true) => {
    const response = await visitorService.sessions(activeWorkspace?.id, uniqueId || "", {
      page: page,
      autocaptured: autocaptured
    });

    if (response?.data.length > 0) {
      return response.data;
    }

    return [];
  };

  const fetchVisitorEvents = async (sessionId: string, page: number, autocaptured = true) => {
    const response = await visitorService.events(activeWorkspace?.id, sessionId, {
      page: page,
      autocaptured: autocaptured
    });

    if (response.data.length > 0) {
      return response.data;
    }

    return [];
  };

  /** End of visitor related methods. */

  /**
   * Method to count number of notes against a contact.
   */
  const getNoteCount = async () => {
    const response = await new ContactNotesService().getNoteCount(
      activeWorkspace.id,
      uniqueId || "",
      type
    );
    if (response?.data && response.data > 0) {
      setNotesCount(response.data);
    }
  };

  /**
   * Method to handle modal close.
   */
  const closeModal = () => {
    // Check if query param from_path is present.
    const urlParams = new URLSearchParams(window.location.search);

    console.log("urlParams", urlParams.get("from_path"));

    if (setOpened) {
      setOpened(false);
      return;
    }

    if (urlParams.has("from_path")) {
      // Navigate back to the from_path.
      navigate(urlParams.get("from_path") || "../");
      return;
    }

    // Navigate back to parent route.
    navigate("../");
  };

  /**
   * Check if the feature adoption section should be shown or not.
   */
  const shouldShowFeatureAdoption = useMemo(() => {
    return type === "user" || type === "company";
  }, [type]);

  /**
   * Check if the activities should be shown or not.
   */
  const shouldShowActivities = useMemo(() => {
    return type === "visitor" || type === "user";
  }, [type]);

  /**
   * Check if the users section should be shown or not.
   */
  const shouldShowUsersAndTimeline = useMemo(() => {
    return type === "company";
  }, [type]);

  /**
   * Check if the onboarding section should be shown or not.
   */
  const shouldShowOnboarding = useMemo(() => {
    return (
      (type === "user" && activeWorkspace.reporting_insights_preference === "user") ||
      (type === "company" && activeWorkspace.reporting_insights_preference === "company")
    );
  }, [type, activeWorkspace.reporting_insights_preference]);

  const matches = useMediaQuery("(min-width: 900px)");

  return (
    <>
      <Drawer
        id={"overview-modal"}
        zIndex={1000}
        position={"right"}
        styles={{
          inner: {
            padding: 0
          },

          body: {
            height: "100%"
          }
        }}
        size={matches ? "calc(100vw - 220px)" : "100vw"}
        // overflow="outside"
        opened={opened}
        onClose={() => closeModal()}
        // exitTransitionDuration={300}
        // transition={transition}
        // transitionTimingFunction="ease"
        // title={<ModalHeader title={title}></ModalHeader>}
        withCloseButton={true}>
        <Box h={"100%"}>
          <Grid gutter="sm">
            <Grid.Col span={3}>
              <Flex direction="column">
                <ContactSection contact={contact} loading={loadingContact} />
                {shouldShowOnboarding && <OnboardingSection onFetch={onFetchOnboarding} />}
                <AttributeSection title="Attributes" onFetch={onFetchAttributes} />
              </Flex>
            </Grid.Col>
            <Grid.Col span={9}>
              <Flex direction="column">
                <EngagementSection contact={contact} loading={loadingContact} />

                <Tabs
                  mb="md"
                  keepMounted={false}
                  defaultValue={shouldShowActivities ? "Activities" : "Users"}
                  styles={(theme) => ({
                    // tabsList: {
                    //   borderBottom: "1px solid",
                    //   borderColor:
                    //     colorScheme === "dark"
                    //       ? theme.colors.darkBorderColor[1]
                    //       : theme.colors.lightBorderColor[0]
                    // },
                    // tab: {
                    //   padding: "1rem",
                    //   "&[data-active]": {
                    //     // backgroundColor:,
                    //     borderWidth: "2px"
                    //   },
                    //   "&:hover": {
                    //     borderWidth: "2px"
                    //   }
                    // }
                  })}>
                  <Tabs.List grow justify="space-between" mb="sm">
                    {shouldShowActivities && <Tabs.Tab value="Activities">Activities</Tabs.Tab>}
                    {shouldShowUsersAndTimeline && <Tabs.Tab value="Users">Users</Tabs.Tab>}
                    {shouldShowUsersAndTimeline && <Tabs.Tab value="Timeline">Timeline</Tabs.Tab>}
                    <Tabs.Tab value="Top Pages">Top Pages</Tabs.Tab>
                    <Tabs.Tab value="Top Events">Top Events</Tabs.Tab>
                    {shouldShowFeatureAdoption && (
                      <Tabs.Tab value="Feature Usage">Feature Usage</Tabs.Tab>
                    )}
                    <Tabs.Tab value="Notes">
                      Notes {notesCount > 0 && <Badge ml="xs">{notesCount}</Badge>}
                    </Tabs.Tab>
                  </Tabs.List>
                  <Tabs.Panel value="Activities">
                    <ActivitiesTab
                      onFetchSessions={onFetchSessions}
                      onFetchEvents={onFetchEvents}
                      type={type}
                    />
                  </Tabs.Panel>
                  <Tabs.Panel value="Users">
                    <UserSection onFetch={onFetchUsers} />
                  </Tabs.Panel>
                  <Tabs.Panel value="Timeline">
                    <TimelineTab uniqueId={uniqueId} />
                  </Tabs.Panel>
                  <Tabs.Panel value="Top Pages">
                    <TopPagesTab type={type} uniqueId={uniqueId} />
                  </Tabs.Panel>
                  <Tabs.Panel value="Top Events">
                    <TopEventsTab type={type} uniqueId={uniqueId} />
                  </Tabs.Panel>
                  <Tabs.Panel value="Feature Usage">
                    {type != "visitor" && <FeatureAdoptionTab type={type} uniqueId={uniqueId} />}
                  </Tabs.Panel>
                  <Tabs.Panel value="Notes">
                    <NotesTab type={type} uniqueId={uniqueId} setNotesCount={setNotesCount} />
                  </Tabs.Panel>
                </Tabs>
              </Flex>
            </Grid.Col>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
};

export default OverviewModal;
