import {
  Anchor,
  Box,
  Button,
  Center,
  Container,
  Group,
  Text,
  Title,
  Paper,
  useMantineColorScheme,
  Flex
} from "@mantine/core";
import TrackingPixelSVG from "@assets/images/icons/tracking-pixel.svg";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { ThirdPartyTracking } from "@/lib/utils/ThirdPartyTracking";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OnboardingInvitedWorkspaceMemberType } from "types/types.d";
import { TeamMemberInviteModal } from "@/ui/components/App/Modals/TeamMemberInviteModal";
import { TrackingPixel } from "@/ui/components/App/TrackingPixel/TrackingPixel";
import "./onboarding_installation_setup.scss";
import "./onboarding_installation_tracking_pixel.scss";

export const OnboardingInstallationTrackingPixel = (props: any) => {
  const navigate = useNavigate();
  const { user } = useContext(AppLifecycleContext);
  const { activeWorkspace } = useContext(AppLifecycleContext)!;
  const theme = useMantineColorScheme();
  const iconsHeight = "60";
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    document.title = "Install Tracking Pixel | Usermaven";
  }, []);

  const onboardingAddedTeamMember = () => {};
  const onboardingAddTeamMemberDialog = () => {
    setModalOpen(true);
  };

  const isAllowedForTeamMemberOnboarding = (): boolean => {
    let allowed = false;
    console.debug("Has onboarding members available for checking:", activeWorkspace);
    activeWorkspace?.members.forEach((member: OnboardingInvitedWorkspaceMemberType) => {
      if (
        member.user_id === user?.id &&
        member.role?.name !== WORKSPACE_MEMBER_ROLES.VIEWER &&
        member.role?.name !== WORKSPACE_MEMBER_ROLES.AUTHOR
      ) {
        console.debug("The user is part of workspace member.");
        allowed = true;
        return allowed;
      }
    });
    return allowed;
  };
  const checkPixelInstalled = () => {
    const TPService = new ThirdPartyTracking(user, null);
    TPService.track("pixel_installation_check");
    navigate("/onboarding/tracking-pixel-check");
  };

  // useEffect(() => {}, []);
  return (
    <>
      <TeamMemberInviteModal
        open={modalOpen}
        isEdit={false}
        workspaceId={activeWorkspace?.id}
        closeModal={() => {
          setModalOpen(!modalOpen);
          return;
        }}
        addTeamMember={onboardingAddedTeamMember}></TeamMemberInviteModal>
      <div className="um-onboarding">
        <div className="um-onboarding--navigation">
          <div className="um-onboarding--navigation--back-button">
            {/* <Button
              label="< Back to Installation"
              type="submit"
              onClick={() => history.push("/onboarding/setup")}
            /> */}
          </div>
          <div className="um-onboarding--navigation--steps">1/2</div>
        </div>
        <div className="um-onboarding--icon -mt-30">
          <img src={TrackingPixelSVG} alt="tracking-pixel" height={iconsHeight} />
        </div>

        <Title order={3} ta="center" fw={600} pb={"sm"}>
          Copy and paste code
          {/* (most common) */}
        </Title>
        <Container size="sm" pb="sm">
          <Text size="sm" ta="center" my="lg">
            Start integrating Usermaven into your site with our easy-to-use JavaScript snippet, or
            explore other integration options including Segment, Google Tag Manager, Python SDK,
            Wix, WordPress, and more for seamless data transmission.
          </Text>
        </Container>
        {/* <Paper className="paper-bg"> */}
        <Box
          className="um-onboarding--guide"
          style={{}}
          p={"md"}
          mb={"xl"}

          // style={{
          //   padding: "1.5rem",
          //   border: `1px solid`,
          //   marginBottom: "3rem",
          //   borderColor:
          //     theme.colorScheme === "dark"
          //       ? theme.colors.darkBorderColor[0]
          //       : theme.colors.lightBorderColor[0],
          //   backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : "#fff"
          // }}
        >
          <TrackingPixel workspaceId={activeWorkspace?.identifier}></TrackingPixel>

          <div className="um-onboarding--guide--tracking-pixel">
            <div className="um-onboarding--guide--invitation">
              {activeWorkspace?.members && isAllowedForTeamMemberOnboarding() && (
                <Flex className="um-onboarding--guide--invitation--cta " direction={"column"}>
                  <Text className="text-center " py={"xs"} fz="sm">
                    Not working?{" "}
                    <Anchor
                      fz="sm"
                      href="https://usermaven.com/docs/getting-started/how-to-check-if-usermaven-tracking-script-is-installed-correctly"
                      target="_blank"
                      className={"primary-link-color"}>
                      Troubleshoot the integration
                    </Anchor>{" "}
                    with our help guide fist.
                  </Text>
                  <Text fz="sm">
                    Not sure what to do? Ask a team member help.{" "}
                    <Anchor
                      fz="sm"
                      className="primary-link-color text-underline c-pointer"
                      onClick={() => {
                        onboardingAddTeamMemberDialog();
                      }}>
                      Invite Team Member
                    </Anchor>
                  </Text>
                </Flex>
              )}
              <div className="um-onboarding--guide--check-installation">
                <Group>
                  <Button type="submit" onClick={() => checkPixelInstalled()}>
                    Check Installation
                  </Button>
                </Group>
              </div>
              <Center>
                <Anchor href={"https://usermaven.com/book-a-demo"} target="_blank">
                  <Button variant="subtle">Book a demo with us</Button>
                </Anchor>
              </Center>
            </div>

            <Text fz="sm" className="um-onboarding-logout">
              Or,{" "}
              <Link
                to="/logout"
                style={{
                  paddingLeft: "0.5rem"
                }}>
                Logout
              </Link>
            </Text>
          </div>
        </Box>
        {/* </Paper> */}
      </div>
    </>
  );
};
