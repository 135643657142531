import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Flex, Paper, Title, Text, Box, Tooltip } from "@mantine/core";

interface IInsightSection {
  title: string;
  description?: string | React.ReactNode;
  tooltip?: string;
  rightSection?: React.ReactNode;
  children: React.ReactNode;
}

export const InsightSection = ({
  title,
  description,
  tooltip,
  rightSection,
  children
}: IInsightSection) => {
  return (
    <Paper withBorder shadow="xs" mb="xl">
      <Flex align={"center"}>
        <Flex direction={"column"} flex={1} py="xs">
          <Flex align={"center"}>
            <Title order={5} fw={600} className="font-medium" px="md" py="sm">
              {title}
            </Title>
            {tooltip && (
              <Box w={16}>
                <Tooltip label={tooltip} multiline w={400} position="right">
                  <FontAwesomeIcon icon={regular("info-circle")} />
                </Tooltip>
              </Box>
            )}
          </Flex>
          {description && (
            <Text px="md" pb="sm">
              {description}
            </Text>
          )}
        </Flex>
        {rightSection && <Flex mr="md">{rightSection}</Flex>}
      </Flex>
      <Divider />
      <Box p="md">{children}</Box>
    </Paper>
  );
};
