import { Box, Flex, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";

import { useMediaQuery } from "@mantine/hooks";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { MOBILE_BREAKPOINT_QUERY } from "../../../../lib/utils/Constants";
import { createSeriesData } from "../../../../lib/utils/FunnelUtility";
import { useFunnelBreakdownOptions } from "./ChartOptions/ChartOptions";
import { IFunnelMeta, IFunnelStep } from "./FunnelDetailedView/FunnelDetails";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  BarChart,
  CanvasRenderer
]);

export interface IBreakdownBarChart {
  steps: Array<IFunnelStep[]>;
  meta: IFunnelMeta;
  selectedFunnel: IFunnelStep[];
  grouped: boolean;
  type?: "visitor" | "user" | "company";
}

const BreakdownBarChart = ({
  steps,
  meta,
  selectedFunnel,
  grouped = true,
  type = "visitor"
}: IBreakdownBarChart) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);
  const intl = useIntl();


  const resolvedType = useMemo(() => {
    if (!type || type === "visitor") {
      return intl.formatMessage({
        id: "insights.type.visitors",
        defaultMessage: "Visitors"
      });
    } else if (type === "user") {
      return intl.formatMessage({
        id: "insights.type.users",
        defaultMessage: "Users"
      });
    }
    return intl.formatMessage({
      id: "insights.type.companies",
      defaultMessage: "companies"
    });
  }, [type]);

  const stepsNames = useMemo(
    () => (steps.length ? steps[0].map((step) => step.name) : []),
    [steps]
  );
  const breakdown_props = useMemo(() => {
    const names = steps.length ? steps.map((step) => step[0].breakdown_prop || "") : [];
    console.log("names", names);
    // names.push("Dropoffs")
    return names;
  }, [steps]);

  const seriesData = useMemo(
    () => createSeriesData(steps, meta, selectedFunnel, resolvedType, breakdown_props, theme, colorScheme === "dark" ? "dark" : "light"),
    [steps]
  );

  const option = useFunnelBreakdownOptions(theme, steps, stepsNames, seriesData, isMobileView, [
    intl.formatMessage({
      id: "insights.charts.tooltip.completedStep",
      defaultMessage: "Completed Step"
    }),
    intl.formatMessage({
      id: "insights.charts.tooltip.completedStepRatio",
      defaultMessage: "Completed Step Ratio"
    }),
    intl.formatMessage({
      id: "insights.charts.tooltip.dropOffFromPreviousStep",
      defaultMessage: "Drop Off (from previous step)"
    }),
    intl.formatMessage({
      id: "insights.charts.tooltip.dropOffRatioFromPreviousStep",
      defaultMessage: "Drop Off Ratio (from previous step)"
    }),
    intl.formatMessage({
      id: "insights.charts.tooltip.dropOffRatioFromFirstStep",
      defaultMessage: "Drop Off Ratio (from first step)"
    })
  ]);

  /**
   * Calculate the height of the chart based on the number of steps
   * min-height: 500px
   */
  const chartHeight = useMemo(() => {
    if (!grouped) {
      const height = steps.length * 120 + 500;
      return height;
    }

    if (steps.length === 0) return 500;

    const height = steps[0].length * 120 + 650;
    return height;
  }, [steps]);



  return (
    <>
      <Flex>
        <Box
          style={{
            minHeight: `500px`,
            width: "100%"
          }}>
          <ReactEChartsCore
            echarts={echarts}
            option={option}
            style={{
              height: "100%",
              width: "100%"
            }}
            notMerge={true}
            lazyUpdate={true}
          />
        </Box>
      </Flex>
    </>
  );
};

export default BreakdownBarChart;
