import { UserService } from "@/lib/services/UserService";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { SettingsHeaderContent } from "@/ui/components/App/Settings/SettingsHeaderContent/SettingsHeaderContent";

import { Button, PasswordInput, Input, Title, Text, Divider, Box } from "@mantine/core";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";
interface IFormInputs {
  old_password: string;
  password: string;
  password_confirm: string;
}

export const AccountsSecurityChangePassword: React.FC<any> = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset
  } = useForm<IFormInputs>();
  // eslint-disable-next-line
  const [actionState, setActionState] = useState("idle");
  const watchOldPassword = watch("old_password", "");
  const watchPassword = watch("password", "");
  const watchPasswordConfirm = watch("password_confirm", "");
  const isFormChanged =
    watchOldPassword &&
    watchOldPassword.length > 0 &&
    watchPassword &&
    watchPassword.length > 0 &&
    watchPasswordConfirm &&
    watchPasswordConfirm.length > 0;

  const updatePasswordSubmit = (data: any) => {
    console.log(data);
    if (data.password.trim() !== data.password_confirm.trim()) {
      toast.error("Your new password and confirm password does not match.");
      return;
    }
    const userServiceObj = new UserService();
    userServiceObj
      .changePassword(data)
      .then((res) => {
        if (res.data && res.data?.message) {
          toast.success(res.data.message);
          reset({
            old_password: "",
            password: "",
            password_confirm: ""
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          if (
            err?.response?.data.hasOwnProperty("detail") &&
            typeof err.response.data.detail === "string"
          ) {
            toast.error(err.response.data.detail);
          }
        }
      });
  };
  return (
    <>
      <Title order={4} fw={600} mb="md">
        Change Password
      </Title>
      <Text mb="md">Change your account password &amp; use a strong password.</Text>
      <Divider mb="md" />
      <form id="changePasswordForm" onSubmit={handleSubmit(updatePasswordSubmit)}>
        <Box w={"50%"}>
          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <Input.Wrapper mb="xs" id={"password"} size="xs" label="Old Password">
                <PasswordInput
                  mt={4}
                  placeholder="*********"
                  onChange={onChange}
                  withAsterisk
                  value={value}
                />
              </Input.Wrapper>
            )}
            name="old_password"
            control={control}
            defaultValue=""
            rules={{ required: true, minLength: 8 }}
          />

          {errors.old_password?.type === "required" && (
            <Text c="red" fz={"xs"}>
              Old password is required.
            </Text>
          )}

          {errors.old_password?.type === "minLength" && (
            <Text c="red" fz={"xs"}>
              Old password must be greater than 8 characters.
            </Text>
          )}

          {/* New Password Details */}
          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <Input.Wrapper mb="xs" id={"password"} size="xs" label="Password">
                <PasswordInput
                  mt={4}
                  placeholder="*********"
                  onChange={onChange}
                  withAsterisk
                  value={value}
                />
              </Input.Wrapper>
            )}
            name="password"
            control={control}
            //   defaultValue=""
            rules={{ required: true, minLength: 8 }}
          />

          {errors.password?.type === "required" && (
            <Text c="red" fz="xs">
              password is required
            </Text>
          )}

          {errors.password?.type === "minLength" && (
            <Text c="red" fz={"xs"}>
              New password must be greater than 8 characters.
            </Text>
          )}

          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <>
                <Input.Wrapper mb="xs" id={"password"} size="xs" label="Confirm Password">
                  <PasswordInput
                    mt={4}
                    placeholder="*********"
                    onChange={onChange}
                    withAsterisk
                    value={value}
                  />
                </Input.Wrapper>
              </>
            )}
            name="password_confirm"
            control={control}
            defaultValue={watchPasswordConfirm}
            rules={{ required: true, minLength: 8 }}
          />

          {errors.password_confirm?.type === "required" && (
            <Text c="red" fz={"xs"}>
              New confirm password is required
            </Text>
          )}

          {errors.password_confirm?.type === "minLength" && (
            <Text c="red" fz={"xs"}>
              New confirm password must be greater than 8 characters.
            </Text>
          )}
        </Box>
        <Button
          mt={"xs"}
          type="submit"
          disabled={!isFormChanged || actionState === "loading"}
          loading={actionState === "loading"}>
          Save
        </Button>
      </form>
    </>
  );
};
