import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { VisitorService } from "@/lib/services/VisitorService";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";

import { DATE_RANGES, DEFAULT_VISITORS_FIELDS } from "@/lib/utils/Constants";
import {
  Box,
  Text,
  Paper,
  Button,
  useMantineTheme,
  Group,
  Flex,
  Popover,
  ActionIcon,
  useMantineColorScheme
} from "@mantine/core";
import MultiSelectDropdown from "@/ui/components/Common/Dropdown/MultiSelectDropdown";
import { remToPx } from "@/lib/utils/ClassUtility";
import { NoResults } from "../../components/App/NoResults/NoResults";
import { VirtualDataTable } from "../../components/Common/VirtualDataTable";
import { ActionType, SortDirection } from "ka-table/enums";

import { Outlet, useNavigate, useParams } from "react-router-dom";
import { IActionPayload, IColumn } from "../../components/Common/VirtualDataTable/VirtualDataTable";
import { useLocalStorage } from "@mantine/hooks";
import { LS_VISITORS_FIELDS_PREFERENCE } from "../../../lib/utils/Storage";
import { arrayMove } from "../../../lib/utils/ArrayUtility";
import DomainSelector from "./components/DomainSelector";
import { WebAnalyticsService } from "../../../lib/services/WebAnalyticsService";
import { HeaderNavigationH1 } from "../../components/App/HeaderNavigation/HeaderNavigation.style";
import { getActiveDomainValue } from "../../components/App/WebAnalytics/WebAnalyticsUtilities";
import { useSegmentFilterStore } from "@/stores/useSegmentFilterStore";
import { useSegmentListStore } from "@/stores/useSegmentListStore";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { SegmentDropdownMenu } from "@/ui/components/App/Dropdowns/SegmentDropdownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { SegmentsQueryBuilder } from "@/ui/components/App/Segments/SegmentsQueryBuilder";
import { SegmentModal } from "@/ui/components/App/Segments/SegmentModal";
import DateRangeSelector from "./components/DateRangeSelector";
import { IDateRange } from "@/ui/components/App/Dropdowns/DatePickerDropdown";
import PageHeader from "@/ui/pages/ContactsHub/components/PageHeader/PageHeader";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { useCustomizeMenuStore } from "@/stores/useCustomizeMenuStore";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";

const Visitors = () => {
  const { colorScheme } = useMantineColorScheme();
  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);

  // Get segment from the params.
  const { segment } = useParams<{ segment: string }>();
  /**
   * Segment filter store
   */
  const [filterVisible, setFilterVisible] = useSegmentFilterStore((state) => [
    state.filterVisible,
    state.setFilterVisible
  ]);
  /**
   * Segments Store
   */
  const [segments, fetchSegments, setListsSegmentType, updateSegment] = useSegmentListStore(
    (state) => [state.segments, state.fetchSegments, state.setSegmentType, state.updateSegment]
  );

  /**
   * Segment Query builder store
   */
  const [
    filters,
    setFilters,
    resetExceptFilters,
    reset,
    modalOpen,
    setSegmentModalOpen,
    setSegmentType
  ] = useSegmentStore((state) => [
    state.filters,
    state.setFilters,
    state.resetExceptFilters,
    state.reset,
    state.modalOpen,
    state.setModalOpen,
    state.setType
  ]);

  const [internalAside, setInternalAside] = useCustomizeMenuStore((state: any) => [
    state.internalAside,
    state.setInternalAside
  ]);

  // Navigate from react-router-dom.
  const navigate = useNavigate();

  // Visitor service instance - used to handle all visitor related api operations.
  const visitorService = new VisitorService();
  const waService = new WebAnalyticsService();

  // For active workspace id of the user.
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // First render ref - used to check if the component is mounted or not.
  const firstRender = useRef(true);

  // Visitors component state.
  const [noResults, setNoResults] = useState(false);
  const [tableFields, setTableFields] = useLocalStorage<IColumn[]>({
    key: `${LS_VISITORS_FIELDS_PREFERENCE}_${activeWorkspace?.identifier}`,
    defaultValue: [...DEFAULT_VISITORS_FIELDS],
    getInitialValueInEffect: false
  });

  const [total, setTotal] = useState(0);
  const [dateRange, setDateRange] = useState<IDateRange>(
    DATE_RANGES.find((d) => d.label === "Last 7 days")!
  );

  // Ref for table.
  const tableRef = useRef<any>(null);

  // Domain selector state.
  const [domain, setDomain] = useState<string | null>("all_domains");
  const [domains, setDomains] = useState<{ label: string; value: string }[]>([]);
  const [domainsLoading, setDomainsLoading] = useState(true);

  /**
   * Fetch all domains for the workspace.
   */
  const fetchDomains = async () => {
    setDomainsLoading(true);
    const { data } = await waService.domains(activeWorkspace.id);
    setDomainsLoading(false);
    if (data) {
      setDomains(data);

      // If the query has domain assigned already, use that domain, else pick from the active workspace preference.
      const defaultDomain = getActiveDomainValue(data, activeWorkspace.reporting_domain);

      setDomain(defaultDomain);
    }
  };

  /**
   * Fetch next page of data.
   * @param page
   * @param sort
   */
  const fetchNextPageCallback = async (
    page: number,
    sort: { key: string; direction: SortDirection }
  ) => {
    const sortDirection = sort.direction === SortDirection.Ascend ? "asc" : "desc";
    const sortKey = sort.key ? sort.key : "last_seen";

    // If the filter and the group rules are empty, then don't send the filter rules.
    // In case of the page type, the field is going to be empty.
    const filterRules =
      (filters.rules.length > 0 && !filters.rules[0].field && filters.rules[0].type !== "page") ||
      (filters.groups.length > 0 &&
        !filters.groups[0].rules[0].field &&
        filters.groups[0].rules[0].type !== "page")
        ? {}
        : filters;
    // console.log(filterRules, filters);
    const response = await visitorService.fetch(activeWorkspace?.id, {
      fields: [
        "user_anonymous_id",
        "engagement",
        "first_viewed_page",
        "random_id",
        "user_name",
        "first_seen",
        "last_seen",
        "is_online",
        "location",
        "referer",
        "source",
        "pageviews",
        "last_viewed_page",
        "last_parsed_ua_os_family",
        "last_parsed_ua_us_family",
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term"
      ],
      limit: 50,
      domain: domain === "all_domains" ? null : domain,
      page: page,
      sort: `${sortKey}:${sortDirection}`,
      from_date: dateRange.startDate,
      to_date: dateRange.endDate,
      // segment filters
      filters: filterVisible || segment !== "everyone" ? filterRules : {}
    });

    if (response.data) {
      setTotal(response.data.count);
      return response.data;
    }

    return {
      list: [],
      count: 0
    };
  };

  useEffect(() => {
    document.title = "Visitor | Usermaven";
    fetchDomains();
    setSegmentType("visitor");
    setListsSegmentType("visitor");
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (modalOpen) {
      return;
    }

    if (tableRef.current) {
      setNoResults(false);
      tableRef.current.refresh();
    }
  }, [domain, filters]);

  /**
   * Reload the table if the date range changes.
   */
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.refresh();
    }
    if (noResults) {
      setNoResults(false); // reset no results
    }
  }, [dateRange]);

  /**
   * This function is responsible for handling the change in the table fields.
   * @param selectedColumnsList
   */
  const onColumnVisibilityChange = (selectedColumnsList: string[]) => {
    const newFields = tableFields.map((field) => {
      field.visible = selectedColumnsList.includes(field.key);
      return field;
    });

    setTableFields(newFields);
  };

  /**
   * Memorized method to calculate the table height based on the sibling elements.
   */
  const tableHeight = useMemo(() => {
    // Breakdown: 50px Visitor header height
    const headerHeight = 68;

    // Breakdown: 36px (Button Height), 1rem (Div Margin), 1rem (Div Margin), 1rem (Div Height)
    const subHeaderHeight = 36 + remToPx(1 + 1 + 1);

    return window.innerHeight - headerHeight - subHeaderHeight;
  }, []);

  const onNoResults = () => {
    setNoResults(true);
  };

  const onRowClick = (row: any) => {
    // navigate(row.user_anonymous_id);
    setOverviewModalOpen(true, "visitor", row.user_anonymous_id);
  };

  const onColumnChange = (payload: IActionPayload) => {
    const { type, columnKey, width, targetColumnKey } = payload;

    if (type === ActionType.ResizeColumn) {
      const newFields = tableFields.map((field) => {
        if (field.key === columnKey) {
          field.width = width;
        }
        return field;
      });

      setTableFields(newFields);
    }

    if (type === ActionType.ReorderColumns) {
      const columnIndex = tableFields.findIndex((field) => field.key === columnKey);
      const targetColumnIndex = tableFields.findIndex((field) => field.key === targetColumnKey);

      // shift the column to the target column index.
      const newFields = arrayMove(tableFields, columnIndex, targetColumnIndex);

      setTableFields(newFields);
    }
  };

  /**
   * Method to reset the columns to default.
   */
  const resetColumns = () => {
    console.log("Resetting columns", tableFields);

    // // cloning the default fields.
    const newFields = DEFAULT_VISITORS_FIELDS.map((field) => ({ ...field }));

    // Breaking the reference.
    setTableFields(newFields);

    // Hard refresh the table.
    tableRef.current?.refresh({
      columns: newFields
    });
  };

  /**
   * Method to apply the filters
   */

  const handleApplyFilters = (values: any) => {
    setFilters(values);
  };

  /**
   * Get current segment from the segments list
   */
  const currentSegmentName = useMemo(() => {
    if (segment === "everyone") {
      return "";
    }
    const s = segments.find((s: any) => s.id === segment);
    console.log("currentSegmentName", segments);
    if (s) {
      return s.name;
    }

    return "";
  }, [segments, segment]);

  const [openedSegmentPopover, setOpenedSegmentPopover] = useState(false);

  /**
   * Update or create a segment
   * @param values
   */
  const handleUpdateSegment = async (values: any) => {
    const currentSegment = segments.find((s: any) => s.id === segment);

    if (currentSegment && segment !== "everyone") {
      await updateSegment(
        activeWorkspace.id,
        currentSegment.id,
        {
          name: currentSegment.name,
          type: currentSegment.type,
          filters: values,
          description: currentSegment.description
        },
        true
      );
    }
  };

  return (
    <>
      <SegmentModal />
      <Paper
        // p="md"
        // p={"xs"}
        // className="um-mc"
        style={(theme) => ({
          background: colorScheme === "dark" ? theme.colors.dark[9] : "#F8F9FA"
        })}>
        <PageHeader
          sx={
            {
              // marginRight: "-2rem",
              // marginLeft: "-2rem"
            }
          }>
          <Flex align={"center"} justify={"center"} gap={"xs"}>
            <ActionIcon
              color={"dark.2"}
              variant={"transparent"}
              onClick={() => setInternalAside(!internalAside)}>
              <FontAwesomeIcon icon={solid("bars-sort")} />
            </ActionIcon>

            <Text lineClamp={1}>
              {currentSegmentName ? <>{currentSegmentName}</> : <>Visitors</>}{" "}
            </Text>
            <Text fz="xs" fw={600}>
              {" "}
              {total > 0 && <>({numberToCommas(total)})</>}
            </Text>
          </Flex>
          <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
        </PageHeader>

        <div className="um-visitors">
          <Flex
            align={"center"}
            justify={"space-between"}
            wrap={"wrap"}
            gap={"sm"}
            style={{
              // marginRight: "-2rem",
              // marginLeft: "-2rem",
              padding: "24px 19px"
            }}>
            <Popover
              closeOnClickOutside={false}
              opened={openedSegmentPopover}
              onChange={(opened) => setOpenedSegmentPopover(opened)}
              width={"100%"}
              position="bottom-start"
              shadow="md"
              withinPortal
              styles={{
                dropdown: {
                  maxWidth: "990px"
                }
              }}>
              <Popover.Target>
                <Button
                  onClick={() => setOpenedSegmentPopover((o) => !o)}
                  size="xs"
                  variant="outline"
                  mr={"sm"}
                  color={filterVisible ? (colorScheme === "dark" ? "dark.3" : "brand.5") : "gray.6"}
                  leftSection={
                    filterVisible ? undefined : <FontAwesomeIcon icon={regular("plus")} />
                  }>
                  {filterVisible
                    ? `Filters (${filters.rules.length || filters.groups.length})`
                    : "Add a filter"}
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <SegmentsQueryBuilder
                  fullWidth={true}
                  withClearButton={true}
                  handleSegmentCreate={() => {
                    resetExceptFilters();
                    setSegmentModalOpen(true);
                    setOpenedSegmentPopover(false);
                  }}
                  allowSegmentCreate={true}
                  handleSubmitButtonText={currentSegmentName ? "Update" : "Apply"}
                  handleSaveButtonText={
                    currentSegmentName ? "Save as a new Segment" : "Save as a Segment"
                  }
                  handleSubmit={(filters) => {
                    setFilterVisible(true);
                    handleApplyFilters(filters);

                    handleUpdateSegment(filters);
                    setOpenedSegmentPopover(false);
                  }}
                  forcedSegmentType={"visitors"}
                />
              </Popover.Dropdown>
            </Popover>

            <Flex align={"center"} gap={"md"}>
              <Text
                style={(theme) => {
                  return {
                    color: theme.colors.gray[7]
                  };
                }}>
                <DomainSelector
                  domains={domains}
                  domain={domain}
                  setDomain={setDomain}
                  loading={domainsLoading}
                  searchable
                />
              </Text>

              {/*<Box className="mr-4">*/}
              {/*  <SegmentDropdownMenu*/}
              {/*    type="visitor"*/}
              {/*    defaultValue={segment && segment !== "everyone" ? segment : undefined}*/}
              {/*    onChange={(value: any) => {*/}
              {/*      if (!value) {*/}
              {/*        reset();*/}
              {/*        navigate(`/env/${activeWorkspace.identifier}/visitors/everyone`);*/}
              {/*        return;*/}
              {/*      }*/}
              {/*      setFilters(segments.find((s: any) => s.id === value).filters);*/}
              {/*      setFilterVisible(false);*/}
              {/*      navigate(`/env/${activeWorkspace.identifier}/visitors/${value}`);*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Box>*/}
              <MultiSelectDropdown
                disabled={total === 0}
                buttonText={"Edit Displayed Attributes"}
                onChange={onColumnVisibilityChange}
                headerText={"Edit Columns"}
                searchPlaceholder={"Search for a column"}
                options={[...tableFields]}
                initialSelectedOptions={tableFields
                  .filter((field) => field.visible)
                  .map((field) => field.key)}
                onReset={() => resetColumns()}
                withHeader
                withSearch
                withReset
              />
            </Flex>
          </Flex>

          {noResults && (
            <>
              <NoResults
                type="search"
                heading="No Results"
                text={`No visitors found in the ${dateRange.label.toLowerCase()}. Try changing the date range or filters.`}
              />
            </>
          )}

          <Box
            style={{
              display: noResults ? "none" : "block",
              minWidth: "100%",
              maxWidth: "0px"
            }}>
            <VirtualDataTable
              ref={tableRef}
              columns={tableFields}
              rowKeyField="random_id"
              serverCallback={fetchNextPageCallback}
              height={tableHeight}
              noDataAvailable={onNoResults}
              onRowClick={onRowClick}
              onColumnChange={onColumnChange}
              hasFilterApplied={filterVisible}
            />
          </Box>
        </div>
      </Paper>

      <Outlet />
    </>
  );
};

export default Visitors;
