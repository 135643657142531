import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Paper,
  Text,
  Tooltip,
  useMantineColorScheme
} from "@mantine/core";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ChannelMappingRulesProps, useChannelMappingStore } from "@/stores/useChannelMappingStore";
import { ChannelMappingCondition } from "./ChannelMappingCondition";
import { useHover } from "@mantine/hooks";

export const ChannelMappingQueryBuilder = () => {
  /**
   * State isLoaded will be set when the component is loaded for the first time.
   * This is used to prevent sending multiple requests to the backend when the component is loaded
   * in case of editing.
   */
  const [isLoaded, setIsLoaded] = useState(false);
  /**
   * Channel mapping zustand store.
   */
  const [rules, setRules] = useChannelMappingStore((state) => [state.rules, state.setRules]);

  /**
   * This function is called when the user clicks on the "Add condition" button. It adds a new condition
   * to the rules array only if the last condition is valid.
   *
   * @param ruleIndex The index of the rule against which conditions are checked.
   * @returns
   */
  const checkConditionValueAddedToRules = (ruleIndex: number) => {
    const isValuesAdded = rules[ruleIndex].conditions.some(
      (condition) => condition.value.length === 0
    );
    return isValuesAdded ? false : true;
  };

  /**
   * Function to add a new condition to the rule.
   * @param ruleIndex
   */
  const handleAddCondition = (ruleIndex: number) => {
    if (!checkConditionValueAddedToRules(ruleIndex)) {
      toast.error("Please add value to the condition first.");
      return;
    }

    // Add a new condition to the rule.
    const newRules = rules.map((rule, index) => {
      if (index === ruleIndex) {
        rule.conditions.push({
          selector: "utm_source",
          operator: "is",
          value: ""
        });
      }
      return rule;
    });
    setRules(newRules);
  };

  /**
   * Add a new rule to the rules array.
   */
  const handleAddRule = () => {
    setRules([
      ...rules,
      {
        conditions: [
          {
            selector: "utm_source",
            operator: "is",
            value: ""
          }
        ]
      }
    ]);
  };

  /**
   * Function to update the selector value of a condition
   * @param value Value of the selector
   * @param ruleIndex
   * @param conditionIndex
   */
  const handleOnSelectorChange = (value: string, ruleIndex: number, conditionIndex: number) => {
    const newRules = rules.map((rule, index) => {
      if (index === ruleIndex) {
        rule.conditions[conditionIndex].selector = value;
      }
      return rule;
    });
    setRules(newRules);
  };

  /**
   * Function to update the operator value of a condition
   * @param value
   * @param ruleIndex
   * @param conditionIndex
   */
  const handleOnOperatorChange = (value: string, ruleIndex: number, conditionIndex: number) => {
    const newRules = rules.map((rule, index) => {
      if (index === ruleIndex) {
        rule.conditions[conditionIndex].operator = value;
      }
      return rule;
    });
    setRules(newRules);
  };

  /**
   * Function to update the value of a condition
   * @param value
   * @param ruleIndex
   * @param conditionIndex
   */
  const handleOnValueChange = (value: string, ruleIndex: number, conditionIndex: number) => {
    const newRules = rules.map((rule, index) => {
      if (index === ruleIndex) {
        rule.conditions[conditionIndex].value = value;
      }
      return rule;
    });
    setRules(newRules);
  };

  useEffect(() => {
    /**
     * Hack used here, because in case of edit mode, if there are multiple conditions added, it will be
     * sending a lot of requests. So, we setIsLoaded to true once the component is mounted after 500 ms,
     * this will avoid sending a lot of requests.
     *
     * Proper solution to implement...
     */
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }, []);

  const RenderRuleItem = ({
    rule,
    ruleIndex
  }: {
    rule: ChannelMappingRulesProps;
    ruleIndex: number;
  }) => {
    const { hovered, ref } = useHover();
    const { colorScheme } = useMantineColorScheme();
    return (
      <>
        <Paper
          withBorder={colorScheme === "dark"}
          bg={colorScheme === "light" ? "gray.1" : undefined}
          key={`rule-${ruleIndex}`}
          mb="md">
          <Flex
            ref={ref}
            // sx={(theme) => ({
            //   background:
            //     theme.colorScheme === "dark" ? theme.fn.rgba(theme.colors.dark[8], 0.3) : ""
            // })}
            key={`rule-${ruleIndex}`}
            pos="relative"
            direction="column"
            p={"sm"}>
            <Flex align={"center"} pt={16} key={ruleIndex}>
              <Flex pos={"relative"} direction={"column"}>
                {rule.conditions.map((condition, conditionIndex) => (
                  <Flex align={"center"} key={`${ruleIndex}-${conditionIndex}`} mb="md">
                    {conditionIndex === 0 ? (
                      <Box w={100}>
                        <Text fw={600} fz="md">
                          Where
                        </Text>
                      </Box>
                    ) : (
                      <Flex justify={"flex-end"} mr={"md"} w={84}>
                        <Badge radius={"sm"} color={"gray.6"} variant="filled" className="">
                          AND
                        </Badge>
                      </Flex>
                    )}
                    <ChannelMappingCondition
                      isLoaded={isLoaded}
                      ruleIndex={ruleIndex}
                      index={conditionIndex}
                      key={`${ruleIndex}-${conditionIndex}`}
                      selector={condition.selector}
                      operator={condition.operator}
                      value={condition.value}
                      onSelectorChange={(value) =>
                        handleOnSelectorChange(value, ruleIndex, conditionIndex)
                      }
                      onOperatorChange={(value) =>
                        handleOnOperatorChange(value, ruleIndex, conditionIndex)
                      }
                      onValueChange={(value) =>
                        handleOnValueChange(value, ruleIndex, conditionIndex)
                      }
                      onRemove={() => {
                        const newRules = rules.map((rule, index) => {
                          if (index === ruleIndex) {
                            rule.conditions.splice(conditionIndex, 1);
                          }
                          return rule;
                        });
                        setRules(newRules);
                      }}
                    />
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Flex ml={100}>
              <Button
                variant="subtle"
                // className="ml-24"
                mr={16}
                size="xs"
                onClick={() => handleAddCondition(ruleIndex)}
                leftSection={<FontAwesomeIcon icon={regular("plus")}></FontAwesomeIcon>}>
                Add Condition
              </Button>
            </Flex>

            {hovered && (
              <Box pos="absolute" right={2} top={2}>
                <Tooltip label="Remove">
                  <ActionIcon
                    variant="subtle"
                    onClick={() => {
                      setRules(rules.filter((_, index) => index !== ruleIndex));
                    }}
                    color={"red"}>
                    <FontAwesomeIcon icon={regular("trash")} />
                  </ActionIcon>
                </Tooltip>
              </Box>
            )}
          </Flex>
        </Paper>
        {ruleIndex !== rules.length - 1 && (
          <Center>
            <Badge radius={"sm"} color={"gray.7"} variant="filled" mb="md">
              OR
            </Badge>
          </Center>
        )}
      </>
    );
  };

  return (
    <Box w={"75%"}>
      {/* {JSON.stringify(rules)} */}
      {rules.map((rule, ruleIndex) => (
        <>
          <RenderRuleItem key={ruleIndex} rule={rule} ruleIndex={ruleIndex} />
          {ruleIndex === rules.length - 1 && <Divider my="md" />}
        </>
      ))}

      {/* Option to add a rule conditions */}
      <Center py="md">
        <Button
          variant="subtle"
          size="xs"
          onClick={() => handleAddRule()}
          leftSection={<FontAwesomeIcon icon={regular("plus")} />}>
          {rules.length === 0 ? "Add Rule" : "OR"}
        </Button>
      </Center>
    </Box>
  );
};
