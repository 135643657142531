import create from "zustand";

export interface Note {
  id: string;
  title: string;
  description: string;
  author: string;
  visibility: string;
  date: string;
  module: string;
  on_shareable_dashboard: boolean;
  hide_note: boolean;
}

interface NotesStore {
  notes: Note[];
  setNotes: (newNotes: Note[]) => void;
  allNotes: Note[];
  setAllNotes: (newNotes: Note[]) => void;
  addNote: (newNote: Note) => void;
  updateNote: (updatedNote: Note) => void;
  deleteNote: (id: string) => void;
}

// Create a Zustand store
const useAnnotationNotesStore = create<NotesStore>((set) => ({
  notes: [],
  setNotes: (newNotes) => set({ notes: newNotes }),
  allNotes: [],
  setAllNotes: (newNotes) => set({ allNotes: newNotes }),
  addNote: (newNote) =>
    set((state) => ({
      notes: [...state.notes, newNote],
      allNotes: [...state.allNotes, newNote]
    })),
  updateNote: (updatedNote) =>
    set((state) => ({
      notes: state.notes.map((note) => (note.id === updatedNote.id ? updatedNote : note)),
      allNotes: state.allNotes.map((note) => (note.id === updatedNote.id ? updatedNote : note))
    })),
  deleteNote: (id) =>
    set((state) => ({
      notes: state.notes.filter((note) => note.id !== id),
      allNotes: state.allNotes.filter((note) => note.id !== id)
    }))
}));

export default useAnnotationNotesStore;
