import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  Paper,
  useMantineTheme,
  Text,
  Flex,
  Group,
  useMantineColorScheme
} from "@mantine/core";
import { addDays, format, subDays } from "date-fns";
import {
  COMPARE_ANALYTICS_DROPDOWN_OPTIONS,
  DATE_FORMAT,
  DATE_RANGES,
  PLAN_TYPES,
  STARTER_PLAN_DISABLED_DATE_RANGES
} from "@/lib/utils/Constants";
import { useContext, useState } from "react";
import { DateRange } from "react-date-range";
import { useWebFiltersStore } from "@/stores/useWebFiltersStore";
import { LooseObject } from "../../../../types/types.d";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import { toast } from "react-toastify";
import useCompareAnalyticsStore from "@/stores/useCompareAnalyticsStore";
import React from "react";

interface Props {
  scrollPosition?: number;
  ranges?: LooseObject;
  selectedPeriodOption?: any;
  setSelectedPeriodOption?: any;
  selectedComparisonLabel?: any;
  setSelectedComparisonLabel?: any;
  onChange: (
    startDate: string,
    endDate: string,
    label: string,
    custom_range?: boolean | undefined,
    interval?: string | undefined
  ) => void;
  onIntervalChange?: (interval: string) => void;
  allowCustom?: boolean;
  defaultLabel?: string;
  allowInterval?: boolean;
  selectedInterval?: string;
  allowedIntervals?: string[];
  disabled?: boolean;
  showComparisonOption?: boolean;
}

export const DateDropdownMenu = ({
  scrollPosition = 0,
  ranges = DATE_RANGES,
  selectedPeriodOption, //state to store the selected comparison period option that may either be previous_period or custom.
  setSelectedPeriodOption, // setter for the above state selectedPeriodOption
  selectedComparisonLabel, // state to store the comparison dropdown label.
  setSelectedComparisonLabel, // setter for the above state selectedComparisonLabel
  onChange,
  onIntervalChange = () => {},
  allowCustom = true,
  allowInterval = false,
  showComparisonOption = false,
  selectedInterval = "",
  allowedIntervals = ["hour", "day", "week", "month"],
  disabled = false
}: Props) => {
  // Color scheme

  const { colorScheme } = useMantineColorScheme();

  // dropdown menu state
  const [opened, setOpened] = useState(false);
  // dropdown label state from store (default to reporting period range)
  const [dateLabel, setDateLabel] = useWebFiltersStore((state) => [
    state.dateLabel,
    state.setDateLabel
  ]);
  // active Workspace from AppLifecycleContext
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // Month-wise calendar date-picker
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  // Calendar state
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  // Comapre analytics state
  const [isCompareAnalyticsSelected, setIsCompareAnalyticsSelected] = useCompareAnalyticsStore(
    (state: any) => [state.isCompareAnalyticsSelected, state.setIsCompareAnalyticsSelected]
  );

  /**
   *
   * @param startDate Date range start date YYYY-MM-DD
   * @param endDate Date range end date YYYY-MM-DD
   * @param label Label for the date range e.g Last 30 Days
   */
  const handleOnChange = (
    startDate: string,
    endDate: string,
    label: string,
    interval: string | undefined,
    custom_range: boolean | undefined
  ) => {
    onChange(startDate, endDate, label, custom_range, interval);
    setDateLabel(label);
    setSelectedPeriodOption &&
      setSelectedPeriodOption(COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD);
  };

  /**
   * Handle date range change for custom date range picker.
   * The values are set on the state variable state and picked from there.
   */
  const applyCustomChange = () => {
    const startDate = new Date(state[0].startDate);
    const endDate = new Date(state[0].endDate);

    const period = startDate.getDate() === endDate.getDate() ? "hour" : "day";

    // Set the time of endDate to 23:59:59
    endDate.setHours(23, 59, 59, 999);

    const label = `${format(startDate, "MMM dd")} - ${format(endDate, "MMM dd")}`;

    handleOnChange(
      format(startDate, DATE_FORMAT),
      format(endDate, DATE_FORMAT),
      label,
      period,
      true
    );

    setIsCalendarVisible(false);
  };

  /**
   * Getter to show the label value.
   * @returns string
   */
  const getDateLabel = (): string => {
    return dateLabel ? dateLabel : "Last 30 Days";

    // if (query && query.from_date && query.to_date) {
    //   return `${format(new Date(query.from_date.replace(" ", "T")), "MMM dd")} - ${format(
    //     new Date(query.to_date.replace(" ", "T")),
    //     "MMM dd"
    //   )}`
    // }
  };

  return (
    <div className="relative">
      <Menu shadow="md" width={320} onChange={setOpened} disabled={disabled} zIndex={9999}>
        <Menu.Target>
          <Button
            disabled={disabled}
            size="xs"
            color={scrollPosition > 0.03 ? "white" : "gray.6"}
            variant={scrollPosition && scrollPosition > 0.03 ? "light" : "outline"}
            leftSection={<FontAwesomeIcon icon={regular("list-dropdown")} />}
            rightSection={
              <FontAwesomeIcon
                icon={solid("angle-up")}
                className={`transition transform rotate-180 ${opened ? "rotate-0" : ""}`}
              />
            }
            data-cy="date-drop-down-btn">
            {getDateLabel()}
          </Button>
        </Menu.Target>

        <Menu.Dropdown
          style={{
            right: "2rem"
          }}>
          {allowInterval && (
            <>
              <Flex align={"center"}>
                <Text pl={10} pr={16} flex={1}>
                  Group by
                </Text>
                <Group gap={8}>
                  <Button
                    onClick={() => onIntervalChange("day")}
                    size="xs"
                    color={selectedInterval === "day" ? "brand" : "gray.6"}
                    variant={selectedInterval === "day" ? "light" : "outline"}
                    disabled={!(allowedIntervals.indexOf("day") > 0)}>
                    Day
                  </Button>
                  <Button
                    onClick={() => onIntervalChange("week")}
                    size="xs"
                    color={selectedInterval === "week" ? "brand" : "gray.6"}
                    variant={selectedInterval === "week" ? "light" : "outline"}
                    disabled={!(allowedIntervals.indexOf("week") > 0)}>
                    Week
                  </Button>
                  <Button
                    onClick={() => onIntervalChange("month")}
                    size="xs"
                    color={selectedInterval === "month" ? "brand" : "gray.6"}
                    variant={selectedInterval === "month" ? "light" : "outline"}
                    disabled={!(allowedIntervals.indexOf("month") > 0)}>
                    Month
                  </Button>
                </Group>
              </Flex>
              <Menu.Divider />
            </>
          )}

          <Grid>
            <Grid.Col span={6}>
              {ranges.slice(0, 7).map((range: any, index: number) => (
                <React.Fragment key={index}>
                  {/* In case if label is divider, we add a separator to the menu */}
                  {range.label === "divider" ? (
                    <>
                      <Menu.Divider key={index} />
                    </>
                  ) : /* Date range menu items older than 6 months are disabled for starter plan */
                  STARTER_PLAN_DISABLED_DATE_RANGES.includes(range.label.toLowerCase()) &&
                    (activeWorkspace?.organization?.plan?.name === PLAN_TYPES.STARTER ||
                      activeWorkspace?.organization?.plan?.name ===
                        PLAN_TYPES.STARTER_BADGE_PROGRAM ||
                      activeWorkspace?.organization?.plan?.name === PLAN_TYPES.STARTER_LEGACY) ? (
                    <div
                      key={index}
                      className={"hover:bg-gray-100 cursor-not-allowed"}
                      onClick={() =>
                        toast.error("Please upgrade your plan to unlock these date ranges")
                      }>
                      <Menu.Item key={index} disabled>
                        <div className="flex">
                          <span className="flex-grow">{range.label}</span>
                          <span>
                            <FontAwesomeIcon
                              key={"fa" + index}
                              icon={regular("lock")}></FontAwesomeIcon>
                          </span>
                        </div>
                      </Menu.Item>
                    </div>
                  ) : (
                    // Date range menu item
                    <Menu.Item
                      key={index}
                      onClick={(event: any) => {
                        event.stopPropagation();
                        handleOnChange(
                          range?.startDate,
                          range?.endDate,
                          range.label,
                          range.interval,
                          false
                        );
                      }}>
                      <Flex>
                        <Flex flex={1}>{range.label}</Flex>
                        {range.label === dateLabel && (
                          <>
                            <span>
                              <FontAwesomeIcon
                                key={"fa" + index}
                                icon={regular("check")}></FontAwesomeIcon>
                            </span>
                          </>
                        )}
                      </Flex>
                    </Menu.Item>
                  )}
                </React.Fragment>
              ))}
            </Grid.Col>
            <Grid.Col span={6}>
              {ranges.slice(7).map((range: any, index: number) => (
                <React.Fragment key={index}>
                  {
                    /* Date range menu items older than 6 months are disabled for starter plan */
                    STARTER_PLAN_DISABLED_DATE_RANGES.includes(range.label.toLowerCase()) &&
                    (activeWorkspace?.organization?.plan?.name === PLAN_TYPES.STARTER ||
                      activeWorkspace?.organization?.plan?.name ===
                        PLAN_TYPES.STARTER_BADGE_PROGRAM ||
                      activeWorkspace?.organization?.plan?.name === PLAN_TYPES.STARTER_LEGACY) ? (
                      <div
                        key={index}
                        className={"hover:bg-gray-100 cursor-not-allowed"}
                        onClick={() =>
                          toast.error("Please upgrade your plan to unlock these date ranges")
                        }>
                        <Menu.Item key={index} disabled>
                          <div className="flex">
                            <span className="flex-grow">{range.label}</span>
                            <span>
                              <FontAwesomeIcon
                                key={"fa" + index}
                                icon={regular("lock")}></FontAwesomeIcon>
                            </span>
                          </div>
                        </Menu.Item>
                      </div>
                    ) : (
                      // Date range menu item
                      <Menu.Item
                        key={index}
                        onClick={(event: any) => {
                          event.stopPropagation();
                          handleOnChange(
                            range?.startDate,
                            range?.endDate,
                            range.label,
                            range.interval,
                            false
                          );
                        }}>
                        <div className="flex">
                          <span className="flex-grow">{range.label}</span>
                          {range.label === dateLabel && (
                            <>
                              <span>
                                <FontAwesomeIcon
                                  key={"fa" + index}
                                  icon={regular("check")}></FontAwesomeIcon>
                              </span>
                            </>
                          )}
                        </div>
                      </Menu.Item>
                    )
                  }
                </React.Fragment>
              ))}
            </Grid.Col>
          </Grid>
          <Divider mt={8} />
          <Grid>
            <Grid.Col span={6}>
              {/* Custom date range menu item, in some cases we do not show the custom option. */}
              {allowCustom &&
                (activeWorkspace?.organization?.plan?.name === PLAN_TYPES.STARTER ||
                activeWorkspace?.organization?.plan?.name === PLAN_TYPES.STARTER_BADGE_PROGRAM ||
                activeWorkspace?.organization?.plan?.name === PLAN_TYPES.STARTER_LEGACY ? (
                  // Custom date range menu item is disabled for starter plan
                  <>
                    <div
                      className={"hover:bg-gray-100 cursor-not-allowed"}
                      onClick={() =>
                        toast.error("Please upgrade your plan to unlock these date ranges")
                      }>
                      <Menu.Item disabled mt={4}>
                        <div className="flex">
                          <span className="flex-grow">Custom</span>
                          <span>
                            <FontAwesomeIcon icon={regular("lock")}></FontAwesomeIcon>
                          </span>
                        </div>
                      </Menu.Item>
                    </div>
                  </>
                ) : (
                  <>
                    <Menu.Item
                      mt={4}
                      onClick={(event: any) => {
                        event.stopPropagation();
                        setIsCalendarVisible(!isCalendarVisible);
                      }}>
                      <Text>Custom</Text>
                    </Menu.Item>
                  </>
                ))}
            </Grid.Col>
            <Grid.Col span={6}>
              {dateLabel !== "All Time" && showComparisonOption && (
                <>
                  <Menu.Item
                    mt={4}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      setIsCompareAnalyticsSelected(!isCompareAnalyticsSelected);
                      setSelectedPeriodOption(COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD);
                      setSelectedComparisonLabel(
                        COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD
                      );
                    }}>
                    {isCompareAnalyticsSelected ? (
                      <Text color="red">End comparison</Text>
                    ) : (
                      <Text>Compare</Text>
                    )}
                  </Menu.Item>
                </>
              )}
            </Grid.Col>
          </Grid>
        </Menu.Dropdown>

        {/* Custom date range calendar */}
        {isCalendarVisible && (
          <Paper
            shadow="md"
            withBorder
            pos="absolute"
            right={0}
            sx={(theme) => ({
              // borderColor:
              //   colorScheme === "dark"
              //     ? theme.colors.darkBorderColor[0]
              //     : theme.colors.lightBorderColor[0],

              ".rdrCalendarWrapper": {
                backgroundColor: colorScheme === "dark" ? theme.colors.dark[7] : ""
              },

              ".rdrDateDisplayWrapper": {
                backgroundColor: colorScheme === "dark" ? theme.colors.dark[6] : ""
              },

              ".rdrDateDisplayItem": {
                backgroundColor: colorScheme === "dark" ? theme.colors.dark[5] : ""
              },

              ".rdrNextPrevButton": {
                backgroundColor: colorScheme === "dark" ? theme.colors.dark[5] : ""
              },

              ".rdrDayNumber span": {
                color: colorScheme === "dark" ? theme.colors.dark[1] : ""
              },

              ".rdrDayDisabled": {
                backgroundColor: colorScheme === "dark" ? theme.colors.dark[5] : ""
              }
            })}>
            <DateRange
              className="um-react-date-range"
              editableDateInputs={false}
              onChange={(item: any) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
              maxDate={addDays(new Date(), 0)}
              rangeColors={["#7D47EB"]}
            />
            <Divider />
            <Flex
              py={"sm"}
              justify={"end"}
              sx={(theme) => ({
                borderColor:
                  colorScheme === "dark"
                    ? theme.colors.darkBorderColor[0]
                    : theme.colors.lightBorderColor[0]
              })}>
              <Button
                color="red"
                size="xs"
                onClick={() => {
                  setIsCalendarVisible(false);
                }}>
                Cancel
              </Button>
              <Button
                size="xs"
                ml={6}
                mr={6}
                onClick={() => {
                  applyCustomChange();
                }}>
                Apply
              </Button>
            </Flex>
          </Paper>
        )}
      </Menu>
    </div>
  );
};
