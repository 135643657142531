/* eslint-disable react-hooks/exhaustive-deps */
import {
  HeaderNavigationActions,
  HeaderNavigationContainer,
  HeaderNavigationH1
} from "@/ui/components/App/HeaderNavigation/HeaderNavigation.style";
import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  HoverCard,
  Menu,
  MenuDivider,
  Paper,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AnalyticsIcon } from "@/ui/pages/MavenAi/components/Assistant/Assistant";
import useReportStore, { IReport } from "@/stores/useReportStore";
import React, { useContext, useEffect, useState } from "react";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { showNotification } from "@mantine/notifications";
import { stringToInitials } from "@/lib/utils/StringUtility";
import { format, setHours, setMinutes } from "date-fns";
import { utcToRelativeTimezone } from "@/lib/utils/DateUtility";
import { openConfirmModal } from "@mantine/modals";

import { toast } from "react-toastify";
import ReportActivityLogs from "./ReportActivityLogs";
import { getSeedColor } from "@/style/ColorSeeding";
import ReportForm from "./ReportForm";
import { Header } from "@/ui/components/Common/Header/Header";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface IFormInputs {
  name: string;
  email_recipients: string;
}

const Reports = () => {
  const { colorScheme } = useMantineColorScheme();
  const theme = useGlobalMantineTheme();
  const [opened, setOpen] = useState(false);
  const [openedActivityLogs, setOpenActivityLogs] = useState(false);
  const { activeWorkspace, user: activeUser } = useContext(AppLifecycleContext);
  const [
    loading,
    fetchReports,
    reports,
    setReport,
    deleteReport,
    updateReport,
    previewType,
    setPreviewType
  ] = useReportStore((state) => [
    state.loading,
    state.fetchReports,
    state.list,
    state.setReport,
    state.deleteReport,
    state.updateReport,
    state.previewType,
    state.setPreviewType
  ]);

  const isActiveUserViewer = activeWorkspace?.members.some(
    (item: { user_id: any; role: { name: string } }) =>
      item.user_id === activeUser.id && item.role && item.role.name === "viewer"
  );

  useEffect(() => {
    document.title = "Reports | Usermaven";

    fetchReports(activeWorkspace.id);
  }, []);

  const convert24to12 = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    let date = new Date();
    date = setHours(date, hours);
    date = setMinutes(date, minutes);
    return format(date, "hh:mm aa");
  };

  const handleEdit = (report: IReport) => {
    console.log(report);
    setOpen(true);
    setReport(report);
  };

  const handleReportPause = (report: IReport) => {
    report = { ...report, is_paused: !report.is_paused };
    try {
      updateReport(activeWorkspace.id, report, (type, message) => {
        console.log(type, message);
        if (type === "success") {
          toast.success(
            `Report: ${report.name} ${report.is_paused ? "paused" : "resumed"} successfully`
          );
        }

        if (type === "error") {
          console.error(message);
          showNotification({
            color: "red",
            title: "Error",
            message: message,
            autoClose: 5000
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = (reportId: string) => {
    openConfirmModal({
      title: <h3 className="font-medium">Delete note</h3>,
      size: "sm",
      children: (
        <>
          <Text size="sm" className="mt-4">
            Are you sure you want to delete this report?
          </Text>
        </>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: {
        color: "red.6"
      },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        // Delete the report
        try {
          deleteReport(activeWorkspace.id, reportId);
          setOpen(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <Header
        title="Reports"
        rightSection={
          <>
            <>
              {isActiveUserViewer ? (
                <HoverCard width={280} shadow="md" withArrow disabled={!isActiveUserViewer}>
                  <HoverCard.Target>
                    <Button size="xs" mr="0.25rem">
                      Create Report
                      {isActiveUserViewer && (
                        <FontAwesomeIcon
                          icon={solid("lock-keyhole")}
                          className="w-4 h-4 ml-1.5 hover:opacity-80"
                        />
                      )}
                    </Button>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <Text size="xs">
                      Viewer cannot create report. Only the Owner/Admin create it.
                    </Text>
                  </HoverCard.Dropdown>
                </HoverCard>
              ) : (
                <Button
                  size="xs"
                  mr="0.25rem"
                  disabled={isActiveUserViewer}
                  onClick={() => setOpen(true)}>
                  Create Report
                </Button>
              )}
            </>
            <Button
              size="xs"
              ml="0.25rem"
              onClick={() => setOpenActivityLogs(true)}
              variant={"outline"}>
              Report Logs
            </Button>
          </>
        }
      />
      {reports.length === 0 && loading !== "fetching" ? (
        <Flex justify="center" align="center" py="xl" direction={"column"}>
          <Text fz="md" mb="md">
            <FontAwesomeIcon icon={light("filter-list")} size="2xl" />
          </Text>

          <Text mb="md">You don't have any reports yet. Please create a reports</Text>

          <Button size="sm" onClick={() => setOpen(true)}>
            Create Report
          </Button>
        </Flex>
      ) : (
        <>
          <Grid mt={"lg"}>
            {reports.map((report, index) => (
              <Grid.Col
                span={{
                  sm: 4,
                  base: 6,
                  md: 4
                }}
                key={index}>
                <Paper withBorder p={"md"} pos={"relative"} shadow="xs">
                  <Group>
                    <Group>
                      <AnalyticsIcon width={14} height={14} />

                      <Text size="sm" fw={500}>
                        {report.name}
                      </Text>
                      {report.is_paused && (
                        <Badge
                          radius={"md"}
                          size="sm"
                          variant="outline"
                          color={colorScheme === "dark" ? "dark.3" : "gray"}>
                          Paused
                        </Badge>
                      )}
                    </Group>

                    <Menu shadow="md">
                      <Menu.Target>
                        <ActionIcon
                          pos={"absolute"}
                          right={8}
                          variant="subtle"
                          color={colorScheme === "dark" ? "dark.3" : "gray"}>
                          <FontAwesomeIcon icon={solid("ellipsis-v")} />
                        </ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item
                          pb="sm"
                          onClick={(e) => {
                            if (isActiveUserViewer) {
                              e.stopPropagation();
                              return;
                            }

                            handleEdit(report);
                          }}
                          style={{
                            cursor: isActiveUserViewer ? "default" : "pointer"
                          }}>
                          <Tooltip
                            label={"Viewer cannot edit report. Only the Owner/Admin can do it."}
                            position="top"
                            disabled={!isActiveUserViewer}>
                            <Text
                              style={{
                                lineHeight: "0.55rem"
                              }}
                              c={
                                colorScheme === "dark"
                                  ? isActiveUserViewer
                                    ? theme.colors.dark[2]
                                    : theme.colors.dark[0]
                                  : isActiveUserViewer
                                  ? theme.colors.dark[2]
                                  : theme.colors.dark[9]
                              }>
                              Edit
                            </Text>
                          </Tooltip>
                        </Menu.Item>

                        <Menu.Item
                          pb="sm"
                          onClick={(e) => {
                            if (isActiveUserViewer) {
                              e.stopPropagation();
                              return;
                            }

                            handleReportPause(report);
                          }}
                          style={{
                            cursor: isActiveUserViewer ? "default" : "pointer"
                          }}>
                          <Tooltip
                            label={`Viewer cannot ${
                              report.is_paused ? "resume" : "pause"
                            } report. Only the Owner/Admin can do it.`}
                            position="top"
                            disabled={!isActiveUserViewer}>
                            <Text
                              style={{
                                lineHeight: "0.55rem"
                              }}
                              c={
                                colorScheme === "dark"
                                  ? isActiveUserViewer
                                    ? theme.colors.dark[2]
                                    : theme.colors.dark[0]
                                  : isActiveUserViewer
                                  ? theme.colors.dark[2]
                                  : theme.colors.dark[9]
                              }>
                              {report.is_paused ? "Resume" : "Pause"}
                            </Text>
                          </Tooltip>
                        </Menu.Item>
                        <Menu.Item
                          pb="sm"
                          onClick={(e) => {
                            if (isActiveUserViewer) {
                              e.stopPropagation();
                              return;
                            }
                            handleDelete(report.id || "");
                          }}
                          color={"red.7"}
                          style={{
                            cursor: isActiveUserViewer ? "default" : "pointer"
                          }}>
                          <Tooltip
                            label={"Viewer cannot delete report. Only the Owner/Admin can do it."}
                            position="top"
                            disabled={!isActiveUserViewer}>
                            <Text
                              style={{
                                lineHeight: "0.55rem"
                              }}
                              c={
                                colorScheme === "dark"
                                  ? isActiveUserViewer
                                    ? theme.colors.red[2]
                                    : theme.colors.red[4]
                                  : isActiveUserViewer
                                  ? theme.colors.red[3]
                                  : theme.colors.red[4]
                              }>
                              Delete
                            </Text>
                          </Tooltip>
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Group>

                  <Text size="xs" my={"sm"}>
                    {report.period === "weekly"
                      ? `Report will be sent on every ${report.weekday} at ${convert24to12(
                          report.schedule_time
                        )}`
                      : report.period === "monthly"
                      ? `Report will be sent on the first of every month at ${convert24to12(
                          report.schedule_time
                        )}`
                      : `Report will be sent everyday at ${convert24to12(report.schedule_time)}`}
                  </Text>

                  <Group gap={0}>
                    {report.email_recipients.map((email, index) => (
                      <Tooltip key={index} label={email} position={"top"}>
                        <Avatar
                          td={"none"}
                          size={20}
                          variant={"filled"}
                          radius={"xl"}
                          mr={8}
                          component="div"
                          styles={{
                            placeholder: {
                              backgroundColor: getSeedColor(email)
                            }
                          }}
                          tt={`uppercase`}>
                          {stringToInitials(email)}
                        </Avatar>
                      </Tooltip>
                    ))}
                  </Group>

                  <Text size="xs" c={"dimmed"} mt={"sm"} fs="italic">
                    {report.updated_by_user === null ? "Created" : "Updated"}{" "}
                    {utcToRelativeTimezone(report.updated_at)}, by{" "}
                    {report.updated_by_user?.full_name || report.created_by_user?.full_name}
                  </Text>
                </Paper>
              </Grid.Col>
            ))}
          </Grid>
        </>
      )}

      <ReportForm
        opened={opened}
        setOpen={setOpen}
        previewType={previewType}
        setPreviewType={setPreviewType}
      />
      <ReportActivityLogs opened={openedActivityLogs} setOpen={setOpenActivityLogs} />
    </>
  );
};

export default Reports;
