/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { numberToCommas, numberToUnit } from "@/lib/utils/StringUtility";
import React, { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import { SummaryComparisonCard } from "../SummaryComparisonCard";
import ContentLoader from "react-content-loader";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
};

const UniqueVisitorsComparisonCard = ({
  activeCard,
  setActiveCard,
  comparisonTopBarCardValues,
  loading,
  query
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const getUniqueVisitorsComparisonCardValue = () => {
    let value = comparisonTopBarCardValues.visitors;
    return value < 100000 ? numberToCommas(value) : numberToUnit(value);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="unique-visitors-card">
      {loading === "loaded" ? (
        <SummaryComparisonCard
          card="unique_visitors"
          activeCard={activeCard}
          onClick={() => setActiveCard("unique_visitors")}
          uniquekey={"uniqueVisitors"}
          loading={loading}
          comparisonValue={getUniqueVisitorsComparisonCardValue()}
          comparisonTooltip={`
                        ${numberToCommas(comparisonTopBarCardValues.new_visitors)} new visitors, 
                        ${numberToCommas(
                          comparisonTopBarCardValues.returning_visitors
                        )} returning visitors`}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const UniqueVisitorsComparisonCardMemoized = React.memo(UniqueVisitorsComparisonCard);
export { UniqueVisitorsComparisonCardMemoized as UniqueVisitorsComparisonCard };
