import "./onboarding_layout.scss";

import { Box, Divider, Text, useMantineColorScheme } from "@mantine/core";
import logoBlackSvg from "../../assets/images/logos/logo-black-usermaven.svg";
import logoWhiteSvg from "../../assets/images/logos/logo-white-usermaven.svg";
import classes from "./Layout.module.css";
export const OnboardingLayout = (props: any) => {
  const theme = useMantineColorScheme();
  return (
    <Box
      className={classes.wrapper}
      // sx={(theme) => ({
      //   ".um-onboarding-header": {
      //     background: theme.colorScheme === "dark" ? theme.colors.dark[7] : "#f0f0f0",
      //     borderColor: theme.colorScheme === "dark" ? theme.colors.gray[8] : "#dce0e3"
      //   },
      //   background: theme.colorScheme === "light" ? "#F8F9FA" : undefined,

      //   ".um-onboarding-footer": {
      //     background: theme.colorScheme === "dark" ? theme.colors.dark[8] : "",
      //     borderColor: theme.colorScheme === "dark" ? theme.colors.gray[8] : "#dce0e3"
      //   }
      // })}
    >
      <div className="um-onboarding-wrapper">
        <Box
          className="um-onboarding-header"
          bg={theme.colorScheme === "dark" ? "dark.7" : "gray.1"}>
          <img
            className="um-onboarding-logo"
            src={theme.colorScheme === "dark" ? logoWhiteSvg : logoBlackSvg}
            alt="Usermaven"
          />
        </Box>
        <div className="um-onboarding-container">{props.children}</div>
        <Divider />
        <Box
          className="um-onboarding-footer"
          bg={theme.colorScheme === "dark" ? "dark.7" : "gray.1"}>
          <Text>© 2024 Usermaven. All rights reserved</Text>
        </Box>
      </div>
    </Box>
  );
};
