import { NoResults } from "../../../../components/App/NoResults/NoResults";
import { LockButton } from "../../../../components/Common/LockButton/LockButton";
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Group,
  HoverCard,
  Loader,
  Paper,
  Progress,
  Select,
  Table,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { JourneyService } from "../../../../../lib/services/JourneyService";
import React, { useCallback, useContext, useEffect, useState } from "react";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import Sunburst from "../SunburstChart/SunburstChart";
import {
  InsightsDashboardHeader,
  InsightsDashboardHeaderLeft,
  InsightsDashboardHeaderRight
} from "../../../InsightsDashboard/Components/InsightsDashboardStyle/InsightsDashboardStyle.style";
import { DateDropdownMenu } from "../../../../components/App/Dropdowns/DateDropdownMenu";
import { useJourneyStore } from "../../../../../stores/useJourneyStore";
import { Oval } from "react-loader-spinner";
import { DATE_FORMAT, LOADER_COLOR } from "../../../../../lib/utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import JourneySetup from "./JourneySetup";
import { toast } from "react-toastify";
import { roundFunnelRate } from "../../../../../lib/utils/FunnelUtility";
import { StringParam, useQueryParams } from "use-query-params";
import { getReportingPeriodRange } from "../../../../../lib/utils/ReportingPeriodUtility";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import videoSrc from "@assets/videos/user-journeys-demo.mp4";
import { openConfirmModal } from "@mantine/modals";
import { useWebFiltersStore } from "@/stores/useWebFiltersStore";
import classes from "./JourneysListing.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
interface IJourneysListing {
  onNewJourneyClick?: () => void;
}

const JourneysListing = ({ onNewJourneyClick = () => {} }: IJourneysListing) => {
  const theme = useGlobalMantineTheme()
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [setDateLabel] = useWebFiltersStore((state) => [state.setDateLabel]);

  const journeyServices = new JourneyService();
  const [fetchingJourneys, setFetchingJourneys] = useState(true);
  const [fetchingJourneyInsights, setFetchingJourneyInsights] = useState(true);

  const [journeys, setJourneys] = useState<any[]>([]);
  const [selectedJourneyInsights, setSelectedJourneyInsights] = useState<any | any[]>(null);
  const [selectedJourney, setSelectedJourney] = useState<string | null>(null);
  const [mostCommonPaths, setMostCommonPaths] = useState<any[] | null>(null);
  // const [depth, setDepth] = useDebouncedState('6', 200);
  // const [depthLoading, setDepthLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    start_date: StringParam,
    end_date: StringParam
  });

  const [showCompleteList, setShowCompleteList] = useState(false);
  const [depth, setDepth] = useState(6);
  const [commonPathsLoading, setCommonPathsLoading] = useState(false);

  useEffect(() => {
    const getPeriod = getReportingPeriodRange(activeWorkspace.reporting_period);
    const startDate =
      getPeriod?.startDate || format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT);
    const endDate = getPeriod?.endDate || format(endOfDay(new Date()), DATE_FORMAT);
    setQuery({
      start_date: startDate,
      end_date: endDate
    });
    if (query && query.start_date && query.end_date) {
      setDateLabel(
        `${format(new Date(query.start_date.replace(" ", "T")), "MMM dd")} - ${format(
          new Date(query.end_date.replace(" ", "T")),
          "MMM dd"
        )}`
      );
    } else {
      setDateLabel(getPeriod?.label || "Last 30 Days");
    }
    getAllJourneys(null, 1, 20);
  }, []);

  useEffect(() => {
    if (!selectedJourney) {
      return;
    }
    getInsights(selectedJourney, query.start_date, query.end_date);
  }, [selectedJourney]);

  /**
   * Get the max number of paths from the most common paths
   */
  const getMaxPathsNumber = useCallback(() => {
    if (!mostCommonPaths || mostCommonPaths.length === 0) {
      return 0;
    }

    const max = mostCommonPaths.reduce((prev, current) => {
      return prev.length > current.length ? prev : current;
    });

    return max.length;
  }, [mostCommonPaths]);

  const [isModalOpen, setIsModalOpen, setJourneyStore] = useJourneyStore((state) => [
    state.modalOpen,
    state.setModalOpen,
    state.setJourneyStore
  ]);

  /**
   * Edit journey
   * @param journeyId
   */
  const editJourney = (journeyId: string) => {
    setIsModalOpen(true);

    // Get journey details
    const journey = journeys.find((journey: any) => journey.id === journeyId);
    console.log("Edit journey", journey.conditions);

    if (journey) {
      setJourneyStore({
        id: journey.id,
        condition: {
          key: "",
          value: journey.conditions[0].value,
          operator: "",
          type: journey.conditions[0].type,
          label: "",
          ignoreQueryParams: journey.conditions[0].ignoreQueryParams
        },
        name: journey.name,
        description: journey.description,
        flow: journey.journey_flow,
        pathType: journey.journey_path_type,
        exclusionConditions: journey.exclusion_rules.map((rule: any) => ({
          key: "",
          value: rule.exclusion_value,
          operator: "",
          type: rule.exclusion_type,
          label: ""
        })),
        groupingRules: journey.grouping_rules
      });

      setIsModalOpen(true);
    }
  };

  /**
   * Delete Journey Prompt
   */
  const deleteJourneyPrompt = (journeyId: string) => {
    openConfirmModal({
      title: <h2 className="font-medium">Delete journey</h2>,
      children: <Text size="sm">Are you sure you want to delete this journey ?</Text>,
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => deleteJourney(journeyId)
    });
  };

  /**
   * Delete journey
   */
  const deleteJourney = async (journeyId: string) => {
    const response = await journeyServices.deleteJourney(activeWorkspace?.id, journeyId);
    console.log(response);
    if (response.status === 200) {
      getAllJourneys(null, 1, 20);
      toast.success("Journey deleted successfully");
    }
  };

  /**
   * Get all journeys
   * @param sort
   * @param page
   * @param limit
   */
  const getAllJourneys = async (
    sort: "created_at:desc" | "name:desc" | "name:asc" | string | null = null,
    page: number = 1,
    limit: number = 20,
    defaultSelectedJourney: string | null = null
  ) => {
    setFetchingJourneys(true);
    const response = await journeyServices.getJourneys(activeWorkspace?.id, sort, page, limit);
    console.log(response);
    if (response.status === 200) {
      setJourneys(response.data);

      // Fetch insights for first journey
      if (response.data.length > 0 && response.data[0].id) {
        if (defaultSelectedJourney) {
          setSelectedJourney(defaultSelectedJourney);
        } else {
          setSelectedJourney(response.data[0].id);
        }
      }
    }
    setFetchingJourneys(false);
  };

  const handleDepthChange = async (depth: number) => {
    if (!selectedJourney) {
      return;
    }
    await getInsights(
      selectedJourney,
      query.start_date,
      query.end_date,
      depth,
      true,
      showCompleteList
    );
    setDepth(depth);
    return Promise.resolve();
  };

  /**
   * Method to get insights for a journey
   * @param journeyId
   * @param startDate
   * @param endDate
   * @param depth
   * @param noLoader
   */
  const getInsights = async (
    journeyId: string,
    startDate: string | null | undefined = "",
    endDate: string | null | undefined = "",
    depth = 6,
    noLoader = false,
    showCompleteList = false
  ) => {
    console.log("getInsights", journeyId);
    if (!noLoader) {
      setFetchingJourneyInsights(true);
    }
    const response = await journeyServices.getJourneyInsights(
      activeWorkspace?.id,
      journeyId,
      startDate ? startDate : "",
      endDate ? endDate : "",
      depth,
      showCompleteList
    );
    console.log(response);
    if (response.status === 200) {
      setSelectedJourneyInsights(response.data.insights);
      setMostCommonPaths(response.data.most_common_paths);
    }

    setFetchingJourneyInsights(false);
  };

  if (fetchingJourneys) {
    return (
      <div className="flex items-center justify-center my-12">
        <Oval color={LOADER_COLOR} height={14} width={14} />
      </div>
    );
  }

  /**
   * Method to handle date change
   * @param startDate
   * @param endDate
   */
  const handleDateChange = (startDate: string, endDate: string) => {
    if (!selectedJourney) {
      return;
    }
    setQuery({ start_date: startDate, end_date: endDate });
    getInsights(selectedJourney, startDate, endDate, depth, false, showCompleteList);
  };

  const handleCompleteListClick = async () => {
    if (!selectedJourney) {
      return;
    }
    setCommonPathsLoading(true);
    setShowCompleteList(!showCompleteList);
    await getInsights(
      selectedJourney,
      query.start_date,
      query.end_date,
      depth,
      true,
      !showCompleteList
    );
    setCommonPathsLoading(false);
  };

  return (
    <div>
      <JourneySetup
        onCreated={() => getAllJourneys()}
        onUpdated={(id: string) => {
          getAllJourneys(null, 1, 20, id);
          getInsights(id, query.start_date, query.end_date);
        }}
      />

      <Box component="div" className="py-4">
        {journeys.length === 0 ? (
          <Flex align={"center"} justify={"center"} direction={"column"}>
            <video width="60%" height="100%" controls>
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="mt-4"></div>
            <Container size="sm">
              <Text py="lg" ta="center">
                User Journeys help you visualize the paths users take through your site, from the
                moment they sign up to their interactions with your product. Track, analyze, and
                optimize these journeys to improve user experience and engagement.
              </Text>
            </Container>

            <LockButton
              label="New User Journey"
              errorMessage="Please upgrade your plan to Pro or Premium to create user journeys"
              onClick={() => {
                onNewJourneyClick();
              }}
            />
          </Flex>
        ) : (
          <div>
            <Flex align={"center"}>
              <Flex align={"center"} flex={1}>
                <Select
                  searchable
                  mr={4}
                  variant="filled"
                  size="sm"
                  label="Select User Journey"
                  // labelProps={{ style: { fontSize: "1rem", paddingBottom: "0.25rem" } }}
                  // searchable
                  w={300}
                  placeholder="Select user journey"
                  data={journeys.map((journey: any) => ({
                    value: journey.id,
                    label: journey.name
                  }))}
                  onChange={(value) => {
                    setShowCompleteList(false);
                    setSelectedJourney(value as string);
                  }}
                  value={selectedJourney}
                  disabled={fetchingJourneyInsights}></Select>
                <Flex align={"center"} mt={26}>
                  <Tooltip label="Edit" withArrow>
                    <ActionIcon
                      ml={"sm"}
                      mr={"sm"}
                      variant="outline"
                      color={theme.colorScheme === "dark" ? "gray.1" : "gray.6"}
                      disabled={fetchingJourneyInsights}
                      onClick={() => {
                        editJourney(selectedJourney as string);
                      }}>
                      <FontAwesomeIcon icon={regular("pencil")} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Delete" withArrow>
                    <ActionIcon
                      color="red"
                      variant="outline"
                      disabled={fetchingJourneyInsights}
                      onClick={() => {
                        deleteJourneyPrompt(selectedJourney as string);
                      }}>
                      <FontAwesomeIcon icon={regular("trash")} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              </Flex>

              <Flex>
                <DateDropdownMenu onChange={handleDateChange} />
              </Flex>
            </Flex>
            <Box mt={"lg"}>
              {fetchingJourneyInsights ? (
                <Center>
                  <Loader size={"xs"} />
                </Center>
              ) : (
                <>
                  {!selectedJourneyInsights ||
                  (Array.isArray(selectedJourneyInsights) &&
                    selectedJourneyInsights?.length === 0) ? (
                    <div className="pt-28 flex flex-col justify-center items-center">
                      <NoResults
                        heading={"No data available"}
                        text={"There is no data available for this user journey."}
                      />
                    </div>
                  ) : (
                    <>
                      <Sunburst
                        data={selectedJourneyInsights}
                        // mostCommonPaths={mostCommonPaths}
                        onDepthChange={handleDepthChange}
                      />
                      <Text mt={40} mb={"xs"} fw={500}>
                        Most commonly taken paths:
                      </Text>
                      <Paper
                        withBorder
                        radius={"sm"}
                        style={{
                          overflow: "hidden"
                        }}>
                        <Paper
                          style={{
                            overflowX: "auto",
                            width: "100%",
                            whiteSpace: "nowrap"
                          }}
                        >
                          <Table
                            horizontalSpacing="sm"
                            verticalSpacing="sm"
                            withColumnBorders
                            className={classes.table}>
                            <Table.Thead fw={600}>
                              <Table.Tr>
                                <Table.Th>Completion rate</Table.Th>
                                {Array.from(
                                  { length: getMaxPathsNumber() },
                                  (_, index) => index + 1
                                ).map((i) => (
                                  <Table.Th key={i}>Step {i}</Table.Th>
                                ))}
                                {/*<th>Users</th>*/}
                                {/*<th>Drop Off Rate</th>*/}
                                {/*<th>Conversion Rate</th>*/}
                              </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                              {mostCommonPaths?.map((path, index) => (
                                <Table.Tr key={index + "" + path.value}>
                                  <Table.Td>
                                    <Flex align={"center"} wrap={'nowrap'}>
                                      <Progress
                                        ml={16}
                                        w={"100%"}
                                        styles={(theme) => ({
                                          label: {
                                            fontSize: "7px"
                                          }
                                        })}
                                        radius={"sm"}
                                        size="sm"
                                        value={path[path.length - 1]?.conversion_rate}
                                      />
                                      <Text pl={"xs"} ta="right" w={60} fw={600} fz={11}>
                                        {path[path.length - 1]?.conversion_rate}%
                                      </Text>
                                    </Flex>
                                  </Table.Td>
                                  {Array.from(
                                    { length: getMaxPathsNumber() },
                                    (_, index) => index
                                  ).map((i) => (
                                    <Table.Td key={i}>
                                      {path[i] ? (
                                        <Flex direction={"column"} pt="xs">
                                          <Text ta={"center"} mb={"xs"} fw={500}>
                                            {path[i].name}
                                          </Text>
                                          <Flex
                                            direction={"column"}
                                            align={"center"}
                                            flex={1}
                                            px={"xs"}
                                            mb={"xs"}>
                                            <Flex align={"center"} justify={"center"} fz={12}>
                                              <Tooltip label="Users">
                                                <Text c={"green.7"} fz={12} fw={600}>
                                                  <FontAwesomeIcon icon={solid("right")} />

                                                  <Text span pl={4} fz={13} fw={600}>
                                                    {path[i].value || 0} Users
                                                  </Text>
                                                </Text>
                                              </Tooltip>
                                              {i !== 0 ? (
                                                <>
                                                  <Text px={"sm"}> ⬩ </Text>

                                                  <Tooltip label="Drop Off Rate">
                                                    <Text c="red.7" fz={13}>
                                                      <FontAwesomeIcon icon={solid("down")} />{" "}
                                                      <Text span pl={4} fz={13} fw={600}>
                                                        {roundFunnelRate(
                                                          path[i].drop_off_rate || 0
                                                        )}
                                                        %
                                                      </Text>
                                                    </Text>
                                                  </Tooltip>
                                                </>
                                              ) : null}
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      ) : (
                                        "-"
                                      )}
                                    </Table.Td>
                                  ))}
                                </Table.Tr>
                              ))}
                            </Table.Tbody>
                          </Table>
                        </Paper>
                      </Paper>
                      <Center>
                        <Group align={"center"} my={"lg"}>
                          <Button
                            variant={"light"}
                            size={"xs"}
                            disabled={(mostCommonPaths as any)?.length < 5}
                            onClick={() => handleCompleteListClick()}
                            loading={commonPathsLoading}>
                            {commonPathsLoading
                              ? "Loading..."
                              : showCompleteList
                              ? "Show Top 5 Results"
                              : "Show Complete List"}
                          </Button>

                          <HoverCard
                            width={350}
                            shadow="md"
                            withArrow
                            position={"top"}
                            withinPortal>
                            <HoverCard.Target>
                              <FontAwesomeIcon icon={regular("info-circle")}></FontAwesomeIcon>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                              <Text size="xs">
                                When you click on "Show Complete List", you will see all the paths
                                taken by users in this journey.
                                <br />
                                <br />
                                <Text span size="xs" fs="italic">
                                  Note: Maximum of 50 paths will be shown.
                                </Text>
                              </Text>
                            </HoverCard.Dropdown>
                          </HoverCard>
                        </Group>
                      </Center>
                    </>
                  )}
                </>
              )}
            </Box>
          </div>
        )}
      </Box>
    </div>
  );
};

export default JourneysListing;
