import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
import { SettingsHeaderContent } from "@/ui/components/App/Settings/SettingsHeaderContent/SettingsHeaderContent";
import { Box, Button, Flex, Title, Text } from "@mantine/core";
import { useEffect } from "react";
import _debounce from "lodash.debounce";
import { ChannelMappingModal } from "../../../../components/App/ChannelMapping/ChannelMappingModal";
import ChannelMappingTable from "@/ui/components/App/ChannelMapping/ChannelMappingTable";
import { useChannelMappingStore } from "@/stores/useChannelMappingStore";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { toast } from "react-toastify";

const ChannelMapping = () => {
  const [setModalOpen] = useChannelMappingStore((state) => [state.setModalOpen]);
  const { isStarterPlan } = useWorkspaceUtilityHook();

  useEffect(() => {
    document.title = "Custom Channel Mapping | Usermaven";
  }, []);

  return (
    <>
      <ChannelMappingModal />
      <Flex align={"center"}>
        <Flex direction={"column"} flex={1}>
          <Title order={4} fw={600} mb="md">
            Categorize traffic sources into custom channels
          </Title>
          <Text mb="md">
            Map your utm parameters, first url and referrer information into a custom channel
          </Text>
        </Flex>
        <Button
          color="brand"
          onClick={() =>
            isStarterPlan
              ? toast.error(
                  "Custom channel mapping is available in Premium plans. Please upgrade your plan to Premium."
                )
              : setModalOpen(true)
          }
          rightSection={isStarterPlan ? <FontAwesomeIcon icon={regular("lock")} /> : undefined}>
          Add a Custom Channel
        </Button>
      </Flex>

      <ChannelMappingTable />
    </>
  );
};

export default ChannelMapping;
