import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import useFeatureAdoptionStore, { Module } from "@/stores/useFeatureAdoptionStore";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Collapse,
  Divider,
  Flex,
  Grid,
  Group,
  Paper,
  Space,
  Text,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import { createStyles } from "@mantine/emotion";
import classes from "./FeatureAdoptionLeftPane.module.css";
import { useDisclosure } from "@mantine/hooks";
import clsx from "clsx";
import { isActive } from "@tiptap/react";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface IFeatureAdoptionLeftPane {
  setActiveModule: (e: any) => void;
  setActiveFeature: (e: any) => void;
}

export const FeatureAdoptionLeftPane = ({
  setActiveModule,
  setActiveFeature
}: IFeatureAdoptionLeftPane) => {
  /**
   * This left pane is used to provide the easy navigation view for the users to access:
   *
   * 1. Overview of all features.
   * 2. Select individual feature to view the details.
   */
  const { colorScheme } = useMantineColorScheme();

  const [modules, setModules] = useFeatureAdoptionStore((state) => [
    state.modules,
    state.setModules
  ]);

  const { activeWorkspace } = useContext(AppLifecycleContext);
  let { moduleId, featureId } = useParams();
  const theme = useGlobalMantineTheme();

  const RenderModuleItemsAndFeatures = ({ module, index }: { module: Module; index: number }) => {
    const [opened, { toggle }] = useDisclosure(false);
    return (
      <Box key={index}>
        <>
          <Box
            mx={10}
            mr={"sm"}
            onClick={() => {
              toggle();
              setModules(
                modules.map((m, i) => {
                  if (i === index) {
                    return { ...m, is_active: !m.is_active };
                  } else {
                    return { ...m, is_active: false };
                  }
                })
              );
            }}>
            <Box
              mt={module.features.length === 0 ? 4 : 2}
              mb={module.features.length === 0 ? 4 : 2}
              className={clsx(classes.sublink, module.is_active ? classes.sublinkActive : "")}>
              <Flex align={"center"}>
                <Flex justify={"space-between"}>
                  <Flex align={"center"}>
                    <Box w={16} fz="xs">
                      <FontAwesomeIcon
                        icon={solid("angle-right")}
                        className={`mr-3 transform  text-gray-800 transition-all duration-200 ease-in-out cursor-pointer text-sm ${
                          module.is_active ? "rotate-90" : ""
                        }`}
                      />
                    </Box>

                    <Text>{module.name}</Text>
                  </Flex>
                  ...
                  {module.features.length === 0 && (
                    <>
                      <Tooltip
                        w={200}
                        multiline
                        position="bottom"
                        className="z-50"
                        label="You have not added any features in the module. Add some features in the module.">
                        <Box
                          w={16}
                          mt={3}
                          ml={"xs"}
                          fz="xs"
                          c={colorScheme === "dark" ? "dark.4" : "yellow.9"}>
                          <FontAwesomeIcon icon={regular("exclamation-circle")} />
                        </Box>
                      </Tooltip>
                    </>
                  )}
                </Flex>
              </Flex>
            </Box>
          </Box>
        </>
        {module.features && module.features.length > 0 && module.is_active && (
          <>
            {module.features.map((feature: any, featureIndex: number) => (
              <Box
                h={"auto"}
                ml={42}
                my={"sm"}
                key={featureIndex}
                onClick={() => {
                  setActiveModule(module);
                  setActiveFeature(feature);
                }}>
                <NavLink
                  to={`/env/${activeWorkspace.identifier}/insights/dashboard/feature/${module.id}/${feature.id}`}
                  className={({ isActive }) => (isActive ? `${classes.active} ` : classes.link)}>
                  <div>
                    <Tooltip label={toTitleCase(feature.name)} disabled={feature.name.length <= 18}>
                      <Text tt="capitalize">
                        {feature.name.length > 24
                          ? toTitleCase(feature.name.substring(0, 24) + "...")
                          : feature.name}
                      </Text>
                    </Tooltip>
                  </div>
                </NavLink>
              </Box>
            ))}
          </>
        )}
      </Box>
    );
  };

  if (modules.length === 0) {
    return <></>;
  }
  return (
    <>
      {/* <Grid.Col span={2}> */}
      <Box
        pos="fixed"
        className={classes.root}
        // style={{
        //   width: !internalAside ? "0px" : "244px",
        //   opacity: !internalAside ? 0 : 1
        // }}
      >
        <Box className={classes.header}>
          <Group gap={8} wrap="nowrap">
            <Box w={16} h={16}>
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.28303 3.53305C9.40739 3.83956 9.47143 4.16813 9.47143 4.5C9.47143 4.83187 9.40739 5.16045 9.28303 5.46695C9.15866 5.77344 8.97643 6.0518 8.74684 6.28618C8.51725 6.52055 8.2448 6.70635 7.9451 6.83308C7.6454 6.9598 7.32426 7.025 7 7.025C6.67574 7.025 6.3546 6.9598 6.0549 6.83308C5.7552 6.70635 5.48275 6.52055 5.25316 6.28618C5.02357 6.0518 4.84134 5.77344 4.71697 5.46695C4.59261 5.16044 4.52857 4.83187 4.52857 4.5C4.52857 4.16813 4.59261 3.83956 4.71697 3.53305C4.84134 3.22656 5.02357 2.9482 5.25316 2.71382C5.48275 2.47945 5.7552 2.29365 6.0549 2.16692C6.3546 2.0402 6.67574 1.975 7 1.975C7.32426 1.975 7.6454 2.0402 7.9451 2.16692C8.2448 2.29365 8.51725 2.47945 8.74684 2.71382C8.97643 2.9482 9.15866 3.22656 9.28303 3.53305ZM4.5042 1.95515C3.8427 2.63043 3.47143 3.54588 3.47143 4.5C3.47143 5.45412 3.8427 6.36957 4.5042 7.04485C5.16577 7.7202 6.06349 8.1 7 8.1C7.93651 8.1 8.83423 7.7202 9.4958 7.04485C10.1573 6.36957 10.5286 5.45412 10.5286 4.5C10.5286 3.54588 10.1573 2.63043 9.4958 1.95515C8.83423 1.2798 7.93651 0.9 7 0.9C6.06349 0.9 5.16577 1.2798 4.5042 1.95515ZM12.0401 14.025H1.96003C2.04548 11.9455 3.72304 10.2875 5.77589 10.2875H8.22411C10.2797 10.2875 11.9572 11.9456 12.0401 14.025ZM5.77589 9.2125C3.08034 9.2125 0.9 11.4413 0.9 14.1879C0.9 14.6896 1.29909 15.1 1.79554 15.1H12.2045C12.7009 15.1 13.1 14.6896 13.1 14.1879C13.1 11.4413 10.9197 9.2125 8.22411 9.2125H5.77589Z"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="0.2"
                />
              </svg>
            </Box>
            <Title order={5}>Feature Adoption</Title>
          </Group>
        </Box>
        <Divider
          style={{
            borderColor: colorScheme === "light" ? theme.colors.gray[3] : undefined
          }}
        />
        <Box className={classes.section}>
          <NavLink
            to={`/env/${activeWorkspace.identifier}/insights/dashboard/feature`}
            className={({ isActive }) =>
              isActive && !moduleId && !featureId ? clsx(classes.active) : classes.link
            }>
            <Text fw={500}>Group report</Text>
          </NavLink>
        </Box>
        <Text mb="sm" tt="uppercase" px={20} fz="xs" fw={600} className={classes.subHeaderText}>
          Feature-wise reports
        </Text>
        {modules.map((module, index) => (
          <RenderModuleItemsAndFeatures module={module} index={index} />
        ))}
      </Box>
      {/* </Grid.Col> */}
    </>
  );
};
