export const CHANNEL_MAPPING_COLUMNS = [
  { key: "drag", width: 50, isEditable: false, title: "" },
  { key: "priority", title: "Priority", span: 1 },
  { key: "mapped_channel", title: "Channel", span: 3 },
  { key: "conditions", title: "Conditions", span: 6 },
  { key: "actions", title: "Actions", span: 2 }
];

/**
 * Operator options for the condition
 */

export const OPERATOR_OPTIONS = [
  {
    group: "Standard",
    items: [
      { label: "is", value: "is" },
      { label: "contains", value: "contains" },
      // These are implemented with regex in the backend
      { label: "starts with", value: "starts_with", implementation: "regex" },
      { label: "ends with", value: "ends_with", implementation: "regex" }
    ]
  },
  {
    group: "Regex",
    items: [
      // These are implemented with regex in the backend
      { label: "matches pattern", value: "matches_pattern", implementation: "regex" },
      { label: "matches regex", value: "matches_regex", implementation: "regex" }
    ]
  }
];
// export const OPERATOR_OPTIONS = [
//   {
//     label: "is equal to",
//     value: "is",
//     group: "Standard"
//   },
//   {
//     label: "contains",
//     value: "contains",
//     group: "Standard"
//   },
//   {
//     label: "starts with",
//     value: "starts_with",
//     implementation: "regex",
//     group: "Standard"
//   },
//   {
//     label: "ends with",
//     value: "ends_with",
//     group: "Standard",
//     implementation: "regex" // used to filter for page URL
//   },
//   {
//     label: "matches pattern",
//     value: "matches_pattern",
//     group: "Regex", // group appearing in the UI dropdown
//     implementation: "regex"
//   },
//   {
//     label: "matches regex",
//     value: "matches_regex",
//     group: "Regex",
//     implementation: "regex"
//   }
// ];

/**
 * Parameter Selector options for the channel mapping condition
 */
export const SELECTOR_OPTIONS = [
  {
    label: "UTM Medium",
    value: "utm_medium"
  },
  {
    label: "UTM Source",
    value: "utm_source"
  },
  {
    label: "UTM Campaign",
    value: "utm_campaign"
  },
  {
    label: "Page URL",
    value: "url"
  },
  {
    label: "Referrer",
    value: "referer"
  }
];
