import { useContext, useState } from "react";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import EventsSection from "./EventsSection";
import { WorkspacePinnedEventsService } from "@/lib/services/WorkspacePinnedEventsService";
import { EventsService } from "@/lib/services/EventsService";
import { Paper, Stack } from "@mantine/core";

export interface ITopEventsTab {
  type: "user" | "company" | "visitor";
  uniqueId: string | undefined;
}

const TopEventsTab = ({ uniqueId, type }: ITopEventsTab) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [hasEventsInWorkspace, sethasEventsInWorkspace] = useState(true);

  const workspacePinnedEventsService = new WorkspacePinnedEventsService();
  const eventsService = new EventsService();

  /** Method to fetch top pinned events data */
  const onFetchPinnedEvents = async (page: number) => {
    return await fetchPinnedEvents(page);
  };

  const onFetchCustomEvents = async (page: number) => {
    return await fetchCustomEvents(page);
  };

  const fetchPinnedEvents = async (page: number) => {
    const response = await workspacePinnedEventsService
      .usageForContact(activeWorkspace?.id, uniqueId || "", page, type)
      .catch((err) => {
        if (err && err?.response?.status && err.response.status === 422) {
          sethasEventsInWorkspace(false);
        }
      });
    if (response?.data) {
      return response.data;
    }
    return [];
  };

  const fetchCustomEvents = async (page: number) => {
    const response = await eventsService.groupedEventsByContact(
      activeWorkspace?.id,
      uniqueId || "",
      page,
      type
    );
    if (response?.data) {
      return response.data;
    }
    return [];
  };

  return (
    <>
      <Stack>
        <EventsSection
          type="pinned_events"
          title="Pinned Events"
          hasEventsInWorkspace={hasEventsInWorkspace}
          onFetchEvents={onFetchPinnedEvents}
        />
        <EventsSection
          type="custom_events"
          title="Custom Events"
          hasEventsInWorkspace={hasEventsInWorkspace}
          onFetchEvents={onFetchCustomEvents}
        />
      </Stack>
    </>
  );
};

export default TopEventsTab;
