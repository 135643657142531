import useStyles from "@/ui/pages/MavenAi/components/Assistant/Assistant.styles";
import { Box, Flex, Group, Stack, Text } from "@mantine/core";
import RobotIcon from "@/ui/pages/MavenAi/components/RobotIcon/RobotIcon";
import WebAnalyticsIcon from "@assets/images/icons/ai-assistant/web-analytics.svg";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface ISplashScreenProps {
  hideSuggestions?: boolean;
  onSuggestionClick?: (suggestion: string) => void;
}

const SUGGESTIONS = [
  "What is my top traffic source for this month?",
  "What is the percentage difference in traffic from the previous 2 months?",
  "Give me the traffic breakdown for last 6 months",
  "What date has a traffic spike in the last month?",
  "Give me the top sources and their traffic for the last 3 months"
];

const SplashScreen = ({
                        hideSuggestions = true,
                        onSuggestionClick = () => {
                        }
                      }: ISplashScreenProps) => {
  const theme = useGlobalMantineTheme();

  const { classes } = useStyles();

  return (
    <Box sx={{
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <Flex align={"center"} justify={"center"} direction={"column"}>
        <Stack mb={"xl"}>
          {/*<img src={logo} alt="Usermaven" />*/}
          <Group align={"center"} justify={"center"}>
            <RobotIcon />
            <Text sx={{
              fontWeight: 700,
              fontSize: "40px",
              lineHeight: "29px"
            }}>
              Maven AI
            </Text>
          </Group>
          <Text size={"xl"} fw={700} color={"gray"} sx={() => ({
            fontWeight: 400,
            fontSize: "20px",
            color: theme.colorScheme === "dark" ? "#C1C2C5" : "#000000"
          })}>
            Your AI data analytics companion
          </Text>
        </Stack>
        {
          !hideSuggestions && (
            <Box sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "16px",
              justifyContent: "center",
              marginTop: "40px"
            }}>
              {
                SUGGESTIONS.map((suggestion, index) => (
                  <Box className={classes.suggestionCard} p={"sm"} key={index}
                       onClick={() => onSuggestionClick(suggestion)}>
                    <Stack gap={"xs"}>
                      <Group align={"center"}>
                        <img
                          src={WebAnalyticsIcon}
                          alt="assistant"
                          width="32"
                          height="32"
                        />
                        <Text
                          className={classes.suggestionCardTitle}
                        >
                          Website Analytics
                        </Text>
                      </Group>

                      <Text className={classes.suggestionCardText}>
                        {suggestion}
                      </Text>
                    </Stack>
                  </Box>
                ))
              }
            </Box>
          )
        }

      </Flex>
    </Box>
  );
};

export default SplashScreen;