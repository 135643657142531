import {
  Button,
  Input,
  Modal,
  Text,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { closeAllModals, openModal } from "@mantine/modals";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { OrganizationService } from "@/lib/services/OrganizationService";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import { PLAN_TYPES, TOAST_MESSAGES } from "@/lib/utils/Constants";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { ThirdPartyTracking } from "@/lib/utils/ThirdPartyTracking";
import { useContext, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import TimezoneSelect, { ITimezone } from "react-timezone-select";
import { toast } from "react-toastify";
import { WorkspaceType } from "types/types.d";
import { Label } from "../../Common/Label/Label";
import { ValidationLabel } from "../../Common/ValidationLabel/ValidationLabel";
import "./modal.scss";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface WorkspaceModalProps {
  /**
   * Button contents
   */
  title?: string;
  /**
   * Optional click handler
   */
  open: boolean;
  isEdit?: boolean;
  isRedirect?: boolean;
  editWorkspaceDetails?: WorkspaceType;
  closeModal: (value: boolean) => void;
}

interface IFormInputs {
  name: string;
  website: string;
}

export const WorkspaceModal: React.FC<WorkspaceModalProps> = ({
  open,
  closeModal,
  isEdit = false,
  isRedirect = true,
  ...props
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    register
  } = useForm<IFormInputs>({});
  const { colorScheme } = useMantineColorScheme();
  const { workspaces, setWorkspaces, activeWorkspace, setActiveWorkspace } =
    useContext(AppLifecycleContext);
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
    props.editWorkspaceDetails?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const onChangeTimezone = (tz: any): any => {
    setSelectedTimezone(tz.value);
  };
  const [loading, setLoading] = useState("idle");
  const modifierLoading = useRef("idle");
  const navigate = useNavigate();
  const handleApplyModifier = async (onClose: any, organizationId: string) => {
    modifierLoading.current = "loading";
    await new OrganizationService()
      .applyModifier(organizationId)
      .then((res) => {
        if (res.data) {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status_code === 422) {
          toast.error(err.response.data.detail);
        }
      });
    onClose();
    modifierLoading.current = "loaded";
  };

  const onWorkspaceModalSubmit = async (data: any) => {
    console.log(data);
    const wsService = new WorkspaceService();
    data.timezone = selectedTimezone;
    setLoading("loading");
    await wsService
      .create(data)
      .then((res) => {
        if (res.data) {
          toast.success(TOAST_MESSAGES.WORKSPACE_CREATED);
          // add item to the list.
          const wsList = [...workspaces, res.data];
          setWorkspaces(wsList);
          const TPService = new ThirdPartyTracking();
          TPService.track("workspace_created");
          closeModal(false);

          if (!isEdit && isRedirect) {
            setActiveWorkspace(res.data);
            navigate(`/env/${res.data.identifier}/setup-guides/integration`);
          }
          reset();
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          // toast(err.response.data.detail)
          toast.error(({ closeToast }) => <div>{err.response.data.message} </div>);

          // Modifiers option is available only for the monthly PRO plan.
          // Lifetime users will have to upgrade it from the billing.
          if (err.response.data?.plan_name === PLAN_TYPES.PRO) {
            openModal({
              zIndex: 1000,
              title: (
                <h2 className="flex flex-grow-1 justify-center font-medium">Upgrade your plan</h2>
              ),
              // title: "Pinned event cannot be deleted",
              children: (
                <>
                  <div className=" ">
                    <h2 className="mb-4">
                      You have exceeded your plan limits, would you like to upgrade your plan?
                    </h2>
                    <p>
                      Upgrading to the next plan will give you{" "}
                      <span className="font-medium">
                        {err.response.data.next_plan_limits.workspaces} workspaces
                      </span>{" "}
                      and{" "}
                      <span className="font-medium">
                        {numberToCommas(err.response.data.next_plan_limits.events)} events
                      </span>
                      .
                    </p>
                    <div className="py-4 flex justify-center">
                      <Button color="gray.9" variant="subtle" onClick={() => closeAllModals()}>
                        Cancel
                      </Button>
                      <Button
                        color={"green.6"}
                        disabled={modifierLoading.current === "loading"}
                        loading={modifierLoading.current === "loading"}
                        onClick={() => {
                          handleApplyModifier(closeAllModals, err.response.data.organization_id);
                        }}>
                        Yes, upgrade my plan!
                      </Button>
                    </div>
                  </div>
                </>
              )
            });
          }
        }
      });
    setLoading("loaded");
  };
  const theme = useGlobalMantineTheme();
  return (
    <>
      <Modal
        size={"540px"}
        opened={open}
        onClose={() => closeModal(false)}
        title={
          isEdit ? (
            <Title order={4}>Update Workspace</Title>
          ) : (
            <Title order={4}>Create Workspace</Title>
          )
        }>
        <Text size="sm">
          A workspace in Usermaven is like a separate room for each brand or client, where you can
          manage and view their analytics independently. It keeps data organized and easy to access
          for each specific entity.
        </Text>
        <div className="pt-4">
          <div className="um-modal--content--form">
            <form onSubmit={handleSubmit(onWorkspaceModalSubmit)} id="workspaceModalSubmit">
              <div className="um-modal--content--form--input">
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <Input.Wrapper size="xs" id={"workspaceName"} label="Workspace Name" required>
                      <Input
                        mt={4}
                        value={value}
                        onChange={onChange}
                        placeholder="e.g ContentStudio"
                        error={(invalid || error) === true}
                        defaultValue={props.editWorkspaceDetails?.name}
                      />
                    </Input.Wrapper>
                  )}
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, maxLength: 24 }}
                />

                {errors.name?.type === "required" && (
                  <ValidationLabel
                    validationType="error"
                    text="Workspace name is required"
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
                {errors.name?.type === "maxLength" && (
                  <ValidationLabel
                    validationType="error"
                    text="Workspace name should be less than 24 characters."
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
              </div>
              <div className="um-modal--content--form--input">
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <Input.Wrapper size="xs" id={"websiteURL"} label="Website URL" required>
                      <Input
                        mt={4}
                        value={value}
                        onChange={onChange}
                        placeholder="e.g https://contentstudio.io"
                        error={(invalid || error) === true}
                        defaultValue={props.editWorkspaceDetails?.website}
                      />
                    </Input.Wrapper>
                  )}
                  name="website"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, maxLength: 48 }}
                />

                {errors.website?.type === "required" && (
                  <ValidationLabel
                    validationType="error"
                    text="Website URL is required"
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
                {errors.website?.type === "maxLength" && (
                  <ValidationLabel
                    validationType="error"
                    text="Website URL should be less than 48 characters."
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
              </div>
              <div className="um-modal--content--form--input">
                <Text fz="xs" mb={"sm"}>
                  Workspace timezone
                </Text>
                <TimezoneSelect
                  className={`um-select-timezone ${
                    colorScheme === "dark" ? "um-select-timezone-dark" : "um-select-timezone-light"
                  }`}
                  value={selectedTimezone}
                  onChange={onChangeTimezone}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: 14,
                      background: colorScheme === "dark" ? "#25262b" : "hsl(0, 0%, 100%)",
                      borderColor: colorScheme === "dark" ? "#373a40" : "hsl(0, 0%, 80%)",
                      color: colorScheme === "dark" ? theme.colors.dark[1] : "hsl(0, 0%, 20%)"
                    }),

                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: 14,
                      color: colorScheme === "dark" ? theme.colors.dark[1] : "hsl(0, 0%, 20%)"
                    }),

                    menuList: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: 14,
                      background:
                        colorScheme === "dark" ? theme.colors.dark[9] : "hsl(0, 0%, 100%)",
                      color: colorScheme === "dark" ? theme.colors.dark[1] : undefined
                    }),

                    option: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: 14,
                      color: colorScheme === "dark" ? theme.colors.dark[1] : "hsl(0, 0%, 20%)"
                    })
                  }}
                />
              </div>
              <div className="um-modal--content--form--actions">
                <div className="um-modal--content--form--actions--info"></div>
                <div className="um-modal--content--form--actions--buttons">
                  <Button
                    type="submit"
                    disabled={loading === "loading"}
                    loading={loading === "loading"}>
                    {isEdit ? "Update" : "Create"}
                  </Button>
                  <Button
                    color={colorScheme === "dark" ? "dark.2" : "gray.9"}
                    variant="subtle"
                    onClick={() => closeModal(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
