import { IReportType } from "@/types/types.d";
import axios from "./JWTService";

export interface CreateModuleSchema {
  name: string;
  description: string;
  is_default: boolean;
}

export interface UpdateModuleSchema {
  id: string;
  name: string;
  description: string;
  is_default: boolean;
}

export interface CreateFeatureSchema {
  name: string;
  conditions: Record<string, any>; // You can specify the conditions type more accurately
  adoption_rate: number;
  retention_period: string;
  is_new: boolean;
  is_active: boolean;
  is_saved: boolean;
}

export interface BulkCreateModuleAndFeaturesSchema {
  name: string;
  description: string;
  features: Array<CreateFeatureSchema>;
  is_default: boolean;
}

export interface UpdateFeatureSchema {
  id: string;
  name: string;
  conditions: Record<string, any>; // You can specify the conditions type more accurately
  adoption_rate: number;
  retention_period: string;
  is_new: boolean;
  is_active: boolean;
  is_saved: boolean;
}
export class FeatureAdoptionService {
  private readonly _endpoint;
  constructor() {
    this._endpoint = "/v1/feature_adoption";
  }

  count = (
    ws_id: any,
    insight_id: any,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${ws_id}/${insight_id}/count`, {
          audience_conditions: audienceConditions,
          audience_condition_type: audienceConditionType,
          audience_group_conditions: audienceGroupConditions
        })
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  frequency = (
    ws_id: any,
    insight_id: any,
    type: "monthly" | "weekly",
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${ws_id}/${insight_id}/frequency/${type}`, {
          audience_conditions: audienceConditions,
          audience_condition_type: audienceConditionType,
          audience_group_conditions: audienceGroupConditions
        })
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  usage = (
    ws_id: any,
    insight_id: any,
    type: "monthly",
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${ws_id}/${insight_id}/usage_frequency/${type}`, {
          audience_conditions: audienceConditions,
          audience_condition_type: audienceConditionType,
          audience_group_conditions: audienceGroupConditions
        })
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  contact_usage = (ws_id: string, contact_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this._endpoint}/${ws_id}/contact_usage/${contact_id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  histogram = (
    ws_id: any,
    insight_id: any,
    startDate: string,
    endDate: string,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/histogram?start_date=${startDate}&end_date=${endDate}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  activation = (
    ws_id: any,
    insight_id: any,
    startDate: string,
    endDate: string,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/activation?start_date=${startDate}&end_date=${endDate}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  export = (
    ws_id: any,
    insight_id: any,
    export_type: string,
    startDate: string,
    endDate: string,
    page: number = 1,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/list/${export_type}?start_date=${startDate}&end_date=${endDate}&page=${page}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  byContact = (ws_id: string, contact_id: string, type: "user" | "company"): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this._endpoint}/${ws_id}/contact_usage/${contact_id}/${type}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  report = (
    ws_id: any,
    startDate: string,
    endDate: string,
    reportType: IReportType,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>,
    comparison?: boolean,
    moduleId?: string,
    featureId?: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      let reportURL = `${
        this._endpoint
      }/${ws_id}/report?start_date=${startDate}&end_date=${endDate}&report_type=${reportType}&comparison=${
        comparison ? "true" : "false"
      }`;
      if (moduleId && featureId) {
        reportURL += `&module_id=${moduleId}&feature_id=${featureId}`;
      }
      axios
        .post(`${reportURL}`, {
          audience_conditions: audienceConditions,
          audience_condition_type: audienceConditionType,
          audience_group_conditions: audienceGroupConditions
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  bulkCreateModuleAndFeatures = (
    ws_id: any,
    module: BulkCreateModuleAndFeaturesSchema
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${ws_id}/module/bulk_create`, {
          name: module.name,
          description: module.description,
          is_default: module.is_default,
          features: module.features
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  createModule = (ws_id: any, module: CreateModuleSchema): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${ws_id}/module`, {
          name: module.name,
          description: module.description,
          is_default: module.is_default
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  updateModule = (ws_id: any, module: UpdateModuleSchema): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${this._endpoint}/${ws_id}/module`, {
          id: module.id,
          name: module.name,
          description: module.description,
          is_default: module.is_default
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  deleteModule = (ws_id: any, module_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${this._endpoint}/${ws_id}/${module_id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getIndividualFeature = (ws_id: any, feature_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this._endpoint}/${ws_id}/feature/${feature_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getFeatures = (ws_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this._endpoint}/${ws_id}/list`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  createFeature = (ws_id: any, module_id: string, feature: CreateFeatureSchema): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${ws_id}/feature/create`, {
          module_id: module_id,
          feature: feature
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  updateFeature = (ws_id: any, module_id: string, feature: UpdateFeatureSchema): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${this._endpoint}/${ws_id}/feature/update`, {
          module_id: module_id,
          feature: feature
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  deleteFeature = (ws_id: any, module_id: any, feature_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${this._endpoint}/${ws_id}/${module_id}/${feature_id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  moveFeatureToAnotherModule = (
    ws_id: any,
    module_id: any,
    feature_id: any,
    new_module_id: any
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${this._endpoint}/${ws_id}/${module_id}/${feature_id}/move/${new_module_id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getUsersList = (
    ws_id: any,
    feature_id: string,
    funnel_level: string,
    report_type: IReportType,
    export_type: string,
    startDate: string,
    endDate: string,
    page: number = 1,
    limit: number = 100,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${feature_id}/list/${report_type}/${export_type}?start_date=${startDate}&end_date=${endDate}&funnel_level=${funnel_level}&page=${page}&limit=${limit}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
