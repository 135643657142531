// @ts-ignore
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { BillingService } from "@/lib/services/BillingService";
import { OrganizationService } from "@/lib/services/OrganizationService";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import {
  ENTERPRISE_PLAN_FEATURES,
  PADDLE_PLAN_IDS,
  PADDLE_SUBSCRIPTION_STATUS,
  PLAN_TYPES,
  PREMIUM_PLAN,
  PRO_PLAN,
  STARTER_PLAN
} from "@/lib/utils/Constants";
import { numberToUnit } from "@/lib/utils/StringUtility";
import { ThirdPartyTracking, gtag_report_conversion } from "@/lib/utils/ThirdPartyTracking";
import { useAppGuardStore } from "@/stores/useAppGuardStore";
import { useCustomizeMenuStore } from "@/stores/useCustomizeMenuStore";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  List,
  Paper,
  SimpleGrid,
  Slider,
  Text,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { Crisp } from "crisp-sdk-web";
import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { LoadingStateProps, PlanLimitsType, WorkspaceType } from "types/types.d";
import { differenceInDays } from "date-fns";
import classes from "./Plans.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
// Configure marks to match step
const PRO_PLAN_MARKS = [
  { value: 100000, label: "100k", price: "$14" },
  { value: 250000, label: "250k", price: "$29" },
  { value: 500000, label: "500k", price: "$44" },
  { value: 1000000, label: "1M", price: "$54" },
  { value: 2000000, label: "2M", price: "$74" },
  { value: 5000000, label: "3M", price: "$89" },
  { value: 5000000, label: "4M", price: "$104" },
  { value: 5000000, label: "5M", price: "$119" },
  { value: 6000000, label: "6M", price: "$134" },
  { value: 7000000, label: "7M", price: "$149" },
  { value: 8000000, label: "8M", price: "$164" },
  { value: 9000000, label: "9M", price: "$179" },
  { value: 10000000, label: "10M", price: "$194" }
];

// with 15$ additional for every 1M events
const EXTENDED_PRO_PLAN_MARKS = [
  { value: 15000000, label: "15M", price: "$224" },
  { value: 20000000, label: "20M", price: "$299" },
  { value: 25000000, label: "25M", price: "$374" },
  { value: 30000000, label: "30M", price: "$449" },
  { value: 35000000, label: "35M", price: "$524" },
  { value: 40000000, label: "40M", price: "$599" },
  { value: 45000000, label: "45M", price: "$674" },
  { value: 50000000, label: "50M", price: "$749" },
  { value: 60000000, label: "60M", price: "$899" },
  { value: 70000000, label: "70M", price: "$1049" }
];

const PREMIUM_PLAN_MARKS = [
  { value: 100000, label: "100k", price: "$24" },
  { value: 250000, label: "250k", price: "$49" },
  { value: 500000, label: "500k", price: "$74" },
  { value: 1000000, label: "1M", price: "$99" },
  { value: 2000000, label: "2M", price: "$124" },
  { value: 3000000, label: "3M", price: "$149" },
  { value: 4000000, label: "4M", price: "$174" },
  { value: 5000000, label: "5M", price: "$199" },
  { value: 6000000, label: "6M", price: "$224" },
  { value: 7000000, label: "7M", price: "$249" },
  { value: 8000000, label: "8M", price: "$274" },
  { value: 9000000, label: "9M", price: "$299" },
  { value: 10000000, label: "10M", price: "$324" }
];

// with 25$ additional for every 1M events
const EXTENDED_PREMIUM_PLAN_MARKS = [
  { value: 15000000, label: "15M", price: "$449" },
  { value: 20000000, label: "20M", price: "$574" },
  { value: 25000000, label: "25M", price: "$699" },
  { value: 30000000, label: "30M", price: "$824" },
  { value: 35000000, label: "35M", price: "$949" },
  { value: 40000000, label: "40M", price: "$1074" },
  { value: 45000000, label: "45M", price: "$1199" },
  { value: 50000000, label: "50M", price: "$1324" },
  { value: 60000000, label: "60M", price: "$1574" },
  { value: 70000000, label: "70M", price: "$1824" }
];

type MonthlyProductSuitePlansProps = {
  onPlanChange: () => void;
  planLimits: PlanLimitsType;
  eventsConsumption: number;
};
export const MonthlyProductSuitePlans = ({
  onPlanChange,
  planLimits,
  eventsConsumption = 0
}: MonthlyProductSuitePlansProps) => {
  console.log("planLimits", planLimits);
  const eventsConsumed =
    eventsConsumption > planLimits.current_plan.events
      ? eventsConsumption
      : planLimits.current_plan.events;
  const [setForceBilling] = useAppGuardStore((state) => [state.setForceBilling]);
  const { user, activeWorkspace, setWorkspaces, setActiveWorkspace } =
    useContext(AppLifecycleContext);
  const { isStarterPlan } = useWorkspaceUtilityHook();

  // if eventsConsumption is greater than the max mark, extend the marks
  const proPlansMarks = eventsConsumed > 10000000 ? EXTENDED_PRO_PLAN_MARKS : PRO_PLAN_MARKS;

  const PRO_PLAN_MARKS_FILTERED = proPlansMarks.filter((item: any) => {
    if (activeWorkspace.organization.plan.name === PLAN_TYPES.PRO) {
      return eventsConsumed <= item.value;
    } else {
      return eventsConsumption <= item.value;
    }
  });

  // if eventsConsumption is greater than the max mark, extend the marks
  const premiumPlansMarks =
    eventsConsumed > 10000000 ? EXTENDED_PREMIUM_PLAN_MARKS : PREMIUM_PLAN_MARKS;

  const PREMIUM_PLAN_MARKS_FILTERED = premiumPlansMarks.filter((item: any) => {
    if (activeWorkspace.organization.plan.name === PLAN_TYPES.PREMIUM_LEGACY) {
      return eventsConsumed <= item.value;
    } else {
      return eventsConsumption <= item.value;
    }
  });

  const [proEvents, setProEvents] = useState(100000);
  const [premiumEvents, setPremiumEvents] = useState(
    activeWorkspace.organization.plan.name === PLAN_TYPES.PREMIUM_LEGACY ? 1000000 : 100000
  );

  const findPriceForEvents = (plansList: any, events: number) => {
    let selectedPrice = null;

    // Iterate over the PRO_PLAN_MARKS array in reverse order
    for (let i = 0; i < plansList.length; i++) {
      const plan = plansList[i];

      // If the user-selected number of events is less than or equal to the plan value,
      // set the selected price to the plan price and break the loop
      if (events <= plan.value) {
        selectedPrice = plan.price;
        break;
      }
    }

    // If no price was found (events < minimum plan value), set the selected price to the first plan's price
    if (!selectedPrice) {
      selectedPrice = plansList[plansList.length - 1].price;
    }

    return selectedPrice;
  };

  function valueLabelFormat(value: number) {
    return numberToUnit(value);
  }

  const [downgradeLoading, setDowngradeLoading] = useState<LoadingStateProps>("idle");
  const [trialLoading, setTrialLoading] = useState<LoadingStateProps>("idle");

  const [renewLoading, setRenewLoading] = useState<LoadingStateProps>("idle");
  const [upgradeLoading, setUpgradeLoading] = useState<LoadingStateProps>("idle");

  const fetchMenuItems = useCustomizeMenuStore((state: any) => state.fetchMenuItems);

  /**
   * Retrieves visitor id and referral id from cookies that are injected by FirstPromoter.
   * @returns null or string with JSON formatted fp_tid and fp_ref.
   */
  const getFirstPromoterCookies = () => {
    if (Cookies.get("_fprom_tid") && Cookies.get("_fprom_ref")) {
      return JSON.stringify({
        fp_tid: Cookies.get("_fprom_tid"),
        fp_ref: Cookies.get("_fprom_ref")
      });
    }
    return null;
  };

  /**
   * Retrieve all workspaces of the current user as the billing state may have updated
   * to active from past due or restarted from paused.
   * @param workspaceId Workspace UUID to set the active workspace.
   */
  const fetchWorkspacesWithBilling = (workspaceId: string) => {
    new WorkspaceService()
      .workspacesAccess()
      .then(async (res) => {
        console.log(`Organization billing response`, res);
        setWorkspaces(res.workspacesList);
        // current active workspace
        const activeWorkspace = res.workspacesList.find(
          (workspaceItem: WorkspaceType) => workspaceItem.id === workspaceId
        );
        setActiveWorkspace(activeWorkspace);
        await fetchMenuItems(activeWorkspace.id);
      })
      .catch((err) => {});
  };

  const handleContactEnterprisePlan = () => {
    Crisp.chat.open();
    Crisp.message.send("text", "Hello, I am looking for an enterprise plan for Usermaven.");
  };

  // Upgrade subscription or create new subscriptions.

  const handleUpgrade = (planId: string) => {
    if (activeWorkspace.organization.subscriptions.length === 0) {
      handleUpgradePaddleCheckout(planId);
    } else {
      if (
        activeWorkspace.organization.subscriptions[0].paddle_status ===
        PADDLE_SUBSCRIPTION_STATUS.DELETED
      ) {
        handleUpgradePaddleCheckout(planId);
      } else {
        handleUpgradePlan(planId);
      }
    }
  };

  // New subscriptions - open Paddle checkout pop-up.

  const handleUpgradePaddleCheckout = async (planId: string) => {
    setUpgradeLoading("loading");
    const isProPlan = PADDLE_PLAN_IDS.PRO === planId;
    let override = undefined;
    await new OrganizationService()
      .upgradePlanPrice(activeWorkspace.organization.id, isProPlan ? "pro" : "premium")
      .then((res) => {
        if (res.data.success) {
          override = res.data.response.url;
        }
      })
      .catch((err) => err);

    let couponCode =
      differenceInDays(
        new Date(activeWorkspace?.organization.trial_expires_at.replace(" ", "T")),
        new Date()
      ) < 15
        ? "TRIAL50OFF"
        : null;

    // @ts-ignore
    Paddle.Checkout.open({
      // @ts-ignore
      override: override,
      // @ts-ignore
      product: parseInt(planId),
      coupon: couponCode,
      email:
        activeWorkspace.organization.billing_email || activeWorkspace.organization.creator.email,
      allowQuantity: false,
      passthrough: getFirstPromoterCookies(),
      successCallback: function (data: any) {
        setTimeout(() => {
          fetchWorkspacesWithBilling(activeWorkspace.id);
          onPlanChange();
          setUpgradeLoading("loaded");
          setForceBilling(false);
        }, 12000);

        try {
          const TPService = new ThirdPartyTracking();

          gtag_report_conversion(
            parseInt(data.checkout.prices.customer.total),
            data.checkout.prices.customer.currency,
            data?.checkout?.id || ""
          );

          TPService.track("plan_upgraded", {
            plan_id: planId,
            price: parseInt(data.checkout.prices.customer.total),
            currency: data?.checkout?.prices?.customer?.currency || "USD"
          });
        } catch (e) {
          console.log(e);
        }

        toast.success(
          "Plan upgraded successfully. Please wait for a few seconds while plan is refreshed."
        );
      },
      closeCallback: () => setUpgradeLoading("idle")
    });
  };

  // Upgrade existing plans.
  const handleUpgradePlan = async (planId: string) => {
    setUpgradeLoading("loading");
    await new BillingService()
      .upgrade(activeWorkspace.organization.subscriptions[0].paddle_id, parseInt(planId))
      .then((res) => {
        if (res.data?.success && res.data?.response?.plan_id === parseInt(planId)) {
          setTimeout(() => {
            fetchWorkspacesWithBilling(activeWorkspace.id);
            onPlanChange();
            setForceBilling(false);
          }, 12000);
          toast.success(
            "Plan upgraded successfully. Please wait for a few seconds while your plan is refreshed."
          );
        } else {
          toast.error(res.data.error.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to upgrade subscription, please contact support.");
      });
    setUpgradeLoading("loaded");
  };

  // In case of subscription cancelled, allow users to renew plan.
  const renewPlan = async (planId: string) => {
    setRenewLoading("loading");
    // @ts-ignore
    Paddle.Checkout.open({
      // @ts-ignore
      product: parseInt(planId),
      email: activeWorkspace.organization.billing_email,
      allowQuantity: false,
      successCallback: function (data: any) {
        setTimeout(() => {
          fetchWorkspacesWithBilling(activeWorkspace.id);
          onPlanChange();
          setForceBilling(false);
          setRenewLoading("loaded");
        }, 12000);

        try {
          const TPService = new ThirdPartyTracking();

          gtag_report_conversion(
            parseInt(data.checkout.prices.customer.total),
            data.checkout.prices.customer.currency,
            data?.checkout?.id || ""
          );

          TPService.track("plan_upgraded", {
            plan_id: planId,
            price: parseInt(data.checkout.prices.customer.total),
            currency: data?.checkout?.prices?.customer?.currency || "USD"
          });
        } catch (e) {
          console.log(e);
        }

        toast.success(
          "Plan upgraded successfully. Please wait for a few seconds while plan is refreshed."
        );
      },
      closeCallback: () => setRenewLoading("idle")
    });
  };

  const downgradePlanHandle = async () => {
    setDowngradeLoading("loading");
    await new OrganizationService()
      .downgradePlanToStarter(activeWorkspace.organization.id)
      .then((res) => {
        if (res.data.message) {
          toast.success(res.data.message);
          setForceBilling(false);
          onPlanChange();
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          if (err?.response?.data?.detail && typeof err.response.data.detail === "string") {
            toast.error(err.response.data.detail);
          }
        }
      });
    setDowngradeLoading("loaded");
  };

  const handleStartTrial = async (name: string) => {
    setTrialLoading("loading");
    await new OrganizationService()
      .startTrial(activeWorkspace.organization.id, name)
      .then((res) => {
        if (res.data.message) {
          toast.success(res.data.message);
          setForceBilling(false);
          onPlanChange();
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          if (err?.response?.data?.detail && typeof err.response.data.detail === "string") {
            toast.error(err.response.data.detail);
          }
        }
      });
    setTrialLoading("loaded");
  };

  const canSeeTrialPlan = () => {
    return (
      // activeWorkspace.organization.plan.name === PLAN_TYPES.PRO_TRIAL ||
      // activeWorkspace.organization.plan.name === PLAN_TYPES.PREMIUM_TRIAL ||
      activeWorkspace.organization.plan.name.includes(PLAN_TYPES.STARTER)
    );
  };

  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  return (
    <>
      <Box mt={16}>
        <Grid grow>
          <Grid.Col span={4}>
            <Paper
              style={{
                borderTopWidth: "4px",
                borderStyle: "solid",
                borderColor: theme.colors.blue[5]
              }}
              withBorder
              p="sm"
              shadow="lg"
              h={"100%"}
              className={
                activeWorkspace.organization?.plan?.name === PLAN_TYPES.PREMIUM_LEGACY ||
                activeWorkspace.organization?.plan?.name === PLAN_TYPES.PREMIUM ||
                activeWorkspace.organization.plan.is_lifetime
                  ? classes.disabled
                  : ""
              }>
              {/* <div className="flex justify-center absolute -mt-16 ml-24">
              <Badge color="blue">Only Web Analytics</Badge>
            </div> */}
              <Title py="sm" order={4} mb={"sm"} ta="center">
                Pro
              </Title>
              <Text ta="center" mb="sm">
                Simple website analytics for businesses and agencies.
              </Text>
              <Divider mb="md" />

              <Box h={100}>
                <Box my="md" pt={"sm"}>
                  <Slider
                    labelAlwaysOn
                    size={6}
                    className="mb-4 mt-8 pt-2 mx-8"
                    color="blue.5"
                    label={(val) => {
                      return `${valueLabelFormat(val)} events`;
                    }}
                    styles={{
                      mark: {
                        background: "transparent"
                      },
                      markLabel: {
                        display: "none",
                        fontSize: "0.5rem",
                        paddingTop: "0.25rem",
                        paddingBottom: "0.25rem"
                      },
                      thumb: { borderWidth: "0.125rem", padding: "0.25rem" },
                      label: {
                        background: "transparent",
                        color: colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[9],
                        fontWeight: "bold",
                        marginTop: "8px"
                      }
                    }}
                    value={proEvents}
                    onChange={(value) => setProEvents(value)}
                    thumbSize={24}
                    defaultValue={PRO_PLAN_MARKS_FILTERED[0].value}
                    min={PRO_PLAN_MARKS_FILTERED[0].value}
                    max={PRO_PLAN_MARKS_FILTERED[PRO_PLAN_MARKS_FILTERED.length - 1].value}
                    // label={(value) =>
                    //   PRO_PLAN_MARKS_FILTERED.find((mark) => mark.value === value)?.label
                    // }
                    // step={100000}
                    step={eventsConsumed > 10000000 ? 5000000 : 100000}
                    marks={PRO_PLAN_MARKS_FILTERED}
                    thumbChildren={
                      <>
                        <FontAwesomeIcon className="text-xs" icon={solid("caret-left")} />
                        <FontAwesomeIcon className="text-xs" icon={solid("caret-right")} />
                      </>
                    }
                  />
                </Box>
                <Title order={4} ta="center">
                  {findPriceForEvents(PRO_PLAN_MARKS_FILTERED, proEvents)}/month
                </Title>
              </Box>
              <Divider mb={8} />
              <List ml={"sm"} mb="md" mt={"sm"}>
                {PRO_PLAN.map((item: any, index: number) => (
                  <>
                    <List.Item key={`pro-${index}`}>
                      <Flex align={"center"}>
                        <FontAwesomeIcon icon={solid("check")}></FontAwesomeIcon>

                        {item.includes("workspaces") ? (
                          <Text ml={8}>Unlimited workspaces</Text>
                        ) : (
                          // <>{selectedProPlan.workspaces} workspaces</>
                          <>
                            {item.includes("events") ? (
                              <Text ml={8}>
                                Track upto {numberToUnit(proEvents)} events per month
                              </Text>
                            ) : (
                              <Text ml={8}>{item}</Text>
                            )}
                          </>
                        )}
                      </Flex>
                    </List.Item>
                  </>
                ))}
              </List>
              <Flex direction={"column"} flex={1} justify={"end"}>
                <Box px={"sm"} mb={"sm"}>
                  {activeWorkspace.organization?.plan?.name === PLAN_TYPES.PRO ? (
                    <>
                      {activeWorkspace.organization?.subscriptions?.length > 0 &&
                      activeWorkspace.organization.subscriptions[0].paddle_status === "deleted" ? (
                        <>
                          <Button
                            fullWidth
                            color="pink"
                            loading={renewLoading === "loading"}
                            disabled={renewLoading === "loading"}
                            onClick={() => renewPlan(PADDLE_PLAN_IDS.PRO || "")}>
                            Renew Plan
                          </Button>
                        </>
                      ) : (
                        <>
                          <Box px="sm" my="md">
                            <Button fullWidth color="gray" variant="outline" disabled>
                              Current Plan
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  ) : isStarterPlan ? (
                    <Flex>
                      <Button
                        mr={12}
                        variant="subtle"
                        color="pink.5"
                        loading={trialLoading === "loading"}
                        disabled={trialLoading === "loading"}
                        onClick={() => {
                          handleStartTrial("pro-trial");
                        }}>
                        Start Trial
                      </Button>
                      <Button
                        color="blue.4"
                        loading={upgradeLoading === "loading"}
                        disabled={upgradeLoading === "loading"}
                        onClick={() => {
                          handleUpgrade(PADDLE_PLAN_IDS.PRO || "");
                        }}>
                        Upgrade Plan
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      {activeWorkspace.organization.plan.name.endsWith("trial") && (
                        <>
                          <Button
                            color="blue.4"
                            fullWidth
                            loading={upgradeLoading === "loading"}
                            disabled={upgradeLoading === "loading"}
                            onClick={() => {
                              handleUpgrade(PADDLE_PLAN_IDS.PRO || "");
                            }}>
                            Upgrade Plan
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Flex>
            </Paper>
          </Grid.Col>
          <Grid.Col span={4}>
            <Paper
              withBorder
              p="sm"
              shadow="lg"
              style={{
                borderTopWidth: "4px",
                borderStyle: "solid",
                borderColor: theme.colors.pink[5]
              }}>
              {/* <div className="flex justify-center absolute -mt-16 ml-36">
              <Badge color="pink">Full Suite</Badge>
            </div> */}
              <Title py="sm" order={4} mb={"sm"} ta="center">
                Premium
              </Title>
              <Text ta="center" mb="sm">
                Advanced analytics for growing businesses and agencies.
              </Text>
              <Divider mb="md" />

              <Box h={100}>
                <Box my="md" pt={"sm"}>
                  <Slider
                    size={6}
                    labelAlwaysOn
                    value={premiumEvents}
                    color="pink.5"
                    onChange={(value) => setPremiumEvents(value)}
                    className="mb-4 mt-8 pt-2 mx-8"
                    label={(val) => {
                      return `${valueLabelFormat(val)} events`;
                      // const labelValue = PREMIUM_PLAN_MARKS.find(
                      //   (mark: any) => mark.value === val
                      // )?.label
                      // if (labelValue) {
                      //   return `${labelValue} events`
                      // }
                    }}
                    styles={{
                      mark: {
                        background: "transparent"
                      },
                      markLabel: {
                        display: "none",
                        fontSize: "0.5rem",
                        paddingTop: "0.25rem",
                        paddingBottom: "0.25rem"
                      },
                      thumb: { borderWidth: "0.125rem", padding: "0.25rem" },
                      label: {
                        background: "transparent",

                        color: colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[9],
                        fontWeight: "bold",
                        marginTop: "8px"
                      }
                    }}
                    thumbSize={24}
                    defaultValue={PREMIUM_PLAN_MARKS_FILTERED[0].value}
                    min={PREMIUM_PLAN_MARKS_FILTERED[0].value}
                    max={PREMIUM_PLAN_MARKS_FILTERED[PREMIUM_PLAN_MARKS_FILTERED.length - 1].value}
                    // label={(value) =>
                    //   PREMIUM_PLAN_MARKS_FILTERED.find((mark) => mark.value === value)?.label
                    // }
                    // step={1000000}
                    step={eventsConsumed > 10000000 ? 5000000 : 100000}
                    marks={PREMIUM_PLAN_MARKS_FILTERED}
                    thumbChildren={
                      <>
                        <FontAwesomeIcon className="text-xs" icon={solid("caret-left")} />
                        <FontAwesomeIcon className="text-xs" icon={solid("caret-right")} />
                      </>
                    }
                  />
                </Box>
                <Title order={4} ta="center">
                  {findPriceForEvents(PREMIUM_PLAN_MARKS_FILTERED, premiumEvents)}
                  /month
                </Title>
              </Box>
              <Divider mb={8} />
              <Title order={5} my={"md"} fz={14} ml={8} c={"pink"}>
                Everything in Pro, plus:
              </Title>

              <List ml={"sm"} mb="md">
                {PREMIUM_PLAN.map((item: any, index: number) => (
                  <>
                    <List.Item>
                      <Flex align={"center"}>
                        <FontAwesomeIcon icon={solid("check")}></FontAwesomeIcon>
                        {item.includes("workspaces") ? (
                          <Text ml={8}>Unlimited workspaces</Text>
                        ) : (
                          // <>{selectedProPlan.workspaces} workspaces</>
                          <>
                            {item.includes("events") ? (
                              <Text ml={8}>
                                Track upto {numberToUnit(premiumEvents)} events per month
                              </Text>
                            ) : (
                              <Text ml={8}>{item}</Text>
                            )}
                          </>
                        )}
                      </Flex>
                    </List.Item>
                  </>
                ))}
              </List>
              <Flex direction={"column"} flex={1} justify={"end"}>
                <Box px="sm" mb="sm">
                  {activeWorkspace.organization?.plan?.name === PLAN_TYPES.PREMIUM_LEGACY ||
                  activeWorkspace.organization?.plan?.name === PLAN_TYPES.PREMIUM ? (
                    <>
                      {activeWorkspace.organization?.subscriptions?.length > 0 &&
                      activeWorkspace.organization.subscriptions[0].paddle_status === "deleted" ? (
                        <>
                          <Button
                            fullWidth
                            loading={renewLoading === "loading"}
                            disabled={renewLoading === "loading"}
                            onClick={() => renewPlan(PADDLE_PLAN_IDS.PREMIUM || "")}>
                            Renew Plan
                          </Button>
                        </>
                      ) : (
                        <>
                          <div className=" px-4 mb-4">
                            <Button fullWidth variant="outline" color="gray" disabled={true}>
                              Current Plan
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  ) : isStarterPlan ||
                    activeWorkspace.organization.plan.name === PLAN_TYPES.PRO_TRIAL ? (
                    <Flex>
                      <Button
                        mr={12}
                        variant="subtle"
                        color="pink.5"
                        loading={trialLoading === "loading"}
                        disabled={trialLoading === "loading"}
                        onClick={() => {
                          handleStartTrial("premium-trial");
                        }}>
                        {activeWorkspace.organization.plan.name === PLAN_TYPES.PRO_TRIAL
                          ? "Change Trial"
                          : "Start Trial"}
                      </Button>
                      <Button
                        loading={upgradeLoading === "loading"}
                        disabled={upgradeLoading === "loading"}
                        onClick={() => {
                          handleUpgrade(PADDLE_PLAN_IDS.PREMIUM || "");
                        }}>
                        Upgrade Plan
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      {(activeWorkspace.organization.plan.name.endsWith("trial") ||
                        activeWorkspace.organization.plan.name === PLAN_TYPES.PRO) && (
                        <>
                          <Button
                            fullWidth
                            color="pink.5"
                            loading={upgradeLoading === "loading"}
                            disabled={upgradeLoading === "loading"}
                            onClick={() => {
                              handleUpgrade(PADDLE_PLAN_IDS.PREMIUM || "");
                            }}>
                            Upgrade Plan
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Flex>
            </Paper>
          </Grid.Col>
          <Grid.Col span={4}>
            {/* Enterprise plan */}
            <Paper
              withBorder
              p="sm"
              shadow="lg"
              style={{
                borderTopWidth: "4px",
                borderStyle: "solid",
                borderColor: theme.colors.brand[5]
              }}
              className={` ${activeWorkspace.organization?.plan?.name === PLAN_TYPES.ENTERPRISE}`}>
              <Title py="sm" order={4} mb={"sm"} ta="center">
                Enterprise
              </Title>
              <Text ta="center" mb="sm">
                Tailored setup for businesses with custom requirements.
              </Text>
              <Divider />
              <Flex align={"center"} justify={"center"} h={100} ta="center">
                <Text c="dimmed" fz={16} fw={600}>
                  Contact us
                </Text>
              </Flex>
              <Divider mb={8} />
              <Title order={5} my={"md"} fz={14} ml={8} c={"brand"}>
                Everything in Premium, plus:
              </Title>
              <List mb={"md"} ml="sm">
                {ENTERPRISE_PLAN_FEATURES.map((item: any, index: number) => (
                  <>
                    <List.Item key={`starter-plan-items:${index}`}>
                      <Flex align={"center"}>
                        <FontAwesomeIcon
                          className="text-purple-500"
                          icon={solid("check")}></FontAwesomeIcon>
                        <Text pt={2} ml={8}>
                          {item}
                        </Text>
                      </Flex>
                    </List.Item>
                  </>
                ))}
              </List>
              <Flex direction={"column"} justify={"end"}>
                <Button
                  fullWidth
                  size="sm"
                  variant={"gradient"}
                  gradient={{ from: "#6033CA", to: "#7D47EB" }}
                  onClick={() => handleContactEnterprisePlan()}>
                  Let's Talk
                </Button>
              </Flex>
            </Paper>
          </Grid.Col>
        </Grid>
        <Flex align={"center"} justify={"center"} py={12} mt={12}>
          <FontAwesomeIcon className="text-green-600" icon={regular("check")}></FontAwesomeIcon>
          <Text pl={8}>100% secure payment, powered by Paddle</Text>
        </Flex>
        {canSeeTrialPlan() && (
          <>
            <Container size={800}>
              <Paper withBorder shadow="sm" bg="pink.0">
                <Grid>
                  <Grid.Col span={8}>
                    <Box p={"md"} px="lg">
                      <Title order={3} mb="sm">
                        Starter
                      </Title>
                      <Text mb="md">
                        Free analytics for getting started for individuals and businesses.
                      </Text>
                      <SimpleGrid cols={2}>
                        <List>
                          {STARTER_PLAN.slice(0, STARTER_PLAN.length / 2 + 1).map((item, index) => (
                            <List.Item key={index} className="flex items-center font-medium">
                              <Flex align={"center"}>
                                <Box w={16} c="brand" mr={"sm"}>
                                  <FontAwesomeIcon icon={regular("check")}></FontAwesomeIcon>
                                </Box>
                                <Text fz="xs">{item}</Text>
                              </Flex>
                            </List.Item>
                          ))}
                        </List>
                        <List>
                          {STARTER_PLAN.slice(STARTER_PLAN.length / 2 + 1).map((item, index) => (
                            <List.Item key={index} className="flex items-center font-medium">
                              <Flex align={"center"}>
                                <Box w={16} c="brand" mr={"sm"}>
                                  <FontAwesomeIcon icon={regular("check")}></FontAwesomeIcon>
                                </Box>
                                <Text fz="xs">{item}</Text>
                              </Flex>
                            </List.Item>
                          ))}
                        </List>
                      </SimpleGrid>
                      <ul className="py-4 "></ul>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Flex flex={1} h={200} align={"center"} justify={"center"} direction={"column"}>
                      {/* <Title order={1}>$0/mo</Title> */}
                      {isStarterPlan ? (
                        <>
                          <Flex>
                            <Button fullWidth color="gray" variant="outline" disabled>
                              Current Plan
                            </Button>
                          </Flex>
                        </>
                      ) : activeWorkspace.organization?.plan?.name.endsWith("-trial") ? (
                        <>
                          <div className=" px-4 mb-4">
                            <Button
                              onClick={downgradePlanHandle}
                              loading={downgradeLoading === "loading"}
                              disabled={downgradeLoading === "loading"}
                              className="!w-full"
                              color="purple.6">
                              Downgrade Plan
                            </Button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Paper>
            </Container>
          </>
        )}
      </Box>
    </>
  );
};
