import create from 'zustand';

interface IUseAppGuardStore {
  forceBilling: boolean;
  setForceBilling: (forceBilling: boolean) => void;
}
export const useAppGuardStore = create<IUseAppGuardStore>((set, get) => ({
  forceBilling: false,
  setForceBilling: (forceBilling: boolean) => set({ forceBilling })
}));
