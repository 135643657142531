/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import useInsightHook from "@/hooks/useInsightHook";
import { InsightsSetupEvents } from "@/ui/components/App/InsightsSetupEvents/InsightsSetupEvents";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Input,
  Modal,
  NumberInput,
  Select,
  Space,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";

import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { FeatureAdoptionService } from "@/lib/services/FeatureAdoptionService";
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import useFeatureAdoptionStore, { Feature } from "@/stores/useFeatureAdoptionStore";
import { ConditionItem } from "@/types/types.d";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./setup.scss";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface FeatureSetupProps {
  moduleId: string;
  moduleName: string;
  features: Array<Feature>;
  nameIdentifier?: string;
  title: string;
  description: string;
  minimumConditions?: number;
  maximumConditions?: number;
  conditionType?: "feature_adoption";
  stepNo?: number;
  conditionButtonTitle?: string;
  allowVisitorsStep?: boolean;
  isVisitorsStepEnabled?: boolean;
  setIsVisitorsStepEnabled?: (e: any) => void;
}

export const FeatureSetup: React.FC<FeatureSetupProps> = ({
  moduleId,
  moduleName,
  features,
  nameIdentifier = "condition",
  conditionType = "feature_adoption",
  title = "Your Headline",
  description = "Your description...",
  minimumConditions = 1,
  maximumConditions = 100
}) => {
  const { colorScheme } = useMantineColorScheme();
  const theme = useGlobalMantineTheme();
  const [
    addFeatureToModule,
    updateFeature,
    updateFeaturesInModule,
    removeFeatureFromModule,
    modules,
    moveFeatureToAnotherModule
  ] = useFeatureAdoptionStore((state) => [
    state.addFeatureToModule,
    state.updateFeature,
    state.updateFeaturesInModule,
    state.removeFeatureFromModule,
    state.modules,
    state.moveFeatureToAnotherModule
  ]);
  const { insightEvents, insightAttributes, insightPinnedEvents } = useInsightHook();
  const setConditions = (featureId: string, conditions: ConditionItem[]) => {
    updateFeature(moduleId, featureId, {
      conditions: { feature_events_conditions: conditions }
    });
  };

  const [movedToModuleModal, setMovedToModuleModal] = useState(false);
  const [moveFeature, setMoveFeature] = useState<Feature | null>(null);
  const [selectedMovableModuleId, setSelectedMovableModuleId] = useState<any>(null);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const featureAdoptionService = new FeatureAdoptionService();

  const [isFeatureAddedLoader, setIsFeatureAddedLoader] = useState("idle");
  const [featureToDelete, setFeatureToDelete] = useState({
    id: "",
    name: "RSS Automation",
    conditions: {},
    adoption_rate: 0,
    retention_period: "",
    is_new: false,
    is_active: false,
    is_saved: false
  });
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);

  const deleteFeature = (feature: Feature) => {
    if (feature.is_new) {
      // NOTE: No service call required beacuse feature is not saved yet on database. Only remove from store.
      removeFeatureFromModule(moduleId, feature.id);
    } else {
      // NOTE: Open model to process.
      setFeatureToDelete(feature);
      setOpenDeletePrompt(true);
    }
  };

  const deleteFeatureFromDB = async () => {
    await featureAdoptionService
      .deleteFeature(activeWorkspace.id, moduleId, featureToDelete.id)
      .then((res) => {
        if (res.data) {
          removeFeatureFromModule(moduleId, featureToDelete.id);
          setOpenDeletePrompt(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setOpenDeletePrompt(false);
      });
  };

  const validateFeatureAttributes = (feature: Feature) => {
    let conditions = feature.conditions.feature_events_conditions;

    if (
      feature.name !== "" &&
      conditions.length > 0 &&
      conditions.every(
        (obj: { key: string; label: string }) => obj.key !== "" && obj.label !== ""
      ) &&
      feature.adoption_rate != null &&
      feature.retention_period != null
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    updateFeaturesInModule(moduleId, features);
  }, [features]);

  const handleFeatureSave = async (feature: Feature) => {
    if (feature.is_saved) {
      // Update feature
      setIsFeatureAddedLoader("loading");
      await featureAdoptionService
        .updateFeature(activeWorkspace.id, moduleId, feature)
        .then((res) => {
          if (res.data) {
            const apiResponse = res.data;
            const updatedFeature = {
              name: apiResponse.name,
              conditions: apiResponse.conditions,
              adoption_rate: apiResponse.adoption_rate,
              retention_period: apiResponse.retention_period,
              is_saved: true,
              is_new: false,
              is_active: false
            };
            updateFeature(moduleId, feature.id, updatedFeature);
            toast.success("Feature updated successfully");
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsFeatureAddedLoader("loaded");
        });
    } else {
      // Create feature
      setIsFeatureAddedLoader("loading");
      const { id, ...rest } = feature;
      await featureAdoptionService
        .createFeature(activeWorkspace.id, moduleId, rest)
        .then((res) => {
          if (res.data) {
            if (res.data.status_code === 409) {
              toast.error(res.data.detail);
              return;
            }
            const apiResponse = res.data;
            const newFeature = {
              id: apiResponse.id,
              name: apiResponse.name,
              conditions: apiResponse.conditions,
              adoption_rate: apiResponse.adoption_rate,
              retention_period: apiResponse.retention_period,
              is_saved: true,
              is_new: false,
              is_active: false
            };
            // add new feature to store
            addFeatureToModule(moduleId, newFeature);
            // remove temp feature from store
            removeFeatureFromModule(moduleId, id);
            toast.success("Feature added successfully");
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsFeatureAddedLoader("loaded");
        });
    }
  };

  // move to another module code

  const handleMoveToAnotherModuleDialog = (feature: Feature) => {
    setMovedToModuleModal(true);
    setMoveFeature(feature);
  };

  const moveFeatureFromDB = async () => {
    await featureAdoptionService
      .moveFeatureToAnotherModule(
        activeWorkspace.id,
        moduleId,
        moveFeature?.id,
        selectedMovableModuleId
      )
      .then((res) => {
        if (res.data) {
          setMoveFeature(null);
          setMovedToModuleModal(false);
          setSelectedMovableModuleId(null);
          moveFeatureToAnotherModule(moduleId, moveFeature?.id || "", selectedMovableModuleId);
          toast.success("Feature moved successfully");
        }
      })
      .catch((err) => {})
      .finally(() => {
        setMovedToModuleModal(false);
      });
  };

  const getModulesListExcludingCurrentModule = () => {
    return modules
      .filter((module) => module.id !== moduleId)
      .map((module) => {
        return {
          label: module.name,
          value: module.id
        };
      });
  };

  return (
    <>
      {features.length > 0 && (
        <Box>
          {features.map((feature, index) => (
            <Box
              my={20}
              className="feature-item"
              p={8}
              mr={24}
              key={index}
              sx={() => {
                return {
                  // marginBottom: "20px",
                  borderRadius: "8px",
                  backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : "#ffff",
                  border: "1px solid",
                  borderColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.darkBorderColor[0]
                      : theme.colors.gray[4]
                };
              }}>
              <Box m={5}>
                {!feature.is_active ? (
                  <Flex justify={"space-between"}>
                    <Flex
                      className="flex-grow"
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          opacity: 0.6
                        }
                      }}
                      onClick={() => {
                        updateFeature(moduleId, feature.id, {
                          is_active: !feature.is_active
                        });
                      }}
                      justify={"flex-start"}
                      align={"center"}>
                      <FontAwesomeIcon
                        icon={solid("angle-right")}
                        className={` transform   transition-all duration-200 ease-in-out ${
                          feature.is_active ? "rotate-90" : ""
                        }`}
                      />
                      <Text fz="sm" ml={5}>
                        {toTitleCase(feature.name)}
                      </Text>
                    </Flex>

                    <Flex justify={"flex-end"} className="">
                      {/* <Tooltip label={feature.is_saved ? "Completed" : "Setup remaining"}>
                        <ActionIcon
                          variant="outline"
                          size={"sm"}
                          radius="xl"
                          color={feature.is_saved ? "green.6" : "gray.4"}>
                          <FontAwesomeIcon icon={solid("check")} />
                        </ActionIcon>
                      </Tooltip> */}
                      {!feature.is_new && (
                        <>
                          <Tooltip label="Move to another module">
                            <ActionIcon
                              className="feature-actions"
                              variant="outline"
                              color="gray.6"
                              mr={"xs"}
                              size={"sm"}
                              onClick={() => handleMoveToAnotherModuleDialog(feature)}>
                              <FontAwesomeIcon size="xs" icon={regular("up-down-left-right")} />
                            </ActionIcon>
                          </Tooltip>

                          <Space w="xs" />
                        </>
                      )}
                      <Tooltip label="Delete">
                        <ActionIcon
                          variant="outline"
                          className="feature-actions"
                          size={"sm"}
                          color={"red.5"}
                          onClick={() => deleteFeature(feature)}>
                          <FontAwesomeIcon size="xs" icon={regular("trash")} />
                        </ActionIcon>
                      </Tooltip>
                    </Flex>
                  </Flex>
                ) : (
                  <Box m={5} style={{ transition: "all 200ms ease-in-out" }}>
                    <Flex justify={"space-between"}>
                      <Flex justify={"flex-start"} align={"flex-start"}>
                        <Box mr={"xs"} mt={4}>
                          <FontAwesomeIcon
                            size="xs"
                            icon={solid("angle-right")}
                            className={` transform  text-gray-800 transition-all duration-200 ease-in-out cursor-pointer ${
                              feature.is_active ? "rotate-90" : ""
                            }`}
                            onClick={() => {
                              updateFeature(moduleId, feature.id, {
                                is_active: !feature.is_active
                              });
                            }}
                          />
                        </Box>
                        <Input.Wrapper mt={3} size="sm" id={"featureName"} label="Feature Name">
                          <TextInput
                            styles={{
                              input: {
                                borderBottom: "1px dashed",
                                borderTop: "0px",
                                borderLeft: "0px",
                                borderRight: "0px",

                                borderColor:
                                  colorScheme === "dark"
                                    ? theme.colors.dark[3]
                                    : theme.colors.gray[5],
                                "&:focus-within": {
                                  borderColor: theme.colors.brand[5]
                                }
                              }
                            }}
                            defaultValue={toTitleCase(feature.name)}
                            onChange={(e) =>
                              updateFeature(moduleId, feature.id, { name: e.target.value })
                            }
                            className="mt-2"
                            variant="unstyled"
                            placeholder="e.g Compose Email"></TextInput>
                        </Input.Wrapper>
                      </Flex>
                      <Flex justify={"flex-end"}>
                        {/* <Tooltip label={feature.is_saved ? "Completed" : "Setup remaining"}>
                          <ActionIcon
                            variant="outline"
                            size={"sm"}
                            radius="xl"
                            color={feature.is_saved ? "green.6" : "gray.4"}>
                            <FontAwesomeIcon icon={solid("check")} />
                          </ActionIcon>
                        </Tooltip> */}

                        {!feature.is_new && (
                          <Tooltip label="Move to another module">
                            <ActionIcon
                              mr="xs"
                              color="gray.6"
                              variant="subtle"
                              size={"sm"}
                              onClick={() => handleMoveToAnotherModuleDialog(feature)}>
                              <FontAwesomeIcon size="xs" icon={regular("up-down-left-right")} />
                            </ActionIcon>
                          </Tooltip>
                        )}

                        <Tooltip label="Delete feature">
                          <ActionIcon
                            variant="subtle"
                            color={"red.5"}
                            size={"sm"}
                            onClick={() => deleteFeature(feature)}>
                            <FontAwesomeIcon size="xs" icon={regular("trash")} />
                          </ActionIcon>
                        </Tooltip>
                      </Flex>
                    </Flex>
                    <Box ml={20}>
                      <Box mt={4} py={16}>
                        <Text fw={600} mb={8} fz="sm">
                          {title}
                        </Text>

                        <Text mb={12}>{description}</Text>
                        <Box mt={4}>
                          <InsightsSetupEvents
                            options={[
                              {
                                label: "Page URL",
                                value: "pageview"
                              },
                              { label: "Custom Event", value: "event" },
                              { label: "Pinned Event", value: "pinned_event" }
                            ]}
                            conditionType={"or"}
                            conditions={feature.conditions.feature_events_conditions}
                            setConditions={setConditions}
                            attributesOptions={insightAttributes}
                            pinnedEventOptions={insightPinnedEvents}
                            eventsOptions={insightEvents}
                            nameIdentifier={nameIdentifier}
                            featureId={feature.id}
                            featureName={feature.name}
                            usedIn={"feature_adoption"}
                          />
                        </Box>
                      </Box>
                      <Box mt={4}>
                        <Text fw={600} mb={12} fz="sm">
                          Feature evaluation criteria
                        </Text>
                        <Flex gap={"xs"} mb={12} align={"center"}>
                          <Text
                            ml={"xs"}
                            sx={{
                              strong: {
                                fontWeight: 500
                              },

                              i: {
                                fontStyle: "italic"
                              }
                            }}>
                            <strong>Adoption</strong> - A user is considered{" "}
                            <u>
                              <i>adopted</i>
                            </u>{" "}
                            if the number of events are greater than
                          </Text>
                          <NumberInput
                            max={120}
                            min={0}
                            w={100}
                            size={"xs"}
                            variant="default"
                            onChange={(val) =>
                              updateFeature(moduleId, feature.id, { adoption_rate: val })
                            }
                            value={feature.adoption_rate || 2}
                            defaultValue={feature.adoption_rate || 2}
                          />
                        </Flex>
                        <Flex gap={"xs"} mt={4} align={"center"}>
                          <Text ml={"xs"}>
                            <Text span fw={600}>
                              Retention
                            </Text>{" "}
                            - A user is considered{" "}
                            <u>
                              <Text span fs="italic">
                                retained
                              </Text>
                            </u>{" "}
                            if they use the feature on a
                          </Text>
                          <Select
                            // styles={{
                            //   input: { minHeight: "5px", height: "22px" },
                            //   item: { minHeight: "5px", height: "25px" }
                            // }}
                            size="xs"
                            w={120}
                            defaultValue={feature.retention_period || "bi-weekly"}
                            data={[
                              {
                                label: "Weekly",
                                value: "weekly"
                              },
                              {
                                label: "Bi-weekly",
                                value: "bi-weekly"
                              },
                              {
                                label: "Monthly",
                                value: "monthly"
                              }
                            ]}
                            onChange={(val) =>
                              updateFeature(moduleId, feature.id, { retention_period: val })
                            }
                          />
                          basis
                        </Flex>
                      </Box>
                    </Box>
                    <Flex justify={"flex-end"} mt={12} mb={12}>
                      <Button
                        mr={12}
                        variant="subtle"
                        color="red"
                        onClick={() => {
                          updateFeature(moduleId, feature.id, {
                            is_active: !feature.is_active
                          });
                        }}>
                        Cancel
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => {
                          handleFeatureSave(feature);
                        }}
                        loading={isFeatureAddedLoader === "loading"}
                        disabled={
                          isFeatureAddedLoader === "loading" || !validateFeatureAttributes(feature)
                        }>
                        {feature.is_saved ? "Update" : "Save"}
                      </Button>
                    </Flex>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
          <Modal
            overlayProps={{
              opacity: 0.5,
              blur: 1
            }}
            opened={openDeletePrompt}
            onClose={() => setOpenDeletePrompt(false)}
            title={<Title order={5}>Delete Feature</Title>}>
            <div>
              <Text>
                Do you really want to delete the feature -{" "}
                <Text span fw={600}>
                  {toTitleCase(featureToDelete.name)}
                </Text>
              </Text>
              <Flex justify={"flex-end"} px={20} pt={20} gap="sm">
                <Button
                  variant="subtle"
                  color="gray.6"
                  onClick={() => {
                    setOpenDeletePrompt(false);
                  }}>
                  Cancel
                </Button>

                <Button color="red" onClick={() => deleteFeatureFromDB()}>
                  Yes
                </Button>
              </Flex>
            </div>
          </Modal>

          <Modal
            overlayOpacity={0.5}
            overlayBlur={1}
            opened={movedToModuleModal}
            onClose={() => setMovedToModuleModal(false)}
            title={
              <Title order={5} fw={600}>
                Move to Module
              </Title>
            }>
            <div>
              <Text
                mb={4}
                sx={{
                  strong: {
                    fontWeight: 500
                  },

                  i: {
                    fontStyle: "italic"
                  }
                }}>
                Please choose a module to move "{moveFeature?.name}" feature
              </Text>
              <Select
                placeholder="Please select a module"
                value={selectedMovableModuleId}
                onChange={(val) => setSelectedMovableModuleId(val)}
                data={getModulesListExcludingCurrentModule()}></Select>
              <Flex justify={"flex-end"} px={20} pt={20} gap="sm">
                <Button
                  variant="subtle"
                  color="red"
                  onClick={() => {
                    setMovedToModuleModal(false);
                  }}>
                  Cancel
                </Button>

                <Button onClick={() => moveFeatureFromDB()}>Move it!</Button>
              </Flex>
            </div>
          </Modal>
        </Box>
      )}
    </>
  );
};
