import create from "zustand";

interface Props {
  list: any[];
  setList: (goals: any[]) => void;
  findGoalName: (id: string) => string;
}

// We use this store to manage the conversion goals instead of using the insights hook.
// This is because conversion goals need to be shown in public dashboards.
// We also do not have fetch / edit functions with the service inside this for the same reason.

export const useConversionGoalsStore = create<Props>((set, get) => ({
  list: [],
  setList: (list: any[]) => set({ list }),
  findGoalName: (id: string) => {
    const goal = get().list.find((g: any) => g.id === id);
    return goal?.name || "";
  }
}));
