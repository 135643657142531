import { utcToRelativeTimezone } from "@/lib/utils/DateUtility";
import { Flex, Text } from "@mantine/core";
import { format, isToday } from "date-fns";

interface IRelativeDateWithTextProps {
  text: string;
}

export const RelativeDateWithText = ({ text }: IRelativeDateWithTextProps) => {
  if (!text) return null;

  if (text.includes(" ")) {
    text = text.replace(" ", "T");
  }

  return (
    <Flex direction={"column"}>
      <Text>{utcToRelativeTimezone(text, true)}</Text>
      <Text c="dimmed" fz="xs">
        {isToday(new Date(text))
          ? format(new Date(text), "HH:mm:ss")
          : format(new Date(text), "dd MMM yyyy HH:mm:ss")}
      </Text>
    </Flex>
  );
};
