/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Center,
  Divider,
  Flex,
  Menu,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useWebFiltersStore } from "@/stores/useWebFiltersStore";
import { AppLifecycleContext } from "../../../../../lib/contexts/AppLifecycleContext";
import { useWebSavedViewsStore } from "../../../../../stores/useWebSavedViewsStore";
import { SavedViewsModal } from "./SavedViewsModal";

/**
 * Saved views component is used to display the saved views in the filters nav. The user
 * can select a saved view to load it in the filters.
 *
 * Further, the user can also edit, or delete a saved view.
 *
 * @param props
 * @returns
 */
export const SavedViews = ({
  query,
  setQuery,
  scrollPosition,
  isPublic,
  setSelectedDomains
}: {
  query: any;
  setQuery: any;
  scrollPosition: number;
  isPublic: boolean;
  setSelectedDomains: any;
}) => {
  // dropdown menu toggle
  const [open, setOpen] = useState<boolean>(false);
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // set date label based on the from_date and to_date
  const [setDateLabel] = useWebFiltersStore((state) => [state.setDateLabel]);

  // saved views store
  const [
    selectedId,
    setSelectedId,
    savedViewsList,
    editView,
    deleteSavedView,
    modalOpen,
    setModalOpen,
    fetchSavedViews,
    reset
  ] = useWebSavedViewsStore((state) => [
    state.selectedId,
    state.setSelectedId,
    state.list,
    state.edit,
    state.delete,
    state.modalOpen,
    state.setModalOpen,
    state.fetch,
    state.reset
  ]);

  /**
   * function will set the web analytics query to the selected view based on its value.
   * @param id view id
   */
  const selectView = (id: string) => {
    // find the view based on the id
    const view = savedViewsList.find((item: any) => item.id === id);

    // keys that are to be excluded from the response of the view.
    const notAllowedKeys = [
      "id",
      "workspace_id",
      "created_by_user_id",
      "created_at",
      "updated_at",
      "from_date",
      "to_date",
      "name",
      "domain"
    ];

    // create filter array for the query object that we use to set the query.
    const filters: any = [];
    Object.keys(view).map((item) => {
      if (typeof view[item] === "string" && notAllowedKeys.indexOf(item) === -1) {
        filters.push(item + ":" + view[item]);
      }
    });

    // if the view has an option for include time period set, set the from and to date to the query
    if (view.include_time_period && view.from_date && view.to_date) {
      query.from_date = view.from_date;
      query.to_date = view.to_date;
      setDateLabel(
        `${format(new Date(view.from_date), "MMM d")} - ${format(new Date(view.to_date), "MMM d")} `
      );
      query.period = "day";
    }
    if (view.include_domain && view.domain) {
      let viewDomain = view.domain.split(",");
      query.domain = viewDomain;
      let viewDomainsMapping = viewDomain.map((item: any) => {
        return { label: item, value: item };
      });
      setSelectedDomains(viewDomainsMapping);
    }
    query.filters = filters;
    // set selected id is used for highlighting the selected view in the dropdown
    setSelectedId(id);
    setQuery(query);
  };

  /**
   * Show the confirmation modal to delete the saved view. If the user
   * confirms, delete the view.
   * @param id
   */
  const onDeleteItemPrompt = (id: string) => {
    openConfirmModal({
      title: "Delete saved view",
      children: <Text size="sm">Are you sure you want to delete your saved view?</Text>,
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6", className: "saved-view-confirm-delete" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => deleteSavedView(activeWorkspace.id, id)
    });
  };

  useEffect(() => {
    fetchSavedViews(activeWorkspace.id);
    ReactTooltip.rebuild();
    return () => {
      // in case of component destruction, we need to set the selectedId to null
      setSelectedId("");
      // also reset the saved views store
      reset();
    };
  }, []);

  const { colorScheme } = useMantineColorScheme();
  return (
    <>
      <SavedViewsModal
        query={query}
        opened={modalOpen}
        onClose={() => {
          setModalOpen(!modalOpen);
          reset();
        }}
      />
      <div className="mr-4 ">
        <Menu width={"200"} withArrow opened={open} onChange={setOpen}>
          <Menu.Target>
            <Button
              ml={4}
              pr={8}
              size="xs"
              leftSection={
                <FontAwesomeIcon icon={regular("bookmark")} data-cy="saved-views-icon" />
              }
              // className={`${
              //   scrollPosition > 0.03
              //     ? theme.colorScheme === "dark"
              //       ? "border border-solid border-gray-200 !text-gray-600 !bg-dark-600"
              //       : "border border-solid border-gray-200 text-gray-900"
              //     : " text-gray-800"
              // }`}
              color={scrollPosition > 0.03 ? "white" : "gray.6"}
              variant={scrollPosition > 0.03 ? "light" : "outline"}>
              Saved Views
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {(savedViewsList.length === 0 ||
              savedViewsList.filter((item: any) => !(isPublic && !item.is_public_view)).length ===
                0) && (
              <>
                <Menu.Item
                  pt={4}
                  ta="center"
                  pb={4}
                  className={" text-base cursor-default"}
                  data-cy="saved-views-list">
                  No saved views
                </Menu.Item>
                <Divider />
                <Center>
                  <Anchor
                    href="https://usermaven.com/docs/website-analytics-overview#saved-views"
                    target="_blank"
                    fz="xs"
                    py={4}
                    fw={500}>
                    Learn more
                  </Anchor>
                </Center>
              </>
            )}
            {savedViewsList
              .filter((item: any) => !(isPublic && !item.is_public_view))
              .map((item: any) => (
                <Menu.Item
                  key={item.id}
                  onClick={() => {
                    selectView(item.id);
                  }}
                  data-cy="saved-views-list">
                  <Flex align="center" justify={"space-between"}>
                    <Text
                      tt="capitalize"
                      c={selectedId === item.id ? "brand" : undefined}
                      fw={selectedId === item.id ? 500 : 400}>
                      {item.name}
                    </Text>
                    <div className={"flex"}>
                      {item.is_public_view ? (
                        <Badge size="sm" radius={"sm"} color={"gray.7"}>
                          Public
                        </Badge>
                      ) : (
                        <> </>
                      )}
                      <div />
                    </div>
                    {!isPublic && (
                      <Flex align={"center"}>
                        <Tooltip label="Edit">
                          <ActionIcon mr={4} size={"xs"} color={"gray.6"} variant="subtle">
                            <FontAwesomeIcon
                              size={"sm"}
                              icon={regular("edit")}
                              onClick={(e: any) => {
                                setModalOpen(true);
                                editView(item.id);
                                e.stopPropagation();
                              }}
                            />
                          </ActionIcon>
                        </Tooltip>

                        <Tooltip label="Delete">
                          <ActionIcon variant="subtle" size={"xs"} color={"red.6"}>
                            <FontAwesomeIcon
                              size={"sm"}
                              icon={regular("trash")}
                              onClick={(e: any) => {
                                onDeleteItemPrompt(item.id);
                                e.stopPropagation();
                              }}
                              data-cy="saved-view-delete-icon"
                            />
                          </ActionIcon>
                        </Tooltip>
                      </Flex>
                    )}
                  </Flex>
                </Menu.Item>
              ))}
          </Menu.Dropdown>
        </Menu>
      </div>
    </>
  );
};
