import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceMiscService } from "@/lib/services/WorkspaceMiscService";
import { currenciesList } from "@/lib/utils/Currencies/index";
import { Box, Select, Text, Title } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

export const MiscellaneousWorkspaceReportingCurrency = () => {
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [selected, setSelected] = useState(activeWorkspace.reporting_currency);
  const handleOnChange = (value: any) => {
    new WorkspaceMiscService()
      .reportingCurrency(activeWorkspace.id, value)
      .then((res) => {
        if (res.data) {
          activeWorkspace.reporting_currency = res.data.data;
          setActiveWorkspace(activeWorkspace);
          toast.success(`Your default reporting currency has been set to ${value}`);
          setSelected(value);
        }
      })
      .catch((err) => err);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Title order={4} fw={600} mb="md">
        Reporting currency
      </Title>
      <Text mb="md">Select the default value for the reporting currency.</Text>
      <Box>
        <Select
          defaultValue={selected}
          value={selected}
          w={300}
          onChange={handleOnChange}
          placeholder="Pick your currency"
          searchable
          nothingFoundMessage="No options"
          maxDropdownHeight={280}
          data={currenciesList.map((c: any) => {
            return { label: `${c.name} (${c.symbolNative})`, value: c.code };
          })}
        />
      </Box>
    </>
  );
};
