import AppLifecycleContext from '@/lib/contexts/AppLifecycleContext';
import { SlippingAwayUsersService } from '@/lib/services/SlippingAwayUsersService';
import { useContext, useState } from 'react';
import { IReportType } from '../types/types.d';

export default function useSlippingAwayUsersHook() {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [isCountLoading, setIsCountLoading] = useState('idle');
  const [slippingAwayUsersCount, setSlippingAwayUsersCount] = useState<
    Array<{
      period: string;
      users: number;
    }>
  >([]);
  const [totalUsers, setTotalUsers] = useState(0);

  const fetchSlippingAwayUsersCount = async (
    insightId: string | undefined,
    reportType: IReportType,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ) => {
    setIsCountLoading('loading');
    const slippingAwayService = new SlippingAwayUsersService();
    await slippingAwayService
      .count(
        activeWorkspace.id, 
        insightId, 
        reportType, 
        audienceConditions, 
        audienceConditionType,
        audienceGroupConditions
        )
      .then((res) => {
        if (res.data) {
          const payload = Object.entries(res.data.slipping_away_users).map((item: any) => {
            return {
              period: item[0].replace('_', ' '),
              users: item[1],
              'Users Ratio': Math.round((item[1] / res.data.total_users) * 100)
            };
          });
          setSlippingAwayUsersCount(payload);
          setTotalUsers(res.data.total_users);
        }
      })
      .catch((err) => {});
    setIsCountLoading('loaded');
  };
  return {
    isCountLoading,
    fetchSlippingAwayUsersCount,
    slippingAwayUsersCount,
    totalUsers
  };
}
