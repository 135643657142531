import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { OrganizationService } from "@/lib/services/OrganizationService";
import { PLAN_TYPES } from "@/lib/utils/Constants";
import { BillingInformation } from "@/ui/components/App/Billing/BillingInformation";
import { BillingInvoices } from "@/ui/components/App/Billing/BillingInvoices";
import { BillingPlan } from "@/ui/components/App/Billing/BillingPlan";
import { TeamMemberBillingView } from "@/ui/components/App/Billing/TeamMemberBillingView";
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
import { LifetimePlans } from "@/ui/components/App/Upgrade/LifetimePlans";
import { MonthlyProductSuitePlans } from "@/ui/components/App/Upgrade/MonthlyProductSuitePlans";
import { Box, Paper, Tabs, useMantineColorScheme } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { LoadingStateProps } from "types/types.d";

type PlanProps = {
  events: number;
  id: string;
  is_lifetime: boolean;
  name: string;
  pixel_whitelabel: boolean;
  plan_type: string;
  price: number;
  workspaces: number;
};

export type ReceiptProps = {
  plan_name?: string;
  order_id: string;
  checkout_id: string;
  amount: string;
  tax: string;
  currency: string;
  quantity: number;
  receipt_url: string;
  paid_at: string;
  organization_id: string;
  status?: string;
};

export interface IEventsConsumptionBreakdown {
  pageviews: number;
  autocaptured_events: number;
  custom_events: number;
  form_events: number;
  integration_events: number;
}

export interface IPlanLimits {
  current_plan: {
    events: number;
    workspaces: number;
    funnels: number;
    goals: number;
    price: number;
  };
  next_plan: {
    workspaces?: number;
    events: number;
    price: number;
  };
}

const AccountsBilling = () => {
  const theme = useGlobalMantineTheme();

  const { activeWorkspace, user, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [activeTab, setActiveTab] = useState<string | null>("overview");
  console.log("Active workspace ->", activeWorkspace);
  const [plan, setPlan] = useState<PlanProps>();
  const [eventsConsumption, setEventsConsumption] = useState<{
    total: 0;
    percentage: 0;
  }>({
    total: 0,
    percentage: 0
  });
  const [planLimits, setPlanLimits] = useState<IPlanLimits>({
    current_plan: {
      events: 0,
      workspaces: 0,
      funnels: 0,
      goals: 0,
      price: 0
    },
    next_plan: {
      workspaces: 0,
      events: 0,
      price: 0
    }
  });

  const [eventsConsumptionBreakdown, setEventsConsumptionBreakdown] =
    useState<IEventsConsumptionBreakdown>({
      pageviews: 0,
      autocaptured_events: 0,
      custom_events: 0,
      form_events: 0,
      integration_events: 0
    });
  const [receipts, setReceipts] = useState<Array<ReceiptProps>>([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");

  const fetchOrganizationPlan = async () => {
    setLoading("loading");
    await new OrganizationService()
      .plan(activeWorkspace.organization.id)
      .then((res) => {
        setPlan(res.data.plan);
        setReceipts(res.data.receipts);
        setEventsConsumption(res.data.events_consumption);
        setPlanLimits(res.data.plan_limits);

        setEventsConsumptionBreakdown(res.data.events_consumption_breakdown);

        console.log("FetchOrganizationPlan: ", res.data.plan, activeWorkspace);
        // update organization plan type, this will be called when the user upgrades their plan.
        let workspace = JSON.parse(JSON.stringify(activeWorkspace));
        workspace.organization.plan_type = res.data.plan.plan_type;
        workspace.organization.plan.name = res.data.plan.name;
        workspace.organization.plan.plan_type = res.data.plan.plan_type;

        if (
          res.data.plan.name === PLAN_TYPES.STARTER ||
          res.data.plan.name === PLAN_TYPES.STARTER_BADGE_PROGRAM ||
          res.data.plan.name === PLAN_TYPES.STARTER_LEGACY
        ) {
          workspace.organization.is_trial_expired = false;
        }
        console.log("Setting organization active workspace", workspace, res.data.plan);
        setActiveWorkspace(workspace);
      })
      .catch((err) => {});
    setLoading("loaded");
  };
  const fetchOrganizationSubscriptions = async () => {
    new OrganizationService()
      .subscriptions(activeWorkspace.organization.id)
      .then((res) => setSubscriptions(res.data))
      .catch((err) => setSubscriptions([]));
  };
  useEffect(() => {
    document.title = "Plan & Payment | Usermaven";
    fetchOrganizationPlan();
    fetchOrganizationSubscriptions();
  }, []);

  // helper functions

  const isOwnerOfOrganization = () => {
    return activeWorkspace.members.some(
      (member: any) =>
        member.user_id === user.id && user.id === activeWorkspace.organization.created_by_user_id
    );
  };

  if (!isOwnerOfOrganization()) {
    return (
      <>
        <HeaderNavigation title="Plan &amp; Payment"></HeaderNavigation>
        <div className="um-settings-wrapper">
          <Box
            component="div"
            sx={() => ({
              border: `1px solid`,
              borderColor:
                theme.colorScheme === "dark"
                  ? theme.colors.darkBorderColor[0]
                  : theme.colors.lightBorderColor[0],
              backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : "#fff"
            })}
            className="um-settings-wrapper--container">
            <TeamMemberBillingView />
          </Box>
        </div>
      </>
    );
  }

  const handleOnUpgrade = () => {
    fetchOrganizationPlan();
    fetchOrganizationSubscriptions();
  };
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <Tabs
        styles={(theme) => ({
          tab: {
            marginRight: 8,
            borderBottom:
              colorScheme === "dark"
                ? `3px solid ${theme.colors.dark[2]}`
                : `3px solid ${theme.colors.gray[2]}`,
            "&:hover": {
              transition: "all 0.3s",
              borderBottom: `3px solid ${theme.colors.brand[5]}`
            },
            "&[data-active]": {
              borderBottom: `3px solid ${theme.colors.brand[5]}`
            }
          }
        })}
        value={activeTab}
        onChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="overview">Overview</Tabs.Tab>
          <Tabs.Tab value="plans">Plans</Tabs.Tab>
          <Tabs.Tab value="invoices">Invoices</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="overview" p={"sm"}>
          <>
            <Box>
              <BillingPlan
                loading={loading}
                plan={plan}
                eventsConsumption={eventsConsumption}
                eventsConsumptionBreakdown={eventsConsumptionBreakdown}
                planLimits={planLimits}
                fetchOrganizationPlan={fetchOrganizationPlan}
                fetchOrganizationSubscriptions={fetchOrganizationSubscriptions}
                subscriptions={subscriptions}
                setActiveTab={setActiveTab}
              />
            </Box>
            {!plan?.is_lifetime &&
              !plan?.name.includes(PLAN_TYPES.STARTER) &&
              !plan?.name.endsWith("trial") &&
              loading === "loaded" && (
                <Paper
                  mt={32}
                  withBorder
                  component="div"
                  className="um-settings-wrapper--container">
                  <>
                    <BillingInformation
                      subscriptions={subscriptions}
                      fetchOrganizationPlan={fetchOrganizationPlan}
                    />
                  </>
                </Paper>
              )}
          </>
        </Tabs.Panel>
        <Tabs.Panel value="plans">
          <Box px={0} py={32}>
            {plan?.is_lifetime && plan?.name !== "web-analytics-10m-events" ? (
              <>
                <LifetimePlans
                  open={true}
                  fetchOrganizationPlan={fetchOrganizationPlan}
                  closeModal={() => {}}
                />
              </>
            ) : (
              <MonthlyProductSuitePlans
                eventsConsumption={eventsConsumption.total}
                planLimits={planLimits}
                onPlanChange={handleOnUpgrade}
              />
            )}
          </Box>
        </Tabs.Panel>
        <Tabs.Panel value="invoices">
          <Paper
            mt={16}
            pb={32}
            withBorder
            shadow="xs"
            component="div"
            className="um-settings-wrapper--container">
            <BillingInvoices loading={loading} receipts={receipts} />
          </Paper>
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
export default AccountsBilling;
