import { Popover } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"

export const GoogleSearchConsoleSites = ({ integration }: any) => {
  const [opened, { close, open }] = useDisclosure(false)

  return (
    <>
      {integration.meta_details.hasOwnProperty("site_urls") &&
      integration.meta_details?.site_urls.length > 0 ? (
        <>
          <Popover width={200} position="bottom" withArrow shadow="md" opened={opened}>
            <Popover.Target>
              <span className="cursor-pointer" onMouseEnter={open} onMouseLeave={close}>
                {integration.meta_details?.site_urls.length} site(s) connected{" "}
              </span>
            </Popover.Target>
            <Popover.Dropdown sx={{ pointerEvents: "none" }}>
              <ul>
                {integration.meta_details?.site_urls.map((url: string, index: number) => (
                  <li key={`gsc:site:${index}:${url}`}>{url}</li>
                ))}
              </ul>
            </Popover.Dropdown>
          </Popover>{" "}
        </>
      ) : (
        "No site connected"
      )}{" "}
    </>
  )
}
