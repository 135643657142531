import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Table,
  Text,
  Modal,
  Flex,
  Title,
  Divider,
  Center
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceIntegrationService } from "@/lib/services/WorkspaceIntegrationService";
import { LIST_OF_INTEGRATIONS, WORKSPACE_INTEGRATIONS_CONSTANTS } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { ConnectedIntegrationType, IntegrationType, LooseObject } from "types/types.d";
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";

import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import { StringParam, useQueryParam } from "use-query-params";
import { GoogleSearchConsoleSites } from "./GoogleSearchConsoleSites";
import { GSCSitesModal } from "./GSCSitesModal";
import { IntegrationSyncStatusRow } from "./IntegrationSyncStatusRow";
type IntegrationServiceSlugType = {
  service: string;
};

export const IntegrationService = (props: any) => {
  const [wsIntegrationName, setWsIntegrationName] = useLocalStorage({
    key: "um_workspace_integration_name"
  });
  const [wsIdentifier, setWsIdentifier] = useLocalStorage({
    key: "um_workspace_identifier"
  });
  const [state, setState] = useLocalStorage({
    key: "um_workspace_integration_state"
  });

  const { service } = useParams<IntegrationServiceSlugType>();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [activeIntegration, setActiveIntegration] = useState<IntegrationType>();
  const [authorizeURL, setAuthorizeURL] = useState("");
  const [isLoading, setIsLoading] = useState("idle");
  const [isListLoading, setIsListLoading] = useState("idle");
  const [connectedIntegrationsList, setConnectedIntegrationsList] = useState<
    Array<ConnectedIntegrationType>
  >([]);
  const wsIntegrationService = new WorkspaceIntegrationService();
  const getWorkspaceIntegrationAuthorizeURL = (name: any) => {
    wsIntegrationService
      .authorizeIntegrationURL(activeWorkspace?.id, name)
      .then((res) => {
        if (res.data) {
          setAuthorizeURL(res.data.authorize_url);
          setState(res.data.state);
        }
      })
      .catch((err) => {});
  };

  const getWorkspaceIntegrationConnectedByService = async (name: any) => {
    setIsListLoading("loading");
    await wsIntegrationService
      .connectedIntegrationsByProvider(activeWorkspace.id, name)
      .then((res) => {
        if (res.data) {
          setConnectedIntegrationsList(res.data);
        }
      })
      .catch((err) => {});
    setIsListLoading("loaded");
    ReactTooltip.rebuild();
  };

  useEffect(() => {
    const foundIntegration = LIST_OF_INTEGRATIONS.find(
      (integrationItem) => integrationItem.name === service
    );
    console.log(foundIntegration);
    setActiveIntegration(foundIntegration);
    document.title = `${foundIntegration?.display_name} Integration | Usermaven `;
    getWorkspaceIntegrationAuthorizeURL(foundIntegration?.name);
    getWorkspaceIntegrationConnectedByService(foundIntegration?.name);
    setIsLoading("loaded");
  }, []);

  if (isLoading === "idle") {
    return <LoadingComponent />;
  }

  const redirectToAuthorizeURL = () => {
    if (authorizeURL) {
      setWsIntegrationName(activeIntegration?.name || "");
      setWsIdentifier(activeWorkspace?.identifier);
      // setWorkspaceIntegrationNameToStorage(activeIntegration?.name)
      window.location.href = authorizeURL;
    }
  };

  const removeIntegration = (integration: any) => {
    console.log(integration);
    wsIntegrationService
      .deleteIntegration(integration.id)
      .then((res) => {
        if (res.data) {
          toast.success(res.data.message);
        }
        const filteredConnectedIntegrations = connectedIntegrationsList.filter(
          (connectedIntegration: ConnectedIntegrationType) =>
            connectedIntegration.id !== integration.id
        );
        setConnectedIntegrationsList(filteredConnectedIntegrations);
      })
      .catch((err) => {});
  };

  const removeIntegrationPrompt = (integration: any) => {
    openConfirmModal({
      title: "Delete integration",
      children: (
        <Text size="sm">
          You want to delete your {integration.name.replaceAll("_", " ")} integration?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => removeIntegration(integration)
    });
  };

  const renderTooltipInformation = () => {
    if (connectedIntegrationsList.length > 0) {
      const name = connectedIntegrationsList[0].name || "";
      switch (name) {
        case "google_search_console":
          return (
            <>
              <FontAwesomeIcon
                data-for="data-gsc"
                data-tip={
                  "Only domains that are connected in the workspace will be synced. To sync more domains, add them to the additional domains section in your Workspace."
                }
                icon={"info-circle"}
                className="text-gray-700 mr-2"></FontAwesomeIcon>
              <ReactTooltip
                effect="solid"
                id="data-gsc"
                className="w-96 font-normal text-base normal-case"
              />
            </>
          );
        default:
          break;
      }
    }
  };

  return (
    <>
      <GSCSitesModal reload={getWorkspaceIntegrationConnectedByService}></GSCSitesModal>
      {/* <HeaderNavigation
        is_integration_view={true}
        integration_name={activeIntegration?.display_name}></HeaderNavigation> */}

      <Flex align={"center"}>
        <Flex align={"center"} flex={1}>
          <Box w={64} h={64}>
            <img src={activeIntegration?.image} alt="" />
          </Box>
          <Flex pl={"sm"} direction={"column"}>
            <Badge size="sm" color="gray.6" mb="xs" radius={"sm"}>
              {activeIntegration?.category}
            </Badge>
            <Title order={5} fw={600} mb="xs">
              {activeIntegration?.display_name}
            </Title>

            <Text>{activeIntegration?.description}</Text>
          </Flex>
        </Flex>
        <div>
          <Button
            disabled={authorizeURL === "" || !authorizeURL}
            onClick={() => {
              redirectToAuthorizeURL();
            }}>{`Connect ${activeIntegration?.display_name}`}</Button>
        </div>
      </Flex>
      <Divider my="xl" />
      {connectedIntegrationsList.length === 0 && (
        <Center>
          <Text mb="sm">You do not have any integration connected yet.</Text>
        </Center>
      )}
      {connectedIntegrationsList.length > 0 && (
        <Box component="div" className="mt-4">
          <Table verticalSpacing={"md"} striped>
            <thead className="uppercase ">
              <tr>
                <th>Account ID</th>
                <th>Active Status {renderTooltipInformation()}</th>
                <th>Sync Status</th>
                <th className="text-center flex justify-center">Actions</th>
              </tr>
            </thead>
            {isListLoading === "loaded" ? (
              <>
                <tbody>
                  {connectedIntegrationsList.map(
                    (connectedIntegration: ConnectedIntegrationType) => (
                      <>
                        <tr>
                          <td>{connectedIntegration.account_id}</td>
                          <td>
                            {connectedIntegration.name ===
                              WORKSPACE_INTEGRATIONS_CONSTANTS.HELPSCOUT && (
                              <>
                                {connectedIntegration.meta_details?.mailboxes?.page?.totalElements}{" "}
                                Mailbox(s) Connected
                              </>
                            )}
                            {connectedIntegration.name ===
                              WORKSPACE_INTEGRATIONS_CONSTANTS.GOOGLE_SEARCH_CONSOLE && (
                              <>
                                <GoogleSearchConsoleSites integration={connectedIntegration} />
                              </>

                              // <>{connectedIntegration.meta_details?.sites.length} sites connected</>
                            )}
                          </td>
                          <td>
                            <IntegrationSyncStatusRow integration={connectedIntegration} />
                          </td>
                          <td className="text-center flex justify-center">
                            <>
                              <ActionIcon
                                data-tip="Remove"
                                variant="outline"
                                color={"red"}
                                onClick={() => {
                                  removeIntegrationPrompt(connectedIntegration);
                                }}>
                                <FontAwesomeIcon icon={regular("trash")} className=" transform " />
                              </ActionIcon>
                            </>
                          </td>
                        </tr>
                      </>
                    )
                  )}
                </tbody>
              </>
            ) : (
              <>
                <tbody>
                  <tr>
                    <td colSpan={4}>
                      <Oval color="#333354" height={16} width={16} />
                    </td>
                  </tr>
                </tbody>
              </>
            )}
          </Table>
        </Box>
      )}
    </>
  );
};
