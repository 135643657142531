import { icon, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Box, Flex, MultiSelect, Select } from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { MultiSelectRulesCreatable } from "./MultiSelectRulesCreatable";

interface Props {
  item: {
    id: string;
    type: string;
    operator: string;
    rules: string[];
  };
  onDelete: (id: string) => void;
  onUpdate: (id: string, type: string | null, operator: string | null, rules: string[]) => void;
}

const MESSAGES = {
  HOSTNAME: "Add the hostnames you want to exclude i.e staging.yourdomain.com",
  SOURCE_IP: "Add IP addresses you want to exclude.",
  EMAIL: "Add your team members or test accounts you want to exclude."
};

export const FilterInternalTrafficQueryBuilder = ({ item, onDelete, onUpdate }: Props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [type, setType] = useState<string | null>(item.type);
  const [rules, setRules] = useState<Array<any>>(item.rules);
  const [rulesOptions, setRulesOptions] = useState<Array<any>>(
    item.rules.map((rule) => ({ label: rule, value: rule }))
  );
  const [operator, setOperator] = useState<string | null>(item.operator);

  useEffect(() => {
    ReactTooltip.rebuild();
    if (type === "hostname") {
    }
  }, [type]);

  useEffect(() => {
    if (rules.length === 0) {
      setIsEdit(true);
    }
  }, []);

  const handleOnSave = () => {
    if (rules.length === 0) {
      toast.error("Please add at-least one rule to save the filter");
      return;
    }
    console.log("Handle on save", rules);
    onUpdate(item.id, type, operator, rules);
    setIsEdit(false);
  };

  return (
    <>
      <Flex align="center">
        <Box w={"30%"}>
          <Select
            defaultValue={"hostname"}
            disabled={!isEdit}
            placeholder="Select type"
            value={type}
            onChange={(value) => {
              setType(value);
            }}
            data={[
              { value: "ip_address", label: "IP Address" },
              { value: "hostname", label: "Hostname" },
              { value: "email", label: "Email" }
            ]}
          />
        </Box>

        <Box mx={"sm"} w={"20%"}>
          <Select
            disabled={!isEdit}
            defaultValue={"equals"}
            placeholder="Select type"
            value={operator}
            onChange={(value) => {
              setOperator(value);
            }}
            data={[
              { value: "equals", label: "Equals" },
              { value: "contains", label: "Contains" }
            ]}
          />
        </Box>
        <Flex flex={1} align={"center"}>
          <Flex flex={1}>
            <MultiSelectRulesCreatable
              rulesOptions={rulesOptions}
              setRulesOptions={setRulesOptions}
              item={item}
              type={type}
              isEdit={isEdit}
              setRules={setRules}
            />
          </Flex>
        </Flex>

        {isEdit ? (
          <>
            <Box>
              <ActionIcon
                data-tip="Save"
                className=""
                ml={8}
                variant="outline"
                color={"green.6"}
                onClick={() => handleOnSave()}>
                <FontAwesomeIcon icon={regular("check")} className=" " />
              </ActionIcon>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <ActionIcon
                data-tip="Edit"
                className="hidden group-hover:flex"
                ml={8}
                variant="outline"
                color={"gray.6"}
                onClick={() => {
                  setIsEdit(true);
                }}>
                <FontAwesomeIcon icon={regular("pencil")} className=" " />
              </ActionIcon>
            </Box>
          </>
        )}

        <div className="w-20">
          <ActionIcon
            className="hidden group-hover:flex"
            ml={8}
            data-tip="Delete"
            variant="outline"
            color={"red"}
            onClick={() => onDelete(item.id)}>
            <FontAwesomeIcon icon={regular("trash")} className=" text-red-500 transform " />
          </ActionIcon>
        </div>
      </Flex>
    </>
  );
};
