// Used for the integrations array purpose.

import GOOGLE_SEARCH_CONSOLE_ICON from "@/assets/images/icons/integrations/google_search_console.svg";
import paddleIcon from "@/assets/images/icons/integrations/paddle.svg";
import stripeIcon from "@/assets/images/icons/integrations/stripe.svg";
import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears
} from "date-fns";
import { SortDirection } from "ka-table/enums";
import { IntegrationType, TopSourcesProps, WorkspaceSharingType } from "types/types.d";
import { IColumn } from "../../ui/components/Common/VirtualDataTable/VirtualDataTable";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export const TIMER_THIRTY_SECONDS = 30000;

export const DEFAULT_SELECTED_DOMAIN = {
  label: "All domains",
  value: "all_domains",
  is_enabled: true
};

export const TOAST_MESSAGES = {
  EMAIL_ADDRESS_CHANGED: "Your email address has been updated.",
  PROFILE_DETAILS_CHANGED: "Your profile details have been updated.",
  PROFILE_IMAGE_UPLOADED: "Profile image uploaded successfully!",
  SUPPORT_ACCESS_GIVEN: "Your request for support access has been granted to Usermaven.",
  SUPPORT_ACCESS_REVOKED: "Your request for support access has been revoked from Usermaven.",
  // Workspace related
  WORKSPACE_DETAILS_CHANGED: "Your workspace details have been updated.",
  WORKSPACE_IDENTIFIER_COPIED: "Your workspace identifier has been copied.",
  WORKSPACE_CREATED: "Workspace created successfully!",
  WORKSPACE_SET_AS_DEFAULT: "has been set as a default workspace.",
  WORKSPACE_JOINED_ACCOUNT_CREATED:
    "Your account has been created successfully. Please login to continue.",
  WORKSPACE_RESEND_INVITATION: "An invitation has been sent to",
  WORKSPACE_INVITE_LINK_COPIED: "Your invite link copied.",
  WORKSPACE_LOGO_UPLOADED: "Workspace logo updated successfully!",
  WORKSPACE_IMAGE_UPLOAD_FAILED: "Workspace logo upload failed.",

  // Team members related
  TEAM_MEMBER_INVITED_TO_WORKSPACE: "Team member has been invited to workspace successfully.",
  TEAM_MEMBER_ROLE_CHANGED: "Team member role has been updated successfully.",

  // Insights

  INSIGHT_CREATED: "Your insight has been created successfully.",
  INSIGHT_UPDATED: "Your insight has been updated successfully.",
  INSIGHT_REMOVED: "Your insight has been removed.",

  // Team Member limit

  STARTER_PLAN_TEAM_MEMBER_LIMIT:
    "You have reached the maximum number of team members for your plan."
};

export const WORKSPACE_MEMBER_ROLES = {
  OWNER: "owner",
  ADMINISTRATOR: "administrator",
  MANAGER: "manager",
  AUTHOR: "author",
  VIEWER: "viewer"
};
export const WORKSPACE_MEMBER_STATE = {
  JOINED: "joined",
  INVITED: "invited"
};

export const LIST_OF_INTEGRATIONS: Array<IntegrationType> = [
  {
    name: "google_search_console",
    display_name: "Google Search Console",
    category: "Data Management",
    description: "Connect Google Search Console to get keywords data under web analytics.",
    is_connected: false,
    image: GOOGLE_SEARCH_CONSOLE_ICON,
    homepage: "https://search.google.com/search-console/about",
    is_live: true
  },
  // {
  //   name: 'helpscout',
  //   display_name: 'HelpScout',
  //   category: 'Customer Support',
  //   description: 'Connect HelpScout to see the conversations for your contacts.',
  //   is_connected: false,
  //   image: helpscoutIcon,
  //   homepage: 'https://helpscout.com'
  // },
  // {
  //   name: 'segment',
  //   display_name: 'Segment',
  //   category: 'Data Management',
  //   description: 'Connect Segment to pull all the data from Segment to Usermaven.',
  //   is_connected: false,
  //   image: segmentIcon,
  //   homepage: 'https://segment.com'
  // },
  {
    name: "paddle",
    display_name: "Paddle",
    category: "Billing and Payment",
    description: "Connect Paddle to see LTV, MRR and other metrics for your contacts.",
    is_connected: false,
    image: paddleIcon,
    homepage: "https://paddle.com",
    is_live: false
  },
  {
    name: "stripe",
    display_name: "Stripe",
    category: "Billing and Payment",
    description: "Connect Stripe to see LTV, MRR and other metrics for your contacts.",
    is_connected: false,
    image: stripeIcon,
    homepage: "https://stripe.com",
    is_live: false
  }
];

export const WORKSPACE_INTEGRATIONS_CONSTANTS = {
  HELPSCOUT: "helpscout",
  PADDLE: "paddle",
  SEGMENT: "segment",
  GOOGLE_SEARCH_CONSOLE: "google_search_console"
};

export const PUBLIC_ROUTES_LIST = [
  "/join-workspace",
  "/reset-password",
  "/forgot-password",
  "/register",
  "/share/"
];

export const DEFAULT_PEOPLE_FIELDS = [
  "user_id",
  "user_account_id",
  "user_account_name",
  "user_email",
  "user_first_name",
  "user_last_name",
  "user_created_at",
  "location_country",
  "location_city"
];

export const DEFAULT_COMPANIES_FIELDS: IColumn[] = [
  {
    key: "avatar",
    title: "",
    displayName: "",
    width: 70,
    visible: true,
    isResizable: false,
    isSortable: false
  },
  {
    key: "company_id",
    title: "Company ID",
    displayName: "Company ID",
    width: 200,
    visible: true,
    isResizable: true,
    isSortable: true
  },
  {
    key: "company_name",
    title: "Company Name",
    displayName: "Company Name",
    width: 200,
    visible: true,
    isResizable: true,
    isSortable: true
  },
  {
    key: "sessions",
    title: "Sessions",
    displayName: "Sessions",
    width: 200,
    visible: true,
    isResizable: true,
    isSortable: true
  },
  {
    key: "number_of_users",
    title: "Users",
    displayName: "Users",
    width: 200,
    visible: true,
    isResizable: true,
    isSortable: true
  },
  {
    key: "last_visited_time",
    title: "Last Logged In",
    displayName: "Last Logged In",
    width: 200,
    visible: true,
    isResizable: true,
    isSortable: true,
    sortDirection: SortDirection.Descend
  },
  {
    key: "company_created_at",
    title: "Created At",
    displayName: "Created At",
    width: 200,
    visible: true,
    isResizable: true,
    isSortable: true
  }
];

export const DEFAULT_VISITORS_FIELDS: IColumn[] = [
  {
    key: "avatar",
    title: "",
    displayName: "",
    width: 50,
    visible: true,
    isResizable: false,
    isSortable: false
  },
  {
    key: "user_name",
    title: "Name",
    displayName: "Name",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "engagement",
    title: "Engagement",
    displayName: "Engagement",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "first_seen",
    title: "First Seen",
    displayName: "First Seen",
    width: 200,
    visible: true,
    isSortable: true,
    sortDirection: SortDirection.Descend
  },
  {
    key: "last_seen",
    title: "Last Seen",
    displayName: "Last Seen",
    width: 200,
    visible: true,
    isSortable: true,
    sortDirection: SortDirection.Descend
  },
  {
    key: "location",
    title: "Location",
    displayName: "Location",
    width: 230,
    visible: true,
    isSortable: true
  },
  // {
  //   key: 'referer',
  //   title: 'Referrer',
  //   width: 200,
  //   visible: true,
  //   isSortable: true
  // },
  {
    key: "source",
    title: "First TouchPoint",
    displayName: "First TouchPoint",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "first_viewed_page",
    title: "First Viewed Page",
    displayName: "First Viewed Page",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "last_viewed_page",
    title: "Last Viewed Page",
    displayName: "Last Viewed Page",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "last_parsed_ua_os_family",
    title: "Operating System",
    displayName: "Operating System",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "last_parsed_ua_us_family",
    title: "User Agent",
    displayName: "User Agent",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "pageviews",
    title: "Page Views",
    displayName: "Page Views",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "utm_source",
    title: "Original UTM Source",
    displayName: "Original UTM Source",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "utm_medium",
    title: "Original UTM Medium",
    displayName: "Original UTM Medium",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "utm_campaign",
    title: "Original UTM Campaign",
    displayName: "Original UTM Campaign",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "utm_content",
    title: "Original UTM Content",
    displayName: "Original UTM Content",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "utm_term",
    title: "Original UTM Term",
    displayName: "Original UTM Term",
    width: 200,
    visible: true,
    isSortable: true
  },
  {
    key: "is_online",
    title: "",
    displayName: "",
    width: 200,
    visible: false
  }
];

export const ROW_LOADING = "loading";
export const LOADER_COLOR = "#333354";
export const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const customStylesForSelect = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "#fff",
    borderColor: state.isSelected ? "#242424" : "#dce0e3",
    minHeight: "30px",
    height: "30px",
    minWidth: "180px",
    // maxWidth: "300px",
    width: "180px",
    boxShadow: state.isFocused ? null : null
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    background:
      state.isSelected || state.isFocused
        ? "rgba(159, 114, 252, 0.15)"
        : "rgba(159, 114, 252, 0.04)",

    ":active": {
      background: "rgba(159, 114, 252, 0.15)"
    },
    ":hover": {
      background: "rgba(159, 114, 252, 0.15)"
    },
    ":focus": {
      background: "rgba(159, 114, 252, 0.15)"
    },
    color: "#01002a"
  }),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    lineHeight: "30px",
    height: "30px",
    padding: "0 6px",
    fontSize: "0.9325rem",
    fontWeight: "500",
    color: "#636a73"
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    marginTop: "-8px !important",
    borderColor: state.isFocused ? "#7d47eb" : "#dce0e3"
  }),
  indicatorSeparator: (state: any) => ({
    display: "none"
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: "26px",
    svg: {
      height: "10px"
    }
  })
};

export const INSIGHT_FILTER_EVENTS = {
  PAGEVIEW: "pageview",
  EVENT: "event",
  ATTRIBUTE: "attribute"
};

export const PAGEVIEW_OPTIONS = {
  URL: "url",
  PATH: "path",
  TITLE: "title"
};

export const HELP_WIDGET_CONSTANTS = {
  FEEDBACK_URL: "https://usermaven.frill.co/b/j0xn3emq/feature-requests",
  CHANGELOG_URL: "https://usermaven.frill.co/announcements",
  HELP_DOCS_URL: "https://docs.usermaven.com",
  FRILL_KEY: "28613365-0737-4017-a2dd-da8910f3ee26"
};

export const USERMAVEN_OPTS = {
  key: process.env.REACT_APP_USERMAVEN_PROJECT_ID || "",
  tracking_host: process.env.REACT_APP_USERMAVEN_TRACKING_HOST || "",
  autocapture: true,
  randomize_url: true
};

// Billing related types

export const PLAN_TYPES = {
  STARTER: "starter",
  STARTER_LEGACY: "starter-legacy",
  STARTER_BADGE_PROGRAM: "starter-badge-program",
  PRO: "pro",
  PREMIUM: "premium",
  PREMIUM_LEGACY: "premium-legacy",
  PRO_TRIAL: "pro-trial",
  PREMIUM_TRIAL: "premium-trial",
  ENTERPRISE: "enterprise"
};

export const PADDLE_PLAN_IDS = {
  PRO: process.env.REACT_APP_PADDLE_PRO_PLAN_ID,
  PREMIUM: process.env.REACT_APP_PADDLE_PREMIUM_PLAN_ID
};

export const PADDLE_VENDOR_ID = process.env.REACT_APP_PADDLE_VENDOR_ID || 7124;

export const STARTER_PLAN = [
  "Web analytics and product insights",
  // 'Track upto 1 million events per month',
  "6 months data history",
  "2 users",
  "1 workspace",
  "Basic funnel analysis"
];

export const PRO_PLAN = [
  "Website analytics",
  "Unlimited websites",
  "Unlimited workspaces",
  "Unlimited users",
  "2 years data history",
  "Conversion goals tracking",
  "Funnel analytics",
  "User journey analytics",
  "Visitor profiles",
  "Ad-blocker bypassing",
  "2 tracking modes",
  "Maven AI"
];

export const PREMIUM_PLAN = [
  "SaaS product analytics",
  "Multi-touch attribution",
  "Contacts hub (CRM)",
  "Google ads and Meta integrations (soon)",
  "Contacts segmentation",
  "Custom channel mapping",
  "Customizable dashboards (soon)"
];

export const ENTERPRISE_PLAN_FEATURES = [
  "On-premise hosting",
  "Private cloud hosting",
  "Custom integrations",
  "Concierge onboarding",
  "Dedicated account manager",
  "Priority support",
  "Advanced security & privacy compliance"
];

export const ProPlansPricingOptions = [
  {
    label: "100,000 events ($14/month)",
    value: "100,000",
    workspaces: 3,
    price: 14,
    events: "100,000 events",
    events_numeric: 100000
  },
  {
    label: "250,000 events ($24/month)",
    value: "250,000",
    workspaces: 5,
    price: 24,
    events: "250,000 events",
    events_numeric: 250000
  },
  {
    label: "500,000 events ($44/month)",
    value: "500,000",
    workspaces: 10,
    price: 44,
    events: "500,000 events",
    events_numeric: 500000
  },
  {
    label: "1 million events ($54/month)",
    value: "1,000,000",
    workspaces: 20,
    price: 54,
    events: "1 million events",
    events_numeric: 1000000
  },
  {
    label: "2 million events ($74/month)",
    value: "2,000,000",
    workspaces: 30,
    price: 74,
    events: "2 million events",
    events_numeric: 2000000
  },
  {
    label: "5 million events ($99/month)",
    value: "5,000,000",
    workspaces: "Unlimited",
    price: 99,
    events: "5 million events",
    events_numeric: 5000000
  },
  {
    label: "10 million events ($199/month)",
    value: "10,000,000",
    workspaces: "Unlimited",
    price: 199,
    events: "10 million events",
    events_numeric: 10000000
  }
];
export const PremiumPlansPricingOptions = [
  {
    label: "1 million events ($99/month)",
    value: "1,000,000",
    price: 99,
    events: "1 million events",
    events_numeric: 1000000
  },
  {
    label: "2 million events ($124/month)",
    value: "2,000,000",
    price: 124,
    events: "2 million events",
    events_numeric: 2000000
  },
  {
    label: "3 million events ($149/month)",
    value: "3,000,000",
    price: 149,
    events: "3 million events",
    events_numeric: 3000000
  },
  {
    label: "4 million events ($174/month)",
    value: "4,000,000",
    price: 174,
    events: "4 million events",
    events_numeric: 4000000
  },
  {
    label: "5 million events ($199/month)",
    value: "5,000,000",
    price: 199,
    events: "5 million events",
    events_numeric: 5000000
  },
  {
    label: "10 million events ($324/month)",
    value: "10,000,000",
    price: 324,
    events: "10 million events",
    events_numeric: 10000000
  }
];

export const PADDLE_SUBSCRIPTION_STATUS = {
  ACTIVE: "active",
  PAST_DUE: "past_due",
  PAUSED: "paused",
  DELETED: "deleted"
};

export const ORGANIZATION_STATES = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  DELETED: "deleted",
  QUOTA_EXCEEDED: "quota_exceeded"
};

export const PLAN_PRODUCT_TYPE = {
  WEB: "web",
  WEB_PRODUCT: "web_product"
};

export const WORKSPACE_SHARING_TYPES: {
  PUBLIC: WorkspaceSharingType;
  PRIVATE: WorkspaceSharingType;
  NONE: null;
} = {
  PUBLIC: "public",
  PRIVATE: "private",
  NONE: null
};

export const AUTO_CAPTURE_WIZARD_TYPES = {
  SELECT_EVENTS: "select_events",
  REVIEW_AND_SAVE: "review_and_save"
};

export const COMPARE_DATE_RANGES: Array<{
  label: string;
  startDate?: string;
  endDate?: string;
  interval?: string;
}> = [
  {
    label: "Current vs previous day",
    startDate: format(startOfDay(subDays(new Date(), 1)), DATE_FORMAT),
    endDate: format(endOfDay(subDays(new Date(), 1)), DATE_FORMAT),
    interval: "hour"
  },
  {
    label: "Current vs previous week",
    startDate: format(startOfWeek(subWeeks(new Date(), 1)), DATE_FORMAT),
    endDate: format(endOfWeek(subWeeks(new Date(), 1)), DATE_FORMAT),
    interval: "day"
  },
  {
    label: "Current vs previous month",
    startDate: format(startOfMonth(subMonths(new Date(), 1)), DATE_FORMAT),
    endDate: format(endOfMonth(subMonths(new Date(), 1)), DATE_FORMAT),
    interval: "day"
  },
  {
    label: "Current vs previous quarter",
    startDate: format(startOfQuarter(subQuarters(new Date(), 1)), DATE_FORMAT),
    endDate: format(endOfQuarter(subQuarters(new Date(), 1)), DATE_FORMAT),
    interval: "month"
  },
  {
    label: "Current vs previous year",
    startDate: format(startOfYear(subYears(new Date(), 1)), DATE_FORMAT),
    endDate: format(endOfYear(subYears(new Date(), 1)), DATE_FORMAT),
    interval: "month"
  }
];

export const CURRENT_DATE_RANGES: Array<{
  label: string;
  startDate?: string;
  endDate?: string;
  interval?: string;
}> = [
  {
    label: "Current day",
    startDate: format(startOfDay(new Date()), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "hour"
  },
  {
    label: "Current week",
    startDate: format(startOfWeek(new Date()), DATE_FORMAT),
    endDate: format(endOfWeek(new Date()), DATE_FORMAT),
    interval: "day"
  },
  {
    label: "Current month",
    startDate: format(startOfMonth(new Date()), DATE_FORMAT),
    endDate: format(endOfMonth(new Date()), DATE_FORMAT),
    interval: "day"
  },
  {
    label: "Current quarter",
    startDate: format(startOfQuarter(new Date()), DATE_FORMAT),
    endDate: format(endOfQuarter(new Date()), DATE_FORMAT),
    interval: "month"
  },
  {
    label: "Current year",
    startDate: format(startOfYear(new Date()), DATE_FORMAT),
    endDate: format(endOfYear(new Date()), DATE_FORMAT),
    interval: "month"
  }
];

export const DATE_RANGES: Array<{
  label: string;
  startDate?: string;
  endDate?: string;
  interval?: string;
}> = [
  {
    label: "Today",
    startDate: format(startOfDay(new Date()), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "hour"
  },
  {
    label: "Yesterday",
    startDate: format(startOfDay(subDays(new Date(), 1)), DATE_FORMAT),
    endDate: format(endOfDay(subDays(new Date(), 1)), DATE_FORMAT),
    interval: "hour"
  },
  {
    label: "Last 7 days",
    startDate: format(startOfDay(subDays(new Date(), 7)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "day"
  },

  {
    label: "Last 14 days",
    startDate: format(startOfDay(subDays(new Date(), 14)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "day"
  },
  {
    label: "Last 21 days",
    startDate: format(startOfDay(subDays(new Date(), 21)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "day"
  },
  {
    label: "Last 30 days",
    startDate: format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "day"
  },

  {
    label: "This Month",
    startDate: format(startOfMonth(new Date()), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "day"
  },
  {
    label: "Last Month",
    startDate: format(startOfMonth(subMonths(new Date(), 1)), DATE_FORMAT),
    endDate: format(endOfMonth(subMonths(new Date(), 1)), DATE_FORMAT),
    interval: "day"
  },

  {
    label: "Last Quarter",
    startDate: format(subQuarters(startOfQuarter(new Date()), 1), DATE_FORMAT),
    endDate: format(subQuarters(endOfQuarter(new Date()), 1), DATE_FORMAT),
    interval: "week"
  },
  {
    label: "Last 6 Months",
    startDate: format(startOfMonth(subMonths(new Date(), 6)), DATE_FORMAT),
    endDate: format(new Date(), DATE_FORMAT),
    interval: "month"
  },
  {
    label: "Last 9 Months",
    startDate: format(startOfMonth(subMonths(new Date(), 9)), DATE_FORMAT),
    endDate: format(new Date(), DATE_FORMAT),
    interval: "month"
  },
  {
    label: "Last 12 Months",
    startDate: format(subMonths(new Date(), 12), DATE_FORMAT),
    endDate: format(new Date(), DATE_FORMAT),
    interval: "month"
  }
];

export const STARTER_PLAN_DISABLED_DATE_RANGES = [
  "last 9 months",
  "last 12 months",
  "last 1 year",
  "all time"
];

export const FUNNEL_BREAKDOWN_COLORS = [
  "#7f17d1",
  "#f5b237",
  "#01baa1",
  "#e65652",
  "#0197e8",
  "#797979"
  // '#6bd117',
  // '#1799d1',
  // '#d1b817',
  // '#d17417',
  // '#d13317',
  // '#d11717',
  // '#b8d117',
  // '#17d158',
  // '#17d19f',
  // '#17cbd1',
];

export const AI_FEEDBACK_TYPES = {
  POSITIVE: "positive",
  NEGATIVE: "negative",
  NEGATIVE_TRY_AGAIN: "negative_try_again"
};

export const COMPARE_ANALYTICS_DROPDOWN_OPTIONS = {
  DISABLE_COMPARISON: "End comparison",
  PREVIOUS_PERIOD: "Previous period",
  CUSTOM_PERIOD: "Custom",
  MATCH_DAY_OF_THE_WEEK: "Match day of the week",
  MATCH_EXACT_DATE: "Match exact date"
};

export const LEFT_MENU_TYPES = {
  WebAnalytics: "Web Analytics",
  ProductInsights: "Product Insights",
  Overview: "Overview", // Submenu of Product Insights
  Onboarding: "Onboarding", // Submenu of Product Insights
  ProductEngagement: "Product Engagement", // Submenu of Product Insights
  FeatureAdoption: "Feature Adoption", // Submenu of Product Insights
  Retention: "Retention", // Submenu of Product Insights
  ContactsHub: "Contacts Hub",
  Visitors: "Visitors", // Submenu of Contacts Hub
  Users: "Users", // Submenu of Contacts Hub
  Companies: "Companies", // Submenu of Contacts Hub
  Segments: "Segments", // Submenu of Contacts Hub
  Analyze: "Analyze",
  Funnels: "Funnels",
  Journeys: "Journeys",
  Attribution: "Attribution",
  Events: "Events",
  MavenAi: "Maven AI",
  Reports: "Reports"
};

export interface ILeftMenuItem {
  name: string;
  path: string;
  iconName: IconDefinition;
  isVisible: boolean;
  hideSubMenu?: boolean;
  subMenu?: ILeftMenuItem[];
  activePatterns?: string[];
  isBeta?: boolean;
}
export const LEFT_MENU_ITEMS: ILeftMenuItem[] = [
  {
    name: LEFT_MENU_TYPES.MavenAi,
    path: "maven-ai/new",
    iconName: light("solar-system"),
    isVisible: true,
    isBeta: true,
    activePatterns: ["/maven-ai/"]
  },
  {
    name: LEFT_MENU_TYPES.WebAnalytics,
    path: "web/statistics",
    iconName: light("browser"),
    isVisible: true
  },
  {
    name: LEFT_MENU_TYPES.ProductInsights,
    path: "insights/dashboard/overview",
    iconName: light("chart-pie-simple"),
    isVisible: true,
    subMenu: [
      {
        name: LEFT_MENU_TYPES.Overview,
        path: "insights/dashboard/overview",
        iconName: light("chart-tree-map"),
        isVisible: true
      },
      {
        name: LEFT_MENU_TYPES.Onboarding,
        path: "insights/dashboard/onboarding",
        iconName: light("users-medical"),
        isVisible: true
      },
      {
        name: LEFT_MENU_TYPES.ProductEngagement,
        path: "insights/dashboard/adoption_engagement",
        iconName: light("chart-line-up-down"),
        isVisible: true
      },
      {
        name: LEFT_MENU_TYPES.FeatureAdoption,
        path: "insights/dashboard/feature",
        iconName: light("face-smile-plus"),
        isVisible: true
      },
      {
        name: LEFT_MENU_TYPES.Retention,
        path: "insights/dashboard/retention",
        iconName: light("chart-user"),
        isVisible: true
      }
    ]
  },
  {
    name: LEFT_MENU_TYPES.ContactsHub,
    path: "visitors/everyone",
    iconName: light("user"),
    activePatterns: ["/visitors/", "/users/", "/companies/"],
    isVisible: true,
    hideSubMenu: true,
    subMenu: [
      {
        name: LEFT_MENU_TYPES.Visitors,
        path: "visitors/everyone",
        activePatterns: ["/visitors/"],
        iconName: light("eye"),
        isVisible: true
      },
      {
        name: LEFT_MENU_TYPES.Users,
        path: "users/everyone",
        activePatterns: ["/users/"],
        iconName: light("users"),
        isVisible: true
      },
      {
        name: LEFT_MENU_TYPES.Companies,
        path: "companies/everyone",
        activePatterns: ["/companies/"],
        iconName: light("building"),
        isVisible: true
      }
      // {
      //   name: LEFT_MENU_TYPES.Segments,
      //   path: "segments",
      //   iconName: light("circle-user"),
      //   isVisible: true
      // }
    ]
  },
  // {
  //   name: LEFT_MENU_TYPES.Analyze,
  //   path: "analyze/funnels",
  //   iconName: light("chart-area"),
  //   isVisible: true,
  //   subMenu: [
  //     {
  //       name: LEFT_MENU_TYPES.Funnels,
  //       path: "analyze/funnels",
  //       iconName: light("filter"),
  //       isVisible: true
  //     },
  //     {
  //       name: LEFT_MENU_TYPES.Attribution,
  //       path: "analyze/attribution",
  //       iconName: light("bullseye-arrow"),
  //       isVisible: true
  //     },
  //     {
  //       name: LEFT_MENU_TYPES.Journeys,
  //       path: "analyze/journeys",
  //       iconName: light("road"),
  //       isVisible: true,
  //       isBeta: true
  //     }
  //   ]
  // },
  {
    name: LEFT_MENU_TYPES.Funnels,
    path: "analyze/funnels",
    iconName: light("filter"),
    isVisible: true
  },
  {
    name: LEFT_MENU_TYPES.Journeys,
    path: "analyze/journeys",
    iconName: light("road"),
    isVisible: true,
    isBeta: false
  },
  {
    name: LEFT_MENU_TYPES.Attribution,
    path: "analyze/attribution",
    iconName: light("bullseye-arrow"),
    isVisible: true
  },
  {
    name: LEFT_MENU_TYPES.Reports,
    path: "reports",
    iconName: light("file-chart-line"),
    isVisible: true,
    isBeta: false
  }
];

export const PRODUCT_INSIGHTS_MENU_ITEMS = [
  LEFT_MENU_TYPES.Overview,
  LEFT_MENU_TYPES.Onboarding,
  LEFT_MENU_TYPES.ProductEngagement,
  LEFT_MENU_TYPES.FeatureAdoption,
  LEFT_MENU_TYPES.Retention
];

export const CONTACTS_HUB_MENU_ITEMS = [
  LEFT_MENU_TYPES.Visitors,
  LEFT_MENU_TYPES.Users,
  LEFT_MENU_TYPES.Companies
  // LEFT_MENU_TYPES.Segments
];
export const ANALYZE_MENU_ITEMS = [
  LEFT_MENU_TYPES.Funnels,
  LEFT_MENU_TYPES.Attribution,
  LEFT_MENU_TYPES.Journeys
];

export const MOBILE_BREAKPOINT_QUERY = "(max-width: 900px)";

export const FUNNEL_TYPE_KEYS = "funnel_chart_type";

export enum DEFAULT_CHANNEL_TYPES {
  PAID_SEARCH = "paid_search",
  PAID_SOCIAL = "paid_social",
  DISPLAY_ADS = "display_ads",
  EMAIL = "email",
  OTHER_CAMPAIGNS = "other_campaigns",
  ORGANIC_SOCIAL = "organic_social",
  ORGANIC_SEARCH = "organic_search",
  REFERRAL = "referral",
  DIRECT = "direct"
}

export const ALL_DEFAULT_CHANNELS = [
  DEFAULT_CHANNEL_TYPES.DIRECT,
  DEFAULT_CHANNEL_TYPES.REFERRAL,
  DEFAULT_CHANNEL_TYPES.ORGANIC_SEARCH,
  DEFAULT_CHANNEL_TYPES.PAID_SEARCH,
  DEFAULT_CHANNEL_TYPES.ORGANIC_SOCIAL,
  DEFAULT_CHANNEL_TYPES.PAID_SOCIAL,
  DEFAULT_CHANNEL_TYPES.EMAIL,
  DEFAULT_CHANNEL_TYPES.DISPLAY_ADS,
  DEFAULT_CHANNEL_TYPES.OTHER_CAMPAIGNS
];

export const PALE_SHADES = {
  purple: "#F7F0FD",
  pink: "#FFF0F7",
  blue: "#EFF8FF",
  green: "#F0FFF4",
  orange: "#FFF8F0",
  yellow: "#FFFAF0"
};

export const ATTRIBUTION_MODELS = {
  LINEAR: "linear",
  FIRST_TOUCH: "first_touch",
  LAST_TOUCH: "last_touch",
  TIME_DECAY: "time_decay",
  U_SHAPED: "u_shaped",
  POSITION_DECAY: "position_decay",
  FIRST_TOUCH_NON_DIRECT: "first_touch_non_direct",
  LAST_TOUCH_NON_DIRECT: "last_touch_non_direct"
};
