import axios from './JWTService';

export class SavedViewsService {
  all = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/web_analytics_saved_views/${workspaceId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  get = (workspaceId: string, savedViewId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/web_analytics_saved_views/${workspaceId}/${savedViewId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  create = (workspaceId: string, data = {}): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/web_analytics_saved_views/${workspaceId}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  update = (workspaceId: string, id: string, data = {}): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/web_analytics_saved_views/${workspaceId}/${id}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  delete = (workspaceId: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/v1/web_analytics_saved_views/${workspaceId}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
