import create from "zustand";

export interface ChannelMappingRulesProps {
  conditions: Array<ChannelMappingRuleConditionProps>;
}

interface ChannelMappingRuleConditionProps {
  /**
   * The name of the selector field i.e label_text, css_attribute, source_page_url, destination_url, element_id, element_name
   */
  selector: string;
  /**
   * The operator i.e is, is_not, contains, does_not_contain
   */
  operator: string;
  /**
   * The value of the selector
   */
  value: string;
}

interface ChannelMappingStore {
  /**
   * Channel mapping id
   */
  id: string;
  /**
   * Set channel mapping id.
   */
  setId: (id: string) => void;
  /**
   * Priority of the channel mapping
   */
  priority: number;
  /**
   * Set channel mapping priority.
   */
  setPriority: (priority: number) => void;
  /**
   * Channel Mapping name
   */
  name: string;
  /**
   * Set channel mapping name.
   */
  setName: (name: string) => void;
  /**
   * Channel Mapping rules
   */
  rules: ChannelMappingRulesProps[];
  /**
   * Set channel mapping rules
   */
  setRules: (rules: ChannelMappingRulesProps[]) => void;
  /**
   * Channel Mapping modal is open or not.
   */
  modalOpen: boolean;
  /**
   * Set channel mapping modal open or not.
   */
  setModalOpen: (modalOpen: boolean) => void;
  /**
   * Reset the form for channel mapping.
   */
  reset: () => void;
}

export const useChannelMappingStore = create<ChannelMappingStore>((set, get) => ({
  id: "",
  setId: (id: string) => set({ id }),
  priority: 0,
  setPriority: (priority: number) => set({ priority }),
  name: "",
  setName: (name: string) => set({ name }),
  /**
   * The default rules will have one condition added by default without any value.
   */
  rules: [
    {
      conditions: [
        {
          selector: "utm_source",
          operator: "is",
          value: ""
        }
      ]
    }
  ],
  setRules: (rules: ChannelMappingRulesProps[]) => set({ rules }),
  modalOpen: false,
  setModalOpen: (modalOpen) => set({ modalOpen }),
  /**
   * Reset attributes of channel mapping to their default values.
   * @returns
   */
  reset: () =>
    set({
      id: "",
      name: "",
      rules: [
        {
          conditions: [
            {
              selector: "utm_source",
              operator: "is",
              value: ""
            }
          ]
        }
      ]
    })
}));
