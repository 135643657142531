/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-redeclare */
import {
  Box,
  Button,
  Flex,
  Progress,
  Table,
  Text,
  Tooltip,
  useMantineColorScheme
} from "@mantine/core";
import React, { useCallback, useState } from "react";
import { IFunnelMeta, IFunnelStep, TAttribute } from "./FunnelDetails";
import {
  convertSecondsToReadableFormat,
  numberToCommas
} from "../../../../../lib/utils/StringUtility";
import { FUNNEL_BREAKDOWN_COLORS, LOADER_COLOR } from "../../../../../lib/utils/Constants";
import { Oval } from "react-loader-spinner";
import {
  convertFunnelAttributeToLabel,
  roundFunnelRate
} from "../../../../../lib/utils/FunnelUtility";
import { _getRowValue } from "../../../../../lib/utils/Virtualtable";
import LazyFlag from "../../../../components/Common/LazyFlag/LazyFlag";
import { getBrowserIcon, getDeviceIcon, getOSIcon } from "../../../../../lib/utils/IconsUtility";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import classes from "./BreakdownDetailTable.module.css";
export interface BreakdownDetailTable {
  /**
   * Specify the funnel step to display the breakdown
   * IFunnelStep[] in case of single level funnel and IFunnelStep[][] in case of breakdown funnel
   */
  steps: IFunnelStep[][] | IFunnelStep[];

  /**
   * Specify the callback setter function to handle the steps state update
   */
  setSteps?: (steps: IFunnelStep[][]) => void;

  /**
   * Specify the steps meta data
   */
  meta?: IFunnelMeta;

  /**
   * Specify the callback setter function to handle the meta state update
   */
  setMeta?: (meta: IFunnelMeta) => void;

  /**
   * Specify the loader state
   */
  loading: boolean;

  /**
   * Specify the selected attribute
   */
  selectedAttribute: TAttribute;

  /**
   * Specify the variant of the table
   */
  variant?: "single" | "breakdown";

  /**
   * Specify if the grouped funnel is enabled
   */
  showCompleteList?: boolean;

  /**
   * Specify the callback function to handle the grouped funnel toggle
   */
  onShowCompleteListChange?: (grouped: boolean) => void;

  /**
   * Specify if the complete list button should be hidden
   */
  hideCompleteListButton?: boolean;

  /**
   * Specify if the color of the funnel step should be same
   */
  sameColor?: boolean;
}

const BreakdownDetailTable = ({
  steps,
  setSteps,
  meta,
  setMeta,
  loading,
  selectedAttribute,
  variant = "breakdown",
  showCompleteList = false,
  onShowCompleteListChange = () => {},
  hideCompleteListButton = false,
  sameColor = false
}: BreakdownDetailTable) => {
  const { colorScheme } = useMantineColorScheme();
  // state for sort order
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const sortStepsByConversionRate = (
    steps: IFunnelStep[][] | IFunnelStep[],
    sortColumn: string,
    level: number,
    sortOrder: "asc" | "desc"
  ) => {
    setSortOrder(sortOrder);

    /**
     *    NOTE: This is a workaround to handle nested steps sorting.
     *    It is only in case of breakdown funnel and not single level funnel.
     *    The sorting is done by converting the nested steps to a single array and then sorting it.
     *    The original nested steps object order is then remapped according to the sorted single array.
     *    The meta data is also sorted according to the sorted steps. (for level 0 mapping only.)
     */

    if (Array.isArray(steps[0])) {
      // @ts-ignore
      let sortedSteps = [];
      let metaBreakdown = undefined;

      if (level === 0) {
        /**
         * If the level is 0, the meta.breakdown is sorted by the conversion rate based on sort order.
         * Then the steps are sorted based on the meta.breakdown order.
         */
        metaBreakdown = meta?.breakdown;
        metaBreakdown =
          metaBreakdown &&
          metaBreakdown.sort((a, b) => {
            if (sortOrder === "asc") {
              return a.conversion_rate - b.conversion_rate;
            } else {
              return b.conversion_rate - a.conversion_rate;
            }
          });

        // remap the original nested steps object order according to the meta.breakdown sorted order
        metaBreakdown!.map((dataObject) => {
          let stepData = _.find(steps, (innerArray) =>
            // @ts-ignore
            _.some(innerArray, (obj) => _.isEqual(obj?.breakdown_prop, dataObject.breakdown_prop))
          );

          sortedSteps.push(stepData);
        });
      } else {
        /**
         * If the level is not 0, the steps nested list is flattened and then filtered by the level.
         * Then the filtered steps are sorted by the conversion rate based on sort order.
         * Then the original nested steps object order is then remapped according to the sorted single array in above step.
         * The meta data is also sorted according to the sorted steps. (for level 0/first column conversion rate sorting only.)
         */

        // flatten the nested steps and filter by the level
        // @ts-ignore
        let levelFilteredSteps = steps.flatMap((subArray) => _.filter(subArray, { level: level }));

        // sort the levelFilteredSteps by sort column (conversion rate) and sort order
        const sortedLevelFilteredSteps = _.orderBy(levelFilteredSteps, sortColumn, sortOrder);

        // remap the original nested steps object order according to the sorted single array
        sortedLevelFilteredSteps.map((dataObject) => {
          let stepData = _.find(steps, (innerArray) =>
            // @ts-ignore
            _.some(innerArray, (obj) => _.isEqual(obj, dataObject))
          );

          sortedSteps.push(stepData);
        });

        // get the breakdown props sorted order i-e for country [US, UK, CA, Others] and for browser [Chrome, Firefox, Safari, Others
        const breakdownPropsSortedOrder = sortedLevelFilteredSteps.map(
          // @ts-ignore
          (step: any) => step?.breakdown_prop
        );

        // sort the meta breakdown by the breakdown prop sorted order
        metaBreakdown = meta?.breakdown;
        metaBreakdown &&
          metaBreakdown!.sort(
            (a, b) =>
              breakdownPropsSortedOrder.indexOf(a.breakdown_prop) -
              breakdownPropsSortedOrder.indexOf(b.breakdown_prop)
          );
      }

      /**
       * Set the state of steps and meta with the sorted steps and meta data.
       */

      // @ts-ignore
      setMeta({ ...meta, breakdown: metaBreakdown });
      // @ts-ignore
      setSteps(sortedSteps as IFunnelStep[][]);
    }
  };

  return (
    <div>
      <Box
        style={(theme) => ({
          border: "1px solid #e8e8e8",
          borderColor: colorScheme === "dark" ? theme.colors.dark[6] : "rgba(204, 204, 212, 0.31)",
          borderRadius: 4
        })}>
        {variant === "breakdown" ? (
          <Box
            style={{
              overflowX: "auto"
            }}>
            {loading ? (
              <div className="flex items-center justify-center h-64">
                <Oval color={LOADER_COLOR} height={16} width={16} />
              </div>
            ) : (
              <Table
                horizontalSpacing="sm"
                verticalSpacing="sm"
                withColumnBorders
                style={{
                  // display: "block",
                  // maxWidth: "fit-content",
                  // margin: "0 auto",
                  overflowX: "scroll",
                  whiteSpace: "nowrap"
                }}>
                <Table.Thead>
                  <Table.Tr className={classes.tableHeader}>
                    <Table.Th></Table.Th>
                    {steps.length &&
                      (steps[0] as IFunnelStep[]).map((step, index) => (
                        <Table.Th key={index}>
                          <Table.Td>
                            <Flex justify="center" align="center">
                              <Box
                                style={(theme) => ({
                                  marginRight: "12px",
                                  borderRadius: 4,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px solid",
                                  fontWeight: 600,
                                  // height: 24,
                                  // width: 24,
                                  padding: "4px 8px",
                                  fontSize: 12,
                                  color: colorScheme === "dark" ? theme.colors.dark[0] : "#595c5f",
                                  backgroundColor:
                                    colorScheme === "dark" ? theme.colors.dark[4] : "#e8e9ec",
                                  borderColor:
                                    colorScheme === "dark" ? theme.colors.dark[4] : "#e8e9ec",
                                  fontFamily: "Inter, sans-serif"
                                })}>
                                <FormattedMessage
                                  id={"insights.table.column.step"}
                                  defaultMessage={"Step"}
                                />{" "}
                                {index + 1}
                              </Box>
                              <Box>{step.name}</Box>
                            </Flex>
                          </Table.Td>
                        </Table.Th>
                      ))}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td className={classes.striped}  style={{
                      padding: 0,
                      width: '1px'
                    }}>
                      <Table className={classes.subTable}>
                        <Table.Thead>
                          <Table.Tr className={classes.tableSubHeader}>
                            <Table.Th>{convertFunnelAttributeToLabel(selectedAttribute)}</Table.Th>
                            <Table.Th ta="right">
                              <Flex align={"center"} gap={4}>
                                <FormattedMessage
                                  id={"insights.table.column.conversionRate"}
                                  defaultMessage={"Conversion Rate"}
                                />
                                <FontAwesomeIcon
                                  style={{
                                    marginTop: "2px"
                                  }}
                                  icon={regular("sort")}
                                  onClick={() =>
                                    sortStepsByConversionRate(
                                      steps,
                                      "conv_rate_prev_step",
                                      0,
                                      sortOrder === "asc" ? "desc" : "asc"
                                    )
                                  }
                                />
                              </Flex>
                            </Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                      </Table>
                    </Table.Td>
                    {steps.length &&
                      (steps[0] as IFunnelStep[]).map((step, index) => (
                        <Table.Td className={classes.striped} key={index}>
                          <Table className={classes.subTable}>
                            <Table.Thead>
                              <Table.Tr className={classes.tableSubHeader}>
                                <Table.Th>
                                  {index === 0 ? (
                                    <FormattedMessage
                                      id={"insights.table.column.entered"}
                                      defaultMessage={"Entered"}
                                    />
                                  ) : (
                                    <FormattedMessage
                                      id={"insights.table.column.completed"}
                                      defaultMessage={"Completed"}
                                    />
                                  )}
                                </Table.Th>
                                {index !== 0 && (
                                  <>
                                    <Table.Th>
                                      <FormattedMessage
                                        id={"insights.table.column.droppedOff"}
                                        defaultMessage={"Dropped Off"}
                                      />
                                    </Table.Th>
                                    <Table.Th ta="right">
                                      <Flex align={"center"} gap={4}>
                                        <FormattedMessage
                                          id={"insights.table.column.conversionRate"}
                                          defaultMessage={"Conversion Rate"}
                                        />
                                        <FontAwesomeIcon
                                          style={{
                                            marginTop: "2px"
                                          }}
                                          icon={regular("sort")}
                                          onClick={() =>
                                            sortStepsByConversionRate(
                                              steps,
                                              "conv_rate_prev_step",
                                              step.level!,
                                              sortOrder === "asc" ? "desc" : "asc"
                                            )
                                          }
                                        />
                                      </Flex>
                                    </Table.Th>
                                    {index !== 1 && (
                                      <Table.Th ta="right">
                                        <FormattedMessage
                                          id={"insights.table.column.conversionRateFromFirstStep"}
                                          defaultMessage={"Conversion Rate from First Step"}
                                        />
                                      </Table.Th>
                                    )}
                                    <Table.Th ta="right">
                                      <FormattedMessage
                                        id={"insights.table.column.avgTime"}
                                        defaultMessage={"Average Time"}
                                      />
                                    </Table.Th>
                                  </>
                                )}
                              </Table.Tr>
                            </Table.Thead>
                          </Table>
                        </Table.Td>
                      ))}
                  </Table.Tr>

                  <TableRows
                    rows={steps}
                    variant={variant}
                    meta={meta as unknown as IFunnelMeta}
                    selectedAttribute={selectedAttribute}
                    sameColor={sameColor}
                  />
                </Table.Tbody>
              </Table>
            )}
          </Box>
        ) : (
          <Box
            style={{
              maxHeight: "500px",
              overflow: "auto"
            }}>
            {loading ? (
              <div className="flex items-center justify-center h-64">
                <Oval color={LOADER_COLOR} height={16} width={16} />
              </div>
            ) : (
              <Table
                horizontalSpacing="sm"
                verticalSpacing="sm"
                withColumnBorders
                className={classes.scrollContainer}>
                <Table.Thead>
                  <Table.Tr className={classes.tableHeader}>
                    <Table.Th></Table.Th>
                    {steps.length &&
                      (steps as IFunnelStep[]).map((step, index) => (
                        <Table.Th key={index}>
                          <Flex align={"center"}>
                            <Box
                              style={(theme) => ({
                                marginRight: "12px",
                                borderRadius: 4,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid",
                                fontWeight: 700,
                                // height: 24,
                                // width: 24,
                                padding: "4px 8px",
                                fontSize: 12,
                                color: colorScheme === "dark" ? theme.colors.dark[0] : "#595c5f",
                                backgroundColor:
                                  colorScheme === "dark" ? theme.colors.dark[4] : "#e8e9ec",
                                borderColor:
                                  colorScheme === "dark" ? theme.colors.dark[4] : "#e8e9ec",
                                fontFamily: "Inter, sans-serif"
                              })}>
                              <FormattedMessage
                                id={"insights.table.column.step"}
                                defaultMessage={"Step"}
                              />{" "}
                              {index + 1}
                            </Box>
                            <Text fw={600} size="xs">
                              {step.name}
                            </Text>
                          </Flex>
                        </Table.Th>
                      ))}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td className={classes.striped}>
                      <Table className={classes.subTable}>
                        <Table.Thead>
                          <Table.Tr className={classes.tableSubHeader}>
                            {/* <th>Name</th> */}
                            <Table.Th ta="right">
                              <FormattedMessage
                                id={"insights.table.column.conversionRate"}
                                defaultMessage={"Conversion Rate"}
                              />
                            </Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                      </Table>
                    </Table.Td>
                    {steps.length &&
                      (steps as IFunnelStep[]).map((step, index) => (
                        <Table.Td className={classes.striped} key={index}>
                          <Table className={classes.subTable}>
                            <Table.Thead>
                              <Table.Tr className={classes.tableSubHeader}>
                                <Table.Th>
                                  {index === 0 ? (
                                    <FormattedMessage
                                      id={"insights.table.column.entered"}
                                      defaultMessage={"Entered"}
                                    />
                                  ) : (
                                    <FormattedMessage
                                      id={"insights.table.column.completed"}
                                      defaultMessage={"Completed"}
                                    />
                                  )}
                                </Table.Th>
                                {index !== 0 && (
                                  <>
                                    <Table.Th>
                                      <FormattedMessage
                                        id={"insights.table.column.droppedOff"}
                                        defaultMessage={"Dropped Off"}
                                      />
                                    </Table.Th>
                                    <Table.Th ta="right">
                                      <FormattedMessage
                                        id={"insights.table.column.conversionRate"}
                                        defaultMessage={"Conversion Rate"}
                                      />
                                    </Table.Th>
                                    {index !== 1 && (
                                      <Table.Th ta="right">
                                        <FormattedMessage
                                          id={"insights.table.column.conversionRateFromFirstStep"}
                                          defaultMessage={"Conversion Rate from First Step"}
                                        />
                                      </Table.Th>
                                    )}

                                    <Table.Th ta="right">
                                      <FormattedMessage
                                        id={"insights.table.column.avgTime"}
                                        defaultMessage={"Average Time"}
                                      />
                                    </Table.Th>
                                  </>
                                )}
                              </Table.Tr>
                            </Table.Thead>
                          </Table>
                        </Table.Td>
                      ))}
                  </Table.Tr>

                  <TableRows
                    rows={steps}
                    variant={variant}
                    meta={meta as unknown as IFunnelMeta}
                    sameColor={sameColor}
                  />
                </Table.Tbody>
              </Table>
            )}
          </Box>
        )}
      </Box>
      {!hideCompleteListButton &&
        selectedAttribute !== "parsed_device" &&
        selectedAttribute !== "channel" && (
          <Flex justify={"flex-start"} mt={"lg"} mb={"xl"}>
            {variant === "breakdown" && !loading && (
              <Button
                variant={"light"}
                size={"xs"}
                onClick={() => {
                  onShowCompleteListChange(!showCompleteList);
                }}>
                {showCompleteList ? "Show Top 5 Results" : "Show Complete List"}
              </Button>
            )}
          </Flex>
        )}
    </div>
  );
};

const TableRows = React.memo(
  ({
    rows,
    meta,
    variant = "breakdown",
    selectedAttribute = "none",
    sameColor = false
  }: {
    rows: IFunnelStep[][] | IFunnelStep[];
    meta?: IFunnelMeta;
    variant: "breakdown" | "single";
    selectedAttribute?: TAttribute;
    sameColor?: boolean;
  }) => {
    const { colorScheme } = useMantineColorScheme();
    const resolveMetaValue = useCallback(
      (index: number) => {
        return meta && meta?.breakdown && meta?.breakdown[index]
          ? roundFunnelRate(meta?.breakdown[index].conversion_rate || 0)
          : 0;
      },
      [meta]
    );

    /**
     * Render the left cell content
     */
    const renderLeftCellContent = (step: IFunnelStep, index: number) => {
      if (selectedAttribute === "period") {
        return (
          <Box ml={'xs'}>
            <Text size={'xs'}>{meta?.breakdown && meta?.breakdown[index].breakdown_value}</Text>
          </Box>
        );
      }

      if (selectedAttribute === "location_city" && !step.breakdown_prop) {
        return (
          <Box ml={'xs'}>
            <Text size={'xs'}>Unknown</Text>
          </Box>
        );
      }

      if (selectedAttribute === "location_country" && step.breakdown_prop) {
        const name = _getRowValue("location_country", step.breakdown_prop) || "Others";

        return (
          <Flex align={'center'}>
            {name !== "Others" ? (
              <LazyFlag code={step.breakdown_prop as string} width={24} height={24} />
            ) : (
              <Box
                style={(theme) => ({
                  width: 24,
                  height: 18,
                  borderRadius: 2,
                  backgroundColor: colorScheme === "dark" ? theme.colors.dark[4] : "#e8e9ec"
                })}></Box>
            )}

            <Box ml={'xs'}>
              <Text size={'xs'}>{name}</Text>
            </Box>
          </Flex>
        );
      }

      if (
        (selectedAttribute === "parsed_ua_ua_family" ||
          selectedAttribute === "parsed_ua_os_family" ||
          selectedAttribute === "parsed_device") &&
        step.breakdown_prop
      ) {
        return (
          <Flex align={"center"}>
            <Box
              style={(theme) => ({
                width: 24,
                height: 18,
                borderRadius: 2,
                color: colorScheme === "dark" ? theme.colors.dark[0] : "#6b7280",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              })}>
              {selectedAttribute === "parsed_ua_ua_family" &&
                getBrowserIcon(step.breakdown_prop as string)}
              {selectedAttribute === "parsed_ua_os_family" &&
                getOSIcon(step.breakdown_prop as string)}
              {selectedAttribute === "parsed_device" &&
                getDeviceIcon(step.breakdown_prop as string)}
            </Box>

            <Box ml={'xs'}>
              <Text size={'xs'}>{step.breakdown_prop}</Text>
            </Box>
          </Flex>
        );
      }

      if (selectedAttribute === "source" || selectedAttribute === "referer") {
        return (
          <Tooltip
            label={<Text tt="none">{step.breakdown_prop}</Text>}
            position={"top-start"}
            offset={-26}>

            <Box ml={'xs'}>
              <Text size={'xs'} lineClamp={1} tt="none">
                {step.breakdown_prop}
              </Text>
            </Box>
          </Tooltip>
        );
      }

      return (
      <Box ml={'xs'}>
        <Text size={'xs'}>
          {step.breakdown_prop && step.breakdown_prop.replace(/_/g, " ")}
        </Text>
      </Box>
      );
    };

    if (variant === "breakdown") {
      return (
        <>
          {(rows as IFunnelStep[][]).map((steps, index) => {
            if (steps.length === 0) {
              return null;
            }

            return (
              <Table.Tr
                key={index}
                className={classes.breakdownRow}
                style={{
                  ["--main-color" as string]: sameColor
                    ? FUNNEL_BREAKDOWN_COLORS[0]
                    : !FUNNEL_BREAKDOWN_COLORS[index] || steps[0].breakdown_prop === "Others"
                    ? FUNNEL_BREAKDOWN_COLORS[5]
                    : FUNNEL_BREAKDOWN_COLORS[index]
                }}>
                <Table.Td className={classes.breakdownIndicator}>
                  <Table className={classes.subTable}>
                    <Table.Tbody>
                      <Table.Tr className={classes.tableSubHeader}>
                        <Table.Td>{renderLeftCellContent(steps[0], index)}</Table.Td>
                        <Table.Td ta="right">
                          <Flex align={"center"}>
                            <Progress
                              ml={16}
                              w={"100%"}
                              styles={(theme) => ({
                                label: {
                                  fontSize: "7px"
                                }
                              })}
                              radius={"sm"}
                              size="md"
                              value={resolveMetaValue(index)}
                            />
                            <Box pl={4} w={96} ta="right" fw={600} fz={11}>
                              {resolveMetaValue(index)}%
                            </Box>
                          </Flex>
                        </Table.Td>
                      </Table.Tr>
                    </Table.Tbody>
                  </Table>
                </Table.Td>
                {steps.map((step, dataIndex) => (
                  <Table.Td key={dataIndex}>
                    <Table className={classes.subTable}>
                      <Table.Tbody>
                        <Table.Tr className={classes.tableSubHeader}>
                          <Table.Td className={classes.colored}>
                            {numberToCommas(step.visitors)}
                          </Table.Td>
                          {dataIndex !== 0 && (
                            <>
                              <Table.Td className={classes.colored}>
                                {step.drop_off_count_prev_step && step.drop_off_count_prev_step > 0
                                  ? numberToCommas(step.drop_off_count_prev_step)
                                  : "0"}
                              </Table.Td>
                              <Table.Td ta="right">
                                <Flex align={"center"}>
                                  <Progress
                                    w={"100%"}
                                    styles={(theme) => ({
                                      label: {
                                        fontSize: "7px"
                                      }
                                    })}
                                    ml={16}
                                    radius={"sm"}
                                    size="md"
                                    value={
                                      step.conv_rate_prev_step
                                        ? step.conv_rate_prev_step
                                        : dataIndex === 0 && step.conv_rate_prev_step === null
                                        ? 100
                                        : 0
                                    }
                                  />
                                  <Box pl={4} w={96} ta="right" fz={11} fw={600}>
                                    {step.conv_rate_prev_step
                                      ? step.conv_rate_prev_step + "%"
                                      : dataIndex === 0 && step.conv_rate_prev_step === null
                                      ? "100%"
                                      : "0%"}
                                  </Box>
                                </Flex>
                              </Table.Td>
                              {dataIndex !== 1 && (
                                <Table.Td ta="right">
                                  <Flex align={"center"}>
                                    <Progress
                                      w={"100%"}
                                      styles={(theme) => ({
                                        label: {
                                          fontSize: "7px"
                                        }
                                      })}
                                      ml={16}
                                      color={"green.7"}
                                      radius={"sm"}
                                      size="md"
                                      value={roundFunnelRate(step.conv_rate_from_first_step || 0)}
                                    />
                                    <Box w={112} pl={4} ta={"right"} fz={11} fw={600}>
                                      {roundFunnelRate(step.conv_rate_from_first_step || 0)}%
                                    </Box>
                                  </Flex>
                                </Table.Td>
                              )}

                              <Table.Td ta="right">
                                {step.avg_time_in_s
                                  ? convertSecondsToReadableFormat(step.avg_time_in_s)
                                  : "-"}
                              </Table.Td>
                            </>
                          )}
                        </Table.Tr>
                      </Table.Tbody>
                    </Table>
                  </Table.Td>
                ))}
              </Table.Tr>
            );
          })}
        </>
      );
    }

    return (
      <Table.Tr
        className={classes.breakdownRow}
        style={{
          ["--main-color" as string]: FUNNEL_BREAKDOWN_COLORS[0]
        }}>
        <Table.Td className={classes.breakdownIndicator}>
          <Table className={classes.subTable}>
            <Table.Tbody>
              <Table.Tr className={classes.tableSubHeader}>
                {/* <td>{meta?.funnel_name || "Funnel"}</td> */}
                <Table.Td>
                  <Flex align={"center"}>
                    <Progress
                      ml={16}
                      w={"100%"}
                      styles={(theme) => ({
                        label: {
                          fontSize: "7px"
                        }
                      })}
                      radius={"sm"}
                      size="md"
                      value={roundFunnelRate(meta?.conversion_rate || 0)}
                    />
                    <Box pl={4} w={112} ta={"right"} fz={12}>
                      {roundFunnelRate(meta?.conversion_rate || 0)}%
                    </Box>
                  </Flex>
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Table.Td>
        {(rows as IFunnelStep[]).map((step, dataIndex) => (
          <Table.Td key={dataIndex}>
            <Table className={classes.subTable}>
              <Table.Tbody>
                <Table.Tr className={classes.tableSubHeader}>
                  <Table.Td className={classes.colored}>{numberToCommas(step.visitors)}</Table.Td>
                  {dataIndex !== 0 && (
                    <>
                      <Table.Td className={classes.colored}>
                        {step.drop_off_count_prev_step && step.drop_off_count_prev_step > 0
                          ? numberToCommas(step.drop_off_count_prev_step)
                          : "0"}
                      </Table.Td>
                      <Table.Td ta="right">
                        <Flex align={"center"}>
                          <Progress
                            ml={16}
                            w={"100%"}
                            styles={(theme) => ({
                              label: {
                                fontSize: "7px"
                              }
                            })}
                            radius={"sm"}
                            size="md"
                            value={
                              step.conv_rate_prev_step
                                ? step.conv_rate_prev_step
                                : dataIndex === 0 && step.conv_rate_prev_step === null
                                ? 100
                                : 0
                            }
                          />
                          <Box pl={4} w={112} ta={"right"} fz={12}>
                            {step.conv_rate_prev_step
                              ? step.conv_rate_prev_step + "%"
                              : dataIndex === 0 && step.conv_rate_prev_step === null
                              ? "100%"
                              : "0%"}
                          </Box>
                        </Flex>
                      </Table.Td>
                      {dataIndex !== 1 && (
                        <Table.Td ta={'right'}>
                          <Flex align={"center"}>
                            <Progress
                              ml={16}
                              w={"100%"}
                              styles={(theme) => ({
                                label: {
                                  fontSize: "7px"
                                }
                              })}
                              radius={"sm"}
                              size="md"
                              color="green.7"
                              value={roundFunnelRate(step.conv_rate_from_first_step || 0)}
                            />
                            <Box pl={4} w={112} ta="right" fz={12}>
                              {roundFunnelRate(step.conv_rate_from_first_step || 0)}%
                            </Box>
                          </Flex>
                        </Table.Td>
                      )}

                      <Table.Td ta="right">
                        {step.avg_time_in_s
                          ? convertSecondsToReadableFormat(step.avg_time_in_s)
                          : "-"}
                      </Table.Td>
                    </>
                  )}
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </Table.Td>
        ))}
      </Table.Tr>
    );
  }
);

export default BreakdownDetailTable;
