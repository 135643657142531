/**
 * Move an array element from one array position to another.
 * @param arr
 * @param fromIndex
 * @param toIndex
 */
export const arrayMove = (arr: any[], fromIndex: number, toIndex: number) => {
  // clone array to avoid mutating the original array
  const array = [...arr];

  let element = array[fromIndex];
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, element);

  return array;
};
