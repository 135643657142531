import {
  format,
  formatDistance,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  parseISO
} from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import { enGB } from "date-fns/locale";

export const getRelativeDate = (date: string) => {
  if (date.includes(" ")) {
    date = date.replace(" ", "T");
  }
  try {
    return formatDistance(new Date(date), new Date(), { addSuffix: true });
  } catch (ex) {
    return "...";
  }
};

export const getRelativeDateToNow = (date: string) => {
  if (date.includes(" ")) {
    date = date.replace(" ", "T");
  }
  try {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  } catch (ex) {
    return "...";
  }
};

export const getRelativeDateToNowStrict = (date: string) => {
  if (date.includes(" ")) {
    date = date.replace(" ", "T");
  }
  try {
    return formatDistanceToNowStrict(new Date(date));
  } catch (ex) {
    return "...";
  }
};

export const formatRelativeLocale: any = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: "dd.MM.yyyy"
};

export const locale = {
  ...enGB,
  formatRelative: (token: string) => formatRelativeLocale[token]
};

export const getIntlDateAndTime = (date: string, includeHour = false) => {
  return Intl.DateTimeFormat("en-us", {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: includeHour ? "numeric" : undefined
  }).format(new Date(date));
};

export const getIntlDateWithoutWeekday = (date: string) => {
  return Intl.DateTimeFormat("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric"
  }).format(new Date(date.replace(" ", "T")));
};

export const convertDateToTimezone = (
  date: any,
  timezone: any = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  // NOTE: reference link https://stackoverflow.com/a/63227335
  // We are using date-fns-tz for converting the time to appropriate timezone.

  let parsedTime = parseISO(date);
  let timeZoneDate = toZonedTime(parsedTime, timezone);

  return formatDistance(fromZonedTime(timeZoneDate, "UTC"), toZonedTime(new Date(), timezone), {
    addSuffix: true
  });
};

export const convertTZ = (date: any, tzString: string) => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString
    })
  );
};

export const utcToRelativeTimezone = (date: any, removeExtraWords = false) => {
  try {
    const localTime = fromZonedTime(new Date(date.replace(" ", "T")), "UTC");
    const finalDate = formatDistance(localTime, new Date(), {
      addSuffix: true
    });

    if (removeExtraWords) {
      return finalDate
        .replace("about ", "")
        .replace("almost ", "")
        .replace("less than ", "")
        .replace("over ", "");
    }
    return finalDate;
  } catch (ex) {
    return "...";
  }
};

export const utcToTime = (date: any) => {
  const localTime = fromZonedTime(new Date(date.replace(" ", "T")), "UTC");
  return format(localTime, "HH:mm:ss");
};

export const ClickhouseDateToLocalRelativeTime = (date: string) => {
  const localTime = fromZonedTime(new Date(date.replace(" ", "T")), "UTC");
  return formatDistance(localTime, new Date(), {
    addSuffix: true
  });
};

/**
 * Method to convert seconds to time in format e.g. 1 minute 30 seconds
 * To achieve this we will use date-fns library.
 * @param seconds
 * @returns {string}
 */
export const secondsToDuration = (seconds: number) =>
  formatDistance(0, seconds * 1000, { includeSeconds: true });

export const formatToMidnightUTC = (dateString: string) => {
  if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(dateString)) {
    // If not in the expected format, append "T00:00:00.000Z"
    dateString += "T00:00:00.000Z";
  }
  return dateString;
};
