import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Autocomplete,
  Badge,
  Box,
  Flex,
  Select,
  TextInput,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import DragIcon from "@assets/images/icons/dots.svg";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import { ConditionItem } from "types/types.d";
import { eventOptions, pageviewOptions, pinnedEventValueOptions } from "./InsightConditionsUtils";
import { InsightsService } from "@/lib/services/InsightsService";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface InsightConditionProps {
  id: number;
  index: number;
  conditionType: string;
  item: ConditionItem;
  attributesOptions: Array<any>;
  eventsOptions?: Array<any>;
  options?: Array<{
    label: string;
    value: string;
  }>;
  nameIdentifier?: string;
  moveCondition: (dragIndex: number, hoverIndex: number) => void;
  handleItemSelectChange: (e: any, index: number) => void;
  handleKeyChange: (e: any, index: number) => void;
  handleValueChange: (e: any, index: number) => void;
  handleAliasChange: (e: any, index: number) => void;
  handleOperatorChange: (e: any, index: number) => void;
  handleRemoveCondition: (index: number) => void;
  filteredEventOptions: () => any;
  pinnedEventOptions: () => any;
}

interface DragConditionItem extends ConditionItem {
  index: number;
}

const style = {
  //   border: "1px dashed gray",
  padding: "0.75rem 1rem",
  //   paddingLeft: "1rem",
  // marginBottom: ".5rem",
  // backgroundColor: "white",
  cursor: "move"
};

export const InsightCondition: FC<InsightConditionProps> = ({
  id,
  index,
  item,
  conditionType = "or",
  attributesOptions,
  eventsOptions = [],
  options = [
    {
      label: "Page URL",
      value: "pageview"
    },
    { label: "Custom Event", value: "event" }
  ],
  nameIdentifier = "condition",
  moveCondition,
  handleItemSelectChange,
  handleKeyChange,
  filteredEventOptions,
  pinnedEventOptions,
  handleOperatorChange,
  handleValueChange,
  handleAliasChange,
  handleRemoveCondition
}) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [pageViewSelectSuggestions, setPageViewSelectSuggestions] = useState<any>([]);
  /**
   * This is the state to get the suggested options for the auto-complete.
   */
  const [debounced] = useDebouncedValue(item.value, 200);
  const [activePageViewSuggestionsIndex, setActivePageViewSuggestionsIndex] = useState<number>(-1);
  const ref = useRef<HTMLDivElement>(null);

  const [collectedProps, drop] = useDrop<any>({
    accept: nameIdentifier,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver()
      };
    },

    hover(item: DragConditionItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCondition(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const { handlerId, isOver } = collectedProps as {
    handlerId: string | undefined;
    isOver: boolean;
  };

  const [{ isDragging }, drag] = useDrag({
    type: nameIdentifier,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0.7 : 1;
  drag(drop(ref));

  const closeDropdown = () => {
    setActivePageViewSuggestionsIndex(-1);
  };
  const innerPageViewRef = useDetectClickOutside({
    onTriggered: closeDropdown
  });

  const handlePageViewSuggestions = (query: string, typeOfEvent: string) => {
    new InsightsService()
      .suggestions(activeWorkspace.id, "page_url", query)
      .then((res) => {
        setPageViewSelectSuggestions(res.data);
      })
      .catch((err) => {
        setPageViewSelectSuggestions([]);
      });
  };

  useEffect(() => {
    if (item.type === "pageview") {
      handlePageViewSuggestions(debounced, item.type);
    } else {
      setPageViewSelectSuggestions([]);
    }
  }, [debounced, item.type]);

  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <div
        className="um-insights-setup-events__filters-sorting um-insights-conditions-draggable"
        key={`insight-condition-${index}`}>
        {index > 0 && (
          <Box ml="xl">
            {conditionType === "or" && (
              <>
                <Badge
                  className="ml-8"
                  radius={"sm"}
                  autoContrast
                  color={colorScheme === "dark" ? "dark.5" : "gray.3"}
                  variant="filled">
                  OR
                </Badge>
                {/* <span className="um-insights-setup-events__or"></span> */}
              </>
            )}
            {conditionType === "funnel" && (
              <>
                <Badge
                  className="ml-8"
                  radius={"sm"}
                  autoContrast
                  color={colorScheme === "dark" ? "dark.5" : "gray.3"}
                  variant="filled">
                  <FontAwesomeIcon icon={regular("down")}></FontAwesomeIcon>
                </Badge>
              </>
            )}
          </Box>
        )}

        <Flex
          align={"center"}
          ref={ref}
          style={{
            ...style,
            opacity,
            borderTop: isOver ? "0.25rem solid #9f72fc" : "0.25rem solid transparent"
          }}
          data-handler-id={handlerId}
          // sx={(theme) => ({
          //   [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
          //     flexWrap: "wrap",
          //     gap: 10,

          //     ".um-insights-setup-events__filters-item-drag": {
          //       display: "none"
          //     },

          //     ".um-insights-setup-events__filters-item-select": {
          //       width: "100%",
          //       marginRight: "0",
          //       marginLeft: "0",

          //       ".usrmvn-Select-root": {
          //         width: "100%"
          //       }
          //     },

          //     "usrmvn-Select-wrapper": {
          //       width: "100%"
          //     },

          //     ".um-error-field": {
          //       position: "relative"
          //     },

          //     ".um-insights-setup-events__filters-input-text": {
          //       marginLeft: "0 !important"
          //     }
          //   }
          // })}
        >
          <Box mr="sm" mt={6} ml="md">
            <img src={DragIcon} alt="" />
          </Box>
          <Flex align={"center"}>
            <Select
              miw={160}
              w={160}
              mr="sm"
              value={item.type}
              onChange={(e) =>
                handleItemSelectChange(
                  {
                    target: {
                      value: e
                    }
                  },
                  index
                )
              }
              data={options}
              placeholder="Select"
            />
            {item.type !== "pageview" && (
              <Box pos="relative">
                {item.type === "attribute" && (
                  <>
                    <Select
                      w={170}
                      error={item.key.length === 0}
                      onChange={(e: any) => {
                        handleKeyChange(
                          attributesOptions.find((i) => i.value === e),
                          index
                        );
                      }}
                      data={attributesOptions}
                      key={item.key}></Select>
                  </>
                )}
                {item.type === "event" && (
                  <>
                    <Select
                      w={170}
                      error={item.key.length === 0}
                      searchable
                      onChange={(e: any) => {
                        handleKeyChange(
                          filteredEventOptions().find((i: any) => i.value === e),
                          index
                        );
                      }}
                      value={item.key}
                      data={filteredEventOptions()}
                      key={item.key}
                      data-cy="insight-condition-custom-event-type"></Select>
                  </>
                )}
                {item.type === "pinned_event" && (
                  <>
                    <Select
                      w={170}
                      error={item.key.length === 0}
                      className="w-64"
                      searchable
                      onChange={(e: any) => {
                        handleKeyChange(
                          pinnedEventOptions().find((i: any) => i.value === e),
                          index
                        );
                      }}
                      data={pinnedEventOptions()}
                      key={item.key}
                      value={item.key}></Select>
                  </>
                )}
                {item.key.length === 0 && (
                  <Badge size="xs" color="red.6" pos={"absolute"} mt={4}>
                    can't be blank
                  </Badge>
                )}
              </Box>
            )}

            {/* Select based on the field. */}
            <div
              className={`um-insights-setup-events__filters-item-select ${
                item.type !== "pageview" ? "ml-4" : ""
              }`}>
              <Select
                w={200}
                value={item.operator}
                data={
                  item.type === "event"
                    ? eventOptions
                    : item.type === "pinned_event"
                    ? pinnedEventValueOptions
                    : pageviewOptions // item.type === "pageview"
                }
                onChange={(e: any) => {
                  handleOperatorChange(
                    {
                      target: {
                        value: e
                      }
                    },
                    index
                  );
                }}
              />
            </div>
          </Flex>
          <Flex>
            {/* Input value field */}
            {item.type !== "event" &&
              item.type !== "pinned_event" &&
              item.operator !== "exists" &&
              item.operator !== "!exists" && (
                <>
                  <Autocomplete
                    maw={350}
                    w={350}
                    // w={"100%"}
                    data={pageViewSelectSuggestions}
                    placeholder={
                      item.operator === "matches_pattern"
                        ? "https://www.example.com/*/products/*"
                        : item.operator === "matches_regex"
                        ? "https://www\\.example\\.com/(product|category)/[0-9]+"
                        : "Enter your page URL"
                    }
                    defaultValue={item?.value || ""}
                    key={`autocomplete-insight-condition-select-${item}`}
                    id={`autocomplete-insight-condition-select-${item}`}
                    limit={25}
                    className="w-full"
                    maxDropdownHeight={190}
                    value={item.value}
                    onChange={(value: string) => {
                      handleValueChange(value, index);
                    }}
                    data-cy="insight-condition-suggestion"
                  />
                </>
              )}
            {/* Alias (Optional) */}

            {nameIdentifier === "conversionFunnel" && (
              <div
                className={`um-insights-setup-events__filters-input-text ${
                  item.type === "pageview" ? "ml-4" : ""
                }`}>
                <TextInput
                  miw={120}
                  placeholder="Alias (Optional)"
                  maxLength={25}
                  value={item.alias}
                  onChange={(event: any) => {
                    handleAliasChange(event.currentTarget.value, index);
                  }}
                />
              </div>
            )}
          </Flex>

          {/* Remove item from the list */}

          <div className="um-insights-setup-events__filters-item-remove">
            <ActionIcon
              variant="outline"
              color={"red"}
              onClick={() => {
                handleRemoveCondition(index);
              }}>
              <FontAwesomeIcon icon={regular("trash")} className=" text-red-500 transform " />
            </ActionIcon>
          </div>
        </Flex>
      </div>
    </>
  );
};
