/* eslint-disable react-hooks/exhaustive-deps */
import { getReadableFormatNumber, numberToCommas } from "@/lib/utils/StringUtility";
import React from "react";
import { LoadingStateProps } from "@/types/types.d";
import { SummaryComparisonCard } from "../../../Cards/SummaryComparisonCard";

type Props = {
  loading: LoadingStateProps;
  comparisonData: any;
};

const UniqueConversionsComparison = ({ loading, comparisonData }: Props) => {
  return (
    <div data-cy="events-card">
      <SummaryComparisonCard
        uniquekey={"UniqueConversions"}
        loading={loading}
        comparisonValue={getReadableFormatNumber(comparisonData?.unique_conversions)}
        comparisonTooltip={`${numberToCommas(
          comparisonData.unique_conversions
        )} Unique Conversions`}
      />
    </div>
  );
};
const UniqueConversionsComparisonMemoized = React.memo(UniqueConversionsComparison);
export { UniqueConversionsComparisonMemoized as UniqueConversionsComparison };
