import { Accordion, Anchor, Avatar, Box, Flex, Text, Title } from "@mantine/core";
// import { Prism } from "@mantine/prism";
// import duotoneDark from "prism-react-renderer/themes/dracula";
// import githubTheme from "prism-react-renderer/themes/github";
import { FC, useContext } from "react";
import { GeneralAPIKeyAndServerToken } from "@/ui/pages/Settings/WorkspaceSettings/General/GeneralAPIKeyAndServerToken";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CodeHighlight } from "@mantine/code-highlight";

type IATrackingPixelPythonGuideProps = {
  connectedDataSource?: boolean;
  identifiedUsers?: boolean;
  trackedBehaviors?: boolean;
};

export const TrackingPixelPythonGuide: FC<IATrackingPixelPythonGuideProps> = ({
  connectedDataSource = false,
  identifiedUsers = false,
  trackedBehaviors = false
}) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const location = useLocation();
  const isInsightsDashboard = location.pathname.includes("/insights/dashboard");

  const defaultOpened = !isInsightsDashboard
    ? "install_pixel" // default to install pixel
    : connectedDataSource
    ? identifiedUsers
      ? trackedBehaviors
        ? "" // all done - all panels closed
        : "custom_events" // only custom events left
      : "user_identify" // first step done - second panel open
    : "install_pixel"; // nothing done - first panel open

  return (
    <>
      <Title order={4} my="md">
        Credentials
      </Title>
      <GeneralAPIKeyAndServerToken withBorder={true} withHeader={false} />
      <Accordion mt={32} variant="separated" defaultValue={defaultOpened}>
        <Accordion.Item value="install_sdk">
          <Accordion.Control>
            <Flex align="center">
              {connectedDataSource ? (
                <Avatar size="md" color="teal.7" radius="xl" mr={"sm"}>
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" className="mr-4 font-bold">
                  1
                </Avatar>
              )}
              <Text className="flex-grow" fz="md" fw="bold">
                {isInsightsDashboard ? "Connect a data source" : "Install Usermaven SDK"}
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Text className="py-4" fz="sm" mb="sm">
              To collect events from your Python application with Usermaven, first install usermaven
              python SDK.
            </Text>
            <CodeHighlight language="python" code={`pip3 install usermaven`} mb="sm" />

            <Text className="py-4" fz="sm">
              {" "}
              Next, see our example code for{" "}
              <a
                className="font-bold primary-link-color"
                href={"https://github.com/usermaven/usermaven-python"}
                target="_blank">
                Python application.
              </a>
            </Text>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="user_identify">
          <Accordion.Control>
            <Flex align="center">
              {identifiedUsers ? (
                <Avatar size="md" color="teal.7" radius="xl" mr={"sm"}>
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" className="mr-4 font-bold">
                  2
                </Avatar>
              )}
              <Text className="flex-grow" fz="md" fw="bold">
                {isInsightsDashboard
                  ? "Send users and companies attributes"
                  : "Send users and companies attributes (For SaaS product insights)"}{" "}
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Text className="py-4" fz="sm" mb={"sm"}>
              First, make a client object using your workspace credentials.
              <span className="font-bold"> Next, use our identify function</span> to send us
              customer data.
            </Text>
            <CodeHighlight
              fz={"sm"}
              language="python"
              code={`
  client = Client(api_key="${activeWorkspace.identifier}", server_token="${activeWorkspace?.server_token}")

client.identify(
     user={
      # Required attributes of user object
      "id": "lzL24K3kYw",    # Unique ID for the user in database.
      "email": "user@domain.com", # Email address for the user.
      "created_at": "2021-01-20T09:55:35",   # DateTime string in your system that represents when the user first signed up.
    
      # Recommended attributes
    
      # First name and last name are shown on users pages.
      "first_name": 'John',       
      "last_name": 'Smith',
      
       # Optional attributes (you can name attributes what you wish)
      "custom": {
           "plan_name": "premium",
       }       
      },

     # If your product is used by multiple users in a company, we recommend to pass the Company attributes.
     company={
        # Required Attributes of company object
        "id": "uPq9oUGrIt", # Company ID in your database
        "name": "Usermaven", # Company Name in your database.
        "created_at": "2021-01-20T09:55:35",   # DateTime string in your system that represents when the company first signed up.
      
        # Optional attributes such as industry, website, employee count etc.
        custom: {
          "plan": "enterprise",
          "industry": "Technology", 
          "website": "https://usermaven.com", 
          "employees": 20
        }
      }
    );
  `}
              mb="sm"
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="custom_events">
          <Accordion.Control>
            <Flex align="center">
              {trackedBehaviors ? (
                <Avatar size="md" color="teal.7" radius="xl" mr={"sm"}>
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" className="mr-4 font-bold">
                  3{" "}
                </Avatar>
              )}
              <Text className="flex-grow" fz="md" fw="bold">
                {isInsightsDashboard
                  ? "Track behaviours"
                  : "Send important events (For SaaS product insights)"}
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Text className="py-4" fz="sm" mb="sm">
              Usermaven allows you to track user behaviours by using Pinned Events as well as have
              an option to send the custom events.
            </Text>
            <Flex mb={16} ml={16}>
              <Avatar variant="outline" size="sm" color="brand.5" radius="xl">
                1
              </Avatar>
              <Box ml={16}>
                <Title order={5} mb={"sm"}>
                  Pinned Events
                </Title>
                <Text fz="sm">
                  You can create your Pinned events from{" "}
                  <NavLink to={`/env/${activeWorkspace.identifier}/events/pinned-events`}>
                    here
                  </NavLink>
                  .{" "}
                  <Anchor
                    fz="sm"
                    href="https://usermaven.com/docs/getting-started/creating-pinned-events"
                    target="_blank">
                    Learn more about Pinned Events.
                  </Anchor>
                </Text>
              </Box>
            </Flex>
            <Flex ml={16}>
              <Avatar variant="outline" size="sm" color="brand.5" radius="xl">
                2
              </Avatar>
              <Box ml={16}>
                <Title order={5} mb="sm">
                  Custom Events
                </Title>
                <Box>
                  <Text className="py-4" fz="sm" mb="sm">
                    Start sending your important events such as{" "}
                    <span className="font-bold">"signed_up", "book-a-demo"</span> etc.
                  </Text>
                  <CodeHighlight
                    language="python"
                    code={`
  client.track( 
  user_id="lzL24K3kYw", # Unique ID for the user in database. (Required)
  event_type="plan_purchased", # Event name to be tracked (Required)
  
  # Optional attributes
  event_attributes={ 
    "plan_name": "premium",
    "plan_price": 100,
    "plan_currency": "USD"
    }
  )`}
                  />
                </Box>
              </Box>
            </Flex>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
