import { createStyles } from "@mantine/emotion";
import { MantineColorScheme } from "@mantine/core";

const useStyles = createStyles((theme) => {
  return ({
    suggestionCard: {
      display: "inline-block",
      background: 'light-dark(var(--mantine-color-white), var(--mantine-color-dark-7))',
      boxShadow: "0px 4px 4px 0px #0000001A",
      padding: "16px, 12px, 24px, 12px",
      borderRadius: "8px",
      gap: "8px",
      cursor: "pointer",
      maxWidth: "340px",

      "&:hover": {
        background: 'light-dark(#F9F9F9, var(--mantine-color-dark-8))',
        boxShadow: "0px 4px 4px 0px #0000001A",
        transition: "box-shadow 0.2s ease-in-out"
      }
    },

    suggestionCardTitle: {
      background: 'linear-gradient(108.98deg, light-dark(#917FFB, #917FFB) 27.06%, light-dark(#3F2DAF, #9386E6) 90.91%)',
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0em",
      textAlign: "left"
    },

    suggestionCardText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0em",
      textAlign: "left"
    }
  })
});

export default useStyles;
