import { Oval } from "react-loader-spinner";
import { loaderColor } from "@/style/Colors";
import { LoadingContent, LoadingWrapper } from "./Loading.style";
import { Flex, Loader, Text } from "@mantine/core";
export const LoadingComponent = (props: any) => {
  return (
    <LoadingWrapper>
      <LoadingContent>
        <Flex align={"center"}>
          <Loader size={"xs"} mr={"sm"} />
          <Text size={"xs"}>Loading...</Text>
        </Flex>
      </LoadingContent>
    </LoadingWrapper>
  );
};
