import { Box } from "@mantine/core";
import React from "react";
import classes from "./PageHeader.module.css";
export interface PageHeaderProps {
  children?: React.ReactNode;
  sx?: any;
}

const PageHeader = ({ children, sx = {} }: PageHeaderProps) => {
  return <Box className={classes.header}>{children}</Box>;
};

export default PageHeader;
