import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lazy, useContext, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import Icon from "@/ui/components/Common/Icon/Icon";
// import { AccountsBilling } from "./AccountSettings/AccountsBilling"
// import { AccountsGeneral } from "./AccountSettings/AccountsGeneral"
// import { AccountsSecurity } from "./AccountSettings/AccountsSecurity"
import "./settings_navigation.scss";
// @ts-ignore
import { light, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { MOBILE_BREAKPOINT_QUERY, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { WorkspaceSlugParams } from "types/types.d";
import AccountsDataConsent from "./AccountSettings/AccountsDataConsent";
import { Box, Divider, Flex, Paper, Stack, Title, NavLink, Text } from "@mantine/core";
import AccountAPICredentials from "./AccountSettings/AccountAPICredentials";
import { useMediaQuery } from "@mantine/hooks";
import AppLifecycleContext from "../../../lib/contexts/AppLifecycleContext";
// const AccountsGeneral = lazy(() => import("@/ui/pages/Settings/AccountSettings/AccountsGeneral"))
import AccountsGeneral from "@/ui/pages/Settings/AccountSettings/AccountsGeneral";

// const AccountsBilling = lazy(() => import("@/ui/pages/Settings/AccountSettings/AccountsBilling"))
import AccountsBilling from "@/ui/pages/Settings/AccountSettings/AccountsBilling";
// const AccountsSecurity = lazy(() => import("@/ui/pages/Settings/AccountSettings/AccountsSecurity"))
import AccountsSecurity from "@/ui/pages/Settings/AccountSettings/AccountsSecurity";
// const AccountsReferAndEarn = lazy(
//   () => import("@/ui/pages/Settings/AccountSettings/AccountsReferAndEarn")
// )
import AccountsReferAndEarn from "@/ui/pages/Settings/AccountSettings/AccountsReferAndEarn";
import { Header } from "@/ui/components/Common/Header/Header";

const AccountSettingsNavigation = (props: any) => {
  const { hasRolesWith, hasRolesNotWith } = useWorkspaceUtilityHook();
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);
  const { isCollapsedLeftMenu } = useContext(AppLifecycleContext);
  const { workspaceIdentifier } = useParams<WorkspaceSlugParams>();
  useEffect(() => {
    console.log("Settings...");
    document.title = "Account";
  }, []);
  const location = useLocation();

  const currentPageHeader = useMemo(() => {
    if (location.pathname.includes("/general")) {
      return "General Settings";
    } else if (location.pathname.includes("/api_credentials")) {
      return "API Credentials";
    } else if (location.pathname.includes("/security")) {
      return "Security";
    } else if (location.pathname.includes("/billing")) {
      return "Plan & Billing";
    } else if (location.pathname.includes("/consent")) {
      return "Data Consent";
    } else if (location.pathname.includes("/referral_program")) {
      return "Referral Program";
    }
    return "General Settings";
  }, [location.pathname]);

  const navigate = useNavigate();

  console.log(workspaceIdentifier);
  return (
    <>
      <Box
        className="subnav-navigation"
        sx={{
          left: isMobileView
            ? "16px !important"
            : isCollapsedLeftMenu
            ? "55px !important"
            : "220px",
          transition: "all 0.3s ease"
        }}>
        <div className="container">
          <Box ml={8}>
            <Title mb="xs" order={5}>
              Settings
            </Title>
            <Text>Account</Text>
          </Box>
          <Divider my="md" />
          <Stack gap={4} fw={500}>
            <NavLink
              className="navigation-link"
              leftSection={
                <Flex w={16} justify={"center"}>
                  <Icon icon="General" />
                </Flex>
              }
              label="General"
              active={location.pathname.endsWith("/general")}
              onClick={() => {
                navigate(`/env/${workspaceIdentifier}/account/general`);
              }}></NavLink>

            {hasRolesWith([WORKSPACE_MEMBER_ROLES.OWNER, WORKSPACE_MEMBER_ROLES.ADMINISTRATOR]) && (
              <NavLink
                className="navigation-link"
                leftSection={
                  <Flex w={16} justify={"center"}>
                    <Icon icon="Integrations" />
                  </Flex>
                }
                label="API Credentials"
                active={location.pathname.endsWith("/api_credentials")}
                onClick={() => {
                  navigate(`/env/${workspaceIdentifier}/account/api_credentials`);
                }}
              />
            )}
            <NavLink
              className="navigation-link"
              leftSection={
                <Flex w={16} justify={"center"}>
                  <Icon icon="Security" />
                </Flex>
              }
              label="Security"
              active={location.pathname.endsWith("/security")}
              onClick={() => {
                navigate(`/env/${workspaceIdentifier}/account/security`);
              }}></NavLink>
            {hasRolesWith([
              WORKSPACE_MEMBER_ROLES.OWNER,
              WORKSPACE_MEMBER_ROLES.MANAGER,
              WORKSPACE_MEMBER_ROLES.ADMINISTRATOR
            ]) && (
              <NavLink
                className="navigation-link"
                leftSection={
                  <Flex w={16} justify={"center"}>
                    <FontAwesomeIcon
                      icon={regular("database")}
                      className="flex-grow text-lg"></FontAwesomeIcon>{" "}
                  </Flex>
                }
                label="Data Consent"
                active={location.pathname.endsWith("/consent")}
                onClick={() => {
                  navigate(`/env/${workspaceIdentifier}/account/consent`);
                }}></NavLink>
            )}

            {hasRolesWith([WORKSPACE_MEMBER_ROLES.OWNER]) && (
              <>
                <NavLink
                  className="navigation-link"
                  leftSection={
                    <Flex w={16} justify={"center"}>
                      <FontAwesomeIcon
                        icon={regular("credit-card")}
                        className="flex-grow text-lg"></FontAwesomeIcon>{" "}
                    </Flex>
                  }
                  label="Plan & Billing"
                  active={location.pathname.endsWith("/billing")}
                  onClick={() => {
                    navigate(`/env/${workspaceIdentifier}/account/billing`);
                  }}></NavLink>
                <NavLink
                  className="navigation-link"
                  leftSection={
                    <Flex w={16} justify={"center"}>
                      <FontAwesomeIcon
                        icon={regular("circle-dollar")}
                        className="flex-grow text-lg"></FontAwesomeIcon>
                    </Flex>
                  }
                  label="Refer & Earn"
                  active={location.pathname.endsWith("/referral_program")}
                  onClick={() => {
                    navigate(`/env/${workspaceIdentifier}/account/referral_program`);
                  }}></NavLink>
              </>
            )}
          </Stack>
        </div>
      </Box>

      <Box className="subnav-content-container">
        <Header title={currentPageHeader} />

        <Paper mt={"xl"} p="md" shadow="md" withBorder>
          <Routes>
            <Route path={`general`} element={<AccountsGeneral />} />
            <Route path={`api_credentials`} element={<AccountAPICredentials />} />
            <Route path={`consent`} element={<AccountsDataConsent />} />
            <Route path={`security`} element={<AccountsSecurity />} />
            <Route path={`billing`} element={<AccountsBilling />} />
            {/* 
      
          
          <Route path={`referral_program`} element={<AccountsReferAndEarn />} /> */}
            <Route
              path="*"
              element={<Navigate to={`/env/${workspaceIdentifier}/account/general`} replace />}
            />
          </Routes>
        </Paper>
      </Box>
    </>
  );
};

export default AccountSettingsNavigation;
