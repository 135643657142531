import {
  ActiveCardProps,
  TopDevicesProps,
  TopEventsOptionsProps,
  TopLocationsProps,
  TopPagesProps
} from "types/types.d";
import axios from "./JWTService";
import { stringify } from "query-string";
import { DEFAULT_CHANNEL_TYPES } from "../utils/Constants";
import { deprecate } from "util";

export class WebAnalyticsService {
  topBarSection = (workspaceId: string, type: "histogram" | "count", query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/web_stats/${workspaceId}/top_bar_section/${type}?${stringify(query)}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  visitors = (
    workspaceId: string,
    type: "online" | "histogram" | "count",
    query: any
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/web_stats/${workspaceId}/visitors/${type}?${stringify(query)}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  sessions = (
    workspaceId: string,
    type: "histogram" | "count",
    filterType: ActiveCardProps = "sessions",
    query: any
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/web_stats/${workspaceId}/sessions/${type}/${filterType}?${stringify(query)}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  topSourcesForChannel = (
    workspaceId: string,
    channelType: string = "all",
    type: "count" | "list",
    page: number = 1,
    limit: number = 10,
    sort: string,
    query: any,
    q: string = ""
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${workspaceId}/top_sources/${channelType}/${type}?page=${page}&limit=${limit}&sort=${sort}&${stringify(
            query
          )}&q=${q}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  aggregatedTopChannelsSourcesCount = (workspaceId: string, query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/web_stats/${workspaceId}/aggregated_top_sources_count?${stringify(query)}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  referrer = (wsId: string, searchQuery: string, channel: string, page: number = 1, query: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${wsId}/${channel}/referrer?search_query=${searchQuery}&page=${page}&limit=10&${stringify(
            query
          )}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  UTMTraffic = (
    workspaceId: string,
    sourceType:
      | "utm_source"
      | "utm_campaign"
      | "utm_content"
      | "utm_medium"
      | "utm_term"
      | string
      | null = "utm_source",
    type: "count" | "list" | "csv",
    page: number = 1,
    limit: number = 10,
    sort: string,
    query: any,
    q: string = ""
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${workspaceId}/utm_traffic/${sourceType}/${type}?page=${page}&limit=${limit}&sort=${sort}&${stringify(
            query
          )}&q=${q}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  topDevices = (
    workspaceId: string,
    sourceType: TopDevicesProps = "browser",
    page: number = 1,
    query: any
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${workspaceId}/top_devices/${sourceType}?page=${page}&limit=20&${stringify(
            query
          )}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  topLocations = (
    workspaceId: string,
    sourceType: TopLocationsProps = "country",
    type: "list" | "csv",
    page: number = 1,
    limit: number = 10,
    sort: string,
    query: any,
    q: string = ""
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${workspaceId}/top_locations/${sourceType}/${type}?page=${page}&limit=${limit}&sort=${sort}&${stringify(
            query
          )}&q=${q}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  topPages = (
    workspaceId: string,
    sourceType: TopPagesProps = "top",
    page: number = 1,
    limit: number = 10,
    sort: string,
    query: any,
    q: string = ""
  ): Promise<any> => {
    // TODO: Add source type when we add entry and exit pages with sessions.
    return new Promise<any>((resolve, reject) => {
      // In top pages, we have to set sort to visitors instead of count as the default. This is because in the query for
      // top pages, visitors is used instead of count. For entry and exit pages, count is used.
      if (sourceType === "top" && sort === "count:desc") {
        sort = "visitors:desc";
      }
      if (sourceType === "top" && sort === "count:asc") {
        sort = "visitors:asc";
      }
      axios
        .post(
          `/v1/web_stats/${workspaceId}/pages/${sourceType}?page=${page}&limit=${limit}&sort=${sort}&${stringify(
            query
          )}&q=${q}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  funnel = (
    workspaceId: string,
    id: string,
    query: any,
    sharingSecret: string | null = null
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${workspaceId}/funnel/${id}?${stringify(query)}` +
            (sharingSecret ? `&sharing_secret=${sharingSecret}` : "")
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  breakdown = (
    workspaceId: string,
    id: string,
    query: any,
    sharingSecret: string | null = null
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${workspaceId}/funnel/breakdown/${id}?${stringify(query)}` +
            (sharingSecret ? `&sharing_secret=${sharingSecret}` : "")
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  insights = (
    workspaceId: string,
    id: string,
    query: any,
    sharingSecret: string | null = null
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${workspaceId}/funnel_insights/${id}?${stringify(query)}` +
            (sharingSecret ? `&sharing_secret=${sharingSecret}` : "")
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  topSearchedKeywords = (
    workspaceId: string,
    q: string,
    limit: number = 50,
    page: number = 1,
    query: any,
    sort: string = "total_clicks:desc"
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${workspaceId}/searched_keywords?q=${q}&page=${page}&limit=${limit}&sort=${sort}&${stringify(
            query
          )}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  goals = (workspaceId: string, query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/web_stats/${workspaceId}/goals?${stringify(query)}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  conversionGoalStats = (workspaceId: string, query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/web_stats/${workspaceId}/goal_statistics?${stringify(query)}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  domains = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/web_stats/${workspaceId}/domains`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  funnelConversionsList = (
    ws_id: any,
    sort: "created_at:desc" | "name:desc" | "name:asc" | "created_at:asc" | string,
    page: number = 1,
    search: string = "",
    type: string = "all",
    limit: number = 20,
    funnel_type: string = "all"
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `/v1/web_stats/${ws_id}/conversion_funnels?page=${page}&sort=${sort}&search=${search}&type=${type}&limit=${limit}&funnel_type=${funnel_type}`
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  overview_stats = (workspaceId: string, query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/web_stats/${workspaceId}/overview_stats?${stringify(query)}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  topSourcesExport = (
    workspaceId: string,
    channelType: DEFAULT_CHANNEL_TYPES | "all" = DEFAULT_CHANNEL_TYPES.REFERRAL,
    type: "list",
    page: number = 1,
    limit: number = 10000,
    sort: string,
    query: any,
    q: string = ""
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${workspaceId}/top_sources/export/${channelType}/${type}?page=${page}&limit=${limit}&sort=${sort}&${stringify(
            query
          )}&q=${q}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  topPagesExport = (
    workspaceId: string,
    sourceType: TopPagesProps = "top",
    page: number = 1,
    limit: number = 10000,
    sort: string,
    query: any,
    q: string = ""
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${workspaceId}/pages/export/${sourceType}?page=${page}&limit=${limit}&sort=${sort}&${stringify(
            query
          )}&q=${q}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getConversionTimeDistribution = (workspaceId: string, id: string, query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/web_stats/${workspaceId}/funnel/${id}/conversion_time?${stringify(query)}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getUsersList = (workspaceId: string, id: string, query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/web_stats/${workspaceId}/funnel/${id}/users_list?${stringify(query)}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  exportUsersList = (
    workspaceId: string,
    id: string,
    step: number,
    type: string,
    query: any
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `/v1/web_stats/${workspaceId}/funnel/${id}/users_list/export/${step}/${type}?${stringify(
            query
          )}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  suggestions = (
    workspaceId: string,
    item: string,
    query: any,
    value: string = "",
    limit: number = 25
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      const filters = `${query && stringify(query) + "&"}q=${value}&limit=${limit}`;
      axios
        .post(`/v1/web_stats/${workspaceId}/suggestions/${item}?${filters}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
