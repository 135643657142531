import { greyDark } from "@/style/Colors"
import styled from "styled-components"

export const HeaderNavigationContainer = styled.div`
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: solid 1px;
  border-color: ${props =>
    props.theme.colorScheme === "dark"
      ? props.theme.colors.darkBorderColor[0]
      : props.theme.colors.lightBorderColor[0]};
`

export const HeaderNavigationH1 = styled.h1`
  flex-grow: 1;
  font-size: 1.538rem;
  a {
    color: ${greyDark};
  }
  
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`

export const HeaderNavigationActions = styled.div`
  display: flex;
  svg {
    height: 1.25rem;
    color: #9999a9;
    margin: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`
