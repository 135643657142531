// @ts-ignore
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { ORGANIZATION_STATES } from "@/lib/utils/Constants"
import { useContext } from "react"
import { NavLink } from "react-router-dom"

export const QuotaExceededNotification = ({ className }: any) => {
  const { activeWorkspace } = useContext(AppLifecycleContext)

  return (
    <>
      {activeWorkspace?.organization?.state === ORGANIZATION_STATES.QUOTA_EXCEEDED && (
        <>
          <div
            className={`flex m-4 px-4 py-4 shadow-sm bg-red-100 bg-opacity-50 rounded border border-solid border-red-200  transition-all ease-in-out ${className}`}
          >
            <div className="h-4 w-4 mr-4">
              <FontAwesomeIcon
                size="lg"
                className="text-red-800 "
                icon={regular("triangle-exclamation")}
              />
            </div>
            <div>
              Your account has exceeded the limits on your current plan. Please{" "}
              <NavLink to={`/env/${activeWorkspace.identifier}/account/billing`} className="pr-1.5">
                upgrade
              </NavLink>{" "}
              to avoid service interruption.
            </div>
          </div>
        </>
      )}
    </>
  )
}
