import { format } from "date-fns";
import React, { FC, Fragment } from "react";
import "./cohorts.scss";
import { Table, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import styles from "./CohortsByRows.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
type BodyCellProps = {
  RegisterDate: string;
  users_signed_up: number;
  D1: number;
  D2: number;
  D3: number;
  D4: number;
  D5: number;
  D6: number;
  D7: number;
  D8: number;
  D9: number;
  D10: number;
  D11: number;
  D12: number;
  D1_percentage: number;
  D2_percentage: number;
  D3_percentage: number;
  D4_percentage: number;
  D5_percentage: number;
  D6_percentage: number;
  D7_percentage: number;
  D8_percentage: number;
  D9_percentage: number;
  D10_percentage: number;
  D11_percentage: number;
  D12_percentage: any;
};

interface CohortsProps {
  headerCells?: Array<string>;
  bodyCells?: Array<BodyCellProps>;
  isPercentage?: boolean;
}
const CohortsByRows: FC<CohortsProps> = ({
  isPercentage = false,
  headerCells = [
    "Date",
    "Day 0",
    "Day 1",
    "Day 2",
    "Day 3",
    "Day 4",
    "Day 5",
    "Day 6",
    "Day 7",
    "Day 8",
    "Day 9",
    "Day 10",
    "Day 11",
    "Day 12"
  ],
  bodyCells = [
    {
      RegisterDate: "2021-10-22",
      users_signed_up: 0,
      D1: 0,
      D2: 0,
      D3: 0,
      D4: 0,
      D5: 0,
      D6: 0,
      D7: 0,
      D8: 0,
      D9: 0,
      D10: 0,
      D11: 0,
      D12: 0,
      D1_percentage: 0,
      D2_percentage: 0,
      D3_percentage: 0,
      D4_percentage: 0,
      D5_percentage: 0,
      D6_percentage: 0,
      D7_percentage: 0,
      D8_percentage: 0,
      D9_percentage: 0,
      D10_percentage: 0,
      D11_percentage: 0,
      D12_percentage: 0
    },
    {
      RegisterDate: "2021-10-23",
      users_signed_up: 0,
      D1: 0,
      D2: 0,
      D3: 0,
      D4: 0,
      D5: 0,
      D6: 0,
      D7: 0,
      D8: 0,
      D9: 0,
      D10: 0,
      D11: 0,
      D12: 0,
      D1_percentage: 0,
      D2_percentage: 0,
      D3_percentage: 0,
      D4_percentage: 0,
      D5_percentage: 0,
      D6_percentage: 0,
      D7_percentage: 0,
      D8_percentage: 0,
      D9_percentage: 0,
      D10_percentage: 0,
      D11_percentage: 0,
      D12_percentage: 0
    },
    {
      RegisterDate: "2021-10-24",
      users_signed_up: 0,
      D1: 0,
      D2: 0,
      D3: 0,
      D4: 0,
      D5: 0,
      D6: 0,
      D7: 0,
      D8: 0,
      D9: 0,
      D10: 0,
      D11: 0,
      D12: 0,
      D1_percentage: 0,
      D2_percentage: 0,
      D3_percentage: 0,
      D4_percentage: 0,
      D5_percentage: 0,
      D6_percentage: 0,
      D7_percentage: 0,
      D8_percentage: 0,
      D9_percentage: 0,
      D10_percentage: 0,
      D11_percentage: 0,
      D12_percentage: 0
    },
    {
      RegisterDate: "2021-10-25",
      users_signed_up: 0,
      D1: 0,
      D2: 0,
      D3: 0,
      D4: 0,
      D5: 0,
      D6: 0,
      D7: 0,
      D8: 0,
      D9: 0,
      D10: 0,
      D11: 0,
      D12: 0,
      D1_percentage: 0,
      D2_percentage: 0,
      D3_percentage: 0,
      D4_percentage: 0,
      D5_percentage: 0,
      D6_percentage: 0,
      D7_percentage: 0,
      D8_percentage: 0,
      D9_percentage: 0,
      D10_percentage: 0,
      D11_percentage: 0,
      D12_percentage: 0
    },
    {
      RegisterDate: "2021-10-26",
      users_signed_up: 0,
      D1: 0,
      D2: 0,
      D3: 0,
      D4: 0,
      D5: 0,
      D6: 0,
      D7: 0,
      D8: 0,
      D9: 0,
      D10: 0,
      D11: 0,
      D12: 0,
      D1_percentage: 0,
      D2_percentage: 0,
      D3_percentage: 0,
      D4_percentage: 0,
      D5_percentage: 0,
      D6_percentage: 0,
      D7_percentage: 0,
      D8_percentage: 0,
      D9_percentage: 0,
      D10_percentage: 0,
      D11_percentage: 0,
      D12_percentage: 0
    },
    {
      RegisterDate: "2021-10-27",
      users_signed_up: 0,
      D1: 0,
      D2: 0,
      D3: 0,
      D4: 0,
      D5: 0,
      D6: 0,
      D7: 0,
      D8: 0,
      D9: 0,
      D10: 0,
      D11: 0,
      D12: -1,
      D1_percentage: 0,
      D2_percentage: 0,
      D3_percentage: 0,
      D4_percentage: 0,
      D5_percentage: 0,
      D6_percentage: 0,
      D7_percentage: 0,
      D8_percentage: 0,
      D9_percentage: 0,
      D10_percentage: 0,
      D11_percentage: 0,
      D12_percentage: -1
    },
    {
      RegisterDate: "2021-10-28",
      users_signed_up: 0,
      D1: 0,
      D2: 0,
      D3: 0,
      D4: 0,
      D5: 0,
      D6: 0,
      D7: 0,
      D8: 0,
      D9: 0,
      D10: 0,
      D11: -1,
      D12: -1,
      D1_percentage: 0,
      D2_percentage: 0,
      D3_percentage: 0,
      D4_percentage: 0,
      D5_percentage: 0,
      D6_percentage: 0,
      D7_percentage: 0,
      D8_percentage: 0,
      D9_percentage: 0,
      D10_percentage: 0,
      D11_percentage: -1,
      D12_percentage: -1
    },
    {
      RegisterDate: "2021-10-29",
      users_signed_up: 9,
      D1: 4,
      D2: 2,
      D3: 4,
      D4: 2,
      D5: 3,
      D6: 3,
      D7: 3,
      D8: 1,
      D9: 0,
      D10: -1,
      D11: -1,
      D12: -1,
      D1_percentage: 44.44,
      D2_percentage: 22.22,
      D3_percentage: 44.44,
      D4_percentage: 22.22,
      D5_percentage: 33.33,
      D6_percentage: 33.33,
      D7_percentage: 33.33,
      D8_percentage: 11.11,
      D9_percentage: 0,
      D10_percentage: -1,
      D11_percentage: -1,
      D12_percentage: -1
    },
    {
      RegisterDate: "2021-10-30",
      users_signed_up: 19,
      D1: 2,
      D2: 1,
      D3: 2,
      D4: 1,
      D5: 1,
      D6: 1,
      D7: 0,
      D8: 0,
      D9: -1,
      D10: -1,
      D11: -1,
      D12: -1,
      D1_percentage: 10.53,
      D2_percentage: 5.26,
      D3_percentage: 10.53,
      D4_percentage: 5.26,
      D5_percentage: 5.26,
      D6_percentage: 5.26,
      D7_percentage: 0,
      D8_percentage: 0,
      D9_percentage: -1,
      D10_percentage: -1,
      D11_percentage: -1,
      D12_percentage: -1
    },
    {
      RegisterDate: "2021-10-31",
      users_signed_up: 10,
      D1: 3,
      D2: 1,
      D3: 1,
      D4: 0,
      D5: 0,
      D6: 0,
      D7: 0,
      D8: -1,
      D9: -1,
      D10: -1,
      D11: -1,
      D12: -1,
      D1_percentage: 30,
      D2_percentage: 10,
      D3_percentage: 10,
      D4_percentage: 0,
      D5_percentage: 0,
      D6_percentage: 0,
      D7_percentage: 0,
      D8_percentage: -1,
      D9_percentage: -1,
      D10_percentage: -1,
      D11_percentage: -1,
      D12_percentage: -1
    },
    {
      RegisterDate: "2021-11-01",
      users_signed_up: 28,
      D1: 10,
      D2: 3,
      D3: 3,
      D4: 5,
      D5: 1,
      D6: 0,
      D7: -1,
      D8: -1,
      D9: -1,
      D10: -1,
      D11: -1,
      D12: -1,
      D1_percentage: 35.71,
      D2_percentage: 10.71,
      D3_percentage: 10.71,
      D4_percentage: 17.86,
      D5_percentage: 3.57,
      D6_percentage: 0,
      D7_percentage: -1,
      D8_percentage: -1,
      D9_percentage: -1,
      D10_percentage: -1,
      D11_percentage: -1,
      D12_percentage: -1
    },
    {
      RegisterDate: "2021-11-02",
      users_signed_up: 52,
      D1: 10,
      D2: 8,
      D3: 4,
      D4: 1,
      D5: 0,
      D6: -1,
      D7: -1,
      D8: -1,
      D9: -1,
      D10: -1,
      D11: -1,
      D12: -1,
      D1_percentage: 19.23,
      D2_percentage: 15.38,
      D3_percentage: 7.69,
      D4_percentage: 1.92,
      D5_percentage: 0,
      D6_percentage: -1,
      D7_percentage: -1,
      D8_percentage: -1,
      D9_percentage: -1,
      D10_percentage: -1,
      D11_percentage: -1,
      D12_percentage: -1
    },
    {
      RegisterDate: "2021-11-03",
      users_signed_up: 39,
      D1: 2,
      D2: 4,
      D3: 2,
      D4: 0,
      D5: -1,
      D6: -1,
      D7: -1,
      D8: -1,
      D9: -1,
      D10: -1,
      D11: -1,
      D12: -1,
      D1_percentage: 5.13,
      D2_percentage: 10.26,
      D3_percentage: 5.13,
      D4_percentage: 0,
      D5_percentage: -1,
      D6_percentage: -1,
      D7_percentage: -1,
      D8_percentage: -1,
      D9_percentage: -1,
      D10_percentage: -1,
      D11_percentage: -1,
      D12_percentage: -1
    },
    {
      RegisterDate: "2021-11-04",
      users_signed_up: 20,
      D1: 5,
      D2: 1,
      D3: 0,
      D4: -1,
      D5: -1,
      D6: -1,
      D7: -1,
      D8: -1,
      D9: -1,
      D10: -1,
      D11: -1,
      D12: -1,
      D1_percentage: 25,
      D2_percentage: 5,
      D3_percentage: 0,
      D4_percentage: -1,
      D5_percentage: -1,
      D6_percentage: -1,
      D7_percentage: -1,
      D8_percentage: -1,
      D9_percentage: -1,
      D10_percentage: -1,
      D11_percentage: -1,
      D12_percentage: -1
    },
    {
      RegisterDate: "2021-11-05",
      users_signed_up: 32,
      D1: 6,
      D2: 1,
      D3: -1,
      D4: -1,
      D5: -1,
      D6: -1,
      D7: -1,
      D8: -1,
      D9: -1,
      D10: -1,
      D11: -1,
      D12: -1,
      D1_percentage: 18.75,
      D2_percentage: 3.12,
      D3_percentage: -1,
      D4_percentage: -1,
      D5_percentage: -1,
      D6_percentage: -1,
      D7_percentage: -1,
      D8_percentage: -1,
      D9_percentage: -1,
      D10_percentage: -1,
      D11_percentage: -1,
      D12_percentage: -1
    },
    {
      RegisterDate: "2021-11-06",
      users_signed_up: 12,
      D1: 0,
      D2: -1,
      D3: -1,
      D4: -1,
      D5: -1,
      D6: -1,
      D7: -1,
      D8: -1,
      D9: -1,
      D10: -1,
      D11: -1,
      D12: -1,
      D1_percentage: 0,
      D2_percentage: -1,
      D3_percentage: -1,
      D4_percentage: -1,
      D5_percentage: -1,
      D6_percentage: -1,
      D7_percentage: -1,
      D8_percentage: -1,
      D9_percentage: -1,
      D10_percentage: -1,
      D11_percentage: -1,
      D12_percentage: -1
    },
    {
      RegisterDate: "2021-11-07",
      users_signed_up: 1,
      D1: -1,
      D2: -1,
      D3: -1,
      D4: -1,
      D5: -1,
      D6: -1,
      D7: -1,
      D8: -1,
      D9: -1,
      D10: -1,
      D11: -1,
      D12: -1,
      D1_percentage: -1,
      D2_percentage: -1,
      D3_percentage: -1,
      D4_percentage: -1,
      D5_percentage: -1,
      D6_percentage: -1,
      D7_percentage: -1,
      D8_percentage: -1,
      D9_percentage: -1,
      D10_percentage: -1,
      D11_percentage: -1,
      D12_percentage: -1
    }
  ]
}) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  // const getPercentageCellColorStyles = (index: number, value: any, isPercentage: boolean): any => {
  //   if (isPercentage) {
  //     if (value > 0) {
  //       return `rgba(185, 151, 255, ${value / 100})`;
  //     } else {
  //       return "rgba(185, 151, 255, 0.1)";
  //     }
  //   }
  //   return "";
  // };
  const getPercentageCellColorStyles = (value: number): string => {
    if (value === 0) {
      return colorScheme === "dark" ? theme.colors.dark[5] : "#fff";
    } else if (value < 5) {
      return colorScheme === "dark" ? theme.colors.red[8] : theme.colors.red[2];
    } else if (value < 10) {
      return colorScheme === "dark" ? theme.colors.orange[8] : theme.colors.orange[2];
    } else if (value < 20) {
      return colorScheme === "dark" ? theme.colors.yellow[8] : theme.colors.yellow[0];
    } else {
      const percentage = Math.min(Math.floor((value - 20) / 5), 9);
      return colorScheme === "dark"
        ? theme.colors.brand[9 - percentage]
        : theme.colors.brand[percentage];
    }
  };
  return (
    <Table className={styles.table}>
      <Table.Thead className={styles.header}>
        <Table.Tr>
          {headerCells.map((period: string, headerIndex: number) => (
            <Table.Th
              key={`header:${headerIndex}`}
              className={`${styles.headerCell} ${headerIndex < 2 ? styles.wideCell : ""}`}>
              {period}
            </Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {bodyCells.map((item: BodyCellProps, rowIndex: number) => (
          <Table.Tr key={rowIndex} className={styles.bodyRow}>
            <Table.Td className={`${styles.bodyCell} ${styles.wideCell}`}>
              {item?.RegisterDate && item.RegisterDate === "-1" ? (
                <>Total</>
              ) : (
                <>{format(new Date(item?.RegisterDate), "d MMM yyyy ")}</>
              )}
            </Table.Td>
            <Table.Td className={`${styles.bodyCell} ${styles.wideCell} ${styles.highlightedCell}`}>
              {item?.users_signed_up}
            </Table.Td>
            {isPercentage ? (
              <>
                {Object.entries(item)
                  .filter(([key, value]) => key.includes("_percentage"))
                  .map(([key, value], index) => (
                    <Fragment key={`percentage:${index}`}>
                      {value === -1 || value === null ? (
                        <Table.Td className={`${styles.bodyCell} ${styles.emptyCell}`}>-</Table.Td>
                      ) : (
                        <Table.Td
                          className={styles.bodyCell}
                          style={{
                            backgroundColor: getPercentageCellColorStyles(value)
                          }}>
                          {value}%
                        </Table.Td>
                      )}
                    </Fragment>
                  ))}
              </>
            ) : (
              <>
                {Object.entries(item)
                  .filter(([key, value]) => !key.includes("_percentage") && key.startsWith("D"))
                  .map(([key, value], index) => {
                    const percentageKey = `${key}_percentage`;
                    // @ts-ignore
                    const percentageValue = item[percentageKey];
                    return (
                      <Fragment key={`absolute:${index}`}>
                        {value === -1 ? (
                          <Table.Td className={`${styles.bodyCell} ${styles.emptyCell}`}>
                            -
                          </Table.Td>
                        ) : (
                          <Table.Td
                            className={`${styles.bodyCell} ${styles.highlightedCell}`}
                            style={{
                              backgroundColor: getPercentageCellColorStyles(percentageValue)
                            }}>
                            {value}
                            {/* <br />
                            {percentageValue !== -1 && <>{percentageValue}%</>} */}
                          </Table.Td>
                        )}
                      </Fragment>
                    );
                  })}
              </>
            )}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};

const CohortsByRowsMemoized = React.memo(CohortsByRows);
export { CohortsByRowsMemoized as CohortsByRows };
