import axios from "@/lib/services/JWTService";
import { SegmentsService } from "@/lib/services/SegmentsService";
import { toast } from "react-toastify";
import { LoadingStateProps, LooseObject } from "types/types.d";
import create from "zustand";

interface ISegmentListStore {
  /**
   * List of segments
   */
  segments: any;
  /**
   * Set segments
   */
  setSegments: (segments: any) => void;

  /**
   * Count of list segment
   */

  segmentCountList: any;

  /**
   * Set count of list segment
   */

  setSegmentCountList: (segmentCountList: any) => void;

  /**
   * Count loading
   */
  countLoading: LoadingStateProps;

  /**
   * Set count loading
   */

  setCountLoading: (countLoading: LoadingStateProps) => void;

  /**
   * Loading state
   */
  loading: LoadingStateProps;
  /**
   * Set loading state
   * */
  setLoading: (loading: LoadingStateProps) => void;

  /**
   * a simple state to toggle to trigger a refetch in zustand-less components (sidepane of contact hub)
   */
  refetch: boolean;

  /**
   * Segment type
   */

  segmentType: "all" | "visitor" | "user" | "company";

  /**
   *
   */
  setSegmentType: (segmentType: "all" | "visitor" | "user" | "company") => void;

  /**
   * Fetch segments
   */
  fetchSegments: (
    ws_id: string,
    type?: "all" | "visitor" | "user" | "company" | null
  ) => Promise<void>;
  /**
   * Delete segment
   */
  deleteSegment: (ws_id: string, segment_id: string) => Promise<void>;
  /**
   * Create segment
   */
  createSegment: (ws_id: string, payload: any) => any;
  /**
   * Update Segment
   */
  updateSegment: (ws_id: string, segment_id: string, payload: any, refetch?: boolean) => any;

  /**
   * countList
   * @param id
   * @returns
   */

  /**
   * Fetch count of segment
   */

  fetchSegmentCounts: (workspaceId: string) => Promise<void>;
}

export const useSegmentListStore = create<ISegmentListStore>((set, get) => ({
  segments: [],
  setSegments: (segments: any) => set({ segments }),
  segmentCountList: {},
  setSegmentCountList: (segmentCountList: any) => set({ segmentCountList }),
  loading: "idle",
  setLoading: (loading: LoadingStateProps) => set({ loading }),
  countLoading: "idle",
  segmentType: "all",
  setSegmentType: (segmentType: "all" | "visitor" | "user" | "company") => set({ segmentType }),
  setCountLoading: (countLoading: LoadingStateProps) => set({ countLoading }),
  refetch: false,
  /**
   * Fetch list of segments.
   * @param ws_id The workspace id
   * @param type The default type is null, if we want to pass the type, we can send it directly otherwise it will take the type from the store
   * @returns
   */
  fetchSegments: async (ws_id: string, type = null) => {
    set({ loading: "loading" });
    const data = await new SegmentsService()
      .getAll(ws_id, type || get().segmentType)
      .then((res) => {
        if (res.data) {
          set({ segments: res.data });
          return res.data;
        }
      })
      .catch((err) => {
        return [];
      });
    set({ loading: "loaded" });
    return data;
  },
  deleteSegment: async (ws_id: string, segment_id: string) => {
    set({ loading: "loading" });
    await new SegmentsService()
      .delete(ws_id, segment_id)
      .then((res) => {
        if (res.data) {
          set({ segments: get().segments.filter((segment: any) => segment.id !== segment_id) });
        }
      })
      .catch((err) => {});
    set({ loading: "loaded" });
  },
  createSegment: async (ws_id: string, payload: any) => {
    const data = await new SegmentsService()
      .post(ws_id, payload)
      .then((res) => {
        if (res.data) {
          set({ segments: [res.data, ...get().segments] });
          toast.success("Segment created successfully");
          return true;
        }
      })
      .catch((err) => {
        return false;
      });
    return data;
  },
  updateSegment: async (ws_id: string, id: string, payload: any, refetch = false) => {
    const data = await new SegmentsService()
      .put(ws_id, id, payload)
      .then((res) => {
        if (res.data) {
          let segmentsList = get().segments;
          segmentsList = segmentsList.map((segment: any) => {
            if (segment.id === id) {
              return res.data;
            }
            return segment;
          });
          set({ segments: segmentsList });

          // Remove the history from the segment list.
          if (window.location.pathname.endsWith("/segments")) {
            let segmentCountList = get().segmentCountList;
            delete segmentCountList[id];
            set({ segmentCountList });
            get().fetchSegmentCounts(ws_id);
          }

          if (refetch) {
            set({ refetch: !get().refetch });
          }

          toast.success("Segment updated successfully");
          return true;
        }
      })
      .catch((err) => {
        return false;
      });
    return data;
  },
  fetchSegmentCounts: async (workspaceId: string) => {
    set({ countLoading: "loading" });
    let segmentHistoryRequests: any = [];
    get().segments.map((item: LooseObject) => {
      if (!get().segmentCountList.hasOwnProperty(item.id)) {
        console.log("Request pushed to array.", item.id);
        segmentHistoryRequests.push(new SegmentsService().count(workspaceId, item.id));
      }
    });

    // console.log(segmentHistoryRequests, eventsHistoryList);

    // send all requests in parallel.
    let segmentCountResponse: LooseObject = get().segmentCountList;

    await axios
      .all(segmentHistoryRequests)
      .then(
        axios.spread((...responses) => {
          responses.map((response: any, index: number) => {
            // console.log(response.data);
            if (response.data) {
              segmentCountResponse[response.data.id] = response.data;
            }
          });
        })
      )
      .catch((errors) => {
        console.log(errors);
        // react on errors.
      });
    // console.log(segmentCountResponse);
    console.log(segmentCountResponse);
    set({ segmentCountList: segmentCountResponse });
    set({ countLoading: "loaded" });
  }
}));
