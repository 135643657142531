/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Divider,
  Flex,
  HoverCard,
  List,
  Menu,
  Popover,
  Switch,
  Text,
  Title,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { Fragment, useContext, useState } from "react";
import NotesModel from "./NotesModel";
import NotesDetailView from "./NotesDetailView";
import { Note } from "@/stores/useAnnotationNotesStore";
import NoteVisibilityToggle from "./NoteVisibilityToggle";
import { IAnnotationNoteVisibilityType } from "@/types/types.d";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { formatToMidnightUTC } from "@/lib/utils/DateUtility";
import { truncateText } from "@/lib/utils/StringUtility";
import { format, parseISO } from "date-fns";
import { openConfirmModal } from "@mantine/modals";
import { PLAN_TYPES } from "@/lib/utils/Constants";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useNavigate } from "react-router-dom";
import { groupBy } from "lodash";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const NoteTypes = {
  CREATE: "create",
  UPDATE: "update"
};

interface NotesProps {
  isStarter: boolean;
  isActiveUserViewer: boolean;
  notes: Note[];
  allNotes: Note[];
  notesLoading: string;
  applyNoteChanges: (type: string, values: any) => void;
  noteVisibilityType: IAnnotationNoteVisibilityType;
  setNoteVisibilityType: (reportType: IAnnotationNoteVisibilityType) => void;
  getAnnotationNotes: (reportType: IAnnotationNoteVisibilityType, page: number) => void;
}

const NotesPopoverCard = ({
  isStarter,
  isActiveUserViewer,
  notes,
  allNotes,
  notesLoading,
  applyNoteChanges,
  noteVisibilityType,
  setNoteVisibilityType,
  getAnnotationNotes
}: NotesProps) => {
  const groupedNotes = groupBy(notes, "author");
  const [opened, setOpened] = useState(false);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const navigate = useNavigate();

  const theme = useGlobalMantineTheme();
  const [notesModelOpened, setNotesModelOpened] = useState(false);
  const [notesDetailViewOpened, setNotesDetailViewOpened] = useState(false);
  const [noteToUpdate, setNoteToUpdate] = useState<Note>();
  const [noteType, setNoteType] = useState(NoteTypes.CREATE);

  const handleHideShowNoteClick = (note: Note) => {
    applyNoteChanges("update", {
      ...note,
      hide_note: !note.hide_note,
      date: formatToMidnightUTC(note.date)
    });
  };

  const deleteNotePrompt = (note: Note) => {
    openConfirmModal({
      title: <Title order={5}>Delete Note</Title>,
      children: (
        <Text size="sm">
          Are you sure you want to delete your Note with title: &nbsp;
          <p className="font-semibold">{note.title} ?</p>
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => applyNoteChanges("delete", note)
    });
  };

  return (
    <>
      {isStarter ? (
        <HoverCard width={280} shadow="md" withArrow disabled={!isStarter}>
          <HoverCard.Target>
            <Button size="xs" variant="subtle" mr={4} py={8} h={24}>
              Notes
              {isStarter && (
                <FontAwesomeIcon
                  icon={solid("lock-keyhole")}
                  className="w-4 h-4 ml-1.5 hover:opacity-80"
                />
              )}
            </Button>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text size="sm">
              Upgrade to{" "}
              <Text span fw={600}>
                Pro
              </Text>{" "}
              or{" "}
              <Text span fw={600}>
                Premium
              </Text>{" "}
              plan to use the Notes feature.
              <Anchor
                onClick={() => navigate(`/env/${activeWorkspace.identifier}/account/billing`)}>
                Upgrade now.
              </Anchor>
            </Text>
          </HoverCard.Dropdown>
        </HoverCard>
      ) : (
        <Popover
          width={500}
          position="bottom-end"
          withArrow
          shadow="md"
          radius="md"
          opened={opened}
          onChange={setOpened}>
          <Popover.Target>
            <Flex align={"center"}>
              <Button
                size="xs"
                variant="subtle"
                mr={4}
                // c="brand"
                onClick={() => setOpened(true)}
                leftSection={<FontAwesomeIcon size="sm" icon={regular("comment")} />}>
                <Text ml={8} fw={500} fz="xs">
                  {allNotes.length > 0 ? `Notes (${allNotes.length})` : "Add a Note"}
                </Text>
              </Button>
            </Flex>
          </Popover.Target>
          <Popover.Dropdown>
            <Box>
              <Flex justify={"space-between"} pb={"xs"}>
                <Flex align={"center"}>
                  <Text fw={700} size="sm" mr={"sm"}>
                    Notes
                  </Text>
                  {groupedNotes && Object.keys(groupedNotes).length > 0 && (
                    <Flex justify={"flex-end"}>
                      <NoteVisibilityToggle
                        noteVisibilityType={noteVisibilityType}
                        setNoteVisibilityType={setNoteVisibilityType}
                        showLabel={true}
                      />
                    </Flex>
                  )}
                </Flex>

                <Box>
                  <>
                    {isActiveUserViewer ? (
                      <HoverCard width={280} shadow="md" withArrow disabled={!isActiveUserViewer}>
                        <HoverCard.Target>
                          <Button size="xs" variant={"outline"}>
                            Create Note
                            {isActiveUserViewer && (
                              <FontAwesomeIcon
                                icon={solid("lock-keyhole")}
                                className="w-4 h-4 ml-1.5 hover:opacity-80"
                              />
                            )}
                          </Button>
                        </HoverCard.Target>
                        <HoverCard.Dropdown>
                          <Text size="xs">
                            Viewer cannot create notes. Only the Owner/Admin of the note can create
                            it.
                          </Text>
                        </HoverCard.Dropdown>
                      </HoverCard>
                    ) : (
                      <Button
                        size="xs"
                        disabled={isActiveUserViewer}
                        variant={"outline"}
                        onClick={() => {
                          setNoteType(NoteTypes.CREATE);
                          setNotesModelOpened(true);
                          setOpened(false);
                        }}>
                        Create Note
                      </Button>
                    )}
                  </>
                </Box>
              </Flex>
              <Divider mb="xs" />

              <Box
                mah={175}
                pb={notes.length > 0 ? "mb" : undefined}
                style={{
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none" // hides scrollbar for Webkit browsers
                  },
                  "-ms-overflow-style": "none", // hides scrollbar for IE and Edge
                  scrollbarWidth: "none" // hides scrollbar for Firefox
                }}>
                {notes.length > 0 ? (
                  <List size="sm" type="ordered">
                    {notes.map((note) => (
                      <Fragment key={note.id}>
                        <Flex justify={"space-between"} align={"center"} key={note.id}>
                          <Flex align={"flex-start"}>
                            <List.Item key={note.id} ml={"xs"} pb="xs">
                              <Flex align={"center"}>
                                <div className="ml-1 max-w-[750px] flex flex-col text-base gap-2">
                                  <Text fw={600}>{note.title}</Text>
                                  {note.description && (
                                    <>
                                      <Text>{note.description}</Text>
                                    </>
                                  )}

                                  <Flex gap={10}>
                                    <Text fz="xs" my={3}>
                                      {note.author}
                                    </Text>
                                    <Text c="dimmed" fz="xs" my={3}>
                                      ({format(parseISO(note.date), "MMM dd, yyyy")})
                                    </Text>
                                  </Flex>
                                </div>
                              </Flex>
                            </List.Item>
                          </Flex>

                          <Flex>
                            <ActionIcon
                              variant="subtle"
                              mr={"xs"}
                              color="gray.7"
                              size={"sm"}
                              onClick={(e) => {
                                // e.stopPropagation()
                                handleHideShowNoteClick(note);
                              }}>
                              {note.hide_note ? (
                                <Tooltip label={"Show"} position="top">
                                  <FontAwesomeIcon icon={solid("eye-slash")} size="sm" />
                                </Tooltip>
                              ) : (
                                <Tooltip label={"Hide"} position="top">
                                  <FontAwesomeIcon icon={solid("eye")} size="sm" />
                                </Tooltip>
                              )}
                            </ActionIcon>
                            <Menu withinPortal>
                              <Menu.Target>
                                <ActionIcon
                                  variant="outline"
                                  color="gray.7"
                                  size={"sm"}
                                  onClick={(e) => e.stopPropagation()}>
                                  <FontAwesomeIcon icon={solid("ellipsis-vertical")} />
                                </ActionIcon>
                              </Menu.Target>

                              <Menu.Dropdown ml={-12}>
                                <Menu.Item
                                  fz="xs"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (isActiveUserViewer) {
                                      return;
                                    }
                                    setNoteType(NoteTypes.UPDATE);
                                    setNoteToUpdate(note);
                                    setNotesModelOpened(true);
                                  }}
                                  style={{
                                    cursor: isActiveUserViewer ? "default" : "pointer"
                                  }}
                                  leftSection={<FontAwesomeIcon icon={regular("edit")} />}>
                                  <Tooltip
                                    label={
                                      "Viewer cannot edit notes. Only the Owner/Admin of the note can edit it."
                                    }
                                    position="top"
                                    disabled={!isActiveUserViewer}>
                                    <Text
                                      style={{
                                        lineHeight: "0px"
                                      }}>
                                      Edit
                                    </Text>
                                  </Tooltip>
                                </Menu.Item>
                                <Menu.Divider />
                                <Menu.Item
                                  fz="xs"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (isActiveUserViewer) {
                                      return;
                                    }
                                    deleteNotePrompt(note);
                                  }}
                                  style={{
                                    cursor: isActiveUserViewer ? "default" : "pointer"
                                  }}
                                  color="red.4"
                                  leftSection={<FontAwesomeIcon icon={regular("trash-alt")} />}>
                                  <Tooltip
                                    label={
                                      "Viewer cannot delete notes. Only the Owner/Admin of the note can delete it."
                                    }
                                    position="top"
                                    disabled={!isActiveUserViewer}>
                                    <Text
                                      style={{
                                        lineHeight: "0px"
                                      }}>
                                      Delete
                                    </Text>
                                  </Tooltip>
                                </Menu.Item>
                              </Menu.Dropdown>
                            </Menu>
                          </Flex>
                        </Flex>
                        <Divider mb="xs" />
                      </Fragment>
                    ))}
                  </List>
                ) : (
                  <>
                    <Box>
                      <Text fz="sm" fw={400} ta="center">
                        You do not have any notes yet. Please create one.
                      </Text>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Popover.Dropdown>
        </Popover>
      )}

      {/* NotesModel component */}
      <NotesModel
        opened={notesModelOpened}
        setOpened={setNotesModelOpened}
        type={noteType}
        applyNoteChanges={applyNoteChanges}
        data={noteToUpdate}
      />

      {/* Notes Deatil View Model componnet */}
      <NotesDetailView
        isModalOpen={notesDetailViewOpened}
        setIsModalOpen={setNotesDetailViewOpened}
        notes={notes}
        notesLoading={notesLoading}
        applyNoteChanges={applyNoteChanges}
        noteVisibilityType={noteVisibilityType}
        setNoteVisibilityType={setNoteVisibilityType}
        getAnnotationNotes={getAnnotationNotes}
      />
    </>
  );
};

export default NotesPopoverCard;
