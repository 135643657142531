import { BillingService } from "@/lib/services/BillingService";
import { Center, SegmentedControl } from "@mantine/core";
import { useEffect, useState } from "react";

export const LifetimePlanTypeSwitch = ({
  activePricing,
  setActivePricing
}: {
  activePricing: "pro" | "premium";
  setActivePricing: (value: "pro" | "premium") => void;
}) => {
  const [slots, setSlots] = useState(0);
  useEffect(() => {
    new BillingService()
      .slots()
      .then((res) => setSlots(res?.data?.slots || 0))
      .catch((err) => err);
  }, []);
  return (
    <Center mb="md">
      <SegmentedControl
        color="brand"
        data={[
          { value: "pro", label: "Website Analytics (Pro)" },
          { value: "premium", label: "Web + Product Analytics (Premium)" }
        ]}
        value={activePricing}
        onChange={(value) =>
          //@ts-ignore
          setActivePricing(value)
        }
      />
    </Center>
  );
};
