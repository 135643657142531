import { Box, Paper, useMantineTheme } from "@mantine/core";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { InsightShareService } from "@/lib/services/InsightShareService";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { DATE_FORMAT, LOADER_COLOR } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingStateProps } from "types/types.d";
import { StringParam, useQueryParams } from "use-query-params";
import FunnelDetails, { IFunnelStep } from "../Analyze/components/FunnelDetailedView/FunnelDetails";
import { useSessionStorage } from "@mantine/hooks";
import { useInsightShareableStore } from "@/stores/useInsightShareableStore";
import { ShareDashboardUnauthorized } from "@/ui/components/App/Share/ShareDashboardUnauthorized";

type Params = {
  secret: string;
};

const InsightsShareableFunnel = () => {
  const [query, setQuery] = useQueryParams({
    hide_header: StringParam,
    hide_footer: StringParam,
    start_date: StringParam,
    end_date: StringParam
  });

  const [insightId] = useInsightShareableStore((state) => [state.insightId]);

  /**
   * Method responsible for handling date change
   */
  const handleDateChange = (startDate: string | undefined, endDate: string | undefined) => {
    console.log(startDate, endDate);
    setQuery({ start_date: startDate, end_date: endDate });
  };
  useEffect(() => {
    // Set the default start date and end date.
    const startDate = format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT);
    const endDate = format(endOfDay(new Date()), DATE_FORMAT);
    setQuery({
      ...query,
      start_date: startDate,
      end_date: endDate
    });
  }, []);

  return (
    <Paper my="md" withBorder shadow="sm">
      <Box
        component="div"
        sx={() => {
          return {
            padding: "2rem"
          };
        }}>
        <FunnelDetails
          isPublic={true}
          id={insightId}
          selectedFunnel={[]}
          selectedMeta={undefined}
          date={query}
          onDateChange={handleDateChange}
          initialActiveTabType={{
            activeView: "overview",
            selectedOption: "none"
          }}
        />
      </Box>
    </Paper>
  );
};

export default InsightsShareableFunnel;
