import { useMantineColorScheme, useMantineTheme } from "@mantine/core";
import React from "react";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const renderSourceIcon = (url: string, props?: React.StyleHTMLAttributes<HTMLImageElement>) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  let iconUrl = `https://favicon.usermaven.com/?url=${
    url.length > 0 ? url : "temp-mail.org"
  }&size=32${colorScheme === "dark" ? "&fallback=default-white.svg" : ""}`;
  if (url.startsWith("http")) {
    url = url.replaceAll("http://", "").replaceAll("https://", "");
    iconUrl = `https://favicon.usermaven.com/?url=${url}&size=32${
      colorScheme === "dark" ? "&fallback=default-white.svg" : ""
    }`;
  }

  // if well known source, use the icon
  const wellKnownSources = [
    "google",
    "facebook",
    "twitter",
    "linkedin",
    "instagram",
    "youtube",
    "reddit",
    "pinterest",
    "tumblr",
    "tiktok",
    "yahoo",
    "bing",
    "yandex",
    "wikipedia",
    "amazon",
    "ebay",
    "walmart",
    "etsy",
    "quora",
    "stackoverflow",
    "github",
    "stackoverflow",
    "medium",
    "wordpress",
    "blogger",
    "twitch",
    "netflix",
    "hulu",
    "disney"
  ];

  if (wellKnownSources.includes(url.toLowerCase())) {
    const link = wellKnownSources.find((s) => url.toLowerCase().includes(s));
    if (link) {
      iconUrl = `https://favicon.usermaven.com/?url=${link}.com&size=32${
        colorScheme === "dark" ? "&fallback=default-white.svg" : ""
      }`;
    }
  }
  return (
    <img
      height={16}
      src={iconUrl}
      alt=""
      {...props}
      // on favicon url error, use the default favicon
      onError={(e) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.src = `https://favicon.usermaven.com/?url=temp-mail.org&size=32${
          colorScheme === "dark" ? "&fallback=default-white.svg" : ""
        }`;
      }}
    />
  );
};

const renderSource = (source: string) => {
  if (source.startsWith("http")) {
    source = source
      .replaceAll("http://www.", "")
      .replaceAll("https://www.", "")
      .replaceAll("http://", "")
      .replaceAll("https://", "");
    source = source.split("/")[0];
    return source;
  }
  return source;
};

export { renderSourceIcon, renderSource };
