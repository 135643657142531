import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { InsightTemplateType } from "@/types/types.d";
import { Box, Button, Center, Flex, Text, Title } from "@mantine/core";
import { FC } from "react";

interface InsightsDashboardSetupReportProps {
  title?: string;
  description?: string;
  buttonText?: string;
  setInsightType?: React.Dispatch<React.SetStateAction<InsightTemplateType>>;
  setShowInsightSetupModal: (e: boolean) => void;
}

export const InsightsDashboardSetupReport: FC<InsightsDashboardSetupReportProps> = ({
  title = "Create your first insight",
  description = "Proactively convert anonymous visitors to customers.",
  buttonText = "Create goal",
  setShowInsightSetupModal
}) => {
  const { hasRolesWith } = useWorkspaceUtilityHook();
  return (
    <>
      <Flex direction={"column"} align={"center"}>
        {hasRolesWith([WORKSPACE_MEMBER_ROLES.VIEWER]) ? (
          <>
            <Box mt={"sm"}>
              <Title ta="center" py="sm" order={4}>
                You do not have access to perform this action
              </Title>
              <Text mb="md" ta="center">
                Please ask your workspace owner to allow you to perform this action.
              </Text>
            </Box>
          </>
        ) : (
          <Box mt={"sm"}>
            <Title ta="center" py="sm" order={4}>
              {title}
            </Title>
            <Text mb="md" ta="center">
              {description}
            </Text>
            <Center>
              <Button
                onClick={() => {
                  setShowInsightSetupModal(true);
                }}>
                {buttonText}
              </Button>
            </Center>
          </Box>
        )}
      </Flex>
    </>
  );
};
