export const attributeOptions = {
  string_or_number: [
    {
      name: "is equal to",
      value: "eq"
    },
    {
      name: "is not equal to",
      value: "!eq"
    },
    {
      name: "is greater than",
      value: "gt"
    },
    // {
    //   name: "is between",
    //   value: "bw",
    // },
    {
      name: "exists",
      value: "exists"
    },

    {
      name: "does not exist",
      value: "!exists"
    }
  ],
  string: [
    {
      name: "contains",
      value: "contains"
    },
    {
      name: "does not contain",
      value: "!contains"
    }
  ]
};

export const attributeOptionsList = [
  {
    label: "is equal to",
    value: "eq"
  },
  {
    label: "is not equal to",
    value: "!eq"
  },
  {
    label: "is greater than",
    value: "gt"
  },
  // {
  //   label: "is between",
  //   value: "bw",
  // },
  {
    label: "exists",
    value: "exists"
  },

  {
    label: "does not exist",
    value: "!exists"
  },

  {
    label: "contains",
    value: "contains"
  },
  {
    label: "does not contain",
    value: "!contains"
  }
];

export const attributeGroupedOptionsList = [
  {
    group: "String or Number",
    items: [
      { label: "is equal to", value: "eq" },
      { label: "is not equal to", value: "!eq" },
      { label: "is greater than", value: "gt" },
      { label: "exists", value: "exists" },
      { label: "does not exist", value: "!exists" },
      { label: "contains", value: "contains" },
      { label: "does not contain", value: "!contains" }
    ]
  },
  {
    group: "Timestamp",
    items: [
      { label: "is a timestamp after", value: "timestamp_gt" },
      { label: "is a timestamp before", value: "timestamp_lt" }
    ]
  }
];
export const eventOptions = [
  {
    label: "has been performed",
    value: "true"
  },
  {
    label: "has not been performed",
    value: "false"
  }
];

export const pinnedEventValueOptions = [
  {
    label: "has been performed",
    value: "true"
  }
];

export const pageviewOptions = [
  {
    group: "Standard",
    items: [
      { label: "is", value: "eq" },
      {
        label: "is not equal to",
        value: "!eq"
      },
      { label: "contains", value: "contains" },
      { label: "does not contain", value: "!contains" },
      // These are implemented with regex in the backend
      { label: "starts with", value: "starts_with", implementation: "regex" },
      { label: "ends with", value: "ends_with", implementation: "regex" }
    ]
  },
  {
    group: "Regex",
    items: [
      // These are implemented with regex in the backend
      { label: "matches pattern", value: "matches_pattern", implementation: "regex" },
      { label: "matches regex", value: "matches_regex", implementation: "regex" }
    ]
  }
];
// export const pageviewOptions = [
//   {
//     label: "is equal to",
//     value: "eq",
//     group: "Standard"
//   },
//   {
//     label: "is not equal to",
//     value: "!eq",
//     group: "Standard"
//   },
//   {
//     label: "contains",
//     value: "contains",
//     group: "Standard"
//   },
//   {
//     label: "does not contain",
//     value: "!contains",
//     group: "Standard"
//   },
//   {
//     label: "starts with",
//     value: "starts_with",
//     group: "Standard"
//   },
//   {
//     label: "ends with",
//     value: "ends_with",
//     group: "Standard"
//   },
//   {
//     label: "matches pattern",
//     value: "matches_pattern",
//     group: "Regex"
//   },
//   {
//     label: "matches regex",
//     value: "matches_regex",
//     group: "Regex"
//   }
// ];

export const deviceOptionsList = [
  {
    label: "is equal to",
    value: "eq"
  },
  {
    label: "is not equal to",
    value: "!eq"
  }
];

export const pageviewSelectOptions = [
  { value: "url", label: "URL" },
  { value: "path", label: "Path" }
  // { value: "title", label: "Title" },
];
