import { Avatar, Box, Button, Flex, Paper, Title, Text, Badge } from "@mantine/core";
import roundedProfileIcon from "@assets/images/icons/rounded-profile-icon.svg";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceMemberService } from "@/lib/services/WorkspaceMemberService";
import { ORGANIZATION_STATES, TOAST_MESSAGES, WORKSPACE_MEMBER_STATE } from "@/lib/utils/Constants";
import { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { WorkspaceMemberType, WorkspaceType } from "types/types.d";

import "./workspace_item.scss";
import classes from "./WorkspaceItem.module.css";
type WorkspaceItemProps = {
  wsItem: WorkspaceType;
};

export const WorkspaceItem: React.FC<WorkspaceItemProps> = ({
  wsItem = {
    id: "",
    name: "",
    created_at: "",
    members: [],
    color: "",
    is_setup_completed: false,
    organization: {
      state: "",
      subscriptions: []
    }
  }
}) => {
  const navigate = useNavigate();
  const [isDefaultActionLoading, setIsDefaultActionLoading] = useState("idle");
  const { user, setActiveWorkspace, workspaces, setWorkspaces } = useContext(AppLifecycleContext);
  const setActiveWorkspaceAndRedirect = (workspaceItem: any) => {
    setActiveWorkspace(workspaceItem);
    const redirectURL = workspaceItem.is_setup_completed
      ? `/env/${workspaceItem.identifier}/web/statistics`
      : `/env/${workspaceItem.identifier}/setup-guides/integration`;

    navigate(redirectURL);
  };

  const handleDefaultWorkspace = async (wsMemberItem: WorkspaceMemberType, wsName: string) => {
    console.log(wsMemberItem);
    const wsMemberService = new WorkspaceMemberService();
    setIsDefaultActionLoading("loading");
    await wsMemberService
      .setDefaultWorkspace(wsMemberItem.id)
      .then((res) => {
        // Change the status for the default workspace. Iterating over WS Items and Members and setting it.
        if (res.data.id) {
          let tempWorkspacesList: any = [];
          workspaces.forEach((wsItem: any) => {
            console.log(wsItem);
            wsItem.members.forEach((memberItem: any) => {
              if (memberItem.user_id === user.id) {
                if (memberItem.id === res.data.id) {
                  memberItem.is_default_workspace = true;
                } else {
                  memberItem.is_default_workspace = false;
                }
              }
            });
            tempWorkspacesList.push(wsItem);
          });
          setWorkspaces(tempWorkspacesList);
          console.log(tempWorkspacesList, workspaces);
          toast.success(`${wsName} ${TOAST_MESSAGES.WORKSPACE_SET_AS_DEFAULT}`);
        }
      })
      .catch((err) => {});
    setIsDefaultActionLoading("loaded");
  };

  return (
    <>
      <Paper>
        <Flex
          className={classes.card}
          direction={"column"}
          justify={"space-evenly"}
          p={"sm"}
          component="div"
          h={200}
          key={`workspace-box-${wsItem.id}`}
          // className="um-workspace-item--box shadow-sm"
          onClick={() => {
            setActiveWorkspaceAndRedirect(wsItem);
          }}>
          <Avatar size={"sm"} radius={"xl"} className={` ${wsItem?.color}`} autoContrast>
            <Text>{wsItem?.name.substring(0, 1)}</Text>
          </Avatar>

          <Title order={5} fw={500} py={"sm"}>
            {wsItem.name}
          </Title>
          <Flex align="center">
            <Flex flex="1">
              <Text c="dimmed" fs={"italic"}>
                Created:{" "}
                {Intl.DateTimeFormat("en-us", {
                  year: "numeric",
                  month: "short",
                  day: "numeric"
                }).format(new Date(wsItem.created_at))}
              </Text>
            </Flex>
            {wsItem?.organization?.subscriptions.length > 0 &&
              wsItem?.organization?.subscriptions[0].paddle_status === "paused" && (
                <>
                  <Badge radius={"sm"} variant="outline" color="orange">
                    Paused
                  </Badge>
                </>
              )}

            {wsItem?.organization?.subscriptions.length > 0 &&
              wsItem?.organization?.subscriptions[0].paddle_status === "deleted" && (
                <>
                  <Badge radius={"sm"} variant="outline" color="red">
                    Cancelled
                  </Badge>
                </>
              )}

            {wsItem?.organization?.state === ORGANIZATION_STATES.QUOTA_EXCEEDED && (
              <>
                <Badge radius={"sm"} variant="outline" color="red">
                  Quota Exceeded
                </Badge>
              </>
            )}
          </Flex>

          <Flex align={"center"}>
            <div className="um-workspace-item--box--members">
              {wsItem?.members.map((memberItem, index) => (
                <div className="um-workspace-item--box--members--profile">
                  {index < 4 && (
                    <Fragment key={`profile-image-${memberItem.id}`}>
                      {/* <>{findMemberDetails(memberItem.id)}</> */}
                      {memberItem?.user?.profile_image ? (
                        <img
                          src={`${memberItem?.user?.profile_image}-/scale_crop/50x50/smart/`}
                          alt=""
                        />
                      ) : (
                        <img src={roundedProfileIcon} alt="" />
                      )}
                    </Fragment>
                  )}
                </div>
              ))}
              {wsItem.members.length - 4 > 0 && (
                <span className="um-workspace-item--box--members--profile-count">
                  {wsItem.members.length - 4}+
                </span>
              )}
            </div>
            {wsItem.members.map((wsMemberItem) => (
              <Fragment key={`ws-member-${wsMemberItem.id}`}>
                {wsMemberItem.user_id === user.id && wsMemberItem.is_default_workspace ? (
                  <Badge radius={"sm"} variant="outline" key={`default-${wsMemberItem.id}`}>
                    Default
                  </Badge>
                ) : (
                  <>
                    {wsMemberItem.user_id === user.id &&
                      !wsMemberItem.is_default_workspace &&
                      wsMemberItem.state === WORKSPACE_MEMBER_STATE.JOINED &&
                      wsItem.is_setup_completed && (
                        <div
                          className="um-workspace-item--box--set-as-default-workspace"
                          key={`set-as-default-${wsMemberItem.id}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDefaultWorkspace(wsMemberItem, wsItem.name);
                          }}>
                          <Button
                            variant="outline"
                            size="compact-xs"
                            disabled={isDefaultActionLoading === "loading"}
                            loading={isDefaultActionLoading === "loading"}>
                            Set as default
                          </Button>
                        </div>
                      )}
                    {wsMemberItem.user_id === user.id &&
                      !wsMemberItem.is_default_workspace &&
                      wsMemberItem.state === WORKSPACE_MEMBER_STATE.JOINED &&
                      !wsItem.is_setup_completed && (
                        <Badge
                          color="orange"
                          variant="outline"
                          radius={"sm"}
                          key={`setup-remaining-${wsMemberItem.id}`}>
                          Setup remaining
                        </Badge>
                      )}

                    {/* {wsMemberItem.user_id === user.id &&
                    !wsMemberItem.is_default_workspace &&
                    wsMemberItem.state === WORKSPACE_MEMBER_STATE.INVITED &&
                    !wsItem.is_setup_completed && (
                      <div
                        className="um-workspace-item--box--invited"
                        key={`invited-${wsMemberItem.id}`}
                      >
                        <span>Invited</span>
                      </div>
                    )} */}
                  </>
                )}
              </Fragment>
            ))}
          </Flex>
        </Flex>
      </Paper>
    </>
  );
};
