import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import axios from "@/lib/services/JWTService";
import { SegmentsService } from "@/lib/services/SegmentsService";
import { PLAN_PRODUCT_TYPE } from "@/lib/utils/Constants";
import { truncateText } from "@/lib/utils/StringUtility";
import { useCustomizeMenuStore } from "@/stores/useCustomizeMenuStore";
import { useSegmentFilterStore } from "@/stores/useSegmentFilterStore";
import { useSegmentListStore } from "@/stores/useSegmentListStore";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { LooseObject } from "@/types/types.d";
import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Group,
  Paper,
  Skeleton,
  Stack,
  Text,
  Title,
  Tooltip
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./SidePane.module.css";

export interface SidePaneProps {
  type?: "visitors" | "users" | "companies";
}

const SidePane = ({ type = "visitors" }: SidePaneProps) => {
  // const { classes } = useStyles();
  const { activeWorkspace, isCollapsedLeftMenu, setIsCollapsedLeftMenu } =
    useContext(AppLifecycleContext);
  const isWebPlanType = activeWorkspace.organization.plan.plan_type === PLAN_PRODUCT_TYPE.WEB;

  // Get segment from the params.
  const { segment: segmentId } = useParams<{ segment: string }>();
  const [list, setList] = useState([]);
  const [segmentCountList, setSegmentCountList] = useState<any>();
  const [countLoading, setCountLoading] = useState("idle");
  const [loading, setLoading] = useState("loading");
  const [counts, setCounts] = useState<any>({
    visitors: 0,
    users: 0,
    companies: 0
  });
  const navigate = useNavigate();

  const [deleteSegment, segmentType, setSegments, refetch] = useSegmentListStore((state) => [
    state.deleteSegment,
    state.segmentType,
    state.setSegments,
    state.refetch
  ]);
  const [filterVisible, setFilterVisible] = useSegmentFilterStore((state) => [
    state.filterVisible,
    state.setFilterVisible
  ]);

  const [fetchMenuItems, hiddenMenuItemsList, internalAside, setInternalAside] =
    useCustomizeMenuStore((state: any) => [
      state.fetchMenuItems,
      state.hiddenMenuItemsList,
      state.internalAside,
      state.setInternalAside
    ]);

  const [modalOpen, setModalOpen, setFilters, setName, setId, reset, setDescription, setType] =
    useSegmentStore((state) => [
      state.modalOpen,
      state.setModalOpen,
      state.setFilters,
      state.setName,
      state.setId,
      state.reset,
      state.setDescription,
      state.setType
    ]);

  const getAllCounts = async () => {
    const data = await new SegmentsService()
      .allCounts(activeWorkspace.id)
      .then((res) => {
        if (res.data) {
          setCounts(res.data);
          return res.data;
        }
      })
      .catch((err) => {
        return [];
      });
    return data;
  };

  const fetchSegments = async () => {
    if (list.length === 0) {
      setLoading("loading");
    }
    const data = await new SegmentsService()
      .getAll(activeWorkspace.id, "all")
      .then((res) => {
        if (res.data) {
          setList(res.data);
          fetchSegmentCounts(activeWorkspace.id, res.data, true);
          return res.data;
        }
      })
      .catch((err) => {
        return [];
      });

    setLoading("loaded");
  };

  const fetchSegmentCounts = async (workspaceId: string, list: any[], force: boolean = false) => {
    if (list.length === 0) {
      setLoading("loading");
    }

    let segmentHistoryRequests: any = [];
    list.map((item: LooseObject) => {
      if (!force) {
        if (!segmentCountList?.hasOwnProperty(item.id)) {
          console.log("Request pushed to array.", item.id);
          segmentHistoryRequests.push(new SegmentsService().count(workspaceId, item.id));
        }
      } else {
        segmentHistoryRequests.push(new SegmentsService().count(workspaceId, item.id));
      }
    });

    // send all requests in parallel.
    let segmentCountResponse: LooseObject = segmentCountList ? segmentCountList : {};

    await axios
      .all(segmentHistoryRequests)
      .then(
        axios.spread((...responses) => {
          responses.map((response: any, index: number) => {
            // console.log(response.data);
            if (response.data) {
              segmentCountResponse[response.data.id] = response.data;
            }
          });
        })
      )
      .catch((errors) => {
        console.log(errors);
        // react on errors.
      });
    // console.log(segmentCountResponse);
    console.log(segmentCountResponse);
    setSegmentCountList(segmentCountResponse);
    setCountLoading("loaded");
  };

  useEffect(() => {
    // setLeftPaneCollapsePreference(isCollapsedLeftMenu);
    // setIsCollapsedLeftMenu(true);

    return () => {
      // setIsCollapsedLeftMenu(getLeftPaneCollapsePreference());
    };
  }, [activeWorkspace]);

  useEffect(() => {
    getAllCounts();

    if (!modalOpen) {
      fetchSegments();
    }
  }, [modalOpen, refetch]);

  const removeSegmentDialog = (segment: any) => {
    openConfirmModal({
      title: "Delete Segment",
      styles: {
        title: {
          fontWeight: 600
        }
      },
      children: (
        <Text size="sm">
          Are you sure you want to delete your{" "}
          <span className="font-semibold">"{segment.name}"</span> segment?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        deleteSegment(activeWorkspace.id, segment.id).then((res) => {
          fetchSegments();
        });
      }
    });
  };

  const convertToShort = (value: number) => {
    return value.toLocaleString("en-US", {
      notation: "compact",
      compactDisplay: "short"
    });
  };

  const selectSegment = (segment: any) => {
    console.log("currentSegmentName", segment);
    const segmentType =
      segment.type === "visitor" ? "visitors" : segment.type === "company" ? "companies" : "users";

    // @ts-ignore
    setFilters(list?.find((s: any) => s.id === segment.id).filters);
    setFilterVisible(true);
    setSegments(list);
    navigate(`/env/${activeWorkspace.identifier}/${segmentType}/${segment.id}`);
  };
  return (
    <Box
      className={classes.root}
      style={{
        width: !internalAside ? "0px" : "244px",
        opacity: !internalAside ? 0 : 1
      }}>
      <Box className={classes.header}>
        <Group gap={8} wrap="nowrap">
          <Box w={16} h={16}>
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.28303 3.53305C9.40739 3.83956 9.47143 4.16813 9.47143 4.5C9.47143 4.83187 9.40739 5.16045 9.28303 5.46695C9.15866 5.77344 8.97643 6.0518 8.74684 6.28618C8.51725 6.52055 8.2448 6.70635 7.9451 6.83308C7.6454 6.9598 7.32426 7.025 7 7.025C6.67574 7.025 6.3546 6.9598 6.0549 6.83308C5.7552 6.70635 5.48275 6.52055 5.25316 6.28618C5.02357 6.0518 4.84134 5.77344 4.71697 5.46695C4.59261 5.16044 4.52857 4.83187 4.52857 4.5C4.52857 4.16813 4.59261 3.83956 4.71697 3.53305C4.84134 3.22656 5.02357 2.9482 5.25316 2.71382C5.48275 2.47945 5.7552 2.29365 6.0549 2.16692C6.3546 2.0402 6.67574 1.975 7 1.975C7.32426 1.975 7.6454 2.0402 7.9451 2.16692C8.2448 2.29365 8.51725 2.47945 8.74684 2.71382C8.97643 2.9482 9.15866 3.22656 9.28303 3.53305ZM4.5042 1.95515C3.8427 2.63043 3.47143 3.54588 3.47143 4.5C3.47143 5.45412 3.8427 6.36957 4.5042 7.04485C5.16577 7.7202 6.06349 8.1 7 8.1C7.93651 8.1 8.83423 7.7202 9.4958 7.04485C10.1573 6.36957 10.5286 5.45412 10.5286 4.5C10.5286 3.54588 10.1573 2.63043 9.4958 1.95515C8.83423 1.2798 7.93651 0.9 7 0.9C6.06349 0.9 5.16577 1.2798 4.5042 1.95515ZM12.0401 14.025H1.96003C2.04548 11.9455 3.72304 10.2875 5.77589 10.2875H8.22411C10.2797 10.2875 11.9572 11.9456 12.0401 14.025ZM5.77589 9.2125C3.08034 9.2125 0.9 11.4413 0.9 14.1879C0.9 14.6896 1.29909 15.1 1.79554 15.1H12.2045C12.7009 15.1 13.1 14.6896 13.1 14.1879C13.1 11.4413 10.9197 9.2125 8.22411 9.2125H5.77589Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.2"
              />
            </svg>
          </Box>
          <Title order={5}>Contacts Hub</Title>
        </Group>
      </Box>
      <Divider />
      <Box className={classes.section}>
        <Stack gap={20}>
          <Box
            className={`${classes.sectionItem} ${
              type === "visitors" && (!segmentId || segmentId === "everyone") ? classes.active : ""
            }`}
            style={{
              display: hiddenMenuItemsList.includes("Visitors") ? "none" : "flex"
            }}
            onClick={() => {
              // Clear filters
              reset();
              setFilterVisible(false);
              navigate(`/env/${activeWorkspace.identifier}/visitors/everyone`);
            }}>
            <Group>
              <Text className="side-pane-item-text" lineClamp={1}>
                All Visitors
              </Text>
            </Group>

            <Text className="side-pane-item-text" fw={500}>
              {counts.visitors ? convertToShort(counts.visitors) : "-"}
            </Text>
          </Box>
          <Box
            className={`${classes.sectionItem} ${
              type === "users" && (!segmentId || segmentId === "everyone") ? classes.active : ""
            } ${isWebPlanType ? "disabled" : ""}
            `}
            style={{
              display: hiddenMenuItemsList.includes("Users") ? "none" : "flex"
            }}
            onClick={() => {
              // Clear filters
              reset();
              setFilterVisible(false);
              navigate(`/env/${activeWorkspace.identifier}/users/everyone`);
            }}>
            <Group>
              <Text className="side-pane-item-text" lineClamp={1}>
                All Users
              </Text>
            </Group>

            <Text className="side-pane-item-text" fw={500}>
              {isWebPlanType ? (
                <FontAwesomeIcon icon={light("lock")}></FontAwesomeIcon>
              ) : (
                <>{counts.users ? convertToShort(counts.users) : "-"}</>
              )}
            </Text>
          </Box>
          <Box
            className={`${classes.sectionItem} ${
              type === "companies" && (!segmentId || segmentId === "everyone") ? classes.active : ""
            }  ${isWebPlanType ? "disabled" : ""}`}
            style={{
              display: hiddenMenuItemsList.includes("Companies") ? "none" : "flex"
            }}
            onClick={() => {
              // Clear filters
              reset();
              setFilterVisible(false);
              navigate(`/env/${activeWorkspace.identifier}/companies/everyone`);
            }}>
            <Group>
              <Text className="side-pane-item-text" lineClamp={1}>
                All Companies
              </Text>
            </Group>

            <Text className="side-pane-item-text" lineClamp={1} fw={500}>
              {isWebPlanType ? (
                <FontAwesomeIcon icon={light("lock")}></FontAwesomeIcon>
              ) : (
                <>{counts.companies ? convertToShort(counts.companies) : "-"}</>
              )}
            </Text>
          </Box>
        </Stack>
      </Box>
      <Divider />
      <Box
        className={classes.section}
        style={{
          flex: 1
        }}>
        <Box className={classes.sectionHeader}>
          <Group justify={"space-between"}>
            <Title fz={13} c="dimmed">
              Segments
            </Title>
            <Tooltip label={"Create new segment"} position={"right"} withinPortal>
              <ActionIcon
                variant="subtle"
                size={"xs"}
                color={"gray.6"}
                style={{
                  marginRight: "-4px"
                }}
                disabled={isWebPlanType}
                onClick={() => {
                  reset();
                  setType(segmentType === "all" ? "visitor" : segmentType);
                  setModalOpen(true);
                }}>
                <FontAwesomeIcon size={"xs"} icon={solid("plus")} className=" transform " />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Box>
        {isWebPlanType ? (
          <Box mt={"md"}>
            <Stack>
              <Text size={"sm"} color={"dimmed"}>
                Create unlimited segments with our Premium plan.
              </Text>

              <Button
                size={"xs"}
                variant={"outline"}
                onClick={() => {
                  window.open(`/env/${activeWorkspace.identifier}/account/billing`, "_blank");
                }}>
                Upgrade to Premium
              </Button>
            </Stack>
          </Box>
        ) : (
          <Stack mt={"20px"} gap={20}>
            {loading === "loading" ? (
              <>
                <Skeleton height={30} radius="xl" />
                <Skeleton height={30} radius="xl" />
              </>
            ) : (
              <>
                {list.length === 0 && (
                  <Box>
                    <Text size={"sm"} c={"dimmed"}>
                      No segments have been found. You can easily create a new one by tapping on the
                      '+' icon.
                    </Text>
                  </Box>
                )}
                {list.map((segment: any) => (
                  <Box
                    key={`segment-${segment.id}`}
                    className={`${classes.sectionItem} ${
                      segment.id === segmentId ? classes.active : ""
                    }`}
                    onClick={() => selectSegment(segment)}>
                    <Tooltip
                      label={segment.name}
                      position={"bottom-start"}
                      withinPortal
                      disabled={segment.name.length < 15}>
                      <Group
                        style={{
                          flexWrap: "nowrap"
                        }}
                        gap={"xs"}>
                        <Text
                          tt={"capitalize"}
                          className="side-pane-item-text capitalize"
                          lineClamp={1}>
                          {truncateText(segment.name, 15)}
                        </Text>
                        <div className={classes.sectionItemPill}>
                          <span>
                            {segment.type === "visitor" ? "V" : segment.type === "user" ? "U" : "C"}
                          </span>
                        </div>
                      </Group>
                    </Tooltip>

                    {countLoading === "loaded" ? (
                      <>
                        {segmentCountList?.hasOwnProperty(segment.id) ? (
                          <Text className="side-pane-item-text" fw={500}>
                            {convertToShort(segmentCountList[segment.id].segment_users)}
                          </Text>
                        ) : (
                          <Text className="side-pane-item-text">-</Text>
                        )}
                      </>
                    ) : (
                      <Text className="side-pane-item-text">-</Text>
                    )}

                    <div className={classes.sectionItemActions}>
                      <Tooltip label="Edit segment" position={"right"} withinPortal>
                        <ActionIcon variant="outline" size={"xs"} color={"gray.6"}>
                          <FontAwesomeIcon
                            size={"2xs"}
                            icon={solid("edit")}
                            className=" transform "
                            onClick={(event) => {
                              event.stopPropagation();
                              setFilters(segment.filters);
                              setName(segment.name);
                              setId(segment.id);
                              setDescription(segment.description);
                              setType(segment.type);
                              setModalOpen(true);
                            }}
                          />
                        </ActionIcon>
                      </Tooltip>
                      <Tooltip label="Delete segment" position={"right"} withinPortal>
                        <ActionIcon
                          variant="outline"
                          color={"red"}
                          size={"xs"}
                          onClick={(event) => {
                            event.stopPropagation();
                            removeSegmentDialog(segment);
                          }}>
                          <FontAwesomeIcon
                            size={"2xs"}
                            icon={solid("trash")}
                            className=" transform "
                          />
                        </ActionIcon>
                      </Tooltip>
                    </div>
                  </Box>
                ))}
              </>
            )}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(SidePane);
