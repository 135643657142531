import { green, red, yellow } from "@/style/Colors";
import styled from "styled-components";

export const PasswordStrengthContainer = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
`;

export const PasswordStrengthSpan = styled.span`
  display: flex;
  width: 8px;
  height: 5px;
  border-radius: 4px;
  margin: 0 4px 0 0;
  background-color: rgba(220, 224, 227, 0.5);
  &.weak {
    background-color: ${red} !important;
  }
  &.weak:nth-child(n + 2) {
    background-color: rgba(220, 224, 227, 0.5) !important;
  }
  &.moderate {
    background-color: ${yellow} !important;
  }
  &.moderate:nth-child(n + 3) {
    background-color: rgba(220, 224, 227, 0.5) !important;
  }

  &.strong {
    background-color: ${green} !important;
  }
  &.strong:nth-child(n + 4) {
    background-color: rgba(220, 224, 227, 0.5) !important;
  }

  &.very-strong {
    background-color: ${green} !important;
  }
`;
