import { useMantineTheme } from '@mantine/core';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import AppLifecycleContext from '@/lib/contexts/AppLifecycleContext';
import { UserRetentionService } from '@/lib/services/UserRetentionService';
import { DATE_FORMAT } from '@/lib/utils/Constants';
import { capitalizeFirstLetter } from '@/lib/utils/StringUtility';
import cloneDeep from 'lodash.clonedeep';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { purple } from '@/style/Colors';
import { LooseObject, IReportType } from 'types/types.d';
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export default function useRetentionCohortHook() {
  const theme = useGlobalMantineTheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [userRetentionMessage, setUserRetentionMessage] = useState<string>('');
  const [userRetentionHistogram, setUserRetentionHistogram] = useState<any>({
    color: [purple],
    title: {
      text: ''
    },
    notMerge: true,
    tooltip: {
      borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2],
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#fff',
      textStyle: {
        color: theme.colorScheme === 'dark' ? '#A6A7AB' : '#595c5f'
      },
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        label: {}
      }
    },
    legend: {
      show: false,
      data: ['Users Retention']
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      top: '10%',
      left: '0%',
      right: '0%',
      bottom: '5%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        axisLine: {
          show: false
        },
        axisLabel: {
          fontSize: 10,
          axisPointer: {
            show: false
          },
          margin: 14
        },
        splitLine: {
          show: false
        },
        axisTick: {
          splitNumber: 4
        }
      }
    ],
    yAxis: [
      {
        min: 0,
        max: 100,
        type: 'value',
        splitLine: true,
        alignTicks: true,
        axisTick: {
          show: true,
          splitNumber: 4
        }
      }
    ],
    series: []
    // replaceMerge: ['series']
  });
  const [isUserRetentionHistogramLoading, setIsUserRetentionHistogramLoading] = useState('idle');

  const fetchUserRetentionHistogram = async (
    insightId: string | undefined,
    retentionFrequency: 'daily' | 'weekly' | 'monthly',
    reportType: IReportType,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>,
    startDate = format(
      startOfDay(subDays(new Date(), retentionFrequency === 'daily' ? 30 : 90)),
      DATE_FORMAT
    ),
    endDate = format(endOfDay(new Date()), DATE_FORMAT)
  ) => {
    const userRetentionService = new UserRetentionService();
    setIsUserRetentionHistogramLoading('loading');
    await userRetentionService
      .histogram(
        activeWorkspace.id,
        insightId,
        startDate,
        endDate,
        retentionFrequency,
        reportType,
        audienceConditions,
        audienceConditionType,
        audienceGroupConditions
      )
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          const retentionDaysRatio: any = Object.entries(res.data)
            .filter((item) => item[0] === 'signed_up' || item[0].includes('_percentage'))
            .map((item: any) => {
              console.log(item);
              if (item[0] === 'signed_up' || item[0].includes('_percentage')) {
                return item[0] === 'signed_up' ? 100 : item[1] || 0;
              }
            });
          const retentionListOfDays: any = Object.entries(res.data)
            .filter((item) => item[0] === 'signed_up' || item[0].includes('_percentage'))
            .map((item: any) => {
              console.log(item);
              if (item[0] === 'signed_up' || item[0].includes('_percentage')) {
                return capitalizeFirstLetter(
                  item[0]
                    .replace('_', ' ')
                    .replace('percentage_avg', '')
                    .replace('D', 'Day ')
                    .replace('M', 'Month ')
                    .replace('W', 'Week ')
                );
              }
            });
          console.log(retentionDaysRatio);
          userRetentionHistogram.xAxis[0].data = retentionListOfDays;
          userRetentionHistogram.series = [
            {
              name: `${reportType == 'user' ? 'Users' : 'Companies'} Retention`,
              emphasis: {
                focus: 'series'
              },
              data: retentionDaysRatio,
              type: 'line',
              markLine: {
                symbol: 'none',
                animationDuration: 0,
                lineStyle: {
                  color: theme.colorScheme === 'dark' ? '#A6A7AB' : '#242424'
                },
                data: [
                  {
                    name: 'Retention',
                    xAxis: 1,
                    label: {
                      color: theme.colorScheme === 'dark' ? '#A6A7AB' : '#242424',
                      padding: [0, 0, 0, 60],
                      fontWeight: 'bold',
                      formatter: `${retentionDaysRatio[1]}% of the ${
                        reportType == 'user' ? 'users' : 'companies'
                      } returned on ${retentionListOfDays[1]}`
                    }
                  }
                ]
              }
            }
          ];
          console.log('UserRetentionHistogram', userRetentionHistogram);
          setUserRetentionMessage(
            `<span className="font-bold">${retentionDaysRatio[1]}%</span> of the ${
              reportType == 'user' ? 'users' : 'companies'
            } returned on <span className="font-bold">${retentionListOfDays[1]}</span>`
          );
          setUserRetentionHistogram(cloneDeep(userRetentionHistogram));
        }
      })
      .catch();
    setIsUserRetentionHistogramLoading('loaded');
  };

  return {
    isUserRetentionHistogramLoading,
    fetchUserRetentionHistogram,
    userRetentionHistogram,
    userRetentionMessage
  };
}
