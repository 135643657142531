import { endOfDay, format, startOfDay, subDays, subMonths } from "date-fns";
import { LooseObject } from "types/types.d";
import { DATE_FORMAT } from "./Constants";

export const getCohortDateRangesByFrequency = (
  frequency: "daily" | "weekly" | "monthly" | undefined
): LooseObject => {
  const ranges = {};
  switch (frequency) {
    case "daily":
      return {
        "Last 14 days": [
          format(startOfDay(subDays(new Date(), 14)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT),
        ],
        "Last 30 days": [
          format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT),
        ],
        "Last 60 days": [
          format(startOfDay(subDays(new Date(), 60)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT),
        ],
        "Last 90 days": [
          format(startOfDay(subDays(new Date(), 60)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT),
        ],
      };
    case "weekly":
    case "monthly":
      return {
        "Last 3 months": [
          format(startOfDay(subMonths(new Date(), 3)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT),
        ],
        "Last 6 months": [
          format(startOfDay(subMonths(new Date(), 6)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT),
        ],
        "Last 9 months": [
          format(startOfDay(subMonths(new Date(), 9)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT),
        ],
        "Last 12 months": [
          format(startOfDay(subMonths(new Date(), 12)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT),
        ],
      };
  }
  return ranges;
};

export const getCohortDateDefaultLabelByFrequency = (
  frequency: "daily" | "weekly" | "monthly" | undefined
): string => {
  return frequency === "daily" ? "Last 30 days" : "Last 3 months";
};

export const getCohortDefaultDateRangeByFrequency = (
  frequency: "daily" | "weekly" | "monthly" | undefined
): LooseObject => {
  return frequency === "daily"
    ? {
        // last 30 days
        startDate: format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
        endDate: format(endOfDay(new Date()), DATE_FORMAT),
      }
    : {
        // last 90 days
        startDate: format(startOfDay(subDays(new Date(), 90)), DATE_FORMAT),
        endDate: format(endOfDay(new Date()), DATE_FORMAT),
      };
};
