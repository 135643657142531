import { brands, light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor, Box, Divider, Flex, Title, Tooltip, useMantineTheme } from "@mantine/core";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import ReactTooltip from "react-tooltip";
import {
  HeaderNavigationActions,
  HeaderNavigationContainer,
  HeaderNavigationH1
} from "../HeaderNavigation/HeaderNavigation.style";
import { ShareDashboardDropdown } from "./ShareDashboardDropdown";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const WebAnalyticsHeader = ({ isPublic }: { isPublic: boolean }) => {
  const { hasRolesNotWith } = useWorkspaceUtilityHook();
  const theme = useGlobalMantineTheme();

  if (isPublic) {
    return <></>;
  }
  return (
    <>
      <Flex align={"center"} mb={"md"}>
        <Flex flex={1} align={"center"}>
          <Title order={3} fw={700} mr={8}>
            Web Analytics
          </Title>
          <Anchor className="" href="https://www.youtube.com/watch?v=33BgrXr9Q8I" target={"_blank"}>
            <Tooltip label="Learn more about web analytics" style={{ fontWeight: 600 }}>
              <FontAwesomeIcon
                id="web-analytics-youtube-video"
                style={{
                  height: "16px",
                  width: "16px",
                  cursor: "pointer",
                  color: theme.colors.red[6]
                }}
                icon={brands("youtube")}
              />
            </Tooltip>
          </Anchor>
        </Flex>
        <Flex>
          {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
            <>
              <ShareDashboardDropdown />
            </>
          )}
        </Flex>
      </Flex>

      <Divider my="md" />
      {/* <HeaderNavigationContainer theme={theme}>
        <HeaderNavigationH1>
          Web Analytics <Box component={"span"} ml={"sm"}></Box>
        </HeaderNavigationH1>
        <HeaderNavigationActions>
          {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
            <>
              <ShareDashboardDropdown />
            </>
          )}
        </HeaderNavigationActions>
      </HeaderNavigationContainer> */}
    </>
  );
};
