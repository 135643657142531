// @ts-ignore
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Button, Flex, Text } from "@mantine/core";
import { differenceInDays } from "date-fns";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const TrialPlanNotification = () => {
  const [isTrialDismissed, setIsTrialDismissed] = useState(true);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    // 240
    <Box pos={"relative"}>
      {activeWorkspace?.organization?.plan.name.endsWith("-trial") &&
        isTrialDismissed &&
        !activeWorkspace?.organization.is_trial_expired && (
          <>
            <Alert
              variant="light"
              color="yellow.2"
              withCloseButton
              closeButtonLabel="Dismiss"
              className="flex justify-center"
              onClose={() => setIsTrialDismissed(false)}>
              <Flex align="center" justify={"center"}>
                <Box w={16} mt={-4}>
                  <FontAwesomeIcon size="lg" icon={regular("info-circle")} />
                </Box>

                {/* Discounted message if the trial is ending in 15 days */}
                {differenceInDays(
                  new Date(activeWorkspace?.organization.trial_expires_at.replace(" ", "T")),
                  new Date()
                ) < 15 ? (
                  <>
                    <Text ml={"sm"} fw={500}>
                      Your{" "}
                      <Text span fw={700} tt="capitalize">
                        {activeWorkspace?.organization?.plan.name.split("-")[0]}
                      </Text>{" "}
                      plan trial ends in{" "}
                      <Text span fw={700}>
                        {differenceInDays(
                          new Date(
                            activeWorkspace?.organization.trial_expires_at.replace(" ", "T")
                          ),
                          new Date()
                        )}{" "}
                        days
                      </Text>
                      . Upgrade today and save 50% on your first payment.
                      {!location.pathname.includes(
                        `/env/${activeWorkspace.identifier}/account/billing`
                      ) && (
                        <Button
                          ml={8}
                          color="orange.6"
                          variant="filled"
                          size="xs"
                          onClick={() =>
                            navigate(
                              `/env/${activeWorkspace.identifier}/account/billing?upgrade=true`
                            )
                          }>
                          Upgrade Now
                        </Button>
                      )}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text ml={"sm"}>
                      Your{" "}
                      <Text span fw={700} tt="capitalize">
                        {activeWorkspace?.organization?.plan.name.split("-")[0]}
                      </Text>{" "}
                      plan trial ends in{" "}
                      <Text span fw={700}>
                        {differenceInDays(
                          new Date(
                            activeWorkspace?.organization.trial_expires_at.replace(" ", "T")
                          ),
                          new Date()
                        )}{" "}
                        days
                      </Text>
                      .{" "}
                      {!location.pathname.includes(
                        `/env/${activeWorkspace.identifier}/account/billing`
                      ) && (
                        <Button
                          ml={8}
                          color="orange.6"
                          variant="filled"
                          size="xs"
                          onClick={() =>
                            navigate(
                              `/env/${activeWorkspace.identifier}/account/billing?upgrade=true`
                            )
                          }>
                          Upgrade Now
                        </Button>
                      )}
                    </Text>
                  </>
                )}
              </Flex>
            </Alert>
          </>
        )}

      {activeWorkspace?.organization?.plan.name.endsWith("-trial") &&
        activeWorkspace.organization.is_trial_expired && (
          <>
            <Alert variant="light" color="red.6" className="flex justify-center">
              <Flex align={"center"}>
                <Box w={16}>
                  <FontAwesomeIcon size="lg" icon={regular("info-circle")} />
                </Box>

                <Text fw={700} ml={16}>
                  Your trial has finished. Please upgrade your plan.{" "}
                  {!location.pathname.includes(
                    `/env/${activeWorkspace.identifier}/account/billing`
                  ) && (
                    <Button
                      ml={8}
                      color="orange.6"
                      variant="filled"
                      size="xs"
                      onClick={() =>
                        navigate(`/env/${activeWorkspace.identifier}/account/billing?upgrade=true`)
                      }>
                      Upgrade Now
                    </Button>
                  )}
                </Text>
              </Flex>
            </Alert>
          </>
        )}
    </Box>
  );
};
