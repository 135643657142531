import { greyDark, red, yellow } from "@/style/Colors";
import styled from "styled-components";

interface ValidationLabelParagraphProps {
  size: string;
  bold: any;
  validationType: any;
}

const handleColorType = (validationType: any) => {
  let colorCode;
  switch (validationType) {
    case "error":
      colorCode = red;
      break;
    case "warning":
      colorCode = yellow;
      break;
    default:
      colorCode = greyDark;
      break;
  }
  return colorCode;
};

export const ValidationLabelParagraph = styled.p<ValidationLabelParagraphProps>`
  padding-top: 0.5rem;
  font-size: ${(props) =>
    props.size === "small" ? "0.825rem" : props.size === "medium" ? "0.875rem" : "1rem"};
  font-weight: ${(props) => (props.bold ? 500 : 400)};
  color: ${(props) => handleColorType(props.validationType)};
`;
