/* eslint-disable react-hooks/exhaustive-deps */
import { getReadableFormatNumber, numberToCommas } from "@/lib/utils/StringUtility";
import React from "react";
import { LoadingStateProps } from "@/types/types.d";
import { SummaryComparisonCard } from "../../../Cards/SummaryComparisonCard";

type Props = {
  loading: LoadingStateProps;
  comparisonData: any;
};

const TotalConversionsComparison = ({ loading, comparisonData }: Props) => {
  return (
    <div data-cy="events-card">
      <SummaryComparisonCard
        uniquekey={"uniqueVisitors"}
        loading={loading}
        comparisonValue={getReadableFormatNumber(comparisonData?.total_conversions)}
        comparisonTooltip={`${numberToCommas(comparisonData.total_conversions)} Total Conversions`}
      />
    </div>
  );
};
const TotalConversionsComparisonMemoized = React.memo(TotalConversionsComparison);
export { TotalConversionsComparisonMemoized as TotalConversionsComparison };
