/**
 * InsightsShareableDashboardMain is main component for the shareable dashboard
 * for the Product Insights module.
 *
 * The Dashboard is responsible for rendering the complete insights dashboard.
 */

import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceShareService } from "@/lib/services/WorkspaceShareService";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { lazy, useContext, useEffect, useMemo, useState } from "react";
import { Oval } from "react-loader-spinner";
import { Route, Routes, useParams } from "react-router-dom";
import { LoadingStateProps } from "types/types.d";
import { ShareDashboardFooter } from "@/ui/components/App/Share/ShareDashboardFooter";
import { ShareDashboardHeader } from "@/ui/components/App/Share/ShareDashboardHeader";
import { useSearchParams } from "react-router-dom";
import { InsightsShareableGuard } from "./InsightsShareableGuard";
import { IntlProvider, FormattedMessage } from "react-intl";

// const InsightsShareableFunnel = lazy(
//   () => import("@/ui/pages/InsightsShareableDashboard/InsightsShareableFunnel")
// )
import InsightsShareableFunnel from "./InsightsShareableFunnel";
import locale from "./locale";
import { Box } from "@mantine/core";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";

type Params = {
  wsIdentifier: string;
};
const InsightsShareableDashboardMain = () => {
  const [searchParams] = useSearchParams();

  const { wsIdentifier } = useParams<Params>();
  const [loading, setLoading] = useState<LoadingStateProps>("loading");
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);

  // lang query param
  const lang = searchParams.get("lang") || "en";

  // resolve lang from query param with usememo
  const language = useMemo(() => {
    if (lang === "jp") {
      return "jp";
    }

    return "en";
  }, [lang]);

  const onLangChange = (lang: string) => {
    searchParams.set("lang", lang);
    window.location.search = searchParams.toString();
  };

  const fetchSharingWorkspaceDetails = async () => {
    setLoading("loading");
    await new WorkspaceShareService()
      .getByIdentifier(wsIdentifier || "")
      .then((res) => {
        if (res.data) {
          setActiveWorkspace(res.data);
        }
      })
      .catch((err) => err);
    setLoading("loaded");
  };

  useEffect(() => {
    try {
      $crisp.push(["do", "chat:hide"]);
    } catch (ex) {
      console.log(ex);
    }

    fetchSharingWorkspaceDetails();
    return () => {
      try {
        $crisp.push(["do", "chat:show"]);
      } catch (ex) {
        console.log(ex);
      }
    };
  }, []);
  if (loading === "loading") {
    return <LoadingComponent />;
  }
  console.log(searchParams.get("hide_header"), searchParams);
  return (
    <>
      <IntlProvider locale={language || "en"} messages={locale[language || "en"]}>
        <Box maw={"85rem"} mx="auto">
          {!searchParams.get("hide_header") === true && (
            <ShareDashboardHeader
              showLanguageSwitcher={true}
              lang={language}
              setLang={onLangChange}
            />
          )}

          <Routes>
            <Route
              path="funnel/:secret"
              element={
                <InsightsShareableGuard>
                  <InsightsShareableFunnel />
                </InsightsShareableGuard>
              }></Route>
          </Routes>
          {!searchParams.get("hide_footer") === true && <ShareDashboardFooter />}
        </Box>
      </IntlProvider>
    </>
  );
};

export default InsightsShareableDashboardMain;
