import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { ActiveUsersTrendsService } from "@/lib/services/ActiveUsersTrendsService";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import { DATE_FORMAT } from "@/lib/utils/Constants";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { DropdownMenu } from "@/ui/components/App/Dropdowns/DropdownMenu";
import { LineGraphWrapper } from "@/ui/components/App/EChartsWrappers/LineGraphWrapper";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";
import { Box, useMantineTheme } from "@mantine/core";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import * as echarts from "echarts/core";
import { FC, useContext, useEffect, useState } from "react";
import { InsightIdType } from "types/types.d";

export const DailyActiveUsers: FC<InsightIdType> = ({ insightId, reportType = "user" }) => {
  /**
   * Segment Query builder store
   */
  const [filters] = useSegmentStore((state) => [state.filters]);

  const [isLoading, setIsLoading] = useState("idle");
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [open, setOpen] = useState(false);

  const [dailyActiveUsers, setDailyActiveUsers] = useState<Array<any>>([]);

  const getActiveUsersHistogram = async (
    startDate = format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
    endDate = format(endOfDay(new Date()), DATE_FORMAT)
  ) => {
    setIsLoading("loading");
    const activeUsersTrendsService = new ActiveUsersTrendsService();
    await activeUsersTrendsService
      .activeUsersHistogram(
        activeWorkspace.id,
        insightId,
        "daily",
        reportType,
        startDate,
        endDate,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        if (res.data) {
          setDailyActiveUsers([
            {
              name: `Daily Active ${reportType == "user" ? "Users" : "Companies"}`,
              type: "line",
              smooth: false,
              lineStyle: {
                width: 2
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgb(125, 71, 235, 0.6)"
                  },
                  {
                    offset: 1,
                    color: "rgba(125, 71, 235, 0.01)"
                  }
                ])
              },
              emphasis: {
                focus: "series"
              },
              data: res.data.slice(0, -1).map((item: any) => [item.start_day, item.count])
            },
            {
              name: `Daily Active ${
                reportType == "user" ? "Users" : "Companies"
              } (In progress current day)`,
              type: "line",
              color: "#7D47EB",
              smooth: false,
              lineStyle: {
                width: 2,
                type: "dashed"
              },
              itemStyle: {
                emphasis: {
                  label: {
                    show: false
                  }
                }
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgb(125, 71, 235, 0.3)"
                  },
                  {
                    offset: 1,
                    color: "rgba(125, 71, 235, 0.01)"
                  }
                ])
              },
              emphasis: {
                focus: "series"
              },
              data: res.data.slice(-2).map((item: any) => [item.start_day, item.count])
            }
          ]);
        }
      })
      .catch((err) => setDailyActiveUsers([]));
    setIsLoading("loaded");
  };

  useEffect(() => {
    getActiveUsersHistogram();
  }, [insightId, filters]);

  const onChangeDate = (item: any) => {
    console.log(item);
    switch (item.value) {
      case "last_7_days":
        getActiveUsersHistogram(
          format(startOfDay(subDays(new Date(), 7)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT)
        );
        break;
      case "last_14_days":
        getActiveUsersHistogram(
          format(startOfDay(subDays(new Date(), 14)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT)
        );
        break;
      case "last_30_days":
        getActiveUsersHistogram(
          format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT)
        );
        break;
      case "last_60_days":
        getActiveUsersHistogram(
          format(startOfDay(subDays(new Date(), 60)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT)
        );
        break;
      case "last_90_days":
        getActiveUsersHistogram(
          format(startOfDay(subDays(new Date(), 90)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT)
        );
        break;
      default:
        break;
    }
  };
  // =
  return (
    <>
      <InsightSection
        title={`Daily Active ${reportType == "user" ? "Users" : "Companies"}`}
        description={
          <>
            A daily active{" "}
            {reportType == "user" ? "user is someone who" : "company is the one which"} interacts
            with an app over the period of a specific day (e.g. January 1st).
          </>
        }
        rightSection={
          <DropdownMenu
            selectedOption={{
              label: "Last 30 Days",
              value: "last_30_days"
            }}
            options={[
              {
                label: "Last 7 Days",
                value: "last_7_days"
              },
              {
                label: "Last 14 Days",
                value: "last_14_days"
              },
              {
                label: "Last 30 Days",
                value: "last_30_days"
              },
              {
                label: "Last 60 Days",
                value: "last_60_days"
              },
              {
                label: "Last 90 Days",
                value: "last_90_days"
              }
            ]}
            onChange={onChangeDate}></DropdownMenu>
        }>
        <Box w={"100%"}>
          {isLoading === "loaded" ? (
            <>
              {dailyActiveUsers.length > 0 ? (
                <LineGraphWrapper series={dailyActiveUsers} useGenericTooltip={true} />
              ) : (
                <NoResults heading={"No Results"} text={"There are no results."} />
              )}
            </>
          ) : (
            <BarChartSkeleton />
          )}
        </Box>
      </InsightSection>
    </>
  );
};
