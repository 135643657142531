import create from 'zustand';
import { MavenAIService } from "@/lib/services/MavenAIService";

export type TChat = {
  chat_id: string;
  title: string;
}

export type TChatMessage = {
  id?: string;
  user_name?: string;
  role: 'user' | 'assistant' | 'system';
  content: string | any[] | object;
  responses?: TChatMessage[]
}

/**
 * States involved in this store
 */
type State = {
  chats: Record<string, TChat[]>
  chatsOrder: string[];
  messages: TChatMessage[];
  activeChat: TChat | null;
  fetchingChats: boolean;
  fetchingMessages: boolean;
  inputMessage: string;
  waitingForResponse: boolean;
  flag: 'mine' | 'all';
}

/**
 * Actions involved in this store
 */
type Actions = {
  /**
   * Setter for input message
   */
  setInputMessage: (message: string) => void;
  /**
   * Set chats in the store for side pane
   * @param chats
   */
  setChats: (chats: Record<string, TChat[]>) => void;

  /**
   * Set chats order in the store for side pane
   */
  setChatsOrder: (chatsOrder: string[]) => void;

  /**
   * Set messages in the store for chat screen
   * @param messages
   */
  setMessages: (messages: TChatMessage[]) => void;

  /**
   * Set active chat for reference
   * @param chat
   */
  setActiveChat: (chat: TChat | null) => void;

  /**
   * Set waiting for response
   */
  setWaitingForResponse: (waiting: boolean) => void;

  /**
   * Add chat to the chats list
   * @param chat
   */
  // addChat: (chat: Record<string, TChat[]>) => void;

  /**
   * Add message to the messages list
   * @param message
   */
  addMessage: (message: TChatMessage) => void;

  /**
   * Clear messages from the store
   */
  clearMessages: () => void;

  /**
   * Clear chats from the store
   */
  clearChats: () => void;

  /**
   * Fetch chats from the server
   * @param activeWorkspaceId
   */
  fetchChats: (activeWorkspaceId: string) => void;

  /**
   * Fetch messages from the server
   * @param chatId
   * @param activeWorkspaceId
   */
  fetchMessages: (chatId: string, activeWorkspaceId: string) => Promise<void>;

  /**
   * Set flag for chat
   */
  setFlag: (flag: 'mine' | 'all') => void;
}


const useMavenAIStore = create<State & Actions>((set, get) => ({
  flag: 'mine',
  inputMessage: "",
  chats: {},
  chatsOrder: [],
  messages: [],
  activeChat: null,
  fetchingChats: false,
  fetchingMessages: false,
  waitingForResponse: false,
  setFlag: (flag: 'mine' | 'all') => set({ flag }),
  setChatsOrder: (chatsOrder: string[]) => set({ chatsOrder }),
  setChats: (chats: Record<string, TChat[]>) => set({ chats }),
  setMessages: (messages: TChatMessage[]) => set({ messages }),
  setActiveChat: (activeChat: TChat | null) => set({ activeChat }),
  // addChat: (chat: TChat) => set((state) => ({ chats: { ...state.chats, chat } })),
  addMessage: (message: TChatMessage) => set((state) => ({ messages: [...state.messages, message] })),
  clearMessages: () => set({ messages: [], waitingForResponse: false }),
  clearChats: () => set({ chats: {}, chatsOrder: [] }),
  setInputMessage: (inputMessage: string) => set({ inputMessage }),
  setWaitingForResponse: (waiting: boolean) => set({ waitingForResponse: waiting }),
  fetchChats: async (activeWorkspaceId: string) => {
    // Fetch chats
    const service = new MavenAIService(activeWorkspaceId || "")
    set({ fetchingChats: true });

    const response = await service.chats(get().flag || 'mine')

    if (response.status === 200) {
      set({ chats: response.data.chats, chatsOrder: response.data.order })
    }

    set({ fetchingChats: false });
  },
  fetchMessages: async (chatId: string, activeWorkspaceId: string) => {
    // Fetch messages
    const service = new MavenAIService(activeWorkspaceId || "")
    set({ fetchingMessages: true });

    const response = await service.messages(chatId)

    if (response.status === 200) {
      set({ messages: response.data.messages })
    }

    set({ fetchingMessages: false });
  }
}));

export default useMavenAIStore;
