import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Center } from "@mantine/core";

export const ViewDetailedButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <Center mb="sm">
        <Button
          variant="subtle"
          autoContrast
          onClick={onClick}
          color="black.9"
          leftSection={
            <>
              <FontAwesomeIcon icon={regular("expand")}></FontAwesomeIcon>
            </>
          }>
          Detailed view
        </Button>
      </Center>
      {/* <Flex
        justify="center"
        al
        className="flex justify-center items-center pt-2 cursor-pointer hover:underline cursor-pointer"
        onClick={onClick}>
        <FontAwesomeIcon icon={regular("expand")}></FontAwesomeIcon>
        <span className="ml-2">Detailed view</span>
      </Flex> */}
    </>
  );
};
