import { FILTER_GROUPS } from '@/ui/components/App/WebAnalytics/Filters/FiltersUtility';
import create from 'zustand';

interface Props {
  filterKey: string;
  setFilterKey: (filterKey: string) => void;
  filterModalOpened: boolean;
  setFilterModalOpened: (filterModalOpened: boolean) => void;
  handleOnFilterValueClick: (filterKey: string) => void;
}

export const useWebFiltersModalStore = create<Props>((set) => ({
  filterKey: '',
  setFilterKey: (filterKey: string) => set({ filterKey }),
  filterModalOpened: false,
  setFilterModalOpened: (filterModalOpened: boolean) => set({ filterModalOpened }),
  handleOnFilterValueClick: (filterKey: string) => {
    Object.entries(FILTER_GROUPS).forEach(([key, value]) => {
      // console.log(key, value);
      if (value.includes(filterKey.split(':')[0])) {
        set({ filterKey: key, filterModalOpened: true });
        return;
      }
    });
  }
}));
