import "./home.scss";
import { differenceInMinutes } from "date-fns";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { ORGANIZATION_STATES, PADDLE_SUBSCRIPTION_STATUS } from "@/lib/utils/Constants";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import { RightPane } from "./RightPane";
import { useAppGuardStore } from "@/stores/useAppGuardStore";
import MenuPane from "./MenuPane";
import { Paper } from "@mantine/core";

export const Home = () => {
  const { activeWorkspace, workspaces } = useContext(AppLifecycleContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [setForceBilling] = useAppGuardStore((state) => [state.setForceBilling]);
  useEffect(() => {
    document.title = "Dashboard | Usermaven";
    if (workspaces.length === 0) {
      navigate("/workspaces");
    }
  }, []);
  const isSettingsPage =
    location.pathname.includes("/settings/") || location.pathname.includes("/account/");

  if (!activeWorkspace?.name) {
    return <LoadingComponent />;
  }
  console.log("Home component!");
  if (
    activeWorkspace &&
    activeWorkspace?.organization &&
    !location.pathname.includes("/account/billing")
  ) {
    let shouldNavigate = false;

    // billing hook if paused redirect to accounts billing page.
    if (activeWorkspace.organization?.subscriptions.length > 0) {
      // deleted/cancelled subscription
      if (
        activeWorkspace.organization.subscriptions[0].paddle_status ===
        PADDLE_SUBSCRIPTION_STATUS.DELETED
      ) {
        if (
          differenceInMinutes(
            new Date(activeWorkspace.organization.subscriptions[0].cancellation_effective_at),
            new Date()
          ) < 5
        ) {
          shouldNavigate = true;
        }
      }
      // paused subscription

      if (
        activeWorkspace.organization.subscriptions[0].paddle_status ===
        PADDLE_SUBSCRIPTION_STATUS.PAUSED
      ) {
        shouldNavigate = true;
      }
    }

    // Quota Exceeded

    if (
      activeWorkspace.organization.state === ORGANIZATION_STATES.QUOTA_EXCEEDED &&
      !isSettingsPage
    ) {
      if (!location.pathname.includes("/account/billing")) {
        console.log("Quota exceeded.");
        shouldNavigate = true;
      }
    }
    // Trial plan users
    if (
      activeWorkspace.organization?.plan?.name?.endsWith("-trial") &&
      activeWorkspace.organization.is_trial_expired &&
      !isSettingsPage
    ) {
      if (!location.pathname.includes("/account/billing")) {
        shouldNavigate = true;
      }
    }

    if (shouldNavigate) {
      setForceBilling(true);
      navigate(`/env/${activeWorkspace.identifier}/account/billing`);
    }
  }

  return (
    <>
      <MenuPane />
      <Paper className="app-wrapper">
        {/*<LeftPane />*/}
        <RightPane />
      </Paper>
    </>
  );
};
