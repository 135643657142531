import { useState } from "react";
import { CheckIcon, Combobox, Group, Pill, PillsInput, useCombobox, Text } from "@mantine/core";
import { IReport } from "@/stores/useReportStore";

interface Props {
  workspaceMemberEmails: string[];
  report: IReport;
  setReport: (report: IReport) => void;
  clearFormError: (field: string) => void;
  formErrors: {
    email_recipients: string | null;
    [key: string]: any;
  };
}

export const EmailRecipientsInput = ({
  workspaceMemberEmails,
  report,
  setReport,
  clearFormError,
  formErrors
}: Props) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active")
  });

  const [search, setSearch] = useState("");
  const [data, setData] = useState(workspaceMemberEmails);
  const [value, setValue] = useState(report.email_recipients || []);

  const exactOptionMatch = data.some((item) => item === search);

  const handleValueSelect = (val: string) => {
    setSearch("");
    if (val === "$create") {
      setData((current) => [...current, search.trim()]);
      setValue((current) => [...current, search.trim()]);
    } else {
      setValue((current) =>
        current.includes(val) ? current.filter((v) => v !== val) : [...current, val]
      );
    }
    setReport({ ...report, email_recipients: value as string[] });
    clearFormError("email_recipients");
  };

  const handleValueRemove = (val: string) => {
    setValue((current) => current.filter((v) => v !== val));
    setReport({ ...report, email_recipients: value as string[] });
  };

  const values = value.map((item) => (
    <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {item}
    </Pill>
  ));

  const options = data
    .filter((item) => item.toLowerCase().includes(search.trim().toLowerCase()))
    .map((item) => (
      <Combobox.Option value={item} key={item} active={value.includes(item)}>
        <Group gap="sm">
          {value.includes(item) ? <CheckIcon size={12} /> : null}
          <span>{item}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <>
      <Text size="sm" mb={4}>
        Email Addresses
      </Text>
      <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
        <Combobox.DropdownTarget>
          <PillsInput onClick={() => combobox.openDropdown()}>
            <Pill.Group>
              {values}
              <Combobox.EventsTarget>
                <PillsInput.Field
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  value={search}
                  placeholder="Enter email addresses"
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace" && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(value[value.length - 1]);
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>
        <Combobox.Dropdown>
          <Combobox.Options>
            {options}
            {!exactOptionMatch && search.trim().length > 0 && (
              <Combobox.Option value="$create">+ Add {search}</Combobox.Option>
            )}
            {exactOptionMatch && search.trim().length > 0 && options.length === 0 && (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      {formErrors.email_recipients && (
        <Text size="sm" color="red">
          {formErrors.email_recipients}
        </Text>
      )}
    </>
  );
};
