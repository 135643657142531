import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState } from "react";
import { Button, Flex, Menu, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { LooseObject } from "types/types.d";
import { STARTER_PLAN_DISABLED_DATE_RANGES } from "../../../../lib/utils/Constants";
import { toast } from "react-toastify";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface Props {
  selectedOption?: { label: any; value: any } | undefined;
  options: Array<LooseObject>;
  onChange: (item: LooseObject | undefined) => void;
}
export const DropdownMenu = ({ selectedOption, options = [], onChange }: Props) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState<LooseObject | undefined>(selectedOption);
  // starter plan check from useWorkspaceUtilityHook
  const { isStarterPlan } = useWorkspaceUtilityHook();

  const handleOnChange = (item: LooseObject | undefined) => {
    setSelected(item || undefined);
    onChange(item);
  };
  return (
    <>
      <Menu shadow="md" width={200} onChange={setOpened}>
        <Menu.Target>
          <Button
            size="xs"
            color={colorScheme === "dark" ? "dark.2" : "gray.6"}
            variant="outline"
            leftSection={<FontAwesomeIcon icon={regular("list-dropdown")} />}
            rightSection={
              <FontAwesomeIcon
                icon={solid("angle-up")}
                className={`transition-all ease-in-out transform rotate-180 ${
                  opened ? "rotate-0" : ""
                }`}
              />
            }>
            {selected?.label || "Select"}
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          {options.map((option: any, index: number) => (
            <Fragment key={index}>
              {/* In case if label is divider, we add a separator to the menu */}
              {option.label === "divider" ? (
                <>
                  <Menu.Divider />
                </>
              ) : /* Date range menu items older than 6 months are disabled for starter plan */
              STARTER_PLAN_DISABLED_DATE_RANGES.includes(option.label.toLowerCase()) &&
                isStarterPlan ? (
                <div
                  className={"hover:bg-gray-100 cursor-not-allowed"}
                  onClick={() =>
                    toast.error("Please upgrade your plan to unlock these date ranges")
                  }>
                  <Menu.Item disabled>
                    <div className="flex">
                      <span className="flex-grow">{option.label}</span>
                      <span>
                        <FontAwesomeIcon
                          key={"fa" + index}
                          icon={regular("lock")}></FontAwesomeIcon>
                      </span>
                    </div>
                  </Menu.Item>
                </div>
              ) : (
                // Date range menu item
                <Menu.Item onClick={() => handleOnChange(option)}>
                  <Flex align={"center"}>
                    <Flex flex={1}>{option.label}</Flex>
                    {option.label === selected?.label && (
                      <>
                        <span>
                          <FontAwesomeIcon icon={regular("check")}></FontAwesomeIcon>
                        </span>
                      </>
                    )}
                  </Flex>
                </Menu.Item>
              )}
            </Fragment>
          ))}
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
