import * as Sentry from "@sentry/browser";
import { usermavenClient } from "@usermaven/sdk-js";
import { getTime, parse, parseISO } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { LooseObject } from "types/types.d";
import { USERMAVEN_OPTS, WORKSPACE_MEMBER_ROLES } from "./Constants";
import { getAdminLoggedInStorage } from "./Storage";

export class ThirdPartyTracking {
  private user;
  private workspaces;
  constructor(user: any = {}, workspaces: any = {}) {
    this.user = user;
    this.workspaces = workspaces;
    console.log("ThirdPartyTracking", user, workspaces);
  }

  identify = () => {
    if (getAdminLoggedInStorage() === "true") {
      return "No pixel to be fired for admin.";
    }
    if (this.user && this.user?.first_name) {
      console.log("Identify request for Helpscout", this.user);

      console.debug("Identify request for Sentry", this.user);
      Sentry.setUser({ name: this.user.full_name, email: this.user.email });
      this._crisp();
      this._usermaven();
      this._customerio();
      if (process.env.REACT_APP_APP_ENV !== "prod") {
        this._segment();
      }
    }
  };

  _usermaven = () => {
    // usermaven integration.
    console.log("Identify request for usermaven", this.user, this.workspaces);

    let userPayload: LooseObject = {
      // Required attributes
      id: this.user.id, // Unique ID for the user in database.
      email: this.user.email, // Email address for the user.
      created_at: this.user.created_at.substring(0, 19), // DateTime string in your system that represents when the user first signed up.

      // Recommended attributes
      // First name and last name are shown on user pages.
      first_name: this.user.first_name,
      last_name: this.user.last_name

      // Optional attributes (you can name attributes what you wish)
    };
    let companyPayload = {};

    if (this?.workspaces?.defaultWorkspace && this.workspaces.defaultWorkspace.id) {
      companyPayload = {
        company: {
          id: this.workspaces.defaultWorkspace?.organization.id, // Company ID in your database
          name: this.workspaces.defaultWorkspace?.organization.name, // Company Name in your database.
          created_at: this.workspaces.defaultWorkspace?.organization.created_at.substring(0, 19), // DateTime string in your system that represents when the company first signed up.
          // Optional attributes such as industry, website, employee count etc.
          custom: {
            plan: this.workspaces.defaultWorkspace.organization?.plan?.name
            // industry: "Technology",
            // website: "https://usermaven.com",
            // employees: 20,
          }
        }
      };

      userPayload.is_integrated_with_pixel =
        this.workspaces.defaultWorkspace?.is_integrated_with_pixel;
    }
    let data = {
      ...userPayload,
      ...companyPayload
    };
    usermavenClient(USERMAVEN_OPTS).id(data);
  };

  _customerio = () => {
    const created_at = Math.round(getTime(fromZonedTime(this.user.created_at, "UTC")) / 1000);
    console.log("Customer.io", this.workspaces, this.user);
    let payload: LooseObject = {
      id: this.user.id,
      created_at: created_at, // Timestamp in your system that represents when
      first_name: this.user.first_name, // Add any attributes you'd like to use in the email subject or body.
      last_name: this.user.last_name, // First name and last name are shown on user pages.
      email: this.user.email
      // Optional attributes
    };

    if (this?.workspaces?.defaultWorkspace && this.workspaces.defaultWorkspace.id && this.user) {
      try {
        const member = this.workspaces.defaultWorkspace.members.find(
          (member: LooseObject): any => member?.user?.id === this.user?.id
        );
        if (member) {
          payload = {
            ...payload,
            role: member?.role?.name,
            is_integrated_with_pixel: this.workspaces.defaultWorkspace?.is_integrated_with_pixel,
            plan: this.workspaces.defaultWorkspace.organization?.plan?.name
          };
        }
      } catch (m) {
        console.warn("Could not find member in workspace", m);
      }
    }
    // @ts-ignore
    _cio.identify(payload);
  };

  _firstPromoter = () => {
    // @ts-ignore
    fpr("referral", {
      email: this.user.email,
      uid: this.user.id
    });
  };

  _segment = () => {
    //@ts-ignore
    analytics.identify(this.user.id, {
      email: this.user.email,
      created_at: this.user.created_at,
      first_name: this.user.first_name,
      last_name: this.user.last_name
    });

    // Send the company data

    if (this?.workspaces?.defaultWorkspace && this.workspaces.defaultWorkspace.id) {
      // @ts-ignore
      analytics.group(this.workspaces.defaultWorkspace?.organization.id, {
        name: this.workspaces.defaultWorkspace?.organization.name,
        created_at: this.workspaces.defaultWorkspace?.organization.created_at.substring(0, 19), // DateTime string in your system that represents when the company first signed up.
        // Optional attributes such as industry, website, employee count etc.

        plan: this.workspaces.defaultWorkspace.organization?.plan?.name
        // industry: "Technology",
        // website: "https://usermaven.com",
        // employees: 20,
      });
    }
  };

  _crisp = () => {
    try {
      $crisp.push(["set", "user:email", [this.user.email]]);
      $crisp.push(["set", "user:nickname", [this.user.nickname]]);
      console.log("Crisp.chat", this.workspaces.defaultWorkspace);
      if (this?.workspaces?.defaultWorkspace && this.workspaces.defaultWorkspace?.organization) {
        $crisp.push([
          "set",
          "session:data",
          ["organization_id", `${this.workspaces.defaultWorkspace.organization.id}`]
        ]);
        $crisp.push([
          "set",
          "session:data",
          ["organization_plan", this.workspaces.defaultWorkspace.organization?.plan?.name]
        ]);
        $crisp.push([
          "set",
          "session:data",
          ["organization_type", this.workspaces.defaultWorkspace.organization?.type]
        ]);
        $crisp.push([
          "set",
          "session:data",
          ["organization_name", this.workspaces.defaultWorkspace.organization?.name]
        ]);
        $crisp.push([
          "set",
          "session:data",
          ["organization_created_at", this.workspaces.defaultWorkspace.organization?.created_at]
        ]);
        $crisp.push(["set", "user:company", [this.workspaces.defaultWorkspace.organization.name]]);
      }
    } catch (ex) {
      console.log(ex);
    }

    if (this?.workspaces?.defaultWorkspace && this.workspaces.defaultWorkspace.id && this.user) {
      try {
        const member = this.workspaces.defaultWorkspace.members.find(
          (member: LooseObject): any => member?.user?.id === this.user?.id
        );
        if (member) {
          $crisp.push([
            "set",
            "session:event",
            [
              [
                [
                  "profile",
                  {
                    name: this.workspaces.defaultWorkspace?.organization.name,
                    role: member?.role?.name,
                    plan: this.workspaces.defaultWorkspace.organization?.plan?.name
                  },
                  "red"
                ]
              ]
            ]
          ]);
        }
      } catch (m) {
        console.warn("Could not find member in workspace", m);
      }
    }
  };

  track = (eventName: string, eventProperties: any = {}) => {
    if (getAdminLoggedInStorage() === "true") {
      return "No pixel to be fired for admin.";
    }
    usermavenClient(USERMAVEN_OPTS).track(eventName, eventProperties);
    // @ts-ignore
    _cio.track(eventName, eventProperties);

    if (process.env.REACT_APP_APP_ENV !== "prod") {
      // @ts-ignore
      analytics.track(eventName, eventProperties);
    }
  };
}

export function gtag_report_conversion(
  value: number,
  currency: string = "USD",
  transaction_id = ""
): boolean {
  gtag("event", "conversion", {
    send_to: "AW-11179183055/YbeUCLCFyoQZEM-X09Ip",
    value: value,
    currency: currency,
    transaction_id: transaction_id
  });

  return false;
}
