import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";
import logo from "@assets/images/logos/usermaven-logo.png";
import { LoadingStateProps } from "types/types.d";
import { WorkspaceShareService } from "@/lib/services/WorkspaceShareService";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Paper,
  PasswordInput,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import logoWhiteSvg from "../../../../assets/images/logos/logo-white-usermaven.svg";
import logoBlackSvg from "../../../../assets/images/logos/logo-black-usermaven.svg";

export const ShareDashboardPasswordProtected = ({ onSuccess }: { onSuccess: () => void }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    register,
    setValue
  } = useForm<{
    share_password: string;
  }>({});
  const { colorScheme } = useMantineColorScheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const handlePasswordSubmit = async (data: any) => {
    setLoading("loading");
    await new WorkspaceShareService()
      .validation(activeWorkspace.id, data.share_password)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          // Set password validation to true
          onSuccess();
        }
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          toast.error(err?.response?.data?.detail);
        } else if (err?.response?.status === 500) {
          toast.error("An error occurred. Please try again later.");
        }
      });

    setLoading("loaded");
  };
  return (
    <>
      <Box>
        <Box component="div" className="h-full w-full">
          <Flex justify={"center"} my="md" className="mt-24 sm:mt-20 mb-8 flex justify-center">
            <img
              className="um-onboarding-logo"
              width={165}
              height={29}
              src={colorScheme === "dark" ? logoWhiteSvg : logoBlackSvg}
              alt="Usermaven"
            />
          </Flex>
          <Divider />
          <Box mt={64} component="div" mx={"auto"} className=" sm:mx-auto mx-4 sm:w-[40rem]">
            <Paper mx="auto" shadow="sm" withBorder w={400} radius={"sm"}>
              <Flex direction={"column"} data-cy="web-analytics-share-views-login-form">
                <Box>
                  <Flex mt={"sm"} align="center" justify="center" mb="md">
                    <FontAwesomeIcon icon={regular("lock")} size="lg" />
                  </Flex>
                  <Title order={5} fw={500} ta={"center"}>
                    This link is password protected
                  </Title>
                </Box>

                <Box p="md">
                  <form
                    id="handlePassword"
                    onSubmit={handleSubmit(handlePasswordSubmit)}
                    autoComplete="off">
                    <div className=" flex ">
                      <div className="flex-grow relative">
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error }
                          }) => (
                            <Input.Wrapper id={"password"} size="xs" label="Password" required>
                              <PasswordInput
                                id="shared-dashboard-password-input-dialog"
                                mt={4}
                                placeholder="*********"
                                onChange={onChange}
                                withAsterisk
                                value={value}
                                className="w-full"
                                error={(invalid || error) === true}
                                data-cy="shared-dashboard-password-input-dialog"
                              />
                            </Input.Wrapper>
                          )}
                          name="share_password"
                          control={control}
                          defaultValue=""
                          rules={{ required: true, maxLength: 48 }}
                        />

                        {errors.share_password?.type === "required" && (
                          <ValidationLabel
                            validationType="error"
                            text="Password is required"
                            size="small"
                            customClasses="pt-2"></ValidationLabel>
                        )}
                        {errors.share_password?.type === "maxLength" && (
                          <ValidationLabel
                            validationType="error"
                            text="Password should be less than 48 characters."
                            size="small"
                            customClasses="pt-2"></ValidationLabel>
                        )}
                      </div>
                      <Flex my="md">
                        <Button
                          loading={loading === "loading"}
                          disabled={loading === "loading"}
                          type="submit"
                          fullWidth
                          data-cy="shared-dashboard-password-submit">
                          Submit
                        </Button>
                      </Flex>
                    </div>
                  </form>
                </Box>
              </Flex>
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
};
