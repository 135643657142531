import { Box, Button, Title, Flex, Text, Anchor } from "@mantine/core";
import PixelInstallationSVG from "@assets/images/icons/pixel-installation.svg";
import { Crisp } from "crisp-sdk-web";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Countdown from "react-countdown";
interface customWindow extends Window {
  Beacon?: any;
}
declare const window: customWindow;

export const OnboardingWaitingPixelAnalytics = (props: any) => {
  const [nextCallTime, setNextCallTime] = useState<number>(30);

  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);

  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Waiting for events | Usermaven";
  }, []);
  useEffect(() => {
    // Timer to reduce by 1 after every second.
    const tickInterval = setInterval(() => {
      console.debug("setTickInterval");
      if (nextCallTime > 0) {
        setNextCallTime(nextCallTime - 1);
      }
    }, 1000);
    return () => {
      clearInterval(tickInterval);
    };
  }, [nextCallTime]);

  const isPixelSetupCompleted = async () => {
    const wsService = new WorkspaceService();

    wsService
      .isPixelEventsAvailable(activeWorkspace?.id)
      .then((res) => {
        if (res.data?.id) {
          setActiveWorkspace(res.data);
          navigate(`/env/${res.data.identifier}/web/statistics`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // Send request again to check if the events are in or not.
    isPixelSetupCompleted();
    const intervalId = setInterval(() => {
      // TODO: send a call to check if the event is received or not.
      console.debug("setIntervalId");
      isPixelSetupCompleted();
      setNextCallTime(30);
    }, 30000);
    return () => {
      clearInterval(intervalId); //This is important
    };
  }, []);

  return (
    <div className="um-onboarding">
      <div className="um-onboarding--navigation">
        <div className="um-onboarding--navigation--back-button">
          <Button
            size="xs"
            variant="outline"
            color="gray.6"
            className="mr-4"
            onClick={() => navigate("/onboarding/tracking-pixel")}>
            &larr; Back to Setup
          </Button>
        </div>
        <div className="um-onboarding--navigation--steps">2/2</div>
      </div>

      <Title ta="center" order={2} fw={600}>
        We’re waiting for a page view from your site
      </Title>
      <Flex justify="center" direction={"column"}>
        <Flex justify={"center"} py="xl">
          <img className="globe" src={PixelInstallationSVG} alt="" height={200} />
        </Flex>

        <Text ta="center" py="sm">
          Next event check in{" "}
          <span
            style={{
              fontWeight: 600
            }}>
            {nextCallTime}
          </span>{" "}
          seconds.
        </Text>
        <Text ta="center" py="sm">
          <Anchor
            href="javascript:;"
            onClick={() => {
              Crisp.chat.open();
            }}>
            Ask for help!
          </Anchor>
        </Text>
      </Flex>
      <Text ta="center">
        Or, <Link to="/logout">Logout</Link>
      </Text>
    </div>
  );
};
