import { Box } from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext, useEffect } from "react";
import { AccessCustomDomainUpgrade } from "@/ui/components/App/CustomDomain/AccessCustomDomainUpgrade";
import { AddCustomDomain } from "@/ui/components/App/CustomDomain/AddCustomDomain";
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";

const CustomDomain = () => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  useEffect(() => {
    document.title = "Pixel White-labeling | Usermaven";
  }, []);

  if (activeWorkspace?.organization?.plan.pixel_whitelabel) {
    return <AddCustomDomain />;
  }
  return <AccessCustomDomainUpgrade />;
};

export default CustomDomain;
