import { stringToInitials } from "@/lib/utils/StringUtility";
import { Avatar, Flex, Text } from "@mantine/core";

interface IAvatarWithTextProps {
  color: string;
  text: string;
}
export const AvatarWithText = ({ color, text }: IAvatarWithTextProps) => {
  return (
    <>
      <Flex align={"center"}>
        <Avatar
          sx={{
            textDecoration: "none"
          }}
          size={20}
          variant={"filled"}
          radius={"xl"}
          mr={8}
          component="div"
          styles={{
            placeholder: {
              backgroundColor: color
            }
          }}
          className={`uppercase `}>
          {stringToInitials(text)}
        </Avatar>
        <Text tt="capitalize">{text}</Text>
      </Flex>
    </>
  );
};
