export const LS_ACCESS_TOKEN = "um_access_token";
export const LS_REFRESH_TOKEN = "um_refresh_token";
export const LS_USERMAVEN_USER = "um_user_account";
export const LS_WORKSPACE_INTEGRATION_NAME = "um_workspace_integration_name";
export const LS_GRAPH_PREFERENCE = "um_web_graph_preference";
export const LS_WEB_CARD_GRAPH_PREFERENCE = "um_web_card_preference";
export const LS_UTM_TRAFFIC_PREFERENCE = "um_web_utm_traffic_preference";
export const LS_TOP_SOURCES_PREFERENCE = "um_web_top_sources_preference";
export const LS_TOP_SOURCES_OVERVIEW_PREFERENCE = "um_web_top_sources_overview_preference";
export const LS_TOP_PAGES_PREFERENCE = "um_web_top_pages_preference";
export const LS_TOP_DEVICES_PREFERENCE = "um_web_top_devices_preference";
export const LS_TOP_LOCATIONS_PREFERENCE = "um_web_top_locations_preference";
export const LS_VISITORS_AUTOCAPTURED_PREFERENCE = "um_web_visitors_autocaptured_preference";
export const LS_PEOPLE_FIELDS_PREFERENCE = "um_web_people_fields_preferences";
export const LS_COMPANIES_FIELDS_PREFERENCE = "um_web_companies_fields_preferences";
export const LS_VISITORS_FIELDS_PREFERENCE = "um_web_visitors_fields_preferences";
export const LS_IS_DISMISSED_TRIAL_NOTIFICATION = "um_dismiss_trial_notification";
export const LS_LEFT_PANEL_COLLAPSE_PREFERENCE = "um_left_panel_collapse_preference";
export const LS_ATTRIBUTION_PREFERENCES = "um_attribution_preferences";

export function authLocalStorageUpdate(accessToken: string, refreshToken: string): void {
  localStorage.setItem(LS_ACCESS_TOKEN, accessToken);
  localStorage.setItem(LS_REFRESH_TOKEN, refreshToken);
}

export function authAccessTokenLocalStorageUpdate(accessToken: string): void {
  localStorage.setItem(LS_ACCESS_TOKEN, accessToken);
}

export function cleanAuthLocalStorage(): void {
  localStorage.removeItem(LS_ACCESS_TOKEN);
  localStorage.removeItem(LS_REFRESH_TOKEN);
}

export function authAccessTokenFromStorage(): string | null {
  return localStorage.getItem(LS_ACCESS_TOKEN);
}

export function authRefreshTokenFromStorage(): string | null {
  console.log("Local storage refresh token: ", localStorage.getItem(LS_REFRESH_TOKEN));
  return localStorage.getItem(LS_REFRESH_TOKEN);
}

export const authUserFromLocalStorage = (): any => {
  const userObject = localStorage.getItem(LS_USERMAVEN_USER) || "{}";
  console.log("Local Storage ", userObject);
  if (userObject) {
    return JSON.parse(userObject);
  }
  return null;
};

export const authUserStorageUpdate = (user: any): void => {
  localStorage.setItem(LS_USERMAVEN_USER, JSON.stringify(user));
};

export const cleanAuthUserStorage = (): void => {
  localStorage.removeItem(LS_USERMAVEN_USER);
};

export const setWorkspaceIntegrationNameToStorage = (name: any): void => {
  localStorage.setItem(LS_WORKSPACE_INTEGRATION_NAME, name);
};
export const getWorkspaceIntegrationNameFromStorage = (): any => {
  return localStorage.getItem(LS_WORKSPACE_INTEGRATION_NAME);
};

export const setAdminLoggedInStorage = (): void => {
  localStorage.setItem("admin_logged_in", "true");
};

export const getAdminLoggedInStorage = (): string => {
  return localStorage.getItem("admin_logged_in") || "";
};
export const resetAdminLoggedInStorage = (): void => {
  localStorage.removeItem("admin_logged_in");
};

export const setLeftPaneCollapsePreference = (isCollapsed: boolean): void => {
  localStorage.setItem(LS_LEFT_PANEL_COLLAPSE_PREFERENCE, JSON.stringify(isCollapsed));
};

export const getLeftPaneCollapsePreference = (): boolean => {
  const isCollapsed = localStorage.getItem(LS_LEFT_PANEL_COLLAPSE_PREFERENCE);
  if (isCollapsed) {
    return JSON.parse(isCollapsed);
  }
  return false;
};
