/**
 * A single store for the user overview modal open/close state throughout the app.
 */

import create from "zustand";


interface Props {
  isOpen: boolean;
  type: "visitor" | "user" | "company";
  uniqueId: string;
  setIsOpen: (isOpen: boolean, type?: "visitor" | "user" | "company", uniqueId?: string) => void;
}

/**
 * Zustand store for the user overview modal.
 */
export const useUserOverviewModalStore = create<Props>((set) => ({
  isOpen: false,
  type: "visitor",
  uniqueId: "",
  setIsOpen: (isOpen: boolean, type?: "visitor" | "user" | "company", uniqueId?: string) => {
    if (isOpen) {
      set({ isOpen, type, uniqueId });
    } else {
      set({ isOpen: false, type: "visitor", uniqueId: "" });
    }
  }
}));

