import axios from './JWTService';
export class SlippingAwayUsersService {
  private readonly _endpoint;
  constructor() {
    this._endpoint = '/v1/slipping_away_users';
  }
  count = (
    ws_id: any,
    insight_id: any,
    report_type: 'user' | 'company',
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${ws_id}/${insight_id}/count/${report_type}`, {
          audience_conditions: audienceConditions,
          audience_condition_type: audienceConditionType,
          audience_group_conditions: audienceGroupConditions
        })
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  list = (
    ws_id: any,
    insight_id: any,
    period: string,
    report_type: 'user' | 'company',
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>,
    page: number = 1,
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/list/${period}/${report_type}?page=${page}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  export = (
    ws_id: any,
    insight_id: any,
    period: string,
    report_type: 'user' | 'company',
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>,
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${ws_id}/${insight_id}/list_export/${period}/${report_type}`, {
          audience_conditions: audienceConditions,
          audience_condition_type: audienceConditionType,
          audience_group_conditions: audienceGroupConditions
        })
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
