import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { LoadingStateProps } from "types/types.d";
import { UserStickinessDashboard } from "@/ui/pages/InsightsDashboard/Components/InsightsDashboardOverview/UserStickinessDashboard";
import { StringParam, useQueryParams } from "use-query-params";
import { getReportingPeriodRange } from "../../../../lib/utils/ReportingPeriodUtility";
import { useWebFiltersStore } from "../../../../stores/useWebFiltersStore";
import { DateDropdownMenu } from "../../../components/App/Dropdowns/DateDropdownMenu";
import { DashboardOverviewCompanies } from "../Components/InsightsDashboardOverview/DashboardOverviewCompanies";
import { DashboardOverviewUsers } from "../Components/InsightsDashboardOverview/DashboardOverviewUsers";
import { FeatureUsageHistogram } from "../Components/InsightsDashboardOverview/FeatureUsageHistogram";
import { MostEngagedUsers } from "../Components/InsightsDashboardOverview/MostEngagedUsers";
import { UserAcquisitionByChannel } from "../Components/InsightsDashboardOverview/UserAcqusitionByChannel";
import { Flex, Loader, SimpleGrid } from "@mantine/core";

const InsightsDashboardOverview = () => {
  const [query, setQuery] = useQueryParams({
    from_date: StringParam,
    to_date: StringParam
  });
  const [isLoading, setIsLoading] = useState<LoadingStateProps>("idle");
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [setDateLabel] = useWebFiltersStore((state) => [state.setDateLabel]);

  const handleDateChange = (startDate: string | undefined, endDate: string | undefined) => {
    console.log("handleDateRangeChange", startDate, endDate);
    setQuery({
      from_date: startDate,
      to_date: endDate
    });
  };

  useEffect(() => {
    document.title = "Insights | Overview | Usermaven";
    const getPeriod = getReportingPeriodRange(activeWorkspace.reporting_period);
    setDateLabel(getPeriod?.label || "Last 30 Days");
    setQuery({
      from_date: getPeriod?.startDate,
      to_date: getPeriod?.endDate
    });
    setIsLoading("loaded");
  }, [activeWorkspace.id]);

  return (
    <>
      <Flex direction={"row-reverse"} mb="md">
        <DateDropdownMenu onChange={handleDateChange} />
      </Flex>

      <SimpleGrid
        cols={{
          base: 2,
          sm: 1,
          md: 2
        }}
        spacing={"lg"}
        w={"100%"}>
        {isLoading === "loaded" ? (
          <>
            <DashboardOverviewUsers query={query} />
            <DashboardOverviewCompanies query={query} />
            <UserStickinessDashboard />
            <UserAcquisitionByChannel query={query} />
            <MostEngagedUsers query={query} />
            <FeatureUsageHistogram query={query} />
          </>
        ) : (
          <>
            <Loader size={"xs"} />
          </>
        )}
      </SimpleGrid>
    </>
  );
};

export default InsightsDashboardOverview;
