/* eslint-disable react-hooks/exhaustive-deps */
// @ts-ignore
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Text,
  Title,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { BulletListSkeleton } from "@/lib/utils/ChartsSkeletons";
import { PALE_SHADES, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { LoadingStateProps } from "types/types.d";
import { NoResults } from "../NoResults/NoResults";
import { SearchedKeywordsDetailedView } from "./DetailedView/SearchedKeywordsDetailedView";
import { ViewDetailedButton } from "./ViewDetailedButton";
import { Paper } from "@mantine/core";
import { useIntersection } from "@mantine/hooks";
import { DynoTable, ProgressCell } from "../../Common/DynoTable/DynoTable";

export interface ISearchedKeywordItemProps {
  site_url: string;
  query: string;
  total_clicks: number;
  avg_position: number;
  total_impressions: number;
  avg_ctr: number;
}

interface ISearchedKeywords {
  query: any;
  isPublic: boolean;
}

const GSCIntegrationStates = {
  SYNCING: "syncing",
  SYNCED: "synced",
  NOT_CONNECTED: "not_connected"
};

export const SearchedKeywords = ({ query, isPublic }: ISearchedKeywords) => {
  // Workspace active role.
  const { hasRolesNotWith } = useWorkspaceUtilityHook();

  // List of searched queries
  const [searchedKeywords, setSearchedKeywords] = useState<Array<ISearchedKeywordItemProps>>([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);

  // active workspace selected
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // state to open detailed view
  const [opened, setOpened] = useState(false);

  // loading state
  const [loading, setLoading] = useState<LoadingStateProps>("idle");

  // state for integration is connected or not
  const [GSCIntegrationState, setGSCIntegrationState] = useState("synced");

  // state for the data syncing

  const navigate = useNavigate();

  const fetchTopSearchedKeywords = async () => {
    setLoading("loading");
    // fetch top searched keywords
    await new WebAnalyticsService()
      .topSearchedKeywords(activeWorkspace.id, "", 10, 1, query)
      .then((res) => {
        if (res.data.data) {
          setSearchedKeywords(res.data.data);
          if (res.data.columns) {
            const highestValue = res.data.highest_value;
            // Modify columns before setting the state
            const modifiedColumns = res.data.columns.map((column: { accessor: string }) => {
              if (column.accessor === "query_with_value") {
                return {
                  ...column,
                  render: (data: any) => (
                    <ProgressCell
                      barColor={PALE_SHADES.yellow}
                      value={highestValue === 0 ? 0 : Math.floor((data.value / highestValue) * 100)}
                      label={
                        <Flex align={"center"} justify={"space-between"}>
                          <Text>{data.query}</Text>
                        </Flex>
                      }
                    />
                  )
                };
              }
              if (["total_clicks", "total_impressions"].includes(column.accessor)) {
                return {
                  ...column,
                  render: (data: any) => <Text fw={400}>{numberToCommas(data)}</Text>
                };
              }
              if (["avg_position", "avg_ctr"].includes(column.accessor)) {
                return {
                  ...column,
                  render: (data: any) => <Text fw={400}>{data.toFixed(2)}</Text>
                };
              }
              return column;
            });
            setTableColumns(modifiedColumns);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 422) {
          if (err.response.data.detail === GSCIntegrationStates.SYNCING) {
            setGSCIntegrationState(GSCIntegrationStates.SYNCING);
          } else {
            setGSCIntegrationState(GSCIntegrationStates.NOT_CONNECTED);
          }
        }
      });

    setLoading("loaded");
  };

  const [hasBecomeVisibleYet, setHasBecomeVisibleYet] = useState(false);

  const containerRef = useRef();
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 0
  });

  useEffect(() => {
    if (entry?.isIntersecting && !hasBecomeVisibleYet) {
      setHasBecomeVisibleYet(true);
      fetchTopSearchedKeywords();
    }
  }, [entry?.isIntersecting]);

  useEffect(() => {
    if (hasBecomeVisibleYet && entry?.isIntersecting) {
      fetchTopSearchedKeywords();
    } else {
      setHasBecomeVisibleYet(false);
    }
  }, [query]);

  const renderSearchedKeywords = () => {
    return (
      <>
        <Paper ref={ref} shadow="xs" withBorder>
          <Box h={510} component="div">
            <Flex component="div" p="md" align={"center"}>
              <Title order={6} fw={600} mr={8}>
                Keywords
              </Title>
              <Tooltip
                label="Google search keywords users enter to find your website."
                position="right">
                <FontAwesomeIcon icon={light("circle-info")} />
              </Tooltip>
            </Flex>
            <Divider />
            <Box
              style={{
                maxWidth: "0",
                minWidth: "100%",
                overflowX: "auto"
              }}>
              <Box
                style={{
                  minWidth: "1000px"
                }}>
                {loading === "loaded" ? (
                  <>
                    {searchedKeywords?.length > 0 ? (
                      <>
                        <DynoTable columns={tableColumns} data={searchedKeywords} />

                        {searchedKeywords?.length >= 10 && (
                          <ViewDetailedButton
                            onClick={() => {
                              setOpened(true);
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <NoResults
                          className="pt-20"
                          heading="No Results"
                          text="There are no results available for selected period."
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="ml-4">
                      <BulletListSkeleton />
                    </div>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </>
    );
  };

  const getErrorMessage = () => {
    if (GSCIntegrationState === GSCIntegrationStates.SYNCING) {
      return "Google Search Console is syncing. Please try again in a few minutes.";
    } else if (GSCIntegrationState === GSCIntegrationStates.NOT_CONNECTED) {
      return "Google Search Console is not connected. Please connect it to view the data.";
    }
  };

  if (loading === "loaded" && GSCIntegrationState !== GSCIntegrationStates.SYNCED) {
    return (
      <Paper withBorder shadow="xs">
        <Box component="div" mih={240} mah={510}>
          <Flex component="div" p="md" align={"center"}>
            <Title order={6} fw={600} mr={8}>
              Keywords
            </Title>
            <Tooltip
              label="Google search keywords users enter to find your website."
              position="right">
              <FontAwesomeIcon icon={light("circle-info")} />
            </Tooltip>
          </Flex>
          <Divider />

          <Flex direction={"column"} justify={"center"}>
            <Box my={"lg"} ta="center" fz={"xl"}>
              <FontAwesomeIcon icon={light("search")} />
            </Box>
            <Text ta="center" className="mb-4">
              {getErrorMessage()}
            </Text>

            {!isPublic &&
              hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) &&
              GSCIntegrationState === GSCIntegrationStates.NOT_CONNECTED && (
                <Center my={"lg"}>
                  <Button
                    variant="outline"
                    size="xs"
                    // color="brand.5"
                    onClick={() =>
                      navigate(
                        `/env/${activeWorkspace.identifier}/settings/integrations/google_search_console`
                      )
                    }>
                    Connect Google Search Console
                  </Button>
                </Center>
              )}
          </Flex>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper>
      <SearchedKeywordsDetailedView setOpened={setOpened} opened={opened} query={query} />
      {renderSearchedKeywords()}
    </Paper>
  );
};
