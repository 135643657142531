import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { MOBILE_BREAKPOINT_QUERY, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { useAutoCapturedEventsStore } from "@/stores/useAutoCapturedEventsStore";
import { usePinnedEventStore } from "@/stores/usePinnedEventStore";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Flex, Tooltip, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "../../Common/Header/Header";
import { PinnedEventModal } from "./PinnedEvents/PinnedEventModal";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const EventsHeader = () => {
  const { hasRolesNotWith } = useWorkspaceUtilityHook();
  const [modalOpen, setModalOpen, resetPinnedEvent] = usePinnedEventStore((state) => [
    state.modalOpen,
    state.setModalOpen,
    state.reset
  ]);
  const [reset] = useAutoCapturedEventsStore((state) => [state.reset]);
  const theme = useGlobalMantineTheme();
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);
  const location = useLocation();

  const currentPageHeader = useMemo(() => {
    if (location.pathname.includes("auto-captured")) {
      return "Auto-Captured Events";
    } else if (location.pathname.includes("custom")) {
      return "Custom Events";
    } else if (location.pathname.includes("pinned-events")) {
      return "Pinned Events";
    } else if (location.pathname.includes("forms")) {
      return "Forms Submissions";
    }
    return "Events Activity";
  }, [location.pathname]);

  const showCreateButton = useMemo(() => {
    if (
      location.pathname.includes("auto-captured") ||
      location.pathname.includes("pinned-events")
    ) {
      return true;
    }

    return false;
  }, [location.pathname]);

  return (
    <>
      <PinnedEventModal open={modalOpen} closeModal={() => setModalOpen(false)} />
      <Header
        title={currentPageHeader}
        rightSection={
          <>
            {showCreateButton && (
              <>
                {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                  <Flex align="center">
                    <Tooltip label="Learn more about Pinned events">
                      <FontAwesomeIcon
                        onClick={() => {
                          window.open(
                            "https://usermaven.com/docs/getting-started/creating-pinned-events"
                          );
                        }}
                        className="pt-1 cursor-pointer"
                        icon={regular("info-circle")}></FontAwesomeIcon>
                    </Tooltip>

                    <Button
                      ml="sm"
                      // variant="gradient"
                      data-cy="create-new-pinned-event-btn"
                      onClick={() => {
                        reset();
                        resetPinnedEvent();
                        setModalOpen(true);
                      }}
                      size={isMobileView ? "xs" : "sm"}>
                      Create Pinned Event
                    </Button>
                  </Flex>
                )}
              </>
            )}
          </>
        }
      />
    </>
  );
};
