import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import AppLifecycleContext from '@/lib/contexts/AppLifecycleContext';
import { UserRetentionService } from '@/lib/services/UserRetentionService';
import { DATE_FORMAT } from '@/lib/utils/Constants';
import { useContext, useState } from 'react';
import { IReportType } from '../types/types.d';

export default function useUserRetentionCohortHook() {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [isRetentionCohortLoading, setIsRetentionCohortLoading] = useState('idle');
  const [cohortData, setCohortData] = useState([]);
  const [cohortValuesType, setCohortValuesType] = useState<'numbers' | 'ratio' | string>('ratio');

  const fetchUserRetentionCohort = async (
    insightId: string | undefined,
    retentionFrequency: 'daily' | 'weekly' | 'monthly',
    reportType: IReportType,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>,
    startDate = format(
      startOfDay(subDays(new Date(), retentionFrequency === 'daily' ? 30 : 90)),
      DATE_FORMAT
    ),
    endDate = format(endOfDay(new Date()), DATE_FORMAT)
  ) => {
    const userRetentionService = new UserRetentionService();
    setIsRetentionCohortLoading('loading');
    await userRetentionService
      .cohort(
        activeWorkspace.id,
        insightId,
        startDate,
        endDate,
        retentionFrequency,
        reportType,
        audienceConditions,
        audienceConditionType,
        audienceGroupConditions
      )
      .then((res) => {
        setCohortData(res.data);
      })
      .catch();
    setIsRetentionCohortLoading('loaded');
  };

  const getCohortColumns = (
    retentionFrequency: 'daily' | 'weekly' | 'monthly',
    reportType: IReportType
  ) => {
    let headerCells = ['Date', `Total ${reportType == 'user' ? 'Users' : 'Companies'}`];
    switch (retentionFrequency) {
      case 'monthly':
        let monthsList = Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], (x) => `Month ${x}`);
        return headerCells.concat(monthsList);
      case 'weekly':
        let weeksList = Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], (x) => `Week ${x}`);
        return headerCells.concat(weeksList);
      case 'daily':
        let daysList = Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], (x) => `Day ${x}`);
        return headerCells.concat(daysList);
    }
  };
  return {
    isRetentionCohortLoading,
    cohortData,
    cohortValuesType,
    fetchUserRetentionCohort,
    getCohortColumns,
    setCohortValuesType
  };
}
