var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const getCountryName = (value: string) => {
  return value === "Unknown"
    ? value
    : countries.getName(value.toUpperCase(), "en", {
        select: "official"
      });
};
