import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Autocomplete, Box, Flex, Select, Tooltip } from "@mantine/core";
import { useDebouncedValue, useHover } from "@mantine/hooks";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { usePinnedEventStore } from "@/stores/usePinnedEventStore";
/**
 * Selector options for the condition
 */
const SELECTOR_OPTIONS = [
  {
    label: "Label text",
    value: "label_text"
  },
  {
    label: "CSS attribute(s)",
    value: "css_attribute"
  },
  {
    label: "Source page url",
    value: "source_page_url"
  },
  {
    label: "Destination page url",
    value: "destination_url"
  },

  { label: "HTML element ID", value: "element_id" },
  { label: "HTML element name", value: "element_name" }
];

/**
 * Operator options for the condition
 */
// const OPERATOR_OPTIONS = [
//   {
//     label: "is",
//     value: "is",
//     group: "Standard"
//   },
//   {
//     label: "is not",
//     value: "is_not",
//     group: "Standard"
//   },
//   {
//     label: "contains",
//     value: "contains",
//     group: "Standard"
//   },
//   {
//     label: "does not contain",
//     value: "does_not_contain",
//     group: "Standard"
//   },
//   {
//     label: "starts with",
//     value: "starts_with",
//     group: "Standard",
//     implementation: "regex" // These are implemented with regex in the backend
//   },
//   {
//     label: "ends with",
//     value: "ends_with",
//     group: "Standard",
//     implementation: "regex" // These are implemented with regex in the backend
//   },
//   {
//     label: "matches pattern",
//     value: "matches_pattern",
//     group: "Regex",
//     implementation: "regex" // These are implemented with regex in the backend
//   },
//   {
//     label: "matches regex",
//     value: "matches_regex",
//     group: "Regex",
//     implementation: "regex" // These are implemented with regex in the backend
//   }
// ];

const OPERATOR_OPTIONS = [
  {
    group: "Standard",
    items: [
      { label: "is", value: "is" },
      { label: "is not", value: "is_not" },
      { label: "contains", value: "contains" },
      { label: "does not contain", value: "does_not_contain" },
      // These are implemented with regex in the backend
      { label: "starts with", value: "starts_with", implementation: "regex" },
      { label: "ends with", value: "ends_with", implementation: "regex" }
    ]
  },
  {
    group: "Regex",
    items: [
      // These are implemented with regex in the backend
      { label: "matches pattern", value: "matches_pattern", implementation: "regex" },
      { label: "matches regex", value: "matches_regex", implementation: "regex" }
    ]
  }
];
interface Props {
  /**
   * Pinned event rule index
   */
  ruleIndex: number;
  /**
   * Condition index
   */
  index: number;
  /**
   * Selector value i.e label_text, css_attribute, source_page_url, destination_url, element_id, element_name
   */
  selector: string;
  /**
   * Operator value i.e is, is_not, contains, does_not_contain
   */
  operator: string;
  /**
   * Value of the condition
   */
  value: string;
  /**
   * isLoaded will be true when the pinned event mounted and loaded
   */
  isLoaded: boolean;
  /**
   * Function to get the suggested options for the auto-complete.
   */
  fetchOptions: (ruleIndex: number, conditionIndex: number, query: string) => Promise<any>;
  /**
   * Function to update the selector value
   */
  onSelectorChange: (value: string) => void;
  /**
   * Function to update the operator value
   */
  onOperatorChange: (value: string) => void;
  /**
   * Function to update the value of the condition
   */
  onValueChange: (value: string) => void;
  /**
   * Function to remove the condition
   */
  onRemove: () => void;
}

/**
 * This component is used to render the condition of the rule.
 * Each pinned event rule has multiple conditions. So, we need to
 * render multiple conditions for each rule.
 *
 * @returns
 */
export const PinnedEventCondition = ({
  isLoaded = false,
  ruleIndex = 0,
  index = 0,
  selector = "label_text",
  operator = "equals",
  value = "",
  onSelectorChange,
  onOperatorChange,
  onValueChange,
  onRemove,
  fetchOptions
}: Props) => {
  const { hovered, ref } = useHover();
  /**
   * Current active workspace
   */
  const { activeWorkspace } = useContext(AppLifecycleContext);

  /**
   * Zustand store function to get the total events
   */
  const [fetchTotalEvents] = usePinnedEventStore((state) => [state.fetchTotalEvents]);

  /**
   * This is the state to get the suggested options for the auto-complete.
   */
  const [debounced] = useDebouncedValue(value, 200);

  /**
   * Function to get the auto-complete suggestions. This function will be called
   * when the user have typed something in the auto-complete.
   *
   * fetchOptions is the function passed from the parent component.
   * @param q query string to search the options
   */
  const fetchAutocompleteOptions = (q: string) => {
    fetchOptions(ruleIndex, index, q)
      .then((res) => setOptions(res))
      .catch((err) => setOptions([]));
  };
  /**
   * This is the state to store the auto-complete suggestions.
   */
  const [options, setOptions] = useState([]);

  /**
   * Function to check if the selector supports regex or not.
   */
  const supportsRegex = (selector: string) => {
    return ["source_page_url"].includes(selector);
  };

  /**
   * Function to get the operator options for the select component.
   * @returns Operator options based on the selector
   */
  const getOperatorOptions = useMemo(() => {
    // If an option's `implementation` property is "regex", it checks whether the `selector`
    // supports regex. If it does, the option is included in the result.
    // If the `implementation` is not "regex", the option is always included.
    return OPERATOR_OPTIONS.map((group) => ({
      group: group.group,
      items: group.items.filter((option) => {
        if (option.implementation && option.implementation === "regex") {
          return supportsRegex(selector);
        }
        return true;
      })
    }));
  }, [selector]); // The function will be re-computed when `selector` changes.

  /**
   * Get example values for the auto-complete input for regex operators.
   * This will be shown in the description below the auto-complete input.
   */
  const getDescription = useMemo(() => {
    switch (operator) {
      case "matches_pattern":
        return "E.g.<url>/*/products/*";
      case "matches_regex":
        return "E.g.<url>/(product|category)/[0-9]+";
      default:
        return "";
    }
  }, [operator]);

  /**
   * Function to handle the change of the selector.
   * If the selector does not support regex and the operator is regex,
   * it will change the operator to "is".
   * @param value
   */
  const handleSelectorChange = (value: string) => {
    onSelectorChange(value);
    if (
      !supportsRegex(value) &&
      ["matches_pattern", "matches_regex", "starts_with", "ends_with"].includes(operator)
    ) {
      onOperatorChange("is");
    }
  };

  /**
   * Fetch the total events and auto-complete options when the component is loaded.
   */
  useEffect(() => {
    // Only fetch the options if component is loaded.
    if (isLoaded) {
      fetchAutocompleteOptions(debounced);
      fetchTotalEvents(activeWorkspace?.id);
    }
  }, [debounced]);

  return (
    <Flex align={"center"} ref={ref}>
      <Select
        // @ts-ignore
        onChange={handleSelectorChange}
        className=""
        value={selector}
        data={SELECTOR_OPTIONS}
        mr={8}
        data-cy="pinned-event-selector"
      />

      <Select
        // @ts-ignore
        onChange={onOperatorChange}
        value={operator}
        data={getOperatorOptions}
        mr={8}
      />
      <Autocomplete
        style={{
          zIndex: 1000
        }}
        error={value.length === 0}
        data={options}
        description={getDescription}
        inputWrapperOrder={["label", "input", "description", "error"]}
        placeholder={"Enter value..."}
        defaultValue={value}
        key={`autocomplete-search-${index}`}
        id={`autocomplete-search-${index}`}
        limit={25}
        w={300}
        onClick={() => fetchAutocompleteOptions(value)}
        maxDropdownHeight={250}
        value={value}
        onChange={(value: string) => {
          onValueChange(value);
        }}
        data-cy="pinned-event-condition-autocomplete"
      />
      <Box w={40}>
        {hovered && (
          <>
            <Tooltip label="Delete">
              <ActionIcon ml={"xs"} variant="subtle" size={"sm"} onClick={onRemove} color={"red"}>
                <FontAwesomeIcon icon={regular("trash")} />
              </ActionIcon>
            </Tooltip>
          </>
        )}
      </Box>
    </Flex>
  );
};
