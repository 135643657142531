import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext } from "react";
import { SettingsHeaderContent } from "@/ui/components/App/Settings/SettingsHeaderContent/SettingsHeaderContent";
import { TrackingPixel } from "@/ui/components/App/TrackingPixel/TrackingPixel";
import { Title, Text } from "@mantine/core";

export const GeneralWorkspaceTrackingPixels: React.FC<any> = () => {
  // eslint-disable-next-line
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);

  return (
    <>
      <Title order={4} mb="md">
        Usermaven Tracking Setup
      </Title>
      <Text mb="md">
        Get started by selecting the platform or language that powers your application. The fastest
        way to get started with Usermaven on the web is via using the JavaScript snippet.
      </Text>
      <TrackingPixel workspaceId={activeWorkspace.identifier}></TrackingPixel>
    </>
  );
};
