/* eslint-disable react-hooks/exhaustive-deps */
// @ts-ignore

import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Flex,
  Input,
  NumberInput,
  SegmentedControl,
  Select,
  Tooltip,
  Text
} from "@mantine/core";
import getSymbolFromCurrency from "currency-symbol-map";
import useConditionsValidityHook from "@/hooks/useConditionsValidityHook";
import useInsightHook from "@/hooks/useInsightHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { InsightsService } from "@/lib/services/InsightsService";
import { ThirdPartyTracking } from "@/lib/utils/ThirdPartyTracking";
import { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ConditionItem, ConversionGoalValueTypes, LoadingStateProps } from "types/types.d";

import { Label } from "@/ui/components/Common/Label/Label";

import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";
import { InsightsSetupEvents } from "../../InsightsSetupEvents/InsightsSetupEvents";
import { isGoalSelected } from "../Filters/FiltersUtility";
import { EventsService } from "@/lib/services/EventsService";
import { format, startOfDay, subDays } from "date-fns";
import { DATE_FORMAT } from "@/lib/utils/Constants";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useConversionGoalsStore } from "@/stores/useConversionGoalsStore";
import { StepCard } from "@/ui/components/Common/StepCard/StepCard";

interface GoalConditionsProps {
  goal_conditions: Array<ConditionItem>;
  conversion_value: number;
  conversion_value_type: ConversionGoalValueTypes;
  conversion_value_attribute: string;
  target: string;
  is_track_unique_conversion: boolean;
}

interface Props {
  isOpen: boolean;
  isEdit: boolean;
  onClose: (value: boolean) => void;
  reload: () => void;
  goal?: {
    id: string;
    name: string;
    conditions: GoalConditionsProps;
  };
  query?: any;
  setQuery?: any;
}

interface IFormInputs {
  name: string;
}

export const ConversionGoalModal = ({
  isOpen = false,
  isEdit,
  onClose,
  goal,
  reload,
  query,
  setQuery
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset
  } = useForm<IFormInputs>({
    defaultValues: {
      name: goal?.name || ""
    }
  });
  const { insightEvents, insightAttributes, insightPinnedEvents } = useInsightHook();

  // all existing conversion goals
  const [goals] = useConversionGoalsStore((state) => [state.list]);

  const [conversionValue, setConversionValue] = useState(
    goal?.conditions ? goal.conditions.conversion_value : 0
  );
  // revenue tracking type, static or dynamic
  const [trackingType, setTrackingType] = useState<string>(
    goal?.conditions ? goal.conditions.conversion_value_type : "static"
  );
  // properties of the selected custom event for dynamic tracking
  const [customEventProperties, setCustomEventProperties] = useState<any[]>([]);
  const [selectedEventProperty, setSelectedEventProperty] = useState<string | null>(
    goal?.conditions ? goal.conditions.conversion_value_attribute : null
  );

  const [trackUniqueConversion, setTrackUniqueConversion] = useState<boolean>(
    goal?.conditions ? goal.conditions.is_track_unique_conversion : true
  );
  const [conditions, setConditions] = useState<Array<ConditionItem>>(
    goal?.conditions && goal.conditions.hasOwnProperty("goal_conditions")
      ? goal.conditions.goal_conditions
      : []
  );
  const { stepsValidation } = useConditionsValidityHook();

  const onSubmit = async (data: any) => {
    console.log(data);
    console.log("Performing validation.");
    // validate unique name for conversion goal creation
    // if the goal is being edited, the name can be the same as the current goal
    if (
      goals.find(
        (g) => g.name.toLowerCase().trim() === data.name.toLowerCase().trim() && g.id !== goal?.id
      )
    ) {
      toast.error("Conversion goal with this name already exists.");
      return;
    }
    // validate conditions
    if (!stepsValidation(conditions, 1, 10)) {
      return;
    }
    // validate if dynamic tracking is enabled and an event property is selected
    if (trackingType === "dynamic" && !selectedEventProperty) {
      toast.error("Please select an event property for revenue calculation.");
      return;
    }
    console.log("Validation performed.");
    setLoading("loading");
    if (!goal?.id) {
      await new InsightsService()
        .create(activeWorkspace.id, {
          name: data.name,
          conditions: {
            goal_conditions: conditions,
            is_track_unique_conversion: trackUniqueConversion,
            conversion_value: trackingType === "static" ? conversionValue : 0,
            conversion_value_type: trackingType as ConversionGoalValueTypes,
            conversion_value_attribute:
              trackingType === "dynamic" ? selectedEventProperty || "" : "",
            target: ""
          },
          type: "web_conversion_goal"
        })
        .then((res) => {
          if (res.data) {
            toast.success("Conversion goal created successfully.");
            onClose(false);
            reload();
            setConditions([]);
            reset();
            new ThirdPartyTracking().track("web_conversion_goal_created");
          }
        })
        .catch((err) => {
          if (err && err?.response?.status === 422 && err?.response?.data?.limit_reached) {
            toast.error(
              "You have reached the limit. Please upgrade your plan to create more conversion goals."
            );
          }
        });
    } else {
      await new InsightsService()
        .update(activeWorkspace.id, goal.id, {
          name: data.name,
          conditions: {
            goal_conditions: conditions,
            is_track_unique_conversion: trackUniqueConversion,
            conversion_value: trackingType === "static" ? conversionValue : 0,
            conversion_value_type: trackingType as ConversionGoalValueTypes,
            conversion_value_attribute:
              trackingType === "dynamic" ? selectedEventProperty || "" : "",
            target: ""
          }
        })
        .then((res) => {
          if (res.data) {
            toast.success(`Conversion goal updated successfully.`);
            reload();
            if (isGoalSelected()) {
              setQuery({
                ...query,
                is_unique_conversion: trackUniqueConversion
              });
            }
          }
          onClose(false);
          setConditions([]);
          reset();
        })
        .catch((err) => err);
    }
    setLoading("loaded");
  };

  const getCustomEventProperties = async () => {
    const eventsService = new EventsService();
    await eventsService
      .eventAttributes(
        activeWorkspace.id,
        conditions[0]?.key,
        format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
        format(new Date(), DATE_FORMAT)
      )
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setCustomEventProperties(res.data.data.keys ? res.data.data.keys : []);
        }
      })
      .catch((err) => {});
  };

  // disable dynamic tracking if there are more than one conditions or if there is a non event condition
  const isDynamicDisabled = useMemo(() => {
    return conditions.length > 1 || conditions.every((condition) => condition.type !== "event");
  }, [conditions]);

  // get custom event properties if dynamic tracking is enabled
  // also get custom event properties if the tracking type is dynamic and the conditions are changed
  useEffect(() => {
    if (trackingType === "dynamic") {
      getCustomEventProperties();
    }
  }, [conditions, trackingType]);

  // change the tracking type to static if dynamic tracking is disabled due to change in conditions
  useEffect(() => {
    if (trackingType === "dynamic" && isDynamicDisabled) {
      setTrackingType("static");
    }
  }, [isDynamicDisabled]);

  useEffect(() => {
    if (isEdit) {
      console.log("Is Edit", goal?.conditions);
      setConditions(goal?.conditions["goal_conditions"] || []);
      setValue("name", goal?.name || "");
      setTrackUniqueConversion(
        goal?.conditions ? goal?.conditions.is_track_unique_conversion : true
      );
      setConversionValue(goal?.conditions ? goal?.conditions.conversion_value : 0);
      setTrackingType(goal?.conditions ? goal.conditions.conversion_value_type : "static");
      setSelectedEventProperty(
        goal?.conditions ? goal.conditions.conversion_value_attribute : null
      );
    } else {
      setConditions([]);
      setValue("name", "");
      setConversionValue(0);
      setTrackingType("static");
      setSelectedEventProperty(null);
      setTrackUniqueConversion(true);
    }
  }, [isEdit, goal]);

  return (
    <>
      <SidePanel
        opened={isOpen}
        onCancel={() => onClose(false)}
        title={
          isEdit ? (
            <h2 className="font-medium">Update Conversion Goal</h2>
          ) : (
            <h2 className="font-medium">Create Conversion Goal</h2>
          )
        }
        loading={true}>
        <div className="um-modal--body">
          <div className="um-modal--content--form relative">
            <form onSubmit={handleSubmit(onSubmit)} id="conversionGoalModal">
              <StepCard step={1}>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <Input.Wrapper
                      size="xs"
                      id={"conversionGoalTitle"}
                      label="Conversion goal name"
                      required>
                      <Input
                        mt={4}
                        defaultValue={goal?.name}
                        value={value}
                        onChange={onChange}
                        placeholder="e.g Visited pricing page"
                        error={(invalid || error) === true}
                        data-cy="conversion-goal-name"
                      />
                    </Input.Wrapper>
                  )}
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                />

                {errors.name?.type === "required" && (
                  <Text fz="xs" pt="xs" c="red">
                    Conversion goal name is required
                  </Text>
                )}
              </StepCard>
              <StepCard step={2}>
                <Flex direction={"column"}>
                  <Text fw={600} mb="md">
                    Choose your event for the conversion goal. e.g visitors who visited pricing
                    page.
                  </Text>
                  <div className="mt-4 flex flex-col">
                    <InsightsSetupEvents
                      conditionType={"or"}
                      conditions={conditions}
                      setConditions={setConditions}
                      attributesOptions={insightAttributes}
                      eventsOptions={insightEvents}
                      pinnedEventOptions={insightPinnedEvents}
                      nameIdentifier={"conversionGoal"}
                      maxConditions={10}
                      options={[
                        {
                          label: "Page URL",
                          value: "pageview"
                        },
                        { label: "Custom Event", value: "event" },
                        { label: "Pinned Event", value: "pinned_event" }
                      ]}
                    />
                  </div>
                </Flex>
              </StepCard>
              <StepCard step={3}>
                <Flex direction={"column"}>
                  <Text fw={600} mb="md">
                    Conversion goal value (optional){" "}
                  </Text>
                  <Tooltip
                    disabled={!isDynamicDisabled}
                    label="Dynamic conversion goal value is only available for custom events."
                    withinPortal
                    position="right"
                    withArrow>
                    <SegmentedControl
                      w={{ base: "100%", sm: "20rem" }}
                      value={trackingType}
                      onChange={setTrackingType}
                      fullWidth={false}
                      disabled={isDynamicDisabled}
                      my="sm"
                      // color={theme.colorScheme === "dark" ? "dark" : "brand"}
                      data={[
                        { value: "static", label: "Static" },
                        { value: "dynamic", label: "Dynamic" }
                      ]}
                    />
                  </Tooltip>
                  {trackingType === "static" && (
                    <NumberInput
                      w={130}
                      defaultValue={
                        goal?.conditions ? goal?.conditions.conversion_value : undefined
                      }
                      mb="md"
                      stepHoldDelay={500}
                      stepHoldInterval={100}
                      decimalScale={2}
                      value={conversionValue}
                      onChange={(val) => {
                        // @ts-ignore
                        setConversionValue(val || 0);
                      }}
                      leftSection={getSymbolFromCurrency(activeWorkspace.reporting_currency)}
                      data-cy="conversion-goal-value"
                    />
                  )}
                  {trackingType === "dynamic" && (
                    <Select
                      mb="md"
                      // withinPortal={true}
                      maxDropdownHeight={140}
                      placeholder="Select revenue attribute"
                      searchable
                      data={customEventProperties}
                      nothingFoundMessage="No attributes found for custom event"
                      value={selectedEventProperty}
                      onChange={(event) => setSelectedEventProperty(event)}
                    />
                  )}
                  <Alert color="yellow" icon={<FontAwesomeIcon icon={regular("info")} />}>
                    <Text>
                      You can set your default currency in{" "}
                      <Text span fw={500}>
                        "Workspace settings {">"} Miscellaneous"
                      </Text>{" "}
                      page.
                    </Text>
                  </Alert>
                </Flex>
              </StepCard>

              <Checkbox
                py="sm"
                checked={trackUniqueConversion}
                onChange={(event) => setTrackUniqueConversion(event.currentTarget.checked)}
                label="Track conversion rate and value based on unique conversions"
              />

              <Divider mt="md" />
              <Flex direction={"row-reverse"} my="md">
                <Button
                  type="submit"
                  loading={loading === "loading"}
                  disabled={loading === "loading"}
                  data-cy="conversion-goal-modal-submit-btn">
                  {isEdit ? "Update" : "Create"}
                </Button>
              </Flex>
            </form>
          </div>
        </div>
      </SidePanel>
    </>
  );
};
