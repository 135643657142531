import {
  Box,
  Button,
  Divider,
  Flex,
  Loader,
  Paper,
  Table,
  Text,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useContext, useEffect, useRef, useState } from "react";
import { Oval } from "react-loader-spinner";
import { loaderColor } from "@/style/Colors";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { ClickhouseDateToLocalRelativeTime } from "@/lib/utils/DateUtility";
import { useNavigate } from "react-router-dom";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { PulseLineGraph } from "../../../PulseLineGraph/PulseLineGraph";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export type IPinnedEventSection = {
  title: string;
  type: "pinned_events";
  hasEventsInWorkspace: boolean;
  onFetchEvents: (page: number) => Promise<IPinnedEventSectionItem[] | []>;
};

export type ICustomEventSection = {
  title: string;
  type: "custom_events";
  hasEventsInWorkspace: boolean;
  onFetchEvents: (page: number) => Promise<ICustomEventSectionItem[] | []>;
};

export type IEventSection = IPinnedEventSection | ICustomEventSection;

export type IPinnedEventSectionItem = {
  count: number;
  first_occurred_at: string;
  last_occurred_at: string;
  pinned_event_id: string;
  name: string;
  histogram: string[];
};

export type ICustomEventSectionItem = {
  count: number;
  first_occurred_at: string;
  last_occurred_at: string;
  event_type: string;
  is_muted: boolean;
  histogram: string[];
};

export type IEventSectionItem = IPinnedEventSectionItem | ICustomEventSectionItem;

function isPinnedEvent(
  event: IPinnedEventSectionItem | IEventSectionItem
): event is IPinnedEventSectionItem {
  return "name" in event;
}

const EventsSection = ({
  title = "Events",
  type = "pinned_events",
  hasEventsInWorkspace = true,
  onFetchEvents = () => Promise.resolve([])
}: IEventSection) => {
  const { colorScheme } = useMantineColorScheme();
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [eventsList, setEventsList] = useState<IEventSectionItem[]>([]);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const theme = useGlobalMantineTheme();
  const navigate = useNavigate();
  const page = useRef(1);

  const fetchTopEvents = async () => {
    setLoading(true);
    const fetchedEventsList = await onFetchEvents(page.current);

    if (fetchedEventsList) {
      setEventsList((prevEventsList) => [...prevEventsList, ...fetchedEventsList]);

      if (fetchedEventsList?.length < 5) {
        setHasMore(false);
      }
      page.current++;
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTopEvents();
  }, []);

  return (
    <Paper withBorder shadow="xs">
      {/* Header */}

      <Title order={5} fw={600} p="xs">
        {title}
      </Title>
      <Divider />

      {/* Body */}
      <Box
        p="xs"
        style={(theme) => ({
          overflowX: "auto",
          wordBreak: "normal"
        })}>
        {eventsList && eventsList.length > 0 && (
          <Table withTableBorder borderColor={colorScheme === "dark" ? "dark.6" : "gray.2"}>
            <Table.Thead fw={500} bg={colorScheme === "dark" ? "dark.6" : "gray.2"}>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Total Count</Table.Th>
                <Table.Th>First Occurrence</Table.Th>
                <Table.Th>Last Occurrence</Table.Th>
                <Table.Th>Last 30 days activity</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {eventsList.map((event) => (
                <Table.Tr key={isPinnedEvent(event) ? event.pinned_event_id : event.event_type}>
                  <Table.Td style={{ verticalAlign: "middle" }}>
                    {isPinnedEvent(event) ? event.name : event.event_type}
                  </Table.Td>
                  <Table.Td style={{ verticalAlign: "middle" }}>
                    {numberToCommas(event.count)}
                  </Table.Td>
                  <Table.Td style={{ verticalAlign: "middle" }}>
                    {ClickhouseDateToLocalRelativeTime(event.first_occurred_at)}
                  </Table.Td>
                  <Table.Td style={{ verticalAlign: "middle" }}>
                    {ClickhouseDateToLocalRelativeTime(event.last_occurred_at)}
                  </Table.Td>
                  <Table.Td style={{ verticalAlign: "middle" }}>
                    <Flex align={"center"} justify={"center"} h={48} w={160}>
                      {
                        <PulseLineGraph
                          color={theme.colors.brand[5]}
                          data={event.histogram}
                          name="Events"
                        />
                      }
                    </Flex>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        )}

        {loading ? (
          <Flex justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
            <Loader size="xs" />
          </Flex>
        ) : (
          <>
            {hasMore ? (
              <Flex h={80} justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
                <Button size="xs" variant="light" onClick={fetchTopEvents}>
                  Load More
                </Button>
              </Flex>
            ) : eventsList.length === 0 ? (
              <div className="flex w-full flex-grow items-center justify-center py-5 text-center text-gray-500 py-4">
                {type === "pinned_events" && !hasEventsInWorkspace ? (
                  <Button
                    variant="outline"
                    onClick={() =>
                      navigate(`/env/${activeWorkspace.identifier}/events/pinned-events`)
                    }>
                    Create Pinned Event
                  </Button>
                ) : (
                  <Text>No events performed.</Text>
                )}
              </div>
            ) : null}
          </>
        )}
      </Box>
    </Paper>
  );
};

export default EventsSection;
