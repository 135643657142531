/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Menu,
  Paper,
  Tooltip,
  useMantineTheme,
  Text,
  Flex,
  Divider
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useCompareAnalyticsStore from "@/stores/useCompareAnalyticsStore";
import { COMPARE_ANALYTICS_DROPDOWN_OPTIONS, DATE_FORMAT } from "@/lib/utils/Constants";
import { getComparisonPeriod } from "../WebAnalytics/WebAnalyticsComparisonDatesUtilities";
import { DateRange } from "react-date-range";
import { differenceInDays, format, parseISO, subDays } from "date-fns";
import { useWebFiltersStore } from "@/stores/useWebFiltersStore";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface CompareAnalyticsDropdownProps {
  scrollPosition?: number;
  query: any;
  setQuery: any;
  selectedPeriodOption: any; //state to store the selected comparison period option that may either be previous_period or custom.
  setSelectedPeriodOption: any; // setter for the above state selectedPeriodOption
  selectedComparisonLabel: any; // state to store the comparison dropdown label.
  setSelectedComparisonLabel: any; // setter for the above state selectedComparisonLabel
  onChange: (startDate: string, endDate: string, label: string, comparison?: string) => void;
}

export const CompareAnalyticsDropdown = ({
  scrollPosition,
  query,
  setQuery,
  selectedPeriodOption,
  setSelectedPeriodOption,
  selectedComparisonLabel,
  setSelectedComparisonLabel,
  onChange
}: CompareAnalyticsDropdownProps) => {
  const theme = useGlobalMantineTheme();
  const [opened, setOpened] = useState(false);

  const [selectedMatchDayorDateOption, setSelectedMatchDayorDateOption] = useState(
    COMPARE_ANALYTICS_DROPDOWN_OPTIONS.MATCH_EXACT_DATE
  );
  const [isCompareAnalyticsSelected, setIsCompareAnalyticsSelected] = useCompareAnalyticsStore(
    (state: any) => [state.isCompareAnalyticsSelected, state.setIsCompareAnalyticsSelected]
  );

  const periodOptions = [
    COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD,
    COMPARE_ANALYTICS_DROPDOWN_OPTIONS.CUSTOM_PERIOD
  ];

  const matchDayorDateOptions = [
    COMPARE_ANALYTICS_DROPDOWN_OPTIONS.MATCH_EXACT_DATE,
    COMPARE_ANALYTICS_DROPDOWN_OPTIONS.MATCH_DAY_OF_THE_WEEK
  ];

  const [dateLabel] = useWebFiltersStore((state) => [state.dateLabel]);

  // Calendar state
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  // Month-wise calendar date-picker
  const [state, setState] = useState([
    {
      startDate: subDays(
        parseISO(query.from_date),
        differenceInDays(parseISO(query.to_date), parseISO(query.from_date)) + 1
      ),
      endDate: subDays(
        parseISO(query.to_date),
        differenceInDays(parseISO(query.to_date), parseISO(query.from_date)) + 1
      ),
      key: "selection"
    }
  ]);

  useEffect(() => {
    if (isCompareAnalyticsSelected) {
      const { from_date, to_date, period } = query;
      let comparisonType =
        selectedPeriodOption === COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD
          ? "previous_period"
          : "custom";
      let match_day_of_week =
        selectedMatchDayorDateOption === COMPARE_ANALYTICS_DROPDOWN_OPTIONS.MATCH_DAY_OF_THE_WEEK
          ? true
          : false;
      if (from_date && to_date && period !== "live") {
        const comparisonPeriodDates = getComparisonPeriod({
          from_date,
          to_date,
          period,
          match_day_of_week,
          comparison: comparisonType,
          custom_range: query.custom_range,
          label: dateLabel
        });
        setQuery({
          ...query,
          comparison: comparisonType,
          match_day_of_week: match_day_of_week,
          comparison_from_date: comparisonPeriodDates.comparison_from_date,
          comparison_to_date: comparisonPeriodDates.comparison_to_date
        });
      } else {
        setIsCompareAnalyticsSelected(false);
      }
    }
  }, [isCompareAnalyticsSelected]);

  const handleAllowComparison = () => {
    let updatedState = !isCompareAnalyticsSelected;
    setIsCompareAnalyticsSelected(updatedState);
    setOpened(false);
    setSelectedPeriodOption(COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD);
    setSelectedComparisonLabel(COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD);
  };

  const handleOnPeriodOptionChange = (item: string) => {
    setSelectedComparisonLabel(item);
    setSelectedPeriodOption(item);
    setOpened(false);
    if (item === COMPARE_ANALYTICS_DROPDOWN_OPTIONS.CUSTOM_PERIOD) {
      setIsCalendarVisible(true);
    } else {
      const { from_date, to_date, period, comparison, custom_range, match_day_of_week } = query;
      const comparisonPeriodDates = getComparisonPeriod({
        from_date: from_date,
        to_date: to_date,
        period: period,
        match_day_of_week: match_day_of_week,
        comparison: comparison,
        custom_range
      });
      setQuery({
        ...query,
        comparison: "previous_period",
        comparison_from_date: comparisonPeriodDates.comparison_from_date,
        comparison_to_date: comparisonPeriodDates.comparison_to_date
      });
    }
  };

  const handleOnComparisonChange = (
    startDate: string,
    endDate: string,
    label: string,
    comparison?: string
  ) => {
    onChange(startDate, endDate, label, comparison);
  };

  const handleOnMatchDayorDateOptionChange = (item: string) => {
    setSelectedMatchDayorDateOption(item);
    let match_day_of_week =
      item === COMPARE_ANALYTICS_DROPDOWN_OPTIONS.MATCH_DAY_OF_THE_WEEK ? true : false;

    const { from_date, to_date, period } = query;

    const comparisonPeriodDates = getComparisonPeriod({
      from_date,
      to_date,
      period,
      match_day_of_week,
      comparison: "previous_period",
      custom_range: false,
      label: dateLabel
    });

    setQuery({
      ...query,
      comparison: "previous_period",
      comparison_from_date: comparisonPeriodDates.comparison_from_date,
      comparison_to_date: comparisonPeriodDates.comparison_to_date,
      custom_range: false,
      match_day_of_week: match_day_of_week
    });
    setOpened(false);
  };

  /**
   * Handle date range change for custom date range picker.
   * The values are set on the state variable state and picked from there.
   */
  const applyCustomChange = () => {
    const label = `${format(new Date(state[0].startDate), "MMM dd")} - ${format(
      new Date(state[0].endDate),
      "MMM dd"
    )}`;

    handleOnComparisonChange(
      format(new Date(state[0].startDate), DATE_FORMAT),
      format(new Date(state[0].endDate), DATE_FORMAT),
      label,
      "custom"
    );

    setIsCalendarVisible(false);
  };

  return (
    <div className="relative">
      <Menu
        shadow="md"
        width={200}
        opened={opened}
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}>
        <Menu.Target>
          <Button
            disabled={!isCompareAnalyticsSelected}
            size="xs"
            className="border border-solid border-gray-200 !font-medium"
            color={scrollPosition && scrollPosition > 0.03 ? "white" : "gray.6"}
            // color={theme.colorScheme === "dark" ? "dark.2" : "gray.7"}
            variant={scrollPosition && scrollPosition > 0.03 ? "light" : "outline"}
            leftSection={<FontAwesomeIcon icon={regular("list-dropdown")} />}
            rightSection={
              <FontAwesomeIcon
                icon={solid("angle-up")}
                className={`transition transform rotate-180 ${opened ? "rotate-0" : ""}`}
              />
            }
            data-cy="date-drop-down-btn">
            {selectedComparisonLabel || "Previous period"}
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={() => handleAllowComparison()}>
            <div className="flex items-center justify-between">
              <Text c="red">{COMPARE_ANALYTICS_DROPDOWN_OPTIONS.DISABLE_COMPARISON}</Text>
            </div>
          </Menu.Item>
          <Menu.Divider />
          {periodOptions.map((option, index) => (
            <React.Fragment>
              {option === COMPARE_ANALYTICS_DROPDOWN_OPTIONS.CUSTOM_PERIOD &&
              query.match_day_of_week ? (
                <Tooltip
                  label="Custom Period is only available for exact date comparison"
                  position="left"
                  w={230}
                  withArrow
                  multiline
                  style={{ fontSize: "12px" }}>
                  <div>
                    <Menu.Item disabled>
                      <div className="flex items-center justify-between">
                        <span>{option}</span>
                      </div>
                    </Menu.Item>
                  </div>
                </Tooltip>
              ) : (
                <Menu.Item key={index} onClick={() => handleOnPeriodOptionChange(option)}>
                  <Flex align="center">
                    <Flex flex={1}>{option}</Flex>
                    {option === selectedPeriodOption && <FontAwesomeIcon icon={regular("check")} />}
                  </Flex>
                </Menu.Item>
              )}
            </React.Fragment>
          ))}
          <Menu.Divider />
          {matchDayorDateOptions.map((option, index) => (
            <React.Fragment key={index}>
              {option === COMPARE_ANALYTICS_DROPDOWN_OPTIONS.MATCH_DAY_OF_THE_WEEK &&
              query.comparison === "custom" ? (
                <Tooltip
                  label="Match Day of Week is only available for previous period comparison"
                  position="left"
                  w={230}
                  withArrow
                  multiline
                  style={{ fontSize: "12px" }}>
                  <div>
                    <Menu.Item disabled>
                      <div
                        className={`flex items-center justify-between ${
                          option === selectedMatchDayorDateOption ? "font-semibold" : ""
                        }`}>
                        <span>{option}</span>
                      </div>
                    </Menu.Item>
                  </div>
                </Tooltip>
              ) : (
                <Menu.Item onClick={() => handleOnMatchDayorDateOptionChange(option)}>
                  <div
                    className={`flex items-center justify-between ${
                      option === selectedMatchDayorDateOption ? "font-semibold" : ""
                    }`}>
                    <span>{option}</span>
                  </div>
                </Menu.Item>
              )}
            </React.Fragment>
          ))}
        </Menu.Dropdown>

        {/* Custom date range calendar */}
        {isCalendarVisible && (
          <Paper
            shadow="md"
            withBorder
            pos="absolute"
            right={0}
            sx={() => ({
              // borderColor:
              //   theme.colorScheme === "dark"
              //     ? theme.colors.darkBorderColor[0]
              //     : theme.colors.lightBorderColor[0],

              ".rdrCalendarWrapper": {
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : ""
              },

              ".rdrDateDisplayWrapper": {
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : ""
              },

              ".rdrDateDisplayItem": {
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : ""
              },

              ".rdrNextPrevButton": {
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : ""
              },

              ".rdrDayNumber span": {
                color: theme.colorScheme === "dark" ? theme.colors.dark[1] : ""
              },

              ".rdrDayDisabled": {
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : ""
              }
            })}>
            <DateRange
              className="um-react-date-range"
              editableDateInputs={false}
              onChange={(item: any) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
              maxDate={subDays(parseISO(query.from_date), 1)}
              rangeColors={["#7D47EB"]}
              disabledDates={[]}
            />
            <Divider />
            <Flex
              py={"sm"}
              justify={"end"}
              sx={() => ({
                borderColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.darkBorderColor[0]
                    : theme.colors.lightBorderColor[0]
              })}>
              <Button
                color="red"
                size="xs"
                onClick={() => {
                  setIsCalendarVisible(false);
                  setQuery({
                    ...query,
                    comparison: "previous_period"
                  });
                  setSelectedComparisonLabel(COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD);
                  setSelectedPeriodOption(COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD);
                }}>
                Cancel
              </Button>
              <Button
                size="xs"
                ml={6}
                mr={6}
                onClick={() => {
                  applyCustomChange();
                }}>
                Apply
              </Button>
            </Flex>
          </Paper>
        )}
      </Menu>
    </div>
  );
};
