import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Avatar,
  Box,
  Card,
  Divider,
  Flex,
  Grid,
  Paper,
  Title,
  Tooltip,
  rgba,
  Text,
  useMantineTheme,
  useMantineColorScheme
} from "@mantine/core";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { LOADER_COLOR } from "../../../../../lib/utils/Constants";
import { utcToRelativeTimezone } from "../../../../../lib/utils/DateUtility";
import {
  getReadableFormatNumber,
  numberToCommas,
  stringToInitials,
  truncateText
} from "../../../../../lib/utils/StringUtility";
import { loaderColor } from "../../../../../style/Colors";
import { PulseLineGraph } from "../../PulseLineGraph/PulseLineGraph";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface IUserSection {
  /**
   * Specify the activities section title.
   */
  title?: string;

  /**
   * Specify the callback function to be called when fetching more users
   */
  onFetch?: (page: number) => Promise<IUser[] | []>;
}

export interface IUser {
  user_id: string;
  uniq_sessions: number;
  project_id: string;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  user_created_at: string;
  user_custom_map: any;
  location_country: string;
  location_city: string;
  _is_deleted?: boolean;
  last_visited_time: string;
  doc_path: string;
  url: string;
  page_title: string;
  visits: number;
  sessions: number;
  histogram: string[];
}

const UserSection = ({ title = "Users", onFetch = () => Promise.resolve([]) }: IUserSection) => {
  // Navigate from react-router-dom.
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const navigate = useNavigate();

  // For active workspace id of the user.
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // State to store the users.
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [users, setUsers] = useState<IUser[]>([]);

  const page = useRef(1);
  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);
  useEffect(() => {
    (async () => {
      await fetchUsers(true);
    })();
  }, []);

  /**
   * Fetch users.
   * @param clear
   */
  const fetchUsers = async (clear = false) => {
    if (clear) {
      page.current = 1;
    }
    if (hasMore || clear) {
      page.current === 1 && setLoading(true);
      const newUsers = await onFetch(page.current);
      console.log("newUsers", newUsers);
      if (newUsers.length > 0) {
        setUsers(clear ? [...newUsers] : [...users, ...newUsers]);
        page.current = page.current + 1;
      }
      setHasMore(false); // TODO: Fix this later, when we have pagination with the API.
      setLoading(false);
    }
  };

  /**
   * Method to render the username
   */
  const getUserName = useCallback((user: IUser) => {
    if (user.user_first_name && user.user_last_name) {
      return `${user.user_first_name} ${user.user_last_name}`;
    } else {
      return (
        user.user_first_name || user.user_last_name || <i className={"italic text-gray-800"}>...</i>
      );
    }
  }, []);

  const onRowClick = (item: any) => {
    setOverviewModalOpen(false);

    setTimeout(() => {
      setOverviewModalOpen(true, "user", item.user_id);
    }, 500);
  };

  return (
    <Paper withBorder>
      <Title order={5} fw={500} p="xs">
        {title}
      </Title>
      <Divider />

      <Box pos="relative" p="xs" className="p-4 overflow-y-auto um-users-container  relative">
        {!loading ? (
          <>
            <InfiniteScroll
              scrollableTarget="overview-modal-inner "
              dataLength={users.length}
              next={fetchUsers}
              hasMore={hasMore}
              endMessage={null}
              loader={
                <div className="um-virtual-table-loader">
                  <Oval color={LOADER_COLOR} height={16} width={16} />
                </div>
              }>
              {users && (
                <Box style={{ overflowX: "auto" }}>
                  {users.map((user, index) => (
                    <Card
                      withBorder
                      miw={800}
                      styles={{
                        root: {
                          "&:hover": {
                            backgroundColor:
                              colorScheme === "dark"
                                ? theme.colors.dark[6]
                                : rgba(theme.colors.dark[1], 0.1)
                          }
                        }
                      }}
                      className={`grid grid-cols-12 gap-4 py-8 ${
                        users.length - 1 === index ? "" : "border-b border-solid border-gray-200"
                      }`}
                      key={`company:user:${user.user_id}`}
                      onClick={() => onRowClick(user)}>
                      <Grid>
                        <Grid.Col span={4}>
                          <Flex
                            mx={4}
                            align="center"
                            style={{ cursor: "pointer", userSelect: "none" }}>
                            <Avatar
                              size="md"
                              variant="filled"
                              radius="xl"
                              color="brand"
                              mr={10}
                              tt="uppercase">
                              {stringToInitials(user.user_first_name + " " + user.user_last_name)}
                            </Avatar>
                            <Flex direction="column">
                              <Text fw={500} mb={6} tt="capitalize">
                                {getUserName(user)}
                              </Text>
                              <Text truncate pr={"sm"} fz="xs">
                                {user.user_email}
                              </Text>
                            </Flex>
                          </Flex>
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <Flex
                            direction="column"
                            style={{ cursor: "pointer", userSelect: "none" }}>
                            <Flex align="center" my={4}>
                              <FontAwesomeIcon
                                icon={regular("clock")}
                                style={{
                                  fontSize: "0.875rem",
                                  marginRight: "0.5rem"
                                }}
                              />
                              <Text fw={600} size="sm" fz={13} tt="uppercase">
                                Created
                              </Text>
                            </Flex>
                            {user.user_created_at ? (
                              <Text fz="xs">
                                {utcToRelativeTimezone(user.user_created_at.replace(" ", "T"))}
                              </Text>
                            ) : (
                              <Text fz="xs">...</Text>
                            )}
                          </Flex>
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <Flex
                            direction="column"
                            style={{ cursor: "pointer", userSelect: "none" }}>
                            <Flex align="center" my={4}>
                              <FontAwesomeIcon
                                icon={regular("eye")}
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#4B5563",
                                  marginRight: "0.5rem"
                                }}
                              />
                              <Text fw={600} fz={13} tt="uppercase">
                                Last seen
                              </Text>
                            </Flex>
                            <Text fz="xs">
                              {utcToRelativeTimezone(user.last_visited_time.replace(" ", "T"))}
                            </Text>
                          </Flex>
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <Flex
                            direction="column"
                            style={{ cursor: "pointer", userSelect: "none" }}>
                            <Flex align="center" my={4}>
                              <FontAwesomeIcon
                                icon={regular("browser")}
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#4B5563",
                                  marginRight: "0.5rem"
                                }}
                              />
                              <Text fw={600} fz={13} tt="uppercase">
                                Sessions
                              </Text>
                            </Flex>
                            <Tooltip
                              label={numberToCommas(user.uniq_sessions)}
                              disabled={user.uniq_sessions < 10000}
                              position="top-start">
                              <Text fz="xs">
                                {getReadableFormatNumber(user.uniq_sessions, 10000)}
                              </Text>
                            </Tooltip>
                          </Flex>
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <Flex align={"center"} my={4}>
                            <Flex direction="column" justify="center" style={{ cursor: "pointer" }}>
                              <PulseLineGraph
                                data={user.histogram}
                                color={theme.colors.brand[5]}
                                name="Events"
                              />
                            </Flex>
                            <Flex
                              direction="column"
                              justify="center"
                              style={{ cursor: "pointer" }}
                              ml="xs">
                              <ActionIcon variant="subtle" color="brand.5" radius="sm">
                                <FontAwesomeIcon icon={regular("eye")} />
                              </ActionIcon>
                            </Flex>
                          </Flex>
                        </Grid.Col>
                      </Grid>
                    </Card>
                  ))}
                </Box>
              )}
            </InfiniteScroll>
            {users.length === 0 && (
              <Text ta="center" py="sm">
                No users found.
              </Text>
            )}
          </>
        ) : (
          <>
            <div className="flex items-center justify-center flex-grow w-full h-20">
              <Oval color={loaderColor} height={12} width={12} />
            </div>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default UserSection;
