import { format } from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import { numberToCommas } from "./StringUtility";
import { differenceInDays, subDays, parseISO } from "date-fns";
import { useMantineTheme } from "@mantine/core";

const getAdjustedDate = (query: any, firstAxisValueLabel: string) => {
  if (query.period === "hour") {
    const currentDate = toZonedTime(parseISO(firstAxisValueLabel), "UTC");
    const oneDayBackDate = subDays(currentDate, 1);
    const formattedDate = format(oneDayBackDate, "EEE, dd MMM yy, haa");
    return formattedDate;
  } else {
    // Parse the provided date strings
    const { from_date, comparison_from_date, comparison_to_date, comparison } = query;
    const fromDate = parseISO(from_date);
    const comparisonFromDate = parseISO(comparison_from_date);
    const comparisonToDate = parseISO(comparison_to_date);
    const axisDate = parseISO(firstAxisValueLabel);
    let daysDifference = 0;

    if (comparison === "previous_period") {
      // Calculate the difference in days
      daysDifference = differenceInDays(comparisonToDate, comparisonFromDate);
    } else {
      // Calculate the difference in days
      daysDifference = differenceInDays(fromDate, comparisonFromDate);
    }

    if (query.match_day_of_week && !["hour", "live"].includes(query.period)) {
      daysDifference = differenceInDays(fromDate, comparisonFromDate);
    }

    // Subtract the daysDifference from firstAxisValueLabel and return the new date
    let dateFormat = query.period === "month" ? "MMM yy" : "eee, MMMM dd, yyyy";
    let adjustedDate = subDays(axisDate, daysDifference);
    return query.match_day_of_week
      ? format(adjustedDate, dateFormat)
      : format(toZonedTime(adjustedDate, "UTC"), dateFormat);
  }
};

const getColor = (seriesName: string, comparison: boolean = false) => {
  if (seriesName === "Visit Duration") {
    return comparison ? "#B6EDD4" : "#26B774";
  } else if (seriesName === "Bounce Rate") {
    return comparison ? "#FFBABA" : "#FF5E5E";
  } else {
    return comparison ? "#E7DBFF" : "#7D47EB";
  }
};

export const GenericComparisonTooltip = (
  params: any,
  showSecondSeries = false,
  dateFormat = "eee, MMMM dd, yyyy",
  period?: string,
  query?: any,
  seriesName?: any,
  timeZone?: any
) => {
  // NOTE: For Comparison params[1] is the current data and params[0] is the comparison data
  let comparisonDate = null;
  let isSeriesOneCurrent = params[0].seriesName.includes(["Current"]);
  let isSeriesTwoCurrentPartial =
    params.length > 1 ? params[1].seriesName.includes(["Current Partial"]) : false;

  dateFormat =
    period === "month" ? "MMM yy" : period === "hour" ? "EEE, dd MMM yy, haa" : dateFormat;
  timeZone = period === "hour" ? "UTC" : timeZone;

  const seriesOneformattedDate = format(
    toZonedTime(parseISO(params[0].value[0]), timeZone),
    dateFormat
  );
  const seriesTwoformattedDate =
    params.length > 1
      ? format(toZonedTime(parseISO(params[1].value[0]), timeZone), dateFormat)
      : null;

  if (
    isSeriesOneCurrent &&
    isSeriesTwoCurrentPartial &&
    params[0].value[1] === params[1].value[1]
  ) {
    showSecondSeries = false;
  }
  if (query && query.comparison_from_date && query.comparison_to_date) {
    comparisonDate = getAdjustedDate(query, params[0].value[0]);
  }
  return `
      <div class="echart-tooltip">
        <div class="echart-tooltip-header">${seriesName}</div> 
        <div class="flex items-center mt-3" style="margin-top:8px;">
          <span class="circle-inside-tooltip" style="background: ${getColor(
            seriesName,
            !isSeriesOneCurrent
          )};" ></span> 
          ${
            isSeriesOneCurrent ? seriesOneformattedDate : comparisonDate
          } <span class="font-bold ml-4">${numberToCommas(params[0].value[1])}</span>
        </div>

        ${
          showSecondSeries && params.length > 1
            ? ` <div class="flex items-center mt-2">
              <span  class="circle-inside-tooltip" style="background: ${getColor(
                seriesName
              )}; " ></span> 
              ${seriesTwoformattedDate} <span class="font-bold ml-4">${numberToCommas(
                params[1].value[1]
              )}</span>
            </div>`
            : ""
        }

        ${
          period && period !== "live" && period !== "hour"
            ? `<div class="echart-tooltip-italic">
            Click to view this ${period}'s stats
        </div>`
            : ""
        }
      </div>`;
};

export const GenericTooltip = (
  params: any,
  showSecondSeries = false,
  dateFormat = "eee, MMMM dd, yyyy",
  period?: string,
  timeZone?: any
) => {
  // const theme = useGlobalMantineTheme();
  dateFormat =
    period === "month"
      ? "MMM yy"
      : period === "hour"
      ? "EEE, dd MMM yy, haa"
      : period === "live"
      ? "HH:mm"
      : dateFormat;
  timeZone = period === "hour" || period === "live" ? "UTC" : timeZone;
  const formattedDate =
    period === "hour" || period === "live"
      ? format(toZonedTime(parseISO(params[0].value[0]), timeZone), dateFormat)
      : format(parseISO(params[0].value[0]), dateFormat);

  return `<div class="echart-tooltip">
            <div class="echart-tooltip-header">${formattedDate}</div> 
            
            ${
              showSecondSeries && params.length > 1
                ? ` 
            <div class="echart-tooltip-row">
              <span class="circle-inside-tooltip" style="background-color: #868e96;"></span> 
                Total ${params[1].seriesName.split(" ")[1]} 
              <span class="font-bold ml-4">${numberToCommas(
                params[0].value[1] + params[1].value[1]
              )}</span>
            </div>`
                : ""
            }
            <div class="echart-tooltip-row">
              <span class="circle-inside-tooltip" style="background: ${params[0].color}; " ></span> 
              ${params[0].seriesName} 
              <span class="font-bold ml-4">${numberToCommas(params[0].value[1])}</span>
            </div>
            ${
              showSecondSeries && params.length > 1
                ? ` 
            <div class="echart-tooltip-row">
              <span class="circle-inside-tooltip" style="background: ${params[1].color} " ></span> 
              ${params[1].seriesName} 
              <span class="font-bold ml-4">${numberToCommas(params[1].value[1])}</span>
            </div>`
                : ""
            }
            ${
              period && period !== "live" && period !== "hour"
                ? `
                <div class="echart-tooltip-italic">
            Click to view this ${period}'s stats
            </div>
            </div>`
                : ""
            }
          </div>`;
};

export const GenericTooltipBar = (
  params: any,
  showSecondSeries = false,
  dateFormat = "eee, MMMM dd, yyyy",
  period: string,
  timeZone?: any
) => {
  dateFormat =
    period === "month"
      ? "MMM yy"
      : period === "hour"
      ? "EEE, dd MMM yy, haa"
      : period === "live"
      ? "HH:mm"
      : dateFormat;
  timeZone = period === "hour" || period === "live" ? "UTC" : timeZone;
  const formattedDate =
    period === "hour" || period === "live"
      ? format(toZonedTime(parseISO(params[0].data.date), timeZone), dateFormat)
      : format(parseISO(params[0].data.date), dateFormat);

  return `<div class="echart-tooltip">
                <div class="echart-tooltip-header">${formattedDate}</div>

                ${
                  showSecondSeries && params.length > 1
                    ? ` <div class="echart-tooltip-row">
                    <span class="circle-inside-tooltip" style="background-color: #868e96;"></span> 
                      Total ${
                        params[1].seriesName.split(" ")[1]
                      } <span class="font-bold ml-4">${numberToCommas(
                        params[0].data.value + params[1].data.value
                      )}</span>
                      </div>`
                    : ""
                }

                <div class="echart-tooltip-row">

                <span style="background: ${
                  params[0].color.colorStops[2].color
                }" class="circle-inside-tooltip"></span> ${
    params[0].seriesName
  } <span class="font-bold ml-4">${numberToCommas(params[0].data.value)}</span>

                
                </div>
                ${
                  showSecondSeries && params.length > 1
                    ? ` <div class="echart-tooltip-row">
                    <span style="background: ${
                      params[1].color.colorStops[2].color
                    }" class="circle-inside-tooltip"></span> ${
                        params[1].seriesName
                      } <span class="font-bold ml-4">${numberToCommas(params[1].data.value)}</span>
                      </div>`
                    : ""
                }
                ${
                  period && period !== "live" && period !== "hour"
                    ? `
                    <div class="echart-tooltip-italic">
                Click to view this ${period}'s stats
                </div>
                </div>`
                    : ""
                }
                </div>
                `;
};

export const xAxisLabelFormatter = (value: string, params: any) => {
  if (value === "month") {
    return "{MMM}";
  } else if (value === "hour") {
    return format(toZonedTime(new Date(params), "UTC"), "HH");
  } else if (value === "live") {
    return format(toZonedTime(new Date(params), "UTC"), "HH:mm");
  } else {
    return "{MMM} {dd}";
  }
};

export const getDateFormatFromPeriod = (period: string) => {
  if (period === "hour") {
    return "hh a";
  } else if (period === "live") {
    return "hh:mm";
  } else {
    return "eee, MMMM dd, yyyy";
  }
};

export const getDateWithZonedTimeToUTC = (date: any, period: string) => {
  const dateValue = !date.includes(" ") ? `${date} 00:00:00` : date;
  return period === "hour"
    ? format(new Date(dateValue.replace(" ", "T")), "H")
    : period === "month"
    ? format(new Date(dateValue.replace(" ", "T")), "MMMM")
    : period === "live"
    ? format(new Date(dateValue.replace(" ", "T")), "k:mm")
    : format(fromZonedTime(new Date(dateValue.replace(" ", "T")), "UTC"), "ccc, MMM d");
};
export const dateWithUtcToZonedTime = (date: any) => {
  const dateValue = !date.includes(" ") ? `${date} 00:00:00` : date;
  return toZonedTime(new Date(dateValue), "UTC");
};
