import { useState } from 'react';
import { toast } from 'react-toastify';
import { ConditionItem } from 'types/types.d';

export default function useConditionsValidityHook() {
  const [isValidationChecked, setIsValidationChecked] = useState(false);
  const stepsValidation = (
    conditions: Array<ConditionItem>,
    minimumConditions: number = 1,
    maximumConditions: number = 100
  ) => {
    if (conditions.length < minimumConditions) {
      toast.error(`Please add at least ${minimumConditions} condition(s).`);
      return;
    }
    if (conditions.length > maximumConditions) {
      toast.error(`Please make sure you have only ${maximumConditions} condition(s).`);
      return;
    }
    const isInvalid = conditions.some((item: ConditionItem) => {
      if (item.type === 'attribute' || item.type === 'pageview') {
        if (!['exists', '!exists'].includes(item.operator)) {
          if (item.type === 'pageview') {
            // we only check for the value
            return item.value.trim().length === 0;
          } else {
            return item.value.length === 0 || item.key.length === 0;
          }
        }
      }
      if (item.type === 'event') {
        return item.key.length === 0;
      }
      if (item.type === 'pinned_event') {
        return item.key.length === 0;
      }
    });

    if (isInvalid) {
      toast.error('Please complete your conditions first.');
      setIsValidationChecked(false);

      return;
    }
    setIsValidationChecked(true);
    return true;
  };
  return {
    isValidationChecked,
    setIsValidationChecked,
    stepsValidation
  };
}
