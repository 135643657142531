import React from "react";
import { LabelContainer, LabelRequired } from "./Label.style";

export interface LabelProps {
  /**
   * Is the field required. If so, * will be added.
   */
  required?: boolean;

  /**
   * Text for the label
   */
  text?: string;

  /**
   * How large should the text size be?
   */
  size?: "small" | "medium" | "large";

  bold?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const Label: React.FC<LabelProps> = ({
  required = false,
  bold = false,
  size = "medium",
  text = "Your label text",
  ...props
}) => {
  return (
    <LabelContainer bold={bold} size={size}>
      {text}
      {required && <LabelRequired>*</LabelRequired>}
    </LabelContainer>
  );
};
