import {
  EventPayload,
  UsermavenClient,
  usermavenClient,
  UsermavenOptions,
} from "@usermaven/sdk-js";
import { getAdminLoggedInStorage } from "@/lib/utils/Storage";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

export type UseUsermaven = {
  usermaven: UsermavenClient;
};

export function useUsermaven(opts: UsermavenOptions): UseUsermaven {
  const usermaven = useMemo(() => usermavenClient(opts), [opts]);
  // usermaven.set({ project_id: process.env.REACT_APP_USERMAVEN_PROJECT_ID });
  return { usermaven };
}

export function usePageView(usermaven: UsermavenClient, event?: EventPayload) {
  let location = useLocation();
  console.log("location change", location);

  useEffect(() => {
    if (getAdminLoggedInStorage() !== "true") {
      usermaven.track("pageview");
    }
  }, [location, usermaven]);
}
