import DeveloperDocumentationSVG from "@assets/images/icons/developer-documentation.svg"
import TrackingPixelSVG from "@assets/images/icons/tracking-pixel.svg"
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import "./onboarding_installation_setup.scss"

export const OnboardingInstallationSetup = (props: any) => {
  const navigate = useNavigate()
  const iconsHeight = "60"
  useEffect(() => {
    document.title = "Workspace Installation | Usermaven"
  }, [])
  return (
    <div className="um-onboarding">
      <div className="um-onboarding--navigation">
        <div className="um-onboarding--navigation--back-button"></div>
        <div className="um-onboarding--navigation--steps">1/3</div>
      </div>

      <h2 className="um-onboarding--heading">Installing Usermaven takes less than 2 minutes</h2>
      <p className="um-onboarding--info">
        Install Usermaven to start tracking data and capturing leads with Assistant.
      </p>
      <div className="um-onboarding--container mx-auto justify-center">
        <div
          className="um-onboarding--container--box "
          onClick={() => navigate("/onboarding/tracking-pixel")}
        >
          <div className="um-onboarding--container--box--image">
            <img src={TrackingPixelSVG} alt="tracking-pixel" height={iconsHeight} />
          </div>

          <p>Copy and paste code (most common)</p>
        </div>
        {/* <div className="um-onboarding--container--box">
          <div className="um-onboarding--container--box--image">
            <img src={SegmentSVG} alt="segment" height={iconsHeight} />
          </div>
          <p>Connect Segment to Usermaven</p>
        </div> */}
        <div className="um-onboarding--container--box">
          <div className="um-onboarding--container--box--image">
            <img
              src={DeveloperDocumentationSVG}
              alt="developer documentation"
              height={iconsHeight}
            />
          </div>
          <p>View developer documentation</p>
        </div>
      </div>

      <p className="um-onboarding-logout">
        Or,{" "}
        <Link to={`/logout`} className="pl-2 primary-link-color">
          Logout
        </Link>
      </p>
    </div>
  )
}
