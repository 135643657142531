import { Box, Flex, Title, Tooltip, useMantineTheme, Text, Skeleton } from "@mantine/core";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
// import { CurrentPeriod } from "./CurrentPeriod"
// import { ComparisonPeriod } from "./ComparisonPeriod"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import ContentLoader from "react-content-loader";
import classes from "./SummaryCard.module.css";
import clsx from "clsx";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
interface Props {
  activeCard: ActiveCardProps;
  activeCardColor?: string;
  card: ActiveCardProps;
  title: string;
  value: number | string;
  runningDiff: number;
  tooltip: string;
  aboutTooltip?: string;
  runningDiffTooltip: string;
  showRunningDiff?: boolean;
  uniquekey: string;
  loading: LoadingStateProps;
  borderRight?: boolean;
  cursor?: string;
  onClick: () => void;
  inverseColors?: boolean;
  comparison?: boolean;
  comparisonPercentageDiff?: number;
}

export const SummaryCard = ({
  activeCard,
  activeCardColor = "brand.5",
  card,
  title,
  value,
  tooltip,
  aboutTooltip,
  runningDiff,
  runningDiffTooltip,
  showRunningDiff = true,
  uniquekey,
  loading,
  borderRight = true,
  onClick,
  cursor = "cursor-pointer",
  inverseColors = false,
  comparison = false,
  comparisonPercentageDiff
}: Props) => {
  const theme = useGlobalMantineTheme();

  if (activeCardColor === "brand.5" && theme.colorScheme === "dark") {
    activeCardColor = "brand.2";
  }
  const decreaseColor = inverseColors ? "green.7" : "red.7";
  const increaseColor = inverseColors ? "red.7" : "green.7";
  return (
    <Flex
      direction={"column"}
      p={"xs"}
      className={clsx(
        classes.card,
        !borderRight && activeCard ? classes.noBorder : "",
        !borderRight && !activeCard ? classes.noBorder : "",
        card === activeCard ? classes.activeCard : ""
      )}
      onClick={onClick}>
      <>
        <Flex align={"center"} mb="xs">
          <Title mr={"xs"} order={5} fw={600} c={activeCard === card ? activeCardColor : ""}>
            {title}
          </Title>

          {aboutTooltip && (
            <>
              <Tooltip
                multiline
                w={480}
                position="right"
                label={aboutTooltip}
                style={{
                  zIndex: 1000
                }}>
                <Flex c="gray.7" fz="sm">
                  <FontAwesomeIcon icon={regular("info-circle")}></FontAwesomeIcon>
                </Flex>
              </Tooltip>
            </>
          )}
        </Flex>

        {loading === "loaded" ? (
          <Flex align={"center"} flex={1}>
            <Tooltip multiline={true} position="right" label={tooltip}>
              <Title order={4}>{value}</Title>
            </Tooltip>

            {showRunningDiff && (
              <>
                {runningDiff !== 0 && (
                  <>
                    <div className="pl-4 text-base flex items-center">
                      {comparison && comparisonPercentageDiff ? (
                        <>
                          <Tooltip multiline w={300} label={runningDiffTooltip}>
                            <Text
                              fz="xs"
                              c={comparisonPercentageDiff < 0 ? decreaseColor : increaseColor}>
                              <FontAwesomeIcon
                                icon={
                                  comparisonPercentageDiff < 0
                                    ? regular("arrow-down")
                                    : regular("arrow-up")
                                }
                              />
                              <Text fz="xs" span pl={4}>
                                {comparisonPercentageDiff}%
                              </Text>
                            </Text>
                          </Tooltip>
                        </>
                      ) : comparison ? null : (
                        <>
                          <Tooltip position="right" multiline w={300} label={runningDiffTooltip}>
                            <Text fz="xs" c={runningDiff < 0 ? decreaseColor : increaseColor}>
                              <FontAwesomeIcon
                                icon={runningDiff < 0 ? regular("arrow-down") : regular("arrow-up")}
                                className={runningDiff < 0 ? decreaseColor : increaseColor}
                              />
                              <Text fz="xs" span pl={4}>
                                {runningDiff}%
                              </Text>
                            </Text>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </Flex>
        ) : (
          <Box mt={8}>
            <Skeleton height={15} width={100} />
          </Box>
        )}
      </>
    </Flex>
  );
};
