import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Anchor,
  Box,
  Divider,
  Flex,
  Group,
  Paper,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Title
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { UsersCRMService } from "@/lib/services/UsersCRMService";
import { StatsSkeleton } from "@/lib/utils/ChartsSkeletons";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UsersHistogram } from "./UsersHistogram";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export const DashboardOverviewUsers = ({ query }: any) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const navigate = useNavigate();
  // count companies

  const [usersCountWithPeriod, setUsersCountWithPeriod] = useState({
    today: 0,
    yesterday: 0,
    week: 0,
    month: 0,
    total: 0
  });

  const [isUsersCountLoading, setIsUsersCountLoading] = useState("idle");

  const fetchUsersCountWithPeriod = async () => {
    setIsUsersCountLoading("loading");
    await new UsersCRMService()
      .usersWithPeriod(activeWorkspace.id)
      .then((res) => {
        // if (res.data.length > 0) {
        setUsersCountWithPeriod(res.data);
        // }
      })
      .catch((err) => {});
    setIsUsersCountLoading("loaded");
  };
  useEffect(() => {
    fetchUsersCountWithPeriod();
  }, [activeWorkspace.id]);
  return (
    <>
      <Paper shadow="sm" withBorder mb="md">
        <Title order={5} fw={600} className="font-medium" p="md">
          Users
        </Title>
        <Divider />
        {isUsersCountLoading === "loaded" && usersCountWithPeriod?.total > 0 && (
          <SimpleGrid p="md" cols={{ base: usersCountWithPeriod?.total > 0 ? 5 : 1, sm: 1, md: 5 }}>
            <>
              {usersCountWithPeriod?.total > 0 && (
                <>
                  <Stack gap={8}>
                    <Title order={4}>{numberToCommas(usersCountWithPeriod.today)}</Title>
                    <Text size="xs" tt="uppercase">
                      Today
                    </Text>
                  </Stack>
                  <Stack gap={8}>
                    <Title order={4}>{numberToCommas(usersCountWithPeriod.yesterday)}</Title>
                    <Text size="xs" tt="uppercase">
                      Yesterday
                    </Text>
                  </Stack>
                  <Stack gap={8}>
                    <Title order={4}>{numberToCommas(usersCountWithPeriod.week)}</Title>
                    <Text size="xs" tt="uppercase">
                      This Week
                    </Text>
                  </Stack>
                  <Stack gap={8}>
                    <Title order={4}>{numberToCommas(usersCountWithPeriod.month)}</Title>
                    <Text size="xs" tt="uppercase">
                      This Month
                    </Text>
                  </Stack>

                  <Stack gap={8}>
                    <Title order={4}>{numberToCommas(usersCountWithPeriod.total)}</Title>
                    <Text size="xs" tt="uppercase">
                      Total
                    </Text>
                  </Stack>
                </>
              )}
            </>
          </SimpleGrid>
        )}
        {isUsersCountLoading === "loading" && (
          <Box mx="md" mt="lg">
            <SimpleGrid cols={5}>
              <Skeleton height={15} width={80} />
              <Skeleton height={15} width={80} />
              <Skeleton height={15} width={80} />
              <Skeleton height={15} width={80} />
              <Skeleton height={15} width={80} />
            </SimpleGrid>
          </Box>
        )}
        {isUsersCountLoading === "loaded" && usersCountWithPeriod.total === 0 && (
          <>
            <Alert
              my="sm"
              icon={
                <FontAwesomeIcon
                  icon={regular("info-circle")}
                  className="text-md text-blue-500 py-2"></FontAwesomeIcon>
              }>
              <Text>
                There are no users available. Start adding your users by following our{" "}
                <Anchor
                  onClick={() => navigate(`/env/${activeWorkspace.identifier}/settings/setup`)}>
                  integration guide.
                </Anchor>
              </Text>
            </Alert>
          </>
        )}
        {/*<div className="um-insights-dashboard-container__box-content-stats h-28">*/}
        {/*  */}
        {/*</div>*/}

        <UsersHistogram query={query} />
      </Paper>
    </>
  );
};
