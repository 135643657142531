import { Title, Text, Select } from "@mantine/core";
import { useEffect, useState } from "react";

export const AccountsGeneralPreference = () => {
  const [fontSize, setFontSize] = useState<string | null>(
    localStorage.getItem("fontSize") || "default"
  );

  useEffect(() => {
    // Apply the font size from local storage on initial load
    if (fontSize) {
      document.documentElement.style.setProperty("font-size", `var(--font-size-${fontSize})`);
    } else {
      document.documentElement.style.setProperty("font-size", "var(--font-size-default)");
    }
  }, [fontSize]);

  return (
    <>
      <Title order={4} fw={600} mb="xs">
        Font Size
      </Title>
      <Text mb="md">
        Select your preferred font size to enhance readability. Your preference will be saved for
        future visits.
      </Text>
      <Select
        w={250}
        data={[
          { value: "smaller", label: "Smaller" },
          { value: "small", label: "Small" },
          { value: "default", label: "Default" },
          { value: "large", label: "Large" },
          { value: "larger", label: "Larger" }
        ]}
        value={fontSize}
        tt="capitalize"
        onChange={(value: string | null) => {
          localStorage.setItem("fontSize", value || "default");
          setFontSize(value);
        }}
        placeholder="Select font size"
      />
    </>
  );
};
