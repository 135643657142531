import React from "react";
import ReactDOM from "react-dom/client";
import "@assets/scss/main.scss";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { MantineProvider, localStorageColorSchemeManager } from "@mantine/core";
import { emotionTransform } from "@mantine/emotion";
// import {
//   faLaptopCode,
//   fas,
//   faUserPlus,
//   faThList,
//   faMousePointer
// } from '@fortawesome/pro-solid-svg-icons';
// import { library } from '@fortawesome/fontawesome-svg-core';

// library.add(fas, faUserPlus, faLaptopCode, faMousePointer, faThList);

function noop() {}

if (process.env.REACT_APP_APP_ENV !== "develop") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}
// disable logs on prod
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
}

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);
const colorSchemeManager = localStorageColorSchemeManager({
  key: "usermaven-app-color-scheme"
});

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <MantineProvider>
      <App />
    </MantineProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
