import { useContext, useRef, useState, useEffect } from "react";
import { Box, Button, Center, Loader, Text, TextInput, Title } from "@mantine/core";
import { SettingsHeaderContent } from "../../../../components/App/Settings/SettingsHeaderContent/SettingsHeaderContent";
import { openConfirmModal, closeAllModals } from "@mantine/modals";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { toast } from "react-toastify";
import { WorkspaceService } from "../../../../../lib/services/WorkspaceService";
import { useNavigate } from "react-router-dom";
import { LoadingStateProps } from "../../../../../types/types.d";
import { Oval } from "react-loader-spinner";
import { LOADER_COLOR } from "../../../../../lib/utils/Constants";

export const GeneralWorkspaceDelete = () => {
  const navigate = useNavigate();
  const { activeWorkspace, setActiveWorkspace, workspaces, setWorkspaces } =
    useContext(AppLifecycleContext);
  const workspaceNameInput = useRef<HTMLInputElement>(null);
  const [defaultWorkspaceInfo, setDefaultWorkspaceInfo] = useState<any>();
  const [loading, setLoading] = useState<LoadingStateProps>("idle");

  const deleteWorkspace = async () => {
    if (defaultWorkspaceInfo?.is_default_workspace && workspaces.length > 1) {
      toast.error(
        "You cannot delete your default workspace. Please change your default workspace to some other workspace. After that try deleting this workspace",
        { autoClose: 10000 }
      );
    } else {
      await new WorkspaceService()
        .deleteWorkspace(activeWorkspace.id)
        .then((res) => {
          if (res.data) {
            toast.success("Workspace deleted successfully");
            setActiveWorkspace({});
            const updatedWorkspaces = workspaces.filter(
              (workspace: any) => workspace.identifier != activeWorkspace.identifier
            );
            setWorkspaces(updatedWorkspaces);
            navigate("/workspaces");
            closeAllModals();

            // if (workspaces.length == 1) {
            //   navigate("/workspaces")
            // } else {
            //   const updatedWorkspaces = workspaces.filter(
            //     (workspace: any) => workspace.identifier != activeWorkspace.identifier
            //   )
            //   setWorkspaces(updatedWorkspaces)
            //   setActiveWorkspace([defaultWorkspaceInfo?.default_workspace])
            //   navigate(`/env/${defaultWorkspaceInfo?.default_workspace.identifier}/web/statistics`)
            // }
          }
        })
        .catch((err) => {});
    }
  };

  const removeWorkspacePrompt = () => {
    if (loading == "loaded") {
      openConfirmModal({
        title: (
          <Title order={5} fw={600}>
            Delete workspace
          </Title>
        ),
        size: "lg",
        children: (
          <>
            <Text mb={"sm"}>
              This action cannot be undone. Deleting a workspace will also remove all the associated
              data like team members, conversion goals, funnels, etc.
            </Text>
            <Text mb={"sm"}>
              Please type in the exact name of the workspace{" "}
              <Text span fw={500}>
                "{activeWorkspace.name}"
              </Text>{" "}
              to confirm:
            </Text>
            <TextInput placeholder="Workspace name" ref={workspaceNameInput} mb="sm" />
            <Text size="xs">The above field is case sensitive.</Text>
          </>
        ),
        labels: { confirm: "Delete", cancel: "Cancel" },
        confirmProps: {
          color: "red.6"
        },

        closeOnConfirm: false,
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          const workspaceName = workspaceNameInput.current?.value;
          workspaceName == activeWorkspace.name
            ? deleteWorkspace()
            : toast.error("Workspace name does not match");
        }
      });
    } else {
      return (
        <Center py="lg">
          <Loader size={"xs"} />
        </Center>
      );
    }
  };

  const fetchDefaultWorkspaceInfo = async () => {
    setLoading("loading");
    await new WorkspaceService()
      .getIsDefaultWorkspace(activeWorkspace.id)
      .then((res) => {
        if (res.data) {
          setDefaultWorkspaceInfo(res.data);
        }
      })
      .catch((err) => {});
    setLoading("loaded");
  };

  useEffect(() => {
    fetchDefaultWorkspaceInfo();
  }, []);

  return (
    <>
      <Title order={4} fw={600} mb="md" mt="md">
        Delete workspace
      </Title>
      <Text mb="md">
        Deleting this workspace will permanently erase all its data and prevent your team members
        from accessing it. This action cannot be undone.
      </Text>
      <div className="pb-4">
        <Button onClick={removeWorkspacePrompt} color="red.6">
          Delete this workspace
        </Button>
      </div>
    </>
  );
};
