import { Box } from "@mantine/core"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { UsersCRMService } from "@/lib/services/UsersCRMService"
import { LOADER_COLOR } from "@/lib/utils/Constants"
import { utcToRelativeTimezone } from "@/lib/utils/DateUtility"
import { useContext, useEffect, useState } from "react"
import { Oval } from "react-loader-spinner"
import { Route, Routes, useParams } from "react-router-dom"
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation"
import { IconCard } from "@/ui/components/Common/IconCard/IconCard"
import { UserCRMDetailActivities } from "./UserCRMDetailActivities"
import { UserCRMDetailAttributes } from "./UserCRMDetailAttributes"
import { UserCRMDetailOverview } from "./UserCRMDetailOverview"
import { UserCRMDetailSegments } from "./UserCRMDetailSegments"
import "./users_detail.scss"

const UserCRMDetail = (props: any) => {
  const { segment, peopleId } =
    useParams<{
      segment: string
      peopleId: string
    }>()
  const [isLoading, setIsLoading] = useState("idle")
  const { activeWorkspace } = useContext(AppLifecycleContext)
  const [individualPeopleDetail, setIndividualPeopleDetail] = useState({
    first_name: "",
    last_name: "",
    email: "",
    user_id: peopleId,
    created_at: "",
    company_name: "...",
    last_seen: "",
  })

  const getPeopleDetails = async () => {
    const peopleService = new UsersCRMService()
    setIsLoading("loading")
    await peopleService
      .individualUserDetailsById(activeWorkspace.id, peopleId)
      .then(res => {
        setIndividualPeopleDetail(res.data)
      })
      .catch(err => {})
    setIsLoading("loaded")
  }
  useEffect(() => {
    getPeopleDetails()
  }, [])

  useEffect(() => {
    document.title = `${individualPeopleDetail.first_name} ${individualPeopleDetail.last_name} | People | Usermaven`
  }, [individualPeopleDetail.first_name])

  return (
    <>
      <div className="um-mc" id="umMainContainer">
        <HeaderNavigation
          title={``}
          is_people_view={true}
          people_name={`${individualPeopleDetail?.first_name}
            ${individualPeopleDetail?.last_name}`}
        ></HeaderNavigation>

        <Box
          className="px-4 py-8 my-4 flex"
          sx={theme => ({
            border: "1px solid",
            borderColor:
              theme.colorScheme === "dark"
                ? theme.colors.darkBorderColor[0]
                : theme.colors.lightBorderColor[0],
            background: theme.colorScheme === "dark" ? theme.colors.dark[6] : "",
          })}
        >
          {isLoading === "loaded" ? (
            <>
              <div className="grid grid-cols-5 gap-4 flex-grow">
                <div className="flex items-center col-span-2">
                  <div className="w-24 h-24 flex items-center  font-semibold justify-center uppercase pl-4">
                    <div className="w-16 h-16 text-white rounded-full text-xl  shadow color-band-2 text-center items-center flex justify-center">
                      {individualPeopleDetail.first_name.substring(0, 1)}
                      {individualPeopleDetail.last_name.substring(0, 1)}
                    </div>
                  </div>
                  <div className="pl-4">
                    <div className="text-xl font-semibold line-clamp-1 capitalize">
                      {`${individualPeopleDetail?.first_name}
                    ${individualPeopleDetail?.last_name}`}
                    </div>
                    <p className="break-all">{individualPeopleDetail?.email}</p>
                  </div>
                </div>
                <IconCard
                  title="Created"
                  icon="clock"
                  value={utcToRelativeTimezone(individualPeopleDetail.created_at.replace(" ", "T"))}
                ></IconCard>
                <IconCard
                  title="Last seen"
                  icon="eye"
                  value={utcToRelativeTimezone(individualPeopleDetail.last_seen.replace(" ", "T"))}
                  bg="bg-yellow-100"
                  iconColor="text-yellow-500"
                ></IconCard>
                <IconCard
                  title="Company"
                  icon="users"
                  value={individualPeopleDetail.company_name}
                  bg="bg-blue-100"
                  iconColor="text-blue-500"
                ></IconCard>
                {/* <IconCard
                  title="Health Score"
                  icon="heart"
                  value={"Coming soon"}
                  bg="bg-green-100"
                  iconColor="text-green-500"
                  border={false}
                ></IconCard> */}
              </div>
            </>
          ) : (
            <div className="flex w-full flex-grow items-center justify-center">
              <Oval color={LOADER_COLOR} height={16} width={16} />
            </div>
          )}
        </Box>
        {/* <div className="um-individual-overview-nav">
          <ul>
            <li>
              <NavLink
                activeClassName="um-individual-overview-nav--active"
                to={`/env/${activeWorkspace.identifier}/users/${segment}/${peopleId}/overview`}
              >
                Overview
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="um-individual-overview-nav--active"
                to={`/env/${activeWorkspace.identifier}/users/${segment}/${peopleId}/activity`}
              >
                Activity
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="um-individual-overview-nav--active"
                to={`/env/${activeWorkspace.identifier}/users/${segment}/${peopleId}/attributes`}
              >
                Attributes
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="um-individual-overview-nav--active"
                to={`/env/${activeWorkspace.identifier}/users/${segment}/${peopleId}/conversations`}
              >
                Conversations
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="um-individual-overview-nav--active"
                to={`/env/${activeWorkspace.identifier}/users/${segment}/${peopleId}/segments`}
              >
                Segments
              </NavLink>
            </li>
          </ul>
        </div> */}
        <div className="um-individual-overview-content">
          <>
            {/* Routes commented */}
            <Routes>
              <Route path={`overview`} element={<UserCRMDetailOverview />}></Route>
              <Route path={`activity`} element={<UserCRMDetailActivities />}></Route>
              <Route path={`attributes`} element={<UserCRMDetailAttributes />}></Route>
              <Route path={`segments`} element={<UserCRMDetailSegments />}></Route>
            </Routes>
          </>
        </div>
      </div>
    </>
  )
}

export default UserCRMDetail
