import { useState, useEffect, useContext } from 'react';
import AppLifecycleContext from '@/lib/contexts/AppLifecycleContext';
import { UsersCRMService } from '@/lib/services/UsersCRMService';
import { WorkspacePinnedEventsService } from '@/lib/services/WorkspacePinnedEventsService';
import { CompaniesService } from '@/lib/services/CompaniesService';

export default function useInsightHook() {
  const [insightAttributes, setInsightAttributes] = useState([]);
  const [companyAttributes, setCompanyAttributes] = useState([]);
  const [insightEvents, setInsightEvents] = useState([]);
  const [insightPinnedEvents, setInsightPinnedEvents] = useState([]);

  const { activeWorkspace } = useContext(AppLifecycleContext);

  const getUsersCRMAttributesAndEvents = async () => {
    await new UsersCRMService()
      .attributes(activeWorkspace.id)
      .then((res) => {
        if (res.data) {
          setInsightAttributes(
            res.data.attributes.map((item: any) => {
              return {
                label: item.display_name.replaceAll(' ', '_'),
                value: item.name
              };
            })
          );
          setInsightEvents(
            res.data.events
              .map((item: any) => {
                return { label: item.display_name, value: item.name };
              })
              .concat({ label: 'user_identify', value: 'user_identify' })
          );
        }
      })
      .catch((err) => {});
  };

  const fetchCompanyAttributes = () => {
    new CompaniesService()
      .attributes(activeWorkspace.id)
      .then((res) => {
        if (res.data) {
          setCompanyAttributes(res.data);
        }
      })
      .catch((err) => []);
  };

  const fetchPinnedEvents = () => {
    new WorkspacePinnedEventsService()
      .all(activeWorkspace.id, 1, 1000)
      .then((res) => {
        if (res.data) {
          setInsightPinnedEvents(
            res.data.map((item: any) => {
              return { label: item.name, value: item.id };
            })
          );
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getUsersCRMAttributesAndEvents();
    fetchPinnedEvents();
    fetchCompanyAttributes();
  }, []);

  return {
    insightEvents,
    setInsightEvents,
    insightAttributes,
    setInsightAttributes,
    insightPinnedEvents,
    setInsightPinnedEvents,
    companyAttributes,
    setCompanyAttributes
  };
}
