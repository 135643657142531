/**
 * This class is responsible for handling the visitor's api calls.
 * @class VisitorService
 */

import axios from './JWTService';

export class VisitorService {
  /**
   * This function is responsible for fetching the visitor's data.
   * @param {string} ws_id - The workspace id.
   * @param {object} payload - The payload.
   * @returns {Promise<any>} - The promise.
   */
  fetch = (ws_id: any, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/visitors/${ws_id}`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * This function is responsible for fetching the visitor's sessions.
   * @param {string} ws_id - The workspace id.
   * @param {string} s_id - The user id.
   * @param {object} payload - The payload.
   * @returns {Promise<any>} - The promise.
   */
  sessions = (ws_id: any, s_id: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/visitors/${ws_id}/${s_id}/sessions`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * This function is responsible for fetching the session's events.
   */
  events = (ws_id: any, s_id: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/visitors/${ws_id}/${s_id}/events`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * This function is responsible for fetching the visitor's details.
   */
  details = (ws_id: any, s_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/visitors/${ws_id}/${s_id}/details`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * This function is responsible for fetching the visitor's attributes.
   */
  attributes = (ws_id: any, s_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/visitors/${ws_id}/${s_id}/attributes`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }
}
