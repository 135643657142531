import axios from './JWTService';

export class ApiKeyService {
  private readonly _endpoint;
  constructor() {
    this._endpoint = '/v1/api_keys/';
  }

  /**
   * Get all api keys.
   */
  getApiKeys = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this._endpoint}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Create new api key.
   * @param data
   */
  createApiKey = (data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}`, data)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Delete api key.
   */
  deleteApiKey = (id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${this._endpoint}${id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
