import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { ActiveUsersTrendsService } from "@/lib/services/ActiveUsersTrendsService";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import { blue, purple } from "@/style/Colors";
import { FC, useContext, useEffect, useState } from "react";

// @ts-ignore
import { useSegmentStore } from "@/stores/useSegmentStore";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";
import { Flex, RingProgress, SimpleGrid, Text, useMantineTheme } from "@mantine/core";
import { IReportType } from "../../../../../../types/types.d";

export const UserStickinessRatio: FC<{ insightId: string; reportType?: IReportType }> = ({
  insightId,
  reportType = "user"
}) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [filters] = useSegmentStore((state) => [state.filters]);
  // dau/mau states

  const [isDAUMAULoading, setIsDAUMAULoading] = useState("idle");
  const [DAUMAUData, setDAUMAUData] = useState<any>();

  // dau/wau states
  const [isDAUWAULoading, setIsDAUWAULoading] = useState("idle");
  const [DAUWAUData, setDAUWAUData] = useState<any>();

  // wau/mau states
  const [isWAUMAULoading, setIsWAUMAULoading] = useState("idle");
  const [WAUMAUData, setWAUMAUData] = useState<any>();

  const activeUsersService = new ActiveUsersTrendsService();

  const fetchDAUMAUAvg = async () => {
    setIsDAUMAULoading("loading");
    await activeUsersService
      .userStickinessAvg(
        activeWorkspace.id,
        insightId,
        "dau_mau_avg",
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        setDAUMAUData(res.data.total);
      })
      .catch((err) => {
        setDAUMAUData(0);
      });
    setIsDAUMAULoading("loaded");
  };

  const fetchDAUWAUAvg = async () => {
    setIsDAUWAULoading("loading");
    await activeUsersService
      .userStickinessAvg(
        activeWorkspace.id,
        insightId,
        "dau_wau_avg",
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        setDAUWAUData(res.data.total);
      })
      .catch((err) => {
        setDAUWAUData(0);
      });
    setIsDAUWAULoading("loaded");
  };

  const fetchWAUMAUAvg = async () => {
    setIsWAUMAULoading("loading");
    await activeUsersService
      .userStickinessAvg(
        activeWorkspace.id,
        insightId,
        "wau_mau_avg",
        reportType,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        setWAUMAUData(res.data.total);
      })
      .catch((err) => {
        setWAUMAUData(0);
      });
    setIsWAUMAULoading("loaded");
  };

  useEffect(() => {
    fetchDAUMAUAvg();
    fetchDAUWAUAvg();
    fetchWAUMAUAvg();
  }, [insightId, filters]);

  return (
    <>
      <InsightSection
        title={`${reportType == "user" ? "User" : "Company"} Stickiness Metrics`}
        tooltip={`Stickiness is generally calculated as the ratio of Daily Active ${
          reportType == "user" ? "Users" : "Companies"
        } to Monthly Active ${reportType == "user" ? "Users" : "Companies"}. A ${
          reportType == "user" ? "DAU/MAU" : "DAC/MAC"
        } ratio of 50% would mean that the average ${
          reportType == "user" ? "user" : "company"
        } of your app is using it 15 out of 30 days that month`}>
        <SimpleGrid
          cols={{
            md: 3,
            sm: 2,
            xs: 1
          }}
          mb={"xl"}
          spacing={"xs"}
          w={"100%"}>
          <div>
            <Flex justify="center" align={"center"} h={250}>
              {isDAUMAULoading === "loaded" ? (
                <>
                  <RingProgress
                    size={235}
                    thickness={30}
                    sections={[
                      {
                        value: DAUMAUData,
                        color: purple,
                        tooltip: `${
                          reportType == "user" ? "DAU/MAU" : "DAC/MAC"
                        } Ratio is ${DAUMAUData}%`
                      }
                    ]}
                    label={
                      <Text c={"brand"} fw={700} ta="center" size="xl">
                        {DAUMAUData || 0}%
                      </Text>
                    }
                  />
                </>
              ) : (
                <Flex justify={"center"} h={250}>
                  <BarChartSkeleton></BarChartSkeleton>
                </Flex>
              )}
            </Flex>
            <Flex direction={"column"} align={"center"} justify={"center"}>
              <Text fw={600} style={{ textAlign: "center", paddingBottom: "1.5rem" }}>
                {reportType == "user" ? "DAU/MAU" : "DAC/MAC"}
              </Text>
              <Text c="dimmed" size="xs" ta="center" px="lg">
                {reportType == "user" ? "DAU/MAU" : "DAC/MAC"} ratio measures how active monthly{" "}
                {reportType == "user" ? "users" : "companies"} are on a daily basis.
              </Text>
            </Flex>
          </div>

          <div>
            <Flex justify="center" align={"center"} h={250}>
              {isDAUWAULoading === "loaded" ? (
                <>
                  <RingProgress
                    size={235}
                    thickness={30}
                    sections={[
                      {
                        value: DAUWAUData,
                        color: blue,
                        tooltip: `${
                          reportType == "user" ? "DAU/WAU" : "DAC/WAC"
                        } Ratio is ${DAUWAUData}%`
                      }
                    ]}
                    label={
                      <Text color={blue} fw={700} ta="center" size="xl">
                        {DAUWAUData || 0}%
                      </Text>
                    }
                  />
                </>
              ) : (
                <div className="w-[33.3%] flex justify-center">
                  <BarChartSkeleton></BarChartSkeleton>
                </div>
              )}
            </Flex>
            <Flex direction={"column"} justify={"center"}>
              <Text fw={600} style={{ textAlign: "center", paddingBottom: "1.5rem" }}>
                {reportType == "user" ? "DAU/WAU" : "DAC/WAC"}
              </Text>
              <Text c="dimmed" size="xs" ta="center" px="lg">
                {reportType == "user" ? "DAU/WAU" : "DAC/WAC"} ratio measures how active weekly{" "}
                {reportType == "user" ? "users" : "companies"} are on a daily basis.
              </Text>
            </Flex>
          </div>

          <div>
            <Flex justify="center" align={"center"} h={250}>
              {isWAUMAULoading === "loaded" ? (
                <>
                  <RingProgress
                    size={235}
                    thickness={30}
                    sections={[
                      {
                        value: WAUMAUData,
                        color: "#fec107",
                        tooltip: `${
                          reportType == "user" ? "WAU/MAU" : "WAC/MAC"
                        } Ratio is ${WAUMAUData}%`
                      }
                    ]}
                    label={
                      <Text color={"#fec107"} fw={700} ta="center" size="xl">
                        {WAUMAUData || 0}%
                      </Text>
                    }
                  />
                </>
              ) : (
                <div className="w-[33.3%] flex justify-center">
                  <BarChartSkeleton></BarChartSkeleton>
                </div>
              )}
            </Flex>
            <Flex direction={"column"} justify={"center"}>
              <Text fw={600} style={{ textAlign: "center", paddingBottom: "1.5rem" }}>
                {reportType == "user" ? "WAU/MAU" : "WAC/MAC"}
              </Text>
              <Text c="dimmed" size="xs" ta="center" px="lg">
                {reportType == "user" ? "WAU/MAU" : "WAC/MAC"} ratio measures how active weekly{" "}
                {reportType == "user" ? "users" : "companies"} are on a monthly basis.
              </Text>
            </Flex>
          </div>
        </SimpleGrid>
      </InsightSection>
    </>
  );
};
