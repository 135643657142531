import React from 'react'
import Flag from 'react-world-flags'

type TLazyFlag = {
  code: string,
  width?: number,
  height?: number
}
/**
 * It's not advisable to directly load SVGs from node_modules dynamically, due to the limitations that modern builders like Vite and Webpack have on fully dynamic imports. This is for optimization purposes, so that the bundlers can create a dependency map during build time.
 * OLD DYNAMIC IS REPLACED WITH REACT-WORLD-FLAGS
 * @param code
 * @param width
 * @param height
 * @constructor
 */
const LazyFlag: React.FunctionComponent<TLazyFlag> = ({ code, width = 64, height = 64 }) => {

  return <Flag code={code} width={width} height={height} />
}

export default LazyFlag
