// @ts-ignore
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
// import { GeneralWorkspaceTrackingPixels } from "./GeneralWorkspaceTrackingPixels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Alert,
  Anchor,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Input,
  Text,
  Title,
  Tooltip
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { LoadingStateProps } from "types/types.d";
import { SettingsHeaderContent } from "@/ui/components/App/Settings/SettingsHeaderContent/SettingsHeaderContent";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";

interface IFormInputs {
  custom_domain: string;
}
/**
 * This component is used to allow users to add a custom domain to their workspace. The custom domain feature will be used to
 * will by-pass ad-blockers.
 */
export const AddCustomDomain = () => {
  // Active workspace.

  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);

  // Loading state

  const [loading, setLoading] = useState<LoadingStateProps>("idle");

  // Form state is edit for the custom domain
  const [isEdit, setIsEdit] = useState(false);

  // React hook forms default values and hooks.
  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IFormInputs>();

  // On submit of the form, this function will be called.
  const onSubmit = async (data: IFormInputs) => {
    setLoading("loading");
    await new WorkspaceService()
      .customDomain(activeWorkspace.id, data.custom_domain)
      .then((res) => {
        if (res.data) {
          setActiveWorkspace(res.data);
          setIsEdit(false);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          toast.error(err.response.data.detail);
        }
      });
    setLoading("loaded");
  };

  /**
   * This function is to remove the custom domain.
   * The prompt will be shown if the user has domain connected and verified by our system.
   */
  const removeCustomDomainPrompt = () => {
    // if (activeWorkspace.is_custom_domain_verified) {
    openConfirmModal({
      title: <Title order={5}>Delete pixel white-label domain</Title>,
      children: (
        <>
          <Alert color="red" className="flex">
            <Center mb={16} c="red">
              <FontAwesomeIcon icon={regular("warning")} size="2xl" />
            </Center>
            You are about to remove your custom domain.{" "}
            <Text fw={600} span>
              {" "}
              Please be aware that this action will immediately stop all tracking functionalities
              associated with this domain.{" "}
            </Text>{" "}
            <br />
            <br />
            This means you will no longer be able to collect or view data on user interactions that
            occur through this domain.
            <br />
            <br />
            Before you proceed, we{" "}
            <Text span fw={600}>
              recommend updating your tracking script
            </Text>{" "}
            on your website/app to remove references to the white-labeled custom domain.
          </Alert>
        </>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => deleteCustomDomain()
    });
    // } else {
    //   // if the domain not verified, remove the domain without prompt.
    //   deleteCustomDomain();
    // }
  };

  /**
   * Delete custom domain function when the prompt is confirmed.
   */
  const deleteCustomDomain = async () => {
    await new WorkspaceService()
      .removeCustomDomain(activeWorkspace.id)
      .then((res) => {
        if (res.data) {
          setActiveWorkspace(res.data);
          toast.success("Your custom domain removed successfully.");
        }
      })
      .then((err: any) => {
        if (err?.response?.status === 422) {
          toast.error(err.response.data.detail);
        }
      });
  };

  const customDomainConnectivity = async () => {
    await new WorkspaceService()
      .checkCustomDomainConnectivity(activeWorkspace.id)
      .then((res) => {
        if (res.data) {
          setActiveWorkspace(res.data);
          toast.success(
            "Your custom domain has been verified successfully. It may take a few minutes for the SSL certificate to be created for your custom domain."
          );
        }
      })
      .catch((err: any) => {
        if (err?.response?.status === 422) {
          toast.error(err.response.data.detail);
        }
      });
  };
  return (
    <>
      <Title order={4} mb="md">
        Custom Domains
      </Title>
      <Text mb="md">
        Use custom domain to avoid losing traffic from visitors who use ad-blockers
      </Text>
      <Divider mb="sm" />
      <Text size={"sm"} mb="sm">
        First time setting up a custom domain?{" "}
        <Anchor
          fw={500}
          td="underline"
          href="https://usermaven.com/docs/getting-started/pixel-whitelabeling"
          target="_blank">
          Check out this guide.
        </Anchor>
      </Text>

      {!activeWorkspace?.custom_domain || isEdit ? (
        <>
          <Alert
            w={"80%"}
            icon={<FontAwesomeIcon icon={regular("warning")} className="text-2xl" />}
            color="yellow">
            <Text span fw={700}>
              Note:
            </Text>{" "}
            Do not use a subdomain like "stats" or "analytics" as these are automatically blocked by
            ad-blockers.
          </Alert>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Group py="md">
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <Input
                    w={400}
                    value={value}
                    onChange={onChange}
                    placeholder="e.g um.yourdomain.com"
                    error={(invalid || error) === true}
                    defaultValue={activeWorkspace?.custom_domain}
                  />
                )}
                name="custom_domain"
                control={control}
                defaultValue={""}
                rules={{ required: true, maxLength: 100 }}
              />
              {/* {JSON.stringify(errors)} */}
              {errors.custom_domain?.type === "required" && (
                <ValidationLabel
                  validationType="error"
                  text="Custom domain is required"
                  size="small"
                  customClasses="pt-2"></ValidationLabel>
              )}
              {errors.custom_domain?.type === "maxLength" && (
                <ValidationLabel
                  validationType="error"
                  text="Custom domain should be less than 100 characters."
                  size="small"
                  customClasses="pt-2"></ValidationLabel>
              )}

              <div>
                <Button type="submit" loading={loading === "loading"}>
                  {`${isEdit ? "Update Domain" : "Add Domain"}`}
                </Button>
              </div>
            </Group>
          </form>
        </>
      ) : (
        <>
          <Alert w={"75%"} icon={<FontAwesomeIcon icon={regular("info-circle")} />} color="yellow">
            <Text span fw={700}>
              Important:
            </Text>{" "}
            Make sure you point your domains DNS settings to{" "}
            <Text span fw={700}>
              {process.env.REACT_APP_CNAME_DOMAIN}
            </Text>
          </Alert>

          <Group p="sm" py="md" gap={"xl"}>
            <Title w={300} order={6}>
              {activeWorkspace.custom_domain}
            </Title>
            <Flex>
              {activeWorkspace.is_custom_domain_verified ? (
                <>
                  <Flex align={"center"}>
                    <Box w={16} c="green.7" mr={8}>
                      <FontAwesomeIcon icon={regular("check")} className="text-green-600" />
                    </Box>
                    <Text fw={600} c="green.7">
                      Connected
                    </Text>
                  </Flex>
                </>
              ) : (
                <>
                  <Flex align={"center"}>
                    <span className="flex h-2.5 w-2.5 relative mr-2 ">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-300 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-orange-500"></span>
                    </span>
                    <Text c="orange.7">
                      Pending verification.{" "}
                      <Text
                        className="cursor-pointer"
                        span
                        td={"underline"}
                        onClick={() => customDomainConnectivity()}>
                        Click here to verify
                      </Text>
                    </Text>
                  </Flex>
                </>
              )}
            </Flex>
            <div className="flex items-center">
              <Tooltip label="Edit">
                <ActionIcon
                  mr={8}
                  color="gray"
                  variant="outline"
                  onClick={() => {
                    setIsEdit(true);
                    setValue("custom_domain", activeWorkspace?.custom_domain);
                  }}>
                  <FontAwesomeIcon icon={regular("pencil")} className=" " />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Remove">
                <ActionIcon
                  data-tip="Remove"
                  variant="outline"
                  color={"red"}
                  onClick={() => {
                    removeCustomDomainPrompt();
                  }}>
                  <FontAwesomeIcon icon={regular("trash")} className=" text-red-500 transform " />
                </ActionIcon>
              </Tooltip>
            </div>
          </Group>
        </>
      )}
    </>
  );
};
