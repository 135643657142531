import styled from "styled-components";

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  margin-top: 5rem;
`;

export const LoadingContent = styled.div`
  display: flex;
`;
