import BarGroup from "../molecules/BarGroup";
import { IBar, IBarData } from "@/ui/pages/Analyze/components/FunnelBarChart/components/atoms/Bar";
import { Box } from "@mantine/core";

const BarChart = ({ bars, type = "Visitors" }: { bars: IBarData[]; type?: string }) => {
  const max = Math.max(...bars.map((bar) => bar.visitors));

  return (
    <Box
      className="bar-chart"
      style={{
        marginTop: "30px",
        minHeight: 300,
        height: 400,
        width: "100%"
      }}>
      <BarGroup bars={bars} max={max} type={type} />
    </Box>
  );
};

export default BarChart;
