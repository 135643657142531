import { light, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Box,
  Checkbox,
  Flex,
  List,
  Paper,
  Popover,
  Text,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { Domain } from "../../App/WebAnalytics/Filters/FiltersNav";
import classes from "./MultiSelectMenu.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
interface MultiSelectMenuProps {
  data: Domain[];
  scrollPosition: number;
  defaultValue: string;
  selectedData: Domain[];
  setSelectedData: (value: Domain[]) => void;
  onChange: (selectedValues: Domain[]) => void;
}

const MultiSelectMenu = ({
  data,
  scrollPosition,
  defaultValue,
  selectedData,
  setSelectedData,
  onChange
}: MultiSelectMenuProps) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const handleCheckboxChange = (item: Domain) => {
    let newData = [];

    if (item.value === "all_domains") {
      // If 'all_domains' is selected, set newData to only contain 'all_domains'
      newData = [{ label: "All Domains", value: "all_domains" }];
    } else {
      // Remove 'all_domains' if it is currently selected
      const filteredData = selectedData.filter((d) => d.value !== "all_domains");

      // Check if the clicked item is already selected
      if (filteredData.some((d) => d.value === item.value)) {
        // If already selected, remove it from the selection
        newData = filteredData.filter((d) => d.value !== item.value);
      } else {
        // If not selected, add the clicked item to the selection
        newData = [...filteredData, item];
      }
    }

    setSelectedData(newData);
    onChange(newData);
  };

  const handleClearSelection = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    let defaultValueLabel = defaultValue === "all_domains" ? "All Domains" : defaultValue;
    const defaultValues = [{ label: defaultValueLabel, value: defaultValue }];
    setSelectedData([...defaultValues]);
    onChange([...defaultValues]);
  };

  return (
    <Popover width={200} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Flex
          align="center"
          miw={"18rem"}
          maw={"30rem"}
          py={"sm"}
          className={classes.box}
          // className={`${
          //   scrollPosition > 0.03
          //     ? theme.colorScheme === "dark"
          //       ? "border border-solid border-gray-200 !text-gray-800 "
          //       : "border border-solid border-gray-200 text-gray-900"
          //     : " text-gray-800"
          // }`}
          tabIndex={0}>
          <Box px={"xs"} c="gray.7">
            <FontAwesomeIcon icon={regular("browser")} />
          </Box>

          {selectedData.slice(0, 2).map((item) => (
            <Flex key={item.value}>
              <Flex align={"center"} className={classes.chip}>
                <Text fz={"13px"} c={colorScheme === "dark" ? "#FFFFFF" : theme.colors.dark[7]}>
                  {item.label}
                </Text>
                {item.value !== "all_domains" && (
                  <ActionIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      handleCheckboxChange(item);
                    }}
                    pl={"5px"}
                    mt={"1px"}
                    variant="transparent"
                    color={colorScheme === "dark" ? "dark.0" : "gray.7"}
                    size="xs">
                    <FontAwesomeIcon icon={regular("xmark")} style={{ fontSize: "12px" }} />
                  </ActionIcon>
                )}
              </Flex>
            </Flex>
          ))}
          {selectedData.length > 2 && (
            <>
              <Text>...</Text>
              <Text px={"xs"} c="gray.8">
                +{selectedData.length - 2}
              </Text>
            </>
          )}
          {selectedData.length === 1 && (
            <Flex mr={12} direction={"column"} ml={"auto"}>
              <FontAwesomeIcon
                icon={light("angle-up")}
                style={{
                  fontSize: "8px",
                  color: theme.colors.gray[6],
                  padding: "0 !important",
                  margin: "0 !important"
                }}
              />
              <FontAwesomeIcon
                icon={light("angle-down")}
                style={{
                  fontSize: "8px",
                  color: theme.colors.gray[6],
                  padding: "0 !important",
                  margin: "0 !important"
                }}
              />
            </Flex>
          )}

          {selectedData.length > 1 && (
            <FontAwesomeIcon
              icon={light("xmark")}
              style={{ fontSize: "13px", padding: "0 10px", color: theme.colors.gray[5] }}
              onClick={handleClearSelection}
            />
          )}
        </Flex>
      </Popover.Target>
      <Popover.Dropdown miw={"18rem"} maw={"30rem"}>
        <Box>
          <List spacing="xs" size="sm" center>
            {data.map((item) => (
              <List.Item style={{ padding: " 0px 4px" }} key={item.value}>
                <Box>
                  <Checkbox
                    style={{
                      cursor: "pointer"
                    }}
                    size="xs"
                    checked={selectedData.map((data) => data.value).includes(item.value)}
                    label={item.label}
                    onChange={() => handleCheckboxChange(item)}
                  />
                </Box>
              </List.Item>
            ))}
          </List>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default MultiSelectMenu;
