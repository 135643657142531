import { Box, Flex, Text, Tooltip, useMantineTheme } from "@mantine/core";
import Label from "./Label";
import { IFunnelPerformance } from "@/ui/pages/InsightsDashboard/Pages/FeatureAdoption/FeatureDetail";
import classes from "./PerformanceFunnelBar.module.css";
export interface IBar {
  width?: number; // width of the bar
  data: IFunnelPerformance; // data of the bar
  max?: number; // max value of the bar (height)
  isLast?: boolean; // is the bar the last one
  nextData?: IFunnelPerformance | null; // data of the next bar
  type?: string; // type of the bar
  noMeta?: boolean; // no meta info
  noLabel?: boolean; // no label
}

const PerformanceFunnelBar = ({
  width = 70,
  data,
  nextData = null,
  max = 100,
  isLast = true,
  noMeta = false
}: IBar) => {

  const currentHeight = (data.count / max) * 100;
  const prevHeight = (max / max) * 100;
  const label = data.count.toLocaleString("en-US", {
    notation: "compact",
    compactDisplay: "short"
  });

  return (
    <Box pos={"relative"} h={"100%"} className={classes.container}>
      {/* Steps Labels */}
      <Box
        style={{
          // left: data.name.length < 10 ? `28px` : `0px`,
          width: `${width}px`
        }}
        className={classes.stepLabels}>
        <Tooltip label={data.name} disabled={data.name.length <= 18}>
          <Flex align={"center"} justify={"center"} w={"100%"}>
            {data.name.length > 18 ? data.name.substring(0, 18) + "..." : data.name}
          </Flex>
        </Tooltip>

        <Flex align={"center"} justify={"center"} w={"100%"}>
          <Text ta={"center"} c="dimmed">
            {data?.subtitle}
          </Text>
        </Flex>
      </Box>

      {/* Bars */}
      <Box
        className={classes.bar}
        style={{
          width: `${width}px`,
          height: `${prevHeight > currentHeight ? prevHeight : currentHeight}%`
        }}>
        <Box
          className={classes.currentBar}
          style={{
            height: `${currentHeight}%`
          }}
          bg={"brand.4"}
          pos="relative">
          <Label label={label} position={"top"} />
        </Box>

        {/* META */}
        {!noMeta && (
          <>
            {!isLast && nextData && data.conversion?.toFixed(2) && (
              <Box
                className={classes.meta}
                style={{
                  left: `calc(${width}px + 40px)`
                }}>
                <div>
                  <Text fw={600} fz={15} className={classes.metaText}>
                    <div>
                      <span>→</span> <i>{data.conversion?.toFixed(2)}%</i>
                    </div>
                  </Text>
                  <Text
                    fz={11}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}>
                    Converted
                  </Text>
                </div>
                {/* Drop Off is committed for now */}
                {/* <div>
                    <Text
                      weight={600}
                      size={15}
                      sx={{
                        div: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "2px",
                          span: {
                            color: "#c74554"
                          },
                          whiteSpace: "nowrap",

                          i: {
                            fontSize: "13px",
                            fontWeight: 600,
                            fontStyle: "normal",
                            color: "#c74554"
                          }
                        }
                      }}>
                      <div>
                        <span>↓</span> <i>{(data.dropoff)?.toFixed(2)}%</i>
                      </div>
                    </Text>
                    <Text
                      size={11}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}>
                      Drop-off
                    </Text>
                  </div> */}
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default PerformanceFunnelBar;
