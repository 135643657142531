import { endOfDay, format, startOfDay, subDays } from "date-fns";
import useInsightsListHook from "@/hooks/useInsightsListHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { BarChartSkeleton, StatsSkeleton } from "@/lib/utils/ChartsSkeletons";
import { DATE_FORMAT, PLAN_TYPES, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  HoverCard,
  Paper,
  SegmentedControl,
  Select,
  SimpleGrid,
  Skeleton,
  Table,
  Text,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useSegmentDropdownStore } from "@/stores/useSegmentDropdownStore";
import { useSegmentFilterStore } from "@/stores/useSegmentFilterStore";
import { useSegmentListStore } from "@/stores/useSegmentListStore";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { ISegmentsQueryBuilderForm } from "@/ui/components/App/Segments/SegmentFormContext";
import {
  TrendsGraphBody,
  TrendsGraphContainer,
  TrendsGraphHeader,
  TrendsGraphHeaderH3,
  TrendsGraphHeaderLeft,
  TrendsGraphHeaderParagraph,
  TrendsGraphHeaderRight
} from "@/ui/components/App/TrendsGraph/TrendsGraph.style";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import { StringParam, useQueryParam } from "use-query-params";
import { getReportingPeriodRange } from "../../../../../lib/utils/ReportingPeriodUtility";
import { useWebFiltersStore } from "../../../../../stores/useWebFiltersStore";
import { IReportType } from "../../../../../types/types.d";
import { DateDropdownMenu } from "../../../../components/App/Dropdowns/DateDropdownMenu";
import { ReportTypeToggle } from "../../../../components/App/ReportTypeToggle/ReportTypeToggle";
import { EditInsight } from "../../Components/EditInsight/EditInsight";
import { InsightsDashboardSetupReport } from "../../Components/InsightsDashboardSetupReport/InsightsDashboardSetupReport";
import {
  InsightsDashboardHeaderLeft,
  InsightsDashboardHeaderRight
} from "../../Components/InsightsDashboardStyle/InsightsDashboardStyle.style";
import OnboardingCompletionSetupModal from "../../../Insights/Setup/OnboardingCompletionSetupModal";
import { IOutletContext } from "../../InsightsDashboard";
import useFunnelInsightsStore, { UserDetail } from "@/stores/useFunnelInsightsStore";
import locale from "@/ui/pages/InsightsShareableDashboard/locale";
import { IntlProvider } from "react-intl";
import { FunnelChartWrapper } from "@/ui/components/App/WebAnalytics/ConversionFunnel/ConversionFunnelCard";
import InsightsCard from "@/ui/components/App/WebAnalytics/Cards/InsightsCard";
import ConversionTimeDistribution from "@/ui/pages/Analyze/components/tabs/ConversionTimeDistribution";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { stringToInitials } from "@/lib/utils/StringUtility";
import { getRandomColor } from "@/style/Colors";
import { getRelativeDateToNow } from "@/lib/utils/DateUtility";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { ExportButton } from "@/ui/components/Common/ExportButton/ExportButton";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { downloadCSVFromResponse } from "@/lib/utils/CSVExporterUtility";
import FunnelDetails, {
  IFunnelMeta
} from "@/ui/pages/Analyze/components/FunnelDetailedView/FunnelDetails";
import { IDetailViewMenu } from "@/ui/pages/Analyze/components/FunnelDetailedView/DetailViewMenuGroup";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import StatsCard from "@/ui/components/Common/StatsCard/StatsCard";
import { roundFunnelRate } from "@/lib/utils/FunnelUtility";
import { getSeedColor } from "@/style/ColorSeeding";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";
import classes from "./InsightsDashboardOnboarding.module.css";
import { FunnelUsersListCard } from "./FunnelUsersListCard";
import { FunnelUsersDetailedView } from "./FunnelUsersDetailedView";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
const InsightsDashboardOnboarding = () => {
  const { colorScheme } = useMantineColorScheme();
  /**
   * Segment filter store
   */
  const [filterVisible, setFilterVisible] = useSegmentFilterStore((state) => [
    state.filterVisible,
    state.setFilterVisible
  ]);
  /**
   * Segments Store
   */
  const [segments] = useSegmentListStore((state) => [state.segments]);

  /**
   * Segments dropdown store
   */
  const [setSelectedSegment] = useSegmentDropdownStore((state) => [state.setId]);

  /**
   * Segment Query builder store
   */
  const [filters, setFilters, resetExceptFilters, reset, setSegmentModalOpen] = useSegmentStore(
    (state) => [
      state.filters,
      state.setFilters,
      state.resetExceptFilters,
      state.reset,
      state.setModalOpen
    ]
  );

  const theme = useGlobalMantineTheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const isStarter = useWorkspaceUtilityHook().isStarterPlan;
  const [insightId] = useQueryParam("insightId", StringParam);
  const navigate = useNavigate();
  const [setDateLabel] = useWebFiltersStore((state) => [state.setDateLabel]);
  const getPeriod = getReportingPeriodRange(activeWorkspace.reporting_period);
  const fromDate = getPeriod?.startDate || format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT);
  const toDate = getPeriod?.endDate || format(endOfDay(new Date()), DATE_FORMAT);
  const [reportType, setReportType] = useState<IReportType>(
    activeWorkspace.reporting_insights_preference
  );

  // setup modal states
  const [showInsightSetupModal, setShowInsightSetupModal] = useState(false);
  const { showManageInsightsModal }: IOutletContext = useOutletContext();

  // Funnel users list view states
  const [showUsersList, setShowUsersList] = useState(false);
  const [stepNo, setStepNo] = useState(0);
  // store startDate and endDate in state and then use these dates for the query when audience filter applied.

  const [dropdownFilterDate, setDropdownFilterDate] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: fromDate,
    endDate: toDate
  });

  const { insightsList, isInsightsLoading, getDefaultInsight, fetchInsightsList } =
    useInsightsListHook("onboarding");
  const [selectedUserCard, setSelectedUserCard] = useState<{
    name: string;
    index: number;
    type: "completed" | "dropped";
  }>({
    name: "",
    index: 0,
    type: "completed"
  });

  const [
    funnelInsightsSteps,
    funnelInsightsMeta,
    funnelInsightsUsers,
    funnelInsightsFetching,
    fetchFunnelInsights,
    funnelInsightsReset,
    fetchingInsights,
    funnelInsights,
    fetchFunnelInsightsStats,
    fetchingUsers,
    fetchUsers
  ] = useFunnelInsightsStore((state) => [
    state.steps,
    state.meta,
    state.users,
    state.fetching,
    state.fetchFunnel,
    state.reset,
    state.fetchingInsights,
    state.insights,
    state.fetchFunnelInsights,
    state.fetchingUsers,
    state.fetchUsers
  ]);

  const [usersListToggleState, setUsersListToggleState] = useState<"completed" | "dropped">(
    "completed"
  );
  const [detailModal, setDetailModal] = useState(false);

  const [initialActiveTabType, setInitialActiveTabType] = useState<IDetailViewMenu>({
    activeView: "overview",
    selectedOption: "none"
  });

  const onChangeDate = (startDate: string, endDate: string) => {
    console.debug("onboardingDateFilter");
    fetchFunnelInsights(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
      start_date: startDate,
      end_date: endDate,
      funnel_type: reportType
    });

    fetchFunnelInsightsStats(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
      start_date: startDate,
      end_date: endDate,
      funnel_type: reportType
    });

    fetchUsers(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
      start_date: startDate,
      end_date: endDate,
      funnel_type: reportType,
      full_data: false
    });

    setDropdownFilterDate({ startDate, endDate });
  };

  const onClearFiltersChange = () => {
    fetchFunnelInsights(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
      start_date: dropdownFilterDate.startDate,
      end_date: dropdownFilterDate.endDate,
      funnel_type: reportType
    });

    fetchFunnelInsightsStats(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
      start_date: dropdownFilterDate.startDate,
      end_date: dropdownFilterDate.endDate,
      funnel_type: reportType
    });

    fetchUsers(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
      start_date: dropdownFilterDate.startDate,
      end_date: dropdownFilterDate.endDate,
      funnel_type: reportType,
      full_data: false
    });
  };

  const onApplyChange = (filters: ISegmentsQueryBuilderForm) => {
    console.debug("onboardingAudienceFilter");
    fetchFunnelInsights(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
      start_date: dropdownFilterDate.startDate,
      end_date: dropdownFilterDate.endDate,
      funnel_type: reportType
    });
    fetchFunnelInsightsStats(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
      start_date: dropdownFilterDate.startDate,
      end_date: dropdownFilterDate.endDate,
      funnel_type: reportType
    });

    fetchUsers(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
      start_date: dropdownFilterDate.startDate,
      end_date: dropdownFilterDate.endDate,
      funnel_type: reportType,
      full_data: false
    });
  };

  // const handleOnClickStep = (step: number) => {
  //   setShowUsersList(!showUsersList);
  //   setStepNo(step);
  // };

  useEffect(() => {
    document.title = "Insights | Onboarding | Usermaven";
    if (isInsightsLoading === "loaded") {
      console.log("Component loaded... now get the data we need.");

      // set the label for the date picker here.
      setDateLabel(getPeriod?.label || "Last 30 Days");

      fetchFunnelInsights(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
        start_date: dropdownFilterDate.startDate,
        end_date: dropdownFilterDate.endDate,
        funnel_type: reportType
      });

      fetchFunnelInsightsStats(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
        start_date: dropdownFilterDate.startDate,
        end_date: dropdownFilterDate.endDate,
        funnel_type: reportType
      });

      fetchUsers(activeWorkspace.id, insightId || getDefaultInsight()?.value, {
        start_date: dropdownFilterDate.startDate,
        end_date: dropdownFilterDate.endDate,
        funnel_type: reportType,
        full_data: false
      });
    }

    return () => {
      reset();
    };
  }, [isInsightsLoading, reportType]);

  // adding this useffect to reload the list when the manage insights modal is closed
  // or when a new insight is created in the setup modal and insight id changes in the url
  useEffect(() => {
    if (!showInsightSetupModal && !showManageInsightsModal) {
      fetchInsightsList();
    }
  }, [showInsightSetupModal, showManageInsightsModal, insightId]);

  const noInsightData = useMemo(() => {
    if (!funnelInsights) {
      return true;
    }

    let allZero = true;
    for (let key in funnelInsights) {
      if (
        funnelInsights[key].best_performing.value !== 0 ||
        funnelInsights[key].worst_performing.value !== 0
      ) {
        allZero = false;
        break;
      }
    }

    return !allZero;
  }, [funnelInsights]);

  const dateRange = useMemo(() => {
    return {
      start_date: dropdownFilterDate.startDate,
      end_date: dropdownFilterDate.endDate
    };
  }, [dropdownFilterDate]);

  if (isInsightsLoading !== "loaded") {
    return <LoadingComponent />;
  }

  const handleDetailClick = (
    id: string,
    funnel: any,
    meta: IFunnelMeta,
    activeType?: IDetailViewMenu
  ) => {
    if (isStarter) {
      return;
    }
    setDetailModal(true);
  };

  const getUserDetailsMessage = () => {
    const type = reportType == "user" ? "Users" : "Companies";
    if (usersListToggleState === "completed") {
      return `${type} who have completed their onboarding on each step.`;
    }
    return `${type} who have dropped off during onboarding on each step.`;
  };

  return (
    <>
      <IntlProvider locale={"en"} messages={locale["en"]}>
        <OnboardingCompletionSetupModal
          isModalOpen={showInsightSetupModal}
          setIsModalOpen={setShowInsightSetupModal}
          insightId={insightId || getDefaultInsight()?.value}
        />
        {insightsList.length === 0 ? (
          <>
            <InsightsDashboardSetupReport
              title={"Create your first onboarding insight"}
              description={
                "Measure your sign-up to onboarding completion rate. See drop-off stage during onboarding."
              }
              buttonText={"Setup Insight"}
              setShowInsightSetupModal={setShowInsightSetupModal}></InsightsDashboardSetupReport>
          </>
        ) : (
          <>
            {/*<OnboardingUsersList*/}
            {/*  title={`Onboarding ${*/}
            {/*    reportType == "user" ? "Users" : "Companies"*/}
            {/*  } List - Step ${stepNo} (${funnel[stepNo - 1]?.name})`}*/}
            {/*  opened={showUsersList}*/}
            {/*  setOpened={setShowUsersList}*/}
            {/*  insightId={insightId || getDefaultInsight()?.value}*/}
            {/*  stepNo={stepNo}*/}
            {/*  reportType={reportType}*/}
            {/*  fromDate={dropdownFilterDate.startDate}*/}
            {/*  toDate={dropdownFilterDate.endDate}*/}
            {/*  audienceConditions={filters.rules}*/}
            {/*  audienceConditionType={filters.combinator}*/}
            {/*  audienceGroupConditions={filters.groups}*/}
            {/*/>*/}

            <Flex
              justify={"space-between"}
              // sx={{
              //   [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
              //     flexDirection: "column",
              //     gap: "1rem"
              //   }
              // }}
              mb="md">
              <Flex align={"center"}>
                <Select
                  mr={4}
                  variant="filled"
                  size="sm"
                  defaultValue={getDefaultInsight()?.value}
                  // searchable
                  w={260}
                  placeholder="Select insight"
                  onChange={(item: any) => {
                    navigate({ search: `insightId=${item}` });

                    fetchFunnelInsights(activeWorkspace.id, item, {
                      start_date: dropdownFilterDate.startDate,
                      end_date: dropdownFilterDate.endDate,
                      funnel_type: reportType
                    });
                    fetchFunnelInsightsStats(activeWorkspace.id, item, {
                      start_date: dropdownFilterDate.startDate,
                      end_date: dropdownFilterDate.endDate,
                      funnel_type: reportType
                    });

                    fetchUsers(activeWorkspace.id, item, {
                      start_date: dropdownFilterDate.startDate,
                      end_date: dropdownFilterDate.endDate,
                      funnel_type: reportType,
                      full_data: false
                    });
                  }}
                  data={insightsList.map((item: any) => {
                    return {
                      label: item.name,
                      value: item.id
                    };
                  })}
                />

                <EditInsight setShowInsightSetupModal={setShowInsightSetupModal} />
              </Flex>
              <Flex align={"center"}>
                <Box mr="sm">
                  <ReportTypeToggle
                    reportType={reportType}
                    setReportType={(value: IReportType) => {
                      reset();
                      setSelectedSegment("");
                      setReportType(value);
                    }}
                  />
                </Box>

                {/* TODO: add segment support in funnel endpoints */}
                {/*<SegmentDropdownMenu*/}
                {/*  type={reportType === "user" ? "user" : "company"}*/}
                {/*  onChange={(value: any) => {*/}
                {/*    if (!value) {*/}
                {/*      reset();*/}
                {/*      onClearFiltersChange();*/}
                {/*      return;*/}
                {/*    }*/}
                {/*    setFilters(segments.find((s: any) => s.id === value).filters);*/}
                {/*    setFilterVisible(false);*/}
                {/*    onApplyChange(segments.find((s: any) => s.id === value).filters);*/}
                {/*  }}*/}
                {/*/>*/}

                <DateDropdownMenu onChange={onChangeDate} />
              </Flex>
            </Flex>

            <SimpleGrid
              cols={{
                base: 3,
                md: 3,
                sm: 1
              }}
              spacing={"sm"}
              mb={"md"}
              w={"100%"}>
              <StatsCard
                title={`Total ${reportType == "user" ? "Users" : "Companies"}`}
                description={`who have visited the funnel.`}
                value={funnelInsightsMeta.total_visitors}
                icon="eye"
                bg="yellow.6"
                iconColor="text-yellow-500"
                loading={funnelInsightsFetching}
              />

              <StatsCard
                title={`Total Onboarded`}
                value={funnelInsightsMeta.total_converted}
                description={`who have completed all steps.`}
                icon="users"
                bg="blue.6"
                iconColor="text-blue-500"
                loading={funnelInsightsFetching}
              />

              {funnelInsightsSteps.length >= 3 && funnelInsightsMeta?.most_drop_off_step && (
                <StatsCard
                  title={`Most Drop-off Step`}
                  description={
                    <>
                      Highest drop-off rate of{" "}
                      <b>
                        {roundFunnelRate(funnelInsightsMeta?.most_drop_off_step?.percentage || 0)}%
                      </b>{" "}
                      at this step.
                    </>
                  }
                  value={
                    <Flex align={"center"}>
                      <Box
                        style={(theme) => ({
                          marginRight: "12px",
                          borderRadius: 4,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid",
                          fontWeight: 600,
                          // height: 24,
                          // width: 24,
                          padding: "4px 8px",
                          fontSize: 12,
                          color: colorScheme === "dark" ? theme.colors.dark[0] : "#595c5f",
                          backgroundColor:
                            colorScheme === "dark" ? theme.colors.dark[4] : "#e8e9ec",
                          borderColor: colorScheme === "dark" ? theme.colors.dark[4] : "#e8e9ec",
                          fontFamily: "Inter, sans-serif",
                          whiteSpace: "nowrap"
                        })}>
                        Step {funnelInsightsMeta?.most_drop_off_step?.level || "-"}
                      </Box>
                      <Text lineClamp={1}>
                        {funnelInsightsMeta?.most_drop_off_step?.name || "-"}
                      </Text>
                    </Flex>
                  }
                  icon="arrow-down"
                  bg="red.7"
                  iconColor="text-red-500"
                  loading={funnelInsightsFetching}
                />
              )}
            </SimpleGrid>

            <InsightSection
              title="Onboarding Completion funnel"
              description={` The onboarding funnel helps in identifying at which stage your
                    ${reportType == "user" ? "users" : "companies"} have dropped off.`}
              rightSection={
                <>
                  <HoverCard width={280} shadow="md" withArrow disabled={!isStarter}>
                    <HoverCard.Target>
                      <Button
                        size="xs"
                        variant={"light"}
                        disabled={funnelInsightsFetching}
                        onClick={() =>
                          handleDetailClick(
                            insightId || getDefaultInsight()?.value,
                            funnelInsightsSteps,
                            funnelInsightsMeta
                          )
                        }>
                        Deep Dive
                        {isStarter && (
                          <FontAwesomeIcon
                            icon={solid("bolt-lightning")}
                            className="w-4 h-4 ml-1.5 hover:opacity-80"
                          />
                        )}
                      </Button>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Text size="sm">
                        Upgrade to <span className="font-semibold">Pro</span> or{" "}
                        <span className="font-semibold">Premium</span> plan to view the detailed
                        breakdown of your onboarding conversion funnels.
                        <span
                          className="primary-link-color underline cursor-pointer ml-2"
                          onClick={() =>
                            navigate(`/env/${activeWorkspace.identifier}/account/billing`)
                          }>
                          Upgrade now.
                        </span>
                      </Text>
                    </HoverCard.Dropdown>
                  </HoverCard>
                </>
              }>
              <Box className={classes.box}>
                {funnelInsightsFetching ? (
                  <Box
                    h={
                      funnelInsightsSteps.length * 50 < 400
                        ? "400px"
                        : `calc(50px * ${funnelInsightsSteps.length})`
                    }>
                    <BarChartSkeleton />
                  </Box>
                ) : (
                  <Box
                    h={
                      funnelInsightsSteps.length * 50 < 400
                        ? "400px"
                        : `calc(50px * ${funnelInsightsSteps.length})`
                    }>
                    <FunnelChartWrapper
                      funnel={funnelInsightsSteps}
                      meta={funnelInsightsMeta}
                      type={reportType as any}
                    />
                  </Box>
                )}
              </Box>

              <Box
                mt={"md"}
                style={(theme) => ({
                  backgroundColor:
                    colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[1],
                  padding: theme.spacing.lg,
                  borderRadius: "4px",
                  marginRight: "1.5rem",
                  marginLeft: "1.5rem"
                })}>
                <Flex justify={"center"} align={"center"} ml={"xs"} mb="sm">
                  <Title order={4} ta="center">
                    <Flex align={"center"}>
                      <Box w={16} c="yellow.6" mr="md">
                        <FontAwesomeIcon icon={solid("lightbulb-on")} />
                      </Box>
                      Insights
                    </Flex>
                  </Title>
                  <HoverCard width={350} shadow="md" withArrow position={"top-end"} withinPortal>
                    <HoverCard.Target>
                      <Box c="gray.7" ml={"xs"}>
                        <FontAwesomeIcon
                          size={"xs"}
                          icon={regular("info-circle")}></FontAwesomeIcon>
                      </Box>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Text>
                        Insights are automatically generated by our AI model. For variables to be
                        included in the insights, they must contribute more than 5% of the total
                        funnel traffic.
                      </Text>
                    </HoverCard.Dropdown>
                  </HoverCard>
                </Flex>

                <Box mt={"md"}>
                  {!fetchingInsights ? (
                    <>
                      {noInsightData && funnelInsights ? (
                        <SimpleGrid cols={2}>
                          <InsightsCard
                            sx={{
                              height: "100%"
                            }}
                            data={{
                              breakdown: "chf",
                              bestPerforming: funnelInsights.chf.best_performing,
                              worstPerforming: funnelInsights.chf.worst_performing,
                              funnelType: "user"
                            }}
                            hideDetailedViewButton={true}
                          />

                          <InsightsCard
                            sx={{
                              height: "100%"
                            }}
                            data={{
                              breakdown: "sf",
                              bestPerforming: funnelInsights.sf.best_performing,
                              worstPerforming: funnelInsights.sf.worst_performing,
                              funnelType: "user"
                            }}
                            blur={false}
                            hideDetailedViewButton={true}
                          />

                          <InsightsCard
                            sx={{
                              height: "100%"
                            }}
                            data={{
                              breakdown: "cf",
                              bestPerforming: funnelInsights.cf.best_performing,
                              worstPerforming: funnelInsights.cf.worst_performing,
                              funnelType: "user"
                            }}
                            hideDetailedViewButton={true}
                          />

                          <InsightsCard
                            sx={{
                              height: "100%"
                            }}
                            data={{
                              breakdown: "df",
                              bestPerforming: funnelInsights.df.best_performing,
                              worstPerforming: funnelInsights.df.worst_performing,
                              funnelType: "user"
                            }}
                            hideDetailedViewButton={true}
                          />
                        </SimpleGrid>
                      ) : (
                        <>
                          <div className="h-[130px] w-full flex justify-center items-center">
                            <h3>No insights data</h3>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Flex justify={"center"} align={"center"} h={130}>
                        <SimpleGrid cols={3}>
                          <Skeleton h={30} w={200} />
                          <Skeleton h={30} w={200} />
                          <Skeleton h={30} w={200} />
                        </SimpleGrid>
                      </Flex>
                    </>
                  )}
                </Box>
              </Box>
            </InsightSection>
            <InsightSection
              title={`${reportType == "user" ? "Users" : "Companies"} Details`}
              description={getUserDetailsMessage()}
              rightSection={
                <SegmentedControl
                  value={usersListToggleState}
                  onChange={(value: any) => setUsersListToggleState(value)}
                  data={[
                    { label: "Completed", value: "completed" },
                    { label: "Dropped off", value: "dropped" }
                  ]}
                />
              }>
              {!fetchingUsers ? (
                <Flex
                  gap={"1.2rem"}
                  wrap="nowrap"
                  pb="sm"
                  style={{
                    overflowX: "auto"
                  }}>
                  {funnelInsightsUsers.map((users, index) => (
                    <>
                      {!(index === 0 && usersListToggleState === "dropped") ? (
                        <FunnelUsersListCard
                          title={users.name}
                          users={
                            usersListToggleState === "completed"
                              ? users.completed
                              : users.dropped_off
                          }
                          reportType={reportType}
                          type={usersListToggleState}
                          index={index}
                          onFullListClick={() => {
                            setSelectedUserCard({
                              name: users.name,
                              index: index,
                              type: usersListToggleState
                            });
                            setShowUsersList(true);
                          }}
                        />
                      ) : null}
                    </>
                  ))}
                </Flex>
              ) : (
                <BarChartSkeleton />
              )}
            </InsightSection>

            {/*<FunnelTable*/}
            {/*  hasActions={true}*/}
            {/*  isLoading={funnelInsightsFetching ? "loading" : "loaded"}*/}
            {/*  rows={funnelTableData}*/}
            {/*  onClick={handleOnClickStep}*/}
            {/*  reportType={ reportType}></FunnelTable>*/}

            <InsightSection title={" Average time distribution for onboarding completion"}>
              <ConversionTimeDistribution
                id={insightId || getDefaultInsight()?.value}
                dateRange={dateRange as any}
                selectedType={reportType}
              />
            </InsightSection>

            <FunnelUsersDetailedView
              isModalOpen={showUsersList}
              setIsModalOpen={setShowUsersList}
              insightId={insightId || getDefaultInsight()?.value}
              reportType={reportType}
              filters={{
                start_date: dropdownFilterDate.startDate,
                end_date: dropdownFilterDate.endDate
              }}
              selectedUserCard={selectedUserCard}
            />
          </>
        )}

        <SidePanel
          loading={true}
          opened={detailModal}
          onCancel={() => setDetailModal(false)}
          fullWidth
          hideOverflow>
          <FunnelDetails
            id={insightId || getDefaultInsight()?.value}
            initialActiveTabType={initialActiveTabType}
            selectedFunnel={funnelInsightsSteps}
            selectedMeta={funnelInsightsMeta}
            selectedType={reportType}
            date={dateRange as any}
            onDateChange={onChangeDate as any}
            isOnboarding={true}
          />
        </SidePanel>
      </IntlProvider>
    </>
  );
};

export default InsightsDashboardOnboarding;
