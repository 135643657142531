import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Flex, Switch, Text } from "@mantine/core";
import useInsightHook from "@/hooks/useInsightHook";
import { useLocation, useParams } from "react-router-dom";
import { InsightsSetupEvents } from "@/ui/components/App/InsightsSetupEvents/InsightsSetupEvents";

import Icon from "@/ui/components/Common/Icon/Icon";
import "./setup.scss";
import { StepCard } from "@/ui/components/Common/StepCard/StepCard";

interface EventsSetupProps {
  nameIdentifier?: string;
  title: string;
  description: string;
  conditions: any;
  setConditions: (e: any) => void;
  minimumConditions?: number;
  maximumConditions?: number;
  conditionType?: "or" | "funnel";
  stepNo?: number;
  conditionButtonTitle?: string;
  allowVisitorsStep?: boolean;
  isVisitorsStepEnabled?: boolean;
  setIsVisitorsStepEnabled?: (e: any) => void;
}

export const EventsSetup: React.FC<EventsSetupProps> = ({
  nameIdentifier = "condition",
  conditions,
  setConditions,
  conditionType = "funnel",
  title = "Your Headline",
  description = "Your description...",
  minimumConditions = 1,
  maximumConditions = 100,
  stepNo = 2,
  isVisitorsStepEnabled = true,
  setIsVisitorsStepEnabled = () => {}
}) => {
  const { insightId } = useParams<{
    insightId: string;
  }>();
  const location = useLocation();
  const { insightEvents, insightAttributes, insightPinnedEvents } = useInsightHook();

  return (
    <>
      <StepCard step={stepNo}>
        <Flex direction="column">
          <Text fw={600}>{title}</Text>
          <Text size="sm" c="dimmed" my="sm">
            {description}
          </Text>
          <InsightsSetupEvents
            options={[
              {
                label: "Page URL",
                value: "pageview"
              },
              { label: "Custom Event", value: "event" },
              { label: "Pinned Event", value: "pinned_event" }
            ]}
            conditionType={conditionType}
            conditions={conditions}
            setConditions={setConditions}
            attributesOptions={insightAttributes}
            pinnedEventOptions={insightPinnedEvents}
            eventsOptions={insightEvents}
            nameIdentifier={nameIdentifier}
          />
        </Flex>
      </StepCard>
    </>
  );
  // return (
  //   <>
  //     <Box
  //       className="um-insights-setup__box box-shadow-sm"
  //       sx={(theme) => {
  //         return {
  //           backgroundColor:
  //             theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
  //           borderColor:
  //             theme.colorScheme === "dark"
  //               ? theme.colors.darkBorderColor[0]
  //               : theme.colors.lightBorderColor[0]
  //         };
  //       }}>
  //       <div className="um-insights-setup__name">
  //         <div className="um-insights-setup__name-header">
  //           <div className="um-insights-setup__name-header-step">
  //             <Avatar
  //               className="mr-6"
  //               variant="outline"
  //               radius="xl"
  //               color={
  //                 conditions.length > 0 &&
  //                 conditions.every(
  //                   (obj: { key: string; label: string }) => obj.key !== "" && obj.label !== ""
  //                 )
  //                   ? "green.6"
  //                   : "brand.4"
  //               }
  //               styles={{
  //                 placeholder: {
  //                   borderWidth: "2px"
  //                 }
  //               }}>
  //               {conditions.length > 0 &&
  //               conditions.every(
  //                 (obj: { key: string; label: string }) => obj.key !== "" && obj.label !== ""
  //               ) ? (
  //                 <>
  //                   <FontAwesomeIcon icon={solid("check")} />
  //                 </>
  //               ) : (
  //                 <>{stepNo}</>
  //               )}
  //             </Avatar>
  //           </div>
  //           <div className="um-insights-setup__name-header-content">
  //             <h2>{title}</h2>
  //             <p>{description}</p>
  //           </div>
  //         </div>
  //       </div>

  //       <div className={"um-insights-setup__body"}>
  //         <div className="um-insights-setup__body-field">
  //           {/* {location.pathname.includes("funnel_conversion_rate") && (
  //             <>
  //               <div className="flex">
  //                 <div className={!isVisitorsStepEnabled ? "opacity-50" : ""}>
  //                   <div className="mb-4">
  //                     <span className="font-medium py-2 rounded inline-flex px-4 text-gray-900 bg-gray-200 cursor-not-allowed">
  //                       Unique Visitors
  //                     </span>
  //                   </div>
  //                   <Box
  //                     classNames={"div"}
  //                     className={`bg-gray-200 text-gray-900 w-8 h-8 rounded-md border-solid border border-gray-300 flex items-center justify-center ${
  //                       conditions.length === 0 ? "mb-4" : ""
  //                     }`}>
  //                     <span className="">
  //                       <FontAwesomeIcon
  //                         className="text-lg flex"
  //                         icon={regular("angle-down")}></FontAwesomeIcon>
  //                     </span>
  //                   </Box>
  //                 </div>

  //                 <div className="ml-4">
  //                   <>
  //                     <Switch
  //                       mt={8}
  //                       checked={isVisitorsStepEnabled}
  //                       label={`Use this as first step`}
  //                       onChange={(event: any) => setIsVisitorsStepEnabled(event.target.checked)}
  //                       size="xs"></Switch>
  //                   </>
  //                 </div>
  //               </div>
  //             </>
  //           )} */}

  //           <InsightsSetupEvents
  //             options={[
  //               {
  //                 label: "Page URL",
  //                 value: "pageview"
  //               },
  //               { label: "Custom Event", value: "event" },
  //               { label: "Pinned Event", value: "pinned_event" }
  //             ]}
  //             conditionType={conditionType}
  //             conditions={conditions}
  //             setConditions={setConditions}
  //             attributesOptions={insightAttributes}
  //             pinnedEventOptions={insightPinnedEvents}
  //             eventsOptions={insightEvents}
  //             nameIdentifier={nameIdentifier}
  //           />
  //         </div>
  //       </div>
  //     </Box>
  //   </>
  // );
};
