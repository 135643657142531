// import { Prism } from "@mantine/prism";
// import githubTheme from "prism-react-renderer/themes/github";
// import duotoneDark from "prism-react-renderer/themes/dracula";

import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LooseObject } from "types/types.d";
import { StringParam, useQueryParam } from "use-query-params";
import EventsRecommendationLogin from "../../../assets/images/pages/recommendations/login.png";
import EventsRecommendationLogout from "../../../assets/images/pages/recommendations/logout.png";
import EventsRecommendationOnboardingEvents from "../../../assets/images/pages/recommendations/onboarding-events.png";
import EventsRecommendationPlanDowngraded from "../../../assets/images/pages/recommendations/plan-downgraded.png";
import EventsRecommendationPlanUpgraded from "../../../assets/images/pages/recommendations/plan-upgraded.png";
import EventsRecommendationSignUp from "../../../assets/images/pages/recommendations/sign-up.png";
import EventsRecommendationTrialExpired from "../../../assets/images/pages/recommendations/trial-expired.png";
import EventsRecommendationTrialStarted from "../../../assets/images/pages/recommendations/trial-started.png";
import EventsRecommendationUserAccountAdded from "../../../assets/images/pages/recommendations/user-account-added.png";
import { Avatar, Box, Flex, Paper, Title, Text, Grid, List, Image } from "@mantine/core";
import classes from "./EventRecommendations.module.css";
import clsx from "clsx";
import { CodeHighlight } from "@mantine/code-highlight";
const eventsListDetails = [
  {
    name: "signed_up",
    title: "Signed Up",
    description:
      "Trigger this event when a user signs up for a new account. We recommend to call the identify function first before calling the signed up event.",
    image: EventsRecommendationSignUp,
    identifyCode: true,
    js_snippet_code: `<script>
      usermaven("track", "signed_up")
</script>`,
    sdk_code: `usermaven.track("signed_up")`
  },
  {
    name: "logged_in",
    title: "Logged In",
    description: "Trigger this event when a user logs in to use your product.",
    image: EventsRecommendationLogin,
    js_snippet_code: `<script>
      usermaven("track", "logged_in")
</script>`,
    sdk_code: `usermaven.track("logged_in")`
  },
  {
    name: "logged_out",
    title: "Logged Out",
    description: "Trigger this event when a user logs out of your product.",
    image: EventsRecommendationLogout,
    js_snippet_code: `<script>
      usermaven("track", "logged_out")
</script>`,
    sdk_code: `usermaven.track("logged_out")`
  },
  {
    name: "onboarding_events",
    title: "Onboarding events",
    description: `Trigger onboarding events for the actions your users need to perform right after signing-up. These actions should lead the user to reach your product's 'aha' moment.
 <br/><br/>
    For example, a social media scheduler SaaS will have the following events triggered during onboarding process: <br/><br/>  

    <ul class="list-disc ml-4">
      <li>- Create workspace</li>
      <li>- Connect social accounts</li>
      <li>- Schedule post</li>
    </ul>
    `,
    image: EventsRecommendationOnboardingEvents,
    js_snippet_code: ``, // due to multiple events, we set this to empty.
    multiple_events: [
      {
        js_snippet_code: `<script>
  usermaven("track", "workspace_created")
</script>`,
        title: "Workspace created event",
        sdk_code: `usermaven.track("workspace_created")`
      },
      {
        js_snippet_code: `<script>
  usermaven("track", "social_accounts_connected")
</script>`,
        sdk_code: `usermaven.track("social_accounts_connected")`,
        title: "Connected social accounts event"
      },
      {
        js_snippet_code: `<script>
  usermaven("track", "schedule_post")
</script>`,
        title: "Schedule post event",
        sdk_code: `usermaven.track("schedule_post")`
      }
    ]
  },
  {
    name: "core_feature_events",
    title: "Core feature events",
    description:
      "Features are individual tasks that your application can perform. Core features can show you who is getting the most value from your application. <br/><br /> For example, Invoice creation and invoice approval in an Invoicing SaaS product, hotel bookings in a hotel management SaaS product, etc.",
    image: "",
    js_snippet_code: `<script>
      usermaven("track", "invoice_created")
</script>`,
    sdk_code: `usermaven.track("invoice_created")`
  },

  {
    name: "trial_started",
    title: "Trial Started",
    description: "Trigger this event when a user starts their free trial of your product.",
    image: EventsRecommendationTrialStarted,

    js_snippet_code: `<script>
      usermaven("track", "trial_started")
</script>`,
    sdk_code: `usermaven.track("trial_started")`
  },
  {
    name: "trial_ended",
    title: "Trial Ended",
    description: `Trigger this event when a user's free trial ended.
      <br/><br/>
      For example, when the free trial is ended, your users are redirected to the upgrade page. You should trigger this event there. `,
    image: EventsRecommendationTrialExpired,
    js_snippet_code: `<script>
      usermaven("track", "trial_ended")
</script>`,
    sdk_code: `usermaven.track("trial_ended")`
  },

  {
    name: "user_account_added",
    title: "User Account Added",
    description:
      "Trigger this event when a team member is added to your account/organization of your product.",
    image: EventsRecommendationUserAccountAdded,
    js_snippet_code: `<script>
      usermaven("track", "user_account_added")
</script>`,
    sdk_code: `usermaven.track("user_account_added")`
  },
  {
    name: "user_account_removed",
    title: "User Account Removed",
    description:
      "Trigger this event when a team member is removed from your account/organization of your product.",
    image: "",
    js_snippet_code: `<script>
      usermaven("track", "user_account_removed")
</script>`,
    sdk_code: `usermaven.track("user_account_removed")`
  },
  {
    name: "plan_upgraded",
    title: "Plan Upgraded",
    description: "Trigger this event when a user have upgraded their plan",
    image: EventsRecommendationPlanUpgraded,
    js_snippet_code: `<script>
      usermaven("track", "plan_upgraded", {
        // optional attributes
        plan_name: "growth",
        amount: 99
      })
</script>`,
    sdk_code: `usermaven.track("plan_upgraded", {
        // optional attributes
        plan_name: "growth",
        amount: 99
        })`
  },
  {
    name: "plan_downgraded",
    title: "Plan Downgraded",
    description: "Trigger this event when a user have downgraded their plan",
    image: EventsRecommendationPlanDowngraded,
    js_snippet_code: `<script>
      usermaven("track", "plan_downgraded", {
        // optional attributes
        plan_name: "basic",
        amount: 49
    })
</script>`,
    sdk_code: `usermaven.track("plan_downgraded", {
        // optional attributes
        plan_name: "basic",
        amount: 49
  })`
  }
];

export const EventsRecommendations: FC<{}> = () => {
  const [type] = useQueryParam("type", StringParam);

  const navigate = useNavigate();
  const [activeEventRecommendation, setActiveEventRecommendation] = useState<LooseObject>(
    eventsListDetails[0]
  );
  return (
    <Paper>
      <Flex align={"center"} mx="auto" direction={"column"} pos={"relative"}>
        <Flex
          align={"center"}
          justify={"center"}
          className={classes.closeIcon}
          // style={{
          //   position: "absolute",
          //   top: "1rem",
          //   right: "2rem",
          //   padding: 24,
          //   borderRadius: "50%",
          //   background: "light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-6))",
          //   height: 16,
          //   width: 16,
          //   transition: "all 0.3s ease-in-out",
          //   "&:hover": {
          //     background: "light-dark(var(--mantine-color-white), var(--mantine-color-dark-5))"
          //   }
          // }}
          onClick={() => {
            navigate(-1);
          }}>
          <FontAwesomeIcon icon={regular("times")} fontSize={"1.5rem"}></FontAwesomeIcon>
        </Flex>
        <div style={{ width: "960px" }}>
          <Title order={3} ta="center" my={24}>
            Events Tracking Recommendations
          </Title>
          <Text fz="md">
            The following guide explains how SaaS companies can implement their tracking events to
            capture meaningful user actions that can then be analyzed by Usermaven in order to
            identify valuable insights.
          </Text>
          <Grid my={16}>
            <Grid.Col span={3}>
              <Box>
                <Title order={4} my={16}>
                  Events
                </Title>
                <ul className="leading-8">
                  <List>
                    {eventsListDetails.map((item: LooseObject, index: number) => (
                      <List.Item
                        py={"sm"}
                        fz={"sm"}
                        px={"sm"}
                        className={clsx(
                          classes.listItem,
                          activeEventRecommendation.name === item.name ? classes.active : ""
                        )}
                        style={
                          {
                            // background:
                            //   activeEventRecommendation.name === item.name
                            //     ? "light-dark(var(--mantine-color-brand-1), var(--mantine-color-dark-7))"
                            //     : "transparent",
                            // borderColor:
                            //   activeEventRecommendation.name === item.name
                            //     ? "light-dark(var(--mantine-color-brand-5), var(--mantine-color-dark-4))"
                            //     : ""
                          }
                        }
                        key={`events-tracking-recommendations:${item.name}`}
                        onClick={() => {
                          setActiveEventRecommendation(item);
                        }}>
                        {item.title}
                      </List.Item>
                      // <li
                      //   onClick={() => {
                      //     setActiveEventRecommendation(item);
                      //   }}
                      //   className={`px-3 py-1.5 mb-2 flex hover:bg-purple-100 transition-all ease-in-out cursor-pointer rounded border border-solid border-transparent ${
                      //     activeEventRecommendation.name === item.name
                      //       ? "bg-purple-100 border-purple-200"
                      //       : ""
                      //   }`}
                      //   key={`events-tracking-recommendations:${item.name}`}>
                      //   {item.title}
                      // </li>
                    ))}
                  </List>
                </ul>
              </Box>
            </Grid.Col>
            <Grid.Col span={9}>
              <Box className={classes.recommendationContainer}>
                <Title order={4} mb="sm">
                  {activeEventRecommendation.title}
                </Title>
                <Text mb="sm">{parse(activeEventRecommendation.description)}</Text>
                {activeEventRecommendation.image && (
                  <Image
                    fit="contain"
                    radius={"md"}
                    mb={"md"}
                    className="w-full rounded mb-4"
                    src={activeEventRecommendation.image}
                    alt="sign up"
                  />
                )}
                <Title order={4} mb="sm">
                  Tracking code
                </Title>
                {activeEventRecommendation?.multiple_events ? (
                  <>
                    {activeEventRecommendation.multiple_events.map((event: LooseObject) => (
                      <>
                        <h4 className="mb-4 font-medium">{event.title}</h4>
                        <div className="mb-4">
                          <CodeHighlight
                            language="javascript"
                            code={type === "js-sdk" ? event.sdk_code : event.js_snippet_code}
                          />
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <CodeHighlight
                      language="javascript"
                      code={
                        type === "js-sdk"
                          ? activeEventRecommendation.sdk_code
                          : activeEventRecommendation.js_snippet_code
                      }
                    />
                  </>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </div>
      </Flex>
    </Paper>
  );
};
