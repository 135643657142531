import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { PowerUsersTrendsService } from "@/lib/services/PowerUsersTrendsService";
import { CalendarMonthsRange } from "@/lib/utils/CalendarDatesUtility";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import { DATE_FORMAT } from "@/lib/utils/Constants";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { DropdownMenu } from "@/ui/components/App/Dropdowns/DropdownMenu";
import { EmptyGraphResults } from "@/ui/components/App/EmptyGraphResults/EmptyGraphResults";
import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import * as echarts from "echarts/core";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { InsightIdType } from "types/types.d";

import { useSegmentStore } from "@/stores/useSegmentStore";
import { BarGraphWrapper } from "@/ui/components/App/EChartsWrappers/BarGraphWrapper";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Anchor, Center, Text, useMantineTheme } from "@mantine/core";
import { PowerUsersDetailedView } from "./PowerUsersDetailedView";

const PowerUsersActivity: FC<InsightIdType> = ({ insightId, reportType = "user" }) => {
  const [filters] = useSegmentStore((state) => [state.filters]);
  const [isLoading, setIsLoading] = useState("idle");
  const [barChartXAxis, setBarChartXAxis] = useState([]);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  // const { insightId } = useParams<{
  //   insightId: string;
  // }>();
  const memoizedCalendarDateRange = useMemo(() => CalendarMonthsRange(), [insightId]);
  const [powerActivityUsers, setPowerActivityUsers] = useState<any>({
    categories: [],
    series: []
  });
  // Detailed View state to toggle between detailed view and normal view.
  const [detailedView, setDetailedView] = useState(false);
  // Period State to be used in this component and to be passed to detailed view component for showing user related to selected time period
  const [period, setPeriod] = useState({
    startDate: format(startOfMonth(subMonths(new Date(), 1)), DATE_FORMAT),
    endDate: format(endOfMonth(subMonths(new Date(), 1)), DATE_FORMAT)
  });

  const getPowerUsersHistogram = async (startDate = period.startDate, endDate = period.endDate) => {
    setIsLoading("loading");
    const powerUsersTrendsService = new PowerUsersTrendsService();
    await powerUsersTrendsService
      .powerUsersActivityHistogram(
        activeWorkspace.id,
        insightId,
        reportType,
        startDate,
        endDate,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        if (res.data) {
          let seriesData = res.data.map((item: any) => item.Users);
          // get last index from series
          let last = seriesData[seriesData.length - 1];
          last = {
            value: last,
            itemStyle: {
              color: "#FD60B7"
            }
          };

          // update the last index with above object
          seriesData[seriesData.length - 1] = last;

          let data = {
            categories: res.data.map((item: any) => item.active_event_count),
            series: [
              {
                type: "bar",
                name: `Power ${reportType == "user" ? "Users" : "Companies"} Activity`,
                data: seriesData,
                itemStyle: {
                  borderRadius: [4, 4, 0, 0]
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgb(125, 71, 235, 0.7)" },
                  { offset: 0.5, color: "rgb(125, 71, 235, 0.8)" },
                  { offset: 1, color: "rgb(125, 71, 235, 1)" }
                ])
              }
            ]
          };

          console.log(data);
          setPowerActivityUsers(data);
          // setPowerActivityUsers(
          //   res.data.map((item: any) => {
          //     return {
          //       eventCount: item.active_event_count,
          //       users: item.Users,
          //     }
          //   })
          // )

          // const totalWindowForXAxis = res.data.length > 30 ? Math.round(res.data.length / 15) : 5

          // setBarChartXAxis(
          //   res.data.map((item: any, index: number) => {
          //     if (index % totalWindowForXAxis == 0) {
          //       return `${item.active_event_count}`
          //     }
          //   })
          // )
        }
      });
    setIsLoading("loaded");
  };

  // On clicking any bar, this function will check if it's the bar with most events and will open the modal if that's the case
  const handleOnClick = () => {
    setDetailedView(!detailedView);
  };

  useEffect(() => {
    getPowerUsersHistogram();
  }, [insightId, filters]);

  // On changing the period, the graph will be updated with new data and period state will be updated to be passed to detailed view component
  const onChangeDate = (item: { label: string; value: string }) => {
    const splitDates = item.value.split("_");
    setPeriod({ startDate: splitDates[0], endDate: splitDates[1] });
    getPowerUsersHistogram(period.startDate, period.endDate);
  };

  // =
  return (
    <>
      <InsightSection
        title={`  Power ${
          reportType == "user" ? "Users" : "Companies"
        } Activity on 30 days interval`}
        description={
          <>
            {reportType == "user" ? "Users'" : "Companies'"} activity by the total number of events
            they have performed in a month.
          </>
        }
        rightSection={
          <DropdownMenu
            selectedOption={memoizedCalendarDateRange[0]}
            options={memoizedCalendarDateRange}
            onChange={(item: any) => {
              onChangeDate(item);
            }}></DropdownMenu>
        }>
        <div style={{ height: "400px" }}>
          {isLoading === "loaded" ? (
            <>
              {powerActivityUsers.series.length > 0 ? (
                <>
                  {/* Detailed View for power users | companies list */}
                  <PowerUsersDetailedView
                    detailedView={detailedView}
                    setDetailedView={setDetailedView}
                    insightId={insightId}
                    listReportType={"most_activity_users"}
                    reportType={reportType}
                    startDate={period.startDate}
                    endDate={period.endDate}
                  />
                  <BarGraphWrapper
                    series={powerActivityUsers.series}
                    categories={powerActivityUsers.categories}
                    customTooltipFormatter={(params) => {
                      console.log(params);
                      // return `${params.value} users have performed ${params.name} events`
                      return `<span class="font-bold">${params[0].seriesName} </span> <br />
                                  <span class="font-bold">${
                                    typeof params[0].data === "object"
                                      ? params[0].data.value
                                      : params[0].data
                                  }</span> ${
                        reportType == "user" ? "users" : "companies"
                      } have performed <span class="font-bold">${
                        params[0].axisValue
                      }</span> events. <br />
                                  `;
                    }}
                    onClick={(params: any) => {
                      if (typeof params.data === "object") {
                        handleOnClick();
                      }
                    }}
                    xAxisName={
                      reportType == "user"
                        ? "No. of events performed by users"
                        : "No. of events performed by companies"
                    }
                    yAxisName={reportType == "user" ? "No. of users" : "No. of companies"}
                  />
                </>
              ) : (
                <>
                  <EmptyGraphResults />
                </>
              )}
            </>
          ) : (
            <BarChartSkeleton />
          )}
        </div>
        {powerActivityUsers.series.length > 0 && (
          <Center my="md">
            <Alert
              w={600}
              // styles={() => {
              //   return {
              //     icon: {
              //       marginTop: "1.5rem",
              //     },
              //   }
              // }}
              title="Insights"
              className="mx-auto max-w-[64rem] mt-4"
              icon={
                <FontAwesomeIcon className="text-2xl" icon={regular("lightbulb-exclamation")} />
              }>
              <ul className="leading-8">
                <li>
                  <Text span fw={600}>
                    {numberToCommas(
                      powerActivityUsers.series[0].data[
                        powerActivityUsers.series[0].data.length - 1
                      ].value
                    )}
                  </Text>{" "}
                  {reportType == "user" ? "users" : "companies"} have performed more than{" "}
                  <Text span fw={600}>
                    {numberToCommas(
                      powerActivityUsers.categories[powerActivityUsers.categories.length - 1]
                    )}
                  </Text>{" "}
                  events. These are your most active {reportType == "user" ? "users" : "companies"}.{" "}
                  <Anchor
                    fw={600}
                    className="font-bold primary-link-color hover:underline cursor-pointer"
                    onClick={() => setDetailedView(!detailedView)}>
                    Click here
                  </Anchor>{" "}
                  to view {reportType == "user" ? "users" : "companies"} list.
                </li>
              </ul>
            </Alert>
          </Center>
        )}
      </InsightSection>
    </>
  );
};

const PowerUsersActivityMemoized = React.memo(PowerUsersActivity);
export { PowerUsersActivityMemoized as PowerUsersActivity };
