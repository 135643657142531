import axios from "./JWTService";
type WorkspaceJoinParams = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  token: string;
};
export class WorkspaceMemberService {
  getAllMemberEmails = (workspaceId: string | null | undefined): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/workspace_members/${workspaceId}/emails`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  get = (memberId: string | null | undefined): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/workspace_members/${memberId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  delete = (memberId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/v1/workspace_members/${memberId}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  join = (payload: WorkspaceJoinParams): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspace_members/${payload.token}/join`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  acceptInvite = (memberId: string | undefined): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspace_members/${memberId}/accept_invite`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  resendInvite = (memberId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspace_members/${memberId}/resend_invite`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  setDefaultWorkspace = (memberId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspace_members/${memberId}/set_as_default`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  updateRole = (memberId: string | undefined, role: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspace_members/${memberId}`, {
          role: role
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
}
