import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Group,
  Input,
  Modal,
  Text,
  TextInput,
  Title
} from "@mantine/core";
import React, { useContext } from "react";
import { useForm } from "@mantine/form";
import { CreateModuleSchema, FeatureAdoptionService } from "@/lib/services/FeatureAdoptionService";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import useFeatureAdoptionStore, { Module } from "@/stores/useFeatureAdoptionStore";

interface IAddOrUpdateModuleModal {
  open: boolean;
  setOpen: (e: boolean) => void;
  module?: Module;
}

const AddOrUpdateModuleModal: React.FC<IAddOrUpdateModuleModal> = ({ open, setOpen, module }) => {
  const [addModule, updateModule] = useFeatureAdoptionStore((state) => [
    state.addModule,
    state.updateModule
  ]);

  const featureAdoptionService = new FeatureAdoptionService();
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const form = useForm({
    initialValues: {
      name: module ? module.name : "",
      description: module ? module.description : "",
      is_default: module ? module.is_default : false
    },

    validate: {
      name: (value) =>
        value.length > 2 ? null : "Module name should be at least 3 characters long"
    }
  });
  // Function to add or update module object
  const handleOnSubmit = async (values: CreateModuleSchema) => {
    if (module) {
      const updatedValues = {
        id: module.id,
        ...values
      };
      // update module
      await featureAdoptionService
        .updateModule(activeWorkspace.id, updatedValues)
        .then((res) => {
          if (res.data) {
            const apiResponse = res.data;
            const newModule = {
              name: apiResponse.name,
              description: apiResponse.description,
              is_default: apiResponse.is_default
            };
            // add new module to store
            updateModule(module.id, newModule);
            setOpen(false);
            form.reset();
          }
        })
        .catch((err) => {})
        .finally(() => {
          // setIsFeatureAddedLoader("loaded");
        });
    } else {
      // add module
      await featureAdoptionService
        .createModule(activeWorkspace.id, values)
        .then((res) => {
          if (res.data) {
            const apiResponse = res.data;
            const newModule = {
              id: apiResponse.id,
              name: apiResponse.name,
              description: apiResponse.description,
              is_default: apiResponse.is_default,
              features: [],
              is_saved: true,
              is_new: false,
              is_active: true
            };
            // add new module to store
            addModule(newModule);
            setOpen(false);
            form.reset();
          }
        })
        .catch((err) => {})
        .finally(() => {
          // setIsFeatureAddedLoader("loaded");
        });
    }
  };

  return (
    <div>
      <Modal
        overlayOpacity={0.5}
        overlayBlur={1}
        opened={open}
        onClose={() => setOpen(false)}
        title={
          module ? <Title order={5}>Update module</Title> : <Title order={5}>Add new module</Title>
        }>
        <div>
          <Box mx="auto">
            <form onSubmit={form.onSubmit((values: CreateModuleSchema) => handleOnSubmit(values))}>
              <Flex direction="column" gap={"md"}>
                <Input.Wrapper size="xs" id={"name"} label="Name" required>
                  <TextInput
                    mt={4}
                    placeholder="e.g Reporting module"
                    {...form.getInputProps("name")}
                  />
                </Input.Wrapper>
                <Input.Wrapper size="xs" id={"name"} label="Description">
                  <TextInput
                    placeholder="e.g Includes custom report creation, real-time data etc."
                    {...form.getInputProps("description")}
                  />
                </Input.Wrapper>

                <Checkbox
                  className="hidden"
                  label="Set as a default module"
                  {...form.getInputProps("is_default", { type: "checkbox" })}
                />
              </Flex>
              <Divider my="md" />
              <Group justify={"right"}>
                <Button variant="subtle" color="red" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button type="submit">{module ? "Update" : "Create"}</Button>
              </Group>
            </form>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default AddOrUpdateModuleModal;
