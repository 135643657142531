import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
import { GeneralWorkspaceTrackingPixels } from "./GeneralWorkspaceTrackingPixels";
import { useEffect } from "react";
import { Box } from "@mantine/core";
const WorkspaceTrackingSetup = (props: any) => {
  useEffect(() => {
    document.title = "Tracking Setup | Usermaven";
  }, []);

  return (
    <>
      <GeneralWorkspaceTrackingPixels></GeneralWorkspaceTrackingPixels>
    </>
  );
};
export default WorkspaceTrackingSetup;
