import {
  Box,
  useMantineTheme,
  Text,
  Flex,
  Group,
  Avatar,
  Image,
  useMantineColorScheme
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import quotationDarkSvg from "@assets/images/icons/quotation-mark-dark.svg";
import quotationSvg from "@assets/images/icons/quotation-mark.svg";
import MikeHill from "@assets/images/testimonials/mike-hill.png";
import NamN from "@assets/images/testimonials/nam-n.jpg";
import DavidT from "@assets/images/testimonials/david-t.png";
import ScottC from "@assets/images/testimonials/scott-c.png";
import PeterH from "@assets/images/testimonials/peter-h.jpg";
import AbdulS from "@assets/images/testimonials/abdul-s.webp";
import SimonM from "@assets/images/testimonials/simon-m.webp";
import { useMediaQuery } from "@mantine/hooks";
import { MOBILE_BREAKPOINT_QUERY } from "@/lib/utils/Constants";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const TESTIMONIALS = [
  {
    heading: "The best analytics tool for SaaS businesses",
    text: "Finally, I have user-level and company-level analytics for my SaaS. After trying a bunch of different products, Usermaven stood out because it was easy to setup and simple to use.",
    name: "Mike Hill",
    title: "Founder at Frill.co & Curator.co",
    image: MikeHill
  },
  {
    heading: "An easy to use, privacy-focused, cookie-less analytics suite",
    text: `I like how simple and easy it is to use, with no complicated dashboards to set-up. You have all the information you need at first glance, and I also like how it uses cookie-less tracking. The team behind it is fantastic, consistently rolling out new features on a monthly basis.`,
    name: "Scott Collins",
    title: "Founder at innotrends.nl",
    image: ScottC
  },
  {
    heading: "Best cookieless analytics tool on the market",
    text: `Easy integration and implementation, in-depth analytics, great features, fast and very capable support.`,
    name: "Peter Hrnčiar",
    title: "Founder at nwave.sk",
    image: PeterH
  },

  {
    heading: "Google Analytics who?",
    text: `Usermaven is everything all the other site analytic platforms wish they could be. Very nice and easy to read and navigate UI and awesome granular data. This sounds like a puff review but the reality is that I have used at least 8 different analytics platforms and nothing has helped me as much as Usermaven.`,
    name: "David Taggart",
    title: "Small Business Owner",
    image: DavidT
  },
  {
    heading: "The Best Alternative to Google Analytics",
    text: ` It provides a real-time view of website traffic metrics, all while being cookie-free and fully compliant with GDPR, CCPA, and PECR. The simple dashboard is easy to use and provides all the necessary metrics for making informed business decisions, all on a single page.`,
    name: "Nam Nguyen",
    title: "Business Owner",
    image: NamN
  },
  {
    heading: "Goodbye Google Analytics",
    text: `Easy integration and implementation, in-depth analytics, great features, fast and very capable support.`,
    name: "Simon Markham",
    title: "Marketing Consultant",
    image: SimonM
  },
  {
    heading: "Best overall analytics tool so far for funnel analysis and product analytics",
    text: `It’s straightforward and intuitive, functioning seamlessly. It’s significantly more organized, displaying only essential data, making it easy to get a broad view or delve into the details.`,
    name: "Abdul Sattar G.",
    title: "Sr. Web Designer at Round10Boxing.com",
    image: AbdulS
  }
];

export const RegisterTestimonials = () => {
  const { colorScheme } = useMantineColorScheme();
  const autoplay = useRef(Autoplay({ delay: 5000 }));
  const theme = useGlobalMantineTheme();
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);
  if (isMobileView) {
    return <></>;
  }
  return (
    <>
      <Box>
        <Carousel
          loop
          height={260}
          styles={{
            indicator: {
              background: colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.brand[5],
              width: 12,
              height: 4,
              transition: "width 250ms ease",

              "&[data-active]": {
                width: 20
              }
            }
          }}
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          mx="auto"
          withControls={false}
          withIndicators>
          {TESTIMONIALS.map((testimonial, index) => (
            <>
              <Carousel.Slide key={index}>
                {" "}
                {testimonial.heading && (
                  <Text
                    fw={700}
                    size="lg"
                    mb={8}
                    c={colorScheme === "dark" ? "dark.2" : undefined}
                    pr={128}>
                    {testimonial.heading}
                  </Text>
                )}
                <Text c={colorScheme === "dark" ? "dark.2" : undefined} size="md" pr={128}>
                  {testimonial.text}
                </Text>
                <Flex align={"center"}>
                  <Group pt={"lg"} flex={"1"}>
                    <Avatar size={"lg"} src={testimonial.image} radius={"xl"} />
                    <Flex direction={"column"}>
                      <Text size={"md"} fw={500} c="dimmed">
                        {testimonial.name}
                      </Text>
                      <Text fw={500} c="dimmed">
                        {testimonial.title}
                      </Text>
                    </Flex>
                  </Group>
                  <Box pr={64} pt={16}>
                    <Image
                      src={colorScheme === "dark" ? quotationDarkSvg : quotationSvg}
                      width={52}
                      height={47}
                    />
                  </Box>
                </Flex>
              </Carousel.Slide>
            </>
          ))}

          {/* ...other slides */}
        </Carousel>
      </Box>
    </>
  );
};
