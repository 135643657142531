import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { CompaniesService } from "@/lib/services/CompaniesService"
import { utcToRelativeTimezone } from "@/lib/utils/DateUtility"
import { stringToInitials } from "@/lib/utils/StringUtility"
import { FC, useContext, useEffect, useState } from "react"
import { Oval } from "react-loader-spinner"
import { useNavigate, useParams } from "react-router-dom"
import { loaderColor } from "@/style/Colors"
import { LooseObject } from "types/types.d"
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";

export const CompanyUsers: FC<{}> = () => {
  const { companyId } =
    useParams<{
      companyId: string
    }>()
  const [isLoading, setIsLoading] = useState("idle")
  const { activeWorkspace } = useContext(AppLifecycleContext)
  const [users, setUsers] = useState<Array<LooseObject>>()
  const [
    setOverviewModalOpen,
  ] = useUserOverviewModalStore(state => [state.setIsOpen])
  const fetchCompanyUsers = async () => {
    setIsLoading("loading")
    const companyService = new CompaniesService()
    await companyService
      .usersById(activeWorkspace.id, companyId)
      .then(res => {
        if (res.data) {
          setUsers(res.data)
        }
      })
      .catch(err => {})
    setIsLoading("loaded")
  }
  const navigate = useNavigate()

  useEffect(() => {
    fetchCompanyUsers()
  }, [])
  return (
    <>
      <div className="border-gray-200 border border-solid">
        <h3 className="font-semibold py-2 px-4 border-b border-solid border-gray-200 bg-gray-100">
          Users
        </h3>
        <div className="bg-white  border-solid border-b border-gray-200 p-4 flex flex-col">
          {isLoading === "loaded" ? (
            <>
              {users?.map((user: LooseObject, index: number) => (
                <div
                  className="grid grid-cols-5 gap-4 py-8 border-b border-solid border-gray-200"
                  key={`company:user:${user.user_id}`}
                >
                  <div
                    className="flex items-center col-span-2 cursor-pointer"
                    onClick={() => {
                      // navigate(`/env/${activeWorkspace.identifier}/users/everyone/${user.user_id}`)
                      setOverviewModalOpen(true, 'user', user.user_id)
                    }}
                  >
                    <div className="h-12 w-12 mr-4 rounded-full flex items-center justify-center color-band-3 border-2 border-solid border-white">
                      <span className="font-medium text-white uppercase">
                        {stringToInitials(user.user_first_name + " " + user.user_last_name)}
                      </span>
                    </div>
                    <div className="flex-col flex">
                      <div className="font-medium capitalize">
                        {user.user_first_name + " " + user.user_last_name}
                      </div>
                      <p>{user.user_email}</p>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <div className="flex items-center my-1">
                      <FontAwesomeIcon
                        icon="clock"
                        className="text-sm text-gray-700 mr-2"
                      ></FontAwesomeIcon>
                      <h3 className="text-gray-900 uppercase font-semibold text-sm">Created</h3>
                    </div>
                    {user.user_created_at ? (
                      <>
                        <p>{utcToRelativeTimezone(user.user_created_at.replace(" ", "T"))}</p>
                      </>
                    ) : (
                      <p>...</p>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center my-1">
                      <FontAwesomeIcon
                        icon="eye"
                        className="text-sm text-gray-700 mr-2"
                      ></FontAwesomeIcon>
                      <h3 className="text-gray-900 uppercase font-semibold text-sm">Last seen</h3>
                    </div>
                    <p>{utcToRelativeTimezone(user.last_visited_time.replace(" ", "T"))}</p>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center my-1">
                      <FontAwesomeIcon
                        icon="browser"
                        className="text-sm text-gray-700 mr-2"
                      ></FontAwesomeIcon>
                      <h3 className="text-gray-900 uppercase font-semibold text-sm">Visits</h3>
                    </div>
                    <p>{user.uniq_sessions}</p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="flex items-center justify-center flex-grow w-full h-20">
                <Oval color={loaderColor} height={12} width={12} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
