import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  Group,
  Menu,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { COMPARE_DATE_RANGES } from "../../../../../lib/utils/Constants";
import DatePickerDropdown, {
  IDateRange
} from "../../../../components/App/Dropdowns/DatePickerDropdown";
import classes from "./DetailViewMenuGroup.module.css";
import { TAttribute } from "./FunnelDetails";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
export interface IDetailViewMenuGroupProps {
  value: IDetailViewMenu;
  onChange: (value: IDetailViewMenu) => void;
}

export type TDetailView = "overview" | "breakdown" | "compare" | "conversionTimeDistribution";

export interface IDetailViewMenu {
  activeView: TDetailView;
  selectedOption: string | IDateRange | TAttribute | IDateRange[];
}

const DetailViewMenuGroup = ({ value, onChange }: IDetailViewMenuGroupProps) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const intl = useIntl();

  /**
   * Handle link click Method
   * @param tab
   * @param option
   */
  const handleLinkClick = (tab: TDetailView, option: string | IDateRange | IDateRange[] = "") => {
    onChange({ activeView: tab, selectedOption: option });
  };

  const handleDateRangeChange = (range: IDateRange | IDateRange[]) => {
    handleLinkClick("compare", range);
  };

  const COMPARE_DATE_RANGES_RESOLVED = useMemo(() => {
    return COMPARE_DATE_RANGES.map((range) => {
      let label = range.label;
      switch (label) {
        case "Current vs previous day":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousDay" });
          break;
        case "Current vs previous week":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousWeek" });
          break;
        case "Current vs previous month":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousMonth" });
          break;
        case "Current vs previous quarter":
          label = intl.formatMessage({
            id: "insights.headerTabs.compare.currentVsPreviousQuarter"
          });
          break;
        case "Current vs previous year":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousYear" });
          break;
      }
      return {
        ...range,
        label: label
      };
    });
  }, []);

  return (
    <>
      <Group gap="sm" mt="lg">
        <Button
          size={"xs"}
          variant={
            value.activeView === "overview"
              ? "filled"
              : colorScheme === "dark"
              ? "default"
              : "light"
          }
          onClick={() => handleLinkClick("overview")}>
          <FormattedMessage id="insights.headerTabs.overview" defaultMessage="Overview" />
        </Button>
        <Menu shadow="md" width={200} position="bottom-start">
          <Menu.Target>
            <Button
              size={"xs"}
              variant={
                value.activeView === "breakdown"
                  ? "filled"
                  : colorScheme === "dark"
                  ? "default"
                  : "light"
              }
              rightSection={<FontAwesomeIcon icon={solid("angle-down")} />}>
              <FormattedMessage id="insights.headerTabs.breakdown" defaultMessage="Breakdown" />
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>
              <FormattedMessage
                id="insights.headerTabs.breakdown.firstTouch"
                defaultMessage="Breakdown"
              />
            </Menu.Label>
            <Menu.Item
              onClick={() => handleLinkClick("breakdown", "source")}
              className={value.selectedOption === "source" ? classes.menuActiveItem : ""}>
              <FormattedMessage id="insights.headerTabs.breakdown.source" defaultMessage="Source" />
            </Menu.Item>
            <Menu.Item
              onClick={() => handleLinkClick("breakdown", "channel")}
              className={value.selectedOption === "channel" ? classes.menuActiveItem : ""}>
              <FormattedMessage
                id="insights.headerTabs.breakdown.channel"
                defaultMessage="Channel"
              />
            </Menu.Item>
            <Menu.Item
              onClick={() => handleLinkClick("breakdown", "referer")}
              className={value.selectedOption === "referer" ? classes.menuActiveItem : ""}>
              <FormattedMessage
                id="insights.headerTabs.breakdown.referrer"
                defaultMessage="Referrer"
              />
            </Menu.Item>
            <Menu.Label>
              <FormattedMessage
                id="insights.headerTabs.breakdown.location"
                defaultMessage="Location"
              />
            </Menu.Label>
            <Menu.Item
              onClick={() => handleLinkClick("breakdown", "location_country")}
              className={value.selectedOption === "location_country" ? classes.menuActiveItem : ""}>
              <FormattedMessage
                id="insights.headerTabs.breakdown.country"
                defaultMessage="Country"
              />
            </Menu.Item>
            <Menu.Item
              onClick={() => handleLinkClick("breakdown", "location_city")}
              className={value.selectedOption === "location_city" ? classes.menuActiveItem : ""}>
              <FormattedMessage id="insights.headerTabs.breakdown.city" defaultMessage="City" />
            </Menu.Item>
            <Menu.Label>
              <FormattedMessage
                id="insights.headerTabs.breakdown.technologies"
                defaultMessage="Technologies"
              />
            </Menu.Label>
            <Menu.Item
              onClick={() => handleLinkClick("breakdown", "parsed_device")}
              className={value.selectedOption === "parsed_device" ? classes.menuActiveItem : ""}>
              <FormattedMessage id="insights.headerTabs.breakdown.device" defaultMessage="Device" />
            </Menu.Item>
            <Menu.Item
              onClick={() => handleLinkClick("breakdown", "parsed_ua_ua_family")}
              className={
                value.selectedOption === "parsed_ua_ua_family" ? classes.menuActiveItem : ""
              }>
              <FormattedMessage
                id="insights.headerTabs.breakdown.browser"
                defaultMessage="Browser"
              />
            </Menu.Item>
            <Menu.Item
              onClick={() => handleLinkClick("breakdown", "parsed_ua_os_family")}
              className={
                value.selectedOption === "parsed_ua_os_family" ? classes.menuActiveItem : ""
              }>
              <FormattedMessage
                id="insights.headerTabs.breakdown.os"
                defaultMessage="Operating System"
              />
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <DatePickerDropdown
          data={COMPARE_DATE_RANGES_RESOLVED}
          range={value.selectedOption as IDateRange}
          onRangeChange={(range) => handleDateRangeChange(range)}
          showArrow={false}
          withCustomRange>
          {(_) => (
            <Button
              size={"xs"}
              variant={
                value.activeView === "compare"
                  ? "filled"
                  : colorScheme === "dark"
                  ? "default"
                  : "light"
              }
              rightSection={<FontAwesomeIcon icon={solid("angle-down")} />}>
              <FormattedMessage id="insights.headerTabs.compare" defaultMessage="Compare" />
            </Button>
          )}
        </DatePickerDropdown>

        <Button
          size={"xs"}
          variant={
            value.activeView === "conversionTimeDistribution"
              ? "filled"
              : colorScheme === "dark"
              ? "default"
              : "light"
          }
          onClick={() => handleLinkClick("conversionTimeDistribution")}>
          Conversion Time Distribution
        </Button>
      </Group>
    </>
  );
};

export default DetailViewMenuGroup;
