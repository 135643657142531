import ReactEChartsCore from "echarts-for-react/lib/core";
import {
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
  GeoComponent
} from "echarts/components";
import { MapChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { WorldCountries } from "./WorldCountries";
import { pink, purple, purpleLightest } from "@/style/Colors";
import * as echarts from "echarts/core";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { getCountryName } from "@/lib/utils/Locale";
import { Box } from "@mantine/core";

echarts.use([
  TitleComponent,
  TooltipComponent,
  CanvasRenderer,
  UniversalTransition,
  VisualMapComponent,
  GeoComponent,
  MapChart
]);
// @ts-ignore
echarts.registerMap("world", WorldCountries);
interface ITopLocationsMap {
  list: any;
}
export const TopLocationsMap = ({ list }: ITopLocationsMap) => {
  const getMapOptions = () => {
    return {
      tooltip: {
        trigger: "item",
        formatter: (params: any) => {
          console.log(params);
          return `<span class="font-bold">${params.name}:</span> ${
            params.value ? numberToCommas(params.value) : 0
          }`;
        }
      },
      visualMap: {
        min: 0,
        max: Math.max(...list.map((item: any) => item.source_with_value.value)),
        text: ["High", "Low"],
        realtime: false,
        calculable: true,
        inRange: {
          color: [pink, purpleLightest, purple]
        },
        show: false
      },

      series: [
        {
          type: "map",
          map: "world",
          // Set the item style for the map data items

          data: list.map((item: any) => {
            return {
              name: item.location_source === "USA" ? "USA" : getCountryName(item.location_source),
              value: item.source_with_value.value,
              itemStyle: {
                // Set the emphasis style
                emphasis: {
                  // Set the emphasis color
                  color: purpleLightest,
                  // Set the emphasis opacity
                  opacity: 0.8,
                  // Set the emphasis border width
                  borderWidth: 1
                }
              }
            };
          })
        }
      ]
    };
  };
  return (
    <>
      <Box mt={64} h={"26rem"}>
        <ReactEChartsCore option={getMapOptions()} echarts={echarts} />
      </Box>
    </>
  );
};
