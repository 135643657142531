/* eslint-disable react-hooks/exhaustive-deps */
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Title,
  Avatar,
  Text,
  Accordion,
  SegmentedControl,
  Center,
  Button,
  Group,
  Stack,
  Paper,
  useMantineTheme,
  ActionIcon,
  TextInput,
  ScrollArea
} from "@mantine/core";
import useInsightHook from "@/hooks/useInsightHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { InsightsService } from "@/lib/services/InsightsService";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ConditionItem, InsightTemplateType } from "types/types.d";
import { InsightsSetupEvents } from "../InsightsSetupEvents/InsightsSetupEvents";
import { INSIGHT_FILTER_EVENTS, PLAN_TYPES } from "@/lib/utils/Constants";
import useConditionsValidityHook from "@/hooks/useConditionsValidityHook";
import { WorkspaceMiscService } from "@/lib/services/WorkspaceMiscService";
import { useJourneyStore } from "@/stores/useJourneyStore";
import { TrackingPixel } from "../TrackingPixel/TrackingPixel";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import useFeatureAdoptionStore, { Feature, Module } from "@/stores/useFeatureAdoptionStore";
import { OnboardingModuleSetup } from "@/ui/pages/Insights/Components/Setup/OnboardingModuleSetup";
import { FeatureAdoptionService } from "@/lib/services/FeatureAdoptionService";
import { update } from "lodash";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface IAInsights {
  value: string;
  label: string;
}

interface FeatureAdoptionArray {
  name: string;
  conditions: ConditionItem[];
}

interface IAProductInsightsOnboardingProps {
  setShowOnboardingModal: (value: boolean) => void;
}

export const ProductInsightsOnboarding = ({
  setShowOnboardingModal
}: IAProductInsightsOnboardingProps) => {
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const { insightEvents, insightPinnedEvents, insightAttributes } = useInsightHook();
  const { stepsValidation } = useConditionsValidityHook();
  const theme = useGlobalMantineTheme();

  const [loading, setLoading] = useState<boolean>(true);
  const [fetchingEventsData, setFetchingEventsData] = useState<boolean>(false);
  const [connectedDataSource, setConnectedDataSource] = useState<boolean>(false);
  const [identifiedUsers, setIdentifiedUsers] = useState<boolean>(false);
  const [trackedBehaviors, setTrackedBehaviors] = useState<boolean>(false);

  // const [appURL, setAppURL] = useState("")
  // const [pageViewSelectSuggestions, setPageViewSelectSuggestions] = useState<any>([])
  const [reportType, setReportType] = useState("user");
  const [signedUpConditions, setSignedUpConditions] = useState<Array<ConditionItem>>([]);
  const [activeUserConditions, setActiveUserConditions] = useState<Array<ConditionItem>>([]);
  const [powerUserConditions, setPowerUserConditions] = useState<Array<ConditionItem>>([]);
  const [onboardingCompletionRateConditions, setOnboardingCompletionRateConditions] = useState<
    Array<ConditionItem>
  >([]);
  const [featuresArray, setFeaturesArray] = useState<Array<FeatureAdoptionArray>>([
    { name: "Feature 1", conditions: [] }
  ]);

  const [accordionValue, setAccordionValue] = useState("");
  const [isSignedUpEventSelected, setIsSignedUpEventSelected] = useState(false);
  const [isPowerUserEventSelected, setIsPowerUserEventSelected] = useState(false);
  const [isActiveUserEventSelected, setIsActiveUserEventSelected] = useState(false);
  const [isReportTypeSelected, setIsReportTypeSelected] = useState(false);
  const [isOnboardingCompletionRateSelected, setIsOnboardingCompletionRateSelected] =
    useState(false);

  const allEvents: IAInsights[] = [...insightEvents, ...insightPinnedEvents];

  // get possible default events for sign up
  const signedUpEventsRegex = /sign(?:.*)up|register|user(?:.*)(add|create)/gi;
  const defaultSignedUpEvents = allEvents.filter((item: any) =>
    item.value.match(signedUpEventsRegex)
  );

  // constant values needed in API call payloads
  const TRUE = "true";
  const BEFORE = "before";
  const AFTER = "after";
  const SEQUENTIAL_FUNNEL_ORDER = "strict_increase";
  const DAILY = "daily";
  const OR = "or";
  const journeyPathType = "user-based";
  const options = [
    { label: "Page URL", value: "pageview" },
    { label: "Custom Event", value: "event" },
    { label: "Pinned Event", value: "pinned_event" }
  ];
  const ACCORDION_VALUES = {
    APP_URL: "app_url",
    SIGNED_UP_EVENTS: "signed_up_events",
    ACTIVE_USER_EVENTS: "active_user_events",
    POWER_USER_EVENTS: "power_user_events",
    REPORT_TYPE: "report_type",
    FEATURE_ADOPTION_EVENTS: "feature_adoption_events",
    ONBOARDING_COMPLETION_RATE: "onboarding_completion_rate"
  };

  // default user identify event
  const userIdentifyEvent = "user_identify";
  const userIdentifyCondition: ConditionItem = {
    key: userIdentifyEvent,
    value: "",
    operator: TRUE,
    type: INSIGHT_FILTER_EVENTS.EVENT,
    label: userIdentifyEvent
  };

  const { isStarterPlan } = useWorkspaceUtilityHook();
  const isJourneysUnlocked =
    isStarterPlan || activeWorkspace.organization.plan.name.includes(PLAN_TYPES.PREMIUM_LEGACY);
  const [setJourneyStore, createJourney] = useJourneyStore((state) => [
    state.setJourneyStore,
    state.createJourney
  ]);

  const [modules, resetModules, addModule, setOnboardingFeatureAdoption] = useFeatureAdoptionStore(
    (state) => [
      state.modules,
      state.resetModules,
      state.addModule,
      state.setOnboardingFeatureAdoption
    ]
  );

  const featureAdoptionService = new FeatureAdoptionService();
  // const handlePageViewSuggestions = () => {
  // new InsightsService()
  //     .suggestions(activeWorkspace.id, "page_url")
  //     .then(res => {
  //       setPageViewSelectSuggestions(res.data)
  //     })
  //     .catch(err => {
  //       setPageViewSelectSuggestions([])
  //     })
  // }

  // get event data for workspace
  const getInsightsEventsOnboardingData = async () => {
    new WorkspaceMiscService()
      .insightsEventsOnboardingData(activeWorkspace.id)
      .then((res) => {
        if (res.data && res.data.success) {
          setConnectedDataSource(res.data.connected_data_source);
          setIdentifiedUsers(res.data.identified_users_or_companies);
          setTrackedBehaviors(res.data.tracking_behaviours);
        }
        setLoading(false);
        setFetchingEventsData(false);
      })
      .catch((err) => {
        setLoading(false);
        setFetchingEventsData(false);
      });
  };

  // set insights onboarding to true in workspace
  const insightsOnboardingCompleted = async (value: boolean) => {
    setLoading(true);
    new WorkspaceMiscService()
      .insightsOnboardingCompleted(activeWorkspace.id, value)
      .then((res) => {
        if (res.data) {
          activeWorkspace.is_insights_onboarding_completed = res.data.data;
          setActiveWorkspace(activeWorkspace);
          setShowOnboardingModal(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleReportsCreate = async () => {
    // validate all inputs
    // user can skip inputs but the existing inputs should be valid
    const areAllEventsValid =
      stepsValidation(signedUpConditions, 0, 10) &&
      stepsValidation(activeUserConditions, 0, 10) &&
      stepsValidation(powerUserConditions, 0, 10) &&
      // user can skip the onboarding completion rate step
      // but if conditions are added, they should be minimum 2
      (onboardingCompletionRateConditions.length === 0 ||
        stepsValidation(onboardingCompletionRateConditions, 2, 10)) &&
      // user can have 0 features
      // but if features are added, conditions should be valid
      modules.every((module) => {
        return module.features.every((feature) => {
          return stepsValidation(feature.conditions.feature_events_conditions, 0, 10);
        });
      });

    if (!areAllEventsValid) {
      return;
    }

    setLoading(true);

    const insightsService = new InsightsService();

    const powerUserInsightType: InsightTemplateType = "power_users";
    const activeUserInsightType: InsightTemplateType = "active_users";
    const featureAdoptionInsightType: InsightTemplateType = "feature_adoption";
    const slippingAwayInsightType: InsightTemplateType = "slipping_away_users";
    const userRetentionInsightType: InsightTemplateType = "user_retention";
    const onboardingCompletionRateInsightType: InsightTemplateType = "onboarding_completion_rate";

    const powerUsersInsightPayload = {
      type: powerUserInsightType,
      name: "Power Users",
      conditions: {
        power_user_conditions: powerUserConditions,
        power_user_ratio: 5
      }
    };

    const activeUsersInsightPayload = {
      type: activeUserInsightType,
      name: "Active Users",
      conditions: activeUserConditions
    };

    const userRetentionPayload = {
      name: "Logged in users retention",
      type: userRetentionInsightType,
      conditions: {
        retention_signed_up_conditions: signedUpConditions,
        retention_signed_up_condition_type: OR,
        retention_conditions: [userIdentifyCondition],
        retention_condition_type: OR,
        retention_frequency: DAILY
      }
    };

    const slippingAwayUsersPayload = {
      name: "Users who have not logged in",
      type: slippingAwayInsightType,
      conditions: {
        slipped_away_activity_conditions: [userIdentifyCondition]
      }
    };

    const onboardingCompletionRatePayload = {
      name: "Onboarding completion rate",
      type: onboardingCompletionRateInsightType,
      conditions: {
        funnel_conditions: onboardingCompletionRateConditions,
        funnel_order: SEQUENTIAL_FUNNEL_ORDER
      }
    };

    const getOneSignedUpEventOrPageView = (conditions: ConditionItem[]) => {
      const eventCondition = conditions.find(
        (condition) => condition.type === INSIGHT_FILTER_EVENTS.EVENT
      );
      if (eventCondition) {
        return {
          type: INSIGHT_FILTER_EVENTS.EVENT,
          value: eventCondition.key
        };
      }

      const pageViewCondition = conditions.find(
        (condition) => condition.type === INSIGHT_FILTER_EVENTS.PAGEVIEW
      );
      if (pageViewCondition) {
        return {
          type: INSIGHT_FILTER_EVENTS.PAGEVIEW,
          value: pageViewCondition.key
        };
      }

      return null;
    };

    const { type: signedUpEventType, value: signedUpEventValue } =
      getOneSignedUpEventOrPageView(signedUpConditions) || {};

    const beforeSignedUpJourney = {
      id: "before-signed-up",
      name: "Before Signed-up",
      description: "What users did before signing up",
      flow: BEFORE,
      pathType: journeyPathType,
      condition: {
        key: "",
        value: signedUpEventValue,
        operator: "",
        type: signedUpEventType,
        label: ""
      },
      exclusionConditions: [],
      groupingRules: []
    };

    const afterSignedUpJourney = {
      id: "after-signed-up",
      name: "After Signed-up",
      description: "What users did after signing up",
      flow: AFTER,
      pathType: journeyPathType,
      condition: {
        key: "",
        value: signedUpEventValue,
        operator: "",
        type: signedUpEventType,
        label: ""
      },
      exclusionConditions: [],
      groupingRules: []
    };

    try {
      powerUserConditions.length > 0 &&
        (await insightsService.create(activeWorkspace.id, powerUsersInsightPayload));
      activeUserConditions.length > 0 &&
        (await insightsService.create(activeWorkspace.id, activeUsersInsightPayload));

      if (
        modules.length > 0 &&
        modules.some((module) => module.features && module.features.length > 0)
      ) {
        const moduleToCreate = modules[0];
        await featureAdoptionService
          .bulkCreateModuleAndFeatures(activeWorkspace.id, moduleToCreate)
          .then((res) => {
            if (res.data) {
              const apiResponse = res.data;
              resetModules();
              addModule({
                id: apiResponse.id,
                name: apiResponse.name,
                features: apiResponse.features,
                description: apiResponse.description,
                is_default: apiResponse.is_default,
                is_new: false,
                is_active: false,
                is_saved: true
              });
            }
          })
          .catch((err) => {})
          .finally(() => {});
      }

      if (signedUpConditions.length > 0) {
        if (isJourneysUnlocked && signedUpEventValue) {
          // journey store set state and create journey
          setJourneyStore(beforeSignedUpJourney);
          await createJourney(activeWorkspace.id);
          setJourneyStore(afterSignedUpJourney);
          await createJourney(activeWorkspace.id);
        }
      }
      if (onboardingCompletionRateConditions.length > 1) {
        await insightsService.create(activeWorkspace.id, onboardingCompletionRatePayload);
      }

      await insightsService.create(activeWorkspace.id, slippingAwayUsersPayload);
      await insightsService.create(activeWorkspace.id, userRetentionPayload);

      // set the report preference in the workspace level
      if (!isStarterPlan) {
        new WorkspaceMiscService()
          .reportingInsightsPreference(activeWorkspace.id, reportType)
          .then((res) => {
            if (res.data) {
              activeWorkspace.reporting_insights_preference = res.data.data;
              setActiveWorkspace(activeWorkspace);
            }
          })
          .catch((err) => err);
      }

      setOnboardingFeatureAdoption(true);
      await insightsOnboardingCompleted(true);

      toast.success("Product Insights created successfully!");
    } catch (error) {
      toast.error("Something went wrong. Please try submitting the form again.");
      setLoading(false);
    }
  };

  const getModulesAndFeatures = async () => {
    await featureAdoptionService
      .getFeatures(activeWorkspace.id)
      .then((res) => {
        if (res.data) {
          const apiResponse = res.data;
          if (apiResponse.length === 0) {
            resetModules();
          }
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  // set form default values once the events are loaded from insight hook
  useEffect(() => {
    if (defaultSignedUpEvents.length > 0) {
      const defaultSignedUpConditions = defaultSignedUpEvents.map((event: any) => {
        return {
          key: event.value,
          value: "",
          operator: TRUE,
          type: INSIGHT_FILTER_EVENTS.EVENT,
          label: event.label
        };
      });

      setSignedUpConditions(defaultSignedUpConditions);
    }

    const defaultActiveUserConditions = [userIdentifyCondition];
    setActiveUserConditions(defaultActiveUserConditions);
    setPowerUserConditions(defaultActiveUserConditions);
  }, [insightEvents, insightPinnedEvents]);

  useEffect(() => {
    // handlePageViewSuggestions()
    setLoading(true);
    getInsightsEventsOnboardingData();
    getModulesAndFeatures();
  }, []);

  const handleSkip = async () => {
    await insightsOnboardingCompleted(true);
  };

  const handleAccordionValueChange = (value: string) => {
    // these accordions have default values
    // so even if the user does not select anything
    // we should show the field as completed
    if (value === ACCORDION_VALUES.SIGNED_UP_EVENTS) {
      signedUpConditions.length > 0 && setIsSignedUpEventSelected(true);
    } else if (value === ACCORDION_VALUES.ACTIVE_USER_EVENTS) {
      activeUserConditions.length > 0 && setIsActiveUserEventSelected(true);
    } else if (value === ACCORDION_VALUES.POWER_USER_EVENTS) {
      powerUserConditions.length > 0 && setIsPowerUserEventSelected(true);
    } else if (value === ACCORDION_VALUES.REPORT_TYPE) {
      reportType && setIsReportTypeSelected(true);
    }

    setAccordionValue(value);
  };

  const handleRefreshClick = () => {
    setFetchingEventsData(true);
    getInsightsEventsOnboardingData().then(() => {
      if (!connectedDataSource) {
        toast.error("Data source is not connected yet");
      } else if (!identifiedUsers) {
        toast.error("User data has not been sent yet");
      } else if (!trackedBehaviors) {
        toast.error("No event data sent yet");
      }
    });
  };

  const handleModuleAdd = () => {
    const tempRandomUUID = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) =>
      c === "x" ? ((Math.random() * 16) | 0).toString(16) : (0x8 | (Math.random() * 4)).toString(16)
    );
    addModule({
      id: tempRandomUUID,
      name: "",
      features: [],
      description: "",
      is_default: true,
      is_new: true,
      is_active: true,
      is_saved: false
    });
  };

  const checkModuleConditions = () => {
    return (
      modules.length > 0 &&
      modules.some((module) => module.features && module.features.length > 0) &&
      modules.every(
        (module) =>
          module.features &&
          module.features.every(
            (feature) =>
              feature.conditions &&
              feature.conditions.feature_events_conditions &&
              Array.isArray(feature.conditions.feature_events_conditions) &&
              feature.conditions.feature_events_conditions.length > 0 &&
              feature.is_saved === true
          )
      )
    );
  };

  return (
    <Box
      component="div"
      h={"100vh"}
      w={"100%"}
      pos={"absolute"}
      style={{
        zIndex: 5000,
        display: "flex",
        justifyContent: "center"
        // alignItems: "center"
      }}
      sx={{
        backgroundColor:
          theme.colorScheme === "dark" ? "rgba(26, 27, 30, 0.75)" : "rgba(242, 244, 246, 0.85)",
        backgroundOpacity: 0.85
      }}>
      <Paper
        w={"860px"}
        radius={"lg"}
        p={"lg"}
        pos="fixed"
        sx={{
          backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : "#FFF",
          border: "1px solid",
          borderColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#FFF"
        }}>
        <ScrollArea h={650} w={800}>
          {loading ? (
            <LoadingComponent />
          ) : !connectedDataSource || !identifiedUsers || !trackedBehaviors ? (
            <Box mb={"lg"} w={800}>
              <Title order={4} mb="lg" ta="center">
                Install tracking code
              </Title>
              <TrackingPixel
                workspaceId={activeWorkspace.identifier}
                connectedDataSource={connectedDataSource}
                identifiedUsers={identifiedUsers}
                trackedBehaviors={trackedBehaviors}
              />
              <Group justify="center" mt="xs">
                <Button
                  onClick={handleRefreshClick}
                  loading={fetchingEventsData}
                  disabled={fetchingEventsData}>
                  {connectedDataSource ? "Check for Events" : "Check Installation"}
                </Button>
              </Group>
            </Box>
          ) : (
            <>
              <Title className="text-center" order={4}>
                Get Started with Product Insights
              </Title>
              <Accordion
                mt={32}
                variant="separated"
                value={accordionValue}
                onChange={handleAccordionValueChange}>
                {/* <Accordion.Item value={ACCORDION_VALUES.APP_URL}>
                <Accordion.Control>
                  <div className="flex items-center">
                    <Avatar
                      size="md"
                      color={appURL ? "teal.7" : "purple"}
                      radius="xl"
                      className="mr-4 font-bold"
                    >
                      1{" "}
                    </Avatar>
                    <Text className="flex-grow" fz="md">
                      Select your app URL which people access by signing-in.
                    </Text>
                  </div>
                </Accordion.Control>
                <Accordion.Panel>
                  <Autocomplete
                    placeholder="https://app.abc.com"
                    data={pageViewSelectSuggestions.map((suggestion: any) => {
                      return { value: suggestion, label: suggestion }
                    })}
                    value={appURL}
                    onChange={setAppURL}
                  />
                </Accordion.Panel>
              </Accordion.Item> */}

                <Accordion.Item value={ACCORDION_VALUES.SIGNED_UP_EVENTS}>
                  <Accordion.Control>
                    <div className="flex items-center">
                      <Avatar
                        size="md"
                        color={isSignedUpEventSelected ? "teal.7" : "purple"}
                        radius="xl"
                        className="mr-4 font-bold">
                        1{" "}
                      </Avatar>
                      <Text className="flex-grow" fz="md">
                        Select event(s) for classifying a user as signed-up
                      </Text>
                    </div>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <InsightsSetupEvents
                      conditions={signedUpConditions}
                      setConditions={(value: ConditionItem[]) => {
                        setSignedUpConditions(value);
                        setIsSignedUpEventSelected(value.length > 0);
                      }}
                      conditionType={OR}
                      attributesOptions={insightAttributes}
                      eventsOptions={insightEvents}
                      pinnedEventOptions={insightPinnedEvents}
                      options={options}
                    />
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value={ACCORDION_VALUES.ACTIVE_USER_EVENTS}>
                  <Accordion.Control>
                    <div className="flex items-center">
                      <Avatar
                        size="md"
                        color={isActiveUserEventSelected ? "teal.7" : "purple"}
                        radius="xl"
                        className="mr-4 font-bold">
                        2{" "}
                      </Avatar>
                      <Text className="flex-grow" fz="md">
                        Select event(s) for classifying a user as an active user
                      </Text>
                    </div>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <InsightsSetupEvents
                      conditions={activeUserConditions}
                      setConditions={(value: ConditionItem[]) => {
                        setActiveUserConditions(value);
                        setIsActiveUserEventSelected(value.length > 0);
                      }}
                      conditionType={OR}
                      attributesOptions={insightAttributes}
                      eventsOptions={insightEvents}
                      pinnedEventOptions={insightPinnedEvents}
                      options={options}
                    />
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value={ACCORDION_VALUES.POWER_USER_EVENTS}>
                  <Accordion.Control>
                    <div className="flex items-center">
                      <Avatar
                        size="md"
                        color={isPowerUserEventSelected ? "teal.7" : "purple"}
                        radius="xl"
                        className="mr-4 font-bold">
                        3{" "}
                      </Avatar>
                      <Text className="flex-grow" fz="md">
                        Select event(s) for classifying a user as a power user
                      </Text>
                    </div>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <InsightsSetupEvents
                      conditions={powerUserConditions}
                      setConditions={(value: ConditionItem[]) => {
                        setPowerUserConditions(value);
                        setIsPowerUserEventSelected(value.length > 0);
                      }}
                      conditionType={OR}
                      attributesOptions={insightAttributes}
                      eventsOptions={insightEvents}
                      pinnedEventOptions={insightPinnedEvents}
                      options={options}
                    />
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value={ACCORDION_VALUES.FEATURE_ADOPTION_EVENTS}>
                  <Accordion.Control>
                    <div className="flex items-center">
                      <Avatar
                        size="md"
                        color={checkModuleConditions() === true ? "teal.7" : "purple"}
                        radius="xl"
                        className="mr-4 font-bold">
                        4{" "}
                      </Avatar>
                      <Text className="flex-grow" fz="md">
                        Select event(s) to measure feature adoption
                      </Text>
                    </div>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Stack spacing="xs" mb="xs">
                      {modules.map((module, moduleIndex) => (
                        <OnboardingModuleSetup
                          key={moduleIndex}
                          module={module}
                          moduleIndex={moduleIndex}
                        />
                      ))}
                      <Button
                        className="!font-medium "
                        color={theme.colorScheme === "dark" ? theme.colors.dark[5] : "gray.9"}
                        variant={theme.colorScheme === "dark" ? "subtle" : "light"}
                        size="sm"
                        leftSection={<FontAwesomeIcon icon={regular("circle-plus")} />}
                        onClick={handleModuleAdd}
                        disabled={modules.length > 0}>
                        Add Module
                      </Button>
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value={ACCORDION_VALUES.ONBOARDING_COMPLETION_RATE}>
                  <Accordion.Control>
                    <div className="flex items-center">
                      <Avatar
                        size="md"
                        color={isOnboardingCompletionRateSelected ? "teal.7" : "purple"}
                        radius="xl"
                        className="mr-4 font-bold">
                        5{" "}
                      </Avatar>
                      <Text className="flex-grow" fz="md">
                        Select the event(s) that indicate user onboarding
                      </Text>
                    </div>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz="sm" className="mb-4" c="dimmed">
                      Add at least two onboarding steps to track your onboarding completion rate.
                    </Text>
                    <InsightsSetupEvents
                      conditions={onboardingCompletionRateConditions}
                      setConditions={(value: ConditionItem[]) => {
                        setOnboardingCompletionRateConditions(value);
                        value.length > 1 && value
                          ? setIsOnboardingCompletionRateSelected(true)
                          : setIsOnboardingCompletionRateSelected(false);
                      }}
                      conditionType={OR}
                      attributesOptions={insightAttributes}
                      eventsOptions={insightEvents}
                      pinnedEventOptions={insightPinnedEvents}
                      options={options}
                    />
                  </Accordion.Panel>
                </Accordion.Item>

                {!isStarterPlan && (
                  <Accordion.Item value={ACCORDION_VALUES.REPORT_TYPE}>
                    <Accordion.Control>
                      <div className="flex items-center">
                        <Avatar
                          size="md"
                          color={isReportTypeSelected ? "teal.7" : "purple"}
                          radius="xl"
                          className="mr-4 font-bold">
                          6{" "}
                        </Avatar>
                        <Text className="flex-grow" fz="md">
                          What are you more interested in?
                        </Text>
                      </div>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <SegmentedControl
                        fullWidth
                        data={[
                          {
                            value: "user",
                            label: (
                              <Center>
                                <FontAwesomeIcon icon={regular("user")}></FontAwesomeIcon>
                                <Box ml={8}>User-level reports</Box>
                              </Center>
                            )
                          },
                          {
                            value: "company",
                            label: (
                              <Center>
                                <FontAwesomeIcon icon={regular("building")}></FontAwesomeIcon>
                                <Box ml={8}>Company-level reports</Box>
                              </Center>
                            )
                          }
                        ]}
                        value={reportType}
                        onChange={setReportType}
                      />
                    </Accordion.Panel>
                  </Accordion.Item>
                )}
              </Accordion>

              <Group align="center" justify="center" mt="xs">
                <Button variant="subtle" color="gray.6" className="mt-8" onClick={handleSkip}>
                  Skip &amp; Finish Later
                </Button>
                <Button className="mt-8 ml-4" onClick={handleReportsCreate}>
                  Generate Insights
                </Button>
              </Group>
            </>
          )}
        </ScrollArea>
      </Paper>
    </Box>
  );
};
