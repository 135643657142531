/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { FeatureAdoptionService } from "@/lib/services/FeatureAdoptionService";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import { useSegmentDropdownStore } from "@/stores/useSegmentDropdownStore";
import { useSegmentListStore } from "@/stores/useSegmentListStore";
import { IReportType } from "@/types/types.d";
import {
  TrendsGraphBody,
  TrendsGraphContainer,
  TrendsGraphHeader,
  TrendsGraphHeaderH3,
  TrendsGraphHeaderLeft,
  TrendsGraphHeaderParagraph
} from "@/ui/components/App/TrendsGraph/TrendsGraph.style";
import { DailyFeatureAdoptionHistogram } from "@/ui/pages/Insights/Trends/FeatureAdoption/Components/DailyFeatureAdoptionHistogram";
import { Box, Grid, useMantineTheme } from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import UserOrCompanyDetailViewCard from "../../Components/FeatureAdoption/UserOrCompanyDetailViewCard";
import { FeatureEngagementHistogram } from "../../Components/Graphs/FeatureEngagementHistogram";
import { PerformanceFunnelBarChart } from "../../Components/Graphs/PerformanceFunnelGraph";
import UserOrCompanyDetailView from "../../Components/Modals/UserOrCompanyDetailView";
import { AdoptionModule } from "../InsightsDashboardFeatureAdoption";
import { Feature } from "@/stores/useFeatureAdoptionStore";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";

interface IFeatureDetail {
  adoptionReportData: AdoptionModule[];
  isFeaturesReportLoading: string;
  reportType: IReportType;
  moduleId: string;
  featureId: string;
  dropdownFilterDate: any;
  filters: any;
  activeFeature: Feature | null;
}

export interface IFunnelPerformance {
  count: number;
  conversion: number | null;
  dropoff: number | null;
  name: string;
  subtitle: string;
}

export interface IUserOrCompanyData {
  id: string;
  name: string;
  email?: string;
  feature_usage: number;
  funnel_level: string;
  feature_first_used: string;
  feature_last_used: string;
}

const FeatureDetail: React.FC<IFeatureDetail> = ({
  adoptionReportData,
  isFeaturesReportLoading,
  reportType,
  moduleId,
  featureId,
  dropdownFilterDate,
  filters,
  activeFeature
}) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [performanceFunnelData, setPerformanceFunnelData] = useState<IFunnelPerformance[]>([]);
  const [engagementHistogram, setEngagementHistogram] = useState<number[]>([]);

  const [isUserOrCompanyListLoading, setIsUserOrCompanyListLoading] = useState("idle");
  const [activeUserOrCompanyList, setActiveUserOrCompanyList] = useState<IUserOrCompanyData[]>([]);
  const [awareUserOrCompanyList, setAwareUserOrCompanyList] = useState<IUserOrCompanyData[]>([]);
  const [adoptedUserOrCompanyList, setAdoptedUserOrCompanyList] = useState<IUserOrCompanyData[]>(
    []
  );
  const [retainedUserOrCompanyList, setRetainedUserOrCompanyList] = useState<IUserOrCompanyData[]>(
    []
  );

  // NOTE: for detailed view
  const [activeDetailViewName, setActiveDetailViewName] = useState<string>("");

  const [showUserOrCompanyDetailedViewModal, setShowUserOrCompanyDetailedViewModal] =
    useState(false);

  // NOTE: for segment dropdown menu
  /**
   * Segments list store.
   */
  const segments = useSegmentListStore((state) => state.segments);

  /** Segments dropdown store */
  const selected = useSegmentDropdownStore((state) => state.id);

  const featureService = new FeatureAdoptionService();

  const getUserOrCompanyDetails = async (funnelLevel: string) => {
    setIsUserOrCompanyListLoading("loading");
    setActiveUserOrCompanyList([]);
    setAwareUserOrCompanyList([]);
    setAdoptedUserOrCompanyList([]);
    setRetainedUserOrCompanyList([]);

    await featureService
      .getUsersList(
        activeWorkspace.id,
        featureId,
        funnelLevel, // Can be aware, adopted or retained
        reportType,
        "list",
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate,
        1,
        5,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        if (res.data) {
          const apiResponse = res.data;

          const dataToShow: IUserOrCompanyData[] = apiResponse;

          if (funnelLevel === "aware") {
            setAwareUserOrCompanyList(dataToShow);
          } else if (funnelLevel === "adopted") {
            setAdoptedUserOrCompanyList(dataToShow);
          } else if (funnelLevel === "retained") {
            setRetainedUserOrCompanyList(dataToShow);
          } else if (funnelLevel === "segment_active") {
            setActiveUserOrCompanyList(dataToShow);
          } else {
            setActiveUserOrCompanyList([]);
            setAwareUserOrCompanyList([]);
            setAdoptedUserOrCompanyList([]);
            setRetainedUserOrCompanyList([]);
          }
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsUserOrCompanyListLoading("loaded");
      });
  };

  useEffect(() => {
    if (adoptionReportData.length > 0 && adoptionReportData[0].features[0]) {
      // Set Performance Funnel and Engagement Histogram Data
      const feature = adoptionReportData[0].features[0];
      setEngagementHistogram(feature.engagement_histogram);

      const selectedSegment = segments.find((segment: any) => segment.id === selected);

      const data = [
        {
          count: feature.active_users,
          conversion: feature.awareness_ratio,
          dropoff: 100 - feature.awareness_ratio,
          name: selectedSegment
            ? selectedSegment.name
            : `All Active ${reportType === "user" ? "Users" : "Companies"}`,
          subtitle: "(Segment)"
        },
        {
          count: feature.awareness,
          conversion: feature.adoption_ratio,
          dropoff: 100 - feature.adoption_ratio,
          name: "Used",
          subtitle: "1-2 events"
        },
        {
          count: feature.adoption,
          conversion: feature.retention_ratio,
          dropoff: 100 - feature.retention_ratio,
          name: "Adopted",
          subtitle: `${activeFeature?.adoption_rate || 0}+ ${
            activeFeature?.adoption_rate === 1 ? "event" : "events"
          }`
        },
        {
          count: feature.retention,
          conversion: null,
          dropoff: null,
          name: "Retained",
          subtitle: `Within  ${
            activeFeature?.retention_period === "weekly"
              ? "7 days"
              : activeFeature?.retention_period === "bi-weekly"
              ? "14 days"
              : "a month" || 0
          }`
        }
      ];
      setPerformanceFunnelData(data);
    }
  }, [adoptionReportData]);

  useEffect(() => {
    setIsUserOrCompanyListLoading("loading");
    getUserOrCompanyDetails("aware");
    getUserOrCompanyDetails("adopted");
    getUserOrCompanyDetails("retained");
  }, [reportType, dropdownFilterDate, filters, moduleId, featureId]);


  return (
    <>
      {/* [Feature Criteria] */}

      {/* PERFORMANCE FUNNEL */}
      <InsightSection
        title="Performance"
        description="This graph tracks user engagement, showing the number of users who tried it,
                  continued to use it, and consistently included it in their routine.">
        {isFeaturesReportLoading === "loading" ? (
          <Box pb={60}>
            <BarChartSkeleton />
          </Box>
        ) : (
          <Box pt={30}>
            <PerformanceFunnelBarChart bars={performanceFunnelData as any} />
          </Box>
        )}
      </InsightSection>

      {/* USER/COMPANY LIST */}
      <Box>
        <Grid gutter={16}>
          <>
            {["Used", "Adopted", "Retained"].map((card, index) => (
              <Grid.Col span={4} key={index}>
                <UserOrCompanyDetailViewCard
                  cardTitle={card}
                  reportType={reportType}
                  isUserOrCompanyListLoading={isUserOrCompanyListLoading}
                  userOrCompanyList={
                    card === "Used"
                      ? awareUserOrCompanyList
                      : card === "Adopted"
                      ? adoptedUserOrCompanyList
                      : retainedUserOrCompanyList
                  }
                  setActiveDetailViewName={setActiveDetailViewName}
                  setShowUserOrCompanyDetailedViewModal={setShowUserOrCompanyDetailedViewModal}
                />
              </Grid.Col>
            ))}
          </>
        </Grid>
      </Box>
      {/* Number of times feature used over time */}
      <DailyFeatureAdoptionHistogram
        featureId={featureId}
        fromDate={dropdownFilterDate.startDate}
        toDate={dropdownFilterDate.endDate}
        reportType={reportType}
      />

      {/* ENGAGEMENT HISTOGRAM */}
      <InsightSection
        title={"Engagement Histogram"}
        description="  The engagement histogram (distribution) of user engagement with the feature,
                considering both usage and frequency, but only for those users who meet the adoption
                criteria.">
        {isFeaturesReportLoading === "loading" ? (
          <Box pb={60}>
            <BarChartSkeleton />
          </Box>
        ) : (
          <FeatureEngagementHistogram
            reportType={reportType}
            engagementHistogram={engagementHistogram}
          />
        )}
      </InsightSection>
      <UserOrCompanyDetailView
        isModalOpen={showUserOrCompanyDetailedViewModal}
        setIsModalOpen={setShowUserOrCompanyDetailedViewModal}
        featureId={featureId}
        reportType={reportType}
        dropdownFilterDate={dropdownFilterDate}
        filters={filters}
        activeDetailViewName={activeDetailViewName}
      />
    </>
  );
};

export default FeatureDetail;
