import { Note } from "@/stores/useAnnotationNotesStore";
import axios from "./JWTService";

export class AnnotationNotesService {
  private readonly _endpoint;
  constructor() {
    this._endpoint = "/v1/annotation_notes";
  }

  getNotes = (
    ws_id: any,
    startDate: string,
    endDate: string,
    page: number = 1,
    limit: number = 100,
    user_id?: any
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      let reportURL = `${this._endpoint}/${ws_id}?`;
      startDate && endDate && (reportURL += `&start_date=${startDate}&end_date=${endDate}`);
      user_id && (reportURL += `&user_id=${user_id}`);
      reportURL += `&page=${page}&limit=${limit}`;
      axios
        .get(`${reportURL}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  createNote = (ws_id: any, data: Note): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      let reportURL = `${this._endpoint}/${ws_id}`;
      axios
        .post(`${reportURL}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  updateNote = (ws_id: any, id: string | number, data: Note): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      let reportURL = `${this._endpoint}/${ws_id}/${id}`;
      axios
        .put(`${reportURL}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  deleteNote = (workspace_id: string, id: string | number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${this._endpoint}/${workspace_id}/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
