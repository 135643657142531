import { endOfDay, format, startOfDay, subDays } from "date-fns";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { AttributionService } from "@/lib/services/AttributionService";
import { DATE_FORMAT } from "@/lib/utils/Constants";
import { useContext, useState } from "react";
import { AttributionItemDrilldownProps, AttributionItemProps, LoadingStateProps } from "@/types/types.d";

export default function useAttributionHook() {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  // Channel attribution state
  const [isAggregationLoading, setIsAggregationLoading] = useState<LoadingStateProps>("idle");
  const [aggregationData, setAggregationData] = useState<AttributionItemProps[]>([]);

  // Source attribution state
  const [isDrilledReportLoading, setIsDrilledReportLoading] = useState<LoadingStateProps>("idle");
  const [drilledReport, setDrilledReport] = useState<AttributionItemDrilldownProps[]>([]);

  // Fetch the attribution for channels (aggregated)
  const fetchAttributionAggregated = async (
    insightId: string,
    startDate = format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
    endDate = format(endOfDay(new Date()), DATE_FORMAT),
    lookbackWindow: number = 90
  ) => {
    setIsAggregationLoading("loading");
    const attributionService = new AttributionService();
    await attributionService
      .attribution(activeWorkspace.id, insightId, startDate, endDate, lookbackWindow)
      .then((res) => {
        if (res.data) {
          const data:any = res.data
          // loop through the data and add a unique key to each item
          data.forEach((item:any, index:number) => {
            item.key = index;
            item.id = index;
          });
          setAggregationData(res.data);
        } else {
          throw new Error("No attribution data available");
        }
      })
      .catch((err) => {
        setAggregationData([]);
      });
    setIsAggregationLoading("loaded");
  };

  // Fetch the attribution for sources (drilled down)
  const fetchDrilledAttributionReport = async (
    insightId: string,
    startDate = format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
    endDate = format(endOfDay(new Date()), DATE_FORMAT),
    lookbackWindow = 90
  ) => {
    setIsDrilledReportLoading("loading");
    const attributionService = new AttributionService();
    await attributionService
      .attribution(activeWorkspace.id, insightId, startDate, endDate, lookbackWindow, true)
      .then((res) => {
        if (res.data) {
          setDrilledReport(res.data);
        } else {
          throw new Error("No attribution source data available");
        }

        setIsDrilledReportLoading("loaded");
      })
      .catch((err) => {
        setDrilledReport([]);
        setIsDrilledReportLoading("idle");
      })


  };

  return {
    isAggregationLoading,
    aggregationData,
    fetchAttributionAggregated,
    isDrilledReportLoading,
    drilledReport,
    setDrilledReport,
    fetchDrilledAttributionReport,
  };
}
