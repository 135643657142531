/* eslint-disable react-hooks/exhaustive-deps */
import { useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { format } from "date-fns";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import cloneDeep from "lodash.clonedeep";
import React, { useEffect, useState } from "react";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

const PulseLineGraph = ({ data, color = "#2fa6d9", name, period = "day" }: any) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const CHART_OPTIONS = {
    animationDuration: 500,
    animationDurationUpdate: 500,
    color: [color],
    title: {
      text: ""
    },
    grid: {
      top: "10%",
      left: "5%",
      right: "5%",
      bottom: "10%",
      containLabel: true
    },
    tooltip: {
      appendToBody: true,
      borderColor: "#2C2E33",
      backgroundColor: "#101113",
      textStyle: {
        color: "#595c5f"
      },
      trigger: "axis",
      axisPointer: {
        type: "line",
        label: {
          formatter: (params: any) => {
            return period === "hour"
              ? format(new Date(params.value), "EEE, dd MMM yy, haa")
              : format(new Date(params.value), "eee, MMMM dd, yyyy");
          }
        }
      }
    },
    legend: {
      show: false,
      data: [name]
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {}
      }
    },

    xAxis: [
      {
        type: "time",
        axisLine: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        min: 0,
        type: "value",
        splitLine: true,
        alignTicks: true,
        axisLabel: {
          show: false
        },
        axisTick: {
          show: false,
          splitNumber: 4
        }
      }
    ],
    series: [
      {
        name: name,
        type: "line",
        lineStyle: {
          width: 2
        },
        showSymbol: false,
        emphasis: {
          focus: "series"
        },

        data: data
      }
    ]
  };
  console.log(CHART_OPTIONS);
  const [option, setOption] = useState<any>(CHART_OPTIONS);

  useEffect(() => {
    const deepCopy = cloneDeep(option);
    deepCopy.tooltip.borderColor =
      colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2];
    deepCopy.tooltip.backgroundColor = colorScheme === "dark" ? theme.colors.dark[9] : "#fff";
    deepCopy.tooltip.textStyle.color = colorScheme === "dark" ? "#A6A7AB" : "#595c5f";
    setOption(deepCopy);
  }, [colorScheme]);
  return (
    <>
      {option.series[0].data.length > 0 && (
        <div className="w-full">
          <ReactEChartsCore
            style={{ height: "1rem", width: "100px" }}
            echarts={echarts}
            option={option}
          />
        </div>
      )}
    </>
  );
};

const PulseLineGraphMemoized = React.memo(PulseLineGraph);
export { PulseLineGraphMemoized as PulseLineGraph };
