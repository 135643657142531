// @ts-ignore
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { MOBILE_BREAKPOINT_QUERY, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { EventsHeader } from "@/ui/components/App/Events/EventsHeader";
import AutoCapturedEvents from "@/ui/pages/Events/AutoCapturedEvents";
import CustomEvents from "@/ui/pages/Events/CustomEvents";
import EventsActivity from "@/ui/pages/Events/EventsActivity";
import FormsActivity from "@/ui/pages/Events/FormsActivity";
import { PinnedEvents } from "@/ui/pages/Events/PinnedEvents";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, Divider, Flex, NavLink, Paper, Stack, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useContext, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import AppLifecycleContext from "../../../lib/contexts/AppLifecycleContext";
import "./settings_navigation.scss";

// import { WorkspaceTrackingSetup } from "./WorkspaceSettings/WorkspaceTrackingSetup"

type SlugParams = {
  workspaceIdentifier: string;
};

const EventsNavigation = (props: any) => {
  const { workspaceIdentifier } = useParams<SlugParams>();
  const { hasRolesWith } = useWorkspaceUtilityHook();
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);
  const { isCollapsedLeftMenu } = useContext(AppLifecycleContext);
  useEffect(() => {
    document.title = "Events | Auto Captured | Usermaven";
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(workspaceIdentifier);
  return (
    <>
      <Box
        className="subnav-navigation"
        sx={{
          left: isMobileView
            ? "16px !important"
            : isCollapsedLeftMenu
            ? "55px !important"
            : "220px",
          transition: "all 0.3s ease"
        }}>
        <div className="container">
          <Box ml={8}>
            <Title mb="xs" order={5}>
              Configure Events
              {/* Events {isCollapsedLeftMenu ? "Closed" : "Navigation"} */}
            </Title>
            <Text>Current workspace</Text>
          </Box>
          <Divider my="md" />
          <Stack gap={4} fw={500}>
            <NavLink
              className="navigation-link"
              href="#required-for-focus"
              leftSection={
                <Flex w={16} justify={"center"}>
                  <FontAwesomeIcon icon={regular("wave-pulse")} />
                </Flex>
              }
              label="Auto-captured"
              active={location.pathname.endsWith("/auto-captured")}
              onClick={() => {
                navigate(`/env/${workspaceIdentifier}/events/auto-captured`);
              }}></NavLink>
            <NavLink
              className="navigation-link"
              leftSection={
                <Flex w={16} justify={"center"}>
                  <FontAwesomeIcon icon={regular("thumbtack")} />
                </Flex>
              }
              label="Pinned events"
              active={location.pathname.endsWith("/pinned-events")}
              onClick={() => {
                navigate(`/env/${workspaceIdentifier}/events/pinned-events`);
              }}></NavLink>
            {hasRolesWith([WORKSPACE_MEMBER_ROLES.OWNER, WORKSPACE_MEMBER_ROLES.ADMINISTRATOR]) && (
              <NavLink
                className="navigation-link"
                leftSection={
                  <Flex w={16} justify={"center"}>
                    <FontAwesomeIcon icon={regular("brackets-curly")} />
                  </Flex>
                }
                label="Custom events"
                active={location.pathname.endsWith("/custom")}
                onClick={() => {
                  navigate(`/env/${workspaceIdentifier}/events/custom`);
                }}
              />
            )}
            {hasRolesWith([
              WORKSPACE_MEMBER_ROLES.OWNER,
              WORKSPACE_MEMBER_ROLES.MANAGER,
              WORKSPACE_MEMBER_ROLES.ADMINISTRATOR
            ]) && (
              <NavLink
                className="navigation-link"
                leftSection={
                  <Flex w={16} justify={"center"}>
                    <FontAwesomeIcon icon={regular("database")} />
                  </Flex>
                }
                label="Events activity"
                active={location.pathname.endsWith("/activity")}
                onClick={() => {
                  navigate(`/env/${workspaceIdentifier}/events/activity`);
                }}></NavLink>
            )}
          </Stack>
        </div>
      </Box>

      <Box className="subnav-content-container">
        <EventsHeader />
        <Paper mt={"xl"} p="md" shadow="md" withBorder>
          <Routes>
            <Route path={`auto-captured`} element={<AutoCapturedEvents />}></Route>
            <Route path={`pinned-events`} element={<PinnedEvents />}></Route>
            <Route path={`custom`} element={<CustomEvents />}></Route>

            <Route path={`activity`} element={<EventsActivity />}></Route>
            <Route path={`forms`} element={<FormsActivity />}></Route>
            <Route path="*" element={<CustomEvents />}></Route>
          </Routes>
        </Paper>
      </Box>
    </>
  );
};

export default EventsNavigation;
