import { Drawer, Modal } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { Oval } from "react-loader-spinner";

import { loaderColor } from "@/style/Colors";
import "./SidePanel.css";

export interface Props {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  opened: boolean;
  loading?: boolean;
  onCancel?: any;
  onConfirm?: any;
  fullWidth?: boolean;
  hideOverflow?: boolean;
}

export const SidePanel: React.FC<Props> = ({
  children,
  title,
  opened,
  loading,
  onCancel,
  fullWidth = false,
  hideOverflow = false
}) => {
  const [open, setOpen] = React.useState(opened ? opened : false);

  useEffect((): any => {
    setOpen(opened);
    // if (opened) {
    //   document.body.style.overflow = "hidden";
    // } else {
    //   document.body.style.overflow = "unset";
    // }
  }, [opened]);

  function stopPropagation(e: React.MouseEvent) {
    e.stopPropagation();
  }

  function handleOpenChange(open: boolean) {
    if (opened !== undefined && !open) {
      // controlled component behaviour
      onCancel();
    } else {
      // un-controlled component behaviour
      setOpen(open);
    }
  }

  const closeDropdown = () => {
    if (open) {
      setOpen(false);
      console.log("outside clicked side panel.", open);
    }
  };
  const matches = useMediaQuery("(min-width: 900px)");

  const getTitle = () => {
    if (typeof title === "string") {
      return <h2 className="font-medium text-2xl">{title}</h2>;
    } else {
      return title;
    }
  };

  return (
    <>
      <Drawer
        // zIndex={900}
        position={"right"}
        styles={{}}
        // styles={(theme) => ({
        //   inner: {
        //     padding: 0
        //   },
        //   modal: {
        //     marginLeft: "auto",
        //     minHeight: "100%",
        //     maxWidth: fullWidth ? "100%" : "1300px",
        //     boxShadow:
        //       theme.colorScheme === "dark"
        //         ? "0 0 #0000, 0 0 #0000, 0 0 0 1px rgba(107,144,192,.15),0 4px 8px" +
        //           " rgba(26,27,30,.15),0 6px 24px 10px rgba(26,27,30,.2)"
        //         : "0 0 #0000, 0 0 #0000, 0 0 0 1px rgba(107,144,192,.15),0 4px 8px" +
        //           " rgba(89,97,104,.15),0 6px 24px 10px rgba(89,97,104,.2)",
        //     padding: matches ? "20px 35px !important" : undefined,
        //     background: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#fcfcfc"
        //   },
        //   close: {
        //     position: "absolute",
        //     left: matches ? "-18px" : undefined,
        //     right: matches ? undefined : "5px",
        //     backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[4] : "#fff",
        //     borderRadius: "9999px",
        //     height: "35px",
        //     width: "35px",
        //     color: theme.colorScheme === "dark" ? theme.colors.dark[1] : "rgba(89,97,104,1)",
        //     fontWeight: 800,
        //     boxShadow:
        //       theme.colorScheme === "dark"
        //         ? "0 0 #0000, 0 0 #0000, 0 0 0 1px rgba(107,144,192,.15),0 4px 8px" +
        //           " rgba(26,27,30,.15),0 6px 24px 10px rgba(26,27,30,.2)"
        //         : "0 0 #0000, 0 0 #0000, 0 0 0 1px rgba(107,144,192,.15),0 4px 8px" +
        //           " rgba(89,97,104,.15),0 6px 24px 10px rgba(89,97,104,.2)"
        //   },
        //   body: {
        //     height: "100%"
        //   },
        //   header: {
        //     marginBottom: title ? "16px" : 0
        //   }
        // })}
        size={matches ? "calc(100vw - 220px)" : "100vw"}
        // overflow="outside"
        opened={open}
        title={getTitle()}
        onClose={() => handleOpenChange(!open)}
        // exitTransitionDuration={300}
        // transition={"slide-left"}
        // transitionTimingFunction="ease"
        withCloseButton={true}

        // closeOnEsc={true}
        // center
        // transitionDuration={200}
        // modalId="um-side-panel"
        // classNames={{
        //   overlay: "um-modal-overlay",
        //   modal: "top-0 right-0 m-0 block w-8/12 max-w-full	 h-screen bg-white float-right",
        //   //   overlayAnimationIn: "customEnterOverlayAnimation",
        //   //   overlayAnimationOut: "customLeaveOverlayAnimation",
        //   modalAnimationIn: "customEnterModalAnimation",
        //   modalAnimationOut: "customLeaveModalAnimation",
        // }}
      >
        <div
          className={`flex flex-col pb-4 h-full ${hideOverflow ? "" : "overflow-scroll"}`}
          id="um-side-panel">
          {loading ? (
            <>{children}</>
          ) : (
            <>
              <div className="flex flex-col items-center">
                <Oval color={loaderColor} height={16} width={16} />
              </div>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};
