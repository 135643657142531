import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mantine/core";
import { MOBILE_BREAKPOINT_QUERY } from "@/lib/utils/Constants";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mantine/hooks";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";

interface IExportButtonProps {
  /**
   * Callback function to be called when the export button is clicked
   */
  onClick: () => Promise<boolean>;

  /**
   * Specify the export button label
   */
  label?: string;

  /**
   * Loading state of the export button
   */
  loading?: boolean;
  /**
   * Disable state of the export button
   */
  disabled?: boolean;
  /**
   * Plan name to show in the toast message
   */
  planName?: string;
}

/**
 * Export button component that will be used to export data. This component will show the lock icon for the users
 * who are on the starter plan.
 *
 * Later, we will add a dialog to ask the user to upgrade the plan to export the data.
 * @returns
 */
export const ExportButton = ({
  onClick,
  label = "Export as CSV",
  loading = false,
  disabled = false,
  planName = "Premium"
}: IExportButtonProps) => {
  const { isStarterPlan } = useWorkspaceUtilityHook();
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  const onClickHandler = async () => {
    await toast.promise(onClick, {
      pending: "Preparing export...",
      success: "Export done!",
      error: "Error in exporting data"
    });
  };
  return (
    <>
      {isStarterPlan ? (
        <>
          <Button
            size="xs"
            // variant="gradient"
            onClick={() =>
              toast.error(`Please upgrade your plan to ${planName} to export data in CSV.`)
            }
            rightSection={<FontAwesomeIcon icon={regular("lock")} />}>
            {label}
          </Button>
        </>
      ) : (
        <>
          <Button
            // variant="gradient"
            onClick={onClickHandler}
            disabled={disabled}
            loading={loading}
            size={"xs"}>
            {label}
          </Button>
        </>
      )}
    </>
  );
};
