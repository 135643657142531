import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek
} from "date-fns";
import { DATE_FORMAT } from "@/lib/utils/Constants";
import { getCountryName } from "@/lib/utils/Locale";
import { LooseObject } from "types/types.d";
import { Tooltip } from "@mantine/core";
export const FILTER_LABELS: LooseObject = {
  goal: "Conversion Goal",
  source: "Source",
  channel: "Channel",
  page: "Page",
  entry_page: "Entry Page",
  exit_page: "Exit Page",
  country: "Country",
  city: "City",
  region: "Region",
  device_type: "Device Type",
  browser: "Browser",
  browser_version: "Browser Version",
  os: "Operating System",
  os_version: "OS Version",
  screen: "Screen size",
  utm_source: "UTM Source",
  utm_medium: "UTM Medium",
  utm_campaign: "UTM Campaign",
  utm_content: "UTM Content",
  utm_term: "UTM Term",
  event_type: "Event",
  referrer: "Referrer URL"
};

export const OPERATOR_LABELS = {
  EQUALS: "is",
  NOT_EQUALS: "is not",
  CONTAINS: "contains",
  NOT_CONTAINS: "does not contain"
};

export const OPERATOR_IDENTIFIER = {
  EQUALS: "",
  NOT_EQUALS: "!",
  CONTAINS: "~",
  NOT_CONTAINS: "!~"
};

/**
 * Operator Items for the Select menu for the filter modal dropdown.
 */
export const OPERATOR_ITEMS = [
  {
    label: "is",
    value: OPERATOR_IDENTIFIER.EQUALS
  },
  {
    label: "is not",
    value: OPERATOR_IDENTIFIER.NOT_EQUALS
  },
  {
    label: "contains",
    value: OPERATOR_IDENTIFIER.CONTAINS
  },
  {
    label: "does not contain",
    value: OPERATOR_IDENTIFIER.NOT_CONTAINS
  }
];

/**
 * Used to show the selector options in the Filter Modal for web analytics.
 */
export const FILTER_GROUPS: LooseObject = {
  page: ["page", "entry_page", "exit_page"],
  source: ["source"], // referrer is hidden for now.
  channel: ["channel"],
  location: ["country", "region", "city"], // "region" is hidden for now.
  device: ["device_type"],
  screen: ["screen"],
  browser: ["browser"], // "browser_version" is hidden for now.
  os: ["os"], // "os_version" is hidden for now.
  utm: ["utm_medium", "utm_source", "utm_campaign", "utm_term", "utm_content"],
  goal: ["goal"]
};

export const withIndefiniteArticle = (word: string) => {
  if (word.startsWith("UTM")) {
    return `a ${word}`;
  }
  if (["a", "e", "i", "o", "u"].some((vowel) => word.toLowerCase().startsWith(vowel))) {
    return `an ${word}`;
  }
  return `a ${word}`;
};

/**
 * Filter menu items constants, shown in the dropdown menu under web analytics.
 */
export const FILTER_MENU_ITEMS = [
  {
    label: "Page",
    value: "page"
  },
  {
    label: "Source",
    value: "source"
  },
  {
    label: "Channel",
    value: "channel"
  },
  {
    label: "Location",
    value: "location"
  },
  {
    label: "Device",
    value: "device"
  },
  {
    label: "Screen",
    value: "screen"
  },
  {
    label: "Browser",
    value: "browser"
  },
  {
    label: "Operating System",
    value: "os"
  },
  {
    label: "UTM tags",
    value: "utm"
  },
  {
    label: "Conversion Goal",
    value: "goal"
  }
];

/**
 * Representation function that converts the ~, !, and !~ operators to their respective labels
 * @param filter {string} - Applied filter label.
 * @returns
 */
export const getOperatorLabel = (filter: string): string => {
  const filterValue = filter?.split(":")[1];
  if (filterValue.startsWith(OPERATOR_IDENTIFIER.CONTAINS)) {
    return OPERATOR_LABELS.CONTAINS;
  } else if (filterValue.startsWith(OPERATOR_IDENTIFIER.NOT_CONTAINS)) {
    return OPERATOR_LABELS.NOT_CONTAINS;
  } else if (filterValue.startsWith(OPERATOR_IDENTIFIER.NOT_EQUALS)) {
    return OPERATOR_LABELS.NOT_EQUALS;
  } else {
    return OPERATOR_LABELS.EQUALS;
  }
};

/**
 *
 * @param filter Filter string.
 * @returns CSS class name based on tailwind.
 */
export const getOperatorLabelClass = (filter: string): string => {
  const filterValue = filter?.split(":")[1];
  if (filterValue.startsWith(OPERATOR_IDENTIFIER.CONTAINS)) {
    return "pink.6";
  } else if (filterValue.startsWith(OPERATOR_IDENTIFIER.NOT_CONTAINS)) {
    return "yellow.6";
  } else if (filterValue.startsWith(OPERATOR_IDENTIFIER.NOT_EQUALS)) {
    return "red.6";
  } else {
    return "brand.6";
  }
};

/**
 * Representation function that returns the operator
 * @param filter {string} - Applied filter label.
 * @returns
 */
export const getOperatorByValue = (filter: string): string => {
  const filterValue = filter?.split(":")[1];
  if (
    filterValue.startsWith(OPERATOR_IDENTIFIER.CONTAINS) ||
    filterValue.startsWith(OPERATOR_IDENTIFIER.NOT_CONTAINS) ||
    filterValue.startsWith(OPERATOR_IDENTIFIER.NOT_EQUALS)
  ) {
    return filterValue?.split(":")[0][0];
  } else {
    return OPERATOR_IDENTIFIER.EQUALS;
  }
};

/**
 * Change the applied filter for the query parameters. Following are the cases.
 * If the filter is applied to equals, it changes to is not equals.
 * If the filter is applied to is not equals, it changes to contains.
 * If the filter is applied to contains, it changes to does not contain.
 * If the filter is applied to does not contain, it changes to equals.
 * @param item Query filter item.
 * @param query Read the query parameters from the URL.
 * @param setQuery Set the query parameters in the URL.
 */
export const changeFilterOperator = (item: string, query: any, setQuery: any) => {
  const oldItem = item;
  const filterValue = item?.split(":")[1];

  if (filterValue.startsWith(OPERATOR_IDENTIFIER.CONTAINS)) {
    item = item.replace(OPERATOR_IDENTIFIER.CONTAINS, OPERATOR_IDENTIFIER.NOT_CONTAINS);
  } else if (filterValue.startsWith(OPERATOR_IDENTIFIER.NOT_CONTAINS)) {
    item = item.replace(OPERATOR_IDENTIFIER.NOT_CONTAINS, OPERATOR_IDENTIFIER.EQUALS);
  } else if (filterValue.startsWith(OPERATOR_IDENTIFIER.NOT_EQUALS)) {
    item = item.replace(OPERATOR_IDENTIFIER.NOT_EQUALS, OPERATOR_IDENTIFIER.CONTAINS);
  } else {
    let split = item?.split(":");
    item =
      split[0] + ":" + split[1].replace(OPERATOR_IDENTIFIER.EQUALS, OPERATOR_IDENTIFIER.NOT_EQUALS);
  }
  setQuery({
    ...query,
    filters: [
      ...query.filters.map((value: string) => {
        if (value === oldItem) {
          return item;
        }
        return value;
      })
    ]
  });
};
export const getAppliedFilterName = (filter: string) => {
  const filterName = filter?.split(":")[0];
  return FILTER_LABELS[filterName];
};

/**
 * Return the filter value from the filter string.
 * Replaces the operator identifier with its label such as "is not" or "contains"
 *
 * @param filter Web Analytics filter i.e utm_source:Google, 'source:Source: contentstudio.io Medium: referral'
 * @returns
 */
export const getAppliedFilterValue = (filter: string, convertCodeCode = true) => {
  // Split the filter by the filter key
  // Do not split the 'Source', 'Medium', 'Campaign' as they are part of the 'source' value.
  let value = filter?.split(/(?<!Source|Medium|Campaign):/g)[1];
  if (value.startsWith(OPERATOR_IDENTIFIER.CONTAINS)) {
    value = value.replace(OPERATOR_IDENTIFIER.CONTAINS, "");
  } else if (value.startsWith(OPERATOR_IDENTIFIER.NOT_CONTAINS)) {
    value = value.replace(OPERATOR_IDENTIFIER.NOT_CONTAINS, "");
  } else if (value.startsWith(OPERATOR_IDENTIFIER.NOT_EQUALS)) {
    value = value.replace(OPERATOR_IDENTIFIER.NOT_EQUALS, "");
  }

  if (filter.startsWith("country") && convertCodeCode) {
    return filter === "Unknown" ? filter : getCountryName(value);
  }
  return value;
};

/**
 * Checks if conversion goal is selected.
 * @returns {boolean}
 */

export const isGoalSelected = () => {
  return window.location.search.includes("goal:") || window.location.search.includes("goal%3A");
};

/**
 * Returns the static values for the header when the conversion goal is selected.
 * @returns {ReactNode}
 */
export const renderConversionGoalHeader = (comparison: boolean, isMobile?: any) => {
  return (
    <div className={`${isMobile ? "flex items-center flex-grow max-w-[80%]" : "flex"}`}>
      <div className="w-24">Conversions</div>
      {comparison && (
        <Tooltip
          label={`The change in conversions between your selected dates`}
          position="top"
          withArrow
          width={200}
          multiline
          style={{ fontSize: "12px" }}>
          <div className="w-24 text-right">Change</div>
        </Tooltip>
      )}
      <div className="w-24 text-right">CR</div>
      {comparison && (
        <Tooltip
          label={`The change in conversion rate between your selected dates`}
          position="top"
          withArrow
          width={200}
          multiline
          style={{ fontSize: "12px" }}>
          <div className="w-24 text-right">Change</div>
        </Tooltip>
      )}
    </div>
  );
};

/**
 * Returns the from_date and to_date for the given interval. The bar chart only returns us a single date.
 * Therefore, we get the from_date and to_date based o the interval.
 *
 * @param date string in format on click of the bar chart handle function.
 * @param interval period of time. eg: hour, day, week, month, year
 * @returns
 */
export const getRangeByDateAndInterval = (date: string, interval: string) => {
  let from_date;
  let to_date;

  if (interval === "week") {
    from_date = format(startOfWeek(new Date(date.replace(" ", "T"))), DATE_FORMAT);
    to_date = format(endOfWeek(new Date(date.replace(" ", "T"))), DATE_FORMAT);
  } else if (interval === "month") {
    from_date = format(startOfMonth(new Date(date.replace(" ", "T"))), DATE_FORMAT);
    to_date = format(endOfMonth(new Date(date.replace(" ", "T"))), DATE_FORMAT);
  } else {
    from_date = format(startOfDay(new Date(date.replace(" ", "T"))), DATE_FORMAT);
    to_date = format(endOfDay(new Date(date.replace(" ", "T"))), DATE_FORMAT);
  }

  return { from_date, to_date };
};
