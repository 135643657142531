import React, { useEffect, useState } from "react";

import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
import { blue, purple } from "@/style/Colors";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import cloneDeep from "lodash.clonedeep";
echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer,
  UniversalTransition
]);

interface IBarGraphWrapper {
  /**
   * Categories are the data for the X-axis labels.
   */
  categories: any;
  /**
   * List of series objects for the graph. Each object has properties similar to eCharts.
   */
  series: any;
  /**
   * List of legends.
   */
  legend?: any;
  /**
   * Show percentage on the graph. When the show percentage is true, the series data will set the max value to 100 for Y-Axis
   */
  showPercentage?: boolean;
  /**
   *
   * @param params
   * @returns
   */
  customTooltipFormatter?: (params: any) => string;
  /**
   * Bar chart on click handler
   * @param params
   * @returns
   */
  onClick?: (params: any) => void;
  /**
   * X-axis label name
   */
  xAxisName?: string;
  /**
   * Y-axis label name
   */
  yAxisName?: string;
  /**
   * Grid options for the chart/graph margin.
   */
  grid?: {
    top: string;
    left: string;
    right: string;
    bottom?: string;
    containLabel: boolean;
  };
  /**
   * Height of the chart/graph
   */
  height?: string;
}

const BarGraphWrapper = ({
  categories,
  series,
  legend = {
    show: false,
    data: []
  },
  showPercentage = false,
  customTooltipFormatter,
  onClick = () => {},
  xAxisName = "",
  yAxisName = "",
  grid = {
    top: "5%",
    left: "5%",
    right: "0%",
    bottom: "5%",
    containLabel: false
  },
  height = "400px"
}: IBarGraphWrapper) => {
  const theme = useGlobalMantineTheme();
  // Set the x-axis label interval depending on the number of categories elements.
  let interval;
  if (categories.length > 180) {
    interval = 24;
  } else if (categories.length > 90 && categories.length <= 180) {
    interval = 10;
  } else if (categories.length > 30 && categories.length <= 90) {
    interval = 4;
  } else if (categories.length > 15 && categories.length <= 30) {
    interval = 1;
  } else {
    interval = 0;
  }

  let DEFAULT_CHART_OPTIONS = {
    animationDuration: 500,
    useUTC: true,
    color: [purple, blue],
    title: {
      text: ""
    },
    notMerge: true,
    tooltip: {
      borderColor: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2],
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: theme.colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
      },
      trigger: "axis"
    },
    legend: {
      show: false,
      data: []
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {}
      }
    },
    grid: grid,
    xAxis: [
      {
        name: xAxisName,
        nameLocation: "center",
        nameGap: 26,
        data: [],
        type: "category",
        axisLine: {
          show: false
        },
        axisLabel: {
          fontSize: 10,
          axisPointer: {
            show: false
          },
          margin: 10,
          interval: interval
        },
        splitLine: {
          show: false
        },
        axisTick: {
          splitNumber: 4
        }
      }
    ],
    yAxis: [
      {
        name: yAxisName,
        nameLocation: "center",
        nameGap: 26,
        min: 0,
        max: function (value: any) {
          return Math.floor(value.max * 1.4).toString();
        },
        type: "value",
        splitLine: true,
        alignTicks: true,
        axisTick: {
          show: true,
          splitNumber: 4
        }
      }
    ],
    series: [],
    replaceMerge: ["series"]
  };
  DEFAULT_CHART_OPTIONS.xAxis[0].data = categories;
  DEFAULT_CHART_OPTIONS.series = series;
  DEFAULT_CHART_OPTIONS.legend = legend;
  // DEFAULT_CHART_OPTIONS.tooltip.axisPointer.label.padding = [10, 15, 10, 15]

  if (showPercentage) {
    // @ts-ignore
    DEFAULT_CHART_OPTIONS.yAxis[0].max = 100;
    // @ts-ignore
    DEFAULT_CHART_OPTIONS.tooltip.valueFormatter = (value: any) => `${value}%`;
  }

  if (customTooltipFormatter) {
    //@ts-ignore
    DEFAULT_CHART_OPTIONS.tooltip.formatter = customTooltipFormatter;
  }

  const [option, setOption] = useState(DEFAULT_CHART_OPTIONS);

  useEffect(() => {
    DEFAULT_CHART_OPTIONS.series = series;
    setOption(cloneDeep(DEFAULT_CHART_OPTIONS));
  }, [series]);

  const _onEvents = {
    click: onClick
  };
  console.log("option", option);
  return (
    <>
      {series.length > 0 && (
        <div className="h-full w-full">
          <ReactEChartsCore
            style={{ height: height }}
            // ref={chartRef}
            opts={{ renderer: "svg" }}
            notMerge={true}
            echarts={echarts}
            option={option}
            onEvents={_onEvents}
            // onChartReady={handleChartReady}
          />
        </div>
      )}
    </>
  );
};

const BarGraphMemoized = React.memo(BarGraphWrapper);
export { BarGraphMemoized as BarGraphWrapper };
