// import React, { useState } from "react";

import EmailVerifiedSVG from "@assets/images/icons/email-verified.svg"
import { useEffect } from "react"
import { AuthorizationLayout } from "@/ui/layouts/AuthorizationLayout"
import { useNavigate } from "react-router-dom"
import { Button } from "@mantine/core"

export const EmailVerified = (props: any) => {
  const navigate = useNavigate()
  useEffect(() => {
    document.title = "Email Verified | Usermaven"
  }, [])
  return (
    <AuthorizationLayout>
      <div className="auth-container--info">
        <img src={EmailVerifiedSVG} className={"pb-8"} alt="Email Verified" />
        <h1 className="pb-8">Email Verified!</h1>
        <p>Your e-mail is verified. Now let's continue to set you up.</p>
        <div className="auth-container--info--button pt-8">
          <Button
            onClick={() => {
              navigate("/onboarding/create-workspace")
            }}
          >
            Continue Onboarding
          </Button>
        </div>
      </div>
    </AuthorizationLayout>
  )
}
