/* eslint-disable react-hooks/exhaustive-deps */
import { getReadableFormatNumber, numberToCommas } from "@/lib/utils/StringUtility";
import React from "react";
import { LoadingStateProps } from "@/types/types.d";
import { SummaryComparisonCard } from "../../../Cards/SummaryComparisonCard";

type Props = {
  loading: LoadingStateProps;
  comparisonData: any;
};

const ConversionRateComparison = ({ loading, comparisonData }: Props) => {
  return (
    <div data-cy="events-card">
      <SummaryComparisonCard
        uniquekey={"uniqueVisitors"}
        loading={loading}
        comparisonValue={`${getReadableFormatNumber(comparisonData?.conversion_rate)}%`}
        comparisonTooltip={`${numberToCommas(comparisonData.conversion_rate)}% Conversion Rate`}
      />
    </div>
  );
};
const ConversionRateComparisonMemoized = React.memo(ConversionRateComparison);
export { ConversionRateComparisonMemoized as ConversionRateComparison };
