import { Badge, Box, Image, SimpleGrid, useMantineColorScheme, Text, Flex } from "@mantine/core";
import HTTPMethod from "@assets/images/icons/integrations/code.svg";
import GTMIcon from "@assets/images/icons/integrations/gtm.svg";
import SegmentIcon from "@assets/images/icons/integrations/segment.svg";
import JSIcon from "@assets/images/icons/integrations/js.png";
import NextWhiteIcon from "@assets/images/icons/integrations/next-js-logo-white.svg";
import NextIcon from "@assets/images/icons/integrations/next-js.svg";
import PythonIcon from "@assets/images/icons/integrations/python-5.svg";
import ReactIcon from "@assets/images/icons/integrations/react.png";
import WordPressIconWhite from "@assets/images/icons/integrations/wordpress-logo-white.svg";
import WordPressIcon from "@assets/images/icons/integrations/wordpress-logo.svg";
import ShopifyIcon from "@assets/images/icons/integrations/shopify.svg";
import BubbleLightIcon from "@assets/images/icons/integrations/bubble-logo.png";
import BubbleDarkIcon from "@assets/images/icons/integrations/bubble-dark-logo.svg";
import WixIcon from "@assets/images/icons/integrations/wix-logo.png";
import WebflowIcon from "@assets/images/icons/integrations/webflow-logo.svg";
import SquarespaceIcon from "@assets/images/icons/integrations/squarespace-logo.svg";
import SquarespaceDarkIcon from "@assets/images/icons/integrations/squarespace-logo-dark.svg";
import AngularIcon from "@assets/images/icons/integrations/angular-logo.png";

import { StringParam, useQueryParam } from "use-query-params";
import { TrackingPixelHTTP } from "./components/TrackingPixelHTTP";
import { TrackingPixelJavascriptSnippet } from "./components/TrackingPixelJavascriptSnippet";
import { TrackingPixelPythonGuide } from "./components/TrackingPixelPythonGuide";
import { TrackingPixelReactNextGuide } from "./components/TrackingPixelReactNextGuide";
import { TrackingPixelWordPress } from "./components/TrackingPixelWordPress";
import { SegmentInstallation } from "./components/SegmentInstallation";
import { TrackingPixelForCMS } from "./components/TrackingPixelForCMS";
import { BubbleInstallation } from "./components/BubbleInstallation";
import { TrackingPixelAngularGuide } from "@/ui/components/App/TrackingPixel/components/TrackingPixelAngularGuide";
import classes from "./TrackingPixel.module.css";
type Props = {
  workspaceId: string;
  connectedDataSource?: boolean;
  identifiedUsers?: boolean;
  trackedBehaviors?: boolean;
};

export const TrackingPixel: React.FC<Props> = ({
  workspaceId,
  connectedDataSource = false,
  identifiedUsers = false,
  trackedBehaviors = false
}) => {
  const [integrationType = "javascript", setIntegrationType] = useQueryParam("type", StringParam);
  console.log(`TrackingPixel: ${workspaceId}`);
  const mantineTheme = useMantineColorScheme();

  const IntegrationsList = [
    {
      identifier: "javascript",
      text: "JavaScript snippet",
      image: JSIcon,
      isVisible: true,
      rounded: true
    },
    {
      identifier: "gtm",
      text: "Google Tag Manager",
      image: GTMIcon,
      isVisible: true,
      rounded: true
    },
    {
      identifier: "segment",
      text: "Segment",
      image: SegmentIcon,
      isVisible: true,
      rounded: true,
      beta: true
    },
    { identifier: "react", text: "React.js", image: ReactIcon, isVisible: true },
    {
      identifier: "next",
      text: "Next.js",
      image: mantineTheme.colorScheme === "dark" ? NextWhiteIcon : NextIcon,
      isVisible: true,
      rounded: true
    },
    {
      identifier: "angular",
      text: "Angular",
      image: mantineTheme.colorScheme === "dark" ? AngularIcon : AngularIcon,
      isVisible: true,
      rounded: false
    },
    {
      identifier: "python",
      text: "Python",
      image: PythonIcon,
      isVisible: true,
      rounded: true
      // user.email && (user.email.endsWith("@usermaven.com") || user.email.endsWith("@contentstudio.io")),
    },
    {
      identifier: "wordpress",
      text: "WordPress",
      image: mantineTheme.colorScheme === "dark" ? WordPressIconWhite : WordPressIcon,
      isVisible: true,
      rounded: true
    },
    {
      identifier: "shopify",
      text: "Shopify",
      image: ShopifyIcon,
      isVisible: true,
      rounded: true,
      content: <TrackingPixelForCMS href="https://usermaven.com/docs/integrations/shopify" />
    },
    {
      identifier: "bubble",
      text: "Bubble",
      image: mantineTheme.colorScheme === "dark" ? BubbleDarkIcon : BubbleLightIcon,
      isVisible: true,
      rounded: true,
      content: <BubbleInstallation />
    },
    {
      identifier: "wix",
      text: "Wix",
      image: WixIcon,
      isVisible: true,
      rounded: true,
      content: <TrackingPixelForCMS href="https://usermaven.com/docs/integrations/wix" />
    },
    {
      identifier: "webflow",
      text: "Webflow",
      image: WebflowIcon,
      isVisible: true,
      rounded: true,
      content: <TrackingPixelForCMS href="https://usermaven.com/docs/integrations/webflow" />
    },
    {
      identifier: "squarespace",
      text: "SquareSpace",
      image: mantineTheme.colorScheme === "dark" ? SquarespaceDarkIcon : SquarespaceIcon,
      isVisible: true,
      rounded: true,
      content: <TrackingPixelForCMS href="https://usermaven.com/docs/integrations/squarespace" />
    },
    {
      identifier: "http",
      text: "HTTP API",
      image: mantineTheme.colorScheme === "dark" ? HTTPMethod : HTTPMethod,
      isVisible: true,
      rounded: false
    }
  ];

  const findByIdentifier = (value: string | null) => {
    return IntegrationsList.find((i) => i.identifier === value)?.content;
  };

  return (
    <>
      <div>
        <SimpleGrid
          cols={5}
          spacing={8}
          // breakpoints={[
          //   { maxWidth: "xl", cols: 4, spacing: "md" },
          //   { maxWidth: "lg", cols: 3, spacing: "md" },
          //   { maxWidth: "md", cols: 2, spacing: "md" },
          //   { maxWidth: "sm", cols: 3, spacing: "sm" },
          //   { maxWidth: "xs", cols: 2, spacing: "sm" }
          // ]}
          w={"100%"}>
          {IntegrationsList.filter((i) => i.isVisible).map((integration) => (
            <Flex
              p={"xs"}
              align={"center"}
              h={60}
              component="div"
              className={classes.pixelCard}
              key={`integration:${integration.identifier}`}
              onClick={() => {
                setIntegrationType(integration.identifier);
              }}
              bg={
                integrationType === integration.identifier && mantineTheme.colorScheme === "dark"
                  ? "dark.6"
                  : integrationType === integration.identifier &&
                    mantineTheme.colorScheme === "light"
                  ? "blue.1"
                  : mantineTheme.colorScheme === "dark"
                  ? "dark.9"
                  : ""
              }>
              <Box w={40}>
                <Image
                  fit="contain"
                  style={{
                    borderRadius: integration.rounded ? "100%" : ""
                  }}
                  height={32}
                  width={32}
                  src={integration.image}
                  alt={integration.text}
                />
              </Box>
              <Text ml={"sm"} fz={"sm"} fw={500}>
                {integration.text}
              </Text>
              {integration.hasOwnProperty("beta") && integration.beta && (
                <Box
                  className="absolute right-1 top-1"
                  style={{
                    position: "absolute",
                    right: "4px",
                    top: "-4px",
                    zIndex: 1000
                  }}>
                  <Badge size="xs">BETA</Badge>
                </Box>
              )}
            </Flex>
          ))}
        </SimpleGrid>
      </div>

      {["javascript", "gtm"].includes(integrationType || "") && (
        <>
          <TrackingPixelJavascriptSnippet
            workspaceId={workspaceId}
            integrationType={integrationType}
            connectedDataSource={connectedDataSource}
            identifiedUsers={identifiedUsers}
            trackedBehaviors={trackedBehaviors}></TrackingPixelJavascriptSnippet>
        </>
      )}
      {["react", "next"].includes(integrationType || "") && (
        <>
          <TrackingPixelReactNextGuide
            workspaceId={workspaceId}
            integrationType={integrationType}
            connectedDataSource={connectedDataSource}
            identifiedUsers={identifiedUsers}
            trackedBehaviors={trackedBehaviors}></TrackingPixelReactNextGuide>
        </>
      )}
      {integrationType === "angular" && (
        <>
          <TrackingPixelAngularGuide
            workspaceId={workspaceId}
            connectedDataSource={connectedDataSource}
            identifiedUsers={identifiedUsers}
            trackedBehaviors={trackedBehaviors}></TrackingPixelAngularGuide>
        </>
      )}
      {integrationType === "python" && (
        <>
          <TrackingPixelPythonGuide
            connectedDataSource={connectedDataSource}
            identifiedUsers={identifiedUsers}
            trackedBehaviors={trackedBehaviors}
          />
        </>
      )}
      {integrationType === "wordpress" && (
        <>
          <TrackingPixelWordPress />
        </>
      )}
      {integrationType === "http" && (
        <>
          <TrackingPixelHTTP />
        </>
      )}
      {integrationType === "segment" && (
        <>
          <SegmentInstallation
            connectedDataSource={connectedDataSource}
            identifiedUsers={identifiedUsers}
            trackedBehaviors={trackedBehaviors}
          />
        </>
      )}
      {findByIdentifier(integrationType)}
    </>
  );
};
