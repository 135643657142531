import { createContext } from "react";

type InsightContextType = {
  insightAttributes: any;
  setInsightAttributes: (value: Array<object>) => void;
  insightEvents: any;
  setInsightEvents: (value: Array<object>) => void;
};

export const InsightContext = createContext<
  InsightContextType | undefined | any
>(undefined);
export default InsightContext;
