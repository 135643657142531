import { Box } from "@mantine/core";
import PerformanceFunnelBarGroup from "./PerformanceFunnelBarGroup";
import { IFunnelPerformance } from "@/ui/pages/InsightsDashboard/Pages/FeatureAdoption/FeatureDetail";

const PerformanceFunnelBarChart = ({
  bars,
  type = "Visitors"
}: {
  bars: IFunnelPerformance[];
  type?: string;
}) => {
  const max = Math.max(...bars.map((bar) => bar.count));

  return (
    <Box
      className="bar-chart"
      style={{
        minHeight: 300,
        height: 300,
        width: "100%"
      }}>
      <PerformanceFunnelBarGroup bars={bars} max={max} type={type} />
    </Box>
  );
};

export default PerformanceFunnelBarChart;
