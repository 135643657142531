import create from "zustand";

interface ITrackingPixelStore {
  isAutoCaptureEnabled: boolean;
  isPrivacyModeEnabled: boolean;
  useWhiteLabelDomain: boolean;

  setIsAutoCaptureEnabled: (isAutoCaptureEnabled: boolean) => void;
  setIsPrivacyModeEnabled: (isPrivacyModeEnabled: boolean) => void;
  setUseWhiteLabelDomain: (useWhiteLabelDomain: boolean) => void;
}

export const useTrackingPixelStore = create<ITrackingPixelStore>((set) => ({
  isAutoCaptureEnabled: true,
  isPrivacyModeEnabled: false,
  useWhiteLabelDomain: false,

  setIsAutoCaptureEnabled: (isAutoCaptureEnabled) => set({ isAutoCaptureEnabled }),
  setIsPrivacyModeEnabled: (isPrivacyModeEnabled) => set({ isPrivacyModeEnabled }),
  setUseWhiteLabelDomain: (useWhiteLabelDomain) => set({ useWhiteLabelDomain })
}));
