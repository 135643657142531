import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { EventsService } from "@/lib/services/EventsService";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { Button, Center, Divider, Grid, Loader, Popover, Text } from "@mantine/core";
import { useContext, useState } from "react";
import { LoadingStateProps } from "types/types.d";

interface IEventDetailedViewPropertyValuesPopover {
  property: string;
  event: string;
  fromDate: string;
  toDate: string;
}

export const EventDetailedViewPropertyValuesPopover = ({
  property,
  event,
  fromDate,
  toDate
}: IEventDetailedViewPropertyValuesPopover) => {
  const [propertyValues, setPropertyValues] = useState([]);
  const [propertyLoading, setPropertyLoading] = useState<LoadingStateProps>("idle");
  const [opened, setOpened] = useState(false);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const fetchEventPropertyValues = async (eventKey: string = "") => {
    setOpened(!opened);
    setPropertyLoading("loading");
    const eventsService = new EventsService();
    await eventsService
      .eventPropertiesByKey(activeWorkspace.id, event, eventKey, fromDate, toDate)
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setPropertyValues(res.data.data ? res.data.data : []);
        }
      })
      .catch((err) => {
        console.log(err);
        setPropertyValues([]);
      });
    setPropertyLoading("loaded");
  };
  return (
    <>
      <Popover
        width={400}
        withArrow
        opened={opened}
        onChange={() => {
          setOpened(!opened);
        }}
        zIndex={9999}>
        <Popover.Target>
          <Button
            variant="subtle"
            size="compact-sm"
            onClick={() => fetchEventPropertyValues(property)}>
            View values
          </Button>
        </Popover.Target>

        <Popover.Dropdown>
          {propertyLoading === "loaded" ? (
            <>
              <Grid component="div" fz="sm" fw={500}>
                <Grid.Col span={8}>Value</Grid.Col>
                <Grid.Col span={4}>Count</Grid.Col>
              </Grid>
              <Divider my="sm" />
              {propertyValues.length > 0 ? (
                propertyValues.map((property: any) => (
                  <Grid fz="xs">
                    <Grid.Col span={8}>{property.key}</Grid.Col>
                    <Grid.Col span={4} fw={600}>
                      {numberToCommas(property.count)}
                    </Grid.Col>
                  </Grid>
                ))
              ) : (
                <Center>
                  <Text>No properties found.</Text>
                </Center>
              )}
            </>
          ) : (
            <Center py={"lg"}>
              <Loader size={"xs"} />
            </Center>
          )}
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
