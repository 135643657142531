/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Center,
  Flex,
  Group,
  Paper,
  Progress,
  Rating,
  Table,
  Text,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import orderBy from "lodash/orderBy";
import { FC, Fragment, useContext, useState } from "react";
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import { AdoptionModule } from "@/ui/pages/InsightsDashboard/Pages/InsightsDashboardFeatureAdoption";
import { useNavigate } from "react-router-dom";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { BulletListSkeleton } from "@/lib/utils/ChartsSkeletons";
import { NoResults } from "../NoResults/NoResults";
import { FeatureAdoptionFrequencyPulse } from "./FeatureAdoptionFrequencyPulse";
import classes from "./FeatureAdoptionTableExpandable.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface FeatureAdoptionTableExpandableProps {
  isLoading: string;
  data: AdoptionModule[];
  setAdoptionReportData: (data: any) => void;
  reportType: string;
}

export const FeatureAdoptionTableExpandable: FC<FeatureAdoptionTableExpandableProps> = ({
  isLoading,
  reportType,
  data = [
    {
      module_id: "1",
      module: "Module 1",
      features: [
        {
          feature_id: "1",
          feature_name: "brand_created",
          frequency_category: "daily",
          active_users: 1040,
          avg_usage: 1,
          awareness: 386,
          awareness_ratio: 37.12,
          adoption: 1,
          adoption_ratio: 0.26,
          days_active: 9,
          frequency: 30,
          retention: 0,
          retention_ratio: 0,
          engagement_histogram: [1]
        }
      ]
    }
  ],
  setAdoptionReportData
}) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  // const theme = useGlobalMantineTheme();
  const navigate = useNavigate();
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // sorting order
  const [sortOrder, setSortOrder] = useState("featureUsers");
  const [sortDirection, setSortDirection] = useState<any>("desc");

  const onChangeSort = (sortOrderValue: string) => {
    if (sortOrder === sortOrderValue) {
      setSortDirection(sortDirection === "desc" ? "asc" : "desc");
      setSortOrder(sortOrderValue);
    } else {
      setSortOrder(sortOrderValue);
      setSortDirection("desc");
    }
  };

  const colors = {
    red: "#F05252",
    green: "#6ECA7D",
    orange: "#F59E0B"
  };

  const getReportTypeValue = (reportType: string) => {
    return reportType === "user" ? "users" : "companies";
  };
  return (
    <>
      <Paper
        mb="xl"
        shadow="sm"
        withBorder
        style={(theme) => ({
          backgroundColor: colorScheme === "dark" ? theme.colors.dark[8] : "#fff",
          // border: `1px solid`,
          borderRadius: "0.25rem",
          display: "block",
          overflowX: "auto",
          whiteSpace: "nowrap"
        })}
        className="mt-4 shadow-sm">
        <Table withColumnBorders verticalSpacing={"md"} striped>
          <Table.Thead tt="uppercase" fw={600}>
            <Table.Tr>
              {[
                {
                  label: "Module / Feature",
                  value: "featureName"
                },
                {
                  label: "Frequency",
                  value: "featureFrequency"
                },
                {
                  label: "Used",
                  value: "featureUsage"
                },
                {
                  label: "Adopted",
                  value: "featureAdoption"
                },
                {
                  label: "Retained",
                  value: "featureRetention"
                },
                {
                  label: "",
                  value: "action"
                }
              ].map(
                (
                  item: {
                    label: string;
                    value: string;
                  },
                  index: number
                ) => (
                  <Table.Th
                    key={`feature-adoption-table-expandable-header-${index}`}
                    onClick={() => {
                      if (item.value !== "action") {
                        onChangeSort(item.value);
                      }
                    }}
                    miw={index === 0 ? 160 : index === 1 ? 150 : 200}
                    className={
                      index === 0
                        ? "min-w-[160px] cursor-pointer hover:text-opacity-70"
                        : index === 1
                        ? "min-w-[150px] cursor-pointer hover:text-opacity-70"
                        : "min-w-[200px] cursor-pointer hover:text-opacity-70"
                    }>
                    {index === 0 ? (
                      item.label
                    ) : (
                      <Flex align={"center"} justify={"center"}>
                        {item.label}
                      </Flex>
                    )}
                    {/* {sortOrder === item.value && (
                      <FontAwesomeIcon
                        icon={solid("angle-down")}
                        className={`ml-2 transform  text-gray-800 transition-all duration-200 ease-in-out ${
                          sortDirection === "asc" ? "rotate-180" : ""
                        }`}
                      />
                    )} */}
                  </Table.Th>
                )
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {isLoading === "loaded" ? (
              <>
                {data.length === 0 ? (
                  <Table.Tr>
                    <Table.Td colSpan={6}>
                      <Center py="md">
                        <NoResults heading={"No results"} text={"There is no activity"} />
                      </Center>
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  orderBy(data, [sortOrder], [sortDirection]).map((row, index) => (
                    <Fragment key={`feature-adoption-table-expandable-row-${index}`}>
                      <Table.Tr
                        className={classes.borderLeft}
                        style={{
                          borderLeftColor: row.color
                        }}>
                        <Table.Td
                          style={{
                            cursor: "pointer"
                          }}
                          className={classes.moduleTd}
                          colSpan={6}
                          onClick={() => {
                            setAdoptionReportData(
                              data.map((module) => {
                                if (module.module_id === row.module_id) {
                                  return { ...module, expanded: !module.expanded };
                                }
                                return module;
                              })
                            );
                          }}>
                          <Flex align={"center"}>
                            <FontAwesomeIcon
                              icon={solid("angle-right")}
                              className={`mr-2 transform  text-gray-800 transition-all duration-200 ease-in-out -ml-2 ${
                                row.expanded ? "rotate-90" : ""
                              }`}
                            />
                            <Text className={classes.textTransition} fw={600}>
                              {row.module}
                            </Text>
                          </Flex>
                        </Table.Td>
                      </Table.Tr>
                      {row.expanded &&
                        row.features.map((feature, index) => (
                          <Table.Tr
                            key={`feature-${feature.feature_id}-${index}`}
                            className=""
                            style={
                              {
                                // borderColor: row.color
                              }
                            }>
                            {/* Feature Name */}
                            <Table.Td className={classes.featureTd}>
                              <Flex align={"center"} justify={"center"} flex={1} h={"100%"}>
                                <Text fw={500}>{toTitleCase(feature.feature_name)}</Text>
                              </Flex>
                            </Table.Td>
                            {/* Frequency */}
                            <Table.Td className={classes.featureTd}>
                              <Flex direction={"column"} justify={"center"} align={"center"}>
                                {FeatureAdoptionFrequencyPulse(
                                  feature.frequency_category,
                                  colorScheme
                                )}

                                <Text tt="capitalize" c="dimmed" size="xs">
                                  {feature.frequency_category}
                                </Text>
                              </Flex>
                            </Table.Td>
                            {/* Used */}
                            <Table.Td className={classes.featureTd}>
                              <Flex mb={8} justify={"space-between"}>
                                <Text fw="bold" size="xs" c="dimmed">
                                  {feature.awareness_ratio || 0}%
                                </Text>
                                <Text size="xs" c="dimmed">
                                  <span className="font-bold">{feature.awareness}</span>{" "}
                                  {getReportTypeValue(reportType)}
                                </Text>
                              </Flex>
                              <Flex align={"center"}>
                                <Progress
                                  w={"100%"}
                                  styles={(theme) => ({
                                    label: {
                                      fontSize: "7px"
                                    }
                                  })}
                                  color={
                                    feature.awareness_ratio < 20
                                      ? colors.red
                                      : feature.awareness_ratio < 70
                                      ? colors.orange
                                      : theme.colors.green[7]
                                  }
                                  radius={"sm"}
                                  size="md"
                                  value={feature.awareness_ratio}
                                />
                              </Flex>

                              <Box mt={8} mb={8}>
                                <Box
                                  style={{
                                    whiteSpace: "pre",
                                    fontSize: "12px",
                                    fontWeight: 500,

                                    display: "flex",
                                    justifyContent: "center",
                                    color:
                                      feature.previous_awareness_ratio !== undefined
                                        ? feature.awareness_ratio -
                                            feature.previous_awareness_ratio >
                                          0
                                          ? theme.colors.green[7]
                                          : feature.awareness_ratio -
                                              feature.previous_awareness_ratio ===
                                            0
                                          ? ""
                                          : colors.red
                                        : ""
                                  }}>
                                  {feature.previous_awareness_ratio !== undefined && (
                                    <>
                                      {feature.awareness_ratio -
                                        feature.previous_awareness_ratio ===
                                      0
                                        ? ""
                                        : feature.awareness_ratio -
                                            feature.previous_awareness_ratio >
                                          0
                                        ? "↑"
                                        : "↓"}
                                      {(
                                        feature.awareness_ratio - feature.previous_awareness_ratio
                                      ).toFixed(2)}
                                      %
                                    </>
                                  )}{" "}
                                  <Text fz="xs" mt={1} fw="normal" c="dimmed">
                                    from previous period.
                                  </Text>
                                </Box>
                              </Box>
                            </Table.Td>
                            {/* Adopted */}
                            <Table.Td className={classes.featureTd}>
                              <Flex justify={"space-between"} mb={8}>
                                <Text fw="bold" size="xs" c="dimmed" className="flex-grow">
                                  {feature.adoption_ratio || 0}%
                                </Text>
                                <Text size="xs" c="dimmed">
                                  <span className="font-bold">{feature.adoption}</span>{" "}
                                  {getReportTypeValue(reportType)}
                                </Text>
                              </Flex>
                              <Flex align={"center"}>
                                <Progress
                                  w={"100%"}
                                  styles={(theme) => ({
                                    label: {
                                      fontSize: "7px"
                                    }
                                  })}
                                  color={
                                    feature.adoption_ratio < 20
                                      ? colors.red
                                      : feature.adoption_ratio < 70
                                      ? colors.orange
                                      : theme.colors.green[7]
                                  }
                                  radius={"sm"}
                                  size="md"
                                  value={feature.adoption_ratio}
                                />
                              </Flex>

                              <Box mt={8} mb={8}>
                                <Box
                                  style={{
                                    whiteSpace: "pre",
                                    fontSize: "12px",
                                    fontWeight: 500,

                                    display: "flex",
                                    justifyContent: "center",
                                    color:
                                      feature.previous_adoption_ratio !== undefined
                                        ? feature.adoption_ratio - feature.previous_adoption_ratio >
                                          0
                                          ? theme.colors.green[7]
                                          : feature.adoption_ratio -
                                              feature.previous_adoption_ratio ===
                                            0
                                          ? ""
                                          : colors.red
                                        : ""
                                  }}>
                                  {feature.previous_adoption_ratio !== undefined && (
                                    <>
                                      {feature.adoption_ratio - feature.previous_adoption_ratio ===
                                      0
                                        ? ""
                                        : feature.adoption_ratio - feature.previous_adoption_ratio >
                                          0
                                        ? "↑"
                                        : "↓"}
                                      {(
                                        feature.adoption_ratio - feature.previous_adoption_ratio
                                      ).toFixed(2)}
                                      %
                                    </>
                                  )}{" "}
                                  <Text fw="normal" mt={1} fz="xs" c="dimmed">
                                    from previous period.
                                  </Text>
                                </Box>
                              </Box>
                            </Table.Td>
                            {/* Retained */}
                            <Table.Td className={classes.featureTd}>
                              <Flex justify={"space-between"} mb={8}>
                                <Text fw="bold" size="xs" c="dimmed" className="flex-grow">
                                  {feature.retention_ratio || 0}%
                                </Text>
                                <Text size="xs" c="dimmed">
                                  <span className="font-bold">{feature.retention}</span>{" "}
                                  {getReportTypeValue(reportType)}
                                </Text>
                              </Flex>
                              <Flex align={"center"}>
                                <Progress
                                  w={"100%"}
                                  styles={(theme) => ({
                                    label: {
                                      fontSize: "7px"
                                    }
                                  })}
                                  color={
                                    feature.retention_ratio < 20
                                      ? colors.red
                                      : feature.retention_ratio < 70
                                      ? colors.orange
                                      : theme.colors.green[7]
                                  }
                                  radius={"sm"}
                                  size="md"
                                  value={feature.retention_ratio}
                                />
                              </Flex>

                              <Box mt={8} mb={8}>
                                <Box
                                  style={{
                                    whiteSpace: "pre",
                                    fontSize: "12px",
                                    fontWeight: 500,

                                    display: "flex",
                                    justifyContent: "center",
                                    color:
                                      feature.previous_retention_ratio !== undefined
                                        ? feature.retention_ratio -
                                            feature.previous_retention_ratio >
                                          0
                                          ? theme.colors.green[6]
                                          : feature.retention_ratio -
                                              feature.previous_retention_ratio ===
                                            0
                                          ? ""
                                          : colors.red
                                        : ""
                                  }}>
                                  {feature.previous_retention_ratio !== undefined && (
                                    <>
                                      {feature.retention_ratio -
                                        feature.previous_retention_ratio ===
                                      0
                                        ? ""
                                        : feature.retention_ratio -
                                            feature.previous_retention_ratio >
                                          0
                                        ? "↑"
                                        : "↓"}
                                      {(
                                        feature.retention_ratio - feature.previous_retention_ratio
                                      ).toFixed(2)}
                                      %
                                    </>
                                  )}{" "}
                                  <Text fw="normal" fz="xs" mt={1} c="dimmed">
                                    from previous period.
                                  </Text>
                                </Box>
                              </Box>
                            </Table.Td>
                            {/* Action */}
                            <td className={classes.featureTd}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}>
                                <Box>
                                  <Button
                                    mt={16}
                                    color={colorScheme === "dark" ? "dark.2" : undefined}
                                    variant="light"
                                    onClick={() => {
                                      navigate(
                                        `/env/${activeWorkspace.identifier}/insights/dashboard/feature/${row.module_id}/${feature.feature_id}`,
                                        {
                                          replace: true
                                        }
                                      );
                                    }}>
                                    Detailed View
                                  </Button>
                                </Box>
                              </div>
                            </td>
                          </Table.Tr>
                        ))}

                      {row.expanded && row.features.length === 0 && (
                        <>
                          <Table.Tr>
                            <Table.Td className="text-center" colSpan={6}>
                              You have not added any features to this module.{" "}
                            </Table.Td>
                          </Table.Tr>
                        </>
                      )}
                    </Fragment>
                  ))
                )}
              </>
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="flex justify-center items-center">
                    <BulletListSkeleton />
                  </div>
                </td>
              </tr>
            )}
          </Table.Tbody>
        </Table>
      </Paper>
    </>
  );
};
