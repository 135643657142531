import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { Link, useSearchParams } from "react-router-dom";
import { PasswordStrength } from "@/ui/components/App/PasswordStrength/PasswordStrength";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";

import { Button, Input, PasswordInput, Title, Text, Flex, Loader } from "@mantine/core";
import InvalidPasswordHashSVG from "@assets/images/icons/error-state.svg";
import { APIUserService } from "@/lib/services/AuthService";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
interface IFormInputs {
  password: string;
  password_confirm: string;
}
export const ResetPassword = (props: any) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm<IFormInputs>();
  let [searchParams, setSearchParams] = useSearchParams();

  const watchPassword = watch("password", "");
  const watchConfirmPassword = watch("password_confirm", "");
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [tokenVerifyingState, setTokenVerifyingState] = useState("idle");
  const [loadingState, setLoadingState] = useState("idle");
  const navigate = useNavigate();

  useEffect(() => {
    const userService = new APIUserService();

    const token = searchParams.get("token");
    console.debug(`Password reset token is ${token} and type is ${typeof token}`);

    userService
      .validatePasswordToken(token)
      .then((res) => {
        if (res.status) {
          setTokenVerifyingState("success");
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404 || err?.response?.status === 422) {
          setTokenVerifyingState("error");
          setIsInvalidToken(true);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const onSubmit = (data: any) => {
    const userService = new APIUserService();

    if (data.password !== data.password_confirm) {
      toast.error("Your password and confirm password does not match.");
      return;
    }
    const token = searchParams.get("token");
    setLoadingState("loading");
    userService
      .resetPassword(data.password, data.password_confirm, token)
      .then((res) => {
        setLoadingState("success");
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate("/login");
        }
      })
      .catch((err) => {
        setLoadingState("error");
      });
  };
  return (
    <div className="auth-container">
      {isInvalidToken && (
        <div className="auth-container--info">
          <img src={InvalidPasswordHashSVG} className={"pb-8"} alt="invalid password link" />
          <Title order={3} ta="center" mb="md">
            Your reset link is invalid or expired.
          </Title>
          <Text>
            You can request for new password reset link from{" "}
            <Link to="/forgot-password">this link.</Link>
          </Text>
        </div>
      )}
      {tokenVerifyingState === "idle" && (
        <Flex direction={"column"} h={100} justify={"center"} align={"center"}>
          <Loader size={"xs"} mb="sm" />
          <Text>Verifying password reset link.</Text>
        </Flex>
      )}
      {tokenVerifyingState !== "idle" && !isInvalidToken && (
        <div className="auth-container--box">
          <Title order={3} ta="center" mb={16}>
            Reset your password
          </Title>
          <Text fz={14} ta="center">
            Already have Usermaven account?{" "}
            <Text span fz={14}>
              <Link to="/login">Sign in</Link>
            </Text>
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="auth-container--box--form pt-4 pb-2">
              <Flex pos="relative" className="auth-input-group relative">
                <Flex pos={"absolute"} right={0} top={3} direction={"row-reverse"}>
                  <PasswordStrength password={watchPassword} />
                </Flex>

                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <Input.Wrapper id={"password"} size="xs" label="Password" required>
                      <PasswordInput
                        mt={4}
                        placeholder="*********"
                        onChange={onChange}
                        withAsterisk
                        value={value}
                      />
                    </Input.Wrapper>
                  )}
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, minLength: 8 }}
                />

                {errors.password?.type === "required" && (
                  <ValidationLabel
                    validationType="error"
                    text="Password is required"
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}

                {errors.password?.type === "minLength" && (
                  <ValidationLabel
                    validationType="error"
                    text="Password must be greater than 8 characters."
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
              </Flex>
              <div className="auth-input-group pt-4 pb-2">
                <Flex pos={"absolute"} right={0} top={3} direction={"row-reverse"}>
                  <PasswordStrength password={watchConfirmPassword} />
                </Flex>

                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <Input.Wrapper id={"password"} size="xs" label="Confirm Password" required>
                      <PasswordInput
                        mt={4}
                        placeholder="*********"
                        onChange={onChange}
                        withAsterisk
                        value={value}
                      />
                    </Input.Wrapper>
                  )}
                  name="password_confirm"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, minLength: 8 }}
                />

                {errors.password_confirm?.type === "required" && (
                  <ValidationLabel
                    validationType="error"
                    text="Password is required"
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}

                {errors.password_confirm?.type === "minLength" && (
                  <ValidationLabel
                    validationType="error"
                    text="Password must be greater than 8 characters."
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
              </div>

              <div className="auth-submit-form">
                <Button
                  loading={loadingState === "loading"}
                  disabled={loadingState === "loading"}
                  type="submit">
                  Continue
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
