import { ChannelMappingService } from "@/lib/services/ChannelMappingService";
import { ChannelMappingRowType } from "@/ui/components/App/ChannelMapping/ChannelMappingRow";
import { LoadingStateProps } from "types/types.d";
import create from "zustand";

interface ChannelMappingStore {
  /**
   * Ordered list of Channel mappings
   */
  mappingList: Array<ChannelMappingRowType>;
  /**
   * Set list of channel mappings
   * @param mappingList
   * @returns
   */
  setMappingList: (mappingList: Array<ChannelMappingRowType>) => void;

  /**
   * Loading state
   */
  loading: LoadingStateProps;
  /**
   * Set loading state
   * @param loading
   * @returns
   */
  setLoading: (loading: LoadingStateProps) => void;
  /**
   * Fetch channel mappings from the database against the workspace
   * @param wsId
   * @returns
   */
  fetchChannelMappings: (wsId: string, mapping_id?: string) => Promise<any>;
  /**
   * Create channel mapping
   */
  createChannelMapping: (ws_id: string, payload: any) => any;
  /**
   * Update ChannelMapping
   */
  updateChannelMapping: (ws_id: string, mapping_id: string, payload: any) => any;
  /**
   * Update all mapping priorities
   */
  updateAllMappingPriorities: (ws_id: string, payload: any[]) => Promise<any>;
  /**
   * Delete segment
   */
  deleteChannelMapping: (ws_id: string, mapping_id: string) => Promise<void>;

  /**
   * Reset the store
   * @returns
   */
  reset: () => void;
}

export const useChannelMappingListStore = create<ChannelMappingStore>((set, get) => ({
  mappingList: [],
  setMappingList: (mappingList: Array<any>) => set({ mappingList }),
  loading: "idle",
  setLoading: (loading: LoadingStateProps) => set({ loading }),
  fetchChannelMappings: async (wsId: string) => {
    set({ loading: "loading" });
    await new ChannelMappingService()
      .fetchChannelMappings(wsId)
      .then((response) => {
        if (response) {
          set({ mappingList: response });
        } else {
          set({ mappingList: [] });
        }
      })
      .catch((error) => error);
    set({ loading: "loaded" });
  },
  createChannelMapping: async (ws_id: string, payload: any) => {
    set({ loading: "loading" });
    await new ChannelMappingService()
      .createChannelMapping(ws_id, payload)
      .then((response) => {
        if (response) {
          const newList = get().mappingList.concat(response);
          set({ mappingList: newList });
        }
      })
      .catch((error) => error);
    set({ loading: "loaded" });
  },
  updateChannelMapping: async (ws_id: string, mapping_id: string, payload: any) => {
    set({ loading: "loading" });
    await new ChannelMappingService()
      .updateChannelMapping(ws_id, mapping_id, payload)
      .then((response) => {
        if (response) {
          const updatedList = get().mappingList.map((item) =>
            item.id === mapping_id ? response : item
          );
          set({ mappingList: updatedList });
        }
      })
      .catch((error) => error);
    set({ loading: "loaded" });
  },
  updateAllMappingPriorities: async (ws_id: string, originalList: any[]) => {
    // Check if the priority of the mapping has changed
    // If it has changed, then send the mapping to the backend for update
    const mappingsToUpdate = originalList.filter(
      (item, index) => item.priority !== get().mappingList[index].priority
    );
    set({ loading: "loading" });
    await new ChannelMappingService()
      .updateAllMappingPriorities(ws_id, mappingsToUpdate)
      .then((response) => {
        if (response) {
          set({
            mappingList: get()
              .mappingList.map((item) => {
                const updatedItem = response.find(
                  (updatedItem: ChannelMappingRowType) => updatedItem.id === item.id
                );
                if (updatedItem) {
                  return updatedItem;
                }
                return item;
              })
              .sort((a, b) => a.priority - b.priority)
          });
        }
      })
      .catch((error) => error);
    set({ loading: "loaded" });
  },
  deleteChannelMapping: async (ws_id: string, mapping_id: string) => {
    set({ loading: "loading" });
    await new ChannelMappingService()
      .deleteChannelMapping(ws_id, mapping_id)
      .then((response) => {
        if (response) {
          set({ mappingList: response });
        }
      })
      .catch((error) => error);

    set({ loading: "loaded" });
  },

  reset: () => set({ mappingList: [], loading: "idle" })
}));
