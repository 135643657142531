import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";

import { toTitleCaseLabel } from "@/lib/utils/StringUtility";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
import { ATTRIBUTION_MODELS } from "@/lib/utils/Constants";
import cloneDeep from "lodash.clonedeep";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  BarChart,
  CanvasRenderer
]);
const ConversionsChart = ({ data, visibleModels }: { data: any; visibleModels: string[] }) => {
  const theme = useGlobalMantineTheme();
  const [loading, setLoading] = useState<boolean>(true);

  const transformData = (data: any) => {
    const sessionChannels: any[] = [];
    const linearData: any[] = [];
    const positionDecayData: any[] = [];
    const firstTouchData: any[] = [];
    const firstTouchNonDirectData: any[] = [];
    const lastTouchData: any[] = [];
    const lastTouchNonDirectData: any[] = [];
    const timedecayData: any[] = [];

    data.forEach((entry: any) => {
      sessionChannels.push(toTitleCaseLabel(entry.session_channel));
      linearData.push(entry.att_conversions_linear);
      positionDecayData.push(entry.att_conversions_position);
      firstTouchData.push(entry.att_conversions_firsttouch);
      firstTouchNonDirectData.push(entry.att_conversions_firsttouchnondirect);
      lastTouchData.push(entry.att_conversions_lasttouch);
      lastTouchNonDirectData.push(entry.att_conversions_lasttouchnondirect);
      timedecayData.push(entry.att_conversions_timedecay);
    });

    return {
      sessionChannels,
      linearData,
      firstTouchData,
      lastTouchData,
      timedecayData,
      positionDecayData,
      firstTouchNonDirectData,
      lastTouchNonDirectData
    };
  };

  const dataTransformed = useMemo(() => transformData(data), [data]);

  const getSeries = (data: any) => {
    const series = [];
    if (visibleModels.includes("first_touch")) {
      series.push({
        name: "First Touch",
        type: "bar",
        data: data.firstTouchData,
        itemStyle: {
          color: theme.colors.brand[5]
        }
      });
    }
    if (visibleModels.includes("last_touch")) {
      series.push({
        name: "Last Touch",
        type: "bar",
        data: data.lastTouchData,
        itemStyle: {
          color: theme.colors.pink[5]
        }
      });
    }
    if (visibleModels.includes("time_decay")) {
      series.push({
        name: "Time Decay",
        type: "bar",
        data: data.timedecayData,
        itemStyle: {
          color: theme.colors.orange[5]
        }
      });
    }
    if (visibleModels.includes(ATTRIBUTION_MODELS.LINEAR)) {
      series.push({
        name: "Linear",
        type: "bar",
        data: data.linearData,
        itemStyle: {
          color: theme.colors.green[6]
        }
      });
    }
    if (visibleModels.includes(ATTRIBUTION_MODELS.U_SHAPED)) {
      series.push({
        name: "U-Shaped",
        type: "bar",
        data: data.positionDecayData,
        itemStyle: {
          color: theme.colors.red[5]
        }
      });
    }
    if (visibleModels.includes("first_touch_non_direct")) {
      series.push({
        name: "First Touch Non Direct",
        type: "bar",
        data: data.firstTouchNonDirectData,
        itemStyle: {
          color: theme.colors.yellow[5]
        }
      });
    }
    if (visibleModels.includes("last_touch_non_direct")) {
      series.push({
        name: "Last Touch Non Direct",
        type: "bar",
        data: data.lastTouchNonDirectData,
        itemStyle: {
          color: theme.colors.grape[5]
        }
      });
    }
    return series;
  };

  const initialOption = {
    title: {
      text: "", // Keep empty if you do not want a title
      left: "center"
    },
    tooltip: {
      borderColor: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2],
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: theme.colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
      },
      trigger: "axis",
      axisPointer: {
        type: "shadow"
      }
    },
    legend: {
      data: [
        "Linear",
        "First Touch",
        "Last Touch",
        "Time Decay",
        "Position Decay",
        "First Touch Non Direct",
        "Last Touch Non Direct",
        "U-Shaped"
      ],
      top: "bottom",
      textStyle: {
        color: theme.colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
      }
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "6%", // Increase bottom padding for legend
      containLabel: true
    },
    xAxis: {
      type: "category",
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#595c5f"
        }
      },
      data: dataTransformed.sessionChannels,
      axisLabel: {
        rotate: 15,
        interval: 0,
        textStyle: {
          color: theme.colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
        }
      }
    },
    yAxis: {
      type: "value",
      name: "Conversions",
      nameTextStyle: {
        color: theme.colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#595c5f"
        }
      },
      axisLabel: {
        textStyle: {
          color: theme.colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
        }
      },
      splitLine: {
        lineStyle: {
          color: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[2]
        }
      }
    },
    series: [],
    replaceMerge: ["series"]
  };

  const [option, setOption] = useState(initialOption);

  useEffect(() => {
    setLoading(true);
    const newSeries = getSeries(dataTransformed);

    const newOptions = {
      ...cloneDeep(initialOption),
      series: newSeries
    };

    setOption(newOptions as any);
    setLoading(false);
  }, [visibleModels, theme.colorScheme]);

  if (loading) {
    return <>...</>;
  }
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={{ height: "350px", width: "100%" }}
    />
  );
};

export default ConversionsChart;
