// https://www.color-blindness.com/color-name-hue/

import { LooseObject } from "types/types.d";

// Using this: https://www.color-name.com/hex/01002a
export const whiteLight = "#fcfcfc";
export const whiteLighter = "#f0f0f0";
export const white = "#fff";

export const grey = "#f0f0f1";
export const greyLight = "#dce0e3";
export const greyLighter = "#b7b7b7";
export const greyDark = "#636a73";
export const greyDarker = "#01002a";
export const greyDarkest = "#020c19";

export const purple = "#7d47eb";
export const purpleLighter = "#9f72fc";
export const purpleLightest = "#9e72fc9c";

export const pink = "#fd60b7";
export const pinkLighter = "#fd60b7";

export const red = "#ff5e5e";
export const redDark = "#a00808";
export const redLight = "#ff8080";
export const yellow = "#fec107";
export const yellowLightest = "#fec10747";
export const green = "#35d57b";
export const greenDark = "#0b9f4c";

export const blue = "#35b6d5";
export const blueLight = "#ACF3FB";
export const blueDark = "#2fa6d9";

export const loaderColor = "#333354";

export const blueJeans = "#5abbe5";

export const ColorsTheme = [
  "#c84b31", // Deep orange
  "#3b8ea5", // Ocean blue
  "#f08a5d", // Soft terracotta
  "#b83b5e", // Muted fuchsia
  "#6a2c70", // Dark purple
  "#f9ed69", // Bright yellow
  "#4a6fa5", // Slate blue
  "#16db93", // Mint green
  "#efea5a", // Lemon yellow
  "#0cdbd3", // Turquoise
  "#ff99c8", // Light pink
  "#b2f7ef", // Pale turquoise
  "#390099", // Deep violet
  "#9e0059", // Rich magenta
  "#ff0054", // Bright red
  "#ff5400", // Bright orange
  "#ffbd00", // Golden yellow
  "#3f88c5", // Sky blue
  "#44cf6c", // Vivid green
  "#ff7477", // Coral
  "#ffc100", // Mustard yellow
  "#9d69a3", // Soft purple
  "#011627", // Dark navy
  "#fdfffc", // Off white
  "#2ec4b6", // Sea green
  "#cbf3f0", // Light aqua
  "#ff9f1c", // Amber
  "#e71d36", // Scarlet red
  "#264653", // Dark teal
  "#2a9d8f", // Medium teal
  "#e9c46a", // Sand yellow
  "#f4a261", // Sandy brown
  "#e76f51", // Burnt orange
  "#d62828", // Dark red
  "#023e8a", // Royal blue
  "#0077b6", // Cerulean
  "#0096c7", // Bright blue
  "#00b4d8", // Cyan
  "#48cae4", // Light blue
  "#90e0ef", // Ice blue
  "#ade8f4", // Powder blue
  "#caf0f8", // Pale blue
  "#03045e", // Navy blue
  "#007f5f", // Pine green
  "#2b9348", // Forest green
  "#55a630", // Apple green
  "#80b918", // Lime green
  "#aacc00", // Chartreuse
  "#bfd200" // Lemon lime
];

export const PieChartColorsChartsSequence: LooseObject = {
  green: ["#1A996B", "#26B774", "#35d57b", "#64E590", "#85F29F", "#AFFAB9", "#D6FCD8"],
  blue: ["#17629C", "#2282BA", "#2fa6d9", "#5FC8E8", "#81E0F3", "#ACF3FB", "#D5FBFD"],
  orange: ["#B68103", "#DAA005", "#fec107", "#FED544", "#FEE16A", "#FEEE9B", "#FEF7CD"],
  purple: ["#6033CA", "#7D47EB", "#A173F3", "#BA90F9", "#D4B6FD", "#EBDAFE"],
  pink: ["#FD60B7", "#FD87BD", "#FE9FC1", "#FEBFCF", "#FEDFE4", "#ffe6ea"]
};

export const getRandomColor = () => {
  // Generate random RGB values
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  // Convert the RGB values to a hex color string
  const hexColor =
    "#" +
    red.toString(16).padStart(2, "0") +
    green.toString(16).padStart(2, "0") +
    blue.toString(16).padStart(2, "0");

  return hexColor;
};

export const getRandomPurpleColor = () => {
  // Define the base RGB values for a purple shade
  const baseRed = 128;
  const baseGreen = 0;
  const baseBlue = 128;

  // Randomly adjust the red and blue components to get different shades of purple
  const red = Math.floor(baseRed + Math.random() * 127);
  const blue = Math.floor(baseBlue + Math.random() * 127);

  // Convert the RGB values to a hex color string
  const hexColor =
    "#" +
    red.toString(16).padStart(2, "0") +
    baseGreen.toString(16).padStart(2, "0") +
    blue.toString(16).padStart(2, "0");

  console.log(hexColor);
  return hexColor;
};
