import { Box, Button, Divider, Flex, Paper, Text, TextInput, Title, rem } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useChannelMappingListStore } from "@/stores/useChannelMappingListStore";
import { ChannelMappingQueryBuilder } from "./ChannelMappingQueryBuilder";
import { ChannelMappingRulesProps, useChannelMappingStore } from "@/stores/useChannelMappingStore";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { ALL_DEFAULT_CHANNELS } from "@/lib/utils/Constants";
import { SidePanel } from "../../Common/SidePanel/SidePanel";

export const ChannelMappingModal = () => {
  // Current workspace
  const { activeWorkspace } = useContext(AppLifecycleContext);
  // Error state for channel name
  const [channelNameError, setChannelNameError] = useState("");
  // Channel mapping store
  const [
    modalOpen,
    setModalOpen,
    id,
    priority,
    setPriority,
    rules,
    setRules,
    name,
    setName,
    reset
  ] = useChannelMappingStore((state) => [
    state.modalOpen,
    state.setModalOpen,
    state.id,
    state.priority,
    state.setPriority,
    state.rules,
    state.setRules,
    state.name,
    state.setName,
    state.reset
  ]);

  // Channel mapping list store
  const [mappingList, createChannelMapping, updateChannelMapping] = useChannelMappingListStore(
    (state) => [state.mappingList, state.createChannelMapping, state.updateChannelMapping]
  );

  // check if the modal is opened for editing
  const isEdit = id !== "";

  /**
   * Returns true if all the conditions are valid.
   * @param rules
   * @returns
   */
  const isValidChannelMapping = (rules: ChannelMappingRulesProps[]) => {
    if (rules.length === 0) {
      toast.error("Please add at least one rule.");
      return false;
    }

    const isValidRules = rules.every((rule) => {
      // All the rules should have non-empty conditions
      if (rule.conditions.length === 0) {
        toast.error("Please add conditions in all boxes.");
        return false;
      }
      return rule.conditions.some((condition) => {
        // All the conditions should have a value
        if (condition.value.length === 0) {
          toast.error("Please add values in all the conditions.");
          return false;
        }
        return true;
      });
    });
    // check if the channel name is valid
    const isValidName = name.length > 1 && !channelNameError;
    if (!isValidName) {
      toast.error("Please add a proper channel name.");
      return false;
    }

    // check if the channel name is unique
    const isUniqueName =
      mappingList.every((item) => {
        if (isEdit && item.id === id) {
          // If editing and the IDs are the same
          // It's okay if the name matches the current item's name or if the name is the same as the current item's name
          return true;
        } else {
          // If not editing or editing and the IDs don't match, the name should not match any other item's name
          return item.name.toLowerCase() !== name.trim().toLowerCase();
        }
      }) &&
      ALL_DEFAULT_CHANNELS.every((item) => item.replace("_", " ") != name.trim().toLowerCase());

    if (!isUniqueName) {
      toast.error("The channel name should be unique. You cannot use default channel names.");
      return false;
    }

    return isValidRules && isValidName && isUniqueName;
  };

  /**
   * Function to handle closing the modal
   */
  const onModalClose = () => {
    setModalOpen(false);
    reset();
  };

  /**
   * Function to handle the channel name change
   */
  const handleChannelNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    // channel name should only contain alphabets, numbers and spaces
    if (/^[a-zA-Z0-9\s]*$/.test(value)) {
      setName(value);
      setChannelNameError("");
    } else {
      setName(value);
      setChannelNameError("Only alphabets, numbers and spaces are allowed.");
    }
  };
  /**
   * Functions to handle adding, editing and deleting the Channel mapping in the state
   */
  const handleAddMapping = () => {
    // validate the channel mapping rules
    if (!isValidChannelMapping(rules)) {
      return;
    }

    // create a new mapping object
    const newMapping = {
      priority: mappingList.length + 1,
      name: name,
      rules: rules
    };
    // create the mapping
    createChannelMapping(activeWorkspace.id, newMapping);

    // close the modal
    onModalClose();
  };

  const handleEditMapping = () => {
    // validate the channel mapping rules
    if (!isValidChannelMapping(rules)) {
      return;
    }

    // create a new mapping object
    const updatedMapping = {
      name: name,
      rules: rules,
      priority: priority
    };
    // update the list
    updateChannelMapping(activeWorkspace.id, id, updatedMapping);

    // close the modal
    onModalClose();
  };

  /**
   * On component load, update the form. Added the opened in the dependency array,
   * so that the form state is updated when the modal is opened.
   */
  useEffect(() => {
    if (modalOpen && isEdit) {
      const mappingToEdit = mappingList.find((item) => item.id === id);
      if (mappingToEdit) {
        // set the form values
        setName(mappingToEdit.name);
        setPriority(mappingToEdit.priority);
        setRules(mappingToEdit.rules);
      }
    }
  }, [modalOpen, id]);

  return (
    <SidePanel
      title={
        <Title order={5} fw={600}>
          {isEdit ? (
            <span>
              Edit mapping for channel:{" "}
              <Text span fw={700}>
                {name}
              </Text>
            </span>
          ) : (
            `Add a custom channel`
          )}
        </Title>
      }
      opened={modalOpen}
      onCancel={onModalClose}
      loading={true}>
      <Paper p="sm">
        <Text mb="sm">
          Organize incoming traffic into custom channels to use them in filters and other reports.
        </Text>
        <Divider mb="sm" />
        <Flex my={4} align={"center"}>
          <Text fw={600}>
            Custom channel name{" "}
            <Text span c="red">
              *
            </Text>
          </Text>
          <TextInput
            ml="md"
            size="sm"
            id={"channelName"}
            labelProps={{
              paddingBottom: rem(8)
            }}
            placeholder="e.g Paid Facebook"
            required
            autoFocus
            data-autofocus
            data-cy="channel-name-input"
            value={name}
            onChange={handleChannelNameChange}
            error={channelNameError}
          />
        </Flex>
        <Text fw={600} my="xs">
          Choose your conditions
        </Text>
        <ChannelMappingQueryBuilder />
        <Box w={"75%"}>
          <Flex justify={"end"}>
            <Button
              onClick={isEdit ? handleEditMapping : handleAddMapping}
              data-cy="apply-mapping-btn">
              {isEdit ? "Save" : "Add"} Channel
            </Button>
          </Flex>
        </Box>
      </Paper>
    </SidePanel>
  );
};
