/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { InsightsService } from "@/lib/services/InsightsService";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { FUNNEL_TYPE_KEYS, LOADER_COLOR } from "@/lib/utils/Constants";
import { BarChart } from "@/ui/pages/Analyze/components/FunnelBarChart";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Grid,
  Group,
  HoverCard,
  Loader,
  Menu,
  Paper,
  SegmentedControl,
  Skeleton,
  Stack,
  Text,
  Title,
  Tooltip,
  Transition,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingStateProps } from "types/types.d";
import { convertDateToTimezone } from "../../../../../lib/utils/DateUtility";
import { useConversionFunnelStore } from "../../../../../stores/useConversionFunnelStore";
import { IDetailViewMenu } from "../../../../pages/Analyze/components/FunnelDetailedView/DetailViewMenuGroup";
import {
  IFunnelMeta,
  IFunnelStep
} from "../../../../pages/Analyze/components/FunnelDetailedView/FunnelDetails";
import StepsFunnel from "../../../../pages/Analyze/components/StepsFunnel";
import InsightsCard from "../Cards/InsightsCard";
import { LockedInsightService } from "@/lib/services/LockedInsightService";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import classes from "./ConversionFunnelCard.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

// import StepsFunnelVerticalArchived from "@/ui/pages/Analyze/components/StepsFunnelVertical(archived)";

interface Props {
  id: string;
  name: string;
  order?: string;
  type: "visitor" | "user" | "company";
  query: any;
  reload: () => void;
  onDetailClick?: (
    id: string,
    funnel: any,
    meta: IFunnelMeta,
    type: string,
    activeType?: IDetailViewMenu
  ) => void;
  description?: string;
  isLocked?: boolean;
  lockedByUserName?: string | null;
  lockedInsightId?: string | null;
  lockedAt?: string | null;
}

// const dummyData = {
//   sf: {
//     best_performing: {
//       key: "Direct",
//       value: 66.1,
//     },
//     worst_performing: {
//       key: "replug.io",
//       value: -14.649999999999999,
//     },
//   },
//   chf: {
//     best_performing: {
//       key: "search",
//       value: 76.55,
//     },
//     worst_performing: {
//       key: "Uncategorized Referer",
//       value: 1.5,
//     },
//   },
//   cf: {
//     best_performing: {
//       key: "Others",
//       value: 94.6,
//     },
//     worst_performing: {
//       key: "IN",
//       value: -11.799999999999997,
//     },
//   },
//   df: {
//     best_performing: {
//       key: "Desktop",
//       value: 114.55,
//     },
//     worst_performing: {
//       key: "Mobile",
//       value: 5.300000000000004,
//     },
//   },
// }

/**
 * Funnel card component, gets funnel data and displays it.
 * @param id Conversion funnel id.
 * @param name Conversion funnel name.
 * @param query Query filters (URL parameters, useEffect dependency.)
 * @param reload  Function to call when funnel is deleted.
 * @param onDetailClick
 * @returns ReactNode
 */
export const ConversionFunnelCard = ({
  id,
  name,
  description,
  isLocked,
  lockedByUserName,
  lockedInsightId,
  lockedAt,
  order,
  type,
  query,
  reload,
  onDetailClick = () => {}
}: Props) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { activeWorkspace, user: activeUser } = useContext(AppLifecycleContext);
  const [funnel, setFunnel] = useState<Array<IFunnelStep>>([]);
  const [insights, setInsights] = useState<any>(null);
  const [meta, setMeta] = useState<any>(null);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [loadingInsights, setLoadingInsights] = useState<LoadingStateProps>("idle");
  const navigate = useNavigate();
  const [showFunnel, setShowFunnel] = useState<boolean>(false);

  const [list, setFunnelStore, setUpdateFunnel, setModalOpen] = useConversionFunnelStore(
    (state) => [state.list, state.setFunnelStore, state.setUpdateFunnel, state.setModalOpen]
  );

  // Check if the plan is starter or not.
  const isStarter = useWorkspaceUtilityHook().isStarterPlan;
  const isActiveUserOwnerOrAdmin = activeWorkspace.members.some(
    (item: { user_id: any; role: { name: string } }) =>
      item.user_id === activeUser.id &&
      item.role &&
      ["owner", "administrator", "author"].includes(item.role.name)
  );

  const getTotalVisitors = useMemo(() => {
    console.log("meta?.total_visitors", meta);
    if (meta?.total_converted) {
      let total = meta?.total_converted;

      total = total.toLocaleString("en-US", {
        notation: "compact",
        compactDisplay: "short"
      });

      return total;
    }
    return 0;
  }, [meta]);

  const getTotalEntered = useMemo(() => {
    console.log("meta?.total_visitors", meta);
    if (meta?.total_visitors) {
      let total = meta?.total_visitors;

      total = total.toLocaleString("en-US", {
        notation: "compact",
        compactDisplay: "short"
      });

      return total;
    }
    return 0;
  }, [meta]);

  const fetchWebConversionFunnel = async () => {
    setLoading("loading");
    await new WebAnalyticsService()
      .funnel(activeWorkspace.id, id, query)
      .then((res) => {
        if (res?.data?.funnel_steps) {
          console.log(res.data.funnel_steps);
          setFunnel(res.data.funnel_steps);
          setMeta(res.data.meta);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          toast.error(err?.response?.data?.detail);
        }
      });
    setLoading("loaded");
  };

  const fetchInsights = async () => {
    setLoadingInsights("loading");
    await new WebAnalyticsService()
      .insights(activeWorkspace.id, id, query)
      .then((res) => {
        if (res?.data) {
          console.log("fetchInsights", res.data);
          setInsights(res.data);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong.");
      })
      .finally(() => {
        setLoadingInsights("loaded");
      });
  };

  const deleteConversionFunnel = () => {
    new InsightsService()
      .delete(activeWorkspace.id, id)
      .then((res) => {
        if (res.data) {
          reload();
          toast.success("Conversion funnel deleted successfully");
        }
      })
      .catch((err) => {});
  };

  const removeFunnelPrompt = (e: React.MouseEvent) => {
    e.stopPropagation();
    openConfirmModal({
      title: <Title order={5}>Delete conversion funnel</Title>,
      children: (
        <Text size="sm">
          Are you sure you want to delete your <span className="font-semibold">{name}</span> ?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => deleteConversionFunnel()
    });
  };

  const handleShowFunnel = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowFunnel(!showFunnel);
  };

  const handleOnEdit = (e: React.MouseEvent) => {
    e.stopPropagation();

    const funnel = list.find((funnel) => funnel.id === id);
    console.log(funnel);
    setUpdateFunnel(funnel);
    setModalOpen(true);
  };

  const handleLockClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const funnel = list.find((funnel) => {
      return funnel.id === id;
    });

    if (!funnel.is_locked && isActiveUserOwnerOrAdmin) {
      // If funnel is not locked and user is owner or admin, lock the funnel by creating a locked insight.
      await new LockedInsightService()
        .create(funnel.id, {
          is_locked: !funnel.is_locked
        })
        .then((res) => {
          if (res.data) {
            toast.success(`Conversion funnel locked successfully.`);
            reload();
          }
        })
        .catch((err) => err);
    } else if (funnel.is_locked && isActiveUserOwnerOrAdmin) {
      // If funnel is locked and user is owner or admin, unlock the funnel by deleting the locked insight.
      await new LockedInsightService()
        .delete(lockedInsightId)
        .then((res) => {
          if (res.data) {
            toast.success(`Conversion funnel unlocked successfully.`);
            reload();
          }
        })
        .catch((err) => err);
    } else {
      toast.error("Only Owner or Admin can lock/unlock the funnel");
    }
  };

  const handleDuplicateClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const funnel = list.find((funnel) => funnel.id === id);
    setFunnelStore({
      ...funnel,
      name: `${funnel.name} Copy`,
      id: null
    });
    setModalOpen(true);
  };

  const handleDetailClick = (
    id: string,
    funnel: any,
    meta: IFunnelMeta,
    activeType?: IDetailViewMenu
  ) => {
    if (isStarter) {
      return;
    }
    onDetailClick(id, funnel, meta, type, activeType);
  };

  /**
   * Get last step funnel conversion rate and show it in the header of card.
   * @returns number
   */
  const getLastStepConversionRate = () => {
    if (funnel.length > 0) {
      return (
        Math.round((funnel[funnel.length - 1].conv_rate_from_first_step + Number.EPSILON) * 100) /
        100
      );
    }
    return 0;
  };

  useEffect(() => {
    fetchWebConversionFunnel();
  }, [query]);

  useEffect(() => {
    if (showFunnel) {
      fetchInsights();
    }
  }, [showFunnel]);

  return (
    <Box
      pos={"relative"}
      style={{
        overflow: "hidden"
      }}>
      {isLocked && (
        <Box>
          <Flex justify={"flex-end"}>
            <Paper
              withBorder
              mr={"lg"}
              mb="-1rem"
              pos={"relative"}
              style={{
                zIndex: 100,
                width: "auto",
                borderRadius: "0.25rem"

                // backgroundColor: colorScheme === "dark" ? theme.colors.dark[6] : "#fff"
              }}>
              <Flex justify={"flex-start"} align={"center"} px={"xs"} py="sm">
                <FontAwesomeIcon icon={regular("lock-keyhole")} color={theme.colors.brand[4]} />
                {lockedAt && lockedByUserName && (
                  <Text
                    fw={400}
                    size={"xs"}
                    color={"dimmed"}
                    ml="sm"
                    pt="2px"
                    fs="italic"
                    lineClamp={1}>
                    {`Locked by ${lockedByUserName} (${convertDateToTimezone(
                      lockedAt,
                      activeWorkspace.timezone
                    )})`}
                  </Text>
                )}
              </Flex>
            </Paper>
          </Flex>
        </Box>
      )}
      <Paper
        withBorder
        pos={"relative"}
        style={{
          zIndex: 1
        }}>
        <Card
          withBorder
          onClick={() => setShowFunnel(!showFunnel)}
          style={{ cursor: "pointer", zIndex: 999, position: "relative" }}>
          <Grid>
            <Grid.Col span={4}>
              <Flex flex={1} align={"center"}>
                <Box
                  ta={"center"}
                  mr={"md"}
                  w={32}
                  h={32}
                  style={{
                    background:
                      colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[1],
                    color: colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
                    borderRadius: "999px"
                  }}>
                  <Flex justify={"center"} align={"center"} h={"100%"}>
                    <FontAwesomeIcon
                      icon={solid("angle-left")}
                      className="transition duration-300 ease-in-out "
                      rotation={showFunnel ? 270 : 180}
                    />
                  </Flex>
                </Box>

                <Stack gap={3}>
                  <Box
                    style={{
                      display: "flex",
                      gap: "8px"
                    }}>
                    <Title order={5} lineClamp={1}>
                      {name}
                    </Title>
                  </Box>
                  <Text fw={400} fz={13} color={"dimmed"}>
                    {description}
                  </Text>

                  <Group mt={8}>
                    <Badge radius="sm" variant="light" tt="uppercase">
                      {type} level
                    </Badge>{" "}
                    <Badge radius="sm" variant="light" tt="uppercase">
                      {order && order === "strict" ? "Strict order" : "Sequential order"}
                    </Badge>
                  </Group>

                  <Text fw={400} size={"xs"} c={"dimmed"} mt={"xs"} fs="italic">
                    Last updated at{" "}
                    {meta?.updated_at &&
                      convertDateToTimezone(meta?.updated_at, activeWorkspace.timezone)}
                    {loading === "loading" && (
                      <Skeleton
                        height={14}
                        width={"44px"}
                        mr={"xs"}
                        radius="xl"
                        display={"inline-block"}
                        style={{
                          verticalAlign: "middle"
                        }}
                      />
                    )}
                  </Text>
                </Stack>
              </Flex>
            </Grid.Col>
            <Grid.Col span={3}>
              <Flex justify={"center"} align={"center"} flex={1} h={"100%"}>
                {loading === "loading" ? (
                  <Flex direction={"column"} w={"100%"} align={"center"}>
                    <Skeleton height={18} width={"40%"} radius="xl" />
                    <Skeleton height={18} width={"50%"} radius="xl" mt={"8px"} />
                  </Flex>
                ) : (
                  <Flex w={"100%"} align={"center"} justify={"center"} direction={"column"}>
                    <Stack gap={2} align={"center"}>
                      <Text fw={700} size={"sm"} w={160} ta={"center"}>
                        {getTotalEntered} Entered
                      </Text>
                      <Text size={"sm"} c={"brand"} w={160} ta={"center"} fw={700}>
                        {getTotalVisitors}{" "}
                        {/* {type === "visitor" ? "Visitors" : type === "user" ? "Users" : "Companies"}{" "} */}
                        Completed
                      </Text>
                    </Stack>
                  </Flex>
                )}
              </Flex>
            </Grid.Col>
            <Grid.Col span={3}>
              <Flex justify={"center"} align={"center"} flex={1} h={"100%"}>
                {loading === "loading" ? (
                  <>
                    <Skeleton height={18} width={26} radius="xl" />
                  </>
                ) : (
                  <Text
                    size={"md"}
                    ta="center"
                    fw={700}
                    c={colorScheme === "dark" ? undefined : "black"}
                    // className="font-semibold"
                  >
                    <>{getLastStepConversionRate()}%</>
                  </Text>
                )}
              </Flex>
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex justify={"center"} align={"center"} flex={1} h={"100%"}>
                <Flex align={"center"} gap={"xs"}>
                  <HoverCard
                    shadow="md"
                    withArrow
                    withinPortal
                    position={"top"}
                    width={isStarter ? "270px" : "auto"}
                    styles={{
                      dropdown: {
                        padding: "6px 8px"
                      }
                    }}>
                    <HoverCard.Target>
                      <Button
                        id="deep-dive-button-main"
                        size={"xs"}
                        variant={"light"}
                        // color={colorScheme === "dark" ? "dark.0" : "gray.7"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDetailClick(id, funnel, meta);
                        }}>
                        {/* <FontAwesomeIcon icon={regular("chart-bar")} /> */}
                        Deep Dive
                      </Button>
                    </HoverCard.Target>
                    <HoverCard.Dropdown p="sm">
                      {isStarter ? (
                        <Text size="sm" color={colorScheme === "dark" ? "white" : "gray.7"}>
                          Upgrade to{" "}
                          <Text span fw={699}>
                            Pro
                          </Text>{" "}
                          or{" "}
                          <Text span fw={699}>
                            Premium
                          </Text>{" "}
                          plan to view the detailed breakdown of your conversion funnels.
                          <Anchor
                            size="sm"
                            fw={700}
                            onClick={() =>
                              navigate(`/env/${activeWorkspace.identifier}/account/billing`)
                            }>
                            Upgrade now.
                          </Anchor>
                        </Text>
                      ) : (
                        <Text size="sm" color={colorScheme === "dark" ? "white" : "gray.7"}>
                          View detailed funnel analysis
                        </Text>
                      )}
                    </HoverCard.Dropdown>
                  </HoverCard>

                  <Menu withinPortal>
                    <Menu.Target>
                      <ActionIcon
                        size={"sm"}
                        variant="subtle"
                        // color={colorScheme === "dark" ? "dark.0" : "gray.7"}
                        onClick={(e) => e.stopPropagation()}>
                        <FontAwesomeIcon icon={solid("ellipsis-vertical")} />
                      </ActionIcon>
                    </Menu.Target>

                    <Menu.Dropdown ml={-12}>
                      <Menu.Item
                        onClick={(e) => handleOnEdit(e)}
                        style={{
                          cursor: !isActiveUserOwnerOrAdmin ? "default" : "pointer"
                        }}
                        leftSection={
                          <FontAwesomeIcon
                            icon={regular("edit")}
                            color={
                              colorScheme === "dark"
                                ? !isActiveUserOwnerOrAdmin
                                  ? theme.colors.dark[2]
                                  : theme.colors.dark[0]
                                : !isActiveUserOwnerOrAdmin
                                ? theme.colors.dark[2]
                                : theme.colors.dark[9]
                            }
                          />
                        }>
                        <Tooltip
                          label={"Only Owner or Admin can edit the funnel."}
                          position="top"
                          style={{
                            backgroundColor:
                              colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.dark[9]
                          }}
                          disabled={isActiveUserOwnerOrAdmin}>
                          <Text
                            style={{
                              lineHeight: "0px"
                            }}
                            c={
                              colorScheme === "dark"
                                ? !isActiveUserOwnerOrAdmin
                                  ? theme.colors.dark[2]
                                  : theme.colors.dark[0]
                                : !isActiveUserOwnerOrAdmin
                                ? theme.colors.dark[2]
                                : theme.colors.dark[9]
                            }>
                            Edit
                          </Text>
                        </Tooltip>
                      </Menu.Item>

                      <Menu.Item
                        onClick={(e) => handleDuplicateClick(e)}
                        style={{
                          cursor: !isActiveUserOwnerOrAdmin ? "default" : "pointer"
                        }}
                        leftSection={
                          <FontAwesomeIcon
                            icon={regular("copy")}
                            color={
                              colorScheme === "dark"
                                ? !isActiveUserOwnerOrAdmin
                                  ? theme.colors.dark[2]
                                  : theme.colors.dark[0]
                                : !isActiveUserOwnerOrAdmin
                                ? theme.colors.dark[2]
                                : theme.colors.dark[9]
                            }
                          />
                        }>
                        <Tooltip
                          label={"Only Owner or Admin can duplicate the funnel."}
                          position="top"
                          style={{
                            backgroundColor:
                              colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.dark[9]
                          }}
                          disabled={isActiveUserOwnerOrAdmin}>
                          <Text
                            style={{
                              lineHeight: "0px"
                            }}
                            c={
                              colorScheme === "dark"
                                ? !isActiveUserOwnerOrAdmin
                                  ? theme.colors.dark[2]
                                  : theme.colors.dark[0]
                                : !isActiveUserOwnerOrAdmin
                                ? theme.colors.dark[2]
                                : theme.colors.dark[9]
                            }>
                            Duplicate
                          </Text>
                        </Tooltip>
                      </Menu.Item>

                      <Menu.Item
                        onClick={(e) => handleLockClick(e)}
                        style={{
                          cursor: !isActiveUserOwnerOrAdmin ? "default" : "pointer"
                        }}
                        leftSection={
                          isLocked ? (
                            <FontAwesomeIcon
                              icon={regular("lock-keyhole-open")}
                              color={
                                colorScheme === "dark"
                                  ? !isActiveUserOwnerOrAdmin
                                    ? theme.colors.dark[2]
                                    : theme.colors.dark[0]
                                  : !isActiveUserOwnerOrAdmin
                                  ? theme.colors.dark[2]
                                  : theme.colors.dark[9]
                              }
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={regular("lock-keyhole")}
                              color={
                                colorScheme === "dark"
                                  ? !isActiveUserOwnerOrAdmin
                                    ? theme.colors.dark[2]
                                    : theme.colors.dark[0]
                                  : !isActiveUserOwnerOrAdmin
                                  ? theme.colors.dark[2]
                                  : theme.colors.dark[9]
                              }
                            />
                          )
                        }>
                        <Tooltip
                          label={"Only Owner or Admin can lock/unlock the funnel"}
                          position="top"
                          style={{
                            backgroundColor:
                              colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.dark[9]
                          }}
                          disabled={isActiveUserOwnerOrAdmin}>
                          <Text
                            style={{
                              lineHeight: "0px"
                            }}
                            c={
                              colorScheme === "dark"
                                ? !isActiveUserOwnerOrAdmin
                                  ? theme.colors.dark[2]
                                  : theme.colors.dark[0]
                                : !isActiveUserOwnerOrAdmin
                                ? theme.colors.dark[2]
                                : theme.colors.dark[9]
                            }>
                            {isLocked ? "Unlock" : "Lock"}
                          </Text>
                        </Tooltip>
                      </Menu.Item>

                      <Menu.Item
                        onClick={(e) => removeFunnelPrompt(e)}
                        style={{
                          cursor: !isActiveUserOwnerOrAdmin ? "default" : "pointer"
                        }}
                        leftSection={
                          <FontAwesomeIcon
                            icon={regular("trash-alt")}
                            color={
                              colorScheme === "dark"
                                ? !isActiveUserOwnerOrAdmin
                                  ? theme.colors.red[2]
                                  : theme.colors.red[4]
                                : !isActiveUserOwnerOrAdmin
                                ? theme.colors.red[2]
                                : theme.colors.red[4]
                            }
                          />
                        }>
                        <Tooltip
                          label={"Only Owner or Admin can delete the funnel."}
                          position="top"
                          style={{
                            backgroundColor:
                              colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.dark[9]
                          }}
                          disabled={isActiveUserOwnerOrAdmin}>
                          <Text
                            style={{
                              lineHeight: "0px"
                            }}
                            c={
                              colorScheme === "dark"
                                ? !isActiveUserOwnerOrAdmin
                                  ? theme.colors.red[2]
                                  : theme.colors.red[4]
                                : !isActiveUserOwnerOrAdmin
                                ? theme.colors.red[2]
                                : theme.colors.red[4]
                            }>
                            Delete
                          </Text>
                        </Tooltip>
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Flex>
              </Flex>
            </Grid.Col>
          </Grid>
        </Card>
      </Paper>

      <Transition transition={"slide-down"} mounted={showFunnel}>
        {(styles) => (
          <Paper
            shadow="md"
            withBorder
            style={{
              ...styles,
              // borderRadius: "0.5rem", // Equivalent to the 'rounded' class
              // boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)", // Equivalent to the 'shadow-card' and 'shadow-sm' classes
              overflow: "visible", // Equivalent to the 'overflow-visible' class
              animation: "enter 0.5s ease-out" // Assuming 'animate-enter' is defined in your CSS with this animation
            }}>
            <Flex justify={"space-between"} className={classes.root}>
              <div>
                <Box
                  sx={(theme) => ({
                    borderColor:
                      colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[1]
                  })}
                  className="border border-t border-b-0 border-solid">
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      position: "relative",
                      paddingTop: "48px",
                      paddingBottom: "48px",
                      minHeight: "520px",
                      "& > div": {
                        height: funnel.length * 50 < 400 ? "400px" : `calc(50px * ${funnel.length})`
                      },
                      [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                        minHeight: "500px"
                      },

                      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                        minHeight: "400px"
                      }
                    })}
                    className="flex-1 px-4">
                    {funnel.length > 0 && loading === "loaded" ? (
                      <Box h={funnel.length * 50 < 400 ? "400px" : `calc(50px * ${funnel.length})`}>
                        <FunnelChartWrapper funnel={funnel} meta={meta} type={type} />
                      </Box>
                    ) : (
                      <>
                        {loading === "loading" ? (
                          <>
                            <div className="h-[270px] w-full flex justify-center items-center">
                              <Oval color={LOADER_COLOR} height={14} width={14} />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="h-[270px] w-full flex justify-center items-center">
                              <h3>No funnel data</h3>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </div>

              <>
                <Box
                  style={(theme) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor:
                      colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[1],
                    paddingLeft: theme.spacing.xl,
                    paddingRight: theme.spacing.xl,
                    paddingTop: "24px",
                    paddingBottom: "24px"
                  })}>
                  {loadingInsights === "loaded" && (
                    <>
                      {insights ? (
                        <Stack>
                          <Flex align={"center"} justify={"center"}>
                            <Text c="yellow" mr={"sm"}>
                              <FontAwesomeIcon icon={solid("lightbulb-on")} />{" "}
                            </Text>
                            <Title order={4}>Insights</Title>
                            <HoverCard
                              width={350}
                              shadow="md"
                              withArrow
                              position={"top-end"}
                              withinPortal>
                              <HoverCard.Target>
                                <FontAwesomeIcon
                                  className="text-gray-700 ml-2"
                                  size={"xs"}
                                  icon={regular("info-circle")}></FontAwesomeIcon>
                              </HoverCard.Target>
                              <HoverCard.Dropdown p="sm">
                                <Text>
                                  Insights are automatically generated by our AI model. For
                                  variables to be included in the insights, they must contribute
                                  more than 5% of the total funnel traffic.
                                </Text>
                              </HoverCard.Dropdown>
                            </HoverCard>
                          </Flex>

                          <InsightsCard
                            data={{
                              breakdown: "chf",
                              bestPerforming: insights.chf.best_performing,
                              worstPerforming: insights.chf.worst_performing,
                              funnelType: type
                            }}
                            hideDetailedViewButton={isStarter}
                            onViewBreakdown={() =>
                              handleDetailClick(id, funnel, meta, {
                                activeView: "breakdown",
                                selectedOption: "channel"
                              })
                            }
                          />

                          <InsightsCard
                            data={{
                              breakdown: "sf",
                              bestPerforming: insights.sf.best_performing,
                              worstPerforming: insights.sf.worst_performing,
                              funnelType: type
                            }}
                            blur={isStarter}
                            hideDetailedViewButton={isStarter}
                            onViewBreakdown={() =>
                              handleDetailClick(id, funnel, meta, {
                                activeView: "breakdown",
                                selectedOption: "source"
                              })
                            }
                            onLockClick={() => {
                              navigate(`/env/${activeWorkspace.identifier}/account/billing`);
                            }}
                          />

                          <InsightsCard
                            data={{
                              breakdown: "cf",
                              bestPerforming: insights.cf.best_performing,
                              worstPerforming: insights.cf.worst_performing,
                              funnelType: type
                            }}
                            blur={isStarter}
                            hideDetailedViewButton={isStarter}
                            onViewBreakdown={() =>
                              handleDetailClick(id, funnel, meta, {
                                activeView: "breakdown",
                                selectedOption: "location_country"
                              })
                            }
                            onLockClick={() => {
                              navigate(`/env/${activeWorkspace.identifier}/account/billing`);
                            }}
                          />

                          <InsightsCard
                            data={{
                              breakdown: "df",
                              bestPerforming: insights.df.best_performing,
                              worstPerforming: insights.df.worst_performing,
                              funnelType: type
                            }}
                            blur={isStarter}
                            hideDetailedViewButton={isStarter}
                            onViewBreakdown={() =>
                              handleDetailClick(id, funnel, meta, {
                                activeView: "breakdown",
                                selectedOption: "parsed_device"
                              })
                            }
                            onLockClick={() => {
                              navigate(`/env/${activeWorkspace.identifier}/account/billing`);
                            }}
                          />

                          <Flex align={"center"} justify={"center"} mt={"xs"}>
                            <HoverCard width={280} shadow="md" withArrow disabled={!isStarter}>
                              <HoverCard.Target>
                                <Button
                                  size="xs"
                                  variant={colorScheme === "dark" ? "default" : "light"}
                                  onClick={() => handleDetailClick(id, funnel, meta)}
                                  rightSection={
                                    <>
                                      {isStarter && (
                                        <FontAwesomeIcon
                                          icon={solid("bolt-lightning")}
                                          className="w-4 h-4 ml-1.5 hover:opacity-80"
                                        />
                                      )}
                                    </>
                                  }>
                                  Deep Dive
                                </Button>
                              </HoverCard.Target>
                              <HoverCard.Dropdown>
                                <Text size="sm">
                                  {" "}
                                  Upgrade to{" "}
                                  <Text span fw={600}>
                                    Pro
                                  </Text>{" "}
                                  or{" "}
                                  <Text span fw={600}>
                                    Premium
                                  </Text>{" "}
                                  plan to view the detailed breakdown of your conversion funnels.
                                  <Anchor
                                    fz="sm"
                                    fw={600}
                                    onClick={() =>
                                      navigate(`/env/${activeWorkspace.identifier}/account/billing`)
                                    }>
                                    Upgrade now.
                                  </Anchor>
                                </Text>
                              </HoverCard.Dropdown>
                            </HoverCard>
                          </Flex>
                        </Stack>
                      ) : (
                        <>
                          <Box h={270}>
                            <Flex align={"center"} justify={"center"}>
                              <Title order={5}>No insights data</Title>
                            </Flex>
                          </Box>
                        </>
                      )}
                    </>
                  )}

                  {loadingInsights === "loading" && (
                    <Box h={270}>
                      <Flex align={"center"} justify={"center"}>
                        <Loader size={10} mr={"xs"} />
                        <Text fz={"xs"}>Fetching Insights</Text>
                      </Flex>
                    </Box>
                  )}
                </Box>
              </>
            </Flex>
          </Paper>
        )}
      </Transition>
    </Box>
  );
};

export const FunnelChartWrapper = ({
  funnel,
  type,
  meta
}: {
  funnel: Array<IFunnelStep>;
  type: string;
  meta: IFunnelMeta;
}) => {
  // const [value, setValue] = useState<"default" | "vertical">("default");
  const [value, setValue] = useLocalStorage<"default" | "vertical">({
    key: FUNNEL_TYPE_KEYS,
    defaultValue: "default"
  });

  return (
    <Box
      pos={"relative"}
      h={"100%"}
      // styles={{
      //   "& > div:not(:first-child)": {
      //     height: funnel.length * 50 < 400 ? "400px" : `calc(50px * ${funnel.length})`
      //   }
      // }}
    >
      {/*<MultiBarChart />*/}
      <Box pos={"absolute"} right={"10px"} top={"-40px"}>
        <SegmentedControl
          value={value}
          // @ts-ignore
          onChange={(val: "default" | "vertical") => setValue(val)}
          data={[
            {
              value: "default",
              label: (
                <Center>
                  <FontAwesomeIcon
                    className="text-xl text-gray-900"
                    icon={regular("chart-simple-horizontal")}></FontAwesomeIcon>
                </Center>
              )
            },
            {
              value: "vertical",
              label: (
                <Center>
                  <FontAwesomeIcon
                    className="text-xl text-gray-900"
                    icon={regular("chart-simple")}></FontAwesomeIcon>
                </Center>
              )
            }
          ]}
        />
      </Box>
      {value === "vertical" ? (
        <BarChart bars={funnel as any} type={type} />
      ) : (
        <StepsFunnel funnelSteps={funnel} meta={meta} type={type as any} />
      )}
    </Box>
  );
};
