/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { numberToCommas, numberToUnit } from "@/lib/utils/StringUtility";
import React, { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "@/types/types.d";
import { SummaryCard } from "../SummaryCard";
import ContentLoader from "react-content-loader";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  topBarCardValues: ITopBarCardValues;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
  period: any;
  prevPeriod: any;
  comparisonPeriod?: any;
};

const EventsCard = ({
  activeCard,
  setActiveCard,
  topBarCardValues,
  comparisonTopBarCardValues,
  loading,
  query,
  period,
  prevPeriod,
  comparisonPeriod
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const getEventsCardValue = () => {
    return topBarCardValues.events < 100000
      ? numberToCommas(topBarCardValues.events)
      : numberToUnit(topBarCardValues.events);
  };

  const getComparisonPercentageDiff = () => {
    const percentageChange =
      ((topBarCardValues.events - comparisonTopBarCardValues.events) /
        comparisonTopBarCardValues.events) *
      100;
    return Math.round(percentageChange);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="events-card">
      {loading === "loaded" ? (
        <SummaryCard
          aboutTooltip="Events represent actions performed by visitors on your website. For example, loading a page, clicking a link, and completing a purchase are all interactions you can measure with events."
          activeCard={activeCard}
          title={query?.period === "live" ? "Events (Last 30 Min.)" : "Events"}
          onClick={() => setActiveCard("events")}
          uniquekey={"events"}
          card={"events"}
          value={`${getEventsCardValue()}`}
          tooltip={`
            ${numberToCommas(
              topBarCardValues.autocaptured_events
            )} autocaptured events,             ${numberToCommas(
            topBarCardValues.custom_events
          )} custom events`}
          runningDiff={topBarCardValues.events_diff}
          runningDiffTooltip={`${topBarCardValues.events_diff}% ${
            topBarCardValues.events_diff > 0 ? "increase" : "decrease"
          } in events duration from previous period ${
            comparisonPeriod ? comparisonPeriod : prevPeriod
          } `}
          loading={loading}
          borderRight={false}
          showRunningDiff={query?.period !== "live"}
          comparison={query?.comparison}
          comparisonPercentageDiff={getComparisonPercentageDiff()}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const EventsCardMemoized = React.memo(EventsCard);
export { EventsCardMemoized as EventsCard };
