import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import { getRandomColor } from "@/style/Colors";
import { AvatarWithText } from "@/ui/components/Common/AvatarWithText/AvatarWithText";
import { ExportButton } from "@/ui/components/Common/ExportButton/ExportButton";
import { RelativeDateWithText } from "@/ui/components/Common/RelativeDateWithText/RelativeDateWithText";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { Center, Flex, Loader, Table, Text } from "@mantine/core";
import { format } from "date-fns";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IReportType, LoadingStateProps } from "types/types.d";
import AppLifecycleContext from "../../../../../../lib/contexts/AppLifecycleContext";
import { PowerUsersTrendsService } from "../../../../../../lib/services/PowerUsersTrendsService";
import { downloadCSVFromResponse } from "../../../../../../lib/utils/CSVExporterUtility";
import { DATE_FORMAT, WORKSPACE_MEMBER_ROLES } from "../../../../../../lib/utils/Constants";

interface Props {
  /**
   * detailedView will be true when the most engaged users detailed view modal is opened
   */
  detailedView: boolean;
  /**
   * Function to update the detailedView value
   */
  setDetailedView: (detailedView: boolean) => void;
  /**
   * Number of days active for which the results will be shown (Optional)
   */
  daysActive?: number | undefined;
  /**
   * Insight id for which we have to show the results
   */
  insightId: string | undefined;
  /**
   * List Report type defines what type of results list we have to fetch i.e. L7, L30, most_activity
   */
  listReportType: string;
  /**
   * Report type defines what type of results list we have to fetch i.e. user | company
   */
  reportType: IReportType;
  /**
   * Starting date for which the results list is to be fetched
   */
  startDate: string;
  /**
   * Ending date for which the results list is to be fetched
   */
  endDate: string;
}

/**
 * This component shows the detailed view modal for power users of any specific day or maximum_activity.
 */
export const PowerUsersDetailedView = ({
  detailedView,
  setDetailedView,
  //  Optional and initially set to zero as we need it only for L7 and L30 users report.
  daysActive = 0,
  insightId,
  listReportType,
  reportType = "user",
  startDate,
  endDate
}: Props) => {
  // colors for the avatar
  const colors = useMemo(() => {
    return Array.from(Array(100).keys()).map((i) => getRandomColor());
  }, []);

  // Get user role in the workspace.
  const { hasRolesNotWith } = useWorkspaceUtilityHook();

  // Current active workspace
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [filters] = useSegmentStore((state) => [state.filters]);

  // Loading and Exporting state for async fetch and export call.
  const [exporting, setIsExporting] = useState("idle");
  const [loading, setLoading] = useState<LoadingStateProps>("idle");

  //List to set the results of power users of any specific day or maximum_activity.
  const [list, setList] = useState([]);
  const navigate = useNavigate();

  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);

  // Service call to fetch power users | companies of selected day or maximum_activity.
  const fetchPowerUsers = async () => {
    setLoading("loading");
    await new PowerUsersTrendsService()
      .export(
        activeWorkspace.id,
        insightId,
        listReportType,
        reportType,
        startDate,
        endDate,
        daysActive,
        "list",
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        const updatedList = res.data.map((item: any) => {
          return {
            ...item,
            color: colors[Math.floor(Math.random() * 100)]
          };
        });
        console.log("result", updatedList);
        setList(updatedList);
      })
      .catch((err) => console.log("error", err));
    setLoading("loaded");
  };

  // Service call to export power users | companies as CSV.
  const exportPowerUsers = async () => {
    const powerService = new PowerUsersTrendsService();
    setIsExporting("loading");
    try {
      const response = await powerService.export(
        activeWorkspace.id,
        insightId,
        listReportType,
        reportType,
        startDate,
        endDate,
        daysActive,
        "csv",
        filters.rules,
        filters.combinator,
        filters.groups
      );
      if (response.data) {
        //Setting filename of the exported file in accordance with the date, reportType, listReportType and time of export.
        let filename = `power_${reportType}_list_${listReportType}_${format(
          new Date(),
          DATE_FORMAT
        )}.csv`;
        //Utility function to download the csv from response.
        downloadCSVFromResponse(response, filename);
        return true;
      }
      setIsExporting("loaded");
    } catch (err) {
      setIsExporting("loaded");
      throw new Error("400");
    }
    return false;
  };

  //Initial service call when the detailed view is set to true.
  useEffect(() => {
    if (detailedView) fetchPowerUsers();
  }, [detailedView]);

  const title = `Power ${reportType == "user" ? "users" : "companies"} list for ${
    reportType == "user"
      ? listReportType.replaceAll("_", " ")
      : listReportType.replaceAll("_", " ").replace("users", "companies")
  } ${
    listReportType !== "most_activity_users"
      ? ` who are active for ${daysActive} ${daysActive === 1 ? "day" : "days"}`
      : ""
  }`;

  return (
    <>
      <SidePanel
        opened={detailedView}
        onCancel={() => setDetailedView(false)}
        loading={loading === "loaded"}
        title={title}>
        {loading === "loading" ? (
          <>
            <Center my="md">
              <Loader size={"xs"} />
            </Center>
          </>
        ) : (
          <>
            {/*Export button which will allow the user to export list as csv. It will be set to disabled if it's in the loading state*/}
            <Flex direction="row-reverse">
              {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                <ExportButton
                  onClick={exportPowerUsers}
                  disabled={exporting === "loading"}
                  loading={exporting === "loading"}></ExportButton>
              )}
            </Flex>
            {/*If the list has some results then the results will be shown*/}
            {list.length > 0 ? (
              <>
                <Table>
                  <Table.Thead>
                    {reportType == "user" ? (
                      <Table.Tr>
                        <Table.Th>Name</Table.Th>
                        <Table.Th>Email</Table.Th>
                        <Table.Th>Last seen</Table.Th>
                        <Table.Th>Created at</Table.Th>
                      </Table.Tr>
                    ) : (
                      <Table.Tr>
                        <Table.Th>Name</Table.Th>
                        <Table.Th>Users</Table.Th>
                        <Table.Th>Last seen</Table.Th>
                        <Table.Th>Created at</Table.Th>
                      </Table.Tr>
                    )}
                  </Table.Thead>
                  <Table.Tbody>
                    {list.map((item: any, index) =>
                      reportType == "user" ? (
                        <Fragment key={`user-${index}`}>
                          {/*On clicking any user, it will navigate to the overview of that user*/}
                          <Table.Tr
                            onClick={
                              () => setOverviewModalOpen(true, "user", item.user_id)
                              // navigate(
                              //   `/env/${activeWorkspace.identifier}/users/everyone/${item.user_id}`
                              // )
                            }
                            key={`power-user-list:${item.user_email}`}
                            className={"hover:underline transition-all ease-in-out cursor-pointer"}>
                            {/* <div className="col-span-2">{item.user_id}</div> */}
                            <Table.Td>
                              <AvatarWithText
                                color={item.color}
                                text={item.user_first_name + " " + item.user_last_name}
                              />
                            </Table.Td>
                            <Table.Td>{item.user_email || "..."}</Table.Td>
                            <Table.Td>
                              <RelativeDateWithText text={item.last_visited_time} />
                            </Table.Td>
                            <Table.Td>
                              <RelativeDateWithText text={item.user_created_at} />
                            </Table.Td>
                          </Table.Tr>
                        </Fragment>
                      ) : (
                        <Fragment key={`company-${index}`}>
                          {/*On clicking any company, it will navigate to the overview of that company*/}
                          <Table.Tr
                            onClick={() =>
                              // navigate(
                              //   `/env/${activeWorkspace.identifier}/companies/everyone/${item.company_id}`
                              // )
                              setOverviewModalOpen(true, "company", item.company_id)
                            }
                            key={`power-user-list:${item.company_id}`}
                            className={"hover:underline transition-all ease-in-out cursor-pointer"}>
                            {/* <div className="col-span-2">{item.user_id}</div> */}
                            <Table.Td>
                              <AvatarWithText color={item.color} text={item.company_name} />
                            </Table.Td>
                            <Table.Td>
                              <Text c="dimmed" fw={500}>
                                {item.number_of_users} {item.number_of_users > 1 ? "users" : "user"}
                              </Text>
                            </Table.Td>
                            <Table.Td>
                              <RelativeDateWithText text={item.last_visited_time} />
                            </Table.Td>
                            <Table.Td>
                              <RelativeDateWithText text={item.company_created_at} />
                            </Table.Td>
                          </Table.Tr>
                        </Fragment>
                      )
                    )}
                  </Table.Tbody>
                </Table>
              </>
            ) : (
              loading !== "loaded" && (
                <Center my="md">
                  <Loader size={"xs"} />
                </Center>
              )
            )}
          </>
        )}
      </SidePanel>
    </>
  );
};
