import { DATE_FORMAT } from "@/lib/utils/Constants";
import { differenceInHours, format, subDays, subHours } from "date-fns";
import { ITimeSeriesData } from "./WebAnalyticsOverview";

export const getPrevPeriod = (query: any, dateFormat: string = "dd MMM, yyyy") => {
  let prevPeriod =
    query.from_date && query.to_date
      ? `${subHours(
          new Date(query.from_date.replace(" ", "T")),
          differenceInHours(
            new Date(query.to_date.replace(" ", "T")),
            new Date(query.from_date.replace(" ", "T"))
          )
        )}`
      : subDays(new Date(), 30);

  const prevPeriodStartDate = format(new Date(prevPeriod), dateFormat);
  const prevPeriodEndDate = format(
    new Date(query.from_date ? query.from_date.replace(" ", "T") : new Date()),
    dateFormat
  );
  const previousPeriodDate = `${prevPeriodStartDate} - ${prevPeriodEndDate}`;
  return previousPeriodDate;
};

export const getFormattedPeriod = (
  from_date: string | null,
  to_date: string | null,
  dateFormat: string = "dd MMM, yyyy"
) => {
  const startDate = from_date ? format(new Date(from_date), dateFormat) : null;
  const endDate = to_date ? format(new Date(to_date), dateFormat) : null;
  const period = `${startDate} - ${endDate}`;
  return period;
};

export const getComparisonFormattedPeriod = (query: any) => {
  const { comparison_from_date, comparison_to_date } = query;
  const dateFormat = "dd MMM yy";
  const formattedDate = getFormattedPeriod(comparison_from_date, comparison_to_date, dateFormat);
  return formattedDate;
};

/**
 * This function is used to get the default reporting domain.
 *
 * Steps followed:
 * 1. If the workspace has only one domains, its the default domain.
 * 2. If it has more than 1 domain, we check that the first index is "all_domains" or not. If all domains, then we check the reporting domain is set by the user.
 * 3. If the reporting domain is set by the user, then we use that as the default domain.
 * 4. If the reporting domain is not set by the user, then we use the first element from the list of domains.
 *
 * @param domains List of domains from the response.
 * @param defaultReportingDomain Active workspace reporting domain.
 * @returns defaultDomain
 */
export const getActiveDomainValue = (domains: any, defaultReportingDomain: string): string => {
  let defaultDomain;
  if (domains[0].value === "all_domains") {
    // Check if the response has the active workspace reporting_domain
    if (domains.some((d: any) => d.value === defaultReportingDomain)) {
      defaultDomain = defaultReportingDomain;
    } else {
      // Set the first domain from the list.
      defaultDomain = domains.length > 1 ? domains[1].value : domains[0].value;
    }
  } else {
    defaultDomain = domains[0].value;
  }
  return defaultDomain;
};

export const getChartLineTransformedData = ({
  data,
  period,
  partial = false,
  complete = false,
  comparison = false
}: {
  data: any;
  period: string;
  partial?: boolean;
  complete?: boolean;
  comparison?: boolean;
}) => {
  if (complete) {
    return data.histogram.map((item: any, index: number) => {
      return [item.date, item.count];
    });
  } else if (comparison) {
    return data.histogram.map((item: any, index: number) => {
      return [item.date, item.count];
    });
  } else {
    let slice_start = 0;
    let slice_end = data.histogram.length - 1;
    // let current_date = format(zonedTimeToUtc(new Date(), "Asia/Karachi"), "yyyy-MM-dd");
    let current_date = format(new Date(), "yyyy-MM-dd");
    let today = data.histogram[0].date.split(" ")[0] === current_date;
    // setting 0 hours for now.
    // let current_time = format(subHours(zonedTimeToUtc(new Date(), "Asia/Karachi"), 0), "yyyy-MM-dd HH:00:00");
    let current_time = format(subHours(new Date(), 0), "yyyy-MM-dd HH:00:00");
    const current_time_index = data.histogram.findIndex(
      (item: { date: string }) => item.date === current_time
    );

    // if period is hour and today is true, then we need to slice the array from 0 to current_time_index
    if (period === "hour") {
      slice_end = today ? current_time_index : slice_end;
    }

    if (partial === true) {
      slice_start = period === "hour" && today ? current_time_index - 1 : data.histogram.length - 2;
      slice_end = period === "hour" && today ? current_time_index + 1 : data.histogram.length;
    }

    let slicedData = data.histogram
      .slice(slice_start, slice_end)
      .map((item: any, index: number) => {
        return [item.date, item.count];
      });
    return slicedData;
  }
};

export const sortByPercentageChange = (data: any, sortField: string, order = "asc") => {
  const sortedData = [...data];

  sortedData.sort((a, b) => {
    const changeA = a[sortField];
    const changeB = b[sortField];

    if (order === "asc") {
      return changeA - changeB;
    } else if (order === "desc") {
      return changeB - changeA;
    } else {
      throw new Error("Invalid sorting order parameter");
    }
  });

  return sortedData;
};

export const exportTopStats = (data: ITimeSeriesData) => {
  const {
    visitors: { histogram: visitors },
    new_visitors: { histogram: newVisitors },
    returning_visitors: { histogram: returningVisitors },
    pageviews: { histogram: pageviews },
    sessions: { histogram: sessions },
    visit_duration: { histogram: visitDuration },
    bounce_rate: { histogram: bounceRate },
    events: { histogram: events },
    autocaptured_events: { histogram: autocapturedEvents },
    custom_events: { histogram: customEvents }
  } = data;

  const csvData = visitors.map((entry: any) => {
    const date = entry.date;
    const visitorsCount = entry.count;
    const newVisitorsCount = newVisitors.find((item: any) => item.date === date)?.count || 0;
    const returningVisitorsCount =
      returningVisitors.find((item: any) => item.date === date)?.count || 0;
    const pageviewsCount = pageviews.find((item: any) => item.date === date)?.count || 0;
    const sessionsCount = sessions.find((item: any) => item.date === date)?.count || 0;
    const visitDurationAvg = visitDuration.find((item: any) => item.date === date)?.count || 0;
    const bounceRateValue = bounceRate.find((item: any) => item.date === date)?.count || 0;
    const eventsCount = events.find((item: any) => item.date === date)?.count || 0;
    const autocapturedEventsCount =
      autocapturedEvents.find((item: any) => item.date === date)?.count || 0;
    const customEventsCount = customEvents.find((item: any) => item.date === date)?.count || 0;

    return {
      date,
      visitorsCount,
      newVisitorsCount,
      returningVisitorsCount,
      pageviewsCount,
      sessionsCount,
      visitDurationAvg,
      bounceRateValue,
      eventsCount,
      autocapturedEventsCount,
      customEventsCount
    };
  });

  // Header name mapping
  const headerMapping: any = {
    date: "Date",
    visitorsCount: "Visitors",
    newVisitorsCount: "New Visitors",
    returningVisitorsCount: "Returning Visitors",
    pageviewsCount: "Pageviews",
    sessionsCount: "Sessions",
    visitDurationAvg: "Average Visit Duration",
    bounceRateValue: "Bounce Rate",
    eventsCount: "Events",
    autocapturedEventsCount: "Autocaptured Events",
    customEventsCount: "Custom Events"
  };

  // Convert csvData to CSV format
  const csvHeader = Object.keys(headerMapping)
    .map((key: any) => headerMapping[key])
    .join(",");
  const csvRows = csvData.map((row) =>
    Object.entries(row)
      .map(([key, value]) => value)
      .join(",")
  );
  const csvContent = [csvHeader, ...csvRows].join("\n");

  // Create a Blob object with the CSV content
  const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a temporary URL for the Blob object
  const csvUrl = URL.createObjectURL(csvBlob);

  // filename
  let filename = `top_section_stats_${format(new Date(), DATE_FORMAT)}.csv`;

  // Create a temporary link element
  const link = document.createElement("a");
  link.setAttribute("href", csvUrl);
  link.setAttribute("download", filename);

  // Append the link to the document and click it to trigger the download
  document.body.appendChild(link);
  link.click();
};
