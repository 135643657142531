import { SegmentedControl, useMantineTheme } from "@mantine/core";
import * as echarts from "echarts/core";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import { FC, useState } from "react";
import { BarGraphWrapper } from "@/ui/components/App/EChartsWrappers/BarGraphWrapper";
import {
  TrendsGraphBody,
  TrendsGraphContainer,
  TrendsGraphHeader,
  TrendsGraphHeaderH3,
  TrendsGraphHeaderLeft,
  TrendsGraphHeaderParagraph,
  TrendsGraphHeaderRight
} from "@/ui/components/App/TrendsGraph/TrendsGraph.style";
import { IReportType } from "../../../../../../types/types.d";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";

interface SlippingAwayUsersHistogramProps {
  slippingAwayUsersCount: Array<{ period: string; users: number }>;
  isCountLoading: string;
  totalUsers: any;
  handleSlippingAwayUsersModal: (days: string) => void;
  reportType?: IReportType;
}

export const SlippingAwayUsersHistogram: FC<SlippingAwayUsersHistogramProps> = ({
  handleSlippingAwayUsersModal,
  slippingAwayUsersCount,
  isCountLoading,
  totalUsers,
  reportType = "user"
}) => {
  const [valueType, setValueType] = useState("numbers");
  const keysType = valueType === "numbers" ? "users" : "Users Ratio";
  const maxValue = valueType === "numbers" ? totalUsers : 100;
  const getPeriod = (data: any): string => {
    console.log(data, slippingAwayUsersCount);
    let slippingPeriod = slippingAwayUsersCount.find((x) => x.period === data);
    return slippingPeriod ? slippingPeriod.period.replaceAll(" ", "_").toLowerCase() : "7_days";
  };
  const showSlippingAwayUsersModal = (data: any) => {
    handleSlippingAwayUsersModal(getPeriod(data));
  };
  const getCategoriesData = () => {
    return slippingAwayUsersCount.map((i) => i.period);
  };
  const getSeriesData = () => {
    return [
      {
        type: "bar",
        name: `Slipping Away ${reportType == "user" ? "Users" : "Companies"}`,
        data: slippingAwayUsersCount.map((item: any) => {
          return valueType === "numbers" ? item.users || 0 : item["Users Ratio"] || 0;
        }),
        itemStyle: {
          borderRadius: [4, 4, 0, 0]
        },
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgb(125, 71, 235, 0.7)" },
          { offset: 0.5, color: "rgb(125, 71, 235, 0.8)" },
          { offset: 1, color: "rgb(125, 71, 235, 1)" }
        ])
      }
    ];
  };

  return (
    <>
      <InsightSection
        title={`Slipping away ${reportType == "user" ? "users" : "companies"}`}
        description={
          <>
            {reportType == "user" ? "Users" : "Companies"} who were not last seen for 7, 14, 30, 60,
            and 90 days.
          </>
        }
        rightSection={
          <SegmentedControl
            value={valueType}
            size={"xs"}
            onChange={setValueType}
            data={[
              { label: "View absolute numbers", value: "numbers" },
              { label: "View percentage", value: "ratio" }
            ]}
          />
        }>
        <div>
          {isCountLoading === "loaded" ? (
            <>
              <BarGraphWrapper
                categories={getCategoriesData()}
                series={getSeriesData()}
                xAxisName="No. of sessions in days"
                onClick={(params) => showSlippingAwayUsersModal(params.name)}
                showPercentage={valueType !== "numbers"}
                yAxisName={
                  valueType === "numbers"
                    ? reportType === "user"
                      ? "Users"
                      : "Companies"
                    : "Percentage"
                }
              />
            </>
          ) : (
            <>
              <BarChartSkeleton />
            </>
          )}
        </div>
      </InsightSection>
    </>
  );
};
