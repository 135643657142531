/**
 * Common Contact Section component.
 */
import { getIntlDateWithoutWeekday } from "@/lib/utils/DateUtility";
import { getCountryIcon } from "@/lib/utils/IconsUtility";
import { getCountryName } from "@/lib/utils/Locale";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  AvatarProps,
  Box,
  Center,
  Flex,
  Group,
  Loader,
  Tooltip as MantineTooltip,
  Paper,
  Text,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import HeatMap from "@uiw/react-heat-map";
import Tooltip from "@uiw/react-tooltip";
import React, { useEffect } from "react";
import classes from "./ContactSection.module.css";
import { IEngagement } from "./EngagementSection";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
export interface IContactSection {
  contact: (IContact & IEngagement) | null;
  loading: boolean;
}

export interface IContact {
  /**
   * Specify the contact section title.
   * This is the title that will be displayed on the top of the contact section next to the icon/avatar.
   */
  title: string;

  /**
   * Specify the contact section avatar.
   * Refer to https://mantine.dev/core/avatar/ for more details.
   */
  avatar?: AvatarProps;

  /**
   * Specify the contact section subtitle.
   * This is the subtitle that will be displayed below the title.
   */
  subtitle: string | React.ReactNode;

  /**
   * Specify the contact section company name.
   * This is the company name that will be displayed below the subtitle.
   */
  company_name: string;

  /**
   * Specify the company id used to fetch the company details.
   */
  company_id: string;

  /**
   * Specify the contact section city.
   */
  city: string;

  /**
   * Specify the contact section country.
   * This is the country that will be displayed below the company name.
   */
  country: string;

  /**
   * Specify the heat map array.
   */
  heatmap_events_array: IHeatMapItem[];
}

interface IHeatMapItem {
  date: string;
  count: number;
}

const ContactSection = ({ contact, loading }: IContactSection) => {
  const [heatMapValues, setHeatMapValues] = React.useState<IHeatMapItem[]>([]);
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  /**
   * Set heat map values to scaled when contact changes.
   */
  useEffect(() => {
    setHeatMapValues(scaleHeatMapValues(contact?.heatmap_events_array || []));
  }, [contact]);

  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 126);

  const scaleHeatMapValues = (eventsArray: IHeatMapItem[]) => {
    const minCount = Math.min(...eventsArray.map((data) => data.count));
    const maxCount = Math.max(...eventsArray.map((data) => data.count));

    // change the heatmap values to be scaled down to the range 0 - 32
    const scaledHeatMapValues = eventsArray.map((data) => {
      const scaledCount = Math.round(((data.count - minCount) / (maxCount - minCount)) * 32);
      return { ...data, count: scaledCount, content: data.count };
    });
    return scaledHeatMapValues;
  };

  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);

  // merge the default avatar with the passed avatar
  const avatarProps: AvatarProps = {
    variant: "filled",
    radius: "xl",
    size: "sm",
    color: "brand",
    ...contact?.avatar
  };

  return (
    <Paper
      // className="px-4 py-8 shadow-sm mb-4 flex border border-solid "
      withBorder
      px="sm"
      py="md"
      shadow="xs">
      {loading ? (
        <>
          <Flex w={"100%"} flex={1} justify={"center"} align={"center"} h={180}>
            <Loader size={"xs"} />
          </Flex>
        </>
      ) : (
        <>
          {contact ? (
            <Flex direction={"column"} justify={"space-around"} w={"100%"} py="sm">
              <Flex justify={"start"} mb="xs" align={"center"} direction={"column"}>
                <Group gap="xs" justify="center" align="center" mb="sm">
                  <Avatar
                    {...avatarProps}
                    className={"uppercase"}
                    styles={{ placeholder: { wordBreak: "normal" } }}
                  />

                  <Title order={5} ta="center" tt="capitalize">
                    {contact.title}
                  </Title>
                </Group>
                {contact.subtitle && (
                  <Group gap="xs" mb={4} wrap="nowrap">
                    <FontAwesomeIcon icon={regular("envelope")} />
                    <Text c="dimmed" className="break-all line-clamp-1">
                      {contact.subtitle}
                    </Text>
                  </Group>
                )}
                {contact.company_name && contact.company_name != "..." && (
                  <Group gap="xs" mb={4} wrap="nowrap">
                    <FontAwesomeIcon icon={regular("buildings")} />
                    <MantineTooltip
                      bg={colorScheme === "dark" ? theme.colors.dark[8] : ""}
                      label="View company"
                      withArrow>
                      <Text
                        c="dimmed"
                        onClick={() => {
                          // navigate(
                          //   `/env/${activeWorkspace.identifier}/companies/everyone/${contact.company_id}`
                          // );
                          setOverviewModalOpen(true, "company", contact.company_id);
                        }}
                        tt="capitalize"
                        className={classes.company}>
                        {contact.company_name}
                      </Text>
                    </MantineTooltip>
                  </Group>
                )}
                {contact.country && (
                  <MantineTooltip
                    label={`${contact.city ? contact.city : ""} ${getCountryName(
                      contact.country
                    )}`}>
                    <Group gap="xs" mb={4} align="center" wrap="nowrap">
                      <Center>
                        <Box mr={4} w={16}>
                          {getCountryIcon(contact.country, 12)}
                        </Box>
                      </Center>

                      <Text c="dimmed" lineClamp={1}>
                        {contact.city ? `${contact.city}, ` : ""}
                        {getCountryName(contact.country)}
                      </Text>
                    </Group>
                  </MantineTooltip>
                )}
                <Flex
                  align={"center"}
                  justify={"center"}
                  mt="md"
                  w={"100%"}
                  className={classes.heatmap}>
                  <div>
                    <Paper
                      pt="lg"
                      h="9.5rem"
                      w={"21rem"}
                      p="xs"
                      bg={colorScheme === "dark" ? "dark.7" : "gray.0"}>
                      <Flex align={"center"} justify={"center"}>
                        <HeatMap
                          value={heatMapValues || []}
                          width="100%"
                          weekLabels={false}
                          legendCellSize={0}
                          rectRender={(props, data) => {
                            return (
                              <Tooltip
                                content={`${getIntlDateWithoutWeekday(data.date)} events count: ${
                                  data.content || 0
                                }`}>
                                <rect {...props} />
                              </Tooltip>
                            );
                          }}
                          style={{
                            color: "inherit",
                            maxWidth: "260px",
                            width: "100%"
                          }}
                          startDate={defaultStartDate}
                          endDate={new Date()}
                          panelColors={
                            colorScheme === "dark"
                              ? {
                                  0: "#888888",
                                  8: "#e6db97",
                                  4: "#e4d28b",
                                  12: "#d6be5c",
                                  32: "#d4ae50"
                                }
                              : {
                                  0: "#EBEDF0",
                                  8: "#e6db97",
                                  4: "#e4d28b",
                                  12: "#d6be5c",
                                  32: "#d4ae50"
                                }
                          }
                          rectProps={{
                            rx: 2, // radius for each rectangle
                            style: {
                              cursor: "default"
                            }
                          }}
                        />
                      </Flex>
                    </Paper>
                  </div>
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <Flex justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
              <Text>No details found.</Text>
            </Flex>
          )}
        </>
      )}
    </Paper>
  );
};

export default ContactSection;
