import axios from './JWTService';

export class WorkspaceHiddenMenuService {
  private readonly _endpoint;
  constructor() {
    this._endpoint = '/v1/workspaces_hidden_menu';
  }

  getHiddenMenuItems = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this._endpoint}/${workspaceId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  deleteHiddenMenuItems = (workspaceId: string, itemId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${this._endpoint}/${workspaceId}/${itemId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  createHiddenMenuItem = (workspaceId: string, itemId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/`, {
          workspace_id: workspaceId,
          menu_item: itemId,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  createOrDeleteHiddenMenuItemsWithSubmenu = (workspaceId: string, itemId: string, subMenuItemList: string[], isVisible: Boolean): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${workspaceId}/submenu`, {
          menu_item: itemId,
          sub_menu_list: subMenuItemList,
          is_visible: isVisible
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
