/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Paper, SimpleGrid, Text, useMantineColorScheme } from "@mantine/core";
import { useIntersection, useLocalStorage } from "@mantine/hooks";
import { addDays, addMonths, differenceInDays, format, parseISO, startOfMonth } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import {
  GenericComparisonTooltip,
  GenericTooltip,
  GenericTooltipBar,
  getDateFormatFromPeriod,
  xAxisLabelFormatter
} from "@/lib/utils/EChartsTooltipUtility";
import { LS_GRAPH_PREFERENCE } from "@/lib/utils/Storage";
import cloneDeep from "lodash.clonedeep";
import { useContext, useEffect, useRef, useState } from "react";
import { useWebFiltersStore } from "@/stores/useWebFiltersStore";
import { purple } from "@/style/Colors";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import { BarGraphWrapper } from "../EChartsWrappers/BarGraphWrapper";
import { NoResults } from "../NoResults/NoResults";
import { ChartSwitch } from "./ChartSwitch";
import { getRangeByDateAndInterval } from "./Filters/FiltersUtility";
import {
  getChartLineTransformedData,
  getComparisonFormattedPeriod,
  getFormattedPeriod,
  getPrevPeriod
} from "./WebAnalyticsUtilities";
import { UniqueVisitorsConvCard } from "./ConverisonGoals/ConversionCards/UniqueVisitors/UniqueVisitorsConvCard";
import { UniqueConversions } from "./ConverisonGoals/ConversionCards/UniqueConversions/UniqueConversions";
import { TotalConversions } from "./ConverisonGoals/ConversionCards/TotalConversions/TotalConversions";
import { ConversionRate } from "./ConverisonGoals/ConversionCards/ConversionRate/ConversionRate";
import { ConversionValue } from "./ConverisonGoals/ConversionCards/ConversionValue/ConversionValue";
import {
  UniqueVisitorsConvComparisonCard
} from "./ConverisonGoals/ConversionCards/UniqueVisitors/UniqueVisitorsConvComparisonCard";
import {
  UniqueConversionsComparison
} from "./ConverisonGoals/ConversionCards/UniqueConversions/UniqueConversionsComparison";
import {
  TotalConversionsComparison
} from "./ConverisonGoals/ConversionCards/TotalConversions/TotalConversionsComparison";
import { ConversionRateComparison } from "./ConverisonGoals/ConversionCards/ConversionRate/ConversionRateComparison";
import { ConversionValueComparison } from "./ConverisonGoals/ConversionCards/ConversionValue/ConversionValueComparison";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const DEFAULT_CONVERSION_GOAL_STATS_DATA = {
  unique_visitors: 0,
  unique_visitors_diff: 0,
  total_conversions: 0,
  total_conversions_diff: 0,
  unique_conversions: 0,
  unique_conversions_diff: 0,
  conversion_rate: 0,
  conversion_rate_diff: 0,
  total_conversion_value: 0,
  total_conversion_value_diff: 0
};
export const ConversionGoalsOverview = ({ query, setQuery }: any) => {
  const [activeTab, setActiveTab] = useState<ActiveCardProps>("unique_conversions");
  const [hasBecomeVisibleYet, setHasBecomeVisibleYet] = useState(false);

  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [chartType, setChartType] = useLocalStorage<"line" | "bar">({
    key: LS_GRAPH_PREFERENCE,
    defaultValue: "bar",
    getInitialValueInEffect: false
  });
  const [dateLabel, setDateLabel] = useWebFiltersStore((state) => [
    state.dateLabel,
    state.setDateLabel
  ]);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [dataHistogram, setDataHistogram] = useState<any>({
    color: [purple],
    title: {
      text: ""
    },
    notMerge: true,
    tooltip: {
      borderColor: colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2],
      backgroundColor: colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
      },
      trigger: "axis",
      axisPointer: {
        type: "line"
      },
      formatter: (params: any) => GenericTooltip(params, false)
    },
    legend: {
      show: false,
      data: []
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      top: "7%",
      left: "0%",
      right: "3%",
      bottom: "5%",
      containLabel: true
    },
    xAxis: [
      {
        type: "time",
        axisLine: {
          show: false
        },
        axisLabel: {
          formatter: "{MMM} {dd}",
          fontSize: 10,
          axisPointer: {
            show: false
          },
          margin: 14
        },
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        min: 0,
        type: "value",
        splitLine: true,
        alignTicks: true,
        axisTick: {
          show: true,
          splitNumber: 4
        }
      }
    ],
    series: [],
    replaceMerge: ["series"]
  });
  const [dataBars, setDataBars] = useState<any>({
    categories: [],
    series: []
  });
  const [data, setData] = useState<{
    unique_visitors: number;
    unique_visitors_diff: number;
    total_conversions: number;
    total_conversions_diff: number;
    unique_conversions: number;
    unique_conversions_diff: number;
    conversion_rate: number;
    conversion_rate_diff: number;
    total_conversion_value: number;
    total_conversion_value_diff: number;
  }>(DEFAULT_CONVERSION_GOAL_STATS_DATA);
  const [comparisonData, setComparisonData] = useState<{
    unique_visitors: number;
    unique_visitors_diff: number;
    total_conversions: number;
    total_conversions_diff: number;
    unique_conversions: number;
    unique_conversions_diff: number;
    conversion_rate: number;
    conversion_rate_diff: number;
    total_conversion_value: number;
    total_conversion_value_diff: number;
  }>(DEFAULT_CONVERSION_GOAL_STATS_DATA);

  const fetchConversionGoalStats = async () => {
    setLoading("loading");
    await new WebAnalyticsService()
      .conversionGoalStats(activeWorkspace.id, query)
      .then((res) => {
        if (res.data) {
          setData(res.data.stats);
          res.data.stats_comparison && setComparisonData(res.data.stats_comparison);
          if (res.data.histogram) {
            storeEventsLineData(res);
            storeEventsBarData(res);
          }
          if (res.data.is_track_unique_conversion) {
            setActiveTab("unique_conversions");
          } else {
            setActiveTab("total_conversions");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        // setData(DEFAULT_CONVERSION_GOAL_STATS_DATA);
        // setDataBars([])
        // setDataHistogram([])
      });
    setLoading("loaded");
  };

  /** Store data */
  const storeEventsLineData = (res: any) => {
    const isTrackUniqueConversion = res.data.is_track_unique_conversion; // true or false

    let currentPeriodData = res.data.histogram;
    currentPeriodData = currentPeriodData.map(
      (item: { date: any; unique_conversions: any; total_conversions: any }) => ({
        date: item.date,
        count: isTrackUniqueConversion ? item.unique_conversions : item.total_conversions
      })
    );

    let comparisonPeriodData = res.data.histogram_comparison;
    if (comparisonPeriodData) {
      comparisonPeriodData = comparisonPeriodData.map(
        (item: { date: any; unique_conversions: any; total_conversions: any }) => ({
          date: item.date,
          count: isTrackUniqueConversion ? item.unique_conversions : item.total_conversions
        })
      );
    }

    const { from_date, to_date, comparison, period, custom_range } = query;
    let series;
    let currentLineData = getChartLineTransformedData({
      data: { histogram: currentPeriodData },
      period
    });
    let currentPartialLineData = getChartLineTransformedData({
      data: { histogram: currentPeriodData },
      period,
      partial: true
    });
    let completeCurrentLineData = getChartLineTransformedData({
      data: { histogram: currentPeriodData },
      period,
      complete: true
    });
    let comparisonLineData: any[] = [];
    let remappedComparisonLineData: any[][] = [];

    if (period === "hour" || period === "live") {
      currentLineData = currentLineData.map(([dateStr, value]: [string, number]) => [
        format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        value
      ]);
      currentPartialLineData = currentPartialLineData.map(([dateStr, value]: [string, number]) => [
        format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        value
      ]);
      completeCurrentLineData = completeCurrentLineData.map(
        ([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          value
        ]
      );
    } else {
      currentLineData = currentLineData.map(([dateStr, value]: [string, number]) => [
        format(new Date(dateStr), "yyyy-MM-dd"),
        value
      ]);
      currentPartialLineData = currentPartialLineData.map(([dateStr, value]: [string, number]) => [
        format(new Date(dateStr), "yyyy-MM-dd"),
        value
      ]);
      completeCurrentLineData = completeCurrentLineData.map(
        ([dateStr, value]: [string, number]) => [format(new Date(dateStr), "yyyy-MM-dd"), value]
      );
    }

    if (comparisonPeriodData) {
      const parsedFromDate = new Date(from_date);
      const parsedEndDate = new Date(to_date);
      const total_days = differenceInDays(parsedEndDate, parsedFromDate) + 1;
      comparisonLineData = getChartLineTransformedData({
        data: { histogram: comparisonPeriodData },
        period,
        comparison: true
      });
      comparisonLineData = comparisonLineData.map(([dateStr, value]: [string, number]) => [
        format(new Date(dateStr), "yyyy-MM-dd"),
        value
      ]);

      // NOTE: This is only for This Month Filter
      const thisMonthLabel = `${format(startOfMonth(new Date()), "MMM dd")} - ${format(
        new Date(),
        "MMM dd"
      )}`;
      const isThisMonth = [thisMonthLabel, "This Month"].includes(dateLabel);
      const isLabelMismatch = !["Last 7 days", "Last 14 days", "Last 21 days"].includes(dateLabel);
      if (
        isThisMonth &&
        !custom_range &&
        comparison === "previous_period" &&
        period === "day" &&
        total_days < 30 &&
        isLabelMismatch
      ) {
        remappedComparisonLineData = comparisonLineData.map(([dateStr, value]) => [
          format(addMonths(new Date(dateStr), 1), "yyyy-MM-dd"),
          value
        ]);
      } else {
        let lastKnownDate =
          completeCurrentLineData.length > 0
            ? parseISO(completeCurrentLineData[completeCurrentLineData.length - 1][0])
            : null;

        function isDate(date: Date | null): date is Date {
          return date !== null;
        }

        remappedComparisonLineData = comparisonLineData.map((item, idx) => {
          if (completeCurrentLineData[idx]) {
            return [completeCurrentLineData[idx][0], item[1]];
          } else {
            if (isDate(lastKnownDate)) {
              lastKnownDate = addDays(lastKnownDate, 1);
              return [format(lastKnownDate, "yyyy-MM-dd"), item[1]];
            } else {
              return item;
            }
          }
        });
      }
    }

    if (res.data.present_index !== -1) {
      series = [
        comparisonPeriodData &&
        comparisonPeriodData.length && {
          type: "line",
          name: "Comparison Conversions",
          color: "#E7DBFF",
          smooth: false,
          lineStyle: {
            width: 0
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(125, 71, 235, 0.4)"
              },
              {
                offset: 1,
                color: "rgba(125, 71, 235, 0.01)"
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: remappedComparisonLineData
        },
        {
          type: "line",
          name: "Current Conversions",
          color: "#7D47EB",
          smooth: false,
          lineStyle: {
            width: 2
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(125, 71, 235, 0.6)"
              },
              {
                offset: 1,
                color: "rgba(125, 71, 235, 0.01)"
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: currentLineData
        },
        {
          type: "line",
          name: "Current Partial Conversions",
          color: "#7D47EB",
          smooth: false,
          lineStyle: {
            width: 2,
            type: "dashed"
          },
          itemStyle: {
            emphasis: {
              label: {
                show: false
              }
            }
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(125, 71, 235, 0.3)"
              },
              {
                offset: 1,
                color: "rgba(125, 71, 235, 0.01)"
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: currentPartialLineData
        }
      ];
    } else {
      series = [
        comparisonPeriodData &&
        comparisonPeriodData.length && {
          type: "line",
          name: "Comparison Conversions",
          color: "#E7DBFF",
          smooth: false,
          lineStyle: {
            width: 0
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(125, 71, 235, 0.4)"
              },
              {
                offset: 1,
                color: "rgba(125, 71, 235, 0.01)"
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: remappedComparisonLineData
        },
        {
          type: "line",
          name: "Current Conversions",
          color: "#7D47EB",
          smooth: false,
          lineStyle: {
            width: 2
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(125, 71, 235, 0.6)"
              },
              {
                offset: 1,
                color: "rgba(125, 71, 235, 0.01)"
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: completeCurrentLineData
        }
      ];
    }
    query.comparison
      ? (dataHistogram.legend = {
        bottom: "-1%",
        data: ["Current Conversions", "Comparison Conversions"],
        icon: "circle"
      })
      : (dataHistogram.legend = {
        show: false
      });
    dataHistogram.series = series;
    dataHistogram.xAxis[0].axisLabel.formatter = (params: any) => {
      return xAxisLabelFormatter(query.period, params);
    };
    dataHistogram.tooltip.formatter = (params: any) =>
      query.comparison
        ? GenericComparisonTooltip(
          params,
          true,
          getDateFormatFromPeriod(query.period),
          query.period,
          query,
          `${isTrackUniqueConversion ? "Unique" : "Total"} Conversions`
        )
        : GenericTooltip(params, false, getDateFormatFromPeriod(query.period), query.period);

    setDataHistogram(cloneDeep(dataHistogram));
  };

  const storeEventsBarData = (res: any) => {
    let categories = res.data.histogram.map((item: any) => {
      const dateValue = !item.date.includes(" ") ? `${item.date} 00:00:00` : item.date;
      return query?.period === "hour"
        ? format(new Date(dateValue.replace(" ", "T")), "H")
        : query.period === "month"
          ? format(new Date(dateValue.replace(" ", "T")), "MMMM")
          : query?.period === "live"
            ? format(fromZonedTime(new Date(dateValue.replace(" ", "T")), "UTC"), "k:mm")
            : format(fromZonedTime(new Date(dateValue.replace(" ", "T")), "UTC"), "ccc, MMM d");
    });
    let series = [
      {
        type: "bar",
        name: "Unique Conversions",
        data: res.data.histogram.map((item: any) => {
          const dateValue = !item.date.includes(" ") ? `${item.date} 00:00:00` : item.date;
          return {
            value: res.data.is_track_unique_conversion
              ? item.unique_conversions
              : item.total_conversions,
            date: dateValue
          };
        }),
        itemStyle: {
          borderRadius: [4, 4, 0, 0]
        },
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgb(125, 71, 235, 0.7)" },
          { offset: 0.5, color: "rgb(125, 71, 235, 0.8)" },
          { offset: 1, color: "rgb(125, 71, 235, 1)" }
        ])
      }
    ];

    setDataBars({
      categories: categories,
      series: series
    });
  };

  /** Render graphs */
  const renderEventsBarGraph = () => {
    let tickValues = [];

    if (query?.period !== "hour") {
      if (dataBars.length > 10) {
        const indexSkip = Math.floor(dataBars.length / 7);
        tickValues = dataBars.map((item: any, index: number) => {
          console.log(index, indexSkip, index % indexSkip);
          if (index % indexSkip === 0) {
            return dataBars[index].date;
          }
          return null;
        });
        tickValues = tickValues.filter((item: any) => item !== null);
      }
    }

    return (
      <>
        {dataBars.hasOwnProperty("series") && dataBars.series.length > 0 ? (
          <>
            <BarGraphWrapper
              height="300px"
              categories={dataBars.categories}
              series={dataBars.series}
              onClick={(params: any) => handleOnClick(params)}
              grid={{
                top: "5%",
                left: "0%",
                right: "0%",
                bottom: "0%",
                containLabel: true
              }}
              customTooltipFormatter={(params: any) =>
                GenericTooltipBar(
                  params,
                  false,
                  getDateFormatFromPeriod(query.period),
                  query.period
                )
              }
            />
          </>
        ) : (
          <>
            <div className="pt-28">
              <NoResults heading={"No results"} text={"There is no activity"} />
            </div>
          </>
        )}
      </>
    );
  };

  const renderEventsLineGraph = () => {
    return (
      <>
        {dataHistogram.series.length > 0 ? (
          <ReactEChartsCore
            style={{ height: "300px" }}
            echarts={echarts}
            option={dataHistogram}
            notMerge={true}
          />
        ) : (
          <div className="pt-28">
            <NoResults heading={"No results"} text={"There is no activity"} />
          </div>
        )}
      </>
    );
  };

  const handleOnClick = (params: any) => {
    if (query.period !== "hour" && query.period !== "live") {
      const { from_date, to_date } = getRangeByDateAndInterval(params.data.date, query.period);
      setQuery({
        ...query,
        period: query.period === "day" ? "hour" : "day",
        from_date: from_date,
        to_date: to_date
      });
      if (query.period === "day") {
        setDateLabel(format(new Date(from_date), "MMM d, yyyy"));
      } else {
        setDateLabel(
          `${format(new Date(from_date), "MMM d")} - ${format(new Date(to_date), "MMM d")} `
        );
      }
    }
  };

  const formattedPeriod = getFormattedPeriod(query.from_date, query.to_date, "dd MMM yy");
  const formattedPrevPeriod = getPrevPeriod(query, "dd MMM yy");
  const containerRef = useRef();
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 0
  });

  useEffect(() => {
    if (entry?.isIntersecting && !hasBecomeVisibleYet) {
      setHasBecomeVisibleYet(true);
      fetchConversionGoalStats();
    }
  }, [entry?.isIntersecting]);

  useEffect(() => {
    if (hasBecomeVisibleYet && entry?.isIntersecting) {
      fetchConversionGoalStats();
    } else {
      setHasBecomeVisibleYet(false);
    }
  }, [query, activeWorkspace.id]);
  return (
    <>
      <Paper ref={ref} shadow="xs" withBorder p="lg" radius={"sm"} mih={450}>
        <Flex direction="column" component="div">
          {/* Current Period Cards Section */}
          {query.comparison && query?.period !== "live" && (
            <>
              <Text fz="xs" ml={10} fw={500}>
                Current Period: <Box component="span">{formattedPeriod}</Box>
              </Text>
            </>
          )}
          <Flex pos="relative">
            <SimpleGrid
              // cols={5}
              // spacing="md"
              // breakpoints={[
              //   { maxWidth: "xl", cols: 5, spacing: "md" },
              //   { maxWidth: "lg", cols: 4, spacing: "md" },
              //   { maxWidth: "md", cols: 3, spacing: "md" },
              //   { maxWidth: "sm", cols: 2, spacing: "sm" },
              //   { maxWidth: "xs", cols: 2, spacing: "sm" }
              // ]}
              cols={{ base: 2, sm: 2, md: 3, lg: 4, xl: 5 }}
              spacing={{ base: "sm", sm: "md", md: "md", lg: "md", xl: "md" }}
              w={"100%"}>
              <UniqueVisitorsConvCard
                activeCard={activeTab}
                loading={loading}
                data={data}
                comparisonData={comparisonData}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
              <UniqueConversions
                activeCard={activeTab}
                loading={loading}
                data={data}
                comparisonData={comparisonData}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
              <TotalConversions
                activeCard={activeTab}
                loading={loading}
                data={data}
                comparisonData={comparisonData}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
              <ConversionRate
                activeCard={activeTab}
                loading={loading}
                data={data}
                comparisonData={comparisonData}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
              <ConversionValue
                activeCard={activeTab}
                loading={loading}
                data={data}
                comparisonData={comparisonData}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
                activeWorkspace={activeWorkspace}
              />
            </SimpleGrid>

            {!query.comparison && <ChartSwitch chartType={chartType} setChartType={setChartType} />}
          </Flex>

          {query.comparison && (
            <>
              {query?.period !== "live" && (
                <Text fz="xs" fw={500} ml={10} mb={"xs"} mt={"sm"}>
                  Comparison Period:{" "}
                  <Box component="span">{getComparisonFormattedPeriod(query)}</Box>
                </Text>
              )}
              <div className="flex relative mt-2">
                <SimpleGrid
                  // cols={5}
                  // spacing="md"
                  // breakpoints={[
                  //   { maxWidth: "xl", cols: 5, spacing: "md" },
                  //   { maxWidth: "lg", cols: 4, spacing: "md" },
                  //   { maxWidth: "md", cols: 3, spacing: "md" },
                  //   { maxWidth: "sm", cols: 2, spacing: "sm" },
                  //   { maxWidth: "xs", cols: 2, spacing: "sm" }
                  // ]}
                  cols={{ base: 2, sm: 2, md: 3, lg: 4, xl: 5 }}
                  spacing={{ base: "sm", sm: "sm", md: "md", lg: "md", xl: "md" }}
                  w={"100%"}
                >
                  <UniqueVisitorsConvComparisonCard
                    activeCard={activeTab}
                    loading={loading}
                    comparisonData={comparisonData}
                  />
                  <UniqueConversionsComparison loading={loading} comparisonData={comparisonData} />
                  <TotalConversionsComparison loading={loading} comparisonData={comparisonData} />
                  <ConversionRateComparison loading={loading} comparisonData={comparisonData} />
                  <ConversionValueComparison
                    loading={loading}
                    comparisonData={comparisonData}
                    activeWorkspace={activeWorkspace}
                  />
                </SimpleGrid>
              </div>
            </>
          )}

          <div className="mt-4 hidden sm:flex flex-col h-[25rem] py-4 relative ">
            <div className="h-[23rem]">
              {loading === "loaded" ? (
                <>{chartType === "bar" ? renderEventsBarGraph() : renderEventsLineGraph()}</>
              ) : (
                <>
                  <div>
                    <BarChartSkeleton />
                  </div>
                </>
              )}
            </div>
          </div>
        </Flex>
      </Paper>
    </>
  );
};
