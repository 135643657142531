/* eslint-disable react-hooks/exhaustive-deps */
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Select, Tooltip } from "@mantine/core";
import { PLAN_TYPES } from "@/lib/utils/Constants";
import orderBy from "lodash/orderBy";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSegmentDropdownStore } from "@/stores/useSegmentDropdownStore";
import { useSegmentListStore } from "@/stores/useSegmentListStore";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";

interface Props {
  defaultValue?: string | undefined;
  onChange: (item: string | undefined) => void;
  type?: "all" | "visitor" | "user" | "company";
}
export const SegmentDropdownMenu = ({ onChange, defaultValue = "", type = "all" }: Props) => {
  // Get segment from the params.
  let { segment } = useParams<{
    segment: string;
  }>();

  // Active workspace
  const { activeWorkspace } = useContext(AppLifecycleContext);
  // Starter plan check from useWorkspaceUtilityHook
  const { isStarterPlan } = useWorkspaceUtilityHook();

  /**
   * Segments list store.
   */
  const [segments, fetchSegments] = useSegmentListStore((state) => [
    state.segments,
    state.fetchSegments
  ]);

  /** Segments dropdown store */
  const [selected, setSelected] = useSegmentDropdownStore((state) => [state.id, state.setId]);

  /**
   * Handle on change event.
   * @param item
   */
  const handleOnChange = (item: any) => {
    setSelected(item || undefined);
    onChange(item);
  };
  /**
   * Mapping the segments to the select component.
   */
  const mapSegmentsList = () => {
    const segmentsList = orderBy(segments, ["name"], ["asc"]);
    return segmentsList.map((segment: any) => {
      return {
        label: segment.name,
        value: segment.id
      };
    });
  };
  /**
   * Fetch segments on mount and reload if the type changes.
   */
  useEffect(() => {
    fetchSegments(activeWorkspace.id, type);
  }, [type]);

  /**
   * If the segment is everyone, clear the selected segment.
   */
  useEffect(() => {
    if (segment === "everyone") {
      setSelected("");
    }
  }, [segment]);

  useEffect(() => {
    // if the default value available, set it.
    if (defaultValue) {
      setSelected(defaultValue);
    }

    // clear the selected segment.
    return () => {
      setSelected("");
    };
  }, []);
  return (
    <>
      <>
        <Tooltip
          events={{ hover: true, focus: true, touch: true }}
          withArrow
          w={180}
          multiline={true}
          disabled={activeWorkspace?.organization?.plan.name !== PLAN_TYPES.STARTER}
          label="Upgrade your plan to premium to use Segments.">
          <div>
            <Select
              nothingFoundMessage="No segments found"
              leftSection={
                <Box fz="xs">
                  <FontAwesomeIcon icon={regular("chart-pie")} />
                </Box>
              }
              disabled={isStarterPlan}
              size="xs"
              clearable
              allowDeselect
              data={mapSegmentsList()}
              value={selected}
              searchable
              onChange={handleOnChange}
              placeholder="Filter by segment"
            />
          </div>
        </Tooltip>
      </>
    </>
  );
};
