/* eslint-disable react-hooks/exhaustive-deps */
import { WorkspacePinnedEventsService } from "@/lib/services/WorkspacePinnedEventsService";
import { truncateText } from "@/lib/utils/StringUtility";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import { DateDropdownMenu } from "@/ui/components/App/Dropdowns/DateDropdownMenu";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  Paper,
  SegmentedControl,
  SimpleGrid,
  Space,
  Text,
  Title,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { format, startOfDay, subDays } from "date-fns";
import { useContext, useEffect, useRef, useState } from "react";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import { EventsService } from "../../../../lib/services/EventsService";
import { DATE_FORMAT } from "../../../../lib/utils/Constants";
import { ClickhouseDateToLocalRelativeTime } from "../../../../lib/utils/DateUtility";
import { LooseObject } from "../../../../types/types.d";
import { NoResults } from "../../../components/App/NoResults/NoResults";
import { EventsBreakdownByPage } from "./EventsBreakdownByPage";
import { EventsDetailedActivityHistogramCard } from "./EventsDetailedActivityHistogramCard";
import { EventsDetailedPropertyCard } from "./EventsDetailedPropertyCard";
import { EventsDetailedUsageCard } from "./EventsDetailedUsageCard";

type CustomEventDetailedActivityProps = {
  /**
   * Pinned event id, optional for custom events
   */
  pinnedEventId?: string;
  /**
   * eventName for which detailed activity is to be shown
   */
  eventName: string;
  /**
   * First Occurance of the selected event
   */
  firstOccurred: string;
  /**
   * Last Occurance of the selected event
   */
  lastOccurred: string;
  /**
   * Event type for which detailed activity is to be shown
   */
  eventType: "custom";
  /**
   * Insight Usage for pinned events, optional for custom events
   */
  insightUsage?: Array<LooseObject>;
  /**
   * Segment Usage for pinned events, optional for custom events
   */
  segmentUsage?: Array<LooseObject>;
  /**
   * eventDetailedActivityView will be true when the events detailed activity modal is opened
   */
  eventDetailedActivityView: boolean;
  /**
   * Function to update the eventDetailedActivityView value
   */
  setEventDetailedActivityView: (eventDetailedActivityView: boolean) => void;
};

type PinnedEventDetailedActivityProps = {
  pinnedEventId: string;
  eventName: string;
  firstOccurred: string;
  lastOccurred: string;
  eventType: "pinned";
  insightUsage: Array<LooseObject>;
  eventDetailedActivityView: boolean;
  setEventDetailedActivityView: (eventDetailedActivityView: boolean) => void;
};

type Props = CustomEventDetailedActivityProps | PinnedEventDetailedActivityProps;

/**
 * This component shows the detailed activities for individual event
 */
export const EventsDetailedActivity = ({
  pinnedEventId = "",
  eventName,
  firstOccurred,
  lastOccurred,
  eventType = "custom",
  insightUsage = [],
  eventDetailedActivityView,
  setEventDetailedActivityView
}: Props) => {
  const [eventsActivity, setEventsActivity] = useState<Array<LooseObject>>([]);
  const [toggleId, setToggleId] = useState(-1);
  const [loading, setLoading] = useState("idle");
  const [loadMoreLoading, setLoadMoreLoading] = useState("idle");
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const page = useRef(0);
  const [hasMore, setHasMore] = useState(true);
  const [fromDate, setFromDate] = useState<string>(
    format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT)
  );
  const [toDate, setToDate] = useState<string>(format(new Date(), DATE_FORMAT));

  const [activityTab, setactivityTab] = useState<"activity" | "breakdown_by_page" | string>(
    "activity"
  );

  const fetchCustomEventActivity = async () => {
    await new EventsService()
      .individualEventDetailedActivity(
        activeWorkspace.id,
        eventName,
        page.current,
        fromDate,
        toDate
      )
      .then((res) => {
        if (res.data) {
          if (res.data.data.length === 0) {
            setHasMore(false);
          } else {
            setEventsActivity(
              page.current === 1 ? res.data.data : eventsActivity.concat(res.data.data)
            );
          }
        }
      })
      .catch((err) => {
        setEventsActivity([]);
      });
  };

  const fetchPinnedEventActivity = async () => {
    await new WorkspacePinnedEventsService()
      .detailedActivity(activeWorkspace.id, pinnedEventId, page.current, fromDate, toDate)
      .then((res) => {
        if (res.data) {
          if (res.data.data.length === 0) {
            setHasMore(false);
          } else {
            setEventsActivity(
              page.current === 1 ? res.data.data : eventsActivity.concat(res.data.data)
            );
          }
        }
      })
      .catch((err) => {
        setEventsActivity([]);
      });
  };

  const fetchEventActivity = async () => {
    page.current = page.current + 1;
    if (page.current === 1) {
      setLoading("loading");
    } else {
      setLoadMoreLoading("loading");
    }
    eventType === "custom" ? await fetchCustomEventActivity() : await fetchPinnedEventActivity();
    setLoading("loaded");
    setLoadMoreLoading("loaded");
  };

  useEffect(() => {
    if (eventDetailedActivityView) {
      // on reload, we need to reset the page and eventsActivity
      page.current = 0;
      setEventsActivity([]);
      fetchEventActivity();
      setHasMore(true);
    }
  }, [eventDetailedActivityView, fromDate, toDate]);

  const handleToggleEvent = (id: number) => {
    setToggleId(id === toggleId ? -1 : id);
  };

  const handleDateChange = (fromDate: string, toDate: string) => {
    // fetchIndividualEventHistogram(startDate, endDate)
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);

  const title = (
    <Flex justify={"center"} align={"center"} pos="relative">
      <Title order={5} fw={600}>
        {eventType === "custom" ? "Custom" : "Pinned"} Event: {eventName}
      </Title>
    </Flex>
  );

  const renderEventProperties = (event: LooseObject) => {
    return (
      <>
        <Paper withBorder>
          <Grid data-cy="event-detailed-activity-list-toggle-titles" fw={500} fz="sm" p={"sm"}>
            <Grid.Col span={4}>Key</Grid.Col>
            <Grid.Col span={8}>Value</Grid.Col>
            {event.event_attributes &&
              Object.keys(JSON.parse(event.event_attributes)).map((key) => (
                <>
                  <Grid.Col span={4}>{key}</Grid.Col>
                  <Grid.Col span={8}>
                    {typeof JSON.parse(event.event_attributes)[key] === "object" ? (
                      <>{JSON.stringify(JSON.parse(event.event_attributes)[key])}</>
                    ) : (
                      <>{JSON.parse(event.event_attributes)[key]}</>
                    )}
                  </Grid.Col>
                </>
              ))}

            {event.event_attributes &&
              Object.keys(JSON.parse(event.event_attributes)).length === 0 && (
                <Grid.Col span={12}>
                  <Text p={16} ta="center">
                    No properties available against the event.
                  </Text>
                </Grid.Col>
              )}
          </Grid>
        </Paper>
      </>
    );
  };

  return (
    <>
      <SidePanel
        opened={eventDetailedActivityView}
        onCancel={() => setEventDetailedActivityView(false)}
        loading={true}
        title={title}>
        <Flex wrap={"wrap"} mb="sm" align={"center"}>
          <Group gap="sm" flex={1}>
            <Badge variant="outline">First Occurred {firstOccurred}</Badge>
            <Badge variant="outline">Last Occurred {lastOccurred}</Badge>
          </Group>
          {eventType === "custom" && (
            <Box data-cy="event-detailed-activity-histogram-date-dropdown">
              <DateDropdownMenu onChange={handleDateChange} />
            </Box>
          )}
        </Flex>
        <SimpleGrid cols={{ base: 2, md: 2, sm: 1 }} spacing={{ base: "sm", md: "sm" }}>
          <EventsDetailedActivityHistogramCard
            title={"Event Activity"}
            eventName={eventName}
            fromDate={fromDate}
            toDate={toDate}
            eventType={eventType}
            pinnedEventId={pinnedEventId}
            handleDateChange={handleDateChange}
          />
          {eventType === "custom" ? (
            <EventsDetailedPropertyCard eventName={eventName} fromDate={fromDate} toDate={toDate} />
          ) : (
            <EventsDetailedUsageCard insightUsage={insightUsage} />
          )}
        </SimpleGrid>
        <Space h="md" />
        <Flex justify={"start"} mt="md">
          <SegmentedControl
            value={activityTab}
            size={"xs"}
            onChange={setactivityTab}
            data={[
              { label: "Event Activity", value: "activity" },
              { label: "Breakdown by Page", value: "breakdown_by_page" }
            ]}
          />
        </Flex>

        {/* Detailed activity section */}

        {activityTab === "activity" ? (
          <div
            className="um-settings-wrapper p-0 overflow-y-auto"
            data-cy="event-detailed-activity-section">
            <Paper withBorder shadow="sm" component="div" p="md">
              {loading === "loaded" ? (
                <>
                  {eventsActivity.length > 0 && (
                    <>
                      <Grid
                        mb="sm"
                        fw={500}
                        fz="sm"
                        data-cy="event-detailed-activity-section-titles">
                        <Grid.Col span={3}>User</Grid.Col>
                        <Grid.Col span={3}>Time</Grid.Col>
                        <Grid.Col span={eventType === "custom" ? 4 : 6}>Page</Grid.Col>
                        {eventType === "custom" && <Grid.Col span={2}>Properties</Grid.Col>}
                      </Grid>
                      <Divider py="md" />
                    </>
                  )}

                  {eventsActivity.length > 0 &&
                    eventsActivity.map((event: LooseObject, index: number) => (
                      <>
                        <Grid component="div" data-cy="event-detailed-activity-list" mb="md">
                          <Grid.Col span={3}>
                            <Flex align={"center"}>
                              {event.user_first_name && event.user_last_name ? (
                                <Avatar size="sm" radius={"xl"} color="brand">
                                  {event.user_first_name[0] + event.user_last_name[0]}
                                </Avatar>
                              ) : event.user_first_name ? (
                                <Avatar size="sm" radius={"xl"} color="brand">
                                  {event.user_first_name[0]}
                                </Avatar>
                              ) : (
                                <Avatar size="sm" radius={"xl"} color="brand" />
                              )}

                              {event.user_id ? (
                                <Flex
                                  ml={"xs"}
                                  direction={"column"}
                                  onClick={() => {
                                    setEventDetailedActivityView(false);
                                    setOverviewModalOpen(true, "user", event.user_id);
                                  }}>
                                  <Text>{event.user_first_name + " " + event.user_last_name}</Text>
                                  <Text fz="xs" c="dimmed">
                                    {event.user_email}
                                  </Text>
                                </Flex>
                              ) : (
                                <Flex direction={"column"}>
                                  <Text>Anonymous</Text>
                                  <Text className="line-clamp-2" c="dimmed" fz="xs">
                                    {event.user_anonymous_id}
                                  </Text>
                                </Flex>
                              )}
                            </Flex>
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Flex direction={"row"}>
                              {/* <Badge radius={"sm"} color="gray.6" variant="outline" mb={4} mr="xs">
                                {event.event_type}
                              </Badge> */}
                              <Text lineClamp={1} fz="xs" c="dimmed">
                                {ClickhouseDateToLocalRelativeTime(event._timestamp)}
                              </Text>
                            </Flex>
                          </Grid.Col>

                          <Grid.Col span={eventType === "custom" ? 4 : 6}>
                            <Tooltip
                              label={event.page_title}
                              disabled={event.page_title.length < 70}>
                              <Text>{truncateText(event.page_title, 70)}</Text>
                            </Tooltip>
                            <Tooltip multiline label={event.url} disabled={event.url.length < 70}>
                              <Text
                                fz="xs"
                                truncate
                                onClick={() => window.open(event.url, "_blank")}
                                c="dimmed">
                                {truncateText(event.url, 70)}
                              </Text>
                            </Tooltip>
                          </Grid.Col>

                          {eventType === "custom" && (
                            <Grid.Col span={1} onClick={() => handleToggleEvent(index)}>
                              <>
                                <Button
                                  size="xs"
                                  variant="subtle"
                                  data-cy="event-detailed-activity-list-toggle"
                                  fw={500}>
                                  <Text span fw={500} pr={4}>
                                    View
                                  </Text>
                                  {!event.event_attributes
                                    ? "-"
                                    : Object.keys(JSON.parse(event.event_attributes)).length === 0
                                    ? "-"
                                    : Object.keys(JSON.parse(event.event_attributes)).length}
                                  {Object.keys(JSON.parse(event.event_attributes)).length > 0 && (
                                    <Text pl={4} span fw={500}>
                                      {Object.keys(JSON.parse(event.event_attributes)).length === 1
                                        ? "property"
                                        : "properties"}
                                    </Text>
                                  )}
                                </Button>
                              </>
                            </Grid.Col>
                          )}

                          {toggleId === index && (
                            <Grid.Col span={12}>{renderEventProperties(event)}</Grid.Col>
                          )}
                        </Grid>
                      </>
                    ))}

                  {eventsActivity && eventsActivity.length === 0 && (
                    <Center py="md">
                      <NoResults
                        text={"There is no event activity available."}
                        heading={"No event activity"}></NoResults>
                    </Center>
                  )}
                </>
              ) : (
                <>
                  <Center py="lg">
                    <Loader size={"xs"} />
                  </Center>
                </>
              )}

              {hasMore && eventsActivity.length > 0 && (
                <Center>
                  {/* Load more option to fetch the remaining results */}
                  <Button
                    my={"md"}
                    size="sm"
                    loading={loadMoreLoading === "loading"}
                    disabled={loadMoreLoading === "loading"}
                    variant="outline"
                    color="gray.7"
                    onClick={() => fetchEventActivity()}>
                    Load more
                  </Button>
                </Center>
              )}
            </Paper>
          </div>
        ) : (
          <EventsBreakdownByPage
            id={pinnedEventId}
            eventType={eventType}
            eventName={eventName}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
      </SidePanel>
    </>
  );
};
