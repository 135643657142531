import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Box, Flex, SegmentedControl, Tooltip } from "@mantine/core";

interface Props {
  chartType: "line" | "bar";
  setChartType: (type: "line" | "bar") => void;
}

export const ChartSwitch = ({ chartType, setChartType }: Props) => {
  const handle = (type: "line" | "bar") => {
    setChartType(type);
  };

  return (
    <Flex align={"center"} right={0} className="hidden sm:flex right-0 items-center">
      <Tooltip label="Bar Graph" position="top">
        <ActionIcon
          variant="subtle"
          color={chartType === "bar" ? "brand" : "gray.7"}
          onClick={() => handle("bar")}>
          <FontAwesomeIcon
            className="text-xl text-gray-900"
            icon={regular("chart-simple")}></FontAwesomeIcon>
        </ActionIcon>
      </Tooltip>
      <Tooltip label="Line Graph" position="top">
        <ActionIcon
          variant="subtle"
          color={chartType === "line" ? "brand" : "gray.9"}
          onClick={() => handle("line")}>
          <FontAwesomeIcon
            className="text-xl text-gray-900"
            icon={regular("chart-line")}></FontAwesomeIcon>
        </ActionIcon>
      </Tooltip>
    </Flex>
  );
};
