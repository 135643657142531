import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { convertSecondsToReadableFormat } from "@/lib/utils/StringUtility";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Flex,
  Group,
  Loader,
  Paper,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { DecodedValueMap, QueryParamConfig } from "serialize-query-params";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface IConversionTimeDistribution {
  id: string;
  selectedType: "visitor" | "user" | "company";
  dateRange: DecodedValueMap<{
    start_date: QueryParamConfig<string | null | undefined, string | null | undefined>;
    end_date: QueryParamConfig<string | null | undefined, string | null | undefined>;
  }>;
}

export interface IHistogramData {
  avg_time_step: number;
  median_time_step: number;
  bin_start: number[];
  bin_end: number[];
  bin_start_readable: string[];
  bin_end_readable: string[];
  bin_value: number[];
  contacts_value: number[];
  total_steps: number;
  names: string[];
}

const ConversionTimeDistribution = ({
  id,
  selectedType,
  dateRange
}: IConversionTimeDistribution) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [loading, setLoading] = useState(false);
  const [histogramData, setHistogramData] = useState<IHistogramData>();
  const [chartOption, setChartOption] = useState<any>({
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow"
      },
      formatter: function (params: any) {
        const time = params[0].axisValue;
        return `
          <div>
            <div style='display: flex; align-items: center; justify-content: space-between; gap: 8px'>
              <div>
                ${params[0].marker}
                ${convertToShort(Math.round(params[0].value))}
                ${resolvedType} took ${time}
              </div>
            </div>
          </div>
        `;
      }
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "8%",
      containLabel: true
    },
    yAxis: {
      type: "value",
      splitLine: {
        lineStyle: {
          color: colorScheme === "dark" ? theme.colors.dark[8] : "#f8f8f8",
          width: 2
        }
      },
      name: "No. of Users",
      nameLocation: "center",
      nameGap: 40
    },
    xAxis: {
      type: "category",
      data: [],
      axisTick: {
        show: false,
        splitNumber: 4
      },
      name: "Time Taken to Convert",
      nameLocation: "center",
      nameGap: 40
    },
    series: [
      {
        name: "Users",
        color: "#7f17d1",
        type: "bar",
        data: [],
        label: {
          show: true,
          position: "top",
          textShadowColor: "transparent",
          fontWeight: "600",
          color: "#c74554",
          fontSize: 10,
          fontFamily: "Inter, sans-serif",
          offset: [0, 10],
          rich: {
            a: {
              fontWeight: "600",
              padding: [3, 6],
              borderRadius: 8,
              borderWidth: 1,
              fontSize: 10,
              fontFamily: "Inter, sans-serif",
              color: "#7f17d1",
              backgroundColor: "#f3e8ff"
            },

            b: {
              color: "#030303",
              fontWeight: "600",
              textShadowColor: "transparent",
              fontSize: 10,
              align: "center",
              fontFamily: "Inter, sans-serif"
            },
            c: {
              color: "#a173f3",
              fontSize: 10,
              fontFamily: "Inter, sans-serif",
              fontWeight: "600"
            }
          },

          formatter: function (params: any) {
            const name = resolvedType.charAt(0).toUpperCase() + resolvedType.slice(1);
            return `{a|${convertToShort(Math.round(params.value))} ${name}}`;
          }
        },
        markLine: {
          symbol: "none",
          label: {
            show: true,
            rich: {
              a: {
                color: colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[9]
              },
              b: {
                color: colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[9],
                fontWeight: "600"
              }
            }
          },
          data: []
        }
      }
    ]
  });
  const [bins, setBins] = useState(0);
  const [selectedSteps, setSelectedSteps] = useState<any>(["0", "0"]);
  const intl = useIntl();

  const convertToShort = (value: number) => {
    return value.toLocaleString("en-US", {
      notation: "compact",
      compactDisplay: "short"
    });
  };

  /**
   * Resolve type for stats card
   */
  const resolvedType = useMemo(() => {
    if (!selectedType || selectedType === "visitor") {
      return intl.formatMessage({
        id: "insights.type.visitors",
        defaultMessage: "visitors"
      });
    } else if (selectedType === "user") {
      return intl.formatMessage({
        id: "insights.type.users",
        defaultMessage: "users"
      });
    }
    return intl.formatMessage({
      id: "insights.type.companies",
      defaultMessage: "companies"
    });
  }, [selectedType]);

  const stepsStartOptions = useMemo(() => {
    if (!histogramData) return [];
    const options = [];
    for (let i = 0; i < histogramData.total_steps; i++) {
      options.push({
        value: String(i + 1),
        label: `Step ${i + 1}`,
        disabled: i + 1 >= parseInt(selectedSteps[1])
      });
    }
    return options;
  }, [histogramData]);

  const stepsEndOptions = useMemo(() => {
    if (!histogramData) return [];
    const options = [];
    for (let i = 0; i < histogramData.total_steps; i++) {
      options.push({
        value: String(i + 1),
        label: `Step ${i + 1}`,
        disabled: i + 1 <= parseInt(selectedSteps[0])
      });
    }
    return options;
  }, [histogramData]);

  const webAnalyticsService = new WebAnalyticsService();
  // Workspace context
  const { activeWorkspace } = useContext(AppLifecycleContext);

  useEffect(() => {
    getHistogramData();
  }, [id, activeWorkspace.id, dateRange]);

  const findClosest = (list: number[], goal: number) => {
    return list.reduce((prev, curr) =>
      Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
    );
  };

  const findClosestItemIndex = (list: number[], goal: number) => {
    let closest = Math.abs(list[0] - goal);
    let index = 0;

    for (let i = 1; i < list.length; i++) {
      let diff = Math.abs(list[i] - goal);

      if (diff < closest) {
        closest = diff;
        index = i;
      }
    }

    return index;
  };

  const getHistogramData = async (bins = 0, start_level = 0, end_level = 0) => {
    setLoading(true);
    const res = await webAnalyticsService.getConversionTimeDistribution(activeWorkspace.id, id, {
      bins: bins,
      start_level: start_level,
      end_level: end_level,
      start_date: dateRange.start_date,
      end_date: dateRange.end_date,
      funnel_type: selectedType
    });
    if (!res.data) {
      setHistogramData(undefined);
    } else {
      const data = res.data;
      const totalSteps = res.data.total_steps;

      setHistogramData({
        ...data
      });

      if (!histogramData) {
        // initial start value is 1 and end value is total steps
        const start = "1";
        const end = String(totalSteps);
        setSelectedSteps([start, end]);
      }
      console.log("data", res.data);
      const bin_value = res.data.contacts_value.map((value: string) => parseInt(value));
      const bin_start =
        bins === 0
          ? res.data.bin_range
          : res.data.bin_start.map((value: number) => convertSecondsToReadableFormat(value, true));

      const closestAvgIndex = findClosestItemIndex(bin_value, res.data.avg_time_step);
      const closestMediumIndex = findClosestItemIndex(bin_value, res.data.median_time_step);

      let markLineData = [];
      if (closestAvgIndex === closestMediumIndex) {
        markLineData.push({
          name: "Average & Median",
          type: "average",
          xAxis: bin_start[closestAvgIndex]
        });
      } else {
        markLineData.push({
          name: "Average",
          type: "average",
          xAxis: bin_start[closestAvgIndex]
        });
        markLineData.push({
          name: "Median",
          type: "median",
          xAxis: bin_start[closestMediumIndex]
        });
      }

      console.log("markLineData", markLineData, closestMediumIndex);

      setChartOption({
        ...chartOption,
        xAxis: {
          ...chartOption.xAxis,
          data: bin_start
          // axisLabel: {
          //   formatter: function (value: any) {
          //     if (bins === 0) {
          //       return value;
          //     }
          //     return convertSecondsToReadableFormat(value, true);
          //   }
          // }
        },
        series: [
          {
            ...chartOption.series[0],
            data: bin_value,
            markLine: {
              ...chartOption.series[0].markLine,
              data: markLineData,
              label: {
                ...chartOption.series[0].markLine.label,
                formatter: function (params: any) {
                  if (params.name === "Average") {
                    return `{a|${params.name}:} {b|${convertSecondsToReadableFormat(
                      res.data?.avg_time_step || 0
                    )}}`;
                  }

                  if (params.name === "Median") {
                    return `{a|${params.name}:} {b|${convertSecondsToReadableFormat(
                      res.data?.median_time_step || 0
                    )}}`;
                  }

                  return `{a|${params.name}:} {b|${convertSecondsToReadableFormat(
                    res.data?.avg_time_step || 0
                  )}}`;
                }
              }
            },
            label: {
              ...chartOption.series[0].label,

              formatter: function (params: any) {
                const name = resolvedType.charAt(0).toUpperCase() + resolvedType.slice(1);
                return `{a|${convertToShort(Math.round(params.value))} ${name}}`;
              }
            }
          }
        ]
      });
    }
    setLoading(false);
  };

  return (
    <Box>
      <Box
        style={{
          height: "100%",
          minHeight: "400px"
        }}>
        {loading ? (
          <Flex align={"center"} justify={"center"} mih={500}>
            <Loader size={"xs"} />
          </Flex>
        ) : (
          <>
            <Paper p={"md"}>
              <Group justify={"space-between"}>
                <Flex align={"center"} gap={"xs"}>
                  <Text>Average time to convert between </Text>
                  <Select
                    placeholder="Pick one"
                    defaultValue={"1"}
                    value={selectedSteps[0]}
                    onChange={(value) => {
                      setSelectedSteps([value, selectedSteps[1]]);
                      getHistogramData(bins, parseInt(value as string), parseInt(selectedSteps[1]));
                    }}
                    data={stepsStartOptions as any}
                    size={"xs"}
                    w={80}
                  />
                  <Text>to</Text>
                  <Select
                    placeholder="Pick one"
                    defaultValue={"2"}
                    value={selectedSteps[1]}
                    onChange={(value) => {
                      setSelectedSteps([selectedSteps[0], value]);
                      getHistogramData(bins, parseInt(selectedSteps[0]), parseInt(value as string));
                    }}
                    data={stepsEndOptions as any}
                    size={"xs"}
                    w={80}
                  />
                  <Text>
                    is{" "}
                    <Text span fw={700}>
                      {convertSecondsToReadableFormat(histogramData?.avg_time_step || 0)}
                    </Text>
                    .
                  </Text>
                </Flex>

                <div>
                  <Select
                    placeholder="Pick one"
                    defaultValue={"auto"}
                    searchable
                    value={String(bins)}
                    onChange={(value) => {
                      setBins(parseInt(value as string));
                      getHistogramData(
                        parseInt(value as string),
                        parseInt(selectedSteps[0]),
                        parseInt(selectedSteps[1])
                      );
                    }}
                    data={[
                      { value: "0", label: "Auto Bins" },
                      { value: "6", label: "6 Bins" },
                      { value: "7", label: "7 Bins" },
                      { value: "8", label: "8 Bins" },
                      { value: "9", label: "9 Bins" },
                      { value: "10", label: "10 Bins" },
                      { value: "11", label: "11 Bins" },
                      { value: "12", label: "12 Bins" },
                      { value: "13", label: "13 Bins" },
                      { value: "14", label: "14 Bins" },
                      { value: "15", label: "15 Bins" },
                      { value: "16", label: "16 Bins" },
                      { value: "17", label: "17 Bins" },
                      { value: "18", label: "18 Bins" },
                      { value: "19", label: "19 Bins" },
                      { value: "20", label: "20 Bins" },
                      { value: "21", label: "21 Bins" },
                      { value: "22", label: "22 Bins" },
                      { value: "23", label: "23 Bins" },
                      { value: "24", label: "24 Bins" },
                      { value: "25", label: "25 Bins" }
                    ]}
                    variant={"default"}
                    size={"xs"}
                    w={180}
                  />
                </div>
              </Group>
            </Paper>
            <ReactEChartsCore
              echarts={echarts}
              option={chartOption}
              style={{
                height: "400px",
                width: "100%"
              }}
              notMerge={true}
              lazyUpdate={true}
            />
          </>
        )}
      </Box>

      <Flex align={"center"} justify={"center"}>
        <Paper
          mt={"xl"}
          style={(theme) => ({
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: theme.spacing.xl,
            paddingRight: theme.spacing.xl,
            paddingTop: "24px",
            paddingBottom: "24px"
          })}>
          <Stack>
            <Title order={4} ta="center">
              <Flex align={"center"} justify={"center"}>
                <Box mr={"sm"} c="yellow.6">
                  <FontAwesomeIcon icon={solid("lightbulb-on")} />
                </Box>
                Insights
              </Flex>
            </Title>

            <SimpleGrid cols={2}>
              <Paper
                withBorder
                radius="md"
                p="sm"
                style={{
                  userSelect: "none",
                  textAlign: "center"
                }}>
                <Text>
                  Most of the {resolvedType} complete{" "}
                  <Text span fw={600}>
                    Step {selectedSteps[0]} (
                    {histogramData?.names[Number(selectedSteps[0]) - 1] ?? ""})
                  </Text>{" "}
                  and{" "}
                  <Text span fw={600}>
                    Step {selectedSteps[1]} (
                    {histogramData?.names[Number(selectedSteps[1]) - 1] ?? ""})
                  </Text>{" "}
                  in <b>{convertSecondsToReadableFormat(histogramData?.median_time_step || 0)}</b>.
                </Text>
              </Paper>

              <Paper
                withBorder
                radius="md"
                p="sm"
                style={{
                  userSelect: "none",
                  textAlign: "center"
                }}>
                <Text>
                  The average time of {resolvedType} to complete{" "}
                  <Text span fw={600}>
                    Step {selectedSteps[0]} (
                    {histogramData?.names[Number(selectedSteps[0]) - 1] ?? ""})
                  </Text>{" "}
                  and{" "}
                  <Text span fw={600}>
                    Step {selectedSteps[1]} (
                    {histogramData?.names[Number(selectedSteps[1]) - 1] ?? ""})
                  </Text>{" "}
                  is{" "}
                  <Text span fw={600}>
                    {convertSecondsToReadableFormat(histogramData?.avg_time_step || 0)}
                  </Text>
                  .
                </Text>
              </Paper>
            </SimpleGrid>
          </Stack>
        </Paper>
      </Flex>
    </Box>
  );
};

export default ConversionTimeDistribution;
