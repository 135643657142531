import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks
} from 'date-fns';
import { DATE_FORMAT } from './Constants';

export const CalendarWeeksRange = (
  weeks: number = 6
): Array<{
  label: string;
  value: string;
}> => {
  let datesRange: Array<{
    label: string;
    value: string;
  }> = [];
  for (let i = 1; i <= weeks + 1; i++) {
    const getDate = subWeeks(new Date(), i);
    const startWeek = addDays(startOfWeek(getDate), 1);
    const endWeek = addDays(endOfWeek(getDate), 1);
    datesRange.push({
      label: `Week of ${format(startWeek, 'MMM d')}`,
      value: `${format(startWeek, DATE_FORMAT)}_${format(endWeek, DATE_FORMAT)}`
    });
  }
  return datesRange;
};

export const CalendarMonthsRange = (
  months: number = 6
): Array<{
  label: string;
  value: string;
}> => {
  let datesRange: Array<{
    label: string;
    value: string;
  }> = [];
  for (let i = 1; i <= months + 1; i++) {
    const getDate = subMonths(new Date(), i);
    const startMonth = startOfMonth(getDate);
    const endMonth = endOfMonth(getDate);
    datesRange.push({
      label: `${format(startMonth, 'LLLL')}`,
      value: `${format(startMonth, DATE_FORMAT)}_${format(endMonth, DATE_FORMAT)}`
    });
  }
  return datesRange;
};
