import { gray } from "@/config/theme";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { getIntlDateAndTime } from "@/lib/utils/DateUtility";
import { ReceiptProps } from "@/ui/pages/Settings/AccountSettings/AccountsBilling";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor, Badge, Box, Center, Flex, Loader, Table, Text } from "@mantine/core";
import { Oval } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { LoadingStateProps, WorkspaceSlugParams } from "types/types.d";

export const BillingInvoices = ({
  receipts,
  loading
}: {
  receipts: Array<ReceiptProps>;
  loading: LoadingStateProps;
}) => {
  const { workspaceIdentifier } = useParams<WorkspaceSlugParams>();

  return (
    <>
      {loading === "loaded" ? (
        <>
          {receipts.length > 0 ? (
            <>
              <Table id="invoices" verticalSpacing={"sm"}>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>ID</Table.Th>
                    <Table.Th>Date</Table.Th>
                    <Table.Th>Amount</Table.Th>
                    <Table.Th>Status</Table.Th>
                    <Table.Th>View Invoice</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {receipts.map((receipt: ReceiptProps) => (
                    <>
                      <Table.Tr key={`receipt:${receipt.order_id}`}>
                        <Table.Td className="col-span-2">
                          <Flex align={"center"}>
                            <Text tt="capitalize" fw={500}>
                              {receipt.plan_name?.replaceAll("-", " ")}
                            </Text>
                            <Text ml={8}>{receipt.order_id}</Text>
                          </Flex>
                        </Table.Td>
                        <Table.Td>{getIntlDateAndTime(receipt.paid_at)}</Table.Td>
                        <Table.Td fw={500}>
                          {receipt.currency} {receipt.amount}
                        </Table.Td>
                        <Table.Td>
                          {receipt?.status === "failed" ? (
                            <Badge variant="outline" radius={"xs"} color="red">
                              Failed
                            </Badge>
                          ) : (
                            <>
                              {receipt?.status?.includes("refund") ? (
                                <>
                                  <Badge tt="capitalize" variant="outline" radius={"xs"}>
                                    {receipt.status.replaceAll("_", " ")}
                                  </Badge>
                                </>
                              ) : (
                                <Badge variant="outline" radius={"xs"} color="green">
                                  Paid
                                </Badge>
                              )}
                            </>
                          )}
                        </Table.Td>
                        <Table.Td>
                          {receipt.status === "failed" ? (
                            <h4></h4>
                          ) : (
                            <Anchor
                              onClick={() => {
                                window.open(receipt.receipt_url, "_blank");
                              }}>
                              View
                            </Anchor>
                          )}
                        </Table.Td>
                      </Table.Tr>
                    </>
                  ))}
                </Table.Tbody>
              </Table>
            </>
          ) : (
            <Box py={16}>
              <Flex align={"center"} justify={"center"} direction={"column"} pt={16}>
                <FontAwesomeIcon icon={regular("file-invoice")} size="2xl" color={gray[700]} />
                <Text mt={8}>You do not have any invoices yet.</Text>
              </Flex>
            </Box>
          )}
        </>
      ) : (
        <>
          <Center h={36}>
            <Loader size="xs" />
          </Center>
        </>
      )}
    </>
  );
};
