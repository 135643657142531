import { MantineColorScheme } from "@mantine/core";
import { createStyles } from "@mantine/emotion";

const useStyles = createStyles((theme) => {
  return ({
    root: {
      borderRight: `1px solid`,
      borderLeft: `1px solid`,
      borderColor: 'light-dark(#E8E8E8, var(--mantine-color-dark-5))',
      // background: colorScheme === "dark" ? theme.colors.dark[6] : "#f1f1f1",
      background: 'light-dark(#F1F1F1, var(--mantine-color-dark-6))',
      width: "244px",
      flexShrink: 0,
      height: "100vh",
      // color: colorScheme === "dark" ? theme.colors.dark[0] : "#222",
      color: 'light-dark(#222, var(--mantine-color-dark-0))',
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      transition: "width 0.2s ease-in-out, opacity 0.1s ease-in-out",
      position: "sticky",
      top: 0
    },

    header: {
      padding: "28px 19px",
      height: "57px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      whiteSpace: "nowrap",

      color: "inherit",
      fontFamily: "Inter",
      fontSize: "1.23rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "21px"
    },

    section: {
      padding: "19px 19px",
      // borderTop: `1px solid ${colorScheme === "dark" ? theme.colors.dark[5] : "#E8E8E8"}`
      borderTop: '1px solid light-dark(#E8E8E8, var(--mantine-color-dark-5))'
    },

    sectionHeader: {
      // color: colorScheme === "dark" ? theme.colors.dark[0] : "#8A8A8A",
      color: 'light-dark(#8A8A8A, var(--mantine-color-dark-0))',
      leadingTrim: "both",
      textEdge: "cap",
      fontFamily: "Inter",
      fontSize: "0.923rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "21px",
      letterSpacing: "0.48px",
      textTransform: "uppercase"
    },

    sectionItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      cursor: "pointer",
      position: "relative",
      textDecoration: "none",
      gap: "16px",
      overflow: "hidden",

      [`.fade-effect`]: {
        borderRightStyle: "solid",
        borderRightColor: "rgb(227, 227, 227)",
        borderBottomStyle: "solid",
        borderBottomColor: "rgb(227, 227, 227)",
        borderLeftStyle: "solid",
        borderLeftColor: "rgb(227, 227, 227)",
        borderImageOutset: "0",
        borderImageRepeat: "stretch",
        borderImageSlice: "100%",
        borderImageSource: "none",
        borderImageWidth: "1",
        boxSizing: "border-box",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        top: "0px",
        width: "5rem",
        // backgroundImage: `linear-gradient(to left, ${
        //   colorScheme === "dark" ? theme.colors.dark[6] : "#f1f1f1"
        // } 0%, transparent)`,
        backgroundImage: 'linear-gradient(to left, light-dark(#F1F1F1, var(--mantine-color-dark-6)) 0%, transparent)',
        transition: "all 0.2s ease-in-out"
      },

      "&:hover .fade-effect": {
        // backgroundImage: `linear-gradient(to left, ${
        //   colorScheme === "dark" ? theme.colors.dark[6] : "#f1f1f1"
        // } 60%, transparent)`,
        backgroundImage: 'linear-gradient(to left, light-dark(#F1F1F1, var(--mantine-color-dark-6)) 60%, transparent)',
        width: "6rem"
      },

      "&:hover": {
        ".side-pane-item-text": {
          // color: colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[8]
          color: 'light-dark(var(--mantine-color-dark-1), var(--mantine-color-gray-8))'
          // fontWeight: 500
        },

        ".side-pane-item-pill": {
          span: {
            // color: colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[8],
            color: 'light-dark(var(--mantine-color-dark-1), var(--mantine-color-gray-8))',
            fontWeight: 500
          }
        }
      },

      "&.active": {
        ".side-pane-item-text": {
          color: "#7D47EB",
          fontWeight: 600
        },

        ".side-pane-item-pill": {
          span: {
            color: "#7D47EB",
            fontWeight: 600
          }
        }
      },

      "&.disabled": {
        pointerEvents: "none",
        ".side-pane-item-text": {
          color: "#838383"
        },

        ".side-pane-item-pill": {
          span: {
            color: "#838383"
          }
        }
      },

      ".side-pane-item-text": {
        // color: colorScheme === "dark" ? theme.colors.dark[0] : "#222222",
        color: 'light-dark(#222222, var(--mantine-color-dark-0))',
        fontFamily: "Inter",
        fontSize: "1.06rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "21px"
      },

      ".side-pane-item-pill": {
        display: "flex",
        height: "17px",
        padding: "8px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        // background: colorScheme === "dark" ? theme.colors.dark[5] : "#FFFFFF",
        background: 'light-dark(#FFFFFF, var(--mantine-color-dark-5))',
        borderRadius: "4px",

        span: {
          color: "#838383",
          textAlign: "right",
          fontFamily: "Inter",
          fontSize: "0.923rem",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "21px"
        }
      },

      ".side-pane-item-actions": {
        display: "flex",
        gap: "6px",
        alignItems: "center",
        position: "absolute",
        right: "0px",
        top: "50%",
        transform: "translateY(-50%)",
        // background: colorScheme === "dark" ? theme.colors.dark[6] : "#F1F1F1",
        background: 'light-dark(#F1F1F1, var(--mantine-color-dark-6))',
        paddingLeft: "10px",
        opacity: 0,
        transition: "opacity 0.2s ease-in-out"
      },

      "&:hover .side-pane-item-actions": {
        opacity: 1
      }
    }
  })
});

export default useStyles;
