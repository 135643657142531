import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Button,
  Group,
  Select,
  Tooltip,
  useMantineTheme,
  Text,
  Flex,
  useMantineColorScheme
} from "@mantine/core";
import useInsightHook from "@/hooks/useInsightHook";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { PLAN_TYPES, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { ISegmentCondition, SegmentCondition } from "./SegmentCondition";
import SegmentGroup, { ISegmentGroup } from "./SegmentGroup";
import { SegmentFormProvider, useSegmentForm } from "./SegmentFormContext";
import { useSegmentFilterStore } from "@/stores/useSegmentFilterStore";
import { useNavigate, useParams } from "react-router-dom";

export interface ISegmentsQueryBuilderForm {
  combinator: "and" | "or";
  rules: ISegmentCondition[];
}

interface ISegmentsQueryBuilder {
  handleSubmit: (values: any) => void;
  handleSubmitButtonText?: string;
  handleSaveButtonText?: string;
  handleSegmentCreate?: () => void;
  allowSegmentCreate?: boolean;
  fullWidth?: boolean;
  withClearButton?: boolean;
  forcedSegmentType?: "visitors" | "users" | "companies"; // this is used to force the segment type in the query builder
}

export const SegmentsQueryBuilder = ({
  handleSubmit,
  handleSubmitButtonText = "",
  handleSaveButtonText = "Save as a Segment",
  handleSegmentCreate = () => {},
  allowSegmentCreate = false,
  fullWidth = false,
  withClearButton = false,
  forcedSegmentType
}: ISegmentsQueryBuilder) => {
  const { colorScheme } = useMantineColorScheme();
  const { segment } = useParams<{
    segment: string;
  }>();
  const navigate = useNavigate();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const { hasRolesNotWith, isStarterPlan } = useWorkspaceUtilityHook();
  const [filters, setFilters, id, type, reset] = useSegmentStore((state) => [
    state.filters,
    state.setFilters,
    state.id,
    state.type,
    state.reset
  ]);
  const [filterVisible, setFilterVisible] = useSegmentFilterStore((state) => [
    state.filterVisible,
    state.setFilterVisible
  ]);

  /**
   * Get attributes against the workspace.
   */
  const { insightAttributes, insightEvents, insightPinnedEvents, companyAttributes } =
    useInsightHook();

  /**
   * Insight attributes depending on the Segment type
   */

  const insightAttributesByType = () => {
    const visitorAttributes = [
      { label: "First seen", value: "first_seen" },
      { label: "Last seen", value: "last_seen" },
      { label: "First viewed page URL", value: "first_viewed_page_url" },
      { label: "Last viewed page URL", value: "last_viewed_page_url" },
      { label: "Original UTM source", value: "utm_source" },
      { label: "Original UTM medium", value: "utm_medium" },
      { label: "Original UTM campaign", value: "utm_campaign" },
      { label: "Original UTM term", value: "utm_term" },
      { label: "Original UTM content", value: "utm_content" },
      { label: "Original referrer", value: "referer" }
    ];

    if (type === "visitor") {
      return [{ group: "Visitor", items: visitorAttributes }];
    }

    if (type === "user") {
      return [
        { group: "Visitor", items: visitorAttributes },
        { group: "User", items: insightAttributes },
        { group: "Company", items: companyAttributes }
      ];
    }

    if (type === "company") {
      return [
        { group: "Default", items: visitorAttributes },
        { group: "Company", items: companyAttributes }
      ];
    }
  };

  /**
   * Segment related properties
   */

  const form = useSegmentForm({
    initialValues: filters,
    validate: {
      /**
       * NOTE: Mantine does not infer correct type of nested form values in v5
       * so we have used ts-ignore
       * Remove all ts-ignore in validate:{} when upgrading to v7
       */
      rules: {
        type: (value) => (value.length < 2 ? true : null),
        field: (value, values, path) => {
          const index = Number(path.split(".")[1]);
          if (values.rules[index].type === "page") return false;
          return value.length < 1 ? true : null;
        },
        operator: (value) => (value.length < 2 ? true : null),
        value: (value, values, path) => {
          const index = Number(path.split(".")[1]);
          if (values.rules[index].type === "event" || values.rules[index].type === "pinned_event")
            return false;

          if (
            values.rules[index].operator === "exists" ||
            values.rules[index].operator === "!exists"
          ) {
            return false;
          }
          return value.length < 1 ? true : null;
        },
        filters: {
          // combinator: value => (value.length < 1 ? true : null),
          rules: {
            field: (value) => (value.length < 1 ? true : null),
            operator: (value) => (value.length < 1 ? true : null),
            value: (value, values, path) => {
              const index = Number(path.split(".")[1]);
              const filterRuleIndex = Number(path.split(".")[4]);
              if (
                // @ts-ignore
                values.rules[index].filters.rules[filterRuleIndex].operator === "exists" ||
                // @ts-ignore
                values.rules[index].filters.rules[filterRuleIndex].operator === "!exists"
              ) {
                return false;
              }
              return value.length < 1 ? true : null;
            }
          }
        }
      },
      // this validation is for rules inside groups, will not work for nested groups
      groups: {
        rules: {
          type: (value) => (value.length < 2 ? true : null),
          field: (value, values, path) => {
            const groupIndex = Number(path.split(".")[1]);
            const index = Number(path.split(".")[3]);
            // @ts-ignore
            if (values.groups[groupIndex].rules[index].type === "page") return false;
            return value.length < 1 ? true : null;
          },
          operator: (value) => (value.length < 2 ? true : null),
          value: (value, values, path) => {
            const pathParts = path.split(".");
            const groupIndex = pathParts[1];
            const index = pathParts[3];
            if (
              // @ts-ignore
              values.groups[groupIndex].rules[index].type === "event" ||
              // @ts-ignore
              values.groups[groupIndex].rules[index].type === "pinned_event"
            )
              return false;

            if (
              // @ts-ignore
              values.groups[groupIndex].rules[index].operator === "exists" ||
              // @ts-ignore
              values.groups[groupIndex].rules[index].operator === "!exists"
            ) {
              return false;
            }
            return value.length < 1 ? true : null;
          },
          filters: {
            rules: {
              field: (value) => (value.length < 1 ? true : null),
              operator: (value) => (value.length < 1 ? true : null),
              value: (value, values, path) => {
                const pathParts = path.split(".");
                const groupIndex = pathParts[1];
                const index = pathParts[3];
                const filterRuleIndex = pathParts[6];
                if (
                  // @ts-ignore
                  values.groups[groupIndex].rules[index].filters.rules[filterRuleIndex].operator ===
                    "exists" ||
                  // @ts-ignore
                  values.groups[groupIndex].rules[index].filters.rules[filterRuleIndex].operator ===
                    "!exists"
                ) {
                  return false;
                }
                return value.length < 1 ? true : null;
              }
            }
          }
        }
      }
    }
  });

  const renderConditions = form.values.rules.map((rule: ISegmentCondition, index) => (
    <Group className="mb-4 transition-all ease-in-out" key={`segment-condition-${index}`}>
      {index > 0 && (
        <div className="w-16 flex flex-row justify-end">
          <Badge
            radius={form.values.combinator === "and" ? "sm" : "xl"}
            color={colorScheme === "dark" ? "dark.3" : "gray.5"}
            variant="outline">
            {form.values.combinator}
          </Badge>
        </div>
      )}

      <SegmentCondition
        fullWidth={fullWidth}
        index={index}
        attributes={insightAttributesByType()}
        events={insightEvents}
        pinnedEvents={insightPinnedEvents}
        rule={rule}
      />
    </Group>
  ));

  const renderGroups = form.values.groups?.map((group: ISegmentGroup, index) => (
    <Group className="mb-4 transition-all ease-in-out" key={`segment-group-${index}`}>
      {(index > 0 || form.values.rules.length > 0) && (
        <div className="w-16 flex flex-row justify-end">
          <Badge
            radius={form.values.combinator === "and" ? "sm" : "xl"}
            color={colorScheme === "dark" ? "dark.3" : "gray.5"}
            variant="outline">
            {form.values.combinator}
          </Badge>
        </div>
      )}
      <SegmentGroup
        groupIndex={index}
        attributes={insightAttributesByType()}
        group={group}
        fullWidth={fullWidth}
      />
    </Group>
  ));

  const handleFormSubmit = (values: typeof form.values) => {
    console.log(values);
    setFilters(values);
    if (values.rules.length === 0 && values.groups?.length === 0) {
      toast.error("Please add at least one condition or group.");
      return;
    }
    if (values.groups?.some((group) => group.rules.length === 0)) {
      toast.error("Please add at least one condition in each group.");
      return;
    }
    handleSubmit(values);
  };

  const handleFormSubmitAsSegment = (values: typeof form.values) => {
    if (values.rules.length === 0 && values.groups?.length === 0) {
      toast.error("Please add at least one condition or group.");
      return;
    }
    if (values.groups?.some((group) => group.rules.length === 0)) {
      toast.error("Please add at least one condition in each group.");
      return;
    }
    handleSegmentCreate();
    setFilters(values);
  };

  const handleError = () => {
    form.validate();
    console.log(form.errors);
    if (form.errors) {
      toast.error("Please fill all the fields correctly.");
      return;
    }
  };
  return (
    <SegmentFormProvider form={form}>
      <Flex direction={"column"}>
        <form onSubmit={form.onSubmit(handleFormSubmit, handleError)}>
          <Group justify={"space-between"} align={"center"} mb="sm">
            <Flex align="center">
              <Select
                size="xs"
                className="w-36 mr-4"
                {...form.getInputProps(`combinator`)}
                data={[
                  {
                    label: "All",
                    value: "and"
                  },
                  {
                    label: "At least one",
                    value: "or"
                  }
                ]}
              />
              <Text color={colorScheme === "dark" ? "dark.2" : undefined}>
                of the following conditions match
              </Text>
            </Flex>

            {withClearButton && (
              <Button
                size="xs"
                variant="light"
                leftSection={<FontAwesomeIcon icon={regular("trash")} />}
                disabled={!filterVisible}
                onClick={() => {
                  form.reset();
                  reset();
                  setFilterVisible(false);

                  if (segment !== "everyone" && forcedSegmentType) {
                    // push to everyone segment
                    navigate(`/env/${activeWorkspace.identifier}/${forcedSegmentType}/everyone`);
                  }
                }}>
                Clear Filter
              </Button>
            )}
          </Group>
          <div className="transition-all ease-in-out">
            {renderConditions}
            {renderGroups}
            <Flex
              mt="sm"
              justify={"space-between"}
              style={{
                width: fullWidth ? "100%" : "80%"
              }}>
              <Group>
                <Button
                  size="xs"
                  variant="subtle"
                  onClick={() => {
                    form.validate();
                    form.insertListItem("rules", {
                      type: "attribute",
                      field: "",
                      operator: "",
                      value: "",
                      times: 1,
                      within: 1,
                      frequency: "",
                      filters: {
                        combinator: "and",
                        rules: []
                      }
                    });
                  }}
                  leftSection={<FontAwesomeIcon icon={regular("plus")} />}>
                  Add Condition
                </Button>
                <Tooltip position="right" withArrow label="A group is a combination of conditions.">
                  <Button
                    size="xs"
                    variant="subtle"
                    onClick={() => {
                      form.validate();
                      // if form does not have a 'groups' property, set it to empty array
                      if (!form.getInputProps("groups").value) {
                        form.setFieldValue("groups", []);
                      }
                      form.insertListItem("groups", {
                        combinator: "and",
                        rules: [
                          {
                            type: "attribute",
                            field: "",
                            operator: "",
                            value: "",
                            times: 1,
                            within: 1,
                            frequency: "",
                            filters: {
                              combinator: "and",
                              rules: []
                            }
                          }
                        ]
                      });
                    }}
                    leftSection={<FontAwesomeIcon icon={regular("plus")} />}>
                    Add Group
                  </Button>
                </Tooltip>
              </Group>
              {(form.values.rules.length >= 1 ||
                (form.values.groups?.length >= 1 &&
                  form.values.groups?.some((group) => group.rules.length >= 1))) && (
                <>
                  <Flex
                    align={"center"}
                    style={{
                      marginRight: fullWidth ? "0px !important" : ""
                    }}
                    className={`${
                      form.values.rules.length + form.values.groups.length === 1 ? "mr-20" : ""
                    } transition-all ease-in-out `}>
                    {allowSegmentCreate && hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                      <Tooltip
                        w={180}
                        multiline={true}
                        disabled={!isStarterPlan}
                        label="Upgrade your plan to premium to use Segments.">
                        <div>
                          <Button
                            rightSection={
                              isStarterPlan ? <FontAwesomeIcon icon={regular("lock")} /> : undefined
                            }
                            disabled={isStarterPlan}
                            variant="subtle"
                            size="xs"
                            className="mr-4"
                            onClick={() => {
                              form.onSubmit(handleFormSubmitAsSegment, handleError)();
                            }}>
                            {handleSaveButtonText ? (
                              <>{handleSaveButtonText}</>
                            ) : (
                              <>Save as Segment</>
                            )}
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                    <Button size="xs" type="submit">
                      {handleSubmitButtonText ? (
                        <>{handleSubmitButtonText}</>
                      ) : (
                        <>{id ? "Update" : "Create"}</>
                      )}
                    </Button>
                  </Flex>
                </>
              )}
            </Flex>
          </div>
        </form>
      </Flex>
    </SegmentFormProvider>
  );
};
