import axios from './JWTService';

export class AutoCapturedEventsService {
  all = (
    workspaceId: string,
    page: number = 1,
    search: string = '',
    sort: string = ''
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/auto_captured_events/${workspaceId}?page=${page}&sort=${sort}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  grouped = (
    workspaceId: string,
    page: number = 1,
    search: string = '',
    sort: string = ''
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/auto_captured_events/${workspaceId}/grouped?page=${page}&sort=${sort}&q=${search}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  id = (workspaceId: string, eventId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/auto_captured_events/${workspaceId}/event/${eventId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  search = (
    workspaceId: string,
    searchType: string,
    search: string,
    page: number = 1
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `v1/auto_captured_events/${workspaceId}/search/${searchType}?search=${search}&page=${page}`
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  create = (workspaceId: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/auto_captured_events/${workspaceId}/pinned`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  update = (workspaceId: string, id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/auto_captured_events/${workspaceId}/pinned/${id}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
