/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { SidePanel } from "../SidePanel/SidePanel";
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Switch,
  Table,
  Text,
  Title,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Note } from "@/stores/useAnnotationNotesStore";
import { _getRowValue } from "@/lib/utils/Virtualtable";
import { fromZonedTime } from "date-fns-tz";
import { format, isToday, parseISO } from "date-fns";
import NotesModel from "./NotesModel";
import { openConfirmModal } from "@mantine/modals";
import { IAnnotationNoteVisibilityType } from "@/types/types.d";
import NoteVisibilityToggle from "./NoteVisibilityToggle";
import { NoResultsDetailedView } from "../../App/WebAnalytics/DetailedView/NoResultsDetailedView";
import { truncateText } from "@/lib/utils/StringUtility";
import { getFormattedPeriod } from "../../App/WebAnalytics/WebAnalyticsUtilities";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface NotesDetailViewProps {
  isModalOpen: boolean;
  setIsModalOpen: (e: boolean) => void;
  notes: Note[];
  notesLoading: string;
  applyNoteChanges: (type: string, values: any) => void;
  noteVisibilityType: IAnnotationNoteVisibilityType;
  setNoteVisibilityType: (reportType: IAnnotationNoteVisibilityType) => void;
  getAnnotationNotes: (reportType: IAnnotationNoteVisibilityType, page: number) => void;
}

const NotesDetailView = ({
  isModalOpen,
  setIsModalOpen,
  notes,
  notesLoading,
  applyNoteChanges,
  noteVisibilityType,
  setNoteVisibilityType,
  getAnnotationNotes
}: NotesDetailViewProps) => {
  const theme = useGlobalMantineTheme();
  const matches = useMediaQuery("(min-width: 900px)");
  const [notesModelOpened, setNotesModelOpened] = useState(false);
  const [noteToUpdateOrDelete, setNoteToUpdateOrDelete] = useState<Note>();
  const [hasMore, setHasMore] = useState(true);
  const page = useRef(0);

  const queryParams = new URLSearchParams(window.location.search);

  const formatDate = (inputDate: string | null) => {
    if (!inputDate) return "";

    const localTime = fromZonedTime(new Date(inputDate.replace(" ", "T")), "UTC");
    if (isToday(localTime)) {
      return format(localTime, "HH:mm:ss");
    } else {
      return format(localTime, "dd MMM yyyy");
    }
  };

  const deleteNotePrompt = (note: Note) => {
    openConfirmModal({
      title: <Title order={5}>Delete Note</Title>,
      children: (
        <Text size="sm">
          Are you sure you want to delete your Note with title: &nbsp;
          <p className="font-semibold">{note.title} ?</p>
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => applyNoteChanges("delete", note)
    });
  };

  useEffect(() => {
    setHasMore(notes.length > 50);
  }, [notes.length, noteVisibilityType]);

  return (
    <SidePanel opened={isModalOpen} onCancel={() => setIsModalOpen(false)} loading={true}>
      <div
        className="flex-col py-4 justify-between"
        style={{
          overflowX: "auto",
          whiteSpace: "nowrap"
        }}>
        <Flex justify={"space-between"} align={"center"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center"
            }}>
            <Text size="lg" fw={600}>
              Notes
            </Text>
            <Text ml={10} size={13} c="dimmed" fw={500}>
              {`(${getFormattedPeriod(
                queryParams.get("from_date"),
                queryParams.get("to_date"),
                "MMM dd, yyyy"
              )})`}
            </Text>
          </Box>

          <NoteVisibilityToggle
            noteVisibilityType={noteVisibilityType}
            setNoteVisibilityType={setNoteVisibilityType}
            showLabel={true}
          />
        </Flex>
        <Table
          mt={"3rem"}
          striped
          highlightOnHover
          sx={(theme) => ({
            "&[data-striped] tbody tr:nth-of-type(2n+1)": {
              backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : ""
            }
          })}>
          <thead>
            <tr>
              <th className={matches ? "capitalize min-w-[400px]" : ""}>Note</th>
              <>
                <th className="col-span-2 cursor-pointer">
                  <div>Visibility</div>
                </th>
                <th className="col-span-2 cursor-pointer">
                  <div>Shareable on Dashboard</div>
                </th>
                <th className="col-span-2 cursor-pointer">&nbsp;&nbsp;&nbsp;Actions</th>
              </>
            </tr>
          </thead>
          <tbody>
            {notesLoading === "loaded" && notes.length === 0 && (
              <>
                <NoResultsDetailedView colspan={6} />{" "}
              </>
            )}
            {notesLoading === "loaded" &&
              notes.length > 0 &&
              notes.map((note: Note, index: number) => (
                <Fragment key={note.id}>
                  <tr
                    className="group"
                    key={"notes-detail-view" + note.id + index}
                    data-cy="notes-detail-results-list">
                    <td>
                      <div className="flex items-center hover:underline cursor-pointer">
                        <div className="ml-1 pt-2.5 max-w-[750px] flex flex-col text-base gap-2">
                          <span className="font-semibold whitespace-normal ">{note.title}</span>
                          <span className="font-light whitespace-normal">
                            <Tooltip
                              label={
                                <Text fz="sm" c="dimmed">
                                  {note.description}
                                </Text>
                              }
                              position={"bottom-start"}
                              withinPortal
                              multiline
                              width={780}
                              disabled={note.description.length < 350}>
                              <Text>{truncateText(note.description, 350)}</Text>
                            </Tooltip>
                          </span>
                          <Text c="dimmed" fz="xs" my={3}>
                            {format(parseISO(note.date), "MMM dd, yyyy")}
                          </Text>
                        </div>
                      </div>
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      <Flex align={"center"} justify={"center"}>
                        <Text>{note.visibility}</Text>
                      </Flex>
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      <Flex align={"center"} justify={"center"}>
                        <Switch size="xs" checked={note.on_shareable_dashboard} />
                      </Flex>
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      <Flex align={"center"} justify={"center"}>
                        <ActionIcon
                          variant="outline"
                          ml={8}
                          mr={5}
                          size={"sm"}
                          color={"gray.5"}
                          onClick={() => {
                            setNoteToUpdateOrDelete(note);
                            setNotesModelOpened(true);
                          }}>
                          <FontAwesomeIcon
                            size="2xs"
                            icon={solid("edit")}
                            className="text-gray-600 hover:text-gray-900 cursor-pointer"
                          />
                        </ActionIcon>
                        <ActionIcon
                          variant="outline"
                          ml={8}
                          mr={5}
                          size={"sm"}
                          color={"red.5"}
                          onClick={() => {
                            deleteNotePrompt(note);
                          }}>
                          <FontAwesomeIcon
                            size="2xs"
                            icon={solid("trash")}
                            className="text-red-400 hover:text-red-500 cursor-pointer"
                          />
                        </ActionIcon>
                      </Flex>
                    </td>
                  </tr>
                </Fragment>
              ))}
          </tbody>
        </Table>
      </div>
      {hasMore && notes.length > 0 && (
        <div className="flex justify-center mt-4">
          <Button
            loading={notesLoading === "loading"}
            disabled={notesLoading === "loading"}
            variant="outline"
            color={theme.colorScheme === "dark" ? "dark.2" : "gray.7"}
            onClick={() => {
              page.current += 1;
              getAnnotationNotes(noteVisibilityType, page.current);
            }}
            data-cy="annotation-notes-view-load-more-btn">
            Load more
          </Button>
        </div>
      )}

      {/* NotesModel component */}
      <NotesModel
        opened={notesModelOpened}
        setOpened={setNotesModelOpened}
        type={"update"}
        applyNoteChanges={applyNoteChanges}
        data={noteToUpdateOrDelete}
      />
    </SidePanel>
  );
};

export default NotesDetailView;
