/* eslint-disable react-hooks/exhaustive-deps */
import { getReadableFormatNumber, numberToCommas } from "@/lib/utils/StringUtility";
import React from "react";
import { ActiveCardProps, LoadingStateProps } from "@/types/types.d";
import { SummaryCard } from "../../../Cards/SummaryCard";

type Props = {
  activeCard: ActiveCardProps;
  loading: LoadingStateProps;
  data: any;
  comparisonData: any;
  query: any;
  period: any;
  prevPeriod: any;
  comparisonPeriod?: any;
};

const TotalConversions = ({
  activeCard,
  loading,
  data,
  comparisonData,
  query,
  period,
  prevPeriod,
  comparisonPeriod
}: Props) => {
  const getComparisonPercentageDiff = () => {
    const percentageChange =
      ((data.total_conversions - comparisonData.total_conversions) /
        comparisonData.total_conversions) *
      100;
    return Math.round(percentageChange);
  };

  return (
    <div data-cy="events-card">
      <SummaryCard
        cursor="cursor-default"
        activeCard={activeCard}
        title={"Total Conversions"}
        onClick={() => {}}
        uniquekey={"total_conversions"}
        card={"total_conversions"}
        value={getReadableFormatNumber(data.total_conversions)}
        tooltip={`${numberToCommas(data.total_conversions)} Total Conversions`}
        runningDiff={data.total_conversions_diff}
        runningDiffTooltip={`${data.total_conversions_diff}% ${
          data.total_conversions_diff > 0 ? "increase" : "decrease"
        } in total conversions from previous period ${
          comparisonPeriod ? comparisonPeriod : prevPeriod
        } `}
        loading={loading}
        showRunningDiff={query?.period !== "live"}
        comparison={query?.comparison}
        comparisonPercentageDiff={getComparisonPercentageDiff()}
      />
    </div>
  );
};
const TotalConversionsMemoized = React.memo(TotalConversions);
export { TotalConversionsMemoized as TotalConversions };
