import { StepCard } from "@/ui/components/Common/StepCard/StepCard";
import "./setup.scss";
import { Avatar, Box, Flex, Select, Text } from "@mantine/core";
interface RetentionSelectRatioProps {
  retentionFrequency: any;
  setRetentionFrequency: (e: any) => void;
  title?: string;
  description?: string;
}

export const RetentionSelectRatio: React.FC<RetentionSelectRatioProps> = ({
  retentionFrequency,
  setRetentionFrequency,
  title = "Frequency usage of your product",
  description = "Is your product something people use daily, weekly or monthly?"
}) => {
  return (
    <>
      <StepCard step={4}>
        <Flex direction="column">
          <Text fw={600} mb="md">
            {title}
          </Text>

          <Text mb="md">{description}</Text>
          <Select
            w={100}
            name="retention_frequency_ratio"
            id="retentionFrequencyRatio"
            data={[
              {
                label: "Daily",
                value: "daily"
              },
              {
                label: "Weekly",
                value: "weekly"
              },
              {
                label: "Monthly",
                value: "monthly"
              }
            ]}
            value={retentionFrequency}
            onChange={setRetentionFrequency}
          />
        </Flex>
      </StepCard>
    </>
  );
};
