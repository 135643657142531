import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { ActiveUsersTrendsService } from "@/lib/services/ActiveUsersTrendsService";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import { DATE_FORMAT } from "@/lib/utils/Constants";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { DropdownMenu } from "@/ui/components/App/Dropdowns/DropdownMenu";
import { LineGraphWrapper } from "@/ui/components/App/EChartsWrappers/LineGraphWrapper";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";
import { Box, useMantineTheme } from "@mantine/core";
import { endOfDay, format, startOfDay, subMonths } from "date-fns";
import * as echarts from "echarts/core";
import { FC, useContext, useEffect, useState } from "react";
import { InsightIdType } from "types/types.d";

export const MonthlyActiveUsers: FC<InsightIdType> = ({ insightId, reportType = "user" }) => {
  const [filters] = useSegmentStore((state) => [state.filters]);
  const [isLoading, setIsLoading] = useState("idle");
  const { activeWorkspace } = useContext(AppLifecycleContext);
  // const { insightId } = useParams<{
  //   insightId: string;
  // }>();
  const [dailyActiveUsers, setDailyActiveUsers] = useState<Array<any>>([]);

  const getActiveUsersHistogram = async (
    startDate = format(startOfDay(subMonths(new Date(), 3)), DATE_FORMAT),
    endDate = format(endOfDay(new Date()), DATE_FORMAT)
  ) => {
    setIsLoading("loading");
    const activeUsersTrendsService = new ActiveUsersTrendsService();
    await activeUsersTrendsService
      .activeUsersHistogram(
        activeWorkspace.id,
        insightId,
        "monthly",
        reportType,
        startDate,
        endDate,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        if (res.data) {
          setDailyActiveUsers([
            {
              name: `Monthly Active ${reportType == "user" ? "Users" : "Companies"}`,
              type: "line",
              smooth: false,
              lineStyle: {
                width: 2
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgb(125, 71, 235, 0.6)"
                  },
                  {
                    offset: 1,
                    color: "rgba(125, 71, 235, 0.01)"
                  }
                ])
              },
              emphasis: {
                focus: "series"
              },
              data: res.data.slice(0, -1).map((item: any) => [item.month, item.count])
            },
            {
              name: `Monthly Active ${reportType == "user" ? "Users" : "Companies"}(In Progress)`,
              type: "line",
              color: "#7D47EB",
              smooth: false,
              lineStyle: {
                width: 2,
                type: "dashed"
              },
              itemStyle: {
                emphasis: {
                  label: {
                    show: false
                  }
                }
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgb(125, 71, 235, 0.3)"
                  },
                  {
                    offset: 1,
                    color: "rgba(125, 71, 235, 0.01)"
                  }
                ])
              },
              emphasis: {
                focus: "series"
              },
              data: res.data.slice(-2).map((item: any) => [item.month, item.count])
            }
          ]);
        }
      });
    setIsLoading("loaded");
  };

  useEffect(() => {
    getActiveUsersHistogram();
  }, [insightId, filters]);

  const onChangeDate = (item: any) => {
    console.log(item);
    switch (item.value) {
      case "last_3_months":
        getActiveUsersHistogram(
          format(startOfDay(subMonths(new Date(), 3)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT)
        );
        break;
      case "last_6_months":
        getActiveUsersHistogram(
          format(startOfDay(subMonths(new Date(), 6)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT)
        );
        break;
      case "last_9_months":
        getActiveUsersHistogram(
          format(startOfDay(subMonths(new Date(), 9)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT)
        );
        break;
      case "last_1_year":
        getActiveUsersHistogram(
          format(startOfDay(subMonths(new Date(), 12)), DATE_FORMAT),
          format(endOfDay(new Date()), DATE_FORMAT)
        );
        break;
      default:
        break;
    }
  };

  // =
  return (
    <>
      <InsightSection
        title={`Monthly Active ${reportType == "user" ? "Users" : "Companies"}`}
        description={
          <>
            A monthly active{" "}
            {reportType == "user" ? "user is someone who" : "company is the one which"} interacts
            with an app over a period of 30 days (e.g. a {reportType == "user" ? "user" : "company"}{" "}
            who opens the app in January).
          </>
        }
        rightSection={
          <DropdownMenu
            selectedOption={{
              label: "Last 3 Months",
              value: "last_3_months"
            }}
            options={[
              {
                label: "Last 3 Months",
                value: "last_3_months"
              },
              {
                label: "Last 6 Months",
                value: "last_6_months"
              },
              {
                label: "Last 9 Months",
                value: "last_9_months"
              },
              {
                label: "Last 1 Year",
                value: "last_1_year"
              }
            ]}
            onChange={onChangeDate}></DropdownMenu>
        }>
        <Box w={"100%"}>
          {isLoading === "loaded" ? (
            <>
              {dailyActiveUsers.length > 0 ? (
                <LineGraphWrapper
                  series={dailyActiveUsers}
                  tooltipDateFormat={"MMMM, yyyy"}
                  axisLabelFormat={"{MMM}"}
                  useGenericTooltip={true}
                />
              ) : (
                <NoResults heading={"No Results"} text={"There are no results."} />
              )}
            </>
          ) : (
            <BarChartSkeleton />
          )}
        </Box>
      </InsightSection>
    </>
  );
};
