import { Divider, Flex, Title } from "@mantine/core";

interface IHeaderProps {
  title: string;
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
}

export const Header = ({ title, leftSection, rightSection }: IHeaderProps) => {
  return (
    <>
      <Flex align={"center"} mb={"md"}>
        <Flex flex={1} align={"center"}>
          <Title order={3} fw={600} mr={8}>
            {title}
          </Title>
          {leftSection && leftSection}
        </Flex>
        <Flex>{rightSection && rightSection}</Flex>
      </Flex>
      <Divider mb="md" />
    </>
  );
};
