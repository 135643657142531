/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { numberToCommas, numberToUnit } from "@/lib/utils/StringUtility";
import React from "react";
import { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import { SummaryCard } from "../SummaryCard";
import ContentLoader from "react-content-loader";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  topBarCardValues: ITopBarCardValues;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
  period: any;
  prevPeriod: any;
  comparisonPeriod?: any;
};

const PageviewsCard = ({
  activeCard,
  setActiveCard,
  topBarCardValues,
  comparisonTopBarCardValues,
  loading,
  query,
  period,
  prevPeriod,
  comparisonPeriod
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const getPageviewsCardValue = () => {
    let value = topBarCardValues.pageviews;
    return value < 100000 ? numberToCommas(value) : numberToUnit(value);
  };

  const getComparisonPercentageDiff = () => {
    const percentageChange =
      ((topBarCardValues.pageviews - comparisonTopBarCardValues.pageviews) /
        comparisonTopBarCardValues.pageviews) *
      100;
    return Math.round(percentageChange);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="pageviews-card">
      {loading === "loaded" ? (
        <SummaryCard
          aboutTooltip="Pageviews represent each individual time a page on your website is loaded by a visitor. If the visitor reloads a page, this counts as an additional page view. If the visitor checks multiple pages and some of them twice, each visit will count as another page view."
          activeCard={activeCard}
          title={query?.period === "live" ? "Pageviews (Last 30 Min.)" : "Pageviews"}
          onClick={() => setActiveCard("pageviews")}
          uniquekey={"Pageviews"}
          card={"pageviews"}
          value={getPageviewsCardValue()}
          tooltip={`${numberToCommas(topBarCardValues.pageviews)} pageviews`}
          runningDiff={topBarCardValues.pageviews_diff}
          runningDiffTooltip={`${topBarCardValues.pageviews_diff}% ${
            topBarCardValues.pageviews_diff > 0 ? "increase" : "decrease"
          } in pageviews from previous period ${comparisonPeriod ? comparisonPeriod : prevPeriod} `}
          loading={loading}
          showRunningDiff={query?.period !== "live"}
          comparison={query?.comparison}
          comparisonPercentageDiff={getComparisonPercentageDiff()}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const PageviewsCardMemoized = React.memo(PageviewsCard);
export { PageviewsCardMemoized as PageviewsCard };
