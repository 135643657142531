import React, { useEffect, useState } from "react"

import { format } from "date-fns"
import ReactEChartsCore from "echarts-for-react/lib/core"
import { LineChart } from "echarts/charts"
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
} from "echarts/components"
import * as echarts from "echarts/core"
import { UniversalTransition } from "echarts/features"
import { CanvasRenderer } from "echarts/renderers"
import cloneDeep from "lodash.clonedeep"
import { blue, purple } from "@/style/Colors"
import { useMantineTheme } from "@mantine/core"
import { GenericTooltip } from "../../../../lib/utils/EChartsTooltipUtility"
import { useMediaQuery } from '@mantine/hooks';
import { MOBILE_BREAKPOINT_QUERY } from '../../../../lib/utils/Constants';
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
])

interface ILineGraphWrapper {
  /**
   * Series objects for the graph. Each object has properties similar to eCharts.
   */
  series: any
  /**
   * List of legends.
   */
  legend?: any
  /**
   * X-axis label formatter.
   * @param params
   * @returns
   */
  labelFormatter?: (params: any) => string
  /**
   * Date format for the tooltip. i.e "eee, MMMM dd, yyyy"
   */
  tooltipDateFormat?: string
  /**
   * Date format for the X-Axis label. i.e "{MMM} {dd}"
   */
  axisLabelFormat?: string
  /**
   * Show percentage on the graph. When the show percentage is true, the series data will set the max value to 100 for Y-Axis
   */
  showPercentage?: boolean

  /**
   * Use generic tooltip for the graph.
   */
  useGenericTooltip?: boolean
}

const LineGraphWrapper = ({
  series,
  legend = {
    show: false,
    data: [],
  },
  labelFormatter,
  tooltipDateFormat = "eee, MMMM dd, yyyy",
  axisLabelFormat = "{MMM} {dd}",
  showPercentage = false,
  useGenericTooltip = false,
}: ILineGraphWrapper) => {
  const theme = useGlobalMantineTheme()
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);


  let DEFAULT_CHART_OPTIONS = {
    useUTC: true,
    color: [purple, blue],
    title: {
      text: "",
    },
    notMerge: true,
    tooltip: {
      borderColor: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2],
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: theme.colorScheme === "dark" ? "#A6A7AB" : "#595c5f",
      },
      trigger: "axis",
      formatter: useGenericTooltip ? (params: any) => GenericTooltip(params, false) : undefined,
      axisPointer: {
        type: "line",
        label: {
          fontWeight: "bold",
          padding: [10, 15, 10, 15],
          formatter: (params: any) => {
            return format(new Date(params.value), tooltipDateFormat)
          },
        },
      },
    },
    legend: {
      show: false,
      data: [],
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      top: "5%",
      left: "3%",
      right: "3%",
      bottom: "5%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "time",
        axisLine: {
          show: false,
        },
        axisLabel: {
          fontSize: 10,
          formatter: axisLabelFormat,
          axisPointer: {
            show: false,
          },
          margin: 14,
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          splitNumber: 4,
        },
      },
    ],
    yAxis: [
      {
        min: 0,
        type: "value",
        splitLine: true,
        alignTicks: true,
        axisTick: {
          show: true,
          splitNumber: 4,
        },
      },
    ],
    series: [],
    replaceMerge: ["series"],
  }
  DEFAULT_CHART_OPTIONS.series = series
  DEFAULT_CHART_OPTIONS.legend = legend
  DEFAULT_CHART_OPTIONS.tooltip.axisPointer.label.padding = [10, 15, 10, 15]

  if (labelFormatter) {
    // @ts-ignore
    DEFAULT_CHART_OPTIONS.xAxis[0].axisLabel.formatter = labelFormatter
  }
  if (showPercentage) {
    // @ts-ignore
    DEFAULT_CHART_OPTIONS.yAxis[0].max = 100
    // @ts-ignore
    DEFAULT_CHART_OPTIONS.tooltip.valueFormatter = (value: any) => `${value}%`
  }
  const [option, setOption] = useState(DEFAULT_CHART_OPTIONS)

  useEffect(() => {

    if (isMobileView === undefined) {
      return
    }

    DEFAULT_CHART_OPTIONS.series = series
    const cloneOption:any = cloneDeep(DEFAULT_CHART_OPTIONS)

    if (isMobileView) {
      cloneOption.yAxis[0] = {
        ...cloneOption.yAxis[0],
        splitNumber: 2,
      }
    } else {
      // Remove splitNumber from yAxis
      const { splitNumber, ...rest } = cloneOption.yAxis[0]
      cloneOption.yAxis[0] = {
        ...rest,
      }
    }

    setOption(cloneOption)
  }, [series, isMobileView])

  return (
    <>
      {series.length > 0 && (
        <div className="h-full w-full">
          <ReactEChartsCore
            style={{ height: "350px" }}
            // ref={chartRef}
            opts={{ renderer: "svg" }}
            notMerge={true}
            echarts={echarts}
            option={option}
            // onChartReady={handleChartReady}
          />
        </div>
      )}
    </>
  )
}

const LineGraphMemoized = React.memo(LineGraphWrapper)
export { LineGraphMemoized as LineGraphWrapper }
