import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { AIChatMain } from "@/ui/components/App/AIChat/AIChatMain";
import { CancelledSubscriptionNotification } from "@/ui/components/App/Notifications/CancelledSubscriptionNotification";
import { EventRecommendationNotification } from "@/ui/components/App/Notifications/EventRecommendationNotification";
import { PastDueNotification } from "@/ui/components/App/Notifications/PastDueNotification";
import { PausedSubscriptionNotification } from "@/ui/components/App/Notifications/PausedSubscriptionNotification";
import { QuotaExceededNotification } from "@/ui/components/App/Notifications/QuotaExceededNotification";
import { TrialPlanNotification } from "@/ui/components/App/Notifications/TrialPlanNotification";
import { SetupNotification } from "@/ui/components/App/SetupGuides/SetupNotification/SetupNotification";
import { Box, Container, Paper } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { MOBILE_BREAKPOINT_QUERY } from "../../../lib/utils/Constants";
import OverviewModal from "../../components/App/OverviewModal/OverviewModal";
import { ProductInsightsAuth } from "../ProductInsightsAuth";
import { SegmentPreflight } from "../SegmentPreflight";
import { Segments } from "../Segments/Segments";
import { ProductInsightUpgrade } from "../Upgrades/ProductInsightUpgrade";

// const Users = lazy(async () => await import('../UsersCRM/Users'));

// const Visitors = lazy(async () => await import('../Visitors/Visitors'));

// const UsersCRMDetail = lazy(async () => await import('../UsersCRM/UserCRMDetail'));
import UsersCRMDetail from "../UsersCRM/UserCRMDetail";

// const Companies = lazy(async () => await import('../Companies/Companies'));

// const Company = lazy(async () => await import('../Companies/Company'));
import Company from "../Companies/Company";

// const SetupGuidesHome = lazy(async () => await import('@/ui/pages/SetupGuides/SetupGuidesHome'));
import SetupGuidesHome from "@/ui/pages/SetupGuides/SetupGuidesHome";

// const IntegrationSetupGuide = lazy(async () => await import('@/ui/pages/SetupGuides/IntegrationSetupGuide'));
import IntegrationSetupGuide from "@/ui/pages/SetupGuides/IntegrationSetupGuide";

// const InsightsSetupGuide = lazy(async () => await import('@/ui/pages/SetupGuides/InsightsSetupGuide'));
import InsightsSetupGuide from "@/ui/pages/SetupGuides/InsightsSetupGuide";

// Account Settings
// const AccountSettingsNavigation = lazy(async () => await import('@/ui/pages/Settings/AccountSettingsNavigation'));
import AccountSettingsNavigation from "@/ui/pages/Settings/AccountSettingsNavigation";

// Workspace Settings
// const WorkspaceSettingsNavigation = lazy(
//   async () => await import('@/ui/pages/Settings/WorkspaceSettingsNavigation')
// );
import WorkspaceSettingsNavigation from "@/ui/pages/Settings/WorkspaceSettingsNavigation";

// Web analytics
// const WebAnalyticsDashboard = lazy(async () => await import('@/ui/pages/WebAnalytics/WebAnalyticsDashboard'));
import WebAnalyticsDashboard from "@/ui/pages/WebAnalytics/WebAnalyticsDashboard";

// Product Insights
// const InsightsDashboard = lazy(async () => await import('@/ui/pages/InsightsDashboard/InsightsDashboard'));
import InsightsDashboard from "../InsightsDashboard/InsightsDashboard";

// const InsightsDashboardOverview = lazy(
//   async () => await import('@/ui/pages/InsightsDashboard/Pages/InsightsDashboardOverview')
// );
import InsightsDashboardOverview from "@/ui/pages/InsightsDashboard/Pages/InsightsDashboardOverview";

// const InsightsDashboardOnboarding = lazy(
//   async () => await import('@/ui/pages/InsightsDashboard/Pages/InsightsDashboardOnboarding')
// );
import InsightsDashboardOnboarding from "@/ui/pages/InsightsDashboard/Pages/InsightsDashboardOnboarding/InsightsDashboardOnboarding";

// const InsightsDashboardEngagement = lazy(
//   async () => await import('@/ui/pages/InsightsDashboard/Pages/InsightsDashboardEngagement')
// );
import InsightsDashboardEngagement from "@/ui/pages/InsightsDashboard/Pages/InsightsDashboardEngagement";

// const InsightsDashboardFeature = lazy(
//   async () => await import('@/ui/pages/InsightsDashboard/Pages/InsightsDashboardFeature')
// );

// const InsightsDashboardRetention = lazy(
//   async () => await import('@/ui/pages/InsightsDashboard/Pages/InsightsDashboardRetention')
// );
import InsightsDashboardRetention from "@/ui/pages/InsightsDashboard/Pages/InsightsDashboardRetention";

// const Insights = lazy(async () => await import('@/ui/pages/Insights/Insights'));
import Insights from "@/ui/pages/Insights/Insights";

// const OnboardingCompletionRateTrends = lazy(
//   async () => await import('@/ui/pages/Insights/Trends/OnboardingCompletionRate/OnboardingCompletionRateTrends')
// );
import OnboardingCompletionRateTrends from "@/ui/pages/Insights/Trends/OnboardingCompletionRate/OnboardingCompletionRateTrends";

// const ActiveUsersTrends = lazy(
//   async () => await import('@/ui/pages/Insights/Trends/ActiveUsers/ActiveUsersTrends')
// );
import ActiveUsersTrends from "@/ui/pages/Insights/Trends/ActiveUsers/ActiveUsersTrends";

// const PowerUsersTrends = lazy(async () => await import('@/ui/pages/Insights/Trends/PowerUsers/PowerUsersTrends'));
import PowerUsersTrends from "@/ui/pages/Insights/Trends/PowerUsers/PowerUsersTrends";

// const UserRetentionTrends = lazy(
//   async () => await import('@/ui/pages/Insights/Trends/UserRetention/UserRetentionTrends')
// );
import UserRetentionTrends from "@/ui/pages/Insights/Trends/UserRetention/UserRetentionTrends";

// const FeatureRetentionTrends = lazy(
//   async () => await import('@/ui/pages/Insights/Trends/FeatureRetention/FeatureRetentionTrends')
// );

// const SlippingAwayUsersTrends = lazy(
//   async () => await import('@/ui/pages/Insights/Trends/SlippingAwayUsers/SlippingAwayUsersTrends')
// );
import SlippingAwayUsersTrends from "@/ui/pages/Insights/Trends/SlippingAwayUsers/SlippingAwayUsersTrends";

// const FeatureAdoptionTrends = lazy(
//   async () => await import('@/ui/pages/Insights/Trends/FeatureAdoption/FeatureAdoptionTrends')
// );
import FeatureAdoptionTrends from "@/ui/pages/Insights/Trends/FeatureAdoption/FeatureAdoptionTrends";

// const Funnels = lazy(async () => await import('../Analyze/Funnels'));
import Funnels from "../Analyze/Funnels";

// const Journeys = lazy(async () => await import('../Analyze/Journeys'));
import Journeys from "../Analyze/Journeys";

// const Attribution = lazy(async () => await import('../Analyze/Attribution'));
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import { DeprecationOfLegacyPlanNotification } from "@/ui/components/App/Notifications/DeprecationOfLegacyPlanNotification";
import ContactsHub from "@/ui/pages/ContactsHub/ContactsHub";
import Attribution from "../Analyze/Attribution";
import InsightsDashboardFeatureAdoption from "../InsightsDashboard/Pages/InsightsDashboardFeatureAdoption";
import { AdminAccessedBanner } from "@/ui/components/App/Notifications/AdminAccessedBanner";
import MavenAi from "@/ui/pages/MavenAi/MavenAi";
import Reports from "@/ui/pages/Reports/Reports";
import EventsNavigation from "@/ui/pages/Events/EventsNavigation";
import clsx from "clsx";
import classes from "./RightPane.module.css";
export const RightPane = () => {
  const { activeWorkspace, isCollapsedLeftMenu } = useContext(AppLifecycleContext);
  const location = useLocation();
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  const [isOverViewModalOpen, overviewModalType, overviewModalUniqueId, setIsOverViewModalOpen] =
    useUserOverviewModalStore((state) => [
      state.isOpen,
      state.type,
      state.uniqueId,
      state.setIsOpen
    ]);

  const isSettingsPage =
    location.pathname.includes("/settings/") ||
    location.pathname.includes("/account/") ||
    location.pathname.includes("/events/");

  const noPaddingPaths = ["/visitors/", "/users/", "/companies/", "dashboard/feature"];
  const noPaddingRoutes = noPaddingPaths.some((path) => location.pathname.includes(path));
  return (
    <Box className="app-wrapper">
      <Box ml={isMobileView ? 0 : isCollapsedLeftMenu ? 84 : 220}>
        <AdminAccessedBanner className={isSettingsPage ? "ml-[17.5rem] mr-4" : "mx-4 my-2"} />
        <EventRecommendationNotification />
        <PastDueNotification className={isSettingsPage ? "ml-80 mr-12 -left-4 relative" : ""} />
        <Box ml={isSettingsPage ? 320 : 0} mr={0} pos={"relative"}>
          <DeprecationOfLegacyPlanNotification
            className={isSettingsPage ? "ml-80 mr-12 -left-4 relative" : ""}
          />
          <PausedSubscriptionNotification
            className={isSettingsPage ? "ml-80 mr-12 -left-4 relative" : ""}
          />
          <CancelledSubscriptionNotification
            className={isSettingsPage ? "ml-80 mr-12 -left-4 relative" : ""}
          />
          <QuotaExceededNotification
            className={isSettingsPage ? "ml-80 mr-12 -left-4 relative" : ""}
          />
          <TrialPlanNotification />
        </Box>
        <SetupNotification />
      </Box>

      <Paper>
        <div
          className={clsx(
            classes.pane,
            isMobileView ? classes.mobile : undefined,
            isCollapsedLeftMenu ? classes.collapsed : undefined
          )}
          // className={`um-app-wrapper--right-pane ${
          //   isCollapsedLeftMenu ? "um-app-wrapper-right-pane-collapsed" : ""
          // }`}
        >
          {/*{location.pathname.includes("web/statistics") && <AIChatMain />}*/}
          <div
            className={classes.wrapper}
            // className={`um-app-wrapper--right-pane--wrapper ${
            //   !activeWorkspace.is_setup_completed
            //     ? "um-app-wrapper--right-pane--wrapper--setup-remaining"
            //     : ""
            // }`}
          >
            <div className={classes.container}>
              <Box p={noPaddingRoutes ? undefined : "lg"} className={classes.content}>
                <>
                  <Routes>
                    <Route path={`setup-guides/`} element={<SetupGuidesHome />}>
                      <Route path={`integration`} element={<IntegrationSetupGuide />} />
                      <Route path={`insights`} element={<InsightsSetupGuide />} />
                    </Route>
                    <Route path={`web/statistics`} element={<WebAnalyticsDashboard />}></Route>
                    <Route path={`analyze/journeys`} element={<Journeys />}></Route>
                    <Route path={`reports`} element={<Reports />}></Route>
                    <Route path={`analyze/funnels`} element={<Funnels />}></Route>
                    <Route path={`maven-ai/:id`} element={<MavenAi />}></Route>
                    <Route
                      path={`analyze/attribution`}
                      element={
                        <ProductInsightsAuth>
                          <Attribution />
                        </ProductInsightsAuth>
                      }></Route>

                    <Route path={`account/*`} element={<AccountSettingsNavigation />}></Route>
                    <Route path={`settings/*`} element={<WorkspaceSettingsNavigation />}></Route>
                    <Route path={`events/*`} element={<EventsNavigation />}></Route>

                    <Route
                      path={`insights/dashboard/`}
                      element={
                        <ProductInsightsAuth>
                          <InsightsDashboard />
                        </ProductInsightsAuth>
                      }>
                      <Route path={`overview`} element={<InsightsDashboardOverview />} />
                      <Route path={`onboarding`} element={<InsightsDashboardOnboarding />} />
                      <Route
                        path={`adoption_engagement`}
                        element={<InsightsDashboardEngagement />}
                      />
                      <Route
                        path={`feature`}
                        element={
                          <ProductInsightsAuth>
                            <InsightsDashboardFeatureAdoption />
                          </ProductInsightsAuth>
                        }>
                        <Route
                          path={`:moduleId/:featureId`}
                          element={<InsightsDashboardFeatureAdoption />}
                        />
                      </Route>
                      <Route path={`retention`} element={<InsightsDashboardRetention />} />
                    </Route>

                    {/* // This path is deprecated but not the Insights component */}
                    {/* <Route
                      path={`insights/list`}
                      element={
                        <ProductInsightsAuth>
                          <Insights />
                        </ProductInsightsAuth>
                      }></Route> */}
                    {/* <Route path={`insights/reports/trends/`}>
                      <Route path={`active_users`} element={<ActiveUsersTrends />}></Route>
                      <Route
                        path={`active_users/:insightId`}
                        element={<ActiveUsersTrends />}></Route>

                      <Route path={`power_users`} element={<PowerUsersTrends />} />
                      <Route path={`power_users/:insightId`} element={<PowerUsersTrends />} />
                      <Route path={`user_retention`} element={<UserRetentionTrends />} />
                      <Route path={`user_retention/:insightId`} element={<UserRetentionTrends />} />
                      <Route path={`feature_retention`} element={<FeatureRetentionTrends />} />
                      <Route
                        path={`feature_retention/:insightId`}
                        element={<FeatureRetentionTrends />}
                      />
                      <Route path={`feature_adoption`} element={<FeatureAdoptionTrends />} />
                      <Route
                        path={`feature_adoption/:insightId`}
                        element={<FeatureAdoptionTrends />}
                      />
                      <Route path={`slipping_away_users`} element={<SlippingAwayUsersTrends />} />
                      <Route
                        path={`slipping_away_users/:insightId`}
                        element={<SlippingAwayUsersTrends />}
                      />
                      <Route
                        path={`onboarding_completion_rate`}
                        element={<OnboardingCompletionRateTrends />}
                      />
                      <Route
                        path={`onboarding_completion_rate/:insightId`}
                        element={<OnboardingCompletionRateTrends />}
                      />
                    </Route> */}
                    <Route
                      path={`visitors/:segment`}
                      element={
                        <ProductInsightsAuth disabled={true}>
                          <SegmentPreflight>
                            <ContactsHub type={"visitors"} />
                          </SegmentPreflight>
                        </ProductInsightsAuth>
                      }>
                      <Route
                        path={":uniqueId"}
                        element={
                          <OverviewModal
                            title="Visitor Overview"
                            opened={true}
                            transition={"slide-left"}
                            type={"visitor"}
                          />
                        }></Route>
                    </Route>

                    <Route
                      path={`users/:segment`}
                      element={
                        <ProductInsightsAuth>
                          <SegmentPreflight>
                            <ContactsHub type={"users"} />
                          </SegmentPreflight>
                        </ProductInsightsAuth>
                      }>
                      <Route
                        path={":uniqueId"}
                        element={
                          <OverviewModal
                            title="Users Overview"
                            opened={true}
                            transition={"slide-left"}
                            type={"user"}
                          />
                        }></Route>
                    </Route>
                    <Route
                      path={`users/:segment/:peopleId/*`}
                      element={
                        <ProductInsightsAuth>
                          <UsersCRMDetail />
                        </ProductInsightsAuth>
                      }></Route>

                    <Route
                      path={`companies/:segment`}
                      element={
                        <ProductInsightsAuth>
                          <SegmentPreflight>
                            <ContactsHub type={"companies"} />
                          </SegmentPreflight>
                        </ProductInsightsAuth>
                      }>
                      <Route
                        path={":uniqueId"}
                        element={
                          <OverviewModal
                            title="Company Overview"
                            opened={true}
                            transition={"slide-left"}
                            type={"company"}
                          />
                        }></Route>
                    </Route>
                    <Route
                      path={`companies/:segment/:companyId/*`}
                      element={
                        <ProductInsightsAuth>
                          <Company />
                        </ProductInsightsAuth>
                      }></Route>

                    <Route
                      path={`product-insights-upgrade`}
                      element={<ProductInsightUpgrade />}></Route>
                    <Route
                      path="*"
                      element={
                        <Navigate
                          to={`/env/${activeWorkspace.identifier}/web/statistics`}
                          replace
                        />
                      }
                    />
                    <Route element={<>Not found...</>}></Route>
                  </Routes>
                </>
              </Box>
            </div>
          </div>
        </div>
      </Paper>

      <OverviewModal
        opened={isOverViewModalOpen}
        setOpened={() => setIsOverViewModalOpen(false)}
        id={overviewModalUniqueId}
        transition={"slide-left"}
        type={overviewModalType}
      />
    </Box>
  );
};
