import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Flex, Rating, useMantineColorScheme } from "@mantine/core";

export const FeatureAdoptionFrequencyPulse = (frequencyValue: string, colorScheme: any) => {
  const getEngagementColor = (engagement: string) => {
    switch (engagement) {
      case "daily":
        return "#20c997";
      case "weekly":
        return "#94d82d";
      case "bi-weekly":
        return "#fcc419";
      case "monthly":
        return "#ff922b";
      case "quarterly":
        return "#40C057";
      default:
        return "#fff3bf";
    }
  };
  return (
    <>
      <Rating
        style={{
          marginTop: "0.5rem"
        }}
        mr={2}
        // mt="xs"
        size="xs"
        count={5}
        c={colorScheme === "dark" ? "dark.4" : "gray.2"}
        emptySymbol={<FontAwesomeIcon size="xs" icon={solid("square")} />}
        fullSymbol={
          <Box
            style={{
              color: getEngagementColor(frequencyValue)
            }}>
            <FontAwesomeIcon size="xs" icon={solid("square")} />
          </Box>
        }
        value={
          frequencyValue === "daily"
            ? 5
            : frequencyValue === "weekly"
            ? 4
            : frequencyValue === "bi-weekly"
            ? 3
            : frequencyValue === "monthly"
            ? 2
            : 1
        }
        readOnly
      />
    </>
  );
};
