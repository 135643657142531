import axios from "./JWTService";
import { IReport } from "@/stores/useReportStore";

export class ReportService {
  private readonly _endpoint;

  constructor() {
    this._endpoint = "/v1/workspace_report/";
  }

  /**
   * Get all reports.
   */
  getReports = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this._endpoint}${workspaceId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Create a new report.
   */
  createReport = (workspaceId: string, data: IReport): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}${workspaceId}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Update a report.
   */
  updateReport = (workspaceId: string, reportId: string, data: IReport): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${this._endpoint}${workspaceId}/${reportId}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Delete a report.
   */
  deleteReport = (workspaceId: string, reportId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${this._endpoint}${workspaceId}/${reportId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Get report template.
   */
  getReportTemplate = (workspaceId: string, report: IReport, previewType: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `${this._endpoint}${workspaceId}/template/report?name=${report.name}&type=${report.type}&period=${report.period}&preview_type=${previewType}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Get report activity logs.
   */
  getReportActivityLogs = (
    workspaceId: string,
    page: number,
    limit: number = 100
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this._endpoint}${workspaceId}/report_logs?page=${page}&limit=${limit}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
