import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import { TOAST_MESSAGES, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { getWorkspaceAvatarURL } from "@/lib/utils/WorkspaceUtility";
import { SettingsHeaderContent } from "@/ui/components/App/Settings/SettingsHeaderContent/SettingsHeaderContent";
import { Label } from "@/ui/components/Common/Label/Label";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Image,
  Input,
  SimpleGrid,
  Title,
  Text,
  useMantineTheme,
  useMantineColorScheme
} from "@mantine/core";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import TimezoneSelect, { ITimezone } from "react-timezone-select";
import { toast } from "react-toastify";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

type WorkspaceType = {
  id: string;
};

interface IFormInputs {
  name: string;
  website: string;
}

export const GeneralWorkspaceDetails: React.FC<any> = () => {
  const { activeWorkspace, setActiveWorkspace, workspaces, setWorkspaces } =
    useContext(AppLifecycleContext);
  // eslint-disable-next-line
  const [actionState, setActionState] = useState("idle");
  const { hasRolesWith, hasRolesNotWith } = useWorkspaceUtilityHook();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IFormInputs>();
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
    // Intl.DateTimeFormat().resolvedOptions().timeZone
    activeWorkspace.timezone
  );
  const onChangeTimezone = (tz: any): any => {
    setSelectedTimezone(tz.value);
  };
  const [loading, setLoading] = useState(false);
  const onSubmitWorkspaceDetailsUpdate = (data: any) => {
    data.timezone = selectedTimezone;
    console.log(data);
    const wsService = new WorkspaceService();
    wsService
      .updateWorkspaceDetails(activeWorkspace?.id, data)
      .then((res) => {
        if (res.data) {
          setActiveWorkspace(res.data);

          // find workspace by id from workspaces.
          console.log(workspaces);
          const findWorkspaceIndex = workspaces.findIndex(
            (workspaceItem: WorkspaceType) => workspaceItem.id === res.data.id
          );
          console.log(`Found workspace with its index: ${findWorkspaceIndex}`);

          // if workspace is available with its index, update the object and update all workspaces.
          if (findWorkspaceIndex >= 0) {
            let updatedWorkspaces = JSON.parse(JSON.stringify(workspaces));
            updatedWorkspaces.splice(findWorkspaceIndex, 1, res.data);
            setWorkspaces(updatedWorkspaces);
            console.log(updatedWorkspaces, res.data);
          }
          toast.success(TOAST_MESSAGES.WORKSPACE_DETAILS_CHANGED);
        }
      })
      .catch((err) => {});
  };

  const handleImageInput = async (selectorFiles: FileList | null) => {
    console.log("Handle Image Input", selectorFiles);
    setLoading(true);
    if (selectorFiles) {
      let formData = new FormData();
      formData.append("image", selectorFiles[0]);
      const wsService = new WorkspaceService();
      await wsService
        .uploadLogo(activeWorkspace.id, formData)
        .then((res) => {
          console.log(res);
          if (res.data) {
            // update the workspace with the new logo.
            const updatedWorkspace = { ...activeWorkspace, logo: res.data.logo };
            setActiveWorkspace(updatedWorkspace);
            toast.success(TOAST_MESSAGES.WORKSPACE_LOGO_UPLOADED);
          } else {
            toast.error(TOAST_MESSAGES.WORKSPACE_IMAGE_UPLOAD_FAILED);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setLoading(false);
  };

  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <form id="workspaceDetailsUpdate" onSubmit={handleSubmit(onSubmitWorkspaceDetailsUpdate)}>
        <Title order={4} mb="md" fw={600}>
          General information for this workspace
        </Title>
        <Text mb="md">Basic details for this workspace</Text>
        <Divider mb="md" />
        <Box mt={16} className="um-settings-wrapper--container--section--profile-image">
          <Flex
            align={"center"}
            style={{
              flexGrow: 1
            }}>
            <Flex
              direction={"column"}
              w={200}
              className="um-settings-wrapper--container--section--profile-image--container">
              <Center>
                {activeWorkspace?.logo ? (
                  <img src={`${activeWorkspace?.logo}-/scale_crop/90x90/smart/`} alt="" />
                ) : (
                  <>
                    {getWorkspaceAvatarURL(activeWorkspace) && (
                      <Avatar
                        radius={96}
                        size={96}
                        style={{
                          borderColor: colorScheme === "dark" ? theme.colors.dark[4] : undefined
                        }}
                        c={colorScheme === "dark" ? "gray" : "violet"}
                        // className={workspace?.color}
                        src={getWorkspaceAvatarURL(activeWorkspace, 96)}>
                        {activeWorkspace?.name.substring(0, 1)}
                      </Avatar>
                    )}
                  </>
                )}
              </Center>
              <Box>
                <Center>
                  <Button
                    onClick={() => {
                      document.getElementById("uploadWorkspaceLogo")?.click();
                    }}
                    mt={16}
                    size="xs"
                    variant="subtle"
                    color="primary"
                    disabled={loading}
                    loading={loading}
                    leftSection={
                      <FontAwesomeIcon
                        icon={solid("upload")}
                        className="ml-2"
                        size="sm"></FontAwesomeIcon>
                    }>
                    {activeWorkspace.logo ? "Change" : "Upload"}
                  </Button>

                  <input
                    type="file"
                    accept="image/jpeg,image/png"
                    className="display-none"
                    id="uploadWorkspaceLogo"
                    onChange={(e) => {
                      handleImageInput(e.target.files);
                    }}
                  />
                </Center>
              </Box>
            </Flex>
            <Divider mx={32} orientation="vertical" />
            <div
              className=""
              style={{
                flexGrow: 1
              }}>
              <SimpleGrid
                mb="md"
                cols={{
                  base: 2,
                  md: 2,
                  sm: 1
                }}
                spacing={"xs"}
                w={"100%"}>
                <Box>
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error }
                    }) => (
                      <Input.Wrapper size="xs" id={"workspaceName"} label="Workspace Name" required>
                        <Input
                          disabled={hasRolesWith([
                            WORKSPACE_MEMBER_ROLES.VIEWER,
                            WORKSPACE_MEMBER_ROLES.AUTHOR
                          ])}
                          value={value}
                          onChange={onChange}
                          placeholder="Workspace Name"
                          error={(invalid || error) === true}
                        />
                      </Input.Wrapper>
                    )}
                    name="name"
                    control={control}
                    defaultValue={activeWorkspace?.name}
                    rules={{ required: true, maxLength: 30 }}
                  />

                  {errors.name?.type === "required" && (
                    <ValidationLabel
                      validationType="error"
                      text="Workspace name is required"
                      size="small"
                      customClasses="pt-2"></ValidationLabel>
                  )}
                  {errors.name?.type === "maxLength" && (
                    <ValidationLabel
                      validationType="error"
                      text="Workspace name should be less than 30 characters."
                      size="small"
                      customClasses="pt-2"></ValidationLabel>
                  )}
                </Box>
                <Box>
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error }
                    }) => (
                      <Input.Wrapper size="xs" id={"workspaceURL"} label="Workspace URL" required>
                        <Input
                          disabled={hasRolesWith([
                            WORKSPACE_MEMBER_ROLES.VIEWER,
                            WORKSPACE_MEMBER_ROLES.AUTHOR
                          ])}
                          value={value}
                          onChange={onChange}
                          placeholder="e.g https://usermaven.com"
                          error={(invalid || error) === true}
                        />
                      </Input.Wrapper>
                    )}
                    name="website"
                    control={control}
                    defaultValue={activeWorkspace?.website}
                    rules={{ required: true, maxLength: 48 }}
                  />

                  {errors?.website?.type === "required" && (
                    <ValidationLabel
                      validationType="error"
                      text="Website URL is required"
                      size="small"
                      customClasses="pt-2"></ValidationLabel>
                  )}
                  {errors.website?.type === "maxLength" && (
                    <ValidationLabel
                      validationType="error"
                      text="Website URL should be less than 48 characters."
                      size="small"
                      customClasses="pt-2"></ValidationLabel>
                  )}
                </Box>
              </SimpleGrid>
              <Box w={"50%"}>
                <Box className="um-settings-wrapper--container--section--input pr-4" fz="sm">
                  <Text fw={500} fz="xs" mb="xs">
                    Workspace Timezone
                  </Text>
                  <TimezoneSelect
                    isDisabled={hasRolesWith([
                      WORKSPACE_MEMBER_ROLES.VIEWER,
                      WORKSPACE_MEMBER_ROLES.AUTHOR
                    ])}
                    className={`um-select-timezone ${
                      colorScheme === "dark"
                        ? "um-select-timezone-dark"
                        : "um-select-timezone-light"
                    }`}
                    value={selectedTimezone}
                    onChange={onChangeTimezone}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        background:
                          colorScheme === "dark" ? theme.colors.dark[6] : "hsl(0, 0%, 100%)",
                        borderColor:
                          colorScheme === "dark" ? theme.colors.dark[4] : "hsl(0, 0%, 80%)",
                        color: colorScheme === "dark" ? theme.colors.dark[1] : "hsl(0, 0%, 20%)"
                      }),

                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: colorScheme === "dark" ? theme.colors.dark[1] : "hsl(0, 0%, 20%)"
                      }),

                      menuList: (baseStyles, state) => ({
                        ...baseStyles,
                        background:
                          colorScheme === "dark" ? theme.colors.dark[9] : "hsl(0, 0%, 100%)",
                        color: colorScheme === "dark" ? theme.colors.dark[1] : undefined
                      }),

                      option: (baseStyles) => ({
                        ...baseStyles,
                        color: colorScheme === "dark" ? theme.colors.dark[1] : "hsl(0, 0%, 20%)"
                      })
                    }}
                  />
                </Box>
              </Box>
              {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER, WORKSPACE_MEMBER_ROLES.AUTHOR]) && (
                <>
                  <Flex>
                    <Button
                      size="xs"
                      disabled={actionState === "loading"}
                      loading={actionState === "loading"}
                      type="submit">
                      Save
                    </Button>
                  </Flex>
                </>
              )}
            </div>
          </Flex>
        </Box>
      </form>
    </>
  );
};
