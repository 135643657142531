import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceShareService } from "@/lib/services/WorkspaceShareService";
import { LOADER_COLOR, WORKSPACE_SHARING_TYPES } from "@/lib/utils/Constants";
import { ShareDashboardFooter } from "@/ui/components/App/Share/ShareDashboardFooter";
import { ShareDashboardHeader } from "@/ui/components/App/Share/ShareDashboardHeader";
import { ShareDashboardPasswordProtected } from "@/ui/components/App/Share/ShareDashboardPasswordProtection";
import { ShareDashboardPrivate } from "@/ui/components/App/Share/ShareDashboardPrivate";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { Route, Routes, useParams } from "react-router-dom";
import { LoadingStateProps } from "types/types.d";
import ShareDashboardWeb from "./ShareDashboardWeb";
import { Box, Divider, rem, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

type Params = {
  wsIdentifier: string;
};
/**
 * Share dashboard main is acting as an auth guard for the share dashboard page.
 *  Implements two main things:
 * 1. Checks if the workspace exists and the dashboard is shareable.
 * 2. Checks if the workspace is password protected, if it is, then ask the user to verify the password.
 * @returns
 */
const ShareDashboardMain = () => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { wsIdentifier } = useParams<Params>();
  const [loading, setLoading] = useState<LoadingStateProps>("loading");
  const [isPasswordValidated, setIsPasswordValidated] = useState(false);
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const fetchSharingWorkspaceDetails = async () => {
    setLoading("loading");
    await new WorkspaceShareService()
      .getByIdentifier(wsIdentifier || "")
      .then((res) => {
        if (res.data) {
          setActiveWorkspace(res.data);
        }
      })
      .catch((err) => err);
    setLoading("loaded");
  };

  useEffect(() => {
    try {
      $crisp.push(["do", "chat:hide"]);
    } catch (ex) {
      console.log(ex);
    }
    fetchSharingWorkspaceDetails();
    return () => {
      try {
        $crisp.push(["do", "chat:show"]);
      } catch (ex) {
        console.log(ex);
      }
    };
  }, []);
  if (loading === "loading") {
    return (
      <div className="flex h-full flex-col justify-center items-center">
        <Oval color={LOADER_COLOR} height={14} width={14} />
      </div>
    );
  }

  if (!activeWorkspace.sharing) {
    return <ShareDashboardPrivate />;
  }
  console.log(isPasswordValidated);
  if (activeWorkspace.sharing === WORKSPACE_SHARING_TYPES.PRIVATE && !isPasswordValidated) {
    return <ShareDashboardPasswordProtected onSuccess={() => setIsPasswordValidated(true)} />;
  }

  return (
    <Box
      maw={"95rem"}
      mx="auto"
      data-cy="shared-dashboard"
      bg={colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0]}>
      <Box maw={"85rem"} mx="auto">
        <ShareDashboardHeader />
        <Divider />
        <Routes>
          <Route path="web" element={<ShareDashboardWeb />}></Route>
        </Routes>
        <ShareDashboardFooter />
      </Box>
    </Box>
  );
};
export default ShareDashboardMain;
