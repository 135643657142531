import {
  Input,
  TextInput,
  Text,
  Title,
  Radio,
  Box,
  Flex,
  useMantineColorScheme,
  useMantineTheme,
  Divider
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext } from "react";
import { useSegmentListStore } from "@/stores/useSegmentListStore";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { SegmentsQueryBuilder } from "./SegmentsQueryBuilder";
import { toast } from "react-toastify";
import { StepCard } from "../../Common/StepCard/StepCard";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const SegmentModal = () => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [segments, createSegment, updateSegment] = useSegmentListStore((state) => [
    state.segments,
    state.createSegment,
    state.updateSegment
  ]);
  const [
    modalOpen,
    setModalOpen,
    name,
    setName,
    id,
    description,
    setDescription,
    reset,
    type,
    setType
  ] = useSegmentStore((state) => [
    state.modalOpen,
    state.setModalOpen,
    state.name,
    state.setName,
    state.id,
    state.description,
    state.setDescription,
    state.reset,
    state.type,
    state.setType
  ]);
  const handleSubmit = async (values: any) => {
    // Check if the segment name is empty
    if (name.trim().length < 2) {
      toast.error("Please enter a valid segment name.");
      return;
    }
    // Check if the segment name is unique
    if (
      segments.find(
        (segment: any) =>
          segment.name.toLowerCase() === name.trim().toLowerCase() && segment.id !== id
      )
    ) {
      toast.error(`A segment with the name ${name} already exists. Please choose a different name`);
      return;
    }

    // Handle the segment creation or update
    let res;
    if (id) {
      res = await updateSegment(activeWorkspace.id, id, {
        name: name,
        type: type,
        filters: values,
        description: description
      });
    } else {
      res = await createSegment(activeWorkspace.id, {
        name: name,
        type: type,
        filters: values,
        description: description
      });
    }
    if (res) {
      setModalOpen(!modalOpen);
    }
  };
  return (
    <>
      <SidePanel
        onCancel={() => setModalOpen(!modalOpen)}
        opened={modalOpen}
        loading={true}
        title={id ? "Update Segment" : "Create Segment"}>
        <Box p="sm">
          <StepCard step={1}>
            <Input.Wrapper mr="md" size="sm" id={"segmentName"} label="Segment name" required>
              <TextInput
                data-autofocus
                onChange={(e) => setName(e.target.value)}
                defaultValue={name}
                placeholder="e.g Paid users"
                className="mt-2 w-80"
                error={name.length === 0 ? true : undefined}></TextInput>
            </Input.Wrapper>
            <Input.Wrapper size="sm" id={"segmentDescription"} label="Description (Optional)">
              <TextInput
                w={450}
                styles={{
                  input: {
                    borderBottom: "1px dashed",
                    borderTop: "0px",
                    borderLeft: "0px",
                    borderRight: "0px",

                    borderColor:
                      colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[5],
                    "&:focus-within": {
                      borderColor: theme.colors.brand[5]
                    }
                  }
                }}
                defaultValue={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mt-2"
                variant="unstyled"
                placeholder="e.g Users who have purchased premium plan"></TextInput>
            </Input.Wrapper>
          </StepCard>
          <StepCard step={2}>
            <Box>
              <Radio.Group
                size="sm"
                value={type}
                onChange={setType}
                // gap={"lg"}
                // offset="lg"
                name="segmentType"
                label="Choose your segment type">
                <Flex align={"center"} mt="md">
                  <Radio size="xs" mr="md" value="visitor" label="Visitor" />
                  <Radio size="xs" mr="md" value="user" label="User" />
                  <Radio size="xs" value="company" label="Company" />
                </Flex>
              </Radio.Group>
            </Box>
          </StepCard>
          <StepCard step={3}>
            <Flex direction={"column"}>
              <Text className="font-medium" size="sm">
                Choose your conditions
              </Text>
              <SegmentsQueryBuilder handleSubmit={handleSubmit} fullWidth />
            </Flex>
          </StepCard>
        </Box>
      </SidePanel>
    </>
  );
};
