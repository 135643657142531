import { Box, Text, Tooltip, useMantineTheme } from "@mantine/core";
import Label from "./Label";
import { convertSecondsToReadableFormat, ellipsisContent, numberToCommas } from "@/lib/utils/StringUtility";
import { IMultiBarData } from "@/ui/pages/Analyze/components/FunnelBarChart/components/organisms/MultiBarChart";
import { FormattedMessage, useIntl } from "react-intl";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface IBarData {
  level: number;
  visitors: number;
  avg_time_in_s: number;
  prev_count: number;
  conv_rate_prev_step: number | null;
  drop_off_rate_prev_step: number | null;
  drop_off_count_prev_step: number;
  conv_rate_from_first_step: number;
  drop_off_rate_from_first_step: number;
  name: string;
}

export interface IBar {
  /**
   * Width of the bar
   */
  width?: number;

  /**
   * Bar data
   */
  data: IMultiBarData[];

  /**
   * Max visitors value of the bars
   */
  max?: number;

  /**
   * is last bar
   */
  isLast?: boolean;

  /**
   * Type of the bar
   */
  type?: string;

  /**
   * No meta info
   */
  noMeta?: boolean;

  /**
   * No label
   */
  noLabel?: boolean;

  /**
   * meta info for drop off and avg time
   */
  initialBars: IBarData | null;
}

const MultiBar = ({
  width = 50,
  data,
  max = 100,
  isLast = false,
  type,
  noMeta = false,
  noLabel = false,
  initialBars
}: IBar) => {
  const theme = useGlobalMantineTheme();

  const barsHeight = data.map((bar) => (bar.visitors / max) * 100);
  const prevBarsHeight = data.map((bar) => (bar.prev_count / max) * 100);

  const barsLabel = data.map((bar) =>
    bar.visitors.toLocaleString("en-US", {
      notation: "compact",
      compactDisplay: "short"
    })
  );

  return (
    <Box
      pos={"relative"}
      h={"100%"}
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        paddingTop: "80px",
        gap: "10px",

        "&:hover .hover-label": {
          opacity: 1
        }
      }}>
      {/* Steps Labels */}
      {!noLabel && (
        <>
          <Box
            sx={{
              position: "absolute",
              top: "0",
              left: `-10px`,
              width: `calc(100% + 15px)`,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "row",
              gap: "6px",
              fontSize: "13px",
              height: "100%",
              fontWeight: 500,
              whiteSpace: "nowrap",

              "&:before": {
                content: "''",
                position: "absolute",
                top: "0",
                left: "10px",
                width: "1px",
                height: "100%",
                backgroundColor: theme.colorScheme === "dark" ? "#2c2e33" : "#e0e0e0e5",
                zIndex: -1
              }
            }}>
            <Box
              sx={{
                background: theme.colorScheme === "dark" ? "#2c2e33" : "#f1f3f5",
                color: theme.colorScheme === "dark" ? "#fff" : "#595c5f",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 600,
                flexShrink: 0
              }}>
              {data[0].level}
            </Box>

            {ellipsisContent(data[0].name, 22)}
          </Box>
        </>
      )}

      {/* Hover Label */}
      <Box
        className={"hover-label"}
        sx={{
          opacity: 0,
          position: "absolute",
          top: "0",
          left: `50%`,
          transform: `translateX(-50%)`,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "row",
          gap: "6px",
          fontSize: "12px",
          fontWeight: 500,
          whiteSpace: "nowrap",
          backgroundColor: "#495057",
          color: "white",
          textAlign: "center",
          padding: "2px 8px",
          borderRadius: "6px",
          transition: "opacity 0.1s ease-in-out"
        }}>
        {data[0].name}
      </Box>

      {
        // Bars
        data.map((bar, index) => (
          <Tooltip.Floating
            label={<TooltipLabel data={bar} />}
            withinPortal
            styles={{
              tooltip: {
                boxShadow: theme.shadows.xl
              }
            }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                width: `${width}px`,
                height: `${
                  prevBarsHeight[index] > barsHeight[index]
                    ? prevBarsHeight[index]
                    : barsHeight[index]
                }%`,
                backgroundColor: theme.colorScheme === "dark" ? "#2c2e33" : "#e9ecef",
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
                position: "relative"
              }}>
              <Box
                sx={{
                  height: `${barsHeight[index]}%`,
                  borderTopLeftRadius: "6px",
                  borderTopRightRadius: "6px",
                  width: `100%`
                }}
                bg={bar.color}
                pos="relative">
                <Label label={barsLabel[index]} position={"top"} color={bar.color} />
              </Box>
            </Box>
          </Tooltip.Floating>
        ))
      }

      {/* META */}
      {!noMeta && (
        <>
          {!isLast && (
            <Box
              sx={{
                paddingTop: "80px",
                position: "absolute",
                bottom: "0",
                left: `calc(${width}px * ${data.length} + (${data.length * 10}px))`,
                width: `85px`,
                height: "100%",
                minHeight: "80px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "16px"
                // padding: "8px 0"
              }}>
              <div>
                <Text
                  fw={600}
                  fz={11}
                  sx={{

                    span: {
                      color: "#c74554"
                    },
                    whiteSpace: "normal",
                    i: {
                      fontSize: "13px",
                      fontStyle: "normal"
                    }
                  }}>
                  <span>↓</span> <i>{initialBars?.drop_off_rate_prev_step || 0}%</i>{" "}
                  <span>
                    {" "}
                    <FormattedMessage
                      id={"insights.charts.label.dropOff"}
                      defaultMessage={"Drop Off"}
                    />
                  </span>
                </Text>
              </div>

              <div>
                {initialBars?.avg_time_in_s != 0 && (
                  <Text
                    fw={600}
                    fz={11}
                    sx={{
                      color: "#a173f3"
                    }}>
                    ⏱ {convertSecondsToReadableFormat(initialBars?.avg_time_in_s || 0)}
                  </Text>
                )}
              </div>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const TooltipLabel = ({ data }: { data: IMultiBarData }) => {
  const theme = useGlobalMantineTheme();
  const dataIndex = data.level - 1;
  const intl = useIntl();

  const header = `<div style="
                              color: ${
                                theme.colorScheme === "dark" ? theme.colors.dark[0] : "#000d21"
                              };
                              font-size: 14px;
                              font-weight: 600;
                              font-family: 'Inter', sans-serif;
                              background: ${
                                theme.colorScheme === "dark"
                                  ? theme.colors.dark[6]
                                  : "rgba(240, 240, 240, 0.4)"
                              };
                              border-bottom: 1px solid rgba(204, 204, 212, 0.31);
                              border-color: ${
                                theme.colorScheme === "dark"
                                  ? theme.colors.dark[6]
                                  : "rgba(204, 204, 212, 0.31)"
                              };
                              padding: 0.75rem 1rem;
                              text-transform: capitalize;
                            ">${data?.breakdown_prop || "Unknown"}</div>`;

  const values = [
    {
      name: intl.formatMessage({
        id: "insights.charts.tooltip.completedStep",
        defaultMessage: "Completed Step"
      }),
      value: numberToCommas(data.visitors),
      color: theme.colors.green[7]
    }
  ];

  if (data.conv_rate_prev_step !== null) {
    values.push({
      name: intl.formatMessage({
        id: "insights.charts.tooltip.completedStepRatio",
        defaultMessage: "Completed Step Ratio"
      }),
      value: data.conv_rate_prev_step + "%",
      color: theme.colors.green[7]
    });
  } else if (dataIndex === 0 && data.conv_rate_prev_step === null) {
    values.push({
      name: intl.formatMessage({
        id: "insights.charts.tooltip.completedStepRatio",
        defaultMessage: "Completed Step Ratio"
      }),
      value: "100%",
      color: theme.colors.green[7]
    });
  }

  if (data.drop_off_count_prev_step && data.drop_off_count_prev_step > 0) {
    values.push({
      name: intl.formatMessage({
        id: "insights.charts.tooltip.dropOffFromPreviousStep",
        defaultMessage: "Drop Off (from previous step)"
      }),
      value: numberToCommas(data.drop_off_count_prev_step),
      color: theme.colors.red[7]
    });
  }

  if (data.drop_off_rate_prev_step !== null) {
    values.push({
      name: intl.formatMessage({
        id: "insights.charts.tooltip.dropOffRatioFromPreviousStep",
        defaultMessage: "Drop Off Ratio (from previous step)"
      }),
      value: data.drop_off_rate_prev_step + "%",
      color: theme.colors.red[7]
    });
  }

  if (data.drop_off_rate_from_first_step && data.drop_off_rate_from_first_step > 0) {
    values.push({
      name: intl.formatMessage({
        id: "insights.charts.tooltip.dropOffRatioFromFirstStep",
        defaultMessage: "Drop Off Ratio (from first step)"
      }),
      value: data.drop_off_rate_from_first_step + "%",
      color: theme.colors.red[7]
    });
  }

  if (data.avg_time_in_s) {
    values.push({
      name: intl.formatMessage({
        id: "insights.charts.tooltip.avgTime",
        defaultMessage: "Avg. Time in Step"
      }),
      value: convertSecondsToReadableFormat(data.avg_time_in_s),
      color: theme.colors.blue[6]
    });
  }

  const html = `
               <div style='
                margin: -10px;
                background-color: ${theme.colorScheme === "dark" ? theme.colors.dark[5] : "#FFF"};
               '>
                  ${header}
                  <div style='
                    display: flex;
                    flex-direction: column;
                    padding: 0.5rem 0;
                  '>
                    ${values
                      .map(
                        (item) =>
                          `
                        <div style='
                          padding: 0.2rem 1rem;
                          display: flex;
                          line-height: 1.25rem;
                          font-size: 0.9325rem;
                          gap: 16px;
                        '>
                          <div style="
                                display: flex;
                                flex-grow: 1;
                                font-family: 'Inter', sans-serif;
                                color: ${item.color}
                              "
                          >
                              ${item.name}
                          </div>
                          <div style="
                            font-weight: 700;
                            font-family: 'Inter', sans-serif;
                            color: ${
                              theme.colorScheme === "dark" ? theme.colors.dark[0] : "#000d21"
                            }
                           "
                           >
                              ${item.value}
                          </div>
                        </div>
                        `
                      )
                      .join(" ")}
                  </div>
                </div>
              `;

  return (
    <Box
      // sx={{
      //   boxShadow: theme.shadows.lg
      // }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default MultiBar;
