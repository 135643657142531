import { stringify } from 'query-string';
import { UsersCRMViewType } from 'types/types.d';
import axios from './JWTService';

export class CompaniesService {
  search = (ws_id: any, payload: UsersCRMViewType): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/companies/${ws_id}`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  fields = (ws_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/companies/${ws_id}/fields`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  attributes = (ws_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/companies/${ws_id}/attributes`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  countWithPeriods = (ws_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/companies/${ws_id}/count`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  detailsById = (ws_id: string, company_id: string | undefined): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/companies/${ws_id}/${company_id}/details`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  attributesById = (ws_id: string, company_id: string | undefined): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/companies/${ws_id}/${company_id}/attributes`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  usersById = (ws_id: string, company_id: string | undefined): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/companies/${ws_id}/${company_id}/users`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  onboardingById = (ws_id: string, company_id: string | undefined): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/companies/${ws_id}/${company_id}/onboarding`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }
  companyHistogram = (ws_id: string, query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/companies/${ws_id}/histogram?${stringify(query)}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  companiesListExport = (
    ws_id: any,
    payload: { fields: Array<string>; q: string }
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/companies/${ws_id}/export`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  timeline = (ws_id: string, company_id: string | undefined, startDate: string, endDate: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/companies/${ws_id}/${company_id}/birdview?start_date=${startDate}&end_date=${endDate}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }
}
