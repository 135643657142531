import React from "react";
import {
  PasswordStrengthContainer,
  PasswordStrengthSpan,
} from "./PasswordStrength.style";

export interface PasswordStrengthProps {
  /**
   * Password against which strength to check.
   */
  password: string;
}

/**
 * Primary UI component for user interaction
 */
export const PasswordStrength: React.FC<PasswordStrengthProps> = ({
  password = "",
  ...props
}) => {
  /**
   * Is the field required. If so, * will be added.
   */
  let strength: "default" | "weak" | "moderate" | "strong" | "very-strong";
  strength = "default";

  if (password.length > 0 && password.length < 5) {
    strength = "weak";
  } else if (password.length > 4 && password.length < 8) {
    strength = "moderate";
  } else if (password.length >= 8) {
    // regex to check the password type
    let strongPassword = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );
    // let mediumPassword = new RegExp(
    //   "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
    // );
    if (strongPassword.test(password) || password.length > 16) {
      strength = "very-strong";
    } else {
      strength = "strong";
    }
  }

  function getStrengthClassName(): string {
    return `um-password-strength--${strength}`;
  }
  return (
    <>
      <PasswordStrengthContainer>
        <PasswordStrengthSpan className={strength}></PasswordStrengthSpan>
        <PasswordStrengthSpan className={strength}></PasswordStrengthSpan>
        <PasswordStrengthSpan className={strength}></PasswordStrengthSpan>
        <PasswordStrengthSpan className={strength}></PasswordStrengthSpan>
      </PasswordStrengthContainer>
    </>
  );
};
