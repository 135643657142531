import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Group,
  HoverCard,
  Paper,
  Text,
  useMantineColorScheme
} from "@mantine/core";
import { useCallback, useMemo } from "react";
import { getDeviceIcon } from "../../../../../lib/utils/IconsUtility";
import { _getRowValue } from "../../../../../lib/utils/Virtualtable";
import LazyFlag from "../../../Common/LazyFlag/LazyFlag";
import { renderSourceIcon } from "../../../Common/VirtualDataTable/components/Source";

interface IInsightsCardProps {
  data: {
    breakdown: breakdownType;
    funnelType: "visitor" | "user" | "company";
    bestPerforming: PerformingValue;
    worstPerforming: PerformingValue;
  };
  onViewBreakdown?: () => void;
  blur?: boolean;
  hideDetailedViewButton?: boolean;
  onLockClick?: () => void;
  sx?: any;
}

export type breakdownType = "sf" | "chf" | "cf" | "df";

export interface PerformingValue {
  key: string;
  value: string | number;
}

export default function InsightsCard({
  data,
  onViewBreakdown = () => {},
  blur,
  hideDetailedViewButton = false,
  onLockClick = () => {},
  sx = {}
}: IInsightsCardProps) {
  const { colorScheme } = useMantineColorScheme();
  const theme = useGlobalMantineTheme();

  const isBestPerforming = useMemo(() => {
    // Making both values positive
    const bestPerforming = (data.bestPerforming.value as unknown as number) || 0;
    const worstPerforming = (data.worstPerforming.value as unknown as number) || 0;

    // If the best performing value is greater than the worst performing value, then it is the best performing
    if (bestPerforming >= worstPerforming) {
      return true;
    }
    // If the worst performing value is greater than the best performing value, then it is the worst performing
    return false;
  }, [data.bestPerforming.value, data.worstPerforming.value]);

  const resolvedPerformingValue: PerformingValue = useMemo(() => {
    if (blur) {
      return {
        key: "Locked",
        value: "Locked"
      };
    }

    if (isBestPerforming) {
      return {
        key: data.bestPerforming.key,
        value: `${Math.abs(data.bestPerforming.value as unknown as number)}% more likely`
      };
    }
    // If the worst performing value is greater than the best performing value, then it is the worst performing
    return {
      key: data.worstPerforming.key,
      value: `${data.worstPerforming.value as unknown as number}% less likely`
    };
  }, [isBestPerforming, data.bestPerforming.value, data.worstPerforming.value]);

  const resolvedFunnelTypeName = useMemo(() => {
    switch (data.funnelType) {
      case "visitor":
        return "Visitors";
      case "user":
        return "Users";
      case "company":
        return "Companies";
    }
  }, [data.funnelType]);

  const resolvedBreakdownName = useMemo(() => {
    switch (data.breakdown) {
      case "sf":
        return "Sources";
      case "chf":
        return "Channels";
      case "cf":
        return "Countries";
      case "df":
        return "Devices";
    }
  }, [data.breakdown]);

  if (
    resolvedPerformingValue.key === "Unknown" ||
    (data.bestPerforming.value === 0 && data.worstPerforming.value === 0)
  ) {
    return <></>;
  }

  return (
    <Box pos={"relative"}>
      <Box
        pos={"absolute"}
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
          display: blur ? "block" : "none"
        }}>
        <HoverCard width={280} shadow="md" withArrow withinPortal>
          <HoverCard.Target>
            <ActionIcon
              variant="filled"
              color={theme.colors[theme.primaryColor][colorScheme === "dark" ? 5 : 7]}>
              <FontAwesomeIcon icon={regular("unlock")} />
            </ActionIcon>
          </HoverCard.Target>
          <HoverCard.Dropdown p="sm">
            <Text size="sm">
              Upgrade to{" "}
              <Text span fw={600}>
                Pro
              </Text>{" "}
              or{" "}
              <Text span fw={600}>
                Premium
              </Text>{" "}
              plan to unlock these insights.{" "}
              <Anchor fz="sm" fw={600} onClick={onLockClick}>
                Upgrade now.
              </Anchor>
            </Text>
          </HoverCard.Dropdown>
        </HoverCard>
      </Box>
      <Paper
        withBorder
        radius="md"
        p="sm"
        style={{
          filter: blur ? "blur(3px)" : "none",
          userSelect: "none",
          ...sx
        }}>
        <Group>
          {/*<Center>*/}
          {/*<ThemeIcon*/}
          {/*  variant="default"*/}
          {/*  size={38}*/}
          {/*  radius="md"*/}
          {/*  sx={{*/}
          {/*    color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[6],*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {resolvedIcon}*/}
          {/*</ThemeIcon>*/}
          {/*</Center>*/}
          <div>
            <InsightsText
              name={resolvedFunnelTypeName}
              breakdown={data.breakdown}
              resolvedPerformingValue={resolvedPerformingValue}
              bestPerforming={data.bestPerforming}
              worstPerforming={data.worstPerforming}
              isBestPerforming={isBestPerforming}
            />
            {!hideDetailedViewButton &&
              resolvedPerformingValue.key !== "Unknown" &&
              data.bestPerforming.value !== 0 &&
              data.worstPerforming.value !== 0 && (
                <Text size="xs" fw={500} mt={4}>
                  <Button
                    mt={4}
                    // ml={-8}
                    p={0}
                    h={"auto"}
                    size="xs"
                    variant="subtle"
                    color="gray.6"
                    // sx={{
                    //   color: theme.fn.variant({ variant: "subtle", color: "gray.7" }).color,
                    //   background: theme.fn.variant({ variant: "subtle", color: "gray.7" }).background,
                    //   ":hover": {
                    //     textDecoration: "none",
                    //     background: theme.fn.variant({ variant: "subtle", color: "gray.7" }).hover,
                    //   },
                    // }}
                    onClick={onViewBreakdown}>
                    <FontAwesomeIcon icon={solid("arrow-right")} className="mr-2" />
                    View breakdown by {resolvedBreakdownName}
                  </Button>
                </Text>
              )}
          </div>
        </Group>
      </Paper>
    </Box>
  );
}

const InsightsText = ({
  name,
  breakdown,
  resolvedPerformingValue,
  bestPerforming,
  worstPerforming,
  isBestPerforming
}: {
  name: string;
  breakdown: string;
  resolvedPerformingValue: PerformingValue;
  bestPerforming: PerformingValue;
  worstPerforming: PerformingValue;
  isBestPerforming: boolean;
}) => {
  const getIcon = useCallback(
    (performingValue: PerformingValue) => {
      if (performingValue.key === "Others" || !performingValue.key) {
        return "";
      }

      switch (breakdown) {
        case "sf":
        case "chf":
          return (
            <Box
              component="span"
              ml={4}
              mr={6}
              // style={{ width: 16, height: 16, display: "inline-flex", alignItems: "center" }}
              sx={{
                img: {
                  paddingTop: 2
                }
              }}>
              {renderSourceIcon(performingValue.key)}
            </Box>
          );
        case "cf":
          return (
            <Box
              component="span"
              ml={4}
              mt={6}
              mr={4}
              sx={{
                img: {
                  paddingTop: 4
                }
              }}>
              <LazyFlag code={performingValue.key} width={16} height={16} />{" "}
            </Box>
          );
        case "df":
          return (
            <Box component="span" mr={6} ml={4}>
              {getDeviceIcon(performingValue.key as string)}
            </Box>
          );

        default:
          return "";
      }
    },
    [breakdown, bestPerforming, worstPerforming]
  );

  if (
    resolvedPerformingValue.key === "Unknown" ||
    (bestPerforming.value === 0 && worstPerforming.value === 0)
  ) {
    return (
      <Text size="sm" fw={500}>
        There are no meaningful insights available now.
      </Text>
    );
  }

  if (
    bestPerforming.key === worstPerforming.key &&
    bestPerforming.value === worstPerforming.value
  ) {
    return (
      <Text fz="13px" fw={"normal"}>
        {name} from{" "}
        <Box component="span" h={16} mt="sm">
          <Text span fw={500}>
            {getIcon(bestPerforming)}
            {breakdown === "cf"
              ? _getRowValue("location_country", bestPerforming.key)
              : bestPerforming.key}
          </Text>{" "}
        </Box>
        are{" "}
        <Text
          span
          fw={500}
          c={(bestPerforming.value as unknown as number) > 0 ? "green.7" : "gray.8"}>
          {bestPerforming.value}% more likely
        </Text>{" "}
        to complete the funnel.{" "}
      </Text>
    );
  }

  return (
    <Text fs="13px">
      {name} from{" "}
      <Box component="span" h={16} mt="sm">
        <Text span fw={600}>
          {getIcon(bestPerforming)}
          {breakdown === "cf"
            ? _getRowValue("location_country", bestPerforming.key)
            : bestPerforming.key}
        </Text>{" "}
      </Box>
      are{" "}
      <Text
        fw={600}
        span
        c={(bestPerforming.value as unknown as number) > 0 ? "green.7" : "gray.8"}>
        {bestPerforming.value}% more likely
      </Text>
      , while those from{" "}
      <Text span fw={600}>
        {getIcon(worstPerforming)}
        {breakdown === "cf"
          ? _getRowValue("location_country", worstPerforming.key)
          : worstPerforming.key}
      </Text>{" "}
      are{" "}
      <Text span fw={600} c={(worstPerforming.value as unknown as number) > 0 ? "red.6" : "gray.8"}>
        {worstPerforming.value}% less likely
      </Text>{" "}
      to complete the funnel.{" "}
    </Text>
  );
};
