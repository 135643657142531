/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { numberToCommas, numberToUnit } from "@/lib/utils/StringUtility";
import React from "react";
import { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import { SummaryComparisonCard } from "../SummaryComparisonCard";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import ContentLoader from "react-content-loader";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
};

const PageviewsComparisonCard = ({
  activeCard,
  setActiveCard,
  comparisonTopBarCardValues,
  loading,
  query
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const getComparisonPercentageDiff = () => {
    let value = comparisonTopBarCardValues.pageviews;
    return value < 100000 ? numberToCommas(value) : numberToUnit(value);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="pageviews-card">
      {loading === "loaded" ? (
        <SummaryComparisonCard
          card="pageviews"
          activeCard={activeCard}
          onClick={() => setActiveCard("pageviews")}
          uniquekey={"Pageviews"}
          loading={loading}
          comparisonValue={getComparisonPercentageDiff()}
          comparisonTooltip={`${numberToCommas(comparisonTopBarCardValues.pageviews)} pageviews`}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const PageviewsComparisonCardMemoized = React.memo(PageviewsComparisonCard);
export { PageviewsComparisonCardMemoized as PageviewsComparisonCard };
