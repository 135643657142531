import { Button } from "@mantine/core";
import useInsightHook from "@/hooks/useInsightHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { ConditionItem } from "types/types.d";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { useNavigate } from "react-router-dom";
import { EventsSetup } from "../Components/Setup/EventsSetup";
import { InsightsService } from "@/lib/services/InsightsService";
import { TOAST_MESSAGES } from "@/lib/utils/Constants";
import { ThirdPartyTracking } from "@/lib/utils/ThirdPartyTracking";
import { NameSetup } from "../Components/Setup/NameSetup";
import InsightContext from "@/lib/contexts/InsightContext";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import useConditionsValidityHook from "@/hooks/useConditionsValidityHook";
import useInsightsListHook from "@/hooks/useInsightsListHook";
interface IActiveUsersSetupModal {
  isModalOpen: boolean;
  setIsModalOpen: (e: boolean) => void;
  insightId?: string;
}

export const ActiveUsersSetupModal = ({
  isModalOpen,
  setIsModalOpen,
  insightId
}: IActiveUsersSetupModal) => {
  // States that are used.
  // We are passing attributes and events to the child components.
  const { insightEvents, insightAttributes } = useInsightHook();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const insightType = "active_users";
  const [isLoading, setIsLoading] = useState("idle");
  const [templateId, setTemplateId] = useState("");
  const [name, setName] = useState("");
  const [conditions, setConditions] = useState<Array<ConditionItem>>([]);
  const [isStoreLoader, setIsStoreLoader] = useState("idle");
  const navigate = useNavigate();
  const { stepsValidation } = useConditionsValidityHook();
  const { insightsList } = useInsightsListHook("adoption_engagement");

  const insightService = new InsightsService();
  const createInsight = async () => {
    setIsStoreLoader("loading");

    await insightService
      .create(activeWorkspace.id, {
        name: name,
        conditions: conditions,
        type: insightType
      })
      .then((res) => {
        if (res.data) {
          toast.success(TOAST_MESSAGES.INSIGHT_CREATED);
          const TPService = new ThirdPartyTracking();
          TPService.track("active_users_insight_created");
          setIsModalOpen(false);
          // as the user is already on /env/${activeWorkspace.identifier}/insights/dashboard/adoption_engagement
          navigate({ search: `insightId=${res.data.id}` });
        }
      })
      .catch((err) => {});
    setIsStoreLoader("loaded");
  };

  const updateInsight = async () => {
    setIsStoreLoader("loading");

    await insightService
      .update(activeWorkspace.id, templateId, {
        name: name,
        conditions: conditions
      })
      .then((res) => {
        toast.success(TOAST_MESSAGES.INSIGHT_UPDATED);
        setIsModalOpen(false);
      })
      .catch((err) => {});
    setIsStoreLoader("loaded");
  };

  const getInsight = async () => {
    setIsLoading("loading");
    await insightService
      .get(activeWorkspace.id, insightId)
      .then((res) => {
        if (res.data) {
          setTemplateId(res.data.id);
          setName(res.data.name);
          setConditions(res.data.conditions);
        }
      })
      .catch((err) => {});
    setIsLoading("loaded");
  };

  useEffect(() => {
    if (insightId) {
      getInsight();
    } else {
      setIsLoading("loaded");
    }
  }, [insightId]);

  const handleSubmission = async () => {
    if (name.length === 0) {
      toast.error("Please enter a name for your insight.");
      return;
    }
    // Check if the insight name already exists
    if (
      insightsList &&
      insightsList.find(
        (insight: any) =>
          insight.name.toLowerCase() === name.trim().toLowerCase() &&
          insight.id !== insightId &&
          insight.type === "active_users"
      )
    ) {
      toast.error("An insight with the same name already exists. Please enter a different name.");
      return;
    }

    const activeUsersConditionsCheck = stepsValidation(conditions, 1, 10);
    if (!activeUsersConditionsCheck) {
      return;
    }
    if (insightId) {
      await updateInsight();
    } else {
      await createInsight();
    }
  };

  if (isLoading !== "loaded") {
    return (
      <>
        <LoadingComponent></LoadingComponent>
      </>
    );
  }

  return (
    <SidePanel
      opened={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      loading={true}
      title={"Active Users"}>
      <NameSetup name={name} setName={setName} />
      <InsightContext.Provider
        value={{
          insightEvents,
          insightAttributes
        }}>
        <EventsSetup
          title={"Select events that make a user active"}
          description={
            "You can select more than one event if you have multiple apps. e.g post published, task created."
          }
          conditions={conditions}
          setConditions={setConditions}
          conditionType="or"
        />
      </InsightContext.Provider>
      <div className="um-insights-setup__actions pb-20">
        <Button
          onClick={() => {
            setIsModalOpen(false);
          }}
          color="red">
          Cancel
        </Button>
        {templateId ? (
          <>
            <Button
              onClick={() => {
                handleSubmission();
              }}
              loading={isStoreLoader === "loading"}
              disabled={conditions.length === 0 || isStoreLoader === "loading"}>
              Update Insight
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                handleSubmission();
              }}
              loading={isStoreLoader === "loading"}
              disabled={conditions.length === 0 || isStoreLoader === "loading"}>
              Save Insight
            </Button>
          </>
        )}
      </div>
    </SidePanel>
  );
};
