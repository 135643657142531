import { WorkspaceHiddenMenuService } from "@/lib/services/WorkspaceHiddenMenuService";
import {
  ANALYZE_MENU_ITEMS,
  CONTACTS_HUB_MENU_ITEMS,
  LEFT_MENU_ITEMS,
  LEFT_MENU_TYPES,
  PRODUCT_INSIGHTS_MENU_ITEMS
} from "@/lib/utils/Constants";
import create from "zustand";

export interface IMenuItem {
  name: string;
  isVisible: boolean;
  subMenu?: IMenuItem[];
  menu_id?: string;
}

const updateMenuVisibilityAndAddMenuId = (menuItems: IMenuItem[], results: any[]): IMenuItem[] =>
  menuItems.map((menuItem) => {
    const updatedMenuItem = { ...menuItem };

    // check for matching existing menu item in the hidden menu items list
    // If match found inVisible is set false else true.
    const matchingResult = results.find((result) => result.menu_item === updatedMenuItem.name);
    updatedMenuItem.isVisible = !matchingResult;
    if (matchingResult) {
      updatedMenuItem.menu_id = matchingResult.id;
    }

    if (updatedMenuItem.subMenu) {
      updatedMenuItem.subMenu = updateMenuVisibilityAndAddMenuId(updatedMenuItem.subMenu, results);
    }

    return updatedMenuItem;
  });

const getUpdateListOfHiddenMenuItems = (menuItems: IMenuItem[]): string[] =>
  menuItems.reduce((acc: string[], item: any) => {
    if (!item.isVisible) {
      acc.push(item.name);
    }
    if (item.subMenu) {
      acc.push(...getUpdateListOfHiddenMenuItems(item.subMenu));
    }
    return acc;
  }, []);

export const useCustomizeMenuStore = create((set) => ({
  menuItems: [],
  hiddenMenuItemsList: [],
  loading: true,
  internalAside: true,
  setInternalAside: (internalAside: boolean) => set({ internalAside }),

  fetchMenuItems: async (workspaceId: string) => {
    if (!workspaceId) {
      return;
    }

    try {
      const service = new WorkspaceHiddenMenuService();
      const response = await service.getHiddenMenuItems(workspaceId);

      const hiddenMenuItemsList = response.data.map((item: any) => item.menu_item);
      const updatedMenuItemsWithMenuId = updateMenuVisibilityAndAddMenuId(
        LEFT_MENU_ITEMS,
        response.data
      );
      const menuItems = updatedMenuItemsWithMenuId || LEFT_MENU_ITEMS;

      set({ menuItems, hiddenMenuItemsList, loading: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      set({ menuItems: [], hiddenMenuItemsList: [], loading: false });
    }
  },

  updateMenuItems: (index: number, subIndex: number | null, newValue: any) =>
    set((state: { menuItems: any }) => {
      const updatedMenuItems = [...state.menuItems];
      if (subIndex !== null) {
        updatedMenuItems[index].subMenu[subIndex] = {
          ...updatedMenuItems[index].subMenu[subIndex],
          ...newValue
        };

        let hiddenMenuItemsList = getUpdateListOfHiddenMenuItems(updatedMenuItems);

        if (updatedMenuItems[index]["name"] === LEFT_MENU_TYPES.ProductInsights) {
          newValue["isVisible"] = PRODUCT_INSIGHTS_MENU_ITEMS.every((item) =>
            hiddenMenuItemsList.includes(item)
          )
            ? false
            : true;
          updatedMenuItems[index] = {
            ...updatedMenuItems[index],
            ...newValue
          };
        }

        if (updatedMenuItems[index]["name"] === LEFT_MENU_TYPES.ContactsHub) {
          newValue["isVisible"] = CONTACTS_HUB_MENU_ITEMS.every((item) =>
            hiddenMenuItemsList.includes(item)
          )
            ? false
            : true;
          updatedMenuItems[index] = {
            ...updatedMenuItems[index],
            ...newValue
          };
        }

        if (updatedMenuItems[index]["name"] === LEFT_MENU_TYPES.Analyze) {
          newValue["isVisible"] = ANALYZE_MENU_ITEMS.every((item) =>
            hiddenMenuItemsList.includes(item)
          )
            ? false
            : true;
          updatedMenuItems[index] = {
            ...updatedMenuItems[index],
            ...newValue
          };
        }
        hiddenMenuItemsList = getUpdateListOfHiddenMenuItems(updatedMenuItems);

        return { menuItems: updatedMenuItems, hiddenMenuItemsList };
      } else {
        updatedMenuItems[index] = {
          ...updatedMenuItems[index],
          ...newValue
        };

        // If submenu exists, update the isVisible flag of all objects in the submenu
        if (updatedMenuItems[index].subMenu) {
          updatedMenuItems[index].subMenu = updatedMenuItems[index].subMenu.map(
            (subMenuItem: any) => ({
              ...subMenuItem,
              ...newValue
            })
          );
        }
        const hiddenMenuItemsList = getUpdateListOfHiddenMenuItems(updatedMenuItems);
        return { menuItems: updatedMenuItems, hiddenMenuItemsList };
      }
    })
}));
