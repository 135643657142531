import { Badge, Box, Center, Flex, Loader, Paper, SimpleGrid, Text, Title } from "@mantine/core";
import integrationsShape from "@assets/images/icons/integrations/integrations-shape.svg";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceIntegrationService } from "@/lib/services/WorkspaceIntegrationService";
import { LIST_OF_INTEGRATIONS } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router";
import { IntegrationType } from "types/types.d";
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
const Integrations = (props: any) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [integrations, setIntegrations] = useState<Array<IntegrationType>>(LIST_OF_INTEGRATIONS);
  const [isLoading, setIsLoading] = useState("idle");
  const navigate = useNavigate();

  const getConnectedIntegrationsCount = async () => {
    const wsIntegrationService = new WorkspaceIntegrationService();
    setIsLoading("loading");
    await wsIntegrationService
      .countConnected(activeWorkspace.id)
      .then((res) => {
        if (res.data) {
          console.log(integrations, res.data);
          integrations.forEach((integrationItem: IntegrationType) => {
            console.log(integrationItem, res.data.hasOwnProperty(integrationItem.name));
            if (res.data.hasOwnProperty(integrationItem.name)) {
              integrationItem.is_connected = true;
            }
          });
          setIntegrations(integrations);
        }
      })
      .catch((err) => {});
    setIsLoading("loaded");
  };
  useEffect(() => {
    document.title = "Integrations | Usermaven";
    getConnectedIntegrationsCount();
  }, []);

  return (
    <>
      {isLoading === "loaded" ? (
        <>
          <SimpleGrid
            cols={{ base: 1, sm: 1, md: 2 }}
            spacing={{ base: "sm", sm: 'sm', md: 'sm' }}
            w="100%"
          >
            {integrations.map((integrationItem) => (
              <Paper
                withBorder
                shadow="sm"
                p="md"
                key={integrationItem.name}
                component="div"
                style={{
                  cursor: !integrationItem.is_live ? "not-allowed" : "pointer",
                  opacity: !integrationItem.is_live ? 0.5 : 1
                }}
                onClick={() => {
                  if (integrationItem.is_live) {
                    navigate(
                      `/env/${activeWorkspace.identifier}/settings/integrations/${integrationItem.name}`
                    );
                  }
                }}>
                <Flex mb="sm">
                  <Flex flex={1}>
                    <Box h={48} w={48}>
                      <img src={integrationItem.image} alt="" />
                    </Box>
                  </Flex>
                  <Flex>
                    {integrationItem.is_connected && (
                      <Badge color="green.6" size="sm" radius="sm">
                        Connected
                      </Badge>
                    )}
                    {integrationItem.is_live === false && (
                      <Badge color="gray.6" size="sm" radius="sm">
                        Coming Soon
                      </Badge>
                    )}
                  </Flex>
                </Flex>
                <Flex py="sm" direction={"column"}>
                  <Title order={6} fw={600} mb="md">
                    {integrationItem.display_name}
                  </Title>
                  <Text>{integrationItem.description}</Text>
                </Flex>
              </Paper>
            ))}
          </SimpleGrid>
        </>
      ) : (
        <Center my="lg">
          <Loader size="xs"></Loader>
        </Center>
      )}
    </>
  );
};
export default Integrations;
