import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
import { GeneralWorkspaceDetails } from "./GeneralWorkspaceDetails";
// import { GeneralWorkspaceTrackingPixels } from "./GeneralWorkspaceTrackingPixels";
import { Box, Divider } from "@mantine/core";
import { useEffect } from "react";
import { GeneralAdditionalDomains } from "./GeneralAdditionalDomains";
import { GeneralAPIKeyAndServerToken } from "./GeneralAPIKeyAndServerToken";
import { GeneralHideDomains } from "./GeneralHideDomains";
import { GeneralWorkspaceDelete } from "./GeneralWorkspaceDelete";
import useWorkspaceUtilityHook from "../../../../../hooks/useWorkspaceUtilityHook";
import { WORKSPACE_MEMBER_ROLES } from "../../../../../lib/utils/Constants";
import { Header } from "@/ui/components/Common/Header/Header";

const General = (props: any) => {
  const { hasRolesWith } = useWorkspaceUtilityHook();

  useEffect(() => {
    document.title = "Workspace Details | Usermaven";
  }, []);

  return (
    <>
      <GeneralWorkspaceDetails></GeneralWorkspaceDetails>
      <Divider my="xl" />
      <GeneralAPIKeyAndServerToken />
      <Divider my="xl" />
      <GeneralAdditionalDomains />
      <Divider my="xl" />
      <GeneralHideDomains />
      <Divider my="xl" />
      {hasRolesWith([WORKSPACE_MEMBER_ROLES.OWNER]) && <GeneralWorkspaceDelete />}
    </>
  );
};

export default General;
