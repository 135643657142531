import { Flex, Text, Title, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import NoResultsFoundIcon from "@assets/images/icons/no-results-found-2.svg";
import NoResultsFoundDarkModeIcon from "@assets/images/icons/no-results-found-dark-mode.svg";
interface NoResultsProps {
  type?: string;
  text: string;
  heading: string;
  className?: string;
}

export const NoResults: React.FC<NoResultsProps> = ({
  type = "search",
  heading = "No Results",
  text = "No results available.",
  className = ""
}) => {
  const theme = useMantineColorScheme();
  return (
    <>
      <Flex
        direction="column"
        align={"center"}
        my="md"
        className={`flex flex-col justify-center mb-8 ${className}`}>
        <img
          src={theme.colorScheme === "dark" ? NoResultsFoundDarkModeIcon : NoResultsFoundIcon}
          alt=""
          className="mt-8 h-20 mb-4"
        />
        <Title order={6} fw={500} ta="center" my={"sm"}>
          {heading}
        </Title>
        <Text size="xs" ta="center">
          {text}
        </Text>
      </Flex>
    </>
  );
};
