import { BarChart } from "@/ui/pages/Analyze/components/FunnelBarChart";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Center,
  Flex,
  Grid,
  Loader,
  Paper,
  SegmentedControl,
  Text,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Params, useParams } from "react-router-dom";
import { DecodedValueMap, QueryParamConfig } from "serialize-query-params";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "../../../../../lib/services/WebAnalyticsService";
import { FUNNEL_TYPE_KEYS } from "../../../../../lib/utils/Constants";
import { roundFunnelRate } from "../../../../../lib/utils/FunnelUtility";
import { numberToCommas } from "../../../../../lib/utils/StringUtility";
import BreakdownDetailTable from "../FunnelDetailedView/BreakdownDetailTable";
import { IFunnelMeta, IFunnelStep } from "../FunnelDetailedView/FunnelDetails";
import StatsCard from "../../../../components/Common/StatsCard/StatsCard";
import StepsFunnel from "../StepsFunnel";

export interface IOverviewTabProps {
  id: string;
  selectedType: "visitor" | "user" | "company";
  initialSelectedFunnel: IFunnelStep[];
  initialSelectedMeta: IFunnelMeta | undefined;
  dateRange: DecodedValueMap<{
    start_date: QueryParamConfig<string | null | undefined, string | null | undefined>;
    end_date: QueryParamConfig<string | null | undefined, string | null | undefined>;
  }>;
  isOnboarding?: boolean;
}

const OverviewTab = ({
  id,
  selectedType,
  initialSelectedFunnel,
  initialSelectedMeta,
  dateRange,
  isOnboarding
}: IOverviewTabProps) => {
  /** Secret is available in case of the shareable funnels. */
  const { secret } = useParams<Params>();
  const { colorScheme } = useMantineColorScheme();
  // const [value, setValue] = useState<"default" | "vertical">("default");
  const [value, setValue] = useLocalStorage<"default" | "vertical">({
    key: FUNNEL_TYPE_KEYS,
    defaultValue: "default"
  });
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  // Default funnel states (overview)
  const [selectedFunnel, setSelectedFunnel] = useState<IFunnelStep[]>(initialSelectedFunnel);
  const [selectedMeta, setSelectedMeta] = useState<IFunnelMeta | undefined>(initialSelectedMeta);

  // Service instance for api requests
  const webAnalyticsService = new WebAnalyticsService();

  // Workspace context
  const { activeWorkspace } = useContext(AppLifecycleContext);

  useEffect(() => {
    fetchDefaultFunnel();

    return () => {
      setLoading(false);
    };
  }, [dateRange, colorScheme]);

  const fetchDefaultFunnel = async () => {
    setLoading(true);

    const response = await webAnalyticsService.funnel(
      activeWorkspace.id,
      id,
      {
        start_date: dateRange.start_date,
        end_date: dateRange.end_date,
        funnel_type: isOnboarding ? selectedType : ""
      },
      secret || null
    );

    if (response?.data?.funnel_steps) {
      setSelectedFunnel(response.data.funnel_steps);
      setSelectedMeta(response.data.meta);
    }

    setLoading(false);
  };

  /**
   * Resolve type for stats card
   */
  const resolvedType = useMemo(() => {
    if (!selectedType || selectedType === "visitor") {
      return intl.formatMessage({
        id: "insights.type.visitors",
        defaultMessage: "visitors"
      });
    } else if (selectedType === "user") {
      return intl.formatMessage({
        id: "insights.type.users",
        defaultMessage: "users"
      });
    }
    return intl.formatMessage({
      id: "insights.type.companies",
      defaultMessage: "companies"
    });
  }, [selectedType]);

  const columnsCounts = useMemo(() => {
    // We will check if the selectedFunnel.length is greater than 3 and the charate length of the
    // selectedMeta?.most_drop_off_step?.name is greater than 20 then we will return 3 columns with
    // this division: [3, 3, 6] else we will return [4, 4, 4]
    if (
      selectedFunnel.length >= 3 &&
      selectedMeta &&
      selectedMeta?.most_drop_off_step?.name.length > 20
    ) {
      return [3, 3, 6];
    }
    return [4, 4, 4];
  }, [selectedFunnel]);

  return (
    <>
      <Grid m={0} mb={10}>
        <Grid.Col
          span={{
            sm: 6,
            md: columnsCounts[0]
          }}>
          <StatsCard
            title={intl.formatMessage(
              {
                id: "insights.cards.totalCount",
                defaultMessage: "Total Visitors"
              },
              {
                type: resolvedType
              }
            )}
            description={intl.formatMessage(
              {
                id: "insights.cards.totalCount.subtitle",
                defaultMessage: "who have visited the funnel"
              },
              {
                type: resolvedType
              }
            )}
            value={numberToCommas(selectedMeta?.total_visitors || 0)}
            icon="eye"
            bg={"yellow.6"}
            iconColor={""}
          />
        </Grid.Col>
        <Grid.Col
          span={{
            sm: 6,
            md: columnsCounts[1]
          }}>
          <StatsCard
            // title="Conversions Rate"
            title={intl.formatMessage(
              {
                id: "insights.cards.conversionRate",
                defaultMessage: "Conversion Rate"
              },
              {
                type: resolvedType
              }
            )}
            // description={`of ${resolvedType} have completed all steps`}
            description={intl.formatMessage(
              {
                id: "insights.cards.conversionRate.subtitle",
                defaultMessage: "have completed all steps"
              },
              {
                type: resolvedType
              }
            )}
            value={
              <>
                {roundFunnelRate(selectedMeta?.conversion_rate || 0)}%{" "}
                <small>
                  ({selectedMeta?.total_converted} {resolvedType})
                </small>
              </>
            }
            icon="users"
            bg="blue.6"
            iconColor={""}
          />
        </Grid.Col>
        {selectedFunnel.length >= 3 && selectedMeta?.most_drop_off_step && (
          <Grid.Col
            span={{
              sm: 12,
              md: columnsCounts[2]
            }}>
            <StatsCard
              // title="Most Drop-off Step"
              title={intl.formatMessage({
                id: "insights.cards.mostDropOffStep",
                defaultMessage: "Most Drop-off Step"
              })}
              value={
                <Flex align={"center"} mt={-11}>
                  <Box
                    style={(theme) => ({
                      marginRight: "12px",
                      borderRadius: 4,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid",
                      fontWeight: 600,
                      // height: 24,
                      // width: 24,
                      padding: "4px 8px",
                      fontSize: 12,
                      color: colorScheme === "dark" ? theme.colors.dark[0] : "#595c5f",
                      backgroundColor: colorScheme === "dark" ? theme.colors.dark[4] : "#e8e9ec",
                      borderColor: colorScheme === "dark" ? theme.colors.dark[4] : "#e8e9ec",
                      fontFamily: "Inter, sans-serif",
                      whiteSpace: "nowrap"
                    })}>
                    <FormattedMessage
                      id={"insights.cards.current.dropOff.step"}
                      defaultMessage={"Step"}
                    />{" "}
                    {selectedMeta?.most_drop_off_step.level}
                  </Box>
                  <Text lineClamp={1}>{selectedMeta?.most_drop_off_step?.name || "-"}</Text>
                </Flex>
              }
              description={
                <>
                  <FormattedMessage
                    id="insights.cards.mostDropOffStep.subtitle"
                    defaultMessage="Highest drop-off rate of {value} at this step"
                    values={{
                      value: (
                        <Text span fw={600} fz="xs" c="red.7">
                          {roundFunnelRate(selectedMeta?.most_drop_off_step?.percentage || 0)}%
                        </Text>
                      )
                    }}
                  />
                </>
              }
              icon="arrow-down"
              bg="red.7"
              iconColor="text-red-500"
            />
          </Grid.Col>
        )}
      </Grid>

      <>
        {loading ? (
          <Flex mih={500} align={"center"} justify={"center"}>
            <Loader size="xs" />
          </Flex>
        ) : (
          <Paper mt="xl" withBorder pb={"lg"} mb="xl" pos="relative">
            <Box pos={"absolute"} right={"10px"} top={"-35px"}>
              <SegmentedControl
                value={value}
                // @ts-ignore
                onChange={(val: "default" | "vertical") => setValue(val)}
                data={[
                  {
                    value: "default",
                    label: (
                      <Center>
                        <FontAwesomeIcon
                          className="text-xl text-gray-900"
                          icon={regular("chart-simple-horizontal")}></FontAwesomeIcon>
                      </Center>
                    )
                  },
                  {
                    value: "vertical",
                    label: (
                      <Center>
                        <FontAwesomeIcon
                          className="text-xl text-gray-900"
                          icon={regular("chart-simple")}></FontAwesomeIcon>
                      </Center>
                    )
                  }
                ]}
              />
            </Box>
            {value === "vertical" ? (
              <BarChart bars={selectedFunnel as any} type={selectedType} />
            ) : (
              <StepsFunnel funnelSteps={selectedFunnel} type={selectedType} autoHeight />
            )}
          </Paper>
        )}

        <div className="mt-3">
          <BreakdownDetailTable
            steps={selectedFunnel}
            meta={selectedMeta}
            variant="single"
            loading={loading}
            selectedAttribute={"none"}
            showCompleteList={false}
          />
        </div>
      </>
    </>
  );
};

export default OverviewTab;
