// @ts-ignore
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import AdBlockerBypassImage from "@assets/images/icons/adblocker-bypass.webp";
import {
  Button,
  Container,
  Grid,
  Title,
  Image,
  List,
  Box,
  Text,
  Flex,
  Divider,
  Center
} from "@mantine/core";
export const AccessCustomDomainUpgrade = () => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const navigate = useNavigate();
  return (
    <Container size={"800"}>
      <Title order={3} my={"md"} fw={600} ta="center" py="sm">
        Bypass ad-blockers with pixel white-labeling
      </Title>
      <Flex align={"center"}>
        <Image src={AdBlockerBypassImage} alt="" w={300} fit="contain" />
        <Box flex={1} ml={"lg"}>
          <List fz="sm">
            <List.Item
              mb="sm"
              icon={
                <Box c="green.7">
                  <FontAwesomeIcon icon={regular("check")} />
                </Box>
              }>
              {" "}
              Get the true picture from your analytics by using your{" "}
              <Text span fw={500}>
                own custom domain
              </Text>{" "}
              in the tracking script to bypass ad-blockers.
            </List.Item>
            <List.Item
              mb="sm"
              icon={
                <Box c="green.7">
                  <FontAwesomeIcon icon={regular("check")} />
                </Box>
              }>
              {" "}
              According to the latest statistics, more than{" "}
              <Text span fw={600}>
                {" "}
                30% of internet users
              </Text>{" "}
              are using ad-blockers while surfing the web which means that almost one-third of the
              traffic is not tracked by most analytics tools.
            </List.Item>
            <List.Item
              icon={
                <Box c="green.7">
                  <FontAwesomeIcon icon={regular("check")} />
                </Box>
              }>
              {" "}
              Usermaven's pixel white-labeling feature helps you get{" "}
              <Text span fw={600}>
                {" "}
                true and accurate numbers
              </Text>{" "}
              for top content, referrals and more without breaching anyone's privacy.
            </List.Item>
          </List>
        </Box>
      </Flex>
      <Divider my={"lg"} />
      <Center>
        <Button
          onClick={() => {
            navigate(`/env/${activeWorkspace.identifier}/account/billing`);
          }}>
          Upgrade Plan
        </Button>
      </Center>
    </Container>
  );
};
