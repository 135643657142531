/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Modal, useMantineTheme, Text, Flex, Divider, CloseButton, Center } from "@mantine/core";
import {
  addDays,
  addMonths,
  differenceInDays,
  format,
  parseISO,
  startOfMonth,
  startOfWeek
} from "date-fns";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import {
  GenericComparisonTooltip,
  GenericTooltip,
  GenericTooltipBar,
  getDateFormatFromPeriod,
  getDateWithZonedTimeToUTC,
  xAxisLabelFormatter
} from "@/lib/utils/EChartsTooltipUtility";
import cloneDeep from "lodash.clonedeep";
import { useContext, useEffect, useState } from "react";
import { useWebFiltersStore } from "@/stores/useWebFiltersStore";
import { purple } from "@/style/Colors";
import { LoadingStateProps } from "types/types.d";
import { BarGraphWrapper } from "../../EChartsWrappers/BarGraphWrapper";
import { NoResults } from "../../NoResults/NoResults";
import { getRangeByDateAndInterval } from "../Filters/FiltersUtility";
import { getChartLineTransformedData } from "../WebAnalyticsUtilities";
import { ITimeSeriesDataMetric } from "../WebAnalyticsOverview";
import { Note } from "@/stores/useAnnotationNotesStore";
import { groupBy } from "lodash";
import { snakeCaseToSimpleString, truncateText } from "@/lib/utils/StringUtility";
import { formatToMidnightUTC } from "@/lib/utils/DateUtility";
import { NoteVisibilityEnum } from "@/ui/components/Common/Notes/NotesModel";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

type Props = {
  query: any;
  setQuery: any;
  activeCard: string;
  chartType: string;
  graphColor: string;
  graphAreaColor: string;
  comparisonGraphColor: string;
  timeSeriesGraphData: ITimeSeriesDataMetric;
  comparisonTimeSeriesGraphData: ITimeSeriesDataMetric;
  loading: LoadingStateProps;
  notes: Note[];
  dashboardUrl?: string;
};

const GenericLineAndBarGraphWrapper = ({
  query,
  setQuery,
  activeCard,
  chartType,
  graphColor,
  graphAreaColor,
  comparisonGraphColor,
  timeSeriesGraphData,
  comparisonTimeSeriesGraphData,
  loading,
  notes,
  dashboardUrl
}: Props) => {
  const [hasResults, setHasResults] = useState(false);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const theme = useGlobalMantineTheme();
  const [lineGraph, setLineGraph] = useState<any>({
    animationDuration: 500,
    color: [purple],
    title: {
      text: ""
    },
    notMerge: true,
    tooltip: {
      borderColor: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2],
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: theme.colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
      },
      trigger: "axis",
      axisPointer: {
        type: "line"
      },
      formatter: (params: any) => GenericTooltip(params, false)
    },
    legend: {
      show: false,
      data: []
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      top: "7%",
      left: "0%",
      right: "3%",
      bottom: "5%",
      containLabel: true
    },
    xAxis: [
      {
        type: "time",
        axisLine: {
          show: false
        },
        axisLabel: {
          formatter: "{MMM} {dd}",
          fontSize: 10,
          axisPointer: {
            show: false
          },
          margin: 14
        },
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        min: 0,
        max: function (value: any) {
          return Math.floor(value.max * 1.4).toString();
        },
        type: "value",
        splitLine: true,
        alignTicks: true,
        axisTick: {
          show: true,
          splitNumber: 4
        }
      }
    ],
    series: [],
    replaceMerge: ["series"]
  });
  const [barGraph, setBarGraph] = useState<any>({
    categories: [],
    series: []
  });
  const [dateLabel, setDateLabel] = useWebFiltersStore((state) => [
    state.dateLabel,
    state.setDateLabel
  ]);

  const [markPointAnnotations, setMarkPointAnnotations] = useState<Note[]>([]);
  const [annotationPortalOpened, setAnnotationPortalOpened] = useState(false);
  const [portalPosition, setPortalPosition] = useState({ x: 0, y: 0 });

  const checkIsSharedDashboard = (url: string): boolean =>
    url.split("/")[1] === "share" && url.split("/").pop() === "web";
  const isSharedDashboard = dashboardUrl && checkIsSharedDashboard(dashboardUrl);

  // Function to get the start of the period (week or month)
  const getPeriodStartDate = (date: Date, period: string): Date => {
    if (period === "week") {
      return startOfWeek(date, { weekStartsOn: 1 }); // Adjust weekStartsOn as per your locale
    } else if (period === "month") {
      return startOfMonth(date);
    }
    return date; // Default to day if period is not week or month
  };

  let annotationsGroupedData = groupBy(
    notes.filter((note) => {
      if (isSharedDashboard) {
        // If isSharedDashboard is true, show notes with visibility "All Users" and on_shareable_dashboard true,
        // and hide_note is false
        return (
          note.visibility === NoteVisibilityEnum.AllUsers &&
          note.on_shareable_dashboard &&
          !note.hide_note
        );
      } else {
        // If isSharedDashboard is false, show all notes that are not hidden
        return !note.hide_note;
      }
    }),
    (item) => format(getPeriodStartDate(new Date(item.date), query.period), "yyyy-MM-dd")
  );

  const [annotationsGroupedDataState, setAnnotationsGroupedDataState] =
    useState<Record<string, Note[]>>(annotationsGroupedData);

  const renderGraph = async (bottomValue: string) => {
    const currentPeriodData = timeSeriesGraphData;
    const comparisonPeriodData = comparisonTimeSeriesGraphData;
    if (currentPeriodData.histogram.length > 0) {
      setHasResults(true);
    } else {
      setHasResults(false);
    }
    if (chartType === "line") {
      storeLineGraphData(currentPeriodData, comparisonPeriodData, bottomValue);
    } else {
      storeBarGraphData(currentPeriodData, comparisonPeriodData);
    }
  };

  const handleGraphOnClick = (params: any) => {
    if (params.componentType === "markPoint") {
      // show annotations modal
      const datePoint = params.data.name.split("_")[1];
      const annotations = annotationsGroupedDataState && annotationsGroupedDataState[datePoint];
      setMarkPointAnnotations(annotations);

      // Get cursor position
      const cursorPositionX = params.event.offsetX;
      const cursorPositionY = params.event.offsetY;

      setPortalPosition({
        x: cursorPositionX > 1000 ? cursorPositionX - 50 : cursorPositionX - 400,
        y: cursorPositionY + 50
      });
      setAnnotationPortalOpened(true);
    } else {
      // bar drill down
      if (query.period !== "hour" && query.period !== "live") {
        const dataPointDate =
          params.componentSubType === "bar"
            ? params.data.date
            : formatToMidnightUTC(params.data[0]);
        const { from_date, to_date } = getRangeByDateAndInterval(dataPointDate, query.period);
        setQuery({
          ...query,
          period: query.period === "day" ? "hour" : "day",
          from_date: from_date,
          to_date: to_date
        });
        if (query.period === "day") {
          setDateLabel(format(new Date(from_date), "MMM d, yyyy"));
        } else {
          setDateLabel(
            `${format(new Date(from_date), "MMM d")} - ${format(new Date(to_date), "MMM d")} `
          );
        }
      }
    }
  };

  useEffect(() => {
    const bottomValue =
      query?.comparison !== undefined && query?.comparison !== null ? "10%" : "5%";
    renderGraph(bottomValue);
    setAnnotationsGroupedDataState(annotationsGroupedData);
    // Add rerendering of the chart on colorScheme change
    setLineGraph((currentGraph: any) => {
      currentGraph.tooltip.borderColor = theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2];
      currentGraph.tooltip.backgroundColor = theme.colorScheme === "dark" ? theme.colors.dark[9] : "#fff";
      currentGraph.tooltip.textStyle.color = theme.colorScheme === "dark" ? "#A6A7AB" : "#595c5f";
      return cloneDeep(currentGraph);
    });
  }, [query, activeWorkspace.id, chartType, notes, theme.colorScheme]);

  const storeBarGraphData = (currentPeriodData: any, comparisonPeriodData: any) => {
    const completeData = currentPeriodData.histogram;

    let currentPeriodCategories = completeData.map((item: any) => {
      return getDateWithZonedTimeToUTC(item.date, query.period);
    });

    if (query.period === "week" || query.period === "month") {
      const mappedData: Record<string, any[]> = Object.fromEntries(
        Object.entries(annotationsGroupedData).map(([date, data]) => [
          completeData.reduce(
            (a: string | number | Date, b: { date: string | number | Date }) =>
              Math.abs(new Date(b.date).getTime() - new Date(date).getTime()) <
              Math.abs(new Date(a).getTime() - new Date(date).getTime())
                ? b.date
                : a,
            completeData[0].date
          ),
          data
        ])
      );
      annotationsGroupedData = mappedData;
      setAnnotationsGroupedDataState(annotationsGroupedData);
    }

    const markPoints = completeData
      .map((item: any) => {
        const matchingData = annotationsGroupedData[item.date];
        if (matchingData) {
          const markPointLabel =
            matchingData.length > 1 ? `${matchingData.length} Notes` : "1 Note";
          return {
            coord: [
              getDateWithZonedTimeToUTC(item.date, query.period),
              parseInt((item.count * 1.18).toFixed(0))
            ],
            name: "Annotation_" + item.date,
            symbolSize: 25,
            itemStyle: {
              color: `rgb(${graphAreaColor}, 0.6)`,
              borderColor: graphColor,
              borderWidth: 1
            },
            label: {
              show: true,
              formatter: `${markPointLabel}`,
              position: "top",
              color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[9],
              backgroundColor: "transparent"
            }
          };
        }
        return null; // If no matching data is found
      })
      .filter(Boolean); // Remove null entries

    const markLines = completeData
      .map((item: any) => {
        const matchingData = annotationsGroupedData[item.date];
        if (matchingData && matchingData.length >= 1) {
          const line = [
            {
              xAxis: getDateWithZonedTimeToUTC(item.date, query.period),
              yAxis: item.count
            },
            {
              xAxis: getDateWithZonedTimeToUTC(item.date, query.period),
              yAxis: parseInt((item.count * 1.18).toFixed(0)),
              lineStyle: {
                type: "dotted",
                color: graphColor
              }
            }
          ];
          return line;
        }
        return null; // If no matching data or not enough data is found
      })
      .filter(Boolean); // Remove null entries

    let series = [
      {
        type: "bar",
        name: `${snakeCaseToSimpleString(activeCard)}`,
        data: currentPeriodData.histogram.map((item: any) => {
          const dateValue = !item.date.includes(" ") ? `${item.date} 00:00:00` : item.date;
          return {
            value: item.count,
            date: format(new Date(dateValue), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
          };
        }),
        markPoint: {
          data: !query.comparison && markPoints,
          emphasis: {
            label: {
              show: true
            }
          }
        },
        markLine: {
          symbol: "none",
          data: !query.comparison && markLines
        },
        itemStyle: {
          borderRadius: [4, 4, 0, 0]
        },
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: `rgb(${graphAreaColor}, 0.7)` },
          { offset: 0.5, color: `rgb(${graphAreaColor}, 0.8)` },
          { offset: 1, color: `rgb(${graphAreaColor}, 1)` }
        ])
      }
    ];

    setBarGraph({
      categories: currentPeriodCategories,
      series: series
    });
  };

  const renderBarGraph = () => {
    return (
      <>
        {barGraph.hasOwnProperty("series") && barGraph.series.length > 0 && hasResults ? (
          <div data-cy={`${activeCard}-bar-graph`}>
            <BarGraphWrapper
              height="300px"
              categories={barGraph.categories}
              series={barGraph.series}
              onClick={(params: any) => handleGraphOnClick(params)}
              grid={{
                top: "5%",
                left: "0%",
                right: "1%",
                bottom: "0%",
                containLabel: true
              }}
              customTooltipFormatter={(params: any) =>
                GenericTooltipBar(
                  params,
                  false,
                  getDateFormatFromPeriod(query.period),
                  query.period,
                  activeWorkspace.timezone
                )
              }
            />
          </div>
        ) : (
          <>
            <div className="pt-28">
              <NoResults heading={"No results"} text={"There is no activity"} />
            </div>
          </>
        )}
      </>
    );
  };

  const storeLineGraphData = (
    currentPeriodData: any,
    comparisonPeriodData: any,
    bottomValue: string
  ) => {
    const { from_date, to_date, comparison, period, custom_range } = query;
    let series;
    let currentLineData = getChartLineTransformedData({ data: currentPeriodData, period });
    let currentPartialLineData = getChartLineTransformedData({
      data: currentPeriodData,
      period,
      partial: true
    });
    let completeCurrentLineData = getChartLineTransformedData({
      data: currentPeriodData,
      period,
      complete: true
    });
    let comparisonLineData: any[] = [];
    let remappedComparisonLineData: any[][] = [];

    if (period === "hour" || period === "live") {
      currentLineData = currentLineData.map(([dateStr, value]: [string, number]) => [
        format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        value
      ]);
      currentPartialLineData = currentPartialLineData.map(([dateStr, value]: [string, number]) => [
        format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        value
      ]);
      completeCurrentLineData = completeCurrentLineData.map(
        ([dateStr, value]: [string, number]) => [
          format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          value
        ]
      );
    } else {
      currentLineData = currentLineData.map(([dateStr, value]: [string, number]) => [
        format(new Date(dateStr), "yyyy-MM-dd"),
        value
      ]);
      currentPartialLineData = currentPartialLineData.map(([dateStr, value]: [string, number]) => [
        format(new Date(dateStr), "yyyy-MM-dd"),
        value
      ]);
      completeCurrentLineData = completeCurrentLineData.map(
        ([dateStr, value]: [string, number]) => [format(new Date(dateStr), "yyyy-MM-dd"), value]
      );
    }

    const completeData = completeCurrentLineData.map(([date, count]: [any, any]) => ({
      date,
      count
    }));
    if (query.period === "week" || query.period === "month") {
      const mappedData: Record<string, any[]> = Object.fromEntries(
        Object.entries(annotationsGroupedData).map(([date, data]) => [
          completeData.reduce(
            (a: string | number | Date, b: { date: string | number | Date }) =>
              Math.abs(new Date(b.date).getTime() - new Date(date).getTime()) <
              Math.abs(new Date(a).getTime() - new Date(date).getTime())
                ? b.date
                : a,
            completeData[0].date
          ),
          data
        ])
      );
      annotationsGroupedData = mappedData;
      setAnnotationsGroupedDataState(annotationsGroupedData);
    }

    const markPoints = completeCurrentLineData
      .map(([date, value]: [string, number]) => {
        const matchingData = annotationsGroupedData[date];
        if (matchingData) {
          const markPointLabel =
            matchingData.length > 1 ? `${matchingData.length} Notes` : "1 Note";
          return {
            coord: [date, parseInt((value * 1.18).toFixed(0))],
            name: "Annotation_" + date,
            symbolSize: 25,
            itemStyle: {
              color: `rgb(${graphAreaColor}, 0.4)`,
              borderColor: graphColor,
              borderWidth: 1
            },
            label: {
              show: true,
              formatter: `${markPointLabel}`,
              position: "top",
              color: theme.colorScheme === "dark" ? "#A6A7AB" : "#242424",
              backgroundColor: "transparent"
            }
          };
        }
        return null; // If no matching data is found
      })
      .filter(Boolean); // Remove null entries

    const markLines = completeCurrentLineData
      .map(([date, value]: [string, number]) => {
        const matchingData = annotationsGroupedData[date];
        if (matchingData && matchingData.length >= 1) {
          const line = [
            {
              xAxis: date,
              yAxis: value
            },
            {
              xAxis: date,
              yAxis: parseInt((value * 1.18).toFixed(0)),
              lineStyle: {
                type: "dotted",
                color: graphColor
              }
            }
          ];
          return line;
        }
        return null; // If no matching data or not enough data is found
      })
      .filter(Boolean); // Remove null entries

    if (comparisonPeriodData) {
      const parsedFromDate = new Date(from_date);
      const parsedEndDate = new Date(to_date);
      const total_days = differenceInDays(parsedEndDate, parsedFromDate) + 1;
      comparisonLineData = getChartLineTransformedData({
        data: comparisonPeriodData,
        period,
        comparison: true
      });
      comparisonLineData = comparisonLineData.map(([dateStr, value]: [string, number]) => [
        format(new Date(dateStr), "yyyy-MM-dd"),
        value
      ]);

      // NOTE: This is only for This Month Filter
      const thisMonthLabel = `${format(startOfMonth(new Date()), "MMM dd")} - ${format(
        new Date(),
        "MMM dd"
      )}`;
      const isThisMonth = [thisMonthLabel, "This Month"].includes(dateLabel);
      const isLabelMismatch = !["Last 7 days", "Last 14 days", "Last 21 days"].includes(dateLabel);
      if (
        isThisMonth &&
        !custom_range &&
        comparison === "previous_period" &&
        period === "day" &&
        total_days < 30 &&
        isLabelMismatch
      ) {
        remappedComparisonLineData = comparisonLineData.map(([dateStr, value]) => [
          format(addMonths(new Date(dateStr), 1), "yyyy-MM-dd"),
          value
        ]);
      } else {
        let lastKnownDate =
          completeCurrentLineData.length > 0
            ? parseISO(completeCurrentLineData[completeCurrentLineData.length - 1][0])
            : null;
        function isDate(date: Date | null): date is Date {
          return date !== null;
        }
        remappedComparisonLineData = comparisonLineData.map((item, idx) => {
          if (completeCurrentLineData[idx]) {
            return [completeCurrentLineData[idx][0], item[1]];
          } else {
            if (isDate(lastKnownDate)) {
              lastKnownDate = addDays(lastKnownDate, 1);
              return [format(lastKnownDate, "yyyy-MM-dd"), item[1]];
            } else {
              return item;
            }
          }
        });
      }
    }

    if (currentPeriodData.present_index !== -1) {
      series = [
        comparisonPeriodData &&
          comparisonPeriodData.histogram &&
          comparisonPeriodData.histogram.length && {
            type: "line",
            name: `Comparison ${snakeCaseToSimpleString(activeCard)}`,
            color: comparisonGraphColor,
            smooth: false,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: `rgb(${graphAreaColor}, 0.4)`
                },
                {
                  offset: 1,
                  color: `rgb(${graphAreaColor}, 0.01)`
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: remappedComparisonLineData
          },
        {
          type: "line",
          name: `Current ${snakeCaseToSimpleString(activeCard)}`,
          color: graphColor,
          smooth: false,
          lineStyle: {
            width: 2
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: `rgb(${graphAreaColor}, 0.6)`
              },
              {
                offset: 1,
                color: `rgb(${graphAreaColor}, 0.01)`
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: currentLineData,
          markPoint: {
            data: !query.comparison && markPoints,
            emphasis: {
              label: {
                show: true
              }
            }
          },
          markLine: {
            symbol: "none",
            data: !query.comparison && markLines
          }
        },
        {
          type: "line",
          name: `Current Partial Day ${snakeCaseToSimpleString(activeCard)}`,
          color: graphColor,
          smooth: false,
          lineStyle: {
            width: 2,
            type: "dashed"
          },
          itemStyle: {
            emphasis: {
              label: {
                show: false
              }
            }
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: `rgb(${graphAreaColor}, 0.4)`
              },
              {
                offset: 1,
                color: `rgb(${graphAreaColor}, 0.01)`
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: currentPartialLineData
        }
      ];
    } else {
      series = [
        comparisonPeriodData &&
          comparisonPeriodData.histogram &&
          comparisonPeriodData.histogram.length && {
            type: "line",
            name: `Comparison ${snakeCaseToSimpleString(activeCard)}`,
            color: comparisonGraphColor,
            smooth: false,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: `rgb(${graphAreaColor}, 0.4)`
                },
                {
                  offset: 1,
                  color: `rgb(${graphAreaColor}, 0.01)`
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: remappedComparisonLineData
          },
        {
          type: "line",
          name: `Current ${snakeCaseToSimpleString(activeCard)}`,
          color: graphColor,
          smooth: false,
          lineStyle: {
            width: 2
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: `rgb(${graphAreaColor}, 0.6)`
              },
              {
                offset: 1,
                color: `rgb(${graphAreaColor}, 0.01)`
              }
            ])
          },
          emphasis: {
            focus: "series"
          },
          data: completeCurrentLineData,
          markPoint: {
            data: !query.comparison && markPoints,
            emphasis: {
              label: {
                show: true
              }
            }
          },
          markLine: {
            symbol: "none",
            data: !query.comparison && markLines
          }
        }
      ];
    }
    query.comparison
      ? (lineGraph.legend = {
          bottom: "-1%",
          data: [
            `Current ${snakeCaseToSimpleString(activeCard)}`,
            `Comparison ${snakeCaseToSimpleString(activeCard)}`
          ],
          icon: "circle"
        })
      : (lineGraph.legend = {
          show: false
        });
    lineGraph.series = series;
    lineGraph.grid.bottom = bottomValue;
    lineGraph.xAxis[0].axisLabel.formatter = (params: any) => {
      return xAxisLabelFormatter(query.period, params);
    };
    lineGraph.tooltip.formatter = (params: any) =>
      query.comparison
        ? GenericComparisonTooltip(
            params,
            true,
            getDateFormatFromPeriod(query.period),
            query.period,
            query,
            snakeCaseToSimpleString(activeCard),
            activeWorkspace.timezone
          )
        : GenericTooltip(
            params,
            false,
            getDateFormatFromPeriod(query.period),
            query.period,
            activeWorkspace.timezone
          );
    setLineGraph(cloneDeep(lineGraph));
  };

  const renderLineGraph = () => {
    return (
      <>
        {lineGraph.hasOwnProperty("series") && hasResults ? (
          <div data-cy={`${activeCard}-line-graph`}>
            <ReactEChartsCore
              style={{ height: "300px" }}
              echarts={echarts}
              option={lineGraph}
              notMerge={true}
              onEvents={{
                click: (params: any) => handleGraphOnClick(params)
              }}
            />
          </div>
        ) : (
          <div className="pt-28">
            <NoResults heading={"No results"} text={"There is no activity"} />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Box h={"23rem"} mt={16}>
        {loading === "loaded" ? (
          <div data-cy={`${activeCard}-graph`}>
            {chartType === "bar" ? renderBarGraph() : renderLineGraph()}
            <Modal
              withCloseButton={false}
              opened={annotationPortalOpened}
              onClose={() => setAnnotationPortalOpened(false)}
              shadow="sm"
              overlayProps={{
                blur: 0,
                opacity: 0.5
              }}
              padding={10}
              size={355}
              title={null}

              styles={{
                header: {
                  marginBottom: 0,
                  marginTop: -8,
                  position: "absolute",
                  right: 0,
                },
                content: {
                  paddingTop: '1rem',
                  left: `${portalPosition.x}px`,
                  top: `${portalPosition.y}px`,
                  position: "absolute",
                  flexShrink: 0,
                  width: '100%',
                  maxWidth: '355px'
                },
                inner: {
                  maxHeight: "280px",
                }
              }}
              withinPortal>
              <Box
                sx={{
                  maxHeight: "280px",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none" // hides scrollbar for Webkit browsers
                  },
                  "-ms-overflow-style": "none", // hides scrollbar for IE and Edge
                  scrollbarWidth: "none" // hides scrollbar for Firefox
                }}>
                <Box w={350} pos={"relative"}>
                  <Box pos="absolute" right={12}>
                    <CloseButton onClick={() => setAnnotationPortalOpened(false)} />
                  </Box>
                  <Flex direction={"column"} align={"center"}>
                    {markPointAnnotations &&
                      markPointAnnotations.map((annotation: Note, index: number) => (
                        <>
                          <Flex direction={"column"} maw={350}>
                            <Text fw={600}>{annotation.title}</Text>
                            <Text fw={400}> {truncateText(annotation.description, 150)}</Text>
                            <Flex gap={10}>
                              <Text c="dimmed" fz="xs" my={3}>
                                {format(parseISO(annotation.date), "MMM dd, yyyy")},
                              </Text>
                              <Text c="dimmed" fz="xs" my={3}>
                                {annotation.author}
                              </Text>
                            </Flex>
                          </Flex>
                          {index !== markPointAnnotations.length - 1 && <Divider />}
                        </>
                      ))}
                  </Flex>
                </Box>
              </Box>
            </Modal>
          </div>
        ) : (
          <>
            <Center my="md" h={'300px'}>
              <BarChartSkeleton />
            </Center>
          </>
        )}
      </Box>
    </>
  );
};

const areEqual = (prevProps: any, nextProps: any) => {
  return Object.keys(prevProps).every((key) => {
    return prevProps[key] === nextProps[key];
  });
};

const MemoizedGenericLineAndBarGraphWrapper = React.memo(GenericLineAndBarGraphWrapper, areEqual);

export default MemoizedGenericLineAndBarGraphWrapper;
