import { Box, Flex } from "@mantine/core";
import SidePane from "@/ui/pages/ContactsHub/components/SidePane/SidePane";
import Visitors from "@/ui/pages/Visitors/Visitors";
import Users from "@/ui/pages/UsersCRM/Users";
import React from "react";
import Companies from "@/ui/pages/Companies/Companies";

export interface ContactsHubProps {
  type?: "visitors" | "users" | "companies";
}

const ContactsHub = ({ type = "visitors" }: ContactsHubProps) => {
  return (
    <Flex>
      <SidePane type={type} />

      <Box style={{ flex: 1 }}>
        {type === "visitors" && <Visitors />}
        {type === "users" && <Users />}
        {type === "companies" && <Companies />}
      </Box>
    </Flex>
  );
};

export default ContactsHub;
