import React from "react";
import { ValidationLabelParagraph } from "./ValidationLabel.style";

export interface ValidationLabelProps {
  /**
   * Text for the label
   */
  validationType?: "warning" | "error";

  /**
   * How large should the text size be?
   */
  size?: "small" | "medium" | "large";

  bold?: boolean;

  text: string;
  customClasses?: string;
}

/**
 * Primary UI component for user interaction
 */
export const ValidationLabel: React.FC<ValidationLabelProps> = ({
  validationType = "error",
  bold = false,
  size = "medium",
  text = "Your label text",
  customClasses = "",
  ...props
}) => {
  const mode =
    validationType === "error" ? "um-validation-label--error" : "um-validation-label--warning";

  return (
    <ValidationLabelParagraph size={size} validationType={validationType} bold={bold}>
      {text}
    </ValidationLabelParagraph>
  );
};
