import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { EventsService } from "@/lib/services/EventsService";
import { WorkspacePinnedEventsService } from "@/lib/services/WorkspacePinnedEventsService";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Loader,
  Paper,
  Text,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { Fragment, useContext, useEffect, useState } from "react";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface IEventsBreakdownByPageProps {
  id: string;
  eventType: string;
  eventName: string;
  fromDate: string;
  toDate: string;
}

type BreakdownByPage = {
  page_title: string;
  url: string;
  count: number;
};

export const EventsBreakdownByPage = ({
  id,
  eventType = "custom",
  eventName,
  fromDate,
  toDate
}: IEventsBreakdownByPageProps) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const theme = useGlobalMantineTheme();
  const [data, setData] = useState<BreakdownByPage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchBreakdownByPage = async () => {
    setLoading(true);

    if (eventType === "custom") {
      await new EventsService()
        .eventBreakdownByPage(activeWorkspace.id, eventName, page, fromDate, toDate)
        .then((response) => {
          if (response.data) {
            setData(response.data.data);

            // if the number of events returned is less than 50, then there are no more events to fetch
            if (response.data.data.length < 50) {
              setHasMore(false);
            }
          } else {
            setData([]);
            setHasMore(false);
          }
        })
        .catch((_error) => {
          setData([]);
          setHasMore(false);
        });
    } else {
      await new WorkspacePinnedEventsService()
        .breakdownByPage(activeWorkspace.id, id, page, fromDate, toDate)
        .then((response) => {
          if (response.data) {
            setData(response.data.data);

            // if the number of events returned is less than 50, then there are no more events to fetch
            if (response.data.data.length < 50) {
              setHasMore(false);
            }
          } else {
            setData([]);
            setHasMore(false);
          }
        })
        .catch((_error) => {
          setData([]);
          setHasMore(false);
        });
    }

    setLoading(false);
  };

  const fetchMoreBreakdownByPage = async () => {
    setLoadingMore(true);

    if (eventType === "custom") {
      await new EventsService()
        .eventBreakdownByPage(activeWorkspace.id, eventName, page, fromDate, toDate)
        .then((response) => {
          if (response.data) {
            // append the new data to the existing data
            setData((prevData) => [...prevData, ...response.data.data]);

            // if the number of events returned is less than 50, then there are no more events to fetch
            if (response.data.data.length < 50) {
              setHasMore(false);
            }
          } else {
            setData([]);
            setHasMore(false);
          }
        })
        .catch((_error) => {
          setData([]);
          setHasMore(false);
        });
    } else {
      await new WorkspacePinnedEventsService()
        .breakdownByPage(activeWorkspace.id, id, page, fromDate, toDate)
        .then((response) => {
          if (response.data) {
            // append the new data to the existing data
            setData((prevData) => [...prevData, ...response.data.data]);

            // if the number of events returned is less than 50, then there are no more events to fetch
            if (response.data.data.length < 50) {
              setHasMore(false);
            }
          } else {
            setData([]);
            setHasMore(false);
          }
        })
        .catch((_error) => {
          setData([]);
          setHasMore(false);
        });
    }

    setLoadingMore(false);
  };

  // Increment the page number to fetch the next page
  const handleLoadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    fetchBreakdownByPage();
  }, [id, fromDate, toDate]);

  // Fetch more data when the page number changes
  useEffect(() => {
    if (page > 1) {
      fetchMoreBreakdownByPage();
    }
  }, [page]);

  return (
    <>
      <div
        className="um-settings-wrapper p-0 overflow-y-auto"
        data-cy="event-detailed-breakdown-by-page-section">
        <Paper withBorder shadow="sm" component="div" p="md">
          <Grid data-cy="event-detailed-activity-section-titles" fw={500} fz="sm">
            <Grid.Col span={8}>Page</Grid.Col>
            <Grid.Col span={4}>Occurrence</Grid.Col>
          </Grid>
          <Divider py="md" />

          {/* List of pages with occurrence */}
          {!loading &&
            data.length > 0 &&
            data.map((event: BreakdownByPage, index: number) => (
              <Fragment key={`event-detailed-activity-list:page:${index}`}>
                <Grid
                  key={event.url}
                  component="div"
                  data-cy="event-detailed-activity-list"
                  pb={"sm"}>
                  <Grid.Col span={8}>
                    <Tooltip label={event.page_title} disabled={event.page_title.length < 120}>
                      <Text lineClamp={1}>{event.page_title}</Text>
                    </Tooltip>

                    <Tooltip label={event.url} disabled={event.url.length < 120} position="bottom">
                      <Text
                        lineClamp={1}
                        className="hover:opacity-80 cursor-pointer hover:underline transition-all ease-in-out"
                        onClick={() => window.open(event.url, "_blank")}
                        c="dimmed">
                        {event.url}
                      </Text>
                    </Tooltip>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Text fw={600}>{numberToCommas(event.count)}</Text>
                  </Grid.Col>
                </Grid>
              </Fragment>
            ))}

          {/* Load more button */}
          {!loading && hasMore && data.length > 0 && (
            <div className="flex justify-center mt-4">
              <Button
                loading={loadingMore}
                disabled={loadingMore}
                variant="outline"
                color="gray.7"
                onClick={() => handleLoadMore()}>
                Load more
              </Button>
            </div>
          )}

          {/* No results */}
          {!loading && data && data.length === 0 && (
            <div className="flex items-center justify-center">
              <NoResults
                text={"There is no event activity available."}
                heading={"No event activity"}></NoResults>
            </div>
          )}

          {/* Loader */}
          {loading && (
            <Flex my={16} justify="center" align="center">
              <Loader size="xs" />
            </Flex>
          )}
        </Paper>
      </div>
    </>
  );
};
