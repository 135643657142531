import { Header } from "@/ui/components/Common/Header/Header";
import videoSrc from "@assets/videos/user-journeys-demo.mp4";
import { useMantineTheme } from "@mantine/core";
import { useContext, useEffect } from "react";
import AppLifecycleContext from "../../../lib/contexts/AppLifecycleContext";
import { useJourneyStore } from "../../../stores/useJourneyStore";
import { LockButton } from "../../components/Common/LockButton/LockButton";
import { ReviewPageContent } from "../../components/Common/ReviewModal/ReviewPageContent";
import JourneysListing from "./components/Journey/JourneysListing";
const Journeys = () => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [_, setIsModalOpen] = useJourneyStore((state) => [state.modalOpen, state.setModalOpen]);

  useEffect(() => {
    document.title = "User Journeys | Usermaven";
    console.log("Journeys.tsx useEffect");
  }, []);

  if (
    activeWorkspace.organization.plan.is_lifetime &&
    !activeWorkspace.organization.is_journey_unlocked
  ) {
    return (
      <ReviewPageContent
        title={
          "User Journey is a new feature that we have added recently and we are giving it away for FREE to our lifetime users."
        }
        feature={"journey"}
        src={videoSrc}
      />
    );
  }

  return (
    <>
      <Header
        title="User Journeys"
        rightSection={
          <>
            <LockButton
              label="New User Journey"
              errorMessage="Please upgrade your plan to Pro or Premium to create user journeys"
              onClick={() => setIsModalOpen(true)}
            />
          </>
        }
      />
      <JourneysListing onNewJourneyClick={() => setIsModalOpen(true)} />
    </>
  );
};

export default Journeys;
