import { Oval } from "react-loader-spinner";
import { loaderColor } from "@/style/Colors";
import React, { useContext, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { UsersCRMService } from "@/lib/services/UsersCRMService";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { EventsService } from "@/lib/services/EventsService";
import { getReadableFormatNumber, numberToCommas } from "@/lib/utils/StringUtility";

export interface ITopPagesTab {
  type: "visitor" | "user" | "company";

  uniqueId: string | undefined;
}

export interface ITopPagesTabItem {
  page_title: string;
  url: string;
  count: number;
}

const TopPagesTab = ({ type = "visitor", uniqueId }: ITopPagesTab) => {
  const [topPagesList, setTopPagesList] = useState<ITopPagesTabItem[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [hasMore, setHasMore] = React.useState(true);

  const { activeWorkspace } = useContext(AppLifecycleContext);

  const page = useRef(1);

  const fetchTopPages = async () => {
    setLoading(true);
    const fetchedTopPages = await onFetchTopPages(page.current);
    if (fetchedTopPages) {
      setTopPagesList((prevTopPagesList) => [...prevTopPagesList, ...fetchedTopPages]);

      if (fetchedTopPages.length < 20) {
        setHasMore(false);
      }

      page.current++;
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchTopPages();
  }, []);

  // Users CRM service instance - used to handle all users related api operations.
  const usersCRMService = new UsersCRMService();

  /** Method to fetch top pages data */
  const onFetchTopPages = async (page: number) => {
    return await fetchUserTopPages(page);
  };

  const fetchUserTopPages = async (page: number) => {
    const response = await new EventsService().eventCountByContact(
      activeWorkspace?.id,
      uniqueId || "",
      "pageview",
      page,
      type
    );
    if (response?.data) {
      return response.data;
    }
    return [];
  };

  return (
    <Paper withBorder radius={"xs"} shadow="xs">
      {/* Header */}
      <Box p="sm">
        <Group justify="space-between">
          <Text fw={600}>Most Visited Pages</Text>
          <Text fw={600}>All time</Text>
        </Group>
      </Box>
      <Divider />

      {/* Body */}
      <Box>
        {topPagesList && topPagesList.length > 0 && (
          <Stack gap="xs">
            {topPagesList.map((item: ITopPagesTabItem, index: number) => (
              <Box key={`pagesList:${index}`}>
                <Grid m={0} gutter="xl" justify="space-between" align="center" p={"sm"}>
                  <Grid.Col span="auto">
                    <Stack gap={0}>
                      <Text fw="bold" lineClamp={1} fz="md" mb="xs">
                        {item.page_title}
                      </Text>
                      <Text>{item.url}</Text>
                    </Stack>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Tooltip label={numberToCommas(item.count)} disabled={item.count < 10000}>
                      <Text fz="lg" ta="right" fw={600}>
                        {getReadableFormatNumber(item.count, 10000)}
                      </Text>
                    </Tooltip>
                  </Grid.Col>
                </Grid>
                <Divider />
              </Box>
            ))}
          </Stack>
        )}

        {loading ? (
          <Flex justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
            <Loader size="xs" />
          </Flex>
        ) : (
          <>
            {hasMore ? (
              <Flex h={80} justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
                <Button size="xs" variant="light" onClick={fetchTopPages}>
                  Load More
                </Button>
              </Flex>
            ) : topPagesList.length === 0 ? (
              <Flex h={80} justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
                <Text>No pages visited.</Text>
              </Flex>
            ) : null}
          </>
        )}
      </Box>
    </Paper>
  );
};

export default React.memo(TopPagesTab);
