import axios from './JWTService';

export class WorkspaceIntegrationService {
  authorizeIntegrationURL = (workspaceId: string, name: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspace_integrations/${workspaceId}/oauth`, {
          name: name
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  oAuthVerify = (state: any, code: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspace_integrations/oauth_verify`, {
          state: state,
          code: code
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  countConnected = (workspace_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspace_integrations/${workspace_id}/connected`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  connectedIntegrationsByProvider = (
    workspace_id: string,
    integrationName: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspace_integrations/${workspace_id}/list/${integrationName}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  deleteIntegration = (id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`v1/workspace_integrations/${id}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  getById = (wsId: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspace_integrations/${wsId}/id/${id}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  updateGSCSites = (wsId: string, id: string, sites: string[]): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspace_integrations/${wsId}/google_search_console_sites/${id}`, {
          site_urls: sites
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
}
