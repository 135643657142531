/* eslint-disable react-hooks/exhaustive-deps */
import { DecodedValueMap, QueryParamConfig } from "serialize-query-params";
import { useContext, useEffect, useRef, useState } from "react";
import DetailViewMenuGroup, { IDetailViewMenu } from "./DetailViewMenuGroup";
import { Box, Flex } from "@mantine/core";
import { InsightsShareableDropdown } from "../../../../components/App/InsightsShareableDropdown/InsightsShareableDropdown";
import { DateDropdownMenu } from "../../../../components/App/Dropdowns/DateDropdownMenu";
import OverviewTab from "../tabs/OverviewTab";
import BreakdownTab from "../tabs/BreakdownTab";
import { WebAnalyticsService } from "../../../../../lib/services/WebAnalyticsService";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { useParams } from "react-router-dom";
import CompareTab from "../tabs/CompareTab";
import { FormattedMessage } from "react-intl";
import ConversionTimeDistribution from "@/ui/pages/Analyze/components/tabs/ConversionTimeDistribution";

export interface IFunnelDetails {
  /**
   * isPublic is a boolean to check if the funnel is public or not
   */
  isPublic?: boolean;
  /**
   * Specify the 'id' of the funnel
   */
  id: string;

  /**
   * Specify the 'selectedFunnel' of the funnel steps
   */
  selectedFunnel: IFunnelStep[];

  /**
   * Specify the 'meta' of the funnel
   */
  selectedMeta?: IFunnelMeta;

  /**
   * Specify the 'funnel_type' of the funnel
   */
  selectedType?: "visitor" | "user" | "company";

  /**
   * Specify the 'date' of the funnel
   */
  date: DecodedValueMap<{
    start_date: QueryParamConfig<string | null | undefined, string | null | undefined>;
    end_date: QueryParamConfig<string | null | undefined, string | null | undefined>;
  }>;

  /**
   * Specify the onDateChange callback
   */
  onDateChange: (startDate: string | undefined, endDate: string | undefined) => void;

  initialActiveTabType: IDetailViewMenu;

  /**
   * isOnboarding is a boolean to check if the user is onboarding or not
   */
  isOnboarding?: boolean;
}

export interface IFunnelStep {
  name: string;
  completed_step_rate_from_first_step: number;
  completed_step_rate_from_next_step: number;
  visitors: number;
  prev_visitors_count: number;
  drop_off_rate_prev_step: number;
  drop_off_count_prev_step: number;
  drop_off_rate_from_first_step: number;
  conv_rate_prev_step: number;
  conv_rate_from_first_step: number;
  avg_time_in_s: number;
  level?: number;
  breakdown_prop?: string;
}

export interface IFunnelMeta {
  total_visitors: number;
  total_converted: number;
  conversion_rate: number;
  levels_count: number;
  most_drop_off_step: {
    name: string;
    percentage: number;
    level: number;
  };
  funnel_name: string;
  breakdown?: IFunnelMeta[];
  breakdown_value?: string;
  breakdown_prop?: string;
}

export type TAttribute =
  | "location_country"
  | "location_city"
  | "parsed_device"
  | "parsed_ua_ua_family"
  | "parsed_ua_os_family"
  | "channel"
  | "source"
  | "referer"
  | "period"
  | "none";

type Params = {
  secret: string;
};

const FunnelDetails = ({
  isPublic = false,
  id,
  selectedFunnel,
  selectedMeta,
  selectedType = "visitor",
  date,
  onDateChange,
  initialActiveTabType,
  isOnboarding = false
}: IFunnelDetails) => {
  const { secret } = useParams<Params>();

  // Service instance for api requests
  const webAnalyticsService = new WebAnalyticsService();

  // Workspace context
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // Initial funnel steps
  const [initialSelectedFunnel, setSelectedFunnel] = useState<IFunnelStep[]>(selectedFunnel);

  // Initial funnel meta
  const [initialSelectedMeta, setSelectedMeta] = useState<IFunnelMeta | undefined>(selectedMeta);

  // Render count
  const renderCount = useRef(0);

  // Toggle between default and breakdown view
  const [selectedTab, setSelectedTab] = useState<IDetailViewMenu>(initialActiveTabType);

  /**
   * Handle the date filter
   * @param startDate
   * @param endDate
   */
  const handleDateChange = (startDate: string, endDate: string) => {
    console.log("handleDateChange", startDate, endDate);

    const fromDate = startDate.replace(" ", "T");
    const toDate = endDate.replace(" ", "T");

    onDateChange(fromDate, toDate);
  };

  /**
   * This will be responsible for updating the initial funnel steps on date change
   */
  const fetchDefaultFunnel = async () => {
    const response = await webAnalyticsService.funnel(
      activeWorkspace.id,
      id,
      {
        start_date: date.start_date,
        end_date: date.end_date,
        funnel_type: isOnboarding ? selectedType : ""
      },
      secret || null
    );

    if (response?.data?.funnel_steps) {
      setSelectedFunnel(response.data.funnel_steps);
      setSelectedMeta(response.data.meta);
    }
  };

  useEffect(() => {
    // Skip first render
    if (renderCount.current === 0) {
      renderCount.current = 1;
      return;
    }

    // Fetch the default funnel steps
    fetchDefaultFunnel();
  }, [date]);

  return (
    <>
      <Flex align="center" mb="md">
        <Flex align={"center"} flex={1}>
          <DetailViewMenuGroup value={selectedTab} onChange={(value) => setSelectedTab(value)} />
        </Flex>
        <Flex align={"center"}>
          {!isPublic && <InsightsShareableDropdown insightId={id} shareableType={"insight"} />}

          <DateDropdownMenu
            onChange={handleDateChange}
            disabled={selectedTab.activeView === "compare"}
          />
        </Flex>
      </Flex>
      {/* <Flex
        align={"center"}
        justify={"space-between"}
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          justifyContent: "space-between",

          [`@media screen and (max-width: ${theme.breakpoints.sm}px)`]: {
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px"
          }
        })}>
        <Flex
          align="center"
          justify="flex-end"
          sx={{
            display: "flex",
            alignItems: "center"
          }}>
          {!isPublic && <InsightsShareableDropdown insightId={id} shareableType={"insight"} />}

          <DateDropdownMenu
            onChange={handleDateChange}
            disabled={selectedTab.activeView === "compare"}
          />
        </Flex>
      </Flex> */}

      {selectedTab.activeView === "overview" ? (
        <OverviewTab
          id={id}
          selectedType={selectedType}
          initialSelectedFunnel={initialSelectedFunnel}
          initialSelectedMeta={initialSelectedMeta}
          dateRange={date}
          isOnboarding={isOnboarding}
        />
      ) : selectedTab.activeView === "breakdown" ? (
        <BreakdownTab
          id={id}
          selectedType={selectedType}
          selectedTab={selectedTab}
          initialSelectedFunnel={initialSelectedFunnel}
          initialSelectedMeta={initialSelectedMeta}
          dateRange={date}
          isOnboarding={isOnboarding}
        />
      ) : selectedTab.activeView === "compare" ? (
        <CompareTab
          id={id}
          selectedType={selectedType}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          initialSelectedFunnel={initialSelectedFunnel}
          initialSelectedMeta={initialSelectedMeta}
          initialDateRange={date}
          isOnboarding={isOnboarding}
        />
      ) : selectedTab.activeView === "conversionTimeDistribution" ? (
        <ConversionTimeDistribution id={id} dateRange={date} selectedType={selectedType} />
      ) : null}
    </>
  );
};

export default FunnelDetails;
