/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Autocomplete, Flex, Select } from "@mantine/core";
import { useContext, useEffect, useMemo, useState } from "react";
import { FILTER_LABELS, OPERATOR_IDENTIFIER, withIndefiniteArticle } from "./FiltersUtility";
import _debounce from "lodash.debounce";
import { stringify } from "query-string";
import axios from "axios";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { getCountryName } from "@/lib/utils/Locale";
import { toTitleCaseLabel } from "@/lib/utils/StringUtility";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";

export const FilterSearchSelect = ({
  item,
  formState,
  query,
  setQuery,
  onChange
}: {
  item: string;
  formState: any;
  query: any;
  setQuery: any;
  onChange: (item: string, value: any) => void;
}) => {
  const [searchValue, onSearchChange] = useState("");
  const { activeWorkspace } = useContext(AppLifecycleContext);

  /**
   * Function to get the auto-completion values based on the filter. Top 25 results are retrieved.
   * @param value
   */
  const fetchOptionsList = async (value: string) => {
    // Creating a deep copy of the query object
    query = JSON.parse(JSON.stringify(query));

    // Current filters from the form are added.

    const filtersList: any = [];
    Object.keys(formState).map((value) => {
      formState[value].value &&
        filtersList.push(value + ":" + formState[value].operator + formState[value].value);
    });
    // merge and remove duplicates
    const concatenatedFilters = query.filters.concat(filtersList);
    query["filters"] = concatenatedFilters.filter(function (item: any, pos: any) {
      return concatenatedFilters.indexOf(item) === pos;
    });

    // The current filter is not added to the query object, otherwise, it picks only single option.

    query.filters = query.filters.filter((filter: string) => !filter.startsWith(item));

    // Send the request.

    await new WebAnalyticsService()
      .suggestions(activeWorkspace.id, item, query, value)
      .then((res) => {
        if (res.data) {
          let options;

          // In case of country, ISO codes are returned, we translate the codes to label/value for the select menu.

          if (item === "country") {
            options = res.data.map((item: any) => {
              return {
                label: getCountryName(item),
                value: item
              };
            });
          } else if (item === "channel") {
            options = res.data.map((item: any) => {
              return {
                label: toTitleCaseLabel(item),
                value: item
              };
            });
          } else {
            // default scenario, only lists of strings are returned.
            options = res.data;
          }
          setOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
        setOptions([]);
      });
  };

  // debounce the function to avoid multiple requests.
  const debouncedFetchOptions = useMemo(() => _debounce(fetchOptionsList, 200), [formState]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    debouncedFetchOptions(searchValue);
  }, [searchValue]);

  return (
    <>
      {formState[item]?.operator === OPERATOR_IDENTIFIER.CONTAINS ||
      formState[item]?.operator === OPERATOR_IDENTIFIER.NOT_CONTAINS ? (
        <Autocomplete
          data={options}
          placeholder={`Select ${withIndefiniteArticle(FILTER_LABELS[item])}`}
          defaultValue={formState[item]?.value || ""}
          key={`filter-select-${item}`}
          id={`filter-select-${item}`}
          limit={25}
          w={"100%"}
          maxDropdownHeight={250}
          value={formState[item]?.value}
          onChange={(value: string) => {
            console.log("Autocomplete", value);
            debouncedFetchOptions(value);
            onChange(item, value);
          }}
          data-cy="filter-search-autocomplete"
        />
      ) : (
        <Select
          onDropdownOpen={() => {
            fetchOptionsList("");
          }}
          key={`filter-select-${item}`}
          id={`filter-select-${item}`}
          maxDropdownHeight={250}
          w={"100%"}
          clearable
          nothingFoundMessage="No results found in the current dashboard. Try selecting a different time range or searching for something different"
          className="w-full"
          placeholder={`Select ${withIndefiniteArticle(FILTER_LABELS[item])}`}
          searchable
          onSearchChange={onSearchChange}
          searchValue={searchValue}
          // searchValue={formState[item]?.value}
          defaultValue={formState[item]?.value || ""}
          value={formState[item]?.value}
          onChange={(value: string | any) => {
            onChange(item, value);
          }}
          data={options}
          data-cy="filter-search-select"
        />
      )}
    </>
  );
};
