import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Box,
  Button,
  CopyButton,
  Flex,
  Popover,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { InsightShareService } from "@/lib/services/InsightShareService";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { LoadingStateProps } from "types/types.d";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface IInsightsShareableDropdown {
  insightId: string;
  shareableType: "insight" | "string";
}
export const InsightsShareableDropdown = ({
  insightId,
  shareableType
}: IInsightsShareableDropdown) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [opened, setOpened] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [secret, setSecret] = useState<string | undefined>("");
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const shareURL = `${process.env.REACT_APP_WEB_APP_URL}/insights/share/${activeWorkspace.identifier}/funnel/`;

  /**
   * Handle the popover change.
   */
  const handlePopoverChange = async (opened: boolean) => {
    setOpened(opened);
    if (opened) {
      setLoading("loading");
      // Send the request to get the insight id.
      await new InsightShareService()
        .getByIdWithType(activeWorkspace.id, insightId, shareableType)
        .then((res) => {
          if (res.data) {
            setIsPublic(res.data.is_public);
            setSecret(`${res.data.sharing_secret}`);
          }
        })
        .catch((err) => {
          if (err && err?.response?.status && err.response.status === 422) {
            toast.error(err.response.data.detail);
          } else {
            toast.error("An error ocurred, please contact support.");
          }
        });
      setLoading("loaded");
    }
  };

  /**
   * Handle the switch change.
   */

  const handlePublicChange = async (value: boolean) => {
    await new InsightShareService()
      .changeVisibility(activeWorkspace.id, insightId, shareableType, value)
      .then((res) => {
        if (res.data) {
          setIsPublic(res.data.is_public);
        }
      })
      .catch((err) => {
        if (err && err?.response?.status && err.response.status === 422) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("An error ocurred, please contact support.");
        }
      });
  };

  /**
   * Handle secret reset
   */

  const handleSecretReset = async () => {
    new InsightShareService()
      .resetSecret(activeWorkspace.id, insightId, shareableType)
      .then((res) => {
        if (res.data) {
          setSecret(res.data.sharing_secret);
          toast.success("Your shareable link has been regenerated.");
        }
      })
      .catch((err) => {
        if (err && err?.response?.status && err.response.status === 422) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("An error ocurred, please contact support.");
        }
      });
  };

  useEffect(() => {
    console.log("insightId: ", insightId);
  }, []);
  return (
    <>
      <Popover
        width={"28rem"}
        position="bottom"
        withArrow
        shadow="md"
        opened={opened}
        onChange={(value) => handlePopoverChange(value)}>
        <Popover.Target>
          <Button
            size="xs"
            onClick={() => handlePopoverChange(!opened)}
            mr={"sm"}
            leftSection={
              <>
                <FontAwesomeIcon
                  icon={regular("arrow-up-from-bracket")}
                  className="!text-white"></FontAwesomeIcon>
              </>
            }>
            Share
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          {loading === "loaded" ? (
            <>
              <Title order={6} fw={600} mb="sm">
                Share this insight
              </Title>
              <Flex align={"center"} mb="sm">
                <TextInput
                  defaultValue={shareURL + secret + "?lang=en"}
                  value={shareURL + secret + "?lang=en"}
                  size="sm"
                  w={"100%"}
                  rightSection={
                    <CopyButton value={`${shareURL + secret + "?lang=en"}`}>
                      {({ copied, copy }) => (
                        <ActionIcon
                          size="xs"
                          variant="subtle"
                          color={
                            copied
                              ? "green"
                              : !copied && colorScheme === "dark"
                              ? "dark.1"
                              : "brand.5"
                          }
                          // mr={32}

                          onClick={copy}>
                          <>
                            <FontAwesomeIcon icon={regular("clipboard")} />
                          </>
                        </ActionIcon>
                      )}
                    </CopyButton>
                  }
                />
                <Tooltip label="Reset sharable link">
                  <ActionIcon
                    onClick={handleSecretReset}
                    ml={"sm"}
                    variant="light"
                    color={colorScheme === "dark" ? "dark.1" : "brand.5"}>
                    <FontAwesomeIcon icon={regular("arrows-rotate")} />
                  </ActionIcon>
                </Tooltip>
              </Flex>
              <Title order={6} fw={600} mb="sm">
                Permissions{" "}
              </Title>
              <Switch
                checked={isPublic}
                onChange={() => handlePublicChange(!isPublic)}
                size="xs"
                className="mb-2"
                label="Anyone with the link can view this insight"></Switch>
            </>
          ) : (
            <div className="flex justify-center items-center">
              <Oval color={LOADER_COLOR} height={12} width={12} />
            </div>
          )}
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
