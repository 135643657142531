// import { faCaretDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useDetectClickOutside } from "react-detect-click-outside"
import { FilterItem } from "./FilterItem"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"

export const FilterViewMoreDropdown = ({ query, setQuery }: { query: any; setQuery: any }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const closeDropdown = () => {
    setIsDropdownVisible(false)
  }

  const ref = useDetectClickOutside({ onTriggered: closeDropdown })
  return (
    <>
      <div
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
        ref={ref}
        className="relative bg-white transition-all ease-in-out cursor-pointer flex items-center rounded-md border border-solid border-gray-200 px-3 py-2 shadow-xs mr-2"
      >
        <span
          className={`hover:text-purple-500 font-medium transition-all ease-in-out ${
            isDropdownVisible ? "text-purple-500" : ""
          }`}
        >
          View {query.filters.length - 2} more filter
          {query.filters.length - 2 > 1 && <>s</>}
        </span>
        <FontAwesomeIcon
          className={`ml-2 hover:text-purple-500 transition-all ease-in-out ${
            isDropdownVisible ? "transform -rotate-180 text-purple-500" : ""
          }`}
          icon={solid("caret-down")}
        ></FontAwesomeIcon>
        {isDropdownVisible && (
          <>
            <div className="absolute z-50 w-96 bg-white rounded border border-solid border-gray-200 top-12 left-0">
              {query.filters.slice(2).map((item: string, index: number) => (
                <FilterItem query={query} setQuery={setQuery} item={item} index={index} />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}
