import axios from './JWTService';

export class InsightShareService {
  private prefixURL = '/v1/insight_share';
  getByIdWithType = (ws_id: any, insightId: string, type: 'insight' | string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this.prefixURL}/${ws_id}/insight/${insightId}/${type}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  resetSecret = (ws_id: any, insightId: string, type: 'insight' | string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this.prefixURL}/${ws_id}/reset_secret/${insightId}/${type}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  changeVisibility = (
    ws_id: any,
    insightId: string,
    type: 'insight' | string,
    isPublic: boolean
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this.prefixURL}/${ws_id}/change_visibility/${insightId}/${type}`, {
          is_public: isPublic
        })
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  getBySecret = (ws_id: any, secret: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this.prefixURL}/${ws_id}/shareable_secret/${secret}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
