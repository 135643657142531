import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Input,
  Popover,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import orderBy from "lodash/orderBy";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import { WorkspaceService } from "../../../../lib/services/WorkspaceService";
import { getWorkspaceAvatarURL } from "@/lib/utils/WorkspaceUtility";
import styles from "./WorkspacesDropdown.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface WorkspaceObject {
  id: string;
  name: string;
  identifier: string;
  color: string;
  members: Array<any>;
  is_starred?: boolean;
  website?: string;
  logo?: string | null;
}

export interface IWorkspacesDropdown {
  activeWorkspace: WorkspaceObject;
  workspaces: Array<WorkspaceObject>;
  onSelectWorkspace: (workspaceItem: any | undefined) => any;
  onCreateWorkspace: () => void;
  compact?: boolean;

  /**
   * In case of compact mode, if background is required or not.
   */
  withBackground?: boolean;
}

const WorkspacesDropdown = ({
  activeWorkspace,
  workspaces,
  onSelectWorkspace,
  onCreateWorkspace,
  compact = true,
  withBackground = false
}: IWorkspacesDropdown) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { user, setWorkspaces } = useContext(AppLifecycleContext);

  const [wsList, setWsList] = useState<Array<WorkspaceObject>>(workspaces);
  const [query, setQuery] = useState("");

  const navigate = useNavigate();
  const [opened, { close, open }] = useDisclosure(false);

  const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null);
  const [control, setControl] = useState<HTMLButtonElement | HTMLDivElement | null>(null);

  useClickOutside(() => close(), null, [control, dropdown]);

  useEffect(() => {
    setWsList(workspaces);
  }, [workspaces]);

  const filteredWorkspacesList = () => {
    let wsItems;

    // filter results if query entered
    if (query.length > 0) {
      wsItems = wsList.filter((workspace) =>
        workspace.name.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      wsItems = wsList;
    }

    // Check if the member has starred the workspace or not.
    wsItems.map((workspace: WorkspaceObject, index) => {
      const member = workspace?.members.find((member) => member.user_id === user.id);
      workspace.is_starred = member?.is_starred_workspace || false;
      console.log(workspace);
      return workspace;
    });

    return orderBy(wsItems, ["is_starred", "name"], ["desc", "asc"]);
  };

  const handleStarredStatus = (workspaceId: string, status: boolean) => {
    let wsList = workspaces.map((workspace, index) => {
      if (workspace.id === workspaceId) {
        workspace.is_starred = status;
        workspace.members.map((member, index) => {
          if (member.user_id === user.id) {
            member.is_starred_workspace = status;
          }
          return member;
        });
      }
      return workspace;
    });
    setWsList(wsList);
  };

  const starredWorkspace = (workspaceId: string) => {
    new WorkspaceService()
      .starred(workspaceId)
      .then(() => {
        handleStarredStatus(workspaceId, true);
      })
      .catch((err) => err);
  };

  const unStarredWorkspace = (workspaceId: string) => {
    new WorkspaceService()
      .unstarred(workspaceId)
      .then(() => {
        handleStarredStatus(workspaceId, false);
      })
      .catch((err) => err);
  };

  return (
    <>
      {" "}
      {activeWorkspace.hasOwnProperty("name") && workspaces.length > 0 && (
        <Popover
          width={250}
          position="bottom-start"
          shadow="md"
          styles={{
            dropdown: {
              background: colorScheme === "dark" ? theme.colors.dark[6] : "#1b1a40",
              border: "1px solid",
              borderColor: colorScheme === "dark" ? theme.colors.dark[4] : "#2a346c",
              color: "#fff",
              padding: "0"
            }
          }}
          offset={compact ? 16 : 8}
          opened={opened}
          onClose={() => {
            setQuery("");
            close();
          }}>
          <Popover.Target>
            {compact ? (
              <ActionIcon
                ref={setControl}
                variant={"transparent"}
                onClick={() => {
                  if (!opened) {
                    open();
                  } else {
                    close();
                  }
                }}
                style={
                  withBackground
                    ? {
                        borderRadius: theme.radius.sm,
                        width: "100%",
                        cursor: "pointer",
                        height: "auto",
                        minHeight: "auto"
                      }
                    : {}
                }>
                {withBackground ? (
                  <Box
                    style={(theme) => ({
                      borderRadius: theme.radius.sm,
                      background: colorScheme === "dark" ? theme.colors.dark[6] : "#1b1a40",
                      padding: "9px 14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    })}>
                    <Avatar
                      variant="outline"
                      size={24}
                      color="red"
                      radius="xl"
                      bg={activeWorkspace?.color}
                      src={getWorkspaceAvatarURL(activeWorkspace, null, theme.colorScheme)}
                      styles={{
                        placeholder: {
                          backgroundColor: "transparent",
                          color: "#fff"
                        }
                      }}>
                      {activeWorkspace.name.substring(0, 1)}
                    </Avatar>
                  </Box>
                ) : (
                  <Avatar
                    size={28}
                    variant="outline"
                    color="red"
                    radius="xl"
                    className={activeWorkspace?.color}
                    styles={{
                      placeholder: {
                        backgroundColor: "transparent",
                        color: "#fff"
                      }
                    }}>
                    {activeWorkspace.name.substring(0, 1)}
                  </Avatar>
                )}
              </ActionIcon>
            ) : (
              <Box
                ref={setControl}
                onClick={() => {
                  if (!opened) {
                    open();
                  } else {
                    close();
                  }
                }}
                style={{
                  background: colorScheme === "dark" ? theme.colors.dark[6] : "#1b1a40",
                  borderRadius: theme.radius.sm,
                  margin: "0 20px",
                  width: "100%",
                  cursor: "pointer"
                }}>
                <Flex justify={"space-between"} align={"center"} px={14} py={10}>
                  <Flex align={"center"} justify={"flex-start"} gap={8} flex={1} miw={0}>
                    <Avatar
                      variant="outline"
                      size={22}
                      bg={activeWorkspace?.color}
                      radius="xl"
                      src={getWorkspaceAvatarURL(activeWorkspace, null, theme.colorScheme)}
                      // className={activeWorkspace?.color}
                      styles={{
                        placeholder: {
                          backgroundColor: "transparent",
                          color: "#fff"
                        }
                      }}>
                      {activeWorkspace.name.substring(0, 1)}
                    </Avatar>

                    <Text lineClamp={1} fz={14}>
                      {activeWorkspace.name}
                    </Text>
                  </Flex>
                  <span>
                    <FontAwesomeIcon icon={regular("chevron-down")} />
                  </span>
                </Flex>
              </Box>
            )}
          </Popover.Target>
          <Popover.Dropdown>
            <Box
              ref={setDropdown}
              className={
                colorScheme === "dark"
                  ? `${styles.workspaceDropdown} ${styles.workspaceDropdownDark}`
                  : `${styles.workspaceDropdown} ${styles.workspaceDropdownLight}`
              }>
              <Box className={styles.inputContainer} mb="xs">
                <Box
                  style={{
                    maxWidth: "150px"
                  }}>
                  <Input
                    unstyled
                    placeholder="Filter Workspaces"
                    autoFocus
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </Box>

                <Button
                  className={styles.buttonStyle}
                  variant="subtle"
                  size="compact-xs"
                  leftSection={<FontAwesomeIcon icon={regular("plus")} />}
                  onClick={() => {
                    onCreateWorkspace();
                    close();
                  }}>
                  New
                </Button>
              </Box>
              {/* <Divider color={colorScheme === "dark" ? theme.colors.dark[5] : "#2a346c"} /> */}
              {filteredWorkspacesList().length === 0 && (
                <>
                  <Text size="xs" c={"white"} p={"sm"} ta="center">
                    <>No workspaces for your query.</>
                  </Text>
                </>
              )}
              <Box
                className={"exclude-box"}
                sx={{
                  maxHeight: "400px",
                  overflowY: "auto",

                  "& > div": {
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderBottom: "1px solid",
                    borderBottomColor: colorScheme === "dark" ? theme.colors.dark[5] : "#2a346c",

                    "&:last-child": {
                      borderBottom: "none"
                    }
                  }
                }}>
                {filteredWorkspacesList().map((workspace) => (
                  <Flex
                    h={40}
                    align={"center"}
                    justify={"space-between"}
                    key={workspace.id}
                    onClick={() => {
                      setQuery("");
                      onSelectWorkspace(workspace);
                      close();
                    }}
                    sx={{
                      gap: "6px",
                      cursor: "pointer",

                      "&:hover": {
                        backgroundColor: colorScheme === "dark" ? theme.colors.dark[5] : "#161538"
                      },

                      ".starred-btn": {
                        display: "none"
                      },

                      "&:hover .starred-btn": {
                        display: "block"
                      }
                    }}>
                    <Flex align="center" gap={8}>
                      <Avatar
                        size={22}
                        bg={workspace?.color}
                        variant="outline"
                        radius="xl"
                        // className={workspace?.color}
                        src={getWorkspaceAvatarURL(workspace, null, theme.colorScheme)}
                        styles={{
                          placeholder: {
                            backgroundColor: "transparent",
                            color: "#fff"
                          }
                        }}>
                        {workspace.name.substring(0, 1)}
                      </Avatar>

                      <Text lineClamp={1} fz={13}>
                        {workspace.name}
                      </Text>
                    </Flex>

                    <Group gap={"xs"}>
                      {workspace.is_starred === true ? (
                        <Box
                          component="span"
                          fz="yellow"
                          onClick={(e) => {
                            e.stopPropagation();
                            unStarredWorkspace(workspace.id);
                          }}>
                          <Tooltip fz={"xs"} label="Unfavorite">
                            <FontAwesomeIcon
                              size="xs"
                              icon={regular("star")}
                              color={"orange"}></FontAwesomeIcon>
                          </Tooltip>
                        </Box>
                      ) : (
                        <Box
                          component="span"
                          color="yellow"
                          className={"starred-btn"}
                          onClick={(e) => {
                            e.stopPropagation();
                            starredWorkspace(workspace.id);
                          }}>
                          <Tooltip fz="xs" label={"Favorite"}>
                            <FontAwesomeIcon size="xs" icon={regular("star")}></FontAwesomeIcon>
                          </Tooltip>
                        </Box>
                      )}

                      {workspace.id === activeWorkspace.id && (
                        <Box component="span">
                          <FontAwesomeIcon
                            size="xs"
                            className=""
                            icon={regular("check")}></FontAwesomeIcon>
                        </Box>
                      )}
                    </Group>
                  </Flex>
                ))}
              </Box>
              <Box>
                <UnstyledButton
                  style={{
                    color: "#BA90F9",
                    textAlign: "center",
                    fontSize: "13px",
                    width: "100%"
                  }}
                  onClick={() => {
                    navigate("/workspaces");
                    close();
                  }}>
                  <FontAwesomeIcon icon={regular("gear")} className="mr-2" />
                  View all workspaces
                </UnstyledButton>
              </Box>
            </Box>
          </Popover.Dropdown>
        </Popover>
      )}
    </>
  );
};

export default WorkspacesDropdown;
