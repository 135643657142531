// @ts-ignore
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@mantine/core"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation"

export const ProductInsightUpgrade = () => {
  const { activeWorkspace } = useContext(AppLifecycleContext)
  const navigate = useNavigate()
  useEffect(() => {
    document.title = "Upgrade to Premium | Usermaven"
  }, [])
  return (
    <>
      <div className="um-mc">
        {/* <HeaderNavigation is_insights_dashboard={false} title="Upgrade"></HeaderNavigation> */}
        <div className="pt-12 flex justify-center flex-col items-center">
          <h2 className="text-2xl pb-12">Upgrade to the Premium plan to access product insights</h2>
          <div className="py-4">
            <div
              className="wistia_embed wistia_async_qtwpj64nv6 seo=false"
              style={{
                height: "450px",
                width: "800px",
                position: "relative",
              }}
            >
              <div
                className="wistia_swatch"
                style={{
                  height: "100%",
                  left: 0,
                  opacity: 0,
                  overflow: "hidden",
                  position: "absolute",
                  top: 0,
                  transition: "opacity 200ms",
                  width: "100%",
                }}
              >
                <img
                  src="https://fast.wistia.com/embed/medias/qtwpj64nv6/swatch"
                  style={{
                    filter: "blur(5px)",
                    height: "100%",
                    objectFit: "contain",
                    width: "100%",
                  }}
                  alt=""
                  aria-hidden="true"
                  // onLoad={this.parentNode.style.opacity=1}
                />
              </div>
            </div>
            <div className="pt-12 mx-auto justify-center flex">
              <Button
                size="md"
                onClick={() => {
                  navigate(`/env/${activeWorkspace.identifier}/account/billing`)
                }}
              >
                Get Started
              </Button>
            </div>
          </div>
        </div>
        <div className="max-w-6xl mx-auto pb-12 pt-8">
          <h1 className="text-center pb-16">Reasons why you should upgrade to the Premium plan</h1>
          <div className="grid grid-cols-3 gap-8">
            <div>
              <FontAwesomeIcon
                className="text-3xl text-gray-900 mb-2"
                icon={regular("file-chart-column")}
              ></FontAwesomeIcon>
              <h2 className="mb-2 font-bold ">Get the insights you need to accelerate growth</h2>
              <p>
                Which acquisition channels are performing best, where users are dropping-off during
                onboarding or which features are getting the most customer engagement - you'll know
                it all.
              </p>
            </div>
            <div>
              <FontAwesomeIcon
                className="text-3xl text-gray-900 mb-2"
                icon={regular("circle-user")}
              ></FontAwesomeIcon>
              <h2 className="mb-2 font-bold ">
                Understand the impact of your efforts across the customer journey
              </h2>
              <p>
                Unlock a single customer view by having user and company-level information in one
                place. Get insight into their actions and traits using audience, attribution, and
                behavior reporting.
              </p>
            </div>
            <div>
              <FontAwesomeIcon
                className="text-3xl text-gray-900 mb-2"
                icon={regular("chart-line-up")}
              ></FontAwesomeIcon>
              <h2 className="mb-2 font-bold ">Built for SaaS companies to grow product-led</h2>
              <p>
                Most analytics solutions are built for all types of businesses. Hence they become
                complex and tracking company-level insights is almost impossible. Usermaven was
                purpose-built for SaaS companies by founders who have vast experience in growing
                their own SaaS businesses.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
