import { Flex, Group, Stack, Text, useMantineTheme } from "@mantine/core";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const ChatMessage = ({ messages, isUser }: {
  messages: {
    text: string;
    showIndicator?: boolean
  }[];
  isUser: boolean,
}) => {
  const theme = useGlobalMantineTheme()

  return (
    <Flex
      align={"center"}
      justify={isUser ? "flex-end" : "flex-start"}
      sx={{
        marginBottom: "16px"
      }}
    >
      <Stack
        sx={{
          background: isUser ? "#464DEB" : theme.colorScheme === "dark" ? "#1E1E1E" : "#FFFFFF",
          color: isUser ? "#FFFFFF" : theme.colorScheme === "dark" ? "#C1C2C5" : "#01002a",
          borderRadius: "8px",
          padding: "16px",
          boxShadow: "0px 4px 4px 0px #0000001A",
          maxWidth: "60%",
          textAlign: "left"
        }}
      >
        {
          messages.map((message, index) => (
            <Group key={index} gap={4}>
              {
                message.showIndicator && <TypingIndicator />
              }

              <Text
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  whiteSpace: "pre-line",
                  wordBreak: "break-word"
                }}
              >
                {message.text}
              </Text>
            </Group>
          ))
        }
      </Stack>
    </Flex>
  );
};

const TypingIndicator = () => {
  return (
    <svg
      style={{
        width: '1.25rem',
        height: '1.25rem'
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <rect
        className="opacity-animation"
        x={6}
        y={2}
        rx={2}
        ry={2}
        width={12}
        height={20}
        fill="currentColor"
      />
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n          @keyframes blink {\n            0%, 100% { opacity: 1; }\n            50% { opacity: 0; }\n          }\n          .opacity-animation {\n            animation: blink 1s linear infinite;\n          }\n        "
        }}
      />
    </svg>

  );
};


export default ChatMessage;