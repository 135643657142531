import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { format } from "date-fns";
import { Fragment, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { getRelativeDateToNow } from "@/lib/utils/DateUtility";
import { stringToInitials } from "@/lib/utils/StringUtility";
import { UserDetail } from "@/stores/useFunnelInsightsStore";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import { getSeedColor } from "@/style/ColorSeeding";
import { getRandomColor } from "@/style/Colors";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Paper,
  Text,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { IReportType } from "../../../../../types/types.d";
import classes from "./FunnelUsersListCard.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
interface IFunnelUsersListCard {
  title?: string;
  onFullListClick?: () => void;
  users: UserDetail[];
  reportType: IReportType;
  type?: "completed" | "dropped";
  index?: number;
}

export const FunnelUsersListCard = ({
  title,
  onFullListClick = () => {},
  users,
  reportType,
  type = "completed",
  index
}: IFunnelUsersListCard) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const theme = useGlobalMantineTheme();
  const navigate = useNavigate();

  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);

  const colors = useMemo(() => {
    return Array.from(Array(15).keys()).map((i) => getRandomColor());
  }, []);

  return (
    <Paper
      p={15}
      withBorder
      my={"sm"}
      style={{
        flexShrink: 0,
        minHeight: "100%",

        width: "100%",
        maxWidth: "420px"
      }}>
      <Flex justify={"center"} align={"center"} mb={8}>
        <Text fz="sm" fw={500} lineClamp={1} mb="sm">
          {title}
        </Text>
      </Flex>

      {users.length === 0 ? (
        <Box
          component="div"
          style={{
            flex: 1
          }}>
          <Flex align={"center"} justify={"center"} py={16}>
            <NoResults
              className={"gap-3.5 w-[60%] text-center"}
              heading={
                type === "completed"
                  ? `No completed ${reportType}(s)`
                  : `No dropped off ${reportType}(s)`
              }
              // text={`There are no ${reportType}(s) in this step.`}
              text={
                type === "dropped"
                  ? `There are no ${reportType}(s) who have dropped off in this step.`
                  : `There are no ${reportType}(s) who have completed this step.`
              }
            />
          </Flex>
        </Box>
      ) : (
        <Box mt={"md"}>
          <Flex justify={"space-between"} direction={"column"}>
            <>
              <Group justify="space-between" grow mb={8}>
                <Flex align={"center"} w={"100%"}>
                  <Box w={"50%"}>
                    <Text fw={500} className="capitalize">
                      {reportType == "user" ? "User" : "Company"}
                    </Text>
                  </Box>
                  <Box w={"25%"}>First Seen</Box>
                  <Box w={"25%"}>Last Seen</Box>
                </Flex>
              </Group>
              <Divider />
            </>
          </Flex>

          {users.map((user, index) => (
            <Fragment key={index}>
              <Group justify="space-between" grow my={8}>
                <Flex align={"center"} w={"100%"}>
                  <Box
                    onClick={() => {
                      setOverviewModalOpen(true, reportType, user.id);
                    }}
                    className={classes.row}
                    w={"50%"}>
                    <Flex align={"center"}>
                      <Avatar
                        td="none"
                        size={20}
                        variant={"filled"}
                        radius={"xl"}
                        mr={8}
                        component="div"
                        styles={{
                          placeholder: {
                            backgroundColor: getSeedColor(user.name)
                          }
                        }}
                        className={`uppercase `}>
                        {stringToInitials(user.name)}
                      </Avatar>
                      <Text
                        tt="capitalize"
                        pr={8}
                        lineClamp={1}
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "100%"
                        }}>
                        {user.name}
                      </Text>
                    </Flex>
                  </Box>

                  <Box w={"25%"}>
                    <Tooltip
                      style={{ fontSize: "10px" }}
                      label={format(new Date(user.first_seen), "dd MMM yyyy hh:mm")}
                      position="right"
                      withArrow>
                      <Text fz="xs" c="gray.6">
                        {getRelativeDateToNow(user.first_seen).replace("about", "")}
                      </Text>
                    </Tooltip>
                  </Box>
                  <Box w={"25%"}>
                    <Tooltip
                      style={{ fontSize: "10px" }}
                      label={format(new Date(user.last_seen), "dd MMM yyyy hh:mm").replace(
                        "about",
                        ""
                      )}
                      position="right"
                      withArrow>
                      <Text fz="xs" c="gray.6">
                        {getRelativeDateToNow(user.last_seen).replace("about", "")}
                      </Text>
                    </Tooltip>
                  </Box>
                </Flex>
              </Group>
              <Divider />
            </Fragment>
          ))}
        </Box>
      )}

      <Flex justify={"center"} align={"center"} mt={16}>
        <Button
          size="xs"
          variant={"light"}
          onClick={() => onFullListClick()}
          disabled={users.length === 0}>
          View full list
        </Button>
      </Flex>
    </Paper>
  );
};
