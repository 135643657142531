import axios from './JWTService';
export class ActiveUsersTrendsService {
  private readonly _endpoint;
  constructor() {
    this._endpoint = '/v1/insights_active_users';
  }
  activeUsersCount = (
    ws_id: any,
    insight_id: any,
    type: 'daily' | 'weekly' | 'monthly',
    report_type: 'user' | 'company',
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audience_group_conditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${ws_id}/${insight_id}/${type}/${report_type}`, {
          audience_conditions: audienceConditions,
          audience_condition_type: audienceConditionType,
          audience_group_conditions: audience_group_conditions
        })
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  activeUsersList = (
    ws_id: any,
    insight_id: any,
    type: 'daily' | 'weekly' | 'monthly',
    report_type: 'user' | 'company',
    export_type: string = 'list',
    page: number = 1,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/list/${type}/${report_type}?export_type=${export_type}&page=${page}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  activeUsersHistogram = (
    ws_id: any,
    insight_id: any,
    type: 'daily' | 'weekly' | 'monthly',
    report_type: 'user' | 'company',
    startDate: string,
    endDate: string,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/histogram/${type}/${report_type}?start_date=${startDate}&end_date=${endDate}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  activeUsersHistogramRatio = (
    ws_id: any,
    insight_id: any,
    type: 'daily' | 'weekly' | 'monthly',
    report_type: 'user' | 'company',
    startDate: string,
    endDate: string,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/histogram_ratio/${type}/${report_type}?start_date=${startDate}&end_date=${endDate}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  userStickinessAvg = (
    ws_id: any,
    insight_id: any,
    type: 'dau_mau_avg' | 'dau_wau_avg' | 'wau_mau_avg',
    report_type: 'user' | 'company',
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${ws_id}/${insight_id}/user_stickiness/${type}/${report_type}`, {
          audience_conditions: audienceConditions,
          audience_condition_type: audienceConditionType,
          audience_group_conditions: audienceGroupConditions
        })
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
