/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Input,
  NumberInput,
  TextInput,
  Text,
  Flex,
  Select,
  Button,
} from "@mantine/core";
import useInsightHook from "@/hooks/useInsightHook";
import { InsightsSetupEvents } from "@/ui/components/App/InsightsSetupEvents/InsightsSetupEvents";

import "./setup.scss";
import useFeatureAdoptionStore, { Feature } from "@/stores/useFeatureAdoptionStore";
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import {  useEffect, useState } from "react";
import { ConditionItem } from "@/types/types.d";

interface FeatureSetupProps {
  moduleId: string;
  moduleName: string;
  features: Array<Feature>;
  nameIdentifier?: string;
  title: string;
  description: string;
  minimumConditions?: number;
  maximumConditions?: number;
  conditionType?: "feature_adoption";
  stepNo?: number;
  conditionButtonTitle?: string;
  allowVisitorsStep?: boolean;
  isVisitorsStepEnabled?: boolean;
  setIsVisitorsStepEnabled?: (e: any) => void;
}

export const OnboardingFeatureSetup: React.FC<FeatureSetupProps> = ({
  moduleId,
  moduleName,
  features,
  nameIdentifier = "condition",
  conditionType = "feature_adoption",
  title = "Your Headline",
  description = "Your description...",
  minimumConditions = 1,
  maximumConditions = 100
}) => {
  const [updateFeature, updateFeaturesInModule, removeFeatureFromModule] =
    useFeatureAdoptionStore((state) => [
      state.updateFeature,
      state.updateFeaturesInModule,
      state.removeFeatureFromModule
    ]);
  const { insightEvents, insightAttributes, insightPinnedEvents } = useInsightHook();
  const setConditions = (featureId: string, conditions: ConditionItem[]) => {
    updateFeature(moduleId, featureId, {
      conditions: { feature_events_conditions: conditions }
    });
  };

  const [isFeatureAddedLoader, setIsFeatureAddedLoader] = useState("idle");

  const deleteFeature = (feature: Feature) => {
    removeFeatureFromModule(moduleId, feature.id);
  };

  const validateFeatureAttributes = (feature: Feature) => {
    let conditions = feature.conditions.feature_events_conditions;

    if (
      feature.name !== "" &&
      conditions.length > 0 &&
      conditions.every(
        (obj: { key: string; label: string }) => obj.key !== "" && obj.label !== ""
      ) &&
      feature.adoption_rate != null &&
      feature.retention_period != null
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    updateFeaturesInModule(moduleId, features);
  }, [features]);

  const handleFeatureSave = async (feature: Feature) => {
    // Update feature with actual data on Save/Update button click
    setIsFeatureAddedLoader("loading");
    const updatedFeature = {
    name: feature.name,
    conditions: feature.conditions,
    adoption_rate: feature.adoption_rate,
    retention_period: feature.retention_period,
    is_saved: true,
    is_new: false,
    is_active: false
    };
    updateFeature(moduleId, feature.id, updatedFeature);
    setIsFeatureAddedLoader("loaded");
  };

  return (
    <>
      {features.length > 0 && (
        <Box>
          {features.map((feature, index) => (
            <Box
              my={20}
              p={7}
              key={index}
              sx={(theme) => {
                return {
                  // marginBottom: "20px",
                  borderRadius: "8px",
                  backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : "#ffff",
                  borderColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.darkBorderColor[0]
                      : theme.colors.lightBorderColor[5]
                };
              }}>
              <Box m={5}>
                {!feature.is_active ? (
                  <Flex justify={"space-between"}>
                    <Flex justify={"flex-start"} align={"center"}>
                      <FontAwesomeIcon
                        icon={solid("angle-right")}
                        className={`mx-2 transform  text-gray-800 transition-all duration-200 ease-in-out ${
                          feature.is_active ? "rotate-90" : ""
                        }`}
                        onClick={() => {
                          updateFeature(moduleId, feature.id, {
                            is_active: !feature.is_active
                          });
                        }}
                      />
                      <Text fz="sm" ml={5}>
                        {toTitleCase(feature.name)}
                      </Text>
                    </Flex>
                    <Flex justify={"flex-end"}>
                      <Avatar
                        variant="outline"
                        size={"sm"}
                        radius="xl"
                        color={feature.is_saved ? "green.6" : "gray.4"}
                        styles={{
                          placeholder: {
                            borderWidth: "2px"
                          }
                        }}>
                        <FontAwesomeIcon icon={solid("check")} />
                      </Avatar>
                      <Avatar
                        variant="outline"
                        ml={5}
                        mr={5}
                        size={"sm"}
                        radius="xl"
                        color={"red.4"}
                        onClick={() => deleteFeature(feature)}
                        styles={{
                          placeholder: {
                            borderWidth: "2px"
                          }
                        }}>
                        <FontAwesomeIcon icon={solid("xmark")} />
                      </Avatar>
                    </Flex>
                  </Flex>
                ) : (
                  <Box m={5} style={{ transition: "all 200ms ease-in-out" }}>
                    <Flex justify={"space-between"}>
                      <Flex justify={"flex-start"} align={"flex-start"}>
                        <FontAwesomeIcon
                          icon={solid("angle-right")}
                          className={`mt-2 ml-1 mr-2 transform  text-gray-800 transition-all duration-200 ease-in-out ${
                            feature.is_active ? "rotate-90" : ""
                          }`}
                          onClick={() => {
                            updateFeature(moduleId, feature.id, {
                              is_active: !feature.is_active
                            });
                          }}
                        />
                        <Input.Wrapper mt={3} size="sm" id={"featureName"} label="Feature Name">
                          <TextInput
                            sx={(theme) => ({
                              input: {
                                borderBottom: "1px dashed",
                                borderColor:
                                  theme.colorScheme === "dark"
                                    ? theme.colors.dark[3]
                                    : theme.colors.gray[5],
                                "&:focus-within": {
                                  borderColor: theme.colors.brand[5]
                                }
                              }
                            })}
                            defaultValue={toTitleCase(feature.name)}
                            onChange={(e) =>
                              updateFeature(moduleId, feature.id, { name: e.target.value })
                            }
                            className="mt-2"
                            variant="unstyled"
                            placeholder="e.g Compose Email"></TextInput>
                        </Input.Wrapper>
                      </Flex>
                      <Flex justify={"flex-end"}>
                        <Avatar
                          variant="outline"
                          size={"sm"}
                          radius="xl"
                          color={feature.is_saved ? "green.6" : "gray.4"}
                          styles={{
                            placeholder: {
                              borderWidth: "2px"
                            }
                          }}>
                          <FontAwesomeIcon icon={solid("check")} />
                        </Avatar>
                        <Avatar
                          variant="outline"
                          ml={5}
                          size={"sm"}
                          radius="xl"
                          color={"red.4"}
                          onClick={() => deleteFeature(feature)}
                          styles={{
                            placeholder: {
                              borderWidth: "2px"
                            }
                          }}>
                          <FontAwesomeIcon icon={solid("xmark")} />
                        </Avatar>
                      </Flex>
                    </Flex>
                    <Box ml={12}>
                      <Box mt={20} py={20}>
                        <h2>{title}</h2>
                        <p>{description}</p>
                        <Box mt={2}>
                          <InsightsSetupEvents
                            options={[
                              {
                                label: "Page URL",
                                value: "pageview"
                              },
                              { label: "Custom Event", value: "event" },
                              { label: "Pinned Event", value: "pinned_event" }
                            ]}
                            conditionType={conditionType}
                            conditions={feature.conditions.feature_events_conditions}
                            setConditions={setConditions}
                            attributesOptions={insightAttributes}
                            pinnedEventOptions={insightPinnedEvents}
                            eventsOptions={insightEvents}
                            nameIdentifier={nameIdentifier}
                            featureId={feature.id}
                            featureName={feature.name}
                            usedIn={"feature_adoption"}
                          />
                        </Box>
                      </Box>
                      <Box mt={4}>
                        <Text fw={600} fz="sm">
                          Feature evaluation criteria
                        </Text>
                        <Flex gap={"xs"} mt={4}>
                          <Text
                            ml={"xs"}
                            sx={{
                              strong: {
                                fontWeight: 500
                              },

                              i: {
                                fontStyle: "italic"
                              }
                            }}>
                            <strong>Adoption</strong> - A user is considered{" "}
                            <u>
                              <i>adopted</i>
                            </u>{" "}
                            if the number of events are greater than
                          </Text>
                          <NumberInput
                            styles={{ input: { minHeight: "5px", height: "22px" } }}
                            max={120}
                            min={0}
                            w={100}
                            size={"xs"}
                            variant="default"
                            onChange={(val) =>
                              updateFeature(moduleId, feature.id, { adoption_rate: val })
                            }
                            value={feature.adoption_rate || 2}
                            defaultValue={feature.adoption_rate || 2}
                          />
                        </Flex>
                        <Flex gap={"xs"} mt={4}>
                          <Text
                            ml={"xs"}
                            sx={{
                              strong: {
                                fontWeight: 500
                              },

                              i: {
                                fontStyle: "italic"
                              }
                            }}>
                            <strong>Retention</strong> - A user is considered{" "}
                            <u>
                              <i>retained</i>
                            </u>{" "}
                            if they use the feature on a
                          </Text>
                          <Select
                            styles={{
                              input: { minHeight: "5px", height: "22px" },
                              item: { minHeight: "5px", height: "25px" }
                            }}
                            size="xs"
                            w={100}
                            defaultValue={feature.retention_period || "bi-weekly"}
                            data={[
                              {
                                label: "Weekly",
                                value: "weekly"
                              },
                              {
                                label: "Bi-weekly",
                                value: "bi-weekly"
                              },
                              {
                                label: "Monthly",
                                value: "monthly"
                              }
                            ]}
                            onChange={(val) =>
                              updateFeature(moduleId, feature.id, { retention_period: val })
                            }
                          />
                          basis
                        </Flex>
                      </Box>
                    </Box>
                    <Flex justify={"flex-end"}>
                      <Button
                        variant="outline"
                        onClick={() => {
                          handleFeatureSave(feature);
                        }}
                        loading={isFeatureAddedLoader === "loading"}
                        disabled={
                          isFeatureAddedLoader === "loading" || !validateFeatureAttributes(feature)
                        }>
                        {feature.is_saved ? "Update" : "Save"}
                      </Button>
                    </Flex>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};
