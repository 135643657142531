import { IconDefinition, IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Text } from "@mantine/core"
import { faClock, faArrowPointer, faEye, faUsers } from "@fortawesome/pro-solid-svg-icons"

interface IconCardProps {
  icon: IconName
  title: string
  value: string | number
  bg?: string
  iconColor?: string
  border?: boolean
}

export const IconCard = ({
  icon = "clock",
  title = "",
  value = "",
  bg = "bg-purple-100",
  iconColor = "text-purple-500",
  border = true,
}: IconCardProps) => {
  const myIcons = {
    clock: faClock,
    eye: faEye,
    users: faUsers,
    "arrow-pointer": faArrowPointer,
  }

  // @ts-ignore
  const currentIcon: IconDefinition = myIcons[icon]

  return (
    <>
      <div className={`flex items-center pl-2 w-full`}>
        <div className="mr-3">
          <span className={`h-10 w-10 rounded-full flex justify-center items-center ${bg}`}>
            {currentIcon && (
              <FontAwesomeIcon
                className={`text-lg ${iconColor}`}
                icon={currentIcon}
              ></FontAwesomeIcon>
            )}
          </span>
        </div>
        <div>
          <Text c="dimmed" className="uppercase text-sm mb-2 font-medium " fz="xs">
            {title}
          </Text>
          <p
            title={value.toString()}
            className={`font-medium break-all line-clamp-1 pr-2 ${
              value === "Coming soon" ? "opacity-50" : ""
            }`}
          >
            {value}
          </p>
        </div>
      </div>
    </>
  )
}
