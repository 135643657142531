// import { Prism } from "@mantine/prism";
// import githubTheme from "prism-react-renderer/themes/github";
// import duotoneDark from "prism-react-renderer/themes/dracula";
import { CodeHighlight, CodeHighlightTabs } from "@mantine/code-highlight";
import {
  Accordion,
  Alert,
  Anchor,
  Avatar,
  Box,
  Code,
  Flex,
  SegmentedControl,
  Space,
  Switch,
  Text,
  Title,
  Tooltip
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { PLAN_PRODUCT_TYPE } from "@/lib/utils/Constants";
import {
  GTMTrackingPixelSnippet,
  identifyPixelSnippet,
  trackingEventsSnippet,
  trackingPixelSnippet
} from "@/lib/utils/TrackingPixelUtility";
import { FC, useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { toast } from "react-toastify";
import { showNotification } from "@mantine/notifications";
import { useTrackingPixelStore } from "@/stores/useTrackingPixelStore";
import { TrackingPixelCustomizations } from "./TrackingPixelCustomizations";

type Props = {
  workspaceId: string;
  integrationType: string | null;
  connectedDataSource?: boolean;
  identifiedUsers?: boolean;
  trackedBehaviors?: boolean;
};

export const TrackingPixelJavascriptSnippet: FC<Props> = ({
  workspaceId,
  integrationType,
  connectedDataSource = false,
  identifiedUsers = false,
  trackedBehaviors = false
}) => {
  // Workspace context

  const { activeWorkspace } = useContext(AppLifecycleContext);

  // Tracking pixel customizations state

  const [isAutoCaptureEnabled, isPrivacyModeEnabled, useWhiteLabelDomain] = useTrackingPixelStore(
    (state) => [state.isAutoCaptureEnabled, state.isPrivacyModeEnabled, state.useWhiteLabelDomain]
  );

  // Custom domain is connected or not

  const customDomain = activeWorkspace?.custom_domain;

  // Get location
  const location = useLocation();

  // Use the location route to see on which pages the tracking pixel steps will be shown.

  const isOnboardingRoute = location.pathname.includes("/onboarding/tracking-pixel");
  const isInsightsDashboard = location.pathname.includes("/insights/dashboard");

  // This is for showing the tracking pixel steps on the onboarding page for the product insights as well as onboarding time..

  const defaultOpened = isOnboardingRoute
    ? "install_pixel" // default to install pixel
    : connectedDataSource
    ? identifiedUsers
      ? trackedBehaviors
        ? "" // all done - all panels closed
        : "custom_events" // only custom events left
      : "user_identify" // first step done - second panel open
    : "install_pixel"; // nothing done - first panel open

  const [scriptOption, setScriptOption] = useState("new");

  const code = [
    {
      fileName: "New",
      code: GTMTrackingPixelSnippet(
        workspaceId,
        isAutoCaptureEnabled,
        isPrivacyModeEnabled,
        useWhiteLabelDomain ? customDomain : null
      ),
      language: "javascript"
    },
    {
      fileName: "Legacy",
      code: trackingPixelSnippet(
        workspaceId,
        isAutoCaptureEnabled,
        isPrivacyModeEnabled,
        useWhiteLabelDomain ? customDomain : null
      ),
      language: "javascript"
    }
  ];
  const navigate = useNavigate();

  useEffect(() => {
    if (integrationType === "javascript") {
      setScriptOption("new");
    }
  }, [integrationType]);

  return (
    <>
      <Accordion mt={32} variant="separated" defaultValue={defaultOpened}>
        <Accordion.Item value="install_pixel">
          <Accordion.Control>
            <Flex align={"center"}>
              {connectedDataSource ? (
                <Avatar size="md" color="teal.7" radius="xl" mr={"sm"}>
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" mr={"sm"}>
                  1
                </Avatar>
              )}

              <Text className="flex-grow" fz="md" fw="bold">
                {isInsightsDashboard ? "Connect a data source" : "Add Tracking Pixel"}
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Text mb={"md"} fz="sm">
              This single line of code initiates the collection of Analytics data. Insert the code
              into your website's header, immediately before the closing{" "}
              <Code fz="xs">&lt;&#47;head&gt;</Code> tag.
            </Text>
            <TrackingPixelCustomizations />
            <Space h={16} />

            <CodeHighlightTabs
              fz={"sm"}
              collapseCodeLabel="Show more"
              withExpandButton
              defaultExpanded={false}
              expandCodeLabel="Show full code"
              code={integrationType === "gtm" ? code[0] : code}
            />

            {integrationType !== "gtm" && (
              <>
                <Alert
                  mt={"xs"}
                  color={"orange"}
                  mb={"xs"}
                  icon={<FontAwesomeIcon size="xl" icon={regular("info-circle")} />}>
                  We recommend using the new tracking code as it works better with WordPress and
                  other CMS platforms.
                </Alert>
              </>
            )}

            {integrationType === "gtm" && (
              <>
                <Box my={"md"}>
                  <Alert
                    color={"orange"}
                    icon={<FontAwesomeIcon size="xl" icon={regular("info-circle")} />}>
                    If you want to learn more about Google Tag Manager Integration, check out{" "}
                    <Anchor
                      fw={500}
                      fz="sm"
                      href="https://usermaven.com/docs/integrations/google-tag-manager"
                      target="_blank">
                      this help article.
                    </Anchor>
                  </Alert>
                </Box>
              </>
            )}

            <Box my={"md"}>
              <Alert
                color={"orange"}
                icon={<FontAwesomeIcon size="xl" icon={regular("info-circle")} />}>
                Learn more about cross-domain tracking through{" "}
                <Anchor
                  fw={500}
                  fz="sm"
                  href="https://usermaven.com/docs/cross-domain-tracking-in-usermaven"
                  target="_blank">
                  this help article.
                </Anchor>
              </Alert>
            </Box>
          </Accordion.Panel>
        </Accordion.Item>
        {!isOnboardingRoute &&
          activeWorkspace.organization.plan.plan_type === PLAN_PRODUCT_TYPE.WEB_PRODUCT && (
            <>
              <Accordion.Item value="user_identify">
                <Accordion.Control>
                  <Flex align={"center"}>
                    {identifiedUsers ? (
                      <Avatar size="md" color="teal.7" radius="xl" mr={"sm"}>
                        <FontAwesomeIcon icon={solid("check")} size="xl" />
                      </Avatar>
                    ) : (
                      <Avatar size="md" color="brand" radius="xl" mr="sm">
                        2
                      </Avatar>
                    )}
                    <Text className="flex-grow" fz="md" fw="bold">
                      {isInsightsDashboard
                        ? "Send users and companies attributes"
                        : "Send users and companies attributes (For SaaS product insights)"}
                    </Text>
                  </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                  <Text>
                    <Text span fw={600}>
                      Next, use our identify function
                    </Text>{" "}
                    to send us customer data. Add this snippet below the previous one and modify it
                    with the attributes you'd like to send.
                  </Text>
                  <CodeHighlight
                    language="javascript"
                    code={identifyPixelSnippet()}
                    copyLabel="Copy to clipboard"
                  />
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="custom_events">
                <Accordion.Control>
                  <Flex align={"center"}>
                    {trackedBehaviors ? (
                      <Avatar size="md" color="teal.7" radius="xl" mr="sm">
                        <FontAwesomeIcon icon={solid("check")} size="xl" />
                      </Avatar>
                    ) : (
                      <Avatar size="md" color="brand" radius="xl" mr="sm">
                        3{" "}
                      </Avatar>
                    )}
                    <Text className="flex-grow" fz="md" fw="bold">
                      {isInsightsDashboard
                        ? "Track behaviours"
                        : "Send important events (For SaaS product insights)"}
                    </Text>
                  </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                  <Text mb="sm" fz="sm">
                    Usermaven allows you to track user behaviours by using Pinned Events as well as
                    have an option to send the custom events.
                  </Text>
                  <Flex mb={16} ml={16}>
                    <Avatar variant="outline" size="sm" color="brand.5" radius="xl">
                      1
                    </Avatar>
                    <Box ml={16}>
                      <Title order={5} mb={8}>
                        Pinned Events
                      </Title>
                      <Text fz="sm">
                        You can create your Pinned events from{" "}
                        <Anchor
                          onClick={() =>
                            navigate(`/env/${activeWorkspace.identifier}/events/pinned-events`)
                          }
                          className={"primary-link-color"}>
                          here
                        </Anchor>
                        .{" "}
                        <Anchor
                          href="https://usermaven.com/docs/getting-started/creating-pinned-events"
                          target="_blank">
                          Learn more about Pinned Events.
                        </Anchor>
                      </Text>
                    </Box>
                  </Flex>
                  <Flex ml={16}>
                    <Avatar variant="outline" size="sm" color="brand.5" radius="xl">
                      2
                    </Avatar>
                    <Box ml={16}>
                      <Title order={5}>Custom Events</Title>
                      <Box>
                        <Text mb="sm" fz="sm">
                          Start sending your important events such as{" "}
                          <Code fz="sm">"signed_up", "book-a-demo"</Code> etc. In most cases, you
                          should make events calls after <Code fz="sm">usermaven('id')</Code> call
                          for the logged in user, so that you can associate events with a
                          user/company in your workspace.
                        </Text>
                        <Text mb="sm" fz="sm">
                          Not sure what events you need to track? We have got you covered.{" "}
                          <Anchor
                            onClick={() => navigate("/events-recommendations?type=js-snippet")}
                            className={"primary-link-color"}>
                            Get started with our tracking guide.
                          </Anchor>
                        </Text>
                        <CodeHighlight
                          language="javascript"
                          code={trackingEventsSnippet()}
                          copyLabel="Copy to clipboard"
                        />
                      </Box>
                    </Box>
                  </Flex>
                </Accordion.Panel>
              </Accordion.Item>
            </>
          )}
      </Accordion>
    </>
  );
};
