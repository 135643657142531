export const numberToCommas = (value: number | undefined = 0) => {
  if (!value) return 0;
  if (value === 0) return 0;
  if (typeof value === "string" || typeof value === "object") return value;
  return value.toLocaleString();
};

export const convertToFloat = (value: any) => {
  if (value) {
    try {
      return parseFloat(value);
    } catch (m) {
      return value;
    }
  }
  return value;
};

// convert string to title case
export const toTitleCaseLabel = (item: string) => {
  return item
    .toLowerCase() // First, make all characters lowercase
    .split(" ") // Split the string into an array of words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }) // Capitalize the first letter of each word
    .join(" "); // Join the array back into a single string
};

// write me a function to capitalize first letter of string
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const stringToInitials = (text: string) => {
  const s = text.split(" ");
  return s.length > 1 ? `${s[0].substring(0, 1)}${s[1].substring(0, 1)}` : s[0].substring(0, 1);
};

export const numberToUnit = (value: number | undefined = 0) => {
  // console.debug(value)
  // Nine Zeroes for Billions
  return Math.abs(Number(value)) >= 1.0e9
    ? Number((Math.abs(Number(value)) / 1.0e9).toFixed(1)) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(value)) >= 1.0e6
    ? Number((Math.abs(Number(value)) / 1.0e6).toFixed(1)) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(value)) >= 1.0e3
    ? Number((Math.abs(Number(value)) / 1.0e3).toFixed(1)) + "K"
    : Number(value) === value && value % 1 !== 0
    ? Math.abs(Number(value)).toFixed(2)
    : Math.abs(Number(value));
};

export const convertSecondsToReadableFormat = (time: number, ignoreLastUnit: boolean = false) => {
  // less than 60 seconds
  if (time < 60) {
    return `${time}s`;
  }
  // less than an hour (60 minutes * 60 seconds)
  else if (time < 60 * 60) {
    return `${Math.floor(time / 60)}m ${Math.floor(time % 60)}s`;
  }
  // less than a day (24 hours * 60 minutes * 60 seconds)
  else if (time < 24 * 60 * 60) {
    const hours = Math.floor(time / 3600);
    time %= 3600;
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);

    if (ignoreLastUnit) return `${hours}h ${minutes}m`;

    return `${hours}h ${minutes}m ${seconds}s`;
  }
  // time in days or more
  else {
    const days = Math.floor(time / (3600 * 24));
    time %= 3600 * 24;
    const hours = Math.floor(time / 3600);
    time %= 3600;
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);

    if (ignoreLastUnit) return `${days}d ${hours}h`;

    return `${days}d ${hours}h ${minutes}m`;
  }
};

/**
 *
 * @param value the number to be formatted
 * @param lessThanValue the value to be used to determine if the number should be formatted to unit or commas
 * @example getReadableFormatNumber(345675, 100000) // 345.7K
 * @example getReadableFormatNumber(345675, 1000000) // 345,675
 * @returns
 */
export const getReadableFormatNumber = (value: number, lessThanValue: number = 100000) => {
  return value < lessThanValue ? numberToCommas(value) : numberToUnit(value);
};

export const ellipsisContent = (content: string, length: number) => {
  if (content.length > length) {
    return `${content.substring(0, length)}...`;
  }
  return content;
};

/**
 * Return the string between the start_word and end_word.
 * Does not include the start_word or end_word in the response.
 * @param string the string to be searched
 * @param startWord the word to start the search from
 * @param endWord the word to end the search
 * @returns the string between the start_word and end_word
 * @example getStringBetweenStartAndEnd('The price is $1234.56 and the quantity is 1234', 'price is $', ' and the') // '1234.56'
 */
export const getStringBetweenStartAndEnd = (
  string: string,
  startWord: string,
  endWord: string
): string | undefined => {
  const tableStart = string.indexOf(startWord) + startWord.length;
  const tableEnd = string.indexOf(endWord);

  if (tableStart >= 0 && tableEnd >= 0 && tableStart < tableEnd) {
    const table = string.substring(tableStart, tableEnd);
    return table;
  }

  return undefined;
};

/**
 * Get html element from string
 * @param htmlString the string to be converted to html element
 * @returns the html element
 * @example getHtmlElementFromString('<div>hello world</div>') // <div>hello world</div>
 * @example getHtmlElementFromString('<div>hello world</div>').innerHTML // hello world
 **/
export const getHtmlDocumentFromString = (htmlString: string): Document => {
  const html = new DOMParser().parseFromString(htmlString, "text/html");
  return html;
};

/**
 * Function to generate a basic hash code from a string
 * @param str the string to be hashed
 * @returns the hash code
 * @example generateHashCode('d3b8f4b6-6b84-47ab-8fe2-0bea6bfae7c2') // -657177897
 **/
export const generateHashCode = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
  }
  return hash;
};

export const snakeCaseToSimpleString = (snakeCaseString: string) =>
  snakeCaseString.replace(/_/g, " ").replace(/\b\w/g, (match) => match.toUpperCase());
