import seedrandom from 'seedrandom';

export function getSeedColor(name: string) {
  const seed = [...name].reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
  const rng = seedrandom(seed);

  const red = Math.floor(rng() * 256);
  const green = Math.floor(rng() * 256);
  const blue = Math.floor(rng() * 256);

  const hexColor = "#" +
    red.toString(16).padStart(2, "0") +
    green.toString(16).padStart(2, "0") +
    blue.toString(16).padStart(2, "0");

  return hexColor;
}