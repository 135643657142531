import axios from "./JWTService";
export class WorkspacePinnedEventsService {
  create = (wsId: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/pinned_events/${wsId}`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  update = (wsId: string, id: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/pinned_events/${wsId}/${id}`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  all = (
    wsId: string,
    page: number = 1,
    limit: number = 20,
    include_usage: boolean = false
  ): Promise<any> => {
    const includeUsageParameter = include_usage ? "&include_usage=true" : "";
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/pinned_events/${wsId}?page=${page}&limit=${limit}${includeUsageParameter}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  get = (wsId: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/pinned_events/${wsId}/${id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (wsId: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/v1/pinned_events/${wsId}/${id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  suggestions = (wsId: string, query: string, selector: string, operator: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `/v1/pinned_events_utils/${wsId}/suggestions?query=${query}&selector=${selector}&operator=${operator}`
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  
  suggestionsByConditions = (
    wsId: string,
    data: {
      conditions: any;
      selected_condition: any;
      query: string;
    }
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/pinned_events_utils/${wsId}/suggestions_by_conditions`, data)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  totalEvents = (wsId: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/pinned_events_utils/${wsId}/rules/total_events`, data)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  occurrence = (wsId: string, id: string, startDate: string, endDate: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `/v1/pinned_events_utils/${wsId}/occurrence/${id}?start_date=${startDate}&end_date=${endDate}`
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  detailedActivity = (
    wsId: string,
    id: string,
    page: number,
    startDate: string,
    endDate: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `/v1/pinned_events_utils/${wsId}/detailed_activity/${id}?page=${page}&start_date=${startDate}&end_date=${endDate}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  breakdownByPage = (
    wsId: string,
    id: string,
    page: number,
    startDate: string,
    endDate: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `/v1/pinned_events_utils/${wsId}/breakdown_by_page/${id}?page=${page}&start_date=${startDate}&end_date=${endDate}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  usageForContact = (
    wsId: string,
    contactId: string,
    page: number,
    type: "user" | "visitor" | "company"
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/pinned_events_utils/${wsId}/usage/${contactId}/${type}?page=${page}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
