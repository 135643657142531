import React from "react";
import { useSegmentFormContext } from "./SegmentFormContext";
import { ISegmentCondition, SegmentCondition } from "./SegmentCondition";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Group,
  Select,
  useMantineTheme,
  Text,
  Paper,
  Flex,
  useMantineColorScheme
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import useInsightHook from "@/hooks/useInsightHook";

interface SegmentGroupProps {
  groupIndex: number;
  attributes: any;
  group: ISegmentGroup;
  fullWidth?: boolean;
}

export interface ISegmentGroup {
  combinator: "and" | "or";
  rules: ISegmentCondition[];
}

const SegmentGroup: React.FC<SegmentGroupProps> = ({
  groupIndex,
  attributes,
  group,
  fullWidth = false
}) => {
  const { colorScheme } = useMantineColorScheme();
  const form = useSegmentFormContext();
  /**
   * Get attributes against the workspace.
   */
  const { insightEvents, insightPinnedEvents } = useInsightHook();

  const renderConditions = group.rules.map((rule, index) => (
    <Group className="mb-4 transition-all ease-in-out" key={`segment-group-condition-${index}`}>
      {index > 0 && (
        <div className="w-16 flex flex-row justify-end">
          <Badge
            radius={form.values.groups[groupIndex].combinator === "and" ? "sm" : "xl"}
            color={colorScheme === "dark" ? "dark.3" : "gray.5"}
            variant="outline"
            className="">
            {form.values.groups[groupIndex].combinator}
          </Badge>
        </div>
      )}
      <SegmentCondition
        fullWidth={fullWidth}
        index={index}
        attributes={attributes}
        events={insightEvents}
        pinnedEvents={insightPinnedEvents}
        rule={rule}
        groupIndex={groupIndex}
      />
    </Group>
  ));

  return (
    <>
      <Paper
        p="sm"
        component="div"
        withBorder
        className={`flex flex-col p-4  rounded ${
          fullWidth ? "w-[100%]" : "w-[75%]"
        } transition-all ease-in-out ${
          form.values.rules.length === 0 &&
          form.values.groups.length > 1 &&
          groupIndex === 0 &&
          !fullWidth
            ? "ml-20"
            : ""
        } group/groups`}>
        <Group justify="space-between">
          <Flex align="center" mb="xs" className="flex items-center mb-4">
            <Select
              size="xs"
              className="w-36 mr-4"
              {...form.getInputProps(`groups.${groupIndex}.combinator`)}
              data={[
                {
                  label: "All",
                  value: "and"
                },
                {
                  label: "At least one",
                  value: "or"
                }
              ]}
            />
            <Text color={colorScheme === "dark" ? "dark.2" : undefined}>
              of the following conditions match
            </Text>
          </Flex>
          <div className="hidden justify-end flex-grow group-hover/groups:flex items-center">
            <ActionIcon
              className="ml-4 mb-4"
              data-tip="Remove"
              variant="outline"
              color={"red"}
              size="sm"
              onClick={() => {
                form.removeListItem("groups", groupIndex);
              }}>
              <FontAwesomeIcon icon={regular("trash")} className=" transform text-sm" />
            </ActionIcon>
          </div>
        </Group>

        {renderConditions}
        <div className="flex justify-between w-[80%]">
          <Button
            size="xs"
            variant="subtle"
            onClick={() => {
              form.validate();
              form.setFieldValue(`groups.${groupIndex}.rules`, [
                ...form.values.groups[groupIndex].rules,
                {
                  type: "attribute",
                  field: "",
                  operator: "",
                  value: "",
                  times: 1,
                  within: 1,
                  frequency: "",
                  filters: {
                    combinator: "and",
                    rules: []
                  }
                }
              ]);
            }}
            leftSection={<FontAwesomeIcon icon={regular("plus")} />}>
            Add Condition
          </Button>

          {/* Add group button here for nested groups */}
        </div>
      </Paper>
    </>
  );
};

export default SegmentGroup;
