/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Paper,
  Text,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import FeatureAdoptionTemplateIcon from "@assets/images/icons/insights/templates/feature_adoption_template.webp";
import FeatureAdoptionIconDarkMode from "@assets/images/icons/insights/templates/feature-adoption-dark-mode.svg";

import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IReportType } from "types/types.d";
import { FeatureAdoptionReportGraph } from "../Graphs/FeatureAdoptionReportGraph";
import { FeatureAdoptionService } from "@/lib/services/FeatureAdoptionService";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { DATE_FORMAT } from "@/lib/utils/Constants";
import { ColorsTheme } from "@/style/Colors";
import { ExtendedAdoptionModule } from "../../Pages/InsightsDashboardFeatureAdoption";

export const FeatureUsageHistogram = ({ query }: any) => {
  const { colorScheme } = useMantineColorScheme();
  const navigate = useNavigate();
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const [adoptionReportData, setAdoptionReportData] = useState<ExtendedAdoptionModule[]>([]);
  const [isFeaturesReportLoading, setIsFeaturesReportLoading] = useState("idle");

  const featureService = new FeatureAdoptionService();

  const getFeatureAdoptionReport = async (
    startDate = format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
    endDate = format(endOfDay(new Date()), DATE_FORMAT),
    reportType: IReportType = "user",
    audienceConditions: Array<any> = [],
    audienceConditionType: string = "or",
    audienceGroupConditions: Array<any> = [],
    comparison: boolean = true,
    moduleId?: string,
    featureId?: string
  ) => {
    setIsFeaturesReportLoading("loading");

    await featureService
      .report(
        activeWorkspace.id,
        startDate,
        endDate,
        reportType,
        audienceConditions,
        audienceConditionType,
        audienceGroupConditions,
        comparison,
        moduleId && moduleId,
        featureId && featureId
      )
      .then((res) => {
        setIsFeaturesReportLoading("loaded");
        if (res.data?.length > 0) {
          // Add color to each feature
          let apiResponse = res.data;
          const updatedApiResponse = apiResponse.map((module: any, index: number) => ({
            ...module,
            color: ColorsTheme[index % ColorsTheme.length],
            isActive: true
          }));

          setAdoptionReportData(updatedApiResponse);
        }
        return true;
      })
      .catch((err) => {
        if (err?.response?.status === 422 || err?.response?.status === 500) {
          setIsFeaturesReportLoading("loaded");
        }
        return false;
      });
  };

  useEffect(() => {
    if (query.from_date && query.to_date) {
      let startDate = format(new Date(query.from_date), DATE_FORMAT);
      let endDate = format(new Date(query.to_date), DATE_FORMAT);
      getFeatureAdoptionReport(startDate, endDate);
    } else {
      getFeatureAdoptionReport();
    }
  }, [query]);

  return (
    <>
      <Paper shadow="sm" withBorder mb="md" h={520}>
        <Title order={5} fw={600} className="font-medium" p="md">
          Feature Usage
        </Title>
        <Divider />
        <div className="grid">
          {isFeaturesReportLoading === "loaded" ? (
            <>
              {adoptionReportData.length > 0 ? (
                <>
                  <Box m={30}>
                    <FeatureAdoptionReportGraph
                      adoptionReportData={adoptionReportData}
                      cohortInsightType={"usage_rate"}
                      isFeatureUsageGraph={true}
                      reportType={"user"}
                    />
                  </Box>
                </>
              ) : (
                <Flex direction={"column"} align={"center"} h={250} justify={"center"}>
                  <img
                    src={
                      colorScheme === "dark"
                        ? FeatureAdoptionIconDarkMode
                        : FeatureAdoptionTemplateIcon
                    }
                    height={96}
                    width={96}
                    alt=""
                    className="rounded-md mb-4  shadow-sm"
                  />
                  <Text mb="md" ta="center">
                    You have not setup any feature adoption insight yet.
                  </Text>
                  <Box>
                    <Button
                      color={colorScheme === "dark" ? "dark.2" : undefined}
                      onClick={() =>
                        navigate(`/env/${activeWorkspace.identifier}/insights/dashboard/feature`, {
                          state: { isModalOpen: true }
                        })
                      }
                      variant="outline"
                      size="xs">
                      Create Insight
                    </Button>
                  </Box>
                </Flex>
              )}
            </>
          ) : (
            <Flex align={"center"} justify={"center"} h={350}>
              <BarChartSkeleton />
            </Flex>
          )}
        </div>
      </Paper>
    </>
  );
};
