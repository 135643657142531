import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { FeatureAdoptionService } from "@/lib/services/FeatureAdoptionService";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import { DATE_FORMAT } from "@/lib/utils/Constants";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { purple } from "@/style/Colors";
import { LineGraphWrapper } from "@/ui/components/App/EChartsWrappers/LineGraphWrapper";
import {
  TrendsGraphBody,
  TrendsGraphContainer,
  TrendsGraphHeader,
  TrendsGraphHeaderH3,
  TrendsGraphHeaderLeft,
  TrendsGraphHeaderParagraph,
  TrendsGraphHeaderRight
} from "@/ui/components/App/TrendsGraph/TrendsGraph.style";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";
import { SegmentedControl, useMantineTheme } from "@mantine/core";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import * as echarts from "echarts/core";
import { useContext, useEffect, useState } from "react";

export const DailyFeatureAdoptionHistogram = ({
  featureId,
  fromDate = format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
  toDate = format(endOfDay(new Date()), DATE_FORMAT),
  reportType
}: {
  featureId?: string;
  fromDate?: string;
  toDate?: string;
  reportType?: string;
}) => {
  const [filters] = useSegmentStore((state) => [state.filters]);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [isLoading, setIsLoading] = useState("idle");
  const [adoptionHistogramUsersList, setAdoptionHistogramUsersList] = useState<Array<any>>([]);

  const [adoptionHistogramUsersPercentageList, setAdoptionHistogramUsersPercentageList] = useState<
    Array<any>
  >([]);

  const [adoptionHistogramFeatureUsageList, setAdoptionHistogramFeatureUsageList] = useState<
    Array<any>
  >([]);

  const [chartType, setChartType] = useState("users_timeline");

  const fetchDailyAdoptionHistogram = async () => {
    setIsLoading("loading");
    const featureService = new FeatureAdoptionService();
    await featureService
      .histogram(
        activeWorkspace.id,
        featureId,
        fromDate,
        toDate,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        setAdoptionHistogramUsersList([
          {
            name: reportType === "user" ? "Users" : "Companies",
            type: "line",
            smooth: false,
            color: purple,
            lineStyle: {
              width: 2
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(125, 71, 235, 0.3)"
                },
                {
                  offset: 1,
                  color: "rgba(125, 71, 235, 0.01)"
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: res.data.map((item: any) => [
              item.date,
              reportType === "user" ? item.users : item.companies
            ])
          }
        ]);

        setAdoptionHistogramUsersPercentageList([
          {
            name: `Active ${reportType === "user" ? "Users" : "Companies"} %`,
            type: "line",
            smooth: false,
            color: "#01baa1",
            lineStyle: {
              width: 2
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(1, 186, 161, 0.3)"
                },
                {
                  offset: 1,
                  color: "rgba(1, 186, 161, 0.01)"
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: res.data.map((item: any) => [
              item.date,
              reportType === "user" ? item.user_adoption : item.company_adoption
            ])
          }
        ]);

        setAdoptionHistogramFeatureUsageList([
          {
            name: "Events",
            type: "line",
            smooth: false,
            color: "#5abbe5",
            lineStyle: {
              width: 2
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(90, 187, 229, 0.3)"
                },
                {
                  offset: 1,
                  color: "rgba(90, 187, 229, 0.01)"
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: res.data.map((item: any) => [item.date, item.events])
          }
        ]);
      })
      .catch((err) => {});
    setIsLoading("loaded");
  };

  const getAdoptionHistogramData = () => {
    switch (chartType) {
      case "users_timeline":
        return adoptionHistogramUsersList;
      case "active_users_percentage":
        return adoptionHistogramUsersPercentageList;
      case "feature_usage_timeline":
        return adoptionHistogramFeatureUsageList;
    }
  };

  useEffect(() => {
    fetchDailyAdoptionHistogram();
  }, [featureId, reportType, fromDate, toDate]);


  return (
    <>
      <InsightSection
        title={"Feature Usage"}
        description={
          <>
            {chartType === "users_timeline"
              ? `No. of ${reportType}(s) that used this feature over time.`
              : chartType === "active_users_percentage"
              ? "Number of times the feature was used over time."
              : chartType === "feature_usage_timeline"
              ? `The percentage of active ${reportType}(s) that used the feature over time`
              : ""}
          </>
        }
        rightSection={
          <SegmentedControl
            value={chartType}
            size={"xs"}
            onChange={setChartType}
            data={[
              {
                label: `No. of ${reportType === "user" ? "users" : "companies"}`,
                value: "users_timeline"
              },
              {
                label: "No. of events",
                value: "feature_usage_timeline"
              },
              {
                label: `Active ${reportType === "user" ? "users" : "companies"} %`,
                value: "active_users_percentage"
              }
            ]}
          />
        }>
        {" "}
        <div>
          {isLoading === "loaded" ? (
            <LineGraphWrapper
              showPercentage={chartType === "active_users_percentage" ? true : false}
              series={getAdoptionHistogramData()}
              // yScaleMax={chartType === "active_users_percentage" ? 100 : "auto"}
              // showPercentageInTooltip={chartType === "active_users_percentage" ? true : false}
            ></LineGraphWrapper>
          ) : (
            <BarChartSkeleton />
          )}
        </div>
      </InsightSection>
    </>
  );
};
