import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatRelative } from "date-fns"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { UsersCRMService } from "@/lib/services/UsersCRMService"
import { LOADER_COLOR } from "@/lib/utils/Constants"
import { locale, utcToTime } from "@/lib/utils/DateUtility"
import _groupBy from "lodash.groupby"
import React, { useContext, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { Oval } from "react-loader-spinner"
import { useParams } from "react-router-dom"
import { UserActivityType } from "types/types.d"
import { NoResults } from "@/ui/components/App/NoResults/NoResults"
// @ts-ignore
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import parse from "html-react-parser"
import { getACEventType } from "@/lib/utils/AutoCaptureUtility"
import { Box } from "@mantine/core"
export const UserCRMDetailActivities = (props: any) => {
  const { segment, peopleId } =
    useParams<{
      segment: string
      peopleId: string
    }>()
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState("idle")
  const { activeWorkspace } = useContext(AppLifecycleContext)
  const [activitiesList, setActivitiesList] = useState<Array<UserActivityType>>([])
  const [activitiesCount, setActivitiesCount] = useState(0)
  const [moreActivities, setMoreActivities] = useState(true)
  const peopleService = new UsersCRMService()

  const getPeopleActivities = async () => {
    setIsLoading("loading")
    await peopleService
      .individualPeopleActivitiesById(activeWorkspace.id, peopleId, 1)
      .then(res => {
        if (res.data) {
          setActivitiesList(res.data.items)
          setActivitiesCount(res.data.count)
          if (res.data.items.length === 0) {
            setMoreActivities(false)
          }
        }
      })
      .catch(err => {
        setMoreActivities(false)
      })
    setIsLoading("loaded")
  }

  const fetchMoreActivities = () => {
    console.log("FetchMoreActivities")
    setPage(page + 1)
    peopleService
      .individualPeopleActivitiesById(activeWorkspace.id, peopleId, page + 1)
      .then(res => {
        if (res.data) {
          let activitiesListItems = activitiesList.concat(res.data.items)
          setActivitiesList(activitiesListItems)
          if (res.data.items.length < 20) {
            setMoreActivities(false)
          }
        }
      })
      .catch(err => {
        setMoreActivities(false)
      })
  }

  const _getActivityIcon = (event_type: string) => {
    switch (event_type) {
      // return

      case "pageview":
        return <FontAwesomeIcon icon={solid("eye")} />
      case "user_identify":
        return <FontAwesomeIcon icon={solid("user")} />
      default:
        return <FontAwesomeIcon icon={solid("arrow-pointer")} className="transform " />
    }
  }
  const _getActivityIconClass = (event_type: string) => {
    switch (event_type) {
      case "pageview":
        return "um-activity-icon-yellow"
      case "user_identify":
        return "um-activity-icon-green"
      default:
        return "um-activity-icon-blue"
    }
  }
  const _getGroupByActivitiesByDay = (): any => {
    console.log(activitiesList)
    let groupedActivities = _groupBy(activitiesList, "date")
    console.log("Group activities", groupedActivities)
    return Object.entries(groupedActivities)
  }
  const _getWeekdayAndDate = (date: string) => {
    return Intl.DateTimeFormat("en-us", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(new Date(date))
  }

  useEffect(() => {
    getPeopleActivities()
  }, [])
  return (
    <>
      <Box
        className="  border border-solid h-full flex flex-col"
        sx={theme => ({
          borderColor:
            theme.colorScheme === "dark"
              ? theme.colors.darkBorderColor[0]
              : theme.colors.lightBorderColor[0],
        })}
      >
        <div className="">
          <Box
            sx={theme => ({
              fontSize: "1.125rem",
              background: theme.colorScheme === "dark" ? theme.colors.dark[6] : "",
              borderColor:
                theme.colorScheme === "dark"
                  ? theme.colors.darkBorderColor[0]
                  : theme.colors.lightBorderColor[0],
            })}
            className="font-semibold py-2 px-4 border-b border-solid  bg-gray-100 flex justify-between items-center"
          >
            Activities
          </Box>
          {isLoading !== "loaded" && (
            <div className="um-activities--loading">
              <Oval color={LOADER_COLOR} height={16} width={16} />
            </div>
          )}
          {isLoading === "loaded" && activitiesList.length === 0 && (
            <>
              <NoResults type="search" heading="No Activities" text="There is no activity yet." />
            </>
          )}
          <div
            className="px-4 max-h-screen overflow-y-auto um-activities-container "
            id="umActivitiesContainer"
          >
            {isLoading === "loaded" && activitiesList.length > 0 && (
              <InfiniteScroll
                dataLength={activitiesList.length}
                next={fetchMoreActivities}
                hasMore={moreActivities}
                scrollThreshold={0.8}
                scrollableTarget="umActivitiesContainer"
                loader={
                  <div className="um-activities--loading">
                    <Oval color={LOADER_COLOR} height={16} width={16} />
                  </div>
                }
              >
                {_getGroupByActivitiesByDay().map((value: any, key: any) => (
                  <>
                    <div className="flex my-4" key={`people-activity-group:${value}:${key}`}>
                      <div className="flex-1">
                        <h4>
                          {formatRelative(new Date(value[0]), new Date(), {
                            locale: locale,
                          })}
                        </h4>
                      </div>
                      <div className="flex w-1/4 flex-row-reverse">
                        <h4>{_getWeekdayAndDate(value[0])}</h4>
                      </div>
                    </div>

                    {value[1].map((activity: UserActivityType, index: number) => (
                      <div
                        className="um-activities--container--item items-center"
                        key={`people-activity:${activity._timestamp}:${index}`}
                      >
                        <div className="um-activities--container--item--icon">
                          <div
                            className={`um-activity-icon ${_getActivityIconClass(
                              activity.event_type
                            )}`}
                          >
                            {_getActivityIcon(activity.event_type)}
                            {/* <Icon
                            icon={_getActivityIcon(activity.event_type)}
                          ></Icon> */}
                          </div>
                        </div>
                        <div className="um-activities--container--item--content pt-1 ">
                          <div className="um-activities--container--item--content--container">
                            {activity?.event_type === "pageview" ? (
                              <>
                                <div className="flex items-center">
                                  <div className="flex-grow flex justify-center flex-col">
                                    <p>
                                      {/* {`${activity.user_first_name} ${activity.user_last_name}`}{" "} */}
                                      Viewed page {activity.page_title}
                                    </p>
                                    <p className="text-sm py-1 ">
                                      <a
                                        className="pl-1 no-underline text-gray-800 hover:text-gray-900 break-all"
                                        target="_blank"
                                        href={activity.url}
                                      >
                                        {`${activity.doc_path}${activity.doc_search}`}
                                      </a>
                                    </p>
                                    {/* <p>{activity.referer}</p> */}
                                  </div>
                                  <div className="text-sm pt-1 text-gray-700">
                                    {utcToTime(activity._timestamp)}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="flex items-center">
                                  <div className="flex-grow flex justify-center flex-col">
                                    <p className="flex-grow">
                                      {/* {`${activity.user_first_name} ${activity.user_last_name}`}{" "} */}

                                      <span className="pl-1 first-letter-capital">
                                        {activity.event_type === "$autocapture" ? (
                                          <>
                                            <span>
                                              {parse(
                                                getACEventType({
                                                  event_id: activity.event_id,
                                                  ac_el_text: activity.ac_el_text,
                                                  ac_event_type: activity.ac_event_type,
                                                  ac_href: activity.ac_href,
                                                  ac_id: activity.ac_id,
                                                  ac_name: activity.ac_name,
                                                  ac_tag_name: activity.ac_tag_name,
                                                  url: activity.url,
                                                  _timestamp: activity._timestamp,
                                                })
                                              )}
                                            </span>
                                            <Box
                                              component="span"
                                              sx={theme => ({
                                                background:
                                                  theme.colorScheme === "dark"
                                                    ? theme.colors.darkBorderColor[0]
                                                    : theme.colors.lightBorderColor[0],
                                                border: "1px solid",
                                                borderColor:
                                                  theme.colorScheme === "dark"
                                                    ? theme.colors.darkBorderColor[1]
                                                    : theme.colors.lightBorderColor[0],
                                              })}
                                              className=" text-xs  rounded ml-2 px-2 py-1 "
                                            >
                                              Autocaptured
                                            </Box>
                                          </>
                                        ) : (
                                          <>
                                            Performed an event{" "}
                                            <span className="primary-link-color ">
                                              {activity.event_type}
                                            </span>
                                          </>
                                        )}
                                      </span>
                                    </p>
                                    <p className="text-sm px-1 py-1.5">
                                      Source Page URL: {activity?.source_page_url}
                                    </p>
                                  </div>
                                  <div className="text-sm pt-1 text-gray-700">
                                    {utcToTime(activity._timestamp)}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ))}
              </InfiniteScroll>
            )}
          </div>
        </div>
      </Box>
    </>
  )
}
