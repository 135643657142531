export const trackingPixelSnippet = (
  workspaceIdentifier: string,

  autoCaptureEnabled: boolean = false,
  isPrivacyModeEnabled: boolean = false,
  customDomain: string | null = null
) => {
  const isCustomDomain = customDomain && customDomain.length > 0;
  const libURL = isCustomDomain
    ? 'https://' + customDomain + '/lib.js'
    : 'https://t.usermaven.com/lib.js';

  const trackingHost = isCustomDomain
    ? `https://${customDomain}`
    : process.env.REACT_APP_TRACKING_HOST;
  const randomizeData = isCustomDomain ? `\n    data-randomize-url="true"` : '';
  return `<script src="${libURL}" 
    data-key="${workspaceIdentifier}" 
    data-tracking-host="${trackingHost}"
    ${autoCaptureEnabled ? 'data-autocapture="true"' : ''} ${randomizeData} ${
    isPrivacyModeEnabled ? '\n    data-privacy-policy="strict"' : ''
  } 
    defer>
</script>
<script>window.usermaven = window.usermaven || (function(){(window.usermavenQ = window.usermavenQ || []).push(arguments);})</script>`;
};

export const GTMTrackingPixelSnippet = (
  workspaceIdentifier: string,
  autoCaptureEnabled: boolean = false,
  isPrivacyModeEnabled: boolean = false,
  customDomain: string | null = null
) => {
  const isCustomDomain = customDomain && customDomain.length > 0;
  const libURL = isCustomDomain
    ? 'https://' + customDomain + '/lib.js'
    : 'https://t.usermaven.com/lib.js';
  const trackingHost = isCustomDomain
    ? `https://${customDomain}`
    : process.env.REACT_APP_TRACKING_HOST;
  const randomizeData = isCustomDomain
    ? `\n        t.setAttribute('data-randomize-url','true');`
    : '';

  return `<script type="text/javascript">     
    (function () {
        window.usermaven = window.usermaven || (function () { (window.usermavenQ = window.usermavenQ || []).push(arguments); })
        var t = document.createElement('script'),
            s = document.getElementsByTagName('script')[0];
        t.defer = true;
        t.id = 'um-tracker';
        t.setAttribute('data-tracking-host', "${trackingHost}")
        t.setAttribute('data-key', '${workspaceIdentifier}');${
    autoCaptureEnabled ? `\n        t.setAttribute('data-autocapture', 'true');` : ''
  } ${
    isPrivacyModeEnabled ? `\n        t.setAttribute('data-privacy-policy', 'strict');` : ''
  } ${randomizeData}
        t.src = '${libURL}';
        s.parentNode.insertBefore(t, s);
    })();
  </script>
  `;
};

export const identifyPixelSnippet = () => {
  return `<script type="text/javascript">
  usermaven('id', {

    // Required attributes
    id: 'lzL24K3kYw',    // Unique ID for the user in database.
    email: "user@domain.com", // Email address for the user.
    created_at: "2021-01-20T09:55:35",   // DateTime string in your system that represents when the user first signed up.

    // Recommended attributes
    // First name and last name are shown on user pages.
    first_name: 'John',       
    last_name: 'Smith',       

    // Optional attributes (you can name attributes what you wish)
    custom: {
        plan_name: "premium",
    },

    // If your product is used by multiple users in a company, we recommend to pass the Company attributes.
    company: {
      // Required Attributes
      id: "uPq9oUGrIt", // Company ID in your database
      name: "Usermaven", // Company Name in your database.
      created_at: "2021-01-20T09:55:35",   // DateTime string in your system that represents when the company first signed up.
                              
      // Optional attributes such as industry, website, employee count etc.
      custom: {
        plan: "enterprise",
        industry: "Technology", 
        website: "https://usermaven.com", 
        employees: 20
      }
    }
  });
</script>`;
};

export const sdkIdentifySnippet = () => {
  return `
usermaven.id({
  // Required attributes
  id: 'lzL24K3kYw',    // Unique ID for the user in database.
  email: "user@domain.com", // Email address for the user.
  created_at: "2021-01-20T09:55:35",   // DateTime string in your system that represents when the user first signed up.

  // Recommended attributes
  // First name and last name are shown on user pages.
  first_name: 'John',       
  last_name: 'Smith',       

  // Optional attributes (you can name attributes what you wish)
  custom: {
      plan_name: "premium",
  },

  // If your product is used by multiple users in a company, we recommend to pass the Company attributes.
  company: {
    // Required Attributes
    id: "uPq9oUGrIt", // Company ID in your database
    name: "Usermaven", // Company Name in your database.
    created_at: "2021-01-20T09:55:35",   // DateTime string in your system that represents when the company first signed up.
                            
    // Optional attributes such as industry, website, employee count etc.
    custom: {
      plan: "enterprise",
      industry: "Technology", 
      website: "https://usermaven.com", 
      employees: 20
    }
  }
});
  `;
};

export const trackingEventsSnippet = () => {
  return `<script type="text/javascript">
    usermaven("track", "signed_up", {
      // additional properties
    });
</script>
  `;
};

export const sdkTrackingEventsSnippet = () => {
  return `usermaven.track("signed_up", {
      // additional properties
    });
  `;
};
