import { WorkspacePinnedEventsService } from '@/lib/services/WorkspacePinnedEventsService';
import create from 'zustand';

export interface PinnedEventRulesProps {
  conditions: Array<PinnedEventRuleConditionProps>;
}

interface PinnedEventRuleConditionProps {
  /**
   * The name of the selector field i.e label_text, css_attribute, source_page_url, destination_url, element_id, element_name
   */
  selector: string;
  /**
   * The operator i.e is, is_not, contains, does_not_contain
   */
  operator: string;
  /**
   * The value of the selector
   */
  value: string;
}

interface PinnedEventStore {
  /**
   * Pinned event id
   */
  id: string;
  /**
   * Pinned event name
   */
  name: string;
  /**
   * Create conversion goal (in create pinned event modal)
   */
  createConversionGoal: boolean;
  /**
   * Set create conversion goal
   */
  setCreateConversionGoal: (createConversionGoal: boolean) => void;
  /**
   * Pinned event rules
   */
  rules: PinnedEventRulesProps[];
  /**
   * Set pinned event rules
   */
  setRules: (rules: PinnedEventRulesProps[]) => void;
  /**
   * Loader to show when the pinned events total count is retrieved
   */
  isTotalEventsLoading: boolean;
  /**
   * Set the loader to show when the pinned events total count is retrieved
   */
  setIsTotalEventsLoading: (isLoading: boolean) => void;
  /**
   * Total pinned events count, displayed in the pinned events modal as a summary.
   */
  totalEvents: number;
  /**
   * Set total events for the pinned event.
   */
  setTotalEvents: (totalEvents: number) => void;
  /**
   * Service to get the pinned events count. The rules with conditions are sent to the service to get the count.
   */
  fetchTotalEvents: (workspaceId: string) => Promise<void>;
  /**
   * Pinned event modal is open or not.
   */
  modalOpen: boolean;
  /**
   * Set pinned event modal open or not.
   */
  setModalOpen: (modalOpen: boolean) => void;
  /**
   * Set pinned event id.
   */
  setId: (id: string) => void;
  /**
   * Set pinned event name.
   */
  setName: (name: string) => void;

  /**
   * Reset the form for pinned event.
   */
  reset: () => void;
}

export const usePinnedEventStore = create<PinnedEventStore>((set, get) => ({
  id: '',
  name: '',
  createConversionGoal: false,
  isTotalEventsLoading: false,
  setIsTotalEventsLoading: (isLoading: boolean) => set({ isTotalEventsLoading: isLoading }),
  /**
   * The default rules will have one condition added by default without any value.
   */
  rules: [
    {
      conditions: [
        {
          selector: 'label_text',
          operator: 'is',
          value: ''
        }
      ]
    }
  ],
  setRules: (rules: PinnedEventRulesProps[]) => set({ rules }),
  setCreateConversionGoal: (createConversionGoal: boolean) => set({ createConversionGoal: createConversionGoal }),
  totalEvents: 0,
  setTotalEvents: (totalEvents: number) => set({ totalEvents }),
  /**
   * Retrieves the total pinned events count.
   * @param workspaceId Active workspace id.
   */
  fetchTotalEvents: async (workspaceId: string) => {
    set({ isTotalEventsLoading: true });
    await new WorkspacePinnedEventsService()
      .totalEvents(workspaceId, {
        rules: get().rules
      })
      .then((res) => {
        if (res.data && res.data.hasOwnProperty('count')) {
          set({ totalEvents: res.data.count });
        }
      })
      .catch((err) => {
        console.error(err);
        set({ totalEvents: 0 });
      });
    set({ isTotalEventsLoading: false });
  },
  setId: (id: string) => set({ id }),
  setName: (name: string) => set({ name }),
  modalOpen: false,
  setModalOpen: (modalOpen) => set({ modalOpen }),
  /**
   * Reset attributes of pinned event to their default values.
   * @returns
   */
  reset: () =>
    set({
      id: '',
      name: '',
      createConversionGoal: false,
      totalEvents: 0,
      rules: [
        {
          conditions: [
            {
              selector: 'label_text',
              operator: 'is',
              value: ''
            }
          ]
        }
      ]
    })
}));
