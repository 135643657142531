import { useLocation } from "react-router-dom";
import {
  DetailedDropdownMenu,
  insightMenuItem
} from "../../Common/DetailedDropdownMenu/DetailedDropdownMenu";
import ActiveUsersTemplateIcon from "@assets/images/icons/insights/templates/active_users_template.webp";
import FeatureAdoptionTemplateIcon from "@assets/images/icons/insights/templates/feature_adoption_template.webp";
import FeatureRetentionIcon from "@assets/images/icons/insights/templates/feature_retention_template.webp";
import OnboardingCompletionRateIcon from "@assets/images/icons/insights/templates/onboarding_completion_rate_template.webp";
import PowerUsersTemplateIcon from "@assets/images/icons/insights/templates/power_users_template.webp";
import SlippingAwayUsersIcon from "@assets/images/icons/insights/templates/slipping_away_users_template.webp";
import OnboardingCompletionRateIconDarkMode from "@assets/images/icons/insights/templates/onboarding-completion-rate-dark-mode.svg";
import ActiveUsersIconDarkMode from "@assets/images/icons/insights/templates/active-users-dark-mode.svg";
import PowerUsersIconDarkMode from "@assets/images/icons/insights/templates/power-users-dark-mode.svg";
import FeatureAdoptionIconDarkMode from "@assets/images/icons/insights/templates/feature-adoption-dark-mode.svg";
import UserRetentionIconDarkMode from "@assets/images/icons/insights/templates/user-retention-dark-mode.svg";
import SlippingAwayUsersIconDarkMode from "@assets/images/icons/insights/templates/slipping-away-users-dark-mode.svg";
import { useEffect, useState } from "react";
import { Button, useMantineTheme } from "@mantine/core";
import { InsightTemplateType } from "@/types/types.d";
import { MOBILE_BREAKPOINT_QUERY } from "@/lib/utils/Constants";
import { useMediaQuery } from "@mantine/hooks";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface IInsightsCreateButtonProps {
  setShowInsightSetupModal: React.Dispatch<React.SetStateAction<boolean>>;
  setInsightType: React.Dispatch<React.SetStateAction<InsightTemplateType>>;
}

export const InsightsCreateButton = ({
  setShowInsightSetupModal,
  setInsightType
}: IInsightsCreateButtonProps) => {
  const location = useLocation();
  const theme = useGlobalMantineTheme();
  const [insightOptions, setInsightOptions] = useState<Array<insightMenuItem>>([]);
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  const getInsightOptions = (type: string, colorScheme: string) => {
    const insightsList: Array<insightMenuItem & { type: string }> = [
      {
        label: "Onboarding Completion Rate",
        description:
          "From sign-up to onboarding completion rate. See drop-off stage during onboarding.",
        value: "onboarding_completion_rate",
        type: "onboarding",
        image:
          colorScheme === "dark"
            ? OnboardingCompletionRateIconDarkMode
            : OnboardingCompletionRateIcon
      },
      {
        label: "Active Users",
        description: "An Overview of Active Users and their stickiness.",
        value: "active_users",
        type: "adoption_engagement",
        image: colorScheme === "dark" ? ActiveUsersIconDarkMode : ActiveUsersTemplateIcon
      },
      {
        label: "Power Users",
        description: "Find users who loves your product.",
        value: "power_users",
        type: "adoption_engagement",
        image: colorScheme === "dark" ? PowerUsersIconDarkMode : PowerUsersTemplateIcon
      },
      {
        label: "Feature Adoption",
        description: "Find the adoption of your latest feature releases.",
        value: "feature_adoption",
        type: "feature",
        image: colorScheme === "dark" ? FeatureAdoptionIconDarkMode : FeatureAdoptionTemplateIcon
      },

      {
        label: "User Retention",
        description: "Overview of your user retention since they signed up.",
        value: "user_retention",
        type: "retention",
        image: colorScheme === "dark" ? UserRetentionIconDarkMode : FeatureRetentionIcon
      },

      {
        label: "Slipping away Users",
        value: "slipping_away_users",
        description: "Find out when users drift away from your product.",
        type: "retention",
        image: colorScheme === "dark" ? SlippingAwayUsersIconDarkMode : SlippingAwayUsersIcon
      }
    ];
    return insightsList.filter((insight) => insight.type === type);
  };

  const handleSelectInsight = (item: insightMenuItem | undefined) => {
    if (item) {
      setInsightType(item.value as InsightTemplateType);
      setShowInsightSetupModal(true);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("onboarding")) {
      setInsightType("onboarding_completion_rate");
      setInsightOptions([]);
    } else if (location.pathname.includes("feature")) {
      setInsightType("feature_adoption");
      setInsightOptions([]);
    } else if (location.pathname.includes("adoption_engagement")) {
      setInsightOptions(getInsightOptions("adoption_engagement", theme.colorScheme));
    } else if (location.pathname.includes("retention")) {
      setInsightOptions(getInsightOptions("retention", theme.colorScheme));
    } else setInsightOptions([]);
  }, [location.pathname]);

  return (
    <>
      {insightOptions.length > 0 ? (
        <DetailedDropdownMenu
          onChange={handleSelectInsight}
          options={insightOptions}
          size={isMobileView ? "xs" : "sm"}
        />
      ) : (
        <Button
          // variant="gradient"
          onClick={() => setShowInsightSetupModal(true)}
          className="mr-4"
          size={isMobileView ? "xs" : "sm"}>
          Create New
        </Button>
      )}
    </>
  );
};
