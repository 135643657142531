// import { ColorScheme, ColorSchemeProvider } from "@mantine/core";
import {
  MantineProvider,
  createTheme,
  mergeThemeOverrides,
  useMantineColorScheme
} from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/code-highlight/styles.css";
import { MantineEmotionProvider, emotionTransform } from "@mantine/emotion";
import "mantine-datatable/styles.layer.css";
import "./App.module.css";

import useAppLifecycle from "@/hooks/useAppLifecycle";
import { AppLifecycleContext } from "@/lib/contexts/AppLifecycleContext";
import { APIUserService } from "@/lib/services/AuthService";
import { PADDLE_VENDOR_ID } from "@/lib/utils/Constants";
import { authRefreshTokenFromStorage } from "@/lib/utils/Storage";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import { AuthorizationLayout } from "@/ui/layouts/AuthorizationLayout";
import { AppMain } from "@/ui/pages/AppMain";
import { EmailVerificationCode } from "@/ui/pages/Auth/EmailVerificationCode";
import { EmailVerified } from "@/ui/pages/Auth/EmailVerified";
import { ForgotPassword } from "@/ui/pages/Auth/ForgotPassword";
import { JoinWorkspace } from "@/ui/pages/Auth/JoinWorkspace";
import { Login } from "@/ui/pages/Auth/Login";
import { Logout } from "@/ui/pages/Auth/Logout";
import { Register } from "@/ui/pages/Auth/Register";
import { ResetPassword } from "@/ui/pages/Auth/ResetPassword";
import { EventsRecommendations } from "@/ui/pages/EventsRecommendations/EventsRecommendations";
import { Home } from "@/ui/pages/Home/Home";
import { AllWorkspaces } from "@/ui/pages/Misc/AllWorkspaces";
import ProtectedRoute from "@/ui/pages/ProtectedRoute";
import PublicRoute from "@/ui/pages/PublicRoute";
import { ModalsProvider } from "@mantine/modals";
import React, { lazy, useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import "./App.scss";
// import { NotificationsProvider } from "@mantine/notifications";
import OnboardingMain from "@/ui/pages/Onboarding/OnboardingMain";
import { Global } from "@emotion/react";
import { Crisp } from "crisp-sdk-web";
import { getTheme } from "./Theme";
import InsightsShareableDashboardMain from "./ui/pages/InsightsShareableDashboard/InsightsShareableDashboardMain";
import ShareDashboardMain from "./ui/pages/ShareDashboard/ShareDashboardMain";
import useGlobalMantineTheme from "./hooks/useGlobalMantineTheme";

// const ShareDashboardMain = lazy(() => import("@/ui/pages/ShareDashboard/ShareDashboardMain"));
// const OnboardingMain = lazy(() => import("@/ui/pages/Onboarding/OnboardingMain"));

// import AdminUsersList from "@/ui/pages/Admin/AdminUsersList";
const AdminUsersList = lazy(() => import("@/ui/pages/Admin/AdminUsersList"));
if (process.env.REACT_APP_APP_ENV !== "prod") {
  // @ts-expect-error "Paddle is in script tag"
  Paddle.Environment.set("sandbox");
}
// @ts-expect-error "Paddle is in script tag"
Paddle.Setup({ vendor: parseInt(PADDLE_VENDOR_ID) });

const App = (props: any) => {
  const {
    user,
    setUser,
    isLoading,
    workspaces,
    setWorkspaces,
    activeWorkspace,
    setActiveWorkspace,
    isCollapsedLeftMenu,
    setIsCollapsedLeftMenu
  } = useAppLifecycle();
  useEffect(() => {
    // Apply the font size from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize") || "small";
    if (savedFontSize) {
      document.documentElement.style.setProperty("font-size", `var(--font-size-${savedFontSize})`);
    } else {
      document.documentElement.style.setProperty("font-size", "var(--font-size-default)");
    }

    // Crisp Chat Widget

    Crisp.configure("e80c07ae-0687-4e09-b9dc-22ad3bdf27ff", {
      // Disabled autoload in develop and ci env to avoid chat popup in test cases
      autoload:
        process.env.REACT_APP_APP_ENV !== "ci" && process.env.REACT_APP_APP_ENV !== "develop"
    });

    // Auth Token Refresh
    const intervalId = setInterval(() => {
      console.log("Send request for token refresh.");
      if (authRefreshTokenFromStorage()) {
        const authService = new APIUserService();
        authService.refreshToken();
      }
    }, 3600 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const location = useLocation();
  console.log(location.pathname, location.pathname.startsWith("/share/"));
  const [finalTheme, setFinalTheme] = useState(getTheme);
  const theme = useGlobalMantineTheme();

  useEffect(() => {
    // alert(theme.colorScheme);
    const theme2 = createTheme({
      primaryShade: 5,
      colors: {
        brand: [
          "#c2aedf", // Darkest
          "#a58cc7",
          "#8b72ad",
          "#735f94",
          "#5c4b7b",
          "#4d3d6b",
          "#40325b",
          "#33284b",
          "#291e3a",
          "#1f142a" // Lightest
        ]
      }
    });
    const finalTheme =
      theme.colorScheme === "dark" ? mergeThemeOverrides(getTheme, theme2) : getTheme;
    setFinalTheme(finalTheme);
    console.log("Theme", finalTheme);
  }, [theme.colorScheme]);

  return (
    <>
      <MantineProvider
        theme={finalTheme}
        stylesTransform={emotionTransform}
        defaultColorScheme="dark">
        <MantineEmotionProvider>
          <React.Suspense fallback={<LoadingComponent />}>
            <div>
              {!isLoading && (
                <ModalsProvider>
                  <AppLifecycleContext.Provider
                    value={{
                      user,
                      setUser,
                      isLoading,
                      workspaces,
                      setWorkspaces,
                      activeWorkspace,
                      setActiveWorkspace,
                      isCollapsedLeftMenu,
                      setIsCollapsedLeftMenu
                    }}>
                    {/* <BrowserRouter> */}
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                      <Routes>
                        <Route path="/share/:wsIdentifier/*" element={<ShareDashboardMain />} />
                        <Route
                          path="/insights/share/:wsIdentifier/*"
                          element={<InsightsShareableDashboardMain />}
                        />
                        <Route
                          path="/env/:workspaceIdentifier/*"
                          element={
                            <ProtectedRoute>
                              <Home />
                            </ProtectedRoute>
                          }></Route>
                        <Route
                          //  exact
                          path="/logout"
                          element={
                            <ProtectedRoute>
                              <Logout />
                            </ProtectedRoute>
                          }
                        />

                        <Route
                          // exact
                          path="/email-verification"
                          element={
                            <ProtectedRoute>
                              <EmailVerificationCode />
                            </ProtectedRoute>
                          }
                        />

                        <Route
                          path="/email-verified"
                          element={
                            <ProtectedRoute>
                              <EmailVerified />
                            </ProtectedRoute>
                          }
                        />

                        <Route
                          path="/workspaces"
                          element={
                            <ProtectedRoute>
                              <AllWorkspaces />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/events-recommendations"
                          element={
                            <ProtectedRoute>
                              <EventsRecommendations />
                            </ProtectedRoute>
                          }
                        />
                        {/*

                     */}
                        {/* Add workspace_id for the dashboard */}
                        {/* Onboarding for Tracking Pixel and Integration routes */}

                        <Route
                          path="/onboarding/*"
                          element={
                            <ProtectedRoute>
                              <OnboardingMain />
                            </ProtectedRoute>
                          }
                        />
                        {/* 
                    <Route
                      path="/oauth/callback/"
                      element={
                        <ProtectedRoute>
                          <IntegrationsOAuthVerifier />
                        </ProtectedRoute>
                      }
                    /> */}

                        <Route
                          path="/internal/users"
                          element={
                            <ProtectedRoute>
                              <AdminUsersList />
                            </ProtectedRoute>
                          }
                        />

                        <Route path="/" element={<AppMain />} />

                        <Route
                          path="/join-workspace/:token"
                          element={
                            <AuthorizationLayout>
                              <PublicRoute>
                                <JoinWorkspace />
                              </PublicRoute>
                            </AuthorizationLayout>
                          }
                        />

                        <Route
                          path="/reset-password"
                          element={
                            <AuthorizationLayout>
                              <PublicRoute>
                                <ResetPassword />
                              </PublicRoute>
                            </AuthorizationLayout>
                          }
                        />
                        <Route
                          path="/forgot-password"
                          element={
                            <AuthorizationLayout>
                              <PublicRoute>
                                <ForgotPassword />
                              </PublicRoute>
                            </AuthorizationLayout>
                          }
                        />
                        <Route
                          path={"/login"}
                          element={
                            <AuthorizationLayout>
                              <PublicRoute restricted={true}>
                                <Login />
                              </PublicRoute>
                            </AuthorizationLayout>
                          }
                        />
                        <Route
                          path="/register"
                          element={
                            <AuthorizationLayout variant={"subtle"}>
                              <PublicRoute restricted={true}>
                                <Register />
                              </PublicRoute>
                            </AuthorizationLayout>
                          }
                        />
                        {/* </AuthorizationLayout> */}
                        {/* <Redirect to="/login"></Redirect> */}
                      </Routes>
                    </QueryParamProvider>
                    {/* </BrowserRouter> */}
                    <ToastContainer />
                    <ReactTooltip effect="solid" />
                  </AppLifecycleContext.Provider>
                </ModalsProvider>
              )}
            </div>
          </React.Suspense>
        </MantineEmotionProvider>
      </MantineProvider>
    </>
  );
};

export default App;
