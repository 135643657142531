import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Paper,
  Switch,
  Text,
  Title
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import { useCustomizeMenuStore, IMenuItem } from "@/stores/useCustomizeMenuStore";
import { WorkspaceHiddenMenuService } from "@/lib/services/WorkspaceHiddenMenuService";
import {
  LEFT_MENU_TYPES,
  CONTACTS_HUB_MENU_ITEMS,
  ANALYZE_MENU_ITEMS,
  WORKSPACE_MEMBER_ROLES,
  PLAN_PRODUCT_TYPE,
  PRODUCT_INSIGHTS_MENU_ITEMS
} from "@/lib/utils/Constants";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

interface ISwitchComponentProps {
  checked: boolean;
  switchDisable: boolean;
  dataCy: string;
  onSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SwitchComponent: React.FC<ISwitchComponentProps> = ({
  checked,
  switchDisable,
  dataCy,
  onSwitchChange
}) => (
  <Group justify="center">
    <Switch
      checked={checked}
      onLabel="ON"
      offLabel="OFF"
      data-cy={dataCy}
      onChange={onSwitchChange}
      disabled={switchDisable}
      size="sm"
    />
  </Group>
);

interface IMenuItemGridProps {
  index: number;
  subIndex?: number | null;
  item: IMenuItem;
  span?: number;
  offset?: number;
  hiddenMenuItemsList: string[];
  isWebPlanType: boolean;
  onSwitchChange: (
    index: number,
    subIndex: number | null,
    item: IMenuItem,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const MenuItemGrid: React.FC<IMenuItemGridProps> = ({
  index,
  subIndex = null,
  item,
  span = 5,
  offset = 0,
  hiddenMenuItemsList,
  isWebPlanType,
  onSwitchChange
}) => {
  let visible = item.isVisible;
  let switchDisable = item.name === LEFT_MENU_TYPES.WebAnalytics ? true : false;

  if (item.name === LEFT_MENU_TYPES.ProductInsights) {
    visible = PRODUCT_INSIGHTS_MENU_ITEMS.every((item) => hiddenMenuItemsList.includes(item))
      ? false
      : true;
  }
  //
  // if(item.name === LEFT_MENU_TYPES.ContactsHub){
  //   visible = CONTACTS_HUB_MENU_ITEMS.every(item => hiddenMenuItemsList.includes(item)) ? false : true;
  // }

  if (item.name === LEFT_MENU_TYPES.Analyze) {
    visible = ANALYZE_MENU_ITEMS.every((item) => hiddenMenuItemsList.includes(item)) ? false : true;
  }

  return (
    <>
      <Grid
        pb="sm"
        key={index.toString() + item.name}
        style={{ padding: "10px" }}
        data-cy={`customize-menu-row-${item.name.toLowerCase().replace(/\s+/g, "-")}`}>
        <Grid.Col span={span} offset={offset / 2}>
          <Text fw={500}>{item.name}</Text>
        </Grid.Col>
        <Grid.Col span={span - offset * 1}>
          <SwitchComponent
            checked={visible}
            switchDisable={switchDisable}
            dataCy={`customize-menu-switch-${item.name.toLowerCase().replace(/\s+/g, "-")}`}
            onSwitchChange={(event) => onSwitchChange(index, subIndex, item, event)}
          />
        </Grid.Col>
      </Grid>
      <Divider />
    </>
  );
};

const CustomizeMenu = () => {
  const [menuItems, loading, fetchMenuItems, hiddenMenuItemsList, updateMenuItems] =
    useCustomizeMenuStore((state: any) => [
      state.menuItems,
      state.loading,
      state.fetchMenuItems,
      state.hiddenMenuItemsList,
      state.updateMenuItems
    ]);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const isWebPlanType = activeWorkspace.organization.plan.plan_type === PLAN_PRODUCT_TYPE.WEB;
  const { hasRolesNotWith } = useWorkspaceUtilityHook();

  useEffect(() => {
    document.title = "Customize Menu | Usermaven";
    fetchMenuItems(activeWorkspace.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSwitchChange = (
    index: number,
    subIndex: number | null,
    item: IMenuItem,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isVisible = event.currentTarget.checked;
    updateMenuItems(index, subIndex, { isVisible: isVisible });
    const service = new WorkspaceHiddenMenuService();

    if (item.subMenu) {
      const subMenuItemList = item.subMenu
        .filter((item) => item.hasOwnProperty("name"))
        .map((item) => item.name);
      service
        .createOrDeleteHiddenMenuItemsWithSubmenu(
          activeWorkspace.id,
          item.name,
          subMenuItemList,
          event.currentTarget.checked
        )
        .then((res) => {
          fetchMenuItems(activeWorkspace.id);
        });
    } else if (item.menu_id && isVisible === true) {
      service.deleteHiddenMenuItems(activeWorkspace.id, item.menu_id).then((res) => {
        fetchMenuItems(activeWorkspace.id);
      });
    } else {
      service.createHiddenMenuItem(activeWorkspace.id, item.name).then((res) => {
        fetchMenuItems(activeWorkspace.id);
      });
    }
  };

  if (
    hasRolesNotWith([
      WORKSPACE_MEMBER_ROLES.ADMINISTRATOR,
      WORKSPACE_MEMBER_ROLES.MANAGER,
      WORKSPACE_MEMBER_ROLES.OWNER
    ])
  ) {
    return (
      <>
        <Flex direction="column" py="md">
          <Box mb="md" c="red.7">
            <Center>
              <FontAwesomeIcon icon={solid("ban")} size="2xl" />
            </Center>
          </Box>

          <Title order={4} mb="md" ta="center">
            You do not have permission to access this resource
          </Title>
          <Container size={"sm"}>
            <Text ta="center">
              Your account owner{" "}
              <Text span fw={500}>
                {activeWorkspace.organization.creator.full_name} (
                {activeWorkspace.organization.creator.email})
              </Text>{" "}
              can only access this page. If you'd like to access this resource, please contact your
              organization owner.
            </Text>
          </Container>
        </Flex>
      </>
    );
  }

  return (
    <>
      <Title order={6} fw={600} mb="md">
        Personalize your interface by customising the left menu to suit your needs.
      </Title>

      {loading ? (
        <div className="um-settings-table--loader">
          <Oval height={16} width={16} />
        </div>
      ) : (
        menuItems.map((item: typeof menuItems, index: number) => {
          return (
            <div key={item.name}>
              <MenuItemGrid
                index={index}
                item={item}
                span={5}
                offset={0}
                hiddenMenuItemsList={hiddenMenuItemsList}
                isWebPlanType={isWebPlanType}
                onSwitchChange={handleSwitchChange}
              />
              {item.subMenu && (
                <>
                  {item.subMenu.map((subItem: typeof item.subMenu, subIndex: number) => (
                    <div key={subItem.name}>
                      <MenuItemGrid
                        index={index}
                        subIndex={subIndex}
                        item={subItem}
                        offset={1}
                        hiddenMenuItemsList={hiddenMenuItemsList}
                        isWebPlanType={isWebPlanType}
                        onSwitchChange={handleSwitchChange}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          );
        })
      )}
    </>
  );
};

export default CustomizeMenu;
