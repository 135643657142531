import { getAdminLoggedInStorage, resetAdminLoggedInStorage } from "@/lib/utils/Storage";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export const AdminAccessedBanner = ({ className }: { className: string }) => {
  const navigate = useNavigate();
  // if not logged in through admin, return nothing
  if (!getAdminLoggedInStorage()) {
    return <></>;
  }

  // if logged in through admin, return the banner
  return (
    <>
      <Alert className={className} color="red" variant="filled" fw={500} ta="center">
        <FontAwesomeIcon
          icon={regular("exclamation-circle")}
          className="text-white mr-2"
          size={"lg"}
        />
        You are logged in as administrator to this account. Please be cautious while making any
        change to this account.
        <Button
          ml={12}
          size="xs"
          onClick={() => {
            resetAdminLoggedInStorage();
            navigate("/logout");
          }}>
          Logout
        </Button>
      </Alert>
    </>
  );
};
