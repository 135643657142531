/**
 * Multi Select Dropdown Component
 * Used to select multiple checkboxes in a dropdown
 * @constructor
 */

import {
  Button,
  Checkbox,
  Box,
  Text,
  Collapse,
  TextInput,
  Tooltip,
  useMantineTheme,
  ActionIcon,
  useMantineColorScheme,
  Flex,
  Divider
} from "@mantine/core";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDetectClickOutside } from "react-detect-click-outside";
import { IColumn } from "../VirtualDataTable/VirtualDataTable";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface MultiSelectDropdownProps {
  /**
   * List of options to be displayed in the dropdown
   */
  options: IColumn[];

  /**
   * Callback function to be called when the options are selected
   * @param value
   */
  onChange: (value: Array<string>) => any;

  /**
   * Label to be displayed on the dropdown button
   */
  buttonText: string;

  /**
   * Indicator to show search input
   */
  withSearch?: boolean;

  /**
   * Placeholder text for search input
   */
  searchPlaceholder?: string;

  /**
   * Indicator to show dropdown header
   */
  withHeader?: boolean;

  /**
   * Header text for dropdown
   */
  headerText?: string;

  /**
   * Indicator to disable the dropdown
   */
  disabled?: boolean;

  /**
   * Default selected options
   */
  initialSelectedOptions?: Array<string>;

  /**
   * Indicator to show the reset button
   */
  withReset?: boolean;

  /**
   * Specifies the on reset callback
   */
  onReset?: () => any;
}

const MultiSelectDropdown = ({
  initialSelectedOptions = [],
  disabled = false,
  options = [],
  onChange,
  buttonText,
  withSearch = false,
  searchPlaceholder = "Search",
  withHeader = false,
  headerText = "Edit Columns",
  withReset = false,
  onReset = () => {}
}: MultiSelectDropdownProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [filterField, setFilterField] = useState("");
  const [collapsable, setCollapsable] = useState<Record<string, boolean>[]>([]);
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setFilterField("");
      setIsDropdownVisible(false);
    }
  });

  useEffect(() => {
    if (initialSelectedOptions.length > 0) {
      setSelectedOptions(initialSelectedOptions);
    }
  }, [initialSelectedOptions]);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsDropdownVisible(!isDropdownVisible);
    }
  };

  /**
   * Function to handle the checkbox selection
   * @param key
   */
  const handleCheckboxChange = (key: string) => {
    const newSelectedOptions = [...selectedOptions];
    const index = newSelectedOptions.indexOf(key);

    if (index > -1) {
      newSelectedOptions.splice(index, 1);
    } else {
      newSelectedOptions.push(key);
    }
    setSelectedOptions(newSelectedOptions);

    onChange(newSelectedOptions);
  };

  return (
    <Box ref={ref} pos="relative">
      <Tooltip label={"Show/Hide columns"}>
        <ActionIcon
          color={colorScheme === "dark" ? "dark.2" : "gray.7"}
          variant="subtle"
          disabled={disabled}
          size="sm"
          onClick={toggleDropdown}
          // icon={}
          // rightIcon={
          //   <>
          //     {isDropdownVisible ? (
          //       <>
          //         <FontAwesomeIcon icon={light("caret-up")}></FontAwesomeIcon>
          //       </>
          //     ) : (
          //       <>
          //         <FontAwesomeIcon icon={light("caret-down")}></FontAwesomeIcon>
          //       </>
          //     )}
          //   </>
          // }
        >
          {/* {buttonText} */}
          <FontAwesomeIcon icon={regular("columns-3")}></FontAwesomeIcon>
        </ActionIcon>
      </Tooltip>

      {isDropdownVisible && (
        <Box
          style={(theme) => ({
            position: "absolute",
            border: "1px solid",
            borderColor: colorScheme === "dark" ? theme.colors.dark[9] : "#dce0e3",
            width: "330px",
            background: colorScheme === "dark" ? theme.colors.dark[7] : "#fff",
            right: 0,
            marginTop: "1rem",
            zIndex: 10000,
            boxShadow:
              colorScheme === "dark"
                ? "0 5px 15px 0 rgba(0, 0, 0, 0.3)"
                : "0 5px 15px 0 rgba(204, 204, 212, 0.3)",
            borderRadius: "0.25rem"
          })}>
          {withHeader && (
            <>
              <Flex py="sm" align={"center"} justify={"center"}>
                <Text fw={500}>{headerText}</Text>
                {withReset && (
                  <Tooltip label="Reset to default" withArrow withinPortal>
                    <Button variant="light" size="xs" ml={"sm"} p={8} onClick={onReset}>
                      <FontAwesomeIcon icon={regular("redo-alt")}></FontAwesomeIcon>
                    </Button>
                  </Tooltip>
                )}
              </Flex>
              <Divider />
            </>
          )}

          {withSearch && (
            <>
              <Flex
                align={"center"}
                style={{
                  padding: "1rem 1rem",
                  display: "flex",
                  flexGrow: 1
                }}>
                <TextInput
                  size={"xs"}
                  w={"100%"}
                  placeholder={searchPlaceholder}
                  value={filterField}
                  onChange={(e) => setFilterField(e.target.value)}
                  leftSection={
                    <FontAwesomeIcon size="sm" icon={regular("search")}></FontAwesomeIcon>
                  }
                />
              </Flex>
              <Divider />
            </>
          )}

          <Flex
            direction={"column"}
            style={{
              padding: "1rem 1rem",
              flexGrow: 1,
              overflowY: "auto",
              maxHeight: "300px"
            }}>
            {options
              .filter(
                (option) =>
                  option.title && option.title.toLowerCase().includes(filterField.toLowerCase())
              )
              .map((option, index) => (
                <Flex
                  align={"center"}
                  key={option.key + index}
                  style={{ marginBottom: index === options.length - 1 ? "0rem" : "0.7rem" }}>
                  {option.children ? (
                    <Box>
                      <Flex
                        align={"center"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCollapsable({
                            ...collapsable,
                            [option.key]: !collapsable[option.key as any]
                          });
                        }}>
                        <Flex
                          align={"center"}
                          justify={"center"}
                          style={{
                            width: "16px",
                            height: "16px"
                          }}>
                          {collapsable[option.key as any] ? (
                            <>
                              <FontAwesomeIcon
                                size="xs"
                                icon={regular("chevron-down")}></FontAwesomeIcon>
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon
                                size="xs"
                                icon={regular("chevron-right")}></FontAwesomeIcon>
                            </>
                          )}
                        </Flex>

                        <Text size={"xs"} pl={12} tt="capitalize">
                          {option.title}
                        </Text>
                      </Flex>

                      <Collapse
                        mt={8}
                        in={option.key ? (collapsable as Record<string, any>)[option.key] : false}>
                        <Box ml={28}>
                          {option.children &&
                            option.children.map((child: any, index: number) => (
                              <Option
                                key={index}
                                option={child}
                                handleCheckboxChange={handleCheckboxChange}
                              />
                            ))}
                        </Box>
                      </Collapse>
                    </Box>
                  ) : (
                    <Option
                      key={option.key}
                      option={option}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  )}
                </Flex>
              ))}
          </Flex>
        </Box>
      )}
    </Box>
  );
};

const Option = ({
  option,
  handleCheckboxChange
}: {
  option: IColumn;
  handleCheckboxChange: (opt: string) => void;
}) => {
  return (
    <Checkbox
      size="xs"
      checked={option.visible}
      onChange={() => handleCheckboxChange(option.key)}
      label={option.displayName}
      styles={{
        label: {
          textTransform: "capitalize"
        }
      }}
    />
  );
};

export default MultiSelectDropdown;
