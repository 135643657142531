import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useConversionGoalsStore } from "@/stores/useConversionGoalsStore"
import { useWebFiltersModalStore } from "@/stores/useWebFiltersModalStore"
import {
  getAppliedFilterName,
  getAppliedFilterValue,
  getOperatorLabel,
  changeFilterOperator,
  getOperatorLabelClass,
} from "./FiltersUtility"

interface Props {
  item: string
  index: number
  query: any
  setQuery: any
}

export const FilterItem = ({ item, index, query, setQuery }: Props) => {
  const [setFilterKey, setFilterModalOpened, handleOnFilterValueClick] = useWebFiltersModalStore(
    state => [state.setFilterKey, state.setFilterModalOpened, state.handleOnFilterValueClick]
  )
  const [findGoalName] = useConversionGoalsStore(state => [state.findGoalName])
  return (
    <>
      <div className="px-3 py-2 flex items-center " key={`dropdown-filter-selection:${index}`}>
        <div className="flex flex-grow">
          <div className="text-black-900 pr-1">{getAppliedFilterName(item || "")}</div>{" "}
          <div
            className={` pr-1 font-medium ${getOperatorLabelClass(item)}  ${
              !item.startsWith("goal:")
                ? "hover:underline hover:text-opacity-90 cursor-pointer"
                : ""
            }`}
            onClick={e => {
              if (!item.startsWith("goal:")) {
                e.stopPropagation()
                changeFilterOperator(item, query, setQuery)
              }
            }}
          >
            {getOperatorLabel(item)}
          </div>
          <div
            className="font-semibold text-black-900 line-clamp-1 hover:underline"
            onClick={() => handleOnFilterValueClick(item)}
          >
            {item.startsWith("goal:") ? (
              <>{findGoalName(item.split(":")[1])}</>
            ) : (
              <>{getAppliedFilterValue(item || "")}</>
            )}
          </div>
        </div>

        <FontAwesomeIcon
          onClick={() => {
            setQuery({
              ...query,
              filters: [...query.filters.filter((f: string, i: number) => f !== item)],
            })
          }}
          className={`ml-3 hover:text-purple-500 transition-all ease-in-out `}
          icon={regular("times")}
        ></FontAwesomeIcon>
      </div>
    </>
  )
}
