/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import React from "react";
import { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import { SummaryCard } from "../SummaryCard";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import ContentLoader from "react-content-loader";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  topBarCardValues: ITopBarCardValues;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
  period: any;
  prevPeriod: any;
  comparisonPeriod?: any;
};

const BounceRateCard = ({
  activeCard,
  setActiveCard,
  topBarCardValues,
  comparisonTopBarCardValues,
  loading,
  query,
  period,
  prevPeriod,
  comparisonPeriod
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const getComparisonPercentageDiff = (currentValue: any, comparisonValue: any) => {
    const percentageChange = ((currentValue - comparisonValue) / comparisonValue) * 100;
    return Math.round(percentageChange);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="bounce-rate-card">
      {loading === "loaded" ? (
        <SummaryCard
          aboutTooltip="Bounce rate represents the percentage of single-page sessions. It is calculated by dividing the number of single-page sessions by the number of total sessions on the site."
          activeCard={activeCard}
          activeCardColor={"red.6"}
          title={"Bounce Rate"}
          onClick={() => setActiveCard("bounce_rate")}
          uniquekey={"bounceRate"}
          card={"bounce_rate"}
          value={`${topBarCardValues.bounce_rate}%`}
          tooltip={`${topBarCardValues.bounce_rate}% bounce rate`}
          runningDiff={topBarCardValues.bounce_rate_diff}
          runningDiffTooltip={`${topBarCardValues.bounce_rate_diff}% ${
            topBarCardValues.bounce_rate_diff > 0 ? "increase" : "decrease"
          } in bounce rate from previous period ${
            comparisonPeriod ? comparisonPeriod : prevPeriod
          } `}
          loading={loading}
          showRunningDiff={query?.period !== "live"}
          inverseColors={true}
          comparison={query?.comparison}
          comparisonPercentageDiff={getComparisonPercentageDiff(
            topBarCardValues.bounce_rate,
            comparisonTopBarCardValues.bounce_rate
          )}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const BounceRateCardMemoized = React.memo(BounceRateCard);
export { BounceRateCardMemoized as BounceRateCard };
