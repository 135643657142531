import { gray } from "@/config/theme";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import {
  DATE_FORMAT,
  LOADER_COLOR,
  PADDLE_SUBSCRIPTION_STATUS,
  PLAN_TYPES
} from "@/lib/utils/Constants";
import { getIntlDateWithoutWeekday } from "@/lib/utils/DateUtility";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { light, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Alert,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Loader,
  Progress,
  Table,
  Text,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { differenceInDays, format, subDays } from "date-fns";
import { FC, useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { NavLink, useLocation } from "react-router-dom";
import { WorkspaceType } from "types/types.d";
import { StringParam, useQueryParam } from "use-query-params";
import { SettingsHeaderContent } from "../Settings/SettingsHeaderContent/SettingsHeaderContent";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const useQuery = () => new URLSearchParams(useLocation().search);

interface BillingPlanProps {
  loading: string;
  plan: any;
  eventsConsumption: any;
  eventsConsumptionBreakdown: any;
  planLimits: any;
  fetchOrganizationPlan: () => void;
  subscriptions: any;
  fetchOrganizationSubscriptions: () => void;
  setActiveTab: (tab: string) => void;
}

export const BillingPlan: FC<BillingPlanProps> = ({
  loading,
  plan,
  eventsConsumption,
  eventsConsumptionBreakdown,
  planLimits,
  fetchOrganizationPlan,
  subscriptions,
  fetchOrganizationSubscriptions,
  setActiveTab
}) => {
  const theme = useGlobalMantineTheme();
  const [upgradeParam] = useQueryParam("upgrade", StringParam);
  const { activeWorkspace, workspaces } = useContext(AppLifecycleContext);
  const { colorScheme } = useMantineColorScheme();

  useEffect(() => {
    if (upgradeParam && loading === "loaded") {
      setActiveTab("plans");
    }
  }, [loading, upgradeParam]);

  const getOrganizationWorkspacesCount = () => {
    return workspaces.filter(
      (item: WorkspaceType) => item.organization.id === activeWorkspace.organization.id
    ).length;
  };

  const isStarterMembersLimitExceeded = () => {
    return workspaces
      .filter((item: WorkspaceType) => item.organization.id === activeWorkspace.organization.id)
      .some((item: WorkspaceType) => item.members.length > 2);
  };

  const renderPlanSummary = () => {
    return (
      <Grid w={"100%"}>
        <Grid.Col span={5}>
          <Box>
            <Flex align={"center"} h={100}>
              <FontAwesomeIcon
                icon={light("circle-bolt")}
                style={{ fontSize: "3rem" }}
                color={gray[900]}
              />
              <Flex direction={"column"} ml={16}>
                <Title order={5} mb={4} fw={600} tt="capitalize">
                  {plan?.name.replaceAll("-", " ")}
                </Title>
                <Text size={"sm"}>Plan</Text>
              </Flex>
            </Flex>
          </Box>
        </Grid.Col>
        <Grid.Col span={7}>
          <Box>
            <Flex align={"center"} h={100}>
              <FontAwesomeIcon
                icon={light("circle-calendar")}
                style={{ fontSize: "3rem" }}
                color={gray[900]}
              />
              <Flex direction={"column"} ml={16}>
                <Title order={5} mb={4} fw={600}>
                  {renderPlanDetails()}
                </Title>
                <Text size={"sm"}>Billing period</Text>
              </Flex>
            </Flex>
          </Box>
        </Grid.Col>
      </Grid>
    );
  };

  const renderPlanDetails = () => {
    if (plan.is_lifetime) {
      return "Lifetime";
    } else if (plan.name.includes(PLAN_TYPES.STARTER)) {
      return "Free";
    } else if (plan.name.endsWith("trial") && activeWorkspace?.organization) {
      if (activeWorkspace.organization.is_trial_expired) {
        return "Trial expired";
      } else {
        const trialExpiresAt = new Date(
          activeWorkspace.organization.trial_expires_at.replace(" ", "T")
        );
        const daysRemaining = differenceInDays(trialExpiresAt, new Date());
        return `Trial ends in ${daysRemaining} days`;
      }
    } else {
      return getBillingPeriod();
    }
  };

  const getBillingPeriod = () => {
    if (subscriptions.length > 0) {
      return `${getIntlDateWithoutWeekday(
        format(subDays(new Date(subscriptions[0].next_bill_date), 30), DATE_FORMAT)
      )} - ${getIntlDateWithoutWeekday(
        format(new Date(subscriptions[0].next_bill_date), DATE_FORMAT)
      )}`;
    }
    return "N/A";
  };

  const UsageProgress = () => {
    const isLifetime = plan.is_lifetime;
    const currentPlanPrice = planLimits.current_plan.price;
    const nextPlanPrice = planLimits?.next_plan?.price;
    const currentPlanEvents = numberToCommas(planLimits.current_plan.events);
    const nextPlanEvents = numberToCommas(planLimits?.next_plan?.events);
    const eventsConsumed = numberToCommas(eventsConsumption.total);
    const usagePercentage = Math.min(eventsConsumption.percentage, 100);

    const progressBarWidth = isLifetime ? "100%" : "60%";
    const separatorLeftPosition = isLifetime ? "100%" : "60%";
    const nextPlanProgressBarWidth = "40%";
    const currentPlanPriceMarginLeft = isLifetime ? "97%" : "calc(60% - 32px)";
    const currentPlanEventsMarginLeft = isLifetime ? undefined : "calc(20% - 32px)";
    return (
      <Box style={{ width: "70%" }}>
        <Text fw={500} mt={16}>
          Your usage
        </Text>
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Text size="sm" fw={500} style={{ marginLeft: currentPlanPriceMarginLeft }}>
            ${currentPlanPrice}
          </Text>
          {!isLifetime && (
            <Text size="sm" fw={500}>
              ${nextPlanPrice}
            </Text>
          )}
        </Box>
        <Box style={{ position: "relative", marginTop: "10px", marginBottom: "10px" }}>
          <Box style={{ width: progressBarWidth }}>
            <Tooltip
              label={`${eventsConsumed} events consumed. Limit resets on the first of every month.`}>
              <Progress
                value={usagePercentage}
                size="lg"
                color="violet"
                style={{ borderRadius: "5px" }}
              />
            </Tooltip>
          </Box>
          <Box
            style={{
              position: "absolute",
              top: 0,
              left: separatorLeftPosition,
              width: "2px",
              height: "100%",
              backgroundColor: colorScheme === "dark" ? theme.colors.dark[2] : "white",
              zIndex: 1
            }}
          />
          {!isLifetime && (
            <Tooltip label="Next plan limits">
              <Progress
                value={0}
                size="lg"
                color="gray"
                style={{
                  position: "absolute",
                  top: 0,
                  left: "60%",
                  width: nextPlanProgressBarWidth,
                  borderRadius: "5px"
                }}
              />
            </Tooltip>
          )}
        </Box>
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Text size="sm" fw={500}>
            0
          </Text>
          <Text size="sm" fw={500} style={{ marginLeft: currentPlanEventsMarginLeft }}>
            {currentPlanEvents} events
          </Text>
          {!isLifetime && (
            <Text size="sm" fw={500}>
              {nextPlanEvents} events
            </Text>
          )}
        </Box>
      </Box>
    );
  };

  const EventBreakdown = () => {
    const data = [
      { eventType: "Pageviews", usage: numberToCommas(eventsConsumptionBreakdown.pageviews) },
      {
        eventType: "Autocaptured events",
        usage: numberToCommas(eventsConsumptionBreakdown.autocaptured_events)
      },
      {
        eventType: "Custom events",
        usage: numberToCommas(eventsConsumptionBreakdown.custom_events)
      },
      { eventType: "Form Events", usage: numberToCommas(eventsConsumptionBreakdown.form_events) },
      {
        eventType: "Integration Events",
        usage: numberToCommas(eventsConsumptionBreakdown.integration_events)
      }
    ];

    const rows = data.map((row) => (
      <Table.Tr key={row.eventType}>
        <Table.Td>{row.eventType}</Table.Td>
        <Table.Td fw={500}>{row.usage}</Table.Td>
      </Table.Tr>
    ));

    return (
      <Box w={"40%"}>
        <Accordion>
          <Accordion.Item value="events">
            <Accordion.Control
              style={{
                fontSize: "1rem",
                paddingLeft: 8,
                borderRadius: 4,
                border: 0
              }}>
              View events breakdown
            </Accordion.Control>
            <Accordion.Panel>
              <Table withTableBorder ml={-16} mt={8}>
                <Table.Thead fw={600}>
                  <Table.Tr>
                    <Table.Th>Event type</Table.Th>
                    <Table.Th>Usage</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <tbody>{rows}</tbody>
              </Table>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>
    );
  };

  if (loading === "loading") {
    return (
      <Center my="lg">
        <Loader size="xs"></Loader>
      </Center>
    );
  }

  return (
    <>
      {loading === "loaded" && (
        <>
          {getOrganizationWorkspacesCount() > planLimits.current_plan.workspaces && (
            <div className="mb-4">
              <Alert
                icon={<FontAwesomeIcon icon={regular("warning")} className="text-2xl" />}
                color="red">
                Your plan allows only{" "}
                <span className="font-semibold">
                  {planLimits.current_plan.workspaces} workspace
                  {planLimits.current_plan.workspaces === 1 ? "" : "s"}
                </span>
                , you have{" "}
                <span className="font-semibold">{getOrganizationWorkspacesCount()} workspaces</span>
                . Please upgrade your plan or contact us to remove your workspace.
              </Alert>
            </div>
          )}
          {isStarterMembersLimitExceeded() &&
            activeWorkspace?.organization?.plan?.name.includes(PLAN_TYPES.STARTER) && (
              <Alert
                icon={<FontAwesomeIcon icon={regular("warning")} className="text-2xl" />}
                color="yellow">
                Your plan allows only <span className="font-semibold">1 team member</span>, you have
                more than 1 team members in your workspace(s). Please upgrade your plan or{" "}
                <NavLink to={`/env/${activeWorkspace.identifier}//settings/team`}>
                  remove your team members.
                </NavLink>
              </Alert>
            )}
        </>
      )}
      <Flex align={"center"} py="md">
        <Flex direction={"column"} flex={1}>
          <Title order={4} fw={600} mb="md">
            Plan Summary
          </Title>
          <Text>Your current plan and usage this month.</Text>
        </Flex>
        <Button onClick={() => setActiveTab("plans")}>Change</Button>
      </Flex>

      <Divider mb="md" />

      {loading === "loaded" ? (
        <>
          {renderPlanSummary()}
          <Divider mb={16} />
          {UsageProgress()}
          <Divider my={16} />
          <EventBreakdown />
        </>
      ) : (
        <div className="h-40 flex items-center justify-center">
          <Oval color={LOADER_COLOR} height={14} width={14} />
        </div>
      )}
    </>
  );
};

export default BillingPlan;
