/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import React from "react";
import { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import { SummaryComparisonCard } from "../SummaryComparisonCard";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import ContentLoader from "react-content-loader";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
};

const BounceRateComparisonCard = ({
  activeCard,
  setActiveCard,
  comparisonTopBarCardValues,
  loading,
  query
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="bounce-rate-card">
      {loading === "loaded" ? (
        <SummaryComparisonCard
          card="bounce_rate"
          activeCard={activeCard}
          onClick={() => setActiveCard("bounce_rate")}
          uniquekey={"bounceRate"}
          loading={loading}
          comparisonValue={`${comparisonTopBarCardValues.bounce_rate}%`}
          comparisonTooltip={`${comparisonTopBarCardValues.bounce_rate}% bounce rate`}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const SessionComparisonCardMemoized = React.memo(BounceRateComparisonCard);
export { SessionComparisonCardMemoized as BounceRateComparisonCard };
