import { useState, useEffect } from 'react';
import { APIUserService } from '@/lib/services/AuthService';
import { authUserStorageUpdate } from '@/lib/utils/Storage';
import { WorkspaceService } from '@/lib/services/WorkspaceService';
import { ThirdPartyTracking } from '@/lib/utils/ThirdPartyTracking';
import { WorkspaceType } from 'types/types.d';
import { useCustomizeMenuStore } from '@/stores/useCustomizeMenuStore';

export default function useAppLifecycle() {
  const [user, setUser] = useState(null);
  const [workspaces, setWorkspaces] = useState([]);
  const [activeWorkspace, setActiveWorkspace] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isCollapsedLeftMenu, setIsCollapsedLeftMenu] = useState(false);

  const fetchMenuItems = useCustomizeMenuStore((state: any) => state.fetchMenuItems)


  const findUserWorkspaceAndBilling = async () => {
    const userService = new APIUserService();
    let res = await userService
      .getProfile()
      .then((res) => {
        // console.log("APP: User retrieved successfully!", res.data);
        setUser(res.data);
        authUserStorageUpdate(res.data);
        return res.data;
      })
      .catch((err) => {
        // console.log("APP: User token expired or invalid.", err);
        // cleanAuthLocalStorage();
        // cleanAuthUserStorage();
        setUser(null);
        return false;
      });

    // If the user is logged in.
    let activeWorkspaceId = null;
    if (res || user) {
      const workspaceService = new WorkspaceService();
      const workspaces = await workspaceService
        .workspacesAccess()
        .then(async(res) => {
          console.log(`Workspace access response`, res);
          activeWorkspaceId = res.defaultWorkspace.id
          setActiveWorkspace(res.defaultWorkspace);
          setWorkspaces(res.workspacesList);
          await fetchMenuItems(activeWorkspaceId);
          return res;
        })
        .catch((err) => {});
      const thirdPartyTP = new ThirdPartyTracking(res, workspaces);
      thirdPartyTP.identify();
    }


    setLoading(false);

    return res;
  };

  useEffect(() => {
    findUserWorkspaceAndBilling();
  }, []);

  return {
    user,
    setUser,
    isLoading,
    workspaces,
    setWorkspaces,
    activeWorkspace,
    setActiveWorkspace,
    isCollapsedLeftMenu,
    setIsCollapsedLeftMenu
  };
}
