export const getSentimentClassNameFromValue = (value: number | undefined) => {
  if (!value) {
    return '';
  }
  if (value == 0) {
    return 'um-neutral';
  }
  if (value < 0) {
    return 'um-negative';
  }
  // positive value case.
  return 'um-positive';
};

/**
 * Rem to px conversion.
 */
export const remToPx = (rem: number) => {
  if (!document.documentElement) {
    return 0;
  }
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};
