import { Box, Flex, Select, SimpleGrid } from "@mantine/core";
import useInsightsListHook from "@/hooks/useInsightsListHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSegmentDropdownStore } from "@/stores/useSegmentDropdownStore";
import { useSegmentFilterStore } from "@/stores/useSegmentFilterStore";
import { useSegmentListStore } from "@/stores/useSegmentListStore";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { SegmentDropdownMenu } from "@/ui/components/App/Dropdowns/SegmentDropdownMenu";
import { ReportTypeToggle } from "@/ui/components/App/ReportTypeToggle/ReportTypeToggle";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import { DailyActiveUsers } from "@/ui/pages/Insights/Trends/ActiveUsers/Components/DailyActiveUsers";
import { DAUActiveUsers } from "@/ui/pages/Insights/Trends/ActiveUsers/Components/DAUActiveUsers";
import { DAUMAURatio } from "@/ui/pages/Insights/Trends/ActiveUsers/Components/DAUMAURatio";
import { DAUWAURatio } from "@/ui/pages/Insights/Trends/ActiveUsers/Components/DAUWAURatio";
import { MAUActiveUsers } from "@/ui/pages/Insights/Trends/ActiveUsers/Components/MAUActiveUsers";
import { MonthlyActiveUsers } from "@/ui/pages/Insights/Trends/ActiveUsers/Components/MonthlyActiveUsers";
import { UserStickinessRatio } from "@/ui/pages/Insights/Trends/ActiveUsers/Components/UserStickinessRatio";
import { WAUActiveUsers } from "@/ui/pages/Insights/Trends/ActiveUsers/Components/WAUActiveUsers";
import { WeeklyActiveUsers } from "@/ui/pages/Insights/Trends/ActiveUsers/Components/WeeklyActiveUsers";
import { L30Users } from "@/ui/pages/Insights/Trends/PowerUsers/Components/L30Users";
import { L7Users } from "@/ui/pages/Insights/Trends/PowerUsers/Components/L7Users";
import { PowerUsersActivity } from "@/ui/pages/Insights/Trends/PowerUsers/Components/PowerUsersActivity";
import { StringParam, useQueryParam } from "use-query-params";
import { IReportType, InsightTemplateType } from "../../../../types/types.d";
import { EditInsight } from "../Components/EditInsight/EditInsight";
import { InsightsDashboardSetupReport } from "../Components/InsightsDashboardSetupReport/InsightsDashboardSetupReport";
import {
  InsightsDashboardHeader,
  InsightsDashboardHeaderLeft,
  InsightsDashboardHeaderRight
} from "../Components/InsightsDashboardStyle/InsightsDashboardStyle.style";
import { ActiveUsersSetupModal } from "../../Insights/Setup/ActiveUsersSetupModal";
import PowerUsersSetupModal from "../../Insights/Setup/PowerUsersSetupModal";
import { IOutletContext } from "../InsightsDashboard";
import { groupInsights } from "@/lib/utils/InsightsUtility";

const InsightsDashboardEngagement = () => {
  /**
   * Segment filter store
   */
  const [filterVisible, setFilterVisible] = useSegmentFilterStore((state) => [
    state.filterVisible,
    state.setFilterVisible
  ]);

  /**
   * Segments dropdown store
   */
  const [setSelectedSegment] = useSegmentDropdownStore((state) => [state.setId]);

  /**
   * Segments Store
   */
  const [segments] = useSegmentListStore((state) => [state.segments]);

  /**
   * Segment Query builder store
   */
  const [filters, setFilters, resetExceptFilters, reset, setSegmentModalOpen] = useSegmentStore(
    (state) => [
      state.filters,
      state.setFilters,
      state.resetExceptFilters,
      state.reset,
      state.setModalOpen
    ]
  );

  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [insightId] = useQueryParam("insightId", StringParam);
  const [reportType, setReportType] = useState<IReportType>(
    activeWorkspace.reporting_insights_preference
  );
  const navigate = useNavigate();

  // states to manage the setup insights modal
  const [showInsightSetupModal, setShowInsightSetupModal] = useState(false);
  const { showManageInsightsModal }: IOutletContext = useOutletContext();

  const {
    insightsList,
    isInsightsLoading,
    getDefaultInsight,
    getInsightById,
    fetchInsightsList,
    getInsightReportType
  } = useInsightsListHook("adoption_engagement");

  useEffect(() => {
    document.title = "Insights | Product Engagement | Usermaven";
    if (isInsightsLoading === "loaded") {
      console.log("Component loaded... now get the data we need.");
    }

    return () => {
      reset();
    };
  }, [isInsightsLoading, reportType]);

  const data = insightsList.reduce((acc: any, item: any) => {
    const group =
      item.type.replaceAll("_", " ")[0].toUpperCase() + item.type.replaceAll("_", " ").slice(1);

    const existingGroup = acc.find((g: any) => g.group === group);
    if (existingGroup) {
      existingGroup.items.push({
        label: item.name,
        value: item.id
      });
    } else {
      acc.push({
        group,
        items: [
          {
            label: item.name,
            value: item.id
          }
        ]
      });
    }

    return acc;
  }, []);

  // adding this useffect to reload the list when the manage insights modal is closed
  // or when a new insight is created in the setup modal and insight id changes in the url
  useEffect(() => {
    if (!showInsightSetupModal && !showManageInsightsModal) {
      fetchInsightsList();
    }
  }, [showInsightSetupModal, showManageInsightsModal, insightId]);

  if (isInsightsLoading !== "loaded") {
    return <LoadingComponent />;
  }
  return (
    <>
      {getInsightReportType(insightId || getDefaultInsight()?.value) === "power_users" ? (
        <PowerUsersSetupModal
          isModalOpen={showInsightSetupModal}
          setIsModalOpen={setShowInsightSetupModal}
          insightId={insightId || getDefaultInsight()?.value}
        />
      ) : (
        // default to active users
        <ActiveUsersSetupModal
          isModalOpen={showInsightSetupModal}
          setIsModalOpen={setShowInsightSetupModal}
          insightId={insightId || getDefaultInsight()?.value}
        />
      )}
      {insightsList.length === 0 ? (
        <>
          <InsightsDashboardSetupReport
            title={"Create your first product engagement insight"}
            description={
              "Measure your key product metrics (DAU, WAU, MAU) and find power users for your application or feature."
            }
            buttonText={"Setup Insight"}
            setShowInsightSetupModal={setShowInsightSetupModal}></InsightsDashboardSetupReport>
        </>
      ) : (
        <>
          <Flex justify={"space-between"} mb={"lg"}>
            <Flex align={"center"}>
              <Select
                mr={4}
                variant="filled"
                size="sm"
                defaultValue={getDefaultInsight()?.value}
                // searchable
                w={270}
                onChange={(item: any) => {
                  navigate({ search: `insightId=${item}` });
                }}
                data={groupInsights(insightsList)}></Select>

              <EditInsight setShowInsightSetupModal={setShowInsightSetupModal} />
            </Flex>
            <Flex>
              <ReportTypeToggle
                reportType={reportType}
                setReportType={(value: IReportType) => {
                  reset();
                  setSelectedSegment("");

                  setReportType(value);
                }}
              />

              <Box className="ml-4">
                <SegmentDropdownMenu
                  type={reportType === "user" ? "user" : "company"}
                  onChange={(value: any) => {
                    if (!value) {
                      reset();
                      return;
                    }
                    setFilters(segments.find((s: any) => s.id === value).filters);
                    setFilterVisible(false);
                    // onApplyChange(segments.find((s: any) => s.id === value).filters)
                  }}
                />
              </Box>
            </Flex>
          </Flex>

          {getInsightById(insightId || getDefaultInsight()?.value)?.type === "active_users" ? (
            <>
              <SimpleGrid
                cols={{
                  base: 3,
                  md: 3,
                  sm: 2,
                  xs: 1
                }}
                mb={"md"}>
                <DAUActiveUsers
                  insightId={insightId || getDefaultInsight()?.value}
                  reportType={reportType}
                />
                <WAUActiveUsers
                  insightId={insightId || getDefaultInsight()?.value}
                  reportType={reportType}
                />
                <MAUActiveUsers
                  insightId={insightId || getDefaultInsight()?.value}
                  reportType={reportType}
                />
              </SimpleGrid>

              <UserStickinessRatio
                insightId={insightId || getDefaultInsight()?.value}
                reportType={reportType}></UserStickinessRatio>

              <DailyActiveUsers
                insightId={insightId || getDefaultInsight()?.value}
                reportType={reportType}
              />
              <WeeklyActiveUsers
                insightId={insightId || getDefaultInsight()?.value}
                reportType={reportType}
              />
              <MonthlyActiveUsers
                insightId={insightId || getDefaultInsight()?.value}
                reportType={reportType}
              />
              <DAUWAURatio
                insightId={insightId || getDefaultInsight()?.value}
                reportType={reportType}
              />
              <DAUMAURatio
                insightId={insightId || getDefaultInsight()?.value}
                reportType={reportType}
              />
              {/* </AudienceFiltersContext.Provider> */}
            </>
          ) : (
            <>
              <L7Users
                insightId={insightId || getDefaultInsight()?.value}
                reportType={reportType}
              />
              <L30Users
                insightId={insightId || getDefaultInsight()?.value}
                reportType={reportType}
              />
              <PowerUsersActivity
                insightId={insightId || getDefaultInsight()?.value}
                reportType={reportType}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default InsightsDashboardEngagement;
