/* eslint-disable react-hooks/exhaustive-deps */
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextInput, Text, useMantineTheme } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { useContext, useEffect, useRef, useState } from "react";
import { LoadingStateProps } from "types/types.d";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { ISearchedKeywordItemProps } from "../SearchedKeywords";
import { NoResultsDetailedView } from "./NoResultsDetailedView";
import { DynoTable, ProgressCell } from "@/ui/components/Common/DynoTable/DynoTable";
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const waService = new WebAnalyticsService();

export const SearchedKeywordsDetailedView = ({ query, opened, setOpened }: any) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const page = useRef(0);
  const [sort, setSort] = useState("total_clicks:desc");
  const [isSortApplied, setIsSortApplied] = useState(true);

  const theme = useGlobalMantineTheme();
  // handle search using debounce
  const [searchSources, setSearchSources] = useDebouncedState("", 200);

  // detailed modal view for listing.
  const [loadingDetailedList, setLoadingDetailedList] = useState<LoadingStateProps>("idle");
  const [detailedList, setDetailedList] = useState<Array<ISearchedKeywordItemProps>>([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);

  // fetching detailed list
  const fetchDetailedList = async () => {
    setLoadingDetailedList("loading");
    // adding page number to show more results
    console.log("page & sort", page, sort);
    page.current = page.current + 1;
    await waService
      .topSearchedKeywords(activeWorkspace.id, searchSources, 50, page.current, query, sort)
      .then((res) => {
        if (res.data.data) {
          // If the detailed list has already some results, then append the new results to the list.
          if (page.current === 1) {
            setDetailedList(res.data.data);
          } else {
            setDetailedList([...detailedList, ...res.data.data]);
          }

          if (res.data.columns) {
            // Modify columns before setting the state
            const modifiedColumns = res.data.columns.map((column: { accessor: string }) => {
              if (column.accessor === "query_with_value") {
                return {
                  ...column,
                  render: (data: any) => (
                    <ProgressCell
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                          }}>
                          <Text>{data.query}</Text>
                        </Box>
                      }
                    />
                  )
                };
              }
              if (["total_clicks", "total_impressions"].includes(column.accessor)) {
                return {
                  ...column,
                  header: (
                    <Box
                      sx={{
                        display: "flex"
                      }}
                      onClick={() => {
                        changeSortOrder(column.accessor); // visitors or bounce_rate
                      }}>
                      <Text>{toTitleCase(column.accessor)}</Text>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "4px"
                        }}>
                        <FontAwesomeIcon
                          icon={solid("sort")}
                          style={{
                            color: sort.startsWith(column.accessor)
                              ? theme.colors.brand[4]
                              : theme.colorScheme === "light"
                              ? theme.colors.gray[5]
                              : theme.colors.gray[7]
                          }}
                          className={`ml-2 transform   transition-all duration-200 ease-in-out`}
                        />
                      </Box>
                    </Box>
                  ),
                  render: (data: any) => <Text fw={400}>{numberToCommas(data)}</Text>
                };
              }
              if (["avg_position", "avg_ctr"].includes(column.accessor)) {
                return {
                  ...column,
                  header: (
                    <Box
                      sx={{
                        display: "flex"
                      }}
                      onClick={() => {
                        changeSortOrder(column.accessor); // visitors or bounce_rate
                      }}>
                      <Text>{toTitleCase(column.accessor)}</Text>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "4px"
                        }}>
                        <FontAwesomeIcon
                          icon={solid("sort")}
                          style={{
                            color: sort.startsWith(column.accessor)
                              ? theme.colors.brand[4]
                              : theme.colorScheme === "light"
                              ? theme.colors.gray[5]
                              : theme.colors.gray[7]
                          }}
                          className={`ml-2 transform   transition-all duration-200 ease-in-out`}
                        />
                      </Box>
                    </Box>
                  ),
                  render: (data: any) => <Text fw={400}>{data.toFixed(2)}</Text>
                };
              }
              return column;
            });
            setTableColumns(modifiedColumns);
          }

          // if the length of the results is less than 50, then there are no more results to show.
          if (res.data.data.length < 50) {
            setHasMore(false);
          }

          // Reset the sort applied to false after the data is fetched.
          setIsSortApplied(false);
        }
      })
      .catch((err) => {
        setHasMore(false);
      });
    setLoadingDetailedList("loaded");
  };

  // function to handle change in sort on clicking table header
  const changeSortOrder = (field: string) => {
    setIsSortApplied(true);
    let sortingKey;
    // if sort is already set to desc, then set it to asc and vice versa
    if (sort.endsWith(":desc")) {
      sortingKey = `${field}:asc`;
    } else if (sort.endsWith(":asc")) {
      sortingKey = `${field}:desc`;
    } else {
      sortingKey = `${field}:desc`;
    }
    // setting detailed list to empty and page to 1 so that new sorted data can be fetched
    setSort(sortingKey);
    setDetailedList([]);
    page.current = 0;
    setHasMore(true);
    setLoadingDetailedList("loading");
  };

  // on close of modal, set detailed list to empty and page to 1
  const onCloseModal = () => {
    setOpened(false);
    setDetailedList([]);
    setTableColumns([]);
    setSearchSources("");
    setSort("total_clicks:desc");
    page.current = 0;
  };

  // useEffect to fetch detailed list on opening modal
  useEffect(() => {
    // Send the service call when the modal is opened.
    // In the rest of the cases, the service call will not be sent.
    if (opened) {
      page.current = 0;
      setHasMore(true);
      setIsSortApplied(true);
      setLoadingDetailedList("loading");
      fetchDetailedList();
    }
  }, [opened, sort, searchSources]);
  return (
    <>
      <SidePanel loading={true} opened={opened} onCancel={onCloseModal} title={"Keywords"}>
        <div className="um-settings-wrapper pt-0">
          <div className="flex py-4 items-center">
            {/*search input to search sources*/}
            <TextInput
              data-autofocus
              leftSection={<FontAwesomeIcon icon={regular("search")} />}
              placeholder="Search by query"
              onChange={(e) => {
                setSearchSources(e.target.value);
              }}></TextInput>
          </div>

          {opened &&
          loadingDetailedList === "loaded" &&
          !isSortApplied &&
          detailedList.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
              <NoResultsDetailedView colspan={3} />
            </Box>
          ) : (
            <>
              <DynoTable
                columns={tableColumns}
                data={detailedList}
                fontSize={14}
                highlightOnHover={true}
                stripped={true}
                loading={loadingDetailedList === "loading" && isSortApplied ? true : false}
              />
              {!isSortApplied && hasMore && detailedList.length > 0 && (
                <div className="flex justify-center mt-4">
                  {/* Load more option to fetch the remaining results */}
                  <Button
                    loading={loadingDetailedList === ("loading" as LoadingStateProps)}
                    disabled={loadingDetailedList === ("loading" as LoadingStateProps)}
                    variant="outline"
                    color={theme.colorScheme === "dark" ? "dark.2" : "gray.7"}
                    onClick={() => fetchDetailedList()}>
                    Load more
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </SidePanel>
    </>
  );
};
