import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { differenceInDays } from "date-fns";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { DashboardService } from "@/lib/services/DashboardService";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import React, { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { blue, purple } from "@/style/Colors";
// @ts-ignore
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  Box,
  Center,
  Divider,
  Flex,
  Paper,
  RingProgress,
  SimpleGrid,
  Text,
  Title,
  Tooltip
} from "@mantine/core";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const UserStickinessDashboard = (data: any) => {
  // const _getDaysDurationTimeline = ()=>{
  //   if(data.data.length < 10)
  // }

  const theme = useGlobalMantineTheme();

  const { activeWorkspace } = useContext(AppLifecycleContext);
  // dau/mau states

  const [isDAUMAULoading, setIsDAUMAULoading] = useState("idle");
  const [DAUMAUData, setDAUMAUData] = useState<any>();

  // dau/wau states
  const [isDAUWAULoading, setIsDAUWAULoading] = useState("idle");
  const [DAUWAUData, setDAUWAUData] = useState<any>();

  const [firstEventDate, setFirstEventDate] = useState(activeWorkspace.created_at);

  // wau/mau states
  const [isWAUMAULoading, setIsWAUMAULoading] = useState("idle");
  const [WAUMAUData, setWAUMAUData] = useState<any>();

  const dashboardservice = new DashboardService(activeWorkspace.id);

  const fetchDAUMAUAvg = async () => {
    setIsDAUMAULoading("loading");
    await dashboardservice
      .userStickinessAvg(activeWorkspace.id, "dau_mau_avg")
      .then((res) => {
        setDAUMAUData(res.data.total);
      })
      .catch((err) => {
        setDAUMAUData(0);
      });
    setIsDAUMAULoading("loaded");
  };

  const fetchDAUWAUAvg = async () => {
    setIsDAUWAULoading("loading");
    await dashboardservice
      .userStickinessAvg(activeWorkspace.id, "dau_wau_avg")
      .then((res) => {
        setDAUWAUData(res.data.total);
      })
      .catch((err) => {
        setDAUWAUData(0);
      });
    setIsDAUWAULoading("loaded");
  };

  const fetchWorkspaceFirstEventDate = () => {
    const wsService = new WorkspaceService();
    wsService
      .firstEventDate(activeWorkspace.id)
      .then((res) => {
        setFirstEventDate(res.data.date);
      })
      .catch((err) => {
        setFirstEventDate("");
      });
  };

  const getDaysDifference = () => {
    let compareDate = firstEventDate === "" ? new Date() : new Date(firstEventDate);
    return differenceInDays(new Date(), compareDate);
  };

  // const fetchWAUMAUAvg = async () => {
  //   setIsWAUMAULoading("loading");
  //   await dashboardservice
  //     .userStickinessAvg(activeWorkspace.id, "wau_mau_avg")
  //     .then((res) => {
  //       setWAUMAUData(res.data.total);
  //     })
  //     .catch((err) => {});
  //   setIsWAUMAULoading("loaded");
  // };

  useEffect(() => {
    fetchDAUMAUAvg();
    fetchDAUWAUAvg();
    fetchWorkspaceFirstEventDate();
    ReactTooltip.rebuild();
    // fetchWAUMAUAvg();
  }, [activeWorkspace.id]);

  return (
    <>
      <Paper shadow="sm" withBorder mb="md" h={420}>
        <Flex align={"center"}>
          <Title order={5} fw={600} p="md">
            User Stickiness Metrics{" "}
          </Title>
          <Tooltip
            w={400}
            multiline
            label="Stickiness is generally calculated as the ratio of Daily Active Users to Monthly Active Users. A DAU/MAU ratio of 50% would mean that the average user of your app is using it 15 out of 30 days that month">
            <Box w={16}>
              <FontAwesomeIcon icon={light("circle-info")} />
            </Box>
          </Tooltip>
        </Flex>

        <Divider />

        <Box px="md">
          <SimpleGrid cols={{ base: 2, md: 2, sm: 1 }} spacing={"sm"} w={"100%"}>
            <div className="sm:h-80">
              {isDAUMAULoading === "loaded" ? (
                <>
                  <Flex justify="center" align="center" w={"100%"}>
                    <RingProgress
                      size={220}
                      thickness={30}
                      sections={[
                        {
                          value: DAUMAUData,
                          color: theme.colors.brand[5],
                          tooltip: `DAU/MAU Ratio is ${DAUMAUData}%`
                        }
                      ]}
                      label={
                        <Text
                          c={theme.colorScheme === "dark" ? "brand.2" : "brand.5"}
                          fw={700}
                          ta="center"
                          size="xl">
                          {DAUMAUData || 0}%
                        </Text>
                      }
                    />
                  </Flex>

                  <Flex align={"center"} direction={"column"}>
                    <Text mb="xs" fw={600}>
                      DAU/MAU
                    </Text>
                    <Text c="brand">
                      {getDaysDifference() < 31 && (
                        <>
                          Partial data
                          <Tooltip label="Based on partial data (As this metric is based on the value of monthly active users, the true value will appear after the first month.)">
                            <Box ml="xs">
                              <FontAwesomeIcon
                                className="ml-2 cursor-pointer"
                                icon={light("circle-info")}
                              />
                            </Box>
                          </Tooltip>
                        </>
                      )}
                    </Text>
                    <Text size="xs" c="dimmed" mb="xs" ta="center" px="sm">
                      DAU/MAU ratio measures how active monthly users are on a daily basis.
                    </Text>
                    <Text size="xs" c="dimmed">
                      (Last 30 days)
                    </Text>
                  </Flex>
                </>
              ) : (
                <>
                  <Center mt="xl">
                    <BarChartSkeleton></BarChartSkeleton>
                  </Center>
                </>
              )}
            </div>
            <div className="sm:h-80">
              {isDAUWAULoading === "loaded" ? (
                <>
                  <Flex justify="center" align="center" w={"100%"}>
                    <RingProgress
                      size={220}
                      thickness={30}
                      sections={[
                        {
                          value: DAUWAUData,
                          color: blue,
                          tooltip: `DAU/WAU Ratio is ${DAUWAUData}%`
                        }
                      ]}
                      label={
                        <Text c={blue} fw={700} ta="center" size="xl">
                          {DAUWAUData || 0}%
                        </Text>
                      }
                    />
                  </Flex>
                </>
              ) : (
                <Center mt="xl">
                  <BarChartSkeleton></BarChartSkeleton>
                </Center>
              )}
              {isDAUWAULoading === "loaded" && (
                <Flex align={"center"} direction={"column"}>
                  <Text mb="xs" fw={600}>
                    DAU/WAU
                  </Text>
                  <div>
                    {getDaysDifference() < 8 && (
                      <>
                        Partial data
                        <Tooltip label="Based on partial data (As this metric is based on the value of weekly active users, the true value will appear after the first week.)">
                          <Box ml="xs">
                            <FontAwesomeIcon
                              className="ml-2 cursor-pointer"
                              icon={light("circle-info")}
                            />
                          </Box>
                        </Tooltip>
                      </>
                    )}
                  </div>

                  <Text c="dimmed" size="xs" mb="xs" ta="center">
                    DAU/WAU ratio measures how active weekly users are on a daily basis.
                  </Text>
                  <Text c="dimmed" size="xs">
                    (Last 7 days)
                  </Text>
                </Flex>
              )}
            </div>
          </SimpleGrid>
        </Box>
      </Paper>
    </>
  );
};

const UserStickinessDashboardMemoized = React.memo(UserStickinessDashboard);
export { UserStickinessDashboardMemoized as UserStickinessDashboard };
