import axios from './JWTService';

type UpdateOrganizationDetailsProps = {
  name: string;
  billing_email?: string;
  plan_id?: string;
};

export class OrganizationService {
  findMyOrganizations = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('/v1/organizations')
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  plan = (organizationId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/organizations/${organizationId}/plan`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  subscriptions = (organizationId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/organizations/${organizationId}/subscription`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  lifetimeUpgradablePlans = (organizationId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/organizations/${organizationId}/lifetime_upgrade_plans`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  updateOrganizationDetails = (
    organizationId: string,
    payload: UpdateOrganizationDetailsProps
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/organizations/${organizationId}`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  applyModifier = (organizationId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/organizations/${organizationId}/apply_modifier`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  upgradePlanPrice = (organizationId: string, planName: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/organizations/${organizationId}/upgrade_plan_price/${planName}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  downgradePlanToStarter = (organizationId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/organizations/${organizationId}/downgrade_plan_to_starter`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  startTrial = (organizationId: string, name: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/organizations/${organizationId}/start_trial/${name}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
