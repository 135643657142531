import { StepCard } from "@/ui/components/Common/StepCard/StepCard";
import { Flex, Text, TextInput } from "@mantine/core";
import "./setup.scss";

interface NameSetupProps {
  name: string;
  placeholder?: string;
  setName: (e: any) => void;
}
export const NameSetup: React.FC<NameSetupProps> = ({
  name,
  setName,
  placeholder = "e.g Active users who published content..."
}) => {
  return (
    <>
      <StepCard step={1}>
        <Flex direction="column">
          <Text fw={600} mb="md">
            Set a name for your insight
          </Text>

          <TextInput
            w={400}
            className="mt-0"
            defaultValue={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder={placeholder}
            data-autofocus
          />
        </Flex>
      </StepCard>
    </>
  );
};
