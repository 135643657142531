import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { PLAN_PRODUCT_TYPE, PLAN_TYPES } from "@/lib/utils/Constants";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

export const ProductInsightsAuth = ({ children, disabled = false }: any) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  // Navigational guards.

  if (disabled) {
    return children;
  }

  if (activeWorkspace.organization.plan.plan_type === PLAN_PRODUCT_TYPE.WEB) {
    return (
      <>
        <Navigate to={`/env/${activeWorkspace.identifier}/product-insights-upgrade`} replace />
      </>
    );
  }
  return children;
};
