/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import {
  COMPARE_ANALYTICS_DROPDOWN_OPTIONS,
  DATE_FORMAT,
  DATE_RANGES,
  MOBILE_BREAKPOINT_QUERY
} from "@/lib/utils/Constants";
import useCompareAnalyticsStore from "@/stores/useCompareAnalyticsStore";
import MultiSelectMenu from "@/ui/components/Common/Dropdown/MultiSelectMenu";
import { light, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Box,
  Flex,
  Paper,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
  Text
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { differenceInDays, differenceInHours, format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { CompareAnalyticsDropdown } from "../../Dropdowns/CompareAnalyticsDropdownMenu";
import { DateDropdownMenu } from "../../Dropdowns/DateDropdownMenu";
import { getComparisonPeriod } from "../WebAnalyticsComparisonDatesUtilities";
import { getActiveDomainValue } from "../WebAnalyticsUtilities";
import { VisitorsOnlineCard } from "./../VisitorsOnlineCard";
import { FilterDropdown } from "./FilterDropdown";
import { Filters } from "./Filters";
import { SavedViews } from "./SavedViews";
import classes from "./FiltersNav.module.css";
import clsx from "clsx";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
export interface Domain {
  label: string;
  value: string;
  disabled?: boolean;
}
interface Props {
  query: any;
  setQuery: any;
  domains: Array<{ label: string; value: string }>;
  isPublic: boolean;
  refreshPage?: () => void;
}

export const FiltersNav = ({ query, setQuery, domains, isPublic, refreshPage }: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  // Comapre analytics state
  const [isCompareAnalyticsSelected] = useCompareAnalyticsStore((state: any) => [
    state.isCompareAnalyticsSelected
  ]);
  /** Scroll detection. */

  /** Web Analytics Comparison states. */

  // state to store the selected comparison period option that may either be previous_period or custom.
  const [selectedPeriodOption, setSelectedPeriodOption] = useState(
    COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD
  );

  // state to store the comparison dropdown label.
  const [selectedComparisonLabel, setSelectedComparisonLabel] = useState(
    COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD
  );
  /** Web Analytics Comparison states. */
  let defaultDomains: any = [];

  // If the query has domain assigned already, use those domains.
  if (query.domain) {
    // Split the domain string into an array of domains
    defaultDomains = query.domain.split(",").map((domain: any) => ({
      label: domain === "all_domains" ? "All Domains" : domain,
      value: domain
    }));
  } else {
    // Use the first domain or a default value if the domains list is empty
    const firstDomainValue = domains.length > 0 ? domains[0].value : "";
    const isAllDomains = firstDomainValue === "all_domains";
    const defaultDomainValue = isAllDomains
      ? getActiveDomainValue(domains, activeWorkspace.reporting_domain)
      : firstDomainValue;

    defaultDomains = [
      {
        label: isAllDomains ? "All Domains" : defaultDomainValue,
        value: defaultDomainValue
      }
    ];
  }

  const [allDomains, setAllDomains] = useState<Domain[]>(domains);
  const [selectedDomains, setSelectedDomains] = useState<Domain[]>(defaultDomains);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    setScrollPosition(scrolled);
  };

  const handleOnChange = (
    startDate: string,
    endDate: string,
    label: string,
    custom_range: boolean | undefined,
    interval: string | undefined
  ) => {
    let comparisonPeriodDates: {
      comparison_from_date: string | null | undefined;
      comparison_to_date: string | null | undefined;
    } = { comparison_from_date: undefined, comparison_to_date: undefined };
    // In case of real-time, we do not need to set the start date and end date
    if (label.toLowerCase() === "live") {
      const toDate = format(new Date(), DATE_FORMAT);
      // 30 minutes before the current time
      const fromDate = format(new Date(Date.now() - 30 * 60000), DATE_FORMAT);

      setQuery({
        ...query,
        period: "live",
        from_date: fromDate.replace(" ", "T"),
        to_date: toDate.replace(" ", "T"),
        comparison_from_date: undefined,
        comparison_to_date: undefined,
        custom_range: undefined
      });
    } else {
      // In case of other options, we will set the start date and end date along with period.
      let hours = differenceInHours(
        new Date(endDate.replace(" ", "T")),
        new Date(startDate.replace(" ", "T"))
      );
      let period;

      if (interval) {
        period = interval;
      } else {
        period = hours <= 24 ? "day" : label.toLowerCase() === "live" ? "live" : undefined;
      }

      if (query.comparison) {
        // Get the comparison period dates
        comparisonPeriodDates = getComparisonPeriod({
          from_date: startDate,
          to_date: endDate,
          period: interval ? interval : label,
          match_day_of_week: query.match_day_of_week,
          comparison: custom_range ? "custom" : "previous_period",
          custom_range,
          label: label
        });
      }

      setQuery({
        ...query,
        period: period,
        from_date: startDate.replace(" ", "T"),
        to_date: endDate.replace(" ", "T"),
        comparison: query.comparison ? (custom_range ? "custom" : "previous_period") : undefined, // Default comparison is 'previous_period'.
        comparison_from_date: comparisonPeriodDates.comparison_from_date,
        comparison_to_date: comparisonPeriodDates.comparison_to_date,
        custom_range: custom_range ? custom_range : undefined
      });

      setSelectedComparisonLabel(COMPARE_ANALYTICS_DROPDOWN_OPTIONS.PREVIOUS_PERIOD);
    }
  };

  const handleOnComparisonChange = (
    startDate: string,
    endDate: string,
    label: string,
    comparison?: string
  ) => {
    setQuery({
      ...query,
      comparison: comparison ? comparison : query.comparison,
      comparison_from_date: startDate.replace(" ", "T"),
      comparison_to_date: endDate.split(" ")[0] + "T23:59:59"
    });
    setSelectedComparisonLabel(label);
  };

  const getAllowedIntervals = () => {
    if (query.period === "live") {
      return [];
    }
    if (query?.from_date && query?.to_date) {
      if (
        differenceInHours(
          new Date(query.to_date.replace(" ", "T")),
          new Date(query.from_date.replace(" ", "T"))
        ) <= 24
      ) {
        return ["hour"];
      } else if (
        differenceInHours(
          new Date(query.to_date.replace(" ", "T")),
          new Date(query.from_date.replace(" ", "T"))
        ) > 23 &&
        differenceInHours(
          new Date(query.to_date.replace(" ", "T")),
          new Date(query.from_date.replace(" ", "T"))
        ) <= 168
      ) {
        return ["hour", "day"];
      } else if (
        differenceInDays(
          new Date(query.to_date.replace(" ", "T")),
          new Date(query.from_date.replace(" ", "T"))
        ) > 7 &&
        differenceInDays(
          new Date(query.to_date.replace(" ", "T")),
          new Date(query.from_date.replace(" ", "T"))
        ) <= 14
      ) {
        return ["hour", "day", "week"];
      } else if (
        differenceInDays(
          new Date(query.to_date.replace(" ", "T")),
          new Date(query.from_date.replace(" ", "T"))
        ) > 31 &&
        differenceInDays(
          new Date(query.to_date.replace(" ", "T")),
          new Date(query.from_date.replace(" ", "T"))
        ) <= 365
      ) {
        return ["hour", "day", "week", "month"];
      } else if (
        differenceInDays(
          new Date(query.to_date.replace(" ", "T")),
          new Date(query.from_date.replace(" ", "T"))
        ) > 365
      ) {
        return ["day", "week", "month"];
      }
    }
    return ["hour", "day", "week"];
  };

  const getWorkspaceIntervalFromCreationDate = () => {
    let interval = "day";
    if (!activeWorkspace?.created_at) return interval;
    if (differenceInDays(new Date(), new Date(activeWorkspace.created_at?.replace(" ", "T"))) < 7) {
      interval = "day";
    } else if (
      differenceInDays(new Date(), new Date(activeWorkspace.created_at?.replace(" ", "T"))) > 6 &&
      differenceInDays(new Date(), new Date(activeWorkspace.created_at?.replace(" ", "T"))) < 31
    ) {
      interval = "week";
    } else {
      interval = "month";
    }
  };

  const ALL_DOMAINS_VALUE = "all_domains";

  const handleDomainChange = (newSelectedDomains: Domain[]) => {
    // Set default domain if no domains are selected
    if (newSelectedDomains.length === 0) {
      let domain = getActiveDomainValue(domains, activeWorkspace.reporting_domain);
      newSelectedDomains = [
        { label: domain === "all_domains" ? "All Domains" : domain, value: domain }
      ];
    } else {
      // Check if 'all_domains' is selected
      const isAllDomainsSelected = newSelectedDomains.some(
        (domain) => domain.value === ALL_DOMAINS_VALUE
      );

      if (isAllDomainsSelected) {
        // Keep only 'all_domains' if it's selected
        newSelectedDomains = [{ label: "All Domains", value: ALL_DOMAINS_VALUE }];
      }
    }

    // Update the selected domains
    setSelectedDomains(newSelectedDomains);

    // Update query with the values of the selected domains
    setQuery({ ...query, domain: newSelectedDomains.map((domain) => domain.value) });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isCompareAnalyticsSelected) {
      setQuery({
        ...query,
        comparison: undefined,
        match_day_of_week: undefined,
        comparison_from_date: undefined,
        comparison_to_date: undefined
      });
    }
  }, [isCompareAnalyticsSelected]);

  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Paper
      style={{
        zIndex: isMobileView ? 1 : undefined,
        top: isMobileView ? "74px" : "0px",
        backgroundColor:
          colorScheme === "dark"
            ? scrollPosition > 0.03
              ? theme.colors.dark[7]
              : theme.colors.dark[9]
            : scrollPosition > 0.03
            ? "#bc93fe"
            : theme.colors.gray[0]
      }}
      px={
        scrollPosition > 0.03 && !isMobileView ? 16 : scrollPosition > 0.03 && isMobileView ? 8 : 0
      }
      my={"16"}
      shadow={scrollPosition > 0.03 ? "sm" : "none"}
      className={clsx(
        classes.box,
        scrollPosition > 0.03 ? classes.boxShadow : "",
        scrollPosition < 0.03 ? classes.noBorders : ""
      )}>
      <Flex flex={1} align={"center"}>
        <>
          {allDomains.length > 0 && allDomains[0].value !== "domain_not_found" ? (
            <>
              <MultiSelectMenu
                data={allDomains}
                scrollPosition={scrollPosition}
                defaultValue={activeWorkspace.reporting_domain}
                selectedData={selectedDomains}
                setSelectedData={setSelectedDomains}
                onChange={handleDomainChange}
              />
            </>
          ) : (
            <>
              <h3 className="mr-4 font-medium">Domain not found</h3>
              <FontAwesomeIcon
                className="mr-4 cursor-pointer"
                icon={light("circle-info")}
                data-tip={
                  "It looks like the tracking pixel installed doesn't match the domain URL provided in the workspace settings."
                }
                data-for={"domainMismatchTooltip"}
              />
              <ReactTooltip
                className="w-96 leading-6"
                id={"domainMismatchTooltip"}
                place="right"
                type="dark"
                effect="solid"
              />
            </>
          )}
        </>

        {query.filters.length > 0 ? (
          <>
            <Filters
              isPublic={isPublic}
              scrollPosition={scrollPosition}
              query={query}
              setQuery={setQuery}
            />
          </>
        ) : (
          <>
            <VisitorsOnlineCard query={query} isPublic={isPublic} scrollPosition={scrollPosition} />
          </>
        )}

        {isMobileView && (
          <SavedViews
            isPublic={isPublic}
            query={query}
            setQuery={setQuery}
            scrollPosition={scrollPosition}
            setSelectedDomains={setSelectedDomains}
          />
        )}
      </Flex>
      <Flex
        align={"center"}
        justify={"space-between"}
        flex={isMobileView ? 1 : undefined}
        className={
          isMobileView
            ? "flex flex-grow items-center w-full sm:w-auto sm:gap-0"
            : "my-2 sm:mt-0 md:mt-1.5 flex items-center justify-between w-full sm:w-auto flex-wrap sm:gap-0 gap-4"
        }>
        <Tooltip label="Refresh" withArrow style={{ fontSize: "12px" }}>
          <ActionIcon
            pl={8}
            pr={8}
            mx={16}
            autoContrast
            color={scrollPosition > 0.03 ? "white" : "gray.6"}
            onClick={() => {
              refreshPage && refreshPage();
            }}
            variant={scrollPosition > 0.03 ? "light" : "subtle"}>
            <FontAwesomeIcon icon={regular("arrows-rotate")} />
          </ActionIcon>
        </Tooltip>

        <FilterDropdown query={query} setQuery={setQuery} scrollPosition={scrollPosition} />
        {!isMobileView && (
          <SavedViews
            isPublic={isPublic}
            query={query}
            setQuery={setQuery}
            scrollPosition={scrollPosition}
            setSelectedDomains={setSelectedDomains}
          />
        )}
        <DateDropdownMenu
          scrollPosition={scrollPosition}
          onChange={handleOnChange}
          selectedPeriodOption={selectedPeriodOption}
          setSelectedPeriodOption={setSelectedPeriodOption}
          selectedComparisonLabel={selectedComparisonLabel}
          setSelectedComparisonLabel={setSelectedComparisonLabel}
          ranges={
            !isCompareAnalyticsSelected
              ? [{ label: "Live" }].concat(
                  DATE_RANGES.concat([
                    {
                      label: "All Time",
                      interval: getWorkspaceIntervalFromCreationDate(),
                      startDate: format(
                        new Date(activeWorkspace.created_at?.replace(" ", "T")),
                        DATE_FORMAT
                      ),
                      endDate: format(new Date(), DATE_FORMAT)
                    }
                  ])
                )
              : DATE_RANGES
          }
          selectedInterval={
            // These checks are only for 'All Time' filter
            query?.period &&
            ["day", "week"].includes(query?.period) &&
            getAllowedIntervals().indexOf("week") === 1
              ? getAllowedIntervals()[1]
              : query?.period &&
                query?.period === "month" &&
                getAllowedIntervals().indexOf("month") === 2
              ? getAllowedIntervals()[2]
              : !query?.period && getAllowedIntervals().indexOf("week") === 1
              ? getAllowedIntervals()[1]
              : query?.period
          }
          allowInterval={true}
          showComparisonOption={
            query && query.period ? (query?.period && query.period !== "live" ? true : false) : true
          }
          allowedIntervals={getAllowedIntervals()}
          onIntervalChange={(interval) => {
            setQuery({ ...query, period: interval });
          }}
        />

        {isCompareAnalyticsSelected && (
          <>
            <Text fz="sm" px={8}>
              vs.
            </Text>
            <CompareAnalyticsDropdown
              scrollPosition={scrollPosition}
              query={query}
              setQuery={setQuery}
              onChange={handleOnComparisonChange}
              selectedPeriodOption={selectedPeriodOption}
              setSelectedPeriodOption={setSelectedPeriodOption}
              selectedComparisonLabel={selectedComparisonLabel}
              setSelectedComparisonLabel={setSelectedComparisonLabel}
            />
          </>
        )}
      </Flex>
    </Paper>
  );
};
