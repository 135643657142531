import { Badge, Popover } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"

export const IntegrationSyncStatusRow = ({ integration }: any) => {
  const [opened, { close, open }] = useDisclosure(false)

  return (
    <>
      {integration.is_expired ? (
        <Popover width={200} position="bottom" withArrow shadow="md" opened={opened}>
          <Popover.Target>
            <span className="cursor-pointer" onMouseEnter={open} onMouseLeave={close}>
              <Badge color="red" radius={"sm"}>
                Error
              </Badge>
            </span>
          </Popover.Target>
          <Popover.Dropdown sx={{ pointerEvents: "none" }}>
            {integration.last_error}
          </Popover.Dropdown>
        </Popover>
      ) : (
        <Badge color="green.6" radius={"sm"}>
          Synced
        </Badge>
      )}
    </>
  )
}
