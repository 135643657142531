import { AutoCaptureEventProps } from "types/types.d";
import { Text } from "@mantine/core";
export const getAutoCapturedActionType = (eventType: string) => {
  return eventType === "click" ? "Clicked" : eventType === "submit" ? "Submitted" : "Changed";
};

export const getAutoCapturedEventTagTranslation = (tag: string) => {
  if (tag === "a") {
    return "link";
  }
  if (tag === "i") {
    return "icon";
  }
  if (tag === "button" || tag === "span" || tag === "div") {
    return "button";
  }
  return tag;
};

export const getACEventType = (event: AutoCaptureEventProps) => {
  const action = getAutoCapturedActionType(event.ac_event_type);

  if (event.ac_el_text) {
    if (event.ac_href) {
      return (
        <Text>
          {action}{" "}
          <Text span fw={600} tt="lowercase">
            {event.ac_tag_name}
          </Text>{" "}
          with text{" "}
          <Text span fw={600} tt="lowercase">
            {event.ac_el_text}
          </Text>
        </Text>
      );
    }
    return (
      <Text>
        {action}{" "}
        <Text span fw={600} tt="lowercase">
          {event.ac_tag_name}
        </Text>{" "}
        with text{" "}
        <Text span fw={600} tt="lowercase">
          {event.ac_el_text}
        </Text>
      </Text>
    );
  }

  if (event.ac_name) {
    return (
      <Text>
        {action}{" "}
        <Text span fw={600} tt="lowercase">
          {event.ac_tag_name}
        </Text>{" "}
        with name{" "}
        <Text span fw={600} tt="lowercase">
          {event.ac_name}
        </Text>
      </Text>
    );
  }
  if (event.ac_id) {
    return (
      <Text>
        {action}{" "}
        <Text span fw={600} tt="lowercase">
          {event.ac_tag_name}
        </Text>{" "}
        with id{" "}
        <Text span fw={600} tt="lowercase">
          {event.ac_el_text}
        </Text>
      </Text>
    );
  }
  if (event.ac_href) {
    return (
      <Text>
        {action}{" "}
        <Text span fw={600} tt="lowercase">
          {event.ac_tag_name}
        </Text>{" "}
        with link{" "}
        <Text span fw={600} tt="lowercase">
          {event.ac_el_text}
        </Text>
      </Text>
    );
  }
  return (
    <Text>
      {action}{" "}
      <Text span fw={600} tt="lowercase">
        {event.ac_tag_name}
      </Text>
    </Text>
  );
};

export const getGroupedACEventType = (event: any) => {
  const action = getAutoCapturedActionType(event.autocaptured_event_type);

  const tagName = getAutoCapturedEventTagTranslation(event.tag_name);

  if (event.text_label) {
    if (event.destination_url) {
      return (
        <Text>
          {action}{" "}
          <Text span fw={600} tt="lowercase">
            {tagName}
          </Text>
          with text{" "}
          <Text span fw={600} tt="lowercase">
            {event.text_label}
          </Text>
        </Text>
      );
    }
    return (
      <Text>
        {action}{" "}
        <Text span fw={600} tt="lowercase">
          {tagName}
        </Text>{" "}
        with text{" "}
        <Text span fw={600} tt="lowercase">
          {event.text_label}
        </Text>
      </Text>
    );
  }
  if (event.autocaptured_attr_name) {
    return (
      <Text>
        {action}{" "}
        <Text span fw={600} tt="lowercase">
          {tagName}
        </Text>{" "}
        with name{" "}
        <Text span fw={600} tt="lowercase">
          {event.autocaptured_attr_name}
        </Text>
      </Text>
    );
  }
  if (event.autocaptured_attr_id) {
    return (
      <Text>
        {action}{" "}
        <Text span fw={600} tt="lowercase">
          {tagName}
        </Text>{" "}
        with id{" "}
        <Text span fw={600} tt="lowercase">
          {event.autocaptured_attr_id}
        </Text>
      </Text>
    );
  }
  if (event.autocaptured_attr_class) {
    <Text>
      {action}{" "}
      <Text span fw={600} tt="lowercase">
        {tagName}
      </Text>{" "}
      with name{" "}
      <Text span fw={600} tt="lowercase">
        {event.autocaptured_attr_class}
      </Text>
    </Text>;
  }
  if (event.destination_url) {
    return (
      <Text>
        {action}{" "}
        <Text span fw={600} tt="lowercase">
          {tagName}
        </Text>{" "}
        with link{" "}
        <Text span fw={600} tt="lowercase">
          {event.destination_url}
        </Text>
      </Text>
    );
  }

  return (
    <Text>
      {action}{" "}
      <Text span fw={600} tt="lowercase">
        {tagName}
      </Text>{" "}
    </Text>
  );
};
