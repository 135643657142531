/* eslint-disable react-hooks/exhaustive-deps */
// @ts-ignore
import { useLocalStorage, useMediaQuery } from "@mantine/hooks";
import { LS_GRAPH_PREFERENCE, LS_WEB_CARD_GRAPH_PREFERENCE } from "@/lib/utils/Storage";
import React, { useContext, useEffect, useState } from "react";
import { ActiveCardProps, IAnnotationNoteVisibilityType, LoadingStateProps } from "types/types.d";
import { EventsCard } from "@/ui/components/App/WebAnalytics/Cards/Events/EventsCard";
import { EventsComparisonCard } from "@/ui/components/App/WebAnalytics/Cards/Events/EventsComparisonCard";
import { PageviewsCard } from "@/ui/components/App/WebAnalytics/Cards/Pageviews/PageviewsCard";
import { PageviewsComparisonCard } from "@/ui/components/App/WebAnalytics/Cards/Pageviews/PageviewsComparisonCard";
import { UniqueVisitorsCard } from "@/ui/components/App/WebAnalytics/Cards/Visitors/UniqueVisitorsCard";
import { UniqueVisitorsComparisonCard } from "@/ui/components/App/WebAnalytics/Cards/Visitors/UniqueVisitorsComparisonCard";
import { SessionCard } from "@/ui/components/App/WebAnalytics/Cards/Sessions/SessionCard";
import { SessionComparisonCard } from "@/ui/components/App/WebAnalytics/Cards/Sessions/SessionComparisonCard";
import { VisitDurationCard } from "@/ui/components/App/WebAnalytics/Cards/VisitDuration/VisitDurationCard";
import { VisitDurationComparisonCard } from "@/ui/components/App/WebAnalytics/Cards/VisitDuration/VisitDurationComparisonCard";
import { BounceRateCard } from "@/ui/components/App/WebAnalytics/Cards/BounceRate/BounceRateCard";
import { BounceRateComparisonCard } from "@/ui/components/App/WebAnalytics/Cards/BounceRate/BounceRateComparisonCard";
import { ChartSwitch } from "@/ui/components/App/WebAnalytics/ChartSwitch";
import {
  exportTopStats,
  getComparisonFormattedPeriod,
  getFormattedPeriod,
  getPrevPeriod
} from "./WebAnalyticsUtilities";
import {
  ActionIcon,
  Box,
  Flex,
  Paper,
  SimpleGrid,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import NotesPopoverCard from "../../Common/Notes/NotesPopoverCard";
import { AnnotationNotesService } from "@/lib/services/AnnotationNotesService";
import useAnnotationNotesStore, { Note } from "@/stores/useAnnotationNotesStore";
import GenericLineAndBarGraphWrapper from "./Graphs/GenericLineAndBarGraphWrapper";
import GenericMultiSeriesLineAndBarGraphWrapper from "./Graphs/GenericMultiSeriesLineAndBarGraphWrapper";
import { useLocation } from "react-router-dom";
import { differenceInDays, format, subDays } from "date-fns";
import useDeepCompareEffect from "@/hooks/useDeepCompareEffect";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { useWebFiltersStore } from "@/stores/useWebFiltersStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface ITopBarCardValues {
  pageviews: number;
  custom_events: number;
  autocaptured_events: number;
  events: number;
  visitors: number;
  sessions: number;
  visit_duration: number;
  bounce_rate: number;
  new_visitors: number;
  returning_visitors: number;
  pageviews_diff: number;
  events_diff: number;
  visitors_diff: number;
  sessions_diff: number;
  visit_duration_diff: number;
  bounce_rate_diff: number;
}

const DEFAULT_TOP_BAR_CARD_VALUES = {
  pageviews: 0,
  custom_events: 0,
  autocaptured_events: 0,
  events: 0,
  visitors: 0,
  sessions: 0,
  visit_duration: 0,
  bounce_rate: 0,
  new_visitors: 0,
  returning_visitors: 0,
  pageviews_diff: 0,
  events_diff: 0,
  visitors_diff: 0,
  sessions_diff: 0,
  visit_duration_diff: 0,
  bounce_rate_diff: 0
};

export interface ITimeSeriesDataItem {
  count: number;
  date: string;
}

export interface ITimeSeriesDataMetric {
  histogram: ITimeSeriesDataItem[];
  present_index: number;
}

export interface ITimeSeriesData {
  pageviews: ITimeSeriesDataMetric;
  custom_events: ITimeSeriesDataMetric;
  autocaptured_events: ITimeSeriesDataMetric;
  events: ITimeSeriesDataMetric;
  sessions: ITimeSeriesDataMetric;
  visit_duration: ITimeSeriesDataMetric;
  bounce_rate: ITimeSeriesDataMetric;
  new_visitors: ITimeSeriesDataMetric;
  returning_visitors: ITimeSeriesDataMetric;
  visitors: ITimeSeriesDataMetric;
}

// Define the default state
const DEFAULT_TIME_SERIES_STATE: ITimeSeriesData = {
  pageviews: { histogram: [], present_index: -1 },
  custom_events: { histogram: [], present_index: -1 },
  autocaptured_events: { histogram: [], present_index: -1 },
  events: { histogram: [], present_index: -1 },
  sessions: { histogram: [], present_index: -1 },
  visit_duration: { histogram: [], present_index: -1 },
  bounce_rate: { histogram: [], present_index: -1 },
  new_visitors: { histogram: [], present_index: -1 },
  returning_visitors: { histogram: [], present_index: -1 },
  visitors: { histogram: [], present_index: -1 }
};

const WebAnalyticsOverview = ({ query, setQuery, prevQueryRef, isPublic = false }: any) => {
  const { colorScheme } = useGlobalMantineTheme();
  const { activeWorkspace, user: activeUser } = useContext(AppLifecycleContext);
  const isStarter = useWorkspaceUtilityHook().isStarterPlan;
  // For the public dashboard, members are not going to be present.
  if (isPublic) {
    activeWorkspace.members = [];
  }
  const isActiveUserViewer = activeWorkspace?.members.some(
    (item: { user_id: any; role: { name: string } }) =>
      item.user_id === activeUser.id && item.role && item.role.name === "viewer"
  );

  let location = useLocation();
  const theme = useGlobalMantineTheme();
  const isMobileView = useMediaQuery("(max-width: 767px)");
  const [chartType, setChartType] = useLocalStorage<"line" | "bar">({
    key: LS_GRAPH_PREFERENCE,
    defaultValue: "bar",
    getInitialValueInEffect: false
  });

  const [activeCard, setActiveCard] = useLocalStorage<ActiveCardProps>({
    key: LS_WEB_CARD_GRAPH_PREFERENCE,
    defaultValue: "unique_visitors",
    getInitialValueInEffect: false
  });

  const [topBarSectionloading, setTopBarSectionloading] = useState<LoadingStateProps>("idle");
  const [topBarCardValues, setTopBarCardValues] = useState<ITopBarCardValues>(
    DEFAULT_TOP_BAR_CARD_VALUES
  );
  const [comparisonTopBarCardValues, setComparisonTopBarCardValues] = useState<ITopBarCardValues>(
    DEFAULT_TOP_BAR_CARD_VALUES
  );

  const [timeSeriesGraphloading, setTimeSeriesGraphloading] = useState<LoadingStateProps>("idle");
  const [timeSeriesGraphData, setTimeSeriesGraphData] =
    useState<ITimeSeriesData>(DEFAULT_TIME_SERIES_STATE);
  const [comparisonTimeSeriesGraphData, setComparisonTimeSeriesGraphData] =
    useState<ITimeSeriesData>(DEFAULT_TIME_SERIES_STATE);

  const formattedPeriod = getFormattedPeriod(query.from_date, query.to_date, "dd MMM yy");
  const formattedPrevPeriod = getPrevPeriod(query, "dd MMM yy");

  const waService = new WebAnalyticsService();
  const annotationNotesService = new AnnotationNotesService();

  const [notes, setNotes, allNotes, setAllNotes, addNote, updateNote, deleteNote] =
    useAnnotationNotesStore((state) => [
      state.notes,
      state.setNotes,
      state.allNotes,
      state.setAllNotes,
      state.addNote,
      state.updateNote,
      state.deleteNote
    ]);

  const [notesLoading, setNotesloading] = useState<LoadingStateProps>("idle");
  const [noteVisibilityType, setNoteVisibilityType] =
    useState<IAnnotationNoteVisibilityType>("all_users");

  const fetchTopBarSectionValues = async () => {
    // If the date range is more than a year, and period is undefined than make period default to week
    // NOTE: This is only for 'All Times' date range
    let isMoreThanYearInterval =
      query.from_date &&
      query.to_date &&
      query.period !== "live" &&
      differenceInDays(
        new Date(query.to_date.replace(" ", "T")),
        new Date(query.from_date.replace(" ", "T"))
      ) > 365;

    query = { ...query, period: !query?.period && isMoreThanYearInterval ? "week" : query?.period };
    setTopBarSectionloading("loading");
    await waService
      .topBarSection(activeWorkspace.id, "count", query)
      .then((res) => {
        if (res.data) {
          setTopBarCardValues(res.data.current_filtered_period);
          res.data.comparison_filtered_period &&
            setComparisonTopBarCardValues(res.data.comparison_filtered_period);
        }
      })
      .catch((err) => {
        setTopBarCardValues(DEFAULT_TOP_BAR_CARD_VALUES);
      });
    setTopBarSectionloading("loaded");
  };

  const fetchTopBarTimelineSeriesData = async () => {
    // If the date range is more than a year, and period is undefined than make period default to week
    // NOTE: This is only for 'All Times' date range
    let isMoreThanYearInterval =
      query.from_date &&
      query.to_date &&
      query.period !== "live" &&
      differenceInDays(
        new Date(query.to_date.replace(" ", "T")),
        new Date(query.from_date.replace(" ", "T"))
      ) > 365;

    query = { ...query, period: !query?.period && isMoreThanYearInterval ? "week" : query?.period };
    setTimeSeriesGraphloading("loading");
    await waService
      .topBarSection(activeWorkspace.id, "histogram", query)
      .then((res) => {
        if (res.data) {
          setTimeSeriesGraphData(res.data.current_filtered_period);
          res.data.comparison_filtered_period
            ? setComparisonTimeSeriesGraphData(res.data.comparison_filtered_period)
            : setComparisonTimeSeriesGraphData(DEFAULT_TIME_SERIES_STATE);
        }
      })
      .catch((err) => {
        setTopBarCardValues(DEFAULT_TOP_BAR_CARD_VALUES);
      });
    setTimeSeriesGraphloading("loaded");
  };

  const getAnnotationNotes = async (
    noteVisibilityType: IAnnotationNoteVisibilityType,
    page: number
  ) => {
    setNotesloading("loading");
    const user_id = noteVisibilityType === "only_me" ? activeUser.id : undefined;

    page = page ? page : 1;
    let limit = 50;

    await annotationNotesService
      .getNotes(activeWorkspace.id, query.from_date, query.to_date, page, limit, user_id)
      .then((res) => {
        if (res.data) {
          const apiResponse = res.data;
          const extractedNotes: Note[] = apiResponse.map((note: Note) => ({
            id: note.id,
            title: note.title,
            description: note.description,
            author: note.author,
            visibility: note.visibility,
            date: note.date,
            module: note.module,
            on_shareable_dashboard: note.on_shareable_dashboard,
            hide_note: note.hide_note
          }));
          if (page === 1) {
            setNotes(extractedNotes);
          } else {
            setNotes([...notes, ...extractedNotes]);
          }
          setNotesloading("loaded");
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const getAllAnnotationNotes = async (page: number) => {
    setNotesloading("loading");
    const user_id = undefined;

    page = page ? page : 1;
    let limit = 50;

    await annotationNotesService
      .getNotes(activeWorkspace.id, query.from_date, query.to_date, page, limit, user_id)
      .then((res) => {
        if (res.data) {
          const apiResponse = res.data;
          const extractedNotes: Note[] = apiResponse.map((note: Note) => ({
            id: note.id,
            title: note.title,
            description: note.description,
            author: note.author,
            visibility: note.visibility,
            date: note.date,
            module: note.module,
            on_shareable_dashboard: note.on_shareable_dashboard,
            hide_note: note.hide_note
          }));
          // setAllNotes([...allNotes, ...extractedNotes]);
          if (page === 1) {
            setAllNotes(extractedNotes);
          } else {
            setAllNotes([...allNotes, ...extractedNotes]);
          }
          setNotesloading("loaded");
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const createNewNote = async (data: Note) => {
    await annotationNotesService
      .createNote(activeWorkspace.id, data)
      .then((res) => {
        if (res.data) {
          const apiResponse = res.data;
          const newNote: Note = {
            id: apiResponse.id,
            title: apiResponse.title,
            description: apiResponse.description,
            author: apiResponse.author,
            visibility: apiResponse.visibility,
            date: apiResponse.date,
            module: apiResponse.module,
            on_shareable_dashboard: apiResponse.on_shareable_dashboard,
            hide_note: apiResponse.hide_note
          };
          addNote(newNote);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const updateExistingNote = async (data: Note) => {
    await annotationNotesService
      .updateNote(activeWorkspace.id, data.id, data)
      .then((res) => {
        if (res.data) {
          const apiResponse = res.data;
          const updatedNote: Note = {
            id: apiResponse.id,
            title: apiResponse.title,
            description: apiResponse.description,
            author: apiResponse.author,
            visibility: apiResponse.visibility,
            date: apiResponse.date,
            module: apiResponse.module,
            on_shareable_dashboard: apiResponse.on_shareable_dashboard,
            hide_note: apiResponse.hide_note
          };
          updateNote(updatedNote);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const deleteExistingNote = async (noteId: string) => {
    await annotationNotesService
      .deleteNote(activeWorkspace.id, noteId)
      .then((res) => {
        if (res.data) {
          deleteNote(noteId);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };
  const applyNoteChanges = async (type: string, data: Note) => {
    if (type === "create") {
      createNewNote(data);
    } else if (type === "update") {
      updateExistingNote(data);
    } else if (type === "delete") {
      deleteExistingNote(data.id);
    }

    return;
  };

  const setDateLabel = useWebFiltersStore((state) => state.setDateLabel);

  useDeepCompareEffect(() => {
    if (query.from_date === undefined && query.to_date === undefined && query.period !== "live") {
      // Set the default date range to last 30 days
      setDateLabel("Last 30 Days");
      const fromDate = format(subDays(new Date(), 30), "yyyy-MM-dd");
      const toDate = format(new Date(), "yyyy-MM-dd");

      setQuery({
        ...query,
        from_date: fromDate,
        to_date: toDate,
        period: query.period ? query.period : "day"
      });
    }

    setTimeSeriesGraphData(DEFAULT_TIME_SERIES_STATE);
    fetchTopBarSectionValues();
    fetchTopBarTimelineSeriesData();
  }, [query, query.filters, activeWorkspace.id]);

  useEffect(() => {
    if (query?.comparison !== undefined) {
      setChartType("line");
    }
  }, [query?.comparison]);

  useEffect(() => {
    if (isStarter) {
      setNotes([]);
      setAllNotes([]);
    } else {
      if (typeof noteVisibilityType !== "undefined") {
        getAnnotationNotes(noteVisibilityType, 1);
      }
      getAllAnnotationNotes(1);
    }
  }, [isPublic, noteVisibilityType, isStarter, activeWorkspace.id, query.from_date, query.to_date]);

  return (
    <Paper shadow="xs" withBorder p="lg" radius={"sm"} mih={300}>
      {!isMobileView ? (
        <Flex
          direction={"column"}
          component="div"
          // sx={(theme) => ({
          //   // borderColor:
          //   //   colorScheme === "dark"
          //   //     ? theme.colors.darkBorderColor[0]
          //   //     : theme.colors.lightBorderColor[0],
          //   // display: "flex",
          //   // padding: "2rem"
          //   // marginTop: "1rem",
          //   // marginBottom: "1rem",
          //   // flexDirection: "column"
          //   // borderRadius: "0.25rem",
          //   // borderWidth: "1px",
          //   // borderStyle: "solid",
          //   // boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)"
          // })}
        >
          {/* Current Period Cards Section */}
          {query.comparison && query?.period !== "live" && (
            <Text mb={4} fz="xs" ml={4}>
              Current Period:{" "}
              <Text span fw={500} fz="xs">
                {formattedPeriod}
              </Text>
            </Text>
          )}
          <Flex direction={"column"} gap={16} pos="relative">
            <SimpleGrid
              cols={{
                xs: 2,
                sm: 2,
                md: query?.period === "live" ? 4 : 3,
                lg: query?.period === "live" ? 4 : 4,
                xl: query?.period === "live" ? 4 : 6
              }}
              spacing="sm">
              <UniqueVisitorsCard
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                topBarCardValues={topBarCardValues}
                comparisonTopBarCardValues={comparisonTopBarCardValues}
                loading={topBarSectionloading}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
              <PageviewsCard
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                topBarCardValues={topBarCardValues}
                comparisonTopBarCardValues={comparisonTopBarCardValues}
                loading={topBarSectionloading}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
              {query?.period !== "live" && (
                <>
                  <SessionCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    topBarCardValues={topBarCardValues}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                    period={formattedPeriod}
                    prevPeriod={formattedPrevPeriod}
                    {...(query.comparison && {
                      comparisonPeriod: getComparisonFormattedPeriod(query)
                    })}
                  />
                  <VisitDurationCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    topBarCardValues={topBarCardValues}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                    period={formattedPeriod}
                    prevPeriod={formattedPrevPeriod}
                    {...(query.comparison && {
                      comparisonPeriod: getComparisonFormattedPeriod(query)
                    })}
                  />
                  <BounceRateCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    topBarCardValues={topBarCardValues}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                    period={formattedPeriod}
                    prevPeriod={formattedPrevPeriod}
                    {...(query.comparison && {
                      comparisonPeriod: getComparisonFormattedPeriod(query)
                    })}
                  />
                </>
              )}
              <EventsCard
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                topBarCardValues={topBarCardValues}
                comparisonTopBarCardValues={comparisonTopBarCardValues}
                loading={topBarSectionloading}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
            </SimpleGrid>

            <Flex
              style={{
                zIndex: 1
              }}
              direction={"row-reverse"}
              align={"flex"}
              justify={"flex-start"}>
              {!query.comparison && (
                <>
                  <ChartSwitch chartType={chartType} setChartType={setChartType} />
                  <Tooltip label="Download">
                    <ActionIcon
                      onClick={() => exportTopStats(timeSeriesGraphData)}
                      color="gray.9"
                      variant="subtle">
                      <FontAwesomeIcon icon={regular("download")}></FontAwesomeIcon>
                    </ActionIcon>
                  </Tooltip>

                  {/* <Box
                    onClick={() => exportTopStats(timeSeriesGraphData)}
                    sx={{
                      cursor: "pointer",
                      padding: "0.5rem", // Adjust padding as needed
                      margin: "0 0.5rem", // Adjust margin as needed
                      borderRadius: "0.25rem", // Adjust border radius as needed
                      "&:hover": {
                        backgroundColor:
                          colorScheme === "dark"
                            ? "rgba(25, 28, 36, 0.8)" // Dark mode background color with opacity 0.8
                            : "rgba(89, 92, 95 , 0.1)" // Light mode background color with opacity 0.8
                      }
                    }}>
                    <FontAwesomeIcon
                      className="text-lg text-gray-900"
                      icon={regular("download")}></FontAwesomeIcon>
                  </Box> */}
                </>
              )}
              {!query.comparison && !isPublic && (
                <>
                  <NotesPopoverCard
                    isStarter={isStarter}
                    isActiveUserViewer={isActiveUserViewer}
                    notes={notes}
                    allNotes={allNotes}
                    notesLoading={notesLoading}
                    applyNoteChanges={applyNoteChanges}
                    noteVisibilityType={noteVisibilityType}
                    setNoteVisibilityType={setNoteVisibilityType}
                    getAnnotationNotes={getAnnotationNotes}
                  />
                </>
              )}
            </Flex>
          </Flex>

          {/* Comparison Cards Section */}
          {query.comparison && (
            <>
              {query?.period !== "live" && (
                <Text fz="xs" my={"sm"} ml={4}>
                  Comparison Period:{" "}
                  <Text component="span" fw={500} fz="xs">
                    {getComparisonFormattedPeriod(query)}
                  </Text>
                </Text>
              )}
              <div className="flex relative flex-col gap-4">
                <SimpleGrid
                  spacing="md"
                  cols={{
                    xs: 2,
                    sm: 2,
                    md: query?.period === "live" ? 4 : 3,
                    lg: query?.period === "live" ? 4 : 4,
                    xl: query?.period === "live" ? 4 : 6
                  }}>
                  <UniqueVisitorsComparisonCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                  />
                  <PageviewsComparisonCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                  />
                  {query?.period !== "live" && (
                    <>
                      <SessionComparisonCard
                        activeCard={activeCard}
                        setActiveCard={setActiveCard}
                        comparisonTopBarCardValues={comparisonTopBarCardValues}
                        loading={topBarSectionloading}
                        query={query}
                      />
                      <VisitDurationComparisonCard
                        activeCard={activeCard}
                        setActiveCard={setActiveCard}
                        comparisonTopBarCardValues={comparisonTopBarCardValues}
                        loading={topBarSectionloading}
                        query={query}
                      />
                      <BounceRateComparisonCard
                        activeCard={activeCard}
                        setActiveCard={setActiveCard}
                        comparisonTopBarCardValues={comparisonTopBarCardValues}
                        loading={topBarSectionloading}
                        query={query}
                      />
                    </>
                  )}
                  <EventsComparisonCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                  />
                </SimpleGrid>
              </div>
            </>
          )}

          {/* Graphs Section */}
          {timeSeriesGraphloading === "loaded" ? (
            <div className="mt-4 hidden sm:flex flex-col h-[25rem] py-4 relative">
              {activeCard === "unique_visitors" &&
                (query.comparison ? (
                  <GenericLineAndBarGraphWrapper
                    query={query}
                    setQuery={setQuery}
                    activeCard={activeCard}
                    chartType={chartType}
                    graphColor="#7D47EB"
                    graphAreaColor="125, 71, 235"
                    comparisonGraphColor="#E7DBFF"
                    timeSeriesGraphData={{
                      histogram: timeSeriesGraphData.new_visitors.histogram.map((entry1) => ({
                        count:
                          entry1.count +
                          (timeSeriesGraphData.returning_visitors.histogram.find(
                            (e) => e.date === entry1.date
                          )?.count || 0),
                        date: entry1.date
                      })),
                      present_index: timeSeriesGraphData.new_visitors.present_index
                    }}
                    comparisonTimeSeriesGraphData={{
                      histogram: comparisonTimeSeriesGraphData.new_visitors.histogram.map(
                        (entry1) => ({
                          count:
                            entry1.count +
                            (comparisonTimeSeriesGraphData.returning_visitors.histogram.find(
                              (e) => e.date === entry1.date
                            )?.count || 0),
                          date: entry1.date
                        })
                      ),
                      present_index: comparisonTimeSeriesGraphData.new_visitors.present_index
                    }}
                    loading={timeSeriesGraphloading}
                    notes={allNotes}
                    dashboardUrl={location.pathname}
                  />
                ) : (
                  <GenericMultiSeriesLineAndBarGraphWrapper
                    colorScheme={colorScheme}
                    query={query}
                    setQuery={setQuery}
                    chartType={chartType}
                    firstSeries={{
                      name: "new_visitors",
                      data: timeSeriesGraphData.new_visitors
                    }}
                    secondSeries={{
                      name: "returning_visitors",
                      data: timeSeriesGraphData.returning_visitors
                    }}
                    loading={timeSeriesGraphloading}
                    notes={allNotes}
                    dashboardUrl={location.pathname}
                  />
                ))}
              {activeCard === "pageviews" && (
                <GenericLineAndBarGraphWrapper
                  query={query}
                  setQuery={setQuery}
                  activeCard={activeCard}
                  chartType={chartType}
                  graphColor="#7D47EB"
                  graphAreaColor="125, 71, 235"
                  comparisonGraphColor="#E7DBFF"
                  timeSeriesGraphData={timeSeriesGraphData.pageviews}
                  comparisonTimeSeriesGraphData={comparisonTimeSeriesGraphData.pageviews}
                  loading={timeSeriesGraphloading}
                  notes={allNotes}
                  dashboardUrl={location.pathname}
                />
              )}
              {activeCard === "events" &&
                (query.comparison ? (
                  <GenericLineAndBarGraphWrapper
                    query={query}
                    setQuery={setQuery}
                    activeCard={activeCard}
                    chartType={chartType}
                    graphColor="#7D47EB"
                    graphAreaColor="125, 71, 235"
                    comparisonGraphColor="#E7DBFF"
                    timeSeriesGraphData={{
                      histogram: timeSeriesGraphData.autocaptured_events.histogram.map(
                        (entry1) => ({
                          count:
                            entry1.count +
                            (timeSeriesGraphData.custom_events.histogram.find(
                              (e) => e.date === entry1.date
                            )?.count || 0),
                          date: entry1.date
                        })
                      ),
                      present_index: timeSeriesGraphData.autocaptured_events.present_index
                    }}
                    comparisonTimeSeriesGraphData={{
                      histogram: comparisonTimeSeriesGraphData.autocaptured_events.histogram.map(
                        (entry1) => ({
                          count:
                            entry1.count +
                            (comparisonTimeSeriesGraphData.custom_events.histogram.find(
                              (e) => e.date === entry1.date
                            )?.count || 0),
                          date: entry1.date
                        })
                      ),
                      present_index: comparisonTimeSeriesGraphData.autocaptured_events.present_index
                    }}
                    loading={timeSeriesGraphloading}
                    notes={allNotes}
                    dashboardUrl={location.pathname}
                  />
                ) : (
                  <GenericMultiSeriesLineAndBarGraphWrapper
                    colorScheme={colorScheme}
                    query={query}
                    setQuery={setQuery}
                    chartType={chartType}
                    firstSeries={{
                      name: "autocaptured_events",
                      data: timeSeriesGraphData.autocaptured_events
                    }}
                    secondSeries={{
                      name: "custom_events",
                      data: timeSeriesGraphData.custom_events
                    }}
                    loading={timeSeriesGraphloading}
                    notes={allNotes}
                    dashboardUrl={location.pathname}
                  />
                ))}
              {query?.period !== "live" && (
                <>
                  {activeCard === "sessions" && (
                    <GenericLineAndBarGraphWrapper
                      query={query}
                      setQuery={setQuery}
                      activeCard={activeCard}
                      chartType={chartType}
                      graphColor="#7D47EB"
                      graphAreaColor="125, 71, 235"
                      comparisonGraphColor="#E7DBFF"
                      timeSeriesGraphData={timeSeriesGraphData.sessions}
                      comparisonTimeSeriesGraphData={comparisonTimeSeriesGraphData.sessions}
                      loading={timeSeriesGraphloading}
                      notes={allNotes}
                      dashboardUrl={location.pathname}
                    />
                  )}
                  {activeCard === "bounce_rate" && (
                    <GenericLineAndBarGraphWrapper
                      query={query}
                      setQuery={setQuery}
                      activeCard={activeCard}
                      chartType={chartType}
                      graphColor="#FF5E5E"
                      graphAreaColor="255, 94, 94"
                      comparisonGraphColor="#ffbaba"
                      timeSeriesGraphData={timeSeriesGraphData.bounce_rate}
                      comparisonTimeSeriesGraphData={comparisonTimeSeriesGraphData.bounce_rate}
                      loading={timeSeriesGraphloading}
                      notes={allNotes}
                      dashboardUrl={location.pathname}
                    />
                  )}
                  {activeCard === "visit_duration" && (
                    <GenericLineAndBarGraphWrapper
                      query={query}
                      setQuery={setQuery}
                      activeCard={activeCard}
                      chartType={chartType}
                      graphColor="#26B774"
                      graphAreaColor="38, 183, 116"
                      comparisonGraphColor="#b6edd4"
                      timeSeriesGraphData={timeSeriesGraphData.visit_duration}
                      comparisonTimeSeriesGraphData={comparisonTimeSeriesGraphData.visit_duration}
                      loading={timeSeriesGraphloading}
                      notes={allNotes}
                      dashboardUrl={location.pathname}
                    />
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="mt-4 hidden sm:flex flex-col h-[25rem] py-4 relative">
              <BarChartSkeleton />
            </div>
          )}
        </Flex>
      ) : (
        <Box
          component="div"
          sx={(theme) => ({
            // borderColor:
            //   colorScheme === "dark"
            //     ? theme.colors.darkBorderColor[0]
            //     : theme.colors.lightBorderColor[0],
            display: "flex",
            // padding: "2rem",
            // marginTop: "1rem",
            // marginBottom: "1rem",
            flexDirection: "column",
            borderRadius: "0.25rem",
            // borderWidth: "1px",
            // borderStyle: "solid",
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)"
          })}>
          {!query.comparison ? (
            <SimpleGrid
              spacing="md"
              cols={{
                xs: 2,
                sm: 2,
                md: query?.period === "live" ? 4 : 3,
                lg: query?.period === "live" ? 4 : 4,
                xl: query?.period === "live" ? 4 : 6
              }}>
              <UniqueVisitorsCard
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                topBarCardValues={topBarCardValues}
                comparisonTopBarCardValues={comparisonTopBarCardValues}
                loading={topBarSectionloading}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
              <PageviewsCard
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                topBarCardValues={topBarCardValues}
                comparisonTopBarCardValues={comparisonTopBarCardValues}
                loading={topBarSectionloading}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
              {query?.period !== "live" && (
                <>
                  <SessionCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    topBarCardValues={topBarCardValues}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                    period={formattedPeriod}
                    prevPeriod={formattedPrevPeriod}
                    {...(query.comparison && {
                      comparisonPeriod: getComparisonFormattedPeriod(query)
                    })}
                  />
                  <VisitDurationCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    topBarCardValues={topBarCardValues}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                    period={formattedPeriod}
                    prevPeriod={formattedPrevPeriod}
                    {...(query.comparison && {
                      comparisonPeriod: getComparisonFormattedPeriod(query)
                    })}
                  />
                  <BounceRateCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    topBarCardValues={topBarCardValues}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                    period={formattedPeriod}
                    prevPeriod={formattedPrevPeriod}
                    {...(query.comparison && {
                      comparisonPeriod: getComparisonFormattedPeriod(query)
                    })}
                  />
                </>
              )}
              <EventsCard
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
            </SimpleGrid>
          ) : (
            <SimpleGrid
              cols={{
                xs: 2,
                sm: 2,
                md: query?.period === "live" ? 4 : 3,
                lg: query?.period === "live" ? 4 : 4,
                xl: query?.period === "live" ? 4 : 6
              }}
              spacing="md">
              <Text
                span
                className="mb-2"
                sx={(theme) => ({
                  color: colorScheme === "dark" ? theme.colors.gray[4] : theme.colors.gray[7],
                  fontSize: "0.875rem"
                })}>
                <p>Current Period:</p>
                <Box
                  component="span"
                  sx={(theme) => ({ color: colorScheme === "dark" ? "#ffffff" : "#000000" })}>
                  {formattedPeriod}
                </Box>
              </Text>
              <Text
                span
                className="mb-2"
                style={(theme) => ({
                  color: colorScheme === "dark" ? theme.colors.gray[4] : theme.colors.gray[7],
                  fontSize: "0.875rem"
                })}>
                <p>Comparison Period:</p>
                <Box
                  component="span"
                  style={(theme) => ({
                    color: colorScheme === "dark" ? "#ffffff" : "#000000"
                  })}>
                  {getComparisonFormattedPeriod(query)}
                </Box>
              </Text>

              <UniqueVisitorsCard
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                topBarCardValues={topBarCardValues}
                comparisonTopBarCardValues={comparisonTopBarCardValues}
                loading={topBarSectionloading}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
              <div className="mt-8">
                <UniqueVisitorsComparisonCard
                  activeCard={activeCard}
                  setActiveCard={setActiveCard}
                  comparisonTopBarCardValues={comparisonTopBarCardValues}
                  loading={topBarSectionloading}
                  query={query}
                />
              </div>
              <PageviewsCard
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                topBarCardValues={topBarCardValues}
                comparisonTopBarCardValues={comparisonTopBarCardValues}
                loading={topBarSectionloading}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
              <div className="mt-8">
                <PageviewsComparisonCard
                  activeCard={activeCard}
                  setActiveCard={setActiveCard}
                  comparisonTopBarCardValues={comparisonTopBarCardValues}
                  loading={topBarSectionloading}
                  query={query}
                />
              </div>
              {query?.period !== "live" && (
                <>
                  <SessionCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    topBarCardValues={topBarCardValues}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                    period={formattedPeriod}
                    prevPeriod={formattedPrevPeriod}
                    {...(query.comparison && {
                      comparisonPeriod: getComparisonFormattedPeriod(query)
                    })}
                  />
                  <div className="mt-8">
                    <SessionComparisonCard
                      activeCard={activeCard}
                      setActiveCard={setActiveCard}
                      comparisonTopBarCardValues={comparisonTopBarCardValues}
                      loading={topBarSectionloading}
                      query={query}
                    />
                  </div>
                  <VisitDurationCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    topBarCardValues={topBarCardValues}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                    period={formattedPeriod}
                    prevPeriod={formattedPrevPeriod}
                    {...(query.comparison && {
                      comparisonPeriod: getComparisonFormattedPeriod(query)
                    })}
                  />
                  <div className="mt-8">
                    <VisitDurationComparisonCard
                      activeCard={activeCard}
                      setActiveCard={setActiveCard}
                      comparisonTopBarCardValues={comparisonTopBarCardValues}
                      loading={topBarSectionloading}
                      query={query}
                    />
                  </div>
                  <BounceRateCard
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    topBarCardValues={topBarCardValues}
                    comparisonTopBarCardValues={comparisonTopBarCardValues}
                    loading={topBarSectionloading}
                    query={query}
                    period={formattedPeriod}
                    prevPeriod={formattedPrevPeriod}
                    {...(query.comparison && {
                      comparisonPeriod: getComparisonFormattedPeriod(query)
                    })}
                  />
                  <div className="mt-8">
                    <BounceRateComparisonCard
                      activeCard={activeCard}
                      setActiveCard={setActiveCard}
                      comparisonTopBarCardValues={comparisonTopBarCardValues}
                      loading={topBarSectionloading}
                      query={query}
                    />
                  </div>
                </>
              )}
              <EventsCard
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                query={query}
                period={query?.period !== "live" ? formattedPeriod : null}
                prevPeriod={query?.period !== "live" ? formattedPrevPeriod : null}
                {...(query.comparison && {
                  comparisonPeriod: getComparisonFormattedPeriod(query)
                })}
              />
              <div className="mt-8">
                <EventsComparisonCard
                  activeCard={activeCard}
                  setActiveCard={setActiveCard}
                  comparisonTopBarCardValues={comparisonTopBarCardValues}
                  loading={topBarSectionloading}
                  query={query}
                />
              </div>
            </SimpleGrid>
          )}
        </Box>
      )}
    </Paper>
  );
};

const WebAnalyticsOverviewMemoized = React.memo(WebAnalyticsOverview);
export { WebAnalyticsOverviewMemoized as WebAnalyticsOverview };
