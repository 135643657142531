import create from 'zustand';

interface Props {
  dateLabel: string;
  setDateLabel: (dateLabel: string) => void;
  filterKey: string;
  setFilterKey: (filterKey: string) => void;
  filterModalOpened: boolean;
  setFilterModalOpened: (filterModalOpened: boolean) => void;
}

export const useWebFiltersStore = create<Props>((set) => ({
  dateLabel: '',
  setDateLabel: (dateLabel: string) => set({ dateLabel }),
  filterKey: '',
  setFilterKey: (filterKey: string) => set({ filterKey }),
  filterModalOpened: false,
  setFilterModalOpened: (filterModalOpened: boolean) => set({ filterModalOpened })
}));

interface TimerStoreProps {
  timer: any;
  setTimer: (timer: any) => void;
}

export const useWebFiltersTimerStore = create<TimerStoreProps>((set) => ({
  timer: null,
  setTimer: (timer: any) => set({ timer })
}));
