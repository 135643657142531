import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { InsightsService } from "@/lib/services/InsightsService";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LooseObject } from "types/types.d";
import { StringParam, useQueryParam } from "use-query-params";

export default function useInsightsListHook(typeOfInsight: string) {
  const [insightId] = useQueryParam("insightId", StringParam);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [insightsList, setInsightsList] = useState([]);
  const [isInsightsLoading, setIsInsightsLoading] = useState("idle");

  // Get the default insight and set it to active. The statistics wil be returned base on this one.
  const getDefaultInsight = () => {
    if (insightId) {
      let insightById: Array<any> = insightsList.filter((item: any) => item.id === insightId);
      if (insightById.length > 0) {
        return {
          label: insightById[0]?.name,
          value: insightById[0]?.id
        };
      }
    }

    // from the default list
    let defaultReports: Array<any> = insightsList.filter((item: any) => item.is_default === true);
    if (defaultReports.length > 0) {
      console.log("Returning the first default report");
      return {
        label: defaultReports[0]?.name,
        value: defaultReports[0]?.id
      };
    }
  };
  const fetchInsightsList = async () => {
    setIsInsightsLoading("loading");
    const insightsService = new InsightsService();
    await insightsService
      .getAll(activeWorkspace.id, "name:asc", 1, "", typeOfInsight, 100)
      .then((res) => {
        setInsightsList(res.data);
      })
      .catch((err) => {});
    setIsInsightsLoading("loaded");
  };

  const getInsightById = (id: string): LooseObject => {
    const insightsById = insightsList.filter((item: any) => item.id === id);
    if (insightsById?.length > 0) {
      return insightsById[0];
    }
    return {};
  };

  const getInsightReportType = (id: string): string => {
    return getInsightById(insightId || getDefaultInsight()?.value)?.type;
  };

  useEffect(() => {
    fetchInsightsList();
  }, []);

  return {
    insightsList,
    getDefaultInsight,
    isInsightsLoading,
    getInsightById,
    getInsightReportType,
    setIsInsightsLoading,
    fetchInsightsList
  };
}
