import { StepCard } from "@/ui/components/Common/StepCard/StepCard";
import "./setup.scss";
import { Box, Select, Avatar, Alert, Text, Flex } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

interface OnboardingCompletionFunnelOrderSetupProps {
  funnelOrder: "strict_increase" | "strict_order";
  setFunnelOrder: (e: any) => void;
}

export const OnboardingCompletionFunnelOrderSetup: React.FC<
  OnboardingCompletionFunnelOrderSetupProps
> = ({ funnelOrder = "strict_increase", setFunnelOrder }) => {
  return (
    <>
      <StepCard step={3}>
        <Flex direction="column">
          <Text fw={600}>Onboarding Step Order</Text>
          <Text size="sm" c="dimmed" my="sm">
            Choose onboarding step order for your onboarding completion rate
          </Text>
          <Alert mb={"sm"} icon={<FontAwesomeIcon icon={regular("info-circle")} />}>
            <Text mb="sm">
              <Text span fw={600}>
                Sequential Order:
              </Text>{" "}
              Step B must happen after Step A, but any number of events can happen between Step A
              and B.{" "}
            </Text>

            <Text>
              <Text span fw={600}>
                Strict Order:
              </Text>{" "}
              Step B must happen directly after Step A without any events in between.
            </Text>
          </Alert>
          <Select
            w={250}
            onChange={setFunnelOrder}
            defaultValue={funnelOrder}
            data={[
              {
                label: "Sequential",
                value: "strict_increase"
              },
              {
                label: "Strict Order",
                value: "strict_order"
              }
            ]}></Select>
        </Flex>
      </StepCard>
    </>
  );
};
