import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { Center, Divider, Flex, Grid, Loader, Paper, ScrollArea, Text, Title } from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { LoadingStateProps } from "types/types.d";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import { EventsService } from "../../../../lib/services/EventsService";
import { EventDetailedViewPropertyValuesPopover } from "./EventDetailedViewPropertyValuesPopover";

export interface Props {
  title?: string;
  eventName: string;
  fromDate: string;
  toDate: string;
}

export const EventsDetailedPropertyCard: React.FC<Props> = ({
  title = "Properties",
  eventName,
  fromDate,
  toDate
}) => {
  const [opened, setOpened] = useState(false);

  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [properties, setProperties] = useState([]);

  const [loading, setLoading] = useState<LoadingStateProps>("idle");

  const fetchEventProperties = async () => {
    setLoading("loading");
    const eventsService = new EventsService();
    await eventsService
      .eventAttributes(activeWorkspace.id, eventName, fromDate, toDate)
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setProperties(res.data.data.keys ? res.data.data.keys : []);
        }
      })
      .catch((err) => {});
    setLoading("loaded");
  };

  useEffect(() => {
    fetchEventProperties();
  }, [fromDate, toDate]);

  return (
    <>
      <Paper withBorder component="div">
        <ScrollArea style={{ height: "24rem" }}>
          <Flex align={"center"} data-cy="event-detailed-activity-histogram-title">
            <Title p="md" order={5} fw={600} flex={1}>
              Properties
            </Title>
          </Flex>
          <Divider />
          {loading === "loaded" ? (
            <>
              {properties.length > 0 ? (
                properties.map((item: any) => (
                  <>
                    <Grid component="div" p="sm">
                      <Grid.Col span={6}>
                        <Text fw={600}>{item}</Text>
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <Flex justify={"center"} align={"center"}>
                          <EventDetailedViewPropertyValuesPopover
                            property={item}
                            event={eventName}
                            fromDate={fromDate}
                            toDate={toDate}
                          />
                        </Flex>
                      </Grid.Col>
                    </Grid>
                    <Divider />
                  </>
                ))
              ) : (
                <Center mt={64}>
                  <NoResults text={"There are no properties found."} heading={"No properties"} />
                </Center>
              )}
            </>
          ) : (
            <Center py="lg">
              <Loader size={16} />
            </Center>
          )}
        </ScrollArea>
      </Paper>
    </>
  );
};
