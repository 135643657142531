import { useMantineTheme } from "@mantine/core";
import { useEffect, useMemo, useState, useRef } from "react";
import { MantineTheme } from "@mantine/core";

export interface GlobalMantineTheme extends MantineTheme {
  colorScheme: string
}

const useGlobalMantineTheme = (): GlobalMantineTheme => {
  const theme = useMantineTheme();
  const [colorScheme, setColorScheme] = useState(
    document.documentElement.getAttribute("data-mantine-color-scheme") || "light"
  );
  const colorSchemeRef = useRef(colorScheme);

  useEffect(() => {
    colorSchemeRef.current = colorScheme;
  }, [colorScheme]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-mantine-color-scheme') {
          const newColorScheme = document.documentElement.getAttribute('data-mantine-color-scheme') || "light";
          if(newColorScheme !== colorSchemeRef.current) {
            setColorScheme(newColorScheme);
          }
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const globalTheme = useMemo(
    () => ({
      ...theme,
      colorScheme,
    }),
    [theme, colorScheme]
  );

  return globalTheme;
};

export default useGlobalMantineTheme;
