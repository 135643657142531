import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { PLAN_TYPES } from "@/lib/utils/Constants";
import { useContext } from "react";

/**
 * Custom hook to provide utility functions related to the workspace
 * @returns {Object} hasRolesWith, hasRolesNotWith, getActiveWorkspaceMemberRole, isStarterPlan
 */
export default function useWorkspaceUtilityHook() {
  const { activeWorkspace, user } = useContext(AppLifecycleContext);
  const getActiveWorkspaceMemberRole = () => {
    if (activeWorkspace && activeWorkspace.members) {
      const member = activeWorkspace.members.find((member: any) => member.user_id === user.id);
      return member.role.name;
    }
  };

  const hasRolesWith = (roles: string[]): boolean => {
    const userRole = getActiveWorkspaceMemberRole();
    return roles.includes(userRole);
  };

  const hasRolesNotWith = (roles: string[]): boolean => {
    const userRole = getActiveWorkspaceMemberRole();
    return !roles.includes(userRole);
  };

  /**
   * Checks if the active workspace plan is starter, starter legacy or starter badge program
   * @returns {boolean}
   */
  const isStarterPlan: boolean = activeWorkspace?.organization?.plan?.name.includes(
    PLAN_TYPES.STARTER
  );

  return {
    hasRolesWith,
    hasRolesNotWith,
    getActiveWorkspaceMemberRole,
    isStarterPlan
  };
}
