import create from 'zustand';

interface ISegmentDropdownStore {
  /**
   * Segment selected id
   */
  id: string;
  /**
   * Set selected segment id
   * */
  setId: (id: string) => void;
}
export const useSegmentDropdownStore = create<ISegmentDropdownStore>((set) => ({
  id: '',
  setId: (id: string) => set({ id })
}));
