import { Flex, Loader, Table } from "@mantine/core";
import { format } from "date-fns";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { UsersCRMService } from "@/lib/services/UsersCRMService";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { LoadingStateProps, MostEngagedUserItem } from "types/types.d";
import { ExportButton } from "@/ui/components/Common/ExportButton/ExportButton";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import {
  DATE_FORMAT,
  LOADER_COLOR,
  WORKSPACE_MEMBER_ROLES
} from "../../../../../lib/utils/Constants";
import { downloadCSVFromResponse } from "../../../../../lib/utils/CSVExporterUtility";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface Props {
  /**
   * detailedView will be true when the most engaged users detailed view modal is opened
   */
  detailedView: boolean;
  /**
   * Function to update the detailedView value
   */
  setDetailedView: (detailedView: boolean) => void;
  /**
   * Optional query parameters
   */
  query?: any;
}

/**
 * This component shows the detailed view modal for most engaged users.
 */
export const MostEngagedUsersDetailedView = ({ detailedView, setDetailedView, query }: Props) => {
  // Get user role in the workspace.
  const { hasRolesNotWith } = useWorkspaceUtilityHook();
  const theme = useGlobalMantineTheme();
  //Current active workspace.
  const { activeWorkspace } = useContext(AppLifecycleContext);
  //Loading and exporting state for async fetch and export calls.
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [exporting, setIsExporting] = useState("idle");
  //List to set the results of most engaged users.
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);
  //Async call to fetch the list of 100 most engaged users for the selected time period.
  const fetchEngagedUsersList = async () => {
    setLoading("loading");
    await new UsersCRMService()
      .mostEngagedUsers(activeWorkspace.id, 100, query)
      .then((res) => {
        setList(res.data);
      })
      .catch();
    setLoading("loaded");
  };

  //Service call to export most engaged users as CSV.
  const exportMostEngagedUsers = async () => {
    setIsExporting("loading");
    try {
      const response = await new UsersCRMService().mostEngagedUsersExport(
        activeWorkspace.id,
        100,
        query
      );
      if (response.data) {
        //Setting filename of the exported file in accordance with the date and time of export.
        let filename = `most_engaged_users_list_${format(new Date(), DATE_FORMAT)}.csv`;
        //Utility function to download the csv from response.
        downloadCSVFromResponse(response, filename);
        setIsExporting("loaded");
        return true;
      }
    } catch (err) {
      setIsExporting("loaded");
      throw new Error("400");
    }
    return false;
  };

  //Initial service call when the detailed view is set to true.
  useEffect(() => {
    if (detailedView) fetchEngagedUsersList();
  }, [detailedView]);

  const onRowClick = (item: any) => {
    setOverviewModalOpen(true, "user", item.user_id);
  };

  return (
    <>
      <SidePanel
        opened={detailedView}
        onCancel={() => setDetailedView(false)}
        loading={loading === "loaded"}
        title={`Top 100 most engaged users`}>
        {/*Export button which will allow the user to export list as csv. It will be set to disabled if it's in the loading state*/}
        <Flex direction={"row-reverse"} my="sm">
          {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
            <ExportButton
              onClick={exportMostEngagedUsers}
              disabled={exporting === "loading"}
              loading={exporting === "loading"}
            />
          )}
        </Flex>
        {/*If the list has some results then the results will be shown*/}
        {list.length > 0 ? (
          <div
            style={{
              overflowX: "auto",
              whiteSpace: "nowrap"
            }}>
            <Table
              striped
              highlightOnHover
              sx={() => ({
                "&[data-striped] tbody tr:nth-of-type(2n+1)": {
                  backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : ""
                }
              })}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th miw={260}>Email</Table.Th>
                  <Table.Th>First name</Table.Th>
                  <Table.Th>Last name</Table.Th>
                  <Table.Th>Hours used</Table.Th>
                  <Table.Th>Days used</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {list.map((item: MostEngagedUserItem, index) => (
                  // On clicking any user, it will navigate to the overview of that user
                  <Table.Tr
                    onClick={() => {
                      setDetailedView(false);
                      onRowClick(item);
                    }}
                    key={`engaged-user-list:${item.user_email}`}
                    className={"hover:underline transition-all ease-in-out cursor-pointer"}>
                    <Table.Td className="col-span-3">{item.user_email}</Table.Td>
                    <Table.Td>{item.user_first_name}</Table.Td>
                    <Table.Td>{item.user_last_name}</Table.Td>
                    <Table.Td>{item.hours}</Table.Td>
                    <Table.Td>{item.num_days_used_product}</Table.Td>
                    {/* <div>{item.longest_session_minutes}</div> */}
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </div>
        ) : (
          <>
            <Flex justify={"center"} align={"center"} py={"lg"}>
              <Loader size={"xs"} />
            </Flex>
          </>
        )}
      </SidePanel>
    </>
  );
};
