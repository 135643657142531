import { Button, Input, Title, Text, Anchor } from "@mantine/core";
import { AppLifecycleContext } from "@/lib/contexts/AppLifecycleContext";
import axios from "@/lib/services/JWTService";
import { UserService } from "@/lib/services/UserService";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";
import { AuthorizationLayout } from "@/ui/layouts/AuthorizationLayout";

interface IFormInputs {
  email_verification_code: string;
}
export const EmailVerificationCode = (props: any) => {
  const { user, setUser } = useContext(AppLifecycleContext);
  const [loadingState, setLoadingState] = useState("idle");
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Email Verification | Usermaven";
  }, []);
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm<IFormInputs>();
  const watchEmailVerificationCode = watch("email_verification_code");

  /**
   * Check if the string contains any alpha characters.
   * @param string email verification code.
   * @returns
   */
  const hasStringCharacter = (string: string): boolean => {
    return /[A-Za-z]/.test(string);
  };
  /**
   * Watch for the email verification code.
   */
  useEffect(() => {
    console.log(user);
    console.log(watchEmailVerificationCode);
    if (watchEmailVerificationCode && watchEmailVerificationCode.length === 6) {
      handleSubmit(onSubmit)();
    }
  }, [watchEmailVerificationCode]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * On submit button this function is called or if the character limit has hit threshold for 6 characters.
   * @param data
   * @returns
   */
  const onSubmit = (data: any) => {
    if (hasStringCharacter(data.email_verification_code)) {
      toast.error("Email verification code should be only digits.");
      return;
    }
    setLoadingState("loading");
    axios
      .post("/v1/auth/email_verification", data)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          user.is_email_verified = true;
          setUser(user);
          setLoadingState("idle");
          toast.success("Email verified successfully.");
          navigate("/onboarding/create-workspace");
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404 && err?.response?.data?.detail) {
          toast.error(err.response.data.detail);
        }
        setLoadingState("idle");
      });
  };

  const resendVerificationEmail = () => {
    const userService = new UserService()
      .resendEmailVerification()
      .then((res) => {
        if (res.data) {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {});
  };
  return (
    <AuthorizationLayout>
      <div className="auth-container--info">
        <Title order={3} mb={"lg"}>
          Please confirm your email!
        </Title>
        <Text ta="center" fz={14} mb="lg">
          We have sent you an email on{" "}
          <Anchor fz={14} href="#">
            {user.hasOwnProperty("email") && JSON.stringify(user.email)}
          </Anchor>
        </Text>

        <form onSubmit={handleSubmit(onSubmit)} className="py-4">
          <div className="">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
                <Input.Wrapper
                  label="Enter 6 digit code here"
                  required
                  className="w-60 mx-auto"
                  pb={12}>
                  <Input
                    pt={8}
                    width={200}
                    value={value}
                    placeholder="e.g 125470"
                    onChange={onChange}
                    error={(invalid || error) === true}
                  />
                </Input.Wrapper>
              )}
              name="email_verification_code"
              control={control}
              defaultValue=""
              rules={{ required: true, maxLength: 6, minLength: 6 }}
            />

            {errors.email_verification_code?.type === "required" && (
              <ValidationLabel
                validationType="error"
                text="Email verification code is required."
                size="small"></ValidationLabel>
            )}
            {(errors.email_verification_code?.type === "maxLength" ||
              errors.email_verification_code?.type === "minLength") && (
              <ValidationLabel
                validationType="error"
                text="Email Verification code must be 6 digits only."
                size="small"></ValidationLabel>
            )}
          </div>

          <div className="pt-4">
            <Button
              disabled={loadingState === "loading"}
              loading={loadingState === "loading"}
              type="submit">
              Continue
            </Button>
          </div>
        </form>

        <div className="auth-container--logout">
          <Text ta="center">
            <Anchor fz={"sm"} ta="center" onClick={() => resendVerificationEmail()}>
              {" "}
              Resend verification code
            </Anchor>
          </Text>
          <Text fz={"sm"}>
            Or,{" "}
            <Link to="/logout" className={""}>
              Logout
            </Link>
          </Text>
        </div>
      </div>
    </AuthorizationLayout>
  );
};
function useId() {
  throw new Error("Function not implemented.");
}
