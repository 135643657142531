import {
  ActionIcon,
  Badge,
  Box,
  CopyButton,
  Divider,
  Flex,
  Paper,
  Text,
  Tooltip,
  useMantineColorScheme
} from "@mantine/core";
import { truncateText } from "@/lib/utils/StringUtility";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useHover } from "@mantine/hooks";
interface Props {
  attribute_key: string;
  attribute_type?: string;
  attribute_value: string;
  attribute_cast_type?: string;
}
export const AttributeItem: React.FC<Props> = ({
  attribute_key,
  attribute_type,
  attribute_value,
  attribute_cast_type
}) => {
  const { colorScheme } = useMantineColorScheme();
  const { hovered, ref } = useHover();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  return (
    <>
      <Box px={"sm"} ref={ref}>
        <Box px={"xs"} component="div" py={"xs"} key={attribute_key}>
          <Flex justify={"space-between"}>
            <Tooltip label={attribute_key}>
              <Text size="xs" mb="xs" fw={500} data-for={`attribute-tooltip:${attribute_key}`}>
                {truncateText(attribute_key, 45)}
              </Text>
            </Tooltip>

            <ReactTooltip
              id={`attribute-tooltip:${attribute_key}`}
              place="top"
              type="dark"
              effect="solid"
            />
            {attribute_type && attribute_type.length > 0 && (
              <Badge
                variant="light"
                radius="sm"
                size={"sm"}
                color={colorScheme === "light" ? "black" : "dark.2"}
                fw={500}>
                {attribute_type}
              </Badge>
            )}
          </Flex>
          <Flex align={"center"} mb="xs">
            <Text size="xs" truncate lineClamp={1} fw={600}>
              {attribute_cast_type === "number" && <>{attribute_value}</>}
              {attribute_cast_type === "string" && (
                <>
                  {attribute_value &&
                  attribute_value.length > 0 &&
                  attribute_value !== "1970-01-01 00:00:00" ? (
                    <>{truncateText(attribute_value, 45)}</>
                  ) : (
                    <>...</>
                  )}
                </>
              )}
            </Text>
            {hovered && (
              <CopyButton value="https://mantine.dev" timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                    <ActionIcon
                      ml={"xs"}
                      color={copied ? "teal" : "gray"}
                      variant="subtle"
                      size={"compact-xs"}
                      onClick={copy}>
                      {copied ? (
                        <FontAwesomeIcon size="xs" icon={regular("check")} />
                      ) : (
                        <FontAwesomeIcon size="xs" icon={regular("clipboard")} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            )}
          </Flex>
        </Box>
      </Box>
      <Divider />
    </>
  );
};
