// @ts-ignore
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { EventsService } from "@/lib/services/EventsService";
import axios from "@/lib/services/JWTService";
import { ClickhouseDateToLocalRelativeTime } from "@/lib/utils/DateUtility";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { PulseLineGraph } from "@/ui/components/App/PulseLineGraph/PulseLineGraph";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Anchor,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Loader,
  Skeleton,
  Space,
  Switch,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { useDebouncedValue, useMediaQuery } from "@mantine/hooks";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { format } from "date-fns-tz";
import { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { LooseObject } from "types/types.d";
import { DATE_FORMAT } from "../../../lib/utils/Constants";
import { EventsDetailedActivity } from "./Components/EventsDetailedActivity";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const CustomEvents = () => {
  const theme = useGlobalMantineTheme();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const { activeWorkspace } = useContext(AppLifecycleContext);
  // Search input using debounce
  // To avoid multiple calls, it will wait for 500 milliseconds, if there is no change, then request will be sent.
  // const [searchEvent, setSearchEvent] = useState("")
  const [eventsList, setEventsList] = useState<Array<LooseObject>>([]);
  const [eventsHistoryList, setEventsHistoryList] = useState<LooseObject>({});
  // const handleDebounceSearchFn = (inputValue: any) => {
  //   fetchGroupedEvents(inputValue)
  // }
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [loading, setIsLoading] = useState("idle");
  const [historyLoading, setHistoryLoading] = useState("idle");
  const [sort, setSort] = useState("count:desc");
  const eventsService = new EventsService();
  // const searchDebounceFn = useCallback(_debounce(handleDebounceSearchFn, 500), [])
  const [searchField, setSearchField] = useState("");
  const [debounced] = useDebouncedValue(searchField, 200);

  // Individual custom event activity view state
  const [eventDetailedActivityView, setEventDetailedActivityView] = useState(false);

  // Selected event for which detailed activity will be shown
  const [selectedEvent, setSelectedEvent] = useState("");

  // States for showing first occured and last occured in event activity detailed view
  const [firstOccurred, setFirstOccurred] = useState("");
  const [lastOccurred, setLastOccurred] = useState("");

  // startDate and endDate for which the activity histogram will be fetched
  const startDate = format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT);
  const endDate = format(endOfDay(new Date()), DATE_FORMAT);

  // histogram service call initiator
  const fetchEventHistory = async () => {
    setHistoryLoading("loading");
    let eventsHistoryRequests: any = [];
    eventsList.map((item: LooseObject) => {
      if (!eventsHistoryList.hasOwnProperty(item.event_type)) {
        console.log("Request pushed to array.", item.event_type);
        eventsHistoryRequests.push(
          eventsService.individualEventHistogram(
            activeWorkspace.id,
            item.event_type,
            startDate,
            endDate
          )
        );
      }
    });

    // console.log(eventsHistoryRequests, eventsHistoryList);

    // send all requests in parallel.
    let eventHistogramResponse: LooseObject = eventsHistoryList;

    await axios
      .all(eventsHistoryRequests)
      .then(
        axios.spread((...responses) => {
          responses.map((response: any, index: number) => {
            // console.log(response.data);
            eventHistogramResponse[response.data.event_name] = response.data.data.map(
              (item: any) => [item.date, item.count]
            );
          });
        })
      )
      .catch((errors) => {
        // react on errors.
      });
    // console.log(eventHistogramResponse);
    console.log(eventHistogramResponse);
    setEventsHistoryList(eventHistogramResponse);
    setHistoryLoading("loaded");
  };

  const fetchGroupedEvents = async (eventName: string = "") => {
    setIsLoading("loading");

    await eventsService
      .groupedEvents(activeWorkspace.id, eventName, 1, sort)
      .then((res) => {
        if (res.data) {
          setEventsList(res.data);
          if (res.data.length < 20) {
            setHasMore(false);
          }
        } else {
          setHasMore(false);
          setEventsList([]);
        }
      })
      .catch((err) => {
        setEventsList([]);
      });
    setIsLoading("loaded");
  };

  const fetchMoreGroupedEvents = async () => {
    setHistoryLoading("loading");
    setPage(page + 1);
    await eventsService
      .groupedEvents(activeWorkspace.id, searchField, page + 1, sort)
      .then((res) => {
        if (res.data) {
          setEventsList(eventsList.concat(res.data));
          if (res.data.length < 20) {
            setHasMore(false);
          }
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        setHasMore(false);
      });
    setHistoryLoading("loaded");
  };

  const changeSortOrder = (field: string) => {
    console.log(field);
    let sortingKey;
    if (sort === `${field}:desc`) {
      sortingKey = `${field}:asc`;
    } else if (sort === `${field}:asc`) {
      sortingKey = `${field}:desc`;
    } else {
      sortingKey = `${field}:desc`;
    }
    setSort(sortingKey);
  };

  const toggleMuteEvent = async (index: number, status: boolean) => {
    const eventList = [...eventsList];
    const eventsService = new EventsService();
    await eventsService
      .muteEvent(activeWorkspace.id, eventList[index].event_type)
      .then((res) => {
        if (res.data) {
          eventsList[index].is_muted = status;
          setEventsList(eventList);
          // toast.success(`Event ${status ? "Muted" : "Unmuted"} Successfully`);
        }
      })
      .catch((err) => {});
  };

  const getPulseLineData = (eventName: any, isMuted: boolean): Array<LooseObject> => {
    const pulseData = eventsHistoryList[eventName];
    pulseData.color = isMuted ? "#c2c8cd" : "#2fa6d9";
    return [pulseData];
  };

  useEffect(() => {
    fetchEventHistory();
  }, [eventsList.length]);

  useEffect(() => {
    fetchGroupedEvents(searchField);
  }, [sort, debounced]);

  useEffect(() => {
    document.title = "Events | Custom | Usermaven";
  }, []);

  return (
    <>
      <EventsDetailedActivity
        eventName={selectedEvent}
        firstOccurred={firstOccurred}
        lastOccurred={lastOccurred}
        eventType="custom"
        eventDetailedActivityView={eventDetailedActivityView}
        setEventDetailedActivityView={setEventDetailedActivityView}
      />
      <div>
        <Text mb="md">
          Custom events in Usermaven are events you send via API or JavaScript code. You can also
          attach custom attributes to these events, which you can then use across Usermaven's tools.
        </Text>
        <Divider mb="md" />
        <Box component="div">
          <Flex align={"center"}>
            <TextInput
              value={searchField}
              mr="sm"
              onChange={(event) => setSearchField(event.currentTarget.value)}
              placeholder="Search event by name"></TextInput>
            <Tooltip label={"Learn how to send custom events"}>
              <Anchor
                href="https://usermaven.com/docs/getting-started/sending-custom-events"
                target="_blank"
                rel="noreferrer">
                <Flex align="center">
                  <FontAwesomeIcon
                    className="text-gray-700"
                    icon={regular("info-circle")}></FontAwesomeIcon>
                  {!isMobile && (
                    <>
                      <Text ml={8} td="underline">
                        Learn more
                      </Text>
                    </>
                  )}
                </Flex>
              </Anchor>
            </Tooltip>
          </Flex>
          <Space my="lg"></Space>
          <Box>
            {eventsList.length > 0 && (
              <>
                <Grid fz="sm" fw={600}>
                  <Grid.Col
                    span={1.9}
                    onClick={() => {
                      changeSortOrder("event_type");
                    }}>
                    Name
                    {sort.startsWith("event_type") && (
                      <>
                        <FontAwesomeIcon
                          icon={solid("angle-down")}
                          className={`ml-2 transform  text-gray-800 transition-all duration-200 ease-in-out ${
                            sort === "event_type:asc" ? "rotate-180" : ""
                          }`}
                        />
                      </>
                    )}
                  </Grid.Col>
                  <Grid.Col
                    span={2}
                    className="cursor-pointer"
                    onClick={() => {
                      changeSortOrder("count");
                    }}>
                    <Flex align={"center"}>
                      Total occurrence
                      {sort.startsWith("count") && (
                        <>
                          <FontAwesomeIcon
                            icon={solid("angle-down")}
                            className={`ml-2 transform  text-gray-800 transition-all duration-200 ease-in-out ${
                              sort === "count:asc" ? "rotate-180" : ""
                            }`}
                          />
                        </>
                      )}
                    </Flex>
                  </Grid.Col>
                  <Grid.Col
                    span={1.8}
                    className="cursor-pointer"
                    onClick={() => {
                      changeSortOrder("first_occurred_at");
                    }}>
                    <Flex align={"center"}>
                      First occurred
                      {sort.startsWith("first_occurred_at") && (
                        <>
                          <FontAwesomeIcon
                            icon={solid("angle-down")}
                            className={`ml-2 transform  text-gray-800 transition-all duration-200 ease-in-out ${
                              sort === "first_occurred_at:asc" ? "rotate-180" : ""
                            }`}
                          />
                        </>
                      )}
                    </Flex>
                  </Grid.Col>
                  <Grid.Col
                    span={2}
                    className="cursor-pointer"
                    onClick={() => {
                      changeSortOrder("last_occurred_at");
                    }}>
                    <Flex align={"center"}>
                      Last occurred
                      {sort.startsWith("last_occurred_at") && (
                        <>
                          <FontAwesomeIcon
                            icon={solid("angle-down")}
                            className={`ml-2 transform  text-gray-800 transition-all duration-200 ease-in-out ${
                              sort === "last_occurred_at:asc" ? "rotate-180" : ""
                            }`}
                          />
                        </>
                      )}
                    </Flex>
                  </Grid.Col>
                  <Grid.Col ta="center" span={1}>
                    Activity{" "}
                    <FontAwesomeIcon
                      icon={"info-circle"}
                      className="text-gray-700 ml-2 cursor-pointer"
                      data-for="activity-pinned-event-tooltip"
                      data-tip="Last 30 days of events activity."></FontAwesomeIcon>
                    <ReactTooltip
                      id={"activity-pinned-event-tooltip"}
                      place="bottom"
                      type="dark"
                      effect="solid"
                    />
                  </Grid.Col>
                  <Grid.Col ta="center" span={2}>
                    <Flex justify={"center"} align={"center"} ta="center">
                      Mute Events{" "}
                      <Tooltip
                        w={300}
                        multiline
                        withArrow
                        position="left"
                        label="Muted events will not be shown in the events list while creating insights, but data against muted events will still be stored in Usermaven.">
                        <FontAwesomeIcon
                          icon={regular("info-circle")}
                          className="text-gray-700 ml-2 cursor-pointer"></FontAwesomeIcon>
                      </Tooltip>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={1.5}></Grid.Col>
                </Grid>
                <Divider mb="sm" />
              </>
            )}

            {loading === "loaded" ? (
              <>
                {eventsList.length > 0 ? (
                  <>
                    {eventsList.map((event, index: number) => (
                      <>
                        <Box px={4} py={8}>
                          <Grid
                            component="div"
                            key={`grouped:event:${event.event_type}:${index}`}
                            style={{
                              opacity: event.is_muted ? 0.3 : 1
                            }}>
                            <Tooltip label={`View detailed ${event.event_type} event activity`}>
                              <Grid.Col
                                span={1.9}
                                data-cy="event-type-list"
                                onClick={() => {
                                  setSelectedEvent(event.event_type);
                                  setFirstOccurred(
                                    ClickhouseDateToLocalRelativeTime(event?.first_occurred_at)
                                  );
                                  setLastOccurred(
                                    ClickhouseDateToLocalRelativeTime(event?.last_occurred_at)
                                  );
                                  setEventDetailedActivityView(!eventDetailedActivityView);
                                }}>
                                <Text truncate fw={600}>
                                  {event.event_type}
                                  {/* {event.src === "usermaven-segment" ? (
                              <>
                                <Image
                                  className="rounded-md ml-2"
                                  src={SegmentIcon}
                                  height={16}
                                  width={16}
                                />
                              </>
                            ) : (
                              <>
                                <Image
                                  className="rounded-md ml-2"
                                  src={UsermavenDarkIcon}
                                  height={16}
                                  width={16}
                                />
                              </>
                            )} */}
                                </Text>
                              </Grid.Col>
                            </Tooltip>
                            <Grid.Col span={2}>
                              <Text fw={500}>{numberToCommas(event.count)}</Text>
                            </Grid.Col>
                            <Grid.Col span={1.8}>
                              <Text fw={500}>
                                {ClickhouseDateToLocalRelativeTime(event?.first_occurred_at)}
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={2}>
                              <Text fw={500}>
                                {ClickhouseDateToLocalRelativeTime(event?.last_occurred_at)}
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={1}>
                              <Flex justify={"center"} align={"center"}>
                                {historyLoading === "loaded" ? (
                                  <>
                                    {eventsHistoryList.hasOwnProperty(event.event_type) && (
                                      <PulseLineGraph
                                        name="Events"
                                        data={eventsHistoryList[event.event_type]}
                                        color={
                                          event.is_muted ? "#c2c8cd" : theme.colors.brand[5]
                                        }></PulseLineGraph>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Skeleton height={10} width={100} />
                                  </>
                                )}
                              </Flex>
                            </Grid.Col>
                            <Grid.Col span={1.5}>
                              <Flex align={"center"} justify={"center"}>
                                <Switch
                                  size="xs"
                                  checked={event.is_muted}
                                  onChange={() => {
                                    toggleMuteEvent(index, !event.is_muted);
                                  }}
                                  key={"custom-events-toggle-switch-" + event.event_type}
                                  id={"custom-events-toggle-switch-" + event.event_type}
                                />
                              </Flex>
                            </Grid.Col>
                            <Grid.Col span={1.5}>
                              <Button
                                variant="subtle"
                                size="compact-xs"
                                // color='gray.6'

                                onClick={() => {
                                  setSelectedEvent(event.event_type);
                                  setFirstOccurred(
                                    ClickhouseDateToLocalRelativeTime(event?.first_occurred_at)
                                  );
                                  setLastOccurred(
                                    ClickhouseDateToLocalRelativeTime(event?.last_occurred_at)
                                  );
                                  setEventDetailedActivityView(!eventDetailedActivityView);
                                }}
                                // leftIcon={<FontAwesomeIcon icon={regular("arrow-right")} />}
                              >
                                Detailed View
                              </Button>
                            </Grid.Col>
                          </Grid>
                        </Box>
                        <Divider mb="sm" />
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <Flex align={"center"} justify={"center"} flex={1} w={"100%"} h={120}>
                      {searchField.length > 0 ? (
                        <Text ta="center">There are no events for your searched query.</Text>
                      ) : (
                        <Flex direction={"column"} justify={"center"}>
                          <Text ta="center" mb={"md"}>
                            There are no custom events available.
                          </Text>
                          <Center>
                            <Anchor
                              href="https://usermaven.com/docs/getting-started/sending-custom-events"
                              target="_blank">
                              <Button
                                variant="subtle"
                                leftSection={
                                  <>
                                    <FontAwesomeIcon icon={regular("arrow-up-right-from-square")} />
                                  </>
                                }>
                                Learn how to send custom events
                              </Button>
                            </Anchor>
                          </Center>
                        </Flex>
                      )}
                    </Flex>
                  </>
                )}
              </>
            ) : (
              <>
                <Center py="md">
                  <Loader size="sm" />
                </Center>
              </>
            )}

            {eventsList.length > 0 && hasMore && (
              <Flex justify={"center"} align={"center"} my={"md"}>
                <Button
                  size="xs"
                  color="gray.7"
                  variant="outline"
                  loading={historyLoading !== "loaded"}
                  onClick={() => {
                    fetchMoreGroupedEvents();
                  }}>
                  Load More
                </Button>
              </Flex>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};
export default CustomEvents;
