import { IBarData } from "@/ui/pages/Analyze/components/FunnelBarChart/components/atoms/Bar";
import { Box } from "@mantine/core";
import MultiBarGroup from "@/ui/pages/Analyze/components/FunnelBarChart/components/molecules/MultiBarGroup";
import { FUNNEL_BREAKDOWN_COLORS } from "@/lib/utils/Constants";
import { useMemo, useState } from "react";

export interface IMultiBarData extends IBarData {
  breakdown_prop: string;
  color: string;
}

const MultiBarChart = ({
  type = "Visitors",
  bars,
  initialBars
}: {
  type?: string;
  bars: IMultiBarData[][];
  initialBars: IBarData[];
}) => {
  const max = Math.max(...bars.map((bar) => bar[0].visitors));

  // Initial state for the bars
  const [shownBars, setShownBars] = useState<boolean[]>(bars.map(() => true));

  /**
   * Restructured bars, all level 1 in one array, all level 2 in one array, etc.
   * Also, now track of shown bars
   */
  const restructuredBars = useMemo(() => {
    // New bars based on the shown bars
    const newBars = bars.reduce((acc, curr, index) => {
      if (shownBars[index]) {
        acc.push(curr);
      }
      return acc;
    }, [] as IMultiBarData[][]);

    return newBars.reduce((acc, curr, currentIndex) => {
      curr.forEach((bar, index) => {
        if (!acc[index]) {
          acc[index] = [];
        }
        if (!bar.color) {
          console.log("bar color not found", currentIndex);
          bar.color =
            bar.breakdown_prop === "Others"
              ? FUNNEL_BREAKDOWN_COLORS[5]
              : FUNNEL_BREAKDOWN_COLORS[currentIndex] || FUNNEL_BREAKDOWN_COLORS[0];
        }
        acc[index].push(bar);
      });
      return acc;
    }, [] as IMultiBarData[][]);
  }, [bars, shownBars]);

  /**
   * Get main color of the bar
   * @param index
   */
  const getMainColor = (index: number) => {
    // Check if the bar is shown
    if (!shownBars[index]) {
      return "#cccccc";
    }

    if (bars[index][0]?.breakdown_prop === "Others") {
      return FUNNEL_BREAKDOWN_COLORS[5];
    }

    if (FUNNEL_BREAKDOWN_COLORS[index]) {
      return FUNNEL_BREAKDOWN_COLORS[index];
    }

    return FUNNEL_BREAKDOWN_COLORS[0];
  };

  /**
   * on click of the legend
   */
  const onLegendClick = (index: number) => {
    setShownBars((prev) => {
      const newShownBars = [...prev];
      newShownBars[index] = !newShownBars[index];
      return newShownBars;
    });
  };

  return (
    <Box
      className="bar-chart"
      sx={{
        marginTop: "30px",
        minHeight: 300,
        height: 480,
        display: "flex",
        flexDirection: "column"
      }}>
      {/* Legends */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "60px",
          paddingLeft: "20px",
          paddingRight: "20px",
          gap: "16px"
        }}>
        {bars.map((bar, index) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
              cursor: "pointer",
              userSelect: "none"
            }}
            onClick={() => onLegendClick(index)}>
            <Box
              sx={{
                width: "24px",
                height: "15px",
                borderRadius: "3px",
                backgroundColor: getMainColor(index),
                marginRight: "5px"
              }}
            />
            <Box sx={{ textTransform: "capitalize" }}>{bar[0].breakdown_prop || "Unknown"}</Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          flex: 1
        }}>
        <MultiBarGroup bars={restructuredBars} max={max} type={type} initialBars={initialBars} />
      </Box>
    </Box>
  );
};

export default MultiBarChart;
