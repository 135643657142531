/* eslint-disable array-callback-return */
/* eslint-disable no-unreachable */
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Menu, useMantineTheme } from "@mantine/core";
import update from "immutability-helper";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ConditionItem } from "types/types.d";
import { InsightCondition } from "./InsightCondition";
import "./insights_setup_events.scss";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface InsightsSetupEventsProps {
  conditions: Array<ConditionItem>;
  setConditions: any;
  maxConditions?: number;
  conditionType: string;
  attributesOptions: Array<any>;
  eventsOptions?: Array<any>;
  pinnedEventOptions?: Array<any>;
  options?: Array<{
    label: string;
    value: string;
  }>;
  nameIdentifier?: string;
  position?: any;
  transition?: any;
  featureName?: any;
  featureId?: any;
  usedIn?: string | null;
}

export const InsightsSetupEvents: React.FC<InsightsSetupEventsProps> = ({
  conditions,
  setConditions,
  maxConditions = 100,
  conditionType = "or",
  attributesOptions,
  eventsOptions = [],
  options = [
    {
      label: "Page URL",
      value: "pageview"
    },
    { label: "Custom Event", value: "event" }
  ],
  pinnedEventOptions = [],
  nameIdentifier = "condition",
  position,
  transition,
  featureName,
  featureId,
  usedIn = null
}) => {
  const theme = useGlobalMantineTheme();

  const [menuOpened, setMenuOpened] = useState(false);
  // default properties

  const handleChange = (e: any) => {
    if (e?.target?.value) {
      let operator;
      switch (e.target.value) {
        case "attribute":
          operator = "exists";
          break;
        case "pageview":
          operator = "eq";
          break;
        case "event":
        case "pinned_event":
          operator = "true";
          break;
        default:
          operator = "";
          break;
      }

      const payload = {
        key: "",
        value: "",
        operator: operator,
        type: e.target.value,
        label: ""
      };

      if (usedIn && usedIn === "feature_adoption") {
        setConditions(featureId, [...conditions, payload]);
      } else {
        setConditions([...conditions, payload]);
      }
    }
  };

  const handleItemSelectChange = (e: any, index: number) => {
    console.log(e.target.value, index);
    let filterArrayList = conditions.map((item: any, itemIndex: number) => {
      if (index === itemIndex) {
        item.type = e.target.value;
        console.debug("handleItemSelectChange: ", item);
        console.log("operator", item.operator);
        if (
          (item.type === "event" || item.type === "pinned_event") &&
          !["true", "false"].includes(item.operator)
        ) {
          item.operator = "true";
        }

        // reset to the default properties.
        if (item.type === "event" || item.type === "pinned_event") {
          item.key = "";
          item.label = "";
        }

        if (
          item.type === "pageview" &&
          !["eq", "!eq", "contains", "!contains"].includes(item.operator)
        ) {
          item.operator = "contains";
        }
        if (item.type === "pageview") {
          item.key = "";
          item.label = "";
        }
      }
      return item;
    });
    if (usedIn === "feature_adoption") {
      setConditions(featureId, filterArrayList);
    } else {
      setConditions(filterArrayList);
    }
  };

  const handleOperatorChange = (e: any, index: number) => {
    console.log(e.target.value, index);
    let filterArrayList = conditions.map((item: any, itemIndex: number) => {
      if (index === itemIndex) {
        item.operator = e.target.value;
      }
      return item;
    });
    if (usedIn === "feature_adoption") {
      setConditions(featureId, filterArrayList);
    } else {
      setConditions(filterArrayList);
    }
  };

  const handleKeyChange = (e: any, index: number) => {
    console.log("handle key change", e, index);
    let filterArrayList = conditions.map((item: any, itemIndex: number) => {
      if (index === itemIndex) {
        item.key = e.value;
        item.label = e.label;
        if (!["true", "false"].includes(item.operator)) {
          item.value = "";
          item.operator = "true";
        }
      }
      return item;
    });

    if (usedIn === "feature_adoption") {
      setConditions(featureId, filterArrayList);
    } else {
      setConditions(filterArrayList);
    }
  };

  const handleValueChange = (value: any, index: number) => {
    let filterArrayList = conditions.map((item: any, itemIndex: number) => {
      if (index === itemIndex) {
        item.value = value;
      }
      if (item.type === "pageview" && item.key === "") {
        item.key = "path";
        item.label = "Path";
      }
      return item;
    });
    if (usedIn === "feature_adoption") {
      setConditions(featureId, filterArrayList);
    } else {
      setConditions(filterArrayList);
    }
  };

  const handleAliasChange = (value: any, index: number) => {
    let filterArrayList = conditions.map((item: any, itemIndex: number) => {
      if (index === itemIndex) {
        item.alias = value;
      }
      return item;
    });
    if (usedIn === "feature_adoption") {
      setConditions(featureId, filterArrayList);
    } else {
      setConditions(filterArrayList);
    }
  };

  const handleRemoveCondition = (index: number) => {
    const filteredArray = conditions.filter((item: any, indexItem: number) => indexItem !== index);
    console.log(filteredArray);
    if (usedIn === "feature_adoption") {
      setConditions(featureId, filteredArray);
    } else {
      setConditions(filteredArray);
    }
  };

  console.log(conditions);

  const filteredEventOptions = (): Array<any> => {
    return eventsOptions;
    const listOfAddedEvents = conditions.map((condition) => {
      if (condition.type === "event") {
        return condition.key;
      }
    });
    const listOfFilteredEvents = eventsOptions.filter(
      (event) => !listOfAddedEvents.includes(event.value)
    );
    console.log(listOfFilteredEvents);
    return listOfFilteredEvents;
  };

  const filteredPinnedEventOptions = (): Array<any> => {
    return pinnedEventOptions;
    const listOfAddedEvents = conditions.map((condition) => {
      if (condition.type === "pinned_event") {
        return condition.key;
      }
    });
    const listOfFilteredEvents = pinnedEventOptions.filter(
      (event) => !listOfAddedEvents.includes(event.value)
    );
    console.log(listOfFilteredEvents);
    return listOfFilteredEvents;
  };

  // Drag and Drop functionality
  const moveCondition = (dragIndex: number, hoverIndex: number) => {
    const dragCondition = conditions[dragIndex];
    if (usedIn === "feature_adoption") {
      setConditions(
        featureId,
        update(conditions, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCondition]
          ]
        })
      );
    } else {
      setConditions(
        update(conditions, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCondition]
          ]
        })
      );
    }
  };

  // drag condition handlers.

  return (
    <>
      <div>
        <DndProvider backend={HTML5Backend}>
          <div>
            {conditions.length > 0 &&
              conditions.map((item: ConditionItem, index: any) => (
                <InsightCondition
                  item={item}
                  index={index}
                  key={index}
                  options={options}
                  conditionType={conditionType}
                  attributesOptions={attributesOptions}
                  handleItemSelectChange={handleItemSelectChange}
                  handleKeyChange={handleKeyChange}
                  handleValueChange={handleValueChange}
                  handleAliasChange={handleAliasChange}
                  handleOperatorChange={handleOperatorChange}
                  handleRemoveCondition={handleRemoveCondition}
                  filteredEventOptions={filteredEventOptions}
                  pinnedEventOptions={filteredPinnedEventOptions}
                  moveCondition={moveCondition}
                  id={index}
                  nameIdentifier={nameIdentifier}></InsightCondition>
              ))}
          </div>
        </DndProvider>
        {/* Select the type of event */}
        {conditions.length < maxConditions && (
          <Box pos="relative">
            <Menu
              // transition={transition ? transition : undefined}
              position={position ? position : undefined}
              opened={menuOpened}
              onChange={setMenuOpened}>
              <Menu.Target>
                <Button
                  className="!font-medium "
                  // color={theme.colorScheme === "dark" ? theme.colors.dark[5] : "gray.9"}
                  // variant={theme.colorScheme === "dark" ? "subtle" : "light"}
                  variant="outline"
                  size="xs"
                  mt="md"
                  leftSection={<FontAwesomeIcon icon={regular("circle-plus")} />}
                  rightSection={
                    <FontAwesomeIcon
                      icon={solid("angle-up")}
                      className={`transition-all ease-in-out transform rotate-180 ${
                        menuOpened ? "rotate-0" : ""
                      }`}
                    />
                  }
                  data-cy="insight-add-condition">
                  {conditionType === "funnel" ? "Add Step" : "Add Condition"}
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                {options.map((option: any, index: number) => (
                  <Menu.Item
                    key={index}
                    onClick={() => {
                      handleChange({
                        target: {
                          value: option.value
                        }
                      });
                    }}>
                    {option.label}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          </Box>
        )}
      </div>
    </>
  );
};
