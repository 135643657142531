import { Box, Divider, Space } from "@mantine/core";
import { useEffect } from "react";
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
import { AccountsGeneralEmailChange } from "./AccountsGeneralEmailChange";
import { AccountsGeneralProfileUpdate } from "./AccountsGeneralProfileUpdate";
import { AccountsGeneralPreference } from "./AccountsGeneralPreference";
const AccountsGeneral = (props: any) => {
  useEffect(() => {
    document.title = "Account General | Usermaven";
  }, []);
  return (
    <>
      <AccountsGeneralProfileUpdate></AccountsGeneralProfileUpdate>
      <Divider my="xl" />
      <AccountsGeneralEmailChange></AccountsGeneralEmailChange>
      <Divider my={"xl"} />
      <AccountsGeneralPreference />
    </>
  );
};
export default AccountsGeneral;
