/**
 * Common Contact Section component.
 */
import React, { useContext } from "react";
import {
  Box,
  Text,
  Group,
  Button,
  Flex,
  Collapse,
  Timeline,
  useMantineTheme,
  Title,
  Paper,
  Loader,
  useMantineColorScheme
} from "@mantine/core";
import { Oval } from "react-loader-spinner";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { ConditionItem } from "types/types.d";
import { useDisclosure } from "@mantine/hooks";
import classes from "./OnboardingSection.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
export interface IOnboardingSection {
  /**
   * Specify the callback function to be called when fetching more activities.
   */
  onFetch?: () => Promise<IOnboarding | null>;

  /**
   * Specify the callback function to be called when fetch completed
   */
  onFetchCompleted?: (contact: IOnboarding | any) => void;
}

export interface IOnboarding {
  completed_step_name: any;
  max_completed_step: number;
  onboarding_conditions: Array<ConditionItem>;
}

const OnboardingSection = ({
  onFetch = () =>
    Promise.resolve({
      completed_step_name: "",
      max_completed_step: 0,
      onboarding_conditions: []
    }),
  onFetchCompleted = () => {}
}: IOnboardingSection) => {
  const { colorScheme } = useMantineColorScheme();
  const [onboardingStatus, setOnboardingStatus] = React.useState<IOnboarding | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [isFinishedOnboarding, setIsFinishedOnboarding] = React.useState(false);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [opened, { toggle }] = useDisclosure(true);
  const navigate = useNavigate();
  const theme = useGlobalMantineTheme();

  React.useEffect(() => {
    setLoading(true);
    fetchContact();
  }, []);

  /**
   * Fetch contact.
   */
  const fetchContact = async () => {
    setLoading(true);
    const onboardingResult = await onFetch();
    if (onboardingResult) {
      setOnboardingStatus(onboardingResult);
      if (onboardingResult.max_completed_step === onboardingResult.onboarding_conditions.length) {
        setIsFinishedOnboarding(true);
      }
    }

    setLoading(false);
    onFetchCompleted(onboardingResult);
  };

  return (
    <Box>
      {loading ? (
        <Flex align={"center"} justify={"center"} w={"100%"} py={"md"} my={"md"}>
          <Loader size="xs" />
        </Flex>
      ) : (
        <>
          {onboardingStatus ? (
            <Flex
              justify={"space-around"}
              direction={"column"}
              w={"100%"}
              my="md"
              className="flex justify-around flex-col w-full">
              <Group justify="space-between" mb="md">
                <Title order={5} fw={500}>
                  {" "}
                  Onboarding Status
                </Title>
                <FontAwesomeIcon
                  size="xs"
                  icon={opened ? solid("chevron-up") : solid("chevron-down")}
                />
              </Group>
              <Collapse in={opened} className={classes.collapse}>
                {onboardingStatus.onboarding_conditions.length > 0 ? (
                  <Paper withBorder p="sm" shadow="xs">
                    {isFinishedOnboarding ? (
                      <Group justify="flex-start" gap="xs" align="center" wrap="nowrap">
                        <FontAwesomeIcon
                          fontSize={"xs"}
                          icon={solid("check-circle")}
                          size="xl"
                          color="green"
                        />
                        <Text fz="sm">Finished Onboarding</Text>
                      </Group>
                    ) : (
                      <Timeline
                        color="green.7"
                        lineWidth={2}
                        active={onboardingStatus.max_completed_step - 1}
                        bulletSize={24}
                        styles={{
                          itemBody: {
                            height: "24px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column"
                          },
                          itemTitle: {
                            marginBottom: 0
                          }
                        }}>
                        {onboardingStatus.onboarding_conditions.map((condition, index) => {
                          return (
                            <Timeline.Item
                              fz="sm"
                              key={index}
                              bullet={
                                <FontAwesomeIcon
                                  style={{
                                    color: colorScheme === "dark" ? "#242424" : "#fafafa"
                                  }}
                                  icon={solid("check")}
                                  size="lg"
                                />
                              }
                              title={condition.key === "path" ? condition.value : condition.label}
                            />
                          );
                        })}
                      </Timeline>
                    )}
                  </Paper>
                ) : (
                  <Flex p="sm" align="center" justify="center">
                    <Button
                      variant="outline"
                      onClick={() =>
                        navigate(
                          `/env/${activeWorkspace.identifier}/insights/dashboard/onboarding`,
                          { state: { isModalOpen: true } }
                        )
                      }>
                      Create Onboarding Insight
                    </Button>
                  </Flex>
                )}
              </Collapse>
            </Flex>
          ) : (
            <Flex justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
              <Text>No details found.</Text>
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default OnboardingSection;
