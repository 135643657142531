/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import {
  DATE_FORMAT,
  LOADER_COLOR,
  PLAN_TYPES,
  WORKSPACE_MEMBER_ROLES
} from "@/lib/utils/Constants";
import { useConversionFunnelStore } from "@/stores/useConversionFunnelStore";
import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  HoverCard,
  Paper,
  SegmentedControl,
  Select,
  Text,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import { getReportingPeriodRange } from "../../../../../lib/utils/ReportingPeriodUtility";
import { IDetailViewMenu } from "../../../../pages/Analyze/components/FunnelDetailedView/DetailViewMenuGroup";
import FunnelDetails, {
  IFunnelMeta,
  IFunnelStep
} from "../../../../pages/Analyze/components/FunnelDetailedView/FunnelDetails";
import { SidePanel } from "../../../Common/SidePanel/SidePanel";
import { DateDropdownMenu } from "../../Dropdowns/DateDropdownMenu";
import { isGoalSelected } from "../Filters/FiltersUtility";
import { ConversionFunnelCard } from "./ConversionFunnelCard";
import { ConversionFunnelModal } from "./ConversionFunnelModal";
import { useWebFiltersStore } from "@/stores/useWebFiltersStore";
import { FormattedMessage } from "react-intl";

export const ConversionFunnel = ({ isPublic = false, createModalOpen = false }: any) => {
  // conversion goal attributes
  const { colorScheme } = useMantineColorScheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const { hasRolesNotWith, isStarterPlan } = useWorkspaceUtilityHook();
  const firstRender = useRef(false);

  const navigate = useNavigate();

  const [
    list,
    fetchAll,
    modalOpen,
    setModalOpen,
    reset,
    listLoading,
    funnelType,
    setFunnelType,
    loadingMore,
    noMoreData
  ] = useConversionFunnelStore((state) => [
    state.list,
    state.fetchAll,
    state.modalOpen,
    state.setModalOpen,
    state.reset,
    state.listLoading,
    state.activeFunnelTab,
    state.setActiveFunnelTab,
    state.loadingMore,
    state.noMoreData
  ]);
  const [setDateLabel] = useWebFiltersStore((state) => [state.setDateLabel]);

  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<string | null>("created_at:desc");
  const [detailModal, setDetailModal] = useState(false);
  const [selectedFunnelId, setSelectedFunnelId] = useState<string>("");
  const [selectedFunnel, setSelectedFunnel] = useState<IFunnelStep[]>([]);
  const [selectedMeta, setSelectedMeta] = useState<IFunnelMeta>();
  const [selectedType, setSelectedType] = useState<"user" | "visitor" | "company">("visitor");
  const [initialActiveTabType, setInitialActiveTabType] = useState<IDetailViewMenu>({
    activeView: "overview",
    selectedOption: "none"
  });

  // Check if the plan is starter or not.
  const isFunnelLocked = isStarterPlan && funnelType === "company";

  const [query, setQuery] = useQueryParams({
    start_date: StringParam,
    end_date: StringParam
  });

  useEffect(() => {
    setPage(1);
    const getPeriod = getReportingPeriodRange(activeWorkspace.reporting_period);
    const startDate =
      getPeriod?.startDate || format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT);
    const endDate = getPeriod?.endDate || format(endOfDay(new Date()), DATE_FORMAT);
    setQuery({
      start_date: startDate,
      end_date: endDate
    });
    if (query && query.start_date && query.end_date) {
      setDateLabel(
        `${format(new Date(query.start_date.replace(" ", "T")), "MMM dd")} - ${format(
          new Date(query.end_date.replace(" ", "T")),
          "MMM dd"
        )}`
      );
    } else {
      setDateLabel(getPeriod?.label || "Last 30 Days");
    }
  }, []);

  // On theme change
  useEffect(() => {
    setPage(1);
    fetchAll(activeWorkspace.id, sortOrder, funnelType);
  }, [colorScheme]);

  /**
   * Open a funnel conversion model and clear the form fields and conditions.
   */
  const toggleCreateModal = () => {
    if (isFunnelLocked) {
      return;
    }

    reset();
    setModalOpen(true);
  };

  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true;
      return;
    }
    setPage(1);
    fetchAll(activeWorkspace.id, sortOrder, funnelType);
  }, [sortOrder, funnelType]);

  /**
   * Method responsible for handling funnels details modal
   */
  const handleDetails = (
    id: string,
    funnel: IFunnelStep[],
    meta: IFunnelMeta,
    type: string,
    activeType?: IDetailViewMenu
  ) => {
    setDetailModal(true);
    setSelectedFunnelId(id);
    setSelectedFunnel(funnel);
    setSelectedMeta(meta);
    setSelectedType(type as any);
    setInitialActiveTabType(activeType || initialActiveTabType);
  };

  /**
   * Method responsible for handling date change
   */
  const handleDateChange = (startDate: string | undefined, endDate: string | undefined) => {
    console.log(startDate, endDate);

    setQuery({ start_date: startDate, end_date: endDate });
  };

  /**
   * Method responsible for handling setting active funnel tab
   */
  const handleSetFunnelType = (value: string) => {
    setPage(1);
    setFunnelType(value as any);
  };

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchAll(activeWorkspace.id, sortOrder, funnelType, String(nextPage));
  };

  return (
    <>
      {!isPublic && (
        <>
          <ConversionFunnelModal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            reload={() => fetchAll(activeWorkspace.id, sortOrder, funnelType)}
          />
        </>
      )}
      <Box>
        <Flex direction="column">
          <Flex
            justify={"space-between"}
            align={"center"}
            sx={(theme) => ({
              borderColor:
                colorScheme === "dark"
                  ? theme.colors.darkBorderColor[0]
                  : theme.colors.lightBorderColor[0],

              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "1rem",

              ".funnels-other-filter": {
                flex: 1,
                justifyContent: "flex-end",
                display: "flex"
              },

              [`@media screen and (max-width: ${theme.breakpoints.sm}px)`]: {
                ".funnels-segmented-control": {
                  width: "100%"
                },

                ".funnels-other-filter": {
                  justifyContent: "space-between"
                }
              }
            })}
            className="py-6">
            <SegmentedControl
              // color={"brand.5"}
              className={"funnels-segmented-control"}
              size="sm"
              value={funnelType}
              onChange={(val) => handleSetFunnelType(val as any)}
              data={[
                { label: "All Funnels", value: "all" },
                { label: "Visitors", value: "visitor" },
                { label: "Users", value: "user" },
                { label: "Companies", value: "company" }
              ]}
            />
            {list.length > 0 && (
              <Flex align={"center"}>
                <Text fz="xs" pr={"xs"} fw={500}>
                  Sort by
                </Text>
                <Select
                  size="sm"
                  placeholder="Sort by"
                  w={120}
                  mr={"sm"}
                  value={sortOrder}
                  variant="filled"
                  onChange={setSortOrder}
                  data={[
                    {
                      label: "A-Z",
                      value: "name:asc"
                    },
                    {
                      label: "Z-A",
                      value: "name:desc"
                    },
                    {
                      label: "Recent",
                      value: "created_at:desc"
                    },
                    {
                      label: "Oldest",
                      value: "created_at:asc"
                    }
                  ]}
                />
                <DateDropdownMenu onChange={handleDateChange} />
              </Flex>
            )}
          </Flex>

          <>
            {list.length === 0 && !listLoading ? (
              <>
                <Flex direction={"column"} justify={"center"} align={"center"} py={"lg"}>
                  <Text c="dimmed" py="md">
                    <FontAwesomeIcon icon={light("filter-list")} size="2xl" />
                  </Text>

                  {funnelType === "all" ? (
                    <Text mb="md">You have not created any conversion funnel.</Text>
                  ) : (
                    <Text mb="md">
                      You have not created any {funnelType} level conversion funnel.
                    </Text>
                  )}
                  {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && !isPublic && (
                    <HoverCard width={280} shadow="md" withArrow disabled={!isFunnelLocked}>
                      <HoverCard.Target>
                        <Button size="xs" onClick={() => toggleCreateModal()}>
                          Create Funnel
                          {isFunnelLocked && (
                            <Box ml={"sm"}>
                              <FontAwesomeIcon icon={solid("bolt-lightning")} />
                            </Box>
                          )}
                        </Button>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Text size="sm">
                          Upgrade to{" "}
                          <Text span fw={500}>
                            Pro
                          </Text>{" "}
                          or{" "}
                          <Text span fw={500}>
                            Premium
                          </Text>{" "}
                          plan to create the company level funnels. <br />
                          <span
                            className="primary-link-color underline cursor-pointer"
                            onClick={() =>
                              navigate(`/env/${activeWorkspace.identifier}/account/billing`)
                            }>
                            Upgrade now.
                          </span>
                        </Text>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  )}
                </Flex>
              </>
            ) : (
              <>
                <Divider mt="md" />
                <Box
                  p={"md"}
                  pt="xl"

                  // sx={(theme) => ({
                  //   [`@media (max-width: 1242px)`]: {
                  //     display: "none"
                  //   }
                  // })}
                >
                  <Grid>
                    <Grid.Col span={4}>
                      <Title order={6} fw={500}>
                        Name
                      </Title>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Title order={6} fw={500} ta={"center"}>
                        Stats
                      </Title>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Title order={6} fw={500} ta={"center"}>
                        Conversion Rate
                      </Title>
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <Title order={6} fw={500} ta={"center"}>
                        Actions
                      </Title>
                    </Grid.Col>
                  </Grid>
                </Box>
                <div className="grid grid-cols-1 gap-4">
                  <InfiniteScroll
                    dataLength={list.length}
                    next={() => handleLoadMore()}
                    hasMore={!noMoreData}
                    endMessage={null}
                    loader={
                      <div className="um-virtual-table-loader">
                        <Oval color={LOADER_COLOR} height={16} width={16} />
                      </div>
                    }>
                    {list.map((funnel, index) => (
                      <Box mb={"md"} key={`conversion-funnel-box-${index}`}>
                        <ConversionFunnelCard
                          key={`conversion-funnel-${index}`}
                          id={funnel.id}
                          name={funnel.name}
                          order={funnel.conditions["steps_order"]}
                          type={funnel.conditions["funnel_type"]}
                          description={funnel.description}
                          isLocked={funnel.is_locked}
                          lockedByUserName={funnel.locked_by}
                          lockedAt={funnel.locked_at}
                          lockedInsightId={funnel.locked_insight_id}
                          query={query}
                          reload={() => fetchAll(activeWorkspace.id, sortOrder, funnelType)}
                          onDetailClick={handleDetails}
                        />
                      </Box>
                    ))}
                  </InfiniteScroll>
                </div>
                {/*<Box*/}
                {/*  mt={40}*/}
                {/*  sx={{*/}
                {/*    display: "flex",*/}
                {/*    justifyContent: "center",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  {!listLoading && (*/}
                {/*    <Button*/}
                {/*      variant={"outline"}*/}
                {/*      color={colorScheme === "dark" ? "dark.2" : "gray.7"}*/}
                {/*      disabled={noMoreData || loadingMore}*/}
                {/*      loading={loadingMore}*/}
                {/*      onClick={() => handleLoadMore()}*/}
                {/*    >*/}
                {/*      {noMoreData ? "No More Data" : "Load More"}*/}
                {/*    </Button>*/}
                {/*  )}*/}
                {/*</Box>*/}
              </>
            )}
          </>
        </Flex>
      </Box>

      <SidePanel
        loading={true}
        opened={detailModal}
        onCancel={() => setDetailModal(false)}
        fullWidth
        hideOverflow
        title={
          <>
            {selectedMeta?.funnel_name && <>{`${selectedMeta?.funnel_name} - ` || ""}</>}
            <FormattedMessage id="insights.headerText" defaultMessage="Funnel" />
          </>
        }>
        <FunnelDetails
          id={selectedFunnelId}
          initialActiveTabType={initialActiveTabType}
          selectedFunnel={selectedFunnel}
          selectedMeta={selectedMeta}
          selectedType={selectedType}
          date={query}
          onDateChange={handleDateChange}
        />
      </SidePanel>
    </>
  );
};
