// @ts-ignore
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { WorkspaceService } from "@/lib/services/WorkspaceService"
import { FC, useContext, useState } from "react"
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import { Box, Button, useMantineTheme } from "@mantine/core"
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const EventRecommendationNotification: FC<{}> = () => {
  const [toggle, setToggle] = useState(true)
  const { activeWorkspace, setActiveWorkspace, user } = useContext(AppLifecycleContext)
  const navigate = useNavigate()
  const location = useLocation()

  const dismissNotification = () => {
    const workspaceService = new WorkspaceService()
    workspaceService.setEventsRecommendationsViewed(activeWorkspace.id).then(res => {
      if (res.data) {
        setActiveWorkspace(res.data)
        setToggle(false)
      } else {
      }
    })
  }
  const theme = useGlobalMantineTheme()
  return (
    <>
      {toggle &&
        location.pathname.includes("/insights/dashboard/") &&
        activeWorkspace.is_setup_completed &&
        user.is_onboarding_video_watched &&
        !activeWorkspace.is_events_recommendations_viewed && (
          <Box
            sx={theme => ({
              backgroundColor:
                theme.colorScheme === "dark" ? theme.colors.dark[8] : "rgba(235, 218, 254, 0.25)",
              border: `1px solid`,
              borderColor:
                theme.colorScheme === "dark"
                  ? theme.colors.darkBorderColor[0]
                  : theme.colors.lightBorderColor[0],
              borderRadius: "0.25rem",
            })}
            className="m-4 px-8 py-4 shadow-sm mx-8"
          >
            {/* <h4 className="text-purple-500 font-bold mb-4">Event Recommendation</h4> */}
            <div className="flex items-center relative">
              <div>
                <FontAwesomeIcon
                  icon={regular("lightbulb-exclamation")}
                  className="text-yellow-400"
                  size={"2x"}
                ></FontAwesomeIcon>
              </div>
              <div className="flex-grow ml-8">
                <h2 className="mb-2 font-semibold">Events Recommendations</h2>
                <p>
                  Not sure what events you need to track? We have got you covered. Get started with
                  our tracking guide.
                </p>
                <div className="pt-4">
                  <Button
                    size="xs"
                    color={theme.colorScheme === "dark" ? "dark.2" : undefined}
                    variant="outline"
                    onClick={() => {
                      navigate("/events-recommendations")
                    }}
                  >
                    Get Started
                  </Button>
                </div>
              </div>
              <div className="absolute top-0 right-0 flex items-center">
                <span className="pr-2 text-sm">Hide forever</span>
                <FontAwesomeIcon
                  onClick={() => {
                    dismissNotification()
                  }}
                  className="text-lg cursor-pointer hover:opacity-50"
                  icon={regular("times")}
                ></FontAwesomeIcon>
              </div>

              {/* <div className="py-4">
          <div className="items-center text-md mb-4">
            <FontAwesomeIcon
              icon="circle-check"
              className="text-green-800 mr-2"
            ></FontAwesomeIcon>
            <span className="font-medium">Create a Workspace</span>
          </div>
          <div className="items-center text-md">
            <FontAwesomeIcon
              icon="circle-check"
              className="text-gray-400 mr-2"
            ></FontAwesomeIcon>
            <span className="font-medium">Add tracking code</span>
          </div>
          <Button
            className="mt-4"
            primary={true}
            label="Install Usermaven"
            onClick={() => {
              history.push(
                `/env/${activeWorkspace.identifier}/setup-guides/integration`
              );
            }}
          ></Button>
        </div> */}
            </div>
          </Box>
        )}
    </>
  )
}
