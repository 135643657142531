import {
  DATE_FORMAT,
  DATE_RANGES,
  STARTER_PLAN_DISABLED_DATE_RANGES
} from "../../../../lib/utils/Constants";
import React, { Fragment, useState } from "react";
import { Box, Button, Flex, Menu, Paper, Text, useMantineColorScheme } from "@mantine/core";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { DateRange } from "react-date-range";
import { addDays, format, subDays } from "date-fns";
import { FormattedMessage } from "react-intl";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import classes from "./DatePickerDropdown.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
export interface IDatePickerDropdownProps {
  range: IDateRange | IDateRange[];
  onRangeChange: (range: IDateRange | IDateRange[]) => void;
  data?: IDateRange[];
  children?: (range: IDateRange | IDateRange[]) => React.ReactNode;
  showArrow?: boolean;
  withCustomRange?: boolean;
}

export interface IDateRange {
  label: string;
  startDate?: string;
  endDate?: string;
  interval?: string;
}

const DatePickerDropdown = ({
  range: activeRange,
  onRangeChange,
  children: target,
  data = DATE_RANGES,
  showArrow = true,
  withCustomRange = false
}: IDatePickerDropdownProps) => {
  // Color Scheme

  const theme = useGlobalMantineTheme();

  // starter plan check from useWorkspaceUtilityHook
  const { isStarterPlan } = useWorkspaceUtilityHook();

  // Month-wise calendar date-picker
  const [currentState, setCurrentState] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const [compareState, setCompareState] = useState([
    {
      startDate: subDays(new Date(), 60),
      endDate: subDays(new Date(), 30),
      key: "selection"
    }
  ]);

  // Calendar state
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  /**
   * Handle date range change for custom date range picker.
   * The values are set on the state variable state and picked from there.
   */
  const applyCustomChange = () => {
    onRangeChange([
      {
        label: "Custom",
        startDate: format(new Date(currentState[0].startDate), DATE_FORMAT),
        endDate: format(new Date(currentState[0].endDate), DATE_FORMAT)
      },
      {
        label: "Custom",
        startDate: format(new Date(compareState[0].startDate), DATE_FORMAT),
        endDate: format(new Date(compareState[0].endDate), DATE_FORMAT)
      }
    ]);

    setIsCalendarVisible(false);
  };

  /**
   * Handle date range change for date range picker.
   * @param range
   */
  const handleOnChange = (range: IDateRange) => {
    onRangeChange(range);
  };

  return (
    <Box>
      <Menu
        shadow="md"
        width={240}
        position="bottom-start"
        withinPortal
        disabled={isCalendarVisible}>
        <Menu.Target>
          <Flex
            align={"center"}
            style={{
              cursor: "pointer",
              gap: "0.5rem"
            }}>
            {target ? (
              target(activeRange)
            ) : !Array.isArray(activeRange) ? (
              <div>{activeRange.label}</div>
            ) : (
              <div>{activeRange.map((range: IDateRange) => range.label).join(", ")}</div>
            )}

            {
              // Show arrow only if showArrow is true
              showArrow && <FontAwesomeIcon icon={solid("angle-down")} />
            }
          </Flex>
        </Menu.Target>

        <Menu.Dropdown>
          {data.map((range: any, index: number) => (
            <Fragment key={`${range.label}-${index}`}>
              {/* In case if label is divider, we add a separator to the menu */}
              {range.label === "divider" ? (
                <>
                  <Menu.Divider key={index} />
                </>
              ) : /* Date range menu items older than 6 months are disabled for starter plan */
              STARTER_PLAN_DISABLED_DATE_RANGES.includes(range.label.toLowerCase()) &&
                isStarterPlan ? (
                <div
                  className={classes["not-allowed"]}
                  onClick={() =>
                    toast.error("Please upgrade your plan to unlock these date ranges")
                  }>
                  <Menu.Item key={index} disabled>
                    <Flex align={"center"}>
                      <Text flex={1}>{range.label}</Text>
                      <span>
                        <FontAwesomeIcon
                          key={"fa" + index}
                          icon={regular("lock")}></FontAwesomeIcon>
                      </span>
                    </Flex>
                  </Menu.Item>
                </div>
              ) : (
                // Date range menu item
                <Menu.Item key={index} onClick={() => handleOnChange(range)}>
                  <Flex align={"center"}>
                    <Text flex={1}>{range.label}</Text>
                    {!Array.isArray(activeRange) && range.label === activeRange.label && (
                      <>
                        <span>
                          <FontAwesomeIcon
                            key={"fa" + index}
                            icon={regular("check")}></FontAwesomeIcon>
                        </span>
                      </>
                    )}
                  </Flex>
                </Menu.Item>
              )}
            </Fragment>
          ))}

          {/* Custom date range menu item */}
          {withCustomRange && (
            <>
              {isStarterPlan ? (
                <>
                  <Menu.Divider />
                  <div
                    className={classes["not-allowed"]}
                    onClick={() =>
                      toast.error("Please upgrade your plan to unlock these date ranges")
                    }>
                    <Menu.Item disabled>
                      <div className="flex">
                        <span className="flex-grow">
                          <FormattedMessage
                            id="insights.headerTabs.compare.custom"
                            defaultMessage="Custom"
                          />
                        </span>
                        <span>
                          <FontAwesomeIcon icon={regular("lock")}></FontAwesomeIcon>
                        </span>
                      </div>
                    </Menu.Item>
                  </div>
                </>
              ) : (
                <>
                  <Menu.Divider />
                  <Menu.Item
                    onClick={(event: any) => {
                      event.stopPropagation();
                      setIsCalendarVisible(!isCalendarVisible);
                    }}>
                    <div className="flex">
                      <span className="flex-grow">
                        <FormattedMessage
                          id="insights.headerTabs.compare.custom"
                          defaultMessage="Custom"
                        />
                      </span>
                      {Array.isArray(activeRange) ||
                        (activeRange?.label === "Custom" && (
                          <>
                            <span>
                              <FontAwesomeIcon icon={regular("check")}></FontAwesomeIcon>
                            </span>
                          </>
                        ))}
                    </div>
                  </Menu.Item>
                </>
              )}
            </>
          )}
        </Menu.Dropdown>

        {/* Custom date range calendar */}
        {isCalendarVisible && (
          <Box
            pos="relative"
            sx={{
              position: "relative"
            }}>
            <Paper
              pos={"absolute"}
              left={0}
              className={`um-date-range-with-buttons absolute mt-4 left-0 shadow-sm border border-solid z-50`}
              sx={() => ({
                // borderColor:
                //   theme.colorScheme === "dark"
                //     ? theme.colors.darkBorderColor[0]
                //     : theme.colors.lightBorderColor[0],

                ".rdrCalendarWrapper": {
                  backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : ""
                },

                ".rdrDateDisplayWrapper": {
                  backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#eff2f7"
                },

                ".rdrDateDisplayItem": {
                  backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : ""
                },

                ".rdrNextPrevButton": {
                  backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : ""
                },

                ".rdrDayNumber span": {
                  color: theme.colorScheme === "dark" ? theme.colors.dark[1] : ""
                },

                ".rdrDayDisabled": {
                  backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : ""
                }
              })}>
              <Box
                sx={() => ({
                  display: "flex",

                  "& > *": {
                    borderRight: "1px solid",
                    borderColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.darkBorderColor[0]
                        : theme.colors.lightBorderColor[0],

                    "&:last-child": {
                      borderRight: "none"
                    }
                  }
                })}>
                <Box>
                  <Text
                    size="sm"
                    color="dimmed"
                    ta={"center"}
                    py={8}
                    sx={() => ({
                      backgroundColor:
                        theme.colorScheme === "dark" ? theme.colors.dark[6] : "#eff2f7"
                    })}>
                    Current Date Range
                  </Text>
                  <DateRange
                    className="um-react-date-range"
                    showDateDisplay={false}
                    editableDateInputs={false}
                    onChange={(item: any) => setCurrentState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={currentState}
                    maxDate={addDays(new Date(), 0)}
                    rangeColors={["#7D47EB"]}
                  />
                </Box>
                <Box>
                  <Text
                    size="sm"
                    c="dimmed"
                    ta={"center"}
                    py={8}
                    sx={() => ({
                      backgroundColor:
                        theme.colorScheme === "dark" ? theme.colors.dark[6] : "#eff2f7"
                    })}>
                    Compared Date Range
                  </Text>
                  <DateRange
                    className="um-react-date-range"
                    editableDateInputs={false}
                    showDateDisplay={false}
                    onChange={(item: any) => setCompareState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={compareState}
                    maxDate={addDays(new Date(), 0)}
                    rangeColors={["#7D47EB"]}
                  />
                </Box>
              </Box>
              <Box
                className="border-t border-solid flex py-2 justify-end"
                sx={() => ({
                  borderColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.darkBorderColor[0]
                      : theme.colors.lightBorderColor[0]
                })}>
                <Button
                  color="red"
                  size="xs"
                  onClick={() => {
                    setIsCalendarVisible(false);
                  }}>
                  Cancel
                </Button>
                <Button
                  size="xs"
                  ml={6}
                  mr={6}
                  onClick={() => {
                    applyCustomChange();
                  }}>
                  Apply
                </Button>
              </Box>
            </Paper>
          </Box>
        )}
      </Menu>
    </Box>
  );
};

export default DatePickerDropdown;
