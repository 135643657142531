import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Menu,
  Tooltip,
  Text,
  Paper,
  Flex,
  UnstyledButton,
  ThemeIcon
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import {
  getBrowserIcon,
  getBrowserIconColor,
  getCountryIcon,
  getOSIcon,
  getOSIconColor
} from "@/lib/utils/IconsUtility";
import { getCountryName } from "@/lib/utils/Locale";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingStateProps } from "types/types.d";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import { useMediaQuery } from "@mantine/hooks";

const waService = new WebAnalyticsService();

interface IUserActivity {
  url: string;
  os: string;
  browser: string;
  country: string;
  user_type: string;
}

export const VisitorsOnlineCard = ({ query, isPublic, scrollPosition }: any) => {
  const isMobileView = useMediaQuery("(max-width: 768px)");
  const [nextCallTime, setNextCallTime] = useState<number>(30);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [visitorsOnline, setVisitorsOnline] = useState(0);
  // list to store real time visitors activity in the last 60 seconds
  const [visitorsActivityList, setVisitorsActivityList] = useState<any>([]);
  const navigate = useNavigate();

  const fetchVisitorsCount = async () => {
    setLoading("loading");
    await waService
      .visitors(activeWorkspace.id, "online", query)
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setVisitorsOnline(res.data.visitors_online);
          setVisitorsActivityList(res.data.visitors_info);
        }
      })
      .catch((err) => {});
    setLoading("loaded");
  };

  /**
   * Pure component to render information for the users activity.
   * @param url
   * @param os
   * @param browser
   * @param country
   * @constructor
   */
  const UsersActivity = React.memo(({ url, os, browser, country, user_type }: IUserActivity) => {
    return (
      <Flex
        align={"center"}
        sx={{
          color: "#6b7280",
          fontSize: "1rem",
          margin: "0.25rem 0",
          "& > *:not(:last-child)": {
            marginRight: "0.5rem"
          }
        }}>
        <Avatar
          size="xs"
          variant={"filled"}
          radius={"xl"}
          color={"brand"}
          mr={10}
          className="uppercase">
          {user_type === "visitor" ? "V" : <FontAwesomeIcon size="sm" icon={solid("user")} />}
        </Avatar>
        <Flex></Flex>
        <Text fz="xs" flex={1}>
          {url}
        </Text>
        <Tooltip label={os} position="top" withArrow withinPortal>
          <Text c={getOSIconColor(os)}>{getOSIcon(os)}</Text>
        </Tooltip>
        <Tooltip label={browser} position="top" withArrow withinPortal>
          <Text className={getBrowserIconColor(browser)}>{getBrowserIcon(browser)}</Text>
        </Tooltip>
        <Tooltip label={getCountryName(country)} position="top" withArrow withinPortal>
          <Text mt={4}>{getCountryIcon(country, 16, 16)}</Text>
        </Tooltip>
      </Flex>
    );
  });

  useEffect(() => {
    // Timer to reduce by 1 after every second.
    const tickInterval = setInterval(() => {
      console.debug("setTickInterval");
      if (nextCallTime > 0) {
        setNextCallTime(nextCallTime - 1);
      }
    }, 1000);
    return () => {
      clearInterval(tickInterval);
    };
  }, [nextCallTime]);

  useEffect(() => {
    fetchVisitorsCount();
    // Send request again to check if the events are in or not.
    const intervalId = setInterval(() => {
      // TODO: send a call to check if the event is received or not.
      console.debug("setIntervalId");
      fetchVisitorsCount();
      setNextCallTime(10);
    }, 10000);
    return () => {
      clearInterval(intervalId); //This is important
    };
  }, [activeWorkspace.id, query]);

  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);

  if (isMobileView) {
    return <></>;
  }

  return (
    <>
      <Box
        style={{
          display: scrollPosition > 0 ? "none" : "block"
        }}>
        <Menu shadow="md" width={450}>
          <Menu.Target>
            <UnstyledButton>
              <Flex align={"center"}>
                <span className="flex h-2.5 w-2.5 relative mr-2 ">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-300 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-green-500"></span>
                </span>
                <Text span fw={700}>
                  {visitorsOnline}
                </Text>
                <Text pl={8}>
                  <span className="hidden sm:flex sm:pr-1">current</span> visitor
                  {visitorsOnline > 1 && "s"}
                </Text>
                <ThemeIcon variant="subtle" c="gray.7" fz="xs">
                  <FontAwesomeIcon icon={regular("angle-down")} />
                </ThemeIcon>
              </Flex>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            {visitorsActivityList.length > 0 ? (
              <>
                <Menu.Label pt={8}>
                  <Text>
                    <Text span fw={700}>
                      {visitorsActivityList.length}
                    </Text>{" "}
                    real-time pageviews in the last 60 seconds
                  </Text>
                </Menu.Label>
                <div className="max-h-72 overflow-y-auto">
                  {visitorsActivityList.map((item: any, inde: number) => (
                    <Menu.Item
                      key={item.user_anonymous_id}
                      py={0}
                      onClick={() =>
                        !isPublic
                          ? setOverviewModalOpen(true, "visitor", item.user_anonymous_id)
                          : // navigate(
                            //     `/env/${activeWorkspace.identifier}/visitors/everyone/${item.user_anonymous_id}`
                            //   )
                            null
                      }>
                      <UsersActivity
                        url={item.doc_path}
                        os={item.os}
                        browser={item.browser}
                        country={item.country}
                        user_type={item.user_type}
                      />
                    </Menu.Item>
                  ))}
                </div>
              </>
            ) : (
              <Text ta="center" fz="xs">
                No page views now
              </Text>
              // <Menu.Item>
              // </Menu.Item>
            )}

            {isPublic ? (
              <></>
            ) : (
              <>
                <Menu.Divider />
                <Menu.Item
                  className="text-xs"
                  onClick={() => {
                    navigate(`/env/${activeWorkspace.identifier}/visitors/everyone`);
                  }}>
                  <Text
                    ta="center"
                    size="xs"
                    c="dimmed"
                    className="flex justify-center text-sm items-center">
                    <FontAwesomeIcon icon={"external-link"} className={"pr-2"} />
                    View all users activity
                  </Text>
                </Menu.Item>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      </Box>
    </>
  );
};
