import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BarChartSkeleton } from "@/lib/utils/ChartsSkeletons";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { FC } from "react";
// @ts-ignore
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, Button, Flex, Paper, Progress, Table, useMantineTheme } from "@mantine/core";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { Oval } from "react-loader-spinner";
import { IReportType } from "../../../../../../types/types.d";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";
interface SlippingAwayUsersTableProps {
  slippingAwayUsersCount: Array<{
    period: string;
    users: number;
  }>;
  isCountLoading: string;
  totalUsers: number;
  handleSlippingAwayUsersModal: (days: string) => void;
  reportType?: IReportType;
}

export const SlippingAwayUsersTable: FC<SlippingAwayUsersTableProps> = ({
  slippingAwayUsersCount,
  isCountLoading,
  totalUsers,
  handleSlippingAwayUsersModal,
  reportType = "user"
}) => {
  return (
    <Paper p="md" withBorder shadow="sm">
      <Table verticalSpacing={"md"} striped withTableBorder>
        <Table.Thead tt={"uppercase"}>
          <Table.Tr>
            <Table.Th>No. of sessions in days</Table.Th>
            <Table.Th>Slipping away {reportType == "user" ? "users" : "companies"} count</Table.Th>
            <Table.Th w={"20%"}>Percent</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {isCountLoading === "loaded" ? (
            <>
              {slippingAwayUsersCount.map(
                (item: { period: string; users: number }, index: number) => (
                  <Table.Tr key={`${index}:${item.period}`}>
                    <Table.Td className="font-medium">{item.period}</Table.Td>
                    <Table.Td>{numberToCommas(item.users)}</Table.Td>
                    <Table.Td>
                      <Flex align="center" pr={"sm"}>
                        <Progress
                          w={"100%"}
                          styles={(theme) => ({
                            label: {
                              fontSize: "7px"
                            }
                          })}
                          radius={"sm"}
                          size="md"
                          value={Math.round((item.users / totalUsers) * 100)}
                        />
                        <Box ml={"sm"}>{Math.round((item.users / totalUsers) * 100)}%</Box>
                      </Flex>
                    </Table.Td>
                    <Table.Td>
                      <Button
                        size="compact-xs"
                        variant="light"
                        onClick={() => {
                          handleSlippingAwayUsersModal(
                            item.period.replaceAll(" ", "_").toLowerCase()
                          );
                        }}>
                        View {reportType == "user" ? "Users" : "Companies"}
                      </Button>
                    </Table.Td>
                  </Table.Tr>
                )
              )}
            </>
          ) : (
            <>
              <tr>
                <td colSpan={4}>
                  <div className="flex justify-center items-center">
                    <Oval color={LOADER_COLOR} height={14} width={14}></Oval>
                  </div>
                </td>
              </tr>
            </>
          )}
        </Table.Tbody>
      </Table>
    </Paper>
  );
};
