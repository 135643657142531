import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Select,
  TextInput,
  Text,
  Divider,
  Tooltip,
  Center
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspacePinnedEventsService } from "@/lib/services/WorkspacePinnedEventsService";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { usePinnedEventStore } from "@/stores/usePinnedEventStore";
import { PinnedEventCondition } from "./PinnedEventCondition";
import classes from "./PinnedEventsQueryBuilder.module.css";
export const PinnedEventsQueryBuilder = () => {
  /**
   * State isLoaded will be set when the component is loaded for the first time.
   * This is used to prevent sending multiple requests to the backend when the component is loaded
   * in case of editing.
   */
  const [isLoaded, setIsLoaded] = useState(false);
  /**
   * Pinned event zustand store.
   */
  const [rules, setRules, fetchTotalEvents] = usePinnedEventStore((state) => [
    state.rules,
    state.setRules,
    state.fetchTotalEvents
  ]);
  /**
   * Current active workspace
   */
  const { activeWorkspace } = useContext(AppLifecycleContext);

  /**
   * This function is called when the user clicks on the "Add condition" button. It adds a new condition
   * to the rules array only if the last condition is valid.
   *
   * @param ruleIndex The index of the rule against which conditions are checked.
   * @returns
   */
  const checkConditionValueAddedToRules = (ruleIndex: number) => {
    const isValuesAdded = rules[ruleIndex].conditions.some(
      (condition) => condition.value.length === 0
    );
    return isValuesAdded ? false : true;
  };

  /**
   * Function to add a new condition to the rule.
   * @param ruleIndex
   */
  const handleAddCondition = (ruleIndex: number) => {
    if (!checkConditionValueAddedToRules(ruleIndex)) {
      toast.error("Please add value to the condition first.");
      return;
    }

    // Count the total number of unique clicks
    fetchTotalEvents(activeWorkspace.id);

    // Add a new condition to the rule.
    const newRules = rules.map((rule, index) => {
      if (index === ruleIndex) {
        rule.conditions.push({
          selector: "label_text",
          operator: "is",
          value: ""
        });
      }
      return rule;
    });
    setRules(newRules);
  };

  /**
   * Add a new rule to the rules array.
   */
  const handleAddRule = () => {
    setRules([
      ...rules,
      {
        conditions: [
          {
            selector: "label_text",
            operator: "is",
            value: ""
          }
        ]
      }
    ]);
  };

  /**
   * Function to update the selector value of a condition
   * @param value Value of the selector
   * @param ruleIndex
   * @param conditionIndex
   */
  const handleOnSelectorChange = (value: string, ruleIndex: number, conditionIndex: number) => {
    const newRules = rules.map((rule, index) => {
      if (index === ruleIndex) {
        rule.conditions[conditionIndex].selector = value;
      }
      return rule;
    });
    setRules(newRules);
    fetchTotalEvents(activeWorkspace.id);
  };
  /**
   * Function to update the operator value of a condition
   * @param value
   * @param ruleIndex
   * @param conditionIndex
   */
  const handleOnOperatorChange = (value: string, ruleIndex: number, conditionIndex: number) => {
    const newRules = rules.map((rule, index) => {
      if (index === ruleIndex) {
        rule.conditions[conditionIndex].operator = value;
      }
      return rule;
    });
    setRules(newRules);
    fetchTotalEvents(activeWorkspace.id);
  };

  /**
   * Function to update the value of a condition
   * @param value
   * @param ruleIndex
   * @param conditionIndex
   */
  const handleOnValueChange = (value: string, ruleIndex: number, conditionIndex: number) => {
    const newRules = rules.map((rule, index) => {
      if (index === ruleIndex) {
        rule.conditions[conditionIndex].value = value;
      }
      return rule;
    });
    setRules(newRules);
  };

  /**
   * Function to fetch suggestions from conditions of a single rule
   */

  const handleFetchOptions = async (ruleIndex: number, conditionIndex: number, query: string) => {
    const rule = rules[ruleIndex];
    const condition = rule.conditions;
    return await new WorkspacePinnedEventsService()
      .suggestionsByConditions(activeWorkspace.id, {
        conditions: condition,
        selected_condition: condition[conditionIndex],
        query: query
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return [];
      });
  };

  useEffect(() => {
    /**
     * Hack used here, because in case of edit mode, if there are multiple conditions added, it will be
     * sending a lot of requests. So, we setIsLoaded to true once the component is mounted after 500 ms,
     * this will avoid sending a lot of requests.
     *
     * Proper solution to implement...
     */
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }, []);

  return (
    <div className="py-4">
      {/* {JSON.stringify(rules)} */}
      {rules.map((rule, ruleIndex) => (
        <>
          <Flex
            pos="relative"
            direction="column"
            key={`rule-${ruleIndex}`}
            className={classes.box}
            p={"sm"}>
            <Flex align={"center"} pt={16} key={ruleIndex}>
              <Flex pos={"relative"} direction={"column"}>
                {rule.conditions.map((condition, conditionIndex) => (
                  <Flex key={`${ruleIndex}-${conditionIndex}`} align={"center"} mb="sm">
                    {conditionIndex === 0 && (
                      <Box w={200}>
                        <Text fw={600} fz="md">
                          Click events where
                        </Text>
                      </Box>
                    )}

                    <>
                      {conditionIndex !== 0 && (
                        <Flex justify={"flex-end"} mr={"md"} w={180}>
                          <Badge radius={"sm"} color={"gray.6"} variant="filled" className="">
                            AND
                          </Badge>
                        </Flex>
                      )}
                    </>
                    <PinnedEventCondition
                      isLoaded={isLoaded}
                      fetchOptions={handleFetchOptions}
                      ruleIndex={ruleIndex}
                      index={conditionIndex}
                      key={`${ruleIndex}-${conditionIndex}`}
                      selector={condition.selector}
                      operator={condition.operator}
                      value={condition.value}
                      onSelectorChange={(value) =>
                        handleOnSelectorChange(value, ruleIndex, conditionIndex)
                      }
                      onOperatorChange={(value) =>
                        handleOnOperatorChange(value, ruleIndex, conditionIndex)
                      }
                      onValueChange={(value) =>
                        handleOnValueChange(value, ruleIndex, conditionIndex)
                      }
                      onRemove={() => {
                        const newRules = rules.map((rule, index) => {
                          if (index === ruleIndex) {
                            rule.conditions.splice(conditionIndex, 1);
                          }
                          return rule;
                        });
                        setRules(newRules);
                        fetchTotalEvents(activeWorkspace.id);
                      }}
                    />
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <div>
              <Button
                variant="subtle"
                className="ml-48"
                mr={16}
                size="xs"
                onClick={() => handleAddCondition(ruleIndex)}
                leftSection={
                  <FontAwesomeIcon
                    className=" text-purple-600"
                    icon={regular("circle-plus")}></FontAwesomeIcon>
                }>
                Add Condition
              </Button>
            </div>

            <Box className={classes.closeButton} pos={"absolute"} top={1} right={1}>
              <Tooltip label="Remove">
                <ActionIcon
                  size={"xs"}
                  fz={"xs"}
                  variant="outline"
                  onClick={() => {
                    setRules(rules.filter((_, index) => index !== ruleIndex));
                  }}
                  color={"red"}>
                  <FontAwesomeIcon icon={regular("trash")} />
                </ActionIcon>
              </Tooltip>
            </Box>
          </Flex>

          {ruleIndex !== rules.length - 1 && (
            <Center>
              <Badge radius={"sm"} color={"gray.7"} variant="filled" className="mb-4">
                OR
              </Badge>
            </Center>
          )}
          {ruleIndex === rules.length - 1 && <Divider my="md" />}
        </>
      ))}

      {/* Option to add a rule conditions */}
      <Flex justify={"center"}>
        <Button
          variant="subtle"
          size="xs"
          onClick={() => handleAddRule()}
          leftSection={
            <FontAwesomeIcon
              className=" text-purple-600"
              icon={regular("circle-plus")}></FontAwesomeIcon>
          }>
          {rules.length === 0 ? "Add Rule" : "OR"}
        </Button>
      </Flex>
    </div>
  );
};
