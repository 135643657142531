/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Reusable VirtualDataTable component for Visitors, people, and other pages.
 * This component is responsible for rendering the large data tables with performance in mind.
 * This component uses ka-table for rendering the table.
 * Core features of this component are:
 * 1. Infinite Scrolling
 * 2. Column resizing
 * 3. Custom cell rendering
 * 4. Sorting
 * 5. Filtering
 * 6. On click row selection
 *
 * @param props
 */
import { Avatar, Box, Flex, Indicator, Loader, Text, Tooltip } from "@mantine/core";
import { ITableProps, Table, kaReducer } from "ka-table";
import DataRowContent from "ka-table/Components/DataRowContent/DataRowContent";
import SortIcon from "ka-table/Components/SortIcon/SortIcon";
import { getSortedColumns } from "ka-table/Utils/PropsUtils";
import {
  hideColumn,
  hideLoading,
  showColumn,
  showLoading,
  updateData,
  updateSortDirection
} from "ka-table/actionCreators";
import { ActionType, SortDirection, SortingMode } from "ka-table/enums";
import { Column } from "ka-table/models";
import { ICellTextProps, IDataRowProps, IHeadCellProps } from "ka-table/props";
import "ka-table/style.css";
import { DispatchFunc } from "ka-table/types";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
// import Flag from "react-world-flags"
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { _getRowValue } from "../../../../lib/utils/Virtualtable";
import LazyFlag from "../LazyFlag/LazyFlag";
import classes from "./VirtualDataTable.module.css";
import { renderSourceIcon } from "./components/Source";

// Using react-fast-compare to compare the before and after props in the memoized component.
// This is used to avoid unnecessary re-renders and improve performance.
// react-fast-compare is a drop-in replacement for lodash.isEqual and is faster than it.
// Read more about it here: https://github.com/formidablelabs/react-fast-compare/blob/master/README.md
import { capitalizeFirstLetter } from "@/lib/utils/StringUtility";
import { EngagementSubtitleItem } from "@/ui/components/App/OverviewModal/components/EngagementSection";
import { format, isToday } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import isEqual from "react-fast-compare";

export interface IVirtualDataTable {
  /**
   * Columns to be rendered in the table
   * For more information on how to define columns, refer to ka-table documentation
   */
  columns: IColumn[];

  /**
   * Specifies the key of the column that should be used as the row key.
   */
  rowKeyField: string;

  /**
   * Specifies if the column resizing should be enabled.
   */
  columnResizing?: boolean;

  /**
   * Height of the table.
   */
  height?: number;

  /**
   * Specifies the initial sort direction.
   */
  initialSortBy?: ISortBy;

  /**
   * Specifies the callback to be called when the table is scrolled to the bottom for loading more data.
   * @param pageIndex - The current page index
   */
  serverCallback: (
    pageIndex: number,
    sortBy: {
      key: string;
      direction: SortDirection;
    }
  ) => Promise<any>;

  /**
   * Specifies the callback to be called when a row is clicked.
   */
  onRowClick?: (row: any) => void;

  /**
   * Optional setter for the table data.
   */
  setData?: (data: any[]) => void;

  /**
   * Callback to be called when no data is available.
   */
  noDataAvailable?: () => void;

  /**
   * Callback to be called when column resize or reordered
   */
  onColumnChange?: (payload: IActionPayload) => void;

  /**
   * Optional hasFilterApplied to be applied to the table.
   */
  hasFilterApplied?: any;
}

export interface IActionPayload {
  type: ActionType.ResizeColumn | ActionType.ReorderColumns;
  columnKey: string;
  targetColumnKey?: string;
  width?: number;
}

export interface ISortBy {
  key: string;
  direction: SortDirection;
}

export interface CellTextProps extends ICellTextProps {
  cellType?:
    | "text"
    | "avatar"
    | "location"
    | "source"
    | "lastSeen"
    | "lastViewedPage"
    | "referer"
    | "engagement";
}

export interface IColumn extends Column {
  key: string;
  children?: IColumn[];
  displayName?: string;
  parentKey?: string;
}

const LOAD_MORE_DATA = "LOAD_MORE_DATA";

export const VirtualDataTable = forwardRef(
  (
    {
      columns = [],
      rowKeyField,
      columnResizing = true,
      height = 500,
      initialSortBy = {
        key: "",
        direction: SortDirection.Descend
      },
      serverCallback = () => Promise.resolve({ list: [], count: 0 }),
      onRowClick = () => {},
      setData = () => {},
      noDataAvailable = () => {},
      onColumnChange = () => {},
      hasFilterApplied = false
    }: IVirtualDataTable,
    ref
  ) => {
    const [tableProps, changeTableProps] = useState<ITableProps>({
      columns,
      columnResizing,
      columnReordering: true,
      rowKeyField,
      virtualScrolling: {
        enabled: true
      },
      singleAction: { type: LOAD_MORE_DATA },
      sortingMode: SortingMode.SingleRemote
    });
    const noMoreData = useRef(false);
    const [sortBy, changeSortBy] = useState<ISortBy>(initialSortBy);
    const pageIndex = useRef(1);

    useEffect(() => {
      noMoreData.current = false;
      pageIndex.current = 1;
    }, [hasFilterApplied]);

    const dispatch: DispatchFunc = async (action) => {
      changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));

      console.log("action", action.type);

      if (action.type === ActionType.ResizeColumn || action.type === ActionType.ReorderColumns) {
        if (action.type === ActionType.ResizeColumn) {
          onColumnChange({
            type: action.type,
            columnKey: action.columnKey,
            width: action.width
          });
        } else {
          onColumnChange({
            type: action.type,
            columnKey: action.columnKey,
            targetColumnKey: action.targetColumnKey
          });
        }
      }

      if (pageIndex.current !== -1) {
        if (action.type === ActionType.UpdateSortDirection) {
          // change sort direction
          const newSortDirection =
            sortBy.direction === SortDirection.Ascend
              ? SortDirection.Descend
              : SortDirection.Ascend;

          // check if existing sort key is same as new sort key
          if (sortBy.key === action.columnKey) {
            // just change the sort direction
            changeSortBy((prevState) => ({
              ...prevState,
              direction: newSortDirection
            }));
          }

          // change the sort key and direction
          changeSortBy({
            key: action.columnKey,
            direction: newSortDirection
          });

          await fetchData(
            1,
            {
              key: action.columnKey,
              direction: newSortDirection
            },
            true
          );
        } else if (action.type === LOAD_MORE_DATA) {
          await fetchData(pageIndex.current, sortBy, false);
        }
      }
    };

    /**
     * Method to fetch the data from the server.
     */
    const fetchData = async (page: number, sortBy: ISortBy, clear = false) => {
      if (noMoreData.current) {
        return;
      }

      await dispatch(showLoading());
      const result = await serverCallback(page, {
        key: sortBy.key,
        direction: sortBy.direction
      });

      if (clear) {
        pageIndex.current = 2;
        await dispatch(updateData([...result.list]));
      } else {
        pageIndex.current = pageIndex.current + 1;
        await dispatch(updateData([...(tableProps.data || []), ...result.list]));
      }

      if (result.list.length === 0) {
        noMoreData.current = true;
      }

      if (result.count === 0) {
        noDataAvailable();
      }
      await dispatch(hideLoading());
    };

    useImperativeHandle(ref, () => ({
      refresh(payload: { columns: IColumn[] }) {
        console.log("refreshing");
        noMoreData.current = false;

        changeTableProps((prevState) => ({
          ...prevState,
          data: [],
          columns: payload?.columns || columns
        }));

        fetchData(1, sortBy, true);
      }
    }));

    useEffect(() => {
      setData(tableProps.data || []);
    }, [setData, tableProps.data]);

    useEffect(() => {
      if (tableProps.columns.length !== columns.length) {
        changeTableProps((prevState) => ({
          ...prevState,
          columns
        }));
      }

      const changedColumns = columns.filter((column) => {
        const existingColumn = tableProps.columns.find((col) => col.key === column.key);
        return existingColumn && existingColumn.visible !== column.visible;
      });

      // dispatch show/hide column action
      changedColumns.forEach((column) => {
        if (column.visible === false) {
          dispatch(hideColumn(column.key));
        } else {
          dispatch(showColumn(column.key));
        }
      });
    }, [columns]);

    /**
     * Custom header cell component.
     */
    const HeadCell: React.FC<IHeadCellProps> = (props) => {
      const {
        column: { title, key, isSortable }
      } = props;
      return (
        <Box
          className="ka-thead-cell-content ka-pointer relative"
          style={{
            color: "#9999a9",
            fontSize: "13px",
            fontWeight: 700,
            textTransform: "uppercase",
            opacity: title ? 1 : 0
          }}
          onClick={() => {
            if (isSortable) {
              dispatch(updateSortDirection(key));
            }
          }}>
          <Box component="span" mr="sm" className={classes.dragIcon}>
            <FontAwesomeIcon
              icon={solid("grip-dots-vertical")}
              // className="mr-2.5 cursor-move relative top-[1px]"
            />
          </Box>

          {title ? title : "-"}
          {getSortedColumns(tableProps).map((column) => {
            if (column.key === key) {
              return <SortIcon key={key} {...props} />;
            }
            return null;
          })}
        </Box>
      );
    };

    return (
      <Box className={classes.tableWrapper}>
        <Table
          {...tableProps}
          dispatch={dispatch}
          childComponents={{
            sortIcon: {
              content: ({ column }) => {
                const up = (
                  <Box component="span" ml={"sm"}>
                    <FontAwesomeIcon icon={regular("chevron-up")} className={"ml-2.5"} />
                  </Box>
                );
                const down = (
                  <Box ml={"sm"} component="span">
                    <FontAwesomeIcon icon={regular("chevron-down")} className={"ml-2.5"} />
                  </Box>
                );
                return column.sortDirection === SortDirection.Ascend ? up : down;
              }
            },
            headCell: {
              content: (props) => {
                return <HeadCell {...props} />;
              },
              elementAttributes: () => ({
                style: {
                  zIndex: 2
                }
              })
            },

            loading: {
              content: () => {
                return (
                  <div className="um-virtual-table--loader">
                    <Loader size="xs" />
                  </div>
                );
              }
            },

            dataRow: {
              content: (props: IDataRowProps) => <DataRowContentMemo {...props} />,
              elementAttributes: () => ({
                onClick: (event, extendedEvent) => {
                  onRowClick(extendedEvent.childProps.rowData);
                }
              })
            },
            cell: {
              content: (props) => {
                switch (props.column.key) {
                  case "avatar":
                    return <CustomCell cellType="avatar" {...props} />;
                  case "location":
                    return <CustomCell cellType="location" {...props} />;
                  case "first_viewed_page":
                  case "last_viewed_page":
                  case "doc_path":
                  case "first_viewed_doc_path":
                    return <CustomCell cellType="lastViewedPage" {...props} />;
                  case "engagement":
                    return <CustomCell cellType="engagement" {...props} />;
                  case "source":
                  case "referer":
                    return <CustomCell cellType="source" {...props} />;
                  case "first_referer":
                    return <CustomCell cellType="referer" {...props} />;
                  case "first_seen":
                  case "last_seen":
                  case "user_created_at":
                  case "last_visited_time":
                  case "company_created_at":
                    return <CustomCell cellType="lastSeen" {...props} />;
                  default:
                    return <CustomCell cellType="text" {...props} />;
                }
              },
              elementAttributes: (props) => ({
                style: {
                  // paddingLeft: props.column.key === "avatar" ? "0px" : "20px",
                  paddingRight: props.column.key === "avatar" ? "0px" : "20px",
                  overflow: "hidden",
                  verticalAlign: "middle"
                }
              })
            },
            tableWrapper: {
              elementAttributes: () => ({
                onScroll: (event, { baseFunc }) => {
                  baseFunc(event);
                  const element = event.currentTarget;
                  if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
                    dispatch({ type: LOAD_MORE_DATA });
                  }
                },
                style: { height: height }
              })
            }
          }}
        />
      </Box>
    );
  }
);

/**
 * Custom data row content component.
 */
const DataRowContentMemo = React.memo(
  (props: IDataRowProps) => {
    return <DataRowContent {...props} />;
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.columns, nextProps.columns);
  }
);

/**
 * Custom cell component.
 */
const CustomCell: React.FC<CellTextProps> = React.memo(
  ({ column, dispatch, rowKeyValue, value, cellType, ...props }) => {
    // Utility function to format a date based on whether it's today or not
    const formatDate = (inputDate: string | null) => {
      if (!inputDate) return "";

      const localTime = fromZonedTime(new Date(inputDate.replace(" ", "T")), "UTC");
      if (isToday(localTime)) {
        return format(localTime, "HH:mm:ss");
      } else {
        return format(localTime, "dd MMM yyyy");
      }
    };

    if (cellType === "avatar") {
      const rowData = props.rowData;
      let isOnlineFieldExists = rowData && rowData.hasOwnProperty("is_online");
      return (
        <Flex align={"center"} justify={"center"} px={8} py={2} h={"100%"}>
          {isOnlineFieldExists ? (
            <Indicator
              processing={rowData.is_online ? true : false}
              inline
              size={12}
              offset={5}
              position="bottom-end"
              zIndex={1}
              color={rowData.is_online ? "green" : "red"}
              withBorder>
              <Avatar
                className={classes.avatarImage}
                src=""
                alt="Image"
                variant="filled"
                size={"sm"}
                radius="xl"
                color="violet">
                {value}
              </Avatar>
            </Indicator>
          ) : (
            <Avatar
              src=""
              alt="Image"
              variant="filled"
              size={"sm"}
              radius="xl"
              color="violet"
              className={classes.avatarImage}
              tt="uppercase">
              {value}
            </Avatar>
          )}
        </Flex>
      );
    }

    if (cellType === "location") {
      const location = value ? value.split("|||") : ["", ""];

      return (
        <Flex align={"center"}>
          <LazyFlag code={location[0]} width={24} height={24} />
          <Text ml={"xs"} className=" ka-cell-text">
            <Text fz={13}>{location[1] || "Unknown City"}</Text>
            <Text fz="xs">{_getRowValue("location_country", location[0])}</Text>
          </Text>
        </Flex>
      );
    }

    if (cellType === "engagement") {
      const [_, user_engagement_level] = value ? value.split("|||") : ["", ""];
      return (
        <Flex align="center">
          <div className="ka-cell-text">
            <EngagementSubtitleItem subtitle={user_engagement_level || "At Risk"} />
          </div>
        </Flex>
      );
    }

    if (cellType === "lastSeen") {
      return (
        <Text className="ka-cell-text " fz={13}>
          <Text fz={13}>{_getRowValue(column.key, value)}</Text>
          <Text span fz="xs" c="dimmed">
            {formatDate(value)}
          </Text>
        </Text>
      );
    }

    if (cellType === "lastViewedPage") {
      const [url, title] = value ? value.split("|||") : ["", ""];

      return (
        <div className="ka-cell-text text-base">
          <Text fz={13}>{title}</Text>

          <Tooltip label={url} withArrow withinPortal>
            <Text fz="xs" c="dimmed" className="text-sm  no-underline">
              {url}
            </Text>
          </Tooltip>
        </div>
      );
    }

    if (cellType === "source") {
      const [source, channel, host] = value ? value.split("|||") : ["", "", ""];
      return (
        <Flex align={"center"} justify={"flex-start"} className=" ka-cell-text">
          <Flex align="center" justify="center" w={20} mr={"xs"}>
            {renderSourceIcon(host, { style: { width: "16px" } })}
          </Flex>

          <Text
            fz={13}
            data-tip={
              value &&
              `Source: ${source}, Channel: ${capitalizeFirstLetter(channel)}, Referrer: ${host}`
            }
            className="ka-cell-text">
            <Text fz={13} span tt="capitalize">
              {channel ? channel : ""}{" "}
            </Text>
            {/* To avoid source & channel both appearing as Direct / Direct */}
            {channel.trim().toLowerCase() != "direct" || source.trim().toLowerCase() != "direct"
              ? (channel.length > 0 ? "/ " : "") + (source ? `${source}` : "Direct")
              : ""}
          </Text>
        </Flex>
      );
    }

    if (cellType === "referer") {
      let url = value;
      if (url && url.length > 0) {
        url = url.replace("http://", "").replace("https://", "").replace("www.", "");

        // Remove trailing slash if exists
        if (url[url.length - 1] === "/") {
          url = url.slice(0, -1);
        }
      }
      return (
        <div className="flex items-center text-base ka-cell-text">
          {renderSourceIcon(value)}
          <div className="ml-3">
            <div data-tip={value} className="ka-cell-text">
              <span>{url ? ` ${url} ` : "Direct"} </span>
            </div>
          </div>
        </div>
      );
    }

    if (column.key.startsWith("events.")) {
      const rowData = props.rowData;
      const newKey = column.key.replace("events.", "");
      const val = rowData[newKey];

      if (typeof val === "string") {
        return (
          <div className="ka-cell-text text-base">
            <Text fz={13}>{_getRowValue(column.key, val)}</Text>
          </div>
        );
      }

      return (
        <div className={"ka-cell-text text-base"}>
          {" "}
          <Text fz={13}>{val}</Text>
        </div>
      );
    }

    if (typeof value === "string") {
      return (
        <div className={"ka-cell-text text-base"}>
          {" "}
          <Text fz={13}>{value.trim() ? value : "..."}</Text>
        </div>
      );
    }

    return (
      <div className={"ka-cell-text text-base"}>
        {" "}
        <Text fz={13}>{value}</Text>
      </div>
    );
  }
);

export default VirtualDataTable;
