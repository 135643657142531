/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip, rgba, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { EChartsInstance, EChartsOption } from "echarts-for-react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import isEqual from "react-fast-compare";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  BarChart,
  CanvasRenderer,
  UniversalTransition
]);

export interface IFeatureEngagementHistogram {
  engagementHistogram: number[];
  reportType: string;
}

export const FeatureEngagementHistogram: React.FC<IFeatureEngagementHistogram> = ({
  engagementHistogram,
  reportType
}) => {
  const [tooltipText, setTooltipText] = useState("");
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const xAxisLabelRef = useRef<HTMLDivElement>(null);

  const [option, setOption] = useState({
    tooltip: {
      borderColor: colorScheme === "dark" ? theme.colors.darkBorderColor[1] : theme.colors.gray[2],
      backgroundColor: colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[9]
      },
      trigger: "item",
      formatter: (params: any) => {
        console.log(params);
        return `<span style="font-weight:500;">${Math.round(
          params.data
        )}</span> ${reportType}(s) with <span style="font-weight:500;">${
          params.name
        }</span> engagement`;
      },
      axisPointer: {
        type: "cross",
        crossStyle: {
          width: 0
        },
        label: {
          backgroundColor: "#676767"
        }
      },
      borderWidth: 0,
      rich: {
        b: {
          fontSize: 13,
          lineHeight: 20
        },
        c: {
          fontSize: 13,
          lineHeight: 20
        }
      }
    },
    // color: ColorsTheme,
    xAxis: {
      type: "category",
      name: "Engagement",
      data: ["Very Low", "Low", "Medium", "High", "Very High"],
      nameLocation: "middle",
      nameGap: 30,
      nameTextStyle: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 13,
        align: "center",
        verticalAlign: "top",
        lineHeight: 30
      }
    },
    yAxis: {
      name: "Users",
      type: "value",
      nameGap: 50,
      nameLocation: "middle",
      nameTextStyle: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 13,
        align: "center",
        verticalAlign: "top",
        lineHeight: 1
      },
      splitLine: {
        show: true,
        // interval: 2,
        lineStyle: {
          width: 0.75,
          color: [colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[2]]
        }
      }
    },
    grid: {
      height: "200px",
      left: 80,
      top: 30
    },
    series: [
      {
        data: engagementHistogram,
        type: "bar",
        showBackground: true,
        barCategoryGap: "60%",
        color: "#7f17d1",
        backgroundStyle: {
          color:
            colorScheme === "dark" ? "rgba(180, 180, 180, 0.1)" : rgba(theme.colors.gray[5], 0.1)
        },
        itemStyle: {
          borderRadius: [8, 8, 0, 0]
        }
      }
    ]
  });

  useEffect(() => {
    setOption({
      ...option,
      series: [
        {
          ...option.series[0],
          data: engagementHistogram
        }
      ]
    });
  }, [engagementHistogram, theme.colorScheme]);

  const onEvents = {
    mouseover: (params: any) => {
      if (params.componentType === "xAxis" && params.targetType === "axisLabel") {
        const fullText = params.value;
        setTooltipText(fullText);
      }
    },
    mouseout: (params: any) => {
      if (params.componentType === "xAxis" && params.targetType === "axisLabel") {
        setTooltipText("");
      }
    }
  };

  return (
    <div>
      <div className="transition-all ease-in-out">
        <Tooltip.Floating
          label={tooltipText}
          withinPortal
          styles={{
            tooltip: {
              display: tooltipText.length === 0 ? "none !important" : ""
            }
          }}>
          <div
            tabIndex={0} // Make the div focusable
            ref={xAxisLabelRef}>
            <MemoizedEchart
              echarts={echarts}
              style={{ height: "300px" }}
              option={option}
              onEvents={onEvents}
            />
          </div>
        </Tooltip.Floating>
      </div>
    </div>
  );
};

type ChartProps = {
  echarts: EChartsInstance;
  style: CSSProperties;
  option: EChartsOption;
  onEvents: Record<string, Function> | undefined;
};

const MemoizedEchart: React.FC<ChartProps> = React.memo(
  ({ echarts, style, option, onEvents }) => (
    <ReactEChartsCore echarts={echarts} style={style} option={option} onEvents={onEvents} />
  ),
  (prevProps, nextProps) => {
    // Here we can perform a deep equality check to prevent re-render
    // For example when option prop doesn't change structurally
    return isEqual(prevProps.option, nextProps.option);
  }
);
