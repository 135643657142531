import { Box, Flex, Text, Tooltip, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import Label from "./Label";
import {
  convertSecondsToReadableFormat,
  ellipsisContent,
  numberToCommas
} from "@/lib/utils/StringUtility";
import { FormattedMessage, useIntl } from "react-intl";
import { useMemo } from "react";
import classes from "./Bar.module.css";
import clsx from "clsx";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
export interface IBarData {
  level: number;
  visitors: number;
  avg_time_in_s: number;
  prev_count: number;
  conv_rate_prev_step: number | null;
  drop_off_rate_prev_step: number | null;
  drop_off_count_prev_step: number;
  conv_rate_from_first_step: number;
  drop_off_rate_from_first_step: number;
  name: string;
}

export interface IBar {
  /**
   * Width of the bar
   */
  width?: number;

  /**
   * Bar data
   */
  data: IBarData;

  /**
   * Max visitors value of the bars
   */
  max?: number;

  /**
   * is last bar
   */
  isLast?: boolean;

  /**
   * Next bar data for meta info
   */
  nextData?: IBarData | null;

  /**
   * Type of the bar
   */
  type?: string;

  /**
   * No meta info
   */
  noMeta?: boolean;

  /**
   * No label
   */
  noLabel?: boolean;
}

const Bar = ({
  width = 70,
  data,
  nextData = null,
  max = 100,
  isLast = true,
  type,
  noMeta = false,
  noLabel = false
}: IBar) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const intl = useIntl();

  const currentHeight = (data.visitors / max) * 100;
  const prevHeight = (data.prev_count / max) * 100;
  // prev_count

  const resolvedType = useMemo(() => {
    if (!type || type === "visitor") {
      return intl.formatMessage({
        id: "insights.type.visitors",
        defaultMessage: "visitors"
      });
    } else if (type === "user") {
      return intl.formatMessage({
        id: "insights.type.users",
        defaultMessage: "users"
      });
    }
    return intl.formatMessage({
      id: "insights.type.companies",
      defaultMessage: "companies"
    });
  }, [type]);

  const TooltipLabel = () => {
    const dataIndex = data.level - 1;
    const header = `<div style="
                              color: ${colorScheme === "dark" ? theme.colors.dark[0] : "#000d21"};
                              font-size: 14px;
                              font-weight: 600;
                              font-family: 'Inter', sans-serif;
                              background: ${
                                colorScheme === "dark"
                                  ? theme.colors.dark[6]
                                  : "rgba(240, 240, 240, 0.4)"
                              };
                              border-bottom: 1px solid rgba(204, 204, 212, 0.31);
                              border-color: ${
                                colorScheme === "dark"
                                  ? theme.colors.dark[6]
                                  : "rgba(204, 204, 212, 0.31)"
                              };
                              padding: 0.75rem 1rem;
                            ">${data.name}</div>`;

    const values = [
      {
        name: intl.formatMessage({
          id: "insights.charts.tooltip.completedStep",
          defaultMessage: "Completed Step"
        }),
        value: numberToCommas(data.visitors),
        color: theme.colors.green[7]
      }
    ];

    if (data.conv_rate_prev_step !== null) {
      values.push({
        name: intl.formatMessage({
          id: "insights.charts.tooltip.completedStepRatio",
          defaultMessage: "Completed Step Ratio"
        }),
        value: data.conv_rate_prev_step + "%",
        color: theme.colors.green[7]
      });
    } else if (dataIndex === 0 && data.conv_rate_prev_step === null) {
      values.push({
        name: intl.formatMessage({
          id: "insights.charts.tooltip.completedStepRatio",
          defaultMessage: "Completed Step Ratio"
        }),
        value: "100%",
        color: theme.colors.green[7]
      });
    }

    if (data.drop_off_count_prev_step && data.drop_off_count_prev_step > 0) {
      values.push({
        name: intl.formatMessage({
          id: "insights.charts.tooltip.dropOffFromPreviousStep",
          defaultMessage: "Drop Off (from previous step)"
        }),
        value: numberToCommas(data.drop_off_count_prev_step),
        color: theme.colors.red[7]
      });
    }

    if (data.drop_off_rate_prev_step !== null) {
      values.push({
        name: intl.formatMessage({
          id: "insights.charts.tooltip.dropOffRatioFromPreviousStep",
          defaultMessage: "Drop Off Ratio (from previous step)"
        }),
        value: data.drop_off_rate_prev_step + "%",
        color: theme.colors.red[7]
      });
    }

    if (data.drop_off_rate_from_first_step && data.drop_off_rate_from_first_step > 0) {
      values.push({
        name: intl.formatMessage({
          id: "insights.charts.tooltip.dropOffRatioFromFirstStep",
          defaultMessage: "Drop Off Ratio (from first step)"
        }),
        value: data.drop_off_rate_from_first_step + "%",
        color: theme.colors.red[7]
      });
    }

    if (data.avg_time_in_s) {
      values.push({
        name: intl.formatMessage({
          id: "insights.charts.tooltip.avgTime",
          defaultMessage: "Avg. Time in Step"
        }),
        value: convertSecondsToReadableFormat(data.avg_time_in_s),
        color: theme.colors.blue[6]
      });
    }

    const html = `
               <div style='
               font-size:11px;
                margin: -10px;
                background-color: ${colorScheme === "dark" ? theme.colors.dark[5] : "#FFF"};
               '>
                  ${header}
                  <div style='
                    display: flex;
                    flex-direction: column;
                    padding: 0.5rem 0;
                  '>
                    ${values
                      .map(
                        (item) =>
                          `
                        <div style='
                          padding: 0.2rem 1rem;
                          display: flex;
                          line-height: 1.25rem;
                          font-size: 0.9325rem;
                          gap: 16px;
                        '>
                          <div style="
                                display: flex;
                                flex-grow: 1;
                                font-family: 'Inter', sans-serif;
                                color: ${item.color}
                              "
                          >
                              ${item.name}
                          </div>
                          <div style="
                            font-weight: 700;
                            font-family: 'Inter', sans-serif;
                            color: ${colorScheme === "dark" ? theme.colors.dark[0] : "#000d21"}
                           "
                           >
                              ${item.value}
                          </div>
                        </div>
                        `
                      )
                      .join(" ")}
                  </div>
                </div>
              `;

    return <Box dangerouslySetInnerHTML={{ __html: html }} />;
  };

  const label =
    data.visitors.toLocaleString("en-US", {
      notation: "compact",
      compactDisplay: "short"
    }) + ` ${resolvedType}`;

  return (
    <Box pos={"relative"} h={"100%"} className={classes.container}>
      {/* Steps Labels */}
      {!noLabel && (
        <>
          <Box className={classes.absoluteContainer}>
            <Box
              className={classes.label}
              style={{
                background: colorScheme === "dark" ? "#2c2e33" : "#f1f3f5",
                color: colorScheme === "dark" ? "#fff" : "#595c5f"
              }}>
              {data.level}
            </Box>

            {ellipsisContent(data.name, 18)}
          </Box>
        </>
      )}

      {/* Hover Label */}
      <Box className={clsx(classes.tooltip, "hover-label")}>{data.name}</Box>
      {/* Bars */}
      <Tooltip.Floating
        label={<TooltipLabel />}
        withinPortal
        styles={{
          tooltip: {
            boxShadow: theme.shadows.xl
          }
        }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            width: `${width}px`,
            height: `${prevHeight > currentHeight ? prevHeight : currentHeight}%`,
            backgroundColor: colorScheme === "dark" ? "#2c2e33" : "#e9ecef",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            position: "relative"
          }}>
          <Box
            style={{
              height: `${currentHeight}%`,
              width: "100%",
              borderTopLeftRadius: "6px",
              borderTopRightRadius: "6px"
            }}
            bg={"#7f17d1"}
            pos="relative">
            <Label label={label} position={"top"} />
          </Box>

          {/* META */}
          {!noMeta && (
            <>
              {!isLast && nextData && (
                <Box
                  className={classes.meta}
                  style={{
                    left: `calc(${width}px + 6px)`
                  }}>
                  <Flex direction='column'>
                    <Text
                      fw={600}
                      fz={11}
                      style={{
                        whiteSpace: "normal",

                        i: {
                          fontSize: "13px",
                          fontStyle: "normal"
                        }
                      }}>
                      <Text fw={600} span c="#c74554" fz={13}>
                        ↓
                      </Text>{" "}
                      <Text fw={600} span fz={12}>
                        {nextData.drop_off_rate_prev_step || 0}%
                      </Text>{" "}
                    </Text>
                    <Text span c="#c74554" fz={11} fw={600}>
                      <FormattedMessage
                        id={"insights.charts.label.dropOff"}
                        defaultMessage={"Drop Off"}
                      />
                    </Text>
                  </Flex>

                  <div>
                    {nextData.avg_time_in_s != 0 && (
                      <Text fw={600} fz={11} c="#a173f3">
                        ⏱ {convertSecondsToReadableFormat(nextData.avg_time_in_s || 0)}
                      </Text>
                    )}
                  </div>
                </Box>
              )}

              {isLast && (
                <Box
                  className={classes.meta}
                  style={{
                    left: `calc(${width}px + 6px)`
                  }}>
                  <div>
                    <Text
                      fw={600}
                      fz={11}
                      style={{
                        whiteSpace: "normal"
                      }}>
                      <Text fz={12} fw={600}>
                        {data.conv_rate_prev_step || 0}%
                      </Text>{" "}
                      <Text c="#26b774" fz={11} fw={600}>
                        <FormattedMessage
                          id={"insights.charts.label.converted"}
                          defaultMessage={"Converted"}
                        />
                      </Text>
                    </Text>
                  </div>

                  <div></div>
                </Box>
              )}
            </>
          )}
        </Box>
      </Tooltip.Floating>
    </Box>
  );
};

export default Bar;
