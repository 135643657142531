import axios from './JWTService';
export class PowerUsersTrendsService {
  private readonly _endpoint;

  constructor() {
    this._endpoint = '/v1/insights_power_users';
  }

  powerUsersHistogram = (
    ws_id: any,
    insight_id: any,
    type: 'weekly' | 'monthly',
    report_type: 'user' | 'company',
    startDate: string,
    endDate: string,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/histogram/${type}/${report_type}?start_date=${startDate}&end_date=${endDate}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  powerUsersActivityHistogram = (
    ws_id: any,
    insight_id: any,
    report_type: 'user' | 'company',
    startDate: string,
    endDate: string,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/${report_type}/activity_histogram?start_date=${startDate}&end_date=${endDate}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  export = (
    ws_id: any,
    insight_id: any,
    list_report_type: string,
    report_type: 'user' | 'company',
    startDate: string,
    endDate: string,
    day: number,
    export_type: string,
    audienceConditions: Array<any>,
    audienceConditionType: string,
    audienceGroupConditions: Array<any>
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._endpoint}/${ws_id}/${insight_id}/list/${list_report_type}/${day}/${export_type}/${report_type}?start_date=${startDate}&end_date=${endDate}`,
          {
            audience_conditions: audienceConditions,
            audience_condition_type: audienceConditionType,
            audience_group_conditions: audienceGroupConditions
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
