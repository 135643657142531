import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { UserService } from "@/lib/services/UserService";
import { TOAST_MESSAGES } from "@/lib/utils/Constants";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { SettingsHeaderContent } from "@/ui/components/App/Settings/SettingsHeaderContent/SettingsHeaderContent";

import { Button, Divider, Input, Title, Text, Box } from "@mantine/core";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";

interface IFormInputs {
  email: string;
}

export const AccountsGeneralEmailChange: React.FC<any> = () => {
  const { user, setUser } = useContext(AppLifecycleContext);
  const [actionState, setActionState] = useState("idle");
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm<IFormInputs>();
  const watchEmail = watch("email", user?.email);
  console.log(`AccountsEmailChange watch email: ${watchEmail}`);
  console.log(`AccountsEmailChange user email: ${user?.email}`);
  const emailChange = async (data: any) => {
    console.log(data);
    const userServiceObj = new UserService();
    setActionState("loading");
    await userServiceObj
      .updateEmailAddress(user?.id, data.email)
      .then((res) => {
        if (res.data) {
          setUser(res.data);
          toast.success(TOAST_MESSAGES.EMAIL_ADDRESS_CHANGED);
        }
      })
      .catch((err) => {
        toast.error("Unable to update email address, please try again.");
      });
    setActionState("loaded");
  };
  return (
    <>
      <Title order={4} fw={600} mb="md">
        Email Address
      </Title>
      <Text mb="md">The email you use to sign in to your Usermaven account.</Text>

      <>
        <form onSubmit={handleSubmit(emailChange)} id="accountsGeneralEmailChange">
          <Box w={"50%"} mb="md">
            <div>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <Input.Wrapper size="xs" id={"workEmail"} label="Email">
                    <Input
                      mt={4}
                      value={value}
                      onChange={onChange}
                      placeholder="carl@usermaven.com"
                      error={(invalid || error) === true}
                      defaultValue={user?.email}
                      data-cy="accounts-general-email"
                    />
                  </Input.Wrapper>
                )}
                name="email"
                control={control}
                defaultValue={user?.email}
                rules={{ required: true, maxLength: 64 }}
              />

              {errors.email?.type === "required" && (
                <ValidationLabel
                  validationType="error"
                  text="Email is required"
                  size="small"
                  customClasses="pt-2"></ValidationLabel>
              )}
              {errors.email?.type === "pattern" && (
                <ValidationLabel
                  validationType="error"
                  text="Please enter a valid email address"
                  size="small"
                  customClasses="pt-2"></ValidationLabel>
              )}
              {errors.email?.type === "maxLength" && (
                <ValidationLabel
                  validationType="error"
                  text="Maximum email length should be less than 64 characters."
                  size="small"
                  customClasses="pt-2"></ValidationLabel>
              )}
            </div>
          </Box>

          <Button
            type="submit"
            disabled={actionState === "loading" || user?.email === watchEmail}
            loading={actionState === "loading"}
            data-cy="accounts-general-email-submit">
            Save
          </Button>
        </form>
      </>
    </>
  );
};
