import create from "zustand";
import { ReportService } from "@/lib/services/ReportService";

export interface IReport {
  id?: string;
  name: string;
  type: "web_analytics" | "kpi_report";
  is_email_notification_channel: boolean;
  is_slack_notification_channel: boolean;
  email_recipients: Array<string>;
  is_paused?: boolean;
  period: "daily" | "weekly" | "monthly";
  weekday?: string;
  schedule_time: string;
  updated_at?: string;
  created_at?: string;
  created_by_user?: {
    full_name: string;
  };
  updated_by_user?: {
    full_name: string;
  };
}

export type Loader = "fetching" | "creating" | "updating" | "deleting" | "none";

export type State = {
  /**
   * List of reports
   */
  list: IReport[];
  /**
   * For updating/creating a report
   */
  report: IReport;

  /**
   * Loader
   */
  loading: Loader;

  /**
   * Report template
   */
  reportTemplate: string;

  /**
   * Preview type
   */
  previewType: "preview" | "live";
};

export type Setter = {
  setList: (list: IReport[]) => void;
  setReport: (report: IReport) => void;
  setLoading: (loading: Loader) => void;
  setReportTemplate: (reportTemplate: string) => void;
  setPreviewType: (previewType: "preview" | "live") => void;
};

export type Actions = {
  fetchReports: (workspaceId: string) => void;
  createReport: (
    workspaceId: string,
    report: IReport,
    onResponse: (type: "success" | "error", message: string) => void
  ) => void;
  updateReport: (
    workspaceId: string,
    report: IReport,
    onResponse: (type: "success" | "error", message: string) => void
  ) => void;
  deleteReport: (workspaceId: string, reportId: string) => void;
  clearReport: () => void;
  fetchReportTemplate: (workspaceId: string, report: IReport, previewType: string) => void;
};

const useReportStore = create<State & Setter & Actions>((set, get) => ({
  // States
  list: [],
  loading: "none",
  report: {
    name: "",
    type: "web_analytics",
    is_email_notification_channel: true,
    is_slack_notification_channel: false,
    email_recipients: [],
    period: "daily",
    weekday: "monday",
    schedule_time: "00:00:00"
  },
  reportTemplate: "",
  previewType: "preview",

  // Setters
  setList: (list) => set({ list }),
  setReport: (report) => set({ report }),
  setLoading: (loading) => set({ loading }),
  setReportTemplate: (reportTemplate) => set({ reportTemplate }),
  setPreviewType: (previewType) => set({ previewType }),

  // Actions
  fetchReports: (workspaceId) => {
    const service = new ReportService();
    set({ loading: "fetching" });
    service
      .getReports(workspaceId)
      .then((res) => {
        if (res.data) {
          set({ list: res.data });
        }
      })
      .catch((err) => {})
      .finally(() => {
        set({ loading: "none" });
      });
  },
  createReport: (workspaceId, report, onResponse) => {
    const service = new ReportService();
    set({ loading: "creating" });
    service
      .createReport(workspaceId, report)
      .then((res) => {
        if (res.data) {
          set({ list: [...get().list, res.data] });
        }
        onResponse("success", "Report created successfully");

        get().fetchReports(workspaceId);
      })
      .catch((err) => {
        let message = err?.request?.response;
        message = JSON.parse(message)?.detail || "Something went wrong, please try again";

        onResponse("error", message);
      })
      .finally(() => {
        set({ loading: "none" });
      });
  },
  updateReport: (workspaceId, report, onResponse) => {
    const service = new ReportService();
    set({ loading: "updating" });
    service
      .updateReport(workspaceId, report.id || "", report)
      .then((res) => {
        if (res.data) {
          set({ list: get().list.map((item) => (item.id === res.data.id ? res.data : item)) });
        }
        onResponse("success", "Report updated successfully");

        get().fetchReports(workspaceId);
      })
      .catch((err) => {
        let message = err?.request?.response;
        message = JSON.parse(message)?.detail || "Something went wrong, please try again";

        onResponse("error", message);
      })
      .finally(() => {
        set({ loading: "none" });
      });
  },
  deleteReport: (workspaceId, reportId) => {
    const service = new ReportService();
    set({ loading: "deleting" });
    service
      .deleteReport(workspaceId, reportId)
      .then((res) => {
        if (res.data) {
          set({ list: get().list.filter((item) => item.id !== reportId) });
        }

        get().fetchReports(workspaceId);
      })
      .catch((err) => {})
      .finally(() => {
        set({ loading: "none" });
      });
  },
  clearReport: () => {
    set({
      report: {
        name: "",
        type: "web_analytics",
        is_email_notification_channel: true,
        is_slack_notification_channel: false,
        email_recipients: [],
        period: "daily",
        weekday: "monday",
        schedule_time: "00:00:00"
      },
      reportTemplate: ""
    });
  },
  fetchReportTemplate: (workspaceId, report, previewType) => {
    set({ loading: "fetching" });
    const service = new ReportService();
    service
      .getReportTemplate(workspaceId, report, previewType)
      .then((res) => {
        if (res.data) {
          set({ reportTemplate: res.data, loading: "none" });
        }
      })
      .catch((err) => {
        set({ loading: "none" });
      });
  }
}));

export default useReportStore;
