import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { cleanAuthLocalStorage, resetAdminLoggedInStorage } from "@/lib/utils/Storage";
import { AuthorizationLayout } from "@/ui/layouts/AuthorizationLayout";
import { Flex, Loader, Text } from "@mantine/core";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Logout = (props: any) => {
  const navigate = useNavigate();
  const { setUser } = useContext(AppLifecycleContext);
  useEffect(() => {
    console.log("Logout Page.");
    document.title = "Logout | Usermaven";
    cleanAuthLocalStorage();

    setUser(null);
    resetAdminLoggedInStorage();
    navigate("/login");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AuthorizationLayout>
      <Flex justify="center" align="center" h={120} direction={"column"}>
        <Loader size="xs" />
        <Text fz="xs">Signing out your session.</Text>
      </Flex>
    </AuthorizationLayout>
  );
};
