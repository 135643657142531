import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Box,
  Divider,
  Flex,
  Paper,
  ScrollArea,
  Switch,
  Title,
  Text,
  Loader
} from "@mantine/core";
import ActivityAccordion from "./ActivityAccordion";
import { Oval } from "react-loader-spinner";
import { loaderColor } from "@/style/Colors";
import InfiniteScroll from "react-infinite-scroll-component";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { useLocalStorage } from "@mantine/hooks";
import { LS_VISITORS_AUTOCAPTURED_PREFERENCE } from "@/lib/utils/Storage";

export interface IActivitiesTab {
  /**
   * Specify the activities section title.
   */
  title?: string;

  /**
   * Specify if the auto-captured switch
   */
  showAutoCapturedSwitch?: boolean;

  /**
   * Specify the callback function to be called when fetching more activities.
   */
  onFetchSessions?: (page: number, autoCaptured: boolean) => Promise<IActivity[] | []>;

  /**
   * Specify the callback function to be called whe fetchin more events.
   */
  onFetchEvents?: (
    sessionID: string,
    page: number,
    autoCaptured?: boolean
  ) => Promise<IEvent[] | []>;

  /**
   * Specify the type of the modal.
   * Either 'company', 'user', or 'visitor'.
   */
  type?: "company" | "user" | "visitor";
}

export interface IActivity {
  session_id: string;
  user_id?: string;
  user_anonymous_id: string;
  session_start_timestamp: string;
  session_end_timestamp: string;
  location_country: string;
  location_city: string;
  parsed_ua_os_family: string;
  parsed_ua_ua_family: string;
  referer?: string;
  pageviews: number;
  custom_events: number;
  autocatured_events: number;
  first_url: string;
  right_user_id: string;
  final_user_id: string;
  duration_in_s: number;
  source: string;
  parsed_device: "Desktop" | "Mobile" | "Tablet" | "Laptop";
  screen_width: number;
}

export interface IEvent {
  doc_path: string;
  doc_search?: string;
  page_title: string;
  referer?: string;
  url: string;
  source_page_url?: string;
  event_id: string;
  event_type: string;
  eventn_ctx_event_id: string;
  _timestamp: string;
  date: string;
  user_first_name?: string;
  user_last_name?: string;
  user_id?: string;
  ac_tag_name: string;
  ac_el_text: string;
  ac_event_type: string;
  ac_id: string;
  ac_name: string;
  ac_href: string;
  event_attributes?: string;
}

export interface IActivityIcon {
  /**
   * Specify the event type.
   */
  eventType?: string;
}

const ActivitiesTab = ({
  title = "Activities",
  onFetchSessions = () => Promise.resolve([]),
  type = "visitor",
  showAutoCapturedSwitch = true,
  onFetchEvents = () => Promise.resolve([])
}: IActivitiesTab) => {
  const [activities, setActivities] = useState<IActivity | any>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [active, setActive] = useState<string | null>(null);
  const [autoCaptured, setAutoCaptured] = useLocalStorage<"true" | "false">({
    key: LS_VISITORS_AUTOCAPTURED_PREFERENCE,
    defaultValue: "true",
    getInitialValueInEffect: false
  });

  const firstUpdate = useRef(true);
  const page = useRef(1);

  /**
   * Fetch activities.
   */
  const fetchActivities = async (clear = false) => {
    console.log("fetching activities");

    if (clear) {
      page.current = 1;
    }

    if (hasMore || clear) {
      page.current === 1 && setLoading(true);
      const newActivities = await onFetchSessions(page.current, autoCaptured === "true");
      if (newActivities.length > 0) {
        setActivities(clear ? [...newActivities] : [...activities, ...newActivities]);
        page.current = page.current + 1;
      } else {
        setHasMore(false);
      }
      setLoading(false);
    }
  };

  /**
   * Fetch events.
   */
  const fetchEvents = async (sessionID: string, page: number) => {
    console.log("fetching events");
    return await onFetchEvents(sessionID, page, autoCaptured === "true");
  };

  useEffect(() => {
    (async () => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        await fetchActivities();
        return;
      }
      await fetchActivities(true);
    })();
  }, [autoCaptured]);

  return (
    <Paper withBorder shadow="sm">
      <Flex justify={"space-between"} p={"xs"}>
        <Title order={5} fw={500}>
          {title}
        </Title>
        {showAutoCapturedSwitch && (
          <Switch
            labelPosition="left"
            label={`Show auto-captured events`}
            size="xs"
            checked={autoCaptured === "true"}
            onChange={(event) => setAutoCaptured(event.currentTarget.checked ? "true" : "false")}
          />
        )}
      </Flex>
      <Divider />
      <ScrollArea>
        <Box p={"xs"} className="p-4 overflow-y-auto ">
          {!loading ? (
            <Accordion
              radius="xs"
              chevronPosition="left"
              variant="contained"
              styles={{
                chevron: {
                  marginTop: "16px",
                  transform: "rotate(270deg)",
                  alignSelf: "self-start"
                },
                control: {
                  border: "none"
                },
                item: {
                  border: "none",
                  ".um-activity-detail-box": {
                    transform: "scaleY(1)",
                    transition: "transform 0.26s ease",
                    marginBottom: "2px"
                  },
                  '[data-active="true"] .um-activity-detail-box': {
                    transform: "scaleY(0)",
                    transformOrigin: "top",
                    display: "none"
                  }
                },
                content: {
                  padding: "16px 43px"
                }
              }}
              onChange={(index) => setActive(index)}>
              <InfiniteScroll
                scrollableTarget="overview-modal-inner"
                dataLength={activities.length}
                next={fetchActivities}
                hasMore={hasMore}
                endMessage={
                  activities.length > 0 ? (
                    <Text ta="center" fw={500} style={{ textAlign: "center" }} pt="sm">
                      <b>Yay! You have seen it all</b>
                    </Text>
                  ) : null
                }
                loader={
                  <div className="um-virtual-table-loader">
                    <Oval color={LOADER_COLOR} height={16} width={16} />
                  </div>
                }>
                {activities &&
                  activities.map((activity: IActivity, index: number) => {
                    return (
                      <ActivityAccordion
                        onFetchEvents={fetchEvents}
                        key={index}
                        activity={activity}
                        isActive={active === activity.session_id}
                        type={type}
                      />
                    );
                  })}
              </InfiniteScroll>

              {activities.length === 0 && (
                <div className="text-center text-gray-500 py-4">No activities found.</div>
              )}
            </Accordion>
          ) : (
            <>
              <Flex justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
                <Loader size="xs" />
              </Flex>
            </>
          )}
        </Box>
      </ScrollArea>
    </Paper>
  );
};

export default ActivitiesTab;
