import {
  Group,
  Title,
  Text,
  Container,
  Box,
  Grid,
  Image,
  Flex,
  Textarea,
  Button,
  Center,
  AspectRatio,
  useMantineTheme
} from '@mantine/core';
import React, { useContext, useState } from 'react';
import { SidePanel } from '@/ui/components/Common/SidePanel/SidePanel';
import CapterraLogo from '@assets/images/ai-chat-assistant/capterra_logo.png';
import G2Logo from '@assets/images/ai-chat-assistant/g2-logo.webp';
import AppLifecycleContext from '@/lib/contexts/AppLifecycleContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import { UserService } from '@/lib/services/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface IReviewPageContent {
  title: string,
  feature: 'ai-chat' | 'journey',
  src: string,
}

export const ReviewPageContent = ({ title, feature, src }: IReviewPageContent) => {
  const { user, activeWorkspace } = useContext(AppLifecycleContext);
  const [active, setActive] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const sendReviewToSlack = async () => {
    // send message to slack
    if (message.trim().length === 0 || !message) {
      toast.error('Please enter a message first.');
      return;
    }
    setLoading(true);

    let msg = `${message}
    
    ------
    Review to unlock feature: ${feature}
    `;

    await new UserService()
      .AIChatReviewSubmit(user?.id, active, msg)
      .then(res => {
        if (res.status === 200) {
          toast.success('Message sent successfully.');
        }
        setSubmitted(true);
      })
      .catch(err => {
        toast.error('Something went wrong.');
      });
    setLoading(false);
  };

  const theme = useGlobalMantineTheme();

  return (
    <>
      <Container size='md' pb={'xl'}>
        <Group align='center'>
          {submitted ? (
            <>
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  margin: '0 auto'
                }}
              >
                <FontAwesomeIcon icon={regular('check-circle')} size='5x' color='#4dc74d' />
              </Box>

              <Title order={3} align='center' mb={8} px={32}>
                Your review has been submitted successfully. Thank you for your support.
              </Title>
            </>
          ) : (
            <>
              <Title
                order={3}
                align='center'
                mb={'1.6rem'}
                mt={'3rem'}
                sx={{
                  display: 'Flex'
                }}
              >
                {title}
              </Title>
              <Box sx={{

                // AspectRatio
                height: '0',
                paddingBottom: '57.25%',
                position: 'relative',
                width: '100%',
                '& > *': {
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%'
                },
                '& > video': {
                  width: '100%',
                  height: '100%',
                  borderRadius: '0.5rem'
                },
              }}>
                <video width="100%" height="100%" controls>
                  <source src={src} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              </Box>
              <Box
                mb={16}
                sx={{
                  width: '100%'
                }}
              >
                <Text ta='center' size='md' mt={'sm'}>
                  But we need a small favor from you to UNLOCK this tool for you.
                </Text>
              </Box>

              <Box
                sx={{
                  width: '100%'
                }}
              >
                <Title
                  variant='gradient'
                  gradient={{ from: 'brand', to: 'pink', deg: 45 }}
                  align='center'
                  mb={16}
                  order={3}
                >
                  Step 1
                </Title>
                <Text ta='center' size='md'>
                  Please write a few words about Usermaven on any of the below mentioned website.
                </Text>
                <Grid mb={16} mt={16} gutter={32}>
                  <Grid.Col span={6}>
                    <Flex
                      onClick={() => {
                        setActive('capterra');
                      }}
                      justify={'center'}
                      align='center'
                      direction='column'
                      sx={theme => {
                        return {
                          transition: 'all 0.2s ease',
                          border: `1px solid ${
                            theme.colorScheme === 'light'
                              ? theme.colors.lightBorderColor[0]
                              : theme.colors.darkBorderColor[0]
                          }`,
                          borderColor: active === 'capterra' ? theme.colors.brand[5] : '',
                          padding: '1rem',
                          borderRadius: '0.5rem',
                          cursor: 'pointer',
                          '&:hover': {
                            borderColor: theme.colors.brand[5]
                          }
                        };
                      }}
                    >
                      <Image src={CapterraLogo} width={124.77} height={30} mb={16} />
                      <a
                        className='primary-link-color'
                        href='https://www.capterra.com/p/276941/Usermaven/'
                        target='_blank'
                      >
                        Write a review on Capterra
                      </a>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Flex
                      justify={'center'}
                      align='center'
                      direction='column'
                      onClick={() => {
                        setActive('g2');
                      }}
                      sx={theme => {
                        return {
                          transition: 'all 0.2s ease',
                          border: `1px solid ${
                            theme.colorScheme === 'light'
                              ? theme.colors.lightBorderColor[0]
                              : theme.colors.darkBorderColor[0]
                          }`,
                          borderColor: active === 'g2' ? theme.colors.brand[5] : '',
                          padding: '1rem',
                          borderRadius: '0.5rem',
                          cursor: 'pointer',
                          '&:hover': {
                            borderColor: theme.colors.brand[5]
                          }
                        };
                      }}
                    >
                      <Image src={G2Logo} width={32} height={32} mb={16} />
                      <a
                        className='primary-link-color'
                        href='https://www.g2.com/products/usermaven/reviews'
                        target='_blank'
                      >
                        Write a review on G2
                      </a>
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>

              <Box
                sx={{
                  width: '100%'
                }}
              >
                <Title
                  align='center'
                  mb={16}
                  order={3}
                  variant='gradient'
                  gradient={{ from: 'brand', to: 'pink', deg: 45 }}
                >
                  Step 2
                </Title>
                <Text ta='center' size='md' align='center' mb={16}>
                  Once you have submitted your review, please send us a message below with a proof
                  to verify that you’ve actually submitted the review.
                </Text>
                <Text ta='center' size='sm' mb={16}>
                  Add a link to the review post that you’ve submitted and/or send us a screenshot
                  or a video proof using loom.com/screencast etc.
                </Text>
                <Textarea
                  value={message}
                  onChange={event => setMessage(event.currentTarget.value)}
                  minRows={4}
                  placeholder='Your link to the review or proof message here.'
                ></Textarea>
                <Center mt={16}>
                  <Button
                    loading={loading}
                    disabled={loading || message.length === 0}
                    onClick={sendReviewToSlack}
                  >
                    Submit
                  </Button>
                </Center>
              </Box>
            </>
          )}
        </Group>
      </Container>
    </>
  );
};
