import { Button, Checkbox, Flex, Modal, Switch, Text, TextInput } from "@mantine/core";
import { useContext } from "react";
import { useWebSavedViewsStore } from "@/stores/useWebSavedViewsStore";
import { LooseObject } from "types/types.d";
import { AppLifecycleContext } from "../../../../../lib/contexts/AppLifecycleContext";

interface Props {
  opened: boolean;
  onClose: () => void;
  query: any;
}

/**
 *  Saved views modal is used to create a new saved view or edit an existing saved view.
 * @param props
 * @returns
 */
export const SavedViewsModal = ({ query, opened, onClose }: Props) => {
  // Saved views store
  const [
    id,
    name,
    setName,
    includeTimePeriod,
    setIncludeTimePeriod,
    isPublic,
    setIsPublic,
    isSelectedDomain,
    setIsSelectedDomain,
    saveView,
    updateView,
    list,
    saveNew,
    setSaveNew,
    reset
  ] = useWebSavedViewsStore((state) => [
    state.id,
    state.name,
    state.setName,
    state.includeTimePeriod,
    state.setIncludeTimePeriod,
    state.isPublic,
    state.setIsPublic,
    state.isSelectedDomain,
    state.setIsSelectedDomain,
    state.create,
    state.update,
    state.list,
    state.saveNew,
    state.setSaveNew,
    state.reset
  ]);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  /**
   * List of supported filters, function to set their values.
   * @returns {LooseObject}
   */
  const getQueryFilters = (): LooseObject => {
    // default filters
    let filters: LooseObject = {
      domain: null,
      browser: null,
      browser_version: null,
      city: null,
      country: null,
      entry_page: null,
      event_type: null,
      exit_page: null,
      from_date: null,
      goal: null,
      os: null,
      os_version: null,
      page: null,
      referrer: null,
      region: null,
      screen: null,
      source: null,
      to_date: null,
      utm_campaign: null,
      utm_content: null,
      utm_medium: null,
      utm_source: null,
      utm_term: null
    };

    // iterate over selected filters and set their value
    query.filters.forEach((filter: any) => {
      const splitValue = filter.split(":");
      if (splitValue[0]) {
        filters[splitValue[0]] = splitValue[1];
      }
    });

    // set time period
    filters = {
      ...filters,
      from_date: includeTimePeriod ? query.from_date : null,
      to_date: includeTimePeriod ? query.to_date : null,
      domain: isSelectedDomain ? query.domain : null
    };
    return filters;
  };

  /**
   * This function will save the view by calling the API from store.
   */
  const onSave = () => {
    saveView(activeWorkspace.id, {
      ...getQueryFilters(),
      name: name,
      is_public_view: isPublic,
      include_time_period: includeTimePeriod,
      include_domain: isSelectedDomain
    });
    reset();
  };
  /**
   * This function will update the view by calling the API from store.
   */
  const onUpdate = () => {
    console.log(query, getQueryFilters());
    updateView(activeWorkspace.id, id, {
      ...getQueryFilters(),
      name: name,
      is_public_view: isPublic,
      include_time_period: includeTimePeriod,
      include_domain: isSelectedDomain
    });
    reset();
  };

  /**x
   * This function will set the name value of the view.
   * @param value boolean: true if the user wants to save the view as a new one.
   */
  const handleSaveAsNew = (value: boolean) => {
    if (value) {
      setName("");
    } else {
      setName(list.find((item) => item.id === id)?.name || "");
    }
    setSaveNew(value);
  };

  return (
    <Modal size={"md"} title={`Save View`} opened={opened} onClose={onClose}>
      <Flex direction="column">
        <Text mb="md">
          Once you have added filters and customized the data shown in the dashboard, you can save
          it to be accessed for future use - showing you exactly what you want to see quickly.
        </Text>
        {id && (
          <Switch
            checked={saveNew}
            onChange={(event: any) => handleSaveAsNew(event.currentTarget.checked)}
            label="Save as new"
            size="xs"
            mb={8}
          />
        )}
        <TextInput
          mb="md"
          value={name}
          placeholder="Enter a name for the view"
          onChange={(event) => setName(event.currentTarget.value)}
          data-cy="save-view-name"
        />
        <Checkbox
          mb="xs"
          size="xs"
          label="Save with the selected domain"
          checked={isSelectedDomain}
          onChange={(event) => setIsSelectedDomain(event.currentTarget.checked)}
        />
        <Checkbox
          mb="xs"
          size="xs"
          label="Save with the selected time period"
          checked={includeTimePeriod}
          onChange={(event) => setIncludeTimePeriod(event.currentTarget.checked)}
          data-cy="save-view-date-preference"
        />
        <Checkbox
          mb="xs"
          mr={4}
          size="xs"
          label="Available on public (shared) dashboard"
          checked={isPublic}
          onChange={(event) => setIsPublic(event.currentTarget.checked)}
        />
      </Flex>
      <Flex justify={"flex-end"}>
        {id && !saveNew ? (
          <Button onClick={onUpdate} disabled={name === ""} data-cy="save-view-save-btn">
            Update
          </Button>
        ) : (
          <Button onClick={onSave} disabled={name === ""} data-cy="save-view-save-btn">
            Save
          </Button>
        )}
      </Flex>
    </Modal>
  );
};
