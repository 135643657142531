import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Loader,
  Paper,
  Skeleton,
  Table,
  Title,
  useMantineColorScheme,
  useMantineTheme,
  Text
} from "@mantine/core";
import { format } from "date-fns";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import cloneDeep from "lodash.clonedeep";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { purple } from "@/style/Colors";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "../../../../lib/services/WebAnalyticsService";
import {
  convertSecondsToReadableFormat,
  getReadableFormatNumber
} from "../../../../lib/utils/StringUtility";
import { WorkspaceType } from "../../../../types/types.d";
import { NoResults } from "../NoResults/NoResults";
import "./workspace_item.scss";
import { getWorkspaceAvatarURL } from "@/lib/utils/WorkspaceUtility";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);
const waService = new WebAnalyticsService();

type WorkspaceItemStatisticsProps = {
  wsItem: WorkspaceType;
  query: {
    from_date: string | undefined;
    to_date: string | undefined;
    period?: string | undefined;
  };
  timer: number;
  type?: "card" | "row";
};

interface IStatisticsProps {
  returning_visitors: number;
  returning_visitors_diff: number;
  new_visitors: number;
  new_visitors_diff: number;
  visitors: number;
  visitors_diff: number;
  pageviews: number;
  pageviews_diff: number;
  autocaptured_events: number;
  autocaptured_events_diff: number;
  custom_events: number;
  custom_events_diff: number;
  sessions: number;
  sessions_diff: number;
  visit_duration: number;
  visit_duration_diff: number;
  bounce_rate: number;
  bounce_rate_diff: number;
  events: number;
  events_diff: number;
}

const DEFAULT_STATS: IStatisticsProps = {
  returning_visitors: 0,
  returning_visitors_diff: 0,
  new_visitors: 0,
  new_visitors_diff: 0,
  visitors: 0,
  visitors_diff: 0,
  pageviews: 0,
  pageviews_diff: 0,
  autocaptured_events: 0,
  autocaptured_events_diff: 0,
  custom_events: 0,
  custom_events_diff: 0,
  sessions: 0,
  sessions_diff: 0,
  visit_duration: 0,
  visit_duration_diff: 0,
  bounce_rate: 0,
  bounce_rate_diff: 0,
  events: 0,
  events_diff: 0
};

export const WorkspaceItemStatistics: React.FC<WorkspaceItemStatisticsProps> = ({
  wsItem = {
    id: "",
    name: "",
    created_at: "",
    members: [],
    color: "",
    is_setup_completed: false,
    organization: {
      state: "",
      subscriptions: []
    },
    logo: ""
  },
  query = {
    from_date: "",
    to_date: "",
    period: undefined
  },
  timer = 0,
  type = "card"
}) => {
  const theme = useGlobalMantineTheme();
  const [statistics, setStatistics] = useState<IStatisticsProps>(DEFAULT_STATS);

  const [option, setOption] = useState({
    animationDuration: 500,
    animationDurationUpdate: 500,
    color: [type === "card" ? purple : "#adb5bd"],
    title: {
      text: ""
    },
    grid: {
      top: "5%",
      left: "3%",
      right: "3%",
      bottom: "1%",
      containLabel: true
    },
    tooltip: {
      borderColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2],
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: "#595c5f"
      },
      trigger: "axis",
      axisPointer: {
        type: "line",
        label: {
          formatter: (params: any) => {
            return format(new Date(params.value), "eee, MMMM dd, yyyy");
          }
        }
      }
    },
    legend: {
      show: false,
      data: ["Visitors"]
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {}
      }
    },

    xAxis: [
      {
        type: "time",
        axisLine: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        min: 0,
        type: "value",
        splitLine: true,
        alignTicks: true,
        axisLabel: {
          show: false
        },
        axisTick: {
          show: false,
          splitNumber: 4
        }
      }
    ],
    series: [
      {
        name: "Visitors",
        type: "line",
        lineStyle: {
          width: 2
        },
        showSymbol: false,
        emphasis: {
          focus: "series"
        },
        areaStyle: {
          opacity: type === "card" ? 0.8 : 0,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgb(125, 71, 235, 0.3)"
            },
            {
              offset: 1,
              color: "rgba(125, 71, 235, 0.01)"
            }
          ])
        },
        data: []
      }
    ]
  });
  const navigate = useNavigate();
  const { setActiveWorkspace } = useContext(AppLifecycleContext);
  const [isLoading, setIsLoading] = useState("idle");
  const setActiveWorkspaceAndRedirect = () => {
    setActiveWorkspace(wsItem);
    const redirectURL = wsItem.is_setup_completed
      ? `/env/${(wsItem as WorkspaceType).identifier}/web/statistics`
      : `/env/${(wsItem as WorkspaceType).identifier}/setup-guides/integration`;
    navigate(redirectURL);
  };

  const fetchWebAnalyticsWorkspaceStatistics = async () => {
    setIsLoading("loading");
    if (!wsItem.is_setup_completed) {
      setIsLoading("loaded");
      return;
    }

    await waService
      .overview_stats(wsItem.id, query)
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setStatistics(res.data.statistics);
          if (query.period === "hour") {
            option.tooltip.axisPointer.label.formatter = (params: any) => {
              return format(
                new Date(params.value),
                // "eee, MMMM dd, yyyy "
                "eee hh a"
              );
            };
          } else {
            option.tooltip.axisPointer.label.formatter = (params: any) => {
              return format(new Date(params.value), "eee, MMMM dd, yyyy ");
            };
          }
          option.series[0].data = res.data.histogram.histogram.map((item: any) => [
            !item.date.includes(" ") ? `${item.date} 00:00:00` : item.date,
            item.count
          ]);

          setOption(option);
        }
      })
      .catch((err) => setStatistics(DEFAULT_STATS));
    setIsLoading("loaded");
  };
  useEffect(() => {
    fetchWebAnalyticsWorkspaceStatistics();
  }, [query, timer]);

  useEffect(() => {
    const deepCopy = cloneDeep(option);
    deepCopy.tooltip.borderColor =
      theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2];
    deepCopy.tooltip.backgroundColor = theme.colorScheme === "dark" ? theme.colors.dark[9] : "#fff";
    deepCopy.tooltip.textStyle.color = theme.colorScheme === "dark" ? "#A6A7AB" : "#595c5f";
    setOption(deepCopy);
  }, [theme.colorScheme]);

  if (type === "row") {
    if (isLoading === "loading") {
      return (
        <Table.Tr>
          <Table.Td className={"max"}>
            <Skeleton height={16} mt={3} width={"40%"} radius="md" />
          </Table.Td>
          {/* <td>
            <Skeleton height={50} mt={3} radius="md" />
          </td> */}
          <Table.Td>
            <Skeleton height={16} mt={3} radius="md" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={16} mt={3} radius="md" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={16} mt={3} radius="md" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={16} mt={3} radius="md" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={16} mt={3} radius="md" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={30} mt={3} radius="md" />
          </Table.Td>
        </Table.Tr>
      );
    }

    return (
      <>
        <Table.Tr>
          <Table.Td>
            <Flex align={"center"}>
              <Avatar
                size={24}
                bg={"rgba(27,26,64,0.05)"}
                variant="outline"
                radius="xl"
                // className={workspace?.color}
                src={getWorkspaceAvatarURL(wsItem, null, theme.colorScheme)}
                styles={{
                  placeholder: {
                    backgroundColor: "transparent",
                    color: "#fff"
                  }
                }}>
                {wsItem.name.substring(0, 1)}
              </Avatar>

              <Text ml={"sm"} fw={600}>
                {wsItem.name}
              </Text>
            </Flex>
          </Table.Td>
          {/* <td>
            <div>
              <ReactEChartsCore
                style={{ height: "5.5rem", width: "70px" }}
                echarts={echarts}
                option={option}
              />
            </div>
          </td> */}
          <Table.Td>
            {statistics.visitors ? getReadableFormatNumber(statistics.visitors) : "-"}
            {statistics.visitors_diff > 0 ? (
              <Badge color={"green"} ml={"xs"}>
                +{statistics.visitors_diff}%
              </Badge>
            ) : (
              <>
                {statistics.visitors ? (
                  <Badge color={"red"} ml={"xs"}>
                    {statistics.visitors_diff}%
                  </Badge>
                ) : null}
              </>
            )}
          </Table.Td>
          <Table.Td>
            {statistics.sessions ? getReadableFormatNumber(statistics.sessions) : "-"}
            {statistics.sessions_diff > 0 ? (
              <Badge color={"green"} ml={"xs"}>
                +{statistics.sessions_diff}%
              </Badge>
            ) : (
              <>
                {statistics.sessions ? (
                  <Badge color={"red"} ml={"xs"}>
                    {statistics.sessions_diff}%
                  </Badge>
                ) : null}
              </>
            )}
          </Table.Td>
          <Table.Td>
            {statistics.visit_duration
              ? convertSecondsToReadableFormat(statistics.visit_duration)
              : "-"}
            {statistics.visit_duration_diff > 0 ? (
              <Badge color={"green"} ml={"xs"}>
                +{statistics.visit_duration_diff}%
              </Badge>
            ) : (
              <>
                {statistics.visit_duration ? (
                  <Badge color={"red"} ml={"xs"}>
                    {statistics.visit_duration_diff}%
                  </Badge>
                ) : null}
              </>
            )}
          </Table.Td>
          <Table.Td>
            {statistics.bounce_rate ? `${statistics.bounce_rate}%` : `-`}
            {statistics.bounce_rate_diff > 0 ? (
              <Badge color={"green"} ml={"xs"}>
                +{statistics.bounce_rate_diff}%
              </Badge>
            ) : (
              <>
                {statistics.bounce_rate ? (
                  <Badge color={"red"} ml={"xs"}>
                    {statistics.bounce_rate_diff}%
                  </Badge>
                ) : null}
              </>
            )}
          </Table.Td>
          <Table.Td>
            {statistics.events ? getReadableFormatNumber(statistics.events) : "-"}
            {statistics.events_diff > 0 ? (
              <Badge color={"green"} ml={"xs"}>
                +{statistics.events_diff}%
              </Badge>
            ) : (
              <>
                {statistics.events ? (
                  <Badge color={"red"} ml={"xs"}>
                    {statistics.events_diff}%
                  </Badge>
                ) : null}
              </>
            )}
          </Table.Td>
          <Table.Td>
            <Button
              color={theme.colorScheme === "dark" ? "dark.2" : undefined}
              variant="outline"
              size={"xs"}
              onClick={setActiveWorkspaceAndRedirect}>
              Go to Workspace
            </Button>
          </Table.Td>
        </Table.Tr>
      </>
    );
  }

  return (
    <>
      <Paper mb={"lg"} h={520}>
        <Box component="div" pos={"relative"} key={`workspace-box-${wsItem.id}`} p={"md"}>
          {isLoading === "loaded" ? (
            <>
              {!wsItem.is_setup_completed && (
                <Box pos={"absolute"} right={0}>
                  <Badge variant="dot" size="sm" radius="md" color={"yellow"}>
                    Setup Remaining
                  </Badge>
                </Box>
              )}
              {wsItem.is_setup_completed ? (
                <>
                  <Flex justify="center" w={"100%"} h={250}>
                    <ReactEChartsCore
                      style={{ height: "11.5rem", width: "100%" }}
                      echarts={echarts}
                      option={option}
                    />
                  </Flex>
                </>
              ) : (
                <Flex justify={"center"}>
                  <Center py={48}>
                    <NoResults text={"No data found"} heading={""}></NoResults>
                  </Center>
                </Flex>
              )}
            </>
          ) : (
            <Center py="md">
              <Loader size={"sm"} />
            </Center>
          )}
          <Flex align={"center"}>
            <Title order={5} fw={500} py={"sm"} flex="1">
              {wsItem.name}
            </Title>
            <Button
              color={theme.colorScheme === "dark" ? "dark.2" : undefined}
              variant="outline"
              size={"xs"}
              onClick={setActiveWorkspaceAndRedirect}>
              Go to Workspace
            </Button>
          </Flex>
          <Divider my={"sm"} />
          {isLoading === "loading" ? (
            <Center py="md">
              <Loader size={"sm"} />
            </Center>
          ) : (
            <>
              <Flex align={"center"} mb="xs">
                <Title order={6} fw={500} flex="1">
                  Visitors{" "}
                  {statistics.visitors_diff != 0 &&
                    (statistics.visitors_diff > 0 ? (
                      <Badge color={"green"}>+{statistics.visitors_diff}%</Badge>
                    ) : (
                      <Badge color="red">{statistics.visitors_diff}%</Badge>
                    ))}{" "}
                </Title>
                <Title order={6} fw={500}>
                  {getReadableFormatNumber(statistics.visitors)}
                </Title>
              </Flex>
              <Flex align={"center"} mb="xs">
                <Title order={6} fw={500} flex="1">
                  Total Sessions{" "}
                  {statistics.sessions_diff != 0 &&
                    (statistics.sessions_diff > 0 ? (
                      <Badge color={"green"}>+{statistics.sessions_diff}%</Badge>
                    ) : (
                      <Badge color={"red"}>{statistics.sessions_diff}%</Badge>
                    ))}{" "}
                </Title>
                <Title order={6} fw={500}>
                  {getReadableFormatNumber(statistics.sessions)}
                </Title>
              </Flex>
              <Flex align={"center"} mb="xs">
                <Title order={6} fw={500} flex="1">
                  Visit Duration{" "}
                  {statistics.visit_duration != 0 &&
                    (statistics.visit_duration > 0 ? (
                      <Badge color={"green"}>+{statistics.visit_duration}%</Badge>
                    ) : (
                      <Badge color={"red"}>{statistics.visit_duration}%</Badge>
                    ))}{" "}
                </Title>
                <Title order={6} fw={500}>
                  {convertSecondsToReadableFormat(statistics.visit_duration_diff)}
                </Title>
              </Flex>
              <Flex align={"center"} mb="xs">
                <Title order={6} fw={500} flex="1">
                  Bounce Rate{" "}
                  {statistics.bounce_rate_diff != 0 &&
                    (statistics.bounce_rate_diff < 0 ? (
                      <Badge color={"green"}>{statistics.bounce_rate_diff}%</Badge>
                    ) : (
                      <Badge color={"red"}>+{statistics.bounce_rate_diff}%</Badge>
                    ))}{" "}
                </Title>
                <Title order={6} fw={500}>
                  {statistics.bounce_rate}%
                </Title>
              </Flex>
              <Flex align={"center"} mb="xs">
                <Title order={6} fw={500} flex="1">
                  Events{" "}
                  {statistics.events_diff != 0 &&
                    (statistics.events_diff > 0 ? (
                      <Badge color={"green"}>+{statistics.events_diff}%</Badge>
                    ) : (
                      <Badge color={"red"}>{statistics.events_diff}%</Badge>
                    ))}{" "}
                </Title>
                <Title order={6} fw={500}>
                  {getReadableFormatNumber(statistics.events)}
                </Title>
              </Flex>
            </>
          )}
        </Box>
      </Paper>
    </>
  );
};
