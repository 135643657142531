import { numberToCommas } from "@/lib/utils/StringUtility";
import { useContext } from "react";
import { PinnedEventRulesProps, usePinnedEventStore } from "@/stores/usePinnedEventStore";

import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  Flex,
  Grid,
  Input,
  Loader,
  Modal,
  Paper,
  Switch,
  Text,
  TextInput,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspacePinnedEventsService } from "@/lib/services/WorkspacePinnedEventsService";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { PinnedEventsQueryBuilder } from "./PinnedEventsQueryBuilder";
import { usePinnedEventsListStore } from "@/stores/usePinnedEventListStore";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface Props {
  open: boolean;
  isEdit?: boolean;
  closeModal: (value: boolean) => void;
}

export const PinnedEventModal = ({ open, isEdit, closeModal }: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [list, setList, historyList, setHistoryList, fetchHistory] = usePinnedEventsListStore(
    (state) => [
      state.list,
      state.setList,
      state.historyList,
      state.setHistoryList,
      state.fetchHistory
    ]
  );
  const [
    pinnedEventId,
    pinnedEventName,
    createConversionGoal,
    setCreateConversionGoal,
    setPinnedEventName,
    totalEvents,
    rules,
    setRules,
    fetchTotalEvents,
    isTotalEventsLoading
  ] = usePinnedEventStore((state) => [
    state.id,
    state.name,
    state.createConversionGoal,
    state.setCreateConversionGoal,
    state.setName,
    state.totalEvents,
    state.rules,
    state.setRules,
    state.fetchTotalEvents,
    state.isTotalEventsLoading
  ]);

  /**
   * Returns true if all the conditions are valid.
   * @param rules
   * @returns
   */
  const validatePinnedEvent = (rules: PinnedEventRulesProps[]) => {
    const isInvalid = rules.some((rule) => {
      if (rule.conditions.length === 0) {
        toast.error("Please add conditions in all boxes.");
        return true;
      }
      return rule.conditions.some((condition) => {
        if (condition.value.length === 0) {
          toast.error("Please add values in all the conditions.");
          return true;
        }
        return false;
      });
    });
    return !isInvalid;
  };

  const handleSubmit = () => {
    // Validate the pinned event name to be unique
    if (
      list.find(
        (pinnedEvent) =>
          pinnedEvent.name.toLowerCase() === pinnedEventName.trim().toLowerCase() &&
          pinnedEvent.id !== pinnedEventId
      )
    ) {
      toast.error(
        `A pinned event with the name '${pinnedEventName.trim()}' already exists. Please choose a different name.`
      );
      return;
    }
    // Validate the pinned event rules
    if (!validatePinnedEvent(rules)) {
      return;
    }

    let payload = {
      name: pinnedEventName,
      rules: JSON.parse(JSON.stringify(rules)),
      create_conversion_goal: createConversionGoal
    };
    if (pinnedEventId) {
      new WorkspacePinnedEventsService()
        .update(activeWorkspace.id, pinnedEventId, payload)
        .then((res) => {
          if (res.data) {
            toast.success("Pinned event updated successfully.");
            closeModal(true);
            const newList = list.map((item) => {
              if (item.id === pinnedEventId) {
                return res.data;
              }
              return item;
            });
            let newHistoryList = historyList;
            delete newHistoryList[pinnedEventId];
            setHistoryList(newHistoryList);
            setList(newList);
            fetchHistory(activeWorkspace.id);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.detail && typeof err.response.data.detail !== "string") {
            toast.error(
              `${err.response.data.detail[0].loc[1]} error: ${err.response.data.detail[0].msg}`
            );
          }
        });
    } else {
      new WorkspacePinnedEventsService()
        .create(activeWorkspace.id, payload)
        .then((res) => {
          if (res.data) {
            toast.success("Pinned event created successfully.");
            closeModal(true);
            const newList = [res.data, ...list];
            setList(newList);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.detail && typeof err.response.data.detail !== "string") {
            toast.error(
              `${err.response.data.detail[0].loc[1]} error: ${err.response.data.detail[0].msg}`
            );
          }
        });
    }
  };
  const { colorScheme } = useMantineColorScheme();
  const theme = useGlobalMantineTheme();

  return (
    <>
      <Drawer
        position="right"
        title={
          <Title order={5} fw={600}>
            {" "}
            Pin or Group Events
          </Title>
        }
        // overflow={"inside"}
        size={"80%"}
        opened={open}
        onClose={() => closeModal(false)}
        // styles={{
        //   modal: {
        //     position: "relative"
        //   }
        // }}
      >
        <Text size={"sm"}>
          Pin single events or group similar events on different pages to use them in filters and
          other reports.
        </Text>
        <div className="my-4 mt-8 flex">
          <Input.Wrapper
            style={{
              display: "flex",
              alignItems: "center"
            }}
            size="sm"
            id={"workEmail"}
            label="Pinned event name"
            required>
            <TextInput
              data-autofocus
              ml={"sm"}
              onChange={(e) => setPinnedEventName(e.target.value)}
              defaultValue={pinnedEventName}
              placeholder="e.g Signed up"
              autoFocus
              data-cy="pinned-event-name-input"
            />
          </Input.Wrapper>
        </div>

        {!pinnedEventId && (
          <Switch
            mt={"md"}
            mb="md"
            labelPosition={"right"}
            fw={500}
            checked={createConversionGoal}
            onChange={(event: any) => setCreateConversionGoal(event.target.checked)}
            label="Create a conversion goal with this pinned event"
            size="sm"></Switch>
        )}

        <Divider my="lg" />

        <Grid>
          <Grid.Col span={9} mih="60vh">
            <PinnedEventsQueryBuilder />
            <Flex
              justify="end"
              mt="md"
              // className="bottom-0 absolute bg-gray-50 h-16 border-t border-solid border-gray-200 flex items-center  justify-end w-full -ml-6 gap-8"
              // sx={(theme) => ({
              //   background: theme.colorScheme === "dark" ? theme.colors.dark[7] : "",
              //   zIndex: 99
              // })}
            >
              <Button
                className="mr-4"
                disabled={pinnedEventName.length === 0 || rules.length === 0}
                onClick={handleSubmit}
                data-cy="pinned-event-create-update-btn">
                {pinnedEventId ? "Update" : "Create"}
              </Button>
            </Flex>
          </Grid.Col>

          <Grid.Col span={3}>
            <Paper
              p={"sm"}
              withBorder
              shadow="lg"
              bg={colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0]}>
              <Center py={"md"}>
                <FontAwesomeIcon
                  size={"3x"}
                  icon={regular("arrow-pointer")}
                  className="transform rotate-[60] text-gray-900 mt-8"></FontAwesomeIcon>
              </Center>
              <Title order={5} fw={600} ta={"center"} pb="sm">
                Events Summary
              </Title>
              {rules.length > 0 ? (
                <>
                  <Text ta="center" pb="sm">
                    Based on the last 30 days
                  </Text>
                  {isTotalEventsLoading ? (
                    <Center py="md">
                      <Loader size={"xs"} />
                    </Center>
                  ) : (
                    <>
                      <Title order={3} ta="center" pb="sm">
                        <>{numberToCommas(totalEvents)} </>
                      </Title>
                      <Text pb="sm" ta="center">
                        Total clicks
                      </Text>
                    </>
                  )}
                </>
              ) : (
                <Text pb="sm" ta="center">
                  Please add some conditions first.
                </Text>
              )}
            </Paper>
          </Grid.Col>
        </Grid>
      </Drawer>
    </>
  );
};
