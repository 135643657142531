import axios from './JWTService';

type UserProfileUpdateProps = {
  first_name: string;
  last_name: string;
  email: string;
  company: string;
};

type UserChangePasswordProps = {
  old_password: string;
  password: string;
  password_confirm: string;
};

export class UserService {
  updateProfileDetails = (userId: string, payload: UserProfileUpdateProps): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/users/${userId}`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  updateEmailAddress = (userId: string, email: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/users/${userId}/email_address`, { email: email })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  changePassword = (payload: UserChangePasswordProps): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/users/change_password`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  uploadProfileImage = (userId: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/users/${userId}/image`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  onboardingVideoWatched = (userId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/users/${userId}/onboarding_video_watched`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  findOrCreatePromoter = (userId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/users/${userId}/find_or_create_promoter`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };
  resendEmailVerification = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/auth/resend_email_verification`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };
  updateRequestSupportAccess = (
    userId: string,
    requested_support_access: boolean
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/users/${userId}/requested_support_access`, {
          requested_support_access: requested_support_access
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  AIChatReviewSubmit = (userId: string, service: string, message: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/users/${userId}/review_submit`, {
          service: service,
          message: message
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
