import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Paper,
  SegmentedControl,
  Select,
  SimpleGrid,
  Space,
  Switch,
  Table,
  TableThead,
  TableTr,
  Text,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useInterval, useLocalStorage } from "@mantine/hooks";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import orderBy from "lodash/orderBy";
import { useContext, useEffect, useState } from "react";
import { WorkspaceMemberType, WorkspaceType } from "types/types.d";
import { WorkspaceModal } from "@/ui/components/App/Modals/WorkspaceModal";
import { WorkspaceItem } from "@/ui/components/App/WorkspaceItem/WorkspaceItem";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import { OnboardingLayout } from "@/ui/layouts/OnboardingLayout";
import { DATE_FORMAT, DATE_RANGES, TIMER_THIRTY_SECONDS } from "../../../lib/utils/Constants";
import { useWebFiltersStore } from "../../../stores/useWebFiltersStore";
import { DateDropdownMenu } from "../../components/App/Dropdowns/DateDropdownMenu";
import { WorkspaceItemStatistics } from "../../components/App/WorkspaceItem/WorkspaceItemStatistics";
import "./all_workspaces.scss";
import { useCustomizeMenuStore } from "@/stores/useCustomizeMenuStore";
import classes from "./AllWorkspaces.module.css";
interface QueryProps {
  from_date: string | undefined;
  to_date: string | undefined;
  period?: string;
}

export const AllWorkspaces = (props: any) => {
  const colorScheme = useMantineColorScheme();
  const { workspaces, user, setActiveWorkspace, setWorkspaces } = useContext(AppLifecycleContext);
  const [ownedWorkspaces, setOwnedWorkspaces] = useState([]);
  const [partOfWorkspaces, setPartOfWorkspaces] = useState([]);
  const [isLoading, setIsLoading] = useState("idle");
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditWorkspace, setIsEditWorkspace] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const [sortOrder, setSortOrder] = useState<string | null>("name:asc");
  const fetchMenuItems = useCustomizeMenuStore((state: any) => state.fetchMenuItems);
  const [view, setView] = useLocalStorage<"cards" | "list" | string>({
    key: "workspaces-view",
    defaultValue: "cards"
  });

  const [showStats, setShowStats] = useLocalStorage({
    key: "show-stats",
    defaultValue: true,
    getInitialValueInEffect: false
  });
  const [dateLabel, setDateLabel] = useWebFiltersStore((state) => [
    state.dateLabel,
    state.setDateLabel
  ]);
  const [timer, setTimer] = useState<number>(0);
  const interval = useInterval(() => {
    setTimer(new Date().getTime());
  }, TIMER_THIRTY_SECONDS);

  const findMemberDetails = (memberId: string): any => {
    const member = allMembers.find((memberItem: WorkspaceMemberType) => {
      return memberItem.id === memberId;
    });
    console.log("FindMemberDetails: ", member);
    return member;
  };

  const getWorkspacesAndMembersList = async () => {
    const workspaceService = new WorkspaceService();
    await workspaceService
      .workspacesAccess()
      .then(async (res) => {
        console.log(`Workspace access response`, res);
        setActiveWorkspace(res.defaultWorkspace);
        setWorkspaces(res.workspacesList);

        await fetchMenuItems(res.defaultWorkspace.id);
      })
      .catch((err) => {});

    await workspaceService
      .allMembers()
      .then((res) => {
        if (res.data) {
          setAllMembers(res.data);
        }
      })
      .catch((err) => {});
  };
  const [query, setQuery] = useState<QueryProps>({
    from_date: format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
    to_date: format(endOfDay(new Date()), DATE_FORMAT)
  });
  const onChangeDate = (startDate: string, endDate: string, label: string, interval?: string) => {
    let payload: QueryProps = {
      from_date: startDate,
      to_date: endDate
    };
    if (interval) {
      payload = { ...payload, period: interval };
    } else if (label.toLowerCase() === "live") {
      payload = { from_date: undefined, to_date: undefined, period: "live" };
    }

    setQuery(payload);
    setDateLabel(label);
  };

  useEffect(() => {
    document.title = "All Workspaces | Usermaven";
    // Sending request again for members to get workspaces and members list.
    getWorkspacesAndMembersList();
  }, []);

  /**
   * Using this for the live mode and interval.
   */
  useEffect(() => {
    if (query.period === "live") {
      interval.start();
    } else {
      interval.stop();
    }
    return () => {
      interval.stop();
    };
  }, [query.period]);

  useEffect(() => {
    console.log(workspaces);
    const ownedWorkspaces = workspaces.filter(
      (workspaceItem: WorkspaceType) => workspaceItem?.organization.created_by_user_id === user.id
    );
    const partOfWorkspaces = workspaces.filter(
      (workspaceItem: WorkspaceType) => workspaceItem?.organization.created_by_user_id !== user.id
    );
    ownedWorkspaces.map((wsItem: WorkspaceType) => {
      wsItem.members.map((wsMemberItem: WorkspaceMemberType) => {
        if (!wsMemberItem?.user) {
          wsMemberItem.user = findMemberDetails(wsMemberItem.id)?.user;
        }
      });
    });

    partOfWorkspaces.map((wsItem: WorkspaceType) => {
      wsItem.members.map((wsMemberItem: WorkspaceMemberType) => {
        if (!wsMemberItem?.user) {
          wsMemberItem.user = findMemberDetails(wsMemberItem.id)?.user;
        }
      });
    });
    console.log(ownedWorkspaces, partOfWorkspaces);
    setOwnedWorkspaces(ownedWorkspaces);
    setPartOfWorkspaces(partOfWorkspaces);
    setIsLoading("loaded");
  }, [workspaces, allMembers]);
  if (isLoading === "idle") {
    return <LoadingComponent />;
  }

  /**
   * Add Team member dialog. Set the edit team member modal and opens the modal
   */
  const addWorkspaceModal = () => {
    setIsEditWorkspace(false);
    setModalOpen(true);
  };

  const getSortedWorkspacesList = (wsType: "owned" | "part_of"): Array<any> => {
    return orderBy(
      wsType === "owned" ? ownedWorkspaces : partOfWorkspaces,
      [sortOrder?.split(":")[0]],
      [sortOrder?.split(":")[1] === "asc" ? "asc" : "desc"]
    );
  };

  return (
    <>
      <WorkspaceModal open={modalOpen} isEdit={false} closeModal={() => setModalOpen(false)} />
      <OnboardingLayout>
        <Container size={"xl"} w={"100%"} pb={"xl"}>
          <Flex direction={"column"}>
            <Title py="xl" order={3} fw={700} my={"sm"} ta="center">
              All Workspaces
            </Title>
            <Space h={16} />
            {ownedWorkspaces.length === 0 && partOfWorkspaces.length === 0 ? (
              <div className="um-all-ws--no-workspaces">
                <p>You don’t have any workspace yet. Create one for your brand Now.</p>
                <Flex
                  direction={"column"}
                  component="div"
                  className={classes.card}
                  justify={"center"}
                  align={"center"}
                  h={200}
                  onClick={() => {
                    addWorkspaceModal();
                  }}>
                  <ActionIcon
                    mb="sm"
                    variant="outline"
                    color={colorScheme.colorScheme === "dark" ? "dark.2" : "gray.9"}>
                    <FontAwesomeIcon icon={solid("plus")} />
                  </ActionIcon>

                  <Title order={5}>Create new workspace</Title>
                  <Text>e.g: Nike, Pepsi, Disney</Text>
                </Flex>
              </div>
            ) : (
              <>
                <div>
                  <Flex align={"center"}>
                    <Flex flex={"1"}>
                      <Button
                        onClick={() => {
                          addWorkspaceModal();
                        }}>
                        Create Workspace
                      </Button>
                    </Flex>
                  </Flex>

                  {ownedWorkspaces.length > 0 && (
                    <>
                      <Flex align={"center"} direction={"row"} mt={"md"}>
                        <Title order={4} fw={600} flex="1">
                          Workspaces owned by me
                        </Title>
                        <Flex align={"center"}>
                          <Text mr={"md"}>Sort by</Text>
                          <Select
                            size="xs"
                            mr={"sm"}
                            placeholder="Sort by"
                            w={100}
                            value={sortOrder}
                            variant="filled"
                            onChange={setSortOrder}
                            data={[
                              {
                                label: "A-Z",
                                value: "name:asc"
                              },
                              {
                                label: "Z-A",
                                value: "name:desc"
                              }
                            ]}
                          />

                          {showStats && (
                            <Box>
                              <DateDropdownMenu
                                ranges={[{ label: "Live" }].concat(DATE_RANGES)}
                                onChange={(startDate: string, endDate: string, label: string) => {
                                  onChangeDate(startDate, endDate, label);
                                }}
                              />
                            </Box>
                          )}

                          <SegmentedControl
                            ml={"sm"}
                            data={[
                              {
                                value: "cards",
                                label: (
                                  <Center>
                                    <FontAwesomeIcon icon={regular("grid-2")} />
                                  </Center>
                                )
                              },
                              {
                                value: "list",
                                label: (
                                  <Center>
                                    <FontAwesomeIcon icon={regular("list")} />
                                  </Center>
                                )
                              }
                            ]}
                            value={view}
                            onChange={(value: string) => {
                              setView(value);
                            }}
                          />
                          <Switch
                            ml={"sm"}
                            className="flex items-center"
                            label="Show Statistics"
                            checked={view === "list" ? true : showStats}
                            size="xs"
                            disabled={view === "list"}
                            onChange={(event) => setShowStats(event.currentTarget.checked)}
                          />
                        </Flex>
                      </Flex>

                      <Divider mt="md" mb="md" />

                      {view === "list" ? (
                        <Paper mt={"md"} className="shadow-sm">
                          <Box className={classes.listViewContainerBox}>
                            <Table
                              withTableBorder
                              striped
                              highlightOnHover
                              horizontalSpacing="md"
                              className={classes.listViewContainerTable}
                              styles={{
                                thead: {
                                  th: {
                                    paddingTop: 10,
                                    fontWeight: 600
                                  }
                                },
                                tbody: {
                                  tr: {
                                    td: {
                                      paddingTop: 10
                                    }
                                  }
                                }
                              }}
                              style={{
                                "th:not(.max), td:not(.max)": {
                                  width: 0,
                                  whiteSpace: "nowrap"
                                },
                                "thead tr th": {
                                  height: "45px",
                                  verticalAlign: "middle",
                                  fontWeight: 600
                                },
                                "tbody tr td": {
                                  fontWeight: 400,
                                  verticalAlign: "middle",

                                  b: {
                                    fontWeight: 500
                                  }
                                }
                              }}>
                              <Table.Thead h={45}>
                                <Table.Tr>
                                  <Table.Th className={"max"}>Workspace</Table.Th>
                                  {/* <th>Activity</th> */}
                                  <Table.Th>Visitors</Table.Th>
                                  <Table.Th>Total Sessions</Table.Th>
                                  <Table.Th>Visits Durations</Table.Th>
                                  <Table.Th>Bounce Rate</Table.Th>
                                  <Table.Th>Events</Table.Th>
                                  <Table.Th></Table.Th>
                                </Table.Tr>
                              </Table.Thead>
                              <Table.Tbody>
                                {getSortedWorkspacesList("owned").map((wsItem: WorkspaceType) => (
                                  <WorkspaceItemStatistics
                                    wsItem={wsItem}
                                    timer={timer}
                                    query={query}
                                    key={wsItem.id}
                                    type={"row"}
                                  />
                                ))}
                              </Table.Tbody>
                            </Table>
                          </Box>
                        </Paper>
                      ) : (
                        <SimpleGrid
                          cols={{
                            xs: 1,
                            base: showStats ? 3 : 4,
                            sm: showStats ? 2 : 3,
                            lg: showStats ? 3 : 4
                          }}
                          spacing={"sm"}
                          mt={"md"}
                          w={"100%"}>
                          {!showStats && (
                            <Flex
                              direction={"column"}
                              component="div"
                              className={classes.card}
                              justify={"center"}
                              align={"center"}
                              h={200}
                              onClick={() => {
                                addWorkspaceModal();
                              }}>
                              <ActionIcon
                                mb="sm"
                                variant="outline"
                                color={colorScheme.colorScheme === "dark" ? "dark.2" : "gray.9"}>
                                <FontAwesomeIcon icon={solid("plus")} />
                              </ActionIcon>

                              <Title order={5}>Create new workspace</Title>
                              <Text>e.g: Nike, Pepsi, Disney</Text>
                            </Flex>
                          )}
                          {getSortedWorkspacesList("owned").map((wsItem: WorkspaceType) => (
                            <div className={``} key={wsItem.id}>
                              {showStats ? (
                                <WorkspaceItemStatistics
                                  timer={timer}
                                  wsItem={wsItem}
                                  query={query}
                                />
                              ) : (
                                <WorkspaceItem wsItem={wsItem} />
                              )}
                            </div>
                          ))}
                        </SimpleGrid>
                      )}
                    </>
                  )}

                  {partOfWorkspaces.length > 0 && (
                    <div className="um-all-ws--container--section">
                      <h2>Workspaces I'm invited to</h2>
                      <Box className="mt-4" component="hr" />

                      {view === "list" ? (
                        <Paper mt={"md"} className="shadow-sm">
                          <Box>
                            <Table striped highlightOnHover horizontalSpacing="md">
                              <Table.Thead>
                                <Table.Tr>
                                  <Table.Th className={"max"}>Workspace</Table.Th>
                                  <Table.Th>Visitors</Table.Th>
                                  <Table.Th>Total Sessions</Table.Th>
                                  <Table.Th>Visits Durations</Table.Th>
                                  <Table.Th>Bounce Rate</Table.Th>
                                  <Table.Th>Events</Table.Th>
                                  <Table.Th></Table.Th>
                                </Table.Tr>
                              </Table.Thead>
                              <tbody>
                                {getSortedWorkspacesList("part_of").map((wsItem: WorkspaceType) => (
                                  <WorkspaceItemStatistics
                                    wsItem={wsItem}
                                    timer={timer}
                                    query={query}
                                    key={wsItem.id}
                                    type={"row"}
                                  />
                                ))}
                              </tbody>
                            </Table>
                          </Box>
                        </Paper>
                      ) : (
                        <SimpleGrid
                          cols={{
                            base: showStats ? 3 : 4,
                            lg: showStats ? 3 : 4,
                            sm: showStats ? 2 : 3,
                            xs: 1
                          }}
                          spacing={"sm"}
                          className="mt-8"
                          w={"100%"}>
                          {getSortedWorkspacesList("part_of").map((wsItem: WorkspaceType) => (
                            <div className="" key={wsItem.id}>
                              {showStats ? (
                                <WorkspaceItemStatistics
                                  timer={timer}
                                  wsItem={wsItem}
                                  query={query}
                                />
                              ) : (
                                <WorkspaceItem wsItem={wsItem} />
                              )}
                            </div>
                          ))}
                        </SimpleGrid>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </Flex>
        </Container>
      </OnboardingLayout>
    </>
  );
};
