/**
 * Use the segment filter store to manage the state of the segment filter
 */

import create from 'zustand';

interface ISegmentFilterStore {
  /**
   * Segment selected id
   *
   */
  id: string;

  /**
   * Set selected segment id
   *
   */
  setId: (id: string) => void;

  /**
   * Segment filter visible
   */
  filterVisible: boolean;
  /**
   * Set segment filter visible
   * */
  setFilterVisible: (filterVisible: boolean) => void;
}

export const useSegmentFilterStore = create<ISegmentFilterStore>((set) => ({
  id: '',
  setId: (id: string) => set({ id }),
  /**
   * Reset segment filter store
   * */

  /**
   * Segment filter visible
   * */
  filterVisible: false,
  /**
   * Set segment filter visible
   *
   * */
  setFilterVisible: (filterVisible: boolean) => set({ filterVisible })
}));
