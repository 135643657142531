import { MantineTheme, lighten } from "@mantine/core";
import {
  IFunnelMeta,
  IFunnelStep,
  TAttribute
} from "../../ui/pages/Analyze/components/FunnelDetailedView/FunnelDetails";
import { FUNNEL_BREAKDOWN_COLORS } from "./Constants";
import { convertSecondsToReadableFormat } from "./StringUtility";

/**
 * Helper function to create series data for funnel breakdown chart in grouped bars with stack
 */
export const createSeriesDataForBreakdown = (
  name: string,
  visitorData: number[],
  dropOffData: number[],
  stackName: string,
  colors: string[],
  theme: MantineTheme,
  colorScheme: 'dark' | 'light',
  isLastInStack: boolean = false,
  dropOffValues: number[] = [],
  avgTimeValues: number[] = [],
  totalLevel: number = 0,
  type: string = "Visitors"
) => {
  let dropOffLabelPosition = ["0%", "110%"];

  if (totalLevel <= 2) {
    dropOffLabelPosition = ["0%", "135%"];
  }
  if (totalLevel === 3) {
    dropOffLabelPosition = ["0%", "128%"];
  }
  if (totalLevel === 4) {
    dropOffLabelPosition = ["0%", "130%"];
  }

  return [
    {
      name: name || "",
      type: "bar",
      stack: stackName,
      barGap: 0.3,
      emphasis: {
        focus: "series"
      },
      data: [...visitorData],
      itemStyle: {
        color: colors[0],
        borderRadius: [0, 6, 6, 0]
      },
      xAxisIndex: 1,
      label: {
        // Show the label only of last bar
        show: isLastInStack,
        // position: "insideBottomRight",
        position: dropOffLabelPosition,
        offset: [10, 5],
        textShadowColor: "transparent",
        fontFamily: "Inter, sans-serif",
        fontWeight: "600",
        color: "#c74554",

        rich: {
          a: {
            fontFamily: "Inter, sans-serif",
            fontWeight: "600",
            color: colorScheme === "dark" ? theme.colors.dark[0] : "#030303"
          },
          b: {
            color: "#a173f3",
            fontFamily: "Inter, sans-serif",
            fontWeight: "600"
          }
        },

        formatter: function (params: any) {
          const level = params.dataIndex + 1;
          const dropOffRate = dropOffValues[level];
          const avgTime = avgTimeValues[level];
          let text = "";
          if (dropOffRate) {
            text = `↓ {a|${dropOffRate + "%"}} Drop Off`;
          }
          if (avgTime) {
            text += `    ⏱ {b|${convertSecondsToReadableFormat(avgTime)}}`;
          }
          return text;
        }
      }
    },
    {
      name: "",
      type: "bar",
      barGap: 0.3,
      stack: stackName,
      emphasis: {
        disabled: true
      },
      data: [...dropOffData],
      itemStyle: {
        color: colors[1]
      },
      label: {
        show: true,
        position: "insideLeft",
        textShadowColor: "transparent",
        fontWeight: "600",
        color: "#c74554",
        fontSize: 10,
        fontFamily: "Inter, sans-serif",
        // offset: [10, 5],
        rich: {
          a: {
            fontWeight: "600",
            padding: [3, 6],
            borderRadius: 8,
            borderWidth: 1,
            fontSize: 10,
            fontFamily: "Inter, sans-serif",
            borderColor: colorScheme === "dark" ? theme.colors.dark[7] : lighten(colors[0], 0.8),
            color: colorScheme === "dark" ? theme.colors.dark[1] : colors[0],
            backgroundColor: colorScheme === "dark" ? theme.colors.dark[5] : lighten(colors[0], 0.9)
          },

          b: {
            color: colorScheme === "dark" ? theme.colors.dark[0] : "#030303",
            fontWeight: "600",
            textShadowColor: "transparent",
            fontSize: 10,
            align: "center",
            fontFamily: "Inter, sans-serif"
          },
          c: {
            color: "#a173f3",
            fontSize: 10,
            fontFamily: "Inter, sans-serif",
            fontWeight: "600"
          }
        },

        formatter: function (params: any) {
          let visitors: number | string = visitorData[params.dataIndex];
          visitors = visitors.toLocaleString("en-US", {
            notation: "compact",
            compactDisplay: "short"
          });
          const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);

          const visitorsLabel = `{a|${visitors} ${capitalizedType}}`;
          return visitorsLabel;
        }
      },
      xAxisIndex: 1
    }
  ];
};

/**
 * Method to create the series data for the breakdown chart using steps and meta data using
 * createSeriesDataForBreakdown method
 * meta useful information:
 * - meta.levels_count
 * steps data structure:
 * - steps[0].name
 * - steps[0].breakdown_prop
 * - steps[0].level
 * - steps[0].visitors
 * - steps[0].avg_time_in_s
 * - steps[0].prev_visitors_count
 * - steps[0].conv_rate_percent
 * - steps[0].drop_off_count_prev_step
 * ...
 */
export const createSeriesData = (
  steps: Array<IFunnelStep[]>,
  meta: IFunnelMeta,
  selectedFunnel: IFunnelStep[],
  type: string,
  stepsNames: string[],
  theme: MantineTheme,
  colorScheme: 'dark' | 'light'
) => {

  const seriesData = [];
  let totalLevels = steps[0].length;

  // get the drop off and avgTimeValues values for each level from the selected funnel
  let dropOffValues = selectedFunnel.map((item) => item.drop_off_rate_prev_step);
  let avgTimeValues = selectedFunnel.map((item) => item.avg_time_in_s);

  console.log("avgTimeValues", avgTimeValues);

  for (let i = 0; i < steps.length; i++) {
    const visitorsData = [];
    const dropOffData = [];

    // To show drop off label only for last bar in each stack
    const isLastInStack = i === steps.length - 1;

    for (let j = 0; j < steps[i].length; j++) {
      visitorsData.push(steps[i][j].visitors);
      dropOffData.push(
        steps[i][j].drop_off_count_prev_step > 0 ? steps[i][j].drop_off_count_prev_step : 0
      );
    }

    seriesData.push(
      ...createSeriesDataForBreakdown(
        stepsNames[i],
        visitorsData,
        dropOffData,
        steps[i][0]?.breakdown_prop || steps[i][0].name,
        [
          steps[i][0]?.breakdown_prop === "Others" || !FUNNEL_BREAKDOWN_COLORS[i]
            ? FUNNEL_BREAKDOWN_COLORS[5]
            : FUNNEL_BREAKDOWN_COLORS[i],
          colorScheme === "dark" ? theme.colors.dark[5] : "#e9ecef"
        ],
        theme,
        colorScheme,
        isLastInStack,
        dropOffValues,
        avgTimeValues,
        totalLevels,
        type
      )
    );
  }

  console.log("seriesData", seriesData);
  return seriesData;
};

export const roundFunnelRate = (rate: number) => {
  return Math.round(((rate || 0) + Number.EPSILON) * 100) / 100;
};

export const convertFunnelAttributeToLabel = (attribute: TAttribute) => {
  switch (attribute) {
    case "parsed_device":
      return "Device";
    case "parsed_ua_ua_family":
      return "Browser";
    case "parsed_ua_os_family":
      return "OS";
    case "location_city":
      return "City";
    case "location_country":
      return "Country";
    case "period":
      return "Period";
    case "source":
      return "Source";
    case "referer":
      return "Referrer";
    case "channel":
      return "Channel";
    default:
      return "";
  }
};
