import { IFunnelPerformance } from "@/ui/pages/InsightsDashboard/Pages/FeatureAdoption/FeatureDetail";
import PerformanceFunnelBar from "./PerformanceFunnelBar";
import { Box, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import styles from "./PerformanceFunnelBarGroup.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface IBarGroupProps {
  /**
   * The bars to be shown in the bar group
   */
  bars: IFunnelPerformance[];
  /**
   * Gap between the bars
   */
  gap?: number;
  /**
   * Max value of the bars
   */
  max: number;
  /**
   * Type of the bar
   */
  type?: string;
}

const PerformanceFunnelBarGroup = ({ bars, gap = 150, max, type = "Visitors" }: IBarGroupProps) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Box style={{ paddingLeft: "20px", paddingRight: "20px", height: "100%" }}>
      <Box
        className={styles.barGroup}
        style={{
          borderLeft: `1px solid ${colorScheme === "dark" ? theme.colors.dark[6] : "#e0e0e0"}`,
          borderBottom: `1px solid ${colorScheme === "dark" ? theme.colors.dark[6] : "#e0e0e0"}`
        }}>
        <Box className={styles.barContainer} style={{ gap: `${gap}px` }}>
          {bars.map((bar, index) => (
            <PerformanceFunnelBar
              key={index}
              data={bar}
              nextData={bars[index + 1] || null}
              max={max}
              isLast={index === bars.length - 1}
              width={bars.length <= 4 ? 120 : 100}
              type={type}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PerformanceFunnelBarGroup;
