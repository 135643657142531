import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Group,
  Input,
  Switch,
  Tooltip,
  Text,
  Title,
  Flex
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceExcludeDomainsService } from "@/lib/services/WorkspaceExcludeDomainsService";
import { WorkspaceMiscService } from "@/lib/services/WorkspaceMiscService";
import { DEFAULT_SELECTED_DOMAIN, LOADER_COLOR } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import { LoadingStateProps } from "types/types.d";
import { toast } from "react-toastify";
import { useHover } from "@mantine/hooks";

interface DomainProps {
  label: string;
  value: string;
  is_enabled: boolean;
}

export const GeneralHideDomains = () => {
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [domains, setDomains] = useState<Array<DomainProps>>([DEFAULT_SELECTED_DOMAIN]);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [query, setQuery] = useState<string>("");

  const handleDomainChange = async (value: boolean, domainValue: string) => {
    let res = false;
    if (value) {
      // delete excluded domain
      res = await new WorkspaceExcludeDomainsService()
        .delete(activeWorkspace.id, domains.find((d) => d.value === domainValue)?.value || "")
        .then((res) => res.data)
        .catch((err) => false);
    } else {
      // add excluded domain
      res = await new WorkspaceExcludeDomainsService()
        .post(activeWorkspace.id, domains.find((d) => d.value === domainValue)?.value || "")
        .then((res) => res.data)
        .catch((err) => false);
    }

    if (res) {
      // update the state is enabled by findng the domain index and updating the state
      const updatedDomains = domains.map((domain: DomainProps) => {
        if (domain.value === domainValue) {
          return { ...domain, is_enabled: value };
        }
        return domain;
      });
      setDomains(updatedDomains);
    }
  };

  const handleSetAsDefaultDomain = (domain: string) => {
    new WorkspaceMiscService()
      .reportingDomain(activeWorkspace.id, domain)
      .then((res) => {
        if (res.data) {
          const ws = { ...activeWorkspace, reporting_domain: domain };
          setActiveWorkspace(ws);
          domain = domain === "all_domains" ? "All Domains" : domain;
          toast.success(`Default domain set to ${domain}`);
        }
      })
      .catch((err) => {});
  };
  const fetchDomains = async () => {
    setLoading("loading");
    await new WorkspaceMiscService()
      .domains(activeWorkspace.id)
      .then((res) => setDomains([DEFAULT_SELECTED_DOMAIN, ...res.data]))
      .catch((err) => err);
    setLoading("loaded");
    ReactTooltip.rebuild();
  };

  const filteredDomains = () => {
    if (query.length > 0) {
      return domains.filter((domain) => domain.label.toLowerCase().includes(query.toLowerCase()));
    }
    return domains;
  };

  const AdditionalDomainItem = ({ domain, index }: { domain: DomainProps; index: number }) => {
    const { hovered, ref } = useHover();
    return (
      <>
        <Flex ref={ref} fz="sm" align={"center"} py="xs" key={domain.value} h={40}>
          <Flex align={"center"} flex={1}>
            <Tooltip
              withArrow
              label="Cannot turn off visibility for default domain"
              disabled={domain.value != activeWorkspace.reporting_domain}>
              <div>
                <Switch
                  mr={12}
                  // disabled={domain.value === activeWorkspace.reporting_domain}
                  checked={domain.is_enabled}
                  onChange={(event) => {
                    if (domain.value === activeWorkspace.reporting_domain) {
                      toast.error("Cannot turn off visibility for default domain");
                      return;
                    }
                    handleDomainChange(event.currentTarget.checked, domain.value);
                  }}
                  size="xs"
                />
              </div>
            </Tooltip>
            <Text>{domain.value === "all_domains" ? "All Domains" : domain.value}</Text>
          </Flex>

          <>
            <Flex fz="sm" justify={"start"}>
              {domain.value === activeWorkspace.reporting_domain ? (
                <Text c="brand" fw={600}>
                  Default
                </Text>
              ) : (
                <>
                  {hovered && (
                    <Button
                      variant="outline"
                      size="compact-xs"
                      onClick={() => handleSetAsDefaultDomain(domain.value)}>
                      Set as default
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </>
        </Flex>
      </>
    );
  };

  useEffect(() => {
    fetchDomains();
  }, []);

  return (
    <>
      <Box>
        <Title order={4} fw={600} mb="md">
          Show/Hide domains on dashboard
        </Title>
        <Text mb="md">
          By default, all domains are visible under Web Analytics. You can turn off domains or
          modify the default domain selection according to your preferences.
        </Text>

        <Box fz="sm">
          {!activeWorkspace.reporting_domain && (
            <Alert
              icon={
                <FontAwesomeIcon className="text-2xl" icon={regular("lightbulb-exclamation")} />
              }>
              You can set a default domain for Web Analytics so that the analytics for that domain
              are displayed automatically when the page loads.
            </Alert>
          )}
        </Box>

        {domains.length > 4 && (
          <Box w={300} mb={"sm"}>
            <Input
              placeholder="Search by domain name"
              size="xs"
              value={query}
              onChange={(event: any) => setQuery(event.currentTarget.value)}
              leftSection={
                <Box fz="xs">
                  <FontAwesomeIcon icon={regular("search")} />
                </Box>
              }
            />
          </Box>
        )}

        <Flex direction={"column"} w={"40%"}>
          {loading === "loaded" ? (
            <>
              {filteredDomains().map((domain: DomainProps, index: number) => (
                <AdditionalDomainItem domain={domain} index={index} />
              ))}

              {filteredDomains().length === 0 && (
                <div className="text-left text-gray-900">No domains found</div>
              )}
            </>
          ) : (
            <div className="flex justify-center items-center py-8">
              <Oval height={14} width={14} color={LOADER_COLOR}></Oval>
            </div>
          )}
        </Flex>
      </Box>
    </>
  );
};
