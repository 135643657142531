// import { Switch } from "@headlessui/react"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import { useContext, useEffect, useState } from "react";
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
import { Box, Divider, Grid, Switch, Title } from "@mantine/core";
import { MiscellaneousWorkspaceReportingPeriod } from "./MiscellaneousWorkspaceReportingPeriod";
import { WorkspaceMiscService } from "@/lib/services/WorkspaceMiscService";
import { MiscellaneousExcludeTraffic } from "./MiscalleneousFilterInternalTraffic";
import { MiscellaneousWorkspaceReportingCurrency } from "./MiscellaneousWorkspaceReportingCurrency";
import { MiscellaneousWorkspaceReportingInsightsPreference } from "./MiscellaneousWorkspaceReportingInsightsPreference";
import { MiscellaneousExcludeServerSideEvents } from "./MiscellaneousExcludeServerSideEvents";
const Miscellaneous = (props: any) => {
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [isBotEnabled, setIsBotEnabled] = useState(
    activeWorkspace.is_exclude_bot_traffic ? true : false
  );
  useEffect(() => {
    document.title = "Miscellaneous | Usermaven";
  }, []);

  const handleBotTrafficToggle = (value: boolean) => {
    new WorkspaceMiscService()
      .toggleBotStatus(activeWorkspace.id, value)
      .then((res) => {
        if (res.data) {
          setActiveWorkspace({ ...activeWorkspace, is_exclude_bot_traffic: value });
          setIsBotEnabled(!isBotEnabled);
        }
      })
      .catch((err) => err);
  };

  return (
    <>
      <Grid>
        <Grid.Col span={6}>
          {" "}
          <MiscellaneousWorkspaceReportingPeriod />
        </Grid.Col>

        <Grid.Col span={6}>
          <MiscellaneousWorkspaceReportingCurrency />
        </Grid.Col>
      </Grid>

      <Divider my="xl" />
      <MiscellaneousWorkspaceReportingInsightsPreference />
      <Divider my="xl" />
      <Title order={4} fw={600} mb="md">
        Exclude bot traffic from all stats
      </Title>
      <Switch
        size={"sm"}
        label={
          <div>
            Keep this option enabled if you want to view accurate stats from real visitors only
          </div>
        }
        checked={isBotEnabled}
        onChange={(event: any) => handleBotTrafficToggle(event.currentTarget.checked)}></Switch>
      <Divider my="xl" />
      <MiscellaneousExcludeServerSideEvents />
      <Divider my="xl" />
      <MiscellaneousExcludeTraffic />
      <Divider my="xl" />
    </>
  );
};
export default Miscellaneous;
