import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Alert } from "@mantine/core"
import parse from "html-react-parser"
import React from "react"
interface IRetentionMessage {
  message: string
}
const RetentionMessage = ({ message }: IRetentionMessage) => {
  return (
    <>
      {message && (
        <Alert
          title="Insights"
          icon={<FontAwesomeIcon className="text-2xl" icon={regular("lightbulb-exclamation")} />}
        >
          {/* <h4 className="font-extrabold">Insights</h4> */}
          <ul className="leading-8 list-disc ">
            {parse(message)}
            {/* <li>
              <span className="font-bold">38%</span> accounts returned to your
              app at any time during the following 12 weeks after the account
              was created.
            </li> */}
          </ul>
        </Alert>
      )}
    </>
  )
}
const RetentionMessageMemoized = React.memo(RetentionMessage)
export { RetentionMessageMemoized as RetentionMessage }
