import { useEffect, useState } from "react";
import { CheckIcon, Combobox, Group, Pill, PillsInput, useCombobox } from "@mantine/core";

const MESSAGES = {
  HOSTNAME: "Add the hostnames you want to exclude i.e staging.yourdomain.com",
  SOURCE_IP: "Add IP addresses you want to exclude.",
  EMAIL: "Add your team members or test accounts you want to exclude."
};

export const MultiSelectRulesCreatable = ({
  rulesOptions,
  setRulesOptions,
  item,
  type,
  isEdit,
  setRules
}: {
  rulesOptions: Array<{ label: string; value: string }>;
  setRulesOptions: (options: Array<{ label: string; value: string }>) => void;
  item: { rules: string[] };
  type: string | null;
  isEdit: boolean;
  setRules: (rules: string[]) => void;
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active")
  });

  const [search, setSearch] = useState("");
  const [data, setData] = useState(rulesOptions);
  const [value, setValue] = useState(item.rules || []);

  const exactOptionMatch = data.some((item) => item.label === search);

  const handleValueSelect = (val: string) => {
    setSearch("");
    if (val === "$create") {
      const newItem = { value: search.trim(), label: search.trim() };
      setData((current) => [...current, newItem]);
      setValue((current) => [...current, newItem.value]);
      // @ts-ignore
      setRulesOptions((current) => [...current, newItem]);
    } else {
      setValue((current) =>
        current.includes(val) ? current.filter((v) => v !== val) : [...current, val]
      );
    }
  };

  const handleValueRemove = (val: string) => {
    setValue((current) => current.filter((v) => v !== val));
  };

  const values = value.map((item) => (
    <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {item}
    </Pill>
  ));

  const options = data
    .filter((item) => item.label.toLowerCase().includes(search.trim().toLowerCase()))
    .map((item) => (
      <Combobox.Option value={item.value} key={item.value} active={value.includes(item.value)}>
        <Group gap="sm">
          {value.includes(item.value) ? <CheckIcon size={12} /> : null}
          <span>{item.label}</span>
        </Group>
      </Combobox.Option>
    ));

  useEffect(() => {
    setRules(value);
  }, [value]);
  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
      <Combobox.DropdownTarget>
        <PillsInput onClick={() => combobox.openDropdown()} disabled={!isEdit} w="100%">
          <Pill.Group>
            {values}
            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder={
                  type === "hostname"
                    ? MESSAGES.HOSTNAME
                    : type === "ip_address"
                    ? MESSAGES.SOURCE_IP
                    : MESSAGES.EMAIL
                }
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace" && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>
      <Combobox.Dropdown>
        <Combobox.Options>
          {options}
          {!exactOptionMatch && search.trim().length > 0 && (
            <Combobox.Option value="$create">+ Create {search}</Combobox.Option>
          )}
          {exactOptionMatch && search.trim().length > 0 && options.length === 0 && (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
