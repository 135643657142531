/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Select,
  TextInput,
  Text,
  Tooltip,
  useMantineTheme,
  Drawer,
  Flex
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { useContext, useEffect, useRef, useState } from "react";
import { LoadingStateProps } from "types/types.d";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NoResultsDetailedView } from "./NoResultsDetailedView";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { DATE_FORMAT, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { ExportButton } from "@/ui/components/Common/ExportButton/ExportButton";
import { format } from "date-fns";
import { downloadCSVFromResponse } from "@/lib/utils/CSVExporterUtility";
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import { DynoTable, ProgressCell } from "@/ui/components/Common/DynoTable/DynoTable";
import { isGoalSelected } from "../Filters/FiltersUtility";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const waService = new WebAnalyticsService();

export const UTMTrafficDetailedView = ({
  query,
  opened,
  activeTab,
  setActiveTab,
  setOpened,
  handleOnClick,
  UTMTrafficNav,
  isPublic
}: any) => {
  const theme = useGlobalMantineTheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const page = useRef(0);
  const [sort, setSort] = useState(isGoalSelected() ? "conversions:desc" : "visitors:desc");
  const [isSortApplied, setIsSortApplied] = useState(true);

  // Get user role in the workspace.
  const { hasRolesNotWith } = useWorkspaceUtilityHook();

  // exporting state for export list
  const [exporting, setIsExporting] = useState("idle");

  // handle search using debounce
  const [searchSources, setSearchSources] = useDebouncedState("", 200);

  // detailed listing page
  const [hasMore, setHasMore] = useState(true);
  const [loadingDetailedList, setLoadingDetailedList] = useState<LoadingStateProps>("idle");
  const [detailedList, setDetailedList] = useState<
    Array<{
      source: string;
      visitors: number;
      visitors_comparison?: number;
      visitors_percentage_change?: {
        label: string;
        percentage_change: number;
        comparison_value: number;
      };
      bounce_rate: number;
      bounce_rate_comparison?: number;
      bounce_rate_percentage_change?: number;
      visitors_percentage: number;
      visitors_percentage_comparison: number;
      visitors_percentage_percentage_change?: {
        label: string;
        percentage_change: number;
        comparison_value: number;
      };
    }>
  >([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);

  // fetching detailed list
  const fetchDetailedUTMs = async () => {
    setLoadingDetailedList("loading");
    // adding page number to show more results
    page.current = page.current + 1;
    await waService
      .UTMTraffic(
        activeWorkspace.id,
        activeTab,
        "list",
        page.current,
        50,
        sort,
        query,
        searchSources
      )
      .then((res) => {
        if (res.data.data) {
          // If the detailed list has already some results, then append the new results to the list.
          if (page.current === 1) {
            setDetailedList(res.data.data);
          } else {
            setDetailedList([...detailedList, ...res.data.data]);
          }

          if (res.data.columns) {
            // Modify columns before setting the state
            const modifiedColumns = res.data.columns.map((column: { accessor: string }) => {
              if (column.accessor === "source_with_value") {
                return {
                  ...column,
                  render: (data: any) => (
                    <ProgressCellWithClick data={data} onClick={handleOnClick} />
                  )
                };
              }
              if (
                column.accessor === "visitors" ||
                column.accessor === "bounce_rate" ||
                column.accessor === "conversions"
              ) {
                return {
                  ...column,
                  header: (
                    <Box
                      sx={{
                        display: "flex"
                      }}
                      onClick={() => {
                        changeSortOrder(column.accessor); // visitors or bounce_rate
                      }}>
                      <Text>{toTitleCase(column.accessor)}</Text>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "4px"
                        }}>
                        <FontAwesomeIcon
                          icon={solid("sort")}
                          style={{
                            color: sort.startsWith(column.accessor)
                              ? theme.colors.brand[4]
                              : theme.colorScheme === "light"
                              ? theme.colors.gray[5]
                              : theme.colors.gray[7]
                          }}
                          className={`ml-2 transform   transition-all duration-200 ease-in-out`}
                        />
                      </Box>
                    </Box>
                  ),
                  render: (data: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start"
                      }}>
                      <Text fw={400}>{numberToCommas(data)}</Text>
                    </Box>
                  )
                };
              }
              if (column.accessor === "conversion_rate") {
                return {
                  ...column,
                  header: (
                    <Box
                      sx={{
                        display: "flex"
                      }}
                      onClick={() => {
                        changeSortOrder(column.accessor); // visitors or bounce_rate
                      }}>
                      <Text>{"CR"}</Text>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "4px"
                        }}>
                        <FontAwesomeIcon
                          icon={solid("sort")}
                          style={{
                            color: sort.startsWith(column.accessor)
                              ? theme.colors.brand[4]
                              : theme.colorScheme === "light"
                              ? theme.colors.gray[5]
                              : theme.colors.gray[7]
                          }}
                          className={`ml-2 transform   transition-all duration-200 ease-in-out`}
                        />
                      </Box>
                    </Box>
                  ),
                  render: (data: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start"
                      }}>
                      <Text fw={400}>{data}%</Text>
                    </Box>
                  )
                };
              }

              if (column.accessor.endsWith("percentage_change")) {
                return {
                  ...column,
                  render: ({ label, percentage_change, comparison_value }: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start"
                      }}>
                      <Tooltip.Floating
                        label={`Comparison period ${label}: ${comparison_value}${
                          label === "CR" ? "%" : ""
                        }`}
                        position="top"
                        style={{ fontSize: "12px" }}>
                        <Text
                          fw={400}
                          color={
                            percentage_change !== undefined && percentage_change !== null
                              ? percentage_change > 0
                                ? "green.6"
                                : percentage_change < 0
                                ? "red.6"
                                : "gray.6"
                              : "gray.6"
                          }>
                          {percentage_change !== undefined &&
                          percentage_change !== null &&
                          percentage_change !== 0
                            ? `${percentage_change > 0 ? "+" : ""}${percentage_change.toFixed(1)}%`
                            : ""}
                        </Text>
                      </Tooltip.Floating>
                    </Box>
                  )
                };
              }
              return column;
            });
            setTableColumns(modifiedColumns);
          }

          // if the length of the results is less than 50, then there are no more results to show.
          if (res.data.data.length < 50) {
            setHasMore(false);
          }

          // Reset the sort applied to false after the data is fetched.
          setIsSortApplied(false);
        }
      })
      .catch((err) => {
        setHasMore(false);
      });
    setLoadingDetailedList("loaded");
  };

  const ProgressCellWithClick = ({ data, onClick }: any) => {
    return (
      <Box
        onClick={() => {
          onClick(data.source);
          setOpened(false);
        }}>
        <ProgressCell
          label={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <Text>{data.source}</Text>
            </Box>
          }
        />
      </Box>
    );
  };

  //Service call to utm traffic details list as CSV.
  const exportUTMDetail = async () => {
    setIsExporting("loading");
    try {
      const response = await waService.UTMTraffic(
        activeWorkspace.id,
        activeTab,
        "csv",
        page.current,
        50000,
        sort,
        query,
        searchSources
      );
      if (response.data) {
        //Setting filename of the exported file in accordance with the activeTab, date and time of export.
        let filename = `${activeTab}_pages_list_${format(new Date(), DATE_FORMAT)}.csv`;
        //Utility function to download the csv from response.
        downloadCSVFromResponse(response, filename);
        setIsExporting("loaded");
        return true;
      }
    } catch (err) {
      setIsExporting("loaded");
      throw new Error("400");
    }
    return false;
  };

  // function to handle change in sort on clicking table header
  const changeSortOrder = (field: string) => {
    setIsSortApplied(true);
    let sortingKey;
    // if sort is already set to desc, then set it to asc and vice versa
    if (sort.endsWith(":desc")) {
      sortingKey = `${field}:asc`;
    } else if (sort.endsWith(":asc")) {
      sortingKey = `${field}:desc`;
    } else {
      sortingKey = `${field}:desc`;
    }
    // setting detailed list to empty and page to 1 so that new sorted data can be fetched
    setSort(sortingKey);
    setDetailedList([]);
    page.current = 0;
    setHasMore(true);
    setLoadingDetailedList("loading");
  };

  // on close of modal, set detailed list to empty and page to 1
  const onCloseModal = () => {
    setOpened(false);
    setDetailedList([]);
    setTableColumns([]);
    setSearchSources("");
    setSort("visitors:desc");
    page.current = 0;
  };

  // useEffect to fetch detailed list on opening modal
  useEffect(() => {
    if (opened) {
      page.current = 0;
      setHasMore(true);
      setIsSortApplied(true);
      fetchDetailedUTMs();
    }
  }, [opened, sort, searchSources, activeTab]);

  return (
    <>
      <SidePanel opened={opened} onCancel={onCloseModal} title={"UTM Traffic"} loading={true}>
        <Flex justify={"space-between"} py={"sm"}>
          <Flex className="flex">
            {/*search input to search sources*/}
            <TextInput
              pr={10}
              data-autofocus
              leftSection={<FontAwesomeIcon icon={regular("search")} />}
              placeholder={`Search by ${activeTab.replace("_", " ")} `}
              onChange={(e) => {
                setSearchSources(e.target.value);
              }}></TextInput>

            <Select
              value={activeTab}
              onChange={(e) => {
                setDetailedList([]);
                setActiveTab(e);
              }}
              data={[
                {
                  label: "Source",
                  value: "utm_source"
                },
                {
                  label: "Campaign",
                  value: "utm_campaign"
                },
                {
                  label: "Content",
                  value: "utm_content"
                },
                {
                  label: "Medium",
                  value: "utm_medium"
                },
                {
                  label: "Term",
                  value: "utm_term"
                }
              ]}
            />
          </Flex>
          {/*Export button which will allow the user to export list as csv. It will be set to disabled if it's in the loading state*/}
          <Box pr={4}>
            {!isPublic && hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
              <ExportButton
                planName="Pro or Premium"
                onClick={exportUTMDetail}
                disabled={exporting === "loading"}
                loading={exporting === "loading"}
              />
            )}
          </Box>
        </Flex>
        {opened &&
        loadingDetailedList === "loaded" &&
        !isSortApplied &&
        detailedList.length === 0 ? (
          <Flex justify={"center"} align={"center"}>
            <NoResultsDetailedView colspan={3} />
          </Flex>
        ) : (
          <>
            <DynoTable
              columns={tableColumns}
              data={detailedList}
              fontSize={14}
              highlightOnHover={true}
              stripped={true}
              loading={loadingDetailedList === "loading" && isSortApplied ? true : false}
            />
            {!isSortApplied && hasMore && detailedList.length > 0 && (
              <div className="flex justify-center mt-4">
                {/* Load more option to fetch the remaining results */}
                <Button
                  loading={loadingDetailedList === ("loading" as LoadingStateProps)}
                  disabled={loadingDetailedList === ("loading" as LoadingStateProps)}
                  variant="outline"
                  color={theme.colorScheme === "dark" ? "dark.2" : "gray.7"}
                  onClick={() => fetchDetailedUTMs()}
                  data-cy="utm-traffic-detailed-view-load-more-btn">
                  Load more
                </Button>
              </div>
            )}
          </>
        )}
      </SidePanel>
    </>
  );
};
