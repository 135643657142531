import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Label } from "@/ui/components/Common/Label/Label";
import {
  Button,
  Checkbox,
  Input,
  PasswordInput,
  Title,
  Center,
  Text,
  Flex,
  Paper
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { APIUserService } from "@/lib/services/AuthService";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import { authLocalStorageUpdate } from "@/lib/utils/Storage";
import { ThirdPartyTracking } from "@/lib/utils/ThirdPartyTracking";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";
import { useCustomizeMenuStore } from "@/stores/useCustomizeMenuStore";

interface IFormInputs {
  password: string;
  email: string;
  remember_me: boolean;
}
export const Login = (props: any) => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IFormInputs>();
  const { setUser, setWorkspaces, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState("idle");
  const fetchMenuItems = useCustomizeMenuStore((state: any) => state.fetchMenuItems);

  useEffect(() => {
    document.title = "Login | Usermaven";
  }, []);

  const onSubmit = async (data: any) => {
    const userService = new APIUserService();
    try {
      setLoadingState("loading");
      let user = await userService
        .login(data.email, data.password, rememberMe)
        .then((res) => {
          setLoadingState("success");
          console.log(res);
          if (res.status === 200) {
            authLocalStorageUpdate(res.data.access_token, res.data.refresh_token);

            /**
             * NOTE: this is causing the problem.
             */
            // const TPService = new ThirdPartyTracking(res.data, null)
            // TPService.identify()

            return res.data;
          }
        })
        .catch((err) => {
          setLoadingState("failure");
          // TODO: use interceptor to handle all such cases.

          // for handling errors if they have the detail property available with string type.
          if (err.response && err.response.data) {
            if (
              err.response.data.hasOwnProperty("detail") &&
              typeof err.response.data.detail === "string"
            )
              toast.error(err.response.data.detail);
            console.debug(`Error code is ${err.response.status}`, err.response);
          }
        });

      console.log("The logged in user is: ", user);

      if (user) {
        const workspaceService = new WorkspaceService();
        const workspaceResponse = await workspaceService
          .workspacesAccess()
          .then(async (res) => {
            console.log(`Workspace access response from promise`, res);
            setActiveWorkspace(res.defaultWorkspace);
            setWorkspaces(res.workspacesList);
            await fetchMenuItems(res.defaultWorkspace.id);

            const TPService = new ThirdPartyTracking(user, res);
            TPService.identify();
          })
          .catch((err) => {
            console.log(err);
            return false;
          });
        // history.push("/");
        setUser(user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="auth-container--box">
      <Title order={3} ta="center" mb={16}>
        Welcome Back
      </Title>
      <Center>
        <Text fz={14}>
          Don’t have an account?{" "}
          <Link className=" " to="/register">
            Sign Up
          </Link>
        </Text>
      </Center>
      <p className="justify-content-center pb-4 display-flex"></p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="auth-container--box--form pt-4 pb-2">
          <div className="auth-input-group">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
                <Input.Wrapper size="xs" id={"workEmail"} label="Work Email" required>
                  <Input
                    mt={4}
                    value={value}
                    onChange={onChange}
                    placeholder="e.g carl@usermaven.com"
                    error={(invalid || error) === true}
                    data-cy="login-email"
                  />
                </Input.Wrapper>
              )}
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: true, pattern: /^\S+@\S+$/ }}
            />
            {errors.email?.type === "required" && (
              <ValidationLabel
                validationType="error"
                text="Email is required"
                size="small"
                customClasses="pt-2"></ValidationLabel>
            )}
            {errors.email?.type === "pattern" && (
              <ValidationLabel
                validationType="error"
                text="Please enter a valid email address"
                size="small"
                customClasses="pt-2"></ValidationLabel>
            )}
          </div>
          <div className="auth-input-group pt-4 pb-2">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
                <Input.Wrapper id={"password"} size="xs" label="Password" required>
                  <PasswordInput
                    mt={4}
                    placeholder="e.g *********"
                    onChange={onChange}
                    withAsterisk
                    value={value}
                    data-cy="login-password"
                  />
                </Input.Wrapper>
              )}
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: true, minLength: 8 }}
            />

            {errors.password?.type === "required" && (
              <ValidationLabel
                validationType="error"
                text="Password is required"
                size="small"
                customClasses="pt-2"></ValidationLabel>
            )}

            {errors.password?.type === "minLength" && (
              <ValidationLabel
                validationType="error"
                text="Password must be greater than 8 characters."
                size="small"
                customClasses="pt-2"></ValidationLabel>
            )}
          </div>
          <Flex my={"sm"} align={"center"}>
            <Checkbox
              size="xs"
              label="Remember me"
              checked={rememberMe}
              onChange={(event: any) => setRememberMe(event.currentTarget.checked)}
            />
            <Flex justify={"end"} flex={"1"}>
              <Text fz="xs">
                <Link className="" to="/forgot-password">
                  Forgot Password?
                </Link>
              </Text>
            </Flex>
          </Flex>

          <Button
            mt={"lg"}
            fullWidth
            // variant="gradient"
            loading={loadingState === "loading"}
            disabled={loadingState === "loading"}
            type="submit"
            data-cy="login-submit">
            Sign in
          </Button>
        </div>
      </form>
    </div>
  );
};
