import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Flex, Tooltip, Text } from "@mantine/core";
import { useConversionGoalsStore } from "@/stores/useConversionGoalsStore";
import { useWebFiltersModalStore } from "@/stores/useWebFiltersModalStore";
import {
  changeFilterOperator,
  getAppliedFilterName,
  getAppliedFilterValue,
  getOperatorLabel,
  getOperatorLabelClass
} from "./FiltersUtility";
import { ellipsisContent, toTitleCaseLabel } from "@/lib/utils/StringUtility";
import classes from "./FilterItemButton.module.css";

interface Props {
  item: string;
  index: number;
  query: any;
  setQuery: any;
}

export const FilterItemButton = ({ item, index, query, setQuery }: Props) => {
  const [setFilterKey, setFilterModalOpened, handleOnFilterValueClick] = useWebFiltersModalStore(
    (state) => [state.setFilterKey, state.setFilterModalOpened, state.handleOnFilterValueClick]
  );
  const [findGoalName] = useConversionGoalsStore((state) => [state.findGoalName]);
  const getFilterItemValue = (): string =>
    item.startsWith("goal:")
      ? findGoalName(item.split(":")[1])
      : item.startsWith("channel:")
      ? toTitleCaseLabel(getAppliedFilterValue(item || ""))
      : getAppliedFilterValue(item || "");
  return (
    <>
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        className={classes.box}
        key={`filter-item-button:${index}`}
        // className="bg-gray-100  flex  items-center rounded-md border border-solid border-gray-200 px-3 py-2 shadow-xs mr-2 transition-all ease-in-out"
        data-cy="filter-item-button">
        <Flex className="flex ">
          <Text pr={4} data-cy="applied-filter-name">
            {getAppliedFilterName(item || "")}{" "}
          </Text>{" "}
          <Text
            span
            fw={600}
            c={getOperatorLabelClass(item)}
            pr={4}
            onClick={() => {
              if (!item.startsWith("goal:")) {
                changeFilterOperator(item, query, setQuery);
              }
            }}>
            {getOperatorLabel(item)}
          </Text>
          <Tooltip
            multiline
            label={getFilterItemValue()}
            disabled={getFilterItemValue().length < 50}
            withArrow>
            <Text
              lineClamp={1}
              fw={600}
              onClick={() => handleOnFilterValueClick(item)}
              data-cy="applied-filter-value">
              {ellipsisContent(getFilterItemValue(), 50)}
            </Text>
          </Tooltip>
        </Flex>
        {/* <Flex justify="flex-end"> */}
        <Box ml={"xs"} c="red.6">
          <FontAwesomeIcon
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
              setQuery({
                ...query,
                filters: [...query.filters.filter((f: string, i: number) => f !== item)]
              });
            }}
            icon={regular("times")}></FontAwesomeIcon>
        </Box>

        {/* </Flex> */}
      </Flex>
    </>
  );
};
