import axios from './JWTService';


export class JourneyService {
  private readonly _endpoint;
  constructor() {
    this._endpoint = '/v1/journeys';
  }

  /**
   * Get all journeys.
   */
  getJourneys = (
    ws_id: any,
    sort: 'created_at:desc' | 'name:desc' | 'name:asc' | string | null,
    page: number = 1,
    limit: number = 20
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `${this._endpoint}/${ws_id}?page=${page}&sort=${sort}&limit=${limit}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Create new journey.
   */
  createJourney = (ws_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${ws_id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Delete journey.
   */
  deleteJourney = (ws_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${this._endpoint}/${ws_id}/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Update journey.
   */
  updateJourney = (ws_id: string, id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${this._endpoint}/${ws_id}/${id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Get journey's insights.
   */
  getJourneyInsights = (
    ws_id: string,
    id: string,
    startDate: string =  '',
    endDate: string = '',
    depth: number = 10,
    showCompleteList: boolean = false
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this._endpoint}/${ws_id}/${id}/insights`, {
          params: {
            start_date: startDate,
            end_date: endDate,
            level_depth: depth,
            show_complete_list: showCompleteList
          }
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }
}