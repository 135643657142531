import {
  Box,
  Divider,
  Grid,
  Group,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import DragIcon from "@assets/images/icons/dots.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Fragment, useRef, useState } from "react";
import { DropTargetMonitor, XYCoord, useDrag, useDrop } from "react-dnd";
import { ChannelMappingRulesProps } from "@/stores/useChannelMappingStore";
import { utcToRelativeTimezone } from "@/lib/utils/DateUtility";
import { useChannelMappingListStore } from "@/stores/useChannelMappingListStore";
import classes from "./ChannelMappingRow.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
export type ChannelMappingRowType = {
  id: string;
  priority: number;
  name: string;
  rules: ChannelMappingRulesProps[];
  created_at: string;
  created_by_user_id: string;
  created_by_first_name: string;
  created_by_last_name: string;
  updated_at: string;
  updated_by_user_id?: string;
  updated_by_first_name?: string;
  updated_by_last_name?: string;
};

interface ChannelMappingRowProps {
  row: ChannelMappingRowType;
  index: number;
  handleEditMapping: (id: string) => void;
  onDeleteMapping: (id: string) => void;
  moveMappingRow: (dragIndex: number, hoverIndex: number) => void;
  dropMappingRow: (originalList: ChannelMappingRowType[]) => void;
  nameIdentifier?: string;
}

export const ChannelMappingRow = ({
  row,
  index,
  handleEditMapping,
  onDeleteMapping,
  moveMappingRow,
  dropMappingRow,
  nameIdentifier = "ChannelMappingRow"
}: ChannelMappingRowProps) => {
  const theme = useGlobalMantineTheme();
  const ref = useRef<HTMLDivElement>(null);

  const [originalList, setOriginalList] = useState<ChannelMappingRowType[]>([]);
  const [mappingList] = useChannelMappingListStore((state) => [state.mappingList]);

  // Drag and drop
  const [collectedProps, drop] = useDrop<any>({
    accept: nameIdentifier,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver()
      };
    },

    hover(item: ChannelMappingRowProps, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveMappingRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  // Destructuring the collectedProps object to get the properties handlerId and isOver
  // handlerId is a string or undefined, it's the unique identifier of the handler
  // isOver is a boolean, it indicates whether the mouse is currently hovering over the component
  const { handlerId, isOver } = collectedProps as {
    handlerId: string | undefined;
    isOver: boolean;
  };

  /**
   * useDrag is a hook from the react-dnd library that is used for drag sources
   * It returns an array where the first item is a monitor object and the second item is a connector function
   * The monitor object contains information about the current drag state
   * The connector function is used to assign the drag source role to a DOM node

   * The hook takes an object as its parameter. This object contains several properties:
   * - type: a unique identifier for this type of draggable item
   * - item: a function that returns a plain object representing the data of the draggable item
   * - collect: a function that specifies what information from the monitor object should be collected and returned in the monitor object in the array returned by the hook
   */
  const [{ isDragging }, drag] = useDrag({
    type: nameIdentifier,
    // this function is fired when the drag starts
    item: () => {
      // Save the original list when drag starts
      setOriginalList(mappingList);
      // return the data that will be used in the drop function
      return { id: row.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
    // The end function is called when the drag ends
    end: (_item, monitor) => {
      // Was the item dropped and was it dropped on a valid target?
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        // If the item was dropped on a valid target
        // handle the drop and send an update to the server
        dropMappingRow(originalList);
      }
    }
  });

  // Opaque if dragging
  const opacity = isDragging ? 0.7 : 1;
  /**
   * The drag and drop handlers are attached to the ref of the component.
   * The drag() function is called with the result of the drop() function, which is called with the ref.
   * This connects the DOM node referred to by the ref to the drag and drop system.
   */
  drag(drop(ref));

  /**
   * Convert the selector key to human readable format to match the label in the UI.
   * @param selector
   */
  const selectorHumanReadable = (selector: string) => {
    switch (selector) {
      case "utm_source":
        return "UTM Source";
      case "utm_medium":
        return "UTM Medium";
      case "utm_campaign":
        return "UTM Campaign";
      case "url":
        return "Page URL";
      case "referer":
        return "Referrer";
      default:
        return selector;
    }
  };

  /**
   * Function to convert the form state key to human readable format.
   * @param rules
   * @returns
   */
  const mappingHumanReadable = (rules: ChannelMappingRulesProps[]) => {
    const humanReadable = (
      <Text span>
        {row.rules.flatMap((rule, ruleIndex, array) => [
          <Fragment key={ruleIndex}>
            {rule.conditions.flatMap((condition, conditionIndex, array) => [
              <Fragment key={conditionIndex}>
                {selectorHumanReadable(condition.selector)} {condition.operator}{" "}
                <Text span fw={700}>
                  {condition.value}
                </Text>
              </Fragment>,
              conditionIndex < array.length - 1 ? " AND " : ""
            ])}
          </Fragment>,
          ruleIndex < row.rules.length - 1 ? ") OR (" : ""
        ])}
      </Text>
    );

    if (rules.length > 1) {
      return <Text span>({humanReadable})</Text>;
    } else {
      return <Text span>{humanReadable}</Text>;
    }
  };
  const { colorScheme } = useMantineColorScheme();

  return (
    <Box ref={ref} data-handler-id={handlerId}>
      <Grid
        className={classes.row}
        opacity={opacity}
        m={0}
        py={"xs"}
        align="center"
        justify="space-between"
        columns={12}
        key={row.priority}>
        <Grid.Col span={1}>
          <Group>
            <img src={DragIcon} alt="" /> <Text>{row.priority}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col span={3}>
          <Text>{row.name}</Text>
          <Text fs="italic" color="dimmed" lineClamp={1} fz="xs">
            {
              // If updated and by same user who created the mapping
              // show "Updated by"
              // else show "Created by"
              row.updated_by_user_id && row.updated_by_user_id == row.created_by_user_id
                ? `Updated by ${row.updated_by_first_name} ${
                    row.updated_by_last_name
                  } ${utcToRelativeTimezone(row.updated_at)}`
                : `Created by ${row.created_by_first_name} ${
                    row.created_by_last_name
                  } ${utcToRelativeTimezone(row.created_at)}`
            }
          </Text>
          <Text fs="italic" color="dimmed" lineClamp={1} fz="xs">
            {
              // If updated by different user than the one who created the mapping
              // show "Updated by" as well as the created by
              row.updated_by_user_id &&
                row.updated_by_user_id !== row.created_by_user_id &&
                `Updated by ${row.updated_by_first_name} ${
                  row.updated_by_last_name
                } ${utcToRelativeTimezone(row.updated_at)}`
            }
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text span>{mappingHumanReadable(row.rules)}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Tooltip label="Edit" withArrow>
            <FontAwesomeIcon
              cursor="pointer"
              icon={solid("pen")}
              className="mr-4 cursor-pointer"
              color="gray"
              onClick={() => handleEditMapping(row.id)}
            />
          </Tooltip>
          <Tooltip label="Delete" withArrow>
            <FontAwesomeIcon
              cursor="pointer"
              icon={regular("trash-alt")}
              className="mr-2.5 cursor-pointer"
              color={theme.colors.red[6]}
              onClick={() => onDeleteMapping(row.id)}
            />
          </Tooltip>
        </Grid.Col>
      </Grid>
      <Divider />
    </Box>
  );
};
