import {
  Anchor,
  Button,
  Center,
  Flex,
  Input,
  Loader,
  PasswordInput,
  Text,
  Title,
  rem
} from "@mantine/core";
import WorkspaceJoinedSVG from "@assets/images/icons/email-verified.svg";
import InvalidTokenSVG from "@assets/images/icons/error-state.svg";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceMemberService } from "@/lib/services/WorkspaceMemberService";
import { TOAST_MESSAGES, WORKSPACE_MEMBER_STATE } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Oval } from "react-loader-spinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PasswordStrength } from "@/ui/components/App/PasswordStrength/PasswordStrength";

import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";

type TeamMemberType = {
  id: string;
  user: {
    email: string;
  };
};

type TokenParams = {
  token: string;
};
interface IFormInputs {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

export const JoinWorkspace = (props: any) => {
  const [loadingState, setLoadingState] = useState("idle"); // default state when we are validating token.
  const [invalidTokenState, setInvalidTokenState] = useState(false); // state when the token is invalid.
  const [workspaceMemberState, setWorkspaceMemberState] = useState<"invited" | "joined" | "">(""); // state of the user, invited or joined.
  const [userAccountState, setUserAccountState] = useState<"invited" | "active" | "">(""); // state of the user, invited or active.

  const { user } = useContext(AppLifecycleContext)!;
  const { token } = useParams<TokenParams>();
  const [actionLoading, setActionLoading] = useState("idle");
  const [teamMember, setTeamMember] = useState<TeamMemberType>();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset
  } = useForm<IFormInputs>();
  const navigate = useNavigate();
  const findUserByToken = async () => {
    const wsMemberService = new WorkspaceMemberService();
    setLoadingState("loading");

    let userRetrieved = await wsMemberService
      .get(token)
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          setWorkspaceMemberState(res.data.state);
          setUserAccountState(res.data.user.state);
          setTeamMember(res.data);
          return res.data;
        }
      })
      .catch((err) => {
        if (err?.response?.status && err.response.status === 422) {
          console.log(err?.response?.data.hasOwnProperty("detail"));
          console.log(typeof err.response.data.detail);
          if (
            err?.response?.data.hasOwnProperty("detail") &&
            typeof err.response.data.detail === "string"
          ) {
            // TODO: in case if we want to show a toast.
          }
          setInvalidTokenState(true);
        }
        return false;
      });

    // Note: Redirect if the user id is not same userRetrieved user_id
    // NOTE: this is a corner case where user id is not same as retrieved user id.
    if (user && user.id && userRetrieved) {
      if (userRetrieved.user_id !== user.id) {
        console.log(
          "Edge case: user id is not same as retrieved user id. So redirecting the customer."
        );
        navigate("/");
        return;
      }

      // if the user id is same as the invited user id.

      await wsMemberService.acceptInvite(token);

      navigate("/workspaces");
      return;
    }

    setLoadingState("loaded");
  };

  useEffect(() => {
    document.title = "Join Workspace | Usermaven";
    console.log(token);
    findUserByToken();
  }, []);
  useEffect(() => {
    if (teamMember) {
      if (teamMember?.user?.email) {
        console.log("reset form values");
        reset({
          email: teamMember.user.email
        });
      }
    }
  }, [teamMember]);
  const onSubmit = async (data: any) => {
    console.log("submit button");
    let payload = data;
    payload.token = token;
    const wsMemberService = new WorkspaceMemberService();
    setActionLoading("loading");
    await wsMemberService
      .join(payload)
      .then((res) => {
        if (res.data) {
          toast.success(TOAST_MESSAGES.WORKSPACE_JOINED_ACCOUNT_CREATED);
          navigate("/login");
        }
      })
      .catch((err) => {
        if (
          err?.response?.data.hasOwnProperty("detail") &&
          typeof err.response.data.detail === "string"
        ) {
          toast.error(err.response.data.detail);
        }
        if (err?.response?.data?.detail && typeof err.response.data.detail !== "string") {
          toast.error(
            `${err.response.data.detail[0].loc[1]} error: ${err.response.data.detail[0].msg}`
          );
        }
      });
    setActionLoading("loaded");
  };

  console.log(loadingState, invalidTokenState);
  const watchPassword = watch("password", "");

  console.log(
    `Loading state is ${loadingState}, user is ${user}, and account state is ${userAccountState}, token state is ${invalidTokenState}`
  );

  if (loadingState === "loading") {
    return (
      <>
        <Flex direction={"column"} h={100} justify={"center"} align={"center"}>
          <Loader size={"xs"} mb="sm" />
        </Flex>
      </>
    );
  }

  if (loadingState !== "loaded" && !invalidTokenState) {
    return (
      <>
        {/* Verify invitation link to join workspace */}
        <Flex direction={"column"} h={100} justify={"center"} align={"center"}>
          <Loader size={"xs"} mb="sm" />
          <Text> Verifying your link to join Workspace.</Text>
        </Flex>
      </>
    );
  }

  if (loadingState === "loaded" && invalidTokenState) {
    /* Catering use-case where the invitation link is invalid */
    return (
      <>
        <div className="auth-container--info">
          <img src={InvalidTokenSVG} className={"pb-8"} alt="invalid workspace link" />{" "}
          <Title order={3} ta="center" my="md">
            Your workspace invitation link is invalid.
          </Title>
        </div>
      </>
    );
  }
  /* Catering use-case where the user has already joined the workspace. */
  if (workspaceMemberState === WORKSPACE_MEMBER_STATE.JOINED && userAccountState === "active") {
    return (
      <>
        <div className="auth-container--info">
          <img src={WorkspaceJoinedSVG} className={"pb-8"} alt="workspace already joined" />
          <Title order={3} ta="center" my="md">
            You have already joined workspace.
          </Title>
          {user && user?.email ? (
            <>
              <Flex justify={"center"}>
                <Button
                  onClick={() => {
                    navigate("/");
                  }}>
                  Go to Dashboard
                </Button>
              </Flex>
            </>
          ) : (
            <>
              <Center>
                <Text>
                  Please login to your account to access workspace.{" "}
                  <Link to="/login">Click here to login.</Link>
                </Text>
              </Center>
            </>
          )}
        </div>
      </>
    );
  }
  /* Use case: Where the customer have already have an account */

  if (
    (!user || !user.email) &&
    loadingState === "loaded" &&
    userAccountState === "active" &&
    workspaceMemberState === WORKSPACE_MEMBER_STATE.INVITED
  ) {
    return (
      <div className="auth-container--info">
        <img src={InvalidTokenSVG} className={"pb-8"} alt="workspace already joined" />
        <Title order={3} ta="center" my="md">
          You have already joined workspace.
        </Title>
        {user && user?.email ? (
          <Center>
            <Button
              onClick={() => {
                navigate("/");
              }}>
              Login to Accept Invite
            </Button>
          </Center>
        ) : (
          <Center>
            <Text>
              You are not logged into platform.{" "}
              <Link to={`/login?redirect=/join-workspace/${token}`}>Click here to login.</Link>
            </Text>
          </Center>
        )}
      </div>
    );
  }
  {
    /* Use-case where the user have not signed up yet */
  }

  return (
    <>
      {loadingState === "loaded" &&
        invalidTokenState === false &&
        userAccountState === "invited" && (
          <div className="auth-container--box">
            <Title order={3} ta="center" my="md">
              Join Usermaven
            </Title>
            <Text ta="center">
              Already have Usermaven account?{" "}
              <Link
                style={{
                  paddingLeft: rem(4)
                }}
                to="/login">
                Sign in
              </Link>
            </Text>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="auth-container--box--form pt-4 pb-2">
                <div className="auth-input-group--row">
                  <div className="auth-input-group auth-input-group--row--group">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error }
                      }) => (
                        <Input.Wrapper size="xs" id={"firstName"} label="First Name" required>
                          <Input
                            mt={4}
                            value={value}
                            onChange={onChange}
                            placeholder="Carl"
                            error={(invalid || error) === true}
                          />
                        </Input.Wrapper>
                      )}
                      name="first_name"
                      control={control}
                      rules={{ required: true, maxLength: 24 }}
                    />

                    {errors.first_name?.type === "required" && (
                      <ValidationLabel
                        validationType="error"
                        text="First name is required"
                        size="small"
                        customClasses="pt-2"></ValidationLabel>
                    )}
                    {errors.first_name?.type === "maxLength" && (
                      <ValidationLabel
                        validationType="error"
                        text="First name should be less than 24 characters."
                        size="small"
                        customClasses="pt-2"></ValidationLabel>
                    )}
                  </div>
                  <div className="auth-input-group auth-input-group--row--group">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error }
                      }) => (
                        <Input.Wrapper size="xs" id={"lastName"} label="Last Name" required>
                          <Input
                            mt={4}
                            value={value}
                            onChange={onChange}
                            placeholder="Frederick"
                            error={(invalid || error) === true}
                          />
                        </Input.Wrapper>
                      )}
                      name="last_name"
                      control={control}
                      rules={{ required: true, maxLength: 24 }}
                    />

                    {errors.last_name?.type === "required" && (
                      <ValidationLabel
                        validationType="error"
                        text="Last name is required"
                        size="small"
                        customClasses="pt-2"></ValidationLabel>
                    )}
                    {errors.last_name?.type === "maxLength" && (
                      <ValidationLabel
                        validationType="error"
                        text="Last name should be less than 24 characters."
                        size="small"
                        customClasses="pt-2"></ValidationLabel>
                    )}
                  </div>
                </div>
                <div className="auth-input-group pt-4 pb-2">
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error }
                    }) => (
                      <Input.Wrapper size="xs" id={"workEmail"} label="Work Email" required>
                        <Input
                          mt={4}
                          value={value}
                          onChange={onChange}
                          disabled
                          defaultValue={teamMember?.user?.email}
                          placeholder="carl@usermaven.com"
                          error={(invalid || error) === true}
                        />
                      </Input.Wrapper>
                    )}
                    name="email"
                    control={control}
                    rules={{ required: true, pattern: /^\S+@\S+$/ }}
                  />

                  {errors.email?.type === "required" && (
                    <ValidationLabel
                      validationType="error"
                      text="Email is required"
                      size="small"
                      customClasses="pt-2"></ValidationLabel>
                  )}
                  {errors.email?.type === "pattern" && (
                    <ValidationLabel
                      validationType="error"
                      text="Please enter a valid email address"
                      size="small"
                      customClasses="pt-2"></ValidationLabel>
                  )}
                </div>
                <Flex pos="relative" className="auth-input-group" mb="md">
                  <Flex pos={"absolute"} right={0} top={10} direction={"row-reverse"}>
                    <PasswordStrength password={watchPassword} />
                  </Flex>

                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error }
                    }) => (
                      <Input.Wrapper id={"password"} size="xs" label="Password" required>
                        <PasswordInput
                          mt={4}
                          placeholder="*********"
                          onChange={onChange}
                          withAsterisk
                          value={value}
                        />
                      </Input.Wrapper>
                    )}
                    name="password"
                    control={control}
                    rules={{ required: true, minLength: 8 }}
                  />

                  {errors.password?.type === "required" && (
                    <ValidationLabel
                      validationType="error"
                      text="Password is required"
                      size="small"
                      customClasses="pt-2"></ValidationLabel>
                  )}

                  {errors.password?.type === "minLength" && (
                    <ValidationLabel
                      validationType="error"
                      text="Password must be greater than 8 characters."
                      size="small"
                      customClasses="pt-2"></ValidationLabel>
                  )}
                </Flex>

                <Button
                  type="submit"
                  fullWidth
                  disabled={actionLoading === "loading"}
                  loading={actionLoading === "loading"}>
                  Create Account
                </Button>

                <div className="auth-input-group pt-4 pb-2">
                  <Text ta="center" pt="sm" fz="xs">
                    By clicking “Create Free Account” you agree to Usermaven’s{" "}
                    <Anchor
                      fz="xs"
                      target="_blank"
                      rel="noreferrer"
                      href="https://usermaven.com/terms-and-conditions"
                      className={"primary-link-color"}>
                      terms &amp; conditions
                    </Anchor>{" "}
                    &amp;{" "}
                    <Anchor
                      fz="xs"
                      target="_blank"
                      rel="noreferrer"
                      href="https://usermaven.com/privacy-policy"
                      className={"primary-link-color"}>
                      privacy policy
                    </Anchor>
                    .
                  </Text>
                </div>
              </div>
            </form>
          </div>
        )}
    </>
  );
};
