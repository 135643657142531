import { Button, Checkbox, Divider, Modal } from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { WorkspaceIntegrationService } from "@/lib/services/WorkspaceIntegrationService"
import { LOADER_COLOR } from "@/lib/utils/Constants"
import { useContext, useEffect, useState } from "react"
import { Oval } from "react-loader-spinner"
import { toast } from "react-toastify"
import { LoadingStateProps } from "types/types.d"
import { StringParam, useQueryParam } from "use-query-params"

interface IGSCSitesModal {
  /**
   * Reload is a callback function to update the list of connected integrations.
   * @param name Type of service. In this case it is "google_search_console"
   * @returns
   */
  reload: (name: any) => void
}

export const GSCSitesModal = ({ reload }: IGSCSitesModal) => {
  /**
   * Loading state of the component
   */
  const [loading, setLoading] = useState<LoadingStateProps>("idle")
  /**
   * The modal will be opened if the modal and ID is present.
   */
  const [isModal] = useQueryParam("modal", StringParam)
  /**
   * Integration id. This is used to fetch the list of connected sites.
   */
  const [integrationId] = useQueryParam("id", StringParam)
  /**
   * Used to open and close the modal.
   */
  const [isModalOpen, setIsModalOpen] = useState(false)
  /**
   * Active workspace of the project
   */
  const { activeWorkspace } = useContext(AppLifecycleContext)
  /**
   * List of connected sites.
   * The user can select the sites to be connected.
   */
  const [connectedSites, setConnectedSites] = useState<string[]>([])
  /**
   * List of all sites.
   */
  const [sites, setSites] = useState<string[]>([])
  /**
   * List of verified permissions that should be for the property from GSC.
   */
  const verifiedPermissions = ["siteOwner", "siteFullUser", "siteRestrictedUser"]

  /**
   * Fetch the integration details by ID and set the connected sites and all sites.
   */
  const fetchIntegration = async () => {
    setLoading("loading")
    await new WorkspaceIntegrationService()
      .getById(activeWorkspace.id, integrationId || "")
      .then(res => {
        if (res.data.id) {
          setConnectedSites(res.data.meta_details.site_urls)
          setSites(res.data.meta_details.all_sites?.siteEntry || [])
        }
      })
      .catch(err => {})
    setLoading("loaded")
  }

  /**
   * Save the connected sites to the integration.
   */
  const handleOnSave = () => {
    new WorkspaceIntegrationService()
      .updateGSCSites(activeWorkspace.id, integrationId || "", connectedSites)
      .then(res => {
        if (res.data.message) {
          toast.success(res.data.message)
          setIsModalOpen(false)
          reload("google_search_console")
        }
      })
      .catch(err => {})
  }

  /**
   * Fetch the integration details on mount.
   */
  useEffect(() => {
    if (isModal && integrationId) {
      setIsModalOpen(true)
      fetchIntegration()
    }
  }, [])
  return (
    <>
      <Modal
        onClose={() => setIsModalOpen(false)}
        opened={isModalOpen}
        title="Connect Google Search Console domains"
      >
        {loading === "loaded" ? (
          <>
            <Checkbox.Group
              value={connectedSites}
              onChange={setConnectedSites}
              defaultValue={sites}
              orientation="vertical"
              label="Select your domains"
              withAsterisk
            >
              {sites.map((site: any) => (
                <>
                  {site.permissionLevel && verifiedPermissions.includes(site.permissionLevel) && (
                    <>
                      <Checkbox value={site.siteUrl} label={site.siteUrl} />
                    </>
                  )}
                </>
              ))}
            </Checkbox.Group>
            <Divider my={'md'} />

            <div className="flex flex-row justify-end">
              <Button mr={8} variant="subtle" color="red" onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
              <Button onClick={handleOnSave}>Save</Button>
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center py-4">
            <Oval color={LOADER_COLOR} height={14} width={14}></Oval>
          </div>
        )}
      </Modal>
    </>
  )
}
