import axios from "./JWTService";

export class ContactNotesService {
  private readonly _endpoint;
  constructor() {
    this._endpoint = "/v1/notes";
  }

  /**
   * Get all notes by workspace id.
   */
  getNotes = (workspace_id: string, contact_id: string, type: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this._endpoint}/${workspace_id}/${contact_id}/${type}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Get note count by workspace id.
   * @param workspace_id
   * @param contact_id
   * @param type
   */
  getNoteCount = (workspace_id: string, contact_id: string, type: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${this._endpoint}/${workspace_id}/${contact_id}/${type}/count`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Create new note.
   * @param data
   */
  createNote = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${this._endpoint}/${workspace_id}`, data)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * Create new note.
   * @param data
   */
  updateNote = (workspace_id: string, id: string | number, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${this._endpoint}/${workspace_id}/${id}`, data)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  /**
   * Delete note.
   */
  deleteNote = (workspace_id: string, id: string | number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${this._endpoint}/${workspace_id}/${id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
