import { InsightTemplateType } from "@/types/types.d";
import { ActiveUsersSetupModal } from "./ActiveUsersSetupModal";
import FeatureAdoptionSetupModal from "./FeatureAdoptionSetupModal";
import OnboardingCompletionSetupModal from "./OnboardingCompletionSetupModal";
import PowerUsersSetupModal from "./PowerUsersSetupModal";
import SlippingAwayUsersSetupModal from "./SlippingAwayUsersSetupModal";
import UserRetentionSetupModal from "./UserRetentionSetupModal";

interface ISetupInsightsModal {
  isModalOpen: boolean;
  setIsModalOpen: (e: boolean) => void;
  insightType: InsightTemplateType;
  insightId?: string;
}

export const SetupInsightsModal = ({
  isModalOpen,
  setIsModalOpen,
  insightType = 'onboarding_completion_rate',
  insightId,
}: ISetupInsightsModal) => {

  return (
    <>
      {insightType === "active_users" ? (
        <ActiveUsersSetupModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          insightId={insightId}
        />
      ) : insightType === "feature_adoption" ? (
        <FeatureAdoptionSetupModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          insightId={insightId}
        />
      ) : insightType === "onboarding_completion_rate" ? (
        <OnboardingCompletionSetupModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          insightId={insightId}
        />
      ) : insightType === "power_users" ? (
        <PowerUsersSetupModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          insightId={insightId}
        />
      ) : insightType === "slipping_away_users" ? (
        <SlippingAwayUsersSetupModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          insightId={insightId}
        />
      ) : insightType === "user_retention" ? (
        <UserRetentionSetupModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          insightId={insightId}
        />
      ) : (
        <></>
      )}
    </>
  );
};
