// @ts-ignore
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "@storybook/addons";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { getIntlDateAndTime } from "@/lib/utils/DateUtility";
import { useContext } from "react";
import { NavLink } from "react-router-dom";

export const PastDueNotification = ({ className }: any) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  return (
    <>
      {!activeWorkspace?.organization.plan.is_lifetime &&
        activeWorkspace?.organization?.subscriptions?.length > 0 &&
        activeWorkspace?.organization?.subscriptions[0].paddle_status === "past_due" &&
        activeWorkspace.organization.subscriptions[0].next_retry_date && (
          <>
            <div
              className={`flex m-4 px-4 py-4 shadow-sm bg-red-100 bg-opacity-50 rounded border border-solid border-red-200  transition-all ease-in-out ${className}`}>
              <div className="h-4 w-4 mr-4">
                <FontAwesomeIcon
                  size="lg"
                  className="text-red-800 "
                  icon={regular("triangle-exclamation")}
                />
              </div>
              <div>
                Your subscription is past due. We'll perform another attempt on your credit card by{" "}
                <span className="font-semibold pl-1.5">
                  {getIntlDateAndTime(
                    activeWorkspace.organization.subscriptions[0].next_retry_date
                  )}
                  .
                </span>{" "}
                <NavLink
                  to={`/env/${activeWorkspace.identifier}/account/billing`}
                  className="pr-1.5">
                  Click here
                </NavLink>
                to update your billing information.
              </div>
            </div>
          </>
        )}
    </>
  );
};
