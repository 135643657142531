import { usePageView, useUsermaven } from "@/hooks/useUsermaven"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { USERMAVEN_OPTS } from "@/lib/utils/Constants"
import { getPublicRedirectionRouteURL } from "@/lib/utils/RedirectionRoute"
import { useContext } from "react"
import { Navigate, RouteProps } from "react-router-dom"
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading"
import { StringParam, useQueryParam } from "use-query-params"

const ProtectedRoute = ({ children }: any) => {
  // usermaven tracker
  const { usermaven } = useUsermaven(USERMAVEN_OPTS)
  usePageView(usermaven)
  const { user, isLoading } = useContext(AppLifecycleContext)
  if (isLoading) {
    return <LoadingComponent />
  }

  if (user) {
    return children
  }
  return (
    <Navigate
      to={{
        pathname: "/login",
      }}
    />
  )
}

export default ProtectedRoute
