import { Avatar, Box, Divider, Flex, Paper } from "@mantine/core";
import React from "react";
import styles from "./StepCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import clsx from "clsx";

export const StepCard = ({
  step,
  checked = false,
  children
}: {
  step: number;
  children: React.ReactNode;
  checked?: boolean;
}) => {
  return (
    <Paper mb="md" withBorder p="md" shadow="xs" className={styles.card}>
      <Flex>
        {checked ? (
          <>
            <Box className={clsx(styles.step, styles.active)}>
              <FontAwesomeIcon icon={solid("check")} />
            </Box>
          </>
        ) : (
          <Box className={styles.step}>{step}</Box>
        )}

        <Divider orientation="vertical" mx="md" />
        {children}
      </Flex>
    </Paper>
  );
};
