/* eslint-disable react-hooks/exhaustive-deps */
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Flex, Text, TextInput, Tooltip, useMantineTheme } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { format } from "date-fns";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { useContext, useEffect, useRef, useState } from "react";
import { LoadingStateProps } from "types/types.d";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import useWorkspaceUtilityHook from "../../../../../hooks/useWorkspaceUtilityHook";
import {
  DATE_FORMAT,
  DEFAULT_CHANNEL_TYPES,
  PALE_SHADES,
  WORKSPACE_MEMBER_ROLES
} from "../../../../../lib/utils/Constants";
import { downloadCSVFromResponse } from "../../../../../lib/utils/CSVExporterUtility";
import { ExportButton } from "../../../Common/ExportButton/ExportButton";
import { NoResultsDetailedView } from "./NoResultsDetailedView";
import { DynoTable, ProgressCell } from "@/ui/components/Common/DynoTable/DynoTable";
import { toTitleCase } from "@/lib/utils/InsightsUtility";
import { isGoalSelected } from "../Filters/FiltersUtility";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const waService = new WebAnalyticsService();

export const TopSourcesDetailedView = ({
  query,
  opened,
  activeTab,
  setActiveTab,
  setOpened,
  onHandleClick,
  isPublic
}: any) => {
  const theme = useGlobalMantineTheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const page = useRef(0);
  const [sort, setSort] = useState(isGoalSelected() ? "conversions:desc" : "visitors:desc");
  const [isSortApplied, setIsSortApplied] = useState(true);

  // handle search using debounce
  const [searchSources, setSearchSources] = useDebouncedState("", 200);

  // detailed modal view for listing.
  const [loadingDetailedList, setLoadingDetailedList] = useState<LoadingStateProps>("idle");
  const [detailedList, setDetailedList] = useState<
    Array<{
      source: string;
      visitors: number;
      visitors_comparison?: number;
      visitors_percentage_change?: {
        label: string;
        percentage_change: number;
        comparison_value: number;
      };
      bounce_rate: number;
      bounce_rate_comparison?: number;
      bounce_rate_percentage_change?: number;
      conversion_rate: number;
      conversion_rate_comparison?: number;
      conversion_rate_percentage_change?: {
        label: string;
        percentage_change: number;
        comparison_value: number;
      };
    }>
  >([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);

  const [hasMore, setHasMore] = useState(true);
  // Get user role in the workspace.
  const { hasRolesNotWith } = useWorkspaceUtilityHook();
  // exporting state for export list
  const [exporting, setIsExporting] = useState("idle");

  // fetching detailed list
  const fetchDetailedList = async () => {
    setLoadingDetailedList("loading");

    const channelString = query.filters
      .find((str: string) => str.startsWith("channel:"))
      ?.split(":")[1]
      .toLowerCase()
      .replace(/ /g, "_");

    // Check if the channelString matches any DEFAULT_CHANNEL_TYPES, if not, set it to all.
    // If channelString is undefined, keep channelType as it is.
    const selectedTab = channelString
      ? Object.values(DEFAULT_CHANNEL_TYPES).includes(channelString as DEFAULT_CHANNEL_TYPES)
        ? (channelString as DEFAULT_CHANNEL_TYPES)
        : "all"
      : activeTab;

    let queryParams = query;
    if (channelString) {
      // Create a copy of query.filters excluding items starting with "channel:"
      const filteredFilters = query.filters.filter(
        (filterItem: string) => !filterItem.startsWith("channel:")
      );

      // updte the queryParams with the new filteredFilters without the channel filter in case of Top Sources only.
      queryParams = { ...query, filters: filteredFilters };
    }

    setActiveTab(selectedTab);

    // adding page number to show more results
    page.current = page.current + 1;
    await waService
      .topSourcesForChannel(
        activeWorkspace.id,
        selectedTab,
        "list",
        page.current,
        50,
        sort,
        queryParams,
        searchSources
      )
      .then((res) => {
        if (res.data.data) {
          // If the detailed list has already some results, then append the new results to the list.
          if (page.current === 1) {
            setDetailedList(res.data.data);
          } else {
            setDetailedList([...detailedList, ...res.data.data]);
          }
          if (res.data.columns) {
            // Modify columns before setting the state
            const modifiedColumns = res.data.columns.map((column: { accessor: string }) => {
              if (column.accessor === "source_with_value") {
                return {
                  ...column,
                  render: (data: any) => (
                    <ProgressCellWithHover cellData={data} onClick={onHandleClick} />
                  )
                };
              }
              if (
                column.accessor === "visitors" ||
                column.accessor === "bounce_rate" ||
                column.accessor === "conversions"
              ) {
                return {
                  ...column,
                  header: (
                    <Box
                      sx={{
                        display: "flex"
                      }}
                      onClick={() => {
                        changeSortOrder(column.accessor); // visitors or bounce_rate
                      }}>
                      <Text>{toTitleCase(column.accessor)}</Text>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "4px"
                        }}>
                        <FontAwesomeIcon
                          icon={solid("sort")}
                          style={{
                            color: sort.startsWith(column.accessor)
                              ? theme.colors.brand[4]
                              : theme.colorScheme === "light"
                              ? theme.colors.gray[5]
                              : theme.colors.gray[7]
                          }}
                          className={`ml-2 transform   transition-all duration-200 ease-in-out`}
                        />
                      </Box>
                    </Box>
                  ),
                  render: (data: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start"
                      }}>
                      <Text fw={400}>{numberToCommas(data)}</Text>
                    </Box>
                  )
                };
              }
              if (column.accessor === "conversion_rate") {
                return {
                  ...column,
                  header: (
                    <Box
                      sx={{
                        display: "flex"
                      }}
                      onClick={() => {
                        changeSortOrder(column.accessor); // visitors or bounce_rate
                      }}>
                      <Text>{"CR"}</Text>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "4px"
                        }}>
                        <FontAwesomeIcon
                          icon={solid("sort")}
                          style={{
                            color: sort.startsWith(column.accessor)
                              ? theme.colors.brand[4]
                              : theme.colorScheme === "light"
                              ? theme.colors.gray[5]
                              : theme.colors.gray[7]
                          }}
                          className={`ml-2 transform   transition-all duration-200 ease-in-out`}
                        />
                      </Box>
                    </Box>
                  ),
                  render: (data: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start"
                      }}>
                      <Text fw={400}>{data}%</Text>
                    </Box>
                  )
                };
              }

              if (column.accessor.endsWith("percentage_change")) {
                return {
                  ...column,
                  render: ({ label, percentage_change, comparison_value }: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start"
                      }}>
                      <Tooltip.Floating
                        label={`Comparison period ${label}: ${comparison_value}${
                          label === "CR" ? "%" : ""
                        }`}
                        position="top"
                        style={{ fontSize: "12px" }}>
                        <Text
                          fw={400}
                          color={
                            percentage_change !== undefined && percentage_change !== null
                              ? percentage_change > 0
                                ? "green.6"
                                : percentage_change < 0
                                ? "red.6"
                                : "gray.6"
                              : "gray.6"
                          }>
                          {percentage_change !== undefined &&
                          percentage_change !== null &&
                          percentage_change !== 0
                            ? `${percentage_change > 0 ? "+" : ""}${percentage_change.toFixed(1)}%`
                            : ""}
                        </Text>
                      </Tooltip.Floating>
                    </Box>
                  )
                };
              }
              return column;
            });
            setTableColumns(modifiedColumns);
          }

          // if the length of the results is less than 50, then there are no more results to show.
          if (res.data.data.length < 50) {
            setHasMore(false);
          }

          // Reset the sort applied to false after the data is fetched.
          setIsSortApplied(false);
        }
      })
      .catch((err) => {
        setHasMore(false);
      });
    setLoadingDetailedList("loaded");
  };

  const ProgressCellWithHover = ({ cellData, onClick }: any) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <Box
        onClick={() => {
          onClick(cellData.source);
          setOpened(false);
        }}>
        <ProgressCell
          label={<LabelWithActions data={cellData} isHovered={isHovered} />}
          setIsHovered={setIsHovered}
          barColor={PALE_SHADES.blue}
          rowSpacing="0.75rem"
        />
      </Box>
    );
  };

  const LabelWithActions = ({ data, isHovered }: any) => {
    const img =
      data.source.includes(".") || data.source === "Direct" ? data.source : data.source + ".com";
    const externalLink = activeTab === "referral" ? `https://${data.source}` : "";

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <Box
              sx={{
                height: "15px"
              }}>
              {img && typeof img === "string" && img.length > 0 && (
                <img
                  height={16}
                  src={`https://favicon.usermaven.com/?url=${
                    img.length > 0 ? img : "temp-mail.org"
                  }&size=32${theme.colorScheme === "dark" ? "&fallback=default-white.svg" : ""}`}
                  alt=""
                />
              )}
              {img && typeof img !== "string" && <>{img}</>}
            </Box>
            <Text pl={"5px"}>{data.source}</Text>
            {externalLink && isHovered && (
              <Tooltip label="Open link" color="dark" withArrow style={{ fontSize: "12px" }}>
                <Box
                  pl={"1rem"}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(externalLink, "_blank");
                  }}>
                  <FontAwesomeIcon
                    icon={regular("up-right-from-square")}
                    className="w-4 h-4 text-gray-900 hover:opacity-80 "
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  //Service call to top sources list as CSV.
  const exportTopSources = async () => {
    setIsExporting("loading");
    try {
      const response = await waService.topSourcesExport(
        activeWorkspace.id,
        activeTab,
        "list",
        1,
        10000,
        sort,
        query,
        searchSources
      );
      if (response.data) {
        //Setting filename of the exported file in accordance with the activeTab, date and time of export.
        let filename = `${activeTab}_sources_list_${format(new Date(), DATE_FORMAT)}.csv`;
        //Utility function to download the csv from response.
        downloadCSVFromResponse(response, filename);
        setIsExporting("loaded");
        return true;
      }
    } catch (err) {
      setIsExporting("loaded");
      throw new Error("400");
    }
    return false;
  };

  // function to handle change in sort on clicking table header
  const changeSortOrder = (field: string) => {
    setIsSortApplied(true);
    let sortingKey;
    // if sort is already set to desc, then set it to asc and vice versa
    if (sort.endsWith(":desc")) {
      sortingKey = `${field}:asc`;
    } else if (sort.endsWith(":asc")) {
      sortingKey = `${field}:desc`;
    } else {
      sortingKey = `${field}:desc`;
    }
    // setting detailed list to empty and page to 1 so that new sorted data can be fetched
    setSort(sortingKey);
    setDetailedList([]);
    page.current = 0;
    setHasMore(true);
  };

  // on close of modal, set detailed list to empty and page to 1
  const onCloseModal = () => {
    setOpened(false);
    setDetailedList([]);
    setTableColumns([]);
    setSearchSources("");
    setSort("visitors:desc");
    page.current = 0;
  };

  // useEffect to fetch detailed list on opening modal
  useEffect(() => {
    // Send the service call when the modal is opened.
    // In the rest of the cases, the service call will not be sent.
    if (opened) {
      page.current = 0;
      setHasMore(true);
      setIsSortApplied(true);
      setLoadingDetailedList("loading");
      fetchDetailedList();
    }
  }, [opened, sort, searchSources]);
  return (
    <>
      <SidePanel
        loading={true}
        title={"Top Sources"}
        onCancel={onCloseModal}
        opened={opened}
        hideOverflow={true}>
        <div className=" pt-0">
          <Flex justify="space-between" my={"sm"}>
            {/*search input to search sources*/}
            <TextInput
              data-autofocus
              leftSection={<FontAwesomeIcon icon={regular("search")} />}
              placeholder="Search by source name"
              onChange={(e) => {
                setSearchSources(e.target.value);
              }}></TextInput>
            {/*Export button which will allow the user to export list as csv. It will be set to disabled if it's in the loading state*/}
            <div className="pr-2 ml-4">
              {!isPublic && hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                <ExportButton
                  planName="Pro or Premium"
                  onClick={exportTopSources}
                  disabled={exporting === "loading"}
                  loading={exporting === "loading"}
                />
              )}
            </div>
          </Flex>
          {opened &&
          loadingDetailedList === "loaded" &&
          !isSortApplied &&
          detailedList.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
              <NoResultsDetailedView colspan={3} />
            </Box>
          ) : (
            <>
              <DynoTable
                columns={tableColumns}
                data={detailedList}
                fontSize={14}
                highlightOnHover={true}
                stripped={true}
                loading={loadingDetailedList === "loading" && isSortApplied ? true : false}
              />
              {!isSortApplied && hasMore && detailedList.length > 0 && (
                <div className="flex justify-center mt-4">
                  {/* Load more option to fetch the remaining results */}
                  <Button
                    loading={loadingDetailedList === ("loading" as LoadingStateProps)}
                    disabled={loadingDetailedList === ("loading" as LoadingStateProps)}
                    variant="outline"
                    color={theme.colorScheme === "dark" ? "dark.2" : "gray.7"}
                    onClick={() => fetchDetailedList()}
                    data-cy="top-sources-detailed-view-load-more-btn">
                    Load more
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </SidePanel>
    </>
  );
};
