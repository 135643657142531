import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Menu, Tooltip, useMantineTheme } from "@mantine/core";
import { useState } from "react";
import { useWebFiltersModalStore } from "@/stores/useWebFiltersModalStore";
import { FilterModal } from "./FilterModal";
import { FILTER_MENU_ITEMS } from "./FiltersUtility";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

/**
 * FilterMobileDropdown is a dropdown menu that contains an option for the user to
 * add a filter i.e Page. Source, Location etc
 * @param Props
 * @returns
 */
export const FilterDropdown = ({
  query,
  setQuery,
  scrollPosition
}: {
  query: any;
  setQuery: any;
  scrollPosition: number;
}) => {
  const theme = useGlobalMantineTheme();
  // toggle state for the dropdown menu
  const [open, setOpen] = useState<boolean>(false);

  // modal store
  const [filterKey, setFilterKey, modalOpened, setModalOpened] = useWebFiltersModalStore(
    (state) => [
      state.filterKey,
      state.setFilterKey,
      state.filterModalOpened,
      state.setFilterModalOpened
    ]
  );
  return (
    <>
      <FilterModal
        query={query}
        setQuery={setQuery}
        opened={modalOpened}
        onClose={() => setModalOpened(!modalOpened)}
        filterKey={filterKey}
      />
      <div className=" hidden sm:block">
        <Menu opened={open} onChange={setOpen}>
          <Menu.Target>
            <Tooltip label="Filter" withArrow style={{ fontSize: "12px" }}>
              <ActionIcon
                mr={8}
                className={`${
                  scrollPosition > 0.03
                    ? theme.colorScheme === "dark"
                      ? "border border-solid border-gray-200 !text-gray-600 !bg-dark-600"
                      : "border border-solid border-gray-200 text-gray-900"
                    : " text-gray-800"
                }`}
                color={scrollPosition > 0.03 ? "white" : "gray.6"}
                variant={scrollPosition > 0.03 ? "light" : "subtle"}>
                <FontAwesomeIcon icon={regular("filter")} data-cy="filter-icon"></FontAwesomeIcon>
              </ActionIcon>
            </Tooltip>
          </Menu.Target>
          <Menu.Dropdown>
            {FILTER_MENU_ITEMS.map((item) => (
              <Menu.Item
                key={item.value}
                className={"w-32"}
                onClick={() => {
                  setModalOpened(true);
                  setFilterKey(item.value);
                }}
                data-cy="filters-list">
                {item.label}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </div>
    </>
  );
};
