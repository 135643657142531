import create from "zustand";
import { IFunnelStep } from "@/ui/pages/Analyze/components/FunnelDetailedView/FunnelDetails";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";

export type UserDetail = {
  id: string;
  completed_step1: string;
  completed_step2: string;
  completed_step3: string;
  name: string;
  created_at: string;
  first_seen: string;
  last_seen: string;
  email?: string;
};

export type UsersList = {
  completed: Array<UserDetail>;
  dropped_off: Array<UserDetail>;
  name: string;
};

type State = {
  /**
   * Steps of funnel insights
   */
  steps: IFunnelStep[];
  setSteps: (list: IFunnelStep[]) => void;

  /**
   * Insights of funnel
   */
  insights: any | null;
  setInsights: (insights: any) => void;

  /**
   * Meta of funnel insights
   */
  meta: any;
  setMeta: (meta: any) => void;

  /**
   * Users list
   */
  users: UsersList[];
  setUsers: (UsersList: UsersList[]) => void;

  /**
   * Fetching state
   */
  fetching: boolean;
  setFetching: (fetching: boolean) => void;
  fetchingInsights: boolean;
  setFetchingInsights: (fetching: boolean) => void;
  fetchingUsers: boolean;
  setFetchingUsers: (fetching: boolean) => void;

  /**
   * Fetch funnel insights
   * @param funnelId
   * @param workspaceId
   * @param query
   */
  fetchFunnel: (workspaceId: string, funnelId: string, query: any) => void;

  /**
   * Fetch funnel insights
   */
  fetchFunnelInsights: (workspaceId: string, funnelId: string, query: any) => Promise<void>;

  /**
   * Fetch users list
   */
  fetchUsers: (workspaceId: string, funnelId: string, query: any) => Promise<void>;

  /**
   * Reset funnel insights
   */
  reset: () => void;
};

const useFunnelInsightsStore = create<State>((set) => ({
  steps: [],
  setSteps: (steps: IFunnelStep[]) => set({ steps }),
  insights: null,
  setInsights: (insights: any) => set({ insights }),
  meta: {},
  setMeta: (meta: any) => set({ meta }),
  fetching: false,
  setFetching: (fetching: boolean) => set({ fetching }),
  fetchingInsights: false,
  fetchingUsers: false,
  setFetchingUsers: (fetchingUsers: boolean) => set({ fetchingUsers }),
  setFetchingInsights: (fetchingInsights: boolean) => set({ fetchingInsights }),
  users: [],
  setUsers: (users: UsersList[]) => set({ users }),
  fetchFunnel: async (workspaceId: string, funnelId: string, query: any) => {
    set({ fetching: true });
    await new WebAnalyticsService()
      .funnel(workspaceId, funnelId, query)
      .then((res) => {
        if (res.data) {
          set({ steps: res.data.funnel_steps, meta: res.data.meta });
        } else {
          throw new Error("No funnel data found");
        }
      })
      .catch((err) => {
        set({ steps: [], meta: {} });
      })
      .finally(() => {
        set({ fetching: false });
      });
  },
  fetchFunnelInsights: async (workspaceId: string, funnelId: string, query: any) => {
    set({ fetchingInsights: true });
    await new WebAnalyticsService()
      .insights(workspaceId, funnelId, query)
      .then((res) => {
        if (res.data) {
          set({ insights: res.data });
        } else {
          throw new Error("No funnel insights data found");
        }
      })
      .catch((err) => {
        set({ insights: {} });
      })
      .finally(() => {
        set({ fetchingInsights: false });
      });
  },
  fetchUsers: async (workspaceId: string, funnelId: string, query: any) => {
    set({ fetchingUsers: true });
    await new WebAnalyticsService()
      .getUsersList(workspaceId, funnelId, query)
      .then((res) => {
        if (res.data) {
          set({ users: res.data });
        } else {
          throw new Error("No funnel users data found");
        }
      })
      .catch((err) => {
        set({ users: [] });
      })
      .finally(() => {
        set({ fetchingUsers: false });
      });
  },
  reset: () =>
    set({
      steps: [],
      meta: {},
      users: [],
      fetching: false,
      fetchingInsights: false,
      fetchingUsers: false
    })
}));

export default useFunnelInsightsStore;
