import { differenceInMinutes } from 'date-fns';
import { LEFT_MENU_TYPES, ORGANIZATION_STATES, PADDLE_SUBSCRIPTION_STATUS, PLAN_PRODUCT_TYPE, PUBLIC_ROUTES_LIST } from './Constants';
import { useCustomizeMenuStore } from '@/stores/useCustomizeMenuStore';


export const getPublicRedirectionRouteURL = (
  user: any,
  activeWorkspace: any = null, // protected route has these attributes
  redirectParam: string | null | undefined
  ): string => {
    let defaultRoute = '/env';
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hiddenMenuItemsList = useCustomizeMenuStore((state: any) => state.hiddenMenuItemsList)
  const isSettingsPage =
    window.location.pathname.includes('/settings/') ||
    window.location.pathname.includes('/account/');

  if (user && user.hasOwnProperty('email')) {
    if (!user.is_email_verified) {
      return '/email-verification';
    }
    if (!user.is_onboarding_completed) {
      return '/onboarding/create-workspace';
    }
    if (user.is_onboarding_completed) {
      console.log('Route URL check if there is an active workspace: ', activeWorkspace);

      if (activeWorkspace && activeWorkspace?.organization) {
        // billing hook if paused redirect to accounts billing page.
        if (activeWorkspace.organization?.subscriptions.length > 0) {
          if (
            activeWorkspace.organization.subscriptions[0].paddle_status ===
            PADDLE_SUBSCRIPTION_STATUS.DELETED
          ) {
            if (
              differenceInMinutes(
                new Date(
                  activeWorkspace.organization.subscriptions[0].cancellation_effective_at.replace(
                    ' ',
                    'T'
                  )
                ),
                new Date()
              ) < 5
            ) {
              return `/env/${activeWorkspace.identifier}/account/billing`;
            }
          }
          if (
            activeWorkspace.organization.subscriptions[0].paddle_status ===
            PADDLE_SUBSCRIPTION_STATUS.PAUSED
          ) {
            if (
              differenceInMinutes(
                new Date(activeWorkspace.organization.subscriptions[0].paused_from),
                new Date()
              ) < 5
            ) {
              return `/env/${activeWorkspace.identifier}/account/billing`;
            }
          }
        }
        // Trial plan users
        if (
          activeWorkspace.organization?.plan?.name?.endsWith('-trial') &&
          activeWorkspace.organization.is_trial_expired === true &&
          !isSettingsPage
        ) {
          if (!window.location.pathname.includes('/account/billing')) {
            return `/env/${activeWorkspace.identifier}/account/billing`;
          }
        }

        // QUOTA exceeded
        if (
          activeWorkspace.organization.state === ORGANIZATION_STATES.QUOTA_EXCEEDED &&
          !isSettingsPage
        ) {
          if (!window.location.pathname.includes('/account/billing')) {
            return `/env/${activeWorkspace.identifier}/account/billing`;
          }
        }
      }

      if (activeWorkspace && activeWorkspace.hasOwnProperty('is_setup_completed')) {
        console.log('Route URL workspace setup completed: ', activeWorkspace.is_setup_completed);
        if (!activeWorkspace.is_setup_completed) {
          return '/onboarding/tracking-pixel';
        }

        // Redirecting based on the redirect parameters.

        if (redirectParam && redirectParam.length > 2) {
          return redirectParam;
        }

        if (activeWorkspace.is_setup_completed) {
          const isWebPlanType = activeWorkspace.organization.plan.plan_type === PLAN_PRODUCT_TYPE.WEB
          const redirectPath = isWebPlanType ? "/web/statistics" : !hiddenMenuItemsList.includes(LEFT_MENU_TYPES.WebAnalytics) ? "/web/statistics" : "/insights/dashboard/overview";
          return `${defaultRoute}/${activeWorkspace.identifier}${redirectPath}`;
        }
      } else {
        console.log('Workspaces: /workspaces route.');
        // the user does not have any active workspace.

        // if it is a join workspace page, allow them to join workspace they are added into.
        if (redirectParam && redirectParam.includes('join-workspace')) {
          return redirectParam;
        }

        // corner case: The user can be added to the workspace but later on removed from the workspace.
        // We'll redirect them to the workspaces page.
        // Redirecting based on the redirect parameters.

        return '/workspaces';
      }

      return defaultRoute;
    }
  }
  // If the user is not logged in, redirect path will be set to login.
  return '/login';
};

export const shouldRedirectToLoginRoute = (): void => {
  let redirectToLogin = true;
  PUBLIC_ROUTES_LIST.forEach((publicRoute) => {
    console.debug(
      `shouldRedirectToLoginRoute -> Path name: ${window.location.pathname}, Public route: ${publicRoute}`
    );
    if (window.location.pathname.includes(publicRoute)) {
      redirectToLogin = false;
      console.debug(
        `shouldRedirectToLoginRoute -> Redirect to login is restricted because of Public route: ${publicRoute}`
      );
    }
  });
  if (redirectToLogin) {
    if (!window.location.pathname.includes('/login')) {
      console.debug(
        `shouldRedirectToLoginRoute -> URL path name is not login, therefore redirecting to Login.`
      );
      // window.location.href = "http://localhost:3000/login";
      const baseURL = process.env.REACT_APP_WEB_APP_URL;
      window.location.href =
        `${baseURL}/login` +
        (window.location.pathname.length > 3 ? `?redirect=${window.location.pathname}` : '');
    }
  }
};
