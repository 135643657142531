// @ts-ignore
import { InsightTemplateType } from "@/types/types.d"
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ReactTooltip from "react-tooltip"

interface IEditInsight {
  setShowInsightSetupModal: (value: boolean) => void
}

export const EditInsight = ({ 
  setShowInsightSetupModal,
}: IEditInsight) => {
  return (
    <>
      <FontAwesomeIcon
        data-tip="Edit Insight Setup"
        data-for="insight-edit-tooltip"
        icon={regular("pen-to-square")}
        className="ml-2 cursor-pointer hover:opacity-75 transition-all ease-in-out focus:outline-none"
        onClick={() => { setShowInsightSetupModal(true) }}
      ></FontAwesomeIcon>
      <ReactTooltip id={"insight-edit-tooltip"} place="right" type="dark" effect="solid" />
    </>
  )
}
