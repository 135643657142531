import { useContext, useEffect, useState } from "react";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceMiscService } from "@/lib/services/WorkspaceMiscService";
import { toast } from "react-toastify";
import { Box, Center, SegmentedControl, Title, Text } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";

export const MiscellaneousWorkspaceReportingInsightsPreference = () => {
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [selected, setSelected] = useState(activeWorkspace.reporting_insights_preference);
  const { isStarterPlan } = useWorkspaceUtilityHook();

  const handleOnChange = (value: any) => {
    new WorkspaceMiscService()
      .reportingInsightsPreference(activeWorkspace.id, value)
      .then((res) => {
        if (res.data) {
          activeWorkspace.reporting_insights_preference = res.data.data;
          setActiveWorkspace(activeWorkspace);
          toast.success(`Your default reporting insights preference has been set to ${value}`);
          setSelected(value);
        }
      })
      .catch((err) => err);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Title order={4} fw={600} mb="md">
        Reporting preference for SaaS product insights
      </Title>
      <Text mb="md">
        Choose your default reporting preference for SaaS product insights. e.g User level or
        company level insights.
      </Text>
      <SegmentedControl
        w={300}
        value={selected}
        size="xs"
        onChange={(value) => {
          if (isStarterPlan) {
            toast.error("Please upgrade your plan to Premium to access this feature.");
            return;
          }

          handleOnChange(value);
        }}
        data={[
          {
            value: "user",
            label: (
              <Center>
                <FontAwesomeIcon
                  //   className=" text-gray-900"
                  icon={regular("user")}></FontAwesomeIcon>
                <Box ml={8}>Users</Box>
              </Center>
            )
          },
          {
            value: "company",
            label: (
              <Center className={isStarterPlan ? "opacity-50" : ""}>
                <FontAwesomeIcon
                  //   className=" text-gray-900"
                  icon={regular("building")}></FontAwesomeIcon>
                <Box ml={8}>Companies</Box>
                {isStarterPlan && (
                  <FontAwesomeIcon
                    className="ml-2"
                    //   className=" text-gray-900"
                    icon={regular("lock")}></FontAwesomeIcon>
                )}
              </Center>
            )
          }
        ]}
      />
    </>
  );
};
