import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks
} from "date-fns";
import { useState } from "react";

interface IFeatureAdoptionDateMenu {
  onChange: (fromDate: string, toDate: string) => void;
}

export const FeatureAdoptionDateMenu = ({ onChange }: IFeatureAdoptionDateMenu) => {
  const { colorScheme } = useMantineColorScheme();
  // dropdown menu state
  const [opened, setOpened] = useState(false);
  // State to track the active menu item
  const [activeItem, setActiveItem] = useState("last-1-month");

  const handleItemClick = (value: string) => {
    setActiveItem(value);
    // Current Date
    const currentDate = new Date();
    switch (value) {
      case "last-2-weeks":
        let endDate2Weeks = currentDate;
        let startDate2Weeks = startOfWeek(subWeeks(currentDate, 2));
        let formattedStartDate2Weeks = format(startDate2Weeks, "yyyy-MM-dd");
        let formattedEndDate2Weeks = format(endDate2Weeks, "yyyy-MM-dd");
        onChange(formattedStartDate2Weeks, formattedEndDate2Weeks);
        return;

      case "last-3-weeks":
        let endDate3Weeks = currentDate;
        let startDate3Weeks = startOfWeek(subWeeks(currentDate, 3));
        let formattedStartDate3Weeks = format(startDate3Weeks, "yyyy-MM-dd");
        let formattedEndDate3Weeks = format(endDate3Weeks, "yyyy-MM-dd");
        onChange(formattedStartDate3Weeks, formattedEndDate3Weeks);
        return;

      case "last-1-month":
        let endDate1Month = currentDate;
        let startDate1Month = startOfMonth(subMonths(currentDate, 1));
        let formattedStartDate1Month = format(startDate1Month, "yyyy-MM-dd");
        let formattedEndDate1Month = format(endDate1Month, "yyyy-MM-dd");
        onChange(formattedStartDate1Month, formattedEndDate1Month);
        return;

      case "last-2-months":
        let endDate2Months = currentDate;
        let startDate2Months = startOfMonth(subMonths(currentDate, 2));
        let formattedStartDate2Months = format(startDate2Months, "yyyy-MM-dd");
        let formattedEndDate2Months = format(endDate2Months, "yyyy-MM-dd");
        onChange(formattedStartDate2Months, formattedEndDate2Months);
        return;

      case "last-3-months":
        let endDate3Months = currentDate;
        let startDate3Months = startOfMonth(subMonths(currentDate, 3));
        let formattedStartDate3Months = format(startDate3Months, "yyyy-MM-dd");
        let formattedEndDate3Months = format(endDate3Months, "yyyy-MM-dd");
        onChange(formattedStartDate3Months, formattedEndDate3Months);
        return;

      default:
        return;
    }
  };

  return (
    <Menu shadow="md" width={200} onChange={setOpened}>
      <Menu.Target>
        <Button
          tt="capitalize"
          size="xs"
          className="border border-solid border-gray-200 !font-medium capitalize"
          color={colorScheme === "dark" ? "dark.2" : "gray.6"}
          variant="outline"
          leftSection={<FontAwesomeIcon icon={regular("list-dropdown")} />}
          rightSection={
            <FontAwesomeIcon
              icon={solid("angle-up")}
              className={`transition-all ease-in-out transform rotate-180 ${
                opened ? "rotate-0" : ""
              }`}
            />
          }
          data-cy="date-feature-adoption-btn">
          {activeItem.split("-").join(" ")}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={() => handleItemClick("last-2-weeks")}>Last 2 weeks</Menu.Item>
        <Menu.Item onClick={() => handleItemClick("last-3-weeks")}>Last 3 weeks</Menu.Item>
        <Menu.Item onClick={() => handleItemClick("last-1-month")}>Last 1 month</Menu.Item>
        <Menu.Item onClick={() => handleItemClick("last-2-months")}>Last 2 months</Menu.Item>
        <Menu.Item onClick={() => handleItemClick("last-3-months")}>Last 3 months</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
