/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { numberToCommas, numberToUnit } from "@/lib/utils/StringUtility";
import React from "react";
import { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import { SummaryComparisonCard } from "../SummaryComparisonCard";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import ContentLoader from "react-content-loader";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
};

const SessionComparisonCard = ({
  activeCard,
  setActiveCard,
  comparisonTopBarCardValues,
  loading,
  query
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const getSessionComparisonCardValue = () => {
    let value = comparisonTopBarCardValues.sessions;
    return value < 100000 ? numberToCommas(value) : numberToUnit(value);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="sessions-card">
      {loading === "loaded" ? (
        <SummaryComparisonCard
          card="sessions"
          activeCard={activeCard}
          onClick={() => setActiveCard("sessions")}
          uniquekey={"sessions"}
          loading={loading}
          comparisonValue={getSessionComparisonCardValue()}
          comparisonTooltip={`${numberToCommas(comparisonTopBarCardValues.sessions)} sessions`}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const SessionComparisonCardMemoized = React.memo(SessionComparisonCard);
export { SessionComparisonCardMemoized as SessionComparisonCard };
