import { AppLifecycleContext } from "@/lib/contexts/AppLifecycleContext";
import { APIUserService } from "@/lib/services/AuthService";
import { authLocalStorageUpdate } from "@/lib/utils/Storage";
import { ThirdPartyTracking } from "@/lib/utils/ThirdPartyTracking";
import { PasswordStrength } from "@/ui/components/App/PasswordStrength/PasswordStrength";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";

import g2Img from "@assets/images/icons/g2.svg";
import capterraImg from "@assets/images/icons/capterra.svg";

import logoBlackSvg from "@assets/images/logos/logo-black-usermaven.svg";
import logoWhiteSvg from "@assets/images/logos/logo-white-usermaven.svg";
import {
  Anchor,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Group,
  Image,
  Input,
  PasswordInput,
  Rating,
  Select,
  Stack,
  Text,
  Title,
  useMantineColorScheme,
  useMantineTheme,
  Paper
} from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";
import Cookies from "js-cookie";
import { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { StringParam, useQueryParam } from "use-query-params";
import { RegisterTestimonials } from "./Components/RegisterTestimonials";
import { TrustedCompanies } from "./Components/TrustedCompanies";
import { useMediaQuery } from "@mantine/hooks";
import { MOBILE_BREAKPOINT_QUERY } from "@/lib/utils/Constants";
import "@mantine/carousel/styles.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
interface IFormInputs {
  first_name: string;
  last_name: string;
  password: string;
  email: string;
  company: string;
  company_type: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Register = (props: any) => {
  const { colorScheme } = useMantineColorScheme();
  const [emailQueryParam] = useQueryParam("email", StringParam);
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  // eslint-disable-next-line
  const { user, setUser } = useContext(AppLifecycleContext)!;
  const [loadingState, setLoadingState] = useState("idle");

  // form hooks from react-hook-form
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm<IFormInputs>({
    defaultValues: {
      email: emailQueryParam || ""
    }
  });

  // react-router-dom hook to navigate to different routes
  const navigate = useNavigate();
  const query = useQuery();
  const plan = query.get("plan") || "premium-trial";
  useEffect(() => {
    document.title = "Register | Usermaven";
  }, []);

  const onSubmit = (data: any) => {
    if (Cookies.get("_fprom_tid")) {
      data.fp_tid = Cookies.get("_fprom_tid");
    }
    data.plan = plan;

    const userService = new APIUserService();
    try {
      setLoadingState("loading");
      userService
        .register(data)
        .then((res) => {
          setLoadingState("success");
          setUser(res.data);
          authLocalStorageUpdate(res.data.access_token, res.data.refresh_token);
          const TPService = new ThirdPartyTracking(res.data, null);
          TPService.identify();
          TPService.track("signed_up");
          TPService._firstPromoter();
          navigate(`/email-verification`);
        })
        .catch((err) => {
          setLoadingState("failure");

          // 401 case to be handled here.
          // err.response.status === 401 -> unauthorized.

          // for handling errors if they have the detail property available with string type.
          if (
            err.response.data.hasOwnProperty("detail") &&
            typeof err.response.data.detail === "string"
          ) {
            toast.error(err.response.data.detail);
          }

          console.debug(`Error code is ${err.response.status}`, err.response);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const watchPassword = watch("password", "");
  const autoplay = useRef(Autoplay({ delay: 10000 }));
  const theme = useGlobalMantineTheme();
  return (
    <Container size="xl">
      <Box py={16} pt={32}>
        <Flex align={"center"}>
          <Box flex={"1"}>
            <img
              width={150}
              className="auth-logo"
              src={colorScheme === "dark" ? logoWhiteSvg : logoBlackSvg}
              alt="Usermaven"
            />
          </Box>
          <Group>
            <Button
              onClick={() => window.open("https://usermaven.com/book-a-demo", "_blank")}
              color={colorScheme === "dark" ? "dark.3" : "gray.9"}
              variant="outline">
              Book a demo
            </Button>
            {!isMobileView && <Button onClick={() => navigate("/login")}>Sign in</Button>}
          </Group>
        </Flex>
      </Box>
      <Paper>
        <Grid
          py={isMobileView ? 16 : 48}
          my={16}
          px={isMobileView ? 16 : 64}
          style={{
            borderRadius: "1rem",
            background:
              colorScheme === "dark"
                ? theme.colors.dark[6]
                : "radial-gradient(circle at center, rgba(234, 205, 255, 0.30) 4%,rgba(218, 217, 255, 0.30)  55%,   rgba(176, 222, 255, 0.30) 100%)"
          }}>
          <Grid.Col span={isMobileView ? 12 : 7}>
            <Stack justify="space-between" h={"100%"}>
              <Box>
                <Title
                  ta={isMobileView ? "center" : undefined}
                  c={colorScheme === "dark" ? "dark.1" : undefined}
                  order={isMobileView ? 3 : 1}
                  pb={isMobileView ? 16 : undefined}>
                  Get started with Usermaven.
                </Title>
                {/* We do not show badges in case of mobile view */}
                {!isMobileView && (
                  <>
                    <Text
                      c={colorScheme === "dark" ? "dark.2" : undefined}
                      pr={64}
                      py={32}
                      size="lg">
                      {" "}
                      Make informed growth decisions with simple yet powerful AI analytics tool.
                      Easy setup, codeless event tracking and end-to-end customer journeys.{" "}
                    </Text>

                    <Flex justify={"space-between"} pr={128}>
                      <Group>
                        <Image w={40.77} h={40.77} src={g2Img} />
                        <Flex direction={"column"}>
                          <Rating color="orange.5" fractions={5} defaultValue={4.9} value={4.9} />
                          <Anchor
                            target="_blank"
                            href="https://www.g2.com/products/usermaven/reviews">
                            <Text
                              pt={4}
                              size="xs"
                              c={colorScheme === "dark" ? "dark.2" : undefined}>
                              Based on <span className="font-semibold">60+ Reviews</span>
                            </Text>
                          </Anchor>
                        </Flex>
                      </Group>
                      <Group>
                        <Image w={37.85} h={39.07} src={capterraImg} />
                        <Flex direction={"column"}>
                          <Rating color="orange.5" fractions={5} defaultValue={4.8} value={4.8} />
                          <Anchor
                            target="_blank"
                            href="https://www.capterra.com/p/276941/Usermaven/">
                            <Text
                              pt={4}
                              size="xs"
                              c={colorScheme === "dark" ? "dark.2" : undefined}>
                              Based on <span className="font-semibold">50+ Reviews</span>
                            </Text>
                          </Anchor>
                        </Flex>
                      </Group>
                    </Flex>
                  </>
                )}
              </Box>
              <RegisterTestimonials />
            </Stack>
          </Grid.Col>
          <Grid.Col span={isMobileView ? 12 : 5}>
            <Box
              p={24}
              style={{
                borderRadius: "1rem",
                backgroundColor: colorScheme === "dark" ? theme.colors.dark[9] : "#fff"
              }}>
              {/* <Title order={4} ta="center" mb={"sm"}>
              Create Account
            </Title>
            <p className="justify-content-center pb-4 display-flex ">
              Already have Usermaven account?
              <Link className="pl-1 primary-link-color" to="/login">
                Sign in
              </Link>
            </p> */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-container--box--form pb-2">
                  <Grid>
                    <Grid.Col span={6}>
                      <div className="auth-input-group--row">
                        <div className="auth-input-group auth-input-group--row--group">
                          <Controller
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error }
                            }) => (
                              <Input.Wrapper size="xs" id={"firstName"} label="First Name" required>
                                <Input
                                  mt={4}
                                  value={value}
                                  onChange={onChange}
                                  placeholder="Carl"
                                  error={(invalid || error) === true}
                                  data-cy="signup-first-name"
                                />
                              </Input.Wrapper>
                            )}
                            name="first_name"
                            control={control}
                            defaultValue=""
                            rules={{ required: true, maxLength: 24 }}
                          />

                          {errors.first_name?.type === "required" && (
                            <ValidationLabel
                              validationType="error"
                              text="First name is required"
                              size="small"
                              customClasses="pt-2"></ValidationLabel>
                          )}
                          {errors.first_name?.type === "maxLength" && (
                            <ValidationLabel
                              validationType="error"
                              text="First name should be less than 24 characters."
                              size="small"
                              customClasses="pt-2"></ValidationLabel>
                          )}
                        </div>
                      </div>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <div className="auth-input-group--row">
                        <div className="auth-input-group auth-input-group--row--group">
                          <div className="auth-input-group auth-input-group--row--group">
                            <Controller
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error }
                              }) => (
                                <Input.Wrapper size="xs" id={"lastName"} label="Last Name" required>
                                  <Input
                                    mt={4}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Frederick"
                                    error={(invalid || error) === true}
                                    data-cy="signup-last-name"
                                  />
                                </Input.Wrapper>
                              )}
                              name="last_name"
                              control={control}
                              defaultValue=""
                              rules={{ required: true, maxLength: 24 }}
                            />

                            {errors.last_name?.type === "required" && (
                              <ValidationLabel
                                validationType="error"
                                text="Last name is required"
                                size="small"
                                customClasses="pt-2"></ValidationLabel>
                            )}
                            {errors.last_name?.type === "maxLength" && (
                              <ValidationLabel
                                validationType="error"
                                text="Last name should be less than 24 characters."
                                size="small"
                                customClasses="pt-2"></ValidationLabel>
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid.Col>
                  </Grid>

                  <div className="auth-input-group pt-4 pb-2">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error }
                      }) => (
                        <Input.Wrapper size="xs" id={"workEmail"} label="Work Email" required>
                          <Input
                            mt={4}
                            value={value}
                            onChange={onChange}
                            placeholder="carl@usermaven.com"
                            error={(invalid || error) === true}
                            data-cy="signup-email"
                          />
                        </Input.Wrapper>
                      )}
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{ required: true, pattern: /^\S+@\S+$/ }}
                    />

                    {errors.email?.type === "required" && (
                      <ValidationLabel
                        validationType="error"
                        text="Email is required"
                        size="small"
                        customClasses="pt-2"></ValidationLabel>
                    )}
                    {errors.email?.type === "pattern" && (
                      <ValidationLabel
                        validationType="error"
                        text="Please enter a valid email address"
                        size="small"
                        customClasses="pt-2"></ValidationLabel>
                    )}
                  </div>
                  <div className="auth-input-group pt-4 pb-2 relative">
                    <div className="flex flex-row-reverse absolute right-0 top-8">
                      <PasswordStrength password={watchPassword} />
                    </div>

                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error }
                      }) => (
                        <Input.Wrapper id={"password"} size="xs" label="Password" required>
                          <PasswordInput
                            mt={4}
                            placeholder="*********"
                            onChange={onChange}
                            withAsterisk
                            value={value}
                            data-cy="signup-password"
                          />
                        </Input.Wrapper>
                      )}
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{ required: true, minLength: 8 }}
                    />

                    {errors.password?.type === "required" && (
                      <ValidationLabel
                        validationType="error"
                        text="Password is required"
                        size="small"
                        customClasses="pt-2"></ValidationLabel>
                    )}

                    {errors.password?.type === "minLength" && (
                      <ValidationLabel
                        validationType="error"
                        text="Password must be greater than 8 characters."
                        size="small"
                        customClasses="pt-2"></ValidationLabel>
                    )}
                  </div>
                  <div className="auth-input-group pt-4 pb-2">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error }
                      }) => (
                        <Input.Wrapper size="xs" id={"company"} label="Company" required>
                          <Input
                            mt={4}
                            value={value}
                            onChange={onChange}
                            placeholder="e.g your company name"
                            error={(invalid || error) === true}
                            data-cy="signup-company"
                          />
                        </Input.Wrapper>
                      )}
                      name="company"
                      control={control}
                      defaultValue=""
                      rules={{ required: true, maxLength: 32 }}
                    />

                    {errors.company?.type === "required" && (
                      <ValidationLabel
                        validationType="error"
                        text="Company is required"
                        size="small"
                        customClasses="pt-2"></ValidationLabel>
                    )}
                    {errors.company?.type === "maxLength" && (
                      <ValidationLabel
                        validationType="error"
                        text="Company should be less than 32 characters."
                        size="small"
                        customClasses="pt-2"></ValidationLabel>
                    )}
                  </div>
                  <div className="auth-input-group pt-4 pb-2">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error }
                      }) => (
                        <Input.Wrapper
                          size="xs"
                          id={"company_type"}
                          label="What kind of business you'd like to track with Usermaven?"
                          required>
                          <Select
                            mt={4}
                            value={value}
                            onChange={onChange}
                            placeholder="Pick one"
                            data={[
                              {
                                value: "b2b_saas",
                                label: "B2B SaaS"
                              },
                              {
                                value: "b2c_saas",
                                label: "B2C SaaS"
                              },
                              {
                                value: "ecommerce",
                                label: "Ecommerce"
                              },
                              {
                                value: "agency",
                                label: "Agency"
                              },
                              {
                                value: "marketplace",
                                label: "Marketplace"
                              },
                              {
                                value: "service_business",
                                label: "Service business"
                              },
                              {
                                value: "other",
                                label: "Other"
                              }
                            ]}
                            error={(invalid || error) === true}
                            data-cy="signup-business-type"
                          />
                        </Input.Wrapper>
                      )}
                      name="company_type"
                      control={control}
                      defaultValue=""
                      rules={{ required: true, maxLength: 32 }}
                    />
                  </div>

                  <div className="auth-submit-form w-full">
                    <Button
                      w={"100%"}
                      mt={"sm"}
                      size="md"
                      // variant="gradient"
                      loading={loadingState === "loading"}
                      disabled={loadingState === "loading"}
                      type="submit"
                      data-cy="signup-submit">
                      Create Account
                    </Button>
                  </div>

                  <div className="auth-input-group pt-4 pb-2">
                    <Text ta="center" c={colorScheme === "dark" ? "dark.2" : undefined}>
                      Already have an account?{" "}
                      <Anchor fw={600} variant="gradient" href={"/login"}>
                        Login
                      </Anchor>
                    </Text>
                  </div>
                </div>
              </form>
            </Box>
            <Text
              c={colorScheme === "dark" ? "dark.2" : undefined}
              pt={"sm"}
              px={64}
              className="auth-input-group--register-tos"
              ta="center"
              size="xs">
              By clicking “Create Account” you agree to Usermaven’s{" "}
              <a target="_blank" rel="noreferrer" href="https://usermaven.com/terms-of-service">
                terms &amp; conditions
              </a>{" "}
              &amp;{" "}
              <a target="_blank" rel="noreferrer" href="https://usermaven.com/privacy">
                privacy policy
              </a>
              .
            </Text>
          </Grid.Col>
        </Grid>
      </Paper>

      <TrustedCompanies />
    </Container>
  );
};
