// @ts-ignore
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Loader,
  Text,
  Title,
  Tooltip
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import profileIcon from "@assets/images/icons/navigation/profile.svg";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceMemberService } from "@/lib/services/WorkspaceMemberService";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import {
  PLAN_TYPES,
  TOAST_MESSAGES,
  WORKSPACE_MEMBER_ROLES,
  WORKSPACE_MEMBER_STATE
} from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { EditWorkspaceMemberType, WorkspaceMemberType } from "types/types.d";
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
import { TeamMemberInviteModal } from "@/ui/components/App/Modals/TeamMemberInviteModal";

const TeamMembers = (props: any) => {
  const { activeWorkspace, user } = useContext(AppLifecycleContext);
  const { getActiveWorkspaceMemberRole } = useWorkspaceUtilityHook();
  const [isLoading, setIsLoading] = useState("idle");
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditTeamMember, setIsEditTeamMember] = useState(false);
  const { hasRolesNotWith } = useWorkspaceUtilityHook();

  const [editMemberDetails, setEditMemberDetails] = useState<EditWorkspaceMemberType>({
    id: undefined,
    email: undefined,
    role: undefined
  });

  const [teamMembers, setTeamMembers] = useState<WorkspaceMemberType[]>([]);
  /**
   * Get list of team members to be displayed in a table.
   */
  const getTeamMembersList = async () => {
    const workspace = new WorkspaceService();
    setIsLoading("loading");
    await workspace
      .workspaceMembers(activeWorkspace.id)
      .then((res) => {
        console.log(res);
        setTeamMembers(res.data);
      })
      .catch((err) => {});
    setIsLoading("loaded");
    ReactTooltip.rebuild();
  };
  useEffect(() => {
    document.title = "Team Members | Usermaven";
    getTeamMembersList();
  }, []);

  /**
   * Add Team member dialog. Set the edit team member modal and opens the modal
   */
  const addTeamMemberDialog = () => {
    setIsEditTeamMember(false);
    setEditMemberDetails({
      id: undefined,
      email: undefined,
      role: undefined
    });
    setModalOpen(true);
  };

  /**
   * Edit team member dialog, set the team member edit to true and opens the modal.
   * @param member Workspace member
   */
  const editTeamMemberDialog = (member: any) => {
    console.log(member);
    setIsEditTeamMember(true);
    setModalOpen(true);
    setEditMemberDetails({
      id: member.id,
      email: member?.user.email,
      role: member.role?.name
    });
  };
  const addTeamMemberToList = (member: any) => {
    setTeamMembers([...teamMembers, member]);
  };
  const updateTeamMemberInList = (member: any) => {
    const memberIndex = teamMembers.findIndex((memberItem) => memberItem.id === member.id);
    teamMembers.splice(memberIndex, 1, member);
    setTeamMembers(teamMembers);
  };
  const removeTeamMember = (member: any) => {
    console.log("Team member along with list of member", member, teamMembers);
    const wsMemberService = new WorkspaceMemberService();
    wsMemberService
      .delete(member.id)
      .then((res) => {
        let membersList = teamMembers.filter((memberItem) => memberItem.id !== member.id);
        setTeamMembers(membersList);
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resendTeamMemberInvitation = (memberId: string, memberEmail: string) => {
    console.log(`Team member user id for resend invitation is ${memberId}`);
    const wsMemberService = new WorkspaceMemberService();
    wsMemberService
      .resendInvite(memberId)
      .then((res) => {
        if (res.data) {
          toast.success(`${TOAST_MESSAGES.WORKSPACE_RESEND_INVITATION} ${memberEmail}.`);
        }
      })
      .catch((err) => {});
  };
  const removeTeamMemberPrompt = (member: any) => {
    openConfirmModal({
      title: "Delete team member",
      children: <Text size="sm">Are you sure you want to delete your team member?</Text>,
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => removeTeamMember(member)
    });
  };
  return (
    <>
      <TeamMemberInviteModal
        open={modalOpen}
        isEdit={isEditTeamMember}
        workspaceId={activeWorkspace.id}
        editMemberDetails={editMemberDetails}
        closeModal={() => {
          setModalOpen(false);
          return;
        }}
        addTeamMember={addTeamMemberToList}
        updateTeamMember={updateTeamMemberInList}></TeamMemberInviteModal>
      <>
        <>
          <Box>
            <Flex align={"center"} mb="md" w={"100%"}>
              <Flex direction={"column"} flex={1}>
                <Title order={4} fw={600} mb="md" mt="md">
                  People with access to this workspace
                </Title>
                <Text pr="sm">
                  The following people will have full access to this Usermaven workspace — learn
                  about shared user permissions.
                </Text>
              </Flex>

              {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                <Flex direction={"row-reverse"}>
                  <Button size="sm" onClick={() => addTeamMemberDialog()}>
                    Add Team Member
                  </Button>
                </Flex>
              )}
            </Flex>
          </Box>
          <Divider />
          <div className="um-settings-table">
            {isLoading === "loading" && (
              <Center>
                <Loader size={"xs"} />
              </Center>
            )}

            {isLoading === "loaded" && teamMembers.length > 0 && (
              <Grid>
                {teamMembers.map((member) => (
                  <>
                    <Grid.Col span={5}>
                      <Flex align={"center"} pb="sm">
                        {member?.user.profile_image ? (
                          <img
                            src={`${member.user?.profile_image}-/scale_crop/45x45/smart/`}
                            alt=""
                          />
                        ) : (
                          <img src={profileIcon} alt="" />
                        )}

                        <Flex direction={"column"} ml={"sm"}>
                          <Text fw={500}>{member.user.full_name}</Text>
                          <Text c="dimmed">{member.user.email}</Text>
                        </Flex>
                        <>
                          {member.role.name !== WORKSPACE_MEMBER_ROLES.OWNER && (
                            <>
                              <Badge
                                ml={"md"}
                                variant="outline"
                                radius={"sm"}
                                color={
                                  member.state === WORKSPACE_MEMBER_STATE.INVITED
                                    ? "yellow.7"
                                    : "green.7"
                                }>
                                {member.state}
                              </Badge>
                            </>
                          )}
                        </>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Text tt={"capitalize"} fw={500} pt={"sm"}>
                        {member.role.name}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Flex align={"center"} pt="sm">
                        {member.role.name !== WORKSPACE_MEMBER_ROLES.OWNER && (
                          <>
                            {member.state === WORKSPACE_MEMBER_STATE.INVITED ? (
                              <>
                                <Tooltip label="Resend invite email.">
                                  <ActionIcon
                                    mr={8}
                                    variant="outline"
                                    color="gray.6"
                                    onClick={() => {
                                      resendTeamMemberInvitation(member.id, member.user.email);
                                    }}>
                                    <FontAwesomeIcon
                                      icon={regular("paper-plane-top")}
                                      className="  transform rotate-inverse-45"
                                    />
                                  </ActionIcon>
                                </Tooltip>

                                <CopyToClipboard
                                  text={`${process.env.REACT_APP_WEB_APP_URL}/join-workspace/${member.id}`}
                                  onCopy={() =>
                                    toast.success(TOAST_MESSAGES.WORKSPACE_INVITE_LINK_COPIED)
                                  }>
                                  <Tooltip label="Copy invite link">
                                    <ActionIcon color="gray.6" variant="outline" mr={8}>
                                      <FontAwesomeIcon icon={regular("copy")} className=" " />
                                    </ActionIcon>
                                  </Tooltip>
                                </CopyToClipboard>

                                <Tooltip label="Delete team member">
                                  <ActionIcon
                                    mr={8}
                                    variant="outline"
                                    color={"red"}
                                    onClick={() => {
                                      removeTeamMemberPrompt(member);
                                    }}>
                                    <FontAwesomeIcon
                                      icon={regular("trash")}
                                      className="transform "
                                    />
                                  </ActionIcon>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                {user.id !== member.user_id && (
                                  <>
                                    <Tooltip label="Edit team member role">
                                      <ActionIcon
                                        color="gray.6"
                                        mr={8}
                                        variant="outline"
                                        onClick={() => {
                                          editTeamMemberDialog(member);
                                        }}>
                                        <FontAwesomeIcon icon={regular("pencil")} className=" " />
                                      </ActionIcon>
                                    </Tooltip>
                                    <Tooltip label="Delete team member">
                                      <ActionIcon
                                        mr={8}
                                        variant="outline"
                                        color={"red"}
                                        onClick={() => {
                                          removeTeamMemberPrompt(member);
                                        }}>
                                        <FontAwesomeIcon icon={regular("trash")} />
                                      </ActionIcon>
                                    </Tooltip>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Flex>
                    </Grid.Col>
                  </>
                ))}
              </Grid>
            )}
          </div>
        </>
      </>
    </>
  );
};
export default TeamMembers;
