type AppEnvironmentType = "development" | "production";

export interface ProcessEnv {
  [key: string]: string | undefined;
}

export type RawConfigObject = {
  env: ProcessEnv;
  keys: {
    intercom?: string;
  };
};

function parseJson(envVar: any, defaultValue: any) {
  if (envVar) {
    try {
      return JSON.parse(envVar);
    } catch (e: any) {
      throw new Error(
        `env vari.able suppose to contain JSON, but the content (${envVar}) is not parsable: ${e?.message}`
      );
    }
  }
  return defaultValue;
}

function getRawApplicationConfig(): RawConfigObject {
  return {
    env: process.env || {},
    keys: parseJson(process.env.INTEGRATION_SERVICE_KEYS, {}),
  };
}
export class ApplicationConfiguration {
  private readonly _rawConfig: RawConfigObject;
  /**
   * One of the following: development, production
   */
  private readonly _appEnvironment: AppEnvironmentType;
  private readonly _backendAPIBase: string;

  constructor() {
    // NOTE: Need to improve this class as we followed Jitsu for inspiration, but we will go with our own.

    this._rawConfig = getRawApplicationConfig();
    this._appEnvironment = (
      this._rawConfig.env.NODE_ENV || "production"
    ).toLowerCase() as AppEnvironmentType;
    // this._backendAPIBase = this._rawConfig.env.API_URL || "http://localhost";
    this._backendAPIBase = process.env.REACT_APP_API_URL || "http://localhost";
    // Generating a hash using build, release version and timestamp.
    console.debug(
      `App initialized. Backend API: ${this._backendAPIBase}.  Env: ${this._appEnvironment}.`
    );
  }

  get appEnvironment() {
    return this._appEnvironment;
  }

  get rawConfig(): RawConfigObject {
    return this._rawConfig;
  }
  get backendAPIBase(): string {
    return this._backendAPIBase;
  }
}
