import ContentLoader from "react-content-loader";
import {
  Box,
  Center,
  Flex,
  Group,
  Loader,
  Skeleton,
  Stack,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import classes from "./ChartsSekeltons.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
export const BarChartSkeleton = (props: any) => {
  const barHeights = [80, 100, 120, 150, 110]; // Heights of the bars in pixels

  return (
    <Center my={32}>
      <Loader type="bars" size={"sm"} color="gray" />
    </Center>
  );
};

export const StatsSkeleton = (props: any) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  return (
    <ContentLoader
      viewBox={`0 0 ${props.width ? props.width : 300} 50`}
      height={`${props.height ? props.height : 50}`}
      gradientRatio={colorScheme === "dark" ? 0.5 : 1.2}
      width={`${props.width ? props.width : 300}`}
      {...props}
      className={classes.skeleton}
      // backgroundColor={theme.colorScheme === "dark" ? theme.colors.dark[8] : "#f5f6f7"}
      // foregroundColor={theme.colorScheme === "dark" ? theme.colors.dark[5] : "#eeeeee"}
      style={{ margin: "1.5rem 0 0 0" }}>
      <rect x="0" y="0" rx="0" ry="0" width="292" height="50" />
    </ContentLoader>
  );
};

export const BulletListSkeleton = (props: any): any => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <Box p="sm" mt="sm">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: number) => (
          <Flex align={"center"} key={item} mb={16}>
            <Skeleton radius="xl" height={16} width={16} mr={8} />
            <Skeleton radius="xl" height={10} width={"60%"} />
          </Flex>
        ))}
      </Box>
    </>
  );
};

export const SmallBulletListSkeleton = (props: any): any => {
  const theme = useGlobalMantineTheme();

  return (
    <ContentLoader
      viewBox="0 0 200 150"
      height={160}
      width={200}
      {...props}
      className={classes.skeleton}
      // backgroundColor={theme.colorScheme === "dark" ? theme.colors.dark[8] : "#f5f6f7"}
      // foregroundColor={theme.colorScheme === "dark" ? theme.colors.dark[5] : "#eeeeee"}
    >
      <circle cx="10" cy="20" r="8" />
      <rect x="25" y="15" rx="5" ry="5" width="330" height="10" />
      <circle cx="10" cy="50" r="8" />
      <rect x="25" y="45" rx="5" ry="5" width="330" height="10" />
      <circle cx="10" cy="80" r="8" />
      <rect x="25" y="75" rx="5" ry="5" width="330" height="10" />
      <circle cx="10" cy="110" r="8" />
      <rect x="25" y="105" rx="5" ry="5" width="330" height="10" />
      <circle cx="10" cy="140" r="8" />
      <rect x="25" y="135" rx="5" ry="5" width="330" height="10" />
    </ContentLoader>
  );
};
