import { Box, Flex, Table, rgba, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { ReactNode } from "react";
import { LoadingRow } from "../../App/WebAnalytics/DetailedView/LoadingRow";
import classes from "./DynoTable.module.css";
import clsx from "clsx";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
type Column = {
  header: ReactNode;
  accessor: string;
  maxWidth?: boolean | undefined;
  render?: (data: any) => JSX.Element | null;
};

type RowData = {
  [key: string]: any;
};

interface DynoTableProps {
  columns: Column[];
  data: RowData[];
  fontSize?: number;
  stripped?: boolean;
  highlightOnHover?: boolean;
  loading?: boolean;
}

const DynoTable = ({
  columns,
  data,
  fontSize = 13,
  highlightOnHover = false,
  stripped = false,
  loading = false
}: DynoTableProps) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  return (
    <Table
      striped={stripped}
      withRowBorders={false}
      highlightOnHover={highlightOnHover}
      className={clsx(
        classes.stripedRow,
        classes.thead,
        classes.tbody,
        classes.noPadding,
        classes.fixedWidth
      )}
      style={
        {
          // borderBottom: stripped ? "1px solid rgb(222, 226, 230)" : "none"
        }
      }
      verticalSpacing={0}
      horizontalSpacing={0}>
      <Table.Thead style={{}}>
        <Table.Tr>
          {columns.map((column, index) => (
            <Table.Th key={index} className={column.maxWidth ? classes.max : ""}>
              {column.header}
            </Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {loading ? (
          <LoadingRow colspan={columns.length} />
        ) : (
          data.map((item, rowIndex) => (
            <Table.Tr key={rowIndex}>
              {columns.map((column, colIndex) => {
                const cellData = item[column.accessor];
                return (
                  <Table.Td
                    key={`${rowIndex}-${colIndex}`}
                    className={clsx(
                      column.maxWidth ? classes.max : "",
                      column.maxWidth ? classes.noPadding : ""
                    )}>
                    {column.render ? column.render(cellData) : cellData}
                  </Table.Td>
                );
              })}
            </Table.Tr>
          ))
        )}
      </Table.Tbody>
    </Table>
  );
};

interface ProgressCellProps {
  value?: number;
  label?: any;
  setIsHovered?: (isHovered: boolean) => void;
  barColor?: string;
  rowSpacing?: string; // top, bottom, both i-e 1rem
}

const ProgressCell = ({
  value = 0,
  label,
  setIsHovered,
  barColor,
  rowSpacing
}: ProgressCellProps) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const barColorValue =
    colorScheme === "dark" ? rgba(theme.colors.dark[7], 1) : barColor ? barColor : "#f2f4f6";

  return (
    <div
      onMouseEnter={() => setIsHovered && setIsHovered(true)}
      onMouseLeave={() => setIsHovered && setIsHovered(false)}>
      <Flex align="center" pos="relative">
        <Box pos={"absolute"} w={"100%"} h={"100%"}>
          <Box
            style={{
              width: `${value}%`,
              height: "100%",
              backgroundColor: barColorValue
            }}></Box>
        </Box>

        <Box
          pos={"relative"}
          style={{
            padding: `${rowSpacing ? rowSpacing : "0.5rem"} 1.5rem`
          }}>
          {label}
        </Box>
      </Flex>
    </div>
  );
};

export { DynoTable, ProgressCell };
export type { DynoTableProps, Column, RowData };
