import useResponsiveness from "../../../hooks/useResponsiveness";
import {
  Badge,
  Box,
  Collapse,
  Flex,
  Image,
  Menu,
  Overlay,
  Space,
  Stack,
  Text,
  Tooltip,
  Transition,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { NavLink as NavLinkNative, NavLinkProps, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light, regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import * as React from "react";
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import AppLifecycleContext from "../../../lib/contexts/AppLifecycleContext";
import FrillWidget from "../../components/App/HeaderNavigation/FrillWidget";
import {
  ANALYZE_MENU_ITEMS,
  CONTACTS_HUB_MENU_ITEMS,
  LEFT_MENU_ITEMS,
  LEFT_MENU_TYPES,
  ORGANIZATION_STATES,
  PLAN_PRODUCT_TYPE,
  PRODUCT_INSIGHTS_MENU_ITEMS,
  WORKSPACE_MEMBER_ROLES
} from "../../../lib/utils/Constants";
import { useAppGuardStore } from "../../../stores/useAppGuardStore";
import { useCustomizeMenuStore } from "../../../stores/useCustomizeMenuStore";

import transparentLogo from "@assets/images/logos/transparent-logo-icon.png";
import logo from "@assets/images/logos/usermaven-dashboard-logo.svg";
import profileIcon from "../../../assets/images/icons/navigation/profile.svg";
import useWorkspaceUtilityHook from "../../../hooks/useWorkspaceUtilityHook";
import classes from "./NavMenu.module.css";
import clsx from "clsx";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
import { useHotkeys } from "@mantine/hooks";
// const useStyles = createStyles((theme) => ({
//   navItem: {
//     a: {
//       // padding: "10px 20px",
//       color: "inherit",
//       textDecoration: "none",
//       display: "flex",
//       alignItems: "center",
//       gap: theme.spacing.sm,
//       fontSize: "13px",
//       overflow: "hidden",

//       "&:hover": {
//         boxShadow: "inset 0 0 50px #0000000f"
//       }
//     },

//     svg: {
//       transition: "all 0.3s ease-in-out"
//     }
//   },

//   navItemInner: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     width: "100%",

//     "&>span": {
//       display: "flex",
//       alignItems: "center",
//       gap: theme.spacing.sm
//     },

//     ".menu-item-name": {
//       whiteSpace: "pre",
//       transition: "all 0.6s ease",
//       display: "flex",
//       alignItems: "center",
//       gap: theme.spacing.sm
//     },

//     ".menu-angle-icon": {
//       width: "20px",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "flex-end"
//     }
//   },

//   locked: {
//     opacity: 0.7,
//     pointerEvents: "none"
//   },

//   activeNavItem: {
//     backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#191849"
//   },

//   subNavItem: {
//     a: {
//       padding: "8px 20px 8px 46px",
//       overflow: "hidden"
//     }
//   }
// }));

export interface INavMenu {
  isMobileView?: boolean;
  workspaceDropdownComponent?: JSX.Element;
}

const NavMenu = ({ isMobileView = true, workspaceDropdownComponent }: INavMenu) => {
  const location = useLocation();
  const { user, activeWorkspace, isCollapsedLeftMenu, setIsCollapsedLeftMenu } =
    useContext(AppLifecycleContext);
  const { hasRolesNotWith, hasRolesWith } = useWorkspaceUtilityHook();

  const { isNavMenuOpened, toggleNavMenuOpened } = useResponsiveness();
  const theme = useGlobalMantineTheme();

  const { colorScheme, toggleColorScheme, setColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";
  const navigate = useNavigate();
  const [forceBilling] = useAppGuardStore((state) => [state.forceBilling]);

  const isBillingLocked = useMemo(() => {
    return (
      activeWorkspace.organization.state === ORGANIZATION_STATES.QUOTA_EXCEEDED || forceBilling
    );
  }, [forceBilling, activeWorkspace]);

  const isWebPlanType = activeWorkspace.organization.plan.plan_type === PLAN_PRODUCT_TYPE.WEB;
  const isWebProductPlanType =
    activeWorkspace.organization.plan.plan_type === PLAN_PRODUCT_TYPE.WEB_PRODUCT;
  const [fetchMenuItems, hiddenMenuItemsList] = useCustomizeMenuStore((state: any) => [
    state.fetchMenuItems,
    state.hiddenMenuItemsList
  ]);

  const isWorkspaceSettingsPage = location.pathname.includes("/settings/");
  const isEventsPage = location.pathname.includes("/events/");
  const isAccountSettingsPage =
    location.pathname.includes("/account/") &&
    !location.pathname.includes("/account/referral_program");
  const isCustomizeMenuPage = location.pathname.includes("/settings/customize_menu"); //  || location.pathname.includes("/account/")

  const logoImage = isCollapsedLeftMenu ? transparentLogo : logo;

  // Collapsable state for submenus e.g. Array(3) [false, false, false]
  const [isSubmenuOpened, setIsSubmenuOpened] = useState<boolean[]>(
    Array(LEFT_MENU_ITEMS.length).fill(false)
  );

  useEffect(() => {
    if (isCustomizeMenuPage) {
      // Expand all submenus on customize menu page
      toggleAllSubmenus(true);
    }
  }, [isCustomizeMenuPage]);

  useEffect(() => {
    if (isMobileView && isCollapsedLeftMenu) {
      setIsCollapsedLeftMenu(false);
    }
  }, [isMobileView]);

  /**
   * Helper function to collapse and expand all submenus
   */
  const toggleAllSubmenus = useCallback(
    (isOpened: boolean) => {
      setIsSubmenuOpened(Array(LEFT_MENU_ITEMS.length).fill(isOpened));
    },
    [isSubmenuOpened]
  );

  const filteredProductInsightsMenuList = PRODUCT_INSIGHTS_MENU_ITEMS.filter(
    (item) => !hiddenMenuItemsList.includes(item)
  ).map((item) => item.toLowerCase());
  const productInsightsMenuToNavigate =
    filteredProductInsightsMenuList.length > 0
      ? filteredProductInsightsMenuList[0].toLowerCase()
      : null;

  const filteredContactsHubMenuList = CONTACTS_HUB_MENU_ITEMS.filter(
    (item) => !hiddenMenuItemsList.includes(item)
  )
    // NOTE: This filter was removing the Contacts Hub main menu. I commented it out to fix the issue.
    // .filter((item) => !(isWebProductPlanType && item === LEFT_MENU_TYPES.Visitors)) // This filter will remove 'Visitors' from the list if isWebProductPlanType is true
    .map((item) => item.toLowerCase());
  const contactsHubMenuToNavigate =
    filteredContactsHubMenuList.length > 0 ? filteredContactsHubMenuList[0].toLowerCase() : null;

  const filteredAnalyzeMenuList = ANALYZE_MENU_ITEMS.filter(
    (item) => !hiddenMenuItemsList.includes(item)
  ).map((item) => item.toLowerCase());
  const analyzeMenuToNavigate =
    filteredAnalyzeMenuList.length > 0 ? filteredAnalyzeMenuList[0].toLowerCase() : null;

  /**
   * Handling the click on the menu item
   * - Manages the submenu functionality and state
   * @param index
   */
  const handleMenuClick = (index: number) => {
    // Getting the menu item
    const menuItem = LEFT_MENU_ITEMS[index];

    // If the menu don't have submenu, navigate to the link
    if (!menuItem.subMenu) {
      // Close all submenus
      setIsSubmenuOpened(Array(LEFT_MENU_ITEMS.length).fill(false));
      isNavMenuOpened && toggleNavMenuOpened();
      return;
    }

    // If the menu has submenu, toggle the submenu
    let newIsSubmenuOpened = [...isSubmenuOpened];
    newIsSubmenuOpened = newIsSubmenuOpened.map((item, i) => {
      if (i === index) {
        return !item;
      }
      return false;
    });
    setIsSubmenuOpened(newIsSubmenuOpened);
  };

  /**
   * Opens the submenu without closing the other submenus
   */
  const handleArrowClick = (e: any, index: number) => {
    e.stopPropagation();
    e.preventDefault();
    let newIsSubmenuOpened = [...isSubmenuOpened];
    newIsSubmenuOpened[index] = !newIsSubmenuOpened[index];
    setIsSubmenuOpened(newIsSubmenuOpened);
  };

  /**
   * Returns the array of locked items for the current plan type
   */
  const lockedItems = useMemo(() => {
    if (isWebPlanType) {
      const lockedItems = [];

      // Product Insights
      lockedItems.push(LEFT_MENU_TYPES.Overview);
      lockedItems.push(LEFT_MENU_TYPES.Onboarding);
      lockedItems.push(LEFT_MENU_TYPES.ProductEngagement);
      lockedItems.push(LEFT_MENU_TYPES.FeatureAdoption);
      lockedItems.push(LEFT_MENU_TYPES.Retention);

      // Contacts Hub
      lockedItems.push(LEFT_MENU_TYPES.Users);
      lockedItems.push(LEFT_MENU_TYPES.Companies);
      // lockedItems.push(LEFT_MENU_TYPES.Segments); // All web plans are pro and can access segments

      // Analytics
      lockedItems.push(LEFT_MENU_TYPES.Attribution);

      return lockedItems; // Return locked items
    }

    if (isBillingLocked) {
      const lockedItems: any[] = [];

      // We will lock all the items
      LEFT_MENU_ITEMS.forEach((item) => {
        lockedItems.push(item.name);
      });

      return lockedItems; // Return locked items
    }

    return [];
  }, [isWebPlanType, isBillingLocked]);

  /**
   * Resolves the url for some menu path cases
   */
  const resolvedUrl = useCallback(
    (path: string, name: string) => {
      switch (name) {
        case LEFT_MENU_TYPES.WebAnalytics:
          return `/env/${activeWorkspace.identifier}/web/statistics${
            window.location.pathname.includes("/web/statistics") &&
            new URLSearchParams(window.location.search)
              ? `?${new URLSearchParams(window.location.search)}`
              : ""
          }`;
        case LEFT_MENU_TYPES.ProductInsights:
          return `/env/${activeWorkspace.identifier}/insights/dashboard/${
            productInsightsMenuToNavigate === "product engagement"
              ? "adoption_engagement"
              : productInsightsMenuToNavigate === "feature adoption"
              ? "feature"
              : productInsightsMenuToNavigate
          }`;
        case LEFT_MENU_TYPES.ContactsHub:
          console.log("contactsHubMenuToNavigate", contactsHubMenuToNavigate);
          return `/env/${activeWorkspace.identifier}/${contactsHubMenuToNavigate}${
            contactsHubMenuToNavigate === "segments" ? "" : "/everyone"
          }`;
        case LEFT_MENU_TYPES.Analyze:
          return `/env/${activeWorkspace.identifier}/analyze/${analyzeMenuToNavigate}`;
        // More cases here if needed...
      }

      return `/env/${activeWorkspace.identifier}/${path}`;
    },
    [
      activeWorkspace.identifier,
      analyzeMenuToNavigate,
      contactsHubMenuToNavigate,
      productInsightsMenuToNavigate
    ]
  );

  /**
   * Hidden Menu Item Conditions Management
   */
  const isItemHidden = (name: string) => {
    if (
      (hiddenMenuItemsList.includes(name) || !contactsHubMenuToNavigate) &&
      name === LEFT_MENU_TYPES.ContactsHub
    ) {
      return true;
    }

    if (
      (hiddenMenuItemsList.includes(name) || !analyzeMenuToNavigate) &&
      name === LEFT_MENU_TYPES.Analyze
    ) {
      return true;
    }

    return hiddenMenuItemsList.includes(name);
  };

  /**
   * Handle Active classes for the menu items in case of Product Insights and Events pages.
   * Also for the submenu items in Contacts Hub when a segment is selected for filtering
   */
  const isActiveTabsPages = (activePatterns?: string[]) => {
    if (activePatterns && activePatterns.length > 0) {
      return activePatterns.some((pattern) => {
        return location.pathname.includes(pattern);
      });
    }

    return false;
  };

  useHotkeys([["mod+J", () => setColorScheme(dark ? "light" : "dark")]]);

  return (
    <Box pos={"relative"} h={"100%"}>
      {isMobileView && (
        <Transition
          mounted={isNavMenuOpened}
          transition="fade"
          duration={400}
          timingFunction="ease">
          {(styles) => (
            <div style={styles}>
              <Tooltip.Floating label={"Click to close"} position={"top"} offset={0}>
                <Overlay
                  color={"black"}
                  opacity={0.2}
                  className={classes.closeIcon}
                  onClick={toggleNavMenuOpened}
                />
              </Tooltip.Floating>
            </div>
          )}
        </Transition>
      )}

      <Transition
        mounted={isNavMenuOpened || !isMobileView}
        transition="slide-right"
        duration={400}
        timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <Flex
              direction={"column"}
              justify={"space-between"}
              className={classes.navMenu}
              style={{
                maxWidth: isCollapsedLeftMenu ? "55px" : "220px",
                width: isMobileView ? "100%" : isCollapsedLeftMenu ? "55px" : "220px",
                height: isMobileView ? "calc(100vh - 70px)" : "100vh",
                position: isMobileView ? "absolute" : "relative"
              }}>
              <Stack
                mt="xs"
                gap={4}
                className={clsx(
                  classes.scrollContainer,
                  colorScheme === "dark" ? classes.dark : classes.light
                )}>
                {!isMobileView && (
                  <>
                    <Box
                      className={clsx(
                        classes.collapseIcon,
                        isCollapsedLeftMenu ? classes.rotate : undefined
                      )}
                      style={{
                        marginLeft: !isCollapsedLeftMenu
                          ? "calc(220px - 1.125rem)"
                          : "calc(60px - 1.125rem)"
                      }}
                      onClick={() => {
                        setIsCollapsedLeftMenu(!isCollapsedLeftMenu);
                      }}>
                      <Box c={colorScheme === "dark" ? "dark.3" : "gray.7"}>
                        <FontAwesomeIcon
                          icon={solid("angle-right")}
                          className="flex-grow text-lg"></FontAwesomeIcon>
                      </Box>
                    </Box>

                    <Flex align={"center"} justify={"center"} mb={"md"}>
                      {workspaceDropdownComponent ? workspaceDropdownComponent : null}
                    </Flex>
                  </>
                )}

                {!activeWorkspace.is_setup_completed && (
                  <Tooltip
                    label={"Setup"}
                    position={"right"}
                    offset={5}
                    withArrow
                    disabled={!isCollapsedLeftMenu}>
                    <Box className={classes.navItem}>
                      <NavLink
                        to={`/env/${activeWorkspace.identifier}/setup-guides/integration`}
                        className={({ isActive }) => {
                          return isActive ? classes.activeNavItem : "";
                        }}
                        disabled={isBillingLocked}>
                        <Flex
                          className={classes.navItemInner}
                          justify={isCollapsedLeftMenu ? "center" : "space-between"}>
                          <span>
                            <Flex w={16} justify={"center"}>
                              <FontAwesomeIcon icon={light("home")} />
                            </Flex>
                            <span
                              className={
                                isCollapsedLeftMenu
                                  ? classes.navItemInnerLabelCollapsed
                                  : classes.navItemInnerLabel
                              }>
                              Setup
                            </span>
                          </span>
                        </Flex>
                      </NavLink>
                    </Box>
                  </Tooltip>
                )}

                {LEFT_MENU_ITEMS.map((item, index) => (
                  <Fragment key={index}>
                    {isItemHidden(item.name) ? null : (
                      <>
                        <Tooltip
                          label={
                            (lockedItems.includes(item.name) || isBillingLocked) &&
                            isCollapsedLeftMenu
                              ? item.name + " (Locked)"
                              : item.name
                          }
                          position={"right"}
                          offset={5}
                          withArrow
                          disabled={!isCollapsedLeftMenu}>
                          <Box
                            className={clsx(
                              classes.navItem,
                              isCollapsedLeftMenu ? classes.navItemCollapsed : "",
                              lockedItems.includes(item.name) ? classes.locked : ""
                            )}
                            onClick={() => handleMenuClick(index)}
                            style={{
                              display:
                                isCollapsedLeftMenu && item.subMenu && !item.hideSubMenu
                                  ? "none"
                                  : ""
                            }}>
                            <NavLink
                              to={resolvedUrl(item.path, item.name)}
                              className={({ isActive }) => {
                                return (isActive && !(item.subMenu && item.subMenu.length > 0)) ||
                                  isActiveTabsPages(item.activePatterns)
                                  ? classes.activeNavItem
                                  : "";
                              }}
                              disabled={isBillingLocked}>
                              <Flex
                                align={"center"}
                                className={classes.navItemInner}
                                justify={isCollapsedLeftMenu ? "center" : "space-between"}>
                                <Flex component="span" align={"center"}>
                                  <Flex w={16} justify={"center"}>
                                    <FontAwesomeIcon icon={item.iconName} />
                                  </Flex>

                                  <span
                                    className={
                                      isCollapsedLeftMenu
                                        ? classes.navItemInnerLabelCollapsed
                                        : classes.navItemInnerLabel
                                    }>
                                    {item.name}
                                    {item?.isBeta && (
                                      <Badge
                                        ml={16}
                                        variant="filled"
                                        color="brand.4"
                                        radius={"xs"}
                                        size={"xs"}>
                                        Beta
                                      </Badge>
                                    )}
                                  </span>
                                </Flex>

                                {(lockedItems.includes(item.name) || isBillingLocked) &&
                                !isCollapsedLeftMenu ? (
                                  <Box mr={16}>
                                    <FontAwesomeIcon icon={light("lock")}></FontAwesomeIcon>
                                  </Box>
                                ) : item.subMenu && item.subMenu.length > 0 && !item.hideSubMenu ? (
                                  <Box
                                    mr={16}
                                    className={classes.menuAngleIcon}
                                    onClick={(e) => handleArrowClick(e, index)}>
                                    <FontAwesomeIcon
                                      className={isSubmenuOpened[index] ? "rotate-90" : ""}
                                      icon={solid("angle-right")}></FontAwesomeIcon>
                                  </Box>
                                ) : null}
                              </Flex>
                            </NavLink>

                            {item.name === LEFT_MENU_TYPES.MavenAi && (
                              <Space h="xs" />
                              // <Divider color="hsla(240, 9%, 62.9%, 0.27)" sx={{
                              //   margin: '11px 20px'
                              // }} />
                            )}
                          </Box>
                        </Tooltip>
                        {item.subMenu && item.subMenu.length > 0 && !item.hideSubMenu ? (
                          <Collapse
                            in={isCollapsedLeftMenu ? true : isSubmenuOpened[index]}
                            transitionTimingFunction="linear"
                            my={isCollapsedLeftMenu ? "xs" : 0}>
                            <>
                              {item.subMenu.map((subItem, subIndex) => (
                                <Fragment key={subIndex}>
                                  {isItemHidden(subItem.name) ? null : (
                                    <Tooltip
                                      label={
                                        (lockedItems.includes(subItem.name) || isBillingLocked) &&
                                        isCollapsedLeftMenu
                                          ? subItem.name + " (Locked)"
                                          : subItem.name
                                      }
                                      position={"right"}
                                      offset={5}
                                      withArrow
                                      disabled={!isCollapsedLeftMenu}>
                                      <Box
                                        className={clsx(
                                          classes.navItem,
                                          classes.subNavItem,
                                          lockedItems.includes(subItem.name) ? classes.locked : "",
                                          isCollapsedLeftMenu ? classes.subNavItemCollapsed : ""
                                        )}
                                        key={subIndex}
                                        // sx={
                                        //   {
                                        //     // a: {
                                        //     //   padding: isCollapsedLeftMenu
                                        //     //     ? "12px 20px !important"
                                        //     //     : ""
                                        //     // }
                                        //   }
                                        // }
                                        onClick={() => {
                                          isNavMenuOpened && toggleNavMenuOpened();
                                        }}>
                                        <NavLink
                                          to={resolvedUrl(subItem.path, subItem.name)}
                                          className={({ isActive }) => {
                                            return isActive ||
                                              isActiveTabsPages(subItem.activePatterns)
                                              ? classes.activeNavItem
                                              : "";
                                          }}
                                          disabled={isBillingLocked}>
                                          <Flex
                                            justify={
                                              isCollapsedLeftMenu ? "center" : "space-between"
                                            }
                                            className={classes.navItemInner}>
                                            {!isCollapsedLeftMenu ? null : (
                                              <FontAwesomeIcon icon={subItem.iconName} />
                                            )}
                                            <span
                                              className={
                                                isCollapsedLeftMenu
                                                  ? classes.navItemInnerLabelCollapsed
                                                  : classes.navItemInnerLabel
                                              }>
                                              {subItem.name}
                                              {subItem.isBeta && (
                                                <Badge
                                                  variant="filled"
                                                  color="brand.4"
                                                  radius={"xs"}
                                                  size={"xs"}>
                                                  Beta
                                                </Badge>
                                              )}
                                            </span>

                                            {(lockedItems.includes(subItem.name) ||
                                              isBillingLocked) &&
                                            !isCollapsedLeftMenu ? (
                                              <div>
                                                <FontAwesomeIcon
                                                  icon={light("lock")}></FontAwesomeIcon>
                                              </div>
                                            ) : null}
                                          </Flex>
                                        </NavLink>
                                      </Box>
                                    </Tooltip>
                                  )}
                                </Fragment>
                              ))}
                            </>
                          </Collapse>
                        ) : null}
                      </>
                    )}
                  </Fragment>
                ))}
              </Stack>

              {isCollapsedLeftMenu ? (
                <Flex display={"flex"} justify={"center"} align={"center"} mt="xs" mb={"lg"}>
                  <Menu shadow="md" width={200} position={"top-start"}>
                    <Menu.Target>
                      <span>
                        <Tooltip
                          label={"Account"}
                          position={"right"}
                          offset={18}
                          withArrow
                          disabled={!isCollapsedLeftMenu}>
                          {user?.profile_image ? (
                            <Image
                              style={{
                                cursor: "pointer"
                              }}
                              h={32}
                              w={32}
                              fit="contain"
                              src={`${user?.profile_image}-/scale_crop/50x50/smart/`}
                              alt=""
                            />
                          ) : (
                            <Image
                              style={{
                                cursor: "pointer"
                              }}
                              h={32}
                              w={32}
                              fit="contain"
                              src={profileIcon}
                              alt=""
                            />
                          )}
                        </Tooltip>
                      </span>
                    </Menu.Target>

                    <Menu.Dropdown>
                      {hasRolesWith([WORKSPACE_MEMBER_ROLES.OWNER]) && (
                        <Menu.Item
                          leftSection={
                            <Flex w={16}>
                              <FontAwesomeIcon
                                icon={light("dollar-sign")}
                                style={{
                                  color: theme.colors.green[6]
                                }}
                              />
                            </Flex>
                          }
                          onClick={(e: any) => {
                            navigate(`/env/${activeWorkspace.identifier}/account/referral_program`);
                          }}>
                          Refer and Earn
                        </Menu.Item>
                      )}
                      <Menu.Item
                        leftSection={
                          <Flex w={16}>
                            <FontAwesomeIcon icon={light("wave-pulse")} />
                          </Flex>
                        }
                        onClick={(e: any) => {
                          navigate(`/env/${activeWorkspace.identifier}/events/auto-captured`);
                        }}>
                        Configure Events
                      </Menu.Item>
                      {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                        <Menu.Item
                          leftSection={
                            <Flex w={16}>
                              <FontAwesomeIcon icon={light("gear")} />
                            </Flex>
                          }
                          onClick={(e: any) => {
                            navigate(`/env/${activeWorkspace.identifier}/settings/general`);
                          }}>
                          Workspace Settings
                        </Menu.Item>
                      )}
                      <Menu.Item
                        leftSection={
                          <Flex w={16}>
                            <FontAwesomeIcon
                              icon={light("user-gear")}
                              style={{
                                width: "15px"
                              }}
                            />
                          </Flex>
                        }
                        onClick={(e: any) => {
                          navigate(`/env/${activeWorkspace.identifier}/account/general`);
                        }}>
                        Account Settings
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Item
                        color="red"
                        leftSection={
                          <Flex w={16}>
                            <FontAwesomeIcon icon={light("sign-out")} />
                          </Flex>
                        }
                        onClick={(e: any) => {
                          navigate("/logout");
                        }}>
                        Logout
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Flex>
              ) : (
                <Box>
                  <Stack gap={4} mb={"md"} mt="xs">
                    <Box
                      className={classes.navItem}
                      onClick={() => {
                        isNavMenuOpened && toggleNavMenuOpened();
                        toggleAllSubmenus(false);
                      }}>
                      <NavLink
                        to={`/env/${activeWorkspace.identifier}/events/auto-captured`}
                        className={({ isActive }) => {
                          return isActive || isEventsPage ? classes.activeNavItem : "";
                        }}>
                        <Flex w={16} justify={"center"}>
                          <FontAwesomeIcon icon={light("wave-pulse")} />
                        </Flex>
                        <span>Configure Events</span>
                      </NavLink>
                    </Box>
                    {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                      <Box
                        className={classes.navItem}
                        onClick={() => {
                          isNavMenuOpened && toggleNavMenuOpened();
                          toggleAllSubmenus(false);
                        }}>
                        <NavLink
                          to={`/env/${activeWorkspace.identifier}/settings/general`}
                          className={({ isActive }) => {
                            return isActive || isWorkspaceSettingsPage ? classes.activeNavItem : "";
                          }}>
                          <Flex w={16} justify={"center"}>
                            <FontAwesomeIcon icon={light("gear")} />
                          </Flex>
                          <span>Workspace Settings</span>
                        </NavLink>
                      </Box>
                    )}
                    <Box
                      className={classes.navItem}
                      onClick={() => {
                        isNavMenuOpened && toggleNavMenuOpened();
                        toggleAllSubmenus(false);
                      }}>
                      <NavLink
                        to={`/env/${activeWorkspace.identifier}/account/general`}
                        className={({ isActive }) => {
                          return isActive || isAccountSettingsPage ? classes.activeNavItem : "";
                        }}>
                        <Flex w={16} justify={"center"}>
                          <FontAwesomeIcon
                            icon={light("user-gear")}
                            style={{
                              width: "15px"
                            }}
                          />
                        </Flex>
                        <span>Account Settings</span>
                      </NavLink>
                    </Box>
                    {hasRolesWith([WORKSPACE_MEMBER_ROLES.OWNER]) && (
                      <Box
                        className={classes.navItem}
                        onClick={() => {
                          isNavMenuOpened && toggleNavMenuOpened();
                          toggleAllSubmenus(false);
                        }}>
                        <NavLink
                          to={`/env/${activeWorkspace.identifier}/account/referral_program`}
                          className={({ isActive }) => {
                            return isActive ? classes.activeNavItem : "";
                          }}>
                          <Flex w={16} justify={"center"}>
                            <FontAwesomeIcon
                              icon={regular("dollar-sign")}
                              style={{
                                color: theme.colors.green[6],
                                marginLeft: "2px"
                              }}
                            />
                          </Flex>
                          <span style={{}}>Refer & Earn</span>
                        </NavLink>
                      </Box>
                    )}
                  </Stack>

                  <Flex
                    align={"center"}
                    justify={"space-between"}
                    className={classes.navMenuFooter}>
                    <Tooltip label={"Switch Theme"} withArrow>
                      <div
                        className={classes.navMenuFooterItem}
                        onClick={() => toggleColorScheme()}>
                        {dark ? (
                          <FontAwesomeIcon icon={regular("sun-bright")}></FontAwesomeIcon>
                        ) : (
                          <FontAwesomeIcon icon={regular("moon")}></FontAwesomeIcon>
                        )}
                      </div>
                    </Tooltip>

                    <FrillWidget />
                    <Tooltip label={"Support"} withArrow>
                      <div className={classes.navMenuFooterItem}>
                        <FontAwesomeIcon icon={regular("circle-question")}></FontAwesomeIcon>
                      </div>
                    </Tooltip>

                    <Tooltip label={"Logout"} withArrow>
                      <div
                        className={classes.navMenuFooterItem}
                        onClick={(e: any) => {
                          navigate("/logout");
                        }}>
                        <FontAwesomeIcon
                          icon={regular("arrow-right-from-bracket")}></FontAwesomeIcon>
                      </div>
                    </Tooltip>
                  </Flex>
                </Box>
              )}
            </Flex>
          </div>
        )}
      </Transition>
    </Box>
  );
};

type TNavLink = NavLinkProps & {
  disabled?: boolean;
};
const NavLink = ({ ...props }: TNavLink) => {
  const to = props.to as string;

  const styles: React.CSSProperties = {
    ...props.style,
    pointerEvents: props.disabled ? "none" : "auto"
  };

  return <NavLinkNative {...props} style={styles} />;
};
export default NavMenu;
