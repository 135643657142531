import {
  Badge,
  Box,
  Radio,
  useMantineColorScheme,
  useMantineTheme,
  Flex,
  Group
} from "@mantine/core";
import React from "react";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface CustomSegmentControlProps {
  data: Array<{
    value: string;
    label: string;
    disabled?: boolean;
    icon?: React.ReactNode;
    isComingSoon?: boolean;
  }>;

  value?: string;
  onChange?: (value: string) => void;
  name?: string;
}

const CustomSegmentControl = ({
  name,
  data,
  value = "",
  onChange = () => {}
}: CustomSegmentControlProps) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Flex align="center">
      <Radio.Group
        dir="ltr"
        name={name || "segmented-control"}
        styles={{
          root: {
            '[role="radiogroup"]': {
              paddingTop: "0px"
            }
          }
        }}
        defaultValue={value}
        value={value}>
        <Group>
          {data.map((item, index) => {
            return (
              <Box
                style={{
                  padding: "12px 18px",
                  borderRadius: "4px",
                  border: `1px solid ${colorScheme === "dark" ? "#373A40" : "#e1e1e1"}`,
                  display: "flex",
                  alignItems: "center",
                  gap: "0.75rem",
                  fontSize: "16px",
                  position: "relative",

                  // Disabled Styles
                  ...(item.disabled && {
                    backgroundColor: `${
                      colorScheme === "dark" ? "#3E3C3E5E" : "rgba(200, 200, 200, 0.09)"
                    }`,
                    color: "#b1b1b1",
                    cursor: "not-allowed"
                  })
                }}
                key={index}
                onClick={() => !item.disabled && onChange(item.value)}>
                <Box>{item.icon && item.icon}</Box>

                <Radio
                  value={item.value}
                  label={item.label}
                  labelPosition="left"
                  size="xs"
                  styles={{
                    label: {
                      fontWeight: 500,
                      paddingRight: "16px"
                    }
                  }}
                  disabled={item.disabled}
                />

                {item.isComingSoon && (
                  <Badge
                    color={colorScheme === "dark" ? "dark" : "brand"}
                    variant={colorScheme === "dark" ? "filled" : "light"}
                    size={"xs"}
                    style={{
                      position: "absolute",
                      right: "50%",
                      bottom: "-8px",
                      transform: "translateX(50%)",
                      fontWeight: 600
                    }}>
                    Soon
                  </Badge>
                )}
              </Box>
            );
          })}
        </Group>
      </Radio.Group>
    </Flex>
  );
};

export default CustomSegmentControl;
