import { Box, Button, Container, Flex, Text, Title } from "@mantine/core";
import AIAssistantVideo from "@assets/videos/ai-assistant-demo.mp4";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";

const LockPage = () => {
  const navigate = useNavigate();
  const { activeWorkspace, user: activeUser } = useContext(AppLifecycleContext);


  const handleRedirectToUpgradePlan = () => {
    navigate(`/env/${activeWorkspace.identifier}/account/billing`)
  }
  return (
    <div>
      <Container size="md" my={'100px'} sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}>
        <Flex align={'center'} justify={'center'} mb={'xl'}>
          <video
            style={{
              borderRadius: "0.5rem",
              maxWidth: "800px"
            }}
            height={503.5}
            width={'100%'}
            controls
            autoPlay
            src={
              AIAssistantVideo
              // theme.colorScheme === "dark" ? AIAssistantVideo : AIAssistantVideoLightMode
            }
          />
        </Flex>

        <Title
          order={3}
          align="center"
          mb={16}
          mt={36}
          sx={{
            display: "Flex"
          }}>
          Maven AI  is your new data companion, designed to provide seamless access to valuable insights within Usermaven.
        </Title>

        <Box
          mb={16}
          sx={{
            width: "100%"
          }}>
          <Text ta="center" size="md">
            Please upgrade to a paid plan to pro or premium to unlock Maven AI.
          </Text>
        </Box>

        <Flex
          mb={16}
          sx={{
            width: "100%"
          }}
          align="center"
          justify="center"
        >
          <Button
            variant="gradient"
            size="lg"
            onClick={() => handleRedirectToUpgradePlan()}
            >
            Upgrade Plan
          </Button>
        </Flex>
      </Container>
    </div>
  );
};

export default LockPage;