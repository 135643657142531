import { Box, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { EditorContent, useEditor } from "@tiptap/react";
import "./notes_editor.scss";

import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useState } from "react";
import { NotesMenu } from "./NotesMenu";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface IRichTextEditor {
  initialContent?: string;
  isEdit: boolean;
  noteId: number | string;
  placeholderText?: string;
  onContentUpdate?: (content: string) => void;
  extensions?: any[];
  onCancel: (noteId: number) => void;
  onSave: (content: string) => void;
  slotsAfterEditor?: React.ReactNode | null;
}

export const RichTextEditor: React.FC<IRichTextEditor> = ({
  initialContent,
  isEdit,
  noteId,
  placeholderText = "Write something ...",
  onContentUpdate,
  extensions = [],
  onCancel,
  onSave,
  slotsAfterEditor = null
}) => {
  const { colorScheme } = useMantineColorScheme();
  const theme = useGlobalMantineTheme();

  const [html, setHtml] = useState(initialContent);
  const defaultExtensions = [
    StarterKit,
    Placeholder.configure({
      emptyEditorClass: "is-editor-empty",
      emptyNodeClass: "my-custom-is-empty-class",
      placeholder: placeholderText
    })
  ];

  const editor = useEditor({
    content: initialContent,
    extensions: [...defaultExtensions, ...extensions]
  });

  useEffect(() => {
    if (!editor) {
      return undefined;
    }

    // Get the initial content …
    if (editor) {
      setHtml(editor.getHTML());
    }

    // … and get the content after every change.
    editor.on("update", () => {
      setHtml(editor.getHTML());
    });
  }, [editor]);
  if (!editor) {
    return null;
  }

  return (
    <>
      <Box>
        {isEdit && (
          <>
            <Box
              style={{
                border: `1px solid ${
                  colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[5]
                }`,
                backgroundColor:
                  colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0]
              }}
              p={8}>
              <EditorContent
                style={{
                  minHeight: "70px"
                }}
                editor={editor}
              />
              <NotesMenu onCancel={onCancel} onSave={onSave} editor={editor} />
              {slotsAfterEditor && slotsAfterEditor}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
