import { AttributeItem } from "../../../Common/AttributeItem/AttributeItem";
import { Oval } from "react-loader-spinner";
import { loaderColor } from "@/style/Colors";
import React, { useState } from "react";
import { Box, Flex, Paper, Title, Text, useMantineColorScheme } from "@mantine/core";

export interface IAttributeSection {
  /**
   * Specify the attribute section title.
   */
  title: string;

  /**
   * Specify the callback function to be called when fetching more attributes.
   */
  onFetch?: (() => Promise<IAttributeSectionItem[] | []>) | undefined;
}

export interface IAttributeSectionItem {
  key: string;
  type?: string;
  value: string | number | boolean | any;
  cast?: string;
}

const AttributeSection = ({
  title = "",
  onFetch = () => Promise.resolve([])
}: IAttributeSection) => {
  const { colorScheme } = useMantineColorScheme();
  const [attributes, setAttributes] = useState<IAttributeSectionItem[]>([]);
  const [loading, setLoading] = React.useState(true);

  const fetchAttributes = async () => {
    setLoading(true);
    const fetchedAttributes = await onFetch();
    if (fetchedAttributes) {
      setAttributes(fetchedAttributes);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchAttributes();
  }, []);

  return (
    <Paper>
      <Title order={5} fw={500} my={"md"}>
        {" "}
        {title}
      </Title>

      {/* Body */}
      <Paper shadow="xs" withBorder>
        {!loading ? (
          <>
            {attributes.length > 0 ? (
              attributes.map((item: IAttributeSectionItem, index: number) => (
                <AttributeItem
                  key={index}
                  attribute_key={item.key}
                  attribute_value={item.value}
                  attribute_type={item.type}
                  attribute_cast_type={item.cast}
                />
              ))
            ) : (
              <div className="flex w-full flex-grow items-center justify-center py-5 text-center text-gray-500 py-4">
                No attributes found.
              </div>
            )}
          </>
        ) : (
          <>
            <Flex justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
              <Text>No details found.</Text>
            </Flex>
          </>
        )}
      </Paper>
    </Paper>
  );
};

export default AttributeSection;
