import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingStateProps } from "types/types.d";
import {
  DATE_FORMAT,
  LOADER_COLOR,
  WORKSPACE_MEMBER_ROLES
} from "../../../../../../lib/utils/Constants";
import { ExportButton } from "@/ui/components/Common/ExportButton/ExportButton";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { Oval } from "react-loader-spinner";
import { downloadCSVFromResponse } from "../../../../../../lib/utils/CSVExporterUtility";
import { format, isToday } from "date-fns";
import {
  Box,
  Button,
  Flex,
  Group,
  Table,
  Tooltip,
  useMantineTheme,
  Text,
  Avatar
} from "@mantine/core";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { ActiveUsersTrendsService } from "@/lib/services/ActiveUsersTrendsService";
import { capitalizeFirstLetter, stringToInitials } from "@/lib/utils/StringUtility";
import { utcToRelativeTimezone } from "@/lib/utils/DateUtility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { getRandomColor } from "@/style/Colors";
import { AvatarWithText } from "@/ui/components/Common/AvatarWithText/AvatarWithText";
import { RelativeDateWithText } from "@/ui/components/Common/RelativeDateWithText/RelativeDateWithText";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface Props {
  /**
   * listView will be true when the active users list modal is opened
   */
  listView: boolean;
  /**
   * Function to update the listView value
   */
  setListView: (listView: boolean) => void;
  /**
   * Insight id of the active users insight
   */
  insightId: string | undefined;
  /**
   * Type of list to be shown ie. "daily" or "weekly" or "monthly"
   */
  type: "daily" | "weekly" | "monthly";
  /**
   * Report type ie. "user" or "company"
   */
  reportType: "user" | "company";
  /**
   * fromDate to show data from a specific date
   */
  fromDate: string;
  /**
   * toDate to show data till a specific date
   */
  toDate: string;
}

/**
 * This component shows the list view modal for active users.
 */
export const ActiveUsersListModal = ({
  listView,
  setListView,
  insightId = "",
  type = "daily",
  reportType = "user",
  fromDate,
  toDate
}: Props) => {
  const theme = useGlobalMantineTheme()

  // colors for the avatar
  const colors = useMemo(() => {
    return Array.from(Array(100).keys()).map((i) => getRandomColor());
  }, []);

  // Get user role in the workspace.
  const { hasRolesNotWith } = useWorkspaceUtilityHook();

  //Current active workspace.
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // Getting audience conditions from audience filter context if any
  const [filters] = useSegmentStore((state) => [state.filters]);

  //Loading and exporting state for async fetch and export calls.
  const [loading, setLoading] = useState<LoadingStateProps>("idle");

  const [exporting, setIsExporting] = useState("idle");

  //List to set the results of active users.
  const [list, setList] = useState([]);
  const navigate = useNavigate();

  const page = useRef(0);

  // hasMore is used to check if there are more users to be fetched.

  const [hasMore, setHasMore] = useState(true);

  //Async call to fetch the list of active users for the selected time period.
  const fetchActiveUsersList = async () => {
    setLoading("loading");
    page.current = page.current + 1;
    await new ActiveUsersTrendsService()
      .activeUsersList(
        activeWorkspace.id,
        insightId,
        type,
        reportType,
        "list",
        page.current,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        if (res.data.length > 0) {
          const updatedList = res.data.map((item: any) => {
            return {
              ...item,
              color: colors[Math.floor(Math.random() * 100)]
            };
          });
          setList(list.concat(updatedList));
          if (res.data.length < 100) {
            setHasMore(false);
          }
        } else {
          setHasMore(false);
        }
      })
      .catch();
    setLoading("loaded");
  };

  //Service call to export users list as CSV.

  const exportActiveUsers = async () => {
    const activeUsersTrendsService = new ActiveUsersTrendsService();
    setIsExporting("loading");
    try {
      const response = await activeUsersTrendsService.activeUsersList(
        activeWorkspace.id,
        insightId,
        type,
        reportType,
        "csv",
        page.current,
        filters.rules,
        filters.combinator,
        filters.groups
      );
      if (response.data) {
        // Setting filename of the exported file in accordance with the date and time of export.
        let filename = `${type}_active_${reportType}_list_${format(new Date(), DATE_FORMAT)}.csv`;
        //Utility function to download the csv from response.
        downloadCSVFromResponse(response, filename);
        setIsExporting("loaded");
        return true;
      }
    } catch (err) {
      setIsExporting("loaded");
      throw new Error("400");
    }
    return false;
  };

  //Initial service call when the detailed view is set to true.
  useEffect(() => {
    if (listView) {
      fetchActiveUsersList();
    } else {
      setList([]);
      setHasMore(true);
      page.current = 0;
    }
  }, [listView]);

  const [
    setOverviewModalOpen,
  ] = useUserOverviewModalStore(state => [state.setIsOpen])

  return (
    <>
      <SidePanel
        opened={listView}
        onCancel={() => setListView(false)}
        loading={true}
        title={
          <Group>
            <h2 className="font-medium text-2xl">
              {capitalizeFirstLetter(type)} active {reportType === "user" ? "users" : "companies"}{" "}
              list
            </h2>
            <Tooltip
              multiline
              w={400}
              withArrow
              position="right"
              label={`The number of active ${
                reportType === "user" ? "users" : "companies"
              } shown here may vary 
              from the card display. Card values may include events where the ${
                reportType + " "
              } is unspecified, 
              so the list shown here may be smaller.`}>
              <FontAwesomeIcon icon={regular("info-circle")} color="gray" />
            </Tooltip>
          </Group>
        }>
        {/*Export button which will allow the user to export list as csv. It will be set to disabled if it's in the loading state*/}
        <div className="flex flex-row-reverse pb-2">
          {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
            <ExportButton
              onClick={exportActiveUsers}
              disabled={exporting === "loading"}
              loading={exporting === "loading"}
            />
          )}
        </div>
        {/*If the list has some results then the results will be shown*/}
        {list.length === 0 && loading === "loaded" ? (
          <NoResults
            className="my-8"
            text={`No active ${
              reportType === "user" ? "users" : "companies"
            } found for the selected time period.`}
            heading={`No active ${reportType === "user" ? "users" : "companies"}`}
          />
        ) : list.length > 0 ? (
          <Box
            sx={{
              display: "block",
              overflowX: "auto",
              whiteSpace: "nowrap"
            }}>
            <Table
              striped
              highlightOnHover
              sx={() => ({
                "&[data-striped] tbody tr:nth-of-type(2n+1)": {
                  backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : ""
                }
              })}>
              <thead>
                {reportType === "user" ? (
                  <tr>
                    <th>Name</th>
                    <th className="min-w-[260px]">Email</th>
                    <th>Last seen</th>
                    <th>Created at</th>
                  </tr>
                ) : (
                  <tr>
                    {/* <th className="min-w-[260px]">Company ID</th> */}
                    <th>Name</th>
                    <th>Users</th>
                    <th>Last seen</th>
                    <th>Created at</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {list.map((item: any, index) =>
                  reportType === "user" ? (
                    // Clicking on any user will navigate to the overview of that user
                    <tr
                      onClick={() =>
                       {
                           // navigate(
                           //   `/env/${activeWorkspace.identifier}/users/everyone/${item.user_id}`
                           // )
                           setListView(false)
                           setOverviewModalOpen(true, "user", item.user_id)
                       }}
                      className={"hover:underline transition-all ease-in-out cursor-pointer"}
                      key={`active-list:${index}:${item.user_created_at}`}>
                      <td>
                        <AvatarWithText
                          color={item.color}
                          text={item.user_first_name + " " + item.user_last_name}
                        />
                      </td>

                      <td>{item.user_email}</td>
                      <td>
                        <RelativeDateWithText text={item.last_visited_time} />
                      </td>
                      <td>
                        <RelativeDateWithText text={item.user_created_at} />
                      </td>
                    </tr>
                  ) : (
                    // Clicking on any company will navigate to the overview of that company
                    <tr
                      onClick={() => {
                        // navigate(
                        //   `/env/${activeWorkspace.identifier}/companies/everyone/${item.company_id}`
                        // )

                        setListView(false)
                        setOverviewModalOpen(true, "company", item.company_id)
                      }}
                      className={"hover:underline transition-all ease-in-out cursor-pointer"}
                      key={`active-list:${index}:${item.company_id}`}>
                      {/* <td>{item.company_id}</td> */}
                      <td>
                        <AvatarWithText color={item.color} text={item.company_name} />
                      </td>
                      <td>
                        <Text c="dimmed" fw={500}>
                          {item.number_of_users} {item.number_of_users > 1 ? "users" : "user"}
                        </Text>
                      </td>
                      <td>
                        {" "}
                        <RelativeDateWithText text={item.last_visited_time} />
                      </td>

                      <td>
                        <RelativeDateWithText text={item.company_created_at} />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
            {hasMore && (
              <div className="flex justify-center pt-8">
                <Button
                  color="brand"
                  variant="outline"
                  size="xs"
                  loading={loading === "loading"}
                  disabled={loading === "loading"}
                  onClick={() => fetchActiveUsersList()}>
                  Load More
                </Button>
              </div>
            )}
          </Box>
        ) : (
          <>
            <div className="flex justify-start items-center">
              <Oval height={14} width={14} color={LOADER_COLOR}></Oval>
            </div>
          </>
        )}
      </SidePanel>
    </>
  );
};
