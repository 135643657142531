import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { Box, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { useMemo, useRef, useState } from "react";
import { ConversionFunnelStepProps } from "../../../../types/types.d";
import { useFunnelChartOption } from "./ChartOptions/ChartOptions";
import {
  convertSecondsToReadableFormat,
  numberToCommas
} from "../../../../lib/utils/StringUtility";
import { IFunnelStep } from "./FunnelDetailedView/FunnelDetails";
import { useMediaQuery } from "@mantine/hooks";
import { MOBILE_BREAKPOINT_QUERY } from "../../../../lib/utils/Constants";
import { useIntl } from "react-intl";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  BarChart,
  CanvasRenderer
]);

export interface IStepsFunnel {
  funnelSteps: IFunnelStep[];
  meta?: Meta;
  type?: "visitor" | "user" | "company";
  autoHeight?: boolean;
}

export interface Meta {
  total_visitors: number;
  total_converted: number;
  conversion_rate: number;
  most_drop_off_step: MostDropOffStep;
}

export interface MostDropOffStep {
  name: string;
  percentage: number;
}

const StepsFunnel = ({ funnelSteps, type, autoHeight = false }: IStepsFunnel) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);
  const intl = useIntl();

  const resolvedType = useMemo(() => {
    if (!type || type === "visitor") {
      return intl.formatMessage({
        id: "insights.type.visitors",
        defaultMessage: "Visitors"
      });
    } else if (type === "user") {
      return intl.formatMessage({
        id: "insights.type.users",
        defaultMessage: "Users"
      });
    }
    return intl.formatMessage({
      id: "insights.type.companies",
      defaultMessage: "companies"
    });
  }, [type]);

  const funnelChartOption = useFunnelChartOption(theme, isMobileView);

  const dropOffLabelPosition = useMemo(() => {
    if (funnelSteps.length <= 2) {
      return ["0%", "135%"];
    }
    if (funnelSteps.length === 3) {
      return ["0%", "128%"];
    }
    if (funnelSteps.length === 4) {
      return ["0%", "120%"];
    }
    return ["0%", "110%"];
  }, [funnelSteps]);

  const chartOption = useMemo(() => {
    return {
      ...funnelChartOption,
      tooltip: {
        ...funnelChartOption.tooltip,
        formatter: function (params: any) {
          const data = funnelSteps[params.dataIndex];

          const header = `<div style="
                              color: ${colorScheme === "dark" ? theme.colors.dark[0] : "#000d21"};
                              font-size: 12px;
                              font-weight: 600;
                              font-family: 'Inter', sans-serif;
                              background: ${
                                colorScheme === "dark"
                                  ? theme.colors.dark[6]
                                  : "rgba(240, 240, 240, 0.4)"
                              };
                              border-bottom: 1px solid rgba(204, 204, 212, 0.31);
                              border-color: ${
                                colorScheme === "dark"
                                  ? theme.colors.dark[6]
                                  : "rgba(204, 204, 212, 0.31)"
                              };
                              padding: 0.75rem 1rem;
                            ">${data.name}</div>`;

          const values = [
            {
              name: intl.formatMessage({
                id: "insights.charts.tooltip.completedStep",
                defaultMessage: "Completed Step"
              }),
              value: numberToCommas(data.visitors),
              color: theme.colors.green[7]
            }
          ];

          if (data.conv_rate_prev_step !== null) {
            values.push({
              name: intl.formatMessage({
                id: "insights.charts.tooltip.completedStepRatio",
                defaultMessage: "Completed Step Ratio"
              }),
              value: data.conv_rate_prev_step + "%",
              color: theme.colors.green[7]
            });
          } else if (params.dataIndex === 0 && data.conv_rate_prev_step === null) {
            values.push({
              name: intl.formatMessage({
                id: "insights.charts.tooltip.completedStepRatio",
                defaultMessage: "Completed Step Ratio"
              }),
              value: "100%",
              color: theme.colors.green[7]
            });
          }

          if (data.drop_off_count_prev_step && data.drop_off_count_prev_step > 0) {
            values.push({
              name: intl.formatMessage({
                id: "insights.charts.tooltip.dropOffFromPreviousStep",
                defaultMessage: "Drop Off (from previous step)"
              }),
              value: numberToCommas(data.drop_off_count_prev_step),
              color: theme.colors.red[7]
            });
          }

          if (data.drop_off_rate_prev_step !== null) {
            values.push({
              name: intl.formatMessage({
                id: "insights.charts.tooltip.dropOffRatioFromPreviousStep",
                defaultMessage: "Drop Off Ratio (from previous step)"
              }),
              value: data.drop_off_rate_prev_step + "%",
              color: theme.colors.red[7]
            });
          }

          if (data.drop_off_rate_from_first_step && data.drop_off_rate_from_first_step > 0) {
            values.push({
              name: intl.formatMessage({
                id: "insights.charts.tooltip.dropOffRatioFromFirstStep",
                defaultMessage: "Drop Off Ratio (from first step)"
              }),
              value: data.drop_off_rate_from_first_step + "%",
              color: theme.colors.red[7]
            });
          }

          if (data.avg_time_in_s) {
            values.push({
              name: intl.formatMessage({
                id: "insights.charts.tooltip.avgTime",
                defaultMessage: "Avg. Time in Step"
              }),
              value: convertSecondsToReadableFormat(data.avg_time_in_s),
              color: theme.colors.blue[6]
            });
          }

          return `
               <div style='
                margin: -10px;
                background-color: ${colorScheme === "dark" ? theme.colors.dark[5] : "#FFF"};
               '>
                  ${header}
                  <div style='
                    display: flex;
                    flex-direction: column;
                    padding: 0.5rem 0;
                  '>
                    ${values
                      .map(
                        (item) =>
                          `
                        <div style='
                          padding: 0.2rem 1rem;
                          display: flex;
                          line-height: 1.25rem;
                          font-size: 0.9325rem;
                          gap: 16px;
                        '>
                          <div style="
                                display: flex;
                                flex-grow: 1;
                                font-family: 'Inter', sans-serif;
                                color: ${item.color}
                              "
                          >
                              ${item.name}
                          </div>
                          <div style="
                            font-weight: 700;
                            font-family: 'Inter', sans-serif;
                            color: ${colorScheme === "dark" ? theme.colors.dark[0] : "#000d21"}
                           "
                           >
                              ${item.value}
                          </div>
                        </div>
                        `
                      )
                      .join(" ")}
                  </div>
                </div>
              `;
        }
      },
      yAxis: {
        ...funnelChartOption.yAxis,
        data: funnelSteps.map((step: any) => step.name)
      },
      series: [
        {
          ...funnelChartOption.series[0],
          data: funnelSteps.map((step) => step.visitors),
          label: {
            ...funnelChartOption.series[0].label,
            position: dropOffLabelPosition,
            show: funnelSteps.length <= 5,
            formatter: function (params: any) {
              let text = "";
              let time = "";
              if (
                params.dataIndex !== funnelSteps.length - 1 &&
                funnelSteps[params.dataIndex + 1]
              ) {
                const dropOffRate =
                  (funnelSteps[params.dataIndex + 1]["drop_off_rate_prev_step"] || 0) + "%";
                const label = intl.formatMessage({
                  id: "insights.charts.label.dropOff",
                  defaultMessage: "Drop Off"
                });
                text = `↓ {a|${dropOffRate}} ${label}`;

                // Add average time spent on step
                if (funnelSteps[params.dataIndex + 1].avg_time_in_s) {
                  time = `${convertSecondsToReadableFormat(
                    funnelSteps[params.dataIndex + 1].avg_time_in_s || 0
                  )}`;

                  time = `    ⏱ {b|${time}}`;
                }
              }

              // Skipping the last step
              if (funnelSteps[params.dataIndex + 1]) {
                return `${text} ${time}`;
              } else {
                // is the last step
                const converted = (funnelSteps[params.dataIndex]["conv_rate_prev_step"] || 0) + "%";
                const label = intl.formatMessage({
                  id: "insights.charts.label.converted",
                  defaultMessage: "Converted"
                });
                text = `{a|${converted}} {c|${label}}`;

                return text;
              }

              return "";
            }
          }
        },
        {
          ...funnelChartOption.series[1],
          data: funnelSteps.map(
            (step, index) => funnelSteps[index - 1]?.visitors - step.visitors || 1
          ),
          label: {
            ...funnelChartOption.series[1].label,
            show: true,
            formatter: function (params: any) {
              let visitors: number | string = funnelSteps[params.dataIndex].visitors || 0;

              visitors = visitors.toLocaleString("en-US", {
                notation: "compact",
                compactDisplay: "short"
              });

              const capitalizedType = resolvedType.charAt(0).toUpperCase() + resolvedType.slice(1);

              const visitorsLabel = `{a|${visitors} ${capitalizedType}}`;

              if (funnelSteps.length <= 5) {
                return visitorsLabel;
              }

              const dropOffRate =
                (funnelSteps[params.dataIndex]["drop_off_rate_prev_step"] || 0) + "%";

              let time: string | number = funnelSteps[params.dataIndex].avg_time_in_s || 0;
              time = time
                ? `    ⏱ {c|${convertSecondsToReadableFormat(time)}}`
                : "    ⏱ {c|No time}";

              const label = `↓ {b|${dropOffRate}} Drop Off`;

              if (params.dataIndex === 0) {
                return visitorsLabel;
              }

              return `${visitorsLabel} ${label} ${time}`;

              return "";
            }
          }
        }
      ]
    };
  }, [funnelChartOption]);

  /**
   * Calculate the height of the chart based on the number of steps
   * min-height: 500px
   */
  const chartHeight = useMemo(() => {
    if (funnelSteps.length === 0) return 350;

    console.log("chartHeight", funnelSteps.length);

    const height = funnelSteps.length * 50 + 100;

    if (height < 290) return 290;

    return height;
  }, [funnelSteps]);

  const ref = useRef();
  return (
    <Box
      style={{
        width: "100%",
        minHeight: autoHeight ? `${chartHeight}px` : "auto",
        height: autoHeight ? "auto" : "100%"
      }}
      onClick={() => {
        if (ref.current) {
          console.log(chartOption);
        }
      }}>
      <ReactEChartsCore
        onChartReady={(echarts) => {
          ref.current = echarts;
        }}
        echarts={echarts}
        option={chartOption}
        style={{
          height: autoHeight ? `${chartHeight}px` : "100%",
          width: "100%"
        }}
        notMerge={true}
        lazyUpdate={true}
      />
    </Box>
  );
};

export default StepsFunnel;
