import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { SmallBulletListSkeleton } from "@/lib/utils/ChartsSkeletons";
import { getRelativeDateToNow } from "@/lib/utils/DateUtility";
import { stringToInitials } from "@/lib/utils/StringUtility";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import { getSeedColor } from "@/style/ColorSeeding";
import { getRandomColor } from "@/style/Colors";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Loader,
  Paper,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { format } from "date-fns";
import React, { Fragment, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
interface ICard {
  cardTitle: string;
  isUserOrCompanyListLoading: string;
  userOrCompanyList: any[];
  setActiveDetailViewName: (e: string) => void;
  setShowUserOrCompanyDetailedViewModal: (e: boolean) => void;
  reportType?: string;
}

const UserOrCompanyDetailViewCard: React.FC<ICard> = ({
  cardTitle,
  isUserOrCompanyListLoading,
  userOrCompanyList,
  reportType,
  setActiveDetailViewName,
  setShowUserOrCompanyDetailedViewModal
}) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const navigate = useNavigate();
  const { colorScheme } = useMantineColorScheme();
  // colors for the avatar
  const colors = useMemo(() => {
    return Array.from(Array(15).keys()).map((i) => getRandomColor());
  }, []);

  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);

  const RenderRow = ({ item, index }: any) => {
    const { hovered, ref } = useHover();
    return (
      <>
        <Fragment key={`user-or-company-${item.id}`}>
          <Group justify="space-between" grow my={8} ref={ref}>
            <Flex align={"center"} w={"100%"}>
              <Box
                onClick={() => {
                  setOverviewModalOpen(true, reportType as any, item.id);
                }}
                style={{
                  cursor: "pointer",
                  opacity: hovered ? 0.8 : 1
                }}
                w={"40%"}>
                <Flex align={"center"}>
                  <Avatar
                    td="none"
                    size={20}
                    variant={"filled"}
                    radius={"xl"}
                    mr={8}
                    component="div"
                    styles={{
                      placeholder: {
                        backgroundColor: getSeedColor(item.id)
                      }
                    }}
                    tt="uppercase">
                    {stringToInitials(item.name)}
                  </Avatar>
                  <Text
                    tt="capitalize"
                    pr={8}
                    fz="xs"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                      textDecoration: hovered ? "underline" : undefined
                    }}>{`${item.name}`}</Text>
                </Flex>
              </Box>
              <Box w={"30%"}>
                <Tooltip
                  style={{ fontSize: "10px" }}
                  label={format(new Date(item.feature_first_used), "dd MMM yyyy hh:mm")}
                  position="right"
                  withArrow>
                  <Text fz="xs" c="dimmed">
                    {getRelativeDateToNow(item.feature_first_used).replace("about", "")}
                  </Text>
                </Tooltip>
              </Box>
              <Box w={"30%"}>
                <Tooltip
                  style={{ fontSize: "10px" }}
                  label={format(new Date(item.feature_last_used), "dd MMM yyyy hh:mm").replace(
                    "about",
                    ""
                  )}
                  position="right"
                  withArrow>
                  <Text fz="xs" c="dimmed">
                    {getRelativeDateToNow(item.feature_last_used).replace("about", "")}
                  </Text>
                </Tooltip>
              </Box>
            </Flex>
          </Group>
          <Divider />
        </Fragment>
      </>
    );
  };

  return (
    <Paper shadow="sm" h={320} p={15} className="" mb="xl">
      <Flex justify={"center"} align={"center"} mb={8}>
        {cardTitle === "Used" ? (
          <Box w={16} c="yellow.6" mr={"sm"}>
            <FontAwesomeIcon size="1x" icon={solid("hand-point-up")} />
          </Box>
        ) : cardTitle === "Adopted" ? (
          <Box w={16} c="green.6" mr={"sm"}>
            <FontAwesomeIcon size="1x" icon={solid("thumbs-up")} />
          </Box>
        ) : (
          <Box w={16} c="brand" mr={"sm"}>
            <FontAwesomeIcon size="1x" icon={solid("face-smile")} />
          </Box>
        )}

        <Text fz="sm" fw={500}>
          <Text span fw={600} pr={"xs"}>
            {cardTitle}
          </Text>
          {cardTitle === "Used" && (
            <>
              <Tooltip
                fw={400}
                multiline
                w={400}
                label="This list includes users who have used the feature but have not yet adopted it. We only show users that are not in future steps">
                <FontAwesomeIcon size="xs" icon={regular("info-circle")} />
              </Tooltip>
            </>
          )}

          {cardTitle === "Adopted" && (
            <>
              <Tooltip
                fw={400}
                multiline
                w={400}
                label="This list displays users who have adopted the feature but are not yet in the retention phase. We only show users that are not in future steps">
                <FontAwesomeIcon size="xs" icon={regular("info-circle")} />
              </Tooltip>
            </>
          )}
        </Text>
      </Flex>

      {isUserOrCompanyListLoading === "loading" ? (
        <Flex align={"center"} h={200} justify={"center"}>
          <Loader type="bars" size={"xs"} />
        </Flex>
      ) : userOrCompanyList.length === 0 ? (
        <Box component="div">
          <Flex align={"center"} justify={"center"} py={16}>
            <NoResults text={`There are no ${cardTitle} user(s).`} heading={"No results"} />
            {/* <Text c="dimmed" align="center">
              No users available.
            </Text> */}
          </Flex>
        </Box>
      ) : (
        <>
          <Box
            mt={10}
            // p={20}
          >
            <Flex justify={"space-between"} direction={"column"}>
              <>
                {userOrCompanyList.length > 0 && (
                  <>
                    <Group justify="space-between" grow mb={8}>
                      <Flex align={"center"} w={"100%"}>
                        <Box w={"40%"}>
                          <Text fz="xs" tt="capitalize">
                            {reportType}
                          </Text>
                        </Box>
                        <Box w={"30%"}>
                          <Text fz="xs">First Used</Text>
                        </Box>
                        <Box w={"30%"}>
                          <Text fz="xs">Last Used</Text>
                        </Box>
                      </Flex>
                    </Group>
                    <Divider />
                  </>
                )}
                {userOrCompanyList.map((item: any, index) => (
                  <RenderRow item={item} index={index} />
                ))}
              </>
            </Flex>
          </Box>
          <Flex justify={"center"} align={"center"} mt={16}>
            {userOrCompanyList.length > 0 && userOrCompanyList.length >= 5 && (
              <Button
                size="xs"
                variant={"subtle"}
                onClick={() => {
                  setActiveDetailViewName(cardTitle);
                  setShowUserOrCompanyDetailedViewModal(true);
                }}>
                View full list
              </Button>
            )}
          </Flex>
        </>
      )}
    </Paper>
  );
};

export default UserOrCompanyDetailViewCard;
