import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceMiscService } from "@/lib/services/WorkspaceMiscService";
import { Box, Switch, Title } from "@mantine/core";
import { useContext, useState } from "react";

export const MiscellaneousExcludeServerSideEvents = () => {
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [isExclusionEnabled, setIsExclusionEnabled] = useState(
    activeWorkspace.is_exclude_server_side_events ? true : false
  );

  const handleChange = (value: boolean) => {
    new WorkspaceMiscService()
      .toggleServerSideEvents(activeWorkspace.id, value)
      .then((res) => {
        if (res.data) {
          setActiveWorkspace({ ...activeWorkspace, is_exclude_server_side_events: value });
          setIsExclusionEnabled(!isExclusionEnabled);
        }
      })
      .catch((err) => err);
  };

  return (
    <>
      <Title order={4} fw={600} mb="md">
        Exclude server side events from website analytics{" "}
      </Title>
      <Switch
        size={"sm"}
        label="Keep this option enabled if you want to exclude server side events from website analytics"
        checked={isExclusionEnabled}
        onChange={(event: any) => handleChange(event.currentTarget.checked)}></Switch>
    </>
  );
};
