import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState } from "react";
import { Box, Button, Divider, Flex, Group, Image, MantineSize, Menu, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { MOBILE_BREAKPOINT_QUERY } from "@/lib/utils/Constants";
import LazyLoad from "react-lazyload";

export type insightMenuItem = {
  label: string;
  value: string;
  description: string;
  image: any;
};

interface Props {
  /**
   * Array of options to be displayed in the dropdown menu
   */
  options: Array<insightMenuItem>;
  /**
   * onChange handler for the dropdown menu, this will be called when an item is selected
   */
  onChange: (item: insightMenuItem | undefined) => void;
  /**
   * Direction of the items in the dropdown menu, horizontal means the items will be displayed in a row
   * and vertical means the items will be displayed in a column. In mobile view, the direction will always be vertical
   */
  direction?: "horizontal" | "vertical";
  /**
   * Size of the button in Mantine UI
   */
  size: MantineSize | undefined;
}

export const DetailedDropdownMenu = ({
  options = [],
  onChange,
  direction = "vertical",
  size = "xs"
}: Props) => {
  const [opened, setOpened] = useState(false);
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  const menuDirection = isMobileView ? "vertical" : direction;

  const handleOnChange = (item: insightMenuItem | undefined) => {
    onChange(item);
  };
  return (
    <>
      <Menu shadow="md" opened={opened} onChange={setOpened}>
        <Menu.Target>
          <Button
            // variant="gradient"
            onClick={() => setOpened((o) => !o)}
            mr={"sm"}
            size={size}
            rightSection={
              <>
                <Divider orientation="vertical" mr="xs" color="#9999a9" />
                <FontAwesomeIcon icon={opened ? solid("angle-up") : solid("angle-down")} />
              </>
            }>
            Create New
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Flex direction={menuDirection === "horizontal" ? "row" : "column"}>
            {options.map((option: any, index: number) => (
              <Fragment key={`insightMenu:${option.label}`}>
                <Menu.Item
                  key={`insightMenu:${index}`}
                  onClick={() => handleOnChange(option)}
                  sx={{
                    alignItems: "flex-start"
                  }}>
                  <Group gap={0}>
                    <Box h={80} w={80} mr={"sm"}>
                      <Image height={80} width={80} src={option.image} alt="" />
                    </Box>
                    <Flex
                      direction="column"
                      key={index}
                      maw={170}
                      p="xs"
                      // ml={index != 0 && menuDirection === "horizontal" ? "xs" : 0}
                    >
                      <Text pb={4} size="sm" fw={500}>
                        {option.label}
                      </Text>
                      <Text size="xs" c="dimmed">
                        {option.description}
                      </Text>
                    </Flex>
                  </Group>
                </Menu.Item>
                {index + 1 !== options.length && (
                  <Menu.Divider
                    key={`divider:${index}`}
                    sx={{
                      margin: menuDirection === "horizontal" ? "0 1px" : "1px 0",
                      borderTop: menuDirection === "horizontal" ? "none" : "",
                      borderLeft: menuDirection === "horizontal" ? "1px solid #e9ecef" : ""
                    }}
                  />
                )}
              </Fragment>
            ))}
          </Flex>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
