import { Box } from "@mantine/core"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { CompaniesService } from "@/lib/services/CompaniesService"
import { FC, useContext, useEffect, useState } from "react"
import { Oval } from "react-loader-spinner"
import { useParams } from "react-router-dom"
import { loaderColor } from "@/style/Colors"
import { LooseObject } from "types/types.d"
import { AttributeItem } from "@/ui/components/Common/AttributeItem/AttributeItem"

export const CompanyAttributes: FC<{}> = () => {
  const { companyId } =
    useParams<{
      companyId: string
    }>()
  const [isLoading, setIsLoading] = useState("idle")
  const { activeWorkspace } = useContext(AppLifecycleContext)
  const [attributes, setAttributes] = useState<any>({})

  const fetchCompanyAttributes = async () => {
    setIsLoading("loading")
    const companyService = new CompaniesService()
    await companyService
      .attributesById(activeWorkspace.id, companyId)
      .then(res => {
        if (res.data) {
          setAttributes(res.data)
        }
      })
      .catch(err => {})
    setIsLoading("loaded")
  }
  const getCustomAttributes = (): any => {
    let customAttributesList: Array<any> = []
    Object.entries(attributes?.company_custom_map).forEach((value, key) => {
      customAttributesList.push({
        key: value[0].replace("company_custom_", ""),
        value: value[1],
        cast_type: typeof value[1] || "",
      })
    })
    console.log(customAttributesList)
    return customAttributesList
  }
  useEffect(() => {
    fetchCompanyAttributes()
  }, [])
  return (
    <>
      <Box
        className="border-gray-200 border border-solid"
        sx={theme => ({
          borderColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "",
        })}
      >
        <h3 className="font-semibold py-2 px-4 border-b border-solid border-gray-200 bg-gray-100">
          Attributes
        </h3>
        <div className=" max-h-screen overflow-y-auto bg-white">
          {isLoading === "loaded" ? (
            <>
              <AttributeItem
                attribute_key="id"
                attribute_value={attributes?.company_id}
                attribute_type="Identifier"
                attribute_cast_type="string"
              />
              <AttributeItem
                attribute_key="name"
                attribute_value={attributes?.company_name}
                attribute_type="Required"
                attribute_cast_type="string"
              />
              <AttributeItem
                attribute_key="created_at"
                attribute_value={attributes?.company_created_at}
                attribute_type="Required"
                attribute_cast_type="string"
              />
              {getCustomAttributes().map((attributeItem: LooseObject, index: number) => (
                <>
                  <AttributeItem
                    key={`${attributeItem.key}-cattribute-${index}`}
                    attribute_key={attributeItem.key}
                    attribute_value={attributeItem.value}
                    attribute_type=""
                    attribute_cast_type={attributeItem.cast_type}
                  />
                </>
              ))}
            </>
          ) : (
            <>
              <div className="flex items-center justify-center flex-grow w-full h-20">
                <Oval color={loaderColor} height={12} width={12} />
              </div>
            </>
          )}
        </div>
      </Box>
    </>
  )
}
