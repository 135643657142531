import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Anchor,
  Badge,
  Box,
  Button,
  Flex,
  Loader,
  Text,
  ThemeIcon,
  Timeline,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { formatRelative } from "date-fns";
import parse from "html-react-parser";
import { getACEventType } from "@/lib/utils/AutoCaptureUtility";
import {
  ClickhouseDateToLocalRelativeTime,
  locale,
  secondsToDuration,
  utcToTime
} from "@/lib/utils/DateUtility";
import { getDeviceIcon, getOSIcon } from "@/lib/utils/IconsUtility";
import React, { useEffect, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { loaderColor } from "@/style/Colors";
import { IActivity, IActivityIcon, IEvent } from "./ActivitiesTab";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface IActivityAccordion {
  activity: IActivity;
  isActive: boolean;
  type?: "company" | "user" | "visitor";
  onFetchEvents?: (sessionId: string, page: number) => Promise<IEvent[] | []>;
}

/**
 * This component will group the activities by the user session.
 */
const ActivityAccordion = ({
  activity,
  isActive,
  type,
  onFetchEvents = () => Promise.resolve([])
}: IActivityAccordion) => {
  const page = useRef(1);
  const [loading, setLoading] = React.useState(true);
  const [events, setEvents] = React.useState<IEvent[]>([]);
  const [hasMore, setHasMore] = React.useState(true);

  useEffect(() => {
    if (isActive) {
      resetEvents();
      fetchEvents();
    }
  }, [isActive]);

  // reset the events when the accordion is closed
  const resetEvents = () => {
    setEvents([]);
    page.current = 1;
    setHasMore(true);
  };

  const parseEventAttributes = (event: IEvent) => {
    try {
      if (event?.event_attributes) {
        return JSON.parse(event?.event_attributes);
      }
    } catch (e) {
      return {};
    }
  };

  const parseTitle = (event: IEvent) => {
    if (event.event_type === "pageview") {
      return (
        <Text span fz={13}>
          Viewed page titled{" "}
          <Text span fw={500} fz={13}>
            {event.page_title}
          </Text>{" "}
        </Text>
      );
    }

    if (event.event_type === "$autocapture") {
      return (
        <Flex align={"center"}>
          <Text span fz={13}>
            {getACEventType({
              event_id: event.event_id,
              ac_el_text: event.ac_el_text,
              ac_event_type: event.ac_event_type,
              ac_href: event.ac_href,
              ac_id: event.ac_id,
              ac_name: event.ac_name,
              ac_tag_name: event.ac_tag_name,
              url: event.url,
              _timestamp: event._timestamp
            })}
          </Text>
          <Text span>
            <Badge variant="light" size="xs" radius={"sm"} ml={8}>
              Autocaptured
            </Badge>
          </Text>
        </Flex>
      );
    }

    if (event.event_type === "$pageleave") {
      return <Text fz={13}>Left page {event.page_title}</Text>;
    }

    if (event.event_type === "$scroll") {
      return (
        <>
          <Text fz={13}>
            Performed page scroll
            <Badge variant="light" size="xs" radius={"sm"} ml={4}>
              {parseEventAttributes(event)?.percent || 0}%
            </Badge>
          </Text>
        </>
      );
    }

    return (
      <Text>
        Performed an event{" "}
        <Badge variant="light" size="xs" radius={"sm"} ml={2}>
          {event.event_type}
        </Badge>
      </Text>
    );
  };

  /**
   * Method to fetch the events from the server against the session id.
   * This same method will be used to fetch the next page of events.
   */
  const fetchEvents = async () => {
    setLoading(true);
    const events = await onFetchEvents(activity.session_id, page.current);

    if (events) {
      setEvents((prevState) => [...prevState, ...events]);

      if (events.length < 10) {
        setHasMore(false);
      }

      page.current++;
    }

    setLoading(false);
  };

  return (
    <>
      <Accordion.Item value={activity.session_id}>
        <Accordion.Control>
          <Box>
            <Flex justify="space-between" align="center">
              <Text
                style={{
                  display: "flex",
                  flexWrap: "wrap"
                }}>
                Visit from
                <Tooltip label={activity.source} position="top" withArrow withinPortal>
                  <Box
                    style={{
                      display: "block",
                      fontWeight: 600,
                      maxWidth: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      marginLeft: "5px"
                    }}>
                    {activity.source}
                  </Box>
                </Tooltip>
                <Text span ml={5}>
                  that lasted
                </Text>
                <Box ml={5} fw={600}>
                  {secondsToDuration(activity.duration_in_s)}
                </Box>
              </Text>

              <Tooltip
                withArrow
                position="left"
                label={`${formatRelative(
                  new Date(activity.session_start_timestamp.replace(" ", "T")),
                  new Date(),
                  { locale }
                )} - ${utcToTime(activity.session_start_timestamp)}`}>
                <Text fz="xs">
                  <>{ClickhouseDateToLocalRelativeTime(activity.session_start_timestamp)}</>
                </Text>
              </Tooltip>
            </Flex>

            <Box>
              <DeviceInfo
                os={activity.parsed_ua_os_family}
                browser={activity.parsed_ua_ua_family}
                device={activity.parsed_device}
                url={activity.first_url}
              />

              <Box
                className="um-activity-detail-box"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  "& > *:not(:last-child)": {
                    marginRight: "0.5rem"
                  }
                }}>
                {activity.pageviews > 0 && (
                  <Badge size="lg" variant="light" radius="sm" tt="capitalize" fw={600} fz={12}>
                    {activity.pageviews} pageviews
                  </Badge>
                )}
                {activity.autocatured_events > 0 && (
                  <Badge size="lg" variant="light" radius="sm" tt="capitalize" fw={600} fz={12}>
                    {activity.autocatured_events} auto-captured events
                  </Badge>
                )}
                {activity.custom_events > 0 && (
                  <Badge size="lg" variant="light" radius="sm" tt="capitalize" fw={600} fz={12}>
                    {activity.custom_events} custom events
                  </Badge>
                )}
              </Box>
            </Box>
          </Box>
        </Accordion.Control>
        <Accordion.Panel>
          {events && events.length > 0 && (
            <>
              <Timeline bulletSize={30} lineWidth={1}>
                {events.map((event: IEvent, index: number) => {
                  return (
                    <Timeline.Item
                      key={index}
                      bullet={<ActivityIcon eventType={event.event_type} />}
                      title={
                        <Flex align={"center"} justify={"space-between"}>
                          <Text fz={13}>{parseTitle(event)}</Text>
                          <Text fz={"xs"}>{utcToTime(event._timestamp.replace(" ", "T"))}</Text>
                        </Flex>
                      }
                      lineVariant={"dashed"}>
                      {event.event_type === "pageview" ? (
                        <Anchor fz="xs" href={event.url} target={"_blank"} c="dimmed">
                          {`${event.doc_path}${event.doc_search}`}
                        </Anchor>
                      ) : (
                        <Text fz={"xs"} c="dimmed">
                          Source Page URL:{" "}
                          <Anchor c="dimmed" href={event.source_page_url} target={"_blank"} fz="xs">
                            {event?.source_page_url}
                          </Anchor>
                        </Text>
                      )}
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </>
          )}

          {loading ? (
            <>
              <Flex align={"center"} justify={"center"} h={40}>
                <Loader size={"xs"} />
              </Flex>
            </>
          ) : (
            <>
              {hasMore ? (
                <Flex
                  align={"center"}
                  justify={"center"}
                  flex={1}
                  w={"100%"}
                  h={40}
                  className="flex items-center justify-center flex-grow w-full h-80">
                  <Button size="xs" variant="light" onClick={fetchEvents}>
                    Load More
                  </Button>
                </Flex>
              ) : events.length === 0 ? (
                <div className="flex flex-grow w-full h-8 text-gray-800 ml-8">
                  <Text>No events found</Text>
                </div>
              ) : null}
            </>
          )}
        </Accordion.Panel>
      </Accordion.Item>
    </>
  );
};

/**
 * Get the activity icon component.
 * Using pure component to avoid unnecessary re-rendering and also as we are rendering the same result given the same
 * props and state
 * @param eventType
 * @constructor
 */
const ActivityIcon = React.memo(({ eventType }: IActivityIcon) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  switch (eventType) {
    case "pageview":
      return (
        <ThemeIcon
          c={colorScheme === "dark" ? theme.colors.dark[9] : "#ffb01d"}
          bg={colorScheme === "dark" ? theme.colors.dark[4] : "#fff5d9"}
          size={32}
          radius="xl">
          <FontAwesomeIcon icon={solid("eye")} />
        </ThemeIcon>
      );

    case "$pageleave":
      return (
        <ThemeIcon
          c={colorScheme === "dark" ? theme.colors.dark[9] : "red.4"}
          bg={colorScheme === "dark" ? theme.colors.dark[4] : "red.2"}
          size={32}
          radius="xl">
          <FontAwesomeIcon icon={solid("right-from-bracket")} />
        </ThemeIcon>
      );
    case "user_identify":
      return (
        <ThemeIcon
          c={colorScheme === "dark" ? theme.colors.dark[9] : "#43d783"}
          bg={colorScheme === "dark" ? theme.colors.dark[4] : "#dff8ea"}
          size={32}
          radius="xl">
          <FontAwesomeIcon icon={solid("user")} />
        </ThemeIcon>
      );

    case "$scroll":
      return (
        <ThemeIcon
          c={colorScheme === "dark" ? theme.colors.dark[9] : "#74bce9"}
          bg={colorScheme === "dark" ? theme.colors.dark[4] : "#e5f5fb"}
          size={32}
          radius="xl">
          <FontAwesomeIcon icon={solid("computer-mouse-scrollwheel")} />
        </ThemeIcon>
      );

    default:
      return (
        <ThemeIcon
          c={colorScheme === "dark" ? theme.colors.dark[9] : "#74bce9"}
          bg={colorScheme === "dark" ? theme.colors.dark[4] : "#e5f5fb"}
          size={32}
          radius="xl">
          <FontAwesomeIcon icon={solid("arrow-pointer")} className="transform " />
        </ThemeIcon>
      );
  }
});

/**
 * Pure component to render the device information for the activity.
 * @param os
 * @param browser
 * @param url
 * @constructor
 */
const DeviceInfo = React.memo(
  ({
    os,
    browser,
    url = "",
    device = "Desktop"
  }: {
    os: string;
    browser: string;
    url?: string;
    device: string;
  }) => {
    const { colorScheme } = useMantineColorScheme();
    return (
      <Flex
        py={"xs"}
        align={"center"}
        sx={
          {
            // display: "flex",
            // alignItems: "center",
            // color: "#6b7280",
            // fontSize: "1rem",
            // margin: "0.8rem 0",
            // "& > *:not(:last-child)": {
            //   marginRight: "0.5rem"
            // }
          }
        }>
        <Tooltip label={device} position="top" withArrow withinPortal>
          <Box w={16} fz="xs" c={colorScheme === "dark" ? "dark.4" : "gray.6"}>
            {getDeviceIcon(device)}
          </Box>
        </Tooltip>

        <Tooltip label={os} position="top" withArrow withinPortal>
          <Box w={16} fz="sm" c={colorScheme === "dark" ? "dark.4" : "gray.6"} ml={4}>
            {getOSIcon(os)}
          </Box>
        </Tooltip>
        <Text
          size="xs"
          c="dimmed"
          className="line-clamp-1 leading-[1.15] text-sm max-w-3xl break-all">
          {url}
        </Text>
      </Flex>
    );
  }
);

export default ActivityAccordion;
