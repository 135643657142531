import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
import { WorkspaceType } from "@/types/types.d";
import { WorkspaceObject } from "@/ui/components/App/WorkspaceDropdown/WorkspacesDropdown";

export const getWorkspaceAvatarURL = (
  workspace: WorkspaceObject | WorkspaceType | any,
  size: null | number = null,
  colorScheme: any = "light"
) => {
  if (workspace.logo) {
    return workspace.logo;
  }

  if (!workspace.website) {
    return "";
  }
  const URL = workspace.website.replace("http://", "").replace("https://", "").split(/[/?#]/)[0];

  return (
    `https://favicon.usermaven.com/?url=${URL}&fallback=${
      colorScheme === "dark" ? "default-white.svg" : "default.svg"
    }` + (size ? `&size=${size}` : "")
  );
};
