/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { convertSecondsToReadableFormat } from "@/lib/utils/StringUtility";
import React from "react";
import { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import { SummaryCard } from "../SummaryCard";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import ContentLoader from "react-content-loader";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

// const waService = new WebAnalyticsService();
type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  topBarCardValues: ITopBarCardValues;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
  period: any;
  prevPeriod: any;
  comparisonPeriod?: any;
};

const VisitDurationCard = ({
  activeCard,
  setActiveCard,
  topBarCardValues,
  comparisonTopBarCardValues,
  loading,
  query,
  period,
  prevPeriod,
  comparisonPeriod
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const getComparisonPercentageDiff = (currentValue: any, comparisonValue: any) => {
    const percentageChange = ((currentValue - comparisonValue) / comparisonValue) * 100;
    return Math.round(percentageChange);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="visit-duration-card">
      {loading === "loaded" ? (
        <SummaryCard
          aboutTooltip="Visit Duration represents the average session duration. It is calculated by dividing the total duration of all sessions (in seconds) by the total number of sessions."
          activeCard={activeCard}
          activeCardColor={"green.6"}
          title={"Visit Duration"}
          onClick={() => setActiveCard("visit_duration")}
          uniquekey={"visitDuration"}
          card={"visit_duration"}
          value={`${
            convertSecondsToReadableFormat(topBarCardValues.visit_duration) === "nulls"
              ? 0
              : convertSecondsToReadableFormat(topBarCardValues.visit_duration)
          }`}
          tooltip={`${
            convertSecondsToReadableFormat(topBarCardValues.visit_duration) === "nulls"
              ? 0
              : convertSecondsToReadableFormat(topBarCardValues.visit_duration)
          } average time spent on website across all pages`}
          runningDiff={topBarCardValues.visit_duration_diff}
          runningDiffTooltip={`${topBarCardValues.visit_duration_diff}% ${
            topBarCardValues.visit_duration_diff > 0 ? "increase" : "decrease"
          } in visits duration from previous period ${
            comparisonPeriod ? comparisonPeriod : prevPeriod
          } `}
          loading={loading}
          showRunningDiff={query?.period !== "live"}
          comparison={query?.comparison}
          comparisonPercentageDiff={getComparisonPercentageDiff(
            topBarCardValues.visit_duration,
            comparisonTopBarCardValues.visit_duration
          )}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const VisitDurationCardMemoized = React.memo(VisitDurationCard);
export { VisitDurationCardMemoized as VisitDurationCard };
