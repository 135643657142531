import { Box, Group, Paper, Stack, Text } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  faArrowPointer,
  faClock,
  faEye,
  faUsers,
  faArrowDown
} from "@fortawesome/pro-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useCallback } from "react";
import classes from "./StatsListCard.module.css";
export interface IStatsListCard {
  title: string;
  list: StatsList[];
}

interface StatsList {
  text: string | number | React.ReactNode;
  icon: string;
  bg: string;
  iconColor: string;
  hide?: boolean;
}

const StatsListCard = ({ title = "", list = [] }: IStatsListCard) => {
  const myIcons = {
    clock: faClock,
    eye: faEye,
    users: faUsers,
    "arrow-down": faArrowDown,
    "arrow-pointer": faArrowPointer
  };

  const currentIcon: (icon: string) => any = useCallback((icon: string) => {
    // @ts-ignore
    return myIcons[icon];
  }, []);
  return (
    <Paper withBorder p="md" radius="md">
      <Group justify="space-between" pos={"relative"} mb={"md"}>
        <Text size="xs" c="dimmed" className={classes.title}>
          {title}
        </Text>
      </Group>
      <Stack gap={12}>
        {list.map((item, index) => {
          if (!item.text || item.hide) return null;
          return (
            <Text key={index} size="sm" c="dimmed" className={classes.text}>
              <Box w={16} c={item.bg}>
                <FontAwesomeIcon
                  className={`text-md ${item.iconColor}`}
                  icon={currentIcon(item.icon)}></FontAwesomeIcon>
              </Box>

              <span>{item.text}</span>
            </Text>
          );
        })}
      </Stack>
    </Paper>
  );
};

export default StatsListCard;
