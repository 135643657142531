import create from 'zustand';

interface ICompareAnalyticsProps {
  isCompareAnalyticsSelected: boolean;
  setIsCompareAnalyticsSelected: (newValue: boolean) => void;
}

const useCompareAnalyticsStore = create<ICompareAnalyticsProps>((set) => ({
  isCompareAnalyticsSelected: false,

  setIsCompareAnalyticsSelected: (newValue) => {
    set({ isCompareAnalyticsSelected: newValue });
  },
}));

export default useCompareAnalyticsStore;
