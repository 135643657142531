/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import {
  Avatar,
  Button,
  Box,
  Text,
  Flex,
  Table,
  useMantineTheme,
  Rating,
  Paper,
  Center
} from "@mantine/core";
import { IUserOrCompanyData } from "../../Pages/FeatureAdoption/FeatureDetail";
import { ExportButton } from "@/ui/components/Common/ExportButton/ExportButton";
import { DATE_FORMAT, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { useContext, useEffect, useRef, useMemo, useState } from "react";
import { FeatureAdoptionService } from "@/lib/services/FeatureAdoptionService";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { IReportType, LoadingStateProps } from "@/types/types.d";
import { format, isToday } from "date-fns";
import { downloadCSVFromResponse } from "@/lib/utils/CSVExporterUtility";
import { _getRowValue } from "@/lib/utils/Virtualtable";
import { getRandomColor } from "@/style/Colors";
import { stringToInitials } from "@/lib/utils/StringUtility";
import { useNavigate } from "react-router-dom";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import { getSeedColor } from "@/style/ColorSeeding";

interface IUserOeCompanyDetailView {
  isModalOpen: boolean;
  setIsModalOpen: (e: boolean) => void;
  featureId: string;
  reportType: IReportType;
  dropdownFilterDate: any;
  filters: any;
  activeDetailViewName: string;
}

const UserOrCompanyDetailView = ({
  isModalOpen,
  setIsModalOpen,
  featureId,
  reportType,
  dropdownFilterDate,
  filters,
  activeDetailViewName
}: IUserOeCompanyDetailView) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const navigate = useNavigate();

  const { hasRolesNotWith } = useWorkspaceUtilityHook();
  const [exporting, setIsExporting] = useState("idle");
  const [isUserOrCompanyListLoading, setIsUserOrCompanyListLoading] =
    useState<LoadingStateProps>("idle");
  const [userOrCompanyList, setUserOrCompanyList] = useState<IUserOrCompanyData[]>([]);

  const page = useRef(0);
  const [hasMore, setHasMore] = useState(true);

  type FunnelLevelType = {
    [key: string]: string;
  };

  const funnelLevel: FunnelLevelType = {
    Used: "aware",
    Adopted: "adopted",
    Retained: "retained"
  };
  const funnelLevelValue = funnelLevel[activeDetailViewName]; // Can be aware, adopted or retained

  const featureService = new FeatureAdoptionService();

  const getUserOrCompanyDetails = async () => {
    setIsUserOrCompanyListLoading("loading");

    page.current = page.current + 1;
    const limit = 100;

    await featureService
      .getUsersList(
        activeWorkspace.id,
        featureId,
        funnelLevelValue,
        reportType,
        "list",
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate,
        page.current,
        limit,
        filters.rules,
        filters.combinator,
        filters.groups
      )
      .then((res) => {
        if (res.data) {
          const apiResponse = res.data;
          let dataToShow: IUserOrCompanyData[] = [...userOrCompanyList, ...res.data];
          if (apiResponse.length < 50) {
            setHasMore(false);
          }

          // const dataToShow: IUserOrCompanyData[] = updatedData.sort((a: any, b: any) => b.feature_usage - a.feature_usage);
          setUserOrCompanyList(dataToShow);

          if (apiResponse.length < 50) {
            setHasMore(false);
          }
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsUserOrCompanyListLoading("loaded");
      });
  };

  const exportUserOrCompanyList = async () => {
    setIsExporting("loading");
    try {
      const response = await featureService.getUsersList(
        activeWorkspace.id,
        featureId,
        funnelLevelValue, // Can be aware, adopted or retained
        reportType,
        "csv",
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate,
        1,
        50000,
        filters.rules,
        filters.combinator,
        filters.groups
      );
      if (response.data) {
        // Setting filename of the exported file in accordance with the step no, date and time of export.
        let filename = `${reportType === "user" ? "users" : "companies"}_list_${format(
          new Date(),
          DATE_FORMAT
        )}.csv`;
        //Utility function to download the csv from response.
        downloadCSVFromResponse(response, filename);
        setIsExporting("loaded");
        return true;
      }
    } catch (err) {
      setIsExporting("loaded");
      throw new Error("400");
    }
    return false;
  };

  useEffect(() => {
    if (isModalOpen) {
      setUserOrCompanyList([]);
      page.current = 0;
      setHasMore(true);
      getUserOrCompanyDetails();
    } else {
      setIsUserOrCompanyListLoading("idle");
      setUserOrCompanyList([]);
    }
  }, [isModalOpen, reportType, dropdownFilterDate, filters, activeDetailViewName]);

  // colors for the avatar
  const colors = useMemo(() => {
    return Array.from(Array(100).keys()).map((i) => getRandomColor());
  }, []);

  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);

  return (
    <SidePanel
      opened={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      loading={true}
      hideOverflow={true}
      title={`${reportType === "user" ? "Users" : "Companies"} List - ${activeDetailViewName}`}>
      <div className="flex items-center mb-4">
        <p className="flex-grow flex">
          <div className="flex"></div>
        </p>
        <div className="flex flex-row-reverse pb-2">
          {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
            <ExportButton
              onClick={exportUserOrCompanyList}
              disabled={exporting === "loading"}
              loading={exporting === "loading"}
            />
          )}
        </div>
      </div>
      <Paper>
        <Box
          style={{
            overflowX: "auto",
            whiteSpace: "nowrap"
          }}>
          <Table striped highlightOnHover>
            <Table.Thead fw={600}>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                {reportType === "user" && <Table.Th>Email</Table.Th>}
                <Table.Th ta="center">Usage</Table.Th>
                <Table.Th>First Used</Table.Th>
                <Table.Th>Last Used</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {userOrCompanyList.map((item: any, index) => (
                <Table.Tr
                  className={"group transition-all ease-in-out cursor-pointer"}
                  key={`feature-adoption-user-list:${index}_${item.name}`}>
                  <Table.Td
                    onClick={() => {
                      // navigate(
                      //   `/env/${activeWorkspace.identifier}/${
                      //     reportType === "user" ? "users" : "companies"
                      //   }/everyone/${item.id}`
                      // );
                      setIsModalOpen(false);
                      setOverviewModalOpen(true, reportType, item.id);
                    }}>
                    <Flex align={"center"}>
                      <Avatar
                        size={20}
                        variant={"filled"}
                        radius={"xl"}
                        mr={8}
                        component="div"
                        styles={{
                          placeholder: {
                            backgroundColor: getSeedColor(item.id)
                          }
                        }}
                        className={`uppercase `}>
                        {stringToInitials(item.name)}
                      </Avatar>
                      <Text>{item.name}</Text>
                    </Flex>
                  </Table.Td>
                  {reportType === "user" && <td>{item.email}</td>}
                  <Table.Td>
                    {/* <Flex align={"center"} justify={"center"}>
                      {performancePulse(item.funnel_level)}{" "}
                    </Flex> */}
                    <Flex align={"center"} justify={"center"} mt={8}>
                      {/* <Text className="capitalize" size="xs" c="dimmed" mr={4}>
                        {" "}
                        {item.funnel_level}{" "}
                      </Text> */}
                      <Text size="xs" c="dimmed" fw={600}>
                        Used {item.feature_usage} {item.feature_usage > 1 ? "times" : "time"}
                      </Text>
                    </Flex>
                  </Table.Td>
                  <Table.Td>
                    <Text>{_getRowValue(item.name, item.feature_first_used)}</Text>
                    <Text c="dimmed" fz="xs">
                      {isToday(new Date(item.feature_first_used))
                        ? format(new Date(item.feature_first_used), "HH:mm:ss")
                        : format(new Date(item.feature_first_used), "dd MMM yyyy HH:mm:ss")}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text>{_getRowValue(item.name, item.feature_last_used)}</Text>
                    <Text fz="xs" c="dimmed">
                      {isToday(new Date(item.feature_last_used))
                        ? format(new Date(item.feature_last_used), "HH:mm:ss")
                        : format(new Date(item.feature_last_used), "dd MMM yyyy HH:mm:ss")}
                    </Text>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
          {hasMore && (
            <Center py="lg">
              <Button
                variant="light"
                size="xs"
                loading={isUserOrCompanyListLoading === "loading"}
                disabled={isUserOrCompanyListLoading === "loading"}
                onClick={() => getUserOrCompanyDetails()}>
                Load More
              </Button>
            </Center>
          )}
        </Box>
      </Paper>
    </SidePanel>
  );
};

export default UserOrCompanyDetailView;
