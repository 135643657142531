import EmptyGraphResultsDarkModeIcon from "@assets/images/icons/no-graph-found-dark-mode.svg";
import EmptyGraphResultsIcon from "@assets/images/icons/no-graph-results-found.svg";
import { Flex, Text, Title, useMantineColorScheme } from "@mantine/core";
import React from "react";
import classes from "./EmptyGraphResults.module.css";
interface EmptyGraphProps {
  title?: string;
  description?: string;
}
export const EmptyGraphResults: React.FC<EmptyGraphProps> = ({
  title = "No results",
  description = "There are no results available for your selected period."
}) => {
  const { colorScheme } = useMantineColorScheme();
  return (
    <>
      <Flex direction="column" my="md" className={classes.container}>
        <img
          src={colorScheme === "dark" ? EmptyGraphResultsDarkModeIcon : EmptyGraphResultsIcon}
          alt=""
        />
        <Title ta="center" order={5} mb="sm">
          {title}
        </Title>
        <Text ta="center">{description}</Text>
      </Flex>
    </>
  );
};
