import { brands, regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Flag from 'react-world-flags';
import LazyFlag from "../../ui/components/Common/LazyFlag/LazyFlag";
const appleOs = ["Mac OS X", "iOS"];
const androidOs = ["Android"];
const chromeOs = ["Chrome OS"];
const windowsOs = ["Windows", "Windows Phone"];
const linuxOs = [
  "Linux",
  "Fedora",
  "Debian",
  "Gentoo",
  "Ubuntu",
  "Red Hat",
  "CentOS",
  "Mandriva",
  "Mint",
  "SUSE",
  "Slackware",
  "Arch",
  "Manjaro",
  "Kali"
];
const blackBerryOs = ["BlackBerry OS"];
const freeBSDOs = ["FreeBSD", "BSD"];

export const getOSIcon = (os: string) => {
  return (
    <>
      {appleOs.includes(os) ? (
        <FontAwesomeIcon icon={brands("apple")} />
      ) : androidOs.includes(os) ? (
        <FontAwesomeIcon icon={brands("android")} />
      ) : chromeOs.includes(os) ? (
        <FontAwesomeIcon icon={brands("chrome")} />
      ) : windowsOs.includes(os) ? (
        <FontAwesomeIcon icon={brands("windows")} />
      ) : linuxOs.includes(os) ? (
        <FontAwesomeIcon icon={brands("linux")} />
      ) : blackBerryOs.includes(os) ? (
        <FontAwesomeIcon icon={brands("blackberry")} />
      ) : freeBSDOs.includes(os) ? (
        <FontAwesomeIcon icon={brands("freebsd")} />
      ) : (
        <FontAwesomeIcon icon={solid("question")} />
      )}
    </>
  );
};

export const getOSIconColor = (os: string) => {
  if (appleOs.includes(os)) {
    return "gray.9";
  } else if (androidOs.includes(os)) {
    return "green.6";
  } else if (chromeOs.includes(os)) {
    return "[#fbc118]";
  } else if (windowsOs.includes(os)) {
    return "blue.6";
  } else if (linuxOs.includes(os)) {
    return "orange.9";
  } else if (blackBerryOs.includes(os)) {
    return "black.5";
  } else if (freeBSDOs.includes(os)) {
    return "red.9";
  } else if (os === "Unknown") {
    return "gray.6";
  }
};
export const getDeviceIcon = (device: string) => {
  return (
    <>
      {device === "Desktop" ? (
        <FontAwesomeIcon icon={solid("desktop")} />
      ) : device === "Mobile" ? (
        <FontAwesomeIcon icon={solid("mobile-alt")} />
      ) : device === "Laptop" ? (
        <FontAwesomeIcon icon={solid("laptop")} />
      ) : (
        <FontAwesomeIcon icon={solid("tablet-alt")} />
      )}
    </>
  );
};

export const getBrowserIcon = (browser: string) => {
  return (
    <>
      <span>
        {browser === "Chrome" ? (
          <FontAwesomeIcon icon={brands("chrome")} />
        ) : browser === "Firefox" ? (
          <FontAwesomeIcon icon={brands("firefox")} />
        ) : browser === "Safari" ? (
          <FontAwesomeIcon icon={brands("safari")} />
        ) : browser === "Edge" ? (
          <FontAwesomeIcon icon={brands("internet-explorer")} />
        ) : browser === "Opera" ? (
          <FontAwesomeIcon icon={brands("opera")} />
        ) : (
          <FontAwesomeIcon icon={solid("browser")} />
        )}
      </span>
    </>
  );
};

export const getBrowserIconColor = (browser: string) => {
  if (browser === "Chrome") {
    return "text-gray-900";
  } else if (browser === "Firefox") {
    return "text-[#ff3347]";
  } else if (browser === "Safari") {
    return "text-blue-600";
  } else if (browser === "Edge") {
    return "text-blue-400";
  } else if (browser === "Opera") {
    return "text-red-500";
  } else if (browser === "Unknown") {
    return "text-gray-600";
  }
};

export const getCountryIcon = (location: string, height: number = 24, width: number = 24) => {
  return (
    <>
      <LazyFlag code={location} width={width} height={height} />
    </>
  );
};

export const getChannelIcon = (channel: string, color: string) => {
  switch (channel) {
    case "organic search":
      return <FontAwesomeIcon icon={regular("magnifying-glass")} color={color} />;
    case "organic social":
      return <FontAwesomeIcon icon={regular("comments")} color={color} />;
    case "paid search":
      return <FontAwesomeIcon icon={regular("magnifying-glass-dollar")} color={color} />;
    case "paid social":
      return <FontAwesomeIcon icon={regular("comments-dollar")} color={color} />;
    case "display ads":
      return <FontAwesomeIcon icon={regular("rectangle-ad")} color={color} />;
    case "email":
      return <FontAwesomeIcon icon={regular("envelope")} color={color} />;
    case "referral":
      return <FontAwesomeIcon icon={regular("arrows-rotate")} color={color} />;
    case "other campaigns":
      return <FontAwesomeIcon icon={regular("bullhorn")} color={color} />;
    case "direct":
      return <FontAwesomeIcon icon={regular("globe")} color={color} />;
    default:
      return <FontAwesomeIcon icon={regular("globe")} color={color} />;
  }
};
