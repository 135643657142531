import { endOfDay, startOfDay, subDays } from 'date-fns';
import { format } from 'date-fns-tz';
import axios from '@/lib/services/JWTService';
import { WorkspacePinnedEventsService } from '@/lib/services/WorkspacePinnedEventsService';
import { DATE_FORMAT } from '@/lib/utils/Constants';
import { LoadingStateProps, LooseObject } from 'types/types.d';
import create from 'zustand';

interface PinnedEventsListStore {
  /**
   * Search value for the pinned events list.
   */
  search: string;
  /**
   * Set search value for the pinned events list.
   */
  setSearch: (search: string) => void;
  /**
   * Pinned events list
   */
  list: Array<any>;
  /**
   * Set pinned events list
   */
  setList: (list: any) => void;
  /**
   * Pinned events list loader
   */
  loading: boolean;
  /**
   * Set pinned events list loader
   */
  setLoading: (loading: boolean) => void;
  /**
   * Pinned events are available than the limit. Default limit is 20.
   */
  hasMore: boolean;
  /**
   * Set the pinned events has more than the limit value. If the value is less than 20, the hasMore value is false.
   */
  setHasMore: (hasMore: boolean) => void;
  /**
   * Pinned events list page number
   */
  page: number;
  /**
   * Set pinned events list page number.
   */
  setPage: (page: number) => void;
  /**
   * If pinned events lists is empty, set the no results.
   */
  noResults: boolean;
  /**
   * Set no results for the pinned events list.
   */
  setNoResults: (noResults: boolean) => void;
  /**
   * Fetch the pinned events list.
   */
  fetch: (wsId: string, page: number) => void;
  /**
   * Reset the pinned event list.
   */
  reset: () => void;

  /**
   * Loosely typed object to store the historical details of pinned events.
   * Data includes first_occurrence, last_occurrence, total_events, and histogram activity of last 30 days.
   */
  historyList: LooseObject;
  /**
   * Set pinned event history list
   * @param historyList
   */
  setHistoryList: (historyList: LooseObject) => void;

  /**
   * Pinned event history list loader
   */
  historyLoading: LoadingStateProps | string;
  /**
   * Set pinned event history list loader
   * @param historyLoading
   * @returns
   */
  setHistoryLoading: (historyLoading: LoadingStateProps | string) => void;

  /**
   * Fetch pinned event history
   */

  fetchHistory: (wsId: string) => void;
}

const initialPinnedEventsList = {
  search: '',
  list: [],
  historyList: {},
  loading: false,
  hasMore: false,
  page: 1,
  noResults: false,
  historyLoading: 'idle'
};

export const usePinnedEventsListStore = create<PinnedEventsListStore>((set, get) => ({
  ...initialPinnedEventsList,

  setHistoryLoading: (historyLoading: LoadingStateProps | string) => set({ historyLoading }),
  setHistoryList: (historyList: LooseObject) => set({ historyList }),
  setSearch: (search: string) => set({ search }),
  setList: (list: []) => set({ list }),
  setLoading: (loading: boolean) => set({ loading }),
  setHasMore: (hasMore: boolean) => set({ hasMore }),
  setPage: (page: number) => set({ page }),
  setNoResults: (noResults: boolean) => set({ noResults }),
  fetch: async (wsId: string, page: number) => {
    // fetch list
    set({ loading: true });
    await new WorkspacePinnedEventsService()
      .all(wsId, page, 20, true)
      .then((res) => {
        if (page > 1) {
          set({
            list: get().list.concat(res.data),
            hasMore: res.data.length === 20,
            page: page
          });
        } else {
          set({
            list: res.data,
            noResults: res.data.length === 0,
            hasMore: res.data.length === 20
          });
        }
      })
      .catch((err) => err);
    set({ loading: false });
  },
  reset: () => set({ ...initialPinnedEventsList }),
  /**
   * Function to fetch the total clicks, first occurrence, last occurrence, and histogram of
   * last 30 days for the pinned event.
   */
  fetchHistory: async (wsId: string) => {
    set({ historyLoading: 'loading' });
    let eventsHistoryRequests: any = [];
    // startDate and endDate for last 30 days which the activity histogram will be fetched
    const startDate = format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT);
    const endDate = format(endOfDay(new Date()), DATE_FORMAT);
    get().list.map((item: LooseObject) => {
      if (!get().historyList.hasOwnProperty(item.id)) {
        console.log('Request pushed to array.', item.id);
        eventsHistoryRequests.push(
          new WorkspacePinnedEventsService().occurrence(wsId, item.id, startDate, endDate)
        );
      }
    });

    // send all requests in parallel.
    let eventHistogramResponse: LooseObject = get().historyList;

    await axios
      .all(eventsHistoryRequests)
      .then(
        axios.spread((...responses) => {
          responses.map((response: any, index: number) => {
            // console.log(response.data);
            console.log(response.data);

            eventHistogramResponse[response.data.id] = {
              total_occurrence: response.data.total_count,
              histogram: response.data.histogram.map((item: any) => {
                return [item[0], item[1]];
              }),
              first_occurrence: response.data.first_occurrence,
              last_occurrence: response.data.last_occurrence
            };
          });
        })
      )
      .catch((errors) => {
        // react on errors.
      });
    // console.log(eventHistogramResponse);
    console.log(eventHistogramResponse);
    set({ historyList: eventHistogramResponse, historyLoading: 'loaded' });
  }
}));
