import "./onboarding_create_workspace.scss";

import {
  Button,
  Flex,
  Input,
  Text,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import { ThirdPartyTracking } from "@/lib/utils/ThirdPartyTracking";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import TimezoneSelect, { ITimezone } from "react-timezone-select";
import { Label } from "@/ui/components/Common/Label/Label";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";

interface IFormInputs {
  name: string;
  website: string;
}

export const OnboardingCreateWorkspace = (props: any) => {
  // eslint-disable-next-line

  const { setActiveWorkspace } = useContext(AppLifecycleContext)!;
  const { user } = useContext(AppLifecycleContext);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IFormInputs>();

  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Create Workspace | Usermaven";

    if (gtag && typeof gtag === "function") {
      gtag("event", "conversion", { send_to: "AW-11179183055/kXh2CIiV5fIYEM-X09Ip" });
    }
  }, []);
  const [loadingState, setLoadingState] = useState("idle");
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const onSubmit = (data: any) => {
    console.log(data);
    data.timezone = selectedTimezone;

    console.log(data);
    setLoadingState("loading");
    new WorkspaceService()
      .create(data)
      .then((res) => {
        setActiveWorkspace(res.data);
        console.log(res);

        // third party tracker call.
        const TPService = new ThirdPartyTracking(user, {
          defaultWorkspace: res.data
        });
        TPService.identify();
        TPService.track("workspace_created");

        setLoadingState("success");
        navigate("/onboarding/tracking-pixel");
      })
      .catch((err) => {
        setLoadingState("error");
      });
  };

  const onChangeTimezone = (tz: any): any => {
    console.debug("Selected Timezone:", tz);
    setSelectedTimezone(tz.value);
  };
  const [focused, setFocused] = useState(false);
  const theme = useMantineColorScheme();
  return (
    <div className="onboarding-container">
      <div className="display-flex-column">
        <div className="onboarding-container--info">
          <Title order={3} mb={"sm"}>
            Create a workspace
          </Title>
          <Text size="sm" c="dimmed">
            A workspace in Usermaven is like a separate room for each brand or client, where you can
            manage and view their analytics independently. It keeps data organized and easy to
            access for each specific entity.
          </Text>
        </div>

        <div className="onboarding-container--without-box">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="onboarding-container--box--form pt-4 pb-2">
              <div className="onboarding-input-group pt-4 pb-2">
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <Input.Wrapper size="sm" id={"workspaceName"} label="Workspace Name" required>
                      <Input
                        mt={4}
                        value={value}
                        onChange={onChange}
                        placeholder="e.g ContentStudio"
                        error={(invalid || error) === true}
                        data-cy="onboarding-workspace-name"
                      />
                    </Input.Wrapper>
                  )}
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, maxLength: 24 }}
                />

                {errors.name?.type === "required" && (
                  <ValidationLabel
                    validationType="error"
                    text="Workspace name is required"
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
                {errors.name?.type === "maxLength" && (
                  <ValidationLabel
                    validationType="error"
                    text="Workspace name should be less than 24 characters."
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
              </div>

              <div className="onboarding-input-group pt-4 pb-2">
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <Input.Wrapper size="sm" id={"websiteURL"} label="Website URL" required>
                      <Input.Description fz={12} mb={"xs"}>
                        Add the website URL that you want to track.
                      </Input.Description>
                      <Input
                        mt={4}
                        value={value}
                        onChange={onChange}
                        placeholder="e.g https://contentstudio.io"
                        error={(invalid || error) === true}
                        data-cy="onboarding-workspace-url"
                      />
                    </Input.Wrapper>
                  )}
                  name="website"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, maxLength: 48 }}
                />

                {errors.website?.type === "required" && (
                  <ValidationLabel
                    validationType="error"
                    text="Website URL is required"
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
                {errors.website?.type === "maxLength" && (
                  <ValidationLabel
                    validationType="error"
                    text="Website URL should be less than 48 characters."
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
              </div>
            </div>
            <div className="onboarding-input-group  pt-2 pb-2 ">
              <Label bold required size="small" text="Timezone" />
              <TimezoneSelect
                className={`um-select-timezone ${
                  theme.colorScheme === "dark"
                    ? "um-select-timezone-dark"
                    : "um-select-timezone-light"
                }`}
                value={selectedTimezone}
                onChange={onChangeTimezone}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    background: theme.colorScheme === "dark" ? "#25262b" : "hsl(0, 0%, 100%)",
                    borderColor: theme.colorScheme === "dark" ? "#373a40" : "hsl(0, 0%, 80%)",
                    color: theme.colorScheme === "dark" ? "#898c90" : "hsl(0, 0%, 20%)"
                  }),

                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: theme.colorScheme === "dark" ? "#898c90" : "hsl(0, 0%, 20%)"
                  }),

                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    background: theme.colorScheme === "dark" ? "#010307" : "hsl(0, 0%, 100%)",
                    color: theme.colorScheme === "dark" ? "#898c90" : undefined
                  }),

                  option: (baseStyles) => ({
                    ...baseStyles,
                    color: theme.colorScheme === "dark" ? "#898c90" : "hsl(0, 0%, 20%)"
                  })
                }}
                data-cy="onboarding-workspace-timezone"
              />
            </div>
            <div className="onboarding-container--info--button pt-8">
              <Button
                type="submit"
                disabled={loadingState === "loading"}
                loading={loadingState === "loading"}
                data-cy="onboarding-submit">
                Create
              </Button>
            </div>
          </form>
        </div>

        <div className="onboarding-container--logout">
          <p>
            Or, <Link to="/logout">Logout</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
