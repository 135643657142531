import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Flex, Loader, SimpleGrid, Text, useMantineColorScheme } from "@mantine/core";
import { format } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Params, useParams } from "react-router-dom";
import { DecodedValueMap, QueryParamConfig } from "serialize-query-params";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "../../../../../lib/services/WebAnalyticsService";
import { COMPARE_DATE_RANGES, CURRENT_DATE_RANGES } from "../../../../../lib/utils/Constants";
import { roundFunnelRate } from "../../../../../lib/utils/FunnelUtility";
import { numberToCommas } from "../../../../../lib/utils/StringUtility";
import DatePickerDropdown, {
  IDateRange
} from "../../../../components/App/Dropdowns/DatePickerDropdown";
import BreakdownDetailTable from "../FunnelDetailedView/BreakdownDetailTable";
import { IDetailViewMenu } from "../FunnelDetailedView/DetailViewMenuGroup";
import { IFunnelMeta, IFunnelStep } from "../FunnelDetailedView/FunnelDetails";
import StatsListCard from "../FunnelDetailedView/StatsListCard";
import StepsFunnel from "../StepsFunnel";

export interface IOverviewTabProps {
  id: string;
  selectedType: "visitor" | "user" | "company";
  selectedTab: IDetailViewMenu;
  setSelectedTab: (tab: IDetailViewMenu) => void;
  initialSelectedFunnel: IFunnelStep[];
  initialSelectedMeta: IFunnelMeta | undefined;
  initialDateRange: DecodedValueMap<{
    start_date: QueryParamConfig<string | null | undefined, string | null | undefined>;
    end_date: QueryParamConfig<string | null | undefined, string | null | undefined>;
  }>;
  isOnboarding?: boolean;
}

const CompareTab = ({
  id,
  selectedType,
  selectedTab,
  setSelectedTab,
  initialSelectedFunnel,
  initialSelectedMeta,
  initialDateRange,
  isOnboarding = false
}: IOverviewTabProps) => {
  const { colorScheme } = useMantineColorScheme();

  /** Secret is available in case of the shareable funnels. */
  const { secret } = useParams<Params>();

  const [loading, setLoading] = useState({
    current: true,
    compare: true
  });

  // Current funnel states
  const [selectedFunnel, setSelectedFunnel] = useState<IFunnelStep[]>(initialSelectedFunnel);
  const [selectedMeta, setSelectedMeta] = useState<IFunnelMeta | undefined>(initialSelectedMeta);

  // Comparison funnel states (compare)
  const [comparisonFunnel, setComparisonFunnel] = useState<IFunnelStep[]>([]);
  const [comparisonMeta, setComparisonMeta] = useState<IFunnelMeta | undefined>(undefined);

  // Current funnel date range

  // Service instance for api requests
  const webAnalyticsService = new WebAnalyticsService();

  // Workspace context
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // Render count
  const renderCount = useRef(0);

  const intl = useIntl();

  const COMPARE_DATE_RANGES_RESOLVED = useMemo(() => {
    return COMPARE_DATE_RANGES.map((range) => {
      let label = range.label;
      switch (label) {
        case "Current vs previous day":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousDay" });
          break;
        case "Current vs previous week":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousWeek" });
          break;
        case "Current vs previous month":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousMonth" });
          break;
        case "Current vs previous quarter":
          label = intl.formatMessage({
            id: "insights.headerTabs.compare.currentVsPreviousQuarter"
          });
          break;
        case "Current vs previous year":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousYear" });
          break;
      }
      return {
        ...range,
        label: label
      };
    });
  }, []);

  const CURRENT_DATE_RANGES_RESOLVED = useMemo(() => {
    return CURRENT_DATE_RANGES.map((range) => {
      let label = range.label;
      switch (label) {
        case "Current day":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentDay" });
          break;
        case "Current week":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentWeek" });
          break;
        case "Current month":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentMonth" });
          break;
        case "Current quarter":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentQuarter" });
          break;
        case "Current year":
          label = intl.formatMessage({ id: "insights.headerTabs.compare.currentYear" });
          break;
      }
      return {
        ...range,
        label: label
      };
    });
  }, []);

  const [dateRange, setDateRange] = useState<IDateRange>(CURRENT_DATE_RANGES_RESOLVED[0]);

  useEffect(() => {
    if (Array.isArray(selectedTab.selectedOption)) {
      // Remove first element and set it as the date range
      const newDateRange = selectedTab.selectedOption[0];
      setDateRange(newDateRange);

      // Reset the selected tab
      setSelectedTab({
        ...selectedTab,
        selectedOption: selectedTab.selectedOption[1]
      });
    }

    fetchAll();

    return () => {
      setLoading({
        current: true,
        compare: true
      });
    };
  }, [id, selectedTab.selectedOption, colorScheme]);

  useEffect(() => {
    if (renderCount.current === 0) {
      renderCount.current += 1;
      return;
    }

    if (dateRange.label === "Custom") {
      return;
    }

    const newDateRange = getDateRangeForCompare(dateRange.label);
    setSelectedTab({ ...selectedTab, selectedOption: newDateRange });
  }, [dateRange]);

  const fetchAll = async () => {
    // Current funnel
    await fetchDefaultFunnel();

    // Comparison funnel
    await fetchDefaultFunnel(true);
  };

  const resolvedDateRangeForCurrent = useMemo(() => {
    console.log("resolvedDateRangeForCurrent", selectedTab);
    switch ((selectedTab.selectedOption as IDateRange).label) {
      case intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousDay" }):
        // Today
        return CURRENT_DATE_RANGES_RESOLVED[0];
      case intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousWeek" }):
        // This week
        return CURRENT_DATE_RANGES_RESOLVED[1];
      case intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousMonth" }):
        // This month
        return CURRENT_DATE_RANGES_RESOLVED[2];
      case intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousQuarter" }):
        // This quarter
        return CURRENT_DATE_RANGES_RESOLVED[3];
      case intl.formatMessage({ id: "insights.headerTabs.compare.currentVsPreviousYear" }):
        // This year
        return CURRENT_DATE_RANGES_RESOLVED[4];
      default:
        return {
          label: "Custom",
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        };
    }
  }, [selectedTab.selectedOption]);

  const getDateRangeForCompare = (label: string) => {
    console.log("getDateRangeForCompare", label);
    switch (label) {
      case intl.formatMessage({ id: "insights.headerTabs.compare.currentDay" }):
        // Yesterday
        return COMPARE_DATE_RANGES_RESOLVED[0];
      case intl.formatMessage({ id: "insights.headerTabs.compare.currentWeek" }):
        // Last week
        return COMPARE_DATE_RANGES_RESOLVED[1];
      case intl.formatMessage({ id: "insights.headerTabs.compare.currentMonth" }):
        // Last month
        return COMPARE_DATE_RANGES_RESOLVED[2];
      case intl.formatMessage({ id: "insights.headerTabs.compare.currentQuarter" }):
        // Last quarter
        return COMPARE_DATE_RANGES_RESOLVED[3];
      case intl.formatMessage({ id: "insights.headerTabs.compare.currentYear" }):
        // Last year
        return COMPARE_DATE_RANGES_RESOLVED[4];
      default:
        return {
          label: "Custom",
          startDate: (selectedTab.selectedOption as IDateRange).startDate,
          endDate: (selectedTab.selectedOption as IDateRange).endDate
        };
    }
  };

  const fetchDefaultFunnel = async (compared = false) => {
    setLoading((prev) => ({
      ...prev,
      [compared ? "compare" : "current"]: true
    }));

    const startDate = compared
      ? (selectedTab.selectedOption as IDateRange).startDate
      : resolvedDateRangeForCurrent.startDate;

    const endDate = compared
      ? (selectedTab.selectedOption as IDateRange).endDate
      : resolvedDateRangeForCurrent.endDate;

    // setDateRange({
    //   ...resolvedDateRangeForCurrent,
    // })

    const response = await webAnalyticsService.funnel(
      activeWorkspace.id,
      id,
      {
        start_date: startDate,
        end_date: endDate,
        funnel_type: isOnboarding ? selectedType : ""
      },
      secret || null
    );

    if (response?.data?.funnel_steps) {
      const funnelSteps = response.data.funnel_steps;
      const funnelMeta = response.data.meta;

      if (compared) {
        setComparisonFunnel(funnelSteps);
        setComparisonMeta(funnelMeta);
      } else {
        setSelectedFunnel(funnelSteps);
        setSelectedMeta(funnelMeta);
      }
    }

    setLoading((prev) => ({
      ...prev,
      [compared ? "compare" : "current"]: false
    }));
  };

  /**
   * Resolve type for stats card
   */
  const resolvedType = useMemo(() => {
    if (!selectedType || selectedType === "visitor") {
      return intl.formatMessage({
        id: "insights.type.visitors",
        defaultMessage: "visitors"
      });
    } else if (selectedType === "user") {
      return intl.formatMessage({
        id: "insights.type.users",
        defaultMessage: "users"
      });
    }
    return intl.formatMessage({
      id: "insights.type.companies",
      defaultMessage: "companies"
    });
  }, [selectedType]);

  /**
   * Format the date range
   */
  const formatDateRange = useCallback((range: IDateRange) => {
    if (range.startDate && range.endDate) {
      const fromDate = format(new Date(range.startDate), "MMM dd");
      const toDate = format(new Date(range.endDate), "MMM dd");

      if (fromDate === toDate) {
        return fromDate;
      }

      return `${fromDate} - ${toDate}`;
    }

    return "";
  }, []);

  const handleDateRangeChange = (range: IDateRange | IDateRange[], isCompare = false) => {
    console.log("handleDateRangeChange", range, isCompare);
    if (Array.isArray(range)) {
      setDateRange(range[0]);
      setSelectedTab({ ...selectedTab, selectedOption: range[1] });

      return;
    }

    if (isCompare) {
      setSelectedTab({ ...selectedTab, selectedOption: range });
      return;
    }

    setDateRange(range);
  };

  return (
    <>
      <SimpleGrid
        spacing={28}
        mt={6}
        cols={{
          base: 2,
          md: 2,
          sm: 1
        }}>
        <StatsListCard
          title={intl.formatMessage({
            id: "insights.cards.current.headline",
            defaultMessage: `Current`
          })}
          list={[
            {
              icon: "eye",
              text: (
                <>
                  <FormattedMessage
                    id="insights.cards.current.total"
                    values={{
                      count: (
                        <Text span fw={700}>
                          {numberToCommas(selectedMeta?.total_visitors || 0)}
                        </Text>
                      ),
                      type: resolvedType
                    }}
                  />
                </>
              ),
              bg: "yellow.6",
              iconColor: "text-yellow-500"
            },
            {
              icon: "users",
              text: (
                <>
                  <FormattedMessage
                    id="insights.cards.current.conversion"
                    values={{
                      value: (
                        <Text span fw={700}>
                          {roundFunnelRate(selectedMeta?.conversion_rate || 0)}%
                        </Text>
                      ),
                      type: resolvedType
                    }}
                  />
                </>
              ),
              bg: "blue.6",
              iconColor: "text-blue-500"
            },
            {
              icon: "arrow-down",
              text: (
                <>
                  <FormattedMessage
                    id="insights.cards.current.dropOff"
                    values={{
                      dropOff: (
                        <Text span fw={700}>
                          {roundFunnelRate(selectedMeta?.most_drop_off_step?.percentage || 0)}%
                        </Text>
                      ),
                      step: (
                        <Text span fw={700}>
                          {selectedMeta?.most_drop_off_step.level}
                        </Text>
                      )
                    }}
                  />
                </>
              ),
              bg: "red.7",
              iconColor: "text-red-500",
              hide: selectedFunnel.length < 3 || !selectedMeta?.most_drop_off_step
            }
          ]}
        />

        <StatsListCard
          title={intl.formatMessage({
            id: "insights.cards.compared.headline",
            defaultMessage: `Compared`
          })}
          list={[
            {
              icon: "eye",
              text: (
                <>
                  <FormattedMessage
                    id="insights.cards.current.total"
                    values={{
                      count: (
                        <Text span fw={700}>
                          {numberToCommas(comparisonMeta?.total_visitors || 0)}
                        </Text>
                      ),
                      type: resolvedType
                    }}
                  />
                </>
              ),
              bg: "yellow.6",
              iconColor: "text-yellow-500"
            },
            {
              icon: "users",
              text: (
                <>
                  <FormattedMessage
                    id="insights.cards.current.conversion"
                    values={{
                      value: (
                        <Text span fw={700}>
                          {roundFunnelRate(comparisonMeta?.conversion_rate || 0)}%
                        </Text>
                      ),
                      type: resolvedType
                    }}
                  />
                </>
              ),
              bg: "blue.7",
              iconColor: "text-blue-500"
            },
            {
              icon: "arrow-down",
              text: (
                <>
                  <FormattedMessage
                    id="insights.cards.current.dropOff"
                    values={{
                      dropOff: (
                        <Text span fw={700}>
                          {roundFunnelRate(comparisonMeta?.most_drop_off_step?.percentage || 0)}%
                        </Text>
                      ),
                      step: (
                        <Text span fw={700}>
                          {comparisonMeta?.most_drop_off_step.level}
                        </Text>
                      )
                    }}
                  />
                </>
              ),
              bg: "red.7",
              iconColor: "text-red-500",
              hide: selectedFunnel.length < 3 || !comparisonMeta?.most_drop_off_step
            }
          ]}
        />
      </SimpleGrid>

      <SimpleGrid
        cols={{
          base: 2,
          sm: 1,
          md: 2
        }}
        spacing={4}
        mt={24}>
        <Flex direction="column" pos="relative" h={400}>
          <Box ml={8}>
            <Text fw={600} mb={4}>
              <FormattedMessage
                id="insights.headerTabs.compare.currentPeriod"
                defaultMessage="Current Period"
              />
              :
            </Text>
            <Text size={"sm"} color={"dimmed"}>
              <DatePickerDropdown
                data={CURRENT_DATE_RANGES_RESOLVED}
                range={resolvedDateRangeForCurrent}
                onRangeChange={(range) => handleDateRangeChange(range, false)}
                withCustomRange
                showArrow={false}>
                {(range) => (
                  <Button
                    size={"sm"}
                    style={{
                      marginLeft: "-4px",
                      fontWeight: "normal",
                      height: "24px",
                      padding: "0 4px",
                      i: {
                        color: "dimmed",
                        fontStyle: "italic",
                        fontSize: "0.8em",
                        marginLeft: "4px"
                      }
                    }}
                    variant="subtle"
                    rightSection={
                      <>
                        <FontAwesomeIcon
                          icon={solid("angle-down")}
                          className="w-4 h-4 ml-1.5 hover:opacity-80"
                        />
                      </>
                    }>
                    {Array.isArray(range) ? (
                      formatDateRange(range[0])
                    ) : (
                      <>{formatDateRange(range)}</>
                    )}
                  </Button>
                )}
              </DatePickerDropdown>
            </Text>
          </Box>
          {loading.compare ? (
            <Box
              style={{
                minHeight: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <Loader size="xs" />
            </Box>
          ) : (
            <StepsFunnel funnelSteps={selectedFunnel} type={selectedType} />
          )}
        </Flex>

        <Flex direction={"column"} h={400} pos="relative">
          <Box ml={8}>
            <Text fw={600} mb={4}>
              <FormattedMessage
                id="insights.headerTabs.compare.comparedTo"
                defaultMessage="Compared To"
              />
              :
            </Text>
            <Text size={"sm"} color={"dimmed"}>
              <DatePickerDropdown
                data={COMPARE_DATE_RANGES_RESOLVED}
                range={selectedTab.selectedOption as IDateRange}
                onRangeChange={(range) => handleDateRangeChange(range, true)}
                withCustomRange
                showArrow={false}>
                {(range) => (
                  <Button
                    size={"sm"}
                    style={{
                      marginLeft: "-4px",
                      fontWeight: "normal",
                      height: "24px",
                      padding: "0 4px",
                      i: {
                        color: "dimmed",
                        fontStyle: "italic",
                        fontSize: "0.8em",
                        marginLeft: "4px"
                      }
                    }}
                    rightSection={
                      <>
                        <FontAwesomeIcon
                          icon={solid("angle-down")}
                          className="w-4 h-4 ml-1.5 hover:opacity-80"
                        />
                      </>
                    }
                    variant="subtle">
                    {Array.isArray(range) ? (
                      <>
                        "Custom" + " " + <Text fz={10}>({formatDateRange(range[1])})</Text>
                      </>
                    ) : (
                      <>
                        {" "}
                        {range.label}{" "}
                        <Text fz={10} pl={4} pt={2} c="brand">
                          ({formatDateRange(range)})
                        </Text>
                      </>
                    )}
                  </Button>
                )}
              </DatePickerDropdown>
            </Text>
          </Box>
          {loading.compare ? (
            <Box
              style={{
                minHeight: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <Loader size="xs" />
            </Box>
          ) : (
            <StepsFunnel funnelSteps={comparisonFunnel} type={selectedType} />
          )}
        </Flex>
      </SimpleGrid>

      <div className="mt-3">
        <BreakdownDetailTable
          steps={[[...selectedFunnel], [...comparisonFunnel]]}
          meta={
            {
              breakdown: [
                {
                  ...selectedMeta,
                  breakdown_value: "Current"
                },
                {
                  ...comparisonMeta,
                  breakdown_value: "Compared"
                }
              ]
            } as IFunnelMeta
          }
          variant="breakdown"
          loading={loading.current || loading.compare}
          selectedAttribute={"period"}
          showCompleteList={false}
          hideCompleteListButton
          sameColor
        />
      </div>
    </>
  );
};

export default CompareTab;
