import { WorkspacePinnedEventsService } from "@/lib/services/WorkspacePinnedEventsService";
import { DateDropdownMenu } from "@/ui/components/App/Dropdowns/DateDropdownMenu";
import {
  Box,
  Divider,
  Flex,
  Loader,
  Paper,
  Title,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { format } from "date-fns-tz";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import React, { useContext, useEffect, useState } from "react";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import { EventsService } from "../../../../lib/services/EventsService";
import { DATE_FORMAT } from "../../../../lib/utils/Constants";
import { LoadingStateProps } from "../../../../types/types.d";
import { NoResults } from "../../../components/App/NoResults/NoResults";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

type CustomDetailedActivityHistogramProps = {
  title?: string;
  eventName: string;
  fromDate: string;
  toDate: string;
  eventType: "custom";
  pinnedEventId?: string;
  handleDateChange: (
    startDate: string,
    endDate: string,
    label: string,
    custom_range?: boolean | undefined,
    interval?: string | undefined
  ) => void;
};

type PinnedDetailedActivityHistogramProps = {
  title?: string;
  eventName: string;
  fromDate: string;
  toDate: string;
  eventType: "pinned";
  pinnedEventId: string;
  handleDateChange: (
    startDate: string,
    endDate: string,
    label: string,
    custom_range?: boolean | undefined,
    interval?: string | undefined
  ) => void;
};
type Props = CustomDetailedActivityHistogramProps | PinnedDetailedActivityHistogramProps;

export const EventsDetailedActivityHistogramCard: React.FC<Props> = ({
  title,
  eventName,
  fromDate,
  toDate,
  eventType = "custom",
  pinnedEventId = "",
  handleDateChange
}) => {
  const { colorScheme } = useMantineColorScheme();
  const theme = useGlobalMantineTheme();
  let DEFAULT_CHART_OPTIONS = {
    color: ["#7D47EB"],
    title: {
      text: ""
    },
    tooltip: {
      borderColor: colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2],
      backgroundColor: colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: "#595c5f"
      },
      trigger: "axis",
      axisPointer: {
        type: "line",
        label: {
          formatter: (params: any) => {
            return format(new Date(params.value), "eee, MMMM dd, yyyy");
          }
        }
      }
    },
    legend: {
      show: false,
      data: ["Event Activity"]
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      left: "5%",
      right: "5%",
      bottom: "5%",
      containLabel: true
    },
    xAxis: [
      {
        type: "time",
        axisLine: {
          show: false
        },
        axisLabel: {
          fontSize: 10,
          formatter: "{MMM} {dd}",
          axisPointer: {
            show: false
          },
          margin: 14
        },
        splitLine: {
          show: false
        },
        axisTick: {
          splitNumber: 4
        }
      }
    ],
    yAxis: [
      {
        min: 0,
        type: "value",
        splitLine: true,
        alignTicks: true,
        axisTick: {
          show: true,
          splitNumber: 4
        }
      }
    ],
    series: [
      {
        name: "Event Activity",
        type: "line",
        smooth: false,
        lineStyle: {
          width: 2
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgb(125, 71, 235, 0.6)"
            },
            {
              offset: 1,
              color: "rgba(125, 71, 235, 0.01)"
            }
          ])
        },
        emphasis: {
          focus: "series"
        },
        data: []
      }
    ],
    animationDuration: 400
  };

  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [histogramLoading, setHistogramLoading] = useState<LoadingStateProps>("idle");
  const [histogram, setHistogram] = useState<any>(DEFAULT_CHART_OPTIONS);

  const fetchIndividualEventHistogram = async (
    startDate = format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
    endDate = format(endOfDay(new Date()), DATE_FORMAT)
  ) => {
    setHistogramLoading("loading");

    // call the appropriate service based on the event type
    if (eventType === "custom") {
      const eventsService = new EventsService();
      await eventsService
        .individualEventHistogram(activeWorkspace.id, eventName, startDate, endDate)
        .then((res) => {
          histogram.series[0].data = res.data.data.map((item: any) => [item.date, item.count]);
          const itemCountList = res.data.map((item: any) => item.count);
          const minValue = Math.min(...itemCountList);
          histogram.yAxis[0] = {
            ...histogram.yAxis[0],
            min: minValue
          };

          setHistogram(histogram);
        })
        .catch((err) => {
          if (err?.response?.status === 500 || err?.response?.status === 422) {
            setHistogram([{ id: "Event Activity", color: "#7D47EB", data: [] }]);
          }
        });
    } else if (eventType === "pinned") {
      const pinnedEventsService = new WorkspacePinnedEventsService();
      await pinnedEventsService
        .occurrence(activeWorkspace.id, pinnedEventId, startDate, endDate)
        .then((res) => {
          histogram.series[0].data = res.data.histogram.map((item: any) => {
            return [item[0], item[1]];
          });
          const itemCountList = res.data.histogram.map((item: any) => item[1]);
          const minValue = Math.min(...itemCountList);
          histogram.yAxis[0] = {
            ...histogram.yAxis[0],
            min: minValue
          };

          setHistogram(histogram);
        })
        .catch((err) => {
          if (err?.response?.status === 500 || err?.response?.status === 422) {
            setHistogram([{ id: "Event Activity", color: "#7D47EB", data: [] }]);
          }
        });
    }
    setHistogramLoading("loaded");
  };

  //   const handleDateChange = (startDate: string, endDate: string) => {
  //     fetchIndividualEventHistogram(startDate, endDate)
  //   }

  useEffect(() => {
    fetchIndividualEventHistogram(fromDate, toDate);
  }, [fromDate, toDate]);

  return (
    <>
      <Paper withBorder={true} component="div">
        <Flex align={"center"} data-cy="event-detailed-activity-histogram-title">
          <Title p="md" order={5} fw={600} flex={1}>
            {title}
          </Title>

          {eventType === "pinned" && (
            <Box data-cy="event-detailed-activity-histogram-date-dropdown" mr="sm">
              <DateDropdownMenu onChange={handleDateChange} />
            </Box>
          )}
        </Flex>
        <Divider />
        {histogramLoading === "loaded" ? (
          <>
            {histogram.series[0].data.length === 0 ? (
              <>
                <NoResults
                  text={"There is no data available for event activity."}
                  heading={"No Results"}
                />
              </>
            ) : (
              <>
                <div className="h-full w-full">
                  <ReactEChartsCore echarts={echarts} option={histogram} />
                </div>
              </>
            )}
          </>
        ) : (
          <Flex justify={"center"} align={"center"} mt={"xl"}>
            <Loader variant="bars" size={"xs"} />
          </Flex>
        )}
      </Paper>
    </>
  );
};
