/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { ActionType, SortDirection } from "ka-table/enums";
import {
  Box,
  TextInput,
  useMantineTheme,
  Text,
  Button,
  Flex,
  Popover,
  ActionIcon,
  Paper,
  useMantineColorScheme
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import MultiSelectDropdown from "../../components/Common/Dropdown/MultiSelectDropdown";
import { VirtualDataTable } from "../../components/Common/VirtualDataTable";
import { NoResults } from "../../components/App/NoResults/NoResults";
import { remToPx } from "../../../lib/utils/ClassUtility";
import { UsersCRMService } from "../../../lib/services/UsersCRMService";
import AppLifecycleContext from "../../../lib/contexts/AppLifecycleContext";
import { LS_PEOPLE_FIELDS_PREFERENCE } from "../../../lib/utils/Storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { numberToCommas } from "../../../lib/utils/StringUtility";
import { IActionPayload, IColumn } from "../../components/Common/VirtualDataTable/VirtualDataTable";
import { useDebouncedState } from "@mantine/hooks";
import { format } from "date-fns";
import { DATE_FORMAT, WORKSPACE_MEMBER_ROLES } from "../../../lib/utils/Constants";
import { downloadCSVFromResponse } from "../../../lib/utils/CSVExporterUtility";
import { ExportButton } from "../../components/Common/ExportButton/ExportButton";
import {
  HeaderNavigationContainer,
  HeaderNavigationH1
} from "@/ui/components/App/HeaderNavigation/HeaderNavigation.style";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { arrayMove } from "../../../lib/utils/ArrayUtility";
import { SegmentDropdownMenu } from "@/ui/components/App/Dropdowns/SegmentDropdownMenu";
import { useSegmentFilterStore } from "@/stores/useSegmentFilterStore";
import { useSegmentListStore } from "@/stores/useSegmentListStore";
import { SegmentsQueryBuilder } from "@/ui/components/App/Segments/SegmentsQueryBuilder";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { SegmentModal } from "@/ui/components/App/Segments/SegmentModal";
import PageHeader from "../ContactsHub/components/PageHeader/PageHeader";
import { useCustomizeMenuStore } from "@/stores/useCustomizeMenuStore";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";

const Users = () => {
  const { colorScheme } = useMantineColorScheme();
  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);
  // Get segment from the params.
  let { segment } = useParams<{
    segment: string;
  }>();
  /**
   * Segment filter store
   */
  const [filterVisible, setFilterVisible] = useSegmentFilterStore((state) => [
    state.filterVisible,
    state.setFilterVisible
  ]);
  /**
   * Segments Store
   */
  const [segments, fetchSegments, setListsSegmentType, updateSegment] = useSegmentListStore(
    (state) => [state.segments, state.fetchSegments, state.setSegmentType, state.updateSegment]
  );

  /**
   * Segment Query builder store
   */
  const [
    filters,
    setFilters,
    resetExceptFilters,
    reset,
    modalOpen,
    setSegmentModalOpen,
    setSegmentType
  ] = useSegmentStore((state) => [
    state.filters,
    state.setFilters,
    state.resetExceptFilters,
    state.reset,
    state.modalOpen,
    state.setModalOpen,
    state.setType
  ]);

  // Get user role in the workspace.
  const { hasRolesNotWith } = useWorkspaceUtilityHook();

  // Navigate from react-router-dom.
  const navigate = useNavigate();

  // Service calls
  const usersCRMService = new UsersCRMService();

  // For active workspace id of the user.
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // exporting state for export users list feature
  const [exporting, setIsExporting] = useState("idle");

  // Users component state.
  const [noResults, setNoResults] = useState(false);
  const [tableFields, setTableFields, removeValue] = useLocalStorage<IColumn[]>({
    key: `${LS_PEOPLE_FIELDS_PREFERENCE}_${activeWorkspace?.identifier}`,
    defaultValue: [],
    getInitialValueInEffect: false
  });
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useDebouncedState("", 500);

  // Ref for table.
  const tableRef = useRef<any>(null);

  // To keep track of first field api call.
  const firstFieldApiCall = useRef(false);

  // To keep track of first render.
  const firstRender = useRef(false);

  const [internalAside, setInternalAside] = useCustomizeMenuStore((state: any) => [
    state.internalAside,
    state.setInternalAside
  ]);

  /**
   * get visible fields from the nested columns (object) and return an array of string with the field keys
   */
  const getVisibleFields = useCallback((columns: IColumn[]) => {
    const staticFields = ["user_id", "avatar"];
    const removeFields = ["avatar", "random_id"];
    const visibleFields: string[] = [];
    columns.forEach((column) => {
      if (column.children) {
        visibleFields.push(...getVisibleFields(column.children));
      } else if (column.visible) {
        visibleFields.push(column.key);
      }
    });

    // merge static fields and visible fields
    const fields = [...staticFields, ...visibleFields];

    // remove duplicates and fields that are not needed
    return fields.filter(
      (field, index) => fields.indexOf(field) === index && !removeFields.includes(field)
    );
  }, []);

  /**
   * Fetch next page of data from the server.
   */
  const fetchNextPageCallback = async (
    page: number,
    sort: { key: string; direction: SortDirection }
  ) => {
    console.log("fetchNextPageCallback");
    const fields = await getUsersFields(false);
    const sortDirection = sort.direction === SortDirection.Ascend ? "asc" : "desc";
    const sortKey = sort.key ? sort.key : "last_visited_time";

    // If the filter and the group rules are empty, then don't send the filter rules.
    // In case of the page type, the field is going to be empty.
    const filterRules =
      (filters.rules.length > 0 && !filters.rules[0].field && filters.rules[0].type !== "page") ||
      (filters.groups.length > 0 &&
        !filters.groups[0].rules[0].field &&
        filters.groups[0].rules[0].type !== "page")
        ? {}
        : filters;

    const response = await usersCRMService.search(activeWorkspace?.id, {
      fields: getVisibleFields(fields),
      page: page,
      sort: `${sortKey}:${sortDirection}`,
      search: search,
      // segment filters
      filters: filterVisible || segment !== "everyone" ? filterRules : {}
    });

    if (response?.data?.people) {
      setTotal(response?.data?.people_count);
      setNoResults(false);

      return {
        list: response.data.people,
        count: response.data.people_count
      };
    }

    return { list: [], count: 0 };
  };

  const getFieldKeys = useCallback((columns: IColumn[]) => {
    const names: string[] = [];
    columns.forEach((column) => {
      if (column.children) {
        names.push(...getFieldKeys(column.children));
      } else {
        names.push(column.key);
      }
    });
    return names.sort();
  }, []);

  // service call to get users fields
  const getUsersFields: (forcedUpdate: boolean) => Promise<IColumn[]> = async (
    forcedUpdate = false
  ) => {
    if (tableFields.length && firstFieldApiCall.current) {
      return tableFields;
    }

    const response = await usersCRMService.columns(activeWorkspace?.id);

    if (response?.data?.fields) {
      firstFieldApiCall.current = true;

      if (forcedUpdate) {
        setTableFields(response.data.fields);
        return response.data.fields;
      }

      if (getFieldKeys(response.data.fields).join(",") !== getFieldKeys(tableFields).join(",")) {
        console.log("fields changed");
        setTableFields(response.data.fields);
        return response.data.fields;
      }

      return tableFields;
    }
    return [];
  };

  //Service call to export users list as CSV.
  const exportUsersList = async () => {
    setIsExporting("loading");
    // get users fields
    const fields = await getUsersFields(false);
    // service call for export with visible fields parameter which export only visible fields in the CSV
    try {
      const filterRules =
        (filters.rules.length > 0 && !filters.rules[0].field && filters.rules[0].type !== "page") ||
        (filters.groups.length > 0 &&
          !filters.groups[0].rules[0].field &&
          filters.groups[0].rules[0].type !== "page")
          ? {}
          : filters;

      const response = await usersCRMService.peopleListExport(activeWorkspace.id, {
        fields: getVisibleFields(fields),
        search: search,
        filters: filterVisible || segment !== "everyone" ? filterRules : {}
      });
      if (response.data) {
        //Setting filename of the exported file in accordance with the date and time of export.
        let filename = `people_list_${format(new Date(), DATE_FORMAT)}.csv.gz`;
        //Utility function to download the csv from response.
        downloadCSVFromResponse(response, filename);
        setIsExporting("loaded");
        return true;
      }
    } catch (err) {
      setIsExporting("loaded");
      throw new Error("400");
    }
    return false;
  };

  // On first render
  useEffect(() => {
    document.title = "Users | Usermaven";
    setSegmentType("user");
    setListsSegmentType("user");
  }, []);

  // on search change
  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true;
      return;
    }
    if (modalOpen) {
      return;
    }

    tableRef.current?.refresh();
  }, [search, filters]);

  /**
   * Callback for when no results are found.
   */
  const onNoResults = () => {
    setNoResults(true);
  };

  /**
   * Callback for when a row is clicked.
   */
  const onRowClick = (row: any) => {
    // navigate(`${row.user_id}`);
    setOverviewModalOpen(true, "user", row.user_id);
  };

  /**
   * Memorized method to calculate the table height based on the sibling elements.
   */
  const tableHeight = useMemo(() => {
    // Breakdown: 50px Visitor header height
    const headerHeight = 60;

    const subHeaderHeight = 36 + remToPx(1.25 + 1.25 + 1); // Removed people box height // + 32

    return window.innerHeight - headerHeight - subHeaderHeight;
  }, []);

  /**
   * This function is responsible for handling the change in the table fields.
   * @param selectedColumnsList
   */
  const onColumnVisibilityChange = (selectedColumnsList: string[]) => {
    const newTableFields = tableFields.map((field) => {
      if (field.children) {
        field.children = field.children.map((child) => {
          child.visible = selectedColumnsList.includes(child.key);
          return child;
        });
      }
      field.visible = selectedColumnsList.includes(field.key);
      return field;
    });

    // Hard refresh the table.
    tableRef.current?.refresh();

    setTableFields(newTableFields);
  };

  /**
   * This function is responsible for handling local storage changes like column resize, column re-ordering, etc.
   * @param payload
   */
  const onColumnChange = (payload: IActionPayload) => {
    const { type, columnKey, width, targetColumnKey } = payload;

    // We are getting nested array of columns, which is stored in component state (tableFields) and also in local storage.
    // The ka-table component needs the flat array of columns, so we are converting the nested array to flat array
    // with flattenTableFields memoized property.

    // So whatever changes we make in the nested array, flattenTableFields automatically gets updated.

    if (type === ActionType.ResizeColumn) {
      const newTableFields = tableFields.map((field) => {
        if (field.children) {
          field.children = field.children.map((child) => {
            if (child.key === columnKey) {
              child.width = width;
            }
            return child;
          });
        }
        if (field.key === columnKey) {
          field.width = width;
        }
        return field;
      });

      setTableFields(newTableFields);
    }

    if (type === ActionType.ReorderColumns) {
      // Get this columnIndex from the nested array, if the columnKey is of child column return the index of parent column
      const columnIndex = tableFields.findIndex((field) => {
        if (field.key === columnKey) {
          return true;
        }

        if (field.children) {
          return field.children.some((child) => child.key === columnKey);
        }

        return false;
      });

      // Get the targetColumnIndex from the nested array, if the targetColumnKey is of child column return the index of parent column
      const targetColumnIndex = tableFields.findIndex((field) => {
        if (field.key === targetColumnKey) {
          return true;
        }

        if (field.children) {
          return field.children.some((child) => child.key === targetColumnKey);
        }

        return false;
      });

      const newTableFields = arrayMove(tableFields, columnIndex, targetColumnIndex);

      setTableFields(newTableFields);
    }
  };

  /**
   * Method to reset the columns to default.
   */
  const resetColumns = async () => {
    // Remove the local storage
    removeValue();

    // Refetch the fields
    firstFieldApiCall.current = false;
    await getUsersFields(true);

    // Hard refresh the table.
    tableRef.current?.refresh();
  };

  /**
   * This will convert the nested columns to a flat array of columns for the ka-table.
   */
  const flattenTableFields = useMemo(() => {
    const fields: IColumn[] = [];

    tableFields.forEach((field) => {
      if (field.children) {
        fields.push(...field.children);
      } else {
        fields.push(field);
      }
    });

    return fields;
  }, [tableFields]);

  /**
   * Method to apply the filters
   */

  const handleApplyFilters = (values: any) => {
    setFilters(values);
  };

  /**
   * Get current segment from the segments list
   */
  const currentSegmentName = useMemo(() => {
    if (segment === "everyone") {
      return "";
    }
    const s = segments.find((s: any) => s.id === segment);

    if (s) {
      return s.name;
    }

    return "";
  }, [segments, segment]);

  const [openedSegmentPopover, setOpenedSegmentPopover] = useState(false);

  /**
   * Update or create a segment
   * @param values
   */
  const handleUpdateSegment = async (values: any) => {
    const currentSegment = segments.find((s: any) => s.id === segment);

    if (currentSegment && segment !== "everyone") {
      await updateSegment(
        activeWorkspace.id,
        currentSegment.id,
        {
          name: currentSegment.name,
          type: currentSegment.type,
          filters: values,
          description: currentSegment.description
        },
        true
      );
    }
  };

  return (
    <>
      <SegmentModal />
      <Paper
        style={(theme) => ({
          background: colorScheme === "dark" ? theme.colors.dark[9] : "#F8F9FA"
        })}>
        <PageHeader>
          <Flex align={"center"} gap={"xs"} flex={1}>
            <ActionIcon
              color={"dark.2"}
              variant={"transparent"}
              onClick={() => setInternalAside(!internalAside)}>
              <FontAwesomeIcon icon={solid("bars-sort")} />
            </ActionIcon>

            <Text lineClamp={1}>
              {currentSegmentName ? <>{currentSegmentName}</> : <>Users</>}{" "}
            </Text>
            <Text fz="xs" fw={600}>
              {" "}
              {total > 0 && <>({numberToCommas(total)})</>}
            </Text>
          </Flex>

          {/* Export Button for exporting people list as csv */}
          <Flex>
            {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
              <ExportButton
                onClick={exportUsersList}
                disabled={exporting === "loading"}
                loading={exporting === "loading"}
              />
            )}
          </Flex>
        </PageHeader>

        <div className="um-people">
          <div>
            <Flex
              align={"center"}
              justify={"space-between"}
              wrap={"wrap"}
              gap={16}
              style={{
                padding: "24px 19px"
              }}>
              <Popover
                closeOnClickOutside={false}
                opened={openedSegmentPopover}
                onChange={(opened) => setOpenedSegmentPopover(opened)}
                width={"100%"}
                position="bottom-start"
                shadow="md"
                withinPortal
                styles={{
                  dropdown: {
                    maxWidth: "990px"
                  }
                }}>
                <Popover.Target>
                  <Button
                    onClick={() => setOpenedSegmentPopover((o) => !o)}
                    size="xs"
                    variant="outline"
                    className="mr-4"
                    color={
                      filterVisible ? (colorScheme === "dark" ? "dark.3" : "brand.5") : "gray.6"
                    }
                    leftSection={
                      filterVisible ? undefined : <FontAwesomeIcon icon={regular("plus")} />
                    }>
                    {filterVisible
                      ? `Filters (${filters.rules.length || filters.groups.length})`
                      : "Add a filter"}
                  </Button>
                </Popover.Target>
                <Popover.Dropdown>
                  <SegmentsQueryBuilder
                    fullWidth={true}
                    withClearButton={true}
                    handleSegmentCreate={() => {
                      resetExceptFilters();
                      setSegmentModalOpen(true);
                      setOpenedSegmentPopover(false);
                    }}
                    allowSegmentCreate={true}
                    handleSubmitButtonText={currentSegmentName ? "Update" : "Apply"}
                    handleSaveButtonText={
                      currentSegmentName ? "Save as a new Segment" : "Save as a Segment"
                    }
                    handleSubmit={(filters) => {
                      setFilterVisible(true);
                      handleApplyFilters(filters);

                      handleUpdateSegment(filters);
                      setOpenedSegmentPopover(false);
                    }}
                    forcedSegmentType={"users"}
                  />
                </Popover.Dropdown>
              </Popover>

              <Flex align={"center"} gap={16}>
                {/*<Button*/}
                {/*  size="xs"*/}
                {/*  variant="outline"*/}
                {/*  color={colorScheme === "dark" ? "dark.3" : "gray.6"}*/}
                {/*  leftIcon={*/}
                {/*    <FontAwesomeIcon icon={filterVisible ? regular("times") : regular("plus")} />*/}
                {/*  }*/}
                {/*  className="mr-4"*/}
                {/*  onClick={() => {*/}
                {/*    if (filterVisible) {*/}
                {/*      reset();*/}
                {/*    }*/}
                {/*    setFilterVisible(!filterVisible);*/}
                {/*  }}>*/}
                {/*  {filterVisible ? "Clear filters" : "Add a filter"}*/}
                {/*</Button>*/}

                <div className="flex items-center">
                  <TextInput
                    size="xs"
                    placeholder="Search by email"
                    leftSection={<FontAwesomeIcon icon={regular("search")} />}
                    onChange={(event) => setSearch(event.currentTarget.value)}
                    defaultValue={search}
                  />
                </div>

                {/*<Box className="mr-4">*/}
                {/*  <SegmentDropdownMenu*/}
                {/*    type="user"*/}
                {/*    defaultValue={segment && segment !== "everyone" ? segment : undefined}*/}
                {/*    onChange={(value: any) => {*/}
                {/*      if (!value) {*/}
                {/*        reset();*/}
                {/*        navigate(`/env/${activeWorkspace.identifier}/users/everyone`);*/}
                {/*        return;*/}
                {/*      }*/}
                {/*      setFilters(segments.find((s: any) => s.id === value).filters);*/}
                {/*      setFilterVisible(false);*/}
                {/*      navigate(`/env/${activeWorkspace.identifier}/users/${value}`);*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</Box>*/}

                <MultiSelectDropdown
                  disabled={total === 0}
                  buttonText={"Edit Displayed Attributes"}
                  headerText={"Edit Columns"}
                  searchPlaceholder={"Search for a column"}
                  options={tableFields}
                  onChange={onColumnVisibilityChange}
                  initialSelectedOptions={flattenTableFields
                    .filter((field) => field.visible)
                    .map((field) => field.key)}
                  onReset={resetColumns}
                  withHeader
                  withSearch
                  withReset
                />
              </Flex>
            </Flex>

            {/* <Box
              className="flex py-2 font-bold"
              sx={{
                height: "32px",
              }}
            >
              {total > 0 && <p>{numberToCommas(total)} users</p>}
            </Box> */}
          </div>

          {noResults && (
            <>
              <NoResults
                type="search"
                heading="No Results"
                text={search ? `No results found for "${search}".` : "No results found."}
              />
            </>
          )}

          <Box
            sx={{
              display: noResults ? "none" : "block",
              minWidth: "100%",
              maxWidth: "0px"
            }}>
            <VirtualDataTable
              ref={tableRef}
              columns={flattenTableFields}
              rowKeyField="random_id"
              serverCallback={fetchNextPageCallback}
              height={tableHeight}
              noDataAvailable={onNoResults}
              onRowClick={onRowClick}
              initialSortBy={{ key: "last_visited_time", direction: SortDirection.Descend }}
              onColumnChange={(payload) => onColumnChange(payload)}
              hasFilterApplied={filterVisible}
            />
          </Box>
        </div>
      </Paper>

      <Outlet />
    </>
  );
};

export default Users;
