import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Anchor,
  Box,
  Divider,
  Paper,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Title
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { CompaniesService } from "@/lib/services/CompaniesService";
import { StatsSkeleton } from "@/lib/utils/ChartsSkeletons";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CompanyHistogram } from "./CompanyHistogram";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export const DashboardOverviewCompanies = ({ query }: any) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const navigate = useNavigate();
  // count companies

  const [companiesCountWithPeriod, setCompaniesCountWithPeriod] = useState({
    today: 0,
    yesterday: 0,
    week: 0,
    month: 0,
    total: 0
  });

  const [isCompaniesCountLoading, setIsCompaniesCountLoading] = useState("idle");

  const fetchCompaniesCountWithPeriod = async () => {
    const companiesService = new CompaniesService();
    setIsCompaniesCountLoading("loading");
    await companiesService
      .countWithPeriods(activeWorkspace.id)
      .then((res) => {
        // if (res.data.length > 0) {
        setCompaniesCountWithPeriod(res.data);
        // }
      })
      .catch((err) => {});
    setIsCompaniesCountLoading("loaded");
  };

  useEffect(() => {
    fetchCompaniesCountWithPeriod();
  }, [activeWorkspace.id]);

  return (
    <>
      <Paper shadow="sm" withBorder mb="md">
        <Title order={5} fw={600} className="font-medium" p="md">
          Companies
        </Title>
        <Divider />
        {isCompaniesCountLoading === "loaded" && companiesCountWithPeriod?.total > 0 && (
          <SimpleGrid
            p="md"
            cols={{ base: companiesCountWithPeriod?.total > 0 ? 5 : 1, sm: 1, md: 5 }}>
            <>
              {companiesCountWithPeriod?.total > 0 && (
                <>
                  <Stack gap={8}>
                    <Title order={4}>{numberToCommas(companiesCountWithPeriod.today)}</Title>
                    <Text size="xs" tt="uppercase">
                      Today
                    </Text>
                  </Stack>
                  <Stack gap={8}>
                    <Title order={4}>{numberToCommas(companiesCountWithPeriod.yesterday)}</Title>
                    <Text size="xs" tt="uppercase">
                      Yesterday
                    </Text>
                  </Stack>
                  <Stack gap={8}>
                    <Title order={4}>{numberToCommas(companiesCountWithPeriod.week)}</Title>
                    <Text size="xs" tt="uppercase">
                      This Week
                    </Text>
                  </Stack>
                  <Stack gap={8}>
                    <Title order={4}>{numberToCommas(companiesCountWithPeriod.month)}</Title>
                    <Text size="xs" tt="uppercase">
                      This Month
                    </Text>
                  </Stack>

                  <Stack gap={8}>
                    <Title order={4}>{numberToCommas(companiesCountWithPeriod.total)}</Title>
                    <Text size="xs" tt="uppercase">
                      Total
                    </Text>
                  </Stack>
                </>
              )}
            </>
          </SimpleGrid>
        )}
        {isCompaniesCountLoading === "loading" && (
          <Box mx="md" mt="lg">
            <SimpleGrid cols={5}>
              <Skeleton height={15} width={80} />
              <Skeleton height={15} width={80} />
              <Skeleton height={15} width={80} />
              <Skeleton height={15} width={80} />
              <Skeleton height={15} width={80} />
            </SimpleGrid>
          </Box>
        )}
        {isCompaniesCountLoading === "loaded" && companiesCountWithPeriod.total === 0 && (
          <>
            <Alert
              my="sm"
              icon={
                <FontAwesomeIcon
                  icon={regular("info-circle")}
                  className="text-md text-blue-500 py-2"></FontAwesomeIcon>
              }>
              <Text>
                There are no companies available. Start adding your users by following our{" "}
                <Anchor
                  onClick={() => navigate(`/env/${activeWorkspace.identifier}/settings/setup`)}>
                  integration guide.
                </Anchor>
              </Text>
            </Alert>
          </>
        )}
        {/*<div className="um-insights-dashboard-container__box-content-stats h-28">*/}
        {/*  */}
        {/*</div>*/}

        <CompanyHistogram query={query} />
      </Paper>
    </>
  );
};
