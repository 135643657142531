import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Alert,
  Avatar,
  Image,
  useMantineTheme,
  Text,
  Title,
  Flex,
  Anchor
} from "@mantine/core";
import WordpressTrackingGuide from "@assets/images/icons/integrations/wordpress-tracking-setup.png";
import { useContext } from "react";
import { GeneralAPIKeyAndServerToken } from "@/ui/pages/Settings/WorkspaceSettings/General/GeneralAPIKeyAndServerToken";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const TrackingPixelWordPress = () => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const theme = useGlobalMantineTheme();
  return (
    <>
      <Title order={4} my="md">
        Credentials
      </Title>{" "}
      <GeneralAPIKeyAndServerToken withBorder={true} withHeader={false} />
      <Accordion mt={32} variant="separated" defaultValue="install_plugin">
        <Accordion.Item value="install_plugin">
          <Accordion.Control>
            <Flex align="center">
              <Avatar size="md" color="brand" radius="xl" mr={"sm"}>
                1{" "}
              </Avatar>
              <Text className="flex-grow" fz="md" fw="bold">
                Install Usermaven WordPress plugin
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Text className="py-4" fz="sm">
              To start collecting events from your WordPress website, install the Usermaven
              WordPress plugin either from the WordPress plugin repository or by{" "}
              <Anchor
                fz="sm"
                fw={500}
                href="https://wordpress.org/plugins/usermaven/"
                target="_blank"
                className="primary-link-color font-bold">
                downloading it from here.
              </Anchor>
            </Text>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="configure_plugin">
          <Accordion.Control>
            <Flex align="center">
              <Avatar size="md" color="brand" radius="xl" mr={"sm"}>
                2{" "}
              </Avatar>
              <Text className="flex-grow" fz="md" fw="bold">
                Configure tracking setup for WordPress plugin
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Text className="py-4" fz="sm" mb="sm">
              Once the plugin is successfully installed and activated, it's time to configure the
              tracking setup within the WordPress plugin.
            </Text>
            <Text className="py-4" fz="sm" mb="sm">
              Copy the API key and paste into API key section. Click on the{" "}
              <span className="font-bold">"Save Changes"</span> button.
            </Text>
            <Image src={WordpressTrackingGuide} radius="md" mb="sm" />

            <Text fz="sm" mb="sm">
              Now, your WordPress website will start sending events to the Usermaven.
            </Text>
            <Alert
              color="yellow"
              className="text-2xl"
              icon={<FontAwesomeIcon icon={regular("info-circle")} />}>
              <Text fz="sm" span fw={500} className="font-semibold">
                Note:
              </Text>{" "}
              You can follow the{" "}
              <Anchor
                fz="sm"
                fw={500}
                href="https://usermaven.com/docs/integrations/wordpress"
                target="_blank"
                className="primary-link-color">
                detailed guide here
              </Anchor>{" "}
              about installing Usermaven on your WordPress website.
            </Alert>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
