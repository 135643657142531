import { Box, Divider, Flex, Grid, Paper, Title, Text, ScrollArea } from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { DashboardService } from "@/lib/services/DashboardService";
import { BulletListSkeleton } from "@/lib/utils/ChartsSkeletons";
import { numberToUnit } from "@/lib/utils/StringUtility";
import { useContext, useEffect, useState } from "react";
import { LoadingStateProps } from "types/types.d";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { MostEngagedUsersDetailedView } from "./MostEngagedUsersDetailedView";

import { Progress } from "@mantine/core";
type AttributedChannelItem = {
  session_channel: string;
  influenced_visitors: number;
  influenced_conversions: number;
  visitors_percentage: number;
  users_percentage: number;
};

export const UserAcquisitionByChannel = ({ query }: any) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [list, setList] = useState<Array<AttributedChannelItem>>([]);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [detailedView, setDetailedView] = useState(false);

  const fetchUserAcquisitionByChannel = async () => {
    setLoading("loading");
    const dashboardService = new DashboardService(activeWorkspace.id);
    await dashboardService
      .userAcquisitionByChannel(query)
      .then((res) => setList(res.data))
      .catch((err) => {
        if (err?.response?.status === 500 || err?.response?.status === 422) {
          setList([]);
        }
      });
    setLoading("loaded");
  };
  useEffect(() => {
    fetchUserAcquisitionByChannel();
  }, [activeWorkspace.id, query]);
  return (
    <>
      <MostEngagedUsersDetailedView detailedView={detailedView} setDetailedView={setDetailedView} />
      <Paper shadow="sm" withBorder mb="md" h={420}>
        <Title order={5} fw={600} className="font-medium" p="md">
          Users Acquisition By Channel{" "}
        </Title>
        <Divider />
        {loading === "loaded" ? (
          <>
            {list.length > 0 ? (
              <>
                <Grid px={"md"} columns={12} py="xs">
                  <Grid.Col span={8}>
                    <Text fw={600}>Channel</Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Text ta="right" fw={600}>
                      Visitors
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Text ta="right" fw={600}>
                      Users
                    </Text>
                  </Grid.Col>
                </Grid>
                <Divider mb="xs" />
                <ScrollArea h={300} type="hover">
                  {list.map((item: AttributedChannelItem, index) => (
                    <Grid key={`attribution-channel-${index}`} px="md">
                      <Grid.Col span={8}>
                        <Text tt="capitalize">{item.session_channel}</Text>
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <Text fw={500} ta="right">
                          {numberToUnit(item.influenced_visitors)}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <Text fw={500} ta="right" pr={"xs"}>
                          {numberToUnit(item.influenced_conversions)}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={12} mt={-8}>
                        <Progress value={item.users_percentage} size="xs" />
                      </Grid.Col>
                    </Grid>
                  ))}
                </ScrollArea>
              </>
            ) : (
              <>
                <Flex justify={"center"} align={"center"} flex={1} h={300}>
                  {/* In case the results list is empty, we will show this */}
                  <NoResults
                    className="pt-28"
                    text={"There is no activity for acquisition channel."}
                    heading={"No Results"}
                  />
                </Flex>
              </>
            )}
          </>
        ) : (
          <BulletListSkeleton className="ml-4" />
        )}
      </Paper>
    </>
  );
};
