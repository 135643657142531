import { SavedViewsService } from '@/lib/services/SavedViewsService';
import { toast } from 'react-toastify';
import create from 'zustand';

interface Props {
  // option to save a new view in case of editing.
  saveNew: boolean;
  setSaveNew: (value: boolean) => void;
  // highlight selected id.
  selectedId: string;
  setSelectedId: (selectedId: string) => void;
  // id and name of the saved view.
  id: string;
  name: string;
  setId: (id: string) => void;
  setName: (name: string) => void;
  // include domain in the query
  isSelectedDomain: boolean;
  setIsSelectedDomain: (isSelectedDomain: boolean) => void;
  // is saved view visible for the public
  isPublic: boolean;
  setIsPublic: (isPublic: boolean) => void;
  // include time period in the query
  includeTimePeriod: boolean;
  setIncludeTimePeriod: (includeTimePeriod: boolean) => void;
  // list of saved views
  list: any[];
  setList: (list: any[]) => void;
  // modal open
  modalOpen: boolean;
  setModalOpen: (filterModalOpened: boolean) => void;
  // list saved views
  fetch: (wsId: string) => void;
  // CRUD operations
  delete: (wsId: string, id: string) => void;
  create: (wsId: string, data: any) => void;
  update: (wsId: string, id: string, data: any) => void;
  edit: (id: string) => void;
  reset: () => void;
}

export const useWebSavedViewsStore = create<Props>((set, get) => ({
  saveNew: false,
  setSaveNew: (value: boolean) => set({ saveNew: value }),
  selectedId: '',
  setSelectedId: (selectedId: string) => set({ selectedId }),
  id: '',
  name: '',
  setId: (id: string) => set({ id }),
  setName: (name: string) => set({ name }),
  isPublic: false,
  setIsPublic: (isPublic: boolean) => set({ isPublic }),
  includeTimePeriod: false,
  setIncludeTimePeriod: (includeTimePeriod: boolean) => set({ includeTimePeriod }),
  isSelectedDomain: false,
  setIsSelectedDomain: (isSelectedDomain: boolean) => set({ isSelectedDomain }),
  list: [],
  setList: (list: any[]) => set({ list }),
  modalOpen: false,
  setModalOpen: (modalOpen: boolean) => set({ modalOpen }),
  reset: () => {
    set({
      id: '',
      name: '',
      isPublic: false,
      includeTimePeriod: false,
      isSelectedDomain: false,
      saveNew: false
    });
  },
  fetch: async (wsId: string) => {
    await new SavedViewsService()
      .all(wsId)
      .then((res) => {
        console.log('res', res);
        if (res.data) {
          set({ list: res.data });
        }
      })
      .catch((err) => {
        console.error('Error occurred for saved views', err);
        set({ list: [] });
      });
  },
  edit: (id: string) => {
    const list = get().list;
    const view = list.find((v) => v.id === id);
    console.log(view);
    set({
      id: view.id,
      name: view.name,
      isPublic: view.is_public_view,
      includeTimePeriod: view.include_time_period,
      isSelectedDomain: view.include_domain,
      saveNew: false
    });
  },
  delete: async (wsId: string, id: string) => {
    await new SavedViewsService()
      .delete(wsId, id)
      .then((res) => {
        const list = get().list;
        const newList = list.filter((v) => v.id !== id);
        set({ list: newList });
        toast.success('Save view deleted successfully');
      })
      .catch((err) => {
        console.log('err', err);
      });
  },
  create: async (wsId: string, data: any) => {
    await new SavedViewsService()
      .create(wsId, data)
      .then((res) => {
        console.log('res', res);
        const list = get().list;
        set({ modalOpen: false, list: [...list, res.data] });
        toast.success('Save view created successfully');
      })
      .catch((err) => {
        console.log('err', err);
      });
  },
  update: async (wsId: string, id: string, data: any) => {
    await new SavedViewsService()
      .update(wsId, id, data)
      .then((res) => {
        console.log('res', res);
        const list = get().list;
        const newList = list.map((item) => {
          if (item.id === id) {
            return res.data;
          }
          return item;
        });
        set({ modalOpen: false, list: newList });
        toast.success('Save view updated successfully');
      })
      .catch((err) => {
        console.log('err', err);
      });
  }
}));
