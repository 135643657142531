import { useEffect } from 'react';
import create from 'zustand';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useMediaQuery } from '@mantine/hooks';
import { MOBILE_BREAKPOINT_QUERY } from '../lib/utils/Constants';

type State = {
  isNavMenuOpened: boolean;
  toggleNavMenuOpened: () => void;
};

const useStore = create<State>((set) => ({
  isNavMenuOpened: false,
  toggleNavMenuOpened: () => set((state) => ({ isNavMenuOpened: !state.isNavMenuOpened })),
}));

const useResponsiveness = () => {
  const isNavMenuOpened = useStore((state) => state.isNavMenuOpened);
  const toggleNavMenuOpened = useStore((state) => state.toggleNavMenuOpened);
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);



  // The scroll disable/enable effect
  useEffect(() => {


    const targetElement = document.querySelector("body");

    if (!isMobileView) {
      enableBodyScroll(targetElement as HTMLElement);
      return;
    }

    if (!isNavMenuOpened) {
      enableBodyScroll(targetElement as HTMLElement);
    } else {
      disableBodyScroll(targetElement as HTMLElement);
    }
  }, [isNavMenuOpened, isMobileView]);

  return { isNavMenuOpened, toggleNavMenuOpened };
};

export default useResponsiveness;
