import "./insights_setup.scss";
import { useContext, useEffect, useState } from "react";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { ConditionItem } from "types/types.d";
import { InsightsService } from "@/lib/services/InsightsService";
import { NameSetup } from "../Components/Setup/NameSetup";
import { EventsSetup } from "../Components/Setup/EventsSetup";
import { useNavigate } from "react-router-dom";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "@/lib/utils/Constants";
import { InsightContext } from "@/lib/contexts/InsightContext";
import useInsightHook from "@/hooks/useInsightHook";
import { OnboardingCompletionFunnelOrderSetup } from "../Components/Setup/OnboardingCompletionFunnelOrderSetup";
import { ThirdPartyTracking } from "@/lib/utils/ThirdPartyTracking";
import { Button } from "@mantine/core";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import useConditionsValidityHook from "@/hooks/useConditionsValidityHook";
import { OnboardingCompletionWindowSetup } from "@/ui/pages/Insights/Components/Setup/OnboardingCompletionWindowSetup";
import useInsightsListHook from "@/hooks/useInsightsListHook";
import { StepCard } from "@/ui/components/Common/StepCard/StepCard";

interface IOnboardingCompletionSetupModal {
  isModalOpen: boolean;
  setIsModalOpen: (e: boolean) => void;
  insightId?: string;
}

const OnboardingCompletionSetupModal = ({
  isModalOpen,
  setIsModalOpen,
  insightId
}: IOnboardingCompletionSetupModal) => {
  // States that are used.
  const [isLoading, setIsLoading] = useState("idle");
  const [templateId, setTemplateId] = useState("");
  const [name, setName] = useState("");
  const [conditions, setConditions] = useState<Array<ConditionItem>>([]);
  const [funnelOrder, setFunnelOrder] = useState<"strict_increase" | "strict_order">(
    "strict_increase"
  );

  const [windowSize, setWindowSize] = useState<number>(0);
  const [windowSizeType, setWindowSizeType] = useState<"days" | "hours" | "minutes">("days");

  const [isStoreLoader, setIsStoreLoader] = useState("idle");
  const navigate = useNavigate();
  const { stepsValidation } = useConditionsValidityHook();
  // We are passing attributes and events to the child components.
  const { insightEvents, insightAttributes } = useInsightHook();
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // Get the list of insights to check the unique name.
  const { insightsList } = useInsightsListHook("onboarding");

  // NOTE: We are going to provide cohort filter in insights as discussed internally.

  // const [audienceConditionType, setAudienceConditionType] = useState<
  //   "or" | "and"
  // >("or");

  // const [audienceConditions, setAudienceConditions] = useState<
  //   Array<ConditionItem>
  // >([]);

  // all service calls
  const insightService = new InsightsService();
  const createInsight = async () => {
    setIsStoreLoader("loading");

    await insightService
      .create(activeWorkspace.id, {
        name: name,
        conditions: {
          funnel_conditions: conditions,
          funnel_order: funnelOrder,
          window_size: windowSize,
          window_size_type: windowSizeType
        },
        type: "onboarding_completion_rate"
      })
      .then((res) => {
        if (res.data) {
          toast.success(TOAST_MESSAGES.INSIGHT_CREATED);
          const TPService = new ThirdPartyTracking();
          TPService.track("onboarding_insight_created");
          setIsModalOpen(false);
          // as the user is already on /env/${activeWorkspace.identifier}/insights/dashboard/onboarding
          navigate({ search: `insightId=${res.data.id}` });
        }
      })
      .catch((err) => {});
    setIsStoreLoader("loaded");
  };

  const updateInsight = async () => {
    setIsStoreLoader("loading");

    await insightService
      .update(activeWorkspace.id, templateId, {
        name: name,
        conditions: {
          funnel_conditions: conditions,
          funnel_order: funnelOrder,
          window_size: windowSize,
          window_size_type: windowSizeType
        }
      })
      .then((res) => {
        toast.success(TOAST_MESSAGES.INSIGHT_UPDATED);
        setIsModalOpen(false);
      })
      .catch((err) => {});
    setIsStoreLoader("loaded");
  };

  const getInsight = async () => {
    setIsLoading("loading");
    await insightService
      .get(activeWorkspace.id, insightId)
      .then((res) => {
        if (res.data) {
          setTemplateId(res.data.id);
          setName(res.data.name);
          // document.title = `${res.data.name} | Insight | Usermaven`;
          setConditions(res.data.conditions.funnel_conditions);
          setFunnelOrder(res.data.conditions.funnel_order);
          setWindowSize(res.data.conditions.window_size || 0);
          setWindowSizeType(res.data.conditions.window_size_type || "days");
        }
      })
      .catch((err) => {});
    setIsLoading("loaded");
  };

  useEffect(() => {
    if (insightId) {
      getInsight();
    } else {
      setIsLoading("loaded");
    }
  }, [insightId]);

  if (isLoading !== "loaded") {
    return (
      <>
        <LoadingComponent></LoadingComponent>
      </>
    );
  }

  const handleSubmission = async () => {
    if (name.length === 0) {
      toast.error("Please enter a name for your insight.");
      return;
    }
    // check if the name is unique when creating a new insight
    // if the insight is being updated, the name can be the same as the current insight
    if (
      insightsList.find(
        (insight: any) =>
          insight.name.toLowerCase() === name.trim().toLowerCase() && insight.id !== insightId
      )
    ) {
      toast.error("The insight name must be unique.");
      return;
    }

    const onboardingCompletionConditionsCheck = stepsValidation(conditions, 2, 10);
    if (!onboardingCompletionConditionsCheck) {
      return;
    }

    if (insightId) {
      await updateInsight();
    } else {
      await createInsight();
    }
  };

  return (
    <SidePanel
      opened={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      loading={true}
      title={"Onboarding Completion"}>
      <NameSetup name={name} setName={setName} placeholder={"e.g Onboarding conversion rate"} />
      <InsightContext.Provider
        value={{
          insightEvents,
          insightAttributes
        }}>
        <EventsSetup
          conditions={conditions}
          setConditions={setConditions}
          title={"Onboarding Steps"}
          description={
            "Add at least two onboarding steps to track your onboarding completion rate."
          }
        />
        <OnboardingCompletionFunnelOrderSetup
          funnelOrder={funnelOrder}
          setFunnelOrder={setFunnelOrder}
        />
        <OnboardingCompletionWindowSetup
          windowSize={windowSize}
          setWindowSize={setWindowSize}
          windowSizeType={windowSizeType}
          setWindowSizeType={setWindowSizeType}
        />
      </InsightContext.Provider>
      <div className="um-insights-setup__actions">
        <Button
          onClick={() => {
            setIsModalOpen(false);
          }}
          color="red">
          Cancel
        </Button>
        {templateId ? (
          <>
            <Button
              onClick={() => {
                handleSubmission();
              }}
              loading={isStoreLoader === "loading"}
              disabled={conditions.length === 0 || isStoreLoader === "loading"}>
              Update Insight
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                handleSubmission();
              }}
              loading={isStoreLoader === "loading"}
              disabled={conditions.length === 0 || isStoreLoader === "loading"}>
              Save Insight
            </Button>
          </>
        )}
      </div>
    </SidePanel>
  );
};

export default OnboardingCompletionSetupModal;
