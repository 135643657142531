import { IAnnotationNoteVisibilityType } from "@/types/types.d";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SegmentedControl, Center, Box, Text } from "@mantine/core";

interface INoteTypeToggle {
  noteVisibilityType: string;
  setNoteVisibilityType: (reportType: IAnnotationNoteVisibilityType) => void;
  showLabel?: boolean;
}

const NoteVisibilityToggle = ({
  noteVisibilityType,
  setNoteVisibilityType,
  showLabel
}: INoteTypeToggle) => {
  return (
    <>
      <SegmentedControl
        value={noteVisibilityType}
        size="xs"
        onChange={(value) => {
          setNoteVisibilityType(value as IAnnotationNoteVisibilityType);
        }}
        data={[
          {
            value: "only_me",
            label: (
              <Center>
                <FontAwesomeIcon icon={light("user")}></FontAwesomeIcon>
                {showLabel && (
                  <Box ml={8} mr={2}>
                    <Text fz="xs">Mine</Text>
                  </Box>
                )}
              </Center>
            )
          },
          {
            value: "all_users",
            label: (
              <Center>
                <FontAwesomeIcon icon={light("user-group")}></FontAwesomeIcon>
                {showLabel && (
                  <Box ml={8} mr={2}>
                    <Text fz="xs">All</Text>
                  </Box>
                )}
              </Center>
            )
          }
        ]}
      />
    </>
  );
};

export default NoteVisibilityToggle;
