import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Anchor,
  Button,
  Center,
  Flex,
  SegmentedControl,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import logo from "@assets/images/logos/usermaven-logo.png";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext } from "react";
import { Link } from "react-router-dom";
import logoWhiteSvg from "../../../../assets/images/logos/logo-white-usermaven.svg";
import logoBlackSvg from "../../../../assets/images/logos/logo-black-usermaven.svg";

interface IShareDashboardHeaderProps {
  showLanguageSwitcher?: boolean;
  lang?: string;
  setLang?: (lang: string) => void;
}

export const ShareDashboardHeader = ({
  showLanguageSwitcher = false,
  lang = "en",
  setLang = () => {}
}: IShareDashboardHeaderProps) => {
  const { user } = useContext(AppLifecycleContext);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  return (
    <>
      <Flex justify="space-between" align={"center"} mt={0} pt={"md"} pb="md">
        <Flex>
          <Anchor target="_blank" href="https://usermaven.com">
            <img
              width={165}
              height={29}
              className="hover:opacity-80 transition-all ease-in-out"
              src={colorScheme === "dark" ? logoWhiteSvg : logoBlackSvg}
              alt="Usermaven"
            />
          </Anchor>
        </Flex>
        <Flex align={"center"}>
          {showLanguageSwitcher && (
            <SegmentedControl
              mr={"sm"}
              value={lang}
              onChange={setLang}
              data={[
                {
                  value: "en",
                  label: (
                    <Tooltip label={"English"} withArrow position={"bottom"} withinPortal>
                      <Center>
                        <Text fw={600} fz={13}>
                          EN
                        </Text>
                      </Center>
                    </Tooltip>
                  )
                },
                {
                  value: "jp",
                  label: (
                    <Tooltip label={"Japanese"} withArrow position={"bottom"} withinPortal>
                      <Center>
                        <Text fw={600} fz={13}>
                          JP
                        </Text>
                      </Center>
                    </Tooltip>
                  )
                }
              ]}
            />
          )}

          <Tooltip label={"Switch Theme"} withArrow>
            <ActionIcon
              mr={"sm"}
              variant="subtle"
              color={dark ? "gray" : "gray.6"}
              onClick={() => toggleColorScheme()}>
              {dark ? (
                <FontAwesomeIcon icon={regular("sun-bright")}></FontAwesomeIcon>
              ) : (
                <FontAwesomeIcon icon={regular("moon")}></FontAwesomeIcon>
              )}
            </ActionIcon>
          </Tooltip>

          {user?.email ? (
            <>
              <Link className="no-underline" to={"/"}>
                <Button>View Account</Button>
              </Link>
            </>
          ) : (
            <div className="flex items-center">
              <Link
                to={"/login"}
                className={`mr-4  no-underline  transition-all ease-in-out font-medium ${
                  colorScheme === "dark"
                    ? "text-gray-400 hover:text-gray-300"
                    : "text-black-900 hover:text-black-700"
                }`}>
                Login
              </Link>
              <Button
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_WEB_APP_URL}/register?utm_source=public_dashboard`)
                }>
                Sign Up
              </Button>
            </div>
          )}
        </Flex>
      </Flex>
    </>
  );
};
