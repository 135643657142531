import axios from "./JWTService";

export class WorkspaceMiscService {
  toggleBotStatus = (workspaceId: string, status: boolean): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces_misc/${workspaceId}/bot`, {
          status: status
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  toggleServerSideEvents = (workspaceId: string, status: boolean): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces_misc/${workspaceId}/server_side_events`, {
          status: status
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  toggleInternalTrafficStatus = (workspaceId: string, status: boolean): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces_misc/${workspaceId}/internal_traffic`, {
          status: status
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  domains = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspaces_misc/${workspaceId}/domains`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  reportingPeriod = (workspaceId: string, reporting_period: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces_misc/${workspaceId}/default_reporting_period`, {
          reporting_period: reporting_period
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  reportingDomain = (workspaceId: string, reporting_domain: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces_misc/${workspaceId}/default_reporting_domain`, {
          reporting_domain: reporting_domain
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  reportingCurrency = (workspaceId: string, reporting_currency: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces_misc/${workspaceId}/default_reporting_currency`, {
          reporting_currency: reporting_currency
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  reportingInsightsPreference = (
    workspaceId: string,
    reporting_insights_preference: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces_misc/${workspaceId}/default_reporting_insights_preference`, {
          reporting_insights_preference: reporting_insights_preference
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  insightsOnboardingCompleted = (
    workspaceId: string,
    is_insights_onboarding_completed: boolean
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces_misc/${workspaceId}/insights_onboarding_completed`, {
          is_insights_onboarding_completed: is_insights_onboarding_completed
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  insightsEventsOnboardingData = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspaces_misc/${workspaceId}/insights_events_onboarding_data`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  attributionUpgraded = (workspaceId: string, is_attribution_upgraded: boolean): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspaces_misc/${workspaceId}/attribution_upgraded`, {
          is_attribution_upgraded: is_attribution_upgraded
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
