import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Alert,
  Avatar,
  Text,
  Code,
  useMantineTheme,
  Box,
  Button,
  Flex
} from "@mantine/core";
// import { Prism } from "@mantine/prism";
// import duotoneDark from "prism-react-renderer/themes/dracula";
// import githubTheme from "prism-react-renderer/themes/github";
import { useContext, useState } from "react";
import { GeneralAPIKeyAndServerToken } from "@/ui/pages/Settings/WorkspaceSettings/General/GeneralAPIKeyAndServerToken";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { GTMTrackingPixelSnippet } from "@/lib/utils/TrackingPixelUtility";
import { TrackingPixelCustomizations } from "./TrackingPixelCustomizations";
import { useTrackingPixelStore } from "@/stores/useTrackingPixelStore";
import { CodeHighlight } from "@mantine/code-highlight";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const TrackingPixelForCMS = ({ href }: { href: string }) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // Tracking pixel customizations state

  const [isAutoCaptureEnabled, isPrivacyModeEnabled, useWhiteLabelDomain] = useTrackingPixelStore(
    (state) => [state.isAutoCaptureEnabled, state.isPrivacyModeEnabled, state.useWhiteLabelDomain]
  );

  const customDomain = activeWorkspace?.custom_domain;

  const theme = useGlobalMantineTheme();

  return (
    <>
      {/* <h2 className="flex items-center mt-4  font-bold mb-4">Credentials</h2>
      <GeneralAPIKeyAndServerToken withBorder={true} withHeader={false} apiKeyOnly={true} /> */}

      <Accordion mt={32} variant="separated" defaultValue="http_event">
        <Accordion.Item value="http_event">
          <Accordion.Control>
            <Flex align="center">
              <Avatar size="md" color="brand" radius="xl" mr={"sm"}>
                1{" "}
              </Avatar>
              <Text className="flex-grow" fz="md" fw="bold">
                Add Tracking Code
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <>
              <Text className="py-4" fz="sm" mb="sm">
                This single line of code initiates the collection of Analytics data. Insert the code
                into your website's header, immediately before the closing
                <Code>&lt;&#47;head&gt;</Code>
                tag.
              </Text>
              <TrackingPixelCustomizations />
              <CodeHighlight
                mt="sm"
                language="javascript"
                code={GTMTrackingPixelSnippet(
                  activeWorkspace.identifier,
                  isAutoCaptureEnabled,
                  isPrivacyModeEnabled,
                  useWhiteLabelDomain ? customDomain : null
                )}
              />
            </>

            <Flex my={"lg"} align={"center"}>
              <Avatar size="md" color="brand" radius="xl" mr={"sm"}>
                2{" "}
              </Avatar>
              <Text className="flex-grow" fz="md" fw="bold">
                Follow Installation Guide
              </Text>
            </Flex>

            <Button
              variant="outline"
              onClick={() => {
                window.open(href, "_blank");
              }}>
              View Installation Guide
            </Button>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
