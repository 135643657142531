import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Switch,
  Text,
  useMantineTheme,
  Notification,
  Anchor,
  Divider,
  Title,
  List,
  Flex
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceFilterInternalTrafficService } from "@/lib/services/WorkspaceFilterInternalTrafficService";
import { WorkspaceMiscService } from "@/lib/services/WorkspaceMiscService";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { FilterInternalTrafficQueryBuilder } from "./FilterInternalTrafficQueryBuilder";

export const MiscellaneousExcludeTraffic = () => {
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [isAddRule, setIsAddRule] = useState(false);
  const [list, setList] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [isFilterInternalTraffic, setIsFilterInternalTraffic] = useState(
    activeWorkspace?.is_exclude_internal_traffic ? true : false
  );
  const createRule = async () => {
    if (list.filter((item) => item.rules.length === 0).length > 0) {
      toast.error("Please add atleast one rule first.");
      return;
    }
    setIsAddRule(true);
    await new WorkspaceFilterInternalTrafficService()
      .create(activeWorkspace.id, {
        type: "ip_address",
        operator: "equals",
        rules: []
      })
      .then((res) => {
        if (res.data) {
          setList([...list, res.data]);
        }
      })
      .catch((err) => {});
    setIsAddRule(false);
  };

  const onDelete = (id: string) => {
    new WorkspaceFilterInternalTrafficService()
      .delete(activeWorkspace.id, id)
      .then((res) => {
        if (res.data) {
          setLoading(true);
          setList(list.filter((item) => item.id !== id));
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const onUpdate = (
    id: string,
    type: string | null,
    operator: string | null,
    rules: Array<any>
  ) => {
    new WorkspaceFilterInternalTrafficService()
      .update(activeWorkspace.id, id, {
        type,
        operator,
        rules
      })
      .then((res) => {
        if (res.data) {
          setList(list.map((item) => (item.id === res.data.id ? res.data : item)));
          toast.success("Filters saved successfully.");
        }
      })
      .catch((err) => {});
  };

  const handleOnChangeInternalTrafficToggle = (value: boolean) => {
    new WorkspaceMiscService()
      .toggleInternalTrafficStatus(activeWorkspace.id, value)
      .then((res) => {
        if (res.data) {
          setActiveWorkspace({ ...activeWorkspace, is_exclude_internal_traffic: value });
          setIsFilterInternalTraffic(!isFilterInternalTraffic);
        }
      })
      .catch((err) => err);
  };

  const fetchRules = async () => {
    setLoading(true);
    await new WorkspaceFilterInternalTrafficService()
      .all(activeWorkspace.id, 1, 100)
      .then((res) => {
        if (res.data) {
          setList(res.data);
        }
      })
      .catch((err) => err);
    setLoading(false);
  };

  useEffect(() => {
    fetchRules();
  }, []);


  return (
    <>
      <>
        <h3 className="font-medium mb-4"></h3>
        <Title order={4} fw={600} mb="md">
          Exclude internal traffic and users
        </Title>
        <Text mb="md">
          Filter out traffic generated from internal sources, such as team members, test accounts,
          or development environments
        </Text>
        <Notification
          w={"85%"}
          mb={"md"}
          mt={"md"}
          color="orange"
          withBorder
          withCloseButton={false}
          title="Usermaven - Traffic Exclusion Chrome Extension">
          You can now exclude your own visits by using the Usermaven Chrome extension.
          <Anchor
            pl={"xs"}
            href="https://usermaven.com/docs/workspace-settings/exclude-internal-traffic-and-users#using-the-chrome-extension"
            target="_blank">
            Follow this link to learn more.
          </Anchor>
        </Notification>

        <Text fw={700} mb="sm">
          Example filters:
        </Text>
        <List fz="sm" mb="md">
          <List.Item>
            "Email contains @yourdomain.com" to exclude all events from your company's team members.
          </List.Item>
          <List.Item>
            "Hostname contains localhost" to exclude all events from local development environments.
          </List.Item>
        </List>

        <div className=" mb-4">
          <Switch
            size={"sm"}
            label={
              <div>
                Keep this option enabled if you want to exclude internal traffic based on the rules
                set below.
              </div>
            }
            checked={isFilterInternalTraffic}
            onChange={(event: any) =>
              handleOnChangeInternalTrafficToggle(event.currentTarget.checked)
            }></Switch>
        </div>
        <Divider my={"md"} />
        {loading ? (
          <>
            <div className="flex justify-center items-center">
              <Oval height={14} width={14} color={LOADER_COLOR}></Oval>
            </div>
          </>
        ) : (
          <>
            <div
              className={`flex flex-col ${
                !isFilterInternalTraffic ? "opacity-50 cursor-not-allowed" : ""
              }`}>
              {list.length === 0 && <Text>You do not have any rules created.</Text>}
              {list.length > 0 && (
                <>
                  <div>
                    {list.map((item, index) => (
                      <>
                        <FilterInternalTrafficQueryBuilder
                          item={item}
                          onDelete={onDelete}
                          onUpdate={onUpdate}
                          key={index}
                        />
                      </>
                    ))}
                  </div>
                </>
              )}
              <Box my={"sm"}>
                <Button
                  size="sm"
                  leftSection={<FontAwesomeIcon icon={regular("plus")} />}
                  variant="subtle"
                  disabled={isAddRule}
                  loading={isAddRule}
                  onClick={createRule}>
                  Add Rule
                </Button>
              </Box>
            </div>
          </>
        )}
      </>
    </>
  );
};
