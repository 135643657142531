import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Autocomplete, Box, Flex, Select, Tooltip } from "@mantine/core";
import { OPERATOR_OPTIONS, SELECTOR_OPTIONS } from "./ChannelMappingUtility";
import { InsightsService } from "@/lib/services/InsightsService";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { useContext, useEffect, useMemo, useState } from "react";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import { useDebouncedValue, useHover } from "@mantine/hooks";

interface Props {
  /**
   * Channel mapping rule index
   */
  ruleIndex: number;
  /**
   * Condition index
   */
  index: number;
  /**
   * Selector value i.e utm_source, utm_medium, utm_campaign, url, referer
   */
  selector: string;
  /**
   * Operator value i.e is, is_not, contains, does_not_contain
   */
  operator: string;
  /**
   * Value of the condition
   */
  value: string;
  /**
   * isLoaded will be true when the channel mapping mounted and loaded
   */
  isLoaded: boolean;
  /**
   * Function to update the selector value
   */
  onSelectorChange: (value: string) => void;
  /**
   * Function to update the operator value
   */
  onOperatorChange: (value: string) => void;
  /**
   * Function to update the value of the condition
   */
  onValueChange: (value: string) => void;
  /**
   * Function to remove the condition
   */
  onRemove: () => void;
}

/**
 * This component is used to render the condition of the rule.
 * Each channel mapping rule has multiple conditions. So, we need to
 * render multiple conditions for each rule.
 *
 * @returns
 */
export const ChannelMappingCondition = ({
  isLoaded = false,
  ruleIndex = 0,
  index = 0,
  selector = "utm_source",
  operator = "equals",
  value = "",
  onSelectorChange,
  onOperatorChange,
  onValueChange,
  onRemove
}: Props) => {
  const { hovered, ref } = useHover();
  /**
   * Current active workspace
   */
  const { activeWorkspace } = useContext(AppLifecycleContext);
  /**
   * This is the state to get the suggested options for the auto-complete.
   */
  const [searchValue] = useDebouncedValue(value, 200);
  /**
   * This is the state to store the auto-complete suggestions.
   */
  const [options, setOptions] = useState<string[]>([]);

  /**
   * Function to fetch autocompletion suggestions for the Channel parameters.
   * This function is called on first render and whenever the user types in the search box.
   * Top 10 results are retrieved.
   * @param value
   */
  const fetchSuggestionsList = async (value: string) => {
    // Send the request.
    // If the item is utm_medium, utm_source, utm_campaign, then send the request to the WebAnalyticsService.
    if (selector === "utm_medium" || selector === "utm_source" || selector === "utm_campaign") {
      await new WebAnalyticsService()
        .suggestions(activeWorkspace.id, selector, "", value, 10)
        .then((res) => {
          if (res.data) {
            setOptions(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
          setOptions([]);
        });
    } else if (selector === "url" || selector === "referer") {
      // If the item is url or referer, then send the request to the InsightsService.
      const type = selector === "url" ? "page_url" : "referer";
      await new InsightsService()
        .suggestions(activeWorkspace.id, type, value)
        .then((res) => {
          if (res.data) {
            setOptions(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
          setOptions([]);
        });
    } else setOptions([]);
  };

  /**
   * Function to check if the selector supports regex or not.
   */
  const supportsRegex = (selector: string) => {
    return ["url"].includes(selector);
  };

  /**
   * Function to get the operator options for the select component.
   * @returns Operator options based on the selector
   */
  const getOperatorOptions = useMemo(() => {
    // If an option's `implementation` property is "regex", it checks whether the `selector`
    // supports regex. If it does, the option is included in the result.
    // If the `implementation` is not "regex", the option is always included.
    return OPERATOR_OPTIONS.map((group) => ({
      group: group.group,
      items: group.items.filter((option) => {
        if (option.implementation && option.implementation === "regex") {
          return supportsRegex(selector);
        }
        return true;
      })
    }));
  }, [selector]); // The function will be re-computed when `selector` changes.

  /**
   *
   */
  const handleSelectorChange = (value: string) => {
    onSelectorChange(value);
    if (
      !supportsRegex(value) &&
      ["matches_pattern", "matches_regex", "starts_with", "ends_with"].includes(operator)
    ) {
      onOperatorChange("is");
    }
  };

  useEffect(() => {
    if (isLoaded) {
      fetchSuggestionsList(searchValue);
    }
  }, [searchValue, selector]);

  useEffect(() => {
    // on the first render, fetch the suggestions list for new conditions.
    if (value === "" && !isLoaded) {
      fetchSuggestionsList(searchValue);
    }
  }, []);

  return (
    <Flex align="center" ref={ref}>
      <Select
        // @ts-ignore
        onChange={handleSelectorChange}
        className="w-48"
        value={selector}
        data={SELECTOR_OPTIONS}
        mr={8}
        data-cy="channel-mapping-selector"
      />
      <Select
        // @ts-ignore
        onChange={onOperatorChange}
        value={operator}
        data={getOperatorOptions}
        mr={8}
      />
      <Autocomplete
        data={options}
        value={value}
        placeholder={
          operator === "matches_pattern"
            ? "https://www.example.com/*/products/*"
            : operator === "matches_regex"
            ? "https://www\\.example\\.com/(product|category)/[0-9]+"
            : `Select a ${selector}`
        }
        key={`channel-mapping-select-${selector}`}
        id={`channel-mapping-select-${selector}`}
        limit={25}
        w={300}
        maxDropdownHeight={250}
        data-cy="channel-mapping-search-autocomplete"
        onChange={(value) => {
          onValueChange(value);
        }}
      />
      <Box w={40} ml={"sm"}>
        {hovered && (
          <Tooltip label="Remove condition">
            <ActionIcon fz="sm" onClick={onRemove} variant="subtle" color={"red"}>
              <FontAwesomeIcon icon={regular("trash")} />
            </ActionIcon>
          </Tooltip>
        )}
      </Box>
    </Flex>
  );
};
