import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
} from "echarts/components"
import * as echarts from "echarts/core"
import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react"
import ReactEChartsCore from "echarts-for-react/lib/core"
import { ScatterChart } from "echarts/charts"
import { CanvasRenderer } from "echarts/renderers"
import { UniversalTransition } from "echarts/features"
import { Box, Button, Loader, Tooltip } from "@mantine/core"
import { FeatureAdoptionService } from "@/lib/services/FeatureAdoptionService"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { useNavigate } from "react-router-dom"
import isEqual from "react-fast-compare"
import { EChartsInstance, EChartsOption } from "echarts-for-react"

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  ScatterChart,
  CanvasRenderer,
  UniversalTransition,
])

export interface IFeatureAdoptionTab {
  uniqueId: string | undefined
  type: "user" | "company"
}


/**
 * 
 * @deprecated - NOT being used anymore
 */
export const FeatureAdoptionTab = ({ uniqueId, type }: IFeatureAdoptionTab) => {
  const { activeWorkspace } = useContext(AppLifecycleContext)
  const [loading, setLoading] = useState(true)
  const [tooltipText, setTooltipText] = useState("")
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const xAxisLabelRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const yAxis = ["never", "daily", "weekly", "bi-weekly", "monthly", "quarterly", "yearly"]
  const [option, setOption] = useState({
    tooltip: {
      show: true, // Enables the tooltip
      trigger: "item", // Trigger type; 'item' means the tooltip is triggered on data item (i.e., a point in scatter graph)
      formatter: function (params: any) {
        const usageMessage =
          params.data[1] === 0
            ? "never used"
            : `used on <span class="font-bold capitalize">${yAxis[params.data[1]]}</span> basis.`
        return `<span style="color:${params.color};" class="font-bold capitalize">${params.name}</span> ${usageMessage} `
      },
    },
    xAxis: {
      triggerEvent: true,
      type: "category",
      axisLine: {
        lineStyle: {
          color: "#343A40", // Light gray color, adjust to your preference
        },
      },
      axisLabel: {
        interval: 0, // This ensures that every label is displayed
        rotate: 45, // Rotate labels to fit more
        fontSize: 10, // Adjust font size for readability
        show: true,
        formatter: function (value: string) {
          return value.length > 20 ? value.substr(0, 20) + "..." : value
        },
      },
      data: [],
    },
    yAxis: {
      splitLine: {
        show: true, // This will show the grid lines (horizontal lines) for x-axis
        lineStyle: {
          type: "dashed", // optional: can be 'solid', 'dashed', or 'dotted'
          color: "#ddd", // optional: color of the grid line
        },
      },
      axisLine: {
        lineStyle: {
          color: "#343A40", // Light gray color, adjust to your preference
        },
      },
      type: "category",
      data: [],
    },
    series: [],
    grid: {
      bottom: "20%", // Increase bottom margin for readability
    },
  })

  const onEvents = {
    mouseover: (params: any) => {
      if (params.componentType === "xAxis" && params.targetType === "axisLabel") {
        const fullText = params.value
        setTooltipText(fullText)
      }
    },
    mouseout: (params: any) => {
      if (params.componentType === "xAxis" && params.targetType === "axisLabel") {
        setTooltipText("")
      }
    },
  }
  const fetchFeatureAdoptionData = async () => {
    await new FeatureAdoptionService()
      .byContact(activeWorkspace.id, uniqueId || "", type)
      .then(res => {
        if (res.data) {
          let updatedOption = {
            ...option,
            xAxis: {
              ...option.xAxis,
              data: res.data.xAxis,
            },
            yAxis: {
              ...option.yAxis,
              data: res.data.yAxis,
            },
            series: res.data.series,
            legends: res.data.legends,
          }
          setOption(updatedOption)
        }
      })
      .catch(err => {})
    setLoading(false)
  }

  useEffect(() => {
    fetchFeatureAdoptionData()
  }, [])

  return (
    <>
      <div>
        {loading ? (
          <Box
            mt="xl"
            pt="xl"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader color="gray" size={16} />
          </Box>
        ) : option.series && option.series.length > 0 ? (
          <>
            <Tooltip.Floating
              label={tooltipText}
              withinPortal
              styles={{
                tooltip: {
                  // hide in case of empty tooltip text
                  display: tooltipText.length === 0 ? "none !important" : "",
                },
              }}
            >
              <div
                tabIndex={0} // Make the div focusable
                ref={xAxisLabelRef}
              >
                <MemoizedEchart
                  echarts={echarts}
                  style={{ height: "450px" }}
                  option={option}
                  onEvents={onEvents}
                />
              </div>
            </Tooltip.Floating>
          </>
        ) : (
          <div className="flex w-full flex-grow items-center justify-center py-5 text-center text-gray-500 py-4">
            <Button
              variant="outline"
              onClick={() =>
                navigate(
                  `/env/${activeWorkspace.identifier}/insights/dashboard/feature`, 
                  { "state" : { "isModalOpen" : true } }
                )
              }
            >
              Create Feature Adoption Insight
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

type ChartProps = {
  echarts: EChartsInstance
  style: CSSProperties
  option: EChartsOption
  onEvents: Record<string, Function> | undefined
}

const MemoizedEchart: React.FC<ChartProps> = React.memo(
  ({ echarts, style, option, onEvents }) => (
    <ReactEChartsCore echarts={echarts} style={style} option={option} onEvents={onEvents} />
  ),
  (prevProps, nextProps) => {
    // Here we can perform a deep equality check to prevent re-render
    // For example when option prop doesn't change structurally
    return isEqual(prevProps.option, nextProps.option)
  }
)
