/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { convertSecondsToReadableFormat } from "@/lib/utils/StringUtility";
import React from "react";
import { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import { SummaryComparisonCard } from "../SummaryComparisonCard";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import ContentLoader from "react-content-loader";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
};

const VisitDurationComparisonCard = ({
  activeCard,
  setActiveCard,
  comparisonTopBarCardValues,
  loading,
  query
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="visit-duration-card">
      {loading === "loaded" ? (
        <SummaryComparisonCard
          card="visit_duration"
          activeCard={activeCard}
          onClick={() => setActiveCard("visit_duration")}
          uniquekey={"visitDuration"}
          loading={loading}
          comparisonValue={`${convertSecondsToReadableFormat(
            comparisonTopBarCardValues.visit_duration
          )}`}
          comparisonTooltip={`${convertSecondsToReadableFormat(
            comparisonTopBarCardValues.visit_duration
          )} average time spent on website across all pages`}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const SessionComparisonCardMemoized = React.memo(VisitDurationComparisonCard);
export { SessionComparisonCardMemoized as VisitDurationComparisonCard };
