/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { AutoCapturedEventsService } from "@/lib/services/AutoCapturedEventsService";
import { getGroupedACEventType } from "@/lib/utils/AutoCaptureUtility";
import { ClickhouseDateToLocalRelativeTime } from "@/lib/utils/DateUtility";
import { numberToCommas, truncateText } from "@/lib/utils/StringUtility";
import { usePinnedEventStore } from "@/stores/usePinnedEventStore";
import { translateEventToConditions } from "@/ui/components/App/Events/PinnedEvents/PinnedEventUtility";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Loader,
  Text,
  TextInput,
  useMantineTheme
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { AutoCaptureEventProps } from "types/types.d";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const ACService = new AutoCapturedEventsService();

const AutoCapturedEvents = () => {
  const theme = useGlobalMantineTheme();
  const [events, setEvents] = useState<Array<AutoCaptureEventProps>>([]);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [toggleMenuValue, setToggleMenuValue] = useState("");
  const [loading, setLoading] = useState("idle");

  const [reset, setModalOpen, setRules, fetchTotalEvents] = usePinnedEventStore((state) => [
    state.reset,
    state.setModalOpen,
    state.setRules,
    state.fetchTotalEvents
  ]);
  const [query, setQuery] = useDebouncedState("", 200);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [historyLoading, setHistoryLoading] = useState("idle");
  const fetchEvents = async () => {
    setLoading("loading");
    await ACService.grouped(activeWorkspace.id, 1, query)
      .then((res) => {
        if (res.data) {
          setEvents(res.data);
        }
        // the limit is 20 events per page
        setHasMore(!res.data || res.data.length === 0 || res.data.length < 20 ? false : true);
      })
      .catch((err) => {});
    setLoading("loaded");
    ReactTooltip.rebuild();
  };
  useEffect(() => {
    fetchEvents();
  }, [query]);

  const handleLoadMoreEvents = async () => {
    setPage(page + 1);
    setHistoryLoading("loading");
    await ACService.grouped(activeWorkspace.id, page + 1, query)
      .then((res) => {
        if (res.data) {
          setEvents([...events, ...res.data]);
        }
        // the limit is 20 events per page
        setHasMore(!res.data || res.data.length === 0 || res.data.length < 20 ? false : true);
      })
      .catch((err) => {});
    setHistoryLoading("loaded");
  };

  const canCreatePinnedEvent = (event: any) => {
    if (event.autocaptured_attr_class) return true;
    if (event.autocaptured_attr_id) return true;
    if (event.text_label) return true;
    return false;
  };
  const createPinnedEvent = (event: any) => {
    const rules = translateEventToConditions(event);
    reset();
    setModalOpen(true);
    setRules([
      {
        conditions: rules
      }
    ]);
    fetchTotalEvents(activeWorkspace.id);
  };

  useEffect(() => {
    document.title = "Events | Auto Captured | Usermaven";
  }, []);

  return (
    <Box>
      <Box component="div">
        <Text mb="md">
          Auto-capture automatically records all user interactions, saving time and ensuring no
          important events are missed. Easily identify and pin key interactions for valuable
          insights into user behavior.
        </Text>
        <Divider mb="md" />
        <Flex align="center" mb={"md"}>
          <TextInput
            leftSection={<FontAwesomeIcon icon={regular("search")}></FontAwesomeIcon>}
            placeholder="Search by text label"
            defaultValue={query}
            onChange={(event) => setQuery(event.currentTarget.value)}
          />
        </Flex>
        <Box>
          <Box
            style={
              {
                // minWidth: "800px"
              }
            }>
            {loading === "loaded" ? (
              <>
                {events.length > 0 && (
                  <>
                    <Grid fz="sm" fw={600} my={"md"}>
                      <Grid.Col span={3}>Event</Grid.Col>
                      <Grid.Col span={4}>Source page URL </Grid.Col>
                      <Grid.Col span={2} ta="center">
                        No. of clicks
                      </Grid.Col>
                      <Grid.Col span={2}>Last occurrence</Grid.Col>
                      <Grid.Col span={1}></Grid.Col>
                    </Grid>
                    <Divider mb="md" />
                  </>
                )}

                {events.length > 0 &&
                  events.map((event: any, index: number) => (
                    <>
                      <Grid key={index} fz="sm" pb="sm" mb="xs">
                        <Grid.Col span={3}>
                          <Flex direction={"column"}>
                            {getGroupedACEventType(event) || ""}
                            <Box></Box>
                            {event.destination_url && (
                              <>
                                <Text c="dimmed" fz="xs" pt={"xs"}>
                                  Destination URL: {truncateText(event.destination_url, 64)}
                                  {/* <span className="pl-2">Total clicks: {event.count}</span> */}
                                </Text>
                              </>
                            )}
                          </Flex>
                        </Grid.Col>

                        <Grid.Col span={4}>
                          <Anchor
                            fz="sm"
                            href={event.source_page_url}
                            target="_blank"
                            rel="noreferrer">
                            <Text fz="sm" lineClamp={2}>
                              {event.source_page_url || "-"}
                            </Text>
                          </Anchor>
                        </Grid.Col>
                        <Grid.Col span={2} fw={500} ta={"center"}>
                          {numberToCommas(event.count)}
                        </Grid.Col>
                        <Grid.Col span={2}>
                          {" "}
                          {ClickhouseDateToLocalRelativeTime(event.last_occurrence)}
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Flex justify={"center"}>
                            {canCreatePinnedEvent(event) && (
                              <>
                                <ActionIcon
                                  size={"sm"}
                                  variant="subtle"
                                  onClick={() => createPinnedEvent(event)}
                                  data-tip="Create pinned event">
                                  <FontAwesomeIcon icon={regular("thumbtack")}></FontAwesomeIcon>
                                </ActionIcon>
                              </>
                            )}
                          </Flex>
                        </Grid.Col>
                      </Grid>
                    </>
                  ))}

                {events && events.length === 0 && (
                  <div className="flex items-center justify-center">
                    <NoResults
                      text={
                        toggleMenuValue === "error"
                          ? "There are no error events available."
                          : "There are no events available."
                      }
                      heading={"No events"}></NoResults>
                  </div>
                )}
              </>
            ) : (
              <>
                <Center py="md">
                  <Loader size={"sm"} />
                </Center>
              </>
            )}

            <Center>
              {events.length > 0 && hasMore && (
                <Button
                  size="xs"
                  variant="outline"
                  loading={historyLoading === "loading"}
                  onClick={() => {
                    handleLoadMoreEvents();
                  }}>
                  Load More
                </Button>
              )}
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AutoCapturedEvents;
