import { ConversionFunnel } from "@/ui/components/App/WebAnalytics/ConversionFunnel/ConversionFunnel";
import {
  HeaderNavigationContainer,
  HeaderNavigationH1
} from "../../components/App/HeaderNavigation/HeaderNavigation.style";
import { useConversionFunnelStore } from "../../../stores/useConversionFunnelStore";
import { Anchor, Box, Button, Flex, Text, useMantineTheme } from "@mantine/core";
import { MOBILE_BREAKPOINT_QUERY, WORKSPACE_MEMBER_ROLES } from "../../../lib/utils/Constants";
import useWorkspaceUtilityHook from "../../../hooks/useWorkspaceUtilityHook";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mantine/hooks";
import locale from "@/ui/pages/InsightsShareableDashboard/locale";
import { IntlProvider } from "react-intl";
import { Header } from "@/ui/components/Common/Header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const Funnels = () => {
  const { hasRolesNotWith, isStarterPlan } = useWorkspaceUtilityHook();

  const [list, setModalOpen, reset, listLoading, activeFunnelTab] = useConversionFunnelStore(
    (state) => [
      state.list,
      state.setModalOpen,
      state.reset,
      state.listLoading,
      state.activeFunnelTab
    ]
  );

  /**
   * Open a funnel conversion model and clear the form fields and conditions.
   */
  const toggleCreateModal = () => {
    if (isStarterPlan && list.length >= 3) {
      toast.error("Please upgrade your plan to create more funnels.");
      return;
    }
    reset({
      funnelType: activeFunnelTab === "all" ? "visitor" : activeFunnelTab
    });
    setModalOpen(true);
  };

  useEffect(() => {
    document.title = "Funnels | Usermaven";
    console.log("Funnels.tsx useEffect");
  }, []);

  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  return (
    <IntlProvider locale={"en"} messages={locale["en"]}>
      <Header
        title="Funnel Analysis"
        leftSection={
          <Anchor href="https://usermaven.com/docs/funnels" target="_blank" fz="xs" ml={2}>
            <Flex align={"center"}>
              <Box mr={4}>
                <FontAwesomeIcon icon={regular("info-circle")} />
              </Box>
              Learn More
            </Flex>
          </Anchor>
        }
        rightSection={
          <>
            {list.length > 0 &&
              !listLoading &&
              hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                <Button
                  // variant="gradient"
                  onClick={() => {
                    toggleCreateModal();
                  }}
                  size={isMobileView ? "xs" : "sm"}>
                  New Funnel
                </Button>
              )}
          </>
        }
      />
      <ConversionFunnel />
    </IntlProvider>
  );
};

export default Funnels;
