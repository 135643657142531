import {
  format,
  differenceInDays,
  subDays,
  startOfMonth,
  endOfMonth,
  subMonths,
  set,
  startOfQuarter,
  subQuarters,
  endOfQuarter,
  addDays
} from "date-fns";

type DateFormat = string;

interface FilterQueryParams {
  from_date: string;
  to_date: string;
  period: string;
  match_day_of_week: boolean;
  comparison?: string;
  custom_range?: boolean;
  label?: string;
}

const comparisonDates = (
  from_date_str: string,
  to_date_str: string,
  period: string | null,
  match_day_of_week: boolean,
  comparison: string | undefined,
  custom_range: boolean | undefined,
  label?: string,
  date_format: DateFormat = "yyyy-MM-dd HH:mm:ss"
): {
  comparison_from_date: string;
  comparison_to_date: string;
} => {
  const from_date = new Date(from_date_str);
  const to_date = new Date(to_date_str);
  if (isNaN(from_date.getTime()) || isNaN(to_date.getTime())) {
    throw new Error("Invalid date strings provided");
  }
  const total_days = differenceInDays(to_date, from_date) + 1;
  let comparison_from_date = subDays(from_date, total_days);
  let comparison_to_date = subDays(to_date, total_days);

  // NOTE: This is only for This Month Filter
  const thisMonthLabel = `${format(startOfMonth(new Date()), "MMM dd")} - ${format(
    new Date(),
    "MMM dd"
  )}`;
  const isThisMonth = label ? [thisMonthLabel, "This Month"].includes(label) : false;
  const isLabelMismatch = label
    ? !["Last 7 days", "Last 14 days", "Last 21 days"].includes(label)
    : false;
  if (
    isThisMonth &&
    isLabelMismatch &&
    !custom_range &&
    comparison === "previous_period" &&
    period === "day" &&
    total_days < 30
  ) {
    // Get the starting date of the month prior to the from_date
    comparison_from_date = startOfMonth(subMonths(from_date, 1));
    // Get the ending date of the month prior to the from_date
    comparison_to_date = endOfMonth(subMonths(from_date, 1));
  }

  const lastQuarterLabel = `${format(
    subQuarters(startOfQuarter(new Date()), 1),
    "MMM dd"
  )} - ${format(subQuarters(endOfQuarter(new Date()), 1), "MMM dd")}`;
  const islastQuarter = label ? [lastQuarterLabel, "Last Quarter"].includes(label) : false;

  if (islastQuarter) {
    comparison_from_date = subQuarters(startOfQuarter(new Date()), 2);
    comparison_to_date = subQuarters(endOfQuarter(new Date()), 2);
  }

  comparison_to_date = set(comparison_to_date, { hours: 23, minutes: 59, seconds: 59 });

  const sameDayLabel = `${format(new Date(from_date), "MMM dd")} - ${format(
    new Date(from_date),
    "MMM dd"
  )}`;
  const isSameDate = label ? [sameDayLabel, "Today", "Yesterday"].includes(label) : false;

  if (match_day_of_week && !isSameDate) {
    const dayOfWeek = format(from_date, "EEEE");
    let difference = findExactSameDayOfWeek(dayOfWeek, comparison_from_date).difference;
    let move = findExactSameDayOfWeek(dayOfWeek, comparison_from_date).move;
    let new_comparison_from_date = findExactSameDayOfWeek(dayOfWeek, comparison_from_date).date;
    const new_comparison_to_date = adjustEndDateBasedOnStartDate(
      comparison_to_date,
      difference,
      move
    );
    return {
      comparison_from_date: format(new_comparison_from_date, date_format),
      comparison_to_date: format(new_comparison_to_date, date_format)
    };
  }
  return {
    comparison_from_date: format(comparison_from_date, date_format),
    comparison_to_date: format(comparison_to_date, date_format)
  };
};

export const getComparisonPeriod = (
  filters: FilterQueryParams
): { comparison_from_date: string | null; comparison_to_date: string | null } => {
  const { from_date, to_date, period, match_day_of_week, comparison, custom_range, label } =
    filters;

  if (["hour", "day", "week", "month", "year"].includes(period)) {
    return comparisonDates(
      from_date,
      to_date,
      period,
      match_day_of_week,
      comparison,
      custom_range,
      label
    );
  }

  return {
    comparison_from_date: null,
    comparison_to_date: null
  };
};

export const findExactSameDayOfWeek = (dayOfWeek: string, date: Date) => {
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const targetDay = daysOfWeek.indexOf(dayOfWeek);

  if (targetDay === -1) {
    throw new Error("Invalid dayOfWeek provided.");
  }

  // Find the closest past and future dates with matching dayOfWeek
  let closestPastDate = date;
  // let closestFutureDate = date; // Not using Future Date for now
  
  while (closestPastDate.getDay() !== targetDay) {
    closestPastDate = subDays(closestPastDate, 1);
  }

  // Not using Future Date for now
  // while (closestFutureDate.getDay() !== targetDay) {
  //   closestFutureDate = addDays(closestFutureDate, 1);
  // }

  const daysDifferenceBefore = Math.abs(differenceInDays(date, closestPastDate));

  return {
    difference: daysDifferenceBefore,
    move: "before",
    date: closestPastDate
  };
};

const adjustEndDateBasedOnStartDate = (endDate: Date, difference: number, move: string) => {
  let newEndDate;
  if (move === "before") {
    newEndDate = subDays(endDate, Math.abs(difference));
  } else if (move === "after") {
    newEndDate = addDays(endDate, difference);
  } else {
    newEndDate = endDate;
  }

  return newEndDate;
};
