// Function to convert camelCase to Title Case
export const toTitleCase = (str: string = ""): string => {
  // Handle snake_case
  if (str.includes("_")) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // Handle camelCase
  return str
    .replace(/([a-z0-9])([A-Z])/g, "$1 $2") // Insert space between lower-case and upper-case letters
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const groupInsights = (insightsList: any[]) => {
  return insightsList.reduce((acc: any, item: any) => {
    const group =
      item.type.replaceAll("_", " ")[0].toUpperCase() + item.type.replaceAll("_", " ").slice(1);

    const existingGroup = acc.find((g: any) => g.group === group);
    if (existingGroup) {
      existingGroup.items.push({
        label: item.name,
        value: item.id
      });
    } else {
      acc.push({
        group,
        items: [
          {
            label: item.name,
            value: item.id
          }
        ]
      });
    }

    return acc;
  }, []);
};
