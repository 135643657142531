import { WorkspaceSharingType } from 'types/types.d';
import axios from './JWTService';

export class WorkspaceShareService {
  put = (
    workspaceId: string,
    sharing: WorkspaceSharingType,
    sharePassword: string | null
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`v1/workspace_share/${workspaceId}`, {
          sharing: sharing,
          share_password: sharePassword
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  get = (workspaceId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`v1/workspace_share/${workspaceId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getByIdentifier = (identifier: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/workspace_share/${identifier}/identifier`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  validation = (workspaceId: string, sharePassword: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`v1/workspace_share/${workspaceId}/validation`, {
          share_password: sharePassword
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
