import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { getPublicRedirectionRouteURL } from "@/lib/utils/RedirectionRoute"
import { useEffect, useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading"
import { StringParam, useQueryParam } from "use-query-params"
export const AppMain = (props: any) => {
  const { user, isLoading, activeWorkspace, workspaces } = useContext(AppLifecycleContext)
  const [redirect, setRedirect] = useQueryParam("redirect", StringParam)

  const navigate = useNavigate()
  const location = useLocation()
  const redirectPath = getPublicRedirectionRouteURL(user, activeWorkspace, redirect)
  console.log(`Redirect path is ${redirectPath}`)
  if (!user) {
    navigate("/login")
  }

  useEffect(() => {
    // redirect to email verified
    if (user && !user.is_email_verified && location.pathname !== redirectPath) {
      navigate(redirectPath)
    }

    // create workspace

    if (user && !user.is_onboarding_completed && location.pathname !== redirectPath) {
      navigate(redirectPath)
    }
    // Onboarding route i.e /onboarding/setup
    if (
      user &&
      user.is_onboarding_completed &&
      !activeWorkspace.is_setup_completed &&
      location.pathname !== redirectPath
    ) {
      navigate(redirectPath)
    }
    // redirect to workspaces page.
    if (
      user &&
      user.is_onboarding_completed &&
      !activeWorkspace &&
      workspaces.length === 0 &&
      location.pathname !== redirectPath
    ) {
      navigate(redirectPath)
    }

    if (redirectPath.includes("env") && location.pathname !== redirectPath) {
      navigate(redirectPath)
    }
    console.log(redirectPath)
  }, [])

  useEffect(() => {
    document.title = "Usermaven"
  }, [])
  console.log("Redirected route...", redirectPath)
  return <LoadingComponent />
}
