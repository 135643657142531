import Bar, { IBar, IBarData } from "../atoms/Bar";
import { Box, useMantineTheme } from "@mantine/core";
import MultiBar from "@/ui/pages/Analyze/components/FunnelBarChart/components/atoms/MultiBar";
import { IFunnelMeta } from "@/ui/pages/Analyze/components/FunnelDetailedView/FunnelDetails";
import { IMultiBarData } from "@/ui/pages/Analyze/components/FunnelBarChart/components/organisms/MultiBarChart";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface IBarGroupProps {
  /**
   * The bars to be shown in the bar group
   */
  bars: IMultiBarData[][];

  /**
   * Gap between the bars
   */
  gap?: number;

  /**
   * Max value of the bars
   */
  max: number;

  /**
   * Type of the bar
   */
  type?: string;

  /**
   * meta info
   */
  initialBars: IBarData[];
}

const MultiBarGroup = ({
  bars,
  gap = 100,
  max,
  type = "Visitors",
  initialBars
}: IBarGroupProps) => {
  const theme = useGlobalMantineTheme();
  return (
    <Box
      sx={{
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "100%"
      }}>
      <Box
        className="bar-group"
        sx={{
          display: "flex",
          overflowX: "auto",
          height: "100%",
          width: "100%",
          borderLeft: `1px solid ${
            theme.colorScheme === "dark" ? theme.colors.darkBorderColor[0] : "#e0e0e0"
          }`,
          borderBottom: `1px solid ${
            theme.colorScheme === "dark" ? theme.colors.darkBorderColor[0] : "#e0e0e0"
          }`
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            height: "100%",
            width: "100%",
            gap: `${gap}px`,
            flexWrap: "nowrap",
            whiteSpace: "nowrap",
            flexShrink: 0,

            "&::before": {
              content: '""',
              margin: "auto",
              marginRight: "-70px"
            },

            "&::after": {
              content: '""',
              margin: "auto",
              marginLeft: "-70px"
            }
          }}>
          {bars.map((bar, index) => {
            return (
              <MultiBar
                key={index}
                data={bar}
                max={max}
                type={type}
                initialBars={initialBars[index + 1]}
                isLast={index === bars.length - 1}
                width={bars.length > 2 ? 35 : 50}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default MultiBarGroup;
