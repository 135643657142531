import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { Badge, Box, Divider, Flex, Paper, ScrollArea, Table, Title } from "@mantine/core";
import React from "react";
import { LooseObject } from "types/types.d";

export interface Props {
  title?: string;
  insightUsage: Array<LooseObject>;
}

export const EventsDetailedUsageCard: React.FC<Props> = ({ title = "Usage", insightUsage }) => {
  return (
    <>
      <Paper withBorder component="div">
        <ScrollArea style={{ height: "24rem" }}>
          <Flex align={"center"} data-cy="event-detailed-activity-histogram-title">
            <Title p="md" order={5} fw={600} flex={1}>
              Usage
            </Title>
          </Flex>
          <Divider />
          <Box p="xs">
            {insightUsage.length > 0 ? (
              <>
                <Table horizontalSpacing="sm" verticalSpacing="sm" striped>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Name</Table.Th>
                      <Table.Th>Type</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <tbody>
                    {insightUsage.length > 0 &&
                      insightUsage.map((insightObject: any) => (
                        <Table.Tr>
                          {insightObject.insight && (
                            <>
                              <Table.Td>{insightObject.insight.name}</Table.Td>
                              <Table.Td>
                                <Badge color="blue" variant="light" mr="xs">
                                  {insightObject.insight.type.split("_").join(" ")}
                                </Badge>
                                <Badge color="blue" variant="light">
                                  Insight
                                </Badge>
                              </Table.Td>
                            </>
                          )}
                          {insightObject.segment && (
                            <>
                              <Table.Td>{insightObject.segment.name}</Table.Td>
                              <Table.Td>
                                <Badge color="violet" variant="light">
                                  Segment
                                </Badge>
                              </Table.Td>
                            </>
                          )}
                          {insightObject.feature && (
                            <>
                              <Table.Td>{insightObject.feature.name}</Table.Td>
                              <Table.Td>
                                <Badge color="grape" variant="light">
                                  Feature
                                </Badge>
                              </Table.Td>
                            </>
                          )}
                        </Table.Tr>
                      ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <Flex justify={"center"} mt={"lg"}>
                <NoResults
                  text={"There is no usage of this pinned event."}
                  heading={"No Usage Found"}
                />
              </Flex>
            )}
          </Box>
        </ScrollArea>
      </Paper>
    </>
  );
};
