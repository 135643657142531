// Remove user_, location_ and _ fields.
// Rename the doc_path and count header for table.
import { formatDistance } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// Get Row Value.
export const _getRowValue = (key: any, value: any): any => {
  if (typeof value === "string" && key === "location_country") {
    return countries.getName(value.toUpperCase(), "en", {
      select: "official"
    });
  }
  // NOTE: Regex taken from: https://gist.github.com/m-coding/c96d99558a47d30aef4992c6dd60437a
  // Check if the pattern is date time then show "time ago using date-fns"
  if (typeof value === "string" && value.match(/(\d{4})-(\d{2})-(\d{2})/)) {
    // NOTE: ClickHouse DB returns 0000-00-00/1970-01-01 when the value is null.
    if (value.includes("0000-00-00") || value.includes("1970-01-01")) {
      return "...";
    }
    try {
      return _convertDateToTimeAgo(value);
    } catch (ex) {
      return "...";
    }
  } else {
    return value || "...";
  }
};

/**
 * This function is responsible for converting the data from the API to the format like: about a minute ago, 2 days ago, etc.
 * We will use date-fns to achieve this.
 * @param date{String} - The date string from the API.
 * @returns {String} - The formatted date string.
 */
export const _convertDateToTimeAgo = (date: string): string => {
  const localTime = fromZonedTime(new Date(date.replace(" ", "T")), "UTC");
  return formatDistance(localTime, new Date(), {
    addSuffix: true
  });
};

// row class name for applying styles
export const _getRowClassName = (index: any) => {
  if (index < 0) {
    return "um-virtual-table--row--header";
  } else {
    return index % 2 === 0 ? "um-virtual-table--row--even" : "um-virtual-table--row--odd";
  }
};

// dropdown caret class
export const _getCaretClassName = (fieldName: string, sort: string) => {
  if (`${fieldName}:asc` === sort) {
    return "um-virtual-table--header--content--value--caret--reverse";
  }
  return "";
};

export const _getActiveSortingClass = (field: any, sort: string) => {
  return `${field?.name}:desc` === sort || `${field?.name}:asc` === sort;
};
