/**
 * Common Engagement Section component.
 */
import {
  Box,
  Card,
  Flex,
  Grid,
  Paper,
  Popover,
  Rating,
  Skeleton,
  Text,
  Tooltip,
  useMantineColorScheme
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { renderSourceIcon } from "@/ui/components/Common/VirtualDataTable/components/Source";
import { IContact } from "./ContactSection";
import { getReadableFormatNumber, numberToCommas } from "@/lib/utils/StringUtility";
import { gray } from "@/config/theme";
import { useDisclosure } from "@mantine/hooks";

export interface IEngagementSection {
  contact: (IContact & IEngagement) | null;
  loading: boolean;
}

export interface IEngagement {
  /**
   * Specify the engagement section items.
   */
  items: IEngagementSectionItem[];
}

export interface IEngagementSectionItem {
  /**
   * Specify the item title.
   */
  title: string;

  /**
   * Specify the item subtitle.
   */
  subtitle: string;

  /**
   * Specify any extra props to be passed to the item.
   */
  props?: any;
}

/**
 * Top Section in Users, Visitors and Companies Modal.
 * Contains boxes with information like First Seen, Created At, Last Seen, etc.
 */
const EngagementSection = ({ contact, loading }: IEngagementSection) => {
  const { colorScheme } = useMantineColorScheme();
  const isEngagementItem = (title: string) => {
    return title === "Engagement";
  };

  const isFirstSourceItem = (title: string) => {
    return title === "First Touch Point" || title === "First Seen";
  };

  const isUsersAndSessionsItem = (title: string) => {
    return title === "Users / Sessions";
  };

  const isTotalPageViewsItem = (title: string) => {
    return title === "Total Page Views";
  };

  // The created at company item includes the source as well
  const isCreatedAndCompanyItem = (title: string, subtitle: string) => {
    return title === "Created" && subtitle.includes("from");
  };

  const faviconStyle = {
    style: {
      verticalAlign: "middle",
      width: "16px"
    }
  };

  const ENGAGEMENT_TOOLTIP_TEXT =
    "We measure user engagement based on their activity in the last 30 days and categorize them into different levels based on how their activity compares to other users.";

  const FirstSourceSubtitleItem = ({ subtitle, props = {} }: IEngagementSectionItem) => {
    const parts = subtitle.split(" from ");
    const { channel: propsChannel = "", detailedSource = "", referrer = "" } = props;
    const isPropsEmpty = !propsChannel && !detailedSource && !referrer;

    // poppver state
    const [opened, { close, open }] = useDisclosure(false);
    // popover content
    const PopoverDropdownContent = () => {
      return (
        <>
          <Text tt="capitalize">
            <Text fw={500} span>
              Channel:{" "}
            </Text>
            {propsChannel}
          </Text>
          <Text>
            {detailedSource.includes("Source:") ? (
              detailedSource
                .split(/ (?=Source:|Medium:|Campaign:)/i)
                .map((item: string, index: number) => {
                  const utm_types = item.split(":");
                  return (
                    <Text key={index}>
                      <Text fw={500} span>
                        {utm_types[0]}
                        {": "}
                      </Text>
                      {utm_types[1]}
                    </Text>
                  );
                })
            ) : (
              <>
                <Text fw={500} span>
                  Source:{" "}
                </Text>
                {detailedSource}
              </>
            )}
          </Text>
          <Text>
            {referrer && (
              <>
                <Text fw={500} span>
                  Referrer:{" "}
                </Text>
                {referrer}
              </>
            )}
          </Text>
        </>
      );
    };

    // icon
    const SourceIcon = (referrer: string = "") => {
      return renderSourceIcon(referrer || "", faviconStyle);
    };

    // Users and Companies case
    // if the subtitle has time and channel/source eg. 10 days ago from Referral / google.com
    // render the favicon after the time and channel and before the source
    if (parts.length > 1) {
      const [time, channelAndSource] = parts;
      const [channel, source] = channelAndSource.split(" / ");
      return (
        <Text ta="center" pt="xs">
          {`${time} from `}
          <Popover
            position="bottom"
            withArrow
            disabled={isPropsEmpty}
            withinPortal
            shadow="md"
            opened={opened}>
            <Popover.Dropdown
              sx={(theme) => ({
                color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[9]
              })}>
              <PopoverDropdownContent />
            </Popover.Dropdown>
            <Popover.Target>
              <Text
                sx={(theme) => ({
                  paddingTop: "4px",
                  cursor: "pointer",
                  "&:hover": {
                    color:
                      theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.gray[7]
                  }
                })}
                onMouseEnter={open}
                onMouseLeave={close}>
                {channel.trim() === "Direct" ? (
                  <>
                    {SourceIcon(referrer)} {channelAndSource}
                  </>
                ) : (
                  <>
                    {channel}
                    {" / "}
                    {SourceIcon(referrer)} {source}
                  </>
                )}
              </Text>
            </Popover.Target>
          </Popover>
        </Text>
      );
    }

    const [channel, source] = subtitle.split(" / ");
    return (
      // In case of visitors
      // if the subtitle only has channel/source eg. Referral / google.com
      // render the favicon after the channel and before the source
      <Popover
        position="bottom"
        withArrow
        disabled={isPropsEmpty}
        withinPortal
        shadow="md"
        opened={opened}>
        <Popover.Dropdown
          sx={(theme) => ({
            color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[9]
          })}>
          <PopoverDropdownContent />
        </Popover.Dropdown>
        <Popover.Target>
          <Text
            ta="center"
            pt="xs"
            span
            sx={(theme) => ({
              cursor: "pointer",
              "&:hover": {
                color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.gray[7]
              }
            })}
            onMouseEnter={open}
            onMouseLeave={close}>
            {channel.trim() === "Direct" ? (
              <>
                {SourceIcon(referrer)} {subtitle}
              </>
            ) : (
              <>
                {channel}
                {" / "}
                {SourceIcon(referrer)} {source}
              </>
            )}
          </Text>
        </Popover.Target>
      </Popover>
    );
  };

  const UsersAndSessionsSubtitleItem = ({ subtitle }: IEngagementSectionItem) => {
    const [numberOfUsers, numberOfSessions] = subtitle.split(" / ");
    return (
      <>
        <Tooltip
          label={`${numberToCommas(Number(numberOfUsers))} / ${numberToCommas(
            Number(numberOfSessions)
          )}`}
          disabled={Number(numberOfUsers) < 10000 && Number(numberOfSessions) < 10000}>
          <Text ta="center" pt="xs">
            {`${getReadableFormatNumber(Number(numberOfUsers), 10000)} / ${getReadableFormatNumber(
              Number(numberOfSessions),
              10000
            )}`}
          </Text>
        </Tooltip>
      </>
    );
  };

  const TopPagesSubtitleItem = ({ subtitle }: IEngagementSectionItem) => {
    return (
      <>
        <Tooltip label={numberToCommas(Number(subtitle))} disabled={subtitle.length < 5}>
          <Text ta="center" pt="xs">
            {getReadableFormatNumber(Number(subtitle), 10000)}
          </Text>
        </Tooltip>
      </>
    );
  };

  return (
    <Box mb="xs">
      {loading ? (
        <Flex w={"100%"} flex={1} justify={"space-between"} align={"center"} pb="sm">
          <Skeleton width={200} height={85} radius={0} />
          <Skeleton width={200} height={85} radius={0} />
          <Skeleton width={200} height={85} radius={0} />
          <Skeleton width={200} height={85} radius={0} />
        </Flex>
      ) : (
        <>
          {contact ? (
            <Grid gutter="md" justify="space-between" align="stretch" mb={0}>
              {contact.items.map((item, index) => (
                <Grid.Col span={3} key={`${item.title}:${index}`}>
                  <Card
                    withBorder
                    radius={"sm"}
                    key={index}
                    bg={colorScheme === "dark" ? "dark.8" : "gray.0"}>
                    <Flex direction="column" align="center" justify="center" my="sm" mih={80}>
                      <Text fz="sm" fw="bold" pt={8}>
                        {item.title}
                        {isEngagementItem(item.title) && (
                          <Tooltip
                            label={ENGAGEMENT_TOOLTIP_TEXT}
                            multiline
                            disabled={!isEngagementItem(item.title)}
                            withArrow
                            w={300}
                            fw={500}>
                            <FontAwesomeIcon
                              className="ml-2 mt-1"
                              icon={light("circle-info")}
                              color={gray[700]}
                              size="sm"
                            />
                          </Tooltip>
                        )}
                      </Text>
                      {isEngagementItem(item.title) ? (
                        <EngagementSubtitleItem subtitle={item.subtitle} />
                      ) : isFirstSourceItem(item.title) ||
                        isCreatedAndCompanyItem(item.title, item.subtitle) ? (
                        <FirstSourceSubtitleItem
                          title={item.title}
                          subtitle={item.subtitle}
                          props={item.props}
                        />
                      ) : isUsersAndSessionsItem(item.title) ? (
                        <UsersAndSessionsSubtitleItem title={item.title} subtitle={item.subtitle} />
                      ) : isTotalPageViewsItem(item.title) ? (
                        <TopPagesSubtitleItem title={item.title} subtitle={item.subtitle} />
                      ) : (
                        <Text ta="center" pt="xs">
                          {item.subtitle}
                        </Text>
                      )}
                    </Flex>
                  </Card>
                </Grid.Col>
              ))}
            </Grid>
          ) : (
            <div className="flex w-full flex-grow items-center justify-center py-5 text-center text-gray-500 py-4">
              No details found.
            </div>
          )}
        </>
      )}
    </Box>
  );
};

export const EngagementSubtitleItem = ({ subtitle }: { subtitle: string }) => {
  const getEngagementColor = (engagement: string) => {
    switch (engagement) {
      case "At Risk":
      case "Very Low":
        return "#FF922B";
      case "Low Active":
        return "#FFD43B";
      case "Low":
        return "#FFD43B";
      case "Moderately Active":
        return "#69DB7C";
      case "Highly Active":
        return "#40C057";
      default:
        return "#008000";
    }
  };

  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <Rating
        // mt="xs"
        count={4}
        c={colorScheme === "dark" ? "dark.4" : "gray.2"}
        emptySymbol={<FontAwesomeIcon size="xs" icon={solid("square")} />}
        fullSymbol={
          <Box
            mr={2}
            style={{
              // display: "flex",
              color: getEngagementColor(subtitle)
            }}>
            <FontAwesomeIcon size="xs" icon={solid("square")} />
          </Box>
        }
        value={
          subtitle === "Highly Active"
            ? 4
            : subtitle === "Moderately Active"
            ? 3
            : subtitle === "Low Active" || subtitle === "Low"
            ? 2
            : subtitle === "Very Low"
            ? 1
            : 1
        }
        readOnly
      />
      <Text fz="xs">{subtitle}</Text>
    </>
  );
};

export default EngagementSection;
