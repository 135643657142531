import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  CopyButton,
  Flex,
  Group,
  SimpleGrid,
  Text,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext } from "react";
import { SettingsHeaderContent } from "@/ui/components/App/Settings/SettingsHeaderContent/SettingsHeaderContent";
import classes from "./General.module.css";
export const GeneralAPIKeyAndServerToken = ({
  withBorder = true,
  withHeader = true,
  apiKeyOnly = false
}: {
  withBorder?: boolean;
  withHeader?: boolean;
  apiKeyOnly?: boolean;
}) => {
  const theme = useMantineColorScheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  return (
    <>
      {withHeader && (
        <>
          <Title order={4} fw={600} mb="md" mt="md">
            Server side tracking credentials
          </Title>
          <Text mb="md">
            API key and server token corresponding to your project. You should never share this
            secret token or send it over email.
          </Text>
        </>
      )}

      <Group className={classes.apiKeyAndServerToken} p={"sm"}>
        <Flex direction={"column"} mr={"sm"}>
          <Title order={5} fz={"sm"} fw={500} mb="sm">
            API Key
          </Title>
          <Flex>
            <Text fz="sm">{activeWorkspace?.identifier}</Text>
            <CopyButton value={activeWorkspace?.identifier}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                  <ActionIcon
                    size="xs"
                    ml={8}
                    color={copied ? "teal" : theme.colorScheme === "dark" ? "dark.2" : "brand.5"}
                    onClick={copy}
                    variant="subtle">
                    <FontAwesomeIcon icon={regular("clipboard")} />
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Flex>
        </Flex>
        {!apiKeyOnly && (
          <Flex direction={"column"}>
            <Title order={5} fw={500} mb="sm" fz="sm">
              Server Token
            </Title>
            <Flex>
              <Text fz="sm">{activeWorkspace?.server_token}</Text>
              <CopyButton value={`${activeWorkspace?.server_token}`}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                    <ActionIcon
                      size="xs"
                      ml={8}
                      color={copied ? "teal" : theme.colorScheme === "dark" ? "dark.2" : "brand.5"}
                      onClick={copy}
                      variant="subtle">
                      <FontAwesomeIcon icon={regular("clipboard")} />
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Flex>
          </Flex>
        )}
      </Group>
    </>
  );
};
