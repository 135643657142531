import { StepCard } from "@/ui/components/Common/StepCard/StepCard";
import "./setup.scss";
import { Box, Avatar, Text, Select, Flex } from "@mantine/core";
interface PowerUsersSelectRatioProps {
  powerUserRatio: any;
  setPowerUserRatio: (e: any) => void;
}

export const PowerUsersSelectRatio: React.FC<PowerUsersSelectRatioProps> = ({
  powerUserRatio,
  setPowerUserRatio
}) => {
  return (
    <>
      <StepCard step={1}>
        <Flex direction="column">
          <Text fw={500} mb="md">
            Power users percentage
          </Text>
          <Text mb="md">
            Select a percentage for your Power users. Power users are the users who are most engaged
            with your product.
          </Text>

          <Select
            w={150}
            value={powerUserRatio.toLocaleString()}
            onChange={(value) => {
              // @ts-ignore
              setPowerUserRatio(parseInt(value));
            }}
            data={[
              { value: "5", label: "5 Percent" },
              { value: "10", label: "10 Percent" },
              { value: "15", label: "15 Percent" },
              { value: "20", label: "20 Percent" },
              { value: "25", label: "25 Percent" },
              { value: "30", label: "30 Percent" }
            ]}
          />
        </Flex>
      </StepCard>
    </>
  );
};
