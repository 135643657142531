import axios from "axios";
import {
  authAccessTokenFromStorage,
  authAccessTokenLocalStorageUpdate,
  authLocalStorageUpdate,
  authRefreshTokenFromStorage,
  cleanAuthUserStorage,
} from "@/lib/utils/Storage";
import { ApplicationConfiguration } from "./ApplicationConfiguration";
export interface UserRegistration {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  company: string;
}

export interface UserService {
  /**
   * Logs in user. On success user must reload
   * @param email email
   * @param password password
   * @returns a promise
   */
  login(email: string, password: string, remember_me: false): Promise<void>;

  register(register_payload: UserRegistration): Promise<void>;

  forgotPassword(email: string): Promise<void>;
}

export class APIUserService implements UserService {
  private readonly _applicationConfiguration: ApplicationConfiguration;

  constructor() {
    this._applicationConfiguration = new ApplicationConfiguration();
  }

  login(
    email: string,
    password: string,
    remember_me: boolean = false
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._applicationConfiguration.backendAPIBase}/v1/auth/login`,
          {
            email: email,
            password: password,
            remember_me: remember_me,
          }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  register(registerPayload: UserRegistration): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._applicationConfiguration.backendAPIBase}/v1/users`,
          registerPayload
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  forgotPassword(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._applicationConfiguration.backendAPIBase}/v1/auth/forgot_password`,
          { email: email }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  validatePasswordToken(token: string | null): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._applicationConfiguration.backendAPIBase}/v1/auth/validate_reset_password_token`,
          { token: token }
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  resetPassword(
    password: string,
    passwordConfirm: string,
    token: string | null
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${this._applicationConfiguration.backendAPIBase}/v1/auth/reset_password`,
          {
            token: token,
            password: password,
            password_confirm: passwordConfirm,
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  getProfile(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const token = authAccessTokenFromStorage();
      axios
        .get(`${this._applicationConfiguration.backendAPIBase}/v1/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }
  refreshToken(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const token = authRefreshTokenFromStorage();
      axios
        .get(
          `${this._applicationConfiguration.backendAPIBase}/v1/auth/refresh_token`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            authAccessTokenLocalStorageUpdate(res.data.access_token);
          }
          resolve(res);
        })
        .catch((error) => {
          // clean JWT token storage.
          if (error?.response?.status === 401) {
            cleanAuthUserStorage();
          }

          reject(error);
        });
    });
  }
}
