import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Button, Input, Title, Text, Anchor } from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";

import ResetPasswordSVG from "@assets/images/icons/reset-password.svg";
import { APIUserService } from "@/lib/services/AuthService";
import { toast } from "react-toastify";
interface IFormInputs {
  email: string;
}
export const ForgotPassword = (props: any) => {
  const [loadingState, setLoadingState] = useState("idle");
  const [emailSent, setEmailSent] = useState(false);
  useEffect(() => {
    document.title = "Forgot Password | Usermaven";
  }, []);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IFormInputs>();

  const onSubmit = (data: any) => {
    const userService = new APIUserService();
    try {
      setLoadingState("loading");
      userService
        .forgotPassword(data.email)
        .then((res) => {
          setLoadingState("success");
          console.log(res);
          if (res.status === 200) {
            setEmailSent(true);
          }
        })
        .catch((err) => {
          setLoadingState("failure");
          // TODO: use interceptor to handle all such cases.

          // for handling errors if they have the detail property available with string type.
          if (
            err.response.data.hasOwnProperty("detail") &&
            typeof err.response.data.detail === "string"
          ) {
            toast.error(err.response.data.detail);
          }

          console.debug(`Error code is ${err.response.status}`, err.response);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {emailSent && (
        <div className="auth-container--info">
          <img src={ResetPasswordSVG} className={"pb-8"} alt="Reset Password" />
          <Title mb={"md"} order={4} ta="center">
            Thanks, check your email for instructions to reset your password
          </Title>
          <Text>
            Didn’t get the email? Check your spam folder or{" "}
            <Anchor onClick={() => setEmailSent(!emailSent)} rel="noreferrer">
              resend
            </Anchor>
            .
          </Text>
        </div>
      )}
      {!emailSent && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="auth-container--box">
            <Title order={3} ta="center" mb={16}>
              Forgot Password
            </Title>
            <Text fz={14} ta="center">
              Enter the email address associated with your account, and we’ll send you a link to
              reset your password.
            </Text>
            <div className="auth-container--box--form pt-4 pb-2">
              <div className="auth-input-group">
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <Input.Wrapper size="xs" id={"email"} label="Email" required>
                      <Input
                        mt={4}
                        value={value}
                        onChange={onChange}
                        placeholder="e.g carl@usermaven.com"
                        error={(invalid || error) === true}
                      />
                    </Input.Wrapper>
                  )}
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, pattern: /^\S+@\S+$/ }}
                />
                {errors.email?.type === "required" && (
                  <ValidationLabel
                    validationType="error"
                    text="Email is required"
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
                {errors.email?.type === "pattern" && (
                  <ValidationLabel
                    validationType="error"
                    text="Please enter a valid email address"
                    size="small"
                    customClasses="pt-2"></ValidationLabel>
                )}
              </div>
              <div className="auth-submit-form pt-4">
                <Button
                  loading={loadingState === "loading"}
                  disabled={loadingState === "loading"}
                  type="submit">
                  Continue
                </Button>
              </div>
            </div>
            <Text ta="center" fz={14} py={"lg"}>
              Don’t have an account? <Link to="/register">Sign Up</Link>
            </Text>
          </div>
        </form>
      )}
    </div>
  );
};
