import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { DATE_FORMAT, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { format } from "date-fns";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { downloadCSVFromResponse } from "@/lib/utils/CSVExporterUtility";
import { getRelativeDateToNow } from "@/lib/utils/DateUtility";
import { stringToInitials } from "@/lib/utils/StringUtility";
import { UserDetail } from "@/stores/useFunnelInsightsStore";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import { getSeedColor } from "@/style/ColorSeeding";
import { getRandomColor } from "@/style/Colors";
import { ExportButton } from "@/ui/components/Common/ExportButton/ExportButton";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Paper,
  Table,
  Text,
  useMantineTheme
} from "@mantine/core";
import { IReportType } from "../../../../../types/types.d";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export interface IFunnelUsersDetailedView {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  insightId: string;
  reportType: IReportType;
  filters?: any;
  selectedUserCard: {
    name: string;
    index: number;
    type: "completed" | "dropped";
  };
}

export const FunnelUsersDetailedView = ({
  isModalOpen,
  setIsModalOpen,
  insightId,
  reportType,
  filters,
  selectedUserCard
}: IFunnelUsersDetailedView) => {
  const theme = useGlobalMantineTheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const navigate = useNavigate();

  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);

  const { hasRolesNotWith } = useWorkspaceUtilityHook();
  const [exporting, setIsExporting] = useState("idle");
  const [data, setData] = useState<{
    list: UserDetail[];
    loading: boolean;
    page: number;
    hasMore: boolean;
  }>({
    list: [],
    loading: false,
    page: 0,
    hasMore: true
  });

  const exportUserOrCompanyList = async () => {
    setIsExporting("loading");
    try {
      const response = await new WebAnalyticsService().exportUsersList(
        activeWorkspace.id,
        insightId,
        selectedUserCard.index,
        selectedUserCard.type,
        {
          ...filters,
          funnel_type: reportType
        }
      );

      if (response.data) {
        //Setting filename of the exported file in accordance with the activeTab, date and time of export.
        let filename = `users_list_${selectedUserCard.type}_${format(new Date(), DATE_FORMAT)}.csv`;
        //Utility function to download the csv from response.
        downloadCSVFromResponse(response, filename);
        setIsExporting("loaded");
      }
    } catch (error) {
      setIsExporting("loaded");
      throw new Error("400");
    }
    return false;
  };

  const colors = useMemo(() => {
    return Array.from(Array(100).keys()).map((i) => getRandomColor());
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      fetchUsers(true);
    } else {
      setData({
        list: [],
        loading: false,
        page: 0,
        hasMore: true
      });
    }
  }, [isModalOpen]);

  const fetchUsers = async (clearFirst = false) => {
    try {
      setData((data) => ({
        ...data,
        loading: true
      }));

      const response = await new WebAnalyticsService().getUsersList(activeWorkspace.id, insightId, {
        ...filters,
        funnel_type: reportType,
        full_data: true,
        limit: 100,
        page: data.page + 1
      });
      let hasMore = true;
      const type = selectedUserCard.type === "completed" ? "completed" : "dropped_off";

      if (response.data.length === 0) {
        hasMore = false;
      }

      let newData = response.data[selectedUserCard.index][type];
      let list: any[] = [];

      if (clearFirst) {
        list = [...newData];
      } else {
        list = [...data.list, ...newData];
      }

      if (newData.length < 100) {
        hasMore = false;
      }

      setData((data) => ({
        ...data,
        list: list,
        page: data.page + 1,
        loading: false,
        hasMore
      }));
    } catch (error) {
      setData((data) => ({
        ...data,
        loading: false
      }));
    }
  };

  const loadMore = () => {
    fetchUsers();
  };

  return (
    <SidePanel
      opened={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      loading={true}
      hideOverflow={true}
      title={`Complete ${reportType === "user" ? "Users" : "Companies"} List`}>
      <Flex align={"center"} mb="sm">
        <Flex flex={1} className="flex-grow flex">
          <div className="flex gap-3.5">
            <Badge variant="dot">{selectedUserCard.name}</Badge>

            <Badge variant="dot">
              {selectedUserCard.type === "completed" ? "Completed" : "Dropped off"}{" "}
              {reportType === "user" ? "Users" : "Companies"}
            </Badge>
          </div>
        </Flex>
        <Flex mr={"sm"}>
          {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
            <ExportButton
              onClick={exportUserOrCompanyList}
              disabled={exporting === "loading"}
              loading={exporting === "loading"}
            />
          )}
        </Flex>
      </Flex>
      <Paper>
        <Box
          style={{
            overflowX: "auto",
            whiteSpace: "nowrap"
          }}>
          <Table striped highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                {reportType === "user" && <th>Email</th>}
                <Table.Th>First Used</Table.Th>
                <Table.Th>Last Used</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.list.map((item, index) => (
                <Table.Tr className={"group transition-all ease-in-out cursor-pointer"} key={index}>
                  <Table.Td
                    onClick={() => {
                      // navigate(
                      //   `/env/${activeWorkspace.identifier}/${
                      //     reportType === "user" ? "users" : "companies"
                      //   }/everyone/${item.id}`
                      // );
                      setIsModalOpen(false);
                      setOverviewModalOpen(true, reportType, item.id);
                    }}>
                    <Flex align={"center"}>
                      <Avatar
                        size={20}
                        variant={"filled"}
                        radius={"xl"}
                        mr={8}
                        component="div"
                        styles={{
                          placeholder: {
                            backgroundColor: getSeedColor(item.name)
                          }
                        }}
                        className={`uppercase `}>
                        {stringToInitials(item.name)}
                      </Avatar>
                      <Text className="group-hover:underline capitalize">{item.name}</Text>
                    </Flex>
                  </Table.Td>
                  {reportType === "user" && <td>{item.email ? item.email : ""}</td>}
                  <Table.Td>
                    <Text>
                      <div>{getRelativeDateToNow(item.first_seen)}</div>
                      <Text c="dimmed" fz="xs">
                        {format(new Date(item.first_seen), "dd MMM yyyy hh:mm")}
                      </Text>
                    </Text>
                  </Table.Td>
                  <td>
                    <Text>
                      <div>{getRelativeDateToNow(item.last_seen)}</div>
                      <Text c="dimmed" fz="xs">
                        {format(new Date(item.last_seen), "dd MMM yyyy hh:mm")}
                      </Text>
                    </Text>
                  </td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
          {data.hasMore ? (
            <Center my={"sm"}>
              <Button
                variant="light"
                size="xs"
                loading={data.loading}
                disabled={data.loading}
                onClick={() => loadMore()}>
                Load More
              </Button>
            </Center>
          ) : null}
        </Box>
      </Paper>
    </SidePanel>
  );
};
