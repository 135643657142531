import "./setup.scss";
import {
  Box,
  Select,
  Avatar,
  HoverCard,
  Paper,
  Text,
  NumberInput,
  Group,
  Flex
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { StepCard } from "@/ui/components/Common/StepCard/StepCard";

interface OnboardingCompletionWindowSetupProps {
  windowSize: number;
  setWindowSize: (e: any) => void;
  windowSizeType: "days" | "hours" | "minutes";
  setWindowSizeType: (e: any) => void;
}

export const OnboardingCompletionWindowSetup: React.FC<OnboardingCompletionWindowSetupProps> = ({
  windowSize = 0,
  setWindowSize,
  windowSizeType,
  setWindowSizeType
}) => {
  return (
    <>
      <StepCard step={4}>
        <Flex direction="column">
          <Text fw={600}>
            <Flex align={"center"}>
              Funnel Window (Optional)
              <HoverCard width={350} shadow="md" withArrow position={"right-start"} withinPortal>
                <HoverCard.Target>
                  <Box ml="xs">
                    <FontAwesomeIcon
                      className="text-gray-700 pt-1 ml-2.5"
                      icon={regular("info-circle")}></FontAwesomeIcon>
                  </Box>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Box>
                    <Text>
                      Choose the window size for your funnel. The window size is the number of
                      days/hours/minutes that a user has to complete the funnel steps.
                      <br />
                      <br />
                      <Text span fw={500}>
                        Default: 0
                      </Text>{" "}
                      (no window size)
                      <br />
                      <br />
                      <Text span fw={500}>
                        Example:
                      </Text>{" "}
                      If you set the window size to 7 days, then the user has 7 days to complete the
                      funnel steps. If the user does not complete the funnel steps within 7 days,
                      then the user will not be counted in the funnel.
                    </Text>
                  </Box>
                </HoverCard.Dropdown>
              </HoverCard>
            </Flex>
          </Text>

          <Text size="sm" c="dimmed" my="sm">
            Choose onboarding step order for your onboarding completion rate
          </Text>
          <Group gap={"sm"}>
            <NumberInput
              placeholder={"Enter the window size in days"}
              size="xs"
              min={0}
              max={365}
              defaultValue={windowSize}
              onChange={setWindowSize}
              value={windowSize}
              w={90}
            />

            <Select
              w={90}
              name="windowSizeType"
              data={[
                { label: "Days", value: "days" },
                { label: "Hours", value: "hours" },
                { label: "Minutes", value: "minutes" }
              ]}
              dropdownPosition="top"
              defaultValue={"days"}
              // @ts-ignore
              onChange={(val: "days" | "hours" | "minutes") => {
                if (val) setWindowSizeType(val);
              }}
              value={windowSizeType}
              size="xs"></Select>
          </Group>
        </Flex>
      </StepCard>
    </>
  );
  return (
    <>
      <Box
        sx={(theme) => {
          return {
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
            borderColor:
              theme.colorScheme === "dark"
                ? theme.colors.darkBorderColor[0]
                : theme.colors.lightBorderColor[0]
          };
        }}
        className="um-insights-setup__box drop-shadow-sm">
        <div className="um-insights-setup__name">
          <div className="um-insights-setup__name-header">
            <Avatar
              className="mr-6"
              variant="outline"
              radius="xl"
              color="brand.4"
              styles={{
                placeholder: {
                  borderWidth: "2px"
                }
              }}>
              <>4</>
            </Avatar>
            <div className="um-insights-setup__name-header-content">
              <h2></h2>
              <p>Choose the window size for your funnel.</p>
            </div>
          </div>
        </div>

        <div className={"um-insights-setup__body"}>
          <div>
            <Group spacing={"sm"}>
              <NumberInput
                placeholder={"Enter the window size in days"}
                size="xs"
                min={0}
                max={365}
                defaultValue={windowSize}
                onChange={setWindowSize}
                value={windowSize}
                w={90}
              />

              <Select
                w={90}
                name="windowSizeType"
                data={[
                  { label: "Days", value: "days" },
                  { label: "Hours", value: "hours" },
                  { label: "Minutes", value: "minutes" }
                ]}
                dropdownPosition="top"
                defaultValue={"days"}
                onChange={(val: "days" | "hours" | "minutes") => {
                  if (val) setWindowSizeType(val);
                }}
                value={windowSizeType}
                size="xs"></Select>
            </Group>
          </div>
        </div>
      </Box>
    </>
  );
};
