/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { useContext, useEffect, useRef, useState } from "react";
import { LoadingStateProps } from "types/types.d";
import { ConversionGoals } from "@/ui/components/App/WebAnalytics/ConverisonGoals/ConversionGoals";

import { WebAnalyticsOverview } from "@/ui/components/App/WebAnalytics/WebAnalyticsOverview";

import { SearchedKeywords } from "@/ui/components/App/WebAnalytics/SearchedKeywords";
import { TopDevices } from "@/ui/components/App/WebAnalytics/TopDevices";
import { TopLocations } from "@/ui/components/App/WebAnalytics/TopLocations";
import { TopPages } from "@/ui/components/App/WebAnalytics/TopPages";
import { TopSources } from "@/ui/components/App/WebAnalytics/TopSources";

import { FiltersNav } from "@/ui/components/App/WebAnalytics/Filters/FiltersNav";
import { UTMTraffic } from "@/ui/components/App/WebAnalytics/UTMTraffic";
import { WebAnalyticsHeader } from "@/ui/components/App/WebAnalytics/WebAnalyticsHeader";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault
} from "use-query-params";

import { getReportingPeriodRange } from "../../../lib/utils/ReportingPeriodUtility";
import { useWebFiltersStore, useWebFiltersTimerStore } from "../../../stores/useWebFiltersStore";
import { format, subDays } from "date-fns";
import { useIntersection, useInterval } from "@mantine/hooks";
import { ConversionGoalsOverview } from "@/ui/components/App/WebAnalytics/ConversionGoalsOverview";
import { getActiveDomainValue } from "@/ui/components/App/WebAnalytics/WebAnalyticsUtilities";
import { DEFAULT_SELECTED_DOMAIN } from "@/lib/utils/Constants";
import { Grid } from "@mantine/core";

const TIMER_THIRTY_SECONDS = 30000;

const WebAnalyticsDashboard = ({ isPublic = false }: { isPublic?: boolean }) => {
  const [query, setQuery] = useQueryParams({
    domain: StringParam,
    period: StringParam,
    from_date: StringParam,
    to_date: StringParam,
    tick: NumberParam,
    comparison: StringParam,
    match_day_of_week: BooleanParam,
    comparison_from_date: StringParam,
    comparison_to_date: StringParam,
    custom_range: BooleanParam,
    refresh: BooleanParam,
    filters: withDefault(ArrayParam, [])
  });

  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [domains, setDomains] = useState([DEFAULT_SELECTED_DOMAIN]);
  const [setDateLabel] = useWebFiltersStore((state) => [state.setDateLabel]);
  const [setTimer] = useWebFiltersTimerStore((state) => [state.setTimer]);
  const interval = useInterval(() => {
    setTimer(new Date().getTime());
  }, TIMER_THIRTY_SECONDS);

  const fetchWorkspaceDomains = async () => {
    setLoading("loading");
    const waService = new WebAnalyticsService();
    await waService
      .domains(activeWorkspace.id)
      .then((res) => {
        setDomains(res.data);

        // If the query has domain assigned already, use that domain, else pick from the active workspace preference.
        const defaultDomain = query.domain
          ? query.domain
          : getActiveDomainValue(res.data, activeWorkspace.reporting_domain);

        // get reporting period for the workspace.
        const getPeriod = getReportingPeriodRange(activeWorkspace.reporting_period);
        const payload = {
          ...query,
          domain: defaultDomain,
          from_date: query?.from_date || getPeriod?.startDate,
          to_date: query?.to_date || getPeriod?.endDate,
          period: query?.period || getPeriod?.interval || "day",
          comparison: query?.comparison || undefined,
          comparison_from_date: query?.comparison_from_date || undefined,
          comparison_to_date: query?.comparison_to_date || undefined,
          custom_range: query?.custom_range || undefined
        };

        // if the query parameters has from_date and to_date then use date formatter for the label.

        let dateLabel;
        if (query && query.from_date && query.to_date && query.period !== "live") {
          dateLabel = `${format(new Date(query.from_date.replace(" ", "T")), "MMM dd")} - ${format(
            new Date(query.to_date.replace(" ", "T")),
            "MMM dd"
          )}`;
        } else if (query && query.period === "live") {
          dateLabel = "Live";
        } else {
          // get label from the reporting period or set the default Last 30 days.
          dateLabel = getPeriod?.label || "Last 30 Days";
        }

        setDateLabel(dateLabel);
        setQuery(payload);
      })
      .catch((err) => err);
    setLoading("loaded");
  };

  const refreshPage = () => {
    setQuery({
      ...query,
      refresh: !query.refresh
    });
  };

  useEffect(() => {
    document.title = "Web Analytics | Usermaven";
    const getPeriod = getReportingPeriodRange(activeWorkspace.reporting_period);
    setDateLabel(getPeriod?.label || "Last 30 Days");
    setQuery({
      from_date: getPeriod?.startDate,
      to_date: getPeriod?.endDate
    });

    fetchWorkspaceDomains();
    return interval.stop();
  }, [activeWorkspace.id]);

  useEffect(() => {
    if (query?.period === "live") {
      interval.start();
    } else {
      interval.stop();
    }
    return () => {
      interval.stop();
    };
  }, [query.period]);

  const isGoalSet = query.filters.some((item: any) => item.startsWith("goal:"));
  const containerRef = useRef();
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 1
  });
  if (loading !== "loaded" || query.from_date === undefined || query.to_date === undefined) {
    return <LoadingComponent />;
  }
  return (
    <>
      <div className="p-4 sm:p-8 sm:pt-0 relative">
        <WebAnalyticsHeader isPublic={isPublic} />
        <FiltersNav
          isPublic={isPublic}
          query={query}
          setQuery={setQuery}
          domains={domains}
          refreshPage={refreshPage}
        />
        {isGoalSet ? (
          <ConversionGoalsOverview query={query} setQuery={setQuery}></ConversionGoalsOverview>
        ) : (
          <WebAnalyticsOverview
            query={query}
            setQuery={(query: any) => setQuery(query)}
            isPublic={isPublic}
          />
        )}
        <Grid my="md" gutter="lg">
          <Grid.Col
            span={{
              xs: 12, // Full width on extra small screens
              sm: 12, // Half width on small screens
              md: 6, // One third on medium screens
              xl: 6
            }}>
            <TopSources query={query} setQuery={setQuery} isPublic={isPublic} />
          </Grid.Col>
          <Grid.Col
            span={{
              xs: 12,
              sm: 12,
              md: 6,
              xl: 6
            }}>
            <UTMTraffic query={query} setQuery={setQuery} isPublic={isPublic} />
          </Grid.Col>
          <Grid.Col
            span={{
              xs: 12,
              sm: 12,
              xl: 12,
              md: 12
            }}>
            <TopPages query={query} setQuery={setQuery} isPublic={isPublic} />
          </Grid.Col>
          <Grid.Col
            span={{
              xs: 12,
              sm: 12,
              xl: 12,
              md: 12
            }}>
            <ConversionGoals query={query} setQuery={setQuery} isPublic={isPublic} />
          </Grid.Col>
          <Grid.Col
            span={{
              xs: 12,
              sm: 12,
              md: 6,
              xl: 6
            }}>
            <TopDevices query={query} setQuery={setQuery} />
          </Grid.Col>
          <Grid.Col
            span={{
              xs: 12,
              sm: 12,
              md: 6,
              xl: 6
            }}>
            <TopLocations query={query} setQuery={setQuery} isPublic={isPublic} />
          </Grid.Col>
          <Grid.Col
            span={{
              xs: 12,
              md: 12
            }}>
            <SearchedKeywords query={query} isPublic={isPublic} />
          </Grid.Col>
        </Grid>
      </div>
    </>
  );
};
export default WebAnalyticsDashboard;
