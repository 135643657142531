import { red } from "@/style/Colors";
import styled from "styled-components";

interface LabelProps {
  bold?: any;
  size: string;
}

// if else if single line javascript

export const LabelContainer = styled.label<LabelProps>`
  font-weight: ${(props) => (props.bold ? 500 : 400)};
  font-size: ${(props) =>
    props.size === "small"
      ? "0.87rem"
      : props.size === "medium"
      ? "1rem"
      : "1.25rem"};
`;

export const LabelRequired = styled.span`
  color: ${red};
  padding-left: 0.25rem;
`;
