import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Menu } from "@mantine/core"
import { useState } from "react"
import { useDetectClickOutside } from "react-detect-click-outside"
import { useWebFiltersModalStore } from "@/stores/useWebFiltersModalStore"
import { FilterItem } from "./FilterItem"
import { FILTER_MENU_ITEMS } from "./FiltersUtility"

export const FilterMobileDropdown = ({
  query,
  setQuery,
  scrollPosition,
}: {
  query: any
  setQuery: any
  scrollPosition: number
}) => {
  const [isMobileDropdownVisible, setIsMobileDropdownVisible] = useState(false)
  const closeMobileDropdown = () => setIsMobileDropdownVisible(false)
  const refMobileDropdown = useDetectClickOutside({ onTriggered: closeMobileDropdown })
  const [open, setOpen] = useState<boolean>(false)
  const [filterKey, setFilterKey, modalOpened, setModalOpened] = useWebFiltersModalStore(state => [
    state.filterKey,
    state.setFilterKey,
    state.filterModalOpened,
    state.setFilterModalOpened,
  ])
  return (
    <>
      {query.filters.length > 0 && (
        <>
          <div className="relative flex-row-reverse w-full hidden" ref={refMobileDropdown}>
            <Button
              className={`mt-1.5  ${
                scrollPosition > 0.03
                  ? "border border-solid border-gray-200 font-medium text-gray-900 mr-1.5"
                  : ""
              }`}
              color={scrollPosition > 0.03 ? "gray.9" : "gray.6"}
              variant={scrollPosition > 0.03 ? "light" : "subtle"}
              size="sm"
              compact
              onClick={() => setIsMobileDropdownVisible(!isMobileDropdownVisible)}
              leftIcon={<FontAwesomeIcon icon={regular("filter")} />}
            >
              <span className="h-2 w-2 rounded-full absolute bg-red-500 absolute -top-0.5 -right-0.5"></span>
            </Button>

            <Menu opened={open} onChange={setOpen}>
              <Menu.Target>
                <Button
                  className={`mt-1.5  ${
                    scrollPosition > 0.03
                      ? "border border-solid border-gray-200 font-medium text-gray-900 mr-1.5"
                      : ""
                  }`}
                  color={scrollPosition > 0.03 ? "gray.9" : "gray.6"}
                  variant={scrollPosition > 0.03 ? "light" : "subtle"}
                  size="sm"
                  compact
                  leftIcon={<FontAwesomeIcon icon={solid("magnifying-glass")} />}
                ></Button>
              </Menu.Target>
              <Menu.Dropdown className="w-64">
                {FILTER_MENU_ITEMS.map(item => (
                  <Menu.Item
                    classNames={"w-40"}
                    onClick={() => {
                      setModalOpened(true)
                      setFilterKey(item.value)
                    }}
                    key={item.value}
                  >
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
            {isMobileDropdownVisible && (
              <>
                <div className="absolute top-12 w-[24rem] right-0 bg-white h-auto z-50 drop-shadow-sm border border-solid border-gray-100">
                  {query.filters.map((item: string, index: number) => (
                    <FilterItem query={query} setQuery={setQuery} item={item} index={index} key={`query-filter-${index}`}/>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}
