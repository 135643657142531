import { createTheme, MantineProvider, Paper, rem } from "@mantine/core";

/**
 * Mantine theme settings
 * Here you can change default theme settings
 * @see https://mantine.dev/theming/theme-object/
 */
export const getTheme = createTheme({
  // Override any other properties from default theme

  fontFamily: "'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  defaultGradient: {
    from: "#7D47EB",
    to: "#E443A0"
    // deg: 45
  },

  colors: {
    brand: [
      "#f4ebff", // Lightest
      "#e2d3ff",
      "#c6aaf2",
      "#a97cee",
      "#8e51e8",
      "#7b39e6",
      "#6e2ae6",
      "#5a1fd1",
      "#4c1bbd",
      "#3d0aa3" // Darkest
    ],
    lightBorderColor: [
      "#f3f5f6",
      "#e7e7e7",
      "#cdcece",
      "#afb2b5",
      "#959ba1",
      "#848d94",
      "#7a878f",
      "#68747c",
      "#5a6770",
      "#4a5964"
    ],
    darkBorderColor: [
      "#f4f4f6",
      "#e6e6e6",
      "#cbcbcb",
      "#aeaeaf",
      "#959598",
      "#86868a",
      "#7d7e85",
      "#6b6c73",
      "#5e6067",
      "#50525d"
    ],

    // Improved dark colors for better readability and contrast
    // dark: [
    //   "#e0e0e0", // Dark 0: Light gray for text and high-contrast elements
    //   "#b0b3b8", // Dark 1: Slightly darker for secondary text
    //   "#8a8d91", // Dark 2: Slightly darker for tertiary text
    //   "#6c7075", // Dark 3: Slightly darker for quaternary text
    //   "#53575c", // Dark 4: Updated for better readability
    //   "#20232a", // Dark 5: Slightly darker primary background color
    //   "#181b20", // Dark 6: Slightly darker than secondary background color
    //   "#15181f", // Dark 7: Tertiary background color
    //   "#0e1015", // Dark 8: Dark elements like headers or footers
    //   "#05070a" // Dark 9: Very dark elements like sidebars or toolbars
    // ]
    dark: [
      "#e0e0e0", // Dark 0: Medium gray for text and high-contrast elements
      "#d1d3d8", // Dark 1: Slightly darker for secondary text
      "#a5a9b1", // Dark 2: Slightly darker for tertiary text
      "#878b92", // Dark 3: Slightly darker for quaternary text
      "#686c73", // Dark 4: Updated for better readability
      "#24282f", // Dark 5: Slightly darker primary background color
      "#1d2026", // Dark 6: Slightly darker than secondary background color
      "#16191e", // Dark 7: Tertiary background color
      "#101317", // Dark 8: Dark elements like headers or footers
      "#090b0e" // Dark 9: Very dark elements like sidebars or toolbars
    ]
  },
  primaryColor: "brand",
  primaryShade: 5,
  components: {
    Paper: {
      styles: (theme: any) => ({
        root: {
          backgroundColor: "light-dark(#fff, var(--mantine-color-dark-8))", //var(--mantine-color-dark-4)
          borderColor: "light-dark(var(--mantine-color-gray-1),var(--mantine-color-dark-7))" //  #1d1f22  var(--mantine-color-dark-4)
        }
      })
    },
    Tooltip: {
      styles: (theme: any) => ({
        tooltip: {
          color: "light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-0))", //var(--mantine-color-dark-4)
          backgroundColor: "light-dark(var(--mantine-color-dark-6), var(--mantine-color-dark-8))" //var(--mantine-color-dark-4)
        }
      })
    },
    Divider: {
      styles: (theme: any) => ({
        root: {
          borderColor: "light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-7) )" //var(--mantine-color-dark-4)
        }
      })
    },
    Text: {
      defaultProps: {
        size: "sm"
      }
    },
    Select: {
      defaultProps: {
        checkIconPosition: "right",
        color: "light-dark(rgb(65, 97, 218), var(--mantine-color-dark-3))"
      }
    },
    // Menu: {
    //   defaultProps: {
    //     color: "light-dark(rgb(65, 97, 218), var(--mantine-color-dark-3))"
    //   }
    // },
    // Popover: {
    //   defaultProps: {
    //     color: "light-dark(rgb(65, 97, 218), var(--mantine-color-dark-6))"
    //   }
    // },
    Anchor: {
      defaultProps: {
        color: "light-dark(rgb(65, 97, 218), rgb(114, 129, 255))"
      }
    },
    Table: {
      defaultProps: {
        borderColor: "light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5))"
        // styles: {
        //   root: {
        //     borderColor: "light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-7))"
        //   }
        // }
      }
    }
  },
  cursorType: "pointer"
  // fontSizes: {
  //   xs: rem(10),
  //   sm: rem(11),
  //   md: rem(14),
  //   lg: rem(16),
  //   xl: rem(20)
  // }

  // components: {
  //   Button: {},

  //   Select: {
  //     styles: {}
  //   },
  //   Modal: {
  //     styles: {
  //       close: {
  //         color: colorScheme === "dark" ? "#fff" : "#000"
  //       }
  //     }
  //   },
  //   SegmentedControl: {
  //     styles: {
  //       root: {
  //         backgroundColor: colorScheme === "dark" ? "#191c24" : "#f1f3f5"
  //       },
  //       active: {
  //         backgroundColor: colorScheme === "dark" ? "#000000" : "#fff"
  //       }
  //     }
  //   }
  // },
});
