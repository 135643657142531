// @ts-ignore
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { getIntlDateAndTime } from "@/lib/utils/DateUtility";
import { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";

export const PausedSubscriptionNotification = ({ className }: any) => {
  const { activeWorkspace, user } = useContext(AppLifecycleContext);
  const isOwnerOfOrganization = () => {
    return activeWorkspace.members.some(
      (member: any) =>
        member.user_id === user.id && user.id === activeWorkspace.organization.created_by_user_id
    );
  };
  return (
    <>
      {!activeWorkspace?.organization.plan.is_lifetime &&
        activeWorkspace?.organization?.subscriptions?.length > 0 &&
        activeWorkspace?.organization?.subscriptions[0]?.paused_from && (
          <>
            <div
              className={`flex m-4 px-4 py-4 shadow-sm bg-orange-100 bg-opacity-50 rounded border border-solid border-orange-200  transition-all ease-in-out ${className}`}>
              <div className="h-4 w-4 mr-4">
                <FontAwesomeIcon
                  size="lg"
                  className="text-orange-800 "
                  icon={regular("triangle-exclamation")}
                />
              </div>
              <div>
                Your subscription will be paused from{" "}
                <span className="font-semibold">
                  {getIntlDateAndTime(activeWorkspace.organization.subscriptions[0].paused_from)}.
                </span>{" "}
                {isOwnerOfOrganization() ? (
                  <>
                    You can restart your subscription by{" "}
                    <NavLink
                      to={`/env/${activeWorkspace.identifier}/account/billing`}
                      className="pr-1.5">
                      Clicking here.
                    </NavLink>
                  </>
                ) : (
                  <>
                    Please contact your organization owner{" "}
                    <span className="font-semibold">
                      {activeWorkspace.organization.creator.full_name} (
                      {activeWorkspace.organization.creator.email})
                    </span>{" "}
                    to restart your subscription.
                  </>
                )}
              </div>
            </div>
          </>
        )}
    </>
  );
};
