import { InsightTemplateCreateProps, InsightTemplateUpdateProps } from "types/types.d";
import axios from "./JWTService";

export class InsightsService {
  create = (ws_id: any, payload: InsightTemplateCreateProps): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/insights/${ws_id}`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  update = (ws_id: any, template_id: any, payload: InsightTemplateUpdateProps): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/insights/${ws_id}/${template_id}`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getAll = (
    ws_id: any,
    sort: "created_at:desc" | "name:desc" | "name:asc" | string | null,
    page: number = 1,
    search: string = "",
    type: string = "all",
    limit: number = 20
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `/v1/insights/${ws_id}?page=${page}&sort=${sort}&search=${search}&type=${type}&limit=${limit}`
        )
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  get = (ws_id: any, insight_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/insights/${ws_id}/${insight_id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  count = (ws_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/insights/${ws_id}/count`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (ws_id: any, insight_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/v1/insights/${ws_id}/${insight_id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  setAsDefault = (ws_id: any, insight_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/insights/${ws_id}/${insight_id}/default`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  audienceUserCohort = (
    wsId: any,
    payload: {
      audience_conditions: Array<any>;
      audience_condition_type: string;
      audience_group_conditions: Array<any>;
    }
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/insights/${wsId}/audience_cohorts/people`, payload)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  suggestions = (wsId: any, type: string = "page_url", query: string = ""): Promise<any> => {
    const querystring = query ? `?query=${query}` : "";
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/insights/${wsId}/autocomplete/suggestions/${type}${querystring}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
}
