/* eslint-disable react-hooks/exhaustive-deps */
import { useIntersection, useLocalStorage } from "@mantine/hooks";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { BulletListSkeleton } from "@/lib/utils/ChartsSkeletons";
import { getCountryName } from "@/lib/utils/Locale";
import { LS_TOP_LOCATIONS_PREFERENCE } from "@/lib/utils/Storage";
import React, { useContext, useEffect, useRef, useState } from "react";
// import Flag from "react-world-flags"
import { LoadingStateProps, LocationObjectProps, TopLocationsProps } from "types/types.d";
import { NoResults } from "../NoResults/NoResults";
import { TopLocationsMap } from "./TopLocationsMap";
import { ViewDetailedButton } from "./ViewDetailedButton";
import { TopLocationsDetailedView } from "./DetailedView/TopLocationsDetailedView";
import {
  Box,
  Divider,
  Flex,
  Group,
  Paper,
  Text,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import LazyFlag from "../../Common/LazyFlag/LazyFlag";
import useDeepCompareEffect from "@/hooks/useDeepCompareEffect";
import { DynoTable, ProgressCell } from "../../Common/DynoTable/DynoTable";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { PALE_SHADES } from "@/lib/utils/Constants";
import classes from "./WebAnalyticsOverview.module.css";
import clsx from "clsx";
const waService = new WebAnalyticsService();

interface LocationItemProps {
  location_source: string;
  loctaion_source_with_value?: {
    source: string;
    value: number;
  };
  loc_country?: string;
  visitors: number;
  visitors_comparison?: number;
  visitors_percentage_change?: number;
  visitors_percentage: number;
  visitors_percentage_comparison?: number;
  visitors_percentage_percentage_change?: number;
  bounce_rate: number;
  bounce_rate_comparison?: number;
  bounce_rate_percentage_change?: number;
}

const TopLocationsNavList: Array<LocationObjectProps> = [
  { key: "map", value: "Map" },
  { key: "country", value: "Countries" },
  { key: "region", value: "Regions" },
  { key: "city", value: "Cities" }
];

const TopLocations = ({ query, setQuery, isPublic }: any) => {
  const [hasBecomeVisibleYet, setHasBecomeVisibleYet] = useState(false);

  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [opened, setOpened] = useState(false);
  const [activeTab, setActiveTab] = useLocalStorage<TopLocationsProps>({
    key: LS_TOP_LOCATIONS_PREFERENCE,
    defaultValue: "country",
    getInitialValueInEffect: false
  });

  // setting list for results
  const [list, setList] = useState<Array<LocationItemProps>>([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  // const isMobile = useMediaQuery("(max-width: 920px)");

  // fetch service calls
  const fetchTopLocations = async () => {
    setLoading("loading");
    await waService
      .topLocations(activeWorkspace.id, activeTab, "list", 1, 10, "visitors:desc", query)
      .then((res) => {
        if (res.data) {
          setList(res.data.data);
          if (res.data.columns === undefined) {
            setTableColumns([]);
          } else {
            const highestValue = res.data.highest_value;
            let columns = res.data.columns;
            // exclude the columns that starts with "bounce_rate"
            if (columns) {
              columns = columns.filter(
                (column: { accessor: string }) => !column.accessor.startsWith("bounce_rate")
              );
            }

            // Modify columns before setting the state
            const modifiedColumns = columns.map((column: { accessor: string }) => {
              if (column.accessor === "source_with_value") {
                return {
                  ...column,
                  render: (data: any) => (
                    <ProgressCellWithClick
                      barColor={PALE_SHADES.green}
                      value={highestValue === 0 ? 0 : Math.floor((data.value / highestValue) * 100)}
                      onClick={handleOnClick}
                      cellData={data}
                    />
                  )
                };
              }
              if (column.accessor === "visitors_with_percentage") {
                return {
                  ...column,
                  render: (data: any) => (
                    <Flex
                      align={"center"}
                      style={{
                        verticalAlign: "middle"
                      }}>
                      <Text fw={400}>{numberToCommas(data.visitors)}</Text>
                      <Text fw={500} color="gray.6" fz={10} pl={"5px"} pt={"3px"}>
                        ({data.percentage}%)
                      </Text>
                    </Flex>
                  )
                };
              }
              if (["visitors"].includes(column.accessor)) {
                return {
                  ...column,
                  render: (data: any) => <Text fw={400}>{numberToCommas(data)}</Text>
                };
              }
              if (column.accessor === "visitors_percentage") {
                return {
                  ...column,
                  render: (data: any) => (
                    <Text fw={400}>
                      {data !== undefined && data !== null ? `${data.toFixed(1)}%` : ""}
                    </Text>
                  )
                };
              }
              if (column.accessor.endsWith("percentage_change")) {
                return {
                  ...column,
                  render: ({ label, percentage_change, comparison_value }: any) => (
                    <Tooltip.Floating
                      label={`Comparison period ${label}: ${comparison_value}${
                        label === "CR" ? "%" : ""
                      }`}
                      position="top"
                      style={{ fontSize: "12px" }}>
                      <Text
                        fw={400}
                        color={
                          percentage_change !== undefined && percentage_change !== null
                            ? percentage_change > 0
                              ? "green.6"
                              : percentage_change < 0
                              ? "red.6"
                              : "gray.6"
                            : "gray.6"
                        }>
                        {percentage_change !== undefined &&
                        percentage_change !== null &&
                        percentage_change !== 0
                          ? `${percentage_change > 0 ? "+" : ""}${percentage_change.toFixed(1)}%`
                          : ""}
                      </Text>
                    </Tooltip.Floating>
                  )
                };
              }
              return column;
            });
            setTableColumns(modifiedColumns);
          }
        }
      })
      .catch((err) => {
        setList([]);
      });
    setLoading("loaded");
  };

  const ProgressCellWithClick = ({ barColor, value, onClick, cellData }: any) => {
    return (
      <Box onClick={() => onClick(cellData.source)}>
        <ProgressCell
          barColor={barColor}
          value={value}
          label={<LabelWithActions data={cellData} activeTab={activeTab} />}
        />
      </Box>
    );
  };

  const renderCountryImage = (value: string) => {
    if (value === "Unknown") {
      return "temp-mail.org";
    }
    return (
      <>
        <div className="h-2 mr-2 block">
          <LazyFlag code={value} height={16} width={16}></LazyFlag>
        </div>
      </>
    );
  };

  // on clicking any of the locations, it will be added to the query
  const handleOnClick = (location_source: string) => {
    setQuery({
      ...query,
      filters: [
        ...query.filters.filter((value: string) => !value.startsWith(`${activeTab}:`)),
        `${activeTab}:${location_source}`
      ]
    });
  };

  const containerRef = useRef();
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 0
  });

  const LabelWithActions = ({ data, activeTab }: any) => {
    const { colorScheme } = useMantineColorScheme();
    const img =
      activeTab === "country"
        ? renderCountryImage(data.source)
        : renderCountryImage(data.source_country);
    return (
      <Flex justify={"space-between"} align={"center"}>
        <Box h={15}>
          {img && typeof img === "string" && img.length > 0 && (
            <img
              src={`https://favicon.usermaven.com/?url=${
                img.length > 0 ? img : "temp-mail.org"
              }&size=32${colorScheme === "dark" ? "&fallback=default-white.svg" : ""}`}
              alt=""
            />
          )}
          {img && typeof img !== "string" && <>{img}</>}
        </Box>
        <Text>{activeTab === "country" ? getCountryName(data.source) : data.source}</Text>
      </Flex>
    );
  };

  useEffect(() => {
    if (entry?.isIntersecting && !hasBecomeVisibleYet) {
      setHasBecomeVisibleYet(true);
      fetchTopLocations();
    }
  }, [entry?.isIntersecting]);

  useDeepCompareEffect(() => {
    if (hasBecomeVisibleYet && entry?.isIntersecting) {
      fetchTopLocations();
    } else {
      setHasBecomeVisibleYet(false);
    }
  }, [activeTab, query, query.filters, activeWorkspace.id]);
  return (
    <Paper shadow="xs" withBorder ref={ref}>
      {/* Modal for detailed view */}
      <TopLocationsDetailedView
        query={query}
        opened={opened}
        activeTab={activeTab}
        setOpened={setOpened}
        handleOnClick={handleOnClick}
        renderCountryImage={renderCountryImage}
        isPublic={isPublic}
      />
      <Flex
        direction={"column"}
        component="div"
        h={520}
        className="flex flex-col h-[36rem] border border-solid  rounded shadow-sm">
        <Flex component="div" wrap={"wrap"} p="md" align={"center"}>
          <Title flex={1} order={6} fw={600}>
            Locations
          </Title>
          <div>
            <Group gap={8}>
              {TopLocationsNavList.map((item: LocationObjectProps, index) => (
                <div data-cy="top-locations-filter-nav-list" key={index}>
                  <Text
                    fw={500}
                    fz={13}
                    key={`top-devices:${index}`}
                    className={clsx(classes.tab, activeTab === item.key && classes.activeTab)}
                    onClick={() => setActiveTab(item.key)}>
                    {item.value}
                  </Text>
                </div>
              ))}
            </Group>
          </div>
        </Flex>
        <Divider />
        <div className="" id="WebAnalytics:TopLocations">
          {loading === "loaded" ? (
            <>
              {list.length > 0 ? (
                <>
                  {activeTab === "map" ? (
                    <div data-cy="top-locations-map">
                      <TopLocationsMap list={list} />
                    </div>
                  ) : (
                    <div data-cy="top-locations-results-list">
                      <DynoTable columns={tableColumns} data={list} />
                    </div>
                  )}

                  {/*Detailed button for opening modal for showing detailed view*/}
                  {activeTab !== "map" && (
                    <div data-cy="top-locations-detailed-view-btn">
                      <ViewDetailedButton
                        onClick={() => {
                          setOpened(true);
                        }}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Flex align={"center"} justify={"center"} h={400}>
                    <NoResults text={"There is no data for locations."} heading={"No Results"} />
                  </Flex>
                </>
              )}
            </>
          ) : (
            <div className="ml-4">
              <BulletListSkeleton />
            </div>
          )}
        </div>
      </Flex>
    </Paper>
  );
};

const TopLocationsMemoized = React.memo(TopLocations);
export { TopLocationsMemoized as TopLocations };
