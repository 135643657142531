import {
  Box,
  Button,
  Table,
  CopyButton,
  TextInput,
  ActionIcon,
  Tooltip,
  Text,
  Title,
  Flex
} from "@mantine/core";
import React, { useContext, useEffect } from "react";
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
import { SettingsHeaderContent } from "../../../components/App/Settings/SettingsHeaderContent/SettingsHeaderContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { width } from "@fortawesome/pro-light-svg-icons/fa0";
import { ApiKeyService } from "../../../../lib/services/ApiKeyService";
import { convertDateToTimezone } from "../../../../lib/utils/DateUtility";
import AppLifecycleContext from "../../../../lib/contexts/AppLifecycleContext";
import { openConfirmModal, openModal, closeAllModals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import classes from "./AccountAPICredentials.module.css";
export interface IAPIKey {
  id: string;
  name: string;
  key: string;
  created_at: string;
}

const AccountAPICredentials = (props: any) => {
  const [apiKeys, setApiKeys] = React.useState<IAPIKey[]>([]);
  const [apiKeyName, setApiKeyName] = React.useState<string>("");

  const { hasRolesNotWith, hasRolesWith } = useWorkspaceUtilityHook();
  const apiKeyService = new ApiKeyService();
  const { activeWorkspace } = useContext(AppLifecycleContext);

  useEffect(() => {
    document.title = "Manage API Credentials | Usermaven";
    getApiKeys();
  }, []);

  /**
   * Create a new API key
   */
  const openCreateModal = async () => {
    openModal({
      title: "Create API Key",
      children: <CreateAPIKeyModalContent createNewApiKey={createNewApiKey} />
    });
  };

  /**
   * Create a new API key
   */
  const createNewApiKey = async (values: any) => {
    console.log(values);
    const response = await apiKeyService.createApiKey({ name: values.name });
    console.log(response);
    if (response.data) {
      setApiKeys([...apiKeys, response.data]);
      closeAllModals();
    }
  };

  /**
   * Delete an API key
   */
  const deleteApiKey = async (id: string) => {
    openConfirmModal({
      title: <h3 className="font-bold">Remove API Key</h3>,
      children: (
        <Text size="sm">
          Are you sure you want to delete this API key? This action cannot be undone.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6" },
      onConfirm: async () => {
        const response = await apiKeyService.deleteApiKey(id);
        if (response.data) {
          setApiKeys(apiKeys.filter((apiKey) => apiKey.id !== id));
        }
      }
    });
  };

  /**
   * Get all API keys
   */
  const getApiKeys = async () => {
    const response = await apiKeyService.getApiKeys();
    console.log("getApiKeys", response);
    if (response.data) {
      setApiKeys(response.data);
    }
  };
  if (hasRolesNotWith([WORKSPACE_MEMBER_ROLES.OWNER, WORKSPACE_MEMBER_ROLES.ADMINISTRATOR])) {
    return (
      <>
        <div className="flex justify-center flex-col items-center py-4 mt-12">
          <FontAwesomeIcon icon={solid("ban")} className="text-3xl mb-4 text-red-600" />
          <h3 className="font-semibold pb-4">You do not have permission to access this resource</h3>
          <p className="w-3/5 text-center">
            Your account owner{" "}
            <span className="font-semibold">
              {activeWorkspace.organization.creator.full_name} (
              {activeWorkspace.organization.creator.email})
            </span>{" "}
            can only access this page. If you'd like to access this resource, please contact your
            organization owner.
          </p>
        </div>
      </>
    );
  }
  return (
    <>
      <>
        <Box component="div">
          <Flex align={"center"}>
            <Flex flex={1} direction={"column"}>
              <Title order={4} fw={600} mb="md">
                Manage API Credentials
              </Title>
              <Text>These credentials are required to authenticate with the API.</Text>
            </Flex>
            <Button ml={"md"} onClick={openCreateModal}>
              Create New API Key
            </Button>
          </Flex>

          <Table mt={"xl"} withTableBorder horizontalSpacing="lg" verticalSpacing="md">
            <Table.Thead fw={600}>
              <Table.Tr>
                <Table.Th style={{ width: "15%" }}>Name</Table.Th>
                <Table.Th style={{ width: "50%" }}>API Key</Table.Th>
                <Table.Th style={{ width: "20%" }}>Created</Table.Th>
                <Table.Th style={{ width: "10%" }}></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {apiKeys.map((apiKey: IAPIKey) => (
                <Table.Tr key={apiKey.id}>
                  <Table.Td>
                    <Text>{apiKey.name}</Text>
                  </Table.Td>
                  <Table.Td
                    style={{
                      paddingRight: "40px"
                    }}>
                    <Flex flex={1}>
                      <TextInput
                        w={"100%"}
                        readOnly={true}
                        value={apiKey.key}
                        withAsterisk
                        rightSection={
                          <>
                            <CopyButton value={apiKey.key} timeout={2000}>
                              {({ copied, copy }) => (
                                <Tooltip
                                  label={copied ? "Copied" : "Copy"}
                                  withArrow
                                  position="right">
                                  <ActionIcon
                                    color={copied ? "teal" : "gray.7"}
                                    variant="subtle"
                                    onClick={copy}>
                                    {copied ? (
                                      <FontAwesomeIcon icon={regular("check")} />
                                    ) : (
                                      <FontAwesomeIcon icon={regular("copy")} />
                                    )}
                                  </ActionIcon>
                                </Tooltip>
                              )}
                            </CopyButton>
                          </>
                        }
                      />
                    </Flex>
                  </Table.Td>
                  <Table.Td>
                    <Text>
                      {convertDateToTimezone(apiKey.created_at, activeWorkspace.timezone)}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <ActionIcon color="red" variant="light" onClick={() => deleteApiKey(apiKey.id)}>
                      <FontAwesomeIcon icon={regular("trash-alt")} />
                    </ActionIcon>
                  </Table.Td>
                </Table.Tr>
              ))}

              {apiKeys.length === 0 && (
                <Table.Tr>
                  <Table.Td colSpan={4}>
                    <Text ta="center" size="sm" color={"dimmed"}>
                      No API keys found.
                    </Text>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Tbody>
          </Table>
        </Box>
      </>
    </>
  );
};
export default AccountAPICredentials;

function CreateAPIKeyModalContent({
  createNewApiKey = () => {}
}: {
  createNewApiKey?: (values: any) => void;
}) {
  const form = useForm({
    initialValues: {
      name: ""
    },

    validate: {
      name: (value: string) => {
        // Name is required
        if (!value) {
          return "Name is required";
        }

        // Name must be 3-50 characters
        if (value.length < 3 || value.length > 50) {
          return "Name must be between 3 and 50 characters";
        }

        return null;
      }
    }
  });

  return (
    <form onSubmit={form.onSubmit(createNewApiKey)}>
      <TextInput
        label="API key name"
        placeholder="Enter a name for this API key"
        data-autofocus
        styles={{
          label: {}
        }}
        required
        {...form.getInputProps("name")}
      />
      <Button fullWidth mt="md" type="submit">
        Generate API Key
      </Button>
    </form>
  );
}
