import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { SunburstChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import React, { memo, useEffect, useMemo, useState } from "react";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  TransformComponent
} from "echarts/components";
import { UniversalTransition } from "echarts/features";
import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  Progress,
  ScrollArea,
  SimpleGrid,
  Slider,
  Space,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import isEqual from "react-fast-compare";
import EChartsReact from "echarts-for-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDebouncedState } from "@mantine/hooks";
import { Oval } from "react-loader-spinner";
import { truncateText } from "../../../../../lib/utils/StringUtility";
import classes from "./SunburstChart.module.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  CanvasRenderer,
  UniversalTransition,
  SunburstChart,
  CanvasRenderer,
  TransformComponent
]);

export interface ISunburstChartProps {
  data: any;
  // mostCommonPaths: any[] | null
  onDepthChange: (depth: number) => Promise<void>;
}

type Item = {
  name: string;
  value: number;
  drop_off_rate: number;
  conversion_rate: number;
  children: Item[];
};

const getFlatChildren = (data: Item[]): Item[] => {
  if (!data) return [];

  return data.reduce((acc: Item[], item: Item): Item[] => {
    if (item.children) {
      acc.push(item);
      acc = acc.concat(getFlatChildren(item.children));
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
};

const Sunburst = ({
  data,
  // mostCommonPaths,
  onDepthChange
}: ISunburstChartProps) => {
  const theme = useGlobalMantineTheme();
  const chartRef = React.useRef<EChartsReact | null>(null);
  const [selected, setSelected] = React.useState<Item>({
    name: "root",
    value: 0,
    drop_off_rate: 0,
    conversion_rate: 0,
    children: []
  });
  const [mouseOverText, setMouseOverText] = React.useState({
    text: "",
    subtext: ""
  });
  const [depth, setDepth] = useDebouncedState(6, 200);
  const [depthLoading, setDepthLoading] = React.useState(false);
  const [totalNumber, setTotalNumber] = React.useState(0);
  const flatData = useMemo(() => getFlatChildren(data), [data]);

  const tooltipFormatter = (params: any) => {
    const { color, data } = params;
    const { name, value, drop_off_rate, conversion_rate } = data;
    // console.log('formatter', params);
    if (params.dataIndex === 0) {
      return ``;
    }
    return `
          <div style='font-family: "Inter", sans-serif; margin: -10px;'>
            <div style='font-size: 14px; font-weight: 500; color: ${
    theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7]
    }; margin-bottom: 4px; border-bottom: 1px solid; border-bottom-color: ${
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
    }; padding: 10px 10px;'>
              <span
                style='display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: ${color}; margin-right: 8px;'
              ></span>
              ${name ? truncateText(name, 50) : ""}
            </div>
            <div style='font-size: 13px; font-weight: 400; color: ${
      theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7]
    }; margin-bottom: 4px; border-bottom: 1px solid; border-bottom-color: ${
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
    }; padding: 6px 10px;'>
             <span style='font-weight: 500'>Users: ${value}</span>
             <i style='font-size: 11px; font-weight: 400; color: ${
      theme.colors.gray[6]
    }; margin-top: 4px; display: block; font-style: italic;'>
              Total number of users who have started this journey
             </i>
            </div>
            ${
      drop_off_rate !== undefined
        ? ` <div style='font-size: 13px; font-weight: 400; color: ${
          theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7]
        }; margin-bottom: 4px; border-bottom: 1px solid; border-bottom-color: ${
          theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
        }; padding: 6px 10px;'>
                 <span style='font-weight: 500'>Drop off rate: ${drop_off_rate}%</span>
                  <i style='font-size: 11px; font-weight: 400; color: ${
          theme.colors.gray[6]
        }; margin-top: 4px; display: block; font-style: italic;'>
                    Percentage of users who have dropped off at this path
                 </i>
                </div>`
        : ""
    }
            
            ${
      conversion_rate !== undefined
        ? `
                <div style='font-size: 13px; font-weight: 400; color: ${    theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7]
        }; margin-bottom: 4px; padding: 6px 10px;'>
                 <span style='font-weight: 500'>Conversion rate: ${conversion_rate}%</span>
                  <i style='font-size: 11px; font-weight: 400; color: ${theme.colors.gray[6]}; margin-top: 4px; display: block; font-style: italic;'>
                    Percentage of users who have converted at this path
                  </i>
                </div>
                `
        : ""
    }
          </div>
        `;
  }


  const [option, setOption] = React.useState<any>({
    tooltip: {
      formatter: tooltipFormatter,
      textStyle: {
        color: theme.colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
      },
    },
    series: {
      type: "sunburst",
      data: [],
      emphasis: {
        focus: "ancestor"
      },
      radius: [60, "100%"],
      universalTransition: true,
      itemStyle: {
        borderRadius: 4,
        borderWidth: 2
      },
      label: {
        show: false
      },
      startAngle: 120
    }
  });
  const firstLoaded = React.useRef(false);


  useEffect(() => {
    if (!firstLoaded.current) {
      firstLoaded.current = true;
      return;
    }
    const fetchData = async () => {
      setDepthLoading(true);
      await onDepthChange(depth);
      setDepthLoading(false);
    };

    fetchData();
  }, [depth]);

  useEffect(() => {
    if (data) {
      setOption((state: any) => ({
        ...state,
        tooltip: {
          ...state.tooltip,
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
          borderColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
          formatter: tooltipFormatter
        },
        series: {
          ...state.series,
          data: data,
          itemStyle: {
            ...state.series.itemStyle,
            borderColor: theme.colorScheme === "dark" ? "#000" : "#fff"
          }
        }
      }));

      // Total Number of users/session
      setTotalNumber(data[0].value);

      setSelected(data);

      setMouseOverText({
        text: data[0].value.toLocaleString(),
        subtext: `100% of users`
      });
    }
  }, [data, theme.colorScheme]);

  /**
   * On click event handler
   */
  const onEvents = {
    // click: (params: any) => {
    //   console.log('onEvents', params);
    //   const data = params.data;
    //   setSelected({
    //     ...data
    //   });
    // },
    mouseover: (params: any) => {
      const { data: nodeData, treePathInfo } = params;
      const ansestors = treePathInfo
        .map((item: any) => {
          // Find the object in the data of current item
          const obj = flatData.find((d: any) => d.name === item.name && d.value === item.value);

          if (obj) {
            return obj;
          }
        })
        .filter((item: any) => item !== undefined);

      setSelected({
        name: "root",
        value: 0,
        drop_off_rate: 0,
        conversion_rate: 0,
        children: ansestors
      });

      if (nodeData.drop_off_rate !== undefined && nodeData.drop_off_rate !== null) {
        setMouseOverText({
          text: nodeData.value.toLocaleString(),
          subtext: `${Math.round((nodeData.value / totalNumber) * 100)}% of users`
        });
      } else if (selected.value !== undefined && selected.value !== null) {
        setMouseOverText({
          text: selected.value.toLocaleString(),
          subtext: `${Math.round((selected.value / totalNumber) * 100)}% of users`
        });
      }
    }
  };

  return (
    <>
      <Grid py="lg">
        <Grid.Col
          span={{
            sm: 12,
            md: 7,
            lg: 8
          }}>
          <Flex direction={"column"}>
            <Box pos={"relative"} h={"100%"} w={"100%"}>
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center"
                }}>
                <Text size="md" fw={600}>
                  {mouseOverText.text}
                </Text>
                <Text size="xs" fw={400}>
                  {mouseOverText.subtext}
                </Text>
              </Box>
              <ChartWrapper option={option} onEvents={onEvents} chartRef={chartRef} />
            </Box>
            <Box p={"md"}>
              <div className={"flex-1 w-full max-w-[60rem] relative mx-auto"}>
                <Flex align={"center"}>
                  <Text size="sm" mb={"xs"}>
                    Interactive Depth (Level {depth})
                    {depthLoading ? <Loader ml={"sm"} size={10} /> : null}
                  </Text>
                </Flex>

                <Slider
                  maw={"80%"}
                  defaultValue={6}
                  label={(value: number) => `${value} levels`}
                  marks={[{ value: 4 }, { value: 6 }, { value: 8 }, { value: 10 }]}
                  min={2}
                  max={12}
                  onChange={setDepth}
                  disabled={depthLoading}
                />
              </div>

              {/*<Box pt={'16px'}>*/}

              {/*  {*/}
              {/*    mostCommonPaths && (*/}
              {/*      <Alert mb={'md'}*/}
              {/*             title="Most common paths are"*/}
              {/*             className="mx-auto max-w-[60rem] mt-4"*/}
              {/*             icon={*/}
              {/*               <FontAwesomeIcon className="text-2xl" icon={regular("lightbulb-exclamation")} />*/}
              {/*             }*/}
              {/*      >*/}
              {/*        <Box component={'ol'} className="!list-decimal list-inside" sx={theme => ({*/}
              {/*          li: {*/}
              {/*            fontSize: '1rem',*/}
              {/*            marginBottom: '0.5rem',*/}
              {/*            color: colorScheme === 'dark' ? theme.colors.dark[2] : theme.black,*/}

              {/*            '&:last-child': {*/}
              {/*              marginBottom: 0*/}
              {/*            },*/}

              {/*            b: {*/}
              {/*              fontWeight: 600*/}
              {/*            }*/}
              {/*          }*/}
              {/*        })}>*/}
              {/*          {*/}
              {/*            mostCommonPaths?.map((path: any[], index: number) => (*/}
              {/*              <li key={index}>*/}
              {/*                {path[0].join(' -> ')}: <b>{path[1]} users</b>*/}
              {/*              </li>*/}
              {/*            ))*/}
              {/*          }*/}
              {/*        </Box>*/}
              {/*      </Alert>*/}
              {/*    )*/}
              {/*  }*/}

              {/*</Box>*/}
            </Box>
          </Flex>
        </Grid.Col>

        <Grid.Col
          span={{
            sm: 12,
            md: 5,
            lg: 4
          }}>
          <Card shadow="lg" radius="md" withBorder>
            <Card.Section p={"md"}>
              <Text size="sm" fw={500}>
                Paths from All Entries
              </Text>
            </Card.Section>

            <Card.Section>
              <Box
                h={650}
                style={{
                  overflowY: "auto"
                }}>
                {!selected.children || selected.children.length === 0 ? (
                  <Alert icon={<FontAwesomeIcon icon={regular("info-circle")} />} color="gray">
                    <Text size="xs">Hover over a path to see the details</Text>
                  </Alert>
                ) : (
                  <div>
                    <Divider />
                    {selected.children &&
                      selected.children.map((item: any, index: number) => (
                        <>
                          <Group
                            key={index}
                            className={classes.path}
                            p={"sm"}
                            pos={"relative"}
                            style={{
                              borderLeft: `4px solid ${item.itemStyle.color}`
                            }}>
                            <Flex
                              className={classes.pathCR}
                              direction={"column"}
                              justify={"space-between"}
                              px="sm"
                              py={"md"}
                              miw={76}
                              style={(theme) => ({
                                borderRadius: theme.radius.sm,
                                gap: "4px"
                              })}>
                              <Text ta="center" size="xs" fw={500}>
                                {item.conversion_rate}%
                              </Text>

                              <FontAwesomeIcon icon={regular("arrow-down")} />
                            </Flex>
                            <Box py={8} px={"md"} flex={1}>
                              <Stack gap={4}>
                                <Tooltip label={item.name} disabled={item.name.length < 33}>
                                  <Text
                                    lineClamp={1}
                                    style={{
                                      wordBreak: "break-all"
                                    }}>
                                    {item.name}
                                  </Text>
                                </Tooltip>
                                <Text size="xs" fw={500} c="brand">
                                  {/* Percentage of conversion with number of user */}
                                  {item.value.toLocaleString()} users
                                </Text>
                                <Progress size={"xs"} value={item.conversion_rate} />
                              </Stack>
                            </Box>
                          </Group>
                          <Divider />
                        </>
                      ))}
                  </div>
                )}
              </Box>
            </Card.Section>
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
};

const ChartWrapper = memo(
  ({ option, onEvents, chartRef }: { option: any; onEvents: any; chartRef?: any }) => (
    <>
      <ReactEChartsCore
        style={{ height: "640px" }}
        ref={chartRef}
        opts={{ renderer: "svg" }}
        notMerge={true}
        echarts={echarts}
        onEvents={onEvents}
        option={option}
        // onChartReady={handleChartReady}
      />
    </>
  ),
  (prevProps, nextProps) => {
    return isEqual(prevProps.option, nextProps.option);
  }
);

export default Sunburst;
