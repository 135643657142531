/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Center, Grid, SegmentedControl, Text, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import StatsCard from "../../../../components/Common/StatsCard/StatsCard";
import { numberToCommas } from "../../../../../lib/utils/StringUtility";
import { roundFunnelRate } from "../../../../../lib/utils/FunnelUtility";
import { Oval } from "react-loader-spinner";
import { FUNNEL_TYPE_KEYS, LOADER_COLOR } from "../../../../../lib/utils/Constants";
import BreakdownDetailTable from "../FunnelDetailedView/BreakdownDetailTable";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { IFunnelMeta, IFunnelStep, TAttribute } from "../FunnelDetailedView/FunnelDetails";
import { IDetailViewMenu } from "../FunnelDetailedView/DetailViewMenuGroup";
import { DecodedValueMap, QueryParamConfig } from "serialize-query-params";
import { WebAnalyticsService } from "../../../../../lib/services/WebAnalyticsService";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { Params, useParams } from "react-router-dom";
import BreakdownBarChart from "../BreakdownBarChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import MultiFunnelBarChart from "../FunnelBarChart/components/organisms/MultiBarChart";
import { useLocalStorage } from "@mantine/hooks";
import { FormattedMessage, useIntl } from "react-intl";

export interface IOverviewTabProps {
  id: string;
  selectedType: "visitor" | "user" | "company";
  selectedTab: IDetailViewMenu;
  initialSelectedFunnel: IFunnelStep[];
  initialSelectedMeta: IFunnelMeta | undefined;
  dateRange: DecodedValueMap<{
    start_date: QueryParamConfig<string | null | undefined, string | null | undefined>;
    end_date: QueryParamConfig<string | null | undefined, string | null | undefined>;
  }>;
  isOnboarding?: boolean;
}

const BreakdownTab = ({
  id,
  selectedTab,
  selectedType,
  dateRange,
  initialSelectedFunnel,
  initialSelectedMeta,
  isOnboarding = false
}: IOverviewTabProps) => {
  const { colorScheme } = useMantineColorScheme();

  const intl = useIntl();

  /** Secret is available in case of the shareable funnels. */
  const { secret } = useParams<Params>();

  const [loading, setLoading] = useState({
    funnel: false,
    breakdown: false
  });
  // const [value, setValue] = useState<"default" | "vertical">("default");
  const [value, setValue] = useLocalStorage<"default" | "vertical">({
    key: FUNNEL_TYPE_KEYS,
    defaultValue: "default"
  });

  // Breakdown funnel states (breakdown)
  const [steps, setSteps] = useState<Array<IFunnelStep[]>>([]);
  const [breakdownTableSteps, setBreakdownTableSteps] = useState<Array<IFunnelStep[]>>([]);
  const [meta, setMeta] = useState<any>(null);
  const [breakdownTableMeta, setBreakdownTableMeta] = useState<any>(null);

  // Show complete list of Breakdown funnel states
  const [completeSteps, setCompleteSteps] = useState<Array<IFunnelStep[]>>([]);
  const [completeMeta, setCompleteMeta] = useState<any>(null);

  // Show complete list toggle
  const [showCompleteList, setShowCompleteList] = useState<boolean>(false);

  // Service instance for api requests
  const webAnalyticsService = new WebAnalyticsService();

  // Workspace context
  const { activeWorkspace } = useContext(AppLifecycleContext);

  useEffect(() => {
    fetchDefaultFunnel();
  }, [dateRange, selectedTab.selectedOption, colorScheme]);

  const handleShowCompleteList = (val: boolean) => {
    fetchDefaultFunnel(val);
    setShowCompleteList(!showCompleteList);
  };

  const fetchDefaultFunnel = async (isCompleteList = false) => {
    setLoading((state) => ({
      funnel: isCompleteList ? state.funnel : true,
      breakdown: isCompleteList ? true : state.breakdown
    }));
    const breakdownBy =
      selectedTab.selectedOption === "none" ? "parsed_device" : selectedTab.selectedOption;

    const response = await webAnalyticsService.breakdown(
      activeWorkspace.id,
      id,
      {
        start_date: dateRange.start_date,
        end_date: dateRange.end_date,
        breakdown_by: breakdownBy,
        grouped:
          breakdownBy === "parsed_device" || breakdownBy === "channel" ? false : !isCompleteList,
        funnel_type: isOnboarding ? selectedType : ""
      },
      secret || null
    );
    setLoading((state) => ({
      funnel: isCompleteList ? state.funnel : false,
      breakdown: isCompleteList ? false : state.breakdown
    }));

    if (response?.data?.funnel_steps) {
      if (isCompleteList) {
        setCompleteSteps(response.data.funnel_steps);
        setCompleteMeta(response.data.meta);
        return;
      }

      setSteps(response.data.funnel_steps);
      setBreakdownTableSteps(response.data.funnel_steps);
      setMeta(response.data.meta);
      setBreakdownTableMeta(response.data.meta);
      setShowCompleteList(false);
    }
  };

  /**
   * Resolve type for stats card
   */
  const resolvedType = useMemo(() => {
    if (!selectedType || selectedType === "visitor") {
      return intl.formatMessage({
        id: "insights.type.visitors",
        defaultMessage: "visitors"
      });
    } else if (selectedType === "user") {
      return intl.formatMessage({
        id: "insights.type.users",
        defaultMessage: "users"
      });
    }
    return intl.formatMessage({
      id: "insights.type.companies",
      defaultMessage: "companies"
    });
  }, [selectedType]);

  const columnsCounts = useMemo(() => {
    // We will check if the steps.length is greater than 3 and the char length of the
    // initialSelectedMeta?.most_drop_off_step?.name is greater than 20 then we will return 3 columns with
    // this division: [3, 3, 6] else we will return [4, 4, 4]
    if (
      steps.length >= 3 &&
      initialSelectedMeta &&
      initialSelectedMeta?.most_drop_off_step?.name?.length > 20
    ) {
      return [3, 3, 6];
    }
    return [4, 4, 4];
  }, [steps]);

  return (
    <>
      <Grid m={0} mb={10}>
        <Grid.Col
                  span={{ base: 12, sm: 6, md: columnsCounts[0] }}
        >
          <StatsCard
            title={intl.formatMessage(
              {
                id: "insights.cards.totalCount",
                defaultMessage: "Total Visitors"
              },
              {
                type: resolvedType
              }
            )}
            description={intl.formatMessage(
              {
                id: "insights.cards.totalCount.subtitle",
                defaultMessage: "who have visited the funnel"
              },
              {
                type: resolvedType
              }
            )}
            value={numberToCommas(initialSelectedMeta?.total_visitors || 0)}
            icon="eye"
            bg="bg-yellow-100"
            iconColor="text-yellow-500"
          />
        </Grid.Col>
        <Grid.Col  span={{ base: 12, sm: 6, md: columnsCounts[1] }}>
          <StatsCard
            title={intl.formatMessage(
              {
                id: "insights.cards.conversionRate",
                defaultMessage: "Conversion Rate"
              },
              {
                type: resolvedType
              }
            )}
            description={intl.formatMessage(
              {
                id: "insights.cards.conversionRate.subtitle",
                defaultMessage: "have completed all steps"
              },
              {
                type: resolvedType
              }
            )}
            value={
              <>
                {roundFunnelRate(initialSelectedMeta?.conversion_rate || 0)}%{" "}
                <small>
                  ({initialSelectedMeta?.total_converted} {resolvedType})
                </small>
              </>
            }
            icon="users"
            bg="bg-blue-100"
            iconColor="text-blue-500"
          />
        </Grid.Col>
        {steps.length && steps[0].length >= 3 && initialSelectedMeta?.most_drop_off_step ? (
          <Grid.Col
            span={{ base: 12, sm: 12, md: columnsCounts[2] }}
          >
            <StatsCard
              // title="Most Drop-off Step"
              title={intl.formatMessage({
                id: "insights.cards.mostDropOffStep",
                defaultMessage: "Most Drop-off Step"
              })}
              value={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-11px"
                  }}>
                  <Box
                    sx={(theme) => ({
                      marginRight: "12px",
                      borderRadius: 4,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid",
                      fontWeight: 600,
                      // height: 24,
                      // width: 24,
                      padding: "4px 8px",
                      fontSize: 12,
                      color: colorScheme === "dark" ? theme.colors.dark[0] : "#595c5f",
                      backgroundColor: colorScheme === "dark" ? theme.colors.dark[4] : "#e8e9ec",
                      borderColor: colorScheme === "dark" ? theme.colors.dark[4] : "#e8e9ec",
                      fontFamily: "Inter, sans-serif",
                      whiteSpace: "nowrap"
                    })}>
                    Step {initialSelectedMeta?.most_drop_off_step.level}
                  </Box>
                  <Text lineClamp={1}>{initialSelectedMeta?.most_drop_off_step?.name || "-"}</Text>
                </Box>
              }
              description={
                <>
                  <FormattedMessage
                    id="insights.cards.mostDropOffStep.subtitle"
                    defaultMessage="Highest drop-off rate of {value} at this step"
                    values={{
                      value: (
                        <b>
                          $
                          {roundFunnelRate(
                            initialSelectedMeta?.most_drop_off_step?.percentage || 0
                          )}
                          %
                        </b>
                      )
                    }}
                  />
                </>
              }
              icon="arrow-down"
              bg="bg-red-100"
              iconColor="text-red-500"
            />
          </Grid.Col>
        ) : null}
      </Grid>

      <>
        {loading.funnel ? (
          <Box
            sx={{
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
            <Oval color={LOADER_COLOR} height={16} width={16} />
          </Box>
        ) : (
          <Box pos={"relative"}>
            <Box pos={"absolute"} right={"10px"} top={"-10px"}>
              <SegmentedControl
                value={value}
                onChange={(val: any) => setValue(val as any)}
                data={[
                  {
                    value: "default",
                    label: (
                      <Center>
                        <FontAwesomeIcon
                          className="text-xl text-gray-900"
                          icon={regular("chart-simple-horizontal")}></FontAwesomeIcon>
                      </Center>
                    )
                  },
                  {
                    value: "vertical",
                    label: (
                      <Center>
                        <FontAwesomeIcon
                          className="text-xl text-gray-900"
                          icon={regular("chart-simple")}></FontAwesomeIcon>
                      </Center>
                    )
                  }
                ]}
              />
            </Box>
            {steps[0] ? (
              <>
                {value === "vertical" ? (
                  <MultiFunnelBarChart
                    bars={steps as any}
                    initialBars={initialSelectedFunnel as any}
                  />
                ) : (
                  <BreakdownBarChart
                    steps={steps}
                    meta={meta}
                    selectedFunnel={initialSelectedFunnel}
                    grouped={!showCompleteList}
                    type={selectedType}
                  />
                )}
              </>
            ) : null}
          </Box>
        )}

        <div className="mt-3">
          <BreakdownDetailTable
            steps={showCompleteList ? completeSteps : breakdownTableSteps}
            setSteps={showCompleteList ? setCompleteSteps : setBreakdownTableSteps}
            meta={showCompleteList ? completeMeta : breakdownTableMeta}
            setMeta={showCompleteList ? setCompleteMeta : setBreakdownTableMeta}
            loading={loading.breakdown || loading.funnel}
            selectedAttribute={selectedTab.selectedOption as TAttribute}
            showCompleteList={showCompleteList}
            onShowCompleteListChange={(grouped) => handleShowCompleteList(grouped)}
          />
        </div>
      </>
    </>
  );
};

export default BreakdownTab;
