import { useState, useEffect } from 'react';

const useGetElement = (query: string) => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    (async () => {
      // @ts-ignore
      let element = await new Promise<HTMLElement | null>((resolve: (value: Element) => void) => {
        function getElement() {
          const element = document.querySelector(query);
          if (element) {
            resolve(element);
          } else {
            requestAnimationFrame(getElement);
          }
        }

        getElement();
      });

      setElement(element);
    })();
  }, [query]);
  return element;
};

export default useGetElement;
