import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
import { useEffect } from "react";
import { AccountsSecurityChangePassword } from "./AccountsSecurityChangePassword";
import { Box } from "@mantine/core";
const AccountsSecurity = (props: any) => {
  useEffect(() => {
    document.title = "Account Security | Usermaven";
  }, []);

  return (
    <>
      <AccountsSecurityChangePassword></AccountsSecurityChangePassword>
    </>
  );
};
export default AccountsSecurity;
