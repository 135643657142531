import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowDown,
  faArrowPointer,
  faClock,
  faEye,
  faUsers
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Group, Paper, Skeleton, Text } from "@mantine/core";
import React from "react";
import classes from "./StatsCard.module.css";
export interface IStatsCard {
  title: string;
  description: React.ReactNode;
  value: string | number | React.ReactNode;
  icon: string;
  bg: string;
  iconColor: string;
  loading?: boolean;
}

const StatsCard = ({
  title = "",
  value = "",
  description = "",
  icon = "clock",
  bg = "bg-purple-100",
  iconColor = "text-purple-500",
  loading = false
}: IStatsCard) => {
  const myIcons = {
    clock: faClock,
    eye: faEye,
    users: faUsers,
    "arrow-down": faArrowDown,
    "arrow-pointer": faArrowPointer
  };

  if (loading) {
    return (
      <Paper withBorder p="md" radius="md" shadow="sm">
        {/*<Skeleton  height={'80px'} />*/}
        <Group pos="relative" justify="space-between">
          <Skeleton height={14} width={"30%"} radius="md" />

          <Skeleton height={30} circle />
        </Group>

        <Group align="flex-end" gap="xs" mt={4}>
          <Skeleton height={16} mt={3} width={"100px"} radius="md" />
        </Group>

        <Skeleton height={12} mt={14} width={"50%"} radius="md" />
      </Paper>
    );
  }

  // @ts-ignore
  const currentIcon: IconDefinition = myIcons[icon];
  return (
    <Paper withBorder p="md" radius="md" shadow="sm" mb="md">
      <Group justify="space-between" pos="relative">
        <Text size="xs" color="dimmed" className={classes.title}>
          {title}
        </Text>
        <Box w={16} c={bg} className={` ${classes.icon}`}>
          <FontAwesomeIcon className={`text-md ${iconColor}`} icon={currentIcon}></FontAwesomeIcon>
        </Box>
      </Group>

      <Group align="flex-end" gap="xs" mt={18}>
        <Text className={classes.value}>{value}</Text>
      </Group>

      <Text size="xs" c="dimmed" mt={"sm"}>
        {description}
      </Text>
    </Paper>
  );
};

export default StatsCard;
