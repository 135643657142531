import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Box, Button, Flex, Popover, Tooltip, useMantineTheme } from "@mantine/core";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { useWebSavedViewsStore } from "@/stores/useWebSavedViewsStore";
import { FilterItemButton } from "./FilterItemButton";
import { FilterMobileDropdown } from "./FilterMobileDropdown";
import { FilterViewMoreDropdown } from "./FilterViewMoreDropdown";
import { useMediaQuery } from "@mantine/hooks";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const Filters = ({ query, setQuery, scrollPosition, isPublic }: any) => {
  const theme = useGlobalMantineTheme();
  const { hasRolesNotWith } = useWorkspaceUtilityHook();
  const [selectedSavedId, editSavedView, openSavedViewModal, resetSavedView] =
    useWebSavedViewsStore((state) => [
      state.selectedId,
      state.edit,
      state.setModalOpen,
      state.reset
    ]);

  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      {isMobile && (
        <FilterMobileDropdown scrollPosition={scrollPosition} query={query} setQuery={setQuery} />
      )}

      {/* Web Filters */}
      {query.filters.length > 1 ? (
        <>
          <Popover
            width={"100%"}
            position="bottom-start"
            shadow="md"
            styles={{
              dropdown: {
                maxWidth: "600px"
              }
            }}>
            <Popover.Target>
              <Button
                className={`mr-4 ${
                  scrollPosition > 0.03
                    ? theme.colorScheme === "dark"
                      ? "border border-solid border-gray-200 !text-gray-600 !bg-dark-600"
                      : "border border-solid border-gray-200 text-gray-900"
                    : " text-gray-800"
                }`}
                size="sm"
                color={scrollPosition > 0.03 ? "gray.9" : "gray.6"}
                variant={scrollPosition > 0.03 ? "light" : "outline"}
                leftSection={<FontAwesomeIcon icon={regular("filter-list")} />}>
                Filters ({query.filters.length})
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <div className="">
                {query.filters.length > 0 && (
                  <Flex justify={"flex-end"} ml={4}>
                    {!isPublic && hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                      <>
                        <Tooltip label="Save view">
                          <ActionIcon
                            mr={8}
                            variant={"light"}
                            className={scrollPosition > 0.03 ? "bg-gray-100" : "bg-opacity-30"}
                            color={"brand.3"}
                            onClick={() => {
                              if (selectedSavedId) {
                                editSavedView(selectedSavedId);
                                openSavedViewModal(true);
                              } else {
                                resetSavedView();
                                openSavedViewModal(true);
                              }
                            }}>
                            <FontAwesomeIcon icon={regular("bookmark")} data-cy="save-view-btn" />
                          </ActionIcon>
                        </Tooltip>
                      </>
                    )}

                    <Tooltip label="Clear filters">
                      <ActionIcon
                        className={scrollPosition > 0.03 ? "bg-gray-100" : ""}
                        color={"red.7"}
                        variant={"light"}
                        onClick={() => {
                          setQuery({
                            ...query,
                            filters: []
                          });
                        }}>
                        <FontAwesomeIcon icon={regular("times")} data-cy="clear-filters-btn" />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                )}
                {query.filters.map((queryItem: string, index: number) => (
                  <Box my={6}>
                    <FilterItemButton
                      query={query}
                      item={queryItem}
                      index={index}
                      setQuery={setQuery}
                      key={`filter-item-button-c:${index}`}
                    />
                  </Box>
                ))}
              </div>
            </Popover.Dropdown>
          </Popover>
        </>
      ) : (
        <Flex align={"center"} wrap={"wrap"} className="flex flex-wrap sm:flex-nowrap">
          {query.filters.slice(0, 2).map((queryItem: string, index: number) => (
            <FilterItemButton
              query={query}
              item={queryItem}
              index={index}
              setQuery={setQuery}
              key={`filter-item-button-c:${index}`}
            />
          ))}
          {query.filters.length - 2 > 0 && (
            <>
              <FilterViewMoreDropdown query={query} setQuery={setQuery} />
            </>
          )}
          {query.filters.length > 0 && (
            <Flex align={"center"} ml={4}>
              {!isPublic && hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                <>
                  <Tooltip label="Save view">
                    <ActionIcon
                      mr={8}
                      ml={4}
                      variant={"light"}
                      color={scrollPosition > 0.03 ? "white" : "gray.6"}
                      onClick={() => {
                        if (selectedSavedId) {
                          editSavedView(selectedSavedId);
                          openSavedViewModal(true);
                        } else {
                          resetSavedView();
                          openSavedViewModal(true);
                        }
                      }}>
                      <FontAwesomeIcon icon={regular("bookmark")} data-cy="save-view-btn" />
                    </ActionIcon>
                  </Tooltip>
                </>
              )}

              <Tooltip label="Clear filters">
                <ActionIcon
                  color={scrollPosition > 0.03 ? "white" : "red.7"}
                  variant={"light"}
                  onClick={() => {
                    setQuery({
                      ...query,
                      filters: []
                    });
                  }}>
                  <FontAwesomeIcon icon={regular("times")} data-cy="clear-filters-btn" />
                </ActionIcon>
              </Tooltip>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};
