import {
  Title,
  Text,
  Container,
  Box,
  Grid,
  Image,
  Flex,
  Textarea,
  Button,
  Center,
  useMantineTheme,
  Stack
} from "@mantine/core";
import { useContext, useState } from "react";
import CapterraLogo from "@assets/images/ai-chat-assistant/capterra_logo.png";
import G2Logo from "@assets/images/ai-chat-assistant/g2-logo.webp";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { toast } from "react-toastify";
import { UserService } from "@/lib/services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import AIAssistantVideo from "@assets/videos/ai-assistant-demo.mp4";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
interface IAIChatAskReview {
  opened: boolean;
  setOpened: (opened: boolean) => void;
}

export const AIChatAskReview = ({ opened, setOpened }: IAIChatAskReview) => {
  const { user, activeWorkspace } = useContext(AppLifecycleContext);
  const [active, setActive] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isStarterPlan } = useWorkspaceUtilityHook();
  const plan = isStarterPlan ? "Starter plan" : "lifetime";
  const sendReviewToSlack = async () => {
    // send message to slack
    if (message.length === 0 || !message) {
      toast.error("Please enter a message first.");
      return;
    }
    setLoading(true);
    await new UserService()
      .AIChatReviewSubmit(user?.id, active, message)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Message sent successfully.");
        }
        setSubmitted(true);
      })
      .catch((err) => {
        toast.error("Something went wrong.");
      });
    setLoading(false);
  };

  const theme = useGlobalMantineTheme();

  if (opened) {
    return (
      <>
        <Container
          size="md"
          mb={"xl"}
          sx={{
            alignItems: "center",
            flexDirection: "column"
          }}>
          <Stack align="center">
            {submitted ? (
              <>
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    margin: "0 auto"
                  }}>
                  <FontAwesomeIcon icon={regular("check-circle")} size="5x" color="#4dc74d" />
                </Box>

                <Title order={3} ta="center" mb={8} px={32}>
                  Your review has been submitted successfully. Thank you for your support.
                </Title>
              </>
            ) : (
              <>
                <Box>
                  <video
                    style={{
                      borderRadius: "0.5rem"
                    }}
                    height={503.5}
                    width={800}
                    controls
                    autoPlay
                    src={
                      AIAssistantVideo
                      // theme.colorScheme === "dark" ? AIAssistantVideo : AIAssistantVideoLightMode
                    }
                  />
                </Box>

                <Title
                  order={3}
                  ta="center"
                  mb={16}
                  mt={16}
                  sx={{
                    display: "Flex"
                  }}>
                  Maven AI is a new tool that we have added recently and we are giving it away for
                  FREE to our {plan} users.
                </Title>
                <Box
                  mb={16}
                  sx={{
                    width: "100%"
                  }}>
                  <Text ta="center" size="md">
                    But we need a small favor from you to UNLOCK this tool for you.
                  </Text>
                </Box>

                <Box
                  sx={{
                    width: "100%"
                  }}>
                  <Title
                    variant="gradient"
                    gradient={{ from: "brand", to: "pink", deg: 45 }}
                    align="center"
                    mb={16}
                    order={3}>
                    Step 1
                  </Title>
                  <Text ta="center" size="md">
                    Please write a few words about Usermaven on any of the below mentioned website.
                  </Text>
                  <Grid mb={16} mt={16} gutter={32}>
                    <Grid.Col span={6}>
                      <Flex
                        onClick={() => {
                          setActive("capterra");
                        }}
                        justify={"center"}
                        align="center"
                        direction="column"
                        sx={() => {
                          return {
                            transition: "all 0.2s ease",
                            border: `1px solid ${
                              theme.colorScheme === "light"
                                ? theme.colors.lightBorderColor[0]
                                : theme.colors.darkBorderColor[0]
                            }`,
                            borderColor: active === "capterra" ? theme.colors.brand[5] : "",
                            padding: "1rem",
                            borderRadius: "0.5rem",
                            cursor: "pointer",
                            "&:hover": {
                              borderColor: theme.colors.brand[5]
                            }
                          };
                        }}>
                        <Image src={CapterraLogo} width={124.77} height={30} mb={16} />
                        <a
                          className="primary-link-color"
                          href="https://www.capterra.com/p/276941/Usermaven/"
                          target="_blank">
                          Write a review on Capterra
                        </a>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Flex
                        justify={"center"}
                        align="center"
                        direction="column"
                        onClick={() => {
                          setActive("g2");
                        }}
                        sx={() => {
                          return {
                            transition: "all 0.2s ease",
                            border: `1px solid ${
                              theme.colorScheme === "light"
                                ? theme.colors.lightBorderColor[0]
                                : theme.colors.darkBorderColor[0]
                            }`,
                            borderColor: active === "g2" ? theme.colors.brand[5] : "",
                            padding: "1rem",
                            borderRadius: "0.5rem",
                            cursor: "pointer",
                            "&:hover": {
                              borderColor: theme.colors.brand[5]
                            }
                          };
                        }}>
                        <Image src={G2Logo} width={32} height={32} mb={16} />
                        <a
                          className="primary-link-color"
                          href="https://www.g2.com/products/usermaven/reviews"
                          target="_blank">
                          Write a review on G2
                        </a>
                      </Flex>
                    </Grid.Col>
                  </Grid>
                </Box>

                <Box
                  sx={{
                    width: "100%"
                  }}>
                  <Title
                    ta="center"
                    mb={16}
                    order={3}
                    variant="gradient"
                    gradient={{ from: "brand", to: "pink", deg: 45 }}>
                    Step 2
                  </Title>
                  <Text ta="center" size="md"  mb={16}>
                    Once you have submitted your review, please send us a message below with a proof
                    to verify that you’ve actually submitted the review.
                  </Text>
                  <Text ta="center" size="sm" mb={16}>
                    Add a link to the review post that you’ve submitted and/or send us a screenshot
                    or a video proof using loom.com/screencast etc.
                  </Text>
                  <Textarea
                    value={message}
                    onChange={(event) => setMessage(event.currentTarget.value)}
                    minRows={4}
                    placeholder="Your link to the review or proof message here."></Textarea>
                  <Center mt={16}>
                    <Button
                      loading={loading}
                      disabled={loading || message.length === 0}
                      onClick={sendReviewToSlack}>
                      Submit
                    </Button>
                  </Center>
                </Box>
              </>
            )}
          </Stack>
        </Container>
      </>
    );
  } else {
    return null;
  }
};
