import axios from "./JWTService";

export class LockedInsightService {
  create = (insight_id: any, payload: { is_locked: boolean }): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/locked_insight/${insight_id}`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (locked_insight_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/v1/locked_insight/${locked_insight_id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
