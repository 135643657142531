import { Box, Flex } from "@mantine/core";
import SidePane from "@/ui/pages/MavenAi/components/SidePane/SidePane";
import Assistant from "@/ui/pages/MavenAi/components/Assistant/Assistant";
import LockPage from "@/ui/pages/MavenAi/components/Assistant/LockPage";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { useEffect } from "react";

const MavenAi = () => {
  // Check if the plan is starter or not.
  const isStarter = useWorkspaceUtilityHook().isStarterPlan;

  useEffect(() => {
    document.title = "Maven AI | Usermaven";
  }, []);

  if (isStarter) {
    return <LockPage />;
  }

  return (
    <Box style={{
      margin: `calc(-1 *  var(--mantine-spacing-lg))`,
    }}>
      <Flex>
        <SidePane />
        <Assistant />
      </Flex>
    </Box>
  );
};

export default MavenAi;
