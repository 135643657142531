import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { MOBILE_BREAKPOINT_QUERY, PLAN_TYPES } from "@/lib/utils/Constants";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mantine/hooks";

interface ILockButton {
  /**
   * Callback function to be called when the export button is clicked
   */
  onClick: () => void;

  /**
   * Specify the export button label
   */
  label?: string;

  /**
   * Loading state of the export button
   */
  loading?: boolean;
  /**
   * Disable state of the export button
   */
  disabled?: boolean;
  /**
   * Plan name to show in the toast message
   */
  planName?: string;
  /**
   * Error message to show in the toast message
   */
  errorMessage?: string;
}
export const LockButton = ({
  onClick,
  label = "Button",
  loading = false,
  disabled = false,
  planName = PLAN_TYPES.STARTER,
  errorMessage = "Please upgrade your plan to Premium to create segments."
}: ILockButton) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  const onClickHandler = () => {
    if (activeWorkspace?.organization?.plan?.name.includes(planName)) {
      toast.error(errorMessage);
    } else {
      onClick();
    }
  };
  return (
    <>
      <Button
        // variant="gradient"
        // disabled={activeWorkspace?.organization?.plan.name === planName}
        rightIcon={
          activeWorkspace?.organization?.plan?.name.includes(planName) ? (
            <FontAwesomeIcon icon={regular("lock")} />
          ) : undefined
        }
        onClick={onClickHandler}
        size={isMobileView ? "xs" : "sm"}>
        {label}
      </Button>
    </>
  );
};
