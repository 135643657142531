import { NoResults } from "../../NoResults/NoResults";

export const NoResultsDetailedView = ({ colspan = 2 }: { colspan?: number }) => {
  return (
    <>
      <tr>
        <td colSpan={colspan} className="text-center mx-auto w-full">
          <NoResults
            text={"No results for your searched query."}
            heading={"No results"}></NoResults>
        </td>
      </tr>
    </>
  );
};
