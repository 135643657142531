import { light, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Anchor,
  Box,
  Button,
  Flex,
  Group,
  Loader,
  Text,
  useMantineTheme
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import useWorkspaceUtilityHook from "../../../hooks/useWorkspaceUtilityHook";
import { LOADER_COLOR, WORKSPACE_MEMBER_ROLES } from "../../../lib/utils/Constants";
import {
  HeaderNavigationContainer,
  HeaderNavigationH1
} from "../../components/App/HeaderNavigation/HeaderNavigation.style";
import { Link } from "react-router-dom";
import { AttributionUpgradeModal } from "./components/Attribution/AttributionUpgradeModal";
import { ConversionGoalModal } from "@/ui/components/App/WebAnalytics/ConverisonGoals/ConversionGoalModal";
import { useConversionGoalsStore } from "@/stores/useConversionGoalsStore";
import { LoadingStateProps } from "@/types/types.d";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { InsightsService } from "@/lib/services/InsightsService";
import { WorkspaceMiscService } from "@/lib/services/WorkspaceMiscService";
import { AttributionDetails } from "./components/Attribution/AttributionDetails";
import { Header } from "@/ui/components/Common/Header/Header";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const Attribution = () => {
  const { hasRolesNotWith } = useWorkspaceUtilityHook();
  const theme = useGlobalMantineTheme();
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);

  // Attribution insights state
  // Not using the useInsightsListHook here as that automatically fetches the insights on load
  const [attributionInsights, setAttributionInsights] = useState<any[]>([]);

  // Conversion goals state
  const [goals, setGoals] = useConversionGoalsStore((state) => [state.list, state.setList]);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");

  // Upgrade attribution modal
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  // Create conversion goal modal
  const [isConversionGoalModalOpen, setIsConversionGoalModalOpen] = useState(false);

  const fetchConversionGoals = async () => {
    setLoading("loading");
    await new WebAnalyticsService()
      .goals(activeWorkspace.id, "")
      .then((res) => {
        if (res.data.data.length > 0) {
          setGoals(res.data.data);
        } else {
          setGoals([]);
        }
      })
      .catch((err) => {
        setGoals([]);
      });
    setLoading("loaded");
  };

  /**
   * Fetch attribution reports. This is only called when the user has not upgraded their attribution
   */
  const fetchAttributionReports = async () => {
    await new InsightsService()
      .getAll(activeWorkspace.id, "name:asc", 1, "", "acquisition", 100)
      .then((res) => {
        setAttributionInsights(res.data);
        // If attribution insights are available, show the upgrade modal
        if (res.data.length > 0) {
          setIsUpgradeModalOpen(true);
        } else {
          // if user has not upgraded attribution and does not have attribution reports either
          // upgrade workspace level check automatically
          new WorkspaceMiscService()
            .attributionUpgraded(activeWorkspace.id, true)
            .then((res) => {
              if (res.data) {
                activeWorkspace.is_attribution_upgraded = res.data.data;
                setActiveWorkspace(activeWorkspace);
              }
            })
            .catch((error) => {});
        }
      })
      .catch((err) => {});
  };

  // Main effect
  useEffect(() => {
    document.title = "Attribution | Usermaven";

    // if attribution has not been upgraded, check if the user has any attribution insights
    if (!activeWorkspace.is_attribution_upgraded) {
      fetchAttributionReports();
    } else {
      // if attribution has been upgraded, fetch conversion goals
      fetchConversionGoals();
    }
  }, []);

  // When attribution is upgraded, fetch the conversion goals
  useEffect(() => {
    fetchConversionGoals();
  }, [activeWorkspace.is_attribution_upgraded]);

  // When conversion goal modal is closed, fetch the conversion goals
  useEffect(() => {
    if (!isConversionGoalModalOpen) {
      fetchConversionGoals();
    }
  }, [isConversionGoalModalOpen]);

  return (
    <>
      {!activeWorkspace.is_attribution_upgraded && attributionInsights.length > 0 && (
        <AttributionUpgradeModal open={isUpgradeModalOpen} setModalOpen={setIsUpgradeModalOpen} />
      )}

      <ConversionGoalModal
        isOpen={isConversionGoalModalOpen}
        onClose={() => setIsConversionGoalModalOpen(false)}
        isEdit={false}
        reload={() => {}} // ???
      />

      <Box pos="relative">
        <Header
          title="Attribution Analysis"
          rightSection={
            <Flex align={"center"}>
              {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                <>
                  <Button
                    mr={"sm"}
                    size="sm"
                    variant="outline"
                    onClick={() => setIsConversionGoalModalOpen(true)}
                    data-cy="create-conversion-goal-btn">
                    Create Conversion Goal
                  </Button>
                </>
              )}
              <Button
                component={Link}
                to={`/env/${activeWorkspace.identifier}/settings/channel_mapping`}
                variant="light"
                size={"sm"}>
                Custom Channel Mapping
              </Button>
            </Flex>
          }></Header>
        {loading === "loading" ? (
          <Flex justify={"center"} align={"center"} mx="auto" pt="4rem">
            <Loader size="xs" />
          </Flex>
        ) : goals.length > 0 && loading === "loaded" ? (
          <AttributionDetails />
        ) : (
          <>
            <Flex justify={"center"} align={"center"} direction={"column"} h="20rem">
              <FontAwesomeIcon
                icon={light("bullseye-arrow")}
                size="2xl"
                style={{ marginTop: "3rem", marginBottom: "1rem" }}
                color={theme.colors.gray[5]}
              />

              <Text mb="sm">You do not have any conversion goals</Text>

              {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
                <>
                  <Button
                    size="xs"
                    variant="outline"
                    onClick={() => setIsConversionGoalModalOpen(true)}
                    data-cy="create-conversion-goal-btn">
                    Create Conversion Goal
                  </Button>
                </>
              )}
              <Anchor href="https://usermaven.com/docs/attribution" target="_blank" mt="md">
                Learn more about Attribution.
              </Anchor>
            </Flex>
          </>
        )}
      </Box>
    </>
  );
};

export default Attribution;
