"use client";
import useAttributionHook from "@/hooks/useAttributionHook";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { DATE_FORMAT, DATE_RANGES } from "@/lib/utils/Constants";
import { getReportingPeriodRange } from "@/lib/utils/ReportingPeriodUtility";
import { LS_ATTRIBUTION_PREFERENCES } from "@/lib/utils/Storage";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { useConversionGoalsStore } from "@/stores/useConversionGoalsStore";
import { useWebFiltersStore } from "@/stores/useWebFiltersStore";
import { DateDropdownMenu } from "@/ui/components/App/Dropdowns/DateDropdownMenu";
import { InsightSection } from "@/ui/components/Common/InsightSection/InsightSection";
import {
  InsightsDashboardHeader,
  InsightsDashboardHeaderLeft,
  InsightsDashboardHeaderRight
} from "@/ui/pages/InsightsDashboard/Components/InsightsDashboardStyle/InsightsDashboardStyle.style";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Anchor,
  Box,
  Flex,
  Group,
  Loader,
  Select,
  Text,
  Tooltip,
  darken,
  rgba,
  useMantineColorScheme
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { DataTable } from "mantine-datatable";
import { useContext, useEffect, useState } from "react";
import classes from "./AttributionDetails.module.css";
import { AttributionModelCombobox } from "./AttributionModelCombobox";
import ConversionsChart from "./ConversionChart";
import { ATTRIBUTION_MODELS } from "@/lib/utils/Constants";
export type AttributionPreferences = {
  insightId: string;
  lookbackWindow: number;
  attributionModel: string;
};

const LOOKBACK_RANGES = [
  {
    label: "Last 30 days",
    value: "Last 30 days",
    daysinwindow: 30
  },
  {
    label: "Last 60 days",
    value: "Last 60 days",
    daysinwindow: 60
  },
  {
    label: "Last 90 days",
    value: "Last 90 days",
    daysinwindow: 90
  },
  {
    label: "Last 180 days",
    value: "Last 180 days",
    daysinwindow: 180
  }
];

const ChannelText = ({
  id,
  session_channel,
  expandedRecordIds
}: {
  id: any;
  session_channel: string;
  expandedRecordIds: string[];
}) => {
  const theme = useGlobalMantineTheme();
  return (
    <>
      <Group gap={"4px"} align={"center"} h={"100%"} p={"xs"}>
        <FontAwesomeIcon
          icon={solid("angle-right")}
          color={theme.colors.grape[5]}
          style={{
            marginRight: "0.5rem",
            transform: expandedRecordIds.includes(id) ? "rotate(90deg)" : "",
            transitionProperty: "all",
            transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
            transitionDuration: "150ms"
          }}></FontAwesomeIcon>
        <Text tt="capitalize" fw={500}>
          {session_channel}
        </Text>
      </Group>
    </>
  );
};

const VisitorsText = ({ value }: { value: number }) => {
  return (
    <>
      <Box p={"xs"}>
        <Text fw={500}>{numberToCommas(value)}</Text>
      </Box>
    </>
  );
};

const AttributedConversions = ({
  value,
  percentage,
  color = ""
}: {
  value: number;
  percentage: number;
  color?: string;
}) => {
  const theme = useGlobalMantineTheme();
  if (!color) {
    color = theme.colors.brand[1];
  }
  return (
    <>
      <Flex
        p={"xs"}
        justify="space-between"
        align="center"
        pos="relative"
        style={{
          width: "100%",
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[0],
          paddingLeft: "10px",
          paddingRight: "10px"
        }}>
        <Box
          pos="absolute"
          left={0}
          top={0}
          bottom={0}
          c={"#fff"}
          style={{
            width: `${percentage}%`,
            backgroundColor: theme.colorScheme === "dark" ? darken(color, 0.4) : color,
            zIndex: 0
          }}
        />
        <Text fw={500} fz={13} style={{ zIndex: 1 }}>
          {value}{" "}
        </Text>
        <Text
          span
          fw={400}
          fz={"xs"}
          style={{
            zIndex: 1000
          }}>
          ({percentage}%)
        </Text>
      </Flex>
    </>
  );
};

const AttributedConversionRate = ({ value }: { value: number }) => {
  return (
    <>
      <Flex justify={"center"} align={"center"} p={"xs"}>
        <Text span fw={600} fz={"xs"}>
          {value}%
        </Text>
      </Flex>
    </>
  );
};

const AttributedConversionValue = ({ value }: { value: number }) => {
  return (
    <>
      <Flex justify={"center"} align={"center"} p={"xs"}>
        <Text span fw={600} fz={"xs"} c="green.7">
          ${value}
        </Text>
      </Flex>
    </>
  );
};

export const AttributionDetails = () => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // conversion goals list, used to populate the dropdown
  const [list, setList] = useConversionGoalsStore((state) => [state.list, state.setList]);

  // manage the default preferences for the attribution model
  const [defaultPreferences, setDefaultPreferences] = useLocalStorage<AttributionPreferences>({
    key: LS_ATTRIBUTION_PREFERENCES,
    defaultValue: {
      // TODO: this fetches the attribution twice, once with the first item in the list
      // and then when the value is fetched from local storage, it fetches again
      insightId: list.length > 0 ? list[0].id : "",
      lookbackWindow: 90,
      attributionModel: "first_touch"
    }
  });

  // hook to fetch the attribution data
  // NOTE: because our drilled attribution query calculates attribution for all channels together,
  // we are fetching the drilled report only once here and then filtering it for each channel in the AttributionItem component
  const {
    isAggregationLoading,
    aggregationData,
    fetchAttributionAggregated,
    isDrilledReportLoading,
    fetchDrilledAttributionReport,
    drilledReport,
    setDrilledReport
  } = useAttributionHook();

  // local states for the date range filter
  const [dropdownFilterDate, setDropdownFilterDate] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT)
  });
  const [setDateLabel] = useWebFiltersStore((state) => [state.setDateLabel]);

  // the attribution models for which we need to show details
  const [visibleModels, setVisibleModels] = useState<string[]>([
    defaultPreferences.attributionModel
  ]);

  // number of columns to show based on the attribution models
  const [numColumns, setNumColumns] = useState<number>(8);

  /**
   * Functions to handle the attribution API parameter changes
   */
  const handleDateChange = (startDate: string, endDate: string, label: string) => {
    // if (!item) return;
    // const { startDate, endDate, label } = item;
    setDropdownFilterDate({ startDate, endDate });
    setDefaultPreferences((current) => ({
      ...current,
      conversionPeriod: DATE_RANGES.find((item) => item.label === label)?.label || "Last 30 days"
    }));
    fetchAttributionAggregated(
      defaultPreferences.insightId,
      startDate,
      endDate,
      defaultPreferences.lookbackWindow
    );
    // empty the drilled report when the date range changes
    // setDrilledReport([]);
    fetchDrilledAttributionReport(
      defaultPreferences.insightId,
      startDate,
      endDate,
      defaultPreferences.lookbackWindow
    );
  };

  const handleChangeLookbackWindow = (value: string) => {
    const daysinwindow = LOOKBACK_RANGES.find((item) => item.value === value)?.daysinwindow;
    if (daysinwindow) {
      setDefaultPreferences((current) => ({
        ...current,
        lookbackWindow: daysinwindow
      }));
    }
    fetchAttributionAggregated(
      defaultPreferences.insightId,
      dropdownFilterDate.startDate,
      dropdownFilterDate.endDate,
      daysinwindow
    );
    // empty the drilled report when the lookback window changes
    // setDrilledReport([]);
    fetchDrilledAttributionReport(
      defaultPreferences.insightId,
      dropdownFilterDate.startDate,
      dropdownFilterDate.endDate,
      daysinwindow
    );
  };

  const handleChangeAttributionModel = (value: string) => {
    setDefaultPreferences((current) => ({
      ...current,
      attributionModel: value
    }));
    fetchAttributionAggregated(
      defaultPreferences.insightId,
      dropdownFilterDate.startDate,
      dropdownFilterDate.endDate,
      defaultPreferences.lookbackWindow
    );
    // empty the drilled report when the attribution model changes
    // setDrilledReport([]);
    fetchDrilledAttributionReport(
      defaultPreferences.insightId,
      dropdownFilterDate.startDate,
      dropdownFilterDate.endDate,
      defaultPreferences.lookbackWindow
    );
  };

  const handleChangeConversionGoal = (item: string) => {
    setDefaultPreferences((current) => ({
      ...current,
      insightId: item
    }));
  };

  const handleAddNewModel = (newModel: string) => {
    setVisibleModels((current) => [...current, newModel]);
    setNumColumns(numColumns + 2);
  };

  // Fetch conversion goals as we do not fetch these in the store
  const fetchConversionGoals = async () => {
    await new WebAnalyticsService()
      .goals(activeWorkspace.id, "")
      .then((res) => {
        if (res.data.data.length > 0) {
          setList(res.data.data);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        setList([]);
      });
  };

  useEffect(() => {
    fetchConversionGoals();
    const getPeriod = getReportingPeriodRange(activeWorkspace.reporting_period);
    const startDate =
      getPeriod?.startDate || format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT);
    const endDate = getPeriod?.endDate || format(endOfDay(new Date()), DATE_FORMAT);

    setDateLabel(getPeriod?.label || "Last 30 Days");
    setDropdownFilterDate({
      startDate: startDate,
      endDate: endDate
    });
    if (defaultPreferences.insightId) {
      fetchAttributionAggregated(
        defaultPreferences.insightId,
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate,
        defaultPreferences.lookbackWindow
      );
      // empty the drilled report when the insight id changes
      // setDrilledReport([]);
      fetchDrilledAttributionReport(
        defaultPreferences.insightId,
        dropdownFilterDate.startDate,
        dropdownFilterDate.endDate,
        defaultPreferences.lookbackWindow
      );
    }
  }, [defaultPreferences.insightId]);

  const ChannelDataTable = () => {
    const [expandedRecordIds, setExpandedRecordIds] = useState<string[]>([]);
    return (
      <>
        <DataTable
          withTableBorder
          withRowBorders
          withColumnBorders
          highlightOnHover
          borderColor={"#242424"}
          className={classes.dataTable}
          rowClassName={classes.dataTableRow}
          columns={[
            {
              accessor: "session_channel",
              title: "Channel / Source",
              noWrap: true,
              width: 300,
              render: ({ id, session_channel }) => (
                <>
                  <ChannelText
                    id={id}
                    expandedRecordIds={expandedRecordIds}
                    session_channel={session_channel}
                  />
                </>
              )
            },
            {
              accessor: "influenced_visitors",
              title: "Influenced Visitors",
              textAlign: "right",
              width: 150,
              render: ({ influenced_visitors }) => (
                <>
                  <VisitorsText value={influenced_visitors} />
                </>
              )
            },
            {
              accessor: "influenced_conversions",
              title: "Influenced Conversions",
              textAlign: "right",
              width: 200,
              render: ({ influenced_conversions }) => (
                <>
                  <Box p={"xs"}>
                    <Text fw={500}>{numberToCommas(influenced_conversions)}</Text>
                  </Box>
                </>
              )
            },
            {
              accessor: "influenced_conversion_value",
              title: "Influenced Value",
              textAlign: "center",
              width: 150,
              render: ({ influenced_conversion_value }) => (
                <Box p={"xs"}>
                  <Text fw={500}>{influenced_conversion_value}</Text>
                </Box>
              )
            },
            // @ts-ignore
            ...(visibleModels.includes(ATTRIBUTION_MODELS.LAST_TOUCH)
              ? [
                  {
                    accessor: "att_conversions_lasttouch",
                    title: "Last Touch Conversions",
                    textAlign: "right",
                    render: ({
                      id,
                      att_conversions_lasttouch,
                      att_conversions_lasttouch_percentage,
                      att_conv_value_lasttouch
                    }) => (
                      <>
                        <AttributedConversions
                          value={att_conversions_lasttouch}
                          percentage={att_conversions_lasttouch_percentage}
                          color={theme.colors.pink[2]}
                        />
                      </>
                    ),
                    width: 200
                  },
                  {
                    accessor: "conversion_rate_lasttouch",
                    title: "Last Touch Conv. Rate",
                    textAlign: "right",
                    render: ({ conversion_rate_lasttouch }) => (
                      <>
                        <AttributedConversionRate value={conversion_rate_lasttouch} />
                      </>
                    ),
                    width: 180
                  },
                  {
                    accessor: "att_conv_value_lasttouch",
                    title: "Last Touch Conv. Value",
                    textAlign: "right",
                    render: ({ att_conv_value_lasttouch }) => (
                      <>
                        <AttributedConversionValue value={att_conv_value_lasttouch} />
                      </>
                    ),
                    width: 200
                  }
                ]
              : []),

            // Check if 'first_touch' is in the visibleModels array
            // @ts-ignore
            ...(visibleModels.includes(ATTRIBUTION_MODELS.FIRST_TOUCH)
              ? [
                  {
                    accessor: "att_conversions_firsttouch",
                    title: "First Touch Conversions",
                    textAlign: "right",
                    render: ({
                      id,
                      att_conversions_firsttouch,
                      att_conversions_firsttouch_percentage,
                      att_conv_value_firsttouch
                    }) => (
                      <>
                        <AttributedConversions
                          value={att_conversions_firsttouch}
                          percentage={att_conversions_firsttouch_percentage}
                          color={theme.colors.brand[2]}
                        />
                      </>
                    ),
                    width: 200
                  },
                  {
                    accessor: "att_conversions_firsttouch",
                    title: "First Touch Conv. Rate",
                    textAlign: "right",
                    render: ({ conversion_rate_firsttouch }) => (
                      <>
                        <AttributedConversionRate value={conversion_rate_firsttouch} />
                      </>
                    ),
                    width: 180
                  },
                  {
                    accessor: "att_conv_value_firsttouch",
                    title: "First Touch Conv. Value",
                    textAlign: "right",
                    render: ({ att_conv_value_firsttouch }) => (
                      <>
                        <AttributedConversionValue value={att_conv_value_firsttouch} />
                      </>
                    ),
                    width: 200
                  }
                ]
              : []),
            // Check if 'first_touch' is in the visibleModels array
            // @ts-ignore
            ...(visibleModels.includes(ATTRIBUTION_MODELS.LINEAR)
              ? [
                  {
                    accessor: "att_conversions_linear",
                    title: "Linear Conversions",
                    textAlign: "right",
                    render: ({
                      id,
                      att_conversions_linear,
                      att_conversions_linear_percentage,
                      att_conv_value_linear
                    }) => (
                      <>
                        <AttributedConversions
                          value={att_conversions_linear}
                          percentage={att_conversions_linear_percentage}
                          color={theme.colors.green[2]}
                        />
                      </>
                    ),
                    width: 200
                  },
                  {
                    accessor: "att_conversions_linear",
                    title: "First Touch Conv. Rate",
                    textAlign: "right",
                    render: ({ conversion_rate_linear }) => (
                      <>
                        <AttributedConversionRate value={conversion_rate_linear} />
                      </>
                    ),
                    width: 180
                  },
                  {
                    accessor: "att_conv_value_linear",
                    title: "First Touch Conv. Value",
                    textAlign: "right",
                    render: ({ att_conv_value_linear }) => (
                      <>
                        <AttributedConversionValue value={att_conv_value_linear} />
                      </>
                    ),
                    width: 200
                  }
                ]
              : []),
            // Check if 'first_touch' is in the visibleModels array
            // @ts-ignore
            ...(visibleModels.includes(ATTRIBUTION_MODELS.U_SHAPED)
              ? [
                  {
                    accessor: "att_conversions_position",
                    title: "U-Shaped Conversions",
                    textAlign: "right",
                    render: ({
                      id,
                      att_conversions_position,
                      att_conversions_position_percentage,
                      att_conv_value_position
                    }) => (
                      <>
                        <AttributedConversions
                          value={att_conversions_position}
                          percentage={att_conversions_position_percentage}
                          color={theme.colors.red[2]}
                        />
                      </>
                    ),
                    width: 200
                  },
                  {
                    accessor: "att_conversions_position",
                    title: "U-Shaped Conv. Rate",
                    textAlign: "right",
                    render: ({ conversion_rate_position }) => (
                      <>
                        <AttributedConversionRate value={conversion_rate_position} />
                      </>
                    ),
                    width: 180
                  },
                  {
                    accessor: "att_conv_value_position",
                    title: "U-Shaped Conv. Value",
                    textAlign: "right",
                    render: ({ att_conv_value_position }) => (
                      <>
                        <AttributedConversionValue value={att_conv_value_position} />
                      </>
                    ),
                    width: 200
                  }
                ]
              : []),
            // Check if 'first_touch' is in the visibleModels array
            // @ts-ignore
            ...(visibleModels.includes(ATTRIBUTION_MODELS.TIME_DECAY)
              ? [
                  {
                    accessor: "att_conversions_timedecay",
                    title: "Time Decay Conversions",
                    textAlign: "right",
                    render: ({
                      id,
                      att_conversions_timedecay,
                      att_conversions_timedecay_percentage,
                      att_conv_value_timedecay
                    }) => (
                      <>
                        <AttributedConversions
                          value={att_conversions_timedecay}
                          percentage={att_conversions_timedecay_percentage}
                          color={theme.colors.orange[2]}
                        />
                      </>
                    ),
                    width: 200
                  },
                  {
                    accessor: "att_conversions_timedecay",
                    title: "Time Decay Conv. Rate",
                    textAlign: "right",
                    render: ({ conversion_rate_timedecay }) => (
                      <>
                        <AttributedConversionRate value={conversion_rate_timedecay} />
                      </>
                    ),
                    width: 180
                  },
                  {
                    accessor: "att_conv_value_timedecay",
                    title: "Time Decay Conv. Value",
                    textAlign: "right",
                    render: ({ att_conv_value_timedecay }) => (
                      <>
                        <AttributedConversionValue value={att_conv_value_timedecay} />
                      </>
                    ),
                    width: 200
                  }
                ]
              : []),
            // Check if 'first_touch' is in the visibleModels array
            // @ts-ignore
            ...(visibleModels.includes(ATTRIBUTION_MODELS.FIRST_TOUCH_NON_DIRECT)
              ? [
                  {
                    accessor: "att_conversions_firsttouchnondirect",
                    title: "First Touch Non Direct Conversions",
                    textAlign: "right",
                    render: ({
                      id,
                      att_conversions_firsttouchnondirect,
                      att_conversions_firsttouchnondirect_percentage,
                      att_conv_value_firsttouchnondirect
                    }) => (
                      <>
                        <AttributedConversions
                          value={att_conversions_firsttouchnondirect}
                          percentage={att_conversions_firsttouchnondirect_percentage}
                          color={theme.colors.yellow[2]}
                        />
                      </>
                    ),
                    width: 200
                  },
                  {
                    accessor: "att_conversions_firsttouchnondirect",
                    title: "First Touch Non Direct Conv. Rate",
                    textAlign: "right",
                    render: ({ conversion_rate_firsttouchnondirect }) => (
                      <>
                        <AttributedConversionRate value={conversion_rate_firsttouchnondirect} />
                      </>
                    ),
                    width: 180
                  },
                  {
                    accessor: "att_conv_value_firsttouchnondirect",
                    title: "First Touch Non Direct Conv. Value",
                    textAlign: "right",
                    render: ({ att_conv_value_firsttouchnondirect }) => (
                      <>
                        <AttributedConversionValue value={att_conv_value_firsttouchnondirect} />
                      </>
                    ),
                    width: 200
                  }
                ]
              : []),
            // Check if 'first_touch' is in the visibleModels array
            // @ts-ignore
            ...(visibleModels.includes(ATTRIBUTION_MODELS.LAST_TOUCH_NON_DIRECT)
              ? [
                  {
                    accessor: "att_conversions_lasttouchnondirect",
                    title: "Last Touch Non Direct Conversions",
                    textAlign: "right",
                    render: ({
                      id,
                      att_conversions_lasttouchnondirect,
                      att_conversions_lasttouchnondirect_percentage,
                      att_conv_value_lasttouchnondirect
                    }) => (
                      <>
                        <AttributedConversions
                          value={att_conversions_lasttouchnondirect}
                          percentage={att_conversions_lasttouchnondirect_percentage}
                          color={theme.colors.grape[2]}
                        />
                      </>
                    ),
                    width: 200
                  },
                  {
                    accessor: "att_conversions_lasttouchnondirect",
                    title: "Last Touch Non Direct Conv. Rate",
                    textAlign: "right",
                    render: ({ conversion_rate_lasttouchnondirect }) => (
                      <>
                        <AttributedConversionRate value={conversion_rate_lasttouchnondirect} />
                      </>
                    ),
                    width: 180
                  },
                  {
                    accessor: "att_conv_value_lasttouchnondirect",
                    title: "Last Touch Non Direct Conv. Value",
                    textAlign: "right",
                    render: ({ att_conv_value_lasttouchnondirect }) => (
                      <>
                        <AttributedConversionValue value={att_conv_value_lasttouchnondirect} />
                      </>
                    ),
                    width: 200
                  }
                ]
              : [])
          ]}
          customLoader={<Loader py="sm" size="xs"></Loader>}
          fetching={isAggregationLoading === "loading"}
          records={aggregationData}
          rowExpansion={{
            allowMultiple: false,
            expanded: {
              recordIds: expandedRecordIds,
              onRecordIdsChange: setExpandedRecordIds
            },
            content: ({ record }) => (
              <>
                <SourceDataTable
                  channel={record?.session_channel}
                  insightId={defaultPreferences.insightId}
                  startDate={dropdownFilterDate.startDate}
                  endDate={dropdownFilterDate.endDate}
                  lookbackWindow={defaultPreferences.lookbackWindow}
                  visibleModels={visibleModels}
                  isDrilledReportLoading={isDrilledReportLoading}
                  fetchDrilledAttributionReport={fetchDrilledAttributionReport}
                  drilledReport={drilledReport.filter(
                    (drilledItem) => drilledItem.session_channel === record?.session_channel
                  )}
                />
              </>
            )
          }}
        />
      </>
    );
  };

  return (
    <>
      <InsightsDashboardHeader className="pb-4 mt-4 ">
        <InsightsDashboardHeaderLeft className="flex items-center">
          <Select
            mr={4}
            w={"14rem"}
            variant="filled"
            size="sm"
            label="Select conversion goal"
            labelProps={{ style: { fontSize: "1rem", paddingBottom: "0.25rem" } }}
            placeholder="Select insight"
            value={defaultPreferences.insightId}
            onChange={(item: any) => {
              handleChangeConversionGoal(item);
            }}
            data={list.map((item: any) => {
              return {
                label: item.name,
                value: item.id
              };
            })}></Select>
        </InsightsDashboardHeaderLeft>
        <InsightsDashboardHeaderRight>
          <AttributionModelCombobox value={visibleModels} setValue={setVisibleModels} />
          <Select
            // mr={"xs"}
            w={170}
            variant="filled"
            size="sm"
            value={`Last ${defaultPreferences.lookbackWindow} days`}
            // @ts-ignore
            onChange={(value: string) => {
              handleChangeLookbackWindow(value);
            }}
            label={
              <Text pl="0.25rem">
                Lookback window
                <Tooltip
                  multiline
                  position="bottom"
                  maw={300}
                  label="The timeframe prior to a conversion during which touchpoints are credited for influencing the conversion.">
                  <FontAwesomeIcon
                    style={{ paddingLeft: "0.25rem" }}
                    color={theme.colors.gray[6]}
                    icon={regular("info-circle")}
                  />
                </Tooltip>
              </Text>
            }
            labelProps={{ style: { fontSize: "1rem", paddingBottom: "0.25rem" } }}
            data={LOOKBACK_RANGES}
          />
          <Flex direction={"column"}>
            <Text pl="0.25rem" mb={4}>
              Conversion period
              <Tooltip
                multiline
                position="bottom"
                maw={300}
                label="Select the date range to analyze conversions. This filter determines the specific time frame during which conversions are tracked and attributed to your marketing efforts.">
                <FontAwesomeIcon
                  style={{ paddingLeft: "0.25rem" }}
                  color={theme.colors.gray[6]}
                  icon={regular("info-circle")}
                />
              </Tooltip>
            </Text>
            <DateDropdownMenu onChange={handleDateChange} />
          </Flex>
        </InsightsDashboardHeaderRight>
      </InsightsDashboardHeader>
      <InsightSection
        title={"Conversions by Channel and Attribution Model"}
        description="Compare how different marketing channels contribute to conversions using various attribution models. Each bar represents the number of conversions attributed to a channel based on the selected model.">
        <Box h={380}>
          {isAggregationLoading === "loading" ? (
            <Box h={"100%"} w={"100%"}>
              <Flex h={430} justify={"center"} align={"center"}>
                <Loader type="bars" size="sm" />
              </Flex>
            </Box>
          ) : (
            <>
              <ConversionsChart data={aggregationData} visibleModels={visibleModels} />
            </>
          )}
        </Box>
      </InsightSection>

      {/* {JSON.stringify(getSelectedAttributedModels())} */}

      <ChannelDataTable />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "full"
        }}>
        {isAggregationLoading === "loaded" && (
          <Box mt={"44px"} mb={"md"}>
            <Alert
              icon={<FontAwesomeIcon size={"lg"} icon={regular("bolt")} />}
              // color={theme.colorScheme === "dark" ? "dark.2" : "brand"}
              styles={{
                root: {
                  backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : ""
                }
              }}>
              Gain granular visibility into your traffic with Channel Mapping. Create custom
              channels using UTM parameters, first URL, and referrer data to align with your unique
              marketing initiatives. Tailor groupings to isolate campaigns, consolidate social
              media, or categorize traffic for your organization's needs.
              <Flex align={"center"} gap={"xs"} mt={"xs"}>
                <Text>
                  <FontAwesomeIcon icon={regular("arrow-right-long")} />
                </Text>

                <Anchor
                  href="https://usermaven.com/docs/grouping-traffic-sources-into-custom-channels"
                  target="_blank">
                  Learn about defining custom channels
                </Anchor>
              </Flex>
              <Flex align={"center"} gap={"xs"} mt={"2px"}>
                <Text>
                  <FontAwesomeIcon icon={regular("arrow-right-long")} />
                </Text>

                <Anchor
                  href="https://usermaven.com/docs/mapping-traffic-sources-into-default-channels"
                  target="_blank">
                  Explore the default channel mapping
                </Anchor>
              </Flex>
            </Alert>
          </Box>
        )}
      </Box>
    </>
  );
};

const SourceDataTable = ({
  channel,
  insightId,
  startDate,
  endDate,
  lookbackWindow,
  visibleModels,
  isDrilledReportLoading,
  fetchDrilledAttributionReport,
  drilledReport
}: {
  channel: string;
  insightId: string;
  startDate: string;
  endDate: string;
  lookbackWindow: number;
  visibleModels: string[];
  isDrilledReportLoading: string;
  fetchDrilledAttributionReport: any;
  drilledReport: any;
}) => {
  console.log("channel", channel);
  const theme = useGlobalMantineTheme();

  useEffect(() => {
    if (drilledReport.length === 0) {
      console.log("fetching drilled report");
      fetchDrilledAttributionReport(insightId, startDate, endDate, lookbackWindow);
    }
  }, [drilledReport, isDrilledReportLoading]);

  return (
    <>
      <DataTable
        noHeader
        withTableBorder
        withRowBorders
        withColumnBorders
        highlightOnHover
        className={classes.dataTable + " " + classes.subTable}
        columns={[
          {
            accessor: "session_source",
            title: "Channel / Source",
            noWrap: true,
            width: 300,
            render: ({ session_source }) => (
              <Box p={"xs"}>
                <Text tt="capitalize" fw={400} truncate="end">
                  {session_source}
                </Text>
              </Box>
            )
          },
          {
            accessor: "influenced_visitors",
            title: "Influenced Visitors",
            textAlign: "right",
            width: 150,
            render: ({ influenced_visitors }) => (
              <Box p={"xs"}>
                <Text fw={400}>{numberToCommas(influenced_visitors)}</Text>
              </Box>
            )
          },
          {
            accessor: "influenced_conversions",
            title: "Influenced Conversions",
            textAlign: "right",
            width: 200,
            render: ({ influenced_conversions }) => (
              <Box p={"xs"}>
                <Text fw={400}>{numberToCommas(influenced_conversions)}</Text>
              </Box>
            )
          },
          {
            accessor: "influenced_conversion_value",
            title: "Influenced Value",
            textAlign: "center",
            width: 150,
            render: ({ influenced_conversion_value }) => (
              <Box p={"xs"}>
                <Text fw={400}>{influenced_conversion_value}</Text>
              </Box>
            )
          },
          // @ts-ignore
          ...(visibleModels.includes(ATTRIBUTION_MODELS.LAST_TOUCH)
            ? [
                {
                  accessor: "att_conversions_lasttouch",
                  title: "Last Touch Conversions",
                  textAlign: "right",
                  render: ({
                    id,
                    att_conversions_lasttouch,
                    att_conversions_lasttouch_percentage,
                    att_conv_value_lasttouch
                  }) => (
                    <>
                      <AttributedConversions
                        value={att_conversions_lasttouch}
                        percentage={att_conversions_lasttouch_percentage}
                        color={theme.colors.pink[1]}
                      />
                    </>
                  ),
                  width: 200
                },
                {
                  accessor: "conversion_rate_lasttouch",
                  title: "Last Touch Conv. Rate",
                  textAlign: "right",
                  render: ({ conversion_rate_lasttouch }) => (
                    <>
                      <AttributedConversionRate value={conversion_rate_lasttouch} />
                    </>
                  ),
                  width: 180
                },
                {
                  accessor: "att_conv_value_lasttouch",
                  title: "Last Touch Conv. Value",
                  textAlign: "right",
                  render: ({ att_conv_value_lasttouch }) => (
                    <>
                      <AttributedConversionValue value={att_conv_value_lasttouch} />
                    </>
                  ),
                  width: 200
                }
              ]
            : []),
          // @ts-ignore
          ...(visibleModels.includes(ATTRIBUTION_MODELS.FIRST_TOUCH)
            ? [
                {
                  accessor: "att_conversions_firsttouch",
                  title: "First Touch Conversions",
                  textAlign: "right",
                  render: ({
                    id,
                    att_conversions_firsttouch,
                    att_conversions_firsttouch_percentage,
                    att_conv_value_firsttouch
                  }) => (
                    <>
                      <AttributedConversions
                        value={att_conversions_firsttouch}
                        percentage={att_conversions_firsttouch_percentage}
                        color={theme.colors.brand[1]}
                      />
                    </>
                  ),
                  width: 200
                },
                {
                  accessor: "conversion_rate_firsttouch",
                  title: "First Touch Conv. Rate",
                  textAlign: "right",
                  render: ({ conversion_rate_firsttouch }) => (
                    <>
                      <AttributedConversionRate value={conversion_rate_firsttouch} />
                    </>
                  ),
                  width: 180
                },
                {
                  accessor: "att_conv_value_firsttouch",
                  title: "First Touch Conv. Value",
                  textAlign: "right",
                  render: ({ att_conv_value_firsttouch }) => (
                    <>
                      <AttributedConversionValue value={att_conv_value_firsttouch} />
                    </>
                  ),
                  width: 200
                }
              ]
            : []),
          // @ts-ignore
          ...(visibleModels.includes(ATTRIBUTION_MODELS.LINEAR)
            ? [
                {
                  accessor: "att_conversions_linear",
                  title: "Linear Conversions",
                  textAlign: "right",
                  render: ({
                    id,
                    att_conversions_linear,
                    att_conversions_linear_percentage,
                    att_conv_value_linear
                  }) => (
                    <>
                      <AttributedConversions
                        value={att_conversions_linear}
                        percentage={att_conversions_linear_percentage}
                        color={theme.colors.green[1]}
                      />
                    </>
                  ),
                  width: 200
                },
                {
                  accessor: "conversion_rate_linear",
                  title: "Linear Conv. Rate",
                  textAlign: "right",
                  render: ({ conversion_rate_linear }) => (
                    <>
                      <AttributedConversionRate value={conversion_rate_linear} />
                    </>
                  ),
                  width: 180
                },
                {
                  accessor: "att_conv_value_linear",
                  title: "Linear Conv. Value",
                  textAlign: "right",
                  render: ({ att_conv_value_linear }) => (
                    <>
                      <AttributedConversionValue value={att_conv_value_linear} />
                    </>
                  ),
                  width: 200
                }
              ]
            : []),
          // @ts-ignore
          ...(visibleModels.includes(ATTRIBUTION_MODELS.U_SHAPED)
            ? [
                {
                  accessor: "att_conversions_position",
                  title: "U-Shaped Conversions",
                  textAlign: "right",
                  render: ({
                    id,
                    att_conversions_position,
                    att_conversions_position_percentage,
                    att_conv_value_position
                  }) => (
                    <>
                      <AttributedConversions
                        value={att_conversions_position}
                        percentage={att_conversions_position_percentage}
                        color={theme.colors.red[1]}
                      />
                    </>
                  ),
                  width: 200
                },
                {
                  accessor: "conversion_rate_position",
                  title: "U-Shaped Conv. Rate",
                  textAlign: "right",
                  render: ({ conversion_rate_position }) => (
                    <>
                      <AttributedConversionRate value={conversion_rate_position} />
                    </>
                  ),
                  width: 180
                },
                {
                  accessor: "att_conv_value_position",
                  title: "U-Shaped Conv. Value",
                  textAlign: "right",
                  render: ({ att_conv_value_position }) => (
                    <>
                      <AttributedConversionValue value={att_conv_value_position} />
                    </>
                  ),
                  width: 200
                }
              ]
            : []),

          // @ts-ignore
          ...(visibleModels.includes(ATTRIBUTION_MODELS.TIME_DECAY)
            ? [
                {
                  accessor: "att_conversions_timedecay",
                  title: "Time Decay Conversions",
                  textAlign: "right",
                  render: ({
                    id,
                    att_conversions_timedecay,
                    att_conversions_timedecay_percentage,
                    att_conv_value_timedecay
                  }) => (
                    <>
                      <AttributedConversions
                        value={att_conversions_timedecay}
                        percentage={att_conversions_timedecay_percentage}
                        color={theme.colors.orange[1]}
                      />
                    </>
                  ),
                  width: 200
                },
                {
                  accessor: "conversion_rate_timedecay",
                  title: "Time Decay Conv. Rate",
                  textAlign: "right",
                  render: ({ conversion_rate_timedecay }) => (
                    <>
                      <AttributedConversionRate value={conversion_rate_timedecay} />
                    </>
                  ),
                  width: 180
                },
                {
                  accessor: "att_conv_value_timedecay",
                  title: "Time Decay Conv. Value",
                  textAlign: "right",
                  render: ({ att_conv_value_timedecay }) => (
                    <>
                      <AttributedConversionValue value={att_conv_value_timedecay} />
                    </>
                  ),
                  width: 200
                }
              ]
            : []),
          // @ts-ignore
          ...(visibleModels.includes(ATTRIBUTION_MODELS.FIRST_TOUCH_NON_DIRECT)
            ? [
                {
                  accessor: "att_conversions_firsttouchnondirect",
                  title: "First Touch Non Direct Conversions",
                  textAlign: "right",
                  render: ({
                    id,
                    att_conversions_firsttouchnondirect,
                    att_conversions_firsttouchnondirect_percentage,
                    att_conv_value_firsttouchnondirect
                  }) => (
                    <>
                      <AttributedConversions
                        value={att_conversions_firsttouchnondirect}
                        percentage={att_conversions_firsttouchnondirect_percentage}
                        color={theme.colors.yellow[1]}
                      />
                    </>
                  ),
                  width: 200
                },
                {
                  accessor: "conversion_rate_firsttouchnondirect",
                  title: "First Touch Non Direct Conv. Rate",
                  textAlign: "right",
                  render: ({ conversion_rate_firsttouchnondirect }) => (
                    <>
                      <AttributedConversionRate value={conversion_rate_firsttouchnondirect} />
                    </>
                  ),
                  width: 180
                },
                {
                  accessor: "att_conv_value_firsttouchnondirect",
                  title: "First Touch Non Direct Conv. Value",
                  textAlign: "right",
                  render: ({ att_conv_value_firsttouchnondirect }) => (
                    <>
                      <AttributedConversionValue value={att_conv_value_firsttouchnondirect} />
                    </>
                  ),
                  width: 200
                }
              ]
            : []),
          // @ts-ignore
          ...(visibleModels.includes(ATTRIBUTION_MODELS.LAST_TOUCH_NON_DIRECT)
            ? [
                {
                  accessor: "att_conversions_lasttouchnondirect",
                  title: "First Touch Non Direct Conversions",
                  textAlign: "right",
                  render: ({
                    id,
                    att_conversions_lasttouchnondirect,
                    att_conversions_lasttouchnondirect_percentage,
                    att_conv_value_lasttouchnondirect
                  }) => (
                    <>
                      <AttributedConversions
                        value={att_conversions_lasttouchnondirect}
                        percentage={att_conversions_lasttouchnondirect_percentage}
                        color={theme.colors.grape[1]}
                      />
                    </>
                  ),
                  width: 200
                },
                {
                  accessor: "conversion_rate_lasttouchnondirect",
                  title: "First Touch Non Direct Conv. Rate",
                  textAlign: "right",
                  render: ({ conversion_rate_lasttouchnondirect }) => (
                    <>
                      <AttributedConversionRate value={conversion_rate_lasttouchnondirect} />
                    </>
                  ),
                  width: 180
                },
                {
                  accessor: "att_conv_value_lasttouchnondirect",
                  title: "First Touch Non Direct Conv. Value",
                  textAlign: "right",
                  render: ({ att_conv_value_lasttouchnondirect }) => (
                    <>
                      <AttributedConversionValue value={att_conv_value_lasttouchnondirect} />
                    </>
                  ),
                  width: 200
                }
              ]
            : [])
        ]}
        records={drilledReport}
        fetching={isDrilledReportLoading === "loading" || isDrilledReportLoading === "idle"}
      />
    </>
  );
};
