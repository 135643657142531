/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Box, Button, Divider, Flex, Modal, Select, Space, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { LooseObject } from "types/types.d";
import { FilterSearchSelect } from "./FilterSearchSelect";
import {
  FILTER_GROUPS,
  getAppliedFilterValue,
  getOperatorByValue,
  OPERATOR_ITEMS
} from "./FiltersUtility";

interface Props {
  opened: boolean;
  onClose: () => void;
  filterKey: string;
  query: any;
  setQuery: any;
}

export const FilterModal = ({ opened, onClose, filterKey, query, setQuery }: Props) => {
  const [formState, setFormState] = useState<any>({});
  /**
   * Function which creates a dynamic form based on the filter key. We create the form based on the FILTER_GROUPS.
   * @returns Dynamic form state based on the filter key
   */
  const getFormState = () => {
    if (!filterKey) return {};
    const obj: LooseObject = {};
    FILTER_GROUPS[filterKey].map((item: string) => {
      obj[item] = {
        operator: "",
        value: ""
      };
      query.filters.forEach((filter: any) => {
        // Split the filter by the filter key
        // Do not split the 'Source', 'Medium', 'Campaign' as they are part of the 'source' value.
        const splitValue = filter.split(/(?<!Source|Medium|Campaign):/g);

        if (splitValue[0] === item) {
          obj[item] = {
            operator: getOperatorByValue(filter),
            value: getAppliedFilterValue(filter, false)
          };
        }
      });
    });

    return obj;
  };

  /**
   * We allow support contains for the limited filters. Only page, entry_page and exit_page has this support.
   * @param item Filter group items.
   * @returns
   */
  const supportsContains = (item: string) => {
    return ["page", "entry_page", "exit_page"].includes(item);
  };

  /**
   * Is not is only for the goal.
   * @param item
   * @returns
   */
  const supportsIsNot = (item: string) => {
    return !["goal"].includes(item);
  };

  /**
   * On component load, update the form state. Added the opened in the dependency array, so that the form state is updated when the modal is opened.
   *
   */

  useEffect(() => {
    if (opened || filterKey) {
      setFormState(getFormState());
    }
  }, [filterKey, opened]);

  /**
   * Function to update the form state operator.
   * @param filter i.e utm_source, page, entry_page etc.
   * @param value i.e ~, !, "!~"
   */
  const handleOperatorChange = (filter: string, value: string) => {
    setFormState({
      ...formState,
      [filter]: {
        ...formState[filter],
        operator: value
      }
    });
  };

  /**
   * Function to update the form state value.
   * @param filter i.e utm_source, page, entry_page etc.
   * @param value i.e User entered value or selected value.
   */
  const handleValueChange = (filter: string, value: string) => {
    setFormState({
      ...formState,
      [filter]: {
        ...formState[filter],
        value: value
      }
    });
  };

  /**
   * Function to convert the form state key to human readable format.
   * @param filter i.e utm_source, page, entry_page etc.
   * @returns
   */
  const filterHumanReadable = (filter: string) => {
    if (filter === "os") {
      return "operating system";
    }
    if (filter === "utm") {
      return "UTM tags";
    }
    if (filter.startsWith("utm_")) {
      return filter.replace("utm_", "UTM_").replaceAll("_", " ");
    }
    return filter.replaceAll("_", " ");
  };

  /**
   * Function that applies the filters to the Query Parameters.
   */
  const onClickApply = () => {
    const filters: any = [];
    Object.keys(formState).map((value) => {
      formState[value].value &&
        filters.push(value + ":" + formState[value].operator + formState[value].value);
    });

    const concatenatedFilters = query.filters
      .filter((f: string) => !formState[f.split(":")[0]])
      .concat(filters);
    query["filters"] = concatenatedFilters.filter(function (item: any, pos: any) {
      return concatenatedFilters.indexOf(item) === pos;
    });

    setQuery(query);
    onClose();
  };

  /**
   * Function that removes the query filters parameters.
   */
  const onRemoveFilter = () => {
    const filteredList = query.filters.filter((f: string) => {
      return !Object.keys(formState).some((value) => value === f.split(":")[0]);
    });
    setQuery({ ...query, filters: filteredList });
    onClose();
  };

  /**
   * If the key is not set and formState length is 0, then return null.
   * The modal will not be shown in this case.
   */
  if (!filterKey || formState.length === 0) {
    return <></>;
  }

  return (
    <Modal
      size={"lg"}
      title={
        <Title order={6} fw={600}>
          Filter by {filterHumanReadable(filterKey)}
        </Title>
      }
      opened={opened}
      onClose={onClose}>
      <Divider mb="sm" />
      {/* {JSON.stringify(formState)} */}
      {Object.values(FILTER_GROUPS[filterKey]).map((item: any, index: number) => (
        <Flex mb="sm" align={"center"} key={`filtered-group:${index}`}>
          <Box w={100}>
            <Title fw={600} order={6} tt="capitalize">
              {filterHumanReadable(item)}
            </Title>
          </Box>

          <Flex align={"center"}>
            <Select
              w={160}
              placeholder="Select operator"
              defaultValue={OPERATOR_ITEMS[0].value}
              value={formState[item]?.operator || ""}
              data={
                supportsIsNot(item)
                  ? supportsContains(item)
                    ? OPERATOR_ITEMS
                    : [OPERATOR_ITEMS[0], OPERATOR_ITEMS[1]]
                  : [OPERATOR_ITEMS[0]]
              }
              className="w-96 mr-4"
              onChange={(value: string | any) => {
                handleOperatorChange(item, value);
              }}
            />

            <FilterSearchSelect
              item={item}
              formState={formState}
              query={query}
              setQuery={setQuery}
              onChange={(item, value) => handleValueChange(item, value)}></FilterSearchSelect>
          </Flex>
        </Flex>
      ))}
      <Divider mb="sm" />
      <Flex justify={"end"} align={"center"} py={"md"}>
        <Button
          onClick={onClickApply}
          disabled={!Object.keys(formState).some((value) => formState[value]?.value)}
          data-cy="apply-filter-btn">
          Apply Filter
        </Button>
        {Object.keys(formState).some((value) => formState[value]?.value) && (
          <>
            <Button onClick={onRemoveFilter} ml={"sm"} color="red">
              Remove Filter
            </Button>
          </>
        )}
      </Flex>
    </Modal>
  );
};
