import { Select, Box, useMantineTheme, useMantineColorScheme } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Oval } from "react-loader-spinner";
import { loaderColor } from "../../../../style/Colors";

export interface IDomainSelector {
  /**
   * The domain to be selected by default.
   */
  domain: string | null;

  /**
   * Callback function to be called when the domain is changed.
   * @param domain
   */
  setDomain: (domain: string | null) => void;

  /**
   * The list of domains to be shown in the dropdown.
   */
  domains: Array<{ label: string; value: string }>;

  /**
   * If true, the dropdown will be disabled.
   */
  disabled?: boolean;

  /**
   * If true, the dropdown will be searchable.
   */
  searchable?: boolean;

  /**
   * loading state
   */
  loading?: boolean;
}

const DomainSelector = ({
  domain,
  setDomain,
  domains,
  disabled = false,
  searchable = false,
  loading = false
}: IDomainSelector) => {
  const { colorScheme } = useMantineColorScheme();
  return (
    <Box pos="relative">
      <Select
        leftSection={<FontAwesomeIcon icon={regular("browser")} />}
        size="xs"
        placeholder={loading ? "Loading domains..." : "Select a domain"}
        data={domains}
        value={domain}
        onChange={(value) => setDomain(value)}
        nothingFoundMessage={<span>No domain found</span>}
        searchable={searchable}
        disabled={disabled || loading || domains.length === 0}
      />

      {loading && (
        <Box
          pos="absolute"
          style={(theme) => ({
            right: 6,
            top: "50%",
            transform: "translateY(-50%)",
            backgroundColor: colorScheme === "dark" ? "dark.2" : "#f5f6f7"
          })}>
          <Oval color={loaderColor} height={16} width={16} />
        </Box>
      )}
    </Box>
  );
};

export default DomainSelector;
