// @ts-ignore
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Switch } from "@headlessui/react"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import { WorkspaceShareService } from "@/lib/services/WorkspaceShareService";
import {
  LOADER_COLOR,
  MOBILE_BREAKPOINT_QUERY,
  WORKSPACE_SHARING_TYPES
} from "@/lib/utils/Constants";
import { useContext, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDetectClickOutside } from "react-detect-click-outside";
import { Controller, useForm } from "react-hook-form";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { LoadingStateProps, WorkspaceSharingType } from "types/types.d";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";
import {
  Button,
  Switch,
  PasswordInput,
  Input,
  Box,
  Popover,
  TextInput,
  CopyButton,
  ActionIcon,
  Tooltip,
  useMantineTheme,
  Flex,
  useMantineColorScheme,
  Title,
  Text,
  Anchor,
  Loader,
  Center
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export const ShareDashboardDropdown = () => {
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [isVisible, setIsVisible] = useState(false);
  const [buttonText, setButtonText] = useState("Copy Link");
  const [loading, setLoading] = useState<LoadingStateProps>("loading");
  const [isPasswordEnabled, setIsPasswordEnabled] = useState(false);
  const [sharePassword, setSharePassword] = useState<null | string>(null);
  const [sharing, setSharing] = useState<WorkspaceSharingType>(null);
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    register,
    setValue
  } = useForm<{
    share_password: string;
  }>({});
  const shareURL = `${process.env.REACT_APP_WEB_APP_URL}/share/${activeWorkspace.identifier}/web`;
  const onHandleLinkCopied = () => {
    setButtonText("Link Copied!");
    setTimeout(() => {
      setButtonText("Copy Link");
    }, 2000);
  };

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setIsVisible(false);
    }
  });

  /**
   * Function to store the states value, this is called from multiple places.
   * @param res
   */
  const storeStates = (res: any) => {
    setSharing(res.data.sharing);
    setIsPasswordEnabled(res.data.sharing === WORKSPACE_SHARING_TYPES.PRIVATE);
    setSharePassword(res.data.share_password);
  };

  /**
   * Triggered when the user clicks on the share public toggle.
   * @param value boolean
   */
  const handleSharePublicToggle = (value: boolean) => {
    let sharing: WorkspaceSharingType = null;
    if (value) {
      sharing = sharePassword ? WORKSPACE_SHARING_TYPES.PRIVATE : WORKSPACE_SHARING_TYPES.PUBLIC;
    }

    // send request to update workspace sharing to public
    new WorkspaceShareService()
      .put(activeWorkspace.id, sharing, sharePassword)
      .then((res) => {
        if (res.data) {
          storeStates(res);
        }
      })
      .catch((err) => err);
  };

  /**
   * Triggered when the user clicks on the enable password toggle.
   * @param value boolean
   */
  const handlePasswordEnabledToggle = (value: boolean) => {
    setIsPasswordEnabled(!isPasswordEnabled);
    new WorkspaceShareService()
      .put(
        activeWorkspace.id,
        value ? WORKSPACE_SHARING_TYPES.PRIVATE : WORKSPACE_SHARING_TYPES.PUBLIC,
        sharePassword
      )
      .then((res) => {
        if (res.data) {
          storeStates(res);
        }
      })
      .catch((err) => err);
    setValue("share_password", sharePassword || "");
  };

  /**
   * On submit of the form, update the share password.
   * @param data Object containing the form data.
   */
  const handleSavePasswordSubmit = (data: any) => {
    // send request to update workspace sharing to public
    new WorkspaceShareService()
      .put(activeWorkspace.id, WORKSPACE_SHARING_TYPES.PRIVATE, data.share_password)
      .then((res) => {
        if (res.data) {
          storeStates(res);
          toast.success("Your dashboard sharing password has been set.");
        }
      })
      .catch((err) => err);
  };

  /**
   * When user clicks on the Create link button.
   */
  const handleShare = () => {
    if (!sharing) {
      handleSharePublicToggle(true);
    }
  };

  /**
   * When user clicks on the Share button on the dashboard. Get the dashboard sharing details.
   */
  const handleOnShareClick = async (value: boolean) => {
    setLoading("loading");
    setIsVisible(value);
    await new WorkspaceShareService()
      .get(activeWorkspace.id)
      .then((res) => {
        if (res.data) {
          setSharing(res.data.sharing);
          setIsPasswordEnabled(res.data.sharing === WORKSPACE_SHARING_TYPES.PRIVATE);
          setSharePassword(res.data.share_password);
          setValue("share_password", res.data.share_password || "");
        }
      })
      .catch((err) => {});
    setLoading("loaded");
  };

  const isSharingEnabled =
    sharing === WORKSPACE_SHARING_TYPES.PRIVATE || sharing === WORKSPACE_SHARING_TYPES.PUBLIC;
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <Popover
        withinPortal
        width={isMobileView ? "20rem" : "28rem"}
        position="bottom"
        withArrow
        shadow="md"
        opened={isVisible}
        onChange={(value) => handleOnShareClick(value)}>
        <Popover.Target>
          <Button
            onClick={() => handleOnShareClick(!isVisible)}
            className="mr-4"
            // variant="gradient"
            leftSection={
              <>
                <FontAwesomeIcon
                  icon={regular("arrow-up-from-bracket")}
                  className="!text-white"
                  size={isMobileView ? "xs" : "sm"}></FontAwesomeIcon>
              </>
            }
            data-cy="share-dashboard-btn"
            size={isMobileView ? "xs" : "sm"}>
            Share
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          {loading === "loading" ? (
            <>
              <Center py="md">
                <Loader size="xs" />
              </Center>
            </>
          ) : (
            <>
              <Title order={5} mb={"sm"} fw={600}>
                Share this dashboard
              </Title>

              {!sharing && (
                <>
                  <Button
                    fullWidth
                    // variant="gradient"
                    onClick={handleShare}
                    className="w-full flex flex-grow"
                    data-cy="share-dashboard-create-link-btn">
                    Create Link
                  </Button>
                </>
              )}

              {sharing && (
                <>
                  <Flex>
                    <TextInput
                      defaultValue={shareURL}
                      value={shareURL}
                      w={"100%"}
                      size="sm"
                      mb={"sm"}
                      rightSection={
                        <CopyButton value={`${shareURL}`}>
                          {({ copied, copy }) => (
                            <ActionIcon
                              variant="subtle"
                              size="xs"
                              color={
                                copied
                                  ? "green"
                                  : !copied && colorScheme === "dark"
                                  ? "dark.1"
                                  : "brand.5"
                              }
                              // mr={32}

                              onClick={copy}>
                              <div data-cy="copy-shareable-link-btn">
                                <FontAwesomeIcon icon={regular("clipboard")} />
                              </div>
                            </ActionIcon>
                          )}
                        </CopyButton>
                      }
                    />
                  </Flex>
                  <Title order={6} fw={600} mb="sm">
                    Permissions
                  </Title>
                  <Switch
                    mb="sm"
                    size={"xs"}
                    label={"Anyone with the link can view this dashboard"}
                    checked={isSharingEnabled}
                    onChange={(event: any) => handleSharePublicToggle(event.target.checked)}
                  />
                  <Switch
                    mb={"sm"}
                    size={"xs"}
                    label={"Enable password protection"}
                    checked={isPasswordEnabled}
                    onChange={(event: any) => handlePasswordEnabledToggle(event.target.checked)}
                    data-cy="share-dashboard-password-protection-toggle"
                  />

                  {isPasswordEnabled && (
                    <>
                      <form
                        id="handlePassword"
                        onSubmit={handleSubmit(handleSavePasswordSubmit)}
                        autoComplete="off">
                        <Flex mb={"sm"} align={"center"}>
                          <Flex flex={1} pos={"relative"} direction={"column"}>
                            <Controller
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error }
                              }) => (
                                <PasswordInput
                                  w={"100%"}
                                  size={"xs"}
                                  value={value}
                                  onChange={onChange}
                                  defaultValue={sharePassword || ""}
                                  error={(invalid || error) === true}
                                  placeholder="Set password"
                                  className="w-full"
                                  data-cy="share-dashboard-password"
                                />
                              )}
                              name="share_password"
                              control={control}
                              defaultValue=""
                              rules={{ required: true, maxLength: 48 }}
                            />

                            {errors.share_password?.type === "required" && (
                              <Text c="red" fz="xs" pt="xs">
                                Share password is required
                              </Text>
                              // <ValidationLabel
                              //   validationType="error"
                              //   text="Share password is required"
                              //   size="small"
                              //   customClasses="pt-2"></ValidationLabel>
                            )}
                            {errors.share_password?.type === "maxLength" && (
                              <Text c="red" fz="xs" pt="xs">
                                Share password should be less than 48 characters
                              </Text>
                            )}
                          </Flex>
                          <Flex justify={"flex"} ml={"sm"} mt={errors.share_password ? -26 : 0}>
                            <Button
                              size="xs"
                              type="submit"
                              data-cy="share-dashboard-save-password-btn">
                              Save Password
                            </Button>
                          </Flex>
                        </Flex>
                      </form>
                    </>
                  )}

                  <Text>
                    Want to embed?{" "}
                    <Anchor
                      href="https://usermaven.com/docs/website-analytics-embed-dashboard"
                      target="_blank"
                      rel="noreferrer">
                      Learn more
                    </Anchor>
                  </Text>
                </>
              )}
            </>
          )}
        </Popover.Dropdown>
      </Popover>

      {/* <div ref={ref}>
        <Button
          onClick={handleOnShareClick}
          leftIcon={
            <>
              <FontAwesomeIcon
                icon={regular("arrow-up-from-bracket")}
                className="!text-white"
              ></FontAwesomeIcon>
            </>
          }
        >
          Share
        </Button>

        <Box
          sx={theme => ({
            background: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#fff",
          })}
          className={`w-[30rem] absolute drop-shadow h-auto z-50  p-4 right-8 rounded transition-all ease-in-out  ${
            isVisible ? "flex flex-col top-14" : "hidden"
          }`}
        ></Box>
      </div> */}
    </>
  );
};
