import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Title,
  useMantineTheme,
  Text,
  Paper,
  List,
  Flex,
  ThemeIcon
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WorkspaceService } from "@/lib/services/WorkspaceService";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { LoadingStateProps } from "types/types.d";

export const SetupNotification: FC<{}> = () => {
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const navigate = useNavigate();
  const location = useLocation();

  const isPixelInstalled = async () => {
    setLoading("loading");
    if (activeWorkspace.is_setup_completed) {
      setLoading("loaded");
      return;
    }

    const wsService = new WorkspaceService();

    await wsService
      .isPixelEventsAvailable(activeWorkspace?.id)
      .then((res) => {
        if (res.data?.id) {
          setActiveWorkspace(res.data);
        }
      })
      .catch((err) => err);
    setLoading("loaded");
  };
  useEffect(() => {
    isPixelInstalled();
  }, []);
  if (location.pathname.includes("product-insights-upgrade")) {
    return <></>;
  }


  return (
    <>
      {!location.pathname.includes("/settings/") &&
        !location.pathname.includes("/account/") &&
        !location.pathname.includes("/setup-guides/") &&
        !location.pathname.includes("/events/") &&
        !location.pathname.includes("/insights/dashboard") &&
        loading === "loaded" &&
        !activeWorkspace.is_setup_completed && (
          <Paper p={"md"} shadow="sm" withBorder m="md">
            <Title fz={"xs"} fw={700} c="brand" tt="uppercase" mb={"sm"}>
              Essential
            </Title>
            <Title order={4} fw={500} mb={8}>
              Finish your Usermaven.com installation
            </Title>
            <Text>
              This will enable Usermaven to capture user behavior data on your website and provide
              actionable insights to you.
            </Text>
            <List my="md">
              <List.Item
                fz="sm"
                icon={
                  <Flex align={"center"} c="green.8">
                    <FontAwesomeIcon icon={regular("circle-check")}></FontAwesomeIcon>
                  </Flex>
                }>
                Create a Workspace
              </List.Item>
              <List.Item
                fz="sm"
                icon={
                  <Flex c="gray.6" align={"center"}>
                    <FontAwesomeIcon icon={regular("circle-check")}></FontAwesomeIcon>
                  </Flex>
                }>
                Add tracking code
              </List.Item>
            </List>
            <Button
              className="mt-4"
              onClick={() => {
                navigate(`/env/${activeWorkspace.identifier}/setup-guides/integration`);
              }}>
              Install Usermaven
            </Button>
          </Paper>
        )}
    </>
  );
};
