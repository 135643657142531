import { Box } from "@mantine/core";
import { useEffect } from "react";
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";

import { AccountsSupportAccess } from "./AccountsSupportAccess";
const AccountsSecurity = (props: any) => {
  useEffect(() => {
    document.title = "Data Consent | Usermaven";
  }, []);

  return (
    <>
      <AccountsSupportAccess />
    </>
  );
};
export default AccountsSecurity;
