import { Box, useMantineTheme, Menu, Button, useMantineColorScheme } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { DATE_FORMAT, PLAN_TYPES } from "@/lib/utils/Constants";
import { IDateRange } from "@/ui/components/App/Dropdowns/DatePickerDropdown";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { endOfDay, format, startOfDay, subDays } from "date-fns";

export interface IDateRangeSelector {
  /**
   * The dateRange to be selected by default.
   */
  dateRange: IDateRange;

  /**
   * Callback function to be called when the dateRange is changed.
   * @param dateRange
   */
  setDateRange: React.Dispatch<React.SetStateAction<IDateRange>>;

  /**
   * The list of dateRanges to be shown in the dropdown.
   */
  dateRanges?: Array<IDateRange>;

  /**
   * If true, the dropdown will be disabled.
   */
  disabled?: boolean;
}

const DAY_DATE_RANGES = [
  {
    label: "Last 7 days",
    startDate: format(startOfDay(subDays(new Date(), 7)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "day"
  },

  {
    label: "Last 14 days",
    startDate: format(startOfDay(subDays(new Date(), 14)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "day"
  },
  {
    label: "Last 21 days",
    startDate: format(startOfDay(subDays(new Date(), 21)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "day"
  },
  {
    label: "Last 30 days",
    startDate: format(startOfDay(subDays(new Date(), 30)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "day"
  },
  {
    label: "Last 60 days",
    startDate: format(startOfDay(subDays(new Date(), 60)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "day"
  },
  {
    label: "Last 90 days",
    startDate: format(startOfDay(subDays(new Date(), 90)), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
    interval: "day"
  }
];
const STARTER_PLAN_ALLOWED_DATE_RANGES = ["last 7 days"];

const DateRangeSelector = ({
  dateRange,
  setDateRange,
  dateRanges = DAY_DATE_RANGES,
  disabled = false
}: IDateRangeSelector) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [opened, setOpened] = useState(false);
  const { colorScheme } = useMantineColorScheme();
  return (
    <Box pos="relative">
      <Menu shadow="md" width={180} withinPortal onChange={setOpened} disabled={disabled}>
        <Menu.Target>
          <Button
            my="xs"
            size="xs"
            color={colorScheme === "dark" ? "dark.2" : "gray.6"}
            variant="outline"
            leftSection={<FontAwesomeIcon icon={regular("list-dropdown")} />}
            rightSection={
              <FontAwesomeIcon
                icon={solid("angle-up")}
                className={`transition-all ease-in-out transform rotate-180 ${
                  opened ? "rotate-0" : ""
                }`}
              />
            }>
            {dateRange?.label || "Last 7 days"}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {dateRanges.map((dateRange: IDateRange, index: number) =>
            /* Date range menu items older than 6 months7 days are disabled for starter plan */
            !STARTER_PLAN_ALLOWED_DATE_RANGES.includes(dateRange.label.toLowerCase()) &&
            (activeWorkspace?.organization?.plan?.name === PLAN_TYPES.STARTER ||
              activeWorkspace?.organization?.plan?.name === PLAN_TYPES.STARTER_BADGE_PROGRAM ||
              activeWorkspace?.organization?.plan?.name === PLAN_TYPES.STARTER_LEGACY) ? (
              <Menu.Item
                key={index}
                className={"hover:bg-gray-100 cursor-not-allowed"}
                disabled
                onClick={() => toast.error("Please upgrade your plan to unlock these date ranges")}
                rightSection={<FontAwesomeIcon key={"fa" + index} icon={regular("lock")} />}>
                {dateRange.label}
              </Menu.Item>
            ) : (
              <Menu.Item key={index} onClick={() => setDateRange(dateRange)}>
                {dateRange.label}
              </Menu.Item>
            )
          )}
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};

export default DateRangeSelector;
