import { getRelativeDateToNow, utcToRelativeTimezone } from "@/lib/utils/DateUtility";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Box, Menu, Paper, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useState } from "react";
import { RichTextEditor } from "./RichTextEditor";
import { useDisclosure } from "@mantine/hooks";

interface INotesCard {
  noteId: number | string;
  body: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  onEdit: (noteId: number | string, content: string) => void;
  onDelete: (noteId: number | string) => void;
}

export const NotesCard: React.FC<INotesCard> = ({
  noteId,
  body,
  name,
  createdAt,
  updatedAt,
  onEdit,
  onDelete
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [opened, setOpened] = useState(false);

  const defaultExtensions = [
    StarterKit,
    Placeholder.configure({
      emptyEditorClass: "is-editor-empty",
      emptyNodeClass: "my-custom-is-empty-class",
      placeholder: "Your note ..."
    })
  ];

  const editor = useEditor({
    content: body,
    extensions: [...defaultExtensions]
  });

  const deleteNotePrompt = (e: any) => {
    openConfirmModal({
      zIndex: 1200,
      title: <h3 className="font-medium">Delete note</h3>,
      size: "sm",
      children: (
        <>
          <Text size="sm" className="mt-4">
            Are you sure you want to delete this note?
          </Text>
        </>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: {
        color: "red.6"
      },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => onDelete(noteId)
    });
  };

  useEffect(() => {
    if (!editor) {
      return undefined;
    }
    // Get the initial content …
    if (editor) {
      editor.setEditable(false);
    }
  }, [editor]);
  useEffect(() => {
    editor?.commands.setContent(body);
  }, [body]);
  if (!editor) {
    return null;
  }

  return (
    <>
      <Paper shadow="xs" mb={16} color="gray.1" withBorder>
        {isEdit ? (
          <Box p={12}>
            <RichTextEditor
              initialContent={body}
              isEdit={true}
              noteId={noteId}
              onCancel={(noteId: number) => setIsEdit(!isEdit)}
              onSave={(content: string) => {
                setIsEdit(!isEdit);
                onEdit(noteId, content);
              }}
            />
          </Box>
        ) : (
          <Box p={12} fz={"sm"}>
            <Text c="dimmed" size="xs" mb={4} fs="italic">
              {utcToRelativeTimezone(createdAt)} by {name}
            </Text>
            <EditorContent editor={editor} />

            <Box
              style={{
                zIndex: 500,
                position: "absolute",
                top: 0,
                right: 0
              }}>
              <ActionIcon
                variant="subtle"
                color="gray.8"
                size={"sm"}
                mr={"xs"}
                mt={12}
                onClick={() => setIsEdit(!isEdit)}>
                <FontAwesomeIcon size="xs" icon={regular("pencil")} />
              </ActionIcon>
              <ActionIcon
                size={"sm"}
                variant="subtle"
                color="red.6"
                mr={12}
                mt={12}
                onClick={deleteNotePrompt}>
                <FontAwesomeIcon size="xs" style={{}} icon={regular("trash")} />
              </ActionIcon>
            </Box>
          </Box>
        )}
      </Paper>
    </>
  );
};
