// Created from this gist: https://gist.github.com/bierik/0baa0de30cc4ee6d3fbf8485c4d12bb8

export const downloadCSVFromResponse = (
  res: any,
  filename: string = "export.csv"
) => {
  let contentType = res.headers["content-type"];
  let file = new Blob([res.data], { type: contentType });
  // For Internet Explorer and Edge
  if ("msSaveOrOpenBlob" in window.navigator) {
    // @ts-ignore
    window.navigator?.msSaveOrOpenBlob(file, filename);
  }
  // For Firefox and Chrome
  else {
    // Bind blob on disk to ObjectURL
    let data = URL.createObjectURL(file);
    let a = document.createElement("a");
    // @ts-ignore
    a.style = "display: none";
    a.href = data;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    // For Firefox
    setTimeout(function () {
      document.body.removeChild(a);
      // Release resource on disk after triggering the download
      window.URL.revokeObjectURL(data);
    }, 100);
  }
};
