import useInsightsListHook from "@/hooks/useInsightsListHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { InsightsService } from "@/lib/services/InsightsService";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { WorkspaceMiscService } from "@/lib/services/WorkspaceMiscService";
import { useConversionGoalsStore } from "@/stores/useConversionGoalsStore";
import { ConditionItem, ConversionGoalValueTypes } from "@/types/types.d";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Group,
  Modal,
  Text,
  Title
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

interface IAttributionUpgradeModalProps {
  open: boolean;
  setModalOpen: (value: boolean) => void;
}
/**
 * Upgrade to Attribution 2.0
 * Modal for converting the existing attribution reports into new Conversion Goals.
 * Shown when the user has existing attribution reports.
 */
export const AttributionUpgradeModal = ({ open, setModalOpen }: IAttributionUpgradeModalProps) => {
  // Workspace
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  // List of existing attribution reports
  const { insightsList: attributionList } = useInsightsListHook("acquisition");
  // Conversion Goals Store
  const [goals, setGoals] = useConversionGoalsStore((state) => [state.list, state.setList]);
  // List of reports that the user has selected to convert into new Conversion Goals
  const [checkedReports, setCheckedReports] = useState<string[]>([]);

  /**
   * Function to convert the attribution conditions to human readable format.
   * @param conditions
   * @returns
   */
  const conditionsHumanReadable = (conditions: ConditionItem[]) => {
    return (
      <Text span>
        {conditions.flatMap((condition, conditionIndex, array) => {
          let conditionText;
          switch (condition.type) {
            case "pageview":
              conditionText = (
                <>
                  Page URL {condition.operator.replace("_", " ")}{" "}
                  {
                    <Text span fw={600}>
                      {condition.value}
                    </Text>
                  }
                </>
              );
              break;
            case "event":
              conditionText = (
                <>
                  Custom event{" "}
                  {
                    <Text span fw={600}>
                      {condition.label}
                    </Text>
                  }{" "}
                  has been performed
                </>
              );
              break;
            case "pinned_event":
              conditionText = (
                <>
                  Pinned event{" "}
                  {
                    <Text span fw={600}>
                      {condition.label}
                    </Text>
                  }{" "}
                  has been performed
                </>
              );
              break;
            default:
              conditionText = `${condition.label} ${condition.operator} ${condition.value}`;
          }

          return [
            <Fragment key={conditionIndex}>
              <Text span>{conditionText}</Text>
            </Fragment>,
            conditionIndex < array.length - 1 ? " AND " : ""
          ];
        })}
      </Text>
    );
  };

  /**
   * Function to handle the conversion of the existing attribution reports into new Conversion Goals.
   */
  const handleUpgrade = async () => {
    // Check if the user has selected any reports
    if (checkedReports.length === 0) {
      toast.error("Please select the reports that you want to convert into new Conversion Goals.");
      return;
    }

    // Convert the selected reports into new Conversion Goals
    const insightsService = new InsightsService();
    for (let index = 0; index < checkedReports.length; index++) {
      // Find the report with the selected ID
      const report: any = attributionList.find((item: any) => item.id === checkedReports[index]);
      if (!report) {
        return;
      }
      // Check if the report has a unique conversion goal name
      const isUnique = !goals?.find(
        (goal: any) => goal.name.toLowerCase() === report.name.toLowerCase()
      );

      // Create new conversion goals with the same conditions as the selected reports
      await insightsService
        .create(activeWorkspace.id, {
          name: isUnique ? report.name : `${report.name} - Attribution goal`,
          conditions: {
            goal_conditions: report.conditions["attribution_conditions"],
            is_track_unique_conversion: true,
            conversion_value: 0,
            conversion_value_type: "static" as ConversionGoalValueTypes,
            conversion_value_attribute: "",
            target: ""
          },
          type: "web_conversion_goal"
        })
        .then((response) => {
          // Update the conversion goals list
          setGoals([...goals, response.data]);

          // Delete the report we converted to a conversion goal
          insightsService
            .delete(activeWorkspace.id, report.id)
            .then(() => {})
            .catch((error) => {
              console.log(`Failed to delete report: ${report.id}. Error: ${error.message}`);
            });
        })
        .catch((error) => {
          console.log(
            `Failed to create conversion goal for report: ${report.id}. Error: ${error.message}`
          );
          toast.error(
            `Failed to create conversion goal for report: ${report.name}. Please try again.`
          );
        });
    }

    // Delete all the unselected reports
    const unselectedReports = attributionList.filter(
      (item: any) => !checkedReports.includes(item.id)
    );
    for (let index = 0; index < unselectedReports.length; index++) {
      const report: any = unselectedReports[index];
      await insightsService
        .delete(activeWorkspace.id, report.id)
        .then(() => {})
        .catch((error) => {
          console.log(`Failed to delete report: ${report.id}. Error: ${error.message}`);
        });
    }

    // Update the is_attribution_upgraded flag in the workspace settings
    await updateAttributionUpgradedCheck();
    toast.success(
      "All selected attribution reports have been converted into new Conversion Goals successfully."
    );
    // Close the upgrade modal
    setModalOpen(false);
  };

  /**
   * When the user wants to start fresh, delete all existing attribution reports.
   */
  const handleDeleteAll = async () => {
    // Confirm with the user
    openConfirmModal({
      title: <Title order={5}>Delete attribution reports</Title>,
      children: (
        <Flex>
          <Alert color="red">
            <Center mb={16}>
              <FontAwesomeIcon icon={regular("warning")} size="2x" color="red" />
            </Center>
            You are about to delete all attribution reports within this workspace.{" "}
            <Text fw={500}>
              {" "}
              Please be aware that this action is irreversible and will permanently delete all
              attribution reports.{" "}
            </Text>{" "}
            <br />
            If you do not wish to do this, please Cancel and select the reports you want to convert
            into new Conversion Goals. <br />
            <br />
            If you are sure you want to delete all the reports, click Delete All.
          </Alert>
        </Flex>
      ),
      labels: { confirm: "Delete All", cancel: "Cancel" },
      confirmProps: { color: "red.6" },

      // cancel delete and open the upgrade modal again
      onCancel: () => setModalOpen(true),
      onConfirm: async () => {
        await deleteAllReports()
          .then(() => {
            toast.success("All attribution reports have been deleted successfully.");
          })
          .catch(() => {
            toast.error("Failed to delete all attribution reports. Please try again.");
          });
      }
    });

    setModalOpen(false);
  };

  const deleteAllReports = async () => {
    // Delete all the existing attribution reports
    const insightsService = new InsightsService();
    for (let index = 0; index < attributionList.length; index++) {
      const attributionReport: any = attributionList[index];
      await insightsService
        .delete(activeWorkspace.id, attributionReport.id)
        .then(() => {
          console.log("Deleted report: ", attributionReport.id);
        })
        .catch((error) => {
          console.log(`Failed to delete report: ${attributionReport.id}. Error: ${error.message}`);

          throw new Error(
            `Failed to delete report: ${attributionReport.id}. Error: ${error.message}`
          );
        });
    }

    await updateAttributionUpgradedCheck();
    // Close the upgrade modal
    setModalOpen(false);
  };

  // Update the is_attribution_upgraded flag in the workspace settings
  const updateAttributionUpgradedCheck = async () => {
    const is_attribution_upgraded = true;
    await new WorkspaceMiscService()
      .attributionUpgraded(activeWorkspace.id, is_attribution_upgraded)
      .then((res) => {
        if (res.data) {
          activeWorkspace.is_attribution_upgraded = res.data.data;
          setActiveWorkspace(activeWorkspace);
        }
      })
      .catch((error) => {
        console.log(`Failed to update workspace settings. Error: ${error.message}`);
      });
  };

  const fetchConversionGoals = async () => {
    // Fetch existing conversion goals
    new WebAnalyticsService()
      .goals(activeWorkspace.id, "")
      .then((response) => {
        setGoals(response.data.data);
      })
      .catch((error) => {
        console.log(
          `Failed to fetch conversion goals. Error: ${error.message}. ${error.response?.data?.detail}`
        );
        setGoals([]);
      });
  };

  // when the modal is opened and attribution reports are fetched
  // check all the reports by default
  useEffect(() => {
    if (!open) return;
    setCheckedReports([...attributionList.map((item: any) => item.id)]);

    // Fetch existing conversion goals
    fetchConversionGoals();
  }, [attributionList]);

  return (
    <Modal
      styles={{
        header: {
          display: "none"
        }
      }}
      withCloseButton={false}
      opened={open}
      onClose={() => {}}
      size={"50%"}>
      <Box p="lg">
        <Title order={3} fz="lg" ta="center" pb="lg">
          Attribution Module has got an upgrade
        </Title>
        <Text>
          The new attribution module works with your existing conversion goals instead of creating
          new attribution reports.
        </Text>
        <Text pt="xs">
          Our system has detected that you have the following attribution reports:
        </Text>
        <Checkbox.Group value={checkedReports} onChange={setCheckedReports} withAsterisk py="md">
          {attributionList.map((item: any) => (
            <Checkbox
              mb={"sm"}
              key={item.id}
              value={item.id}
              label={item.name}
              description={conditionsHumanReadable(item.conditions["attribution_conditions"])}
            />
          ))}
        </Checkbox.Group>

        <Text py="md">
          Please select the reports that you want to convert into new Conversion Goals. All
          unselected reports will be deleted.
        </Text>
        <Divider />
        <Flex justify={"center"} gap={"xl"} py="lg">
          <Group>
            <Button variant="subtle" color="red" size="sm" onClick={handleDeleteAll}>
              No, I'll start fresh
            </Button>
            <Button variant="light" size="sm" onClick={handleUpgrade} data-autofocus>
              Yes, Proceed
            </Button>
          </Group>
        </Flex>
      </Box>
    </Modal>
  );
};
