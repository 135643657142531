/* eslint-disable react-hooks/exhaustive-deps */
import useReportStore, { IReport } from "@/stores/useReportStore";
import {
  Box,
  Grid,
  Input,
  Stack,
  Text,
  TextInput,
  Image,
  useMantineTheme,
  MultiSelect,
  SegmentedControl,
  Group,
  Select,
  Divider,
  Button,
  Center,
  Flex,
  useMantineColorScheme
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { IFormInputs } from "./Reports";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { showNotification } from "@mantine/notifications";
import { WorkspaceMemberService } from "@/lib/services/WorkspaceMemberService";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import CustomSegmentControl from "@/ui/components/Common/CustomSegmentControl/CustomSegmentControl";
import WebAnalyticsIcon from "@assets/images/icons/reports/Analytics.svg";
import KPTIcon from "@assets/images/icons/reports/KPI.svg";
import EmailIcon from "@assets/images/icons/reports/Email.svg";
import SlackIcon from "@assets/images/icons/reports/Slack.svg";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
import classes from "./Reports.module.css";
import { EmailRecipientsInput } from "./EmailRecipientsInput";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface ReportsFormProps {
  opened: boolean;
  setOpen: (value: boolean) => void;
  previewType: "preview" | "live";
  setPreviewType: (value: "preview" | "live") => void;
}

const ReportForm = ({ opened, setOpen, previewType, setPreviewType }: ReportsFormProps) => {
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [wsMembersLoading, setWsMembersLoading] = useState(true);
  const [workspaceMemberEmails, setWorkspaceMemberEmails] = useState<string[]>([]);
  const [
    report,
    setReport,
    loading,
    createReport,
    updateReport,
    clearReport,
    reportTemplate,
    fetchReportTemplate
  ] = useReportStore((state) => [
    state.report,
    state.setReport,
    state.loading,
    state.createReport,
    state.updateReport,
    state.clearReport,
    state.reportTemplate,
    state.fetchReportTemplate
  ]);

  const [formErrors, setFormErrors] = useState<IFormInputs>({
    name: "",
    email_recipients: ""
  });

  const { activeWorkspace } = useContext(AppLifecycleContext);

  const handleSubmission = () => {
    // Validate the form
    if (validateFields()) {
      if (!report.id) {
        // Create the report
        try {
          createReport(activeWorkspace.id, report, (type, message) => {
            console.log(type, message);
            if (type === "success") {
              clearReport();
              setOpen(false);
            }

            if (type === "error") {
              console.error(message);
              showNotification({
                color: "red",
                title: "Error",
                message: message,
                autoClose: 5000
              });
            }
          });
          // setOpen(false);
        } catch (error) {
          console.error(error);
        }
      } else {
        // Update the report
        try {
          updateReport(activeWorkspace.id, report, (type, message) => {
            console.log(type, message);
            if (type === "success") {
              clearReport();
              setOpen(false);
            }

            if (type === "error") {
              console.error(message);
              showNotification({
                color: "red",
                title: "Error",
                message: message,
                autoClose: 5000
              });
            }
          });
          // setOpen(false);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const validateFields = (): boolean => {
    let isValid = true;
    if (report.name.trim() === "") {
      setFormErrors((state) => ({ ...state, name: "Report name is required" }));
      isValid = false;
    }

    // Name should be at least 3 characters
    if (report.name.trim().length < 3) {
      setFormErrors((state) => ({ ...state, name: "Report name should be at least 3 characters" }));
      isValid = false;
    }

    // email_recipients should be at least 1
    if (report.email_recipients.length === 0) {
      setFormErrors((state) => ({ ...state, email_recipients: "Email address is required" }));
      isValid = false;
    }

    return isValid;
  };

  const clearFormError = (field: string) => {
    setFormErrors((state) => ({ ...state, [field]: "" }));
  };

  // give default value for reportToFetch as null

  const getReportTemplate = (reportToFetch: IReport | null = null) => {
    // Get the report template
    fetchReportTemplate(activeWorkspace.id, reportToFetch ? reportToFetch : report, previewType);
  };

  const getWorkspaceMemberEmails = async () => {
    setWsMembersLoading(true);
    const wsMemberService = new WorkspaceMemberService();
    await wsMemberService
      .getAllMemberEmails(activeWorkspace.id)
      .then((response) => {
        if (response.data) {
          setWorkspaceMemberEmails(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setWsMembersLoading(false);
  };

  useEffect(() => {
    if (opened) {
      getWorkspaceMemberEmails();
      getReportTemplate();
    }
  }, [opened, previewType]);

  return (
    <SidePanel
      loading={true}
      opened={opened}
      onCancel={() => {
        setOpen(false);
        clearReport();
      }}
      fullWidth
      hideOverflow
      title={report.id ? "Update Report" : "Create Report"}>
      <Grid p="md">
        <Grid.Col span={5}>
          <Stack gap={"xl"}>
            <Input.Wrapper
              size="sm"
              label="Report Name"
              styles={{
                label: {
                  marginBottom: "4px"
                }
              }}
              required>
              <TextInput
                data-autofocus
                placeholder="e.g Web Analytics Report"
                value={report.name}
                onChange={(e) => {
                  setReport({ ...report, name: e.currentTarget.value });
                  clearFormError("name");
                }}
                error={formErrors.name}></TextInput>
            </Input.Wrapper>

            <Box>
              <Text size={"sm"} fw={500} mb={"4px"}>
                Type of Report
              </Text>

              <CustomSegmentControl
                name={"type-of-report"}
                data={[
                  {
                    label: "Key Summary Web Analytics",
                    value: "web_analytics",
                    icon: <Image src={WebAnalyticsIcon} width={16} height={16} />
                  },
                  {
                    label: "KPIs",
                    value: "kpis",
                    disabled: true,
                    icon: <Image src={KPTIcon} width={16} height={16} />,
                    isComingSoon: true
                  }
                ]}
                value={report.type}
                onChange={(value) => {
                  let updatedReport = { ...report, type: value as any };
                  setReport(updatedReport);
                  getReportTemplate(updatedReport);
                }}
              />
            </Box>

            <Box>
              <Text size={"sm"} fw={500} mb={"4px"}>
                Notification Channel
              </Text>

              <CustomSegmentControl
                name={"notification-channel"}
                data={[
                  {
                    label: "Email",
                    value: "email",
                    icon: <Image src={EmailIcon} width={16} height={16} />
                  },
                  {
                    label: "Slack",
                    value: "slack",
                    disabled: true,
                    icon: <Image src={SlackIcon} width={16} height={16} />,
                    isComingSoon: true
                  }
                ]}
                value={"email"}
                onChange={(value) => {
                  let updatedReport = null;
                  if (value === "email") {
                    updatedReport = {
                      ...report,
                      is_email_notification_channel: true,
                      is_slack_notification_channel: false
                    };
                  } else {
                    updatedReport = {
                      ...report,
                      is_email_notification_channel: false,
                      is_slack_notification_channel: true
                    };
                  }
                  setReport(updatedReport);
                  getReportTemplate(updatedReport);
                }}
              />
            </Box>

            <Box>
              {wsMembersLoading ? (
                <LoadingComponent />
              ) : (
                <EmailRecipientsInput
                  workspaceMemberEmails={workspaceMemberEmails}
                  report={report}
                  setReport={setReport}
                  clearFormError={clearFormError}
                  formErrors={formErrors}
                />
              )}

              {/* <MultiSelect
                placeholder={"Enter email addresses"}
                label={"Email Addresses"}
                data={workspaceMemberEmails.map((email) => ({ value: email, label: email }))}
                searchable
                creatable
                transitionDuration={150}
                transition="pop-top-left"
                transitionTimingFunction="ease"
                styles={{
                  label: {
                    marginBottom: "4px"
                  }
                }}
                value={report.email_recipients}
                onChange={(value) => {
                  setReport({ ...report, email_recipients: value as string[] });
                  clearFormError("email_recipients");
                }}
                getCreateLabel={(query) => `+ Add ${query}`}
                onCreate={(query) => {
                  const qItem = { value: query.trim(), label: query.trim() };
                  setWorkspaceMemberEmails((current) => [...current, query.trim()]);
                  return qItem;
                }}
                error={formErrors.email_recipients}
              /> */}
            </Box>

            <Box>
              <Text size={"sm"} fw={500} mb={"4px"}>
                Schedule
              </Text>

              <SegmentedControl
                data={[
                  { label: "Daily", value: "daily" },
                  { label: "Weekly", value: "weekly" },
                  { label: "Monthly", value: "monthly" }
                ]}
                value={report.period}
                onChange={(value) => {
                  let updatedReport = { ...report, period: value as any };
                  setReport(updatedReport);
                  getReportTemplate(updatedReport);
                }}
              />
            </Box>

            <Group>
              {report.period === "weekly" ? (
                <Text color={colorScheme === "dark" ? "dark.2" : undefined}>Every</Text>
              ) : report.period === "monthly" ? (
                <Text color={colorScheme === "dark" ? "dark.2" : undefined}>
                  First of every month at
                </Text>
              ) : (
                <Text color={colorScheme === "dark" ? "dark.2" : undefined}>Everyday at</Text>
              )}

              {report.period === "weekly" && (
                <>
                  <Select
                    defaultValue={"monday"}
                    data={[
                      { value: "monday", label: "Monday" },
                      { value: "tuesday", label: "Tuesday" },
                      { value: "wednesday", label: "Wednesday" },
                      { value: "thursday", label: "Thursday" },
                      { value: "friday", label: "Friday" },
                      { value: "saturday", label: "Saturday" },
                      { value: "sunday", label: "Sunday" }
                    ]}
                    w={120}
                    value={report.weekday}
                    onChange={(value) =>
                      setReport({ ...report, weekday: (value as any) || "monday" })
                    }
                  />

                  <Text color={colorScheme === "dark" ? "dark.2" : undefined}>at</Text>
                </>
              )}

              <Select
                data={[
                  { value: "00:00:00", label: "00:00" },
                  { value: "01:00:00", label: "01:00" },
                  { value: "02:00:00", label: "02:00" },
                  { value: "03:00:00", label: "03:00" },
                  { value: "04:00:00", label: "04:00" },
                  { value: "05:00:00", label: "05:00" },
                  { value: "06:00:00", label: "06:00" },
                  { value: "07:00:00", label: "07:00" },
                  { value: "08:00:00", label: "08:00" },
                  { value: "09:00:00", label: "09:00" },
                  { value: "10:00:00", label: "10:00" },
                  { value: "11:00:00", label: "11:00" },
                  { value: "12:00:00", label: "12:00" },
                  { value: "13:00:00", label: "13:00" },
                  { value: "14:00:00", label: "14:00" },
                  { value: "15:00:00", label: "15:00" },
                  { value: "16:00:00", label: "16:00" },
                  { value: "17:00:00", label: "17:00" },
                  { value: "18:00:00", label: "18:00" },
                  { value: "19:00:00", label: "19:00" },
                  { value: "20:00:00", label: "20:00" },
                  { value: "21:00:00", label: "21:00" },
                  { value: "22:00:00", label: "22:00" },
                  { value: "23:00:00", label: "23:00" }
                ]}
                w={120}
                defaultValue={report.schedule_time || "00:00:00"}
                value={report.schedule_time}
                onChange={(value) => setReport({ ...report, schedule_time: value as any })}
              />

              <Text color={"dimmed"}>{activeWorkspace.timezone} Timezone</Text>
            </Group>

            <Divider />
            <Flex justify="end">
              <Button
                size="sm"
                variant={"outline"}
                color={"gray.6"}
                disabled={loading === "creating"}
                mr={"sm"}
                onClick={() => {
                  setOpen(false);
                  clearReport();
                }}>
                Close
              </Button>
              <Button
                size="sm"
                onClick={() => handleSubmission()}
                loading={loading === "creating"}
                disabled={loading === "fetching" && previewType === "live"}>
                {report.id ? "Update Report" : "Create Report"}
              </Button>
            </Flex>
          </Stack>
        </Grid.Col>
        <Grid.Col
          span={7}
          style={{
            filter: colorScheme === "dark" ? "invert(0.95) hue-rotate(180deg)" : "none"
          }}>
          <Box className={classes.preview}>
            {loading === "fetching" ? (
              <LoadingComponent />
            ) : reportTemplate === "" ? (
              "No Preview Available"
            ) : (
              <>
                <Center>
                  <SegmentedControl
                    value={previewType}
                    // @ts-ignore
                    onChange={setPreviewType}
                    data={[
                      { label: "Sample", value: "preview" },
                      { label: "Live", value: "live" }
                    ]}
                    color="brand"
                    bg="dark.4"
                    fullWidth
                    transitionDuration={0}
                    radius="xl"
                    size="xs"
                    // styles={(theme) => ({
                    //   root: {
                    //     backgroundColor:
                    //       colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[2]
                    //   },
                    //   label: {
                    //     padding: "5px 10px",
                    //     color: colorScheme === "dark" ? theme.colors.dark[4] : "#898c90",
                    //     "&:hover": {
                    //       color: colorScheme === "dark" ? "#7D47EB" : "#7D47EB"
                    //     }
                    //   },
                    //   labelActive: {
                    //     backgroundColor:
                    //       colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[2],
                    //     color: colorScheme === "dark" ? "#000000" : "#fff !important"
                    //   },
                    //   controlActive: {
                    //     backgroundColor:
                    //       colorScheme === "dark" ? theme.colors.dark[9] : "#7D47EB !important"
                    //   }
                    // })}
                  />
                </Center>
                <div dangerouslySetInnerHTML={{ __html: reportTemplate }} />
              </>
            )}
          </Box>
        </Grid.Col>
      </Grid>
    </SidePanel>
  );
};

export default ReportForm;
