/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Text,
  useMantineTheme,
  HoverCard,
  ActionIcon,
  Modal,
  Tooltip,
  Title,
  useMantineColorScheme
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FeatureSetup } from "@/ui/pages/Insights/Components/Setup/FeatureSetup";
import useFeatureAdoptionStore, { Module } from "@/stores/useFeatureAdoptionStore";
import AddOrUpdateModuleModal from "@/ui/pages/InsightsDashboard/Components/Modals/AddOrUpdateModuleModal";
import { useContext, useState } from "react";
import { FeatureAdoptionService } from "@/lib/services/FeatureAdoptionService";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";

interface ModuleSetupProps {
  module: Module;
  moduleIndex: number;
}

export const ModuleSetup: React.FC<ModuleSetupProps> = ({ module, moduleIndex }) => {
  const { colorScheme } = useMantineColorScheme();

  const [openUpdateModulePrompt, setOpenUpdateModulePrompt] = useState(false);
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  const [moduleToDelete, setModuleToDelete] = useState({
    id: "",
    name: "Module",
    description: {},
    is_default: false,
    is_new: false,
    is_active: false,
    is_saved: false
  });

  const [modules, updateModule, removeModule, addFeatureToModule, isNewFeatureAvailable] =
    useFeatureAdoptionStore((state) => [
      state.modules,
      state.updateModule,
      state.removeModule,
      state.addFeatureToModule,
      state.isNewFeatureAvailable
    ]);

  const featureAdoptionService = new FeatureAdoptionService();
  const { activeWorkspace } = useContext(AppLifecycleContext);

  // Function to add an empty feature object to the current module
  const addNewFeature = (moduleIndex: number) => {
    const tempRandomUUID = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) =>
      c === "x" ? ((Math.random() * 16) | 0).toString(16) : (0x8 | (Math.random() * 4)).toString(16)
    );
    addFeatureToModule(modules[moduleIndex].id, {
      id: tempRandomUUID,
      name: "",
      conditions: { feature_events_conditions: [] },
      adoption_rate: 2,
      retention_period: "bi-weekly",
      is_new: true,
      is_active: true,
      is_saved: false
    });
  };

  const deleteModule = (module: Module) => {
    if (module.is_new) {
      // NOTE: No service call required beacuse feature is not saved yet on database. Only remove from store.
      removeModule(module.id);
    } else {
      // NOTE: Open model to process.
      setModuleToDelete(module);
      setOpenDeletePrompt(true);
    }
  };

  const deleteModuleFromDB = async () => {
    await featureAdoptionService
      .deleteModule(activeWorkspace.id, module.id)
      .then((res) => {
        if (res.data) {
          removeModule(module.id);
          setOpenDeletePrompt(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setOpenDeletePrompt(false);
      });
  };

  return (
    <>
      <Box
        mt={12}
        className="module-item"
        style={(theme) => {
          return {
            borderRadius: theme.radius.md,
            backgroundColor: colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[1],
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor:
              colorScheme === "dark" ? theme.colors.darkBorderColor[1] : theme.colors.gray[6],
            boxShadow: colorScheme === "dark" ? "none" : "0px 0px 10px rgba(0, 0, 0, 0.05)"
          };
        }}>
        <Box m={15}>
          <Flex justify={"space-between"}>
            <Flex
              align={"center"}
              style={{
                cursor: "pointer"
              }}
              flex={1}
              onClick={() => {
                updateModule(module.id, {
                  is_active: !module.is_active
                });
              }}>
              <Flex align={"center"} mr={4} mt={-2}>
                <FontAwesomeIcon
                  size="sm"
                  icon={solid("angle-right")}
                  className={` transform  text-gray-800 transition-all duration-200 ease-in-out ${
                    module.is_active ? "rotate-90" : ""
                  }`}
                />
              </Flex>
              <Flex direction={"column"}>
                <Text fz="sm" ml={5}>
                  Module: {module.name}
                </Text>
                <Text ml={5} mt={2} c="dimmed">
                  {module.description}
                </Text>
              </Flex>

              {/* <TextInput
                className="w-80"
                value={moduleName}
                placeholder={"Module Name"}
                onChange={(event) => setModuleName(event.currentTarget.value)}
              /> */}
            </Flex>
            <Flex gap={"sm"}>
              {module.is_saved && (
                <Tooltip label="Edit module">
                  <ActionIcon
                    className="module-actions"
                    size={"sm"}
                    variant="subtle"
                    color={colorScheme === "dark" ? "dark.0" : "gray.7"}
                    onClick={(e) => setOpenUpdateModulePrompt(true)}>
                    <FontAwesomeIcon icon={regular("pen-to-square")} />
                  </ActionIcon>
                </Tooltip>

                // <HoverCard
                //   shadow="md"
                //   withArrow
                //   withinPortal
                //   position={"top"}
                //   styles={{
                //     dropdown: {
                //       padding: "6px 8px"
                //     }
                //   }}>
                //   <HoverCard.Target>
                //     <ActionIcon
                //       size={"sm"}
                //       color={colorScheme === "dark" ? "dark.0" : "gray.7"}
                //       onClick={(e) => setOpenUpdateModulePrompt(true)}>
                //       <FontAwesomeIcon icon={regular("edit")} />
                //     </ActionIcon>
                //   </HoverCard.Target>
                //   <HoverCard.Dropdown>
                //     <Text size="sm" color={colorScheme === "dark" ? "white" : "gray.7"}>
                //       Edit
                //     </Text>
                //   </HoverCard.Dropdown>
                // </HoverCard>
              )}
              <Tooltip label="Delete module">
                <ActionIcon
                  variant="subtle"
                  className="module-actions"
                  size={"sm"}
                  color="red.5"
                  // p={4}
                  onClick={() => deleteModule(module)}
                  style={{
                    cursor: "pointer"
                  }}
                  // styles={{
                  //   placeholder: {
                  //     borderWidth: "2px"
                  //   }
                  // }}
                >
                  <FontAwesomeIcon size="sm" icon={regular("trash")} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>

          {module.is_active && (
            <>
              <Box ml={25}>
                <FeatureSetup
                  moduleId={module.id}
                  moduleName={module.name}
                  features={module.features}
                  title={"Select events for your feature"}
                  description={"You can select more than one event if you have multiple actions."}
                  conditionType={"feature_adoption"}
                />
              </Box>

              <Flex justify="center" align="center" mt={20}>
                <Button
                  size={"sm"}
                  disabled={isNewFeatureAvailable(module.id)}
                  radius="md"
                  color={colorScheme === "dark" ? "dark.2" : undefined}
                  variant="outline"
                  onClick={() => addNewFeature(moduleIndex)}
                  data-cy="new-conversion-goal-btn">
                  <FontAwesomeIcon
                    icon={regular("plus")}
                    className="w-4 h- hover:opacity-80 mr-2"
                  />
                  Add new feature
                </Button>
              </Flex>
            </>
          )}
        </Box>
      </Box>

      <Divider my="md" variant="dashed" />

      <AddOrUpdateModuleModal
        open={openUpdateModulePrompt}
        setOpen={setOpenUpdateModulePrompt}
        module={module}
      />

      <Modal
        overlayProps={{
          opacity: 0.5,
          blur: 1
        }}
        opened={openDeletePrompt}
        onClose={() => setOpenDeletePrompt(false)}
        title={<Title order={5}>Delete Module</Title>}>
        <div>
          <Text>
            Do you really want to delete the module{" "}
            <Text span fw={500}>
              "{moduleToDelete.name}"
            </Text>
            ?
          </Text>
          <Text>By doing so will delete associated features also.</Text>
          <Flex justify={"flex-end"} px={20} pt={20} gap="sm">
            <Button
              variant="subtle"
              color="gray.6"
              onClick={() => {
                setOpenDeletePrompt(false);
              }}>
              Cancel
            </Button>

            <Button color="red" onClick={() => deleteModuleFromDB()}>
              Yes
            </Button>
          </Flex>
        </div>
      </Modal>
    </>
  );
};
