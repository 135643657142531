import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { UsersCRMService } from "@/lib/services/UsersCRMService"
import { LOADER_COLOR } from "@/lib/utils/Constants"
import React, { useContext, useEffect, useState } from "react"
import { Oval } from "react-loader-spinner"
import { useParams } from "react-router"
import ReactTooltip from "react-tooltip"
import { AttributeItem } from "@/ui/components/Common/AttributeItem/AttributeItem"
import { Box } from "@mantine/core"

export const UserCRMDetailAttributes = (props: any) => {
  const { segment, peopleId } =
    useParams<{
      segment: string
      peopleId: string
    }>()
  const [isLoading, setIsLoading] = useState("idle")
  const { activeWorkspace } = useContext(AppLifecycleContext)
  const [peopleAttributes, setPeopleAttributes] = useState<any>()
  const peopleService = new UsersCRMService()
  const getPeopleAttributes = async () => {
    setIsLoading("loading")
    await peopleService
      .individualUserAttributesById(activeWorkspace.id, peopleId)
      .then(res => {
        if (res.data) {
          setPeopleAttributes(res.data)
        }
      })
      .catch(err => {})
    setIsLoading("loaded")
    ReactTooltip.rebuild()
  }
  const _getPeopleCustomAttributes = (): any => {
    let customAttributesList: Array<any> = []
    Object.entries(peopleAttributes?.user_custom_map || {}).forEach((value, key) => {
      customAttributesList.push({
        key: value[0],
        value: value[1],
        cast_type: typeof value[1] || "",
      })
    })
    console.log(customAttributesList)
    return customAttributesList
  }
  useEffect(() => {
    getPeopleAttributes()
  }, [])
  return (
    <>
      <Box
        className=" border border-solid"
        sx={theme => ({
          borderColor:
            theme.colorScheme === "dark"
              ? theme.colors.darkBorderColor[0]
              : theme.colors.lightBorderColor[0],
        })}
      >
        <div className=" ">
          {isLoading !== "loaded" ? (
            <div className="um-attributes--loading">
              <Oval color={LOADER_COLOR} height={16} width={16} />
            </div>
          ) : (
            <>
              <Box
                sx={theme => ({
                  fontSize: "1.125rem",
                  background: theme.colorScheme === "dark" ? theme.colors.dark[6] : "",
                  borderColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.darkBorderColor[0]
                      : theme.colors.lightBorderColor[0],
                })}
                className="font-semibold py-2 px-4 border-b border-solid  bg-gray-100"
              >
                Attributes
              </Box>
              <div className="um-attributes--list max-h-screen overflow-y-auto">
                <AttributeItem
                  key={`people-attribute:id`}
                  attribute_key="id"
                  attribute_value={peopleAttributes?.user_id}
                  attribute_type="Identifier"
                  attribute_cast_type="string"
                />
                <AttributeItem
                  key={`people-attribute:email`}
                  attribute_key="email"
                  attribute_value={peopleAttributes?.user_email}
                  attribute_type="Reserved"
                  attribute_cast_type="string"
                />
                <AttributeItem
                  key={`people-attribute:created_at`}
                  attribute_key="created_at"
                  attribute_value={peopleAttributes?.user_created_at}
                  attribute_type="Reserved"
                  attribute_cast_type="string"
                />
                <AttributeItem
                  key={`people-attribute:first_name`}
                  attribute_key="first_name"
                  attribute_value={peopleAttributes?.user_first_name}
                  attribute_type="Recommended"
                  attribute_cast_type="string"
                />
                <AttributeItem
                  key={`people-attribute:last_name`}
                  attribute_key="last_name"
                  attribute_value={peopleAttributes?.user_last_name}
                  attribute_type="Recommended"
                  attribute_cast_type="string"
                />
                {_getPeopleCustomAttributes().map((attributeItem: any, index: number) => (
                  <>
                    <AttributeItem
                      key={`people-attribute:${attributeItem.value}:${index}`}
                      attribute_key={attributeItem.key}
                      attribute_value={attributeItem.value}
                      attribute_type=""
                      attribute_cast_type={attributeItem.cast_type}
                    />
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      </Box>
    </>
  )
}
