import { Oval } from "react-loader-spinner";
import { loaderColor } from "@/style/Colors";
import React, { useContext, useRef, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  Text,
  ThemeIcon,
  Timeline,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { CompaniesService } from "@/lib/services/CompaniesService";
import _groupBy from "lodash.groupby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { DATE_FORMAT } from "@/lib/utils/Constants";
import { startOfDay, format, subDays } from "date-fns";
import { generateHashCode } from "@/lib/utils/StringUtility";

export interface ITimelineTab {
  uniqueId: string | undefined;
}

export interface ITimelineTabItem {
  user_id: string;
  user_first_name: string;
  user_last_name: string;
  week: number;
  start_of_week: string;
  end_of_week: string;
  pageviews: number;
  custom_events: number;
  autocaptured_events: number;
}

const ALL_AVATAR_COLORS = [
  "red",
  "pink",
  "grape",
  "violet",
  "indigo",
  "blue",
  "cyan",
  "teal",
  "green",
  "lime",
  "orange"
];

const TimelineTab = ({ uniqueId }: ITimelineTab) => {
  const [timelineList, setTimelineList] = useState<ITimelineTabItem[]>([]);
  const [timelineGroupedList, setTimelineGroupedList] = useState<ITimelineTabItem[][]>([]);
  const [loading, setLoading] = React.useState(true);
  const [hasMore, setHasMore] = React.useState(true);
  const { colorScheme } = useMantineColorScheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const duration = useRef({
    startDate: format(subDays(new Date(), 30), DATE_FORMAT),
    endDate: format(new Date(), DATE_FORMAT)
  });

  // Function to generate a consistent color based on user UUID
  const getUserColor = (userId: string) => {
    const hashCode = generateHashCode(userId);
    const colorIndex = Math.abs(hashCode) % ALL_AVATAR_COLORS.length;
    return ALL_AVATAR_COLORS[colorIndex];
  };

  const fetchTimeline = async () => {
    setLoading(true);
    const fetchedTimeline = await fetchCompanyTimeline(
      duration.current.startDate,
      duration.current.endDate
    );
    if (fetchedTimeline) {
      // if the same number of items were fetched as last time, it means that there are no more items to fetch
      if (fetchedTimeline.length === timelineList.length) {
        setHasMore(false);
      }

      setTimelineList(fetchedTimeline);
      const groupedTimelines = _groupByUserId(fetchedTimeline);
      setTimelineGroupedList(groupedTimelines);

      // change the duration to fetch one month before
      duration.current.startDate = format(
        startOfDay(subDays(new Date(duration.current.startDate), 30)),
        DATE_FORMAT
      );
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchTimeline();
  }, []);

  const fetchCompanyTimeline = async (startDate: string, endDate: string) => {
    const response = await new CompaniesService().timeline(
      activeWorkspace?.id,
      uniqueId,
      startDate,
      endDate
    );
    if (response && response.data) {
      return response.data;
    }
    return [];
  };

  /**
   * Group the response array containing all users to an array of arrays containing each user's data.
   */
  const _groupByUserId = (timelineList: ITimelineTabItem[]) => {
    const groupedTimelines = _groupBy(timelineList, "user_id");
    return Array.from(Object.values(groupedTimelines));
  };

  return (
    <Box my="xs">
      <Box
        sx={(theme) => ({
          background: colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.white,
          overflowX: "auto"
        })}>
        {timelineGroupedList && timelineGroupedList.length > 0 && (
          <Grid p={"lg"} m={0} style={{ flexWrap: "nowrap" }} grow>
            <Grid.Col span="content" p={0}>
              <Box
                sx={(theme) => ({
                  fontSize: "1.125rem",
                  background: colorScheme === "dark" ? theme.colors.dark[6] : "",
                  borderColor:
                    colorScheme === "dark"
                      ? theme.colors.darkBorderColor[0]
                      : theme.colors.lightBorderColor[0]
                })}>
                <Text fw={600} py={"xs"} px={"sm"} h={36}>
                  Week
                </Text>
              </Box>
              <Timeline
                key="weeks"
                mx="xs"
                my="sm"
                lineWidth={0}
                bulletSize={0}
                styles={{
                  itemBody: {
                    height: "80px",
                    maxWidth: "200px"
                  },
                  item: {
                    paddingLeft: "8px"
                  }
                }}>
                {timelineGroupedList[0].map((item: ITimelineTabItem, index) => (
                  <Timeline.Item key={`week:${item.week}`}>
                    <Text fw={500}>
                      {format(new Date(item.start_of_week), "MMM dd")}
                      {" - "}
                      {format(new Date(item.end_of_week), "MMM dd, yyyy")}
                    </Text>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Grid.Col>
            <>
              {timelineGroupedList.map((user: ITimelineTabItem[], index) => (
                <Grid.Col key={`col:${index}`} span={3} p={0}>
                  <Box
                    style={(theme) => ({
                      fontSize: "1.125rem",
                      background: colorScheme === "dark" ? theme.colors.dark[6] : "",
                      borderColor:
                        colorScheme === "dark"
                          ? theme.colors.darkBorderColor[0]
                          : theme.colors.lightBorderColor[0]
                    })}
                    p={"xs"}>
                    <Group gap="xs" justify="start" align="center">
                      <Avatar
                        variant="filled"
                        tt={"uppercase"}
                        radius="xl"
                        size="sm"
                        color={getUserColor(user[0].user_id)}>
                        {user[0].user_first_name[0]}
                        {user[0].user_last_name[0]}
                      </Avatar>
                      <Text tt="capitalize">
                        {user[0].user_first_name} {user[0].user_last_name}
                      </Text>
                    </Group>
                  </Box>
                  <Timeline
                    key={`timeline:${index}`}
                    mx="xs"
                    my="sm"
                    lineWidth={1}
                    active={timelineGroupedList[0].length}
                    color="brand"
                    styles={{
                      itemBody: {
                        height: "80px",
                        maxWidth: "200px"
                      }
                    }}>
                    {user.map((item: ITimelineTabItem, index: number) => (
                      <Timeline.Item
                        key={`pageview:${item.week}:${index}`}
                        bullet={
                          item.pageviews === 0 &&
                          item.custom_events === 0 &&
                          item.autocaptured_events === 0 ? (
                            <ThemeIcon
                              variant="filled"
                              color={colorScheme === "dark" ? "dark.7" : "#fff"}
                              radius="xl"
                              style={{
                                border: "0px"
                              }}>
                              <Divider
                                orientation="vertical"
                                color={colorScheme === "dark" ? "brand.4" : "brand"}
                                style={{ height: "28px", width: "1px" }}
                              />
                            </ThemeIcon>
                          ) : (
                            <ThemeIcon variant="light" color="brand" radius="xl">
                              <FontAwesomeIcon icon={solid("arrow-pointer")} size="sm" />
                            </ThemeIcon>
                          )
                        }>
                        {item.pageviews > 0 && (
                          <Badge
                            size="lg"
                            color={colorScheme === "dark" ? "dark.2" : "gray.6"}
                            variant="outline"
                            radius="sm"
                            tt={"capitalize"}
                            fz={12}
                            fw={600}
                            mb={4}>
                            {item.pageviews} pageviews
                          </Badge>
                        )}
                        {item.autocaptured_events > 0 && (
                          <Badge
                            size="lg"
                            variant="outline"
                            color={colorScheme === "dark" ? "dark.2" : "gray.6"}
                            radius="sm"
                            tt={"capitalize"}
                            fz={12}
                            fw={600}
                            mb={4}>
                            {item.autocaptured_events} auto-captured events
                          </Badge>
                        )}
                        {item.custom_events > 0 && (
                          <Badge
                            size="lg"
                            variant="outline"
                            color={colorScheme === "dark" ? "dark.2" : "gray.6"}
                            radius="sm"
                            tt={"capitalize"}
                            fz={12}
                            fw={600}>
                            {item.custom_events} custom events
                          </Badge>
                        )}
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </Grid.Col>
              ))}
            </>
          </Grid>
        )}

        {loading ? (
          <Flex justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
            <Loader size="xs" />
          </Flex>
        ) : (
          <>
            {hasMore ? (
              <Flex h={80} justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
                <Button size="xs" variant="light" onClick={fetchTimeline}>
                  Load More
                </Button>
              </Flex>
            ) : timelineList.length === 0 ? (
              <Flex h={80} justify={"center"} w={"100%"} flex={1} align={"center"} py={"md"}>
                <Text>No events performed by users.</Text>
              </Flex>
            ) : null}
          </>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(TimelineTab);
