export const gray = {
  50: "#fbfcfc",
  100: "#f2f4f6",
  200: "#eceff1",
  300: "#e3e8eb",
  400: "#dde3e7",
  500: "#d5dce1",
  600: "#c2c8cd",
  700: "#979ca0",
  800: "#75797c",
  900: "#595c5f"
};
