// import { Prism } from "@mantine/prism";
import { sdkIdentifySnippet, sdkTrackingEventsSnippet } from "@/lib/utils/TrackingPixelUtility";
// import githubTheme from "prism-react-renderer/themes/github";
// import duotoneDark from "prism-react-renderer/themes/dracula";
import { FC } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Accordion, Anchor, Avatar, Box, Code, Flex, Text, Title } from "@mantine/core";
import { GeneralAPIKeyAndServerToken } from "@/ui/pages/Settings/WorkspaceSettings/General/GeneralAPIKeyAndServerToken";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CodeHighlightTabs, CodeHighlight } from "@mantine/code-highlight";
type Props = {
  workspaceId: string;
  integrationType: string | null;
  connectedDataSource?: boolean;
  identifiedUsers?: boolean;
  trackedBehaviors?: boolean;
};

export const TrackingPixelReactNextGuide: FC<Props> = ({
  workspaceId,
  integrationType,
  connectedDataSource = false,
  identifiedUsers = false,
  trackedBehaviors = false
}) => {
  const location = useLocation();
  const isInsightsDashboard = location.pathname.includes("/insights/dashboard");

  const defaultOpened = !isInsightsDashboard
    ? `install_sdk_${integrationType}` // default to install pixel
    : connectedDataSource
    ? identifiedUsers
      ? trackedBehaviors
        ? "" // all done - all panels closed
        : `custom_events_${integrationType}` // only custom events left
      : `user_identify_${integrationType}` // first step done - second panel open
    : `install_sdk_${integrationType}`; // nothing done - first panel open

  const getExampleApplicationText = () => {
    if (integrationType === "react") {
      return "React";
    } else if (integrationType === "next") {
      return "Next";
    }
  };

  const getExampleApplicationURL = () => {
    if (integrationType === "react") {
      return "https://github.com/usermaven/usermaven-js/blob/master/packages/react/README.md";
    } else if (integrationType === "next") {
      return "https://github.com/usermaven/usermaven-js/blob/master/packages/nextjs/README.md";
    }
  };

  const getPackageNameByIntegrationType = (packageManager = "npm") => {
    let packageName;
    if (integrationType === "react") {
      packageName = "@usermaven/react";
    } else {
      packageName = "@usermaven/nextjs";
    }
    if (packageManager === "npm") {
      return `npm install --save ${packageName}`;
    } else {
      return `yarn add ${packageName}`;
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <Accordion mt={32} variant="separated" defaultValue={defaultOpened}>
        <Accordion.Item value={`install_sdk_${integrationType}`}>
          <Accordion.Control>
            <Flex align={"center"}>
              {connectedDataSource ? (
                <Avatar size="md" color="teal.7" radius="xl" mr={"sm"}>
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" className="mr-4 font-bold">
                  1
                </Avatar>
              )}
              <Text className="flex-grow" fz="md" fw="bold">
                {isInsightsDashboard ? "Connect a data source" : "Install Usermaven SDK"}
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Text className="py-4" fz="sm" mb="sm">
              To collect events from your {getExampleApplicationText()} application with Usermaven,
              first install usermaven javascript SDK.
            </Text>
            <CodeHighlightTabs
              styles={{}}
              fz={"sm"}
              mb="sm"
              expanded
              code={[
                {
                  fileName: "npm",
                  code: getPackageNameByIntegrationType("npm"),
                  language: "bash"
                },
                {
                  fileName: "yarn",
                  code: getPackageNameByIntegrationType("yarn"),
                  language: "bash"
                }
              ]}
            />

            <Text className="py-4" fz="sm" mb="sm">
              Next, see our example code for{" "}
              <Anchor
                fw={500}
                fz="sm"
                className="font-bold"
                href={getExampleApplicationURL()}
                target="_blank">
                {getExampleApplicationText()} {integrationType === "next" && "Page Router"}{" "}
                application
              </Anchor>
              {integrationType === "next" && (
                <span>
                  {" "}
                  or the{" "}
                  <Anchor
                    fz={"sm"}
                    fw={500}
                    href="https://github.com/usermaven/usermaven-next-app-example"
                    target="_blank">
                    Next App Router application
                  </Anchor>
                </span>
              )}
              . You'll need to use the following API key.
            </Text>
            <GeneralAPIKeyAndServerToken apiKeyOnly={true} withBorder={false} withHeader={false} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value={`user_identify_${integrationType}`}>
          <Accordion.Control>
            <Flex align={"center"}>
              {identifiedUsers ? (
                <Avatar size="md" color="teal.7" radius="xl" mr={"sm"}>
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" mr="sm">
                  2
                </Avatar>
              )}
              <Text className="flex-grow" fz="md" fw="bold">
                {isInsightsDashboard
                  ? "Send users and companies attributes"
                  : "Send users and companies attributes (For SaaS product insights)"}
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Text className="py-4" fz="sm" mb="sm">
              <Text span fw={500} fz="sm">
                Next, use our identify function
              </Text>{" "}
              to send us customer data. Add this snippet below the previous one and modify it with
              the attributes you'd like to send.
            </Text>
            <CodeHighlight code={sdkIdentifySnippet()} language="javascript" />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value={`custom_events_${integrationType}`}>
          <Accordion.Control>
            <Flex align={"center"}>
              {trackedBehaviors ? (
                <Avatar size="md" color="teal.7" radius="xl" mr={"sm"}>
                  <FontAwesomeIcon icon={solid("check")} size="xl" />
                </Avatar>
              ) : (
                <Avatar size="md" color="brand" radius="xl" mr={"sm"}>
                  3{" "}
                </Avatar>
              )}
              <Text className="flex-grow" fz="md" fw="bold">
                {isInsightsDashboard
                  ? "Track behaviours"
                  : "Send important events (For SaaS product insights)"}
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Text fz="sm" mb="sm">
              Usermaven allows you to track user behaviours by using Pinned Events as well as have
              an option to send the custom events.
            </Text>
            <Flex mb={16} ml={16}>
              <Avatar variant="outline" size="sm" color="brand.5" radius="xl">
                1
              </Avatar>
              <Box ml={16}>
                <Title order={5} mb={8}>
                  Pinned Events
                </Title>
                <Text fz="sm">
                  You can create your Pinned events from{" "}
                  <Anchor onClick={() => navigate(`/env/${workspaceId}/events/pinned-events`)}>
                    here
                  </Anchor>
                  .{" "}
                  <Anchor
                    fz="sm"
                    href="https://usermaven.com/docs/getting-started/creating-pinned-events"
                    target="_blank">
                    Learn more about Pinned Events.
                  </Anchor>
                </Text>
              </Box>
            </Flex>
            <Flex ml={16}>
              <Avatar variant="outline" size="sm" color="brand.5" radius="xl">
                2
              </Avatar>
              <Box ml={16}>
                <Title order={5}>Custom Events</Title>
                <Box>
                  <Text className="py-4" fz="sm" mb="sm">
                    Start sending your important events such as{" "}
                    <Code fz="sm">signed_up, book-a-demo</Code> etc. In most cases, you should make
                    events calls after <Code fz="sm">usermaven.id()</Code> call for the logged in
                    user, so that you can associate events with a user/company in your workspace.
                  </Text>
                  <Text className="py-4" fz="sm" mb="sm">
                    Not sure what events you need to track? We have got you covered.{" "}
                    <Anchor
                      onClick={() => navigate("/events-recommendations?type=js-sdk")}
                      className={"primary-link-color"}>
                      Get started with our tracking guide.
                    </Anchor>
                  </Text>
                  <CodeHighlight code={sdkTrackingEventsSnippet()} language="javascript" />
                </Box>
              </Box>
            </Flex>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

// rgba(204, 204, 212, 0.31)
