import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Center, Divider, Flex, Paper, ScrollArea, Table, Title } from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { UsersCRMService } from "@/lib/services/UsersCRMService";
import { BulletListSkeleton } from "@/lib/utils/ChartsSkeletons";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingStateProps, MostEngagedUserItem } from "types/types.d";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { MostEngagedUsersDetailedView } from "./MostEngagedUsersDetailedView";
import { useUserOverviewModalStore } from "@/stores/userOverviewModalStore";
import { ViewDetailedButton } from "@/ui/components/App/WebAnalytics/ViewDetailedButton";

/**
 * This component shows the most engaged users of the product.
 */
export const MostEngagedUsers = ({ query }: any) => {
  //  Current active workspace
  const { activeWorkspace } = useContext(AppLifecycleContext);
  //  List for setting the results of most engaged users.
  const [list, setList] = useState<Array<MostEngagedUserItem>>([]);
  //  Loading state for async fetch call.
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  //  Detailed view state to toggle between detailed view of most engaged users and normal view.
  const [detailedView, setDetailedView] = useState(false);
  //  useNavigate hook to navigate to specific user overview on clicking.
  const navigate = useNavigate();
  const location = useLocation();
  const [setOverviewModalOpen] = useUserOverviewModalStore((state) => [state.setIsOpen]);

  //  Async fetch service call to fetch the list of most engaged users for specific date range.
  const fetchMostEngagedUsers = async () => {
    setLoading("loading");
    await new UsersCRMService()
      .mostEngagedUsers(activeWorkspace.id, 15, query)
      .then((res) => setList(res.data))
      .catch((err) => {
        if (err?.response?.status === 500 || err?.response?.status === 422) {
          setList([]);
        }
      });
    setLoading("loaded");
  };

  // Initial service call.
  useEffect(() => {
    fetchMostEngagedUsers();
  }, [activeWorkspace.id, query]);

  const onRowClick = (item: any) => {
    // Current Path
    // const currentPath = location.pathname;
    //
    // const redirectPath = `/env/${activeWorkspace.identifier}/users/everyone/${item.user_id}?from_path=${currentPath}`;

    // navigate(redirectPath);
    setOverviewModalOpen(true, "user", item.user_id);
  };

  return (
    <>
      {/* Detailed View modal for most engaged users */}
      <MostEngagedUsersDetailedView
        detailedView={detailedView}
        setDetailedView={setDetailedView}
        query={query}
      />
      <Paper shadow="sm" withBorder mb="md" h={520}>
        <Title order={5} fw={600} className="font-medium" p="md">
          Most Engaged Users
        </Title>
        <Divider />

        <div
          style={{
            overflowY: "auto"
          }}>
          <Box style={{ minWidth: "450px" }}>
            {/* If the loading state is loaded and the list has some results then we will show them */}
            {loading === "loaded" ? (
              <>
                {list.length > 0 ? (
                  <>
                    {/* Header Row */}
                    <ScrollArea h={400} type="hover">
                      <Table>
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>Email</Table.Th>
                            <Table.Th>Hours used</Table.Th>
                            <Table.Th>Days used</Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        {/* <div className="inline-flex">Longest session</div> */}

                        <Table.Tbody>
                          {/* Body Row Mapping */}
                          {list.map((item: MostEngagedUserItem, index) => (
                            // On clicking any user, it will navigate to the overview of that user
                            <Table.Tr
                              onClick={() => onRowClick(item)}
                              key={`engaged-user:${item.user_email}`}>
                              <Table.Td>{item.user_email}</Table.Td>
                              <Table.Td>{item.hours}</Table.Td>
                              <Table.Td>{item.num_days_used_product}</Table.Td>
                              {/* <div>{item.longest_session_minutes}</div> */}
                            </Table.Tr>
                          ))}
                        </Table.Tbody>
                      </Table>
                    </ScrollArea>
                    {/* Detailed View button for setting detailed view to true and showing the detailed view modal */}
                    <ViewDetailedButton
                      onClick={() => setDetailedView(!detailedView)}></ViewDetailedButton>
                  </>
                ) : (
                  <Flex justify={"center"} align={"center"} flex={1} h={300}>
                    {/* In case the results list is empty, we will show this */}
                    <NoResults
                      text={"There is no activity for most engaged users"}
                      heading={"No Results"}
                    />
                  </Flex>
                )}
              </>
            ) : (
              <BulletListSkeleton className="ml-4" />
            )}
          </Box>
        </div>
      </Paper>
    </>
  );
};
