import { stringify } from "query-string";
import { UsersCRMViewType } from "types/types.d";
import { ISegmentsQueryBuilderForm } from "@/ui/components/App/Segments/SegmentFormContext";
import axios from "./JWTService";

export class UsersCRMService {
  search = (ws_id: any, payload: UsersCRMViewType): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/crm/users/${ws_id}`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  fields = (ws_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/crm/users/${ws_id}/columns`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  attributes = (ws_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/crm/users/${ws_id}/attributes`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  columns = (ws_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/crm/users/${ws_id}/fields`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  individualUserDetailsById = (wsId: any, peopleId: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/crm/users/${wsId}/${peopleId}/details`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  individualPeopleActivitiesById = (wsId: any, peopleId: any, page: number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/crm/users/${wsId}/${peopleId}/activities`, {
          page: page
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  individualUserSessionsById = (wsId: any, peopleId: any, page: number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/crm/users/${wsId}/${peopleId}/sessions`, {
          page: page
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  individualUserEventsById = (
    wsId: any,
    sessionId: any,
    page: number,
    autocaptured: boolean
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/crm/users/${wsId}/${sessionId}/events`, {
          page: page,
          autocaptured: autocaptured
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  individualUserAttributesById = (wsId: any, peopleId: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/crm/users/${wsId}/${peopleId}/attributes`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  individualUserOnboardingById = (wsId: any, peopleId: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/crm/users/${wsId}/${peopleId}/onboarding`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  individualUserTopPagesById = (wsId: any, peopleId: any, page: number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/crm/users/${wsId}/${peopleId}/top_pages`, {
          page: page
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  usersWithPeriod = (wsId: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/crm/users/${wsId}/count`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  mostEngagedUsers = (wsId: any, limit: number = 15, query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/crm/users/${wsId}}/most_engaged_users?limit=${limit}&${stringify(query)}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  usersHistogram = (wsId: any, query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/crm/users/${wsId}/histogram?${stringify(query)}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  // Export service function to export most engaged users.
  mostEngagedUsersExport = (wsId: any, limit: number = 100, query: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/crm/users/${wsId}}/most_engaged_users/export?limit=${limit}&${stringify(query)}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  peopleListExport = (
    ws_id: any,
    payload: { fields: Array<string>; search: string; filters: ISegmentsQueryBuilderForm | {} }
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/crm/users/${ws_id}/export`, payload, { responseType: 'blob' })
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
