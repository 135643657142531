import { Box, Stack } from "@mantine/core";
import { useCallback, useContext, useEffect, useState } from "react";
import useMavenAIStore, { TChatMessage } from "@/stores/useMavenAIStore";
import ChatMessage from "@/ui/pages/MavenAi/components/Assistant/components/ChatMessage";
import ChatRichMessage from "@/ui/pages/MavenAi/components/Assistant/components/ChatRichMessage";
import { MavenAIService } from "@/lib/services/MavenAIService";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { showNotification } from "@mantine/notifications";


export interface ChatMessageProps {
  waitingForResponse: boolean;
  showLoading?: boolean;
  onProgress?: () => void;
}

const ChatScreen = ({ waitingForResponse, showLoading, onProgress }: ChatMessageProps) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const service = new MavenAIService(activeWorkspace?.id || "");

  const [messages, activeChat] = useMavenAIStore((state) => [
    state.messages,
    state.activeChat
  ]);

  const [loadingMessages, setLoadingMessages] = useState<any[]>([
    { text: "Initializing Maven AI...", showIndicator: true }
  ]);

  useEffect(() => {
    const messagesToAdd = [
      { text: "Preparing an SQL query.", showIndicator: true },
      { text: "Scanning millions of rows.", showIndicator: true },
      { text: "Aggregating the results.", showIndicator: true },
      { text: "Formatting the results.", showIndicator: true }
    ];
    let count = 0;

    if (waitingForResponse) {
      onProgress && onProgress();

      const timer = setInterval(() => {
        setLoadingMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          // Change showIndicator of first message to false when the second message is added
          if (count === 0) {
            newMessages[0].showIndicator = false;
          }

          if (count > 0 && count < messagesToAdd.length) {
            newMessages[newMessages.length - 1].showIndicator = false;
          }

          if (count < messagesToAdd.length) {
            newMessages.push({ ...messagesToAdd[count] });
            count++;
          }
          setTimeout(() => {
            onProgress && onProgress();
          }, 200);

          return newMessages;
        });

        if (count === messagesToAdd.length) {
          clearInterval(timer);
        }
      }, 2000);

      return () => {
        clearInterval(timer);
      };
    } else {
      setLoadingMessages([
        { text: "Initializing Maven AI...", showIndicator: true }
      ]);
    }
  }, [waitingForResponse]);


  const sendFeedback  = (messageIds: string[]) => {
    console.log("Feedback sent for messages: ", messageIds);
    if (activeChat?.chat_id) {
      service.feedback(activeChat?.chat_id, messageIds, true).then(() => {
        console.log("Feedback sent");
        showNotification({
          title: "Feedback sent",
          message: "Thank you for your feedback",
          autoClose: 6000
        });
      });
    }
  }


  return (
    <Box sx={{ flex: 0, width: "100%" }}>
      {/*{JSON.stringify(messages)}*/}
      <Stack gap={"xs"} mb={"xs"} mt={"xl"}>
        {
          showLoading && (
            <ChatMessage
              messages={[{
                text: "Loading data...",
                showIndicator: true
              }]}
              isUser={false}
            />
          )
        }
        {
          messages.map((message, index) => {

            return (
              <>
                {
                  message.role === "user" ? (
                    <ChatRichMessage
                      key={index}
                      data={{ text: message.content as string }}
                      isUser={true}
                      username={message.user_name}
                    />
                  ) : message.role === "assistant" && message?.responses ? (
                    <ChatRichMessage
                      key={index}
                      data={{
                        text: message?.responses?.[0]?.content as string,
                        chart_data: message?.responses?.[1]?.content as any,
                        table_data: message?.responses?.[2]?.content as any
                      }}
                      isUser={false}
                      onRate={() => sendFeedback(message?.responses?.map((response: TChatMessage) => response.id) as string[])}

                    />
                  ) : null
                }
              </>
            )
          })
        }
      </Stack>

      {
        waitingForResponse && (
          <ChatMessage
            messages={loadingMessages}
            isUser={false}
          />
        )
      }
    </Box>
  );
};

export default ChatScreen;