/* eslint-disable react-hooks/exhaustive-deps */
import { useIntersection, useLocalStorage, useMediaQuery } from "@mantine/hooks";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { BulletListSkeleton } from "@/lib/utils/ChartsSkeletons";
import { LOADER_COLOR, PALE_SHADES } from "@/lib/utils/Constants";
import { LS_TOP_DEVICES_PREFERENCE } from "@/lib/utils/Storage";
import { capitalizeFirstLetter, numberToCommas, numberToUnit } from "@/lib/utils/StringUtility";
import React, { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Oval } from "react-loader-spinner";
import { PieChartColorsChartsSequence } from "@/style/Colors";
import { DevicesObjectProps, LoadingStateProps, LooseObject, TopDevicesProps } from "types/types.d";
import { NoResults } from "../NoResults/NoResults";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  List,
  Paper,
  SimpleGrid,
  Space,
  Text,
  Title,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import useDeepCompareEffect from "@/hooks/useDeepCompareEffect";
import { DynoTable, ProgressCell } from "../../Common/DynoTable/DynoTable";
import classes from "./WebAnalyticsOverview.module.css";
import clsx from "clsx";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
const waService = new WebAnalyticsService();

const TopDevicesNavList: Array<DevicesObjectProps> = [
  { key: "device_type", value: "Type" },
  { key: "browser", value: "Browser" },
  { key: "os", value: "OS" },
  { key: "screen", value: "Screen Size" }
];

const TopDevices = ({ query, setQuery }: any) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [hasBecomeVisibleYet, setHasBecomeVisibleYet] = useState(false);

  const [isTableViewToggle, setIsTableViewToggle] = useState(false);
  const [activeTab, setActiveTab] = useLocalStorage<TopDevicesProps>({
    key: LS_TOP_DEVICES_PREFERENCE,
    defaultValue: "device_type",
    getInitialValueInEffect: false
  });
  const [page, setPage] = useState(0);
  const [list, setList] = useState<
    Array<{
      device_source: string;
      visitors: number;
      source_with_value: {
        value: number;
        source: string;
      };

      visitors_comparison?: number;
      visitors_percentage_change?: number;
      visitors_percentage: number;
      visitors_percentage_comparison?: number;
      visitors_percentage_percentage_change?: number;
      bounce_rate: number;
      bounce_rate_comparison?: number;
      bounce_rate_percentage_change?: number;
    }>
  >([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const options = {
    tooltip: {
      backgroundColor: colorScheme === "dark" ? theme.colors.dark[9] : "#fff",
      textStyle: {
        color: colorScheme === "dark" ? "#A6A7AB" : "#595c5f"
      },
      trigger: "item"
    },
    legend: {
      top: "5%",
      left: "center",
      show: false
    },
    series: [
      {
        color: [],
        name: capitalizeFirstLetter(activeTab.replace("_", " ")),
        type: "pie",
        radius: ["30%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
          color: colorScheme === "dark" ? "#A6A7AB" : "#242424"
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 16,
            fontWeight: "bold"
          }
        },
        labelLine: {
          show: false
        },
        data: [{ value: 0, name: "" }]
      }
    ]
  };
  // fetch service calls

  const fetchDevices = async () => {
    setIsTableViewToggle(activeTab === "device_type" ? false : isTableViewToggle);
    setLoading("loading");
    await waService
      .topDevices(activeWorkspace.id, activeTab, 1, query)
      .then((res) => {
        if (res.data) {
          setHasMore(res.data.data.length > 10);
          setList(res.data.data);
          if (res.data.columns) {
            const highestValue = res.data.highest_value;
            const barColor =
              activeTab === "browser"
                ? PALE_SHADES.pink
                : activeTab === "os"
                ? PALE_SHADES.orange
                : PALE_SHADES.blue;
            // Modify columns before setting the state
            const modifiedColumns = res.data.columns.map((column: { accessor: string }) => {
              if (column.accessor === "source_with_value") {
                return {
                  ...column,
                  render: (data: any) => (
                    <ProgressCellWithClick
                      barColor={barColor}
                      value={highestValue === 0 ? 0 : Math.floor((data.value / highestValue) * 100)}
                      onClick={setFilterDevices}
                      cellData={data}
                    />
                  )
                };
              }
              if (column.accessor === "visitors" || column.accessor === "conversions") {
                return {
                  ...column,
                  render: (data: any) => <Text fw={400}>{numberToCommas(data)}</Text>
                };
              }
              if (column.accessor === "conversion_rate") {
                return {
                  ...column,
                  render: (data: any) => (
                    <Text fw={400}>
                      {data !== undefined && data !== null ? `${data.toFixed(1)}%` : ""}
                    </Text>
                  )
                };
              }
              if (column.accessor.endsWith("percentage_change")) {
                return {
                  ...column,
                  render: ({ label, percentage_change, comparison_value }: any) => (
                    <Tooltip.Floating
                      label={`Comparison period ${label}: ${comparison_value}${
                        label === "CR" ? "%" : ""
                      }`}
                      position="top"
                      style={{ fontSize: "12px" }}>
                      <Text
                        fw={400}
                        color={
                          percentage_change !== undefined && percentage_change !== null
                            ? percentage_change > 0
                              ? "green.6"
                              : percentage_change < 0
                              ? "red.6"
                              : "gray.6"
                            : "gray.6"
                        }>
                        {percentage_change !== undefined &&
                        percentage_change !== null &&
                        percentage_change !== 0
                          ? `${percentage_change > 0 ? "+" : ""}${percentage_change.toFixed(1)}%`
                          : ""}
                      </Text>
                    </Tooltip.Floating>
                  )
                };
              }
              return column;
            });
            setTableColumns(modifiedColumns);
          }
          if (res.data.length < 20) {
            setHasMore(false);
          }
        }
      })
      .catch((err) => {
        setList([]);
        setHasMore(false);
      });
    setLoading("loaded");
  };

  const ProgressCellWithClick = ({ barColor, value, onClick, cellData }: any) => {
    return (
      <Box onClick={() => onClick(cellData.source)}>
        <ProgressCell
          barColor={barColor}
          value={value}
          label={
            <Flex justify={"space-between"} align={"center"}>
              <Text>{cellData.source}</Text>
            </Flex>
          }
        />
      </Box>
    );
  };

  const fetchMoreDevices = async () => {
    setPage(page + 1);
    await waService
      .topDevices(activeWorkspace.id, activeTab, page + 1, query)
      .then((res) => {
        if (res.data) {
          setHasMore(res.data.data.length > 10);
          setList(list.concat(res.data.data));
          if (res.data.length < 20) {
            setHasMore(false);
          }
        }
      })
      .catch((err) => {
        setHasMore(false);
      });
  };

  const getColorRange = () => {
    return activeTab === "browser"
      ? PieChartColorsChartsSequence["pink"]
      : activeTab === "os"
      ? PieChartColorsChartsSequence["orange"]
      : activeTab === "device_type"
      ? PieChartColorsChartsSequence["purple"]
      : PieChartColorsChartsSequence["blue"];
  };
  const getTopDevicesPieChartValues = (): LooseObject => {
    const colorsTheme = getColorRange();

    let slicedList = list.slice(0, 5).map((item, index) => {
      return {
        name: item.device_source,
        // label: item.device_source,
        value: item.source_with_value.value
        // color: colorsTheme[index],
      };
    });

    if (activeTab !== "device_type") {
      slicedList = slicedList.concat({
        name: "Others",
        // label: "Others",
        value: list.slice(5).reduce((acc, item) => acc + item.source_with_value.value, 0)
        // color: colorsTheme[5],
      });
    }
    options.series[0].data = slicedList;
    options.series[0].color = colorsTheme.reverse();
    console.log(options, "options");
    return options;
  };

  const setFilterDevices = (value: any) => {
    setQuery({
      ...query,
      filters: [
        ...query.filters.filter((value: string) => !value.startsWith(`${activeTab}:`)),
        `${activeTab}:${value}`
      ]
    });
  };

  const _onEvents = {
    click: (params: any) => {
      setFilterDevices(params.name);
    }
  };

  const containerRef = useRef();
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 0
  });

  useEffect(() => {
    if (entry?.isIntersecting && !hasBecomeVisibleYet) {
      setHasBecomeVisibleYet(true);
      fetchDevices();
    }
  }, [entry?.isIntersecting]);

  useDeepCompareEffect(() => {
    if (hasBecomeVisibleYet && entry?.isIntersecting) {
      setList([]);
      setPage(1);
      setHasMore(true);
      fetchDevices();
    } else {
      setHasBecomeVisibleYet(false);
    }
  }, [activeTab, query, query.filters, activeWorkspace.id]);
  return (
    <Paper withBorder shadow="xs" ref={ref} pos={"relative"}>
      <Box h={520} component="div">
        <Flex component="div" p="md" align={"center"}>
          <Title flex={1} order={6} fw={600}>
            Devices
          </Title>
          <Flex>
            <Group gap={8}>
              {TopDevicesNavList.map((item: DevicesObjectProps, index) => (
                <div data-cy="top-devices-filter-nav-list" key={index}>
                  <Text
                    fz={13}
                    className={clsx(classes.tab, activeTab === item.key && classes.activeTab)}
                    fw={500}
                    key={`top-devices:${index}`}
                    onClick={() => setActiveTab(item.key)}>
                    {item.value}
                  </Text>
                </div>
              ))}
            </Group>
          </Flex>
        </Flex>
        <Divider />
        <div className="" id="WebAnalytics:TopSources">
          {loading === "loaded" && !isTableViewToggle ? (
            <>
              {list.length > 0 ? (
                <>
                  <Grid>
                    <Grid.Col span={6}>
                      <Flex mt={48} justify={"center"} h={240} w={"100%"} ml={32}>
                        {loading === "loaded" && list.length > 0 && (
                          <Box w={"100%"} h={"100%"} data-cy="top-devices-pie-chart">
                            <ReactEChartsCore
                              style={{ height: isMobile ? "220px" : "350px" }}
                              onEvents={_onEvents}
                              lazyUpdate={true}
                              echarts={echarts}
                              option={getTopDevicesPieChartValues()}
                            />
                          </Box>
                        )}
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      {list.length > 0 && (
                        <Flex
                          pt={100}
                          direction={"column"}
                          align={"center"}
                          justify={isMobile ? "justify-start" : "center"}
                          className="flex items-center justify-center sm:justify-start"
                          style={{
                            marginTop: isMobile ? "0.3rem" : "3rem"
                          }}>
                          <List w={"80%"}>
                            {loading === "loaded" && (
                              <Box data-cy="top-devices-results-list" w={"100%"}>
                                {list.slice(0, 5).map((item: LooseObject, index: number) => (
                                  <Flex
                                    align={"center"}
                                    onClick={() => setFilterDevices(item.device_source)}
                                    className="flex items-center cursor-pointer hover:underline"
                                    key={`deviceItem:${index}:${item.device_source}`}>
                                    <Flex w={"75%"} align={"center"} flex={1}>
                                      <Box
                                        h={8}
                                        mr={8}
                                        w={8}
                                        style={{
                                          borderRadius: "50%",
                                          backgroundColor: getColorRange()[index]
                                        }}></Box>
                                      <Text>
                                        {item.device_source ? (
                                          <>{item.device_source}</>
                                        ) : (
                                          <>Unknown</>
                                        )}{" "}
                                      </Text>
                                    </Flex>
                                    <Text size="xs" fw={700} pr={4}>
                                      {item?.source_with_value.percentage}%
                                    </Text>
                                    <Text size="xs" c="dimmed">
                                      ({numberToUnit(item.source_with_value.value)})
                                    </Text>
                                  </Flex>
                                ))}
                                {activeTab !== "device_type" &&
                                  list.slice(5).reduce((acc, item) => acc + item.visitors, 0) >
                                    0 && (
                                    <>
                                      <Flex align={"center"}>
                                        <Flex w={"75%"} align={"center"} flex={1}>
                                          <Box
                                            h={8}
                                            mr={8}
                                            w={8}
                                            style={{
                                              borderRadius: "50%",
                                              backgroundColor: getColorRange()[5]
                                            }}></Box>
                                          <Text>Others</Text>
                                        </Flex>
                                        <Text size="xs" fw={700} pr={4}>
                                          {list
                                            .slice(5)
                                            .reduce(
                                              (acc, item) => acc + item.visitors_percentage,
                                              0
                                            )
                                            .toFixed(2)}
                                          %
                                        </Text>
                                        <Text size="xs" c="dimmed">
                                          (
                                          {numberToUnit(
                                            list
                                              .slice(5)
                                              .reduce((acc, item) => acc + item.visitors, 0)
                                          )}
                                          )
                                        </Text>
                                      </Flex>
                                    </>
                                  )}
                              </Box>
                            )}
                          </List>
                        </Flex>
                      )}
                    </Grid.Col>
                  </Grid>
                </>
              ) : (
                <>
                  <Flex align={"center"} justify={"center"} h={400}>
                    <NoResults text={"There are no devices."} heading={"No Results"} />
                  </Flex>
                </>
              )}
            </>
          ) : (
            <>
              {loading === "loaded" ? (
                <>
                  {list.length > 0 ? (
                    <InfiniteScroll
                      key={"sources-infinite-scroll"}
                      dataLength={list.length}
                      next={fetchMoreDevices}
                      hasMore={hasMore}
                      // scrollThreshold={0.8}
                      height={"28rem"}
                      scrollableTarget="WebAnalytics:TopSources"
                      loader={
                        <div className="um-virtual-table-loader">
                          <Oval color={LOADER_COLOR} height={16} width={16} />
                        </div>
                      }>
                      <Box
                        component="div"
                        data-cy="top-devices-table-view-results-list"
                        style={{
                          "&::-webkit-scrollbar": {
                            display: "none" // hides scrollbar for Webkit browsers
                          },
                          "-ms-overflow-style": "none", // hides scrollbar for IE and Edge
                          scrollbarWidth: "none" // hides scrollbar for Firefox
                        }}>
                        <DynoTable columns={tableColumns} data={list} />
                      </Box>
                    </InfiniteScroll>
                  ) : (
                    <div className="pt-28">
                      <NoResults text={"There is no data for devices."} heading={"No Results"} />
                    </div>
                  )}
                </>
              ) : (
                <div className="ml-4">
                  <BulletListSkeleton />
                </div>
              )}
            </>
          )}

          {loading === "loaded" && loading.length > 0 && activeTab !== "device_type" && (
            <>
              <Flex
                pos={"absolute"}
                bottom={0}
                w={"100%"}
                justify={"center"}
                align={"center"}
                onClick={() => {
                  setIsTableViewToggle(!isTableViewToggle);
                }}
                data-cy="top-devices-table-view-toggle-btn">
                <Center>
                  <Button autoContrast variant="subtle" mb={"sm"} size="xs" color="black.9">
                    {!isTableViewToggle ? <>View in</> : <>Hide</>} table view
                  </Button>
                </Center>
              </Flex>
            </>
          )}
        </div>
      </Box>
    </Paper>
  );
};
const TopDevicesMemoized = React.memo(TopDevices);
export { TopDevicesMemoized as TopDevices };
