/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { BulletListSkeleton } from "@/lib/utils/ChartsSkeletons";
import React, { useContext, useState } from "react";
import { LoadingStateProps, UTMTypesObjectProps } from "types/types.d";
import { NoResults } from "../NoResults/NoResults";
import { ViewDetailedButton } from "./ViewDetailedButton";
import { useLocalStorage } from "@mantine/hooks";
import { LS_UTM_TRAFFIC_PREFERENCE } from "@/lib/utils/Storage";
import { UTMTrafficDetailedView } from "./DetailedView/UTMTrafficDetailedView";
import { Box, Divider, Flex, Group, List, Paper, Table, Text, Title, Tooltip } from "@mantine/core";
import useDeepCompareEffect from "@/hooks/useDeepCompareEffect";
import { DynoTable, ProgressCell } from "../../Common/DynoTable/DynoTable";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { PALE_SHADES } from "@/lib/utils/Constants";
import classes from "./WebAnalyticsOverview.module.css";
import clsx from "clsx";
const waService = new WebAnalyticsService();

const UTMTrafficNav: Array<UTMTypesObjectProps> = [
  { key: "utm_source", value: "Source" },
  { key: "utm_medium", value: "Medium" },
  { key: "utm_campaign", value: "Campaign" },
  { key: "utm_term", value: "Term" },
  { key: "utm_content", value: "Content" }
];

const UTMTraffic = ({ query, setQuery, isPublic }: any) => {
  const [opened, setOpened] = useState(false);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [activeTab, setActiveTab] = useLocalStorage({
    key: LS_UTM_TRAFFIC_PREFERENCE,
    defaultValue: "utm_source",
    getInitialValueInEffect: false
  });

  // setting list for results
  const [list, setList] = useState<
    Array<{
      source: string;
      visitors: number;
      visitors_comparison?: number;
      visitors_percentage_change?: number;
      bounce_rate: number;
      bounce_rate_comparison?: number;
      bounce_rate_percentage_change?: number;
      visitors_percentage: number;
      visitors_percentage_comparison?: number;
      visitors_percentage_percentage_change?: number;
    }>
  >([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);

  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  // const isMobile = useMediaQuery("(max-width: 920px)");

  // fetch service calls
  const fetchUTMs = async () => {
    setLoading("loading");
    await waService
      .UTMTraffic(activeWorkspace.id, activeTab, "list", 1, 10, "visitors:desc", query)
      .then((res) => {
        if (res.data) {
          setList(res.data.data);
          if (res.data.columns) {
            const highestValue = res.data.highest_value;
            let columns = res.data.columns;
            // exclude the columns that starts with "bounce_rate"
            if (columns) {
              columns = columns.filter(
                (column: { accessor: string }) => !column.accessor.startsWith("bounce_rate")
              );
            }
            // Modify columns before setting the state
            const modifiedColumns = columns.map((column: { accessor: string }) => {
              if (column.accessor === "source_with_value") {
                return {
                  ...column,
                  render: (data: any) => (
                    <ProgressCellWithClick
                      barColor={PALE_SHADES.pink}
                      value={highestValue === 0 ? 0 : Math.floor((data.value / highestValue) * 100)}
                      onClick={handleOnClick}
                      cellData={data}
                    />
                  )
                };
              }
              if (column.accessor === "visitors") {
                return {
                  ...column,
                  render: (data: any) => <Text fw={400}>{numberToCommas(data)}</Text>
                };
              }
              if (column.accessor === "visitors_percentage") {
                return {
                  ...column,
                  render: (data: any) => (
                    <Text fw={400}>
                      {data !== undefined && data !== null ? `${data.toFixed(1)}%` : ""}
                    </Text>
                  )
                };
              }
              if (column.accessor.endsWith("percentage_change")) {
                return {
                  ...column,
                  render: ({ label, percentage_change, comparison_value }: any) => (
                    <Tooltip.Floating
                      label={`Comparison period ${label}: ${comparison_value}${
                        label === "CR" ? "%" : ""
                      }`}
                      position="top"
                      style={{ fontSize: "12px" }}>
                      <Text
                        fw={400}
                        color={
                          percentage_change !== undefined && percentage_change !== null
                            ? percentage_change > 0
                              ? "green.6"
                              : percentage_change < 0
                              ? "red.6"
                              : "gray.6"
                            : "gray.6"
                        }>
                        {percentage_change !== undefined &&
                        percentage_change !== null &&
                        percentage_change !== 0
                          ? `${percentage_change > 0 ? "+" : ""}${percentage_change.toFixed(1)}%`
                          : ""}
                      </Text>
                    </Tooltip.Floating>
                  )
                };
              }
              return column;
            });
            setTableColumns(modifiedColumns);
          }
        }
      })
      .catch((err) => {
        setList([]);
      });
    setLoading("loaded");
  };

  const ProgressCellWithClick = ({ barColor, value, onClick, cellData }: any) => {
    return (
      <Box onClick={() => onClick(cellData.source)}>
        <ProgressCell
          barColor={barColor}
          value={value}
          label={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <Text>{cellData.source}</Text>
            </Box>
          }
        />
      </Box>
    );
  };

  // on clicking any of the utm, it will be added to the query
  const handleOnClick = (source: string) => {
    setQuery({
      ...query,
      filters: [
        ...query.filters.filter((value: string) => !value.startsWith(`${activeTab}:`)),
        `${activeTab}:${source}`
      ]
    });
  };

  useDeepCompareEffect(() => {
    fetchUTMs();
  }, [activeTab, query, query.filters, activeWorkspace.id]);

  function BrowserUsageTable() {
    const data = [
      { browser: "Chrome", visitors: 162000, percentage: 58.1 },
      { browser: "Safari", visitors: 71900, percentage: 25.7 },
      { browser: "Firefox", visitors: 30300, percentage: 10.9 },
      { browser: "Microsoft Edge", visitors: 10200, percentage: 3.7 },
      { browser: "Opera", visitors: 883, percentage: 0.3 },
      { browser: "Samsung Browser", visitors: 748, percentage: 0.3 },
      { browser: "Mobile App", visitors: 454, percentage: 0.2 },
      { browser: "Yandex Browser", visitors: 268, percentage: 0.1 },
      { browser: "(not set)", visitors: 128, percentage: 0 }
    ];

    const getBackgroundColor = (percentage: number) => {
      const hue = percentage * 1.2; // Adjust the multiplier to change color range
      return `hsl(${hue}, 100%, 90%)`;
    };

    const rows = data.map((item) => (
      <tr key={item.browser} style={{ background: getBackgroundColor(item.percentage) }}>
        <td>{item.browser}</td>
        <td>{item.visitors.toLocaleString()}</td>
        <td>{item.percentage}%</td>
      </tr>
    ));

    return (
      <Table>
        <thead>
          <tr>
            <th>Browser</th>
            <th>Visitors</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  }

  return (
    <Paper withBorder shadow="xs">
      {/* Modal for detailed view */}
      <UTMTrafficDetailedView
        query={query}
        opened={opened}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setOpened={setOpened}
        handleOnClick={handleOnClick}
        UTMTrafficNav={UTMTrafficNav}
        isPublic={isPublic}
      />
      <Box h={500} component="div">
        <Flex
          component="div"
          sx={(theme) => ({
            flexWrap: "wrap"
          })}
          align={"center"}
          p={"md"}>
          <Title flex={1} order={6} fw={600}>
            UTM Traffic
          </Title>
          <Flex>
            <Flex className="font-medium flex cursor-pointer transition-all ease-in-out">
              <Group gap={8}>
                {UTMTrafficNav.map((item: UTMTypesObjectProps, index) => (
                  <div data-cy="utm-traffic-filter-nav-list" key={index}>
                    <Text
                      className={clsx(classes.tab, activeTab === item.key && classes.activeTab)}
                      fw={500}
                      fz={13}
                      key={`top-sources:${index}`}
                      // className={` pr-2 hover:text-purple-500  transition-all ease-in-out `}
                      onClick={() => setActiveTab(item.key)}>
                      {item.value}
                    </Text>
                  </div>
                ))}
              </Group>
            </Flex>
          </Flex>
        </Flex>
        <Divider />
        <div className="overflow-y-scroll" id="WebAnalytics:UTM">
          {loading === "loaded" ? (
            <>
              {list.length > 0 ? (
                <div className="overflow-y-scroll">
                  {/* <BrowserUsageTable /> */}
                  <DynoTable columns={tableColumns} data={list} />
                  {list.length >= 10 && (
                    <div data-cy="utm-traffic-detailed-view-btn">
                      <ViewDetailedButton
                        onClick={() => {
                          setOpened(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <Flex align={"center"} justify={"center"} h={400}>
                    <NoResults text={"There is no UTM traffic."} heading={"No Results"} />
                  </Flex>
                </>
              )}
            </>
          ) : (
            <div className="ml-4">
              <BulletListSkeleton />
            </div>
          )}
        </div>
      </Box>
    </Paper>
  );
};

const UTMTrafficMemoized = React.memo(UTMTraffic);
export { UTMTrafficMemoized as UTMTraffic };
