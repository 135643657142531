/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// @ts-ignore
import { light, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  Flex,
  Loader,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
  Center,
  useMantineColorScheme
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { InsightsService } from "@/lib/services/InsightsService";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { LOADER_COLOR, PALE_SHADES, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { useContext, useEffect, useRef, useState } from "react";
import { Oval } from "react-loader-spinner";
import { LoadingStateProps } from "types/types.d";
import { ConversionGoalModal } from "./ConversionGoalModal";
import { Button } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { toast } from "react-toastify";
import { useConversionGoalsStore } from "@/stores/useConversionGoalsStore";
import { Paper } from "@mantine/core";
import { numberToCommas } from "@/lib/utils/StringUtility";
import getSymbolFromCurrency from "currency-symbol-map";
import { useIntersection, useMediaQuery } from "@mantine/hooks";
import { PulseLineGraph } from "../../PulseLineGraph/PulseLineGraph";
import useDeepCompareEffect from "@/hooks/useDeepCompareEffect";
import { DynoTable, ProgressCell } from "@/ui/components/Common/DynoTable/DynoTable";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const ConversionGoals = ({ query, setQuery, isPublic = false }: any) => {
  const isMobile = useMediaQuery("(max-width: 920px)");
  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const { hasRolesNotWith, isStarterPlan } = useWorkspaceUtilityHook();

  const [isConversionGoalModalOpen, setIsConversionGoalModalOpen] = useState(false);
  const [isEditConversionGoal, setIsEditConversionGoal] = useState(false);
  const [goals, setGoals] = useConversionGoalsStore((state) => [state.list, state.setList]);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [individualGoal, setIndividualGoal] = useState<any>({});
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const canEditorDelete = isPublic ? false : hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]);

  /**
   * Fetch all conversion funnels for web analytics. This is used to display the conversion funnel cards.
   */
  const fetchConversionGoals = async () => {
    setLoading("loading");
    setGoals([]);
    await new WebAnalyticsService()
      .goals(activeWorkspace.id, query)
      .then((res) => {
        const conversionGoals = res.data.data;
        conversionGoals ? setGoals(conversionGoals) : setGoals([]);
        const highestValue = conversionGoals[0].visits_count;

        if (res.data.columns) {
          // Modify columns before setting the state
          const modifiedColumns = res.data.columns.map((column: { accessor: string }) => {
            if (column.accessor === "goal_with_value") {
              return {
                ...column,
                render: (data: any) => (
                  <ProgressCellWithHover
                    value={highestValue === 0 ? 0 : Math.floor((data.value / highestValue) * 100)}
                    cellData={data}
                    conversionGoals={conversionGoals}
                    canEditorDelete={canEditorDelete}
                    onEdit={editConversionGoal}
                    onDelete={removeGoalPrompt}
                    onClick={handleGoalClick}
                  />
                )
              };
            }
            if (column.accessor === "conversions_pulse") {
              return {
                ...column,
                ...(Array.isArray(res.data.data[0][column.accessor]) && {
                  render: (data: any) => (
                    <PulseLineGraph
                      data={data}
                      name="Conversion Goals"
                      color={theme.colors.brand[5]}
                      period={query.period}></PulseLineGraph>
                  )
                })
              };
            }
            if (column.accessor === "visits_count" || column.accessor === "total_conversions") {
              return {
                ...column,
                render: (data: any) => <Text fw={400}>{numberToCommas(data)}</Text>
              };
            }
            if (column.accessor === "conv_rate") {
              return {
                ...column,
                render: (data: any) => <Text fw={400}>{`${data}%`}</Text>
              };
            }
            if (column.accessor === "total_conversion_value") {
              return {
                ...column,
                render: (data: any) => (
                  <Text fw={400}>{`${getSymbolFromCurrency(
                    activeWorkspace.reporting_currency
                  )}${numberToCommas(data)}`}</Text>
                )
              };
            }
            if (column.accessor.endsWith("percentage_change")) {
              return {
                ...column,
                render: ({ label, percentage_change, comparison_value }: any) => (
                  <Tooltip.Floating
                    label={`Comparison period ${label}: ${comparison_value}${
                      label === "CR" ? "%" : ""
                    }`}
                    position="top"
                    style={{ fontSize: "12px" }}>
                    <Text
                      fw={400}
                      color={
                        percentage_change !== undefined && percentage_change !== null
                          ? percentage_change > 0
                            ? "green.6"
                            : percentage_change < 0
                            ? "red.6"
                            : "gray.6"
                          : "gray.6"
                      }>
                      {percentage_change !== undefined &&
                      percentage_change !== null &&
                      percentage_change !== 0
                        ? `${percentage_change > 0 ? "+" : ""}${percentage_change.toFixed(1)}%`
                        : ""}
                    </Text>
                  </Tooltip.Floating>
                )
              };
            }
            return column;
          });
          goals && setTableColumns(modifiedColumns);
        }
      })
      .catch((err) => err);
    setLoading("loaded");
  };

  /**
   * Opens an edit mode for the conversion funnel
   * @param id - the id of the conversion funnel to be fetched
   */
  const editConversionGoal = (id: string) => {
    new InsightsService()
      .get(activeWorkspace.id, id)
      .then((res) => {
        if (res.data) {
          setIndividualGoal(res.data);
          setIsEditConversionGoal(true);
          setIsConversionGoalModalOpen(true);
        }
      })
      .catch((err) => {});
  };

  /**
   * Open a funnel conversion model and clear the form fields and conditions.
   */
  const toggleCreateModal = () => {
    if (isStarterPlan && goals.length >= 3) {
      toast.error("Please upgrade your plan to create more conversion goals");
      return;
    }
    setIndividualGoal({});
    setIsConversionGoalModalOpen(true);
    setIsEditConversionGoal(false);
  };

  const handleGoalClick = (goal: any) => {
    setQuery({
      ...query,
      filters: [
        ...query.filters.filter((value: string) => !value.startsWith(`goal:`)),
        `goal:${goal.id}`
      ]
      // domain: "all_domains",
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const deleteConversionGoal = (id: string) => {
    new InsightsService()
      .delete(activeWorkspace.id, id)
      .then((res) => {
        if (res.data) {
          toast.success("Conversion goal deleted successfully");
          fetchConversionGoals();
        }
      })
      .catch((err) => {});
  };

  const removeGoalPrompt = (goal: any) => {
    openConfirmModal({
      title: "Delete conversion goal",
      children: (
        <Text size="sm">
          Are you sure you want to delete your <span className="font-semibold">{goal.name}</span>{" "}
          conversion goal?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red.6", className: "confirmation-modal-confirm-btn" },

      onCancel: () => console.log("Cancel"),
      onConfirm: () => deleteConversionGoal(goal.id)
    });
  };

  const [hasBecomeVisibleYet, setHasBecomeVisibleYet] = useState(false);

  const containerRef = useRef();
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 0
  });

  const renderNewGoalButton = () => {
    if (isPublic) return <></>;
    return (
      <div>
        {goals.length > 0 &&
          loading === "loaded" &&
          hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
            <>
              <Button
                size={"xs"}
                color={colorScheme === "dark" ? "dark.2" : undefined}
                variant="outline"
                onClick={() => {
                  toggleCreateModal();
                }}
                data-cy="new-conversion-goal-btn">
                New Goal
              </Button>
            </>
          )}
      </div>
    );
  };

  const renderNewGoalButtonIfNoGoals = () => {
    if (isPublic) return <></>;
    return (
      <>
        {hasRolesNotWith([WORKSPACE_MEMBER_ROLES.VIEWER]) && (
          <Button
            color={colorScheme === "dark" ? "dark.2" : undefined}
            size="xs"
            variant="outline"
            onClick={() => toggleCreateModal()}
            data-cy="create-conversion-goal-btn">
            Create Goal
          </Button>
        )}
      </>
    );
  };

  const ProgressCellWithHover = ({
    value,
    cellData,
    conversionGoals,
    canEditorDelete,
    onEdit,
    onDelete,
    onClick
  }: any) => {
    const [isHovered, setIsHovered] = useState(false);

    const goal = conversionGoals.find((goal: any) => {
      return goal.id === cellData.id;
    });

    return (
      <Box onClick={() => onClick(goal)}>
        <ProgressCell
          value={value}
          label={
            <LabelWithActions
              data={cellData}
              currentGoal={goal}
              canEditorDelete={canEditorDelete}
              onEdit={onEdit}
              onDelete={onDelete}
              isHovered={isHovered}
            />
          }
          barColor={PALE_SHADES.purple}
          setIsHovered={setIsHovered}
          rowSpacing={"0.75rem"}
        />
      </Box>
    );
  };

  const LabelWithActions = ({
    data,
    currentGoal,
    canEditorDelete,
    onEdit,
    onDelete,
    isHovered
  }: any) => {
    return (
      <Flex justify={"space-between"} align={"center"}>
        <Text>{data.name}</Text>
        {canEditorDelete && isHovered && (
          <Flex align={"center"}>
            <Box
              pl={"0.5rem"}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(currentGoal.id);
              }}>
              <FontAwesomeIcon
                icon={regular("edit")}
                className="w-4 h-4 ml-1.5 hover:opacity-80"
                data-cy="bar-item-edit-icon"
              />
            </Box>
            <Box
              pl={"0.5rem"}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(currentGoal);
              }}
              c="red">
              <FontAwesomeIcon
                icon={regular("trash")}
                className="w-4 h-4 text-red-600  hover:opacity-80"
                data-cy="bar-item-delete-icon"
              />
            </Box>
          </Flex>
        )}
      </Flex>
    );
  };

  useEffect(() => {
    if (entry?.isIntersecting && !hasBecomeVisibleYet) {
      setHasBecomeVisibleYet(true);
      fetchConversionGoals();
    }
  }, [entry?.isIntersecting]);

  useDeepCompareEffect(() => {
    if (hasBecomeVisibleYet && entry?.isIntersecting) {
      fetchConversionGoals();
    } else {
      setHasBecomeVisibleYet(false);
    }
  }, [query, query.filters]);
  return (
    <Paper shadow="xs" withBorder ref={ref} mih={250}>
      {!isPublic && (
        <>
          <ConversionGoalModal
            query={query}
            setQuery={setQuery}
            isEdit={isEditConversionGoal}
            isOpen={isConversionGoalModalOpen}
            onClose={setIsConversionGoalModalOpen}
            reload={() => fetchConversionGoals()}
            goal={individualGoal}
          />
        </>
      )}

      <Box
        component="div"
        style={(theme) => ({
          borderColor:
            colorScheme === "dark"
              ? theme.colors.darkBorderColor[0]
              : theme.colors.lightBorderColor[0]
        })}
        className="min-h-[20rem] pb-2 flex flex-col border border-solid  rounded shadow-sm">
        <Flex component="div" align={"center"} p={"md"}>
          <Title flex={1} order={6} fw={600}>
            Conversion Goals
          </Title>

          {renderNewGoalButton()}
        </Flex>
        <Divider />
        {loading === "loaded" ? (
          <>
            {goals.length === 0 ? (
              <>
                <Flex h={"200"} direction="column" justify={"center"} align={"center"}>
                  <Box mt={"lg"} c="gray" mb="md">
                    <FontAwesomeIcon icon={light("bullseye-arrow")} size="xl" />
                  </Box>

                  <Text mb="md">You have not created any conversion goal.</Text>
                  {renderNewGoalButtonIfNoGoals()}
                </Flex>
              </>
            ) : (
              <DynoTable columns={tableColumns} data={goals} />
            )}
          </>
        ) : (
          <>
            <Center mt={64}>
              <Loader size={"sm"} />
            </Center>
          </>
        )}
      </Box>
    </Paper>
  );
};
