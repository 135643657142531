import { ActionIcon, Box, Button, Group, Select, Text, Textarea } from "@mantine/core";
import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useCustomizeMenuStore } from "@/stores/useCustomizeMenuStore";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { MavenAIService } from "@/lib/services/MavenAIService";
import SplashScreen from "@/ui/pages/MavenAi/components/Assistant/components/SplashScreen";
import ChatScreen from "@/ui/pages/MavenAi/components/Assistant/components/ChatScreen";
import useMavenAIStore from "@/stores/useMavenAIStore";
import { useNavigate } from "react-router-dom";
import { AIChatAskReview } from "@/ui/components/App/AIChat/AIChatAskReview";
import { PLAN_TYPES } from "@/lib/utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useLocalStorage } from "@mantine/hooks";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

const Assistant = () => {
  const theme = useGlobalMantineTheme()
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const { isStarterPlan } = useWorkspaceUtilityHook();
  const [mode, setMode] = useLocalStorage<'fast' | 'smart'>({
    key: "maven-ai-mode",
    defaultValue: "fast"
  });
  const navigate = useNavigate();

  const chatRef = useRef<null | HTMLDivElement>(null);

  const service = new MavenAIService(activeWorkspace?.id || "");

  const [internalAside, setInternalAside] = useCustomizeMenuStore((state: any) => [
    state.internalAside,
    state.setInternalAside
  ]);

  const [activeChat, fetchMessages, fetchChats, fetchingMessages, clearMessages, message, setMessage, addMessage, setMessages, setActiveChat, waitingForResponse, setWaitingForResponse, messages] = useMavenAIStore((state) => [
    state.activeChat,
    state.fetchMessages,
    state.fetchChats,
    state.fetchingMessages,
    state.clearMessages,
    state.inputMessage,
    state.setInputMessage,
    state.addMessage,
    state.setMessages,
    state.setActiveChat,
    state.waitingForResponse,
    state.setWaitingForResponse,
    state.messages
  ]);

  const [reviewOpened, setReviewOpened] = useState(false);

  useEffect(() => {
    if (activeChat) {
      fetchMessages(activeChat.chat_id, activeWorkspace?.id || "").then(() => {
        scrollToBottom();
      });
    }
    //
    // return () => {
    //   if (activeChat) {
    //     setActiveChat(null);
    //     clearMessages();
    //   }
    // };
  }, [activeChat]);


  /**
   * Scroll to the bottom of the chat
   */
  const scrollToBottom = () => {
    console.log("Scrolling to bottom");
    if (chatRef.current) {
      const scrollHeight = chatRef.current.scrollHeight;
      const height = chatRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      chatRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  /**
   * Scroll to top of the chat
   */
  const scrollToTop = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = 0;
    }
  };

  /**
   * Initial scroll to the bottom of the chat
   */
  useEffect(() => {
    if (
      (activeWorkspace.organization.plan.is_lifetime &&
        !activeWorkspace.organization.is_ai_assistant_unlocked) ||
      (isStarterPlan &&
        !activeWorkspace.organization.is_ai_assistant_unlocked)
    ) {
      console.log("Review opened");
      setReviewOpened(true);
    }

    if (activeChat) {
      scrollToBottom();
    } else {
      scrollToTop();
    }
  }, [activeChat, fetchingMessages]);

  const askAssistant = () => {
    scrollToBottom();
    // Validate the message
    if (!message.trim()) {
      return;
    }

    setWaitingForResponse(true);
    setMessage("");
    addMessage({
      content: message,
      role: "user"
    });
    service.ask(message, activeChat ? activeChat.chat_id : undefined, mode).then((response) => {
      console.log(response.data);
      setMessages(response.data.messages);
      setActiveChat({
        chat_id: response.data.chat_id,
        title: response.data.title
      });

      if (!activeChat) {
        fetchChats(activeWorkspace?.id || "");
      }

      scrollToBottom();

      navigate(`/env/${activeWorkspace?.identifier}/maven-ai/${response.data.chat_id}`);
      setWaitingForResponse(false);


    }).catch(() => {
      setWaitingForResponse(false);
      clearMessages();
    });
  };


  return (
    <Box sx={() => ({
      flex: 1,
      display: "flex",
      background: theme.colorScheme === "dark" ? "none" : "radial-gradient(96.24% 3627.57% at 1.53% 50%, rgba(176, 222, 255, 0.3) 5.63%, rgba(234, 205, 255, 0.3) 52.03%, rgba(218, 217, 255, 0.3) 88.94%)",
      maxHeight: "100vh",
      position: "relative"
    })}>
      <Box sx={() => ({
        position: "absolute",
        zIndex: 9,
        top: "0px",
        left: "0px",
        height: "40px",
        width: "100%",
        background: theme.colorScheme === "dark" ? "none" : "radial-gradient(96.24% 3627.57% at 1.53% 50%, rgb(217, 245, 255) 5.63%, rgb(241, 240, 255) 52.03%, rgb(244, 244, 255) 88.94%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 60px"
      })}>
        <Select
          size={'xs'}
          defaultValue={'fast'}
          variant={'default'}
          value={mode}
          onChange={(mode) => setMode(mode as 'fast' | 'smart')}
          data={[
            {
              value: "fast",
              label: "Fast Mode"
            },
            {
              value: "smart",
              label: "Smart Mode"
            }
          ]}
          leftSection={<FontAwesomeIcon icon={regular('bolt')} />}
          styles={{
            root: {
              width: '140px'
            },
            input: {
              border: 'none',
              background: 'none',
              fontWeight: 500,
            }
          }}
        />

   {/*     <Button  color="gray.7" variant="subtle" size={'xs'}
          leftIcon={<FontAwesomeIcon icon={regular('share-alt')} />}
        >
          Share
        </Button>
        */}
      </Box>
      <Box sx={{
        position: "sticky",
        top: "0px",
        left: "0px",
        height: "100vh",
        width: "70px"
      }}>
        <Box
          onClick={() => setInternalAside(!internalAside)}
          className={!internalAside ? "collapsed" : ""}
          sx={{
            position: "absolute",
            top: "50%",
            left: "20px",
            transform: "translateY(-50%)",
            height: "40px",
            width: "4px",
            padding: "10px",
            cursor: "pointer",
            "&:before, &:after": {
              content: "\"\"",
              position: "absolute",
              top: 0,
              width: "6px",
              height: "50%",
              backgroundColor: "#A5A5A5",
              transition: "transform .5s ease-in-out"
            },
            "&:before": {
              // right: 0,
              transformOrigin: "bottom left"
            },
            "&:after": {
              top: "50%",
              // right: 0,
              transformOrigin: "top right"
            },

            "&:hover:before": {
              // Making arrow, rotate
              transform: "rotate(20deg) translateY(1px)"
            },
            "&:hover:after": {
              // Making arrow, rotate
              transform: "rotate(-20deg) translateY(-1px)"
            },

            "&.collapsed": {
              "&:before": {
                // Making arrow, rotate
                transform: "rotate(-20deg) translateY(1px)",
                transformOrigin: "bottom left"
              },
              "&:after": {
                // Making arrow, rotate
                transform: "rotate(20deg) translateY(-1px)",
                transformOrigin: "top right"
              }
            }
          }}
        />
      </Box>
      <Box sx={{
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column"
      }}>
        <Box ref={chatRef} sx={{
          overflow: "auto",
          height: "100%",
          maskImage: "linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 1.5%, rgb(0, 0, 0) 99.5%, rgba(0, 0, 0, 0) 100%)"
        }}>

          <Box sx={{
            width: "100%",
            minHeight: "100%",
            display: "flex",
            alignItems: "center",
            padding: "80px 60px 0px 0",
            flexDirection: "column",
            justifyContent: "space-around",
            position: "relative"
            // overflow: "auto",
            // maskImage: "linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%)"

          }}
          >
            <AIChatAskReview opened={reviewOpened} setOpened={setReviewOpened} />

            {
              !reviewOpened && (
                <>
                  <SplashScreen
                    hideSuggestions={messages.length > 0 || activeChat !== null || waitingForResponse}
                    onSuggestionClick={(suggestion) => {
                      setMessage(suggestion);
                      // askAssistant();
                    }}
                  />
                  <ChatScreen
                    waitingForResponse={waitingForResponse}
                    showLoading={fetchingMessages}
                    onProgress={() => scrollToBottom()}
                  />
                </>
              )
            }

          </Box>

        </Box>
        {
          !reviewOpened && (
            <Box sx={{
              position: "sticky",
              padding: "30px 60px 30px 0",
              bottom: "0px",
              width: "100%"
            }}>
              <Textarea
                placeholder="Write a message"
                value={message}
                onChange={(event) => setMessage(event.currentTarget.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    askAssistant();
                  }
                }}
                autosize
                minRows={1}
                maxRows={4}
                readOnly={waitingForResponse}
                styles={() => ({
                  input: {
                    // height: "48px",
                    borderColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#ECEFF1",
                    paddingRight: "288px",
                    whiteSpace: "pre-wrap"
                  },
                  section: {
                    width: "auto"
                  }
                })}
                rightSection={
                  <Group gap={"xs"} mr={"xs"} wrap={'nowrap'}>
                    {/*  Website Analytics Query*/}
                    <Select
                      variant={"unstyled"}
                      defaultValue={"web_analytics"}
                      leftSection={<AnalyticsIcon />}
                      data={[
                        {
                          value: "web_analytics",
                          label: "Website Analytics Query",
                          // icon: (
                          //   <AnalyticsIcon />
                          // ),
                          // description: "Query your website analytics"
                        },
                        {
                          value: "product_insights",
                          label: "Product Insights (Soon)",
                          // icon: (
                          //   <AnalyticsIcon />
                          // ),
                          // description: "Query your product insights",
                          disabled: true
                        }
                      ]}
                      styles={{
                        input: {
                          width: "240px",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                          letterSpacing: "0em",
                          textAlign: "left"
                        }
                      }}
                      // itemComponent={SelectItem}
                      disabled={waitingForResponse}
                    />
                    <ActionIcon
                      onClick={askAssistant}
                      // onClick={scrollToBottom}
                      disabled={!message || waitingForResponse}
                    >
                      <ArrowIcon />
                    </ActionIcon>
                  </Group>
                }
              />

              <Text ta={'center'} mt={'sm'} size={'xs'}>
                Maven AI can make mistakes. Consider checking important information.
              </Text>
            </Box>
          )
        }
      </Box>
    </Box>
  );
};


export const AnalyticsIcon = ({
                              width = 10,
                              height = 10,
                              }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2100_11322)">
        <g clipPath="url(#clip1_2100_11322)">
          <path
            d="M6.92188 1.18421V8.68421C6.92188 9.52632 7.50082 10 8.10609 10C8.68503 10 9.2903 9.60526 9.2903 8.68421V1.26316C9.2903 0.5 8.71135 0 8.10609 0C7.50082 0 6.92188 0.526316 6.92188 1.18421Z"
            fill="#fd75c7"
          />
          <path
            d="M3.80469 5.00273V8.69781C3.80469 9.5414 4.38335 10.0015 4.99687 10.0015C5.56159 10.0015 6.18906 9.60415 6.18906 8.69781V5.05851C6.18906 4.2916 5.62434 3.81055 4.99687 3.81055C4.36941 3.81055 3.80469 4.33344 3.80469 5.00273Z"
            fill="#bd96fc"
          />
          <path
            d="M1.87187 10.0016C2.5303 10.0016 3.06406 9.4678 3.06406 8.80937C3.06406 8.15095 2.5303 7.61719 1.87187 7.61719C1.21345 7.61719 0.679688 8.15095 0.679688 8.80937C0.679688 9.4678 1.21345 10.0016 1.87187 10.0016Z"
            fill="#6fcaeb"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2100_11322">
          <rect width={10} height={10} fill="white" />
        </clipPath>
        <clipPath id="clip1_2100_11322">
          <rect width={10} height={10} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowIcon = () => {
  return (
    <svg
      width={18}
      height={16}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5" clipPath="url(#clip0_2100_11316)">
        <g clipPath="url(#clip1_2100_11316)">
          <g clipPath="url(#clip2_2100_11316)">
            <path
              d="M4.61034 7.99994C2.65334 10.8819 1.67534 12.3229 1.79034 13.1529C1.84683 13.56 2.01725 13.9429 2.28195 14.2573C2.54665 14.5717 2.89483 14.8049 3.28634 14.9299C4.08634 15.1849 5.67234 14.4659 8.84534 13.0269L12.9303 11.1759C15.1553 10.1669 16.2683 9.66294 16.6133 8.96294C16.9133 8.35594 16.9133 7.64294 16.6133 7.03594C16.2683 6.33594 15.1553 5.83194 12.9303 4.82294L8.84534 2.97194C5.67234 1.53394 4.08534 0.814945 3.28634 1.06994C2.89515 1.19508 2.54727 1.42818 2.28278 1.7424C2.01828 2.05661 1.84793 2.43915 1.79134 2.84594C1.67534 3.67594 2.65334 5.11694 4.61034 7.99994ZM4.61034 7.99994H8.01634"
              stroke="#A6A6A6"
              strokeWidth="1.635"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2100_11316">
          <rect width={18} height={16} fill="white" />
        </clipPath>
        <clipPath id="clip1_2100_11316">
          <rect width={18} height={16} fill="white" />
        </clipPath>
        <clipPath id="clip2_2100_11316">
          <rect width={18} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};


interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  description: string;
  icon: React.ReactNode;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ icon, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group  wrap={'nowrap'}>
        <Box>
          {icon}
        </Box>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export default Assistant;
