import { Box, Grid, Image, Title, Flex } from "@mantine/core";

import Huntly from "@assets/images/trusted-companies/huntly.png";
import Lyne from "@assets/images/trusted-companies/lyne.png";
import NXDigtal from "@assets/images/trusted-companies/nxdigital.png";
import TrustRelay from "@assets/images/trusted-companies/trustrelay.png";
import RedInk from "@assets/images/trusted-companies/redink.png";
import Futy from "@assets/images/trusted-companies/futy.png";
import { useMediaQuery } from "@mantine/hooks";
import { MOBILE_BREAKPOINT_QUERY } from "@/lib/utils/Constants";

const logos = [
  {
    url: Huntly,
    name: "Huntly",
    mt: 16
  },
  {
    url: Lyne,
    name: "Lyne"
  },
  {
    url: NXDigtal,
    name: "NXDigital",
    mt: 4
  },
  {
    url: TrustRelay,
    name: "TrustRelay",
    mt: 24
  },
  {
    url: RedInk,
    name: "RedInk",
    mt: 12
  },
  {
    url: Futy,
    name: "futy",
    mt: 8
  }
];

export const TrustedCompanies = () => {
  const isMobileView = useMediaQuery(MOBILE_BREAKPOINT_QUERY);
  if (isMobileView) {
    return <></>;
  }
  return (
    <>
      <Title py={32} ta="center" order={3} fw={600}>
        Trusted by 2,000+ companies
      </Title>
      <Flex direction={"column"} justify={"center"} align={"center"} mx="auto" mt={16} mb={32}>
        <Grid gutter={32}>
          {logos.map((logo) => (
            <Grid.Col key={logo.name} span={2} /* or other span as per your design */>
              <Image
                mt={logo?.mt ? logo.mt : undefined}
                src={logo.url}
                alt={logo.name}
                className="logo-img"
              />
            </Grid.Col>
          ))}
        </Grid>
      </Flex>
    </>
  );
};
