import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useTrackingPixelStore } from "@/stores/useTrackingPixelStore";
import { Anchor, Switch, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";

// Constant Messages

const TRACKING_MESSAGE_TITLE = "Tracking Script Updated";
const TRACKING_MESSAGE =
  "Your tracking script has been modified; please make sure to update the tracking code on your website or app to take effect.";

export const TrackingPixelCustomizations = () => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [
    isAutoCaptureEnabled,
    setIsAutoCaptureEnabled,
    isPrivacyModeEnabled,
    setIsPrivacyModeEnabled,
    useWhiteLabelDomain,
    setUseWhiteLabelDomain
  ] = useTrackingPixelStore((state) => [
    state.isAutoCaptureEnabled,
    state.setIsAutoCaptureEnabled,
    state.isPrivacyModeEnabled,
    state.setIsPrivacyModeEnabled,
    state.useWhiteLabelDomain,
    state.setUseWhiteLabelDomain
  ]);

  const customDomain = activeWorkspace?.custom_domain;

  useEffect(() => {
    if (customDomain && customDomain.length > 0) {
      setUseWhiteLabelDomain(true);
    }
  }, []);
  return (
    <>
      <Switch
        mb={"sm"}
        size={"xs"}
        label="Automatically capture frontend events i.e button clicks, form submission etc."
        checked={isAutoCaptureEnabled}
        onChange={() => {
          setIsAutoCaptureEnabled(!isAutoCaptureEnabled);
          showNotification({
            title: TRACKING_MESSAGE_TITLE,
            message: TRACKING_MESSAGE,
            autoClose: 6000
          });
        }}
      />
      <Switch
        mb={"sm"}
        size={"xs"}
        label={
          <Text fz={"xs"}>
            Cookie-less (privacy-friendly) tracking:{" "}
            <Anchor
              fz="xs"
              fw={500}
              href="https://usermaven.com/docs/getting-started/cookieless-tracking"
              target="_blank">
              Learn more
            </Anchor>
          </Text>
        }
        checked={isPrivacyModeEnabled}
        onChange={() => {
          setIsPrivacyModeEnabled(!isPrivacyModeEnabled);
          showNotification({
            title: TRACKING_MESSAGE_TITLE,
            message: TRACKING_MESSAGE,
            autoClose: 6000
          });
        }}
      />
      <Switch
        size={"xs"}
        label={
          <>
            White-label pixel tracking (bypass ad-blockers):{" "}
            <Anchor
              fz="xs"
              fw={500}
              href="https://usermaven.com/docs/getting-started/pixel-whitelabeling"
              target="_blank">
              Learn more
            </Anchor>
          </>
        }
        checked={customDomain && customDomain.length > 0 ? useWhiteLabelDomain : false}
        onChange={() => {
          if (customDomain && customDomain.length > 0) {
            setUseWhiteLabelDomain(!useWhiteLabelDomain);
            showNotification({
              title: TRACKING_MESSAGE_TITLE,
              message: TRACKING_MESSAGE,
              autoClose: 6000
            });
          } else {
            showNotification({
              color: "red",
              title: "Custom domain not connected",
              message: (
                <>
                  You have not connected a custom domain, please{" "}
                  <NavLink to={`/env/${activeWorkspace.identifier}/settings/custom_domain`}>
                    click here
                  </NavLink>{" "}
                  to connect your customd domain.
                </>
              ),

              autoClose: 6000
            });
          }
        }}
      />
    </>
  );
};
