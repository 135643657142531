import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import { InsightsList } from "./Components/InsightsList";
import { useLocation } from "react-router-dom";
import { Box } from "@mantine/core";

interface IManageInsightsModal {
  isModalOpen: boolean;
  setIsModalOpen: (e: boolean) => void;
}

export const ManageInsightsModal = ({ isModalOpen, setIsModalOpen }: IManageInsightsModal) => {
  const location = useLocation();
  const lastItem = location.pathname.split("/").pop() || "";
  const typeOfReport = lastItem !== "overview" ? lastItem : "all";

  const getInsightsTypeName = () => {
    switch (typeOfReport) {
      case "adoption_engagement":
        return "Product Engagement";
      case "onboarding":
        return "Onboarding";
      case "retention":
        return "Retention";
      case "feature":
        return "Feature";
      default:
        return "";
    }
  };

  return (
    <>
      <SidePanel
        opened={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        loading={true}
        title={`Existing ${getInsightsTypeName()} Reports`}
      >
        <Box pt="xs">
          <InsightsList insightsType={typeOfReport}></InsightsList>
        </Box>
      </SidePanel>
    </>
  );
};
