import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { WorkspaceService } from "@/lib/services/WorkspaceService"
import { useContext, useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading"
import { OnboardingLayout } from "@/ui/layouts/OnboardingLayout"
import { OnboardingCreateWorkspace } from "./OnboardingCreateWorkspace"
import { OnboardingInstallationSetup } from "./OnboardingInstallationSetup"
import { OnboardingInstallationTrackingPixel } from "./OnboardingInstallationTrackingPixel"
import { OnboardingWaitingPixelAnalytics } from "./OnboardingWaitingPixelAnalytics"
// import { HelpWidget } from "@/ui/components/App/HelpWidget/HelpWidget";
export const OnboardingMain = (props: any): any => {
  const { activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const findWorkspaceWithPendingOnboarding = async () => {
    const workspaceService = new WorkspaceService()
    await workspaceService
      .workspacesAccess()
      .then(res => {
        console.log(`Workspace access response`, res)

        if (res.workspacesList.length === 1) {
          if (res.workspacesList[0]?.is_setup_completed) {
            navigate("/")
            return
          }
          setActiveWorkspace(res.workspacesList[0])
        }
        setIsLoading(false)
        // TODO: Edge case to handle when workspaces are greater than 1

        console.log(res.data)
      })
      .catch(err => {})
  }
  useEffect(() => {
    findWorkspaceWithPendingOnboarding()
  }, [])

  // Showing loader when we are fetching workspaces.
  if (isLoading) {
    return <LoadingComponent />
  }
  return (
    <>
      {/* <HelpWidget /> */}

      <OnboardingLayout>
        {/* Routes commented */}
        <Routes>
          <Route path="create-workspace" element={<OnboardingCreateWorkspace />} />
          <Route path="setup" element={<OnboardingInstallationSetup />} />
          <Route path="tracking-pixel" element={<OnboardingInstallationTrackingPixel />} />
          <Route path="tracking-pixel-check" element={<OnboardingWaitingPixelAnalytics />} />
          <Route path="*" element={<OnboardingInstallationTrackingPixel />}></Route>
        </Routes>
      </OnboardingLayout>
    </>
  )
}

export default OnboardingMain