/**
 * SegmentPreflight is used to fetch the segments as the users will be able to access the
 * URL directly for the segment page.
 *
 * This function is following DRY principle and will be used in the Companies and Users page.
 */

import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useLocation, useParams } from "react-router-dom";
import { useSegmentFilterStore } from "@/stores/useSegmentFilterStore";
import { useSegmentListStore } from "@/stores/useSegmentListStore";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { loaderColor } from "@/style/Colors";
import { LoadingStateProps } from "types/types.d";
import { Flex, Loader } from "@mantine/core";

export const SegmentPreflight = ({ children }: any) => {
  // Get segment from the params.
  const { segment } = useParams<{ segment: string }>();

  // get location

  const location = useLocation();

  // Get the setter from the Segment filter store.
  const [setFilterVisible] = useSegmentFilterStore((state) => [state.setFilterVisible]);

  // Segment ID or everyone for the companies or users page.

  const segmentId = segment || "everyone";

  // Active workspace of the project.

  const { activeWorkspace } = useContext(AppLifecycleContext);

  // Local state to make sure to render the segments once the page is loaded.
  const [isLoading, setIsLoading] = useState<LoadingStateProps>("idle");

  // Get the segment store functions. setFilters and reset functions are used to set the filters and reset the filters.
  const [setFilters, reset] = useSegmentStore((state) => [state.setFilters, state.reset]);

  // Store functions for the segment list.
  const [fetchSegments] = useSegmentListStore((state) => [state.fetchSegments]);

  /**
   * Function to get the segments list from the API. The segment store segments are updated after dom refresh
   * so we are getting those in the return statement.
   */
  const loadSegments = async () => {
    setIsLoading("loading");
    const segmentType = location.pathname.includes("/companies/")
      ? "company"
      : location.pathname.includes("/visitors/")
      ? "visitor"
      : "user";
    const segmentsList: any = await fetchSegments(activeWorkspace.id, segmentType);
    if (segmentId !== "everyone" && segmentsList.length > 0) {
      const segment = segmentsList.find((segment: any) => segment.id === segmentId);
      if (segment) {
        setFilters(segment.filters);
      }
    }
    setIsLoading("loaded");
  };

  /**
   * Use effect to load the segments and reset the filters when the component is unmounted.
   */
  useEffect(() => {
    loadSegments();
    return () => {
      reset();
      setFilterVisible(false);
    };
  }, []);

  /**
   * Reset the filters when the segment is everyone.
   */
  useEffect(() => {
    if (segment === "everyone") {
      reset();
    }
  }, [segment]);

  // Return the children once the segments are loaded.
  return (
    <>
      {isLoading !== "loaded" ? (
        <Flex
          align={"center"}
          justify={"center"}
          h={80}
          className="flex items-center justify-center flex-grow w-full h-20">
          <Loader size={"xs"} />
        </Flex>
      ) : (
        children
      )}
    </>
  );
};
