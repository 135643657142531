/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { numberToCommas, numberToUnit } from "@/lib/utils/StringUtility";
import React from "react";
import { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import { SummaryCard } from "../SummaryCard";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import ContentLoader from "react-content-loader";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  topBarCardValues: ITopBarCardValues;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
  period: any;
  prevPeriod: any;
  comparisonPeriod?: any;
};

const SessionCard = ({
  activeCard,
  setActiveCard,
  topBarCardValues,
  comparisonTopBarCardValues,
  loading,
  query,
  period,
  prevPeriod,
  comparisonPeriod
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const getSessionCardValue = () => {
    let value = topBarCardValues.sessions;
    return value < 100000 ? numberToCommas(value) : numberToUnit(value);
  };

  const getComparisonPercentageDiff = (currentValue: any, comparisonValue: any) => {
    const percentageChange = ((currentValue - comparisonValue) / comparisonValue) * 100;
    return Math.round(percentageChange);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="sessions-card">
      {loading === "loaded" ? (
        <SummaryCard
          aboutTooltip="Sessions represent a single visit to your website. When a session starts (the visitor views a page on your website), Usermaven begins timing the session. That timer stops when the user ends the session by leaving your site, or it stops when the user has been inactive for 30 minutes."
          activeCard={activeCard}
          title={"Sessions"}
          onClick={() => setActiveCard("sessions")}
          uniquekey={"sessions"}
          card={"sessions"}
          value={getSessionCardValue()}
          tooltip={`${numberToCommas(topBarCardValues.sessions)} sessions`}
          runningDiff={topBarCardValues.sessions_diff}
          runningDiffTooltip={`${topBarCardValues.sessions_diff}% ${
            topBarCardValues.sessions_diff > 0 ? "increase" : "decrease"
          } in sessions from previous period ${comparisonPeriod ? comparisonPeriod : prevPeriod} `}
          loading={loading}
          showRunningDiff={query?.period !== "live"}
          comparison={query?.comparison}
          comparisonPercentageDiff={getComparisonPercentageDiff(
            topBarCardValues.sessions,
            comparisonTopBarCardValues.sessions
          )}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const SessionCardMemoized = React.memo(SessionCard);
export { SessionCardMemoized as SessionCard };
