import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";

import { useJourneyStore } from "../../../../../stores/useJourneyStore";
import {
  Anchor,
  Box,
  Button,
  Flex,
  Group,
  HoverCard,
  Input,
  Paper,
  Radio,
  Switch,
  Text,
  TextInput,
  Title,
  rem,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { Label } from "../../../../components/Common/Label/Label";
import useInsightHook from "../../../../../hooks/useInsightHook";
import JourneySetupEvents from "../../../../components/App/JourneySetupEvents/JourneySetupEvents";
import { useContext, useEffect, useState } from "react";
import AppLifecycleContext from "../../../../../lib/contexts/AppLifecycleContext";
import { JourneyService } from "../../../../../lib/services/JourneyService";
import JourneyGroupingRule from "../../../../components/App/JourneyGroupingRule/JourneyGroupingRule";
import { toast } from "react-toastify";
import { InsightsService } from "@/lib/services/InsightsService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { StepCard } from "@/ui/components/Common/StepCard/StepCard";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export type FORM_ERRORS = {
  name: string;
  description: string;
  condition: string;
  exclusionConditions: string[];
  groupingRules: Array<{ alias: string; regex: string }>;
};
const JourneySetup = ({
  onCreated = () => {},
  onUpdated = (id: string) => {}
}: {
  onCreated?: () => void;
  onUpdated?: (id: string) => void;
}) => {
  const [
    isModalOpen,
    setIsModalOpen,
    condition,
    setCondition,
    exclusionConditions,
    setExclusionConditions,
    groupingRules,
    setGroupingRules,
    id,
    setId,
    name,
    setName,
    description,
    setDescription,
    journeyFlow,
    setJourneyFlow,
    pathType,
    setPathType,
    resetJourneyStore
  ] = useJourneyStore((state) => [
    state.modalOpen,
    state.setModalOpen,
    state.condition,
    state.setCondition,
    state.exclusionConditions,
    state.setExclusionConditions,
    state.groupingRules,
    state.setGroupingRules,
    state.id,
    state.setId,
    state.name,
    state.setName,
    state.description,
    state.setDescription,
    state.flow,
    state.setFlow,
    state.pathType,
    state.setPathType,
    state.resetJourneyStore
  ]);

  const { insightEvents, insightAttributes, insightPinnedEvents } = useInsightHook();
  const [pageViewSelectSuggestions, setPageViewSelectSuggestions] = useState<any>([]);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const journeyService = new JourneyService();

  const [errors, setErrors] = useState<FORM_ERRORS>({
    name: "",
    description: "",
    condition: "",
    exclusionConditions: [],
    groupingRules: []
  });

  const submitForm = async () => {
    if (validateFormData()) {
      console.log("form is valid");
    }

    if (id) {
      const response = await journeyService.updateJourney(activeWorkspace.id, id, {
        name,
        description,
        journey_flow: journeyFlow,
        journey_path_type: pathType,
        exclusion_rules: exclusionConditions.map((condition) => ({
          exclusion_type: condition.type,
          exclusion_value: condition.value
        })),
        grouping_rules: groupingRules.map((rule) => ({
          alias: rule.alias,
          regex: rule.regex
        })),
        conditions: JSON.stringify([
          {
            type: condition.type,
            value: condition.value,
            ignoreQueryParams: condition.ignoreQueryParams || false
          }
        ])
      });

      if (response.status === 200) {
        toast.success("Journey updated successfully");
        setIsModalOpen(false);
        resetJourneyStore();
        onUpdated(id);
      } else {
        toast.error("Error updating journey");
      }
      return;
    }

    const response = await journeyService.createJourney(activeWorkspace.id, {
      name,
      description,
      journey_flow: journeyFlow,
      journey_path_type: pathType,
      exclusion_rules: exclusionConditions.map((condition) => ({
        exclusion_type: condition.type,
        exclusion_value: condition.value
      })),
      grouping_rules: groupingRules.map((rule) => ({
        alias: rule.alias,
        regex: rule.regex
      })),
      conditions: JSON.stringify([
        {
          type: condition.type,
          value: condition.value,
          ignoreQueryParams: condition.ignoreQueryParams || false
        }
      ])
    });

    if (response.status === 200) {
      toast.success("Journey created successfully");
      setIsModalOpen(false);
      resetJourneyStore();
      onCreated();
    } else {
      toast.error("Error creating journey");
    }
  };

  const validateFormData = () => {
    let errors = {
      name: "",
      description: "",
      condition: "",
      exclusionConditions: exclusionConditions.map(() => ""),
      groupingRules: groupingRules.map(() => ({
        alias: "",
        regex: ""
      }))
    };

    if (!name) {
      errors.name = "Name is required";
    }

    // if (!description) {
    //   errors.description = 'Description is required'
    // }

    if (!condition.value) {
      errors.condition = "Condition is required";
    }

    if (exclusionConditions.length > 0) {
      console.log("exclusionConditions", exclusionConditions, errors.exclusionConditions);
      exclusionConditions.forEach((condition, index) => {
        console.log("exclusionConditions value", errors.exclusionConditions[index]);

        if (!condition.value) {
          errors.exclusionConditions[index] = "Condition is required";
        }
      });
    }

    if (groupingRules.length > 0) {
      groupingRules.forEach((rule, index) => {
        if (!rule.alias) {
          if (errors.groupingRules[index]) {
            errors.groupingRules[index].alias = "Alias is required";
          }
        }

        if (!rule.regex) {
          if (errors.groupingRules[index]) {
            errors.groupingRules[index].regex = "Regex is required";
          }
        }
      });
    }

    setErrors(errors);

    return Object.values(errors).every((error) => error === "");
  };

  const handlePageViewSuggestions = (query: string, typeOfEvent: string) => {
    console.log(query, typeOfEvent);
    new InsightsService()
      .suggestions(activeWorkspace.id, "page_url")
      .then((res) => {
        setPageViewSelectSuggestions(res.data);
      })
      .catch((err) => {
        setPageViewSelectSuggestions([]);
      });
  };

  useEffect(() => {
    console.log("condition", condition);
    handlePageViewSuggestions(condition.value, condition.type);
  }, [condition.type, condition.value]);

  useEffect(() => {
    if (!isModalOpen) {
      resetJourneyStore(); // Reset journey store when modal is closed
    }
  }, [isModalOpen]);

  const theme = useGlobalMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <SidePanel
        opened={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        loading={true}
        title={id ? "Edit Journey" : "New Journey"}>
        <Box p={"md"}>
          <Box>
            {/* Step 1 */}
            <Box>
              <StepCard step={1}>
                <Flex align={"center"}>
                  <Flex align={"center"} flex={1}>
                    <Input.Wrapper size="sm" label="Journey name" required>
                      <TextInput
                        w={260}
                        data-autofocus
                        onChange={(e) => setName(e.target.value)}
                        defaultValue={name}
                        value={name}
                        placeholder="e.g Users Onboarded"
                        className="mt-2 "
                        error={errors.name}></TextInput>
                    </Input.Wrapper>
                    <Box ml="sm" maw={450}>
                      <Input.Wrapper size="sm" label="Description (Optional)">
                        <TextInput
                          w={450}
                          styles={{
                            input: {
                              borderBottom: "1px dashed",
                              borderTop: "0px",
                              borderLeft: "0px",
                              borderRight: "0px",

                              borderColor:
                                colorScheme === "dark"
                                  ? theme.colors.dark[3]
                                  : theme.colors.gray[5],
                              "&:focus-within": {
                                borderColor: theme.colors.brand[5]
                              }
                            }
                          }}
                          value={description}
                          defaultValue={description}
                          onChange={(e) => setDescription(e.target.value)}
                          variant="unstyled"
                          placeholder="e.g Users who have purchased premium plan"
                          error={errors.description}></TextInput>
                      </Input.Wrapper>
                    </Box>
                  </Flex>
                </Flex>
              </StepCard>
            </Box>

            {/* Step 2 */}
            <Box>
              <StepCard step={2}>
                <Flex direction={"column"}>
                  <Text fw={600} mb="md">
                    Choose Journey Flow
                  </Text>

                  <Radio.Group
                    mb={"xl"}
                    spacing="xl"
                    sx={{
                      b: {
                        fontWeight: 600
                      }
                    }}
                    value={journeyFlow}
                    // @ts-ignore
                    onChange={setJourneyFlow}>
                    <Flex align={"center"}>
                      <Radio
                        mr={"sm"}
                        value="after"
                        label={
                          <>
                            Visualize what users did <b>after</b> performing an event
                          </>
                        }
                      />
                      <Radio
                        value="before"
                        label={
                          <>
                            Visualize what users did <b>before</b> performing an event
                          </>
                        }
                      />
                    </Flex>
                  </Radio.Group>

                  <Text fw={600}>Select the event or page URL</Text>

                  <JourneySetupEvents
                    conditions={[condition]}
                    setConditions={(items) => setCondition(items[0])}
                    attributesOptions={insightEvents}
                    pageViewSelectOptions={pageViewSelectSuggestions}
                    conditionsErrors={[errors.condition]}
                  />
                </Flex>
              </StepCard>
            </Box>
            {/* Step 3 */}
            <StepCard step={3}>
              <Flex direction={"column"}>
                <Text fw={600} mb="md">
                  Choose Path Type
                </Text>
                <Radio.Group
                  my="sm"
                  sx={{
                    b: {
                      fontWeight: 600
                    }
                  }}
                  value={pathType}
                  // @ts-ignore

                  onChange={setPathType}>
                  <Radio mb="sm" value="session-based" label={<>Session-based paths</>} />
                  <Radio value="user-based" label={<>User-based paths</>} />
                </Radio.Group>
              </Flex>
            </StepCard>

            {/* Step 4 */}
            <StepCard step={4}>
              <Flex direction={"column"}>
                <Text mb="sm" fw={600}>
                  Grouping
                </Text>
                <Group gap={"xs"} mb={"sm"}>
                  <Switch
                    style={{
                      display: "flex",
                      fontWeight: 500
                    }}
                    size={"xs"}
                    label={"Ignore Query Parameters"}
                    checked={condition.ignoreQueryParams}
                    onChange={() => {
                      setCondition({
                        ...condition,
                        ignoreQueryParams: !condition.ignoreQueryParams
                      });
                    }}
                  />

                  <HoverCard
                    width={350}
                    shadow="md"
                    withArrow
                    position={"right-start"}
                    withinPortal>
                    <HoverCard.Target>
                      <FontAwesomeIcon
                        className="text-gray-700 pt-1"
                        icon={regular("info-circle")}></FontAwesomeIcon>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Paper>
                        <Text size="xs" fs="italic" mb="xs">
                          <i>
                            When this option is checked, a URL like{" "}
                            <Text span c="brand" fz="xs">
                              /product-details?ref=campaign123
                            </Text>{" "}
                            will be treated as{" "}
                            <Text c="brand" fz="xs">
                              /product-details
                            </Text>
                          </i>
                        </Text>
                        <Text size="xs" fs="italic" mb="xs">
                          Want to learn more? Check out our{" "}
                          <Anchor href="https://usermaven.com/docs" target="_blank">
                            docs
                          </Anchor>
                        </Text>
                      </Paper>
                    </HoverCard.Dropdown>
                  </HoverCard>
                </Group>
                <Text mt="md" fw={600}>
                  Group with Regex (Optional)
                </Text>
                <Text size="sm" c="dimmed" my="sm">
                  Grouping with regex allows you to group multiple events under one alias. This is
                  useful when you want to group similar events or page URLs together.
                </Text>
                <JourneyGroupingRule
                  conditions={groupingRules}
                  setConditions={setGroupingRules}
                  withAddCondition={true}
                  addConditionLabel={"Add Rule"}
                  onAddCondition={() => {
                    setGroupingRules([
                      ...groupingRules,
                      {
                        alias: "",
                        regex: ""
                      }
                    ]);
                  }}
                  onRemoveCondition={(index) => {
                    setGroupingRules(groupingRules.filter((_, i) => i !== index));
                  }}
                  conditionsErrors={errors.groupingRules}
                />
              </Flex>
            </StepCard>

            {/* Step 5 */}
            <StepCard step={5}>
              <Flex direction={"column"} w={"100%"}>
                <Text fw={600}>Exclude events or page URL (Optional)</Text>
                <Text size="sm" c="dimmed" my="sm">
                  This will exclude all traffic that belongs to visitor's URL e.g usermaven.com.
                </Text>
                <JourneySetupEvents
                  conditions={exclusionConditions}
                  setConditions={setExclusionConditions}
                  attributesOptions={insightEvents}
                  pageViewSelectOptions={pageViewSelectSuggestions}
                  withAddCondition={true}
                  addConditionLabel={"Add Exclusion Condition"}
                  onAddCondition={() => {
                    setExclusionConditions([
                      ...exclusionConditions,
                      {
                        key: "", // This is being used here
                        value: "",
                        operator: "eq",
                        type: "pageview", // This is being used here
                        label: ""
                      }
                    ]);
                  }}
                  onRemoveCondition={(index) => {
                    setExclusionConditions(exclusionConditions.filter((_, i) => i !== index));
                  }}
                  conditionsErrors={errors.exclusionConditions}
                />
              </Flex>
            </StepCard>

            <Flex align={"center"} direction={"row"} justify={"end"}>
              <Button
                className="mr-4"
                onClick={() => {
                  setIsModalOpen(false);
                }}
                color="red">
                Cancel
              </Button>
              <Button onClick={() => submitForm()}>{id ? "Update" : "Create"}</Button>
            </Flex>
          </Box>
        </Box>
      </SidePanel>
    </>
  );
};

export default JourneySetup;
