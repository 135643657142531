/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  HoverCard,
  Input,
  Select,
  Switch,
  Text,
  Anchor,
  Paper,
  Radio,
  Flex,
  TextInput,
  Group,
  NumberInput,
  useMantineColorScheme,
  useMantineTheme,
  Badge
} from "@mantine/core";
import useConditionsValidityHook from "@/hooks/useConditionsValidityHook";
import useInsightHook from "@/hooks/useInsightHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { InsightsService } from "@/lib/services/InsightsService";
import { ThirdPartyTracking } from "@/lib/utils/ThirdPartyTracking";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useConversionFunnelStore } from "@/stores/useConversionFunnelStore";
import { ConditionItem, LoadingStateProps } from "types/types.d";
import { Label } from "@/ui/components/Common/Label/Label";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";
import { InsightsSetupEvents } from "../../InsightsSetupEvents/InsightsSetupEvents";
import { PLAN_TYPES } from "../../../../../lib/utils/Constants";
import { SidePanel } from "@/ui/components/Common/SidePanel/SidePanel";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import { StepCard } from "@/ui/components/Common/StepCard/StepCard";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

interface Props {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  reload: () => void;
  funnel?: {
    id: string;
    name: string;
    conditions: {
      funnel_conditions: Array<ConditionItem>;
      is_first_step: boolean;
    };
  };
}

interface IFormInputs {
  name: string;
}

export const ConversionFunnelModal = ({ isOpen = false, onClose, reload }: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [
    id,
    funnelName,
    setName,
    description,
    setDescription,
    conditions,
    setConditions,
    isVisitorsStepEnabled,
    setIsVisitorsStepEnabled,
    reset,
    stepsOrder,
    setStepsOrder,
    funnelType,
    setFunnelType,
    windowSize,
    setWindowSize,
    windowSizeType,
    setWindowSizeType
  ] = useConversionFunnelStore((state) => [
    state.id,
    state.name,
    state.setName,
    state.description,
    state.setDescription,
    state.conditions,
    state.setConditions,
    state.isVisitorsStepEnabled,
    state.setIsVisitorsStepEnabled,
    state.reset,
    state.stepsOrder,
    state.setStepsOrder,
    state.funnelType,
    state.setFunnelType,
    state.windowSize,
    state.setWindowSize,
    state.windowSizeType,
    state.setWindowSizeType
  ]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    register,
    setValue
  } = useForm<IFormInputs>({
    defaultValues: {
      name: funnelName
    }
  });
  const { colorScheme } = useMantineColorScheme();
  const theme = useGlobalMantineTheme();
  const { insightEvents, insightAttributes, insightPinnedEvents } = useInsightHook();
  // Funnel list is used to check unique names.
  const [list] = useConversionFunnelStore((state) => [state.list]);

  // Check if the plan is starter or not.
  const isStarter = useWorkspaceUtilityHook().isStarterPlan;

  const { isValidationChecked, setIsValidationChecked, stepsValidation } =
    useConditionsValidityHook();

  const onSubmit = async (data: any) => {
    // check if the name is unique
    if (
      list.find(
        (item) => item.name.toLowerCase() === data.name.trim().toLowerCase() && item.id !== id
      )
    ) {
      toast.error("An insight with the same name already exists. Please enter a different name.");
      return;
    }

    if (!stepsValidation(conditions, isVisitorsStepEnabled ? 1 : 2, 25)) {
      return;
    }

    if (!stepsOrder) {
      toast.error("Please select step order.");
      return;
    }

    if (!funnelType) {
      toast.error("Please select a funnel type.");
      return;
    }

    // Loop through the conditions and check if the type is "event" or "pinned_event" then set empty string to the value
    // This is to avoid sending the value to the backend as it is not required.
    const tempConditions = conditions.map((condition) => {
      if (condition.type === "event" || condition.type === "pinned_event") {
        return {
          ...condition,
          value: ""
        };
      }
      return condition;
    });

    setConditions(tempConditions);

    setLoading("loading");
    if (!id) {
      await new InsightsService()
        .create(activeWorkspace.id, {
          name: data.name,
          conditions: {
            funnel_conditions: tempConditions,
            is_visitors_step_enabled: isVisitorsStepEnabled,
            funnel_type: funnelType,
            steps_order: stepsOrder,
            window_size: windowSize,
            window_size_type: windowSizeType
          },
          type: "web_conversion_funnel",
          description: description || ""
        })
        .then((res) => {
          if (res.data) {
            toast.success("Conversion funnel created successfully.");
            onClose(false);
            reset();
            reload();

            new ThirdPartyTracking().track("web_conversion_funnel_created");
          }
        })
        .catch((err) => {
          if (err && err?.response?.status === 422 && err?.response?.data?.limit_reached) {
            toast.error(
              "You have reached the limit. Please upgrade your plan to create more funnels."
            );
          }
        });
    } else {
      await new InsightsService()
        .update(activeWorkspace.id, id, {
          name: data.name,
          conditions: {
            funnel_conditions: tempConditions,
            is_visitors_step_enabled: isVisitorsStepEnabled,
            funnel_type: funnelType,
            steps_order: stepsOrder,
            window_size: windowSize,
            window_size_type: windowSizeType
          },
          description: description || ""
        })
        .then((res) => {
          if (res.data) {
            toast.success(`Conversion funnel updated successfully.`);
            reset();

            reload();
          }
          onClose(false);
        })
        .catch((err) => err);
    }
    setLoading("loaded");
  };
  useEffect(() => {
    setValue("name", funnelName);
  }, [id, funnelName]);
  return (
    <>
      <SidePanel
        loading={true}
        opened={isOpen}
        onCancel={() => onClose(false)}
        title={
          id ? (
            <h2 className="font-medium">Update Conversion Funnel</h2>
          ) : (
            <h2 className="font-medium">Create Conversion Funnel</h2>
          )
        }>
        <Box className="" p="md">
          <div className="um-modal--content--form">
            <form onSubmit={handleSubmit(onSubmit)} id="conversionFunnelModal">
              <StepCard step={1}>
                <Flex gap={"xl"}>
                  <div className="flex flex-col w-full">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error }
                      }) => (
                        <Input.Wrapper
                          size="sm"
                          id={"conversionFunnelTitle"}
                          label="Conversion funnel name">
                          <Input
                            mt={4}
                            value={value}
                            onChange={onChange}
                            placeholder="e.g Booked a demo"
                            defaultValue={funnelName}
                            error={(invalid || error) === true}
                            maxLength={32}
                          />
                        </Input.Wrapper>
                      )}
                      name="name"
                      control={control}
                      defaultValue={funnelName}
                      rules={{ required: true }}
                    />

                    {errors.name?.type === "required" && (
                      <Text fz="xs" c="red" size="small" mt="xs">
                        Conversion funnel name is required
                      </Text>
                    )}
                  </div>

                  <Input.Wrapper
                    size="sm"
                    label="Description (Optional)"
                    style={{
                      flexShrink: 0,
                      label: {
                        fontSize: "12px",
                        fontWeight: 500
                      }
                    }}>
                    <TextInput
                      w={400}
                      styles={{
                        input: {
                          borderBottom: "1px dashed",
                          borderTop: "0px",
                          borderLeft: "0px",
                          borderRight: "0px",

                          borderColor:
                            colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[5],
                          "&:focus-within": {
                            borderColor: theme.colors.brand[5]
                          }
                        }
                      }}
                      variant="unstyled"
                      placeholder="e.g Demos booked by users"
                      defaultValue={description}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      maxLength={60}></TextInput>
                  </Input.Wrapper>
                </Flex>
              </StepCard>
              <StepCard step={2}>
                <div>
                  <Flex align={"center"}>
                    <Text mr="xs" fw={600}>
                      Choose your funnel steps{" "}
                    </Text>{" "}
                    <HoverCard
                      width={350}
                      shadow="md"
                      withArrow
                      position={"right-start"}
                      withinPortal>
                      <HoverCard.Target>
                        <FontAwesomeIcon
                          className="text-gray-700 pt-1"
                          icon={regular("info-circle")}></FontAwesomeIcon>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Paper>
                          <Text>
                            Choose between a visitors, users, or companies level audience.
                            <br />
                            <br />
                            <Text span fw={600}>
                              Visitor-level funnels:
                            </Text>{" "}
                            These funnels are used for website improvements and optimization. They
                            track the journey of website visitors from the first interaction to
                            conversion.
                            <br />
                            <br />
                            <Text span fw={600}>
                              User-level funnels:
                            </Text>{" "}
                            These funnels provide the insights that lead to product improvements and
                            optimization. They focus on individual user behavior and track each
                            user's journey through the funnel stages.
                            <br />
                            <br />
                            <Text span fw={600}>
                              Company-level funnels:
                            </Text>{" "}
                            This level of analysis is useful for identifying overall trends and
                            patterns in user behavior, and for making data-driven decisions about
                            optimizing the product for the majority of users.
                            <br />
                            <br />
                            Want to learn more? Check out our{" "}
                            <Anchor
                              fw={700}
                              fz="sm"
                              href="https://usermaven.com/docs"
                              target="_blank">
                              docs
                            </Anchor>
                          </Text>
                        </Paper>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </Flex>

                  <Flex align={"center"} mt="md">
                    <Radio.Group
                      value={funnelType}
                      //@ts-ignore
                      onChange={setFunnelType}
                      spacing={"lg"}
                      name="visitorType">
                      <Group>
                        <Radio size="xs" fw={500} value="visitor" label="Visitor" />
                        <Radio size="xs" fw={500} value="user" label="User" />
                        <Radio
                          size="xs"
                          fw={500}
                          value="company"
                          label="Company"
                          disabled={isStarter}
                        />
                      </Group>
                    </Radio.Group>
                  </Flex>
                </div>
              </StepCard>
              <StepCard step={3}>
                <div>
                  <Flex align={"center"} mb="md">
                    <Text mr="xs" fw={600}>
                      Choose your funnel steps{" "}
                    </Text>

                    <HoverCard
                      width={300}
                      shadow="md"
                      withArrow
                      position={"right-start"}
                      withinPortal>
                      <HoverCard.Target>
                        <FontAwesomeIcon
                          className="text-gray-700 pt-1"
                          icon={regular("info-circle")}></FontAwesomeIcon>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Paper>
                          <Text>
                            Create the funnels steps by selecting the events that you want to track.
                            <br />
                            <br />
                            The events can be page urls,
                            <Anchor
                              fw={700}
                              fz={"sm"}
                              href="https://usermaven.com/docs/getting-started/sending-custom-events"
                              target="_blank"
                              ml={3}>
                              custom events
                            </Anchor>
                            , or
                            <Anchor
                              fw={700}
                              fz={"sm"}
                              href="https://usermaven.com/docs/getting-started/creating-pinned-events"
                              target="_blank"
                              ml={3}>
                              pinned events
                            </Anchor>
                            .
                          </Text>
                        </Paper>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </Flex>

                  <Flex direction={"column"} mt="md">
                    {funnelType === "visitor" && (
                      <Flex direction={"column"} align={"start"}>
                        <Flex align={"center"}>
                          <Box
                            className={!isVisitorsStepEnabled ? "opacity-50" : ""}
                            style={{
                              opacity: !isVisitorsStepEnabled ? 0.5 : undefined
                            }}>
                            <Badge
                              autoContrast
                              color={colorScheme === "light" ? "gray.3" : "dark.5"}
                              radius={"sm"}
                              fw={500}
                              p={"sm"}
                              style={{
                                cursor: "not-allowed"
                              }}>
                              Unique Visitors
                            </Badge>
                            <div
                              className={`bg-gray-200 text-gray-900 w-8 h-8 rounded-md border-solid border border-gray-200 flex items-center justify-center ${
                                conditions.length === 0 ? "mb-4" : ""
                              }`}>
                              <span className=""></span>
                            </div>
                          </Box>
                          <>
                            <Switch
                              ml="xs"
                              checked={isVisitorsStepEnabled}
                              label={`Use this as first step`}
                              onChange={(event: any) =>
                                setIsVisitorsStepEnabled(event.target.checked)
                              }
                              size="xs"></Switch>
                          </>
                        </Flex>
                        <Badge
                          style={{
                            opacity: !isVisitorsStepEnabled ? 0.5 : undefined
                          }}
                          autoContrast
                          radius={"sm"}
                          py="xs"
                          color={colorScheme === "light" ? "gray.3" : "dark.5"}
                          mt="md">
                          <FontAwesomeIcon size="lg" icon={regular("angle-down")}></FontAwesomeIcon>
                        </Badge>
                      </Flex>
                    )}

                    <InsightsSetupEvents
                      conditionType={"funnel"}
                      conditions={conditions}
                      setConditions={setConditions}
                      attributesOptions={insightAttributes}
                      eventsOptions={insightEvents}
                      pinnedEventOptions={insightPinnedEvents}
                      nameIdentifier={"conversionFunnel"}
                      maxConditions={25}
                      options={[
                        {
                          label: "Page URL",
                          value: "pageview"
                        },
                        { label: "Custom Event", value: "event" },
                        { label: "Pinned Event", value: "pinned_event" }
                      ]}
                    />
                  </Flex>
                </div>
              </StepCard>
              <StepCard step={4}>
                <Box w={"100%"}>
                  <Flex align={"center"} mb="md">
                    <Text mr="xs" fw={600}>
                      Choose your step order
                    </Text>
                    <HoverCard
                      width={350}
                      shadow="md"
                      withArrow
                      position={"right-start"}
                      withinPortal>
                      <HoverCard.Target>
                        <FontAwesomeIcon
                          className="text-gray-700 pt-1"
                          icon={regular("info-circle")}></FontAwesomeIcon>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Box>
                          <Text>
                            Choose between a strict order or a sequential order.
                            <br />
                            <br />
                            <Text span fw={600}>
                              Strict order:
                            </Text>{" "}
                            When you only want to measure users who have completed the steps in your
                            funnel in the exact order, without any other events in between.
                            <br />
                            <br />
                            <Text span fw={600}>
                              Sequential order:
                            </Text>{" "}
                            When measuring users who have completed the steps in your funnel in the
                            set order, even if they have triggered other events in between.
                            <br />
                            <br />
                            Want to learn more? Check out our{" "}
                            <Anchor
                              fz="sm"
                              fw={700}
                              href="https://usermaven.com/docs/funnels#setting-up-a-funnel"
                              target="_blank">
                              docs
                            </Anchor>
                            .
                          </Text>
                        </Box>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </Flex>
                  <Select
                    placeholder={"Select Step Order"}
                    name="funnelType"
                    w={200}
                    data={[
                      { label: "Sequential Order", value: "sequential" },
                      { label: "Strict Order", value: "strict" }
                    ]}
                    defaultValue={"sequential"}
                    // @ts-ignore
                    onChange={(val: "sequential" | "strict") => {
                      if (val) setStepsOrder(val);
                    }}
                    value={stepsOrder}
                    size="xs"></Select>
                </Box>
              </StepCard>

              <StepCard step={5}>
                <Box w={"100%"}>
                  <Flex align={"center"} mb="md">
                    <Text mr="xs" fw={600}>
                      Funnel Window (Optional)
                    </Text>
                    <HoverCard
                      width={350}
                      shadow="md"
                      withArrow
                      position={"right-start"}
                      withinPortal>
                      <HoverCard.Target>
                        <FontAwesomeIcon
                          className="text-gray-700 pt-1"
                          icon={regular("info-circle")}></FontAwesomeIcon>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Box>
                          <Text>
                            Choose the window size for your funnel. The window size is the number of
                            days/hours/minutes that a user has to complete the funnel steps.
                            <br />
                            <br />
                            <Text span fw={600}>
                              Default: 0
                            </Text>{" "}
                            (no window size)
                            <br />
                            <br />
                            <Text span fw={600}>
                              Example:
                            </Text>{" "}
                            If you set the window size to 7 days, then the user has 7 days to
                            complete the funnel steps. If the user does not complete the funnel
                            steps within 7 days, then the user will not be counted in the funnel.
                          </Text>
                        </Box>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </Flex>
                  <Group gap={"sm"}>
                    <NumberInput
                      placeholder={"Enter the window size in days"}
                      size="xs"
                      min={0}
                      max={365}
                      defaultValue={windowSize}
                      // @ts-ignore
                      onChange={setWindowSize}
                      value={windowSize}
                      w={90}
                    />

                    <Select
                      w={90}
                      name="windowSizeType"
                      data={[
                        { label: "Days", value: "days" },
                        { label: "Hours", value: "hours" },
                        { label: "Minutes", value: "minutes" }
                      ]}
                      dropdownPosition="bottom"
                      defaultValue={"days"}
                      // @ts-ignore
                      onChange={(val: "days" | "hours" | "minutes") => {
                        if (val) setWindowSizeType(val);
                      }}
                      value={windowSizeType}
                      size="xs"></Select>
                  </Group>
                </Box>
              </StepCard>

              <Flex direction={"row-reverse"} my="md">
                <Button
                  type="submit"
                  loading={loading === "loading"}
                  disabled={loading === "loading"}>
                  {id ? "Update" : "Create"}
                </Button>
              </Flex>
            </form>
          </div>
        </Box>
      </SidePanel>
    </>
  );
};
