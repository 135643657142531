import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { LOADER_COLOR } from "@/lib/utils/Constants";
import { useContext, useEffect } from "react";
import { Oval } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useInsightShareableStore } from "@/stores/useInsightShareableStore";
import { loaderColor } from "@/style/Colors";
import { ShareDashboardPrivate } from "@/ui/components/App/Share/ShareDashboardPrivate";
import { ShareDashboardUnauthorized } from "@/ui/components/App/Share/ShareDashboardUnauthorized";
import { LoadingComponent } from "@/ui/components/Common/Loading/Loading";
type Params = {
  secret: string;
};
export const InsightsShareableGuard = ({ children }: any) => {
  const { secret } = useParams<Params>();

  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [isLoading, isPublicShareEnabled, isAuthorized, fetchInsightBySecret] =
    useInsightShareableStore((state) => [
      state.isLoading,
      state.isPublicShareEnabled,
      state.isAuthorized,
      state.fetchInsightBySecret
    ]);
  const fetchShareableInsight = async () => {
    const res = await fetchInsightBySecret(activeWorkspace.id, secret || "");
  };

  useEffect(() => {
    fetchShareableInsight();
  }, []);
  if (isLoading !== "loaded") {
    return <LoadingComponent />;
  }
  if (!isAuthorized) {
    return (
      <>
        <ShareDashboardUnauthorized />
        <div className="mb-24"></div>
      </>
    );
  }

  if (!isPublicShareEnabled) {
    return (
      <>
        <ShareDashboardPrivate />
        <div className="mb-24"></div>
      </>
    );
  }

  return <>{children}</>;
};
