import axios from "./JWTService";

export class ChannelMappingService {
  fetchChannelMappings = (wsId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/channel_mappings/${wsId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createChannelMapping = (wsId: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/channel_mappings/${wsId}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateChannelMapping = (wsId: string, mappingId: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/channel_mappings/${wsId}/${mappingId}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateAllMappingPriorities = (wsId: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/channel_mappings/${wsId}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } 
  /**
   * Deletes the channel mapping and returns the updated list with the mapping removed
   * and the priority of the remaining items updated
   * @param wsId UUID of workspace
   * @param mappingId UUID of mapping id to be deleted
   * @returns 
   */
  deleteChannelMapping = (wsId: string, mappingId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/v1/channel_mappings/${wsId}/${mappingId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
