import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { EventsService } from "@/lib/services/EventsService";
import { getACEventType } from "@/lib/utils/AutoCaptureUtility";
import { ClickhouseDateToLocalRelativeTime } from "@/lib/utils/DateUtility";
import { NoResults } from "@/ui/components/App/NoResults/NoResults";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Badge,
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  Loader,
  Paper,
  SegmentedControl,
  Space,
  Text,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { LooseObject } from "types/types.d";

const EventsActivity = () => {
  const [events, setEvents] = useState<Array<LooseObject>>([]);
  const [toggleId, setToggleId] = useState(-1);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [toggleMenuValue, setToggleMenuValue] = useState("");
  const [loading, setLoading] = useState("idle");
  const eventsService = new EventsService();
  const fetchEvents = async () => {
    setLoading("loading");
    await eventsService
      .activity(activeWorkspace.id, toggleMenuValue)
      .then((res) => {
        if (res.data) {
          setEvents(res.data);
        }
      })
      .catch((err) => {});
    setLoading("loaded");
  };
  useEffect(() => {
    fetchEvents();
  }, [toggleMenuValue]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchEvents();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    document.title = "Events | Activity | Usermaven";
  }, []);

  const handleToggleEvent = (id: number) => {
    setToggleId(id === toggleId ? -1 : id);
  };


  return (
    <>
      <Box component="div">
        <Flex align={"center"}>
          <Flex flex="1">
            <Text>
              <FontAwesomeIcon
                icon={regular("info-circle")}
                className="text-gray-700 mr-2"></FontAwesomeIcon>
              Only last 100 events are shown.
            </Text>
          </Flex>
          <Flex align={"center"}>
            <Tooltip label="Refresh">
              <ActionIcon variant="outline" onClick={() => fetchEvents()} mr={8}>
                <FontAwesomeIcon icon={regular("refresh")} />
              </ActionIcon>
            </Tooltip>

            <SegmentedControl
              // color="primary"
              value={toggleMenuValue}
              onChange={setToggleMenuValue}
              data={[
                { label: "All Events", value: "" },
                { label: "Failed Events", value: "error" }
              ]}></SegmentedControl>
          </Flex>
        </Flex>
        <Space my="lg"></Space>

        <Box>
          <Box>
            {loading === "loaded" ? (
              <>
                {events.length > 0 && (
                  <>
                    <Grid fw={500} fz={"sm"}>
                      <Grid.Col span={2}>Event Time</Grid.Col>
                      <Grid.Col span={3}>Event</Grid.Col>
                      <Grid.Col span={2}>Status</Grid.Col>
                      <Grid.Col span={5}>Page URL</Grid.Col>
                    </Grid>
                    <Divider my="md"></Divider>
                  </>
                )}

                {events.length > 0 &&
                  events.map((event: LooseObject, index: number) => (
                    <>
                      <Grid component="div" className="cursor-pointer" pb="sm">
                        <Grid.Col
                          span={2}
                          className="line-clamp-1 cursor-pointer "
                          onClick={() => handleToggleEvent(index)}>
                          <Flex align="center">
                            <Box w={16} c={"brand"} fz="xs">
                              <FontAwesomeIcon
                                icon={solid("angle-right")}
                                className={`mr-3 text-purple-500 transition-all ease-in-out ${
                                  toggleId === index ? "transform rotate-90" : ""
                                }`}></FontAwesomeIcon>
                            </Box>
                            <Text>{ClickhouseDateToLocalRelativeTime(event._timestamp || "")}</Text>
                          </Flex>
                        </Grid.Col>
                        <Grid.Col
                          span={3}
                          className="cursor-pointer"
                          onClick={() => handleToggleEvent(index)}>
                          {event.event?.event_type === "$autocapture" ? (
                            <>
                              {getACEventType({
                                event_id: event.event?.eventn_ctx_event_id,
                                ac_el_text: event.event?.autocapture_attributes?.el_text,
                                ac_event_type: event.event?.autocapture_attributes?.event_type,
                                ac_href: event.event?.autocapture_attributes?.attr__href,
                                ac_id: event.event?.autocapture_attributes?.attr__id,
                                ac_name: event.event?.autocapture_attributes?.attr__name,
                                ac_tag_name: event.event?.autocapture_attributes?.tag_name,
                                url: event.event?.url,
                                _timestamp: event.event?._timestamp
                              })}
                              <Badge radius={"sm"} size="xs" variant="outline" ml={2}>
                                Autocaptured
                              </Badge>
                            </>
                          ) : (
                            <Badge radius={"sm"} size="xs" variant="outline">
                              {event.event?.event_type || "-"}
                            </Badge>
                          )}
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <Badge
                            size="lg"
                            radius="md"
                            variant="dot"
                            tt={"capitalize"}
                            fw={500}
                            color={event?.error ? "red.7" : "green.7"}>
                            {event?.error ? "Failed" : "Success"}{" "}
                          </Badge>
                        </Grid.Col>

                        <Grid.Col span={5}>
                          <Flex direction={"column"}>
                            <Text lineClamp={2} fz="sm">
                              {event.event?.url || "-"}
                            </Text>
                            {event?.error && (
                              <>
                                <Text c="red.7">{event?.error_description}</Text>
                              </>
                            )}
                          </Flex>
                        </Grid.Col>
                        {toggleId === index && (
                          <Grid.Col span={12}>
                            <Paper shadow="sm" p={"sm"}>
                              <Grid fz="sm">
                                <Grid.Col span={3}>
                                  <Text fw={500}>Key</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                  <Text fw={500}>Value</Text>
                                </Grid.Col>

                                {event.event &&
                                  Object.keys(event.event).map((key) => (
                                    <>
                                      <Grid.Col span={3}>
                                        <span>{key}</span>
                                      </Grid.Col>
                                      <Grid.Col span={9}>
                                        <Text>
                                          {typeof event.event[key] === "object" ? (
                                            <>{JSON.stringify(event.event[key])}</>
                                          ) : (
                                            <>{event.event[key]}</>
                                          )}
                                          {/* {event.original[key]} */}
                                        </Text>
                                      </Grid.Col>
                                    </>
                                  ))}

                                {!event.event && (
                                  <>
                                    <p className="px-4 pb-4">
                                      No properties available against the event.
                                    </p>
                                  </>
                                )}
                              </Grid>
                            </Paper>
                          </Grid.Col>
                        )}
                      </Grid>
                      <Divider py="xs" />
                    </>
                  ))}

                {events && events.length === 0 && (
                  <Center py="xl">
                    <NoResults
                      text={
                        toggleMenuValue === "error"
                          ? "There are no error events available."
                          : "There are no events available."
                      }
                      heading={"No events"}></NoResults>
                  </Center>
                )}
              </>
            ) : (
              <>
                <Center py="xl">
                  <Loader size={"xs"} />
                </Center>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EventsActivity;
