// @ts-ignore
import { regular, light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Group,
  List,
  Radio,
  Space,
  Text,
  Title,
  Loader
} from "@mantine/core";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { BillingService } from "@/lib/services/BillingService";
import { OrganizationService } from "@/lib/services/OrganizationService";
import { LOADER_COLOR, PADDLE_VENDOR_ID, PLAN_PRODUCT_TYPE } from "@/lib/utils/Constants";
import { numberToCommas } from "@/lib/utils/StringUtility";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LoadingStateProps, LooseObject } from "types/types.d";

import { LifetimePlanTypeSwitch } from "./LifetimePlanTypeSwitch";
import { gtag_report_conversion } from "@/lib/utils/ThirdPartyTracking";
import classes from "./LifetimePlans.module.css";
import clsx from "clsx";
type LifetimePlansProps = {
  open: boolean;
  closeModal: (value: boolean) => void;
  fetchOrganizationPlan: () => void;
};

export const LifetimePlans = ({ fetchOrganizationPlan, closeModal, open }: LifetimePlansProps) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [plans, setPlans] = useState<Array<LooseObject>>([]);
  const [selectedPlan, setSelectedPlan] = useState<LooseObject>({
    name: ""
  });
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [checkoutLoading, setCheckoutLoading] = useState<LoadingStateProps>("idle");
  const [lifetimePlanType, setLifetimePlanType] = useState<"pro" | "premium">("pro");
  const fetchUpgradablePlans = async () => {
    setLoading("loading");
    await new OrganizationService()
      .lifetimeUpgradablePlans(activeWorkspace.organization.id)
      .then((res) => {
        setPlans(res.data);
        if (
          res.data[0].plan_type === PLAN_PRODUCT_TYPE.WEB_PRODUCT ||
          res.data[0].name.toLowerCase() === "pro-10m-events"
        ) {
          setLifetimePlanType("premium");
        }

        setSelectedPlan(res.data.length > 1 ? res.data[1] : res.data[0]);
      })
      .catch((err) => {});
    setLoading("loaded");
  };

  const handleUpgradePlan = async () => {
    setCheckoutLoading("loading");
    const res = await new BillingService()
      .overridePrice(selectedPlan.paddle_id, selectedPlan.price - plans[0].price)
      .then((res) => res)
      .catch((err) => err);
    setCheckoutLoading("loaded");
    // @ts-ignore
    if (process.env.REACT_APP_APP_ENV !== "prod") {
      // @ts-ignore
      Paddle.Environment.set("sandbox");
    }
    // @ts-ignore
    Paddle.Setup({ vendor: parseInt(PADDLE_VENDOR_ID) });
    // @ts-ignore
    Paddle.Checkout.open({
      override: res.data.response.url,
      product: selectedPlan.paddle_id,
      email: activeWorkspace.organization.billing_email,
      allowQuantity: false,
      successCallback: function (data: any) {
        setTimeout(() => {
          fetchOrganizationPlan();
        }, 10000);

        try {
          gtag_report_conversion(
            parseInt(data.checkout.prices.customer.total),
            data.checkout.prices.customer.currency,
            data?.checkout?.id || ""
          );
        } catch (e) {
          console.log(e);
        }

        toast.success(
          "Plan upgraded successfully. Please wait for a few seconds while plan is refreshed."
        );
        closeModal(false);
      }
    });
  };

  const filteredPlans = () => {
    if (lifetimePlanType === "pro") {
      return plans.filter((plan) => plan.name.toLowerCase().includes("pro"));
    } else {
      const premiumPlans = plans.filter(
        (plan) =>
          plan.name.toLowerCase().includes("premium") &&
          plan.name !== activeWorkspace.organization.plan.name
      );
      return [plans[0], ...premiumPlans];
    }
  };

  useEffect(() => {
    if (open) {
      fetchUpgradablePlans();
    }
  }, [open]);
  useEffect(() => {
    const filteredPlansList = filteredPlans();
    if (filteredPlansList && filteredPlansList.length >= 1) {
      setSelectedPlan(filteredPlans()[1]);
    }
  }, [lifetimePlanType]);

  const getIcon = (icon: any) => {
    switch (icon) {
      case "check":
        return regular("check");
      case "times":
        return regular("times");
      default:
        return regular("check");
    }
  };

  const renderListItem = (feature: any) => {
    return (
      <List.Item key={feature.key}>
        <Flex align={"center"}>
          <Box w={16} fz="sm" mr={"xs"} c={feature.color || "green.7"}>
            <FontAwesomeIcon icon={getIcon(feature.icon || "check")}></FontAwesomeIcon>
          </Box>
          <Text>
            {feature.isNumeric && (
              <Text span fw={600}>
                {feature.value}
              </Text>
            )}
            {!feature.isNumeric && feature.value}
            {feature.label}
          </Text>
        </Flex>
      </List.Item>
    );
  };
  const renderProPlanFeaturesList = () => {
    const features = [
      {
        key: "workspaces",
        value: selectedPlan.workspaces > 1000 ? "Unlimited" : selectedPlan.workspaces,
        label: " Workspaces",
        isNumeric: true
      },
      {
        key: "events",
        value: numberToCommas(selectedPlan.events),
        label: " monthly events",
        isNumeric: true
      },
      { key: "dataOwnership", value: "100% Data ownership" },
      { key: "noCookieBanners", value: "No cookie banners required" },
      { key: "dataHistory", value: "2 years data history" },
      { key: "privacyCompliance", value: "Privacy law compliance" },
      { key: "infrastructure", value: "Enterprise-grade infrastructure" },
      {
        key: "adBlockerBypass",
        value: "Ad-blocker bypassing",
        icon: selectedPlan.pixel_whitelabel ? "check" : "times",
        color: selectedPlan.pixel_whitelabel ? "green.7" : "red.7"
      },
      {
        key: "pixelWhitelabel",
        value: "Pixel white-labelling",
        icon: selectedPlan.pixel_whitelabel ? "check" : "times",
        color: selectedPlan.pixel_whitelabel ? "green.7" : "red.7"
      }
    ];

    return <List>{features.map((feature) => renderListItem(feature))}</List>;
  };

  const renderPremiumPlanFeaturesList = () => {
    if (!selectedPlan) {
      return <></>;
    }

    const features = [
      {
        key: "workspaces",
        value: selectedPlan.workspaces > 1000 ? "Unlimited" : selectedPlan.workspaces,
        label: " Workspaces",
        isNumeric: true
      },
      {
        key: "events",
        value: numberToCommas(selectedPlan.events),
        label: " monthly events",
        isNumeric: true
      },
      { key: "dataHistory", value: "Unlimited years data history" },
      { key: "contactsView", value: "Contacts 360° view" },
      { key: "dataOwnership", value: "100% Data ownership" },
      { key: "companyAnalytics", value: "Company level analytics" },
      { key: "infrastructure", value: "Enterprise-grade infrastructure" },
      {
        key: "adBlockerBypass",
        value: "Ad-blocker bypassing",
        icon: selectedPlan.pixel_whitelabel ? "check" : "times",
        color: selectedPlan.pixel_whitelabel ? "green.6" : "red.6"
      },
      {
        key: "pixelWhitelabel",
        value: "Pixel white-labeling",
        icon: selectedPlan.pixel_whitelabel ? "check" : "times",
        color: selectedPlan.pixel_whitelabel ? "green.6" : "red.6"
      }
    ];

    return <List>{features.map((feature) => renderListItem(feature))}</List>;
  };
  console.log(plans);
  return (
    <>
      {loading === "loaded" ? (
        <>
          {plans[0].plan_type !== PLAN_PRODUCT_TYPE.WEB_PRODUCT &&
            plans[0].name.toLowerCase() !== "pro-10m-events" && (
              <LifetimePlanTypeSwitch
                activePricing={lifetimePlanType}
                setActivePricing={setLifetimePlanType}
              />
            )}

          <Grid>
            <Grid.Col span={6}>
              {filteredPlans().map((planItem: any, index: number) => (
                <>
                  <Radio.Card
                    pos={"relative"}
                    className={clsx(classes.root, index === 0 ? classes.disabled : "")}
                    radius="md"
                    disabled={index === 0}
                    checked={planItem.name === selectedPlan?.name && index !== 0}
                    onClick={() => {
                      if (index !== 0) {
                        setSelectedPlan(planItem);
                      }
                    }}>
                    <Group wrap="nowrap" align="flex-start">
                      <Radio.Indicator />
                      <Flex direction={"column"} flex={1}>
                        <Text className={classes.label}>{planItem.name.replaceAll("-", " ")}</Text>
                        <Text className={classes.description}>
                          Up-to {numberToCommas(planItem.events)} monthly events
                        </Text>
                        {index == 0 && (
                          <Badge variant="outline" className={classes.badge}>
                            Current Plan
                          </Badge>
                        )}
                      </Flex>
                    </Group>
                  </Radio.Card>
                </>
              ))}
            </Grid.Col>
            <Grid.Col span={6} p="md">
              {selectedPlan && (
                <>
                  <Title order={4} mb="sm">
                    This plan includes
                  </Title>

                  {selectedPlan && selectedPlan.name.toLowerCase().startsWith("pro") ? (
                    <>{renderProPlanFeaturesList()}</>
                  ) : (
                    <>{renderPremiumPlanFeaturesList()}</>
                  )}
                </>
              )}

              <Space h="md" />
              {selectedPlan && selectedPlan.name !== plans[0].name && (
                <>
                  <Title order={5} mb="xs">
                    You have already paid ${plans[0].price}. You'll pay $
                    {selectedPlan.price - plans[0].price} more.
                  </Title>{" "}
                  <Text fz="xs" mb="md">
                    Excluding VAT/Sales tax.
                  </Text>
                  <Button
                    mb="md"
                    fullWidth
                    size="sm"
                    disabled={checkoutLoading === "loading"}
                    onClick={() => handleUpgradePlan()}>
                    Upgrade Plan
                  </Button>
                  <Center>
                    <Flex align="center">
                      <Box w={16} mr={"sm"} c="green.7">
                        <FontAwesomeIcon icon={regular("lock")}></FontAwesomeIcon>
                      </Box>

                      <Text fz="sm">100% secure payment, powered by Paddle</Text>
                    </Flex>
                  </Center>
                </>
              )}
            </Grid.Col>
          </Grid>
        </>
      ) : (
        <>
          <Center my={"lg"}>
            <Loader size="xs" />
          </Center>
        </>
      )}
    </>
  );
};
