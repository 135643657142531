/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { numberToCommas, numberToUnit } from "@/lib/utils/StringUtility";
import React, { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "types/types.d";
import { SummaryCard } from "../SummaryCard";
import ContentLoader from "react-content-loader";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  topBarCardValues: ITopBarCardValues;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
  period: any;
  prevPeriod: any;
  comparisonPeriod?: any;
};

const UniqueVisitorsCard = ({
  activeCard,
  setActiveCard,
  topBarCardValues,
  comparisonTopBarCardValues,
  loading,
  query,
  period,
  prevPeriod,
  comparisonPeriod
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const getUniqueVisitorsCardValue = () => {
    let value = topBarCardValues.visitors;
    return value < 100000 ? numberToCommas(value) : numberToUnit(value);
  };

  const getComparisonPercentageDiff = () => {
    const percentageChange =
      ((topBarCardValues.visitors - comparisonTopBarCardValues.visitors) /
        comparisonTopBarCardValues.visitors) *
      100;
    return Math.round(percentageChange);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="unique-visitors-card">
      {loading === "loaded" ? (
        <SummaryCard
          aboutTooltip="Visitors represent individuals that visit your site. If that same visitor leaves your site and comes back later, Usermaven will remember it and the second visit won’t increase the number of unique visitors"
          activeCard={activeCard}
          title={query?.period === "live" ? "Visitors (Last 30 Min.)" : "Visitors"}
          onClick={() => setActiveCard("unique_visitors")}
          uniquekey={"uniqueVisitors"}
          card={"unique_visitors"}
          value={getUniqueVisitorsCardValue()}
          tooltip={`
                        ${numberToCommas(topBarCardValues.new_visitors)} new visitors, 
                        ${numberToCommas(topBarCardValues.returning_visitors)} returning visitors`}
          runningDiff={topBarCardValues.visitors_diff}
          runningDiffTooltip={`${topBarCardValues.visitors_diff}% ${
            topBarCardValues.visitors_diff > 0 ? "increase" : "decrease"
          } in visitors from previous period ${comparisonPeriod ? comparisonPeriod : prevPeriod} `}
          loading={loading}
          showRunningDiff={query?.period !== "live"}
          comparison={query?.comparison}
          comparisonPercentageDiff={getComparisonPercentageDiff()}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const UniqueVisitorsCardMemoized = React.memo(UniqueVisitorsCard);
export { UniqueVisitorsCardMemoized as UniqueVisitorsCard };
