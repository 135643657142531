/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { Note } from "@/stores/useAnnotationNotesStore";
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Modal,
  Radio,
  Switch,
  Text,
  TextInput,
  Textarea,
  Title,
  useMantineTheme
} from "@mantine/core";
import { DatePicker, DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { fromZonedTime } from "date-fns-tz";
import { useContext, useEffect } from "react";
import { NoteTypes } from "./NotesPopoverCard";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export enum NoteVisibilityEnum {
  OnlyMe = "Only me",
  AllUsers = "All users"
}

interface NotesModelProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  type: string;
  applyNoteChanges: (type: string, values: any) => void;
  data?: Note;
}

const NotesModel = ({ opened, setOpened, type, applyNoteChanges, data }: NotesModelProps) => {
  const theme = useGlobalMantineTheme();
  const visibilityOptions = Object.values(NoteVisibilityEnum);
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const form = useForm({
    initialValues: {
      title: type === NoteTypes.UPDATE && data ? data.title : "",
      date:
        type === NoteTypes.UPDATE && data
          ? data.date
          : fromZonedTime(new Date(), activeWorkspace.timezone),
      description: type === NoteTypes.UPDATE && data ? data.description : "",
      visibility: type === NoteTypes.UPDATE && data ? data.visibility : NoteVisibilityEnum.OnlyMe,
      on_shareable_dashboard:
        type === NoteTypes.UPDATE && data ? data.on_shareable_dashboard : false
    },

    validate: {
      title: (value) => (value.length > 0 ? null : "Note should not be empty"),
      date: (value) => {
        // Check if the date is empty
        if (value === null) {
          return "Date is required";
        }

        // Check if the date is valid
        const date = fromZonedTime(new Date(), activeWorkspace.timezone);
        const timestamp = date.getTime();
        if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
          return "Invalid date";
        }

        return null;
      }
    }
  });

  useEffect(() => {
    form.setValues({
      title: type === NoteTypes.UPDATE && data ? data.title : "",
      date:
        type === NoteTypes.UPDATE && data
          ? fromZonedTime(new Date(data.date), activeWorkspace.timezone)
          : fromZonedTime(new Date(), activeWorkspace.timezone),
      description: type === NoteTypes.UPDATE && data ? data.description : "",
      visibility:
        type === NoteTypes.UPDATE && data
          ? (data.visibility as NoteVisibilityEnum)
          : NoteVisibilityEnum.OnlyMe,
      on_shareable_dashboard:
        type === NoteTypes.UPDATE && data ? data.on_shareable_dashboard : false
    });
  }, [data, type]);

  if (![NoteTypes.CREATE, NoteTypes.UPDATE].includes(type)) {
    // If the type is not valid, return null to prevent rendering the modal
    return null;
  }

  return (
    <>
      <Modal
        size="lg"
        radius="md"
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        title={
          <Title order={6} fz="sm" fw={600} mb={0}>
            {type === NoteTypes.CREATE ? "New" : type === NoteTypes.UPDATE ? "Update" : "New"} Note
          </Title>
        }>
        {/* Modal content */}
        <Box mx="auto">
          <form
            onSubmit={form.onSubmit((values) => {
              const note = {
                ...values,
                id: data ? data.id : "",
                module: "web_analytics",
                on_shareable_dashboard:
                  values.visibility === NoteVisibilityEnum.AllUsers
                    ? values.on_shareable_dashboard
                    : false
              };
              applyNoteChanges(type, note);
              setOpened(false);
              form.reset();
            })}>
            <Flex justify="space-between" align="center" mb="sm">
              <TextInput
                style={{ flex: "1 1 70%" }}
                styles={{
                  label: {
                    // color: theme.colors.dark[3],
                    fontSize: "13px",
                    fontWeight: 400
                  }
                }}
                placeholder="Add title, such as 'Black Friday promotion'"
                label="Title"
                {...form.getInputProps("title")}
              />
              <DateInput
                ml={"md"}
                style={{ flex: "1 1 30%" }}
                styles={{
                  label: {
                    // color: theme.colors.dark[3],
                    cursor: "pointer",
                    fontSize: "13px",
                    fontWeight: 400
                  }
                }}
                placeholder="Date"
                label="Marker point"
                valueFormat="MMM DD, YYYY"
                // labelFormat="MMM DD, YYYY"
                defaultValue={new Date()}
                {...form.getInputProps("date")}
              />
            </Flex>

            <Textarea
              placeholder="Description (Optional)"
              autosize
              minRows={4}
              maxRows={6}
              {...form.getInputProps("description")}
            />

            <Flex align={"flex-start"} direction={"column"}>
              <Radio.Group
                my="md"
                label="Visibility"
                labelProps={{
                  style: {
                    marginBottom: "0.5rem"
                  }
                }}
                size="xs"
                {...form.getInputProps("visibility")}>
                {visibilityOptions.map((option) => (
                  <Radio
                    mb="xs"
                    size="xs"
                    key={option}
                    value={option}
                    label={
                      option === NoteVisibilityEnum.AllUsers
                        ? "All users within the workspace"
                        : option
                    }
                  />
                ))}
              </Radio.Group>

              {form.values.visibility === NoteVisibilityEnum.AllUsers && (
                <Switch
                  label="Add on shareable dashboard"
                  size="xs"
                  checked={form.values.on_shareable_dashboard}
                  {...form.getInputProps("on_shareable_dashboard", { type: "checkbox" })}
                />
              )}
            </Flex>
            <Divider mb="sm" />
            <Flex justify={"end"}>
              <Button size="xs" type="submit">
                {type === NoteTypes.CREATE
                  ? "Create"
                  : type === NoteTypes.UPDATE
                  ? "Update"
                  : "Create"}
              </Button>
            </Flex>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default NotesModel;
