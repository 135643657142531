/* eslint-disable react-hooks/exhaustive-deps */
import { getReadableFormatNumber, numberToCommas } from "@/lib/utils/StringUtility";
import React from "react";
import { ActiveCardProps, LoadingStateProps } from "@/types/types.d";
import { SummaryCard } from "../../../Cards/SummaryCard";
import getSymbolFromCurrency from "currency-symbol-map";

type Props = {
  activeCard: ActiveCardProps;
  loading: LoadingStateProps;
  data: any;
  comparisonData: any;
  query: any;
  period: any;
  prevPeriod: any;
  comparisonPeriod?: any;
  activeWorkspace?: any;
};

const ConversionValue = ({
  activeCard,
  loading,
  data,
  comparisonData,
  query,
  period,
  prevPeriod,
  comparisonPeriod,
  activeWorkspace
}: Props) => {
  const getComparisonPercentageDiff = () => {
    const percentageChange =
      ((data.total_conversion_value - comparisonData.total_conversion_value) /
        comparisonData.total_conversion_value) *
      100;
    return Math.round(percentageChange);
  };

  return (
    <div data-cy="events-card">
      <SummaryCard
        cursor="cursor-default"
        activeCard={activeCard}
        title={"Conversion Value"}
        onClick={() => {}}
        uniquekey={"conversion_value"}
        card={"conversion_value"}
        value={`${getSymbolFromCurrency(
          activeWorkspace.reporting_currency
        )}${getReadableFormatNumber(data.total_conversion_value)}`}
        tooltip={`${numberToCommas(data.total_conversion_value)} conversion value`}
        runningDiff={data.total_conversion_value_diff}
        runningDiffTooltip={`${data.total_conversion_value_diff}% ${
          data.total_conversion_value_diff > 0 ? "increase" : "decrease"
        } in total conversion value from previous period ${
          comparisonPeriod ? comparisonPeriod : prevPeriod
        } `}
        loading={loading}
        showRunningDiff={query?.period !== "live"}
        comparison={query?.comparison}
        comparisonPercentageDiff={getComparisonPercentageDiff()}
      />
    </div>
  );
};
const ConversionValueMemoized = React.memo(ConversionValue);
export { ConversionValueMemoized as ConversionValue };
