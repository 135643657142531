import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Autocomplete,
  Badge,
  Box,
  Button,
  Flex,
  NumberInput,
  Paper,
  Select,
  Text,
  TextInput,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import axios from "@/lib/services/JWTService";
import { useContext, useEffect, useState } from "react";
import {
  attributeGroupedOptionsList,
  deviceOptionsList,
  eventOptions,
  pageviewOptions
} from "../InsightsSetupEvents/InsightConditionsUtils";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { useSegmentFormContext } from "./SegmentFormContext";
import { addDays, format, subDays } from "date-fns";
import { useSegmentStore } from "@/stores/useSegmentStore";
import { endOfDay, startOfDay } from "date-fns";
import { InsightsService } from "@/lib/services/InsightsService";

const TypeOfSegmentCondition = {
  ATTRIBUTE: "attribute",
  EVENT: "event",
  PAGE: "page",
  DEVICE: "device",
  PINNED_EVENT: "pinned_event",
  LOCATION: "location"
};

interface ISegmentConditionFilter {
  field: string;
  operator: string;
  value: string;
}

export interface ISegmentCondition {
  /**
   * Field used for the condition. i.e "email", "name", "age" etc.
   */
  field: string;
  /**
   * Type of the condition. i.e "attribute", "event", "pageview", "device".
   */
  type: string;
  /**
   * Operator used for the condition. i.e "exists", "equals", "contains" etc.
   */
  operator: string;
  /**
   * Value used for the condition. i.e app.usermaven.com for the pageview, other than that it could be anything.
   */
  value: string;
  /**
   * Times is used for the event condition. i.e 5 times, 3 times etc.
   */
  times: number;
  /**
   * Within is used for the event condition. i.e 1 day, 1 week etc.
   */
  within: number;
  /**
   * Period is used for the event condition. i.e "ever", "within".
   * i.e 5 times ever, 3 times within the last 1 day etc.
   */
  frequency: string;

  /**
   * Timestamp field is used for the relative dates: from now, ago.
   */
  timestamp_field: string;

  /**
   * Timestamp field is used for the relative dates: from now, ago.
   */
  timestamp_value: string | number;

  /**
   * Nested filter options.
   * For example applying filter on the attribute level.
   */
  filters: {
    /**
     * Combinator is used for the event condition. i.e "and", "or".
     */
    combinator: string;
    rules: ISegmentConditionFilter[];
  };
}

interface ISegmentConditionProps {
  /**
   * Rule for the condition.
   */
  rule: ISegmentCondition;
  /**
   * Attributes that can be used for the condition, within workspace.
   */
  attributes: any;
  /**
   * Events that can be used for the condition, within workspace.
   */
  events: any;

  /**
   * Pinned events that can be used for the condition, within workspace.
   */
  pinnedEvents: any;

  /**
   * Index of the condition.
   */
  index: number;

  /**
   * Index of the group, if any.
   */
  groupIndex?: number;

  /**
   * Full width of the condition.
   */
  fullWidth?: boolean;
}

const DEFAULT_DEVICE_SUGGESTIONS = [
  {
    label: "Desktop",
    value: "Desktop"
  },
  {
    label: "Mobile",
    value: "Mobile"
  },
  {
    label: "Tablet",
    value: "Tablet"
  }
];

const DEVICE_OPTIONS = [
  {
    label: "Type",
    value: "type"
  },
  {
    label: "Operating System",
    value: "os"
  },
  {
    label: "Browser",
    value: "browser"
  }
];

const LOCATION_OPTIONS = [
  {
    label: "Country",
    value: "country"
  },
  {
    label: "City",
    value: "city"
  }
];

export const SegmentCondition = ({
  rule,
  attributes,
  pinnedEvents,
  events,
  index,
  groupIndex = -1,
  fullWidth = false
}: ISegmentConditionProps) => {

  /**
   * Color Scheme
   */

  const { colorScheme } = useMantineColorScheme();

  /**
   * Active workspace of the project.
   */
  const { activeWorkspace } = useContext(AppLifecycleContext);

  /**
   * Insights service to fetch the suggestions.
   */
  const insightsService = new InsightsService();

  /**
   * Pageview suggestions for the pageview condition.
   */
  const [pageviewSuggestions, setPageviewSuggestions] = useState<any>([]);

  /**
   * Device suggestions for the device condition.
   */

  const [deviceSuggestions, setDeviceSuggestions] = useState<any>([]);

  /**
   * Location suggestions for the location condition.
   */

  const [locationSuggestions, setLocationSuggestions] = useState<any>([]);

  /**
   * Location search value for the location condition.
   */
  const [locationSearchValue, onLocationSearchChange] = useState(rule.value);

  /**
   * Event attribute suggestions for the attribute condition.
   */

  const [eventAttributeSuggestions, setEventAttributeSuggestions] = useState<any>([]);

  /**
   * Debounced value for the pageview condition.
   */
  const [debounced] = useDebouncedValue(rule.value, 400);

  /**
   * Debounced value for the location conditions.
   */
  const [debouncedLocation] = useDebouncedValue(locationSearchValue, 400);

  /**
   * Check for whether condition is in group or not.
   */
  const isInGroup = groupIndex !== undefined && groupIndex > -1;

  /**
   * Segment mantine form
   */
  const form = useSegmentFormContext();

  /**
   * Type of segment, users, visitors or companies.
   */
  const [type] = useSegmentStore((state) => [state.type]);

  /**
   * Prefix for groups in the form. i.e groups.0. added to the rules to make it groups.0.rules.0.type
   */
  const groupPrefix = isInGroup ? `groups.${groupIndex}.` : "";

  /**
   * Render refining filters section.
   * This section contains the frequency and times.
   * For events it contains the Add event filter to allow attribute level filtering on the events.
   *
   * @returns
   */
  const renderRefineFilters = () => {
    if (
      (rule.type === TypeOfSegmentCondition.EVENT || rule.type === TypeOfSegmentCondition.PAGE) &&
      rule.frequency
    ) {
      return (
        <>
          <Box component="div" className="flex items-center mt-4">
            <Text c={colorScheme === "dark" ? "dark.2" : undefined}>at least</Text>{" "}
            <NumberInput
              styles={{ input: { width: 54 } }}
              width={40}
              ml={8}
              mr={8}
              size={"xs"}
              min={0}
              defaultValue={rule.times}
              stepHoldDelay={500}
              stepHoldInterval={100}
              {...form.getInputProps(`${groupPrefix}rules.${index}.times`)}
            />
            <Text c={colorScheme === "dark" ? "dark.2" : undefined}>
              time{rule.times > 1 ? "s" : ""}
            </Text>
            <Select
              ml={8}
              styles={{ input: { width: 140 } }}
              defaultValue={rule.frequency}
              placeholder="Select period"
              size="xs"
              data={[
                { label: "ever", value: "ever" },
                {
                  label: "within the last",
                  value: "within"
                }
              ]}
              {...form.getInputProps(`${groupPrefix}rules.${index}.frequency`)}
            />
            {rule.frequency === "within" && (
              <>
                <NumberInput
                  styles={{ input: { width: 54 } }}
                  width={40}
                  ml={8}
                  mr={8}
                  size={"xs"}
                  min={0}
                  defaultValue={rule.within}
                  stepHoldDelay={500}
                  stepHoldInterval={100}
                  {...form.getInputProps(`${groupPrefix}rules.${index}.within`)}
                />
                <Text>day{rule.within > 1 ? "s" : ""}</Text>
              </>
            )}
            <div className="hidden group-hover:flex">
              <ActionIcon
                size="xs"
                className=" ml-4"
                data-tip="Remove"
                variant="outline"
                color={"red"}
                onClick={() => form.setFieldValue(`${groupPrefix}rules.${index}.frequency`, "")}>
                <FontAwesomeIcon icon={regular("trash")} className=" transform text-xs" />
              </ActionIcon>
            </div>
          </Box>
          {rule.type === TypeOfSegmentCondition.EVENT && (
            <Box component="div" className="mt-4 mx-4">
              {renderEventAttributeFilters()}

              <Text
                c={colorScheme === "dark" ? "dark.2" : undefined}
                td="underline"
                fz="xs"
                className={` underline hover:opacity-70 cursor-pointer ${
                  rule.filters.rules.length > 0 ? "mt-4" : ""
                }`}
                onClick={() => {
                  form.insertListItem(`${groupPrefix}rules.${index}.filters.rules`, {
                    field: "",
                    operator: "eq",
                    value: ""
                  });
                }}>
                Add event filter
              </Text>
            </Box>
          )}
        </>
      );
    }
    return null;
  };

  /**
   * This function is used for rendering the event attribute filters rules.
   */
  const renderEventAttributeFiltersRules = rule.filters?.rules?.map((filter: any, i: number) => {
    return (
      <Flex
        align="center"
        component="div"
        key={`filter-${i}`}
        className={`${
          i < rule.filters?.rules.length - 1 ? "mb-4" : ""
        } flex items-center transition-all ease-in-out ${
          rule.filters.rules.length > 1 && i === 0 ? "ml-16" : ""
        }`}>
        {rule.filters.rules.length > 1 && i > 0 && (
          <Badge
            radius={"sm"}
            color={colorScheme === "dark" ? "dark.5" : "gray.6"}
            variant="filled"
            className="mr-4">
            {rule.filters.combinator}
          </Badge>
        )}
        <Select
          searchable
          placeholder={`Select event attribute`}
          size="xs"
          className="w-60 mr-4"
          defaultValue={filter.field}
          data={eventAttributeSuggestions}
          {...form.getInputProps(`${groupPrefix}rules.${index}.filters.rules.${i}.field`)}
        />
        <Select
          searchable
          placeholder={"Select operator"}
          size="xs"
          className={`${rule.type === "event" ? "w-60" : "w-48"} mr-4`}
          defaultValue={filter.operator}
          data={attributeGroupedOptionsList}
          {...form.getInputProps(`${groupPrefix}rules.${index}.filters.rules.${i}.operator`)}
        />
        {filter.operator !== "exists" && filter.operator !== "!exists" && (
          <TextInput
            size="xs"
            placeholder="Enter value..."
            {...form.getInputProps(`${groupPrefix}rules.${index}.filters.rules.${i}.value`)}
          />
        )}

        <Flex flex={1} justify={"end"}>
          <ActionIcon
            className=" ml-4"
            data-tip="Remove"
            variant="outline"
            color={"red"}
            size="sm"
            onClick={() => form.removeListItem(`${groupPrefix}rules.${index}.filters.rules`, i)}>
            <FontAwesomeIcon icon={regular("trash")} className=" transform text-sm" />
          </ActionIcon>
        </Flex>
      </Flex>
    );
  });

  /**
   * This function is responsible for the refinement of the event attribute. The event attribute allows users to
   * filter the events based on the attributes.
   *
   * @returns
   */
  const renderEventAttributeFilters = () => {
    if (!isInGroup && form.values.rules?.[index]?.filters?.rules?.length === 0) return null;
    if (isInGroup && form.values.groups?.[groupIndex]?.rules?.[index]?.filters?.rules?.length === 0)
      return null;
    return (
      <Flex
        direction={"column"}
        p={"sm"}
        style={(theme) => ({
          background: colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0]
        })}>
        <div className="flex items-center mb-4">
          <Select
            size="xs"
            className="w-36 mr-4"
            data={[
              {
                label: "All",
                value: "and"
              },
              {
                label: "At least one",
                value: "or"
              }
            ]}
            {...form.getInputProps(`${groupPrefix}rules.${index}.filters.combinator`)}
          />
          <Text color={colorScheme === "dark" ? "dark.2" : undefined}>
            of the following conditions match
          </Text>
        </div>
        <div className="flex flex-col">
          {/* <SegmentAttributeFilterField rule={rule} form={form} index={index} /> */}
          {renderEventAttributeFiltersRules}
        </div>
      </Flex>
    );
  };

  /**
   * This function provides the selection for the field from attributes and events.
   * @returns Field selection. i.e attribute, event for now.
   */
  const renderField = () => {
    if (rule.type === TypeOfSegmentCondition.PAGE) return null;
    return (
      <>
        <Select
          searchable
          placeholder={`Select ${rule.type}`}
          size="xs"
          className="w-60 mr-4"
          zIndex={100}
          defaultValue={rule.field}
          data={
            rule.type === TypeOfSegmentCondition.ATTRIBUTE
              ? attributes
              : rule.type === TypeOfSegmentCondition.EVENT
              ? events
              : rule.type === TypeOfSegmentCondition.PINNED_EVENT
              ? pinnedEvents
              : rule.type === TypeOfSegmentCondition.LOCATION
              ? LOCATION_OPTIONS
              : DEVICE_OPTIONS
          }
          {...form.getInputProps(`${groupPrefix}rules.${index}.field`)}
          // @ts-ignore
          onChange={handleRenderFieldChange}
          //   error={form.errors.rules?.[index]?.field}
        />
      </>
    );
  };

  /**
   * This function provides the selection for the operator from the list of operators.
   * @param value
   */
  const handleRenderFieldChange = (value: string) => {
    form.setFieldValue(`${groupPrefix}rules.${index}.field`, value);
    if (rule.type === "device") {
      form.setFieldValue(`${groupPrefix}rules.${index}.value`, "");
      if (value === "type") {
        setDeviceSuggestions(DEFAULT_DEVICE_SUGGESTIONS);
      } else {
        handleDeviceSuggestions("", value);
      }
    }
    if (rule.type === "location") {
      form.setFieldValue(`${groupPrefix}rules.${index}.value`, "");
      handleLocationSuggestions("", value);
    }
  };

  /**
   * Empty the used values when the user change the type of the rule.
   * Types are: event, attribute, page, device
   * @param value
   */
  const handleTypeChange = (value: string) => {
    const operatorValue =
      value === TypeOfSegmentCondition.EVENT || value === TypeOfSegmentCondition.PINNED_EVENT
        ? eventOptions[0].value
        : value === TypeOfSegmentCondition.ATTRIBUTE
        ? // @ts-ignore
          attributeGroupedOptionsList[0].value
        : value === TypeOfSegmentCondition.PAGE
        ? // @ts-ignore
          pageviewOptions[0].value
        : value === TypeOfSegmentCondition.LOCATION
        ? deviceOptionsList[0].value
        : deviceOptionsList[0].value;

    form.setFieldValue(`${groupPrefix}rules.${index}.type`, value);
    form.setFieldValue(`${groupPrefix}rules.${index}.field`, "");
    form.setFieldValue(`${groupPrefix}rules.${index}.operator`, operatorValue);
    if (value !== "event") {
      form.setFieldValue(`${groupPrefix}rules.${index}.within`, 1);
      form.setFieldValue(`${groupPrefix}rules.${index}.times`, 1);
      form.setFieldValue(`${groupPrefix}rules.${index}.frequency`, "");
      form.setFieldValue(`${groupPrefix}rules.${index}.filters`, {
        combinator: "and",
        rules: []
      });
    }
  };

  const getGroupAttributesOptionsList = () => {
    return rule.field === "last_seen" ||
      rule.field === "first_seen" ||
      rule.field === "user_created_at" ||
      rule.field === "company_created_at"
      ? attributeGroupedOptionsList.filter((attr) => attr.group === "Timestamp")
      : attributeGroupedOptionsList;
  };

  /**
   * Renders the form for the operator field.
   * @returns Operator selection. i.e eq, !eq etc.
   */
  const renderOperator = () => {
    return (
      <>
        <Select
          searchable
          placeholder={"Select operator"}
          size="xs"
          className={`${rule.type === TypeOfSegmentCondition.EVENT ? "w-60" : "w-48"} mr-4`}
          defaultValue={rule.operator}
          data={
            rule.type === TypeOfSegmentCondition.ATTRIBUTE
              ? getGroupAttributesOptionsList()
              : rule.type === TypeOfSegmentCondition.EVENT
              ? eventOptions
              : rule.type === TypeOfSegmentCondition.PINNED_EVENT
              ? [eventOptions[0]]
              : rule.type === TypeOfSegmentCondition.PAGE
              ? pageviewOptions
              : deviceOptionsList
          }
          // @ts-ignore
          onChange={(value: string) => {
            form.setFieldValue(`${groupPrefix}rules.${index}.operator`, value);
            if (value === "timestamp_gt" || value === "timestamp_lt") {
              if (!rule.timestamp_value) {
                form.setFieldValue(`${groupPrefix}rules.${index}.timestamp_value`, 0);
                form.setFieldValue(`${groupPrefix}rules.${index}.value`, "relative_date");
              } else {
                if (rule.value === "specific_date") {
                  // change the timestamp value to reflect the operator
                  // if operator is timestamp_gt then set the timestamp_value to end of day
                  // if operator is timestamp_lt then set the timestamp_value to start of day
                  form.setFieldValue(
                    `${groupPrefix}rules.${index}.timestamp_value`,
                    value === "timestamp_gt"
                      ? endOfDay(new Date(rule.timestamp_value))
                      : startOfDay(new Date(rule.timestamp_value))
                  );
                }
              }
              if (!rule.timestamp_field) {
                form.setFieldValue(`${groupPrefix}rules.${index}.timestamp_field`, "ago");
              }
            }
            // if pageview and operator is !eq or !contains then empty frequency
            if (
              rule.type === TypeOfSegmentCondition.PAGE &&
              (value === "!eq" || value === "!contains")
            ) {
              if (rule.frequency) {
                form.setFieldValue(`${groupPrefix}rules.${index}.frequency`, "");
              }
            }
          }}
          value={form.getInputProps(`${groupPrefix}rules.${index}.operator`).value}
          error={form.getInputProps(`${groupPrefix}rules.${index}.operator`).error}
          onFocus={form.getInputProps(`${groupPrefix}rules.${index}.operator`).onFocus}
        />
      </>
    );
  };

  /**
   * Renders the form for the value field.
   * @returns
   */
  const renderValue = () => {
    if (
      rule.type === TypeOfSegmentCondition.EVENT ||
      rule.type === TypeOfSegmentCondition.PINNED_EVENT
    )
      return null;
    if (rule.operator === "exists" || rule.operator === "!exists") return null;

    if (rule.type === TypeOfSegmentCondition.DEVICE) {
      return (
        <>
          <Select
            data={deviceSuggestions}
            size="xs"
            placeholder="Select value"
            defaultValue={rule.value}
            searchable
            {...form.getInputProps(`${groupPrefix}rules.${index}.value`)}
          />
        </>
      );
    }

    if (rule.type === TypeOfSegmentCondition.LOCATION) {
      return (
        <>
          <Select
            // onClick={() => {
            //   handleLocationSuggestions(debounced, rule.field)
            // }}
            data={locationSuggestions}
            size="xs"
            placeholder="Select value"
            defaultValue={rule.value}
            searchable
            searchValue={locationSearchValue}
            onSearchChange={onLocationSearchChange}
            {...form.getInputProps(`${groupPrefix}rules.${index}.value`)}
          />
        </>
      );
    }
    if (rule.operator === "timestamp_gt" || rule.operator === "timestamp_lt") {
      return (
        <>
          <Select
            size="xs"
            data={[
              { label: "a relative date", value: "relative_date" },
              { label: "a specific date", value: "specific_date" }
            ]}
            value={rule.value}
            // @ts-ignore
            onChange={(value: string) => {
              form.setFieldValue(`${groupPrefix}rules.${index}.value`, value);
              if (value === "specific_date") {
                form.setFieldValue(
                  `${groupPrefix}rules.${index}.timestamp_value`,
                  rule.operator === "timestamp_gt" ? endOfDay(new Date()) : startOfDay(new Date())
                );
              } else {
                if (!Number.isInteger(rule.timestamp_value)) {
                  form.setFieldValue(`${groupPrefix}rules.${index}.timestamp_value`, 0);
                }
              }
            }}
          />
        </>
      );
    }

    // NOTE: this is complicating the scenario for the query builder.

    // if (rule.operator === "timestamp_bw") {
    //   return (
    //     <>
    //       <DateRangePicker
    //         size="xs"
    //         placeholder="Pick dates range"
    //         defaultValue={
    //           rule.value ? [new Date(rule.value[0]), new Date(rule.value[1])] : undefined
    //         }
    //         onChange={(value: any) => {
    //           form.setFieldValue(`${groupPrefix}rules.${index}.value`, value)
    //         }}
    //       />
    //     </>
    //   )
    // }

    return (
      <>
        <div className="w-[250px]">
          <Autocomplete
            data={pageviewSuggestions}
            placeholder={
              rule.operator === "matches_pattern"
                ? "https://www.example.com/*/products/*"
                : rule.operator === "matches_regex"
                ? "http://abc\\.io/(product|category)/[0-9]+"
                : "Select Value..."
            }
            defaultValue={rule?.value || ""}
            key={`autocomplete-segment-condition-select-${rule}`}
            id={`autocomplete-segment-condition-select-${rule}`}
            // transition="pop-top-left"
            // transitionDuration={80}
            limit={25}
            className="w-full"
            // transitionTimingFunction="ease"
            maxDropdownHeight={250}
            // value={rule.value}
            // onChange={(value: string) => {
            //   handleValueChange(value, index)
            // }}
            size="xs"
            {...form.getInputProps(`${groupPrefix}rules.${index}.value`)}
          />
        </div>
      </>
    );
  };

  /**
   *
   * @returns
   */
  const renderRefineButton = () => {
    if (
      (rule.type === TypeOfSegmentCondition.EVENT || rule.type === TypeOfSegmentCondition.PAGE) &&
      !rule.frequency
    ) {
      return (
        <Button
          color="gray.6"
          variant="outline"
          size="xs"
          className="ml-4"
          disabled={
            rule.type === TypeOfSegmentCondition.PAGE &&
            (rule.operator == "!eq" || rule.operator == "!contains")
          }
          onClick={() => {
            rule.type === TypeOfSegmentCondition.EVENT &&
              handleEventAttributeSuggestions(rule.field);
            form.setFieldValue(`${groupPrefix}rules.${index}.frequency`, "ever");
          }}>
          Refine
        </Button>
      );
    }
    return null;
  };

  const renderTimestampFilters = () => {
    if (rule.operator === "timestamp_gt" || rule.operator === "timestamp_lt") {
      if (rule.value === "specific_date") {
        return (
          <DatePickerInput
            w={200}
            my="sm"
            size="xs"
            placeholder="Pick a date"
            leftSection={<FontAwesomeIcon size="xs" icon={regular("calendar")} />}
            rightSection={<FontAwesomeIcon size="xs" icon={regular("angle-down")} />}
            defaultValue={
              rule.timestamp_value
                ? rule.operator === "timestamp_gt"
                  ? endOfDay(new Date(rule.timestamp_value))
                  : startOfDay(new Date(rule.timestamp_value))
                : undefined
            }
            onChange={(value: any) => {
              form.setFieldValue(
                `${groupPrefix}rules.${index}.timestamp_value`,
                rule.operator === "timestamp_gt"
                  ? endOfDay(new Date(value))
                  : startOfDay(new Date(value))
              );
            }}
            value={
              rule.timestamp_value
                ? rule.operator === "timestamp_gt"
                  ? endOfDay(new Date(rule.timestamp_value))
                  : startOfDay(new Date(rule.timestamp_value))
                : rule.operator === "timestamp_gt"
                ? endOfDay(new Date())
                : startOfDay(new Date())
            }
          />
        );
      }

      return (
        <>
          <Flex align={"center"} py="xs">
            <Text c={colorScheme === "dark" ? "dark.2" : undefined}>of</Text>
            <NumberInput
              styles={{ input: { width: 54 } }}
              width={40}
              ml={8}
              mr={8}
              size={"xs"}
              min={0}
              // defaultValue={rule.timestamp_value}
              stepHoldDelay={500}
              stepHoldInterval={100}
              {...form.getInputProps(`${groupPrefix}rules.${index}.timestamp_value`)}
            />
            <Text c={colorScheme === "dark" ? "dark.2" : undefined}>
              day
              {
                // @ts-ignore
                rule.timestamp_value > 1 ? "s" : ""
              }
            </Text>
            <Select
              ml={8}
              styles={{ input: { width: 140 } }}
              defaultValue={rule.timestamp_field}
              placeholder="Select time"
              size="xs"
              data={[
                { label: "ago", value: "ago" },
                {
                  label: "from now",
                  value: "from_now"
                }
              ]}
              {...form.getInputProps(`${groupPrefix}rules.${index}.timestamp_field`)}
            />
            <Tooltip
              position="bottom"
              multiline
              w={230}
              withArrow
              label={`If run right now, this condition would find any ${type} with a ${
                attributes.find((attribute: any) => attribute.value === rule.field)?.label
              } (${rule.field}) date on or ${
                rule.operator === "timestamp_gt" ? "after" : "before"
              } ${
                (isInGroup &&
                  form.values.groups?.[groupIndex]?.rules?.[index]?.timestamp_field === "ago") ||
                (!isInGroup && form.values.rules?.[index]?.timestamp_field === "ago")
                  ? format(
                      subDays(
                        new Date(),
                        ((isInGroup
                          ? form.values.groups?.[groupIndex]?.rules?.[index]?.timestamp_value || 0
                          : form.values.rules?.[index]?.timestamp_value) as number) || 0
                      ),
                      "dd, MMM yyyy"
                    )
                  : format(
                      addDays(
                        new Date(),
                        ((isInGroup
                          ? form.values.groups?.[groupIndex]?.rules?.[index]?.timestamp_value || 0
                          : form.values.rules?.[index]?.timestamp_value) as number) || 0
                      ),
                      "dd, MMM yyyy"
                    )
              } at ${format(new Date(), "pppp")}`}>
              <Text ml="xs" td="underline" c="dimmed">
                What's the output?
              </Text>
            </Tooltip>
          </Flex>
        </>
      );
    }
    return <></>;
  };

  /**
   * Fetch the page view suggestions for the user.
   * @param query Query to search for.
   */

  const handlePageViewSuggestions = (query: any) => {
    insightsService
      .suggestions(activeWorkspace.id, "page_url", query)
      .then((res) => {
        setPageviewSuggestions(res.data);
      })
      .catch((err) => {
        setPageviewSuggestions([]);
      });
  };

  /**
   * Get device suggestions i.e browser, os, type etc.
   * @param query Query to search for.
   * @param typeOfEvent i.e browser, os, type etc.
   */
  const handleDeviceSuggestions = (query: any, typeOfEvent: string) => {
    insightsService
      .suggestions(activeWorkspace.id, typeOfEvent, query)
      .then((res) => {
        setDeviceSuggestions(res.data);
      })
      .catch((err) => {
        setDeviceSuggestions([]);
      });
  };

  /**
   * Get event attribute suggestions based on the event.
   * @param event. Event name against which to bring the suggestions.
   */
  const handleEventAttributeSuggestions = (event: any) => {
    insightsService
      .suggestions(activeWorkspace.id, "event", event)
      .then((res) => {
        setEventAttributeSuggestions(res.data);
      })
      .catch((err) => {
        setEventAttributeSuggestions([]);
      });
  };

  /**
   * Get event attribute suggestions based on the event.
   * @param event. Event name against which to bring the suggestions.
   */
  const handleLocationSuggestions = (event: any, typeOfEvent: string = "country") => {
    insightsService
      .suggestions(activeWorkspace.id, typeOfEvent, event)
      .then((res) => {
        setLocationSuggestions(res.data);
      })
      .catch((err) => {
        setLocationSuggestions([]);
      });
  };

  /**
   * Used for bringing page view suggestions.
   */
  useEffect(() => {
    if (rule.type === TypeOfSegmentCondition.PAGE) {
      handlePageViewSuggestions(debounced);
    } else if (rule.type === TypeOfSegmentCondition.LOCATION) {
      handleLocationSuggestions(debouncedLocation, rule.field);
    } else {
      setLocationSuggestions([]);
      setPageviewSuggestions([]);
    }
  }, [debounced, debouncedLocation, rule.type]);

  /**
   * In case of editing segment, we need to fetch the event attribute suggestions. Otherwise they are blank.
   */
  useEffect(() => {
    if (rule.frequency) {
      handleEventAttributeSuggestions(rule.field);
    } else {
      setEventAttributeSuggestions([]);
    }
  }, [rule.field]);

  /**
   * Fetch initial device suggestions.
   */
  useEffect(() => {
    if (rule.type === TypeOfSegmentCondition.DEVICE) {
      if (rule.field === "type") {
        setDeviceSuggestions(DEFAULT_DEVICE_SUGGESTIONS);
      } else {
        handleDeviceSuggestions("", rule.field);
      }
    }
  }, []);

  return (
    <>
      {/* {JSON.stringify(rule)} */}
      <Paper
        pos={"relative"}
        component="div"
        p="sm"
        withBorder
        style={{
          width: fullWidth || isInGroup ? "100%" : "75%"
        }}
        ml={
          (form.values.rules.length > 1 || form.values.groups?.length > 0) &&
          index === 0 &&
          !isInGroup &&
          !fullWidth
            ? 80
            : 0
        }
        className={`group`}>
        <Flex align={"center"} justify={"start"} wrap="wrap">
          <Select
            searchable
            placeholder="Select type"
            size="xs"
            className="w-48 mr-4"
            defaultValue={rule.type}
            popoverProps={{ withinPortal: false }}
            data={[
              { label: "Attribute", value: TypeOfSegmentCondition.ATTRIBUTE },
              { label: "Pinned event", value: TypeOfSegmentCondition.PINNED_EVENT },
              { label: "Custom Event", value: TypeOfSegmentCondition.EVENT },
              { label: "Visited Page URL", value: TypeOfSegmentCondition.PAGE },
              { label: "Device", value: TypeOfSegmentCondition.DEVICE },
              { label: "Location", value: TypeOfSegmentCondition.LOCATION }
            ]}
            {...form.getInputProps(`${groupPrefix}rules.${index}.type`)}
            // @ts-ignore
            onChange={handleTypeChange}
          />
          {renderField()}
          {renderOperator()}
          {renderValue()}
          <div className="hidden justify-end flex flex-grow group-hover:flex items-center">
            {renderRefineButton()}
            <ActionIcon
              className=" ml-4"
              data-tip="Remove"
              variant="outline"
              color={"red"}
              size="sm"
              onClick={() => {
                form.removeListItem(`${groupPrefix}rules`, index);
              }}>
              <FontAwesomeIcon icon={regular("trash")} className="transform text-sm" />
            </ActionIcon>
          </div>
        </Flex>
        <div>{renderRefineFilters()}</div>
        <div>{renderTimestampFilters()}</div>
      </Paper>
    </>
  );
};
