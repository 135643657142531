// @ts-ignore
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "@storybook/addons"
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext"
import { getIntlDateAndTime } from "@/lib/utils/DateUtility"
import { useContext } from "react"
import { NavLink } from "react-router-dom"
import { PLAN_TYPES } from "@/lib/utils/Constants"
import { Text, useMantineTheme } from "@mantine/core"
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

export const DeprecationOfLegacyPlanNotification = ({ className }: any) => {
  const { activeWorkspace } = useContext(AppLifecycleContext)
  const theme = useGlobalMantineTheme()

  return (
    <>
      {activeWorkspace?.organization?.plan &&
        activeWorkspace.organization.plan.name === PLAN_TYPES.STARTER_LEGACY && (
          <>
            <div
              className={`flex  px-4 py-4 shadow-sm  bg-opacity-50 rounded border border-solid border-red-200 bg-red-100   transition-all ease-in-out ${className} ${
                theme.colorScheme === "dark"
                  ? "!bg-red-400 border-red-600 bg-opacity-100 !text-red-800"
                  : "text-red-800"
              }`}
            >
              <div className="h-4 w-4 mr-4">
                <FontAwesomeIcon
                  size="lg"
                  className="text-red-800 "
                  icon={regular("triangle-exclamation")}
                />
              </div>
              <Text size="sm">
                <span className="font-bold">Starter (legacy)</span> plan will be deprecated on{" "}
                <span className="font-bold">Sep 30, 2023</span> and your account will be moved to
                the updated starter plan shown on our{" "}
                <a href="https://usermaven.com/pricing" target="_blank">
                  pricing page
                </a>
                . Please{" "}
                <NavLink
                  to={`/env/${activeWorkspace.identifier}/account/billing`}
                  className="pr-1.5"
                >
                  upgrade your account
                </NavLink>{" "}
                to avoid any limitations or inconvenience.
              </Text>
            </div>
          </>
        )}
    </>
  )
}
