/* eslint-disable react-hooks/exhaustive-deps */
import { getReadableFormatNumber, numberToCommas } from "@/lib/utils/StringUtility";
import React from "react";
import { ActiveCardProps, LoadingStateProps } from "@/types/types.d";
import { SummaryCard } from "../../../Cards/SummaryCard";

type Props = {
  activeCard: ActiveCardProps;
  loading: LoadingStateProps;
  data: any;
  comparisonData: any;
  query: any;
  period: any;
  prevPeriod: any;
  comparisonPeriod?: any;
};

const UniqueVisitorsConvCard = ({
  activeCard,
  loading,
  data,
  comparisonData,
  query,
  period,
  prevPeriod,
  comparisonPeriod
}: Props) => {
  const getComparisonPercentageDiff = () => {
    const percentageChange =
      ((data.unique_visitors - comparisonData.unique_visitors) / comparisonData.unique_visitors) *
      100;
    return Math.round(percentageChange);
  };

  return (
    <div data-cy="events-card">
      <SummaryCard
        cursor="cursor-default"
        activeCard={activeCard}
        title={"Unique Visitors"}
        onClick={() => {}}
        uniquekey={"unique_visitors"}
        card={"unique_visitors"}
        value={getReadableFormatNumber(data.unique_visitors)}
        tooltip={`${numberToCommas(data.unique_visitors)} Unique Visitors`}
        runningDiff={data.unique_visitors_diff}
        runningDiffTooltip={`${data.unique_visitors_diff}% ${
          data.unique_visitors_diff > 0 ? "increase" : "decrease"
        } in unique visitors from previous period ${
          comparisonPeriod ? comparisonPeriod : prevPeriod
        } `}
        loading={loading}
        showRunningDiff={query?.period !== "live"}
        comparison={query?.comparison}
        comparisonPercentageDiff={getComparisonPercentageDiff()}
      />
    </div>
  );
};
const UniqueVisitorsConvCardMemoized = React.memo(UniqueVisitorsConvCard);
export { UniqueVisitorsConvCardMemoized as UniqueVisitorsConvCard };
