/* eslint-disable react-hooks/exhaustive-deps */
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { numberToCommas, numberToUnit } from "@/lib/utils/StringUtility";
import React, { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActiveCardProps, LoadingStateProps } from "@/types/types.d";
import { SummaryComparisonCard } from "../SummaryComparisonCard";
import ContentLoader from "react-content-loader";
import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import { ITopBarCardValues } from "../../WebAnalyticsOverview";

type Props = {
  activeCard: ActiveCardProps;
  setActiveCard: (value: ActiveCardProps) => void;
  comparisonTopBarCardValues: ITopBarCardValues;
  loading: LoadingStateProps;
  query: any;
};

const EventsComparisonCard = ({
  activeCard,
  setActiveCard,
  comparisonTopBarCardValues,
  loading,
  query
}: Props) => {
  const { activeWorkspace } = useContext(AppLifecycleContext);

  const getEventsComparisonCardValue = () => {
    return comparisonTopBarCardValues.events < 100000
      ? numberToCommas(comparisonTopBarCardValues.events)
      : numberToUnit(comparisonTopBarCardValues.events);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [query, activeWorkspace.id]);

  return (
    <div data-cy="events-card">
      {loading === "loaded" ? (
        <SummaryComparisonCard
          card="events"
          activeCard={activeCard}
          onClick={() => setActiveCard("events")}
          uniquekey={"events"}
          loading={loading}
          comparisonValue={`${getEventsComparisonCardValue()}`}
          comparisonTooltip={`
            ${numberToCommas(comparisonTopBarCardValues.autocaptured_events)} autocaptured events,
            ${numberToCommas(comparisonTopBarCardValues.custom_events)} custom events`}
        />
      ) : (
        <Box my={8}>
          <Skeleton height={12} width={100} />
        </Box>
      )}
    </div>
  );
};
const EventsComparisonCardMemoized = React.memo(EventsComparisonCard);
export { EventsComparisonCardMemoized as EventsComparisonCard };
