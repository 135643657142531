import { AutoCapturedEventsService } from "@/lib/services/AutoCapturedEventsService";
import { LooseObject } from "types/types.d";
import create from "zustand";

interface useAutoCapturedEventsStore {
  loading: boolean;
  hasMore: boolean;
  noResults: boolean;
  page: number;
  events: Array<LooseObject>;
  selectedEvents: Array<LooseObject>;
  eventType: string;
  search: string;
  wizard: string;
  setLoading: (loading: boolean) => void;
  setPage: (page: number) => void;
  setEvents: (events: Array<LooseObject>) => void;
  setEventType: (eventType: string) => void;
  setSearch: (search: string) => void;
  fetchEvents: (wsId: string, page: number) => void;
  addToSelectedEvents: (event: LooseObject) => void;
  setSelectedEvents: (events: Array<LooseObject>) => void;
  setHasMore: (hasMore: boolean) => void;
  setWizard: (wizard: string) => void;
  reset: () => void;
}

const initialState = {
  loading: false,
  hasMore: false,
  noResults: false,
  page: 1,
  selectedEvents: [],
  events: [],
  eventType: "label_text",
  search: "",
  wizard: "select_events"
};

export const useAutoCapturedEventsStore = create<useAutoCapturedEventsStore>((set, get) => ({
  ...initialState,
  setLoading: (loading: boolean) => set({ loading }),
  setHasMore: (hasMore: boolean) => set({ hasMore }),
  setPage: (page) => set({ page }),
  setWizard: (wizard: string) => set({ wizard }),
  setEvents: (events: any) => set({ events }),
  addToSelectedEvents: (event: LooseObject) =>
    set((state) => ({ selectedEvents: [...state.selectedEvents, event] })),
  setSelectedEvents: (selectedEvents: any) => set({ selectedEvents }),
  setEventType: (eventType: string) => set({ eventType }),
  setSearch: (search: string) => set({ search }),
  fetchEvents: async (wsId: string, page: number) => {
    // ...
    set({ loading: true });
    await new AutoCapturedEventsService()
      .search(wsId, get().eventType, get().search, page)
      .then((res) => {
        if (page > 1) {
          set({ events: get().events.concat(res.data) });
          set({ hasMore: res.data.length > 0, page: page });
        } else {
          set({ events: res.data, noResults: res.data.length === 0 });
          set({ hasMore: res.data.length === 50 });
        }
      })
      .catch((err) => err);
    set({ loading: false });
  },
  reset: () => set(initialState)
}));
