import { DATE_RANGES } from './Constants';

interface PeriodRangeProps {
  label: string;
  startDate?: string;
  endDate?: string;
  interval?: string;
}

export const getReportingPeriodRange = (
  period:
    | 'today'
    | 'yesterday'
    | 'month_to_date'
    | 'last_month'
    | 'last_quarter'
    | 'last_6_months'
    | 'last_9_months'
    | 'last_year'
    | 'last_7_days'
    | 'last_14_days'
    | 'last_21_days'
    | 'last_30_days'
): PeriodRangeProps | undefined => {
  return DATE_RANGES.find((range: PeriodRangeProps) => {
    return range.label.replaceAll(' ', '_').toLowerCase() === period;
  });
};
