import { LOADER_COLOR } from "@/lib/utils/Constants";
import { Oval } from "react-loader-spinner";

export const LoadingRow = ({ colspan = 2 }: { colspan?: number }) => {
  return (
    <>
      <tr>
        <td
          colSpan={colspan}
          style={{
            padding: "0.5rem 0rem",
            textAlign: "center",
            margin: "0 auto"
          }}>
          <Oval wrapperClass="flex justify-center" color={LOADER_COLOR} height={16} width={16} />
        </td>
      </tr>
    </>
  );
};
