/* eslint-disable react-hooks/exhaustive-deps */
import { useIntersection, useLocalStorage } from "@mantine/hooks";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { WebAnalyticsService } from "@/lib/services/WebAnalyticsService";
import { BulletListSkeleton } from "@/lib/utils/ChartsSkeletons";
import { LS_TOP_PAGES_PREFERENCE } from "@/lib/utils/Storage";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LoadingStateProps, PagesObjectProps, TopPagesProps } from "types/types.d";
import urlJoin from "url-join";
import { NoResults } from "../NoResults/NoResults";
import { TopPagesDetailedView } from "./DetailedView/TopPagesDetailedView";
import { ViewDetailedButton } from "./ViewDetailedButton";
import { Box, Paper, Tooltip, Text, Title, Flex, List, Group, Divider } from "@mantine/core";
import { convertSecondsToReadableFormat, numberToCommas } from "@/lib/utils/StringUtility";
import useDeepCompareEffect from "@/hooks/useDeepCompareEffect";
import { DynoTable, ProgressCell } from "@/ui/components/Common/DynoTable/DynoTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PALE_SHADES } from "@/lib/utils/Constants";
import classes from "./WebAnalyticsOverview.module.css";
import clsx from "clsx";
const waService = new WebAnalyticsService();

const TopPagesNavList: Array<PagesObjectProps> = [
  { key: "top", value: "Top Pages" },
  { key: "entry", value: "Entry Pages" },
  { key: "exit", value: "Exit Pages" }
];

const TopPages = ({ query, setQuery, isPublic }: any) => {
  // const isMobile = useMediaQuery("(max-width: 920px)");
  const [hasBecomeVisibleYet, setHasBecomeVisibleYet] = useState(false);

  const [opened, setOpened] = useState(false);
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const [activeTab, setActiveTab] = useLocalStorage<TopPagesProps>({
    key: LS_TOP_PAGES_PREFERENCE,
    defaultValue: "top",
    getInitialValueInEffect: false
  });

  // setting list for results
  const [list, setList] = useState<
    Array<{
      source: string;
      count: number;
      count_comparison?: number;
      count_percentage_change?: number;
      percentage: number;
      percentage_comparison?: number;
      percentage_percentage_change?: number;
      pageviews: number;
      pageviews_comparison?: number;
      pageviews_percentage_change?: number;
      total_entrances: number;
      total_entrances_comparison?: number;
      total_entrances_percentage_change?: number;
      total_exits: number;
      total_exits_comparison?: number;
      total_exits_percentage_change?: number;
    }>
  >([]);
  const [loading, setLoading] = useState<LoadingStateProps>("idle");
  const [tableColumns, setTableColumns] = useState<any[]>([]);

  // fetch service calls
  const fetchTopPages = async () => {
    setLoading("loading");
    await waService
      .topPages(activeWorkspace.id, activeTab, 1, 10, "visitors:desc", query)
      .then((res) => {
        if (res.data) {
          setList(res.data.data);
          if (res.data.columns) {
            const highestValue = res.data.highest_value;
            // Modify columns before setting the state
            const modifiedColumns = res.data.columns.map((column: { accessor: string }) => {
              if (column.accessor === "source_with_value") {
                return {
                  ...column,
                  render: (data: any) => (
                    <ProgressCellWithHover
                      value={highestValue === 0 ? 0 : Math.floor((data.value / highestValue) * 100)}
                      cellData={data}
                      onClick={handleOnClick}
                    />
                  )
                };
              }
              if (column.accessor === "visitors_with_percentage") {
                return {
                  ...column,
                  render: (data: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center"
                      }}>
                      <Text fw={400}>{numberToCommas(data.visitors)}</Text>
                      <Text fw={500} color="gray.6" fz={10} pl={"5px"} pt={"3px"}>
                        ({data.percentage}%)
                      </Text>
                    </Box>
                  )
                };
              }
              if (
                [
                  "visitors",
                  "pageviews",
                  "time_on_page",
                  "conversions",
                  "total_entrances",
                  "total_exits"
                ].includes(column.accessor)
              ) {
                return {
                  ...column,
                  render: (data: any) => (
                    <Text fw={400}>
                      {column.accessor === "time_on_page"
                        ? convertSecondsToReadableFormat(data)
                        : numberToCommas(data)}
                    </Text>
                  )
                };
              }
              if (
                ["bounce_rate", "scroll_depth", "conversion_rate", "percentage"].includes(
                  column.accessor
                )
              ) {
                return {
                  ...column,
                  render: (data: any) => <Text fw={400}>{data}%</Text>
                };
              }

              if (column.accessor.endsWith("percentage_change")) {
                return {
                  ...column,
                  render: ({ label, percentage_change, comparison_value }: any) => (
                    <Tooltip.Floating
                      label={`Comparison period ${label}: ${comparison_value}${
                        label === "CR" ? "%" : ""
                      }`}
                      position="top"
                      style={{ fontSize: "12px" }}>
                      <Text
                        fw={400}
                        color={
                          percentage_change !== undefined && percentage_change !== null
                            ? percentage_change > 0
                              ? "green.6"
                              : percentage_change < 0
                              ? "red.6"
                              : "gray.6"
                            : "gray.6"
                        }>
                        {percentage_change !== undefined &&
                        percentage_change !== null &&
                        percentage_change !== 0
                          ? `${percentage_change > 0 ? "+" : ""}${percentage_change.toFixed(1)}%`
                          : ""}
                      </Text>
                    </Tooltip.Floating>
                  )
                };
              }
              return column;
            });
            setTableColumns(modifiedColumns);
          }
        }
      })
      .catch((err) => {
        setList([]);
      });
    setLoading("loaded");
  };

  // on clicking any of the pages, it will be added to the query
  const handleOnClick = (source: string) => {
    const selector =
      activeTab === "top" ? "page" : activeTab === "entry" ? "entry_page" : "exit_page";
    setQuery({
      ...query,
      filters: [
        ...query.filters.filter((value: string) => !value.startsWith(`page:`)),
        `${selector}:${source}`
      ]
    });
  };

  const getExternalLink = (item: any) => {
    return query.domain &&
      query.domain.toLowerCase() !== "domain_not_found" &&
      query.domain.toLowerCase() !== "all_domains"
      ? urlJoin(`https://${query.domain}`, item.source)
      : "";
  };
  const containerRef = useRef();
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 0
  });

  const ProgressCellWithHover = ({ value, cellData, onClick }: any) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <Box onClick={() => onClick(cellData.source)}>
        <ProgressCell
          value={value}
          label={<LabelWithActions data={cellData} isHovered={isHovered} />}
          setIsHovered={setIsHovered}
          barColor={PALE_SHADES.blue}
        />
      </Box>
    );
  };

  const LabelWithActions = ({ data, isHovered }: any) => {
    const externalLink = getExternalLink(data);

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}>
        <Text>{data.source}</Text>
        {externalLink && isHovered && (
          <Tooltip label="Open link" color="dark" withArrow style={{ fontSize: "12px" }}>
            <Box
              pl={"1rem"}
              onClick={(e) => {
                e.stopPropagation();
                window.open(externalLink, "_blank");
              }}>
              <FontAwesomeIcon
                icon={regular("up-right-from-square")}
                className="w-4 h-4 text-gray-900 hover:opacity-80 "
              />
            </Box>
          </Tooltip>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (entry?.isIntersecting && !hasBecomeVisibleYet) {
      setHasBecomeVisibleYet(true);
      fetchTopPages();
    }
  }, [entry?.isIntersecting]);

  useDeepCompareEffect(() => {
    if (hasBecomeVisibleYet && entry?.isIntersecting) {
      fetchTopPages();
    } else {
      setHasBecomeVisibleYet(false);
    }
  }, [activeTab, query, query.filters, activeWorkspace.id]);

  return (
    <Paper withBorder shadow="xs" ref={ref}>
      {/* Modal for detailed view */}
      <TopPagesDetailedView
        query={query}
        opened={opened}
        activeTab={activeTab}
        setOpened={setOpened}
        handleOnClick={handleOnClick}
        getExternalLink={getExternalLink}
        TopPagesNavList={TopPagesNavList}
        isPublic={isPublic}
      />
      <Box
        h={500}
        component="div"
        style={{ overflowY: "scroll" }}
        sx={(theme) => ({
          borderColor:
            theme.colorScheme === "dark"
              ? theme.colors.darkBorderColor[0]
              : theme.colors.lightBorderColor[0]
        })}
        className="flex flex-col h-[36rem] border border-solid  rounded shadow-sm">
        <Flex
          component="div"
          sx={(theme) => ({
            borderColor:
              theme.colorScheme === "dark"
                ? theme.colors.darkBorderColor[0]
                : theme.colors.lightBorderColor[0],
            flexWrap: "wrap"
          })}
          align={"center"}
          p={"md"}>
          <Title flex={1} order={6} fw={600}>
            {TopPagesNavList.find((page: PagesObjectProps) => page.key === activeTab)?.value}
          </Title>

          <Flex>
            <Group gap={8}>
              {TopPagesNavList.map((item: PagesObjectProps, index) => (
                <div data-cy="top-pages-filter-nav-list" key={index}>
                  <Text
                    fz={13}
                    key={`top-pages:${index}`}
                    className={clsx(classes.tab, activeTab === item.key && classes.activeTab)}
                    fw={500}
                    onClick={() => setActiveTab(item.key)}>
                    {item.value}
                  </Text>
                </div>
              ))}
            </Group>
          </Flex>
        </Flex>
        <Divider />
        <div className="overflow-y-scroll" id="WebAnalytics:TopPages">
          {loading === "loaded" ? (
            <div className="overflow-y-scroll">
              <>
                {list.length > 0 ? (
                  <div data-cy="top-pages-results-list">
                    <DynoTable columns={tableColumns} data={list} />
                    {/*Detailed button for opening modal for showing detailed view*/}
                    {
                      <Box data-cy="top-pages-detailed-view-btn" mb={"sm"}>
                        <ViewDetailedButton
                          onClick={() => {
                            // fetchDetailedPagesList()
                            setOpened(true);
                          }}
                        />
                      </Box>
                    }
                  </div>
                ) : (
                  <>
                    <Flex align={"center"} justify={"center"} h={400}>
                      <NoResults text={"There is no data for pages."} heading={"No Results"} />
                    </Flex>
                  </>
                )}
              </>
            </div>
          ) : (
            <div className="ml-4">
              <BulletListSkeleton />
            </div>
          )}
        </div>
      </Box>
    </Paper>
  );
};

const TopPagesMemoized = React.memo(TopPages);
export { TopPagesMemoized as TopPages };
