import { MessageFormatElement } from "@formatjs/icu-messageformat-parser";

const locale: Record<string, Record<string, string> | Record<string, MessageFormatElement[]>> = {
  en: {
    "insights.headerText": "Detailed Funnel Analysis",
    "insights.headerTabs.overview": "Overview",
    "insights.headerTabs.breakdown": "Breakdown",
    "insights.headerTabs.breakdown.firstTouch": "First Touch",
    "insights.headerTabs.breakdown.source": "Source",
    "insights.headerTabs.breakdown.channel": "Channel",
    "insights.headerTabs.breakdown.referrer": "Referer",
    "insights.headerTabs.breakdown.location": "Location",
    "insights.headerTabs.breakdown.country": "Country",
    "insights.headerTabs.breakdown.city": "City",
    "insights.headerTabs.breakdown.technologies": "Technologies",
    "insights.headerTabs.breakdown.device": "Device",
    "insights.headerTabs.breakdown.browser": "Browser",
    "insights.headerTabs.breakdown.os": "Operating System",
    "insights.headerTabs.compare": "Compare",
    "insights.headerTabs.compare.currentVsPreviousDay": "Current vs previous day",
    "insights.headerTabs.compare.currentVsPreviousWeek": "Current vs previous week",
    "insights.headerTabs.compare.currentVsPreviousMonth": "Current vs previous month",
    "insights.headerTabs.compare.currentVsPreviousQuarter": "Current vs previous quarter",
    "insights.headerTabs.compare.currentVsPreviousYear": "Current vs previous year",
    "insights.headerTabs.compare.custom": "Custom",
    "insights.headerTabs.compare.currentPeriod": "Current Period",
    "insights.headerTabs.compare.comparedTo": "Compared To",
    "insights.headerTabs.compare.currentDay": "Current day",
    "insights.headerTabs.compare.currentWeek": "Current week",
    "insights.headerTabs.compare.currentMonth": "Current month",
    "insights.headerTabs.compare.currentQuarter": "Current quarter",
    "insights.headerTabs.compare.currentYear": "Current year",
    "insights.type.visitors": "visitors",
    "insights.type.users": "users",
    "insights.type.companies": "companies",
    // Cards
    "insights.cards.totalCount": "Total {type}",
    "insights.cards.totalCount.subtitle": "{type} who have visited the funnel",
    "insights.cards.conversionRate": "Conversion Rate",
    "insights.cards.conversionRate.subtitle": "of {type} have completed all steps",
    "insights.cards.mostDropOffStep": "Most Drop-off Step",
    "insights.cards.mostDropOffStep.subtitle": "Highest drop-off rate of {value} at this step",
    "insights.cards.current.headline": "Current",
    "insights.cards.compared.headline": "Compared",
    "insights.cards.current.total": "Total of {count} {type} entered the funnel.",
    "insights.cards.current.conversion": "{value} of {type} have completed all steps.",
    "insights.cards.current.dropOff": "Most drop-off is observed at Step {step} with {dropOff}.",
    "insights.cards.current.dropOff.step": "Step",
    // Charts
    "insights.charts.label.dropOff": "Drop Off",
    "insights.charts.label.converted": "Converted",
    "insights.charts.tooltip.completedStep": "Completed Step",
    "insights.charts.tooltip.completedStepRatio": "Completed Step Ratio",
    "insights.charts.tooltip.dropOffFromPreviousStep": "Drop Off (from previous step)",
    "insights.charts.tooltip.dropOffRatioFromPreviousStep": "Drop Off Ratio (from previous step)",
    "insights.charts.tooltip.dropOffRatioFromFirstStep": "Drop Off Ratio (from first step)",
    "insights.charts.tooltip.avgTime": "Avg. Time in Step",
    // Table
    "insights.table.column.step": "Step",
    "insights.table.column.conversionRate": "Conversion Rate",
    "insights.table.column.conversionRateFromFirstStep": "Conversion Rate from First Step",
    "insights.table.column.entered": "Entered",
    "insights.table.column.completed": "Completed",
    "insights.table.column.droppedOff": "Dropped Off",
    "insights.table.column.avgTime": "Average Time"
  },
  jp: {
    "insights.headerText": "詳細なファネル分析",
    "insights.headerTabs.overview": "概要",
    "insights.headerTabs.breakdown": "分解",
    "insights.headerTabs.breakdown.firstTouch": "ファーストタッチ",
    "insights.headerTabs.breakdown.source": "ソース",
    "insights.headerTabs.breakdown.channel": "チャンネル",
    "insights.headerTabs.breakdown.referrer": "リファラ",
    "insights.headerTabs.breakdown.location": "ロケーション",
    "insights.headerTabs.breakdown.country": "国",
    "insights.headerTabs.breakdown.city": "市",
    "insights.headerTabs.breakdown.technologies": "技術",
    "insights.headerTabs.breakdown.device": "デバイス",
    "insights.headerTabs.breakdown.browser": "ブラウザ",
    "insights.headerTabs.breakdown.os": "オペレーティングシステム",
    "insights.headerTabs.compare": "比較",
    "insights.headerTabs.compare.currentVsPreviousDay": "前日との比較",
    "insights.headerTabs.compare.currentVsPreviousWeek": "先週との比較",
    "insights.headerTabs.compare.currentVsPreviousMonth": "先月との比較",
    "insights.headerTabs.compare.currentVsPreviousQuarter": "前四半期との比較",
    "insights.headerTabs.compare.currentVsPreviousYear": "前年との比較",
    "insights.headerTabs.compare.custom": "カスタム",
    "insights.headerTabs.compare.currentPeriod": "現在の期間",
    "insights.headerTabs.compare.comparedTo": "比較対象",
    "insights.headerTabs.compare.currentDay": "今日",
    "insights.headerTabs.compare.currentWeek": "今週",
    "insights.headerTabs.compare.currentMonth": "今月",
    "insights.headerTabs.compare.currentQuarter": "今四半期",
    "insights.headerTabs.compare.currentYear": "今年",
    "insights.type.visitors": "訪問者",
    "insights.type.users": "ユーザー",
    "insights.type.companies": "企業",
    // Cards
    "insights.cards.totalCount": "合計{type}",
    "insights.cards.totalCount.subtitle": "ファネルを訪れた{type}",
    "insights.cards.conversionRate": "コンバージョン率",
    "insights.cards.conversionRate.subtitle": "{type}のうち、すべてのステップを完了した人の割合",
    "insights.cards.mostDropOffStep": "最もドロップオフしたステップ",
    "insights.cards.mostDropOffStep.subtitle": "このステップでの{value}の最高のドロップオフ率",
    "insights.cards.current.headline": "現在",
    "insights.cards.compared.headline": "比較",
    "insights.cards.current.total": "ファネルに入った{count} {type}の合計。",
    "insights.cards.current.conversion": "{type}のうち、{value}がすべてのステップを完了しました。",
    "insights.cards.current.dropOff":
      "最もドロップオフが観察されたステップは{step}で、{dropOff}です。",
    "insights.cards.current.dropOff.step": "ステップ",
    // Charts
    "insights.charts.label.dropOff": "ドロップオフ",
    "insights.charts.label.converted": "コンバージョン",
    "insights.charts.tooltip.completedStep": "完了したステップ",
    "insights.charts.tooltip.completedStepRatio": "完了したステップの割合",
    "insights.charts.tooltip.dropOffFromPreviousStep": "前のステップからのドロップオフ",
    "insights.charts.tooltip.dropOffRatioFromPreviousStep": "前のステップからのドロップオフ率",
    "insights.charts.tooltip.dropOffRatioFromFirstStep": "最初のステップからのドロップオフ率",
    "insights.charts.tooltip.avgTime": "平均時間",
    // Table
    "insights.table.column.step": "ステップ",
    "insights.table.column.conversionRate": "コンバージョン率",
    "insights.table.column.conversionRateFromFirstStep": "最初のステップからのコンバージョン率",
    "insights.table.column.entered": "入力済み",
    "insights.table.column.completed": "完了",
    "insights.table.column.droppedOff": "ドロップオフ",
    "insights.table.column.avgTime": "平均時間"
  }
};

export default locale;
