import axios from './JWTService';
export class WorkspaceFilterInternalTrafficService {
  create = (wsId: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/v1/workspace_filter_internal_traffic/${wsId}`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  update = (wsId: string, id: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/v1/workspace_filter_internal_traffic/${wsId}/${id}`, payload)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  all = (wsId: string, page: number = 1, limit: number = 20): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/workspace_filter_internal_traffic/${wsId}?page=${page}&limit=${limit}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  get = (wsId: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/v1/workspace_filter_internal_traffic/${wsId}/${id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (wsId: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/v1/workspace_filter_internal_traffic/${wsId}/${id}`)
        .then((response) => {
          // update local storage.
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
