import profileIcon from "@assets/images/icons/navigation/profile.svg";
import useWorkspaceUtilityHook from "@/hooks/useWorkspaceUtilityHook";
import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { OrganizationService } from "@/lib/services/OrganizationService";
import { UserService } from "@/lib/services/UserService";
import { TOAST_MESSAGES, WORKSPACE_MEMBER_ROLES } from "@/lib/utils/Constants";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { SettingsHeaderContent } from "@/ui/components/App/Settings/SettingsHeaderContent/SettingsHeaderContent";
import Icon from "@/ui/components/Common/Icon/Icon";
import { ValidationLabel } from "@/ui/components/Common/ValidationLabel/ValidationLabel";

import { Button, Input, SimpleGrid, Title, Text, Divider, Box, Tooltip } from "@mantine/core";

interface IFormInputs {
  first_name: string;
  last_name: string;
  company: string;
}

export const AccountsGeneralProfileUpdate: React.FC<any> = () => {
  const { user, setUser, activeWorkspace, setActiveWorkspace } = useContext(AppLifecycleContext);
  const [actionState, setActionState] = useState("idle");
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm<IFormInputs>();
  const { hasRolesNotWith, hasRolesWith } = useWorkspaceUtilityHook();

  const watchFirstName = watch("first_name", user?.first_name);
  const watchLastName = watch("last_name", user?.last_name);
  const watchCompany = watch("company", activeWorkspace?.organization?.name);
  let isChangedForm =
    watchFirstName !== user?.first_name ||
    watchLastName !== user?.last_name ||
    watchCompany !== activeWorkspace?.organization?.name;

  const profileUpdate = async (data: any) => {
    console.log(data);
    data.email = user?.email;
    let companyName = data.company;
    delete data.company;
    const userServiceObj = new UserService();
    const organizationServiceObj = new OrganizationService();
    setActionState("loading");
    const isUserUpdated = await userServiceObj
      .updateProfileDetails(user?.id, data)
      .then((res) => {
        if (res.data) {
          setUser(res.data);
        }
        return true;
      })
      .catch((err) => {
        return false;
      });
    console.log(data);
    if (!isUserUpdated) {
      toast.error("Unable to update profile details. Please try again.");
      return;
    }
    if (isUserUpdated && hasRolesWith([WORKSPACE_MEMBER_ROLES.OWNER])) {
      await organizationServiceObj
        .updateOrganizationDetails(activeWorkspace?.organization?.id, {
          name: companyName
        })
        .then((res) => {
          if (res.data) {
            activeWorkspace.organization = res.data;
            setActiveWorkspace(activeWorkspace);
            toast.success(TOAST_MESSAGES.PROFILE_DETAILS_CHANGED);
          }
        })
        .catch((err) => {
          toast.error("Unable to update profile details. Please try again.");
        });
    } else {
      toast.success(TOAST_MESSAGES.PROFILE_DETAILS_CHANGED);
    }
    setActionState("loaded");
  };

  const handleImageInput = async (selectorFiles: FileList | null) => {
    console.log("Handle Image Input", selectorFiles);
    if (selectorFiles) {
      let formData = new FormData();
      formData.append("image", selectorFiles[0]);
      const userServiceObj = new UserService();
      await userServiceObj
        .uploadProfileImage(user.id, formData)
        .then((res) => {
          console.log(res);
          if (res.data) {
            setUser(res.data);
            toast.success(TOAST_MESSAGES.PROFILE_IMAGE_UPLOADED);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <Title order={4} fw={600} mb="md">
        Account Information
      </Title>
      <Text mb="md">Edit your basic account and profile details.</Text>
      <Divider mb="md" />
      <Box>
        <Box mb="md">
          <div className="um-settings-wrapper--container--section--profile-image--container">
            {user?.profile_image ? (
              <img src={`${user?.profile_image}-/scale_crop/75x75/smart/`} alt="" />
            ) : (
              <img src={profileIcon} alt="" />
            )}
            <Tooltip label={"Upload profile image"}>
              <div
                className="um-settings-wrapper--container--section--profile-image--container--edit"
                onClick={() => {
                  document.getElementById("uploadProfileImage")?.click();
                }}>
                <Icon icon="Edit"></Icon>
              </div>
            </Tooltip>

            <input
              type="file"
              accept="image/jpeg,image/png"
              className="display-none"
              id="uploadProfileImage"
              onChange={(e) => {
                handleImageInput(e.target.files);
              }}
            />
          </div>
        </Box>
        <form id="accountsGeneralProfileUpdate" onSubmit={handleSubmit(profileUpdate)}>
          <SimpleGrid cols={{ base: 2, md: 2, sm: 1 }}>
            <Box mb="md">
              {/* <Label text="First Name"></Label> */}
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <Input.Wrapper size="xs" id={"firstName"} label="First Name">
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="Carl"
                      error={(invalid || error) === true}
                      data-cy="accounts-general-first-name"
                    />
                  </Input.Wrapper>
                )}
                name="first_name"
                control={control}
                defaultValue={user?.first_name}
                rules={{ required: true, maxLength: 24 }}
              />

              {errors.first_name?.type === "required" && (
                <ValidationLabel
                  validationType="error"
                  text="First name is required"
                  size="small"
                  customClasses="pt-2"></ValidationLabel>
              )}
              {errors.first_name?.type === "maxLength" && (
                <ValidationLabel
                  validationType="error"
                  text="First name should be less than 24 characters."
                  size="small"
                  customClasses="pt-2"></ValidationLabel>
              )}
            </Box>
            <Box mb="md">
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <Input.Wrapper size="xs" id={"lastName"} label="Last Name">
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="Frederick"
                      error={(invalid || error) === true}
                      data-cy="accounts-general-last-name"
                    />
                  </Input.Wrapper>
                )}
                name="last_name"
                control={control}
                defaultValue={user?.last_name}
                rules={{ required: true, maxLength: 24 }}
              />

              {errors.last_name?.type === "required" && (
                <ValidationLabel
                  validationType="error"
                  text="First name is required"
                  size="small"
                  customClasses="pt-2"></ValidationLabel>
              )}
              {errors.last_name?.type === "maxLength" && (
                <ValidationLabel
                  validationType="error"
                  text="First name should be less than 24 characters."
                  size="small"
                  customClasses="pt-2"></ValidationLabel>
              )}
            </Box>
          </SimpleGrid>
          {hasRolesWith([WORKSPACE_MEMBER_ROLES.OWNER]) && (
            <Box mb="md" w={"49.4%"}>
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <Input.Wrapper size="xs" id={"company"} label="Company">
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="e.g Usermaven"
                      error={(invalid || error) === true}
                      data-cy="accounts-general-company"
                    />
                  </Input.Wrapper>
                )}
                name="company"
                control={control}
                defaultValue={activeWorkspace?.organization?.name}
                rules={{ required: true, maxLength: 24 }}
              />

              {errors.company?.type === "required" && (
                <ValidationLabel
                  validationType="error"
                  text="Company name is required"
                  size="small"
                  customClasses="pt-2"></ValidationLabel>
              )}
              {errors.company?.type === "maxLength" && (
                <ValidationLabel
                  validationType="error"
                  text="Company should be less than 32 characters."
                  size="small"></ValidationLabel>
              )}
            </Box>
          )}

          <Button
            type="submit"
            disabled={!isChangedForm || actionState === "loading"}
            loading={actionState === "loading"}
            data-cy="accounts-general-name-submit">
            Save
          </Button>
        </form>
      </Box>
    </>
  );
};
