import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Center, Flex, Paper, Text, Title } from "@mantine/core";

export const ShareDashboardUnauthorized = () => {
  const theme = useGlobalMantineTheme();
  return (
    <Center>
      <Paper sx={(theme) => ({})} maw={450} shadow="sm" withBorder>
        <Flex justify={"center"} align={"center"}>
          <Box component="div" sx={(theme) => ({})} w={400} py="md">
            <div className="h-auto drop-shadow-sm border border-solid shadow-sm rounded-md border-gray-200">
              <div className="flex flex-col">
                <Center py="sm">
                  <FontAwesomeIcon icon={regular("lock")} className={" text-lg sm:text-2xl"} />
                </Center>
                <Title mb="sm" ta="center" order={5} fw={600}>
                  Invalid link
                </Title>
                <Text mb="sm" ta="center">
                  Your shareable link is invalid, please enter a valid link.
                </Text>
              </div>
            </div>
          </Box>
        </Flex>
      </Paper>
    </Center>
  );
};
