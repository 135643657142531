import ReactEChartsCore from "echarts-for-react/lib/core";
import { BarChart, LineChart, PieChart } from "echarts/charts";
import { LabelLayout, UniversalTransition } from "echarts/features";
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";
import * as echarts from "echarts/core";

import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useMemo, useState } from "react";
import { Box, SegmentedControl, useMantineTheme } from "@mantine/core";
import { PagingPosition, Table } from "ka-table";
import { DataType, SortingMode } from "ka-table/enums";
import "ka-table/style.css";
import useGlobalMantineTheme from "@/hooks/useGlobalMantineTheme";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  PieChart,
  CanvasRenderer,
  UniversalTransition,
  LabelLayout,
  DataZoomComponent
]);


const Chart = ({
                 chartData,
                 tableData
               }: {
  chartData?: {
    data: string[],
    values: number[]
    xlabel?: string
    ylabel?: string
  }
  tableData?: Record<string, Record<string, string | number>>;
}) => {
  const [chartType, setChartType] = useState(!chartData?.values ? 'table' : "bar");
  const [option, setOption] = useState({});
  const theme = useGlobalMantineTheme();

  const pieData = useMemo(() => {
    // Assume `chartData` is defined and populated
    let rawData = chartData?.values?.map((value, index) => ({ value: value, name: chartData?.data[index] }));

    if (!rawData) {
      return [];
    }


    // Sort rawData in descending order
    // @ts-ignore
    let sortedData = rawData.sort((a, b) => b.value - a.value);

    // Then we split into top 5 and the rest
    let topFive = sortedData.slice(0, 5);
    let others = sortedData.slice(5);

    if (others.length === 0) {
      return topFive;
    }

    // Now you can get the sum of all "others"
    let othersSum = others.reduce((acc, curr) => acc + curr.value, 0);

    // If you want to include "others" in your chart data:
    let finalData = [...topFive, { value: othersSum, name: "Others" }];

    return finalData;
  }, [chartType]);

  useEffect(() => {
    if (chartType === "pie") {
      setOption({
        tooltip: {
          trigger: "item"
        },
        legend: {
          bottom: 5,
          left: "center",
          textStyle: {
            color: theme.colorScheme === "dark" ? theme.white : theme.colors.dark[8]
          }
        },
        series: [{
          itemStyle: {
            borderRadius: 10,
            borderColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : "#f8f8f8",
            borderWidth: 2
          },
          avoidLabelOverlap: false,
          radius: ["40%", "70%"],
          data: pieData,
          type: "pie",
          label: {
            show: false,
            position: "center"
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
              textShadowColor: "transparent",
              textBorderColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : "#f8f8f8",
              color: theme.colorScheme === "dark" ? "#f8f8f8" : theme.colors.dark[8]
            }
          },
          labelLine: {
            show: false
          }
        }]
      });
    } else {
      setOption({
        tooltip: {
          trigger: "item"
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: chartData && chartData?.data?.length > 10 ? 80 : 40,
          containLabel: true
        },
        dataZoom: chartData && chartData?.data?.length > 10 ? [
          {
            type: "inside"
          },
          {
            type: "slider"
          }
        ] : [],
        xAxis: {
          name: chartData?.xlabel || "",
          nameLocation: "center",
          type: "category",
          nameGap: 30,
          data: chartData?.data,
          splitLine: {
            lineStyle: {
              color: theme.colorScheme === "dark" ? theme.colors.dark[8] : "#f8f8f8",
              width: 2
            }
          }
        },
        yAxis: {
          name: chartData?.ylabel || "",
          nameLocation: "middle",
          nameGap: 60,
          type: "value",
          splitLine: {
            lineStyle: {
              color: theme.colorScheme === "dark" ? theme.colors.dark[8] : "#f8f8f8",
              width: 2
            }
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            },
            pointWidth: 10,
            pointPadding: 0
          }
        },
        series: [{
          // barWidth: '30px',
          // barCategoryGap: '40%',
          large: true,
          data: chartData?.values,
          type: chartType,
          color: "#7f17d1",

          itemStyle: {
            borderRadius: [6, 6, 0, 0]
          }
        }]
      });
    }
  }, [chartType]);


  const showRichContent = useMemo(() => {
    return chartData?.values?.length || tableData;
  }, [chartType, tableData]);

  return (
    <Box pos={"relative"} mt={"md"} sx={{
      minWidth: "400px"
    }}>
      {
        showRichContent && (
          <Box sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            right: "0",
            top: "-10px"
          }}>
            <SegmentedControl
              size={"xs"}
              value={chartType}
              onChange={(value) => setChartType(value)}
              data={[
                { label: "Table", value: "table", disabled: !tableData },
                { label: "Bar", value: "bar", disabled: !chartData?.values?.length },
                { label: "Line", value: "line", disabled: !chartData?.values?.length },
                { label: "Pie", value: "pie", disabled: !chartData?.values?.length }
              ]}
            />
          </Box>
        )
      }

      {
        tableData && chartType === "table" ? (
          <Box mt={"40px"}>
            <Box sx={{
              [`.ka`]: {
                background: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
                border: theme.colorScheme === "dark" ? "1px solid #151515" : "1px solid #ededf0",
                borderRadius: "6px"
              },

              [`.ka-cell-text`]: {
                // color: "#01002a",
                color: theme.colorScheme === "dark" ? "#C1C2C5" : "#01002a",
                wordBreak: "break-word"
              },

              [`.ka-thead-background`]: {
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
                boxShadow:
                  theme.colorScheme !== "dark"
                    ? "inset 0 0px 0 #ededf0, inset 0 -1px 0 #ededf0"
                    : "inset 0 0px 0 #151515, inset 0 -1px 0 #151515"
              },

              [`.ka-thead-cell`]: {
                textTransform: "capitalize",
                verticalAlign: "middle",
                fontWeight: 500
              },

              [`.ka-row`]: {
                borderColor: theme.colorScheme === "dark" ? "rgba(21,21,21,0.25)" : "#F9FBFC"
              },

              // Odd rows
              [`.ka-tr.ka-row:nth-of-type(even) .ka-cell`]: {
                // background: "#fbfbfb",
                backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white
              },

              // Hover
              [`.ka-tr.ka-row .ka-cell`]: {
                cursor: "pointer",
                transition: "background-color 0.02s ease"
              },
              [`.ka-tr.ka-row:hover .ka-cell`]: {
                // background: "#f2eafc",
                background: theme.colorScheme === "dark" ? theme.colors.dark[7] : "#f2eafc"
              },

              [`.ka-drag-over-column`]: {
                // background: "rgba(242,234,252,0.6)",
                background: theme.colorScheme === "dark" ? "#3a3a3d" : "rgba(242,234,252,0.6)",
                boxShadow: "inset 0 1px 0 #ededf0, inset 0 -1px 0 #ededf0"
              },

              [`.ka-paging-page-index`]: {
                width: "31px",
                height: "31px"
              }

            }}>
              <TableComponent tableData={tableData} />
            </Box>
          </Box>
        ) : (
          <>
            {
              chartData?.data && (
                <ReactEChartsCore
                  echarts={echarts}
                  option={option}
                  notMerge={true}
                  lazyUpdate={true}
                  style={{ height: "400px" }}
                />
              )
            }
          </>
        )
      }


    </Box>
  );
};


const TableComponent = ({ tableData }: { tableData: Record<string, Record<string, string | number>> }) => {
  // Convert tableData into dataArray structure
  const dataArray = Object.keys(tableData[Object.keys(tableData)[0]]).map((key, index) => {
    const row = { id: index }; // replace with unique id for each row if available
    Object.keys(tableData).forEach((headerKey: string) => {
      (row as any)[headerKey] = (tableData as any)[headerKey][key];
    });
    return row;
  });

  // Get column data
  const columns = Object.keys(tableData).map((headerKey) => ({
    key: headerKey,
    title: headerKey.replace(/_/g, " "),
    dataType: typeof tableData[headerKey][Object.keys(tableData[headerKey])[0]] === "number" ? DataType.Number : DataType.String,
    width: 190
  }));

  return (
    <Table
      columns={columns}
      data={dataArray}
      rowKeyField={"id"}
      sortingMode={SortingMode.Single}
      paging={{
        enabled: true,
        pageIndex: 0,
        pageSize: 10,
        position: PagingPosition.Bottom
      }}
    />
  );
};

export default Chart;