import AppLifecycleContext from "@/lib/contexts/AppLifecycleContext";
import { CompaniesService } from "@/lib/services/CompaniesService";
import { utcToRelativeTimezone } from "@/lib/utils/DateUtility";
import { FC, useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { loaderColor } from "@/style/Colors";
import { LooseObject } from "types/types.d";
import { HeaderNavigation } from "@/ui/components/App/HeaderNavigation/HeaderNavigation";
import { IconCard } from "@/ui/components/Common/IconCard/IconCard";
import { CompanyAttributes } from "./Components/CompanyAttributes";
import { CompanyUsers } from "./Components/CompanyUsers";

const Company: FC<{}> = () => {
  const { segment, companyId } = useParams<{
    segment: string;
    companyId: string;
  }>();
  const { activeWorkspace } = useContext(AppLifecycleContext);
  const navigate = useNavigate();

  const [company, setCompany] = useState<LooseObject>({
    name: "Usermaven",
    id: "",
    created_at: "2022-01-02 12:32:00",
    last_seen: "2022-01-15 12:32:00",
    number_of_users: 1
  });
  const [isLoading, setIsLoading] = useState("idle");
  const getCompanyInitials = () => {
    const s = company?.name.split(" ");
    return s.length > 1 ? `${s[0].substring(0, 1)}${s[1].substring(0, 1)}` : s[0].substring(0, 1);
  };

  const getCompanyDetails = async () => {
    const companiesService = new CompaniesService();
    setIsLoading("loading");
    await companiesService
      .detailsById(activeWorkspace.id, companyId)
      .then((res) => {
        if (res.data) {
          setCompany(res.data);
        }
      })
      .catch((err) => {});
    setIsLoading("loaded");
  };
  useEffect(() => {
    getCompanyDetails();
  }, []);

  return (
    <>
      <div className="um-mc">
        <HeaderNavigation
          is_company_view={true}
          company_name={`${company?.name}`}></HeaderNavigation>
        <div className="px-4 py-8 shadow-sm my-4 flex border border-solid border-gray-200">
          {isLoading === "loaded" ? (
            <>
              <div className="grid grid-cols-5 gap-4 flex-grow">
                <div className="flex items-center col-span-2">
                  <div className="w-24 h-24 flex items-center  font-semibold justify-center uppercase pl-4">
                    <div className="w-16 h-16 text-white rounded-full text-xl shadow color-band-2 text-center items-center flex justify-center">
                      {getCompanyInitials()}
                    </div>
                  </div>
                  <div className="pl-4">
                    <div className="text-xl font-semibold line-clamp-1 capitalize">
                      {company?.name}
                    </div>
                  </div>
                </div>

                <IconCard
                  title="Created"
                  icon="clock"
                  value={utcToRelativeTimezone(company.created_at.replace(" ", "T"))}></IconCard>
                <IconCard
                  title="Last seen"
                  icon="eye"
                  value={utcToRelativeTimezone(company.last_seen.replace(" ", "T"))}
                  bg="bg-yellow-100"
                  iconColor="text-yellow-500"></IconCard>
                <IconCard
                  title="Users"
                  icon="users"
                  value={company.number_of_users}
                  bg="bg-blue-100"
                  iconColor="text-blue-500"></IconCard>
                {/* <IconCard
                  title="Health Score"
                  icon="heart"
                  value={"Coming soon"}
                  bg="bg-green-100"
                  iconColor="text-green-500"
                  border={false}
                ></IconCard> */}
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-center flex-grow w-full">
                <Oval color={loaderColor} height={12} width={12} />
              </div>
            </>
          )}
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <CompanyAttributes></CompanyAttributes>
          </div>
          <div className="col-span-2">
            <CompanyUsers></CompanyUsers>
          </div>
        </div>
      </div>
    </>
  );
};

export default Company;
